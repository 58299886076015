import classNames from 'classnames';
import { ReactNode, useCallback } from 'react';

import './style.scss';

interface Props {
  active: boolean;
  children: ReactNode;
  level: number;
  navId: string;
  onNavItemClick: (itemId: string) => void;
}

export function Button(props: Props) {
  const className = classNames(
    'AnchorNavItem',
    `AnchorNavItem--level-${props.level}`,
    { 'AnchorNavItem--active': props.active },
  );
  const onClick = useCallback(() => {
    props.onNavItemClick(props.navId);
  }, [props]);
  return (
    <button type="button" className={className} onClick={onClick}>
      {props.children}
    </button>
  );
}

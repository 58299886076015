import { connect } from 'react-redux';

import { selectKindId } from 'reducers/productVersion';

const mapStateToProps = (state) => ({
  productKindId: selectKindId(state),
});

const withProductKind = (WrappedComponent) =>
  connect(mapStateToProps)(WrappedComponent);

export default withProductKind;

import { createSelector } from '@reduxjs/toolkit';
import { List, Map, fromJS } from 'immutable';
import { curry, flow } from 'lodash/fp';

import { OrganizationSource } from '@alkem/lib-front-model';
import { UserImmutable } from '@alkem/lib-front-model/immutable';

import { CONSUMER_UNIT } from 'constants/fields';
import {
  getReadOnlyCustomFields,
  hasAcceptedSourcing,
  hasAssortmentManagement,
  hasCustomSkuGtinZpckSetting,
  hasGroupPermissions,
  hasLegacyTextile,
  hasMarketPermissions,
  hasOnlyExclusiveProducts,
  hasViewSourcing,
  isEligibleForSourcing,
} from 'core/api/organization-settings';
import {
  canCreateProduct,
  filterOrganizationTargetMarkets,
  getIsScopedFor,
  getLocalesByTargetMarket,
  getMyFields,
  getOrganization,
  getOrganizationId,
  getOrganizationSettings,
  getOrganizationSource,
  getOrganizationTargetMarketIds,
  getOrganizationUuid,
  getRole,
  getSupportedLocales,
  getUserId,
  getUserSetting,
  getUserSettings,
  getWorksOnTargetMarkets,
  hasAnyProductUpdatePermission,
  isAdmin,
  isAtLeastPlatformAdmin,
  isAtLeastPlatformAdminLoggedAs,
  isLoggedAs,
  isMaker,
  isManufacturer,
  isPrivateLabel,
  isRetailer,
  isSuperAdmin,
  isSuperAdminLoggedAs,
  isThirdParty,
  loggedAsOrganizationPermissions,
  managesOrganization,
} from 'core/api/user';
import {
  selectHasMultipleLocales,
  selectHasThirdPartyPhysicalCheckStatus,
} from 'modules/feature-flag/selectors';
import { GlobalState } from 'types';
import { keyBy } from 'utils/fp';
import { get } from 'utils/immutable';

import { USER_REDUCER_KEY } from '../constants';

export const selectUser = (state: GlobalState): UserImmutable =>
  state[USER_REDUCER_KEY];

export const selectOrganizationId = createSelector(
  selectUser,
  getOrganizationId,
);

export const selectOrganizationUuid = createSelector(
  selectUser,
  getOrganizationUuid,
);

export const selectOrganization = createSelector(selectUser, getOrganization);

export const selectIsScopedFor = createSelector(selectUser, getIsScopedFor);

export const selectWorksOnTargetMarkets = createSelector(
  selectUser,
  getWorksOnTargetMarkets,
);

export const selectOrganizationTargetMarkets = createSelector(
  selectOrganizationId,
  selectIsScopedFor,
  selectWorksOnTargetMarkets,
  filterOrganizationTargetMarkets,
);

export const selectOrganizationTargetMarketsJS = createSelector(
  selectOrganizationTargetMarkets,
  (e) => e.toJS(),
);

export const selectTargetMarketLabelsById = createSelector(
  selectOrganizationTargetMarkets,
  (tms) =>
    keyBy('id', tms)
      .map((tm) => get(tm, 'label'))
      .toJS(),
);

export const selectOrganizationTargetMarketIds = createSelector(
  selectUser,
  getOrganizationTargetMarketIds,
);

export const selectIsAdmin = createSelector(selectUser, isAdmin);

export const selectIsSuperAdmin = createSelector(selectUser, isSuperAdmin);

export const selectIsSuperAdminLoggedAs = createSelector(
  selectUser,
  isSuperAdminLoggedAs,
);

export const selectIsLoggedAs = createSelector(selectUser, isLoggedAs);

export const selectLoggedAsOrganizationPermissions = createSelector(
  selectUser,
  loggedAsOrganizationPermissions,
);

export const selectLoggedAsHasDeleteSharingUnitPermission = createSelector(
  selectLoggedAsOrganizationPermissions,
  (permissions) => permissions.contains('organization.product.unshare'),
);

export const selectManagesOrganization = createSelector(
  selectUser,
  managesOrganization,
);

export const selectIsMaker = createSelector(selectUser, isMaker);

export const selectIsManufacturer = createSelector(selectUser, isManufacturer);

export const selectIsRetailer = createSelector(selectUser, isRetailer);

export const selectIsPrivateLabel = createSelector(selectUser, isPrivateLabel);

export const selectIsThirdParty = createSelector(selectUser, isThirdParty);

export const selectIsPhysicalChecker = createSelector(
  selectUser,
  selectIsThirdParty,
  (user, _isThirdParty) =>
    _isThirdParty && selectHasThirdPartyPhysicalCheckStatus(user),
);

export const selectIsPhysicalCheckManufacturer = createSelector(
  selectUser,
  selectIsManufacturer,
  (user, _isManufacturer) =>
    _isManufacturer && selectHasThirdPartyPhysicalCheckStatus(user),
);

export const selectUserId = createSelector(selectUser, (user) =>
  getUserId(user),
);

export const selectUserSettings = flow(selectUser, getUserSettings);

export const selectUserSetting = curry((setting, state) =>
  flow(selectUser, getUserSetting(setting))(state),
);

export const selectUserOrganizationSettings = createSelector(
  selectUser,
  (user) => getOrganizationSettings(user),
);

export const selectUserRole = createSelector(selectUser, (user) =>
  getRole(user),
);

export const selectMyFields = createSelector(selectUser, (user) =>
  getMyFields(user),
);

export const selectMyConsumerUnitFields = createSelector(
  selectMyFields,
  (myFields) => get(myFields, CONSUMER_UNIT),
);

export const selectDefaultLocale = createSelector(selectUser, (user) =>
  getSupportedLocales(user).first(),
);

export const selectLocales = createSelector(
  selectUser,
  selectDefaultLocale,
  (user, defaultLocale) =>
    selectHasMultipleLocales(user)
      ? getSupportedLocales(user)
      : fromJS([defaultLocale]),
);

export const selectLocalesByTargetMarket = createSelector(
  selectUser,
  getLocalesByTargetMarket,
);

export const selectLocalesByTargetMarketJS = createSelector(
  selectLocalesByTargetMarket,
  (e) => e.toJS(),
);

export const selectOrganizationSettings = createSelector(
  selectUser,
  getOrganizationSettings,
);

export const selectOrganizationSource = createSelector(
  selectUser,
  getOrganizationSource,
);

export const selectIsProductDnaSource = createSelector(
  selectOrganizationSource,
  (source) => source === OrganizationSource.PRODUCTDNA,
);

export const selectIsEligibleForSourcing = createSelector(selectUser, (user) =>
  isEligibleForSourcing(getOrganizationSettings(user)),
);

export const selectHasOnlyExclusiveProducts = createSelector(
  selectUser,
  (user) => hasOnlyExclusiveProducts(getOrganizationSettings(user)),
);

export const selectHasLegacyTextile = createSelector(selectUser, (user) =>
  hasLegacyTextile(getOrganizationSettings(user)),
);

export const selectHasAcceptedSourcing = createSelector(selectUser, (user) =>
  hasAcceptedSourcing(getOrganizationSettings(user)),
);

export const selectHasViewSourcing = createSelector(selectUser, (user) =>
  hasViewSourcing(getOrganizationSettings(user)),
);

export const selectDefaultTargetMarket = createSelector(
  selectOrganizationTargetMarkets,
  (targetMarkets) => targetMarkets.get(0),
);

export const selectHasChannelPermissionFeature = createSelector(
  selectOrganizationSettings,
  (settings) => hasGroupPermissions(settings),
);

export const selectHasMarketPermissionFeature = createSelector(
  selectOrganizationSettings,
  (settings) => hasMarketPermissions(settings),
);

export const selectHasAssortmentManagement = createSelector(
  selectOrganizationSettings,
  (settings) => hasAssortmentManagement(settings),
);

export const selectCustomSkuGtinZpckSetting = createSelector(
  selectOrganizationSettings,
  (settings) => hasCustomSkuGtinZpckSetting(settings),
);

export const selectHasAnyProductUpdatePermission = createSelector(
  selectUser,
  hasAnyProductUpdatePermission,
);

export const selectCanCreateProduct = createSelector(
  selectUser,
  canCreateProduct,
);

export const isCustomFieldReadOnly = (customFieldName) => (state) =>
  getReadOnlyCustomFields(selectOrganizationSettings(state)).contains(
    customFieldName,
  );

export const selectHasUnshareProduct = (state) => {
  const user = selectUser(state);
  return isAtLeastPlatformAdmin(user) || isAtLeastPlatformAdminLoggedAs(user);
};

export const selectFeatures = createSelector(selectUser, (user) =>
  Map(get(user, 'features')),
);

export const selectFlags = createSelector(selectFeatures, (features) =>
  features.toJS(),
);

export const selectHasNielsenApp = createSelector(selectUser, (user) =>
  (get(user, ['applications'], List()) as List<any>).some(
    (app) => get(app, ['name']) === 'Nielsen',
  ),
);

import { PureComponent } from 'react';

import { Button } from '@alkem/react-ui-button';

import { FieldAction } from '../../AssetV2/types';

import { PictureModal } from './picture-modal';

interface Props {
  items: any[];
  action: FieldAction;
  contentOwnerId: number;
  productId: number;
  productKeyId: number;
  productVersionId: number;
  targetMarketId: number;
  updateEntities: (assetType: string) => void;
}

interface State {
  isOpen: boolean;
}

export default class ActionPicture extends PureComponent<Props, State> {
  public state = {
    isOpen: false,
  };

  private showModal = () => {
    this.setState({ isOpen: true });
  };

  private hideModal = () => {
    this.setState({ isOpen: false });
  };

  private onSuccess = () => {
    this.props.updateEntities('ProductPicture');
    this.hideModal();
  };

  public render() {
    const { action, items, productKeyId } = this.props;
    const { isOpen } = this.state;
    return (
      <div>
        <Button
          secondary
          className="Button--addPicture"
          onClick={this.showModal}
        >
          {action.content}
        </Button>
        {isOpen && (
          <PictureModal
            title={action.content}
            productKeyId={productKeyId}
            onSuccess={this.onSuccess}
            onClose={this.hideModal}
            fieldActionItems={action.items}
            count={
              items.filter((item) => item._type === 'ProductPicture').length
            }
          />
        )}
      </div>
    );
  }
}

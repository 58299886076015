import { registerSaga } from 'utils/modules/saga';

// Register rfps module.
import './modules/rfps';
import mainSaga from './sagas';
import ProductView from './view/product-view';

registerSaga(mainSaga);

export { BasicInformation } from './components/basic-information';
export default ProductView;

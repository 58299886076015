import { Link } from 'react-router-dom';

import { Button } from '@alkem/react-ui-button';

import * as routes from 'routes';
import i18n from 'utils/i18n';

import icon from './product-error-page-icon.svg';
import './product-error-page.scss';

function ProductErrorPage() {
  return (
    <div className="ProductErrorPage alk-flex alk-flex-center alk-justify-center">
      <div className="ProductErrorPage__content">
        <div className="ProductErrorPage__message">
          <div className="message-title">
            {i18n.t('Oops, this is inconvenient')}
          </div>
          <div className="message-body">
            {i18n.t(
              'There seems to be an error with the page you are trying to reach',
            )}
          </div>
          <Link to={routes.catalog}>
            <Button secondary>{i18n.t('Back to My Catalogue')}</Button>
          </Link>
        </div>
        <div className="ProductErrorPage__image">
          <img src={icon} alt="product-error-page-icon" />
        </div>
      </div>
    </div>
  );
}

export default ProductErrorPage;

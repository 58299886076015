import { createAction } from 'utils/redux';

import {
  ADD_RECIPIENT,
  CANCEL_POLL_IMPORT_REPORT,
  CLOSE_MODAL,
  OPEN_MODAL,
  POLL_GENERIC_IMPORT_REPORT,
  POLL_IMPORT_REPORT,
  RECEIVE_IMPORT_REPORT,
  REMOVE_RECIPIENT,
} from './types';

export const pollImportReport = createAction(POLL_IMPORT_REPORT);
export const pollGenericImportReport = createAction(POLL_GENERIC_IMPORT_REPORT);
export const receiveImportReport = createAction(RECEIVE_IMPORT_REPORT);
export const cancelPollImportReport = createAction(CANCEL_POLL_IMPORT_REPORT);
export const openImportModal = createAction(OPEN_MODAL);
export const closeImportModal = createAction(CLOSE_MODAL);

export const addRecipient = (recipientId) => ({
  type: ADD_RECIPIENT,
  recipientId,
});
export const removeRecipient = (recipientId) => ({
  type: REMOVE_RECIPIENT,
  recipientId,
});

import { Map } from 'immutable';
import PropTypes from 'prop-types';
import { PureComponent } from 'react';
import ImmutablePropTypes from 'react-immutable-proptypes';
import { connect } from 'react-redux';

import { ProgressBar } from '@alkem/react-ui-progress';

import Modal from 'components/ui/modal';
import { selectLocalesByTargetMarket } from 'modules/user';
import i18n from 'utils/i18n';
import { separateActions } from 'utils/redux';

import {
  removeProductsFromActiveRange,
  resetRemoveFromActiveRange,
  showRemoveFromActiveRange,
} from '../actions';
import {
  selectDisableProductsErrors,
  selectDisableProductsInProgressCount,
  selectDisableProductsInProgressStatus,
  selectDisableProductsSuccess,
  selectIsDisableProductsDone,
  selectIsDisableProductsInProgress,
  selectIsDisableProductsVisible,
  selectProductVersionsToDisable,
} from '../selectors';

import './index.scss';
import ProductInfo from './product-info';

const mapStateToProps = (state) => ({
  isVisible: selectIsDisableProductsVisible(state),
  productVersions: selectProductVersionsToDisable(state),
  inProgress: selectIsDisableProductsInProgress(state),
  inProgressCount: selectDisableProductsInProgressCount(state),
  inProgressStatus: selectDisableProductsInProgressStatus(state),
  isDone: selectIsDisableProductsDone(state),
  locales: selectLocalesByTargetMarket(state),
  errors: selectDisableProductsErrors(state),
  success: selectDisableProductsSuccess(state),
});

const mapDispatchToProps = {
  resetRemoveFromActiveRange,
  removeProductsFromActiveRange,
  showRemoveFromActiveRange,
};

class RemoveFromActiveRange extends PureComponent {
  static propTypes = {
    isVisible: PropTypes.bool.isRequired,
    inProgress: PropTypes.bool.isRequired,
    isDone: PropTypes.bool.isRequired,
    productVersions: ImmutablePropTypes.map.isRequired,
    inProgressCount: PropTypes.number.isRequired,
    inProgressStatus: PropTypes.string.isRequired,
    locales: ImmutablePropTypes.map.isRequired,
    onClose: PropTypes.func,
    errors: ImmutablePropTypes.list.isRequired,
    success: ImmutablePropTypes.list.isRequired,
    actions: PropTypes.shape({
      resetRemoveFromActiveRange: PropTypes.func.isRequired,
      removeProductsFromActiveRange: PropTypes.func.isRequired,
      showRemoveFromActiveRange: PropTypes.func.isRequired,
    }).isRequired,
  };

  static defaultProps = {
    onClose() {},
  };

  constructor(props) {
    super(props);
    this.close = this.close.bind(this);
    this.retry = this.retry.bind(this);
  }

  componentWillUnmount() {
    this.props.actions.resetRemoveFromActiveRange();
  }

  close() {
    const { actions, inProgress, isDone, onClose } = this.props;
    actions.resetRemoveFromActiveRange();
    onClose(inProgress || isDone);
  }

  retry() {
    const { actions, errors, productVersions } = this.props;
    actions.resetRemoveFromActiveRange();
    actions.showRemoveFromActiveRange(
      productVersions
        .valueSeq()
        .filter((productVersion) =>
          errors.contains(productVersion.getIn(['product_key', 'id'])),
        )
        .reduce(
          (map, productVersion) =>
            map.set(productVersion.get('id'), productVersion),
          Map(),
        ),
    );
    actions.removeProductsFromActiveRange();
  }

  renderRow(productVersion) {
    const { errors, success, locales } = this.props;
    const key_id = productVersion.getIn(['product_key', 'id']);
    return (
      <ProductInfo
        key={key_id}
        productVersion={productVersion}
        locales={locales}
        success={success.contains(key_id)}
        error={errors.contains(key_id)}
      />
    );
  }

  render() {
    const {
      isVisible,
      actions,
      productVersions,
      inProgress,
      inProgressCount,
      inProgressStatus,
      isDone,
      errors,
    } = this.props;

    if (!isVisible) {
      return null;
    }

    const productsCount = productVersions.size;
    const hasErrors = errors.size > 0;

    let buttonLabel;
    if (!isDone) {
      buttonLabel = i18n.t(
        'frontproductstream.remove_from_active_range_modal.confirm.button',
        {
          count: productsCount,
          defaultValue: 'Remove {{count}} products from active range',
        },
      );
    } else if (hasErrors) {
      buttonLabel = i18n.t(
        'frontproductstream.remove_from_active_range_modal.retry.button',
        {
          count: errors.size,
          defaultValue: 'Retry to remove {{count}} products from active range',
        },
      );
    } else {
      buttonLabel = i18n.t(
        'frontproductstream.remove_from_active_range_modal.close.button',
        {
          defaultValue: 'Close modal',
        },
      );
    }

    return (
      <Modal
        modalStyle="dynamic"
        className="RemoveFromActiveRange"
        title={i18n.t(
          'frontproductstream.remove_from_active_range_modal.title.text',
          {
            defaultValue: 'Remove from active range',
          },
        )}
        confirmButtonText={buttonLabel}
        onConfirm={
          (!isDone && actions.removeProductsFromActiveRange) ||
          (hasErrors && this.retry) ||
          this.close
        }
        isProcessing={inProgress}
        danger={!isDone}
        hideCloseButton={inProgress || (isDone && !hasErrors)}
        onClose={this.close}
      >
        <div className="RemoveFromActiveRange__body">
          <div>
            {i18n.t(
              'frontproductstream.remove_from_active_range_modal.confirmation_message.text',
              {
                count: productsCount,
                defaultValue:
                  'Are you sure you want to remove those {{count}} products from your active range?',
              },
            )}
          </div>
          <ul className="RemoveFromActiveRange__products">
            {productVersions
              .valueSeq()
              .map((productVersion) => this.renderRow(productVersion))}
          </ul>
          <ProgressBar
            value={inProgressCount}
            max={productsCount}
            color={inProgressStatus}
            height="medium"
          />
          {hasErrors && isDone && (
            <div className="RemoveFromActiveRange__errorsLabel">
              {i18n.t(
                'frontproductstream.remove_from_active_range_modal.error_message.text',
                {
                  defaultValue:
                    'We have encountered an error while trying to remove those products. Please retry:',
                },
              )}
            </div>
          )}
        </div>
      </Modal>
    );
  }
}

export default connect(
  mapStateToProps,
  mapDispatchToProps,
  separateActions,
)(RemoveFromActiveRange);

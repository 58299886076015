import { takeLatestSafe } from '@alkem/micro-frontend-tools';

import {
  AFTER_LOGAS_SUCCESS,
  AFTER_LOGIN_SUCCESS,
  TRY_AUTHORIZATION,
} from '../actions/action-types';

import { afterLogasSuccessSaga } from './afterLogasSuccess';
import { afterLoginSuccessSaga } from './afterLoginSuccess';
import { tryAuthorizationSaga } from './tryAuthorization';

export function* userRootSaga() {
  yield takeLatestSafe(TRY_AUTHORIZATION, tryAuthorizationSaga);
  yield takeLatestSafe(AFTER_LOGIN_SUCCESS, afterLoginSuccessSaga);
  yield takeLatestSafe(AFTER_LOGAS_SUCCESS, afterLogasSuccessSaga);
}

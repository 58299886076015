import classNames from 'classnames';
import { noop } from 'lodash/fp';
import { PureComponent, ReactNode } from 'react';
import { connect } from 'react-redux';
import { createStructuredSelector } from 'reselect';

import AnchorStickyNav from 'components/ui/anchor-sticky-nav';
import ViewAs from 'modules/view-as';
import { GlobalState, Selector } from 'types';

import { selectNavigationItems } from '../../selectors';

import './index.scss';

const NAVBAR_HEIGHT = 42;

type NavigationItem = any; // FIXME

interface ConnectedProps {
  navigationItems: NavigationItem[];
}

interface OwnProps {
  children: ReactNode;
  className?: string;
  withViewAs?: boolean;
  onItemClick?: (item: NavigationItem) => void;
}

type Props = ConnectedProps & OwnProps;

const mapStateToProps = createStructuredSelector({
  navigationItems: selectNavigationItems as Selector<NavigationItem>,
});

const enhance = connect<ConnectedProps, {}, OwnProps, GlobalState>(
  mapStateToProps,
);

export class Anchored extends PureComponent<Props> {
  private renderNavigation() {
    const { withViewAs, navigationItems, onItemClick } = this.props;
    if (navigationItems.length === 0) {
      return null;
    }
    return (
      <AnchorStickyNav
        items={navigationItems}
        stickyOffset={-NAVBAR_HEIGHT}
        onItemClick={onItemClick || noop}
      >
        {withViewAs && <ViewAs id="ProductPage__ViewAs" />}
      </AnchorStickyNav>
    );
  }

  private renderMain() {
    return <div className="col-md-9 AnchoredMain">{this.props.children}</div>;
  }

  public render() {
    return (
      <div className={classNames('row', this.props.className)}>
        {this.renderNavigation()}
        {this.renderMain()}
      </div>
    );
  }
}

export default enhance(Anchored);

import { connect } from 'react-redux';
import { createStructuredSelector } from 'reselect';

import {
  resetCannotCheckModal,
  submitCannotCheckChoice,
} from 'modules/contribution/actions';
import {
  getCannotCheckModalOpen,
  getCannotCheckModalSaving,
} from 'modules/contribution/selectors';
import i18n from 'utils/i18n';
import { separateActions } from 'utils/redux';

import { BaseCommentModal } from './base-comment-modal';

const mapStateToProps = createStructuredSelector({
  opened: getCannotCheckModalOpen,
  saving: getCannotCheckModalSaving,
});

const mapDispatchToProps = {
  submit: submitCannotCheckChoice,
  reset: resetCannotCheckModal,
};

export class CannotCheckModal extends BaseCommentModal {
  static defaultProps = {
    opened: false,
    saving: false,
    submitText: i18n.t(
      'frontproductstream.physical_check_cannot_check_dialog.submit.button',
      { defaultValue: 'Cannot Check' },
    ),
    title: i18n.t(
      'frontproductstream.physical_check_cannot_check_dialog.header.text',
      { defaultValue: 'Physical check not possible' },
    ),
    description: i18n.t(
      'frontproductstream.physical_check_cannot_check_dialog.message_box.description',
      { defaultValue: 'Reason for fail' },
    ),
    placeholder: i18n.t(
      'frontproductstream.physical_check_cannot_check_dialog.message_box.placeholder',
      { defaultValue: 'e.g. The physical check is impossible - product lost.' },
    ),
  };
}

export default connect(
  mapStateToProps,
  mapDispatchToProps,
  separateActions,
)(CannotCheckModal);

import { PureComponent } from 'react';
import ImmutablePropTypes from 'react-immutable-proptypes';
import { connect } from 'react-redux';
import { createStructuredSelector } from 'reselect';

import { hasFeature } from 'modules/feature-flag';
import { separateActions } from 'utils/redux';

import CatalogExportModalV1 from './v1';
import CatalogExportModalV2 from './v2';

const mapStateToProps = createStructuredSelector({
  user: (state) => state.user,
});

export class CatalogExportModal extends PureComponent {
  static propTypes = {
    user: ImmutablePropTypes.map.isRequired,
  };

  render() {
    const { user } = this.props;
    if (hasFeature(user, 'release-in-export-modal-v2')) {
      return <CatalogExportModalV2 />;
    }
    return <CatalogExportModalV1 />;
  }
}

export default connect(mapStateToProps, separateActions)(CatalogExportModal);

export const RETAILER_RECEIVE_REQUESTS = 'RETAILER_RECEIVE_REQUESTS';
export const RETAILER_RECEIVE_SHARINGUNITS_REQUESTS =
  'RETAILER_RECEIVE_SHARINGUNITS_REQUESTS';
// New apply.
export const RETAILER_REQUESTS_RECEIVE_TRANSACTION_IDS =
  'RETAILER_REQUESTS_RECEIVE_TRANSACTION_IDS';
export const RETAILER_REQUESTS_TOGGLE_PROGRESS =
  'RETAILER_REQUESTS_TOGGLE_PROGRESS';

export const ACCEPT_CONSUMER_UNIT = 'requests/accept-consumer-unit';
export const REFUSE_CONSUMER_UNIT = 'requests/refuse-consumer-unit';
export const ACCEPT_LISTING_UPDATES = 'requests/accept-listing-updates';
export const REFUSE_LISTING_UPDATES = 'requests/refuse-listing-updates';
export const ACCEPT_LISTING_INIT = 'requests/accept-listing-init';

export const FETCH_SHARING_UNITS_SUCCESS = 'FETCH_SHARING_UNITS_SUCCESS';

export const FETCH_DISPLAY_GROUPS_REQUEST = 'FETCH_DISPLAY_GROUPS_REQUEST';
export const FETCH_DISPLAY_GROUPS_SUCCESS = 'FETCH_DISPLAY_GROUPS_SUCCESS';
export const FETCH_DISPLAY_GROUPS_FAILURE = 'FETCH_DISPLAY_GROUPS_FAILURE';

export const CREATE_SHARING_UNIT_REQUEST = 'CREATE_SHARING_UNIT_REQUEST';
export const CREATE_SHARING_UNIT_SUCCESS = 'CREATE_SHARING_UNIT_SUCCESS';
export const CREATE_SHARING_UNIT_FAILURE = 'CREATE_SHARING_UNIT_FAILURE';
export const REQUEST_STATUS_POLLING = 'sharing-units/REQUEST_STATUS_POLLING';
export const CANCEL_STATUS_POLLING = 'sharing-units/CANCEL_STATUS_POLLING';

export const DELETE_SHARING_UNIT_REQUEST = 'DELETE_SHARING_UNIT_REQUEST';
export const DELETE_SHARING_UNIT_SUCCESS = 'DELETE_SHARING_UNIT_SUCCESS';
export const DELETE_SHARING_UNIT_FAILURE = 'DELETE_SHARING_UNIT_FAILURE';

export const UPDATE_SHARING_UNIT_STATUS = 'UPDATE_SHARING_UNIT_STATUS';

export const UPDATE_SHARING_UNIT_FIELD = 'UPDATE_SHARING_UNIT_FIELD';
export const DISPLAY_WARNING_ON_SAVE = 'sharing-units/DISPLAY_WARNING_ON_SAVE';

export const SET_HIERARCHY_GRID_ERROR = 'SET_HIERARCHY_GRID_ERROR';

export const LOAD_SHARING_UNITS_EXPORT_FILE = 'LOAD_SHARING_UNITS_EXPORT_FILE';
export const RECEIVE_SHARING_UNITS_EXPORT_FILE =
  'RECEIVE_SHARING_UNITS_EXPORT_FILE';

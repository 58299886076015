import { CLOSE_EDIT_MODAL, OPEN_EDIT_MODAL, SAVE, VALIDATE } from './constants';

export const openModal = (sharingUnit: any) => ({
  type: OPEN_EDIT_MODAL,
  sharingUnit,
});

export const closeModal = () => ({
  type: CLOSE_EDIT_MODAL,
});

export const save = () => ({
  type: SAVE,
});

export const validate = () => ({
  type: VALIDATE,
});

import { get, set, update } from 'lodash/fp';
import PropTypes from 'prop-types';
import { Component } from 'react';
import { connect } from 'react-redux';

import { notificationError, notificationSuccess } from 'actions/notification';
import InputWithLabel from 'components/ui/input/input-with-label';
import RichContent from 'components/ui/input/rich-content';
import Text from 'components/ui/input/text';
import Modal from 'components/ui/modal';
import {
  ASSET_ORIGIN_STREAM_USER,
  PRODUCT_ENRICHED_CONTENT_TYPE_RECIPE,
} from 'constants/media';
import mediaApi from 'resources/mediaApi';
import { StringOrNumber } from 'types';
import i18n from 'utils/i18n';
import { logError } from 'utils/logging';

import AssetFormGroup from '../form-group';

class RecipeModal extends Component {
  static propTypes = {
    title: PropTypes.string.isRequired,
    productKeyId: StringOrNumber.isRequired,
    media: PropTypes.object,
    onSuccess: PropTypes.func.isRequired,
    onClose: PropTypes.func.isRequired,
    dispatch: PropTypes.func.isRequired,
  };

  constructor(props) {
    super(props);
    const { media } = props;
    this.state = {
      isAdding: false,
      recipe: {
        title: get(['title'], media),
        content: get(['content'], media),
      },
    };
  }

  onConfirm = async () => {
    const { productKeyId, media, dispatch } = this.props;
    const { recipe } = this.state;
    try {
      if (recipe.title && recipe.content) {
        this.setState(set(['isAdding'], true));
        if (media) {
          const payload = { ...media, ...recipe };
          await mediaApi.EnrichedContentPut(payload);
          dispatch(
            notificationSuccess(
              i18n.t(
                'frontproducstream.asset_list.enriched_content_modal.update_notification.success',
                { defaultValue: 'Your enriched content has been updated.' },
              ),
            ),
          );
        } else {
          const payload = {
            type: PRODUCT_ENRICHED_CONTENT_TYPE_RECIPE.id,
            product_key_id: productKeyId,
            origin: ASSET_ORIGIN_STREAM_USER.id,
            ...recipe,
          };
          await mediaApi.EnrichedContentCreate(payload);
          dispatch(
            notificationSuccess(
              i18n.t(
                'frontproducstream.asset_list.enriched_content_modal.create_notification.success',
                { defaultValue: 'Your enriched content has been added.' },
              ),
            ),
          );
        }
        this.props.onSuccess();
      }
    } catch (error) {
      dispatch(notificationError(error.message, { context: 'modal' }));
      this.setState(set(['isAdding'], false));
    }
  };

  onChangeField = (path, value) => {
    this.setState(update(['recipe'], set(path, value)));
  };

  onChangeFormGroup = (model, value) => {
    this.onChangeField(model, value);
  };

  titleChange = (event) => {
    this.onChangeField('title', event.currentTarget.value);
  };

  recipeChange = (text) => {
    this.onChangeField('content', text);
  };

  imageHandler = async (evt) => {
    const { productKeyId } = this.props;
    const { file, el } = evt.data;
    const picture = {
      name: file.name,
      entity_id: productKeyId,
      entity_type: 'product',
      media_type: 'recipe',
    };

    try {
      const response = await mediaApi.ProductEnrichedContentPictureUpload(
        picture,
        file,
      );
      const { url } = response.data.data;
      el.setAttribute('src', url);
    } catch (error) {
      logError(error);
    }
  };

  render() {
    const { title } = this.props;
    const { recipe, isAdding } = this.state;
    return (
      <Modal
        title={title}
        modalStyle="fullHeight"
        isProcessing={isAdding}
        onConfirm={this.onConfirm}
        onClose={this.props.onClose}
      >
        <div className="RecipeModal ProductPage__modal">
          <Text
            id="recipeTitle"
            label={i18n.t(
              'frontproducstream.asset_list.enriched_content_modal.content_title.label',
              { defaultValue: 'Title' },
            )}
            placeholder={i18n.t(
              'frontproducstream.asset_list.enriched_content_modal.content_title.placeholder',
              { defaultValue: 'Ex: Pancakes' },
            )}
            onChange={this.titleChange}
            value={recipe.title}
          />
          <InputWithLabel
            childId="recipe-modal-content"
            label={i18n.t(
              'frontproducstream.asset_list.enriched_content_modal.content.label',
              { defaultValue: 'Content' },
            )}
          >
            <RichContent
              id="recipe-modal-content"
              value={recipe.content}
              onChange={this.recipeChange}
              imageHandler={this.imageHandler}
              large
            />
          </InputWithLabel>
          <AssetFormGroup
            entity={recipe}
            controller="recipe"
            onChangeField={this.onChangeFormGroup}
          />
          <div className="RecipeModal__actions" />
        </div>
      </Modal>
    );
  }
}

export default connect()(RecipeModal);

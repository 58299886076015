import { isMaker } from 'core/api/user';
import i18n from 'utils/i18n';

export const sourcingLabelsManufacturerFilter = {
  key: 'alkemics.sourcing.sourcinglabels',
  query: 'sourcinglabelsm',
  filterLabel: i18n.t(
    'frontproductstream.core.list_filter_sourcing_label_manufacturer.label',
    { defaultValue: 'SourcingLabels' },
  ),
  filterSearchPlaceHolder: i18n.t(
    'frontproductstream.core.list_filter_sourcing_label_manufacturer.search_placeholder',
    { defaultValue: 'Search for sourcing labels' },
  ),
  filterNameKey: 'id',
  isAvailable: ({ user }) => isMaker(user),
};

import PropTypes from 'prop-types';
import { PureComponent, cloneElement } from 'react';
import ImmutablePropTypes from 'react-immutable-proptypes';
import { connect } from 'react-redux';
import { v4 as uuid } from 'uuid';

import { Button } from '@alkem/react-ui-button';
import { Radio } from '@alkem/react-ui-inputs';

import { LanguageProvider } from 'components/ui/form/context';
import { ENTITY_TYPE_PRODUCTVERSION } from 'constants/entities';
import { selectFieldByName } from 'modules/display-groups/selectors';
import {
  selectCurrentLanguage,
  selectProductVersionId,
} from 'reducers/productVersion';
import { getAsReadOnly } from 'utils/displayGroup';
import { i18n } from 'utils/i18n';
import { toJsIfImmutable } from 'utils/immutable';

import { DiffList } from '../list';

import './custom-history-diff-line.scss';

const mapStateToProps = (state, { diff }) => ({
  productVersionId: selectProductVersionId(state),
  currentLanguage: selectCurrentLanguage(state),
  field: selectFieldByName(state)(diff.get('model').split('.').slice(-1)[0]),
});

export class _CustomHistoryDiffLine extends PureComponent {
  static propTypes = {
    diff: ImmutablePropTypes.map.isRequired,
    productVersionId: PropTypes.number.isRequired,
    children: PropTypes.object,
    field: PropTypes.object,
    isFieldNeeded: PropTypes.bool,
    withCurrent: PropTypes.bool,
    notHidden: PropTypes.bool,
    currentLanguage: PropTypes.object,
    currentPosition: PropTypes.string,
  };

  static defaultProps = {
    isFieldNeeded: true,
    withCurrent: false,
    notHidden: false,
    currentPosition: 'middle',
  };

  constructor(props) {
    super(props);

    this.uuid = uuid();
    this.oldOption = { label: i18n.t('Old values'), value: 'old' };
    this.newOption = { label: i18n.t('New values'), value: 'new' };
    this.currentOption = { label: i18n.t('Current values'), value: 'current' };

    this.state = {
      isCardOpened: props.notHidden,
      selectedValue: props.withCurrent
        ? this.oldOption.value
        : this.newOption.value,
      readOnlyField: props.field ? getAsReadOnly(props.field) : null,
    };
  }

  componentDidUpdate(lastProps) {
    if (lastProps.field !== this.props.field && this.props.field) {
      // eslint-disable-next-line
      this.setState({
        readOnlyField: getAsReadOnly(this.props.field),
      });
    }
  }

  switchOpenedCard = () =>
    this.setState((prevState) => ({ isCardOpened: !prevState.isCardOpened }));

  updateValue = (e) => this.setState({ selectedValue: e.target.value });

  render() {
    const {
      diff,
      children,
      productVersionId,
      isFieldNeeded,
      withCurrent,
      notHidden,
      currentPosition,
    } = this.props;
    const { isCardOpened, selectedValue, readOnlyField } = this.state;
    if (isFieldNeeded && !readOnlyField) {
      return null;
    }
    const colSize = withCurrent ? 3 : 4;

    const currentCol = withCurrent ? (
      <div className={`col-xs-${colSize}`}>
        <Radio
          id={`history-diff-card-radio-current-${this.uuid}`}
          className="historyDiffComponent--card__radios"
          value={selectedValue}
          onChange={this.updateValue}
          options={[this.currentOption]}
        />
      </div>
    ) : null;

    return (
      <LanguageProvider value={this.props.currentLanguage}>
        <div className={`CustomHistoryDiffLine historyDiffComponent--line row`}>
          <div className={`col-xs-${colSize}`}>
            <DiffList label={toJsIfImmutable(diff.get('label'))} type="div" />
          </div>
          {!notHidden && !isCardOpened && (
            <div
              className={`historyDiffComponent--button col-xs-${12 - colSize}`}
            >
              <Button
                link
                content={
                  isCardOpened
                    ? i18n.t('Hide the difference')
                    : i18n.t('See the difference')
                }
                onClick={this.switchOpenedCard}
                className="historyDiffComponent--button--content"
              />
            </div>
          )}
          {isCardOpened && (
            <>
              <div className={`col-xs-${colSize}`}>
                <Radio
                  id={`history-diff-card-radio-old-${this.uuid}`}
                  className="historyDiffComponent--card__radios"
                  value={selectedValue}
                  onChange={this.updateValue}
                  options={[this.oldOption]}
                />
              </div>
              {withCurrent && currentPosition === 'middle' && currentCol}
              <div className={`col-xs-${colSize}`}>
                <Radio
                  id={`history-diff-card-radio-new-${this.uuid}`}
                  className="historyDiffComponent--card__radios"
                  value={selectedValue}
                  onChange={this.updateValue}
                  options={[this.newOption]}
                />
              </div>
              {withCurrent && currentPosition === 'last' && currentCol}
            </>
          )}
        </div>
        {isCardOpened && (
          <div className="historyDiffComponent--card">
            {diff.get(selectedValue) &&
              isFieldNeeded &&
              cloneElement(children, {
                validate: false,
                value: diff.get(selectedValue).toJS(),
                entityKind: ENTITY_TYPE_PRODUCTVERSION,
                entityId: productVersionId,
                field: readOnlyField,
                entity: {
                  source: {
                    [readOnlyField.model]: diff.get(selectedValue).toJS(),
                  },
                  edited: {
                    [readOnlyField.model]: diff.get(selectedValue).toJS(),
                  },
                },
              })}
            {diff.get(selectedValue) &&
              !isFieldNeeded &&
              cloneElement(children, {
                validate: false,
                value: diff.get(selectedValue).toJS(),
                entityKind: ENTITY_TYPE_PRODUCTVERSION,
                entityId: productVersionId,
              })}
            {!diff.get(selectedValue) && (
              <span className="historyDiffComponent--card__empty">
                {i18n.t('(empty)')}
              </span>
            )}
          </div>
        )}
        {!notHidden && isCardOpened && (
          <div className="row">
            <div
              className={`historyDiffComponent--button offset-xs-${colSize} col-xs-${
                12 - colSize
              } p-t-1`}
            >
              <Button
                link
                content={i18n.t('Hide the difference')}
                onClick={this.switchOpenedCard}
                className="historyDiffComponent--button--content"
              />
            </div>
          </div>
        )}
      </LanguageProvider>
    );
  }
}

export const CustomHistoryDiffLine = connect(mapStateToProps)(
  _CustomHistoryDiffLine,
);
export default CustomHistoryDiffLine;

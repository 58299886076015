import classNames from 'classnames';
import { ReactNode } from 'react';

import './CoreLayout.scss';

export { CoreBodyLayout } from './body';

interface Props {
  children: ReactNode;
  containerClass?: string;
  absolute?: boolean;
}

export function CoreLayout({ children, containerClass = '', absolute }: Props) {
  return (
    <div
      className={classNames('CoreLayoutRoot', {
        'CoreLayoutRoot--absolute': absolute,
        [containerClass]: containerClass,
      })}
    >
      <div
        className={classNames('CoreLayout', {
          'CoreLayout--absolute': absolute,
        })}
      >
        {children}
      </div>
    </div>
  );
}

export default CoreLayout;

import { List, Map, fromJS } from 'immutable';
import { createReducer } from 'redux-create-reducer';

import {
  ENTITY_TYPE_PRODUCTVERSION,
  ENTITY_TYPE_PRODUCTVERSION_OVERRIDE,
  ENTITY_TYPE_PRODUCT_MEDIA,
  ENTITY_TYPE_PRODUCT_VERSION_HIERARCHY,
  ENTITY_TYPE_RECIPIENT,
  ENTITY_TYPE_RFP_ANSWER,
  ENTITY_TYPE_SHARINGUNIT,
  ENTITY_TYPE_SHARINGUNIT_TARIFF,
} from 'constants/entities';
import {
  ENTITY_TYPE_LOGISTICAL_HIERARCHY_DIMENSIONS,
  ENTITY_TYPE_LOGISTICAL_HIERARCHY_UNIT,
  ENTITY_TYPE_LOGISTICAL_HIERARCHY_WEIGHTS,
} from 'modules/logistical-hierarchies/constants';
import { toJsIfImmutable } from 'utils/immutable';
import {
  duplicateHierarchyRules,
  duplicateRecipientRules,
  duplicateSpecialRulesForHierarchy,
  processLogisticalUnitRules,
  processRfpAnswerRules,
  splitSharingUnitRules,
} from 'utils/validation-helpers';

import {
  ADD_STATIC_WARNING,
  DELETE_STATIC_WARNING,
  RECEIVE_MULTIPLE_ENTITY_RULE_RESULTS,
  RECEIVE_RULE_RESULTS,
  RECEIVE_RULE_RESULTS_FOR_ACTIVE_SHARES_RECIPIENTS,
  RECEIVE_RULE_RESULTS_FOR_SELECTED_RECIPIENTS,
  RECEIVE_SINGLE_ENTITY_RULE_RESULTS,
  UPDATE_ASSET_HAS_ERROR_BY_ASSET_ID,
  UPDATE_MEDIA_HAS_ERROR_BY_RULE_ID,
  VALIDATION_RESET,
  isMigratedMediaFieldRule,
} from '../actions';
import {
  RuleApplicationStatus,
  RuleTemplateType,
  ruleEntities,
} from '../constants';

const getInitialState = () => ({
  ruleResultsByEntity: List([Map()]),
  ruleResults: Map(),
  ruleResultsForViewAsSettings: Map(), // selected recipients in view-as
  ruleResultsActiveSharesRecipients: Map(),
  staticWarning: 0,
  assetHasErrorByAssetId: {},
  assetHasErrorByRuleId: {},
});

function indexRulesById(rules) {
  // Map result details to be indexed by ruleId to allow easy deep merges.
  // Also add a prop to tell if a rule is bypassed.
  // Prefix normalized comments with nc to avoid id conflicts with validation rules
  return Map(
    rules.map((result) => [
      result.get('templateType') === RuleTemplateType.NORMALIZED_COMMENT
        ? `nc-${result.get('id')}`
        : result.get('id'),
      result.set(
        'bypassed',
        result.get('status') === RuleApplicationStatus.BYPASSED,
      ),
    ]),
  );
}

function splitRulesResultsByEntity(
  ruleResults: Map<string, any>,
  versionId: number,
  sharingUnits: any[],
  logisticalUnits?: any[],
  hasMediaFieldsMigrationFlag = false,
) {
  const rules = ruleResults.get('rules', []);
  const isEntityTypeSupported = (rule) =>
    [
      ruleEntities.CONSUMER_UNIT,
      ruleEntities.DISPLAY_UNIT,
      ruleEntities.PRODUCT_PICTURE,
      ruleEntities.PRODUCT_DOCUMENT,
      ruleEntities.PRODUCT_VIDEO,
      ruleEntities.PRODUCT_PICTURES,
      ruleEntities.PRODUCT_DOCUMENTS,
      ruleEntities.PRODUCT_VIDEOS,
      ruleEntities.TEXTILE_VARIANT,
    ].includes(rule.get('entityType'));

  const versionRules = rules.filter(
    (r) =>
      isEntityTypeSupported(r) &&
      (!r.has('status_by_recipients') ||
        r.getIn(['status_by_recipients', 'generic']).size > 0),
  );

  const mediaRules = rules.filter(
    (rule) =>
      ([
        ruleEntities.PRODUCT_PICTURE,
        ruleEntities.PRODUCT_DOCUMENT,
        ruleEntities.PRODUCT_VIDEO,
        ruleEntities.PRODUCT_PICTURES,
        ruleEntities.PRODUCT_DOCUMENTS,
        ruleEntities.PRODUCT_VIDEOS,
      ].includes(rule.get('entityType')) ||
        (hasMediaFieldsMigrationFlag &&
          isMigratedMediaFieldRule(toJsIfImmutable(rule)))) &&
      (!rule.has('status_by_recipients') ||
        rule.getIn(['status_by_recipients', 'generic']).size > 0),
  );

  const specificDataRules = rules
    .filter(
      (r) =>
        isEntityTypeSupported(r) &&
        r.has('status_by_recipients') &&
        r.getIn(['status_by_recipients', 'specific']).size > 0,
    )
    .reduce((acc, rule) => {
      for (const recipientId of rule
        .getIn(['status_by_recipients', 'specific'], Map())
        .keySeq()) {
        if (/^[0-9]+$/.test(recipientId)) {
          acc = acc.update(recipientId, (list) =>
            List.isList(list) ? list.push(rule) : List([rule]),
          );
        }
      }
      return acc;
    }, Map());

  const logisticalHierarchiesRules = processLogisticalUnitRules(
    rules.filter((r) =>
      [
        ruleEntities.LOGISTICAL_UNIT,
        ruleEntities.LOGISTICAL_HIERARCHY,
        ruleEntities.LOGISTICAL_HIERARCHY_TOP_UNIT,
      ].includes(r.get('entityType')),
    ),
  );
  const mappedLogisticalHierarchyRules = (logisticalUnits || []).map(
    (luInternalId, index) =>
      fromJS({
        entity: {
          id: luInternalId,
          _type: ENTITY_TYPE_PRODUCT_VERSION_HIERARCHY,
        },
        rules: indexRulesById(logisticalHierarchiesRules.get(`${index}`) || []),
        fields: fromJS(['logisticalHierarchies']),
      }),
  );
  const { templateRules, sharingUnitRules } = splitSharingUnitRules(
    rules.filter(
      (r) =>
        r.get('entityType') === ruleEntities.SHARING_UNIT ||
        r.get('entityType') === ruleEntities.PRICE_WATERFALL ||
        r.get('entityType') === ruleEntities.TARIFF,
    ),
  );

  const recipientRules = duplicateRecipientRules(
    rules.filter((r) =>
      [ruleEntities.SHARING_UNITS, ruleEntities.TARIFFS].includes(
        r.get('entityType'),
      ),
    ),
  );
  const rfpAnswerRules = processRfpAnswerRules(
    rules.filter((rule) =>
      [ruleEntities.RFP_ANSWER].includes(rule.get('entityType')),
    ),
  );
  return List([
    fromJS({
      entity: { id: versionId, _type: ENTITY_TYPE_PRODUCTVERSION },
      rules: indexRulesById(versionRules),
      fields: ruleResults.get('fields'),
    }),
    ...mappedLogisticalHierarchyRules,
    ...duplicateHierarchyRules(mappedLogisticalHierarchyRules)
      .mapEntries(([gtin, r]) => [
        gtin,
        fromJS({
          entity: {
            id: gtin,
            _type: ENTITY_TYPE_LOGISTICAL_HIERARCHY_UNIT,
          },
          rules: indexRulesById(r || []),
          fields: ruleResults.get('fields'),
        }),
      ])
      .toList(),
    ...duplicateSpecialRulesForHierarchy(
      mappedLogisticalHierarchyRules,
      'isSizedBy',
      ENTITY_TYPE_LOGISTICAL_HIERARCHY_DIMENSIONS,
    ),
    ...duplicateSpecialRulesForHierarchy(
      mappedLogisticalHierarchyRules,
      'grossWeight',
      ENTITY_TYPE_LOGISTICAL_HIERARCHY_WEIGHTS,
    ),
    ...(List as any)(
      sharingUnits.map((su, index) =>
        fromJS({
          entity: { id: su.id, _type: ENTITY_TYPE_SHARINGUNIT },
          rules: indexRulesById(sharingUnitRules.get(`${index}`) || []),
          fields: ruleResults.get('fields').concat(['logisticalHierarchies']),
        }),
      ),
    ),
    ...specificDataRules.reduce(
      (acc, specificRules, recipientId) =>
        acc.push(
          fromJS({
            entity: {
              id: parseInt(recipientId, 10),
              _type: ENTITY_TYPE_PRODUCTVERSION_OVERRIDE,
            },
            rules: indexRulesById(specificRules),
            fields: ruleResults.get('fields'),
          }),
        ),
      List(),
    ),
    fromJS({
      entity: { id: versionId, _type: ENTITY_TYPE_SHARINGUNIT_TARIFF },
      rules: indexRulesById(templateRules),
      fields: ruleResults.get('fields'),
    }),
    fromJS({
      entity: { id: versionId, _type: ENTITY_TYPE_PRODUCT_MEDIA },
      rules: indexRulesById(mediaRules),
      fields: ruleResults.get('fields'),
    }),
    ...recipientRules
      .mapEntries(([oID, r]) => [
        oID,
        fromJS({
          entity: {
            id: oID,
            _type: ENTITY_TYPE_RECIPIENT,
          },
          rules: indexRulesById(r || []),
          fields: fromJS(['recipient']),
        }),
      ])
      .toList(),
    ...(List as any)(
      sharingUnits.map((su, index) =>
        fromJS({
          entity: { id: su.id, _type: ENTITY_TYPE_RFP_ANSWER },
          rules: indexRulesById(rfpAnswerRules.get(`${index}`) || []),
          fields: ruleResults.get('fields'),
        }),
      ),
    ),
  ]);
}

export const validationReducer = createReducer(getInitialState(), {
  [RECEIVE_RULE_RESULTS]: (
    state,
    {
      data,
      versionId,
      sharingUnits,
      logisticalUnits,
      hasMediaFieldsMigrationFlag,
    },
  ) => {
    if (!data) {
      return state;
    }
    return {
      ...state,
      ruleResultsByEntity: splitRulesResultsByEntity(
        data,
        versionId,
        sharingUnits,
        logisticalUnits,
        hasMediaFieldsMigrationFlag,
      ),
      ruleResults: data.set(
        'entity',
        fromJS({
          id: versionId,
          _type: ENTITY_TYPE_PRODUCTVERSION,
        }),
      ),
    };
  },
  [RECEIVE_RULE_RESULTS_FOR_SELECTED_RECIPIENTS]: (
    state,
    { data, versionId },
  ) =>
    !data
      ? state
      : {
          ...state,
          ruleResultsForViewAsSettings: data.set(
            'entity',
            fromJS({
              id: versionId,
              _type: ENTITY_TYPE_PRODUCTVERSION,
            }),
          ),
        },
  [RECEIVE_RULE_RESULTS_FOR_ACTIVE_SHARES_RECIPIENTS]: (
    state,
    { data, versionId },
  ) =>
    !data
      ? state
      : {
          ...state,
          ruleResultsActiveSharesRecipients: data.set(
            'entity',
            fromJS({
              id: versionId,
              _type: ENTITY_TYPE_PRODUCTVERSION,
            }),
          ),
        },
  [VALIDATION_RESET]: getInitialState,
  [ADD_STATIC_WARNING]: (state) => {
    return {
      ...state,
      staticWarning: state.staticWarning + 1,
    };
  },
  [DELETE_STATIC_WARNING]: (state) => {
    return {
      ...state,
      staticWarning: state.staticWarning - 1,
    };
  },
  [RECEIVE_MULTIPLE_ENTITY_RULE_RESULTS]: (
    state,
    { ruleResults, entity_type },
  ) => {
    return {
      ...state,
      ruleResultsByEntity: List(
        ruleResults.map((ruleResult) =>
          fromJS({
            entity: { id: ruleResult.get('entity_id'), _type: entity_type },
            rules: indexRulesById(ruleResult.getIn(['result', 'rules'])),
            fields: ruleResult.getIn(['result', 'fields']),
          }),
        ),
      ),
    } as any;
  },
  [RECEIVE_SINGLE_ENTITY_RULE_RESULTS]: (
    state,
    { ruleResult, entity_type },
  ) => {
    let ruleResultsByEntity = state.ruleResultsByEntity;
    const ruleResultIndex = ruleResultsByEntity.findIndex(
      (r) =>
        r != null &&
        r.getIn(['entity', 'id']) === ruleResult.get('entity_id') &&
        r.getIn(['entity', '_type']) === entity_type,
    );
    if (ruleResultIndex !== -1) {
      ruleResultsByEntity = ruleResultsByEntity.set(
        ruleResultIndex,
        fromJS({
          entity: { id: ruleResult.get('entity_id'), _type: entity_type },
          rules: indexRulesById(ruleResult.getIn(['result', 'rules'])),
          fields: ruleResult.getIn(['result', 'fields']),
        }),
      );
    } else {
      ruleResultsByEntity = ruleResultsByEntity.push(
        fromJS({
          entity: { id: ruleResult.get('entity_id'), _type: entity_type },
          rules: indexRulesById(ruleResult.getIn(['result', 'rules'])),
          fields: ruleResult.getIn(['result', 'fields']),
        }),
      );
    }
    return {
      ...state,
      ruleResultsByEntity,
    };
  },
  [UPDATE_ASSET_HAS_ERROR_BY_ASSET_ID]: (state, { assetId, hasError }) => {
    return {
      ...state,
      assetHasErrorByAssetId: {
        ...state.assetHasErrorByAssetId,
        [assetId]: hasError,
      },
    };
  },
  [UPDATE_MEDIA_HAS_ERROR_BY_RULE_ID]: (state, { assetHasErrorByRuleId }) => {
    return {
      ...state,
      assetHasErrorByRuleId: {
        ...state.assetHasErrorByRuleId,
        ...assetHasErrorByRuleId,
      },
    };
  },
});

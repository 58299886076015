import classNames from 'classnames';
import { List } from 'immutable';
import PropTypes from 'prop-types';
import { PureComponent } from 'react';
import ImmutablePropTypes from 'react-immutable-proptypes';

import { Ellitips } from '@alkem/react-ui-ellitips';
import { LazyTooltip } from '@alkem/react-ui-tooltip';

import i18n from 'utils/i18n';
import { get } from 'utils/immutable';

import './index.scss';

export default class CatalogUserLabelsColumn extends PureComponent {
  static propTypes = {
    productVersionId: PropTypes.number.isRequired,
    data: ImmutablePropTypes.list.isRequired,
  };

  static defaultProps = {
    data: List(),
  };

  static className = 'CatalogUserLabelsColumn';

  userlabelMax = 6;

  setBlockRef = (element) => {
    this.blockElem = element;
  };

  renderLabel = (label) => {
    const { productVersionId } = this.props;
    const id = get(label, 'id');
    const name = get(label, 'name');
    return (
      <div key={id} className="CatalogUserLabel__label">
        <Ellitips label={name} id={`label-${productVersionId}-${id}`} />
      </div>
    );
  };

  renderMoreLabels = (hiddenLabels) => {
    const { productVersionId } = this.props;
    const id = `morelabels-${productVersionId}`;
    const message = hiddenLabels
      .map((label) => get(label, 'name'))
      .join('<br />');
    return (
      <LazyTooltip
        id={id}
        className="CatalogUserLabel__moreLabels"
        tooltipLabel={message}
        place="bottom"
        block
        html
      >
        {i18n.t('+{{count}} more', { count: hiddenLabels.length })}
      </LazyTooltip>
    );
  };

  render() {
    const { data } = this.props;
    let labels = data;
    const nonDisplayedLabels = [];
    const truncate = data.size > this.userlabelMax;
    if (truncate) {
      labels = [];
      data.forEach((label, idx) => {
        if (idx < this.userlabelMax - 1) {
          labels.push(label);
        } else {
          nonDisplayedLabels.push(label);
        }
      });
    }
    return (
      <div
        className={classNames(
          'CatalogUserLabel__list',
          this.blockElem?.offsetWidth > 300 &&
            'CatalogUserLabel__list--restrict',
        )}
        ref={this.setBlockRef}
      >
        {labels.map(this.renderLabel)}
        {truncate && this.renderMoreLabels(nonDisplayedLabels)}
      </div>
    );
  }
}

import { List, Map } from 'immutable';

import { get } from 'utils/immutable';

import { TOTAL_PERCENTAGE_CELL } from '../constants';

export function getIdFromReport(report, idKeys) {
  return idKeys.reduce(
    (str, key) => `${str}${str && '-'}${get(report, key)}`,
    '',
  );
}

export function getReportData(report, key, type) {
  const data = report.get(key);
  switch (type) {
    case TOTAL_PERCENTAGE_CELL:
      return Map({
        ok: data,
        doc_count: report.get('doc_count'),
      });
    default:
      if (List.isList(data) && data.size === 2) {
        return data.get(1);
      }
      return data;
  }
}

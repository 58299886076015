import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { Button } from '@alkem/react-ui-button';

import { RESET_MODULE_ACTION } from 'core/modules/async/constants';
import { AnchoredSection } from 'modules/anchored';
import {
  selectIsLoggedAs,
  selectIsManufacturer,
  selectIsRetailer,
} from 'modules/user';
import i18n from 'utils/i18n';
import { track } from 'utils/tracking';

import { dismissNormalizedComment } from '../actions';
import { NORMALIZED_COMMENTS_STORE_KEY } from '../constants';
import { selectDeprecatedNormalizedComments } from '../selectors';

import './deprecated-normalized-comments.scss';

interface Props {
  order: number;
}

export const DeprecatedNormalizedComments = ({ order }: Props) => {
  const isManufacturer = useSelector(selectIsManufacturer);
  const isRetailer = useSelector(selectIsRetailer);
  const isLoggedAs = useSelector(selectIsLoggedAs);
  const normalizedComments = useSelector(selectDeprecatedNormalizedComments);

  const isManufacturerOrRetailer = isManufacturer || isRetailer;

  const dispatch = useDispatch();

  const dismissComment = (id) => {
    track({
      category: 'product',
      action: 'deprecated_normalized_comment_dismissed',
    });
    dispatch(dismissNormalizedComment({ id }));
  };

  useEffect(() => {
    if (isManufacturerOrRetailer && isLoggedAs && normalizedComments.length) {
      track({
        category: 'product',
        action: 'deprecated_normalized_comment_displayed',
      });
    }
  }, [isManufacturerOrRetailer, isLoggedAs, normalizedComments.length]);

  useEffect(() => {
    return () => {
      dispatch({
        type: RESET_MODULE_ACTION,
        payload: NORMALIZED_COMMENTS_STORE_KEY,
      });
    };
  }, [dispatch]);

  const deprecatedNormalizedCommentsFieldNameColumnLabel = i18n.t(
    'frontproductstream.product_deprecated_normalized_comments.table_column_field.label',
    {
      defaultValue: 'Field',
    },
  );

  const deprecatedNormalizedCommentsDescriptionColumnLabel = i18n.t(
    'frontproductstream.product_deprecated_normalized_comments.table_column_description.label',
    {
      defaultValue: 'Description',
    },
  );

  const deprecatedNormalizedCommentsDescriptionColumnText = i18n.t(
    'frontproductstream.product_deprecated_normalized_comments.table_column_description.text',
    {
      defaultValue:
        'A comment is attached to this field that is not visible anymore',
    },
  );

  const headerContent = {
    text: i18n.t(
      'frontproductstream.product_deprecated_normalized_comments.header.text',
      { defaultValue: 'Only visible to SupplierXM Admin' },
    ),
  };

  if (!isManufacturerOrRetailer || !isLoggedAs || !normalizedComments.length) {
    return null;
  }

  const dimissButtonText = isManufacturer
    ? i18n.t(
        'frontproductstream.product_deprecated_normalized_comments.dismiss.button',
        { defaultValue: 'Dismiss' },
      )
    : i18n.t(
        'frontproductstream.product_deprecated_normalized_comments.cancel.button',
        { defaultValue: 'Cancel' },
      );

  return (
    <AnchoredSection
      collapsible
      section={i18n.t(
        'frontproductstream.product_deprecated_normalized_comments.section.title',
        { defaultValue: 'Deprecated normalized comments' },
      )}
      id="deprecated-normalized-comments"
      order={order}
      header={headerContent}
    >
      <div className="DeprecatedNormalizedComments">
        <table className="DeprecatedNormalizedComments__table">
          <tbody>
            <tr>
              <td>
                <div className="DeprecatedNormalizedComments__header row">
                  <div className="col-xs-5">
                    {deprecatedNormalizedCommentsFieldNameColumnLabel}
                  </div>
                  <div className="col-xs-5">
                    {deprecatedNormalizedCommentsDescriptionColumnLabel}
                  </div>
                </div>
              </td>
            </tr>
            {normalizedComments.map((nc) => (
              <tr key={`normalized-comment-${nc.id}`}>
                <td>
                  <div className="DeprecatedNormalizedComments__line row">
                    <div className="col-xs-5">{nc.fieldName}</div>
                    <div className="col-xs-5">
                      {deprecatedNormalizedCommentsDescriptionColumnText}
                    </div>
                    <div className="col-xs-2">
                      <Button
                        link
                        content={dimissButtonText}
                        className="DeprecatedNormalizedComments__button__dismiss"
                        onClick={() => dismissComment(nc.id)}
                      />
                    </div>
                  </div>
                </td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>
    </AnchoredSection>
  );
};

import classNames from 'classnames';
import { result } from 'lodash';
import { Moment } from 'moment';
import { Component } from 'react';
import RangePicker from 'react-daterange-picker';

import { ClickOutside } from '@alkem/react-ui-click-outside';

import i18n from 'utils/i18n';
import moment from 'utils/moment';

import './date.scss';

interface DateProps {
  id: string;
  value?: Moment | null;
  placeholder?: string;
  onSelect?: (value: Moment | null) => void;
  readOnly?: boolean;
  small?: boolean;
  minimumDate?: object;
  withDeleteSelectionButton?: boolean;
}

interface DateState {
  displayCalendar: boolean;
}

class Date extends Component<DateProps, DateState> {
  static demoProps = [
    {
      id: 'demo_date_1',
      value: moment(),
    },
    {
      id: 'demo_date_2',
      value: moment(),
      readOnly: true,
    },
    {
      id: 'demo_date_3',
      value: moment(),
      small: true,
    },
    {
      id: 'demo_date_4',
      value: moment(),
      readOnly: true,
      small: true,
    },
  ];

  static defaultProps = {
    value: moment(),
    placeholder: null,
    onSelect: () => null,
    readOnly: false,
    small: false,
    minimumDate: null,
    withDeleteSelectionButton: true,
  };

  state = {
    hasError: false,
    displayCalendar: false,
  };

  onClick = () => {
    if (this.props.readOnly) {
      return;
    }
    this.setState((prevState) => ({
      displayCalendar: !prevState.displayCalendar,
    }));
  };

  onDelete = () => {
    this.props.onSelect?.(null);
  };

  onSelect = (date) => {
    this.setState({
      displayCalendar: false,
    });
    this.props.onSelect?.(date);
  };

  static getDerivedStateFromProps() {
    return {
      hasError: false,
    };
  }

  static getDerivedStateFromError() {
    return {
      hasError: true,
      displayCalendar: false,
    };
  }

  handleClickOutside = () => {
    if (this.state.displayCalendar) {
      this.setState({
        displayCalendar: false,
      });
    }
  };

  formatValue(value) {
    if (!value) {
      return '';
    }
    if (this.props.small) {
      return value.format('L');
    }
    return value.format('LL');
  }

  renderDeleteSelectionButton() {
    const { value } = this.props;
    if (!value) {
      return null;
    }
    return (
      <button
        className="InputDate__deleteIcon mdi mdi-close"
        onClick={this.onDelete}
        aria-label={i18n.t('frontproductstream.input.date.clear_button', {
          defaultValue: 'Clear',
        })}
      />
    );
  }

  render() {
    const {
      id,
      value,
      placeholder,
      readOnly,
      small,
      minimumDate,
      withDeleteSelectionButton,
    } = this.props;

    const { hasError } = this.state;

    const itemClasses = {
      InputDate__item: true,
      'InputDate__item--readOnly': readOnly,
      'InputDate__item--small': small,
    };

    const inputClasses = {
      InputDate__input: true,
      'InputDate__input--small': small,
    };

    const iconClasses = {
      'InputDate__icon mdi mdi-calendar-blank': true,
      'InputDate__icon--active': !readOnly,
      'InputDate__icon--readOnly': readOnly,
    };

    const calendarClasses = {
      InputDate__calendar: true,
      'InputDate__calendar--opened': this.state.displayCalendar,
    };

    const rangePickerProps = {
      minimumDate,
      numberOfCalendars: 1,
      selectionType: 'single',
      onSelect: this.onSelect,
      value: !hasError && result(value, 'isValid') ? value : null,
    };

    return (
      <ClickOutside
        id={id}
        className="InputDate InputField__input"
        onClickOutside={this.handleClickOutside}
      >
        <span className={classNames(itemClasses)}>
          <input
            id={id}
            className={classNames(inputClasses)}
            placeholder={
              readOnly
                ? i18n.t('frontproductstream.input.date.placeholder', {
                    defaultValue: 'NC',
                  })
                : placeholder
            }
            onClick={this.onClick}
            value={this.formatValue(value)}
            disabled
          />
          {!!value &&
            !readOnly &&
            withDeleteSelectionButton &&
            this.renderDeleteSelectionButton()}
          <button
            className={classNames(iconClasses)}
            type="button"
            onClick={this.onClick}
            aria-label={i18n.t(
              'frontproductstream.input.date.calendar_button',
              { defaultValue: 'Calendar' },
            )}
          />
        </span>
        {this.state.displayCalendar && (
          <div className={classNames(calendarClasses)}>
            <RangePicker {...rangePickerProps} />
          </div>
        )}
      </ClickOutside>
    );
  }
}

export { Date };
export default Date;

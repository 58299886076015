import { flow, get, set } from 'lodash/fp';

import InputText from 'components/ui/input/text';
import { DataOpsPatchState, patchDataOpsField } from 'modules/data-ops';
import i18n from 'utils/i18n';

import { ENTITY_TYPE_LOGISTICAL_HIERARCHY_UNIT } from '../../constants';

import { DeepUnit, ObjectPath } from './utils';

export function LogisticalHierarchyFieldValue({
  unit,
  path,
  onUpdate,
  patch,
  onPatch,
  entityId,
  label,
}: {
  unit: DeepUnit;
  path: (string | number)[];
  onUpdate: (internalId: string, path: ObjectPath, value: any) => void;
  onPatch: typeof patchDataOpsField;
  patch?: DataOpsPatchState | null;
  entityId: string | number;
  label: string;
}) {
  const patchData = patch?.data?.[0]?.dataNew;
  const isPatchable = typeof patch?.editable === 'boolean';
  const isReadOnly = isPatchable ? !patch?.editable : unit.isReadOnly;
  const fieldName = path[0] as string;
  const entity =
    patchData !== undefined ? { [fieldName]: patchData } : unit.versionData;
  const declinableValue = get(path, entity);

  const onChange = (event: any) => {
    if (!isReadOnly) {
      let { value } = event.target;
      if (typeof value === 'string') {
        value = value.replace(/(,|\.)+/, '.');
      }
      if (isPatchable) {
        onPatch([
          label,
          fieldName,
          flow(set([...path, 0, 'data'], value), get([fieldName]))(entity),
          entityId,
          ENTITY_TYPE_LOGISTICAL_HIERARCHY_UNIT,
        ]);
      } else {
        onUpdate(unit.id, path, value);
      }
    }
  };

  return (
    <div className="LogisticalHierarchyGroupedFields__value">
      <InputText
        id={`${unit.path}-${unit.reference}-${path.join('-')}-0-data`}
        value={get([0, 'data'], declinableValue) || ''}
        onChange={onChange}
        disabled={isReadOnly}
        type="number"
      />
      <span
        id={`${unit.path}-${unit.reference}-${path.join('-')}-0-expressedIn`}
        className="LogisticalHierarchyGroupedFields__unitCode"
      >
        {get([0, 'expressedIn', 'code'], declinableValue) ||
          i18n.t(
            'frontproductstream.logistical_hierarchies_declinable_fields.default_unit.text',
            { defaultValue: 'NC' },
          )}
      </span>
    </div>
  );
}

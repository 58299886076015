import { all, call, put } from 'redux-saga/effects';

import { notificationError } from 'actions/notification';
import i18n from 'utils/i18n';
import { logError } from 'utils/logging';

import * as api from '../api';
import {
  FETCH_APPLICATIONS_ERROR,
  FETCH_APPLICATIONS_SUCCESS,
} from '../constants';

export const token = {
  error: i18n.t(
    'frontproductstream.app_store.fetch_applications.notification_error',
    {
      defaultValue: 'Error when trying to receive apps.',
    },
  ),
};

export default function* fetchApplicationsSaga() {
  try {
    const filters = { visibilities: false };
    const [applications, categories] = yield all([
      call(api.fetchApplications, filters),
      call(api.fetchCategories),
    ]);
    yield put({
      type: FETCH_APPLICATIONS_SUCCESS,
      payload: { applications, categories },
    });
  } catch (error) {
    yield put(notificationError(token.error));
    yield put({ type: FETCH_APPLICATIONS_ERROR, error: error.message });
    yield call(logError, error.message);
  }
}

import { useSelector } from 'react-redux';

import { displayNameFallback } from 'core/api/productversion';
import RowComponent from 'modules/bulk-action-modal/components/rowComponent';
import { ProductVersion, displayName } from 'modules/bulk-unshare';
import { selectUser } from 'modules/user';
import { UserImmutable } from 'types';

import './row.scss';

const BulkUnshareModalRowComponent: RowComponent<ProductVersion> = ({
  entity: productVersion,
}) => {
  const user: UserImmutable = useSelector(selectUser);

  const classNames = ['BulkUnshare-Row'];

  const name = displayName(productVersion, user);
  if (name === displayNameFallback) {
    classNames.push('BulkUnshare-Row-ProductNameNotFound');
  }

  return <span className={classNames.join(' ')}>{name}</span>;
};

export default BulkUnshareModalRowComponent;

import { flow, get, getOr } from 'lodash/fp';

import { MODULE_REDUCER_KEY } from 'constants/reducer';
import { GlobalState } from 'types';

import { REDUCER_KEY } from './reducer';
import { PermissionEntitiesType } from './types';

const selectState = get([MODULE_REDUCER_KEY, REDUCER_KEY]);

export const selectPermissions: (state: GlobalState) => PermissionEntitiesType =
  flow(selectState, getOr({}, ['entities']));

import { flow, get, reduce } from 'lodash/fp';

import { MODULE_REDUCER_KEY } from 'constants/reducer';
import { GlobalState } from 'types/redux';

import { SUB_MODULE_NAME } from './constants';
import { RFPsState } from './reducers';
import { ProductRFPs } from './types';

const selectModuleState: (state: GlobalState) => RFPsState = get([
  MODULE_REDUCER_KEY,
  SUB_MODULE_NAME,
]);

export const selectRFPs = flow<[GlobalState], RFPsState, ProductRFPs[] | null>(
  selectModuleState,
  get(['rfps']),
);

export const selectRFPHierarchyIds = flow<
  [GlobalState],
  RFPsState,
  number[] | null
>(selectModuleState, (state) =>
  reduce(
    (acc: number[], r: ProductRFPs) => acc.concat(r.hierarchy_product_ids),
    [],
    get(['rfps'], state),
  ),
);

import moment from 'moment';
import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';

import { Button } from '@alkem/react-ui-button';
import { LazyTooltip } from '@alkem/react-ui-tooltip';

import i18n from 'utils/i18n';

import {
  productAdminLoadProductIndexedAtDate,
  productAdminReindexProduct,
  productAdminResyncProduct,
} from '../../actions';
import {
  selectIndexedAt,
  selectIsReindexing,
  selectIsResyncing,
} from '../../selectors';

import './indexing-section.scss';

export const IndexingSection = () => {
  const params = useParams<{ productKeyId: string }>();
  const productKeyId = params.productKeyId as string;

  const dispatch = useDispatch();

  const fetchIndexedAt = () =>
    dispatch(productAdminLoadProductIndexedAtDate(+productKeyId));

  useEffect(() => {
    dispatch(productAdminLoadProductIndexedAtDate(+productKeyId));
  }, [dispatch, productKeyId]);

  const indexedAt = useSelector(selectIndexedAt);
  const indexedAtFormatted = indexedAt
    ? moment.utc(indexedAt).local().format('LLL')
    : 'unknown';

  const isReindexing: boolean = useSelector(selectIsReindexing);

  const reindexProduct = () => {
    dispatch(productAdminReindexProduct(+productKeyId));
  };

  const isResyncing: boolean = useSelector(selectIsResyncing);

  const resyncProduct = () => {
    dispatch(productAdminResyncProduct(+productKeyId));
  };

  return (
    <div className="alk-margin-bottom ProductAdminIndexing">
      <div className="alk-flex alk-flex-row alk-flex-space-between alk-margin-bottom ProductAdminIndexing__header">
        <h1>
          {i18n.t('frontproductstream.product_admin.indexing.title', {
            defaultValue: 'Indexing',
          })}
        </h1>
        <div className="alk-flex alk-flex-margin-horizontal">
          <Button
            id="product-admin-reindex-button"
            testid="reindex-button"
            className="Indexing_reindexButton"
            primary
            content={i18n.t(
              'frontproductstream.product_admin.indexing_reindex.button',
              {
                defaultValue: 'Reindex product',
              },
            )}
            onClick={reindexProduct}
            disabled={isReindexing}
            displaySpinner={isReindexing}
          />
          <Button
            id="product-admin-resync-button"
            testid="resync-button"
            className="Indexing_resyncButton"
            primary
            content={i18n.t(
              'frontproductstream.product_admin.indexing_resync.button',
              {
                defaultValue: 'Resync product',
              },
            )}
            onClick={resyncProduct}
            disabled={isResyncing}
            displaySpinner={isResyncing}
          />
        </div>
      </div>

      <div className="ProductAdminIndexing__body">
        {i18n.t('frontproductstream.product_admin.indexing_indexed_at.text', {
          defaultValue: 'Last reindex was',
        })}{' '}
        <b>{indexedAtFormatted}</b>{' '}
        <LazyTooltip
          id="product-admin-refresh-indexed-at-tooltip"
          tooltipLabel={i18n.t(
            'frontproductstream.product_admin.indexing_refresh.button',
            {
              defaultValue: 'Refresh last reindex date',
            },
          )}
        >
          <Button
            id="product-admin-refresh-indexed-at-button"
            testid="refresh-indexed-at-button"
            onClick={fetchIndexedAt}
            primary
          >
            <i className="mdi mdi-reload" />
          </Button>
        </LazyTooltip>
      </div>
    </div>
  );
};

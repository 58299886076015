import { bool } from 'prop-types';
import { connect } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { createStructuredSelector } from 'reselect';

import EmptyState from '@alkem/react-ui-empty-state';

import { openHelpCenter } from 'modules/help';
import * as routes from 'routes';
import i18n from 'utils/i18n';

import { selectIsFirstTime } from '../../../../selectors';

const mapStateToProps = createStructuredSelector({
  isFirstTime: selectIsFirstTime,
});

function CatalogEmptyAssignationTable({ isFirstTime }) {
  const navigate = useNavigate();
  let title;
  let text;
  let action;
  let tips;
  let tipsAction;
  // if first timer, show a helpful empty state
  if (isFirstTime) {
    title = i18n.t('No request found');
    text = i18n.t(
      "Looks like you don't have pending requests from retailers. Get started by adding products to your catalog.",
    );
    action = {
      label: i18n.t('Go to catalog'),
      onClick: () => navigate(routes.catalogAll),
    };
    tips = (
      <span>
        {i18n.t(
          "If you have received emails with requests from retailers that don't appear here, you can contact us",
        )}
      </span>
    );
    tipsAction = {
      label: i18n.t('Contact support'),
      onClick: () => openHelpCenter({ module: 20 }),
    };
  } else {
    title = i18n.t('No request found');
    text = i18n.t(
      'No result were found for this search. Please try again with other parameters.',
    );
  }
  return (
    <EmptyState
      title={title}
      text={text}
      action={action}
      tips={tips}
      tipsAction={tipsAction}
    />
  );
}

CatalogEmptyAssignationTable.propTypes = {
  isFirstTime: bool,
};

export default connect(mapStateToProps)(CatalogEmptyAssignationTable);

import { memo, useCallback } from 'react';

import { SwitchButton } from '@alkem/react-ui-button';

import { PRODUCTS_NOT_DUPLICATED } from 'constants/duplicated-products';
import { duplicatedProductsFilter } from 'core/modules/list/constants/filters';
import { FilterOnChange, FilterOnCollapse, ListAggregations } from 'types';
import i18n from 'utils/i18n';
import { track } from 'utils/tracking';

import CollapsibleFilter from '../collapsible';
import { SelectedFilterMap } from '../generic/types';

interface Props {
  aggregations: ListAggregations;
  collapsed: boolean;
  onCollapse: FilterOnCollapse;
  duplicatedProducts: boolean | undefined;
  onChangeOnlyDuplicatedProducts: (boolean) => void;
  filterQueryValues: string[];
  onChange: FilterOnChange;
  selectedFilterMap: SelectedFilterMap;
}
export const DuplicatedProductsFilter = memo(
  ({
    collapsed,
    onChange,
    selectedFilterMap,
    onCollapse,
    onChangeOnlyDuplicatedProducts,
    duplicatedProducts,
  }: Props) => {
    const { filterLabel, key } = duplicatedProductsFilter;
    const selectId = (filter) => filter.get('id');

    const onLocalCollapse = useCallback(
      (isCollapsed: boolean) => {
        onCollapse(key, isCollapsed);
      },
      [onCollapse, key],
    );

    const onChangeShowOnlyDuplicatedProducts = (isSelected) => {
      onChangeOnlyDuplicatedProducts(!isSelected);
      if (
        isSelected &&
        selectedFilterMap?.get(selectId(PRODUCTS_NOT_DUPLICATED))
      ) {
        onChange(
          {
            key: duplicatedProductsFilter.key,
            value: selectId(PRODUCTS_NOT_DUPLICATED),
            add: false,
          },
          true,
        );
      }
      track({
        action: 'duplicate_product_toggle_clicked',
        value: isSelected,
      });
    };

    return (
      <CollapsibleFilter
        id="duplicated-products-filter"
        label={filterLabel}
        collapsed={collapsed}
        onCollapse={onLocalCollapse}
      >
        <div>
          <SwitchButton
            content={i18n.t(
              'frontproductstream.core.list_filter_duplicated_product.switch_button',
              { defaultValue: 'Show only duplicated products' },
            )}
            onChange={onChangeShowOnlyDuplicatedProducts}
            checked={!duplicatedProducts}
          />
        </div>
      </CollapsibleFilter>
    );
  },
);

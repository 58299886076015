import i18n from 'utils/i18n';

export const BRAND_PRODUCT_BRAND = {
  id: 1,
  name: 'ProductBrand',
  label: i18n.t('frontproductstream.constants.brands.unknown_brand', {
    defaultValue: 'Unknown brand',
  }),
};
export const BRAND_NO_BRAND = {
  id: 2,
  name: 'NoBrand',
  label: i18n.t('frontproductstream.constants.brands.not_branded', {
    defaultValue: 'Not branded',
  }),
};

export const SPECIAL_BRANDS = [BRAND_PRODUCT_BRAND, BRAND_NO_BRAND];

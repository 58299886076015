import classNames from 'classnames';
import PropTypes from 'prop-types';

import i18n from 'utils/i18n';

import './index.scss';

export default function ScoreColumn({ data }) {
  const classes = {
    scoreColumn: true,
    scoreGreen: data > 50,
    scoreYellow: data >= 29 && data <= 50,
    scoreRed: data < 29,
  };

  return data ? (
    <div className={classNames(classes)}>{data}</div>
  ) : (
    <div className="scoreColumn scoreColumnEmpty">{i18n.t('N/A')}</div>
  );
}

ScoreColumn.propTypes = {
  data: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
};

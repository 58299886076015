import CancelablePromise from 'cancelable-promise';
import qs from 'querystringify';

import { ResponseWithData } from '@alkem/sdk-dashboard';

import Autocomplete from 'components/ui/autocomplete';
import Addable from 'components/ui/autocomplete/addable';
import coreBaseApi from 'resources/coreBaseApi';

interface CustomAutoCompleteProps {
  sort?: string;
  path?: string;
  searchParam?: string;
  limit?: number | null;
  minInput?: number;
  dataFormatter?: () => any;
  dataFilterer?: (item: {
    data: { isEarlyPaymentDiscount?: boolean };
  }) => boolean;
  expandAll: boolean;
  keyAttrName: string;
  params?: { [key: string]: any };
  testId?: string;
}

export default class CustomAutocomplete extends Autocomplete<CustomAutoCompleteProps> {
  static demoProps = [
    {
      id: 'demo_custom_autocomplete_1',
      value: [
        {
          _href: '/core/v1/brand/5694',
          _type: 'Brand',
          id: 5694,
          label: 'Alkemics - Dev',
        },
      ],
      multiple: false,
      options: undefined,
      path: '/core/v3/referentials/brands',
      placeholder: 'Marque commerciale',
      disabled: false,
      searchOnClick: false,
    },
  ];

  static demoHandlers = {
    onSelect: {
      prop: null,
      handler: () => null,
    },
    onUnselect: {
      prop: null,
      handler: () => null,
    },
    onAdd: {
      prop: null,
      handler: () => null,
    },
  };

  static defaultProps = Object.assign({}, Autocomplete.defaultProps, {
    limit: 20,
    dataFormatter: null,
    expandAll: false,
    keyAttrName: 'id',
  });

  constructor(props) {
    super(props);
    this.getList = this.getList.bind(this);
    this.formatTree = this.formatTree.bind(this);
  }

  filterTree(node) {
    return node.data ? node.data.tag !== 'obsolete' : true;
  }

  formatTree(tree) {
    const { expandAll, keyAttrName, dataFilterer } = this.props;
    const formattedTree: { [key: string]: any } = {
      label: tree.label || tree.name,
      value: tree,
      key: tree[keyAttrName],
      code: tree.code,
      data: tree.data,
      isOpen: expandAll,
      isExpandable: !expandAll,
    };
    if (tree.children) {
      const filterer = dataFilterer || this.filterTree;
      formattedTree.children = tree.children
        .filter((e) => filterer(e))
        .map((e) => this.formatTree(e));
    }
    return formattedTree;
  }

  getList<T = any>(
    search?: string,
  ):
    | Promise<T>
    | CancelablePromise<ResponseWithData<any>>
    | CancelablePromise<T>
    | null
    | void {
    const {
      path,
      params,
      searchParam,
      minInput,
      dataFormatter,
      dataFilterer,
      limit,
    } = this.props;
    if (minInput && search !== undefined && search.length < minInput) {
      return null;
    }
    const parameters = params || {};
    if (limit !== null) {
      parameters.limit = limit;
    }
    const searchKey = searchParam || 'search';
    parameters[searchKey] = search;

    const checkQueryString = /\?/;
    const querystringAlreadyPresent = checkQueryString.test(path || '');
    const qsParam = querystringAlreadyPresent ? '&' : true;

    const promise = coreBaseApi.get(
      path || '',
      qs.stringify(parameters, qsParam),
    );
    promise.then((response) => {
      const formatter = dataFormatter || this.formatTree;
      const filterer = dataFilterer || this.filterTree;
      const list = response.data.data
        ?.filter((e) => filterer(e))
        .map((result) => formatter(result));
      this.updateList(list, search === '');
    });
    return promise;
  }
}

const CustomAddableAutocomplete = Addable(CustomAutocomplete);

export class AddableCustomAutocomplete extends CustomAddableAutocomplete {
  static defaultProps = Object.assign(
    {},
    CustomAddableAutocomplete.defaultProps,
    { addDisabled: true },
  );
}

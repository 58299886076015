import { noop } from 'lodash';

import { Ellitips } from '@alkem/react-ui-ellitips';

import { NavItem } from '../../types';

import { Button } from './button';
import { Icon } from './icon';
import { NavLabel } from './nav-label';

interface Props extends NavItem {
  content: string;
  navId: string;
  onNavItemClick: (itemId: string) => void;
}

export function AnchorNavItem(props: Props) {
  return (
    <Button
      active={props.active || false}
      level={props.level ?? 1}
      navId={props.navId}
      onNavItemClick={props.onNavItemClick || noop}
    >
      <NavLabel className={props.className}>
        <Icon iconName={props.iconName} />
        <Ellitips label={props.content} id={props.content} />
      </NavLabel>
    </Button>
  );
}

import type { ComponentProps, ComponentType } from 'react';
import { useNavigate } from 'react-router-dom';

export function withNavigate<C extends ComponentType<any>>(
  Component: C,
  statics: string[] = [],
) {
  function WithNavigate(props: any) {
    const navigate = useNavigate();
    return <Component {...props} navigate={navigate} />;
  }
  for (const key of ['defaultProps', ...statics]) {
    if (key in Component) {
      WithNavigate[key] = Component[key];
    }
  }
  if (Component.propTypes) {
    const { navigate, ...propTypes } = Component.propTypes;
    WithNavigate.propTypes = propTypes;
  }
  return WithNavigate as ComponentType<Omit<ComponentProps<C>, 'navigate'>> & {
    [key in keyof C]: C[key];
  };
}

import { PureComponent } from 'react';

import { Button } from '@alkem/react-ui-button';

import { hasFeature } from 'modules/feature-flag';
import FeatureManufacturerRecipeModal from 'modules/feature-flag/components/manufacturer-recipe/modal';
import { FEATURE_MANUFACTURER_RECIPE } from 'modules/feature-flag/constants';
import { UserImmutable } from 'types';

import RecipeModal from './recipe-modal';

interface Props {
  items: any[];
  action: {
    content: string;
  };
  contentOwnerId: number;
  productId: number;
  productKeyId: number;
  productVersionId: number;
  targetMarketId: number;
  user: UserImmutable;
  updateEntities: (assetType: string) => void;
}

interface State {
  isOpen: boolean;
}

export default class ActionRecipe extends PureComponent<Props, State> {
  public state = {
    isOpen: false,
  };

  private hasSomeRecipes = () => {
    const { items } = this.props;
    return (
      items && items.some((item) => item._type === 'ProductEnrichedContent')
    );
  };

  private hideModal = () => {
    this.setState({ isOpen: false });
  };

  private showModal = () => {
    this.setState({ isOpen: true });
  };

  private onSuccess = () => {
    this.props.updateEntities('ProductEnrichedContent');
    this.hideModal();
  };

  private formatAction() {
    const { action, user } = this.props;
    if (hasFeature(user, FEATURE_MANUFACTURER_RECIPE)) {
      return action.content;
    } else {
      return `${action.content} - Upgrade!`;
    }
  }

  private renderModal() {
    const {
      action,
      productId,
      productVersionId,
      contentOwnerId,
      targetMarketId,
      productKeyId,
      user,
    } = this.props;
    if (hasFeature(user, FEATURE_MANUFACTURER_RECIPE)) {
      return (
        <RecipeModal
          title={action.content}
          productId={productId}
          productVersionId={productVersionId}
          contentOwnerId={contentOwnerId}
          targetMarketId={targetMarketId}
          productKeyId={productKeyId}
          onSuccess={this.onSuccess}
          onClose={this.hideModal}
        />
      );
    } else {
      return <FeatureManufacturerRecipeModal onClose={this.hideModal} />;
    }
  }

  public render() {
    if (this.hasSomeRecipes()) {
      // we're only allowing one recipe per product
      return null;
    }
    return (
      <div>
        <Button
          secondary
          className="Button--addRecipe"
          onClick={this.showModal}
        >
          {this.formatAction()}
        </Button>
        {this.state.isOpen && this.renderModal()}
      </div>
    );
  }
}

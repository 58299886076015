import { isEmpty } from 'lodash/fp';
import PropTypes from 'prop-types';
import { useMemo } from 'react';

import FormGroup from 'components/ui/form/form-group';
import { ENTITY_TYPE_PRODUCT_MEDIA } from 'constants/entities';

import { useAssetContext } from '../context';

function AssetFormGroup({
  controllers,
  controller,
  entityId,
  entity,
  onChangeField,
  entityIndex,
  ignoreFieldNames,
  entityKind,
  noDispatch,
}) {
  const fields = useMemo(
    () => controllers.find((item) => item.controller === controller),
    [controllers, controller],
  );

  if (!fields || !fields.items) {
    return null;
  }

  fields.items = fields.items.filter(
    (item) => !ignoreFieldNames.includes(item.model),
  );

  return fields && !isEmpty(fields.items) ? (
    <FormGroup
      entity={entity}
      entityKind={entityKind}
      entityId={entityId}
      formGroup={fields}
      deepIndex={2}
      showTitle={false}
      onChangeField={onChangeField}
      noDispatch={noDispatch}
      entityIndex={entityIndex}
      disableDataOps
      isInitialOnChangeDisabled
    />
  ) : null;
}

function AssetContextualFormGroup({
  entity,
  controller,
  onChangeField,
  entityIndex = 0,
  ignoreFieldNames = [],
  entityKind = ENTITY_TYPE_PRODUCT_MEDIA,
  noDispatch = true,
}) {
  const { formGroup, entityId } = useAssetContext();

  return formGroup && !isEmpty(formGroup.items) ? (
    <AssetFormGroup
      controllers={formGroup.items}
      controller={controller}
      entityId={entityId}
      entity={entity}
      onChangeField={onChangeField}
      entityIndex={entityIndex}
      ignoreFieldNames={ignoreFieldNames}
      entityKind={entityKind}
      noDispatch={noDispatch}
    />
  ) : null;
}

AssetContextualFormGroup.propTypes = {
  entity: PropTypes.shape({ id: PropTypes.number }).isRequired,
  controller: PropTypes.string.isRequired,
  onChangeField: PropTypes.func.isRequired,
};

AssetFormGroup.propTypes = {
  ...AssetContextualFormGroup.propTypes,
  controllers: PropTypes.array.isRequired,
};

export default AssetContextualFormGroup;

import { once } from 'lodash/fp';

import { isRealProduction } from 'utils';

export const initStatusPageIO = once(() => {
  if (isRealProduction()) {
    const script = document.createElement('script');
    script.src = 'https://9xx3sdklp5f1.statuspage.io/embed/script.js';
    script.async = true;
    document.body.appendChild(script);
  }
});

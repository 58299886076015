import classNames from 'classnames';
import { useSelector } from 'react-redux';

import { setCurrentAssetTab } from 'actions/media';
import { useDispatch } from 'hooks/useDispatch';
import { selectIsRetailer } from 'modules/user';
import { MediaTab, selectCurrentAssetTab } from 'reducers/media';
import i18n from 'utils/i18n';

import styles from './AssetModalSubHeader.module.scss';
import { DownloadImageSpecsDropdown } from './DownloadImageSpecsDropdown';

type TabButtonProps = {
  isCurrentTab: boolean;
  label: string;
  onClick: () => void;
};

const TabButton = ({ isCurrentTab, label, onClick }: TabButtonProps) => (
  <button
    type="button"
    className={classNames([
      styles.tabButton,
      {
        [styles.tabButtonActive]: isCurrentTab,
      },
    ])}
    onClick={onClick}
  >
    {label}
  </button>
);

export const AssetModalSubHeader = () => {
  const currentAssetTab = useSelector(selectCurrentAssetTab);
  const isRetailer: boolean = useSelector(selectIsRetailer);

  const dispatch = useDispatch();

  const handleTabClick = (tab: MediaTab) => {
    dispatch(setCurrentAssetTab(tab));
  };

  return (
    <div className={styles.wrapper}>
      <div className={styles.tabs}>
        <TabButton
          label={i18n.t(
            'frontproductstream.asset_list.asset_modal.sub_header.tabs.digital_asset_library',
            {
              defaultValue: 'Digital Asset Library',
            },
          )}
          isCurrentTab={currentAssetTab === 'DIGITAL_ASSET_LIBRARY_TAB'}
          onClick={() => handleTabClick('DIGITAL_ASSET_LIBRARY_TAB')}
        />
        <TabButton
          label={i18n.t(
            'frontproductstream.asset_list.asset_modal.sub_header.tabs.import',
            {
              defaultValue: 'Import',
            },
          )}
          isCurrentTab={currentAssetTab === 'IMPORT_TAB'}
          onClick={() => handleTabClick('IMPORT_TAB')}
        />
      </div>
      {!isRetailer && <DownloadImageSpecsDropdown />}
    </div>
  );
};

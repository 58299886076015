import { cloneDeep, merge, set } from 'lodash';

import { getOrganization, hasOrganization, isAdmin } from 'core/api/user';

export const preferences = {};

// TODO improve
// look for restrictAdmin on maker tabs
function restrictAdmin(prefs) {
  const newPrefs = Object.assign({}, prefs);
  newPrefs.navbar.maker = prefs.navbar.maker.filter(
    (tab) => !tab.restrictAdmin,
  );
  return newPrefs;
}

export function loadPrefs(user, _config = {}) {
  const config = cloneDeep(_config);
  let prefs = {};
  if (
    user &&
    hasOrganization(user) &&
    getOrganization(user).get('preferences')
  ) {
    prefs = getOrganization(user).get('preferences').toJS();
    // Dirty fix to ignore navbar preferences from database
    // lodash merge function is dangerous with arrays:
    // _.merge([1,2,3], [4,5,6]) => [4, 5, 6]
    set(prefs, 'navbar.maker', undefined);
    set(prefs, 'navbar.retailer', undefined);
  }
  let mergedPrefs = Object.assign(preferences, merge(config, prefs));
  if (user && isAdmin(user) === false) {
    mergedPrefs = restrictAdmin(mergedPrefs);
  }
  return mergedPrefs;
}

export default preferences;

import { get } from 'lodash';

import { store } from 'store';

import { RESET_MODULE_ACTION } from 'core/modules/async/constants';
import createReducer from 'reducers';
import { curry } from 'utils/curry';
import { logError } from 'utils/logging';

export const MODULE_REDUCER_KEY = 'module';
export const moduleReducers = {};

export const registerModuleReducer = (name, reducer) => {
  if (!name) {
    logError('Trying to register reducer with no module name');
    return;
  }
  if (name in moduleReducers) {
    return;
  }
  moduleReducers[name] = (state, action) => {
    let newState = state;
    if (action.type === RESET_MODULE_ACTION && action.payload === name) {
      newState = undefined;
    }
    return reducer(newState, action);
  };
  if (store) {
    store.replaceReducer(createReducer(moduleReducers));
  }
};

export const getModuleState = curry((moduleName, state) =>
  get(state, [MODULE_REDUCER_KEY, moduleName]),
);

export interface ProductVersionData {
  data: { id: number; isConsumerUnit: boolean };
  lastRequest: any;
  transactionIds: any[];
  lastModified: any;
  diff: any[];
  patches?: any;
  specificData: any;
}

export interface ProductLogisticalHierarchyChild {
  product_key_id: number;
  uuid: string;
  version: {
    id: number;
    isConsumerUnit: boolean;
    typePackaging: { code: string; label: string };
  };
  gtin: string;
  internalRootId: string;
  children: ProductLogisticalHierarchyChild[];
}

export interface ProductLogisticalHierarchyData {
  id: number;
  data: ProductLogisticalHierarchyChild;
  lastRequest: any;
  transactionIds: any[];
  lastModified: any;
  diff: any[];
  patches?: any;
}

export interface ProductData {
  key: { id: number };
  data: {
    productVersion: ProductVersionData;
    logisticalHierarchies_beta: ProductLogisticalHierarchyData[];
  };
}

export enum ExportableStatus {
  NOT_EXPORTABLE = 0,
  EXPORTABLE = 1,
  NOT_APPLICABLE = 2,
}

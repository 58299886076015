export const HISTORY_REDUCER_KEY = 'history';

export const GET_HISTORY = 'GET_HISTORY';
export const RECEIVE_HISTORY = 'RECEIVE_HISTORY';

export const HISTORY_TRANSACTION_ACCEPTED_ACTION = 'accept';
export const HISTORY_TRANSACTION_CONTRIBUTION_ACCEPT = 'contribution_accept';
export const HISTORY_TRANSACTION_DATA_MIGRATION = 'data_migration';
export const HISTORY_TRANSACTION_DELETED_ACTION = 'DELETE';
export const HISTORY_TRANSACTION_IMPORTED_BY = 'imported_by';
export const HISTORY_TRANSACTION_REFUSED_ACTION = 'refuse';

import { registerModuleReducer } from 'utils/modules/reducer';

import SharingUnitsAgreed from './components/agreed';
import SharingUnitsInit from './components/init';
import SharingUnitsRefused from './components/refused';
import reducer from './reducer';

registerModuleReducer('retailerSharingUnits', reducer);

export { SharingUnitsInit, SharingUnitsAgreed, SharingUnitsRefused };

import { List, Map, fromJS } from 'immutable';

import { ASSET_TYPES } from 'constants/media';
import {
  ISLABELEDBY_FMD_ID,
  KIND_FMD_ID,
  LECLERC_HIERARCHY_FMD_ID,
  RESTRICTION_TYPE_BLOCKING,
  RuleApplicationStatus,
  RuleTemplateLabel,
  RuleTemplateType,
  restrictionTypes,
  ruleEntities,
} from 'modules/validation';
import i18n from 'utils/i18n';
import { every, get, isEmpty } from 'utils/immutable';

export function isBlocking(rule) {
  return rule.get('restrictionType') === RESTRICTION_TYPE_BLOCKING;
}

export function isBypassed(rule, languageCode, multiLocalesEnabled = true) {
  if (languageCode && (isSuggestion(rule) || multiLocalesEnabled)) {
    return (
      rule.getIn(['status_by_language', languageCode]) ===
      RuleApplicationStatus.BYPASSED
    );
  }
  return rule.get('status') === RuleApplicationStatus.BYPASSED;
}

export function isKO(rule, languageCode, multiLocalesEnabled = true) {
  if (languageCode && (isSuggestion(rule) || multiLocalesEnabled)) {
    return (
      rule.getIn(['status_by_language', languageCode]) ===
      RuleApplicationStatus.KO
    );
  }
  return rule.get('status') === RuleApplicationStatus.KO;
}

export function isNormalizedComment(rule) {
  return rule.get('templateType') === RuleTemplateType.NORMALIZED_COMMENT;
}

function isHierarchyRule(rule) {
  return [
    ruleEntities.LOGISTICAL_HIERARCHY,
    ruleEntities.LOGISTICAL_UNIT,
    ruleEntities.LOGISTICAL_HIERARCHY_TOP_UNIT,
  ].includes(rule.get('entityType'));
}

function canBeGroupedByGTIN(rule) {
  const failedGTINs = rule.getIn(['paths', 'failed_gtins']);
  const gtinInRootFields = (rule.get('root_fields') || List()).includes('gtin');
  return failedGTINs && failedGTINs.size && !gtinInRootFields;
}

function isAssetRule(rule) {
  const path = rule.getIn(['paths', 'a', 0]);
  const assetType = typeof path === 'string' && path.split('.')[1];
  return assetType ? ASSET_TYPES.includes(assetType) : false;
}

function isIsPartitionedByRule(rule) {
  const path = rule.getIn(['paths', 'a', 0]);
  return path && path.endsWith('isPartitionedBy');
}

function isPriceWaterfallRule(rule) {
  // Paths are Sets so getting `0` from a set does not work.
  const paths = get(rule.toJS(), ['paths', 'a']);
  return (
    paths && paths.filter((path) => path.match(/^priceWaterfalls\.(\d+)$/))[0]
  );
}

function isTaxRule(rule) {
  return (
    rule
      .get('paths')
      .toList()
      .flatten()
      .filter((p) => p.match(/^dutyFeeTaxInformationList/)).size > 0
  );
}

function groupByPath(rules) {
  return rules
    .toList()
    .groupBy((v) => v.get('paths'))
    .toList();
}

function groupByMessage(rules) {
  return rules
    .toList()
    .groupBy((v) => v.get('errorMessage'))
    .toList();
}

export function groupHierarchyRules(rules, notRequestedMaturities) {
  let maturitiesFiltered;
  if (notRequestedMaturities) {
    // Take rules belonging to an inactive maturity ruleset only
    maturitiesFiltered = rules.filter(
      (r) =>
        r.getIn(['ruleSet', 'type']) === 'MATURITY' &&
        r.getIn(['ruleSet', 'requested']) === false,
    );
  } else {
    // Take rules without a ruleset, or with a maturity ruleset requested
    maturitiesFiltered = rules.filter(
      (r) =>
        (r.getIn(['ruleSet', 'type']) === 'MATURITY' &&
          r.getIn(['ruleSet', 'requested']) === true) ||
        r.getIn(['ruleSet', 'type']) !== 'MATURITY',
    );
  }
  // We want to group all failed GTINS
  const failedGTINsRules = maturitiesFiltered.filter(
    (r) =>
      canBeGroupedByGTIN(r) && r.get('status') === RuleApplicationStatus.KO,
  );
  const failedGtins = failedGTINsRules.reduce(
    (acc, r) => acc.concat(r.getIn(['paths', 'failed_gtins'])),
    List(),
  );

  // For rules that do not have failed GTINs, we group by message.
  const otherRules = maturitiesFiltered.filter((r) => !canBeGroupedByGTIN(r));
  let result = List();
  const correctionMessage =
    failedGtins.size === 1
      ? i18n.t(
          'frontproductstream.plugins.validator.hierarchy_summary.one_label',
          { defaultValue: 'One unit needs to be corrected:' },
        )
      : i18n.t(
          'frontproductstream.plugins.validator.hierarchy_summary.many_label',
          { defaultValue: 'Several units need to be corrected:' },
        );
  if (failedGTINsRules.size) {
    result = result.push(
      failedGTINsRules.toList().map((g) =>
        g.withMutations((r) =>
          r
            .mergeDeep(
              fromJS({
                bypassable: false,
                bypassed: false,
                errorLabel: null,
                errorMessage: correctionMessage,
                paths: {
                  failed_gtin_counts: r
                    .getIn(['paths', 'failed_gtins'])
                    .reduce(
                      (acc, ru) => acc.set(ru, acc.get(ru, 0) + 1),
                      Map(),
                    ),
                },
                status: RuleApplicationStatus.KO,
                templateLabel: null,
              }),
            )
            .deleteIn(['paths', 'failed_gtins']),
        ),
      ),
    );
  }
  return result.concat(groupByMessage(otherRules));
}

export function splitRules(results, languageCode, multiLocalesEnabled = null) {
  const errors = results.filter(
    (rule) =>
      isKO(rule, languageCode, multiLocalesEnabled) ||
      isBypassed(rule, languageCode, multiLocalesEnabled),
  );
  const suggestionsErrors = errors.filter(isSuggestion);
  const rulesErrors = errors.filter((rule) => !isSuggestion(rule));

  // Smart merge rules by error paths.
  const rules = List()
    // Reduce rules by paths.
    .concat(
      groupByPath(
        rulesErrors
          // Take only real rules.
          .filter(
            (r) =>
              !isSuggestion(r) &&
              !isHierarchyRule(r) &&
              !isAssetRule(r) &&
              !isIsPartitionedByRule(r) &&
              !isPriceWaterfallRule(r) &&
              !isTaxRule(r),
          ),
      ),
    )
    // Add all special rules that are handled separately.
    .concat(groupHierarchyRules(rulesErrors.filter(isHierarchyRule), false))
    .concat(groupHierarchyRules(rulesErrors.filter(isHierarchyRule), true))
    .concat(groupByMessage(rulesErrors.filter(isAssetRule)))
    .concat(groupByMessage(rulesErrors.filter(isIsPartitionedByRule)))
    .concat(groupByMessage(rulesErrors.filter(isPriceWaterfallRule)))
    .concat(groupByMessage(rulesErrors.filter(isTaxRule)));

  return { rules, suggestions: suggestionsErrors };
}

export function groupRuleSetLabels(rules) {
  return rules
    .map((r) => r.getIn(['ruleSet', 'label']))
    .filter((l) => !!l)
    .toSet()
    .toList();
}

export function groupRuleSets(rules) {
  return (rules || List())
    .map((rule) =>
      (rule.get('ruleSet') || Map()).set(
        'applicabilityByOrganizations',
        get(rule, 'applicabilityByOrganizations', Map()),
      ),
    )
    .groupBy((ruleSet) => ruleSet.get('label'))
    .map((group) => group.first())
    .toList()
    .filter((ruleSet) => ruleSet.get('label'));
}

export const areRulesNotRequested = (rules) => {
  return (
    !isEmpty(rules) > 0 &&
    every(
      rules,
      (rule) =>
        get(rule, ['ruleSet', 'type']) === 'MATURITY' &&
        (get(rule, ['ruleSet', 'requested']) === false ||
          get(rule, ['ruleSet', 'deadlineHasPassed']) === false),
    )
  );
};

// =============================================================================
//                            Suggestions utils
// =============================================================================

export function isSuggestion(rule) {
  return (
    rule.get('isSuggestion', false) ||
    rule.get('templateType') === RuleTemplateType.NORMALIZED_COMMENT
  );
}

export function isRealtimeLabelSuggestion(rule) {
  return (
    rule.get('templateType') === RuleTemplateType.SUGGESTED_ML_REALTIME &&
    rule.get('fieldmetadataId') === ISLABELEDBY_FMD_ID
  );
}

export function isRealtimeKindSuggestion(rule) {
  return (
    rule.get('templateType') === RuleTemplateType.SUGGESTED_ML_REALTIME &&
    rule.get('fieldmetadataId') === KIND_FMD_ID
  );
}

export function isRealtimeInternalTaxonomySuggestion(rule) {
  return (
    rule.get('templateType') === RuleTemplateType.SUGGESTED_ML_REALTIME &&
    [LECLERC_HIERARCHY_FMD_ID].includes(rule.get('fieldmetadataId'))
  );
}

function hasSpecificDisplay(rule) {
  return (
    isRealtimeLabelSuggestion(rule) ||
    isRealtimeKindSuggestion(rule) ||
    isRealtimeInternalTaxonomySuggestion(rule)
  );
}

export function isSuggestionDisplayedAsWarning(rule) {
  return rule.get('restrictionType') === restrictionTypes.SUGGESTED;
}

export function isSuggestionDisplayedAsHelp(rule) {
  if (hasSpecificDisplay(rule)) {
    return false;
  }
  return rule.get('restrictionType') === restrictionTypes.HELP;
}

export function getSuggestedLabel(value, templateLabel) {
  if (!value) {
    return '';
  } else if (templateLabel === RuleTemplateLabel.VALUE) {
    return value;
  } else if (value.has('isConceptualizedBy')) {
    return value.getIn(['isConceptualizedBy', 'label']);
  } else if (value.has('allergenTypeCode')) {
    return value.getIn(['allergenTypeCode', 'label']);
  } else if (value.has('entity')) {
    // for template label allergen case
    return value.getIn(['entity', 'label']);
  }
  return value.get('label');
}

function isAllergenSuggestionOnFieldCompostion(rule) {
  return (
    rule.get('templateType') === RuleTemplateType.SUGGESTED_PARSING_ALLERGENS &&
    rule.get('root_field') === 'composition'
  );
}

export function shouldDisplayAcceptationButton(rule, languageCode) {
  return (
    !isNormalizedComment(rule) &&
    rule.getIn(['data', 'suggestedValueByLanguage', languageCode]) &&
    !isAllergenSuggestionOnFieldCompostion(rule)
  );
}

export function sortSuggestions(a, b) {
  const probaA = a.getIn(['metadata', 'probability'], -1);
  const probaB = b.getIn(['metadata', 'probability'], -1);
  if (probaA > probaB) {
    return -1;
  }
  if (probaA < probaB) {
    return 1;
  }
  return 0;
}

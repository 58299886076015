import PropTypes from 'prop-types';
import { PureComponent } from 'react';
import ImmutablePropTypes from 'react-immutable-proptypes';

import ProductReference from 'components/ui/product-reference';
import { getDefaultDisplayName, getProductGTIN } from 'core/api/productversion';
import { getShortId } from 'core/api/retailerproductversion';

import './product-info.scss';

export default class RemoveFromActiveRangeProductInfo extends PureComponent {
  static propTypes = {
    productVersion: ImmutablePropTypes.map.isRequired,
    locales: PropTypes.object.isRequired,
    success: PropTypes.bool,
    error: PropTypes.bool,
  };

  render() {
    const { productVersion, locales, success, error } = this.props;
    const shortId = getShortId(productVersion);
    return (
      <li className="RemoveFromActiveRangeProductInfo">
        {!success && !error && (
          <i
            className="RemoveFromActiveRangeProductInfo__icon mdi mdi-minus"
            role="status"
          />
        )}
        {!!success && (
          <i
            className="RemoveFromActiveRangeProductInfo__icon mdi mdi-check"
            role="status"
          />
        )}
        {!!error && (
          <i
            className="RemoveFromActiveRangeProductInfo__icon mdi mdi-close"
            role="status"
          />
        )}
        <strong>{getDefaultDisplayName(productVersion, locales)}</strong>
        <span className="RemoveFromActiveRangeProductInfo__separator">•</span>
        <ProductReference reference={getProductGTIN(productVersion)} />
        {!!shortId && (
          <span className="RemoveFromActiveRangeProductInfo__separator">•</span>
        )}
        {!!shortId && (
          <span className="RemoveFromActiveRangeProductInfo__shortid">
            {shortId}
          </span>
        )}
      </li>
    );
  }
}

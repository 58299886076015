import { call, put, select } from 'redux-saga/effects';

import { notificationError } from 'actions/notification';
import i18n from 'utils/i18n';
import { logError } from 'utils/logging';
import { takeEverySafe } from 'utils/saga';
import { track } from 'utils/tracking';

import { fetchTemplate } from '../actions';
import api from '../api';
import {
  FETCH_PUBLICATION_RESULTS,
  FETCH_PUBLICATION_RESULTS_FAILURE,
  FETCH_PUBLICATION_RESULTS_SUCCESS,
  PUBLISH,
  PUBLISH_FAILURE,
  PUBLISH_SUCCESS,
} from '../constants';
import {
  selectId,
  selectIsDirty,
  selectSelectedRecipientId,
} from '../selectors';

export function* watchPublish() {
  yield takeEverySafe(PUBLISH, publish);
  yield takeEverySafe(FETCH_PUBLICATION_RESULTS, fetchPublicationResults);
}

export function* publish() {
  const isDirty = yield select(selectIsDirty);
  if (isDirty) {
    return;
  }

  const id = yield select(selectId);

  const { error } = yield call(api.publish, id);

  if (!error) {
    const selectedRecipientId = yield select(selectSelectedRecipientId);
    yield call(track, {
      category: 'tariff',
      action: 'tariff_published',
      label: selectedRecipientId,
    });

    yield put(fetchTemplate(id));
    yield put({
      type: PUBLISH_SUCCESS,
    });
  } else {
    yield put(
      notificationError(
        i18n.t(
          'frontproductstream.sharingunit_tariff.publish_notification.error',
          { defaultValue: 'An error occured publishing the price template' },
        ),
      ),
    );
    yield put({ type: PUBLISH_FAILURE, error });
    yield call(logError, error);
  }
}

export function* fetchPublicationResults({ offset }) {
  const id = yield select(selectId);

  const { result, error } = yield call(api.getPublicationResults, id, {
    limit: 5,
    offset,
  });
  if (error) {
    yield put(
      notificationError(
        i18n.t(
          'frontproductstream.sharingunit_tariff.publish_result_notification.error',
          {
            defaultValue: 'An error occured while fetching publication results',
          },
        ),
      ),
    );
    yield put({ type: FETCH_PUBLICATION_RESULTS_FAILURE, error });
    yield call(logError, error);
  } else {
    const { data, totalResults } = result;
    yield put({
      type: FETCH_PUBLICATION_RESULTS_SUCCESS,
      results: data,
      totalResults,
    });
  }
}

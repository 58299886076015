import { call, put, takeLatest } from 'redux-saga/effects';

import { notificationSuccess } from 'actions/notification';
import { searchApi } from 'resources/searchApi';
import { takeLatestSafe, withCatch } from 'utils/saga';

import {
  productAdminDeleteAuditDatas,
  productAdminGetAuditContext,
  productAdminGetAuditDatas,
  productAdminLoadProductIndexedAtDateDone,
  productAdminReindexProduct,
  productAdminResyncProduct,
  productAdminStartAudit,
} from './actions';
import {
  deleteAudit as deleteAuditOnProduct,
  getAuditContext as getAuditContextOnProduct,
  getAudit as getAuditOnProduct,
  reindexProduct as reindex,
  resyncProduct as resync,
  startAudit as startAuditOnProduct,
} from './api';
import {
  PRODUCT_ADMIN_DELETE_AUDIT_DATAS,
  PRODUCT_ADMIN_GET_AUDIT_CONTEXT,
  PRODUCT_ADMIN_GET_AUDIT_DATAS,
  PRODUCT_ADMIN_LOAD_PRODUCT_INDEXED_AT_DATE,
  PRODUCT_ADMIN_REINDEX_PRODUCT,
  PRODUCT_ADMIN_REINDEX_PRODUCT_FAILURE,
  PRODUCT_ADMIN_REINDEX_PRODUCT_SUCCESS,
  PRODUCT_ADMIN_RESYNC_PRODUCT,
  PRODUCT_ADMIN_RESYNC_PRODUCT_DONE,
  PRODUCT_ADMIN_SET_AUDIT_CONTEXT,
  PRODUCT_ADMIN_SET_AUDIT_DATAS,
  PRODUCT_ADMIN_START_AUDIT,
  PRODUCT_ADMIN_START_AUDIT_DONE,
} from './constants';

export function* reindexProduct({
  payload: productKeyId,
}: ReturnType<typeof productAdminReindexProduct>) {
  yield call(reindex, productKeyId);

  yield put(notificationSuccess('The product will be reindexed.'));

  yield put({ type: PRODUCT_ADMIN_REINDEX_PRODUCT_SUCCESS });
}

export function* resyncProduct({
  payload: productKeyId,
}: ReturnType<typeof productAdminResyncProduct>) {
  yield call(resync, productKeyId);
  yield put(notificationSuccess('The product will be resynced.'));
  yield put({ type: PRODUCT_ADMIN_RESYNC_PRODUCT_DONE });
}

export function* loadProductIndexedAtDate({
  payload: productKeyId,
}: ReturnType<typeof productAdminReindexProduct>) {
  const result = yield call(
    [searchApi, searchApi.post],
    '/search/v2/productversion',
    {
      limit: 1,
      advanced_search: {
        query: productKeyId,
        fields: ['product_key.id'],
      },
      allow_not_consumer_units: true,
      filter_source_include: ['alkemics.indexedAt'],
    },
  );

  yield put(
    productAdminLoadProductIndexedAtDateDone(
      result.data.data[0].alkemics.indexedAt as string,
    ),
  );
}

export function* startAudit({
  payload: productKeyId,
}: ReturnType<typeof productAdminStartAudit>) {
  const { audit_id } = yield call(startAuditOnProduct, productKeyId);
  yield put(notificationSuccess(`Product is audited (id: ${audit_id})`));
  yield put({ type: PRODUCT_ADMIN_START_AUDIT_DONE });
}

export function* getAudit({
  payload: productKeyId,
}: ReturnType<typeof productAdminGetAuditDatas>) {
  const datas = yield call(getAuditOnProduct, productKeyId);

  yield put({
    type: PRODUCT_ADMIN_SET_AUDIT_DATAS,
    audits: datas.data.audits,
  });
}

export function* deleteAudit({
  payload: { auditID, productKeyId },
}: ReturnType<typeof productAdminDeleteAuditDatas>) {
  yield call(deleteAuditOnProduct, auditID);

  const datas = yield call(getAuditOnProduct, productKeyId);

  yield put({
    type: PRODUCT_ADMIN_SET_AUDIT_DATAS,
    audits: datas.data.audits,
  });
}

export function* getAuditContext({
  payload: auditId,
}: ReturnType<typeof productAdminGetAuditContext>) {
  const context = yield call(getAuditContextOnProduct, auditId);

  yield put({
    type: PRODUCT_ADMIN_SET_AUDIT_CONTEXT,
    contexts: context.data.contexts,
    id: auditId,
  });
}

export default function* productAdminSaga() {
  yield takeLatest(
    PRODUCT_ADMIN_REINDEX_PRODUCT,
    withCatch(reindexProduct, {
      withNotification: true,
      stopLoadingEvent: () => {
        return {
          type: PRODUCT_ADMIN_REINDEX_PRODUCT_FAILURE,
        };
      },
    }),
  );

  yield takeLatest(
    PRODUCT_ADMIN_LOAD_PRODUCT_INDEXED_AT_DATE,
    withCatch(loadProductIndexedAtDate, {
      withNotification: true,
    }),
  );

  yield takeLatest(
    PRODUCT_ADMIN_RESYNC_PRODUCT,
    withCatch(resyncProduct, {
      withNotification: true,
      stopLoadingEvent: () => {
        return {
          type: PRODUCT_ADMIN_RESYNC_PRODUCT_DONE,
        };
      },
    }),
  );

  yield takeLatest(
    PRODUCT_ADMIN_START_AUDIT,
    withCatch(startAudit, {
      withNotification: true,
      stopLoadingEvent: () => {
        return {
          type: PRODUCT_ADMIN_START_AUDIT_DONE,
        };
      },
    }),
  );

  yield takeLatest(PRODUCT_ADMIN_GET_AUDIT_DATAS, getAudit);
  yield takeLatest(PRODUCT_ADMIN_DELETE_AUDIT_DATAS, deleteAudit);
  yield takeLatestSafe(PRODUCT_ADMIN_GET_AUDIT_CONTEXT, getAuditContext);
}

import i18n from 'utils/i18n';

export const getSharingUnitTitle = (sharingUnit, defaultName = null) =>
  sharingUnit.getIn(['data', 'name']) ||
  defaultName ||
  (sharingUnit.get('clientId')
    ? `${i18n.t('frontproductstream.product_page.sharing_unit.title', {
        defaultValue: 'Listing',
      })} - ${sharingUnit.get('clientId')}`
    : i18n.t('frontproductstream.product_page.sharing_unit.title', {
        defaultValue: 'Listing',
      }));

import { noop, uniq } from 'lodash';
import { PureComponent, ReactNode } from 'react';
import { connect } from 'react-redux';

import { SwitchButton } from '@alkem/react-ui-button';
import { Checkbox } from '@alkem/react-ui-checkbox';
import { Tags } from '@alkem/react-ui-inputs';
import { Select } from '@alkem/react-ui-select';

import { PictureAssetV2 } from 'components/ui/form/asset-list/AssetV2/types';
import DateInput from 'components/ui/input/date';
import InputWithLabel from 'components/ui/input/input-with-label';
import InputText from 'components/ui/input/text';
import { backgroundCheckContentTypes } from 'constants/assets';
import { ASSET_SCOPE } from 'constants/media';
import {
  selectHasJsonStoreInputFeature,
  selectHasMediaIpRights,
  selectHasSpecificMediaFeature,
} from 'modules/feature-flag/selectors';
import { selectCustomSkuGtinZpckSetting } from 'modules/user';
import { selectAmountOfPictures } from 'reducers/media';
import i18n from 'utils/i18n';
import { get } from 'utils/immutable';
import moment from 'utils/moment';

import { loadReferentials } from '../../../actions';
import { selectReferentials } from '../../../selectors';

import './form.scss';

const mapStateToProps = (state) => ({
  referentials: selectReferentials(state),
  hasSpecificMediaFeature: selectHasSpecificMediaFeature(state),
  hasJsonStoreInputFeature: selectHasJsonStoreInputFeature(state),
  amountOfPictures: selectAmountOfPictures(state),
  hasCustomSkuGtinZpckSetting: selectCustomSkuGtinZpckSetting(state),
  hasMediaIpRights: selectHasMediaIpRights(state),
});

const mapDispatchToProps = {
  loadReferentials,
};

interface ProductPictureFormProps {
  picture: PictureAssetV2;
  errors: {}[];
  referentials?: object;
  hasSpecificMediaFeature?: boolean;
  hasJsonStoreInputFeature?: boolean;
  overrideHasJsonStoreInputFeature?: boolean;
  amountOfPictures?: number;
  onUpdateField(field: string, value: any): void;
  onUpdatePackshot?(checked: boolean): void;
  loadReferentials(): void;
  hasCustomSkuGtinZpckSetting?: boolean;
  hasMediaIpRights?: boolean;
  readOnly?: boolean;
  children?: ReactNode;
}

export class ProductPictureForm extends PureComponent<ProductPictureFormProps> {
  componentDidMount() {
    if (!Object.keys(this.props.referentials || {}).length) {
      this.props.loadReferentials();
    }
  }

  onSelectDate = (fieldName) => (date) => {
    const value = date ? date.format('YYYY-MM-DD') : null;
    this.props.onUpdateField(fieldName, value);
  };

  onUpdateTags = (tags) => {
    const value = uniq(tags);
    this.props.onUpdateField('tags', value);
  };

  onUpdateText = (fieldName) => (event) => {
    this.props.onUpdateField(fieldName, event.target.value);
  };

  onSelectOption = (fieldName) => (option) => {
    this.props.onUpdateField(fieldName, option.id);
  };

  onClearOption = (fieldName) => () => {
    this.props.onUpdateField(fieldName, null);
  };

  onUpdatePackshot = (checked) => {
    this.onUpdateScope(true);
    this.props.onUpdatePackshot?.(checked);
  };

  onUpdateScope = (checked) => {
    if (this.props.hasSpecificMediaFeature) {
      this.props.onUpdateField(
        'scope',
        checked ? ASSET_SCOPE.PUBLIC : ASSET_SCOPE.SPECIFIC,
      );
    }
  };

  matchTag = (tag) => {
    // Matches anything that has a non-whitespace character in it
    const re = /\S/;
    return re.test(tag);
  };

  renderValidationSection(errors) {
    if (errors.length === 0) {
      return null;
    }
    const { picture } = this.props;

    return (
      <div className="FormField--raguelError">
        <div className="Raguel">
          {errors.map((error) => (
            <span className="Raguel__message" key={`${error}-${picture.crc32}`}>
              {' '}
              {error}{' '}
            </span>
          ))}
        </div>
      </div>
    );
  }

  hasJsonStoreInputFeature = () =>
    this.props.hasJsonStoreInputFeature &&
    !this.props.overrideHasJsonStoreInputFeature;

  renderAutocomplete({ fieldName, value, slug }) {
    const { referentials, picture, readOnly } = this.props;
    const options = get(referentials, slug) || [];
    const selectedValue = options.find((e) => e.id === value);
    const key = `ProductPictureForm-${picture.crc32}-${fieldName}`;
    return (
      <Select
        id={key}
        key={key}
        options={options}
        values={selectedValue ? [selectedValue] : []}
        onValueAdd={this.onSelectOption(fieldName)}
        onValueDelete={this.onClearOption(fieldName)}
        disabled={readOnly}
        inputable
        placeholder={i18n.t(
          'frontproductstream.assets.product_picture_form.select',
          { defaultValue: 'Select...' },
        )}
      />
    );
  }

  renderForm() {
    const {
      amountOfPictures,
      hasSpecificMediaFeature,
      picture,
      hasCustomSkuGtinZpckSetting,
      hasMediaIpRights,
      readOnly,
      children,
    } = this.props;
    const hasPackshot = (amountOfPictures || 0) > 0;
    return (
      <div className="ProductPictureForm__form">
        <div className="AddPicture__packshotCheckbox">
          {this.props.onUpdatePackshot && !this.hasJsonStoreInputFeature() && (
            <Checkbox
              label={i18n.t(
                'frontproductstream.assets.product_picture_form.checkbox_packshot',
                { defaultValue: 'Set as packshot' },
              )}
              id={`packshot-${picture.crc32}`}
              key={`packshot-${picture.crc32}`}
              checked={picture.isPackshot}
              onChange={this.onUpdatePackshot}
              disabled={readOnly}
            />
          )}
        </div>
        {hasSpecificMediaFeature && hasPackshot ? (
          <InputWithLabel
            childId="ProductPictureForm-scope"
            label={i18n.t(
              'frontproductstream.assets.product_picture_form.input_share',
              { defaultValue: 'Shared by default' },
            )}
          >
            <div
              className="ProductPictureForm__scope"
              data-testid="ProductPictureForm_scope"
            >
              <SwitchButton
                onChange={this.onUpdateScope}
                checked={picture.scope === ASSET_SCOPE.PUBLIC}
                disabled={picture.isPackshot || readOnly}
              />
            </div>
          </InputWithLabel>
        ) : (
          <br />
        )}
        {!this.hasJsonStoreInputFeature() && (
          <InputWithLabel
            childId="ProductPictureForm-contentType"
            label={i18n.t(
              'frontproductstream.assets.product_picture_form.input_type_of_information',
              { defaultValue: 'Type of information' },
            )}
          >
            {this.renderAutocomplete({
              fieldName: 'contentType',
              value: picture.contentType,
              slug: 'picturetypeofcontents',
            })}
          </InputWithLabel>
        )}
        {!this.hasJsonStoreInputFeature() && (
          <InputWithLabel
            childId={`ProductPictureForm-${picture.crc32}-fileEffectiveStartDateTime`}
            label={i18n.t(
              'frontproductstream.assets.product_picture_form.input_start_date',
              { defaultValue: 'Starting date of validity' },
            )}
          >
            <DateInput
              id={`ProductPictureForm-${picture.crc32}-fileEffectiveStartDateTime`}
              value={
                picture.fileEffectiveStartDateTime
                  ? moment(picture.fileEffectiveStartDateTime)
                  : null
              }
              onSelect={this.onSelectDate('fileEffectiveStartDateTime')}
              readOnly={readOnly}
            />
          </InputWithLabel>
        )}
        {!this.hasJsonStoreInputFeature() && (
          <InputWithLabel
            childId={`ProductPictureForm-${picture.crc32}-fileEffectiveEndDateTime`}
            label={i18n.t(
              'frontproductstream.assets.product_picture_form.input_end_date_label',
              { defaultValue: 'Expiration date' },
            )}
            help={i18n.t(
              'frontproductstream.assets.product_picture_form.input_end_date_help',
              {
                defaultValue:
                  'Optional. Leave empty to indicate a permanent validity.',
              },
            )}
          >
            <DateInput
              id={`ProductPictureForm-${picture.crc32}-fileEffectiveEndDateTime`}
              value={
                picture.fileEffectiveEndDateTime
                  ? moment(picture.fileEffectiveEndDateTime)
                  : null
              }
              onSelect={this.onSelectDate('fileEffectiveEndDateTime')}
              readOnly={readOnly}
            />
          </InputWithLabel>
        )}
        {!this.hasJsonStoreInputFeature() && (
          <InputWithLabel
            childId="ProductPictureForm-productFace"
            label={i18n.t(
              'frontproductstream.assets.product_picture_form.input_face',
              { defaultValue: 'Face' },
            )}
          >
            {this.renderAutocomplete({
              fieldName: 'productFace',
              value: picture.productFace,
              slug: 'pictureproductfaces',
            })}
          </InputWithLabel>
        )}
        {!this.hasJsonStoreInputFeature() && (
          <InputWithLabel
            childId="ProductPictureForm-angleVertical"
            label={i18n.t(
              'frontproductstream.assets.product_picture_form.input_vertical_angle',
              { defaultValue: 'Vertical angle' },
            )}
          >
            {this.renderAutocomplete({
              fieldName: 'angleVertical',
              value: picture.angleVertical,
              slug: 'pictureangleverticals',
            })}
          </InputWithLabel>
        )}
        {!this.hasJsonStoreInputFeature() && (
          <InputWithLabel
            childId="ProductPictureForm-angleHorizontal"
            label={i18n.t(
              'frontproductstream.assets.product_picture_form.input_horizontal_angle',
              { defaultValue: 'Horizontal angle' },
            )}
          >
            {this.renderAutocomplete({
              fieldName: 'angleHorizontal',
              value: picture.angleHorizontal,
              slug: 'pictureanglehorizontals',
            })}
          </InputWithLabel>
        )}
        {!this.hasJsonStoreInputFeature() && (
          <InputWithLabel
            childId="ProductPictureForm-webOptimizedType"
            label={i18n.t(
              'frontproductstream.assets.product_picture_form.input_web_optimized_type_label',
              { defaultValue: 'Web optimized type' },
            )}
            help={i18n.t(
              'frontproductstream.assets.product_picture_form.input_web_optimized_type_help',
              {
                defaultValue:
                  'A cleaned pack is a picture whose main elements are highlighted. Should not include any element outside the packaging (band, pictogram)...',
              },
            )}
          >
            {this.renderAutocomplete({
              fieldName: 'webOptimizedType',
              value: picture.webOptimizedType,
              slug: 'pictureweboptimizedtypes',
            })}
          </InputWithLabel>
        )}
        {!this.hasJsonStoreInputFeature() && (
          <InputWithLabel
            childId="ProductPictureForm-fileType"
            label={i18n.t(
              'frontproductstream.assets.product_picture_form.input_file_type',
              { defaultValue: 'File Type' },
            )}
          >
            {this.renderAutocomplete({
              fieldName: 'fileType',
              value: picture.fileType,
              slug: 'picturefiletypes',
            })}
          </InputWithLabel>
        )}
        {!this.hasJsonStoreInputFeature() && (
          <InputWithLabel
            childId="ProductPictureForm-tags"
            label={i18n.t(
              'frontproductstream.assets.product_picture_form.input_tags',
              { defaultValue: 'Tags' },
            )}
          >
            <Tags
              id="ProductPictureForm-tags"
              matchTag={this.matchTag}
              tags={picture.tags || []}
              onChange={this.onUpdateTags}
              disabled={readOnly}
            />
          </InputWithLabel>
        )}
        {picture.id && !this.hasJsonStoreInputFeature() && (
          <InputWithLabel
            childId="InputText-ProductPictureForm-sequenceNumber"
            label={i18n.t(
              'frontproductstream.assets.product_picture_form.input_sequence_number_label',
              { defaultValue: 'Sequence number' },
            )}
            help={i18n.t(
              'frontproductstream.assets.product_picture_form.input_sequence_number_help',
              {
                defaultValue:
                  'The sequence number is a technical number used to generate the gdsn image name. It will be automatically updated if picture metadata has changed. Note that errors can occur when there is no value.',
              },
            )}
          >
            <InputText
              id="ProductPictureForm-sequenceNumber"
              value={picture.sequenceNumber}
              onChange={this.onUpdateText('sequenceNumber')}
              type="number"
              disabled={readOnly}
            />
          </InputWithLabel>
        )}
        {hasMediaIpRights && !this.hasJsonStoreInputFeature() && (
          <InputWithLabel
            childId="ProductPictureForm-ipUsageRights"
            label={i18n.t(
              'frontproductstream.assets.product_picture_form.input_ip_usage_rights',
              { defaultValue: 'IP Usage Rights type' },
            )}
          >
            <InputText
              id="ProductPictureForm-ipUsageRights"
              value={picture.ipUsageRights}
              onChange={this.onUpdateText('ipUsageRights')}
              disabled={readOnly}
            />
          </InputWithLabel>
        )}
        {hasCustomSkuGtinZpckSetting && (
          <>
            <InputWithLabel
              childId="ProductPictureForm-sku"
              label={i18n.t(
                'frontproductstream.assets.product_picture_form.input_sku',
                { defaultValue: 'Associated SAP Material Numbers (SKUs)' },
              )}
            >
              <InputText
                id="ProductPictureForm-sku"
                value={picture.extraInfo ? picture.extraInfo.sku : ''}
                disabled
                onChange={noop}
              />
            </InputWithLabel>
            <InputWithLabel
              childId="ProductPictureForm-gtins"
              label={i18n.t(
                'frontproductstream.assets.product_picture_form.input_associated_gtin',
                { defaultValue: 'Associated GTINs' },
              )}
            >
              <InputText
                id="ProductPictureForm-gtins"
                value={picture.extraInfo ? picture.extraInfo.gtins : ''}
                disabled
                onChange={noop}
              />
            </InputWithLabel>
            <InputWithLabel
              childId="ProductPictureForm-zpck"
              label={i18n.t(
                'frontproductstream.assets.product_picture_form.input_zpck',
                { defaultValue: 'Associated ZPCKs' },
              )}
            >
              <InputText
                id="ProductPictureForm-zpck"
                value={picture.extraInfo ? picture.extraInfo.zpck : ''}
                disabled
                onChange={noop}
              />
            </InputWithLabel>
          </>
        )}
        {children}
      </div>
    );
  }

  renderImage() {
    const { picture, hasJsonStoreInputFeature } = this.props;
    const imageToDisplay =
      (picture.exportables || []).find((e) => e.width === 256) || picture;
    const fileSize = Number(picture.fileSize) / 1000;
    const contentTypeValue = hasJsonStoreInputFeature
      ? get(picture, ['fileContentTypeCode', 'id'])
      : picture.contentType;
    return (
      <>
        <img src={imageToDisplay.uniformResourceIdentifier} alt="" />
        <div className="ProductPictureForm__imageMetadata">
          <div className="ProductPictureForm__imageMetadataDetail">
            <div className="ProductPictureForm__imageMetadataTitle">
              {i18n.t(
                'frontproductstream.assets.product_picture_form.metadata_definition',
                { defaultValue: 'Definition:' },
              )}
            </div>
            <div className="ProductPictureForm__imageMetadataValue">
              {picture.definition +
                i18n.t(
                  'frontproductstream.assets.product_picture_form.picture_definition',
                  { defaultValue: 'px' },
                )}
            </div>
            {picture.height &&
            picture.height < 1500 &&
            picture.width &&
            picture.width < 1500 ? (
              <div className="ProductPictureForm__imageMetadataSuggestion">
                <i className="mdi mdi-alert" />
                {i18n.t(
                  'frontproductstream.assets.product_picture_form.metadata_picture_definition_warning',
                  {
                    defaultValue:
                      'Careful this picture definition is too small',
                  },
                )}
              </div>
            ) : null}
          </div>
          {picture.backgroundScore !== null &&
          backgroundCheckContentTypes.includes(contentTypeValue) ? (
            <div className="ProductPictureForm__imageMetadataDetail">
              <div className="ProductPictureForm__imageMetadataTitle">
                {i18n.t(
                  'frontproductstream.assets.product_picture_form.metadata_background',
                  { defaultValue: 'Background:' },
                )}
              </div>
              <div className="ProductPictureForm__imageMetadataValue">
                {picture.backgroundScore < 75
                  ? i18n.t(
                      'frontproductstream.assets.product_picture_form.metadata_background_not_transparent',
                      {
                        defaultValue:
                          'Background neither white nor transparent',
                      },
                    )
                  : i18n.t(
                      'frontproductstream.assets.product_picture_form.metadata_background_transparent',
                      { defaultValue: 'Background white or transparent' },
                    )}
              </div>
              {picture.backgroundScore < 75 ? (
                <div className="ProductPictureForm__imageMetadataSuggestion">
                  <i className="mdi mdi-alert" />
                  {i18n.t(
                    'frontproductstream.assets.product_picture_form.metadata_background_alert',
                    {
                      defaultValue:
                        'Careful this picture background is neither transparent nor white',
                    },
                  )}
                </div>
              ) : null}
            </div>
          ) : null}
          <div className="ProductPictureForm__imageMetadataDetail">
            <div className="ProductPictureForm__imageMetadataTitle">
              {i18n.t(
                'frontproductstream.assets.product_picture_form.metadata_file_size',
                { defaultValue: 'File size:' },
              )}
            </div>
            <div className="ProductPictureForm__imageMetadataValue">
              {Math.round(fileSize) +
                i18n.t('frontproducstream.asset_list.modal_unit.text', {
                  defaultValue: 'ko',
                })}
            </div>
          </div>
        </div>
      </>
    );
  }

  render() {
    const { errors } = this.props;

    return (
      <div className="ProductPictureForm ProductPage__modal">
        {this.renderValidationSection(errors)}
        <div className="ProductPictureForm__data">
          <div className="ProductPictureForm__form">{this.renderForm()}</div>
          <div className="ProductPictureForm__image">{this.renderImage()}</div>
        </div>
      </div>
    );
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(ProductPictureForm);

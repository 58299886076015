export const GET_ORGANIZATION = 'GET_ORGANIZATION';
export const RECEIVE_ORGANIZATION = 'RECEIVE_ORGANIZATION';
export const GET_MY_ORGANIZATION = 'GET_MY_ORGANIZATION';
export const EDIT_ORGANIZATION = 'EDIT_ORGANIZATION';
export const GET_ORGANIZATION_PENDING_GLN_CLAIMS =
  'GET_ORGANIZATION_PENDING_GLN_CLAIMS';
export const RECEIVE_ORGANIZATION_SETTING = 'RECEIVE_ORGANIZATION_SETTING';
export const RECEIVE_ORGANIZATION_SETTINGS = 'RECEIVE_ORGANIZATION_SETTINGS';
export const RECEIVE_ORGANIZATION_CGU = 'RECEIVE_ORGANIZATION_CGU';
export const ORGANIZATION_CGU_ACCEPTED = 'ORGANIZATION_CGU_ACCEPTED';
export const UPDATE_ORGANIZATION_SETTING = 'UPDATE_ORGANIZATION_SETTING';

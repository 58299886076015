import PropTypes from 'prop-types';

export default function RedirectError({ message }) {
  return (
    <div>
      <h1>Redirect error</h1>
      <div>{`message: ${message}`}</div>
    </div>
  );
}

RedirectError.propTypes = {
  message: PropTypes.string,
};

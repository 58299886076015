import { Map } from 'immutable';
import { once } from 'lodash';
import moment from 'moment';
import PropTypes from 'prop-types';
import { PureComponent } from 'react';
import ImmutablePropTypes from 'react-immutable-proptypes';

import Date from 'components/ui/input/date';
import i18n from 'utils/i18n';
import { get } from 'utils/immutable';

import CollapsibleFilter from '../../collapsible';

import './index.scss';

export class DatesFilter extends PureComponent {
  static propTypes = {
    id: PropTypes.string.isRequired,
    filterKey: PropTypes.string.isRequired,
    filterLabel: PropTypes.string.isRequired,
    selectedFilterMap: PropTypes.oneOfType([
      ImmutablePropTypes.map,
      PropTypes.object,
    ]),
    collapsed: PropTypes.bool,
    onChange: PropTypes.func.isRequired,
    onCollapse: PropTypes.func.isRequired,
    filterQueryValues: PropTypes.object,
  };

  static defaultProps = {
    selectedFilterMap: Map(),
    collapsed: false,
  };

  onCollapse = (collapsed) =>
    this.props.onCollapse(this.props.filterKey, collapsed);

  onChange = (from) => (value) => {
    this.props.onChange({
      key: this.props.filterKey,
      value: from ? 'from' : 'to',
      label: value
        ? `${this.props.filterLabel} ${from ? '>=' : '<='} ${value.format(
            'LL',
          )}`
        : '',
      add: !!value,
      data: value,
    });
  };

  updateSelectionFromQuery = once(() => {
    Object.entries(this.props.filterQueryValues).forEach(([key, value]) => {
      if (value) {
        const mvalue = moment(value);
        this.props.onChange(
          {
            key: this.props.filterKey,
            value: key,
            label: `${this.props.filterLabel} ${
              key === 'from' ? '>=' : '<='
            } ${mvalue.format('LL')}`,
            add: true,
            data: mvalue,
          },
          true,
        );
      }
    });
  });

  componentDidMount() {
    if (this.props.filterQueryValues) {
      this.updateSelectionFromQuery();
    }
  }

  render() {
    const { id, filterLabel, selectedFilterMap, collapsed } = this.props;
    return (
      <div className="DatesFilter">
        <CollapsibleFilter
          id={id}
          label={filterLabel}
          collapsed={collapsed}
          onCollapse={this.onCollapse}
        >
          <Date
            id={`${id}-from`}
            value={get(selectedFilterMap, 'from') || null}
            placeholder={i18n.t(
              'frontproductstream.core.list_filter_date.from_placeholder',
              { defaultValue: 'From' },
            )}
            onSelect={this.onChange(true)}
          />
          <Date
            id={`${id}-to`}
            value={get(selectedFilterMap, 'to') || null}
            placeholder={i18n.t(
              'frontproductstream.core.list_filter_date.to_placeholder',
              { defaultValue: 'To' },
            )}
            onSelect={this.onChange(false)}
          />
        </CollapsibleFilter>
      </div>
    );
  }
}

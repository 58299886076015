import i18n from 'utils/i18n';

interface Props {
  label: string;
  upgradeRequired?: boolean;
}

const UpgradeLabel = ({ label, upgradeRequired = false }: Props) => (
  <span>
    {label}
    {upgradeRequired && <i>{` - ${i18n.t('Upgrade!')}`}</i>}
  </span>
);

export default UpgradeLabel;

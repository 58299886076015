import { createSelector } from '@reduxjs/toolkit';

import { MODULE_REDUCER_KEY } from 'constants/reducer';
import { selectFormattedRecipients } from 'modules/recipients/reducers';

import { REDUCER_KEY } from '../constants';

export const selectModuleState = (state) =>
  state[MODULE_REDUCER_KEY][REDUCER_KEY];

export const selectImportReport = createSelector(selectModuleState, (state) =>
  state.get('importReport'),
);

export const selectImportStatus = createSelector(selectModuleState, (state) =>
  state.get('importStatus'),
);

export const selectIsImportModalOpen = createSelector(
  selectModuleState,
  (state) => state.get('isOpen'),
);

export const selectRecipientsRequiredFields = createSelector(
  selectModuleState,
  (state) => state.get('recipientsRequiredFields'),
);

export const selectRecipientsFilter = createSelector(
  selectFormattedRecipients,
  (recipients) =>
    recipients
      .map((recipient) => ({
        id: recipient.get('id'),
        key: recipient.get('id'),
        name: recipient.get('label'),
        label: recipient.get('label'),
      }))
      .toArray(),
);

export const selectSelectedRecipientIds = createSelector(
  selectModuleState,
  (state) => state.get('selectedRecipients'),
);

import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useLocation } from 'react-router-dom';

import { FRANCE } from 'constants/targetMarkets';
import { getOrganizationTargetMarketIds } from 'core/api/user';
import LinkButton from 'core/components/button/link';
import { selectHasMultiTargetMarketFeature } from 'modules/feature-flag/selectors';
import { hasFeatureInformationRequestList } from 'modules/information-requests/utils';
import { hasFeatureProductReview } from 'modules/product-review/utils';
import {
  selectCanCreateProduct,
  selectIsManufacturer,
  selectIsPrivateLabel,
  selectUser,
} from 'modules/user';
import * as routes from 'routes';
import i18n from 'utils/i18n';

import { selectSearch } from '../../product/selectors';
import { fetchAggregations } from '../actions';
import { selectCountDict } from '../selectors';

import { CatalogBody } from './body';
import './index.scss';
import { CatalogMenuTab } from './tab';

const CatalogMenu = () => {
  const location = useLocation();
  const dispatch = useDispatch();
  const countDict: Map<string, any> = useSelector(selectCountDict);
  const canCreateProduct = useSelector<any, boolean>(selectCanCreateProduct);
  const user = useSelector(selectUser);
  const searchQuery = useSelector(selectSearch);
  const hasMultiTargetMarket = useSelector(selectHasMultiTargetMarketFeature);
  const isManufacturer = useSelector(selectIsManufacturer);
  const isPrivateLabel = useSelector(selectIsPrivateLabel);
  const showAssignation =
    (isManufacturer || isPrivateLabel) &&
    (!hasMultiTargetMarket || getOrganizationTargetMarketIds(user).has(FRANCE));
  const showReview = hasFeatureProductReview(user);
  const showRequests = hasFeatureInformationRequestList(user);

  useEffect(() => {
    dispatch(fetchAggregations());
  }, [dispatch]);

  return (
    <div className="CatalogMenu alk-flex alk-flex-center">
      {(isManufacturer || isPrivateLabel) && (
        <div className="CatalogMenu__left alk-flex alk-flex-center">
          {canCreateProduct && (
            <LinkButton
              to={routes.productCreate}
              className="CatalogMenu__createProduct"
              icon="plus-circle"
              secondary
            >
              {i18n.t('Create a product')}
            </LinkButton>
          )}
        </div>
      )}
      <div className="CatalogMenu__tabs alk-flex">
        {showAssignation && (
          <>
            <CatalogMenuTab
              route={routes.catalogAssignation}
              label={i18n.t('Products waiting to be created')}
              count={countDict.get('assignations')}
              isSelected={location.pathname === routes.catalogAssignation}
              showAsUnread
            />
            <i className="CatalogMenu__chevron mdi mdi-chevron-right" />
          </>
        )}
        <CatalogMenuTab
          route={{
            pathname: routes.catalogAll,
            search: `?search=${searchQuery}`,
          }}
          label={i18n.t('All products')}
          isSelected={location.pathname === routes.catalogAll}
        />
        {showReview && (
          <CatalogMenuTab
            route={routes.catalogToReview}
            label={i18n.t('Products to review')}
            isSelected={location.pathname === routes.catalogToReview}
            showAsUnread
            count={countDict.get('review')}
          />
        )}
        {showRequests && (
          <CatalogMenuTab
            route={routes.informationRequestList}
            label={i18n.t(
              'frontproductstream.catalog_menu_tab.information_requests.label',
              {
                defaultValue: 'Requests',
              },
            )}
            isSelected={location.pathname === routes.informationRequestList}
          />
        )}
      </div>
    </div>
  );
};

export { CatalogMenu, CatalogBody };

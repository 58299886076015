import i18n from 'utils/i18n';

export const WorkflowSharingStatuses = {
  DRAFT: {
    name: i18n.t('frontproductstream.constants.workflow_sharing_status.draft', {
      defaultValue: 'Draft',
    }),
    id: 'DRAFT',
  },
  EXPECTED: {
    name: i18n.t(
      'frontproductstream.constants.workflow_sharing_status.expected',
      {
        defaultValue: 'Expected',
      },
    ),
    id: 'EXPECTED',
  },
  RECIPIENT_ADDED: {
    name: i18n.t(
      'frontproductstream.constants.workflow_sharing_status.recipient_added',
      {
        defaultValue: 'Recipient Added',
      },
    ),
    id: 'RECIPIENT_ADDED',
  },
  BLOCKED: {
    name: i18n.t(
      'frontproductstream.constants.workflow_sharing_status.blocked',
      {
        defaultValue: 'Blocked',
      },
    ),
    id: 'BLOCKED',
  },
  SHARED: {
    name: i18n.t(
      'frontproductstream.constants.workflow_sharing_status.shared',
      {
        defaultValue: 'Shared',
      },
    ),
    id: 'SHARED',
  },
};

import PropTypes from 'prop-types';
import { memo, useCallback } from 'react';
import ImmutablePropTypes from 'react-immutable-proptypes';

import { isManufacturer } from 'core/api/user';
import ListTable from 'core/modules/list/components/table';

import {
  ARCHIVED_PRODUCTS,
  ASSIGNATION,
  PRODUCTS,
  PRODUCTS_TO_REVIEW,
  PRODUCTS_WITH_MENU,
} from '../../../constants/context';
import {
  getHeader,
  getId,
  getLabel,
  getSort,
  getWidth,
} from '../../../selectors/referential';

import CatalogEmptyAssignationTable from './empty/assignation';
import { CatalogEmptyClearFiltersTable } from './empty/clear-filters';
import CatalogDefaultEmptyTable from './empty/default';
import CatalogEmptyProductTable from './empty/product';
import CatalogTableRow from './row';

function CatalogTable({
  productList,
  loading,
  referentials,
  columnMap,
  sorting,
  onSort,
  user,
  hasSelectedFilters,
  hasSearchQuery,
  context = PRODUCTS,
  selectedMap,
  productMap,
  onSelectProduct,
}) {
  const getSortingAsc = useCallback((s) => s.get('asc'), []);

  if (productList.size === 0 && !loading) {
    if (hasSearchQuery && hasSelectedFilters) {
      return <CatalogEmptyClearFiltersTable />;
    }
    if (!(hasSearchQuery || hasSelectedFilters) && isManufacturer(user)) {
      switch (context) {
        case PRODUCTS:
        case PRODUCTS_WITH_MENU:
        case PRODUCTS_TO_REVIEW:
        case ARCHIVED_PRODUCTS:
          return <CatalogEmptyProductTable />;
        case ASSIGNATION:
          return <CatalogEmptyAssignationTable />;
        default:
      }
    }
    return <CatalogDefaultEmptyTable />;
  }

  if (referentials.size === 0) {
    return null;
  }

  return (
    <ListTable
      referentials={referentials}
      columnMap={columnMap}
      sorting={sorting}
      onSort={onSort}
      getReferentialId={getId}
      getReferentialSort={getSort}
      getReferentialWidth={getWidth}
      getReferentialLabel={getLabel}
      getReferentialHeader={getHeader}
      getSortingId={getId}
      getSortingAsc={getSortingAsc}
      cellsOnLeft={context.emptyCells.left}
      cellsOnRight={context.emptyCells.right}
    >
      {productList.map((productId) => (
        <CatalogTableRow
          key={`catalog-table-row-${productId}`}
          user={user}
          productVersion={productMap.get(productId)}
          referentials={referentials}
          columnMap={columnMap}
          selected={selectedMap.get(productId)}
          context={context}
          onSelect={onSelectProduct}
        />
      ))}
    </ListTable>
  );
}

CatalogTable.propTypes = {
  user: ImmutablePropTypes.map.isRequired,
  productList: ImmutablePropTypes.list.isRequired,
  productMap: ImmutablePropTypes.map.isRequired,
  selectedMap: ImmutablePropTypes.map.isRequired,
  columnMap: ImmutablePropTypes.map.isRequired,
  referentials: ImmutablePropTypes.list.isRequired,
  sorting: ImmutablePropTypes.map,
  loading: PropTypes.bool,
  onSelectProduct: PropTypes.func.isRequired,
  onSort: PropTypes.func.isRequired,
  hasSelectedFilters: PropTypes.bool.isRequired,
  hasSearchQuery: PropTypes.bool.isRequired,
  context: PropTypes.shape({
    type: PropTypes.string,
    emptyCells: PropTypes.shape({
      left: PropTypes.oneOfType([PropTypes.number, PropTypes.array]),
      right: PropTypes.oneOfType([PropTypes.number, PropTypes.array]),
    }).isRequired,
  }).isRequired,
};

export default memo(CatalogTable);

import i18n from 'utils/i18n';

export const MODULE_NAME = 'catalog-requests-actions';

// Type modal
export const MODAL_TO_RECONCILE = 'toReconcile';
export const MODAL_FALLBACK = 'fallback';
export const MODAL_REFUSED_BY_RECIPIENT = 'refusedByRecipient';
export const MODAL_CHAT_RECIPIENT = 'chatRecipient';
export const MODAL_CHAT_SENT = 'chatSent';
export const LIST_IR_MODALS = [
  MODAL_TO_RECONCILE,
  MODAL_FALLBACK,
  MODAL_REFUSED_BY_RECIPIENT,
  MODAL_CHAT_RECIPIENT,
  MODAL_CHAT_SENT,
];

export const defaultUserMessage = i18n.t(
  'Hello, \nCan you please give us more information?\n\nThank you.',
);

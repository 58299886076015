export const POLL_IMPORT_REPORT = 'import-modal/poll-import-report';
export const POLL_GENERIC_IMPORT_REPORT =
  'import-modal/poll-generic-import-report';
export const CANCEL_POLL_IMPORT_REPORT =
  'import-modal/cancel-poll-import-report';
export const RECEIVE_IMPORT_REPORT = 'import-modal/receive-import-report';
export const OPEN_MODAL = 'import-modal/open';
export const CLOSE_MODAL = 'import-modal/close';

export const RECEIVE_RECIPIENTS_REQUIRED_FIELDS =
  'import-modal/RECEIVE_RECIPIENTS_REQUIRED_FIELDS';
export const ADD_RECIPIENT = 'import-modal/ADD_RECIPIENT';
export const REMOVE_RECIPIENT = 'import-modal/REMOVE_RECIPIENT';

import classNames from 'classnames';
import { get } from 'lodash';
import { useEffect, useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { Button } from '@alkem/react-ui-button';

import {
  listAllAssets as listAllAssetsToDispatch,
  updateNewPackshot,
} from 'actions/media';
import { SETTING_ENABLE_VALUE } from 'constants/organization-settings';
import * as sharedStatusConstants from 'constants/sharedStatus';
import { getLastShareStatus } from 'core/api/retailerproductversion';
import { loadReferentials } from 'modules/assets/actions';
import { selectReferentials } from 'modules/assets/selectors';
import { hasFeatureValue } from 'modules/feature-flag';
import { FEATURE_PRODUCT_DOCUMENTS } from 'modules/feature-flag/constants';
import { selectHasReleaseProductpageFlywheelMedia } from 'modules/feature-flag/selectors';
import { getAll as getAllRecipients } from 'modules/recipients/reducers';
import { selectUser } from 'modules/user';
import { applyRulesForViewAsRecipients } from 'modules/validation';
import {
  selectAssetExpiredCount,
  selectDocuments,
  selectEnrichedContents,
  selectPictures,
  selectVideos,
} from 'reducers/media';
import { selectProductKeyId } from 'reducers/productVersion';
import { DispatchType } from 'types';
import i18n from 'utils/i18n';
import { sortDesc } from 'utils/sort';

import MediaErrors from '../media-errors';

import { AddMediaDropdown } from './AssetV2/AddMediaDropdown';
import {
  AssetTypeV2,
  DocumentAssetV2,
  Entity,
  Field,
  PictureAssetV2,
  ProductVideoAssetV2,
  RecipeAssetV2,
} from './AssetV2/types';
import Actions from './actions';
import Asset from './asset';
import './asset-list.scss';
import { SeeEditAllAsset } from './asset/modals';
import AssetListButtonsBar from './buttons-bar';
import { AssetContext } from './context';

type AssetListProps = {
  entity: Entity;
  entityKind: string;
  entityId: number;
  field: Field;
  hasProductUpdatePermission: boolean;
};

export const AssetList = ({
  entity,
  entityKind,
  entityId,
  field,
  hasProductUpdatePermission,
}: AssetListProps) => {
  const [withExpired, setWithExpired] = useState(false);
  const [newPackshotId, setNewPackshotId] = useState<number | null>(null);
  const [selectedPictureIds, setSelectedPictureIds] = useState<number[]>([]);
  const [isSeeAndEditAllMediaModalOpen, setIsSeeAndEditAllMediaModalOpen] =
    useState(false);

  const referenceNode = useRef(null);

  const referentials = useSelector(selectReferentials);
  const productKeyId = useSelector(selectProductKeyId);
  const pictures: PictureAssetV2[] = useSelector(selectPictures);
  const docs: DocumentAssetV2[] = useSelector(selectDocuments);
  const videos: ProductVideoAssetV2[] = useSelector(selectVideos);
  const enrichedcontents: RecipeAssetV2[] = useSelector(selectEnrichedContents);
  const user = useSelector(selectUser);
  const hasReleaseProductpageFlywheelMedia = useSelector(
    selectHasReleaseProductpageFlywheelMedia,
  );
  const recipients = useSelector(getAllRecipients);
  const nbOfPictureExpired = useSelector(selectAssetExpiredCount);

  const hasProductDocument = hasFeatureValue(
    user,
    FEATURE_PRODUCT_DOCUMENTS,
    SETTING_ENABLE_VALUE,
  );

  const dispatch: DispatchType = useDispatch();

  useEffect(() => {
    // Do not show asset list before referentials are loaded
    if (!referentials) {
      dispatch(loadReferentials());
    }
  }, [dispatch, referentials]);

  const onPictureSelectionChange = (pictureId, selected) => {
    if (selected) {
      setSelectedPictureIds([...selectedPictureIds, pictureId]);
    } else {
      setSelectedPictureIds(
        selectedPictureIds.filter((id) => pictureId !== id),
      );
    }
  };

  const settingNewPackshot = (
    newPackshot: { newPackshotId: number | null } = { newPackshotId: null },
  ) => {
    if (
      selectedPictureIds.some(
        (pictureId) => pictureId === newPackshot.newPackshotId,
      )
    ) {
      setNewPackshotId(newPackshot.newPackshotId);
      setSelectedPictureIds(
        selectedPictureIds.filter(
          (pictureId) => pictureId !== newPackshot.newPackshotId,
        ),
      );
    } else {
      setNewPackshotId(newPackshotId);
    }
  };

  const listAllAssets = (withLastRequests, localWithExpired) => {
    return dispatch(
      listAllAssetsToDispatch({
        with_last_requests: withLastRequests,
        with_expired: localWithExpired,
        product_key_id: productKeyId,
      }),
    );
  };

  const statusIsPending = (localEntity: Entity) => {
    const sharingStatus = getLastShareStatus(localEntity.source);
    return !!(
      sharingStatus && sharingStatus.id === sharedStatusConstants.pending.id
    );
  };

  const updateEntities = async (
    mediaType?: string,
    assetId: number | null = null,
  ) => {
    // For performances, we do not want to call picturelist if update on packshot only
    // We simply update store if call succeded
    if (assetId !== null && mediaType === 'ProductPicture') {
      dispatch(updateNewPackshot(assetId));
    } else {
      await listAllAssets(statusIsPending(entity), withExpired);
      setSelectedPictureIds((currentSelectedPictureIds) => {
        const localSelectedPictureIds = currentSelectedPictureIds.filter(
          (pictureId) => pictures.some(({ id }) => id === pictureId),
        );
        if (
          currentSelectedPictureIds.length !== localSelectedPictureIds.length
        ) {
          return localSelectedPictureIds;
        }

        return currentSelectedPictureIds;
      });
    }
    dispatch(applyRulesForViewAsRecipients());
  };

  const areAssetReadOnly = (assetType) => {
    return !!(
      field &&
      field.options &&
      (field.options.readOnly || get(field.options, `${assetType}.readOnly`))
    );
  };

  const arePicturesReadOnly = () => areAssetReadOnly('picture');
  const areVideosReadOnly = () => areAssetReadOnly('video');
  const areDocumentsReadOnly = () => areAssetReadOnly('document');
  const areRecipesReadOnly = () => areAssetReadOnly('recipe');

  const filterExpiredMedia = () => {
    listAllAssets(statusIsPending(entity), !withExpired);
    setWithExpired(!withExpired);
    setSelectedPictureIds([]);
  };

  const sortMedia = () => {
    // We want to have the following order for media:
    // packshot - videos - recipes - pictures - documents - archived pictures

    const mapIndex = (_assets: AssetTypeV2[]) =>
      _assets.map((asset, index) => ({ ...asset, index }));

    let assets = mapIndex(pictures)
      .concat(mapIndex(videos))
      .concat(mapIndex(enrichedcontents));

    if (hasProductDocument) {
      assets = assets.concat(mapIndex(docs));
    }

    const toComparable = (asset) => {
      const packshot = asset.isPackshot ? 1 : 0;
      let archived = 1;
      if (
        asset.fileEffectiveStartDateTime &&
        asset.fileEffectiveStartDateTime > Date.now()
      ) {
        archived = 0;
      }
      if (
        asset.fileEffectiveEndDateTime &&
        archived !== 0 &&
        asset.fileEffectiveEndDateTime < Date.now()
      ) {
        archived = 0;
      }
      const date = asset.createdAt ? new Date(asset.createdAt) : new Date();
      return `${packshot}-${archived}-${date.toISOString()}`;
    };

    return assets.sort((a, b) => sortDesc(toComparable(a), toComparable(b)));
  };

  const renderButtonsBar = () => {
    const items: AssetTypeV2[] = [...pictures, ...videos, ...enrichedcontents];

    return (
      <AssetListButtonsBar
        productKeyId={productKeyId}
        pictureReadOnly={arePicturesReadOnly()}
        statusIsPending={statusIsPending(entity)}
        selectedPictureIds={selectedPictureIds}
        updateEntities={updateEntities}
        disableDownloadPictures={items.length === 0}
        recipients={recipients}
      />
    );
  };

  const renderList = () => {
    if (!referentials) {
      return null;
    }
    const items = sortMedia();

    const renderedItems = items.map((item) =>
      hasReleaseProductpageFlywheelMedia ? (
        <Asset
          entityId={entityId}
          asset={item}
          productKeyId={productKeyId}
          updateEntities={updateEntities}
          actions={field.actions}
          options={field.options}
          settingNewPackshot={settingNewPackshot}
          newPackshotId={newPackshotId}
          selectedPictureIds={selectedPictureIds}
          onPictureSelectionChange={onPictureSelectionChange}
          field={field}
          hasUpdatePermission={hasProductUpdatePermission}
          index={item.index}
          key={`${item._type}-${item.id}`}
        />
      ) : (
        <div className="col-xs-4" key={`${item._type}-${item.id}`}>
          <Asset
            entityId={entityId}
            asset={item}
            productKeyId={productKeyId}
            updateEntities={updateEntities}
            actions={field.actions}
            options={field.options}
            settingNewPackshot={settingNewPackshot}
            newPackshotId={newPackshotId}
            selectedPictureIds={selectedPictureIds}
            onPictureSelectionChange={onPictureSelectionChange}
            field={field}
            hasUpdatePermission={hasProductUpdatePermission}
            index={item.index}
          />
        </div>
      ),
    );

    return (
      <AssetContext.Provider value={{ formGroup: field, entityId }}>
        {hasReleaseProductpageFlywheelMedia && (
          <AddMediaDropdown
            items={items}
            updateEntities={updateEntities}
            fieldActions={field.actions}
            productKeyId={productKeyId}
          />
        )}
        <div
          className={classNames({
            'AssetList__list-rfp': hasReleaseProductpageFlywheelMedia,
            'row AssetList__list AssetList__row':
              !hasReleaseProductpageFlywheelMedia,
          })}
        >
          {!hasReleaseProductpageFlywheelMedia && (
            <Actions
              productKeyId={productKeyId}
              actions={field.actions}
              updateEntities={updateEntities}
              items={items}
            />
          )}
          {renderedItems}
        </div>
      </AssetContext.Provider>
    );
  };

  const renderErrors = () => {
    return (
      <MediaErrors
        entityId={entityId}
        entityKind={entityKind}
        hasUpdatePermission={hasProductUpdatePermission}
        picturesReadOnly={arePicturesReadOnly()}
        videosReadOnly={areVideosReadOnly()}
        documentsReadOnly={areDocumentsReadOnly()}
        recipesReadOnly={areRecipesReadOnly()}
        value={field}
        withContactBlock
      />
    );
  };

  if (!entity || !field) {
    return null;
  }

  return (
    <div
      ref={referenceNode}
      className="form-group card card-block AssetList__container"
    >
      {renderButtonsBar()}
      {!hasReleaseProductpageFlywheelMedia && renderErrors()}
      {renderList()}
      {hasReleaseProductpageFlywheelMedia && renderErrors()}

      {!hasReleaseProductpageFlywheelMedia && nbOfPictureExpired > 0 && (
        <Button
          content={
            withExpired
              ? i18n.t(
                  'frontproductstream.asset_list.hide_archived_button.label',
                  { defaultValue: 'Hide archived media' },
                )
              : i18n.t(
                  'frontproductstream.asset_list.show_archived_button.label',
                  { defaultValue: 'Display archived media' },
                )
          }
          onClick={filterExpiredMedia}
          className="AssetList__archivedButton btn-link"
        />
      )}

      {hasReleaseProductpageFlywheelMedia && (
        <>
          <Button link onClick={() => setIsSeeAndEditAllMediaModalOpen(true)}>
            <span>
              {i18n.t(
                'frontproductstream.asset_list.see_all_media_button.label',
                {
                  defaultValue: 'See and edit all media',
                },
              )}
            </span>
            <i className="mdi mdi-24px mdi-arrow-right" />
          </Button>
          {isSeeAndEditAllMediaModalOpen && (
            <AssetContext.Provider value={{ formGroup: field, entityId }}>
              <SeeEditAllAsset
                productKeyId={productKeyId}
                onClose={() => setIsSeeAndEditAllMediaModalOpen(false)}
                updateEntities={updateEntities}
                isShowingExpiredMedia={withExpired}
                items={sortMedia()}
                onToggleArchived={filterExpiredMedia}
                expiredPictureCount={nbOfPictureExpired}
                statusIsPending={statusIsPending(entity)}
              />
            </AssetContext.Provider>
          )}
        </>
      )}
    </div>
  );
};

export default AssetList;

import moment from 'moment';
import { MouseEvent, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { Button } from '@alkem/react-ui-button';
import { LazyTooltip } from '@alkem/react-ui-tooltip';

import i18n from 'utils/i18n';

import {
  productAdminDeleteAuditDatas,
  productAdminGetAuditContext,
} from '../../actions';
import { selectAuditContext } from '../../selectors';
import {
  AuditContext as AuditContextType,
  Audit as AuditType,
} from '../../types';

import { AuditContext } from './audit-context';

interface Props {
  audit: AuditType;
  productKeyId: string;
}

export const Audit = ({ audit, productKeyId }: Props) => {
  const dispatch = useDispatch();
  const auditsContext: AuditContextType = useSelector(selectAuditContext);
  const startUtcTime = moment.utc(audit.start_time);
  const endUtcTime = moment.utc(audit.end_time);
  const nowUtcTime = moment.utc();

  const startTime = startUtcTime.local().format('LLL');
  const endTime = endUtcTime.local().format('LLL');

  const isActive = nowUtcTime.isBetween(
    startUtcTime,
    endUtcTime,
    undefined,
    '[]',
  );

  const [isOpen, setOpen] = useState(false);
  const icon = isOpen ? 'mdi-chevron-up' : 'mdi-chevron-down';
  const contextDatas = auditsContext[audit.id];

  const getAuditContexts = () => {
    dispatch(productAdminGetAuditContext(audit.id.toString()));
  };

  const toggleContextView = () => {
    if (!isOpen) {
      getAuditContexts();
    }
    setOpen(!isOpen);
  };

  const refreshAuditContexts = (e: MouseEvent) => {
    if (isOpen) {
      getAuditContexts();
    }
    e.stopPropagation();
  };

  const deleteAudit = () => {
    dispatch(
      productAdminDeleteAuditDatas({
        auditID: audit.id,
        productKeyId: productKeyId,
      }),
    );
  };

  return (
    <div
      key={'audit-' + audit.id}
      className="ProductAdminAudit__element"
      data-testid="product-admin-audit-data-container"
    >
      <div
        className="alk-flex alk-flex-row alk-flex-space-between ProductAdminAudit__elementHeader"
        onClick={toggleContextView}
      >
        <div className="alk-flex alk-flex-row alk-flex-center alk-flex-space-between">
          <i
            className={`ProductAdminAudit__elementAuditCollapsedIcon mdi ${icon}`}
          />
          <div className="ProductAdminAudit__elementAuditTitle">{audit.id}</div>
        </div>
        <div className="alk-flex alk-flex-row alk-flex-center">
          <div>
            <div>
              <b>
                {i18n.t(
                  'frontproductstream.product_admin.audit_started_at.text',
                  {
                    defaultValue: 'Started at:',
                  },
                )}
              </b>{' '}
              {startTime}
            </div>
            <div>
              <b>
                {i18n.t(
                  'frontproductstream.product_admin.audit_ended_at.text',
                  {
                    defaultValue: 'Ended at:',
                  },
                )}
              </b>{' '}
              {endTime}
            </div>
          </div>
          {isActive && (
            <LazyTooltip
              id="product-admin-refresh-audit-tooltip"
              tooltipLabel={i18n.t(
                'frontproductstream.product_admin.audit_refresh.button',
                {
                  defaultValue: 'Audit in progress, refresh contexts list',
                },
              )}
            >
              <Button
                id="product-admin-refresh-audit-button"
                className="ProductAdminAudit__elementAuditRefreshButton"
                testid="refresh-audit-button"
                onClick={refreshAuditContexts}
                primary
              >
                <i className="mdi mdi-reload" />
              </Button>
            </LazyTooltip>
          )}
          {!isActive && (
            <Button
              id="product-admin-delete-audit-button"
              testid="delete-audit-button"
              className="ProductAdminAudit__elementAuditDeleteButton"
              warning
              onClick={(e) => {
                deleteAudit();
                e.stopPropagation();
              }}
            >
              <i className={'mdi mdi-delete'} />
            </Button>
          )}
        </div>
      </div>

      {isOpen &&
        !!contextDatas &&
        contextDatas.map((context) => (
          <AuditContext
            auditID={audit.id}
            contextDatas={context}
            key={'key-audit-data-context-' + context.id}
          />
        ))}
      {isOpen && contextDatas && contextDatas.length === 0 && (
        <div
          className="alk-flex alk-flex-row alk-flex-space-between ProductAdminAudit__elementContext ProductAdminAudit__elementContextEmpty"
          data-testid="audit-context-empty-datas"
        >
          <div>
            {i18n.t(
              'frontproductstream.product_admin.audit_without_contexts.text',
              {
                defaultValue: 'No contexts data',
              },
            )}
          </div>
        </div>
      )}
    </div>
  );
};

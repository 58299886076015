/* eslint-disable @typescript-eslint/ban-ts-comment */
import 'alloyeditor/dist/alloy-editor/assets/alloy-editor-ocean-min.css';
import 'bootstrap/dist/css/bootstrap.css';
import classnames from 'classnames';
import 'react-daterange-picker/src/css/react-calendar.scss';

import * as sdkDashboard from '@alkem/sdk-dashboard';

import 'styles/vendors.scss';

window.global = window;

for (const [name, lib] of [
  ['@alkem/sdk-dashboard', sdkDashboard],
  ['classnames', classnames],
]) {
  // @ts-ignore
  System.register(name, [], (_export) => ({
    execute: () => {
      _export(lib);
    },
  }));
}

import PropTypes from 'prop-types';
import { Component } from 'react';
import ImmutablePropTypes from 'react-immutable-proptypes';

import {
  getOrganizationId,
  isAdmin,
  isManufacturer,
  isPrivateLabel,
} from 'core/api/user';
import { selectHasHierarchyPlan } from 'modules/feature-flag/selectors';
import ProductSegmentsModal from 'modules/product-segments-modal';
import i18n from 'utils/i18n';

export default class ProductSegmentsAction extends Component {
  static propTypes = {
    user: ImmutablePropTypes.map.isRequired,
    selectedMap: ImmutablePropTypes.map.isRequired,
    productMap: ImmutablePropTypes.map.isRequired,
    disabled: PropTypes.bool,
  };

  static shouldBeDisplayed = ({ user }) =>
    (isManufacturer(user) || isPrivateLabel(user)) &&
    isAdmin(user) &&
    selectHasHierarchyPlan(user);

  state = {
    isModalOpen: false,
  };

  onClick = () => {
    const { disabled } = this.props;
    if (disabled) {
      return;
    }
    this.setState({ isModalOpen: true });
  };

  onClose = () => {
    this.setState({ isModalOpen: false });
  };

  render() {
    const { user, selectedMap, productMap } = this.props;
    const productVersions = selectedMap
      .map((isSelected, pvId) => productMap.get(pvId))
      .toList();
    return (
      <>
        <div className="ActionOption" onClick={this.onClick}>
          {i18n.t('Move {{count}} product to category', {
            count: selectedMap.size,
          })}
        </div>
        {this.state.isModalOpen && (
          <ProductSegmentsModal
            organizationId={getOrganizationId(user)}
            checkedProducts={productVersions}
            onClose={this.onClose}
          />
        )}
      </>
    );
  }
}

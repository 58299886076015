import { createSelector } from '@reduxjs/toolkit';
import { List, Map } from 'immutable';

import {
  selectFailedFetching as selectFailedFetchingPublicationSummary,
  selectGroups,
  selectIsLoading as selectIsLoadingPublicationSummary,
} from 'modules/publication-summary/selectors';
import { isGroupAssigned } from 'modules/publication-summary/utils';
import { selectIsLoading as selectIsLoadingRecipients } from 'modules/recipients/reducers';
import { sharingUnitsByRetailerId as selectSharingUnitsByRetailerId } from 'modules/sharing-units/selectors';
import { selectHasAssortmentManagement } from 'modules/user';
import { selectShares } from 'reducers/productVersion';
import { compareNames } from 'utils';

import { isPrimaryRecipient } from '../utils';

export function selectState(state) {
  return state.viewAs;
}

export const selectIsFetchingRecipients = createSelector(selectState, (state) =>
  state.get('loading'),
);

export const selectCurrentRecipients = createSelector(selectState, (state) =>
  state.get('recipients'),
);

const selectShouldFilterRecipientsOnGroups = createSelector(
  selectHasAssortmentManagement,
  (hasAssortment) => hasAssortment,
);

export const selectFilteredRecipients = createSelector(
  selectCurrentRecipients,
  selectGroups,
  selectFailedFetchingPublicationSummary,
  (recipients, groups: List<any>, failedFetchingPublicationSummary) => {
    if (!failedFetchingPublicationSummary && groups) {
      const allMemberIds = groups
        .filter(isGroupAssigned) // Because admins and local admins receive all the groups
        .map((g) => g.get('members').map((m) => m.get('id')))
        .flatten();

      recipients = recipients.filter(
        (r) => allMemberIds.size === 0 || allMemberIds.includes(r.get('id')),
      );
    }
    return recipients;
  },
);

export const selectRecipients = createSelector(
  selectCurrentRecipients,
  selectShouldFilterRecipientsOnGroups,
  selectFilteredRecipients,
  (recipients, shouldFilterRecipientsOnGroups, filteredRecipients) => {
    if (shouldFilterRecipientsOnGroups) {
      recipients = filteredRecipients;
    }
    return recipients;
  },
);

export const selectRecipientIds = createSelector(
  selectRecipients,
  (recipients) => recipients.map((r) => r.get('id')),
);

const selectActiveRangeRecipientIds = createSelector(selectShares, (shares) =>
  shares
    .filter((share) => share.get('isActive'))
    .map((s) => s.getIn(['targetOrganization', 'id'])),
);

const selectListingRecipientIds = createSelector(
  selectSharingUnitsByRetailerId,
  (sharingUnitsByRetailerId: Map<string, any>) =>
    sharingUnitsByRetailerId.keySeq(),
);

export const selectPrimaryRecipients = createSelector(
  selectRecipients,
  selectActiveRangeRecipientIds,
  selectListingRecipientIds,
  selectShares,
  (recipients, activeRangeRecipientIds, listingRecipientIds, shares) =>
    recipients
      .filter((r) =>
        isPrimaryRecipient(
          r,
          activeRangeRecipientIds,
          listingRecipientIds,
          shares,
        ),
      )
      .sort(compareNames),
);

export const selectSecondaryRecipients = createSelector(
  selectRecipients,
  selectActiveRangeRecipientIds,
  selectListingRecipientIds,
  selectShares,
  (recipients, activeRangeRecipientIds, listingRecipientIds, shares) =>
    recipients
      .filter(
        (r) =>
          !isPrimaryRecipient(
            r,
            activeRangeRecipientIds,
            listingRecipientIds,
            shares,
          ),
      )
      .sort(compareNames),
);

export const selectPreselectedRecipients = createSelector(
  selectState,
  (state) => state.get('preselectedRecipients'),
);

export const selectPreselectedRuleSetIds = createSelector(
  selectState,
  (state) => state.get('preselectedRuleSetIds'),
);

export const selectSelectedRecipients = createSelector(
  selectRecipients,
  (allRecipients) => allRecipients.filter((r) => r.get('checked')),
);

export const selectSelectedRecipientIds = createSelector(
  selectSelectedRecipients,
  (selectedRecipients) =>
    selectedRecipients && selectedRecipients.size
      ? selectedRecipients.map((r) => r.get('id')).toJS()
      : null,
);

export const selectRuleSets = createSelector(selectState, (state) =>
  state.get('ruleSets'),
);

export const selectFilterRuleSetsFields = createSelector(selectState, (state) =>
  state.get('filterRuleSetsFields'),
);

export const selectIsLoading = createSelector(
  selectIsLoadingRecipients,
  selectShouldFilterRecipientsOnGroups,
  selectIsLoadingPublicationSummary,
  (
    isLoadingRecipients,
    shouldFilterRecipientsOnGroups,
    isLoadingPublicationSummary,
  ) =>
    isLoadingRecipients ||
    (shouldFilterRecipientsOnGroups && isLoadingPublicationSummary),
);

import { number, object, oneOfType, shape, string } from 'prop-types';

export * from './autocomplete';
export * from './filters';
export * from './list';
export * from './notification';
export * from './organization';
export * from './product';
export * from './recipients';
export * from './redux';
export * from './targetMarkets';
export * from './user';

export const StringOrNumber = oneOfType([string, number]);

export const Location = shape({
  pathname: string.isRequired,
  search: string.isRequired,
  hash: string.isRequired,
});

export const Match = shape({
  url: string.isRequired,
  params: object.isRequired,
});

type State = any; // application state

export type Selector<T> = (state: State) => T;

export type DeepPartial<T> = {
  [P in keyof T]?: DeepPartial<T[P]>;
};

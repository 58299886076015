import { Map } from 'immutable';
import { Link } from 'react-router-dom';

import TextCell from 'core/modules/list/components/table/cell/text';
import * as routes from 'routes';
import { get } from 'utils/immutable';
import { fill } from 'utils/routing';

export default function CompanyProfileColumn({
  data,
}: {
  data: Map<'id' | 'name' | 'uuid', any>;
}) {
  if (!data) {
    return null;
  }
  return (
    <TextCell className="CompanyProfileColumn">
      <Link to={fill(routes.companyProfile, get(data, 'uuid'))}>
        {get(data, 'name')}
      </Link>
    </TextCell>
  );
}

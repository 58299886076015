import { registerModuleReducer } from 'utils/modules/reducer';
import { registerSaga } from 'utils/modules/saga';

import ProductSuggestions from './product-suggestions';
import reducer from './reducer';
import mainSaga from './sagas';

registerModuleReducer('productSuggestions', reducer);
registerSaga(mainSaga);

export default ProductSuggestions;

import { put } from 'redux-saga/effects';

import { fetchDisplayGroups } from 'modules/display-groups/actions';

export function* handleFormFieldUpdate({ payload: model }) {
  if (
    model === 'typePackaging' ||
    model === 'kind' ||
    model === 'isConsumerUnit' ||
    model === 'isDisplayUnit' ||
    model === 'isBaseUnit'
  ) {
    yield put(fetchDisplayGroups());
  }
}

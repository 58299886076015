export const getLocation = () => window.location;
export const getHostname = () => getLocation().hostname;
export const getHost = () => getLocation().host;
export const reload = () => getLocation().reload();
export const getOrigin = () => getLocation().origin;
export const getHash = (removeHashTag?: boolean) => {
  const hash = getLocation().hash;
  return removeHashTag ? hash.replace(/^#/, '') : hash;
};

export const getAppHostname = () =>
  /salsify/i.test(getHostname()) ? 'supplierxm.salsify.com' : 'alkemics.com';

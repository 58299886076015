import { Map, fromJS } from 'immutable';

import {
  displayNameFallback,
  getLanguageValueFromMultiple,
  getTargetMarketId,
} from 'core/api/productversion';
import { getLocalesByTargetMarket } from 'core/api/user';
import { UserImmutable } from 'types';
import { toJS } from 'utils';

export type ProductVersion = Map<'id' | string, unknown>;

export type ProductsMap = Map<number, ProductVersion>;

const unshareableShareStatuses = [
  'DRAFT',
  'PENDING',
  'ACCEPTED',
  'ARCHIVED',
  'PAUSED',
];

const getShareTargetOrganizationId = (recipient) =>
  recipient.getIn(['share', 'targetOrganization', 'id']);

const shareStatusAllowsUnshare = (recipient) =>
  unshareableShareStatuses.includes(
    recipient.getIn(['share', 'status', 'code'], ''),
  );

export const retailerIDsForUnshare = (
  pv: ProductVersion | undefined,
): number[] => {
  if (!pv) return toJS([]);
  const recipients = pv.getIn(['alkemics', 'recipients'], fromJS([]));
  const recipientsShared = recipients.filter(
    (recipient) =>
      shareStatusAllowsUnshare(recipient) &&
      getShareTargetOrganizationId(recipient),
  );

  const retailerIDs = recipientsShared.map(getShareTargetOrganizationId);

  return retailerIDs.toJS();
};

export const isUnshareable = (pv: ProductVersion | undefined) =>
  !!retailerIDsForUnshare(pv).length;

export const pvIsSharedWith =
  (recipientId: number) =>
  (pv: ProductVersion | undefined): boolean => {
    if (!pv) return false;
    const recipients = pv.getIn(['alkemics', 'recipients']);
    return recipients.find(
      (r) => r.getIn(['targetOrganization', 'id']) === recipientId,
    );
  };

export const displayName = (pv: ProductVersion, user: UserImmutable) => {
  const targetMarketId = getTargetMarketId(pv);
  const locales = getLocalesByTargetMarket(user).get(String(targetMarketId));
  const namePublicLong = pv.get('namePublicLong') as Map<string, unknown>;
  const { data } = getLanguageValueFromMultiple(namePublicLong, locales);

  let name: string;
  if (data) {
    name = data;
  } else {
    name = displayNameFallback;
  }
  return name;
};

import { useCallback, useEffect, useRef } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
  Navigate,
  Route,
  Routes,
  useLocation,
  useParams,
} from 'react-router-dom';

import { deleteProductVersion } from 'actions/productversion';
import { useTargetMarket } from 'hooks/useTargetMarket';
import CoreLayout from 'layouts/CoreLayout';
import { closeAllFormGroups } from 'modules/display-groups/actions';
import { FEATURE_PERMISSIONS_V3_PRODUCT } from 'modules/feature-flag/constants';
import { ProductHistory } from 'modules/history';
import { ProductInsights } from 'modules/insights';
import { reset as resetHierarchies } from 'modules/logistical-hierarchies/actions';
import { hasPermissionV3 } from 'modules/permissions';
import { ProductReview } from 'modules/product-review';
import {
  PRODUCT_CHAT,
  PRODUCT_DASHBOARD,
  PRODUCT_HISTORY,
  PRODUCT_PAGE,
} from 'modules/redirect';
import { StatusHistory } from 'modules/status-history';
import {
  selectHasLegacyTextile,
  selectHasOnlyExclusiveProducts,
  selectIsEligibleForSourcing,
  selectIsSuperAdminLoggedAs,
  selectIsThirdParty,
  selectUser,
} from 'modules/user';
import { selectHasLoadingFailed } from 'reducers/navigation';
import {
  selectHasPermissions,
  selectHasSource,
  selectIsPendingExclusivity,
  selectTextileModelProductKeyId,
} from 'reducers/productVersion';
import * as routes from 'routes';
import { isProduction } from 'utils';
import qs, { withQuery } from 'utils/query';
import { fill, relativeLaxPath } from 'utils/routing';

import {
  fetchProductDataByKeyId,
  fetchProductDataByUuid,
  saveVisibility,
  setViewAs,
} from '../actions';
import ProductErrorPage from '../components/error-page';
import ProductChat from '../components/product-chat';
import ProductDashboard from '../components/product-dashboard';
import ProductHeader from '../components/product-header';
import ProductPage from '../components/product-page';
import ProductPageSpinner from '../components/spinner';
import VisibilityModal from '../components/visibility-modal';
import ProductAdmin from '../modules/product-admin';
import { selectIsSettingVisibility } from '../selectors';

export function ProductView() {
  const user = useSelector(selectUser);
  const hasLoadingFailed: boolean = useSelector(selectHasLoadingFailed);
  const hasPermissions: boolean = useSelector(selectHasPermissions);
  const hasSource: boolean = useSelector(selectHasSource);
  const isThirdParty: boolean = useSelector(selectIsThirdParty);
  const isPendingExclusivity: boolean = useSelector(selectIsPendingExclusivity);
  const isSettingVisibility: boolean = useSelector(selectIsSettingVisibility);
  const isEligibleForSourcing: boolean = useSelector(
    selectIsEligibleForSourcing,
  );
  const hasLegacyTextile: boolean = useSelector(selectHasLegacyTextile);
  const isSuperAdminLoggedAs: boolean = useSelector(selectIsSuperAdminLoggedAs);
  const hasOnlyExclusiveProducts: boolean = useSelector(
    selectHasOnlyExclusiveProducts,
  );
  const textileModelProductKeyId: any = useSelector(
    selectTextileModelProductKeyId,
  );

  const dispatch = useDispatch();
  const params = useParams<{
    productKeyId?: string;
    reference?: string;
    uuid?: string;
  }>();
  const { productKeyId, reference, uuid } = params;
  const location = useLocation();
  const targetMarket = useTargetMarket();

  const searchRef = useRef(location.search);
  const targetMarketRef = useRef(targetMarket);
  const referenceRef = useRef(reference);
  const productKeyIdRef = useRef(productKeyId);

  const { viewAs, ruleSetIds } = qs.parse(location.search);

  // redirect on new product page if it's an old one
  const shouldRedirectWithLegacyReference = 'reference' in params;

  const updateViewAs = () => {
    dispatch(setViewAs(viewAs, ruleSetIds));
  };

  const fetchProductData = (withDeleteOld = false) => {
    if (productKeyId) {
      dispatch(fetchProductDataByKeyId(productKeyId, withDeleteOld));
    } else if (uuid) {
      dispatch(fetchProductDataByUuid(uuid, withDeleteOld));
    }
  };

  const updateVisibility = useCallback(
    (exclusive, allCatalog) => dispatch(saveVisibility(exclusive, allCatalog)),
    [dispatch],
  );

  useEffect(
    () => () => {
      // on unmount
      dispatch(deleteProductVersion());
      dispatch(resetHierarchies());
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [],
  );

  useEffect(
    () => {
      // on mount
      if (!shouldRedirectWithLegacyReference) {
        updateViewAs();
        fetchProductData();
        dispatch(closeAllFormGroups());
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [],
  );

  useEffect(
    () => {
      // on did update: update view as
      if (searchRef.current !== location.search) {
        updateViewAs();
        searchRef.current = location.search;
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [location.search],
  );

  useEffect(
    () => {
      // on did update: update product data
      if (
        targetMarketRef.current !== targetMarket ||
        referenceRef.current !== reference ||
        productKeyIdRef.current !== productKeyId
      ) {
        fetchProductData(true);
        targetMarketRef.current = targetMarket;
        referenceRef.current = reference;
        productKeyIdRef.current = productKeyId;
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [targetMarket, reference, productKeyId],
  );

  useEffect(() => {
    // set product as exclusive if needed
    if (
      hasSource &&
      hasPermissions &&
      !isSettingVisibility &&
      isPendingExclusivity &&
      hasOnlyExclusiveProducts &&
      !(isProduction() && isSuperAdminLoggedAs)
    ) {
      updateVisibility(true, false);
    }
  }, [
    viewAs,
    productKeyId,
    reference,
    hasSource,
    hasPermissions,
    targetMarket,
    isSettingVisibility,
    isPendingExclusivity,
    hasOnlyExclusiveProducts,
    isSuperAdminLoggedAs,
    updateVisibility,
  ]);

  const parentRoute = reference
    ? routes.productPageLegacy
    : uuid
      ? routes.productPageWithUuid
      : routes.productPage;

  if (!hasLegacyTextile && textileModelProductKeyId) {
    return (
      <Routes>
        <Route
          path={relativeLaxPath(routes.productChat, parentRoute)}
          element={
            <Navigate
              to={fill(routes.productChat, textileModelProductKeyId)}
              replace
            />
          }
        />
        <Route
          path={relativeLaxPath(routes.productDashboard, parentRoute)}
          element={
            <Navigate
              to={fill(routes.productDashboard, textileModelProductKeyId)}
              replace
            />
          }
        />
        <Route
          path={relativeLaxPath(routes.productHistory, parentRoute)}
          element={
            <Navigate
              to={fill(routes.productHistory, textileModelProductKeyId)}
              replace
            />
          }
        />
        <Route
          path="*"
          element={
            <Navigate
              to={fill(routes.productPage, textileModelProductKeyId)}
              replace
            />
          }
        />
      </Routes>
    );
  }

  // redirect on new product page if it's an old one
  if (shouldRedirectWithLegacyReference) {
    return (
      <Routes>
        <Route
          path={relativeLaxPath(routes.productChatLegacy, parentRoute)}
          element={
            <Navigate
              to={withQuery(routes.redirect, {
                action: PRODUCT_CHAT,
                gtin: reference,
                targetMarket,
              })}
              replace
            />
          }
        />
        <Route
          path={relativeLaxPath(routes.productDashboardLegacy, parentRoute)}
          element={
            <Navigate
              to={withQuery(routes.redirect, {
                action: PRODUCT_DASHBOARD,
                gtin: reference,
                targetMarket,
              })}
              replace
            />
          }
        />
        <Route
          path={relativeLaxPath(routes.productHistoryLegacy, parentRoute)}
          element={
            <Navigate
              to={withQuery(routes.redirect, {
                action: PRODUCT_HISTORY,
                gtin: reference,
                targetMarket,
              })}
              replace
            />
          }
        />
        <Route
          path="*"
          element={
            <Navigate
              to={withQuery(routes.redirect, {
                action: PRODUCT_PAGE,
                gtin: reference,
                targetMarket,
              })}
              replace
            />
          }
        />
      </Routes>
    );
  }
  if (hasLoadingFailed) {
    return (
      <CoreLayout>
        <ProductErrorPage />
      </CoreLayout>
    );
  }

  const shouldShowVisibilityModal = () =>
    !isThirdParty &&
    isPendingExclusivity &&
    !hasOnlyExclusiveProducts &&
    !(isProduction() && isSuperAdminLoggedAs);

  return (
    <CoreLayout>
      {!(
        hasSource &&
        (hasPermissions ||
          hasPermissionV3(user, FEATURE_PERMISSIONS_V3_PRODUCT))
      ) ? (
        <ProductPageSpinner />
      ) : (
        <>
          <ProductHeader />
          <div className="container-fluid">
            <Routes>
              <Route
                path={relativeLaxPath(routes.productDashboard, parentRoute)}
                element={<ProductDashboard />}
              />
              <Route
                path={relativeLaxPath(routes.productHistory, parentRoute)}
                element={<ProductHistory />}
              />
              <Route
                path={relativeLaxPath(routes.productStatusHistory, parentRoute)}
                element={<StatusHistory />}
              />
              <Route
                path={relativeLaxPath(routes.productChat, parentRoute)}
                element={<ProductChat />}
              />
              <Route
                path={relativeLaxPath(routes.productInsights, parentRoute)}
                element={<ProductInsights />}
              />
              <Route
                path={relativeLaxPath(routes.productReview, parentRoute)}
                element={<ProductReview />}
              />
              <Route
                path={relativeLaxPath(routes.productAdmin, parentRoute)}
                element={<ProductAdmin />}
              />
              <Route path="*" element={<ProductPage />} />
            </Routes>
          </div>
          {shouldShowVisibilityModal() && (
            <VisibilityModal
              onApply={updateVisibility}
              isBusy={isSettingVisibility}
              isEligibleForSourcing={isEligibleForSourcing}
            />
          )}
        </>
      )}
    </CoreLayout>
  );
}

export default ProductView;

import { List } from 'immutable';
import { isArray } from 'lodash';
import moment from 'moment';
import { v4 as uuid } from 'uuid';

import './list.scss';
import { DiffSeparator } from './separator';

function renderElementInfered(element: any): any {
  // It would be better to have a proper type from the backend, but
  // it would require changing the format of all diffs...
  // We have to do the date conversion in the front-end in order to use the proper locale.
  // For now, we only consider timestamps between 2015 and 2030 as valid.
  const isTimeStamp = Number(element)
    ? element >= 1420066800 &&
      element <= 1924988399 &&
      new Date(Number(element)).getTime() > 0
    : false;
  if (isTimeStamp) {
    return moment.unix(element).utc().format('L');
  }
  return element;
}

type Props = {
  label: string | string[] | string[][] | number | number[] | number[][];
  type?: 'div' | 'ul';
  labelClassName?: string;
};

export const DiffList = ({
  label,
  type = 'div',
  labelClassName = 'DiffList__label',
}: Props) => {
  let list: any[] = !isArray(label) ? [label] : label;
  list = list.map((l) => {
    if (isArray(l)) {
      return l.map((elt, i) => (
        // eslint-disable-next-line react/no-array-index-key
        <span key={i} data-diff={`label${i}`}>
          {i > 0 ? <DiffSeparator /> : null}
          {renderElementInfered(elt)}
        </span>
      ));
    }
    return (
      <span key={l} data-diff="label0">
        {l}
      </span>
    );
  });
  const items = List(list)
    .toSet() // TODO did we need this only to remove duplicates ?
    .toArray()
    .map((l) =>
      type === 'ul' ? (
        <li
          data-testid={'li'}
          key={`diff-item-${uuid()}`}
          className={labelClassName}
        >
          {l}
        </li>
      ) : (
        <div key={`diff-item-${uuid()}`} className={labelClassName}>
          {l}
        </div>
      ),
    );
  return type === 'ul' ? (
    <ul className="DiffList">{items}</ul>
  ) : (
    <div className="DiffList">{items}</div>
  );
};

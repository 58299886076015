import { call } from 'redux-saga/effects';

import { logError, takeLatestSafe } from '@alkem/micro-frontend-tools';
import { Authentication } from '@alkem/sdk-dashboard';

import authApi from 'resources/authApi';

import { LOGOUT } from './actions';

export default function* logoutRootSaga() {
  yield takeLatestSafe(LOGOUT, logoutSaga);
}

export function* logoutSaga() {
  try {
    yield call(() => authApi.UserLogout());
  } catch (error) {
    logError(error);
  }
  yield call(() => localStorage.removeItem('sxm_username'));
  yield call(Authentication.clearTokensAfterLogout);
}

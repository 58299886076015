import { set } from 'lodash';

import {
  ENTITY_TYPE_PRODUCTVERSION_OVERRIDE,
  ENTITY_TYPE_RFP_ANSWER,
  ENTITY_TYPE_SHARINGUNIT,
} from 'constants/entities';
import { ENTITY_TYPE_LOGISTICAL_HIERARCHY_UNIT } from 'modules/logistical-hierarchies/constants';

const filterList = function (value, field) {
  // Filter a list to keep only the values that are not specified in the field.lines.
  const mainKeyModel = field.inputKind.mainKey;
  if (!mainKeyModel) {
    return undefined;
  }

  const lineIds = field.lines.map((line) => line.id);

  return value.filter(
    (v) => v[mainKeyModel] && !lineIds.includes(v[mainKeyModel].id),
  );
};

export const getCleanedValue = function (value, field) {
  // Based on the current value, return the cleaned value for this field.
  // Most of the time, it will be undefined to erase the data.

  // Filter out the value in the list (for full lists).
  if (field.inputKind.kind === 'list' && field.lines) {
    if (!value) {
      return undefined;
    } // Nothing to filter.

    const newValue = filterList(value, field);

    if (!newValue.length) {
      return undefined;
    }
    return newValue;
  }

  // In the default case, remove the complete value.
  return undefined;
};

export const cleanFieldLabel = function (field, clearPlaceholder = false) {
  const cleanField = { ...field };
  set(cleanField, 'options.display.label', false);
  if (clearPlaceholder) {
    cleanField.placeholder = ' ';
  }
  return cleanField;
};

export function getId(model, entityKind, entityId) {
  let _model = model;
  if (entityKind === ENTITY_TYPE_SHARINGUNIT) {
    _model = `sharingunit.${entityId}.${_model}`;
  } else if (entityKind === ENTITY_TYPE_LOGISTICAL_HIERARCHY_UNIT) {
    _model = `${ENTITY_TYPE_LOGISTICAL_HIERARCHY_UNIT}.${entityId}.${_model}`;
  } else if (entityKind === ENTITY_TYPE_PRODUCTVERSION_OVERRIDE) {
    _model = `ProductVersionOverride.${entityId}.${_model}`;
  } else if (entityKind === ENTITY_TYPE_RFP_ANSWER) {
    _model = `RfpAnswer.${entityId}.${_model}`;
  }

  const sanitizedModel = _model.replace(/\./g, '-');
  return `FormField-${sanitizedModel}`;
}

import { combineReducers } from 'redux-immutable';

import error from './error';

export default combineReducers({
  error,
});

function getReducer(appState) {
  return appState.redirect;
}

// Selectors

export function getError(appState) {
  return getReducer(appState).get('error');
}

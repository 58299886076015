import { FormProvider } from 'react-hook-form';
import { useDispatch, useSelector } from 'react-redux';

import { Button, SwitchButton } from '@alkem/react-ui-button';

import Anchor from 'components/ui/basic/anchor';
import { usePictureForm } from 'components/ui/form/asset-list/AssetV2/hooks/usePictureForm';
import {
  EnhancedAssetV2,
  PictureAssetV2,
} from 'components/ui/form/asset-list/AssetV2/types';
import { getAssetDerivedData } from 'components/ui/form/asset-list/AssetV2/utils';
import { useAssetContext } from 'components/ui/form/asset-list/context';
import { selectRecipientIdsSharingSomething } from 'modules/recipient-specific-block/selectors';
import { selectUser } from 'modules/user';
import { setFilterRuleSetsFields } from 'modules/view-as/actions';
import { selectFilterRuleSetsFields } from 'modules/view-as/selectors';
import i18n from 'utils/i18n';

import {
  AssetAutoComplete,
  AssetDefinition,
  AssetInput,
  AssetRecipients,
  BackgroundInfo,
  ExpirationDate,
  ExtraInfos,
  FileSizeInfo,
  PackshotToggle,
  SequenceNumber,
  TagsInput,
  ValidityStartDate,
} from '../AssetInputs';
import { PictureErrors } from '../PictureErrors';

import { AssetContextualPictureFormGroupWrapper } from './AssetContextualPictureFormGroupWrapper';
import styles from './AssetPictureForm.module.scss';
import { AssetPictureJsonInfoInputs } from './AssetPictureJsonInfoInputs';

type AssetFormProps = {
  asset: EnhancedAssetV2<PictureAssetV2>;
};

export const AssetPictureForm = ({ asset }: AssetFormProps) => {
  const { previewUri } = getAssetDerivedData(asset);

  const user = useSelector(selectUser);
  const filterRuleSetsFields = useSelector(selectFilterRuleSetsFields);

  const recipientStatuses = asset.recipientStatusById;

  const recipientIdsSharingSomething: number[] = useSelector(
    selectRecipientIdsSharingSomething,
  );

  const recipientIdsSharingAsset = recipientIdsSharingSomething.filter(
    (recipientId) => !(recipientStatuses[recipientId] === 0),
  );

  const { formMethods, onSubmit } = usePictureForm({
    defaultValues: {
      ...asset,
      recipientIds: recipientIdsSharingAsset,
    },
  });

  const dispatch = useDispatch();

  const { formGroup } = useAssetContext();

  const isReadOnly = formGroup.options.picture.readOnly;

  const handleCheckOptionalField = (checked: boolean) => {
    dispatch(setFilterRuleSetsFields(!checked, user));
  };

  return (
    <FormProvider {...formMethods}>
      <form className={styles.form} onSubmit={onSubmit}>
        <div className={styles.inputs}>
          <div className={styles.imageWithMetadataWrapper}>
            <PictureErrors asset={asset} />
            <div className={styles.imageCanvas}>
              <Anchor href={asset.uniformResourceIdentifier}>
                <img
                  className={styles.image}
                  src={previewUri}
                  alt={i18n.t(
                    'frontproducstream.asset_list.picture.image.alt',
                    {
                      defaultValue: 'Product image',
                    },
                  )}
                />
              </Anchor>
            </div>
            <div className={styles.metadataWrapper}>
              <AssetDefinition
                definition={asset.definition}
                height={asset.height}
                width={asset.width}
              />
              <BackgroundInfo
                contentType={asset.contentType}
                backgroundScore={asset.backgroundScore}
              />
              <FileSizeInfo fileSize={asset.fileSize} />
            </div>
          </div>
          <PackshotToggle isReadOnly={isReadOnly} />
          <AssetInput
            childId="ProductPictureForm-contentType"
            label={i18n.t(
              'frontproducstream.asset_list.modal.type_of_information.label',
              { defaultValue: 'Type of information' },
            )}
          >
            <AssetAutoComplete
              name="contentType"
              slug="picturetypeofcontents"
              isReadOnly={isReadOnly}
            />
          </AssetInput>
          <AssetRecipients />
          <ValidityStartDate isReadOnly={isReadOnly} />
          <ExpirationDate isReadOnly={isReadOnly} />
          <AssetInput
            childId="ProductPictureForm-productFace"
            label={i18n.t('frontproducstream.asset_list.modal.face.label', {
              defaultValue: 'Face',
            })}
          >
            <AssetAutoComplete
              name="productFace"
              slug={`${asset.contentType}productfaces`}
              isReadOnly={isReadOnly}
            />
          </AssetInput>
          <AssetInput
            childId="ProductPictureForm-angleVertical"
            label={i18n.t(
              'frontproducstream.asset_list.modal.vertical_angle.label',
              {
                defaultValue: 'Vertical angle',
              },
            )}
          >
            <AssetAutoComplete
              name="angleVertical"
              slug="pictureangleverticals"
              isReadOnly={isReadOnly}
            />
          </AssetInput>
          <AssetInput
            childId="ProductPictureForm-angleHorizontal"
            label={i18n.t(
              'frontproducstream.asset_list.modal.horizontal_angle.label',
              {
                defaultValue: 'Horizontal angle',
              },
            )}
          >
            <AssetAutoComplete
              name="angleHorizontal"
              slug="pictureanglehorizontals"
              isReadOnly={isReadOnly}
            />
          </AssetInput>
          <AssetInput
            childId="ProductPictureForm-webOptimizedType"
            label={i18n.t(
              'frontproducstream.asset_list.modal.web_optimized_type.label',
              { defaultValue: 'Web optimized type' },
            )}
            help={i18n.t(
              'frontproducstream.asset_list.modal.web_optimized_type.help',
              {
                defaultValue:
                  'A cleaned pack is a picture whose main elements are highlighted. Should not include any element outside the packaging (band, pictogram)...',
              },
            )}
          >
            <AssetAutoComplete
              name="webOptimizedType"
              slug="pictureweboptimizedtypes"
              isReadOnly={isReadOnly}
            />
          </AssetInput>
          <AssetInput
            childId="ProductPictureForm-fileType"
            label={i18n.t(
              'frontproducstream.asset_list.modal.file_type.label',
              { defaultValue: 'File Type' },
            )}
          >
            <AssetAutoComplete
              name="fileType"
              slug="picturefiletypes"
              isReadOnly={isReadOnly}
            />
          </AssetInput>
          <TagsInput isReadOnly={isReadOnly} />
          <SequenceNumber isReadOnly={isReadOnly} />
          <ExtraInfos
            sku={asset.extraInfo?.sku}
            gtins={asset.extraInfo?.gtins}
            zpck={asset.extraInfo?.zpck}
          />
          <AssetPictureJsonInfoInputs formMethods={formMethods} asset={asset} />
          <AssetContextualPictureFormGroupWrapper
            formMethods={formMethods}
            isReadOnly={isReadOnly}
          />
        </div>
        <div className={styles.controls}>
          <SwitchButton
            content={i18n.t(
              'frontproducstream.asset_list.modal.show_more_fields_button.label',
              {
                defaultValue:
                  'Show optional fields to market more efficiently your product',
              },
            )}
            onChange={handleCheckOptionalField}
            checked={!filterRuleSetsFields}
          />
          <Button
            displaySpinner={formMethods.formState.isSubmitting}
            primary
            type="submit"
            disabled={
              !formMethods.formState.isDirty ||
              formMethods.formState.isSubmitting
            }
          >
            {i18n.t('frontproducstream.asset.modal.save_button.label', {
              defaultValue: 'Save',
            })}
          </Button>
        </div>
      </form>
    </FormProvider>
  );
};

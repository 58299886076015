function parse(value) {
  try {
    return JSON.parse(value);
  } catch (e) {
    return undefined;
  }
}

const craftStorage = (type) => {
  const storage =
    type === 'session' ? global.sessionStorage : global.localStorage;
  const hasStorage = !!(
    storage &&
    storage.getItem &&
    storage.setItem &&
    storage.removeItem
  );
  return {
    getItem(key, defaultValue) {
      if (hasStorage) {
        return parse(storage.getItem(key)) ?? defaultValue;
      }
      return defaultValue;
    },
    setItem(key, value) {
      if (hasStorage) {
        storage.setItem(key, JSON.stringify(value));
      }
    },
    removeItem(key) {
      if (hasStorage) {
        storage.removeItem(key);
      }
    },
  };
};

export const localStorage = craftStorage('local');
export const sessionStorage = craftStorage('session');

export default localStorage;

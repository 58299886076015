import classNames from 'classnames';
import { isFunction } from 'lodash/fp';
import { useDispatch, useSelector } from 'react-redux';

import { Field } from 'modules/supplier-directory';
import { selectIsRetailer } from 'modules/user';

import { editDataOpsField } from '../../actions';
import { selectHasDataOps } from '../../selectors';

interface Props {
  entityId: string | number;
  entityKind: string;
  field: Field | Pick<Field, 'model'>;
  isEditable: boolean;
  onEdit?: (isEditable: boolean) => void;
}

export const DataOpsEditPlugin = ({
  entityId,
  entityKind,
  field,
  isEditable,
  onEdit,
}: Props) => {
  const dispatch = useDispatch();
  const hasDataOps = useSelector(selectHasDataOps);
  const isRetailer = useSelector(selectIsRetailer);

  if (!hasDataOps || !isRetailer) {
    return null;
  }

  return (
    <i
      className={classNames(
        'mdi mdi-pencil FormField__action DataOpsEditPlugin',
        isEditable && 'FormField__action--selected',
      )}
      data-model={field?.model}
      data-entity-id={entityId}
      data-entity-kind={entityKind}
      data-editable={isEditable}
      data-testid="data-ops-edit-plugin"
      onClick={() => {
        if (isFunction(onEdit)) {
          onEdit(!isEditable);
        } else {
          dispatch(
            editDataOpsField({
              entityId,
              entityKind,
              isReadOnly: isEditable,
              model: field.model,
            }),
          );
        }
      }}
    />
  );
};

import * as sharingUnitStatusConstants from 'constants/sharingUnitStatus';
import { TRANSACTION_STATUS_PENDING } from 'constants/transaction';
import {
  SHARING_UNIT_STATUS_AGREED,
  SHARING_UNIT_STATUS_DELETED,
  SHARING_UNIT_STATUS_DRAFT,
  SHARING_UNIT_STATUS_INIT,
  SHARING_UNIT_STATUS_REFUSED,
} from 'modules/sharing-units/constants';

// for more information see
// https://alkemics.atlassian.net/wiki/display/TECH/B2B+SharingUnit+-+How+to+design+SharingUnit+workflow

export const constantsByStatuses = {
  // draft
  [[SHARING_UNIT_STATUS_DRAFT, undefined]]: sharingUnitStatusConstants.draft,
  [[SHARING_UNIT_STATUS_DELETED, undefined]]: sharingUnitStatusConstants.draft,
  // pending
  [[SHARING_UNIT_STATUS_INIT, TRANSACTION_STATUS_PENDING]]:
    sharingUnitStatusConstants.pending,
  [[SHARING_UNIT_STATUS_INIT, undefined]]: sharingUnitStatusConstants.pending,
  // refused
  [[SHARING_UNIT_STATUS_REFUSED, undefined]]:
    sharingUnitStatusConstants.refused,
  // updatePending
  [[SHARING_UNIT_STATUS_AGREED, TRANSACTION_STATUS_PENDING]]:
    sharingUnitStatusConstants.updatePending,
  [[SHARING_UNIT_STATUS_AGREED, undefined]]:
    sharingUnitStatusConstants.accepted,
};

export const getSharingUnitStatus = (sharingUnit) => {
  const sharingUnitStatus = sharingUnit.get('status');
  const lastRequestStatus = sharingUnit.getIn(['lastRequest', 'status']);

  return (
    constantsByStatuses[[sharingUnitStatus, lastRequestStatus]] ||
    sharingUnitStatusConstants.draft
  );
};

import { createAction, createActionWithPayload } from 'utils/redux';

export const MODULE_NAME = '/sharing-unit-templates';

export const LOAD_SHARING_UNIT_TEMPLATES = `${MODULE_NAME}/fetch/load`;
export const STOP_LOAD_SHARING_UNIT_TEMPLATES = `${MODULE_NAME}/fetch/stop-load`;

export const UPDATE_PAGINATION_SHARING_UNIT_TEMPLATES = `${MODULE_NAME}/upate-pagination`;

export const LIST_SHARING_UNIT_TEMPLATES = `${MODULE_NAME}/fetch`;
export const LIST_SHARING_UNIT_TEMPLATES_DONE = `${MODULE_NAME}/fetch/done`;
export const CREATE_SHARING_UNIT_TEMPLATES = `${MODULE_NAME}/create`;
export const DELETE_SHARING_UNIT_TEMPLATES = `${MODULE_NAME}/delete`;
export const GET_SHARING_UNIT_TEMPLATES = `${MODULE_NAME}/get`;
export const GET_SHARING_UNIT_TEMPLATES_DONE = `${MODULE_NAME}/get/done`;
export const UPDATE_SHARING_UNIT_TEMPLATES = `${MODULE_NAME}/update`;
export const SAVE_SHARING_UNIT_TEMPLATES = `${MODULE_NAME}/save`;
export const SAVE_SHARING_UNIT_TEMPLATES_DONE = `${MODULE_NAME}/save/done`;
export const SHARING_UNIT_TEMPLATE_IS_CREATING = `${MODULE_NAME}/is-creating`;
export const PUBLISH_SHARING_UNIT_TEMPLATES = `${MODULE_NAME}/publish`;
export const PUBLISH_SHARING_UNIT_TEMPLATES_DONE = `${MODULE_NAME}/publish/done`;

export const FETCH_DISPLAY_GROUP_SHARING_UNIT_TEMPLATES = `${MODULE_NAME}/display-groups/fetch`;
export const FETCH_DISPLAY_GROUP_SHARING_UNIT_TEMPLATES_DONE = `${MODULE_NAME}/display-groups/fetch/done`;

export const LIST_RETAILERS = `${MODULE_NAME}/retailers/fetch`;
export const LIST_RETAILERS_DONE = `${MODULE_NAME}/retailers/fetch/done`;

export const SEARCH_LIST_SHARING_UNIT_TEMPLATES = `${MODULE_NAME}/search`;
export const RESET_SEARCH_LIST_SHARING_UNIT_TEMPLATES = `${MODULE_NAME}/search/reset`;
export const UPDATE_SELECTED_FILTERS = `${MODULE_NAME}/update-selected-filters`;
export const UPDATE_FILTERED_FILTER = `${MODULE_NAME}/update-filtered-filter`;
export const RESET_SELECTED_FILTERS = `${MODULE_NAME}/reset-selected-filters`;

export const VALIDATE_SHARING_UNIT_TEMPLATE = `${MODULE_NAME}/validate`;
export const VALIDATE_SHARING_UNIT_TEMPLATE_DONE = `${MODULE_NAME}/validate/done`;

export const VALIDATE_SHARING_UNIT_TEMPLATE_PRODUCT = `${MODULE_NAME}/validate-product`;
export const VALIDATE_SHARING_UNIT_TEMPLATE_PRODUCT_DONE = `${MODULE_NAME}/validate-product/done`;

export const TOGGLE_SHARING_UNIT_TEMPLATES_PRODUCT_MODAL = `${MODULE_NAME}/modal/toggle-modal`;
export const INIT_SHARING_UNIT_TEMPLATES_PRODUCT = `${MODULE_NAME}/modal/product/init`;
export const FETCH_SHARING_UNIT_TEMPLATES_PRODUCT_DONE = `${MODULE_NAME}/modal/product/fetch/done`;
export const FETCH_SHARING_UNIT_TEMPLATES_PRODUCT_ERROR = `${MODULE_NAME}/modal/product/fetch/error`;
export const SAVE_SHARING_UNIT_TEMPLATES_PRODUCT = `${MODULE_NAME}/modal/product/save`;
export const SAVE_SHARING_UNIT_TEMPLATES_PRODUCT_DONE = `${MODULE_NAME}/modal/product/save/done`;
export const UPDATE_SHARING_UNIT_TEMPLATES_PRODUCT = `${MODULE_NAME}/modal/product/update`;
export const DELETE_SHARING_UNITS_TEMPLATES_PRODUCT = `${MODULE_NAME}/modal/products/delete`;

export const START_LOAD_SHARING_UNIT_TEMPLATES_PRODUCT = `${MODULE_NAME}/product/start-load`;
export const STOP_LOAD_SHARING_UNIT_TEMPLATES_PRODUCT = `${MODULE_NAME}/product/stop-load`;
export const CHECK_SHARING_UNIT_TEMPLATES_PRODUCT_SHARED = `${MODULE_NAME}/product/check/shared`;
export const CHECK_SHARING_UNIT_TEMPLATES_PRODUCT_UNIQ = `${MODULE_NAME}/product/check/uniq`;
export const UPDATE_SHARING_UNIT_TEMPLATES_PRODUCT_ERRORS = `${MODULE_NAME}/product/errors/update`;

export const SHARING_UNIT_TEMPLATES_FETCH_PRODUCT = `${MODULE_NAME}/product/fetch`;
export const SHARING_UNIT_TEMPLATES_CANCEL_PRODUCTS_POLLING = `${MODULE_NAME}/product/cancel-products-polling`;
export const LOAD_SHARING_UNIT_TEMPLATES_FETCH_PRODUCT = `${MODULE_NAME}/product/fetch/load`;
export const STOP_LOAD_SHARING_UNIT_TEMPLATES_FETCH_PRODUCT = `${MODULE_NAME}/product/fetch/stop-load`;
export const SHARING_UNIT_TEMPLATES_FETCH_PRODUCT_DONE = `${MODULE_NAME}/product/fetch/done`;
export const SEARCH_SHARING_UNIT_TEMPLATES_PRODUCT = `${MODULE_NAME}/product/search`;
export const TOGGLE_SHARING_UNIT_TEMPLATES_PRODUCT_IN_ERROR_FILTER = `${MODULE_NAME}/product/toggle-in-error-filter`;
export const SET_SHARING_UNIT_PRODUCT_VERSION = `${MODULE_NAME}/product/set-product-version`;
export const FETCH_SHARING_UNIT_PRODUCT_DATA_DONE = `${MODULE_NAME}/product/fetch-product-data-done`;
export const UPDATE_PAGINATION_SHARING_UNIT_TEMPLATES_PRODUCT = `${MODULE_NAME}/product/upate-pagination`;

export const SET_ERROR_ON_SAVE = `${MODULE_NAME}/error-on-save`;

export const loadSharingUnitTemplates = createAction(
  LOAD_SHARING_UNIT_TEMPLATES,
);
export const stopLoadSharingUnitTemplates = createAction(
  STOP_LOAD_SHARING_UNIT_TEMPLATES,
);

export const updatePaginationSharingUnitTemplates = createActionWithPayload(
  UPDATE_PAGINATION_SHARING_UNIT_TEMPLATES,
);

export const listSharingUnitTemplates = createActionWithPayload(
  LIST_SHARING_UNIT_TEMPLATES,
);
export const listSharingUnitTemplatesDone = createActionWithPayload(
  LIST_SHARING_UNIT_TEMPLATES_DONE,
);

export const searchSharingUnitTemples = createActionWithPayload(
  SEARCH_LIST_SHARING_UNIT_TEMPLATES,
);
export const resetSharingUnitTemplesSearch = createAction(
  RESET_SEARCH_LIST_SHARING_UNIT_TEMPLATES,
);

export const resetSelectedFilters = createAction(RESET_SELECTED_FILTERS);

export const listRetailers = createAction(LIST_RETAILERS);
export const listRetailersDone = createActionWithPayload(LIST_RETAILERS_DONE);
export const updateSelectedFilters = createActionWithPayload(
  UPDATE_SELECTED_FILTERS,
);

export const createSharingUnitTemplates = createActionWithPayload(
  CREATE_SHARING_UNIT_TEMPLATES,
);
export const deleteSharingUnitTemplates = createActionWithPayload(
  DELETE_SHARING_UNIT_TEMPLATES,
);
export const getSharingUnitTemplates = createActionWithPayload(
  GET_SHARING_UNIT_TEMPLATES,
);
export const getSharingUnitTemplatesDone = createActionWithPayload(
  GET_SHARING_UNIT_TEMPLATES_DONE,
);
export const updateSharingUnitTemplates = createActionWithPayload(
  UPDATE_SHARING_UNIT_TEMPLATES,
);
export const saveSharingUnitTemplates = createAction(
  SAVE_SHARING_UNIT_TEMPLATES,
);
export const saveSharingUnitTemplatesDone = createAction(
  SAVE_SHARING_UNIT_TEMPLATES_DONE,
);

export const sharingUnitTemplateIsCreating = createAction(
  SHARING_UNIT_TEMPLATE_IS_CREATING,
);
export const publishSharingUnitTemplates = createAction(
  PUBLISH_SHARING_UNIT_TEMPLATES,
);
export const publishSharingUnitTemplatesDone = createAction(
  PUBLISH_SHARING_UNIT_TEMPLATES_DONE,
);

export const fetchDisplayGroupSharingUnitTemplates = createActionWithPayload(
  FETCH_DISPLAY_GROUP_SHARING_UNIT_TEMPLATES,
);
export const fetchDisplayGroupSharingUnitTemplatesDone =
  createActionWithPayload(FETCH_DISPLAY_GROUP_SHARING_UNIT_TEMPLATES_DONE);

export const validateSharingUnitTemplate = createAction(
  VALIDATE_SHARING_UNIT_TEMPLATE,
);

export const validateSharingUnitTemplateProduct = createAction(
  VALIDATE_SHARING_UNIT_TEMPLATE_PRODUCT,
);

export const validateSharingUnitTemplateDone = createActionWithPayload(
  VALIDATE_SHARING_UNIT_TEMPLATE_DONE,
);
export const validateSharingUnitTemplateProductDone = createActionWithPayload(
  VALIDATE_SHARING_UNIT_TEMPLATE_PRODUCT_DONE,
);

export const toggleSharingUnitTemplatesProductModal = createActionWithPayload(
  TOGGLE_SHARING_UNIT_TEMPLATES_PRODUCT_MODAL,
);
export const initSharingUnitTemplatesProduct = createAction(
  INIT_SHARING_UNIT_TEMPLATES_PRODUCT,
);
export const fetchSharingUnitTemplatesProductDone = createActionWithPayload(
  FETCH_SHARING_UNIT_TEMPLATES_PRODUCT_DONE,
);

export const fetchProductVersionDone = createActionWithPayload(
  FETCH_SHARING_UNIT_PRODUCT_DATA_DONE,
);

export const fetchSharingUnitTemplatesProductError = createAction(
  FETCH_SHARING_UNIT_TEMPLATES_PRODUCT_ERROR,
);
export const saveSharingUnitTemplatesProduct = createAction(
  SAVE_SHARING_UNIT_TEMPLATES_PRODUCT,
);
export const saveSharingUnitTemplatesProductDone = createAction(
  SAVE_SHARING_UNIT_TEMPLATES_PRODUCT_DONE,
);
export const updateSharingUnitTemplatesProduct = createActionWithPayload(
  UPDATE_SHARING_UNIT_TEMPLATES_PRODUCT,
);
export const deleteSharingUnitTemplatesProduct = createActionWithPayload(
  DELETE_SHARING_UNITS_TEMPLATES_PRODUCT,
);

export const startLoadSharingUnitTemplatesProduct = createAction(
  START_LOAD_SHARING_UNIT_TEMPLATES_PRODUCT,
);
export const stopLoadSharingUnitTemplatesProduct = createAction(
  STOP_LOAD_SHARING_UNIT_TEMPLATES_PRODUCT,
);
export const checkSharingUnitTemplatesProductShared = createActionWithPayload(
  CHECK_SHARING_UNIT_TEMPLATES_PRODUCT_SHARED,
);

export const setProductVersion = createActionWithPayload(
  SET_SHARING_UNIT_PRODUCT_VERSION,
);

export const checkSharingUnitTemplatesProductUniq = createActionWithPayload(
  CHECK_SHARING_UNIT_TEMPLATES_PRODUCT_UNIQ,
);
export const updateSharingUnitTemplatesProductErrors = createActionWithPayload(
  UPDATE_SHARING_UNIT_TEMPLATES_PRODUCT_ERRORS,
);

export const sharingUnitTemplatesFetchProduct = createAction(
  SHARING_UNIT_TEMPLATES_FETCH_PRODUCT,
);

export const sharingUnitTemplatesCancelProductsPolling = createAction(
  SHARING_UNIT_TEMPLATES_CANCEL_PRODUCTS_POLLING,
);

export const loadSharingUnitTemplatesFetchProduct = createAction(
  LOAD_SHARING_UNIT_TEMPLATES_FETCH_PRODUCT,
);
export const stopLoadSharingUnitTemplatesFetchProduct = createAction(
  STOP_LOAD_SHARING_UNIT_TEMPLATES_FETCH_PRODUCT,
);
export const sharingUnitTemplatesFetchProductDone = createActionWithPayload(
  SHARING_UNIT_TEMPLATES_FETCH_PRODUCT_DONE,
);
export const searchSharingUnitTemplatesProduct = createActionWithPayload(
  SEARCH_SHARING_UNIT_TEMPLATES_PRODUCT,
);
export const toggleSharingUnitTemplatesProductInErrorFilter =
  createActionWithPayload(
    TOGGLE_SHARING_UNIT_TEMPLATES_PRODUCT_IN_ERROR_FILTER,
  );

export const updatePaginationSharingUnitTemplatesProduct =
  createActionWithPayload(UPDATE_PAGINATION_SHARING_UNIT_TEMPLATES_PRODUCT);

export const setErrorOnSave = createActionWithPayload<boolean | undefined>(
  SET_ERROR_ON_SAVE,
);

export const initSharingUnitTemplateProduct = createAction(
  INIT_SHARING_UNIT_TEMPLATES_PRODUCT,
);

import type { ComponentProps, ComponentType } from 'react';
import { useLocation } from 'react-router-dom';

export function withLocation<C extends ComponentType<any>>(
  Component: C,
  statics: string[] = [],
) {
  function WithLocation(props: any) {
    const location = useLocation();
    return <Component {...props} location={location} />;
  }
  for (const key of ['defaultProps', ...statics]) {
    if (key in Component) {
      WithLocation[key] = Component[key];
    }
  }
  if (Component.propTypes) {
    const { location, ...propTypes } = Component.propTypes;
    WithLocation.propTypes = propTypes;
  }
  return WithLocation as ComponentType<Omit<ComponentProps<C>, 'location'>> & {
    [key in keyof C]: C[key];
  };
}

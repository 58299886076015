import classNames from 'classnames';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { compose } from 'redux';

import { updateEntity } from 'actions/entity';
import Field from 'components/ui/form/field';
import { withProductKind } from 'hocs';
import i18n from 'utils/i18n';

import './labels.scss';
import LabelList from './list';
import LabelSuggestions from './suggestions';

export class Labels extends Field {
  static propTypes = Object.assign({}, Field.propTypes, {
    productKindId: PropTypes.number,
  });

  componentDidUpdate() {
    this.ensureDataIsPresent();
  }

  updateLabels = (labels) => {
    const { entityKind, entityId, field, dispatch } = this.props;

    const params = [field.model, labels, entityId, entityKind];

    if (this.dispatchPatchDataOpsField(params)) {
      return;
    }

    dispatch(updateEntity(...params));
  };

  render() {
    const { value, field, productKindId, entityId, entityKind } = this.props;
    if (!field) {
      return null;
    }
    field.label = i18n.t('frontproductstream.labels.field.label', {
      defaultValue: 'Selected labels',
    });
    const isReadOnly = this.isReadOnly();
    return (
      <div id={this.getId()}>
        <div className={classNames(this.getClasses({ Labels: true }))}>
          <div className="Labels__selection">
            {this.renderLabel(null, true)}
            <LabelList
              labels={value || []}
              kindId={productKindId}
              searchPlaceholder={field.placeholder}
              disabled={isReadOnly}
              onChange={this.updateLabels}
            />
            {this.renderPlugins()}
          </div>
        </div>
        <LabelSuggestions
          entityId={entityId}
          entityKind={entityKind}
          field={field}
          value={value}
          isReadOnly={isReadOnly}
        />
      </div>
    );
  }
}

export default compose(connect(null), withProductKind)(Labels);

export const RECEIVE_PROFILE = 'user/receive-profile';
export const UPDATE_USERSETTING = 'user/update-usersetting';
export const UPDATE_USER_FIELD = 'user/update-user-field';
export const RECEIVE_PRODUCT_SEGMENTS_PERMISSIONS =
  'user/receive-product-segments-permissions';
export const RECEIVE_USER_ORGANIZATION_PERMISSIONS =
  'user/receive-user-organization-permissions';
export const LOGOUT = 'user/logout';
export const AUTHORIZED = 'user/authorized';
export const NOT_AUTHORIZED = 'user/not-authorized';
export const RESET_AUTHORIZED = 'user/reset-authorized';
export const TRY_AUTHORIZATION = 'user/try-authorization';
export const AFTER_LOGIN_SUCCESS = 'user/after-login-success';
export const AFTER_LOGAS_SUCCESS = 'user/after-logas-success';
export const USER_PROFILE_LOADED = 'user/user-profile-loaded';

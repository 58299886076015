import { all, call, put } from 'redux-saga/effects';

import { regularReferentialApi } from 'resources/referentialApi';
import { requestWithHeaders } from 'utils/api';
import { takeFirst, withCatch } from 'utils/saga';

import { LOAD_REFERENTIALS, RECEIVE_REFERENTIALS } from './events';

export default function* assetsMainSaga() {
  yield takeFirst(LOAD_REFERENTIALS, withCatch(loadReferentials));
}

function* loadReferentials() {
  const results = yield all({
    picturetypeofcontents: call(
      requestWithHeaders,
      regularReferentialApi,
      'ReferentialGetList',
      'picturetypeofcontents',
    ),
    pictureproductfaces: call(
      requestWithHeaders,
      regularReferentialApi,
      'ReferentialGetList',
      'pictureproductfaces',
    ),
    pictureangleverticals: call(
      requestWithHeaders,
      regularReferentialApi,
      'ReferentialGetList',
      'pictureangleverticals',
    ),
    pictureanglehorizontals: call(
      requestWithHeaders,
      regularReferentialApi,
      'ReferentialGetList',
      'pictureanglehorizontals',
    ),
    pictureweboptimizedtypes: call(
      requestWithHeaders,
      regularReferentialApi,
      'ReferentialGetList',
      'pictureweboptimizedtypes',
    ),
    picturefiletypes: call(
      requestWithHeaders,
      regularReferentialApi,
      'ReferentialGetList',
      'picturefiletypes',
    ),
    picturesspecification: call(
      requestWithHeaders,
      regularReferentialApi,
      'ReferentialGetList',
      'picturesspecifications',
    ),
  });
  const referentials = {};
  Object.entries(results).forEach(([k, e]) => {
    referentials[k] = e.result.data.data;
  });
  yield put({ type: RECEIVE_REFERENTIALS, referentials });
}

import { AssetTypeV2 } from 'components/ui/form/asset-list/AssetV2/types';

import { ActionsDropdown } from './ActionsDropdown';
import styles from './AssetModalControls.module.scss';

type AssetModalControlsProps = {
  productKeyId: number;
  items: AssetTypeV2[];
  updateEntities: () => void;
};
export const AssetModalControls = ({
  productKeyId,
  items,
  updateEntities,
}: AssetModalControlsProps) => (
  <div className={styles.wrapper}>
    <ActionsDropdown
      productKeyId={productKeyId}
      items={items}
      updateEntities={updateEntities}
    />
  </div>
);

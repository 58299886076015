import { createSelector } from '@reduxjs/toolkit';
import { List, Set, fromJS } from 'immutable';
import {
  cloneDeep as cloneDeepf,
  curry,
  flow,
  getOr as getOrf,
  get as getf,
  isObject,
  isUndefined,
  set as setf,
  update as updatef,
} from 'lodash/fp';
import { compose } from 'redux';
import { createReducer } from 'redux-create-reducer';

import { KIND_BASE } from 'constants/concepts';
import { UPDATE_ENTITY } from 'constants/events/entity';
import {
  ADD_PRODUCT_VERSION_TAG,
  BULK_SEED_DATA,
  DELETE_PRODUCT_VERSION,
  PRODUCT_VERSION_RESET,
  READY_FOR_VALIDATION,
  RECEIVE_GROUP_SCOPES,
  RECEIVE_PRODUCT_KEY,
  RECEIVE_PRODUCT_SHARES,
  RECEIVE_PRODUCT_VERSION,
  RECEIVE_PRODUCT_VERSION_PERMISSIONS,
  REMOVE_PRODUCT_VERSION_TAG,
  SET_CONTENT_OWNERS,
  SET_KIND_PARENTS,
  UPDATE_CURRENT_LANGUAGE,
} from 'constants/events/productversion';
import { rightToLeftLocales } from 'constants/localization';
import * as shareStatus from 'constants/sharedStatus';
import {
  TAG_EXCLUSIVE,
  TAG_EXPORTABLE,
  TAG_PENDING_EXCLUSIVITY,
  TAG_PUBLISHED,
} from 'constants/tags';
import {
  TargetProductStatusAccepted,
  TargetProductStatusUpdated,
  TargetProductStatusWaitingToBeShared,
} from 'constants/targetProductStatus';
import { TypePackagingLabels } from 'constants/typePackaging';
import {
  getAllergenTypeList,
  getDisplayName,
  getDisplayNameWithoutFallback,
  getIsBaseUnit,
  getIsConsumerUnit,
  getIsDespatchUnit,
  getIsDisplayUnit,
  getIsMadeOf,
  getIsMadeOfError,
  getIsRequested,
  getLanguageValue,
  getProductGTIN,
  getProductGTINWithFallbackOnId,
  getProductGTINWithFallbackOnInternal,
  getProductIdentifier,
  getProductVersionTargetProductStatus,
  getTargetMarketId,
  getTypePackagingId,
  hasTag,
} from 'core/api/productversion';
import {
  getLastShareStatus,
  getSharedProductVersionId,
} from 'core/api/retailerproductversion';
import {
  findFieldByName,
  selectDefaultDisplayGroups,
} from 'modules/display-groups/selectors';
import { FEATURE_PERMISSIONS_V3_PRODUCT } from 'modules/feature-flag/constants';
import {
  isHeterogeneousLogisticalUnit,
  productIsHeterogeneousLogisticalUnit,
} from 'modules/logistical-hierarchies/helpers';
import {
  getPermissionKey,
  hasInstancePermissions,
  hasPermissionV3,
  selectPermissions as selectPermissionsV3,
} from 'modules/permissions';
import {
  CERTIFY_PERMISSION,
  NORMALIZEDCOMMENT_PERMISSION,
  PATCH_PERMISSION,
  PRODUCT_PERMISSION,
  SHARING_UNIT_PERMISSION,
  SHOW_PERMISSION,
  UPDATES_VALIDATE_PERMISSION,
  UPDATE_PERMISSION,
  USERLABEL_MANAGE_PERMISSION,
  VALIDATE_PERMISSION,
} from 'modules/permissions/const';
import { Statuses } from 'modules/product-page/modules/textile/constants';
import { selectFlags, selectOrganizationId, selectUser } from 'modules/user';
import { GlobalState } from 'types';
import { Language } from 'types/language';
import { get } from 'utils/immutable';
import {
  cleanBrandAndKind,
  updateIsMadeOfInReducer,
  updateIsSizedByInReducer,
  updateReferenceOfPath,
} from 'utils/reducers/entity';

import {
  AMBIGOUS_UNIT,
  CONSUMER_UNIT,
  DISPLAY_UNIT,
  HETEROGENEOUS_UNIT,
  LOGISTICAL_UNIT,
} from '../constants/unitTypes';

export const defaultRank = Number.MAX_SAFE_INTEGER;

// UTILS

export const comparePnq = (item, jtem) => {
  if (!item || !item.nutrientCode) {
    return 1;
  }
  if (!jtem || !jtem.nutrientCode) {
    return -1;
  }
  const [irank, ilabel] = [item.nutrientCode.rank, item.nutrientCode.label];
  const [jrank, jlabel] = [jtem.nutrientCode.rank, jtem.nutrientCode.label];
  let result;
  if (irank || jrank) {
    result = (irank || defaultRank) - (jrank || defaultRank);
  } else if (ilabel === jlabel) {
    result = 0;
  } else if (!ilabel || ilabel > jlabel) {
    result = 1;
  } else {
    result = -1;
  }
  return result;
};

export function setupProductVersionLocale(
  state,
  userLocalesByTargetMarket,
  productVersion,
  supportsMultipleLocales,
) {
  // Get the languages available in the product version.
  let availableLanguages: Language[] = [];
  productVersion.expressedIn.forEach((item) => {
    if (item.referential.name === 'languages') {
      availableLanguages = item.presentEntities;
    }
  });

  let supportedLocales: List<any> | null = null;
  const targetMarketId = getTargetMarketId(productVersion);
  if (targetMarketId && userLocalesByTargetMarket) {
    supportedLocales = userLocalesByTargetMarket.get(String(targetMarketId));
  }

  if (!supportedLocales) {
    supportedLocales = List();
  }

  supportedLocales = supportsMultipleLocales
    ? supportedLocales
    : List.of(supportedLocales.get(0));
  const languageExistsMap = {};
  const languageIsSupportedMap = {};
  const languageIsSupportedAndAvailableMap = {};
  const supportedAndAvailableLanguages: Language[] = [];
  const viewableLanguages: Language[] = [];

  const mainLocale =
    supportedLocales && supportedLocales.count()
      ? supportedLocales.get(0).toJS()
      : null;

  // add everything from supported locales
  supportedLocales.forEach((lang) => {
    if (!languageExistsMap[lang.id]) {
      viewableLanguages.push(lang.toJS());
      languageExistsMap[lang.get('id')] = true;
      languageIsSupportedMap[lang.get('id')] = true;
    }
  });

  // add everything from available locales (that are not already in viewable)
  availableLanguages.forEach((lang) => {
    if (!languageExistsMap[lang.id]) {
      viewableLanguages.push(lang);
      languageExistsMap[lang.id] = true;
    }

    if (languageIsSupportedMap[lang.id]) {
      supportedAndAvailableLanguages.push(lang);
      languageIsSupportedAndAvailableMap[lang.id] = true;
    }
  });

  let currentLanguage: Language | null = null;
  if (mainLocale && languageIsSupportedAndAvailableMap[mainLocale.id]) {
    currentLanguage = mainLocale;
  } else if (supportedAndAvailableLanguages.length) {
    [currentLanguage] = supportedAndAvailableLanguages;
  } else if (supportedLocales.count()) {
    // Use the first supported locale.
    currentLanguage = supportedLocales.get(0).toJS();
  } else {
    // Should happen only if a user try to load a product version
    // on targetMarket not link to his organization
    [currentLanguage] = availableLanguages;
  }

  // Set up the available locales.
  return {
    ...state,
    currentLanguage,
    availableLanguages: viewableLanguages,
  };
}

const updateIsPartitionedBy = (action) => (state) => {
  if (!action.key.includes('isPartitionedBy')) {
    return state;
  }

  if (action.key.endsWith('isPartitionedBy')) {
    return setf(action.key, action.value, state);
  }

  const path = action.key.split('.');
  const idx = path.indexOf('isPartitionedBy');
  const endPath = path.splice(idx + 1);
  return updatef(path, (isPartitionedBy) =>
    (setf(endPath, action.value, isPartitionedBy) as any).map(
      updatef('contains', (contains) => (contains || []).sort(comparePnq)),
    ),
  )(state);
};

const firstPart = (key) => key.split('.')[0];

// TODO PALPA-1201 Delete the part on ignoreField
const updateIgnoredFields = (action) => (state) => {
  let nextState = state;
  const rootFieldName = firstPart(action.key);
  if (action.ignoreField && !action.isDirty) {
    if (!get(state, ['includedFields']).has(rootFieldName)) {
      nextState = updatef('ignoredFields', (ignoredFields) =>
        ignoredFields.add(rootFieldName),
      )(nextState);
    }
  } else {
    nextState = updatef('ignoredFields', (ignoredFields) =>
      ignoredFields.delete(rootFieldName),
    )(nextState);

    nextState = updatef('includedFields', (includedFields) =>
      includedFields.add(rootFieldName),
    )(nextState);
  }

  return nextState;
};

const updateSpecialFieldsToHandle = (action) => (state) => {
  let nextState = state;
  if (action.key.includes('isPartitionedBy')) {
    const path = action.key.split('.');
    const idx = path.indexOf('isPartitionedBy');
    nextState = updatef(
      'specialFieldsToHandle',
      (s) => s.add(path.slice(0, idx + 1).join('.')),
      nextState,
    );
  }
  return nextState;
};

const updateEdited = (state, action) =>
  compose(
    updateIsPartitionedBy(action),
    updateIsSizedByInReducer(action),
    updateIsMadeOfInReducer(action),
    // TODO should work without cloneDeepf()
    setf(action.key, cloneDeepf(action.value)),
    // TODO should work without updateReferenceOfPath()
  )(updateReferenceOfPath(state, action.key));

const getTrx = (lastRequest) => {
  if (lastRequest) {
    const { id, updatedAt } = lastRequest;
    return { id, updatedAt };
  } else {
    return null;
  }
};

// REDUCER

const initialState: any = {
  availableLanguages: [],
  currentLanguage: null,

  /* List of organizations having an instance of the product.
   */
  contentOwners: [],

  /* When we load the product, each input field is updating it's value in the
   * product version object, without making a real change, for example from
   * undefined to null, or to '' (empty string) and so on. When we save these
   * values would be sent to the server.
   *
   * To keep track of which fields really have been updated by the user and
   * which ones have just a replaced empty value we have the ignoredFields set.
   */
  ignoredFields: Set(),
  // this is to track that if a field was ever removed from ignoreFields,
  // we never add it back. It is definitely a hack. The better way would
  // be to only use included fields but there seem to be too many tests
  // around ignored fields to warrant a full change of how this piece
  // works. Also the lack of comments around clearing a list in
  // selectProductVersionToSave makes this a bit annoying to update.
  includedFields: Set(),

  // list of parent kind
  kindParents: List(),

  // list of the path to special fields that should be handled upon
  // saving.
  // For now, it concerns isPartitionedBy, but as the field can be
  // set as a child of another field, we need to be able to clean
  // deeper in the data.
  specialFieldsToHandle: Set(),

  // define if the data have been seeded already to make it complete for the
  // validation process
  readyForValidation: false,

  // the scopes of the product, i.e. the activation status per retailer group.
  scopes: null,
};

export default createReducer<typeof initialState, any>(initialState, {
  [RECEIVE_PRODUCT_VERSION]: (state, action) => {
    const { setupLocale, userLocalesByTargetMarket, supportsMultipleLocales } =
      action;
    let { productVersion } = action;
    // TODO start with initialState
    let newState = { ...state };

    // Hack to not display default kinds and brands.
    // TODO can we do this via selectors instead?
    if (action.cleanBrandAndKind) {
      productVersion = cleanBrandAndKind(productVersion);
    }

    // Order the PSQs.
    // TODO can we do this via selectors instead?
    if ('isPartitionedBy' in productVersion) {
      productVersion.isPartitionedBy = productVersion.isPartitionedBy.map(
        (psq) => {
          const orderedPsq = { ...psq };
          if (Array.isArray(orderedPsq.contains)) {
            orderedPsq.contains.sort(comparePnq);
          }
          return orderedPsq;
        },
      );
    }

    // clean allergenTypeList in received product version
    if (
      'allergenTypeList' in productVersion &&
      productVersion.allergenTypeList != null
    ) {
      const NC_ALLERGEN_ID = 3;
      productVersion.allergenTypeList = productVersion.allergenTypeList.filter(
        (allergen) =>
          !allergen.allergenPresenceCode ||
          allergen.allergenPresenceCode.id !== NC_ALLERGEN_ID,
      );
    }

    // TODO why don't we need cleanBrandAndKind or ordering of PSQs
    const displayedProductVersion = productVersion.lastRequest
      ? productVersion.lastRequest.data
      : productVersion;

    newState.productVersion = {
      ...(state.productVersion || {}),
      source: displayedProductVersion,
      edited: cloneDeepf(displayedProductVersion),
      isDirty: false,
      trx: getTrx(productVersion.lastRequest),
    };

    if (setupLocale) {
      newState = setupProductVersionLocale(
        newState,
        userLocalesByTargetMarket,
        productVersion,
        supportsMultipleLocales,
      );
    }

    newState.ignoredFields = Set();
    newState.specialFieldsToHandle = Set();
    newState.includedFields = Set();

    return newState;
  },

  [SET_CONTENT_OWNERS]: (state, { payload }) => {
    const { productKeys } = payload;
    return {
      ...state,
      contentOwners: (productKeys || []).map((k) => ({
        id: k.organization_id,
      })),
    };
  },

  [UPDATE_ENTITY]: (state, action) => {
    switch (action.entityKind) {
      case 'ProductVersion':
        return flow(
          updatef(['productVersion'], (productVersion) =>
            flow(
              setf(
                ['edited'],
                updateEdited(getf(['edited'], productVersion), action),
              ),
              setf(
                ['isDirty'],
                getf(['isDirty'], productVersion) || getf(['isDirty'], action),
              ),
            )(productVersion),
          ),
          updateSpecialFieldsToHandle(action),
          updateIgnoredFields(action),
        )(state);
      default:
        return state;
    }
  },

  [BULK_SEED_DATA]: (state, { updates }) => {
    if (!state.productVersion) {
      return state;
    }
    return compose(
      updatef('ignoredFields', (ignoredFields) =>
        ignoredFields.union(updates.map((u) => u.key)),
      ),
      updatef('productVersion', (productVersion) =>
        setf(
          'edited',
          updates.reduce((acc, u) => updateEdited(acc, u), {
            ...productVersion.edited,
          }),
        )(productVersion),
      ),
    )(state);
  },

  [READY_FOR_VALIDATION]: (state) => setf('readyForValidation', true, state),

  [UPDATE_CURRENT_LANGUAGE]: (state, action) =>
    setf('currentLanguage', action.currentLanguage, state),

  [RECEIVE_PRODUCT_VERSION_PERMISSIONS]: (
    state,
    { payload: { permissions } },
  ) => {
    if (Array.isArray(permissions)) {
      return setf('productVersion.permissions', permissions, state);
    } else if (isObject(permissions)) {
      return setf(
        'productVersion.permissions',
        Object.keys(permissions),
        state,
      );
    }
    return setf('productVersion.permissions', permissions, state);
  },

  [RECEIVE_PRODUCT_KEY]: (state, { payload }) =>
    setf('productKey', payload.productKey, state),

  [SET_KIND_PARENTS]: (state, action) =>
    setf('kindParents', fromJS(action.kindParents), state),

  [RECEIVE_PRODUCT_SHARES]: (state, { payload }) =>
    setf('shares', payload.productShares, state),

  [ADD_PRODUCT_VERSION_TAG]: (state, { payload }) =>
    compose(
      updatef('productVersion.source.isTaggedBy', (isTaggedBy) => [
        ...(isTaggedBy || []),
        payload.tag,
      ]),
      updatef('productVersion.edited.isTaggedBy', (isTaggedBy) => [
        ...(isTaggedBy || []),
        payload.tag,
      ]),
    )(state),

  [REMOVE_PRODUCT_VERSION_TAG]: (state, { payload }) =>
    compose(
      updatef('productVersion.source.isTaggedBy', (isTaggedBy) =>
        (isTaggedBy || []).filter((t) => t !== payload.tag),
      ),
      updatef('productVersion.edited.isTaggedBy', (isTaggedBy) =>
        (isTaggedBy || []).filter((t) => t !== payload.tag),
      ),
    )(state),

  [PRODUCT_VERSION_RESET]: (state) => {
    if (!get(state, 'productVersion.source')) {
      return state;
    } else {
      return compose(
        setf('productVersion.edited', getf('productVersion.source')),
        setf('productVersion.isDirty', false),
      )(state);
    }
  },

  [DELETE_PRODUCT_VERSION]: (state) =>
    compose(
      setf('productVersion', null),
      setf('readyForValidation', false),
      setf('scopes', null),
    )(state),

  [RECEIVE_GROUP_SCOPES]: (state, { scopes }) => setf('scopes', scopes, state),
});

// SELECTORS
// ---------

// PRODUCT-VERSION

const selectModuleState = getf('productVersion');

export const selectProductVersion = createSelector(
  selectModuleState,
  getf('productVersion'),
);

export const selectSourceProductVersion = createSelector(
  selectProductVersion,
  getf('source'),
);

export const selectProductVersionId = createSelector(
  selectSourceProductVersion,
  getf('id'),
);

export const selectIsDirty = createSelector(
  selectProductVersion,
  getf('isDirty'),
);

export const selectHasSource = createSelector(
  selectSourceProductVersion,
  Boolean,
);

export const selectEditedProductVersion = createSelector(
  selectProductVersion,
  getf('edited'),
);

export const selectCurrentProductVersionGTIN = createSelector(
  selectEditedProductVersion,
  getProductGTIN,
);

export const selectCurrentProductVersionProductIdentifier = createSelector(
  selectEditedProductVersion,
  getProductIdentifier,
);

export const selectProductId = createSelector(
  selectSourceProductVersion,
  getf('specializes.id'),
);

export const selectKindParents = createSelector(
  selectModuleState,
  getf('kindParents'),
);

export const selectReadyForValidation = createSelector(
  selectModuleState,
  getf('readyForValidation'),
);

// Product Key
export const selectProductKey = createSelector(
  selectModuleState,
  getf('productKey'),
);

export const selectProductKeyId: (state: any) => number = createSelector(
  selectProductKey,
  getf('id'),
);

export const selectProductKeyProductId = createSelector(
  selectProductKey,
  getf('product_id'),
);

export const selectProductKeyOrganizationId = createSelector(
  selectProductKey,
  getf('organization_id'),
);

export const selectProductKeyTargetMarketId = createSelector(
  selectProductKey,
  getf('target_market_id'),
);

export const selectProductShares = createSelector(
  selectModuleState,
  getf('shares'),
);

export const selectProductFirstSourceShares = createSelector(
  selectProductShares,
  (share) =>
    (get(share, 'sources') || []).find(
      (s) => get(s, ['status', 'id']) !== shareStatus.draft.id,
    ),
);

export const selectProductOrganizationSourceId = createSelector(
  selectProductFirstSourceShares,
  getf('sourceOrganization.id'),
);

export const selectProductOrganizationSourceName = createSelector(
  selectProductFirstSourceShares,
  getf('sourceOrganization.nameLegal'),
);

export const selectIsConsumerUnit = createSelector(
  selectEditedProductVersion,
  compose(Boolean, getIsConsumerUnit),
);

export const selectIsBaseUnit = createSelector(
  selectEditedProductVersion,
  compose(Boolean, getIsBaseUnit),
);

export const selectIsDisplayUnit = createSelector(
  selectEditedProductVersion,
  compose(Boolean, getIsDisplayUnit),
);

export const selectIsRequested = createSelector(
  selectEditedProductVersion,
  compose(Boolean, getIsRequested),
);

export const selectIsLogisticalUnit = createSelector(
  selectIsConsumerUnit,
  selectIsDisplayUnit,
  (isConsumerUnit, isDisplayUnit) => !(isConsumerUnit || isDisplayUnit),
);

export const selectIsDespatchUnit = createSelector(
  selectEditedProductVersion,
  compose(Boolean, getIsDespatchUnit),
);

export const selectProductVersionIsMadeOf: (state: any) => [
  {
    targetProduct?: {
      version: {
        isConsumerUnit: boolean;
      };
    };
  },
] = createSelector(selectEditedProductVersion, getIsMadeOf);

export const selectProductVersionIsMadeOfError = createSelector(
  selectEditedProductVersion,
  selectFlags,
  getIsMadeOfError,
);

export const selectTextileVariantList = createSelector(
  selectEditedProductVersion,
  getOrf([], 'textileVariantList'),
);

export const selectActiveTextileVariantList = createSelector(
  selectTextileVariantList,
  (variants) =>
    (variants || []).filter((variant) => variant.status === Statuses.ACTIVE.id),
);

export const selectHasTextileVariants = createSelector(
  selectTextileVariantList,
  (variants) => !!variants && variants.length > 0,
);

export const selectTypePackaging = createSelector(
  selectEditedProductVersion,
  getf('typePackaging'),
);

export const selectTypePackagingId: (state: any) => number = createSelector(
  selectEditedProductVersion,
  getTypePackagingId,
);

export const selectLifeCycle = createSelector(
  selectEditedProductVersion,
  getf('lifeCycle'),
);

export const selectIsSizedBy = createSelector(
  selectEditedProductVersion,
  getf('isSizedBy'),
);

export const selectGrossWeight = createSelector(
  selectEditedProductVersion,
  getf('grossWeight'),
);

export const selectKindId = createSelector(
  selectEditedProductVersion,
  (productVersion) => get(productVersion, 'kind.id'),
);

export const selectIsMadeOf = createSelector(
  selectEditedProductVersion,
  (productVersion) => get(productVersion, 'isMadeOf', []),
);

export const selectIgnoredFields = createSelector(
  selectModuleState,
  getf('ignoredFields'),
);

export const selectIncludedFields = createSelector(
  selectModuleState,
  getf('includedFields'),
);

export const selectSpecialFieldsToHandle = createSelector(
  selectModuleState,
  getf('specialFieldsToHandle'),
);

export const selectCurrentLanguage: (state: any) => Language | undefined =
  createSelector(selectModuleState, getf('currentLanguage'));

export const selectIsHeterogeneousLogisticalUnit = createSelector(
  selectIsDespatchUnit,
  selectIsDisplayUnit,
  selectIsConsumerUnit,
  selectTypePackagingId,
  selectProductVersionIsMadeOf,
  isHeterogeneousLogisticalUnit,
);

export const selectUnitType = createSelector(
  selectIsConsumerUnit,
  selectIsDisplayUnit,
  selectIsHeterogeneousLogisticalUnit,
  (isConsumerUnit, isDisplayUnit, isHeterogeneousUnit) => {
    if (isDisplayUnit && isConsumerUnit) {
      return AMBIGOUS_UNIT;
    } else if (isDisplayUnit) {
      return DISPLAY_UNIT;
    } else if (isConsumerUnit) {
      return CONSUMER_UNIT;
    } else if (isHeterogeneousUnit) {
      return HETEROGENEOUS_UNIT;
    }
    return LOGISTICAL_UNIT;
  },
);

export const selectIsOriginalProductHeterogeneousUnit = createSelector(
  selectSourceProductVersion,
  (versionData) => productIsHeterogeneousLogisticalUnit({ versionData }),
);

// TODO add info in alk_core/ReferentialEntity.data : {..., rtl: true}
export const selectIsRightToLeft = createSelector(
  [selectCurrentLanguage],
  (language) =>
    language ? rightToLeftLocales.includes(language.normalizedCode) : false,
);

export const selectAvailableLanguages = createSelector(
  selectModuleState,
  getf('availableLanguages'),
);

export const selectDisplayName = createSelector(
  selectEditedProductVersion,
  selectCurrentLanguage,
  getDisplayName,
);

export const selectDisplayNameWithoutFallback = createSelector(
  selectEditedProductVersion,
  selectCurrentLanguage,
  getDisplayNameWithoutFallback,
);

export const selectProductGTINWithFallbackOnInternal = createSelector(
  selectSourceProductVersion,
  getProductGTINWithFallbackOnInternal,
);

export const selectProductGTINWithFallbackOnId = createSelector(
  selectSourceProductVersion,
  getProductGTINWithFallbackOnId,
);

export const selectProductPackaging = createSelector(
  selectEditedProductVersion,
  selectCurrentLanguage,
  (pv, language) => getLanguageValue(get(pv, 'packaging'), language),
);

export const selectHasLifeCycle = createSelector(
  selectEditedProductVersion,
  (pv) => get(pv, 'lifeCycle') !== null,
);

export const selectTargetProductStatus = createSelector(
  selectSourceProductVersion,
  getProductVersionTargetProductStatus,
);

export const selectHasTargetProductStatus = createSelector(
  selectSourceProductVersion,
  compose(Boolean, getProductVersionTargetProductStatus),
);

export const selectProductIsWaitingToBeShared = createSelector(
  selectTargetProductStatus,
  (targetProductStatus) =>
    targetProductStatus === TargetProductStatusWaitingToBeShared.id,
);

// SHARES

export const selectShares = createSelector(
  selectSourceProductVersion,
  compose(fromJS, getOrf([], 'isSharedWith')),
);

export const selectActiveShares = createSelector(selectShares, (shares) =>
  shares.filter((share) => share.get('isActive')),
);

export const selectAlreadySharedShares = createSelector(
  selectShares,
  (shares) =>
    shares.filter((share) =>
      [shareStatus.pending.id, shareStatus.accepted.id].includes(
        share.getIn(['status', 'id']),
      ),
    ),
);

export const selectIsShareAccepted = createSelector(
  selectSourceProductVersion,
  (pv) => get(getLastShareStatus(pv), 'id') === shareStatus.accepted.id,
);

export const selectHasBeenAccepted = createSelector(
  selectSourceProductVersion,
  ({ targetProductStatus }) =>
    targetProductStatus === TargetProductStatusAccepted.id ||
    targetProductStatus === TargetProductStatusUpdated.id,
);

export const selectIsExportable = createSelector(
  selectSourceProductVersion,
  (pv) => hasTag(pv, TAG_EXPORTABLE),
);

export const selectIsPublished = createSelector(
  selectSourceProductVersion,
  (pv) => hasTag(pv, TAG_PUBLISHED),
);

export const selectIsPendingExclusivity = createSelector(
  selectSourceProductVersion,
  (pv) => hasTag(pv, TAG_PENDING_EXCLUSIVITY),
);

export const selectIsExclusive = createSelector(
  selectSourceProductVersion,
  (pv) => hasTag(pv, TAG_EXCLUSIVE),
);

export const selectGroupScopes = createSelector(
  selectModuleState,
  (state) => state.scopes,
);

export const selectSharedProductVersionId = createSelector(
  selectSourceProductVersion,
  getSharedProductVersionId,
);

// PERMISSIONS

export const selectPermissions = (state: GlobalState) => {
  const user = selectUser(state);
  return hasPermissionV3(user, FEATURE_PERMISSIONS_V3_PRODUCT)
    ? getf(
        [PRODUCT_PERMISSION, selectProductKeyId(state)],
        selectPermissionsV3(state),
      )
    : getf(['permissions'], selectProductVersion(state));
};

export const selectHasPermissions = flow(selectPermissions, Boolean);

const permissionV3 = (state: GlobalState) =>
  hasPermissionV3(selectUser(state), FEATURE_PERMISSIONS_V3_PRODUCT);

const selectHasPermissionV2 = (
  entityType: string,
  permission: string,
  state: GlobalState,
) =>
  getOrf([], ['permissions'], selectProductVersion(state)).includes(
    getPermissionKey(entityType, permission),
  );

const selectHasPermissionV3 = (
  entityType: string,
  permission: string,
  state: GlobalState,
) =>
  hasInstancePermissions(
    selectPermissionsV3(state),
    PRODUCT_PERMISSION,
    selectProductKeyId(state),
    getPermissionKey(entityType, permission),
    { modulePrefix: false },
  );

const selectHasPermission = curry(
  (entityType: string, permission: string, state: GlobalState) =>
    permissionV3(state)
      ? selectHasPermissionV3(entityType, permission, state)
      : selectHasPermissionV2(entityType, permission, state),
);

export const selectCanReadProduct = selectHasPermission(
  PRODUCT_PERMISSION,
  SHOW_PERMISSION,
);

export const selectCanUpdateProduct = selectHasPermission(
  PRODUCT_PERMISSION,
  UPDATE_PERMISSION,
);

export const selectCanPatchProduct = selectHasPermission(
  PRODUCT_PERMISSION,
  PATCH_PERMISSION,
);

export const selectCanShareProduct = selectHasPermission(
  PRODUCT_PERMISSION,
  CERTIFY_PERMISSION,
);

export const selectCanValidateProduct = selectHasPermission(
  PRODUCT_PERMISSION,
  VALIDATE_PERMISSION,
);

export const selectCanReadSharingUnit = selectHasPermission(
  SHARING_UNIT_PERMISSION,
  SHOW_PERMISSION,
);

export const selectCanUpdateSharingUnit = selectHasPermission(
  SHARING_UNIT_PERMISSION,
  UPDATE_PERMISSION,
);

export const selectCanValidateSharingUnitUpdates = (state: GlobalState) =>
  permissionV3(state)
    ? selectHasPermissionV3(
        SHARING_UNIT_PERMISSION,
        UPDATES_VALIDATE_PERMISSION,
        state,
      )
    : selectCanUpdateSharingUnit(state);

export const selectHasSharePermissions = selectHasPermission(
  PRODUCT_PERMISSION,
  CERTIFY_PERMISSION,
);

export const selectHasNormalizedCommentsPermission = (state: GlobalState) =>
  permissionV3(state)
    ? selectHasPermissionV3(
        PRODUCT_PERMISSION,
        NORMALIZEDCOMMENT_PERMISSION,
        state,
      )
    : selectCanUpdateProduct(state);

export const selectHasManageLabelPermission = (state: GlobalState) =>
  permissionV3(state)
    ? selectHasPermissionV3(
        PRODUCT_PERMISSION,
        USERLABEL_MANAGE_PERMISSION,
        state,
      )
    : selectCanUpdateProduct(state);

export const selectContentOwnerId = createSelector(
  selectSourceProductVersion,
  selectOrganizationId,
  (productVersion, organizationId) =>
    get(productVersion, 'tags.contentOwner.id') || organizationId,
);

export const selectKindParentIds = createSelector(
  selectKindParents,
  (kindParents) =>
    kindParents
      .map((parent) => parent.get('id'))
      .skipUntil((id) => id === KIND_BASE)
      .skip(1),
);

export const selectTargetMarketId = createSelector(
  selectSourceProductVersion,
  (productVersion) => get(productVersion, 'tags.targetMarket.id'),
);

export const selectTextileModelProductKeyId = createSelector(
  selectSourceProductVersion,
  (productVersion) => {
    return get(productVersion, 'textileModel.product_key_id');
  },
);

// TAGS

export const selectIsTaggedBy = createSelector(
  selectSourceProductVersion,
  getOrf([], 'isTaggedBy'),
);

// CONTENT OWNERS

export const selectAllContentOwners = createSelector(
  selectModuleState,
  (state) => state.contentOwners,
);

// SAVE

export const selectProductVersionToSave = createSelector(
  selectSourceProductVersion,
  selectEditedProductVersion,
  selectIncludedFields,
  selectSpecialFieldsToHandle,
  selectDefaultDisplayGroups,
  (source, edited, includedFields, specialFieldsToHandle, displayGroups) => {
    const version: any = {};
    for (const f of includedFields.toJS()) {
      if (isUndefined(edited[f])) {
        // If data is not present, it won't be erased so we need to send it as the default value.
        const field = findFieldByName(displayGroups, f)[0];
        version[f] =
          field && 'defaultValue' in field ? field.defaultValue : null;
      } else {
        version[f] = cloneDeepf(edited[f]);
      }
    }
    let segments;

    // Validate PSQs.
    specialFieldsToHandle.forEach((path) => {
      const value = get(version, path);
      if (path.includes('isPartitionedBy') && Array.isArray(value)) {
        value.forEach((psq) => {
          if (Array.isArray(psq.contains)) {
            const nutrientIdsToRemove: any[] = [];
            psq.contains.forEach((pnq) => {
              if (
                !get(pnq, 'quantity.0.expressedIn') ||
                (!get(pnq, 'quantity.0.data') &&
                  get(pnq, 'quantity.0.data') !== 0)
              ) {
                nutrientIdsToRemove.push(pnq.nutrientCode.id);
              }
            });
            nutrientIdsToRemove.forEach((nutrientId) => {
              const indexToRemove = psq.contains.findIndex(
                (f) => f.nutrientCode.id === nutrientId,
              );
              psq.contains.splice(indexToRemove, 1);
            });
          }
        });
      }
    });

    // Remove N.C. allergens from version
    if (version.allergenTypeList) {
      const allergenTypeList = getAllergenTypeList(version);
      const NC_ALLERGEN_ID = 3;
      version.allergenTypeList = allergenTypeList.filter(
        (allergen) => allergen.allergenPresenceCode.id !== NC_ALLERGEN_ID,
      );
    }

    // Drop the children units if the version is an each.
    if (
      (getTypePackagingId(version) !== undefined
        ? getTypePackagingId(version)
        : getTypePackagingId(source)) === TypePackagingLabels.EACH.id
    ) {
      version.isMadeOf = [];
    }

    // Extract segments.
    if (version.isClassifiedIn) {
      segments = { hierarchy: version.isClassifiedIn };
      delete version.isClassifiedIn;
    }

    return { version, segments };
  },
);

import { UserRole } from '@alkem/lib-front-model';

import i18n from 'utils/i18n';

export const USER_REDUCER_KEY = 'user';
export const USER_AUTHORIZED_REDUCER_KEY = 'userAuthorized';

interface UserRoleOption {
  label: string;
  value: UserRole;
}

export const userRoles: UserRoleOption[] = [
  {
    value: UserRole.ADMIN,
    label: i18n.t('frontproductstream.constants.user_role.admin', {
      defaultValue: 'Administrator',
    }),
  },
  {
    value: UserRole.LOCAL_ADMIN,
    label: i18n.t('frontproductstream.constants.user_role.local_admin', {
      defaultValue: 'Local Administrator',
    }),
  },
  {
    value: UserRole.USER,
    label: i18n.t('frontproductstream.constants.user_role.user', {
      defaultValue: 'User',
    }),
  },
];

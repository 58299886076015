import PropTypes from 'prop-types';
import { Component } from 'react';

import PNQItem from './pnq-item';
import './pnq-table.scss';

export class PNQTable extends Component {
  static propTypes = {
    onChange: PropTypes.func,
    entity: PropTypes.object.isRequired,
    entityKind: PropTypes.string.isRequired,
    entityId: PropTypes.oneOfType([PropTypes.string, PropTypes.number])
      .isRequired,
    field: PropTypes.object.isRequired,
    value: PropTypes.array,
    fieldExtraParams: PropTypes.object,
  };

  constructor(props) {
    super(props);
    this.onDeleteItem = this.onDeleteItem.bind(this);
    this.renderItem = this.renderItem.bind(this);
    this.dispatchChange = this.dispatchChange.bind(this);
  }

  shouldComponentUpdate(nextProps) {
    return (
      nextProps.value !== this.props.value ||
      nextProps.field !== this.props.field ||
      nextProps.fieldExtraParams !== this.props.fieldExtraParams
    );
  }

  onDeleteItem(index) {
    const { value } = this.props;
    const newValue = [...value];
    newValue.splice(index, 1);
    this.dispatchChange(newValue);
  }

  dispatchChange(newValue, isDirty = true, ignoreField = true) {
    const { onChange, field } = this.props;
    onChange(field.model, newValue, isDirty, ignoreField);
  }

  renderItem(value, index) {
    const { field, entity, entityId, entityKind, onChange, fieldExtraParams } =
      this.props;
    const localField = { ...field };
    localField.children = (localField.children || []).map((column) => {
      const enrichedColumn = { ...column };
      if (enrichedColumn.model) {
        enrichedColumn.model = `${field.model}.${index}.${enrichedColumn.model}`;
      }
      return enrichedColumn;
    });
    return (
      <div className="PNQTable__item" key={index}>
        <PNQItem
          entity={entity}
          entityId={entityId}
          entityKind={entityKind}
          field={localField}
          value={value}
          index={index}
          onDelete={this.onDeleteItem}
          onChange={onChange}
          fieldExtraParams={fieldExtraParams}
        />
      </div>
    );
  }

  render() {
    const { value } = this.props;
    return <div className="PNQTable">{value.map(this.renderItem)}</div>;
  }
}

export default PNQTable;

import { publish } from '@alkem/micro-frontend-tools';

import { OPEN_HELP_CENTER_EVENT } from '../constants';
import type { HelpState } from '../types';

export const openHelpCenter = (opt: Pick<HelpState, 'article' | 'module'>) => {
  publish<HelpState>(OPEN_HELP_CENTER_EVENT, { ...opt, isOpen: true });
};

export const closeHelpCenter = () => {
  publish<HelpState>(OPEN_HELP_CENTER_EVENT, { isOpen: false });
};

import { ReactNode } from 'react';

import './empty-state.scss';

export function PermissionsEmptyState({ children }: { children: ReactNode }) {
  return (
    <div
      className="PermissionsEmptyState"
      data-testid="permissions-empty-state"
    >
      <div className="PermissionsEmptyState__circle">
        <i className="PermissionsEmptyState__icon mdi mdi-alert" />
        <div className="PermissionsEmptyState__body">{children}</div>
      </div>
    </div>
  );
}

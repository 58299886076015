export const BULK_PREPARE_SHOW = 'bulk-prepare-for-channels/BULK_PREPARE_SHOW';
export const BULK_PREPARE_RESET =
  'bulk-prepare-for-channels/BULK_PREPARE_RESET';
export const BULK_PREPARE_UPDATE_PROGRESS =
  'bulk-prepare-for-channels/BULK_PREPARE_UPDATE_PROGRESS';

export const BULK_PREPARE_SELECT_GROUP =
  'bulk-prepare-for-channels/BULK_PREPARE_SELECT_GROUP';
export const BULK_PREPARE_UNSELECT_GROUP =
  'bulk-prepare-for-channels/BULK_PREPARE_UNSELECT_GROUP';

export const BULK_PREPARE_ASSIGN =
  'bulk-prepare-for-channels/BULK_PREPARE_ASSIGN';
export const BULK_PREPARE_ASSIGN_SUCCESS =
  'bulk-prepare-for-channels/BULK_PREPARE_ASSIGN_SUCCESS';
export const BULK_PREPARE_ASSIGN_FAILURE =
  'bulk-prepare-for-channels/BULK_PREPARE_ASSIGN_FAILURE';

import PropTypes from 'prop-types';
import { Component } from 'react';
import ImmutablePropTypes from 'react-immutable-proptypes';
import { connect } from 'react-redux';
import { createStructuredSelector } from 'reselect';

import { fetchReferential } from './actions';
import { selectReferential } from './selectors';

// TODO: add support for partial referential fetching

const mapStateToProps = createStructuredSelector({
  referential: (state, ownProps) => selectReferential(state)(ownProps.slug),
});

const mapDispatchToProps = {
  fetchReferential,
};

export class WithReferential extends Component {
  static propTypes = {
    children: PropTypes.func.isRequired,
    referential: ImmutablePropTypes.mapContains({
      entities: ImmutablePropTypes.list.isRequired,
      entitiesById: ImmutablePropTypes.map.isRequired,
    }),
    slug: PropTypes.string.isRequired,
    fetchReferential: PropTypes.func.isRequired,
  };

  componentDidMount() {
    this.props.fetchReferential(this.props.slug);
  }

  render() {
    const { referential, children } = this.props;
    if (!referential) {
      return null;
    }
    return children({
      entities: referential.get('entities'),
      entitiesById: referential.get('entitiesById'),
    });
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(WithReferential);

import { NotificationApi } from '@alkem/sdk-dashboard';

import { getUserLanguage as acceptLanguage } from 'utils/i18n';

const options = {
  acceptLanguage,
  cacheEnabled: true,
};

export const notificationApi = new NotificationApi(options);

export const userContentUpsert = (consents: object) =>
  notificationApi.post(`/notification/v1/userconsent`, consents);

import classNames from 'classnames';
import { connect } from 'react-redux';

import Field from 'components/ui/form/field';
import StringList from 'components/ui/input/string-list';

import './string-list.scss';

export class FormStringList extends Field {
  componentDidUpdate() {
    this.ensureDataIsPresent();
  }

  onChange = (value) => {
    this.dispatchChange(value);
  };

  render() {
    const { value, field } = this.props;
    if (!this.isVisible()) {
      return null;
    }
    const displayPlaceholder = this.shouldDisplayItem('placeholder');
    const renderedLabel = this.renderLabel('col-xs-4');
    const classes = {
      InputField__input: true,
      'col-xs-8': !!renderedLabel,
      'col-xs-12': !renderedLabel,
    };
    return (
      <div
        className={classNames(
          this.getClasses({ FormStringList: true, row: true }),
        )}
      >
        {this.renderLabel('col-xs-4')}
        <div className={classNames(classes)}>
          <StringList
            id={this.getId()}
            value={value}
            onChange={this.onChange}
            placeholder={displayPlaceholder ? field.placeholder : null}
            disabled={this.isReadOnly()}
          />
          {this.renderPlugins()}
        </div>
      </div>
    );
  }
}

export default connect()(FormStringList);

import { Filter } from 'core/modules/list/constants/filters/types';
import { TemplateStatus } from 'modules/sharing-unit-templates/constants';
import i18n from 'utils/i18n';

export const retailerFilter: Filter = {
  key: 'sharing-unit-template-retailer',
  isAvailable: () => true,
  query: 'retailer',
};

export const DATE_FILTER_KEY = 'sharingunit-template-date';
export const STATUS_FILTER_KEY = 'sharingunit-template-status';
export const sharingUnitTemplatefilterKeys = [
  DATE_FILTER_KEY,
  STATUS_FILTER_KEY,
];

export const SourceStatusFilters = [
  {
    id: TemplateStatus.DRAFT,
    code: TemplateStatus.DRAFT,
    label: i18n.t(
      'frontproductstream.sharingunit_template_constant.status_draft.label',
      { defaultValue: 'Draft' },
    ),
  },
  {
    id: TemplateStatus.PUBLISHED,
    code: TemplateStatus.PUBLISHED,
    label: i18n.t(
      'frontproductstream.sharingunit_template_constant.status_published.label',
      { defaultValue: 'Published' },
    ),
  },
  {
    id: TemplateStatus.ARCHIVED,
    code: TemplateStatus.ARCHIVED,
    label: i18n.t(
      'frontproductstream.sharingunit_template_constant.status_archived.label',
      { defaultValue: 'Archived' },
    ),
  },
  {
    id: TemplateStatus.UPDATE_TO_BE_PUBLISHED,
    code: TemplateStatus.UPDATE_TO_BE_PUBLISHED,
    label: i18n.t(
      'frontproductstream.sharingunit_template_constant.status_update_to_be_published.label',
      { defaultValue: 'Update to be published' },
    ),
  },
];

import classnames from 'classnames';
import { MouseEventHandler, useCallback, useState } from 'react';

import { ClickOutside } from '@alkem/react-ui-click-outside';

import './style.scss';

interface Item {
  label: string;
  disabled?: boolean;
  onClick?: MouseEventHandler;
  onChildClick?: () => void;
  children?: (Item | null)[];
  className?: string;
  firstLevel?: boolean;
}

interface MultiLevelDropDownProps {
  items: (Item | null)[];
  disabled?: boolean;
}

export default function MultiLevelDropDown(props: MultiLevelDropDownProps) {
  return (
    <ul
      className={classnames({
        MultiLevelDropdown: true,
        'MultiLevelDropdown--disabled': props.disabled,
      })}
    >
      {props.items.map((item) =>
        item ? (
          <DropDownItem
            key={item.label}
            firstLevel
            className="MultiLevelDropdown__toggle MultiLevelDropdown__toggle--bottom"
            {...item}
            disabled={item.disabled || props.disabled}
          />
        ) : null,
      )}
    </ul>
  );
}

function DropDownItem(item: Item) {
  const [open, setOpen] = useState(false);

  const onChildClick = useCallback(() => {
    setOpen(false);
    item?.onChildClick?.();
  }, [item]);

  const onClick = useCallback(
    (event) => {
      if (!item.disabled) {
        if (item.children?.length) {
          setOpen(!open);
        } else {
          item?.onClick?.(event);
          onChildClick();
        }
      }
    },
    [item, onChildClick, open],
  );

  const onClickOutside = useCallback(() => {
    setOpen(false);
  }, []);

  return (
    <ClickOutside onClickOutside={onClickOutside}>
      <li
        key={item.label}
        className={classnames(
          {
            'flyout-alt MultiLevelDropdown__toggle--right':
              item.children?.length && !item.firstLevel,
            'MultiLevelDropdown__toggle--open': open,
          },
          item.className,
        )}
      >
        <span
          onClick={onClick}
          className={classnames({
            MultiLevelDropdown__label: true,
            'MultiLevelDropdown__label--disabled': item.disabled,
          })}
        >
          {item.label}
        </span>
        {item.children?.length && open && (
          <ul className="MultiLevelDropdown__content MultiLevelDropdown__content--stacked">
            {item.children.map((child) =>
              child ? (
                <DropDownItem
                  key={child.label}
                  {...child}
                  onChildClick={onChildClick}
                />
              ) : null,
            )}
          </ul>
        )}
      </li>
    </ClickOutside>
  );
}

import classNames from 'classnames';
import { useSelector } from 'react-redux';

import HelpTooltip from '@alkem/react-ui-helptooltip';

import { AnchoredSection } from 'modules/anchored';
import 'modules/data-ops/components/deprecated-data-ops/index.module.scss';
import { selectDataOpsPatches } from 'modules/data-ops/selectors';
import { DataOpsDiffStatus, DataOpsPatch } from 'modules/data-ops/types';
import { RELEASE_B2K_COLLAB_EDITS_PDP_SUMMARY } from 'modules/feature-flag';
import { selectHasFeature } from 'modules/feature-flag/selectors';
import { selectIsRetailer } from 'modules/user';
import i18n from 'utils/i18n';

import styles from './index.module.scss';

interface Props {
  order: number;
}

export const DataOpsSummary = ({ order }: Props) => {
  const isRetailer = useSelector(selectIsRetailer);
  const userHasFeature = useSelector(
    selectHasFeature(RELEASE_B2K_COLLAB_EDITS_PDP_SUMMARY),
  );

  const patches = useSelector(selectDataOpsPatches);

  if (!isRetailer || !userHasFeature || !Object.keys(patches).length) {
    return null;
  }

  const patchesByStatus = {
    accepted: 0,
    refused: 0,
    pending: 0,
    disputed: 0,
  };

  Object.values(patches).forEach((patchGroup) => {
    (patchGroup?.data || []).forEach((patch) => {
      switch ((patch as DataOpsPatch).diffStatus) {
        case DataOpsDiffStatus.PATCH_RESOLVED_STATUS:
          patchesByStatus.accepted += 1;
          break;
        case DataOpsDiffStatus.PATCH_IGNORED_STATUS:
          patchesByStatus.refused += 1;
          break;
        case DataOpsDiffStatus.PATCH_DISPUTE_STATUS:
          patchesByStatus.disputed += 1;
          break;
        default:
          if ((patch as DataOpsPatch).refusedAt) {
            patchesByStatus.refused += 1;
          } else {
            patchesByStatus.pending += 1;
          }
      }
    });
  });

  const title = i18n.t(
    'frontproductstream.product_dataops_patches_summary.section.title',
    { defaultValue: 'Modifications requested' },
  );

  const disputeSectionTitle = i18n.t(
    'frontproductstream.product_dataops_patches_summary.dispute_section.title',
    { defaultValue: 'Supplier updates to process' },
  );
  const disputeSectionHelp = i18n.t(
    'frontproductstream.product_dataops_patches_summary.dispute_section_help.text',
    {
      defaultValue:
        'Number of counterproposals you received from your supplier for the fields you corrected. These counterproposals represent adjustments suggested by your supplier in response to your initial corrections.',
    },
  );

  const trackingSectionTitle = i18n.t(
    'frontproductstream.product_dataops_patches_summary.tracking_section.title',
    { defaultValue: 'Tracking modification requests' },
  );
  const trackingSectionHelp = i18n.t(
    'frontproductstream.product_dataops_patches_summary.tracking_section_help.text',
    {
      defaultValue:
        'Number of correction requests that you have issued as a distributor. You can track the status of each request and check if it has been processed by the supplier.',
    },
  );

  const disputedText = i18n.t(
    'frontproductstream.product_dataops_patches_summary.disputed.text',
    { defaultValue: 'to process' },
  );
  const acceptedText = i18n.t(
    'frontproductstream.product_dataops_patches_summary.accepted.text',
    { defaultValue: 'accepted' },
  );
  const refusedText = i18n.t(
    'frontproductstream.product_dataops_patches_summary.refused.text',
    { defaultValue: 'refused' },
  );
  const pendingText = i18n.t(
    'frontproductstream.product_dataops_patches_summary.pending.text',
    { defaultValue: 'pending' },
  );

  return (
    <AnchoredSection
      collapsible
      section={title}
      id="patches-summary"
      order={order}
    >
      <div className={styles.summary}>
        <div className={styles.section}>
          <div className={styles.header}>
            <h4 className={styles.title}>{disputeSectionTitle}</h4>
            <HelpTooltip id="help-dispute" message={disputeSectionHelp} />
          </div>
          <div className={styles.content}>
            <div
              className={styles.statusSummary}
              data-testid="data-ops-summary-status-disputed"
            >
              <span
                className={`mdi mdi-format-list-checks alk-txt-primary ${styles.icon}`}
              />
              <span className={styles.number}>{patchesByStatus.disputed}</span>
              <span className={styles.statusText}>{disputedText}</span>
            </div>
          </div>
        </div>
        <div className={classNames(styles.section, styles.trackingSection)}>
          <div className={styles.header}>
            <h4 className={styles.title}>{trackingSectionTitle}</h4>
            <HelpTooltip id="help-tracking" message={trackingSectionHelp} />
          </div>
          <div className={styles.content}>
            <div
              className={styles.statusSummary}
              data-testid="data-ops-summary-status-accepted"
            >
              <span
                className={`mdi mdi-check-circle alk-txt-success ${styles.icon}`}
              />
              <span className={styles.number}>{patchesByStatus.accepted}</span>
              <span className={styles.statusText}>{acceptedText}</span>
            </div>
            <div className={styles.separator} />
            <div
              className={styles.statusSummary}
              data-testid="data-ops-summary-status-refused"
            >
              <span
                className={`mdi mdi-close-circle alk-txt-danger ${styles.icon}`}
              />
              <span className={styles.number}>{patchesByStatus.refused}</span>
              <span className={styles.statusText}>{refusedText}</span>
            </div>
            <div className={styles.separator} />
            <div
              className={styles.statusSummary}
              data-testid="data-ops-summary-status-pending"
            >
              <span className={`mdi mdi-clock alk-txt-orange ${styles.icon}`} />
              <span className={styles.number}>{patchesByStatus.pending}</span>
              <span className={styles.statusText}>{pendingText}</span>
            </div>
          </div>
        </div>
      </div>
    </AnchoredSection>
  );
};

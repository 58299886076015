import { MODULE_REDUCER_KEY } from 'constants/reducer';

import { TARGET_CATALOG_IMPORT_MODAL_STORE_KEY } from './constants';

// ---------
// SELECTORS
// ---------

export function selectState(state) {
  return state[MODULE_REDUCER_KEY][TARGET_CATALOG_IMPORT_MODAL_STORE_KEY];
}

export function selectModalIsOpen(state) {
  return selectState(state).get('modalIsOpen');
}

export function selectUploadingFile(state) {
  return selectState(state).get('uploadingFile');
}

export function selectModalSelectedFile(state) {
  return selectState(state).get('file');
}

export function selectModalSelectedFileFormat(state) {
  return selectState(state).get('fileFormat');
}

export function selectModalReportID(state) {
  return selectState(state).get('reportID');
}

import classNames from 'classnames';
import { useEffect, useState } from 'react';
import { useController } from 'react-hook-form';
import { useSelector } from 'react-redux';

import { Checkbox } from '@alkem/react-ui-checkbox';
import { ClickOutside } from '@alkem/react-ui-click-outside';

import { selectRecipients } from 'modules/recipients';
import { useGetRecipients } from 'modules/recipients/hooks';
import { selectIsRetailer } from 'modules/user';
import i18n from 'utils/i18n';

import styles from './AssetRecipients.module.scss';

const PLURAL_THRESHOLD = 2;

export const AssetRecipients = () => {
  const [isPanelOpen, setIsPanelOpen] = useState(false);
  const [searchText, setSearchText] = useState('');

  const name = 'recipientIds';

  const {
    field: { onChange, value },
  } = useController({ name });

  const recipients = useGetRecipients(value);

  const isRetailer = useSelector(selectIsRetailer);

  const secondaryRecipientsImmutable = useSelector(selectRecipients);

  const allOptions = secondaryRecipientsImmutable
    .map((r) => ({
      id: r?.get('id') || 0,
      label: r?.get('nameLegal') || '',
    }))
    .toArray();

  useEffect(() => {
    if (!isPanelOpen) {
      setSearchText('');
    }
  }, [isPanelOpen]);

  const getValueText = () => {
    if (!recipients.length) {
      return i18n.t('frontproducstream.asset_list.modal.shared_with.none', {
        defaultValue: 'Not shared yet',
      });
    }

    return recipients.length > PLURAL_THRESHOLD
      ? i18n.t('frontproducstream.asset_list.modal.shared_with.plural', {
          count: recipients.length,
          defaultValue: '{{count}} recipients',
        })
      : recipients.map((r) => r.nameLegal).join(', ');
  };

  const handleTogglePanel = () => {
    setIsPanelOpen(!isPanelOpen);
  };

  const handleToggle = (recipientId, isChecked) => {
    if (isChecked) {
      onChange([...value, recipientId]);
    } else {
      onChange(value.filter((id) => id !== recipientId));
    }
  };

  if (isRetailer) return null;

  const filteredOptions = allOptions.filter((r) =>
    r.label.toLowerCase().includes(searchText.toLowerCase()),
  );

  return (
    <div className={styles.wrapper}>
      <label>
        {i18n.t('frontproducstream.asset_list.modal.shared_with.label', {
          defaultValue: 'Shared with',
        })}
      </label>
      <ClickOutside onClickOutside={() => setIsPanelOpen(false)}>
        <div className={styles.relativeWrapper}>
          <div className={styles.inputWrapper} onClick={handleTogglePanel}>
            <span className={styles.inputValue}>{getValueText()}</span>
            <i
              className={`mdi ${
                isPanelOpen ? 'mdi-chevron-up' : 'mdi-chevron-down'
              }`}
            />
          </div>
          {isPanelOpen && (
            <div className={styles.panel}>
              <div className={styles.panelHeader}>
                <i className={`mdi mdi-magnify ${styles.searchIcon}`} />
                <input
                  type="text"
                  className={styles.searchInput}
                  placeholder={i18n.t(
                    'frontproducstream.asset_list.modal.shared_with_search.placeholder',
                    { defaultValue: 'Start typing to search' },
                  )}
                  value={searchText}
                  onChange={(e) => setSearchText(e.target.value)}
                />
                <button
                  type="button"
                  onClick={() => setSearchText('')}
                  className={classNames(styles.close, {
                    [styles.hidden]: !searchText,
                  })}
                >
                  <i className="mdi mdi-close" />
                </button>
              </div>
              <ul className={styles.itemList}>
                {filteredOptions.map((r) => (
                  <li key={r.id} className={styles.item}>
                    <label className={styles.itemClickable}>
                      <Checkbox
                        id={String(r.id)}
                        checked={value.includes(r.id)}
                        onChange={(isChecked) => handleToggle(r.id, isChecked)}
                      />
                      <span className={styles.itemText}>{r.label}</span>
                    </label>
                  </li>
                ))}
              </ul>
            </div>
          )}
        </div>
      </ClickOutside>
    </div>
  );
};

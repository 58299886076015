import { fromJS } from 'immutable';
import { compose } from 'redux';
import { createReducer } from 'redux-create-reducer';

import {
  initSelectedFilterState,
  updateSelectedFilters,
} from 'core/modules/list/reducers/filters';
import {
  collapseFilters,
  initFiltersConfigState,
  updateDuplicatedProducts,
  updateFilteredFilters,
  updateFiltersPagination,
  updateWithArchivedProducts,
} from 'core/modules/list/reducers/filters-config';
import {
  createSelectEntitiesReducer,
  resetPage,
} from 'core/modules/list/reducers/list';
import {
  initPaginationState,
  updateItemsPerPage,
} from 'core/modules/list/reducers/pagination';

import {
  COLLAPSE_FILTERS,
  EXPORT_REPORT,
  EXPORT_REPORT_DONE,
  NEXT_PAGE,
  PREVIOUS_PAGE,
  RECEIVE_PRODUCTS_AGGREGATIONS,
  RECEIVE_REPORT,
  RESET,
  SAVE_REPORT_CONFIG,
  SELECT_REPORT,
  SORT_REPORT,
  START_REPORTING_LOADER,
  STOP_REPORTING_LOADER,
  UPDATE_FILTERED_FILTERS,
  UPDATE_FILTERS_PAGINATION,
  UPDATE_ITEMS_PER_PAGE,
  UPDATE_ONLY_DUPLICATED_PRODUCTS,
  UPDATE_SELECTED_FILTERS,
  UPDATE_WITH_ARCHIVED_PRODUCTS,
} from '../actions/types';
import { FILTERS_CONFIG_KEY, ITEMS_PER_PAGE_KEY } from '../constants';

import { receiveProductsAggregations } from './products';
import {
  extractReportList,
  nextPage,
  previousPage,
  receiveReport,
  sortReport,
  updateTotalPages,
} from './reports';

export const initialState = fromJS({
  columnList: [],
  reportList: [],
  reportFullList: [],
  reportMap: {},
  aggregations: {},
  selectedMap: {},
  exportInProgress: false,
  reportAggregation: undefined,
  isDemo: false,
  charts: [],
  loading: false,
});

const enhanceInitialState = compose(
  initFiltersConfigState(FILTERS_CONFIG_KEY),
  initPaginationState(ITEMS_PER_PAGE_KEY),
  initSelectedFilterState,
);

export default createReducer(enhanceInitialState(initialState), {
  [SAVE_REPORT_CONFIG]: (state, action) =>
    state.set('reportConfig', action.payload),
  [RECEIVE_REPORT]: receiveReport,
  [PREVIOUS_PAGE]: previousPage,
  [NEXT_PAGE]: nextPage,
  [RECEIVE_PRODUCTS_AGGREGATIONS]: receiveProductsAggregations,
  [SORT_REPORT]: compose(extractReportList, sortReport),
  [EXPORT_REPORT]: (state) => state.set('exportInProgress', true),
  [EXPORT_REPORT_DONE]: (state) => state.set('exportInProgress', false),
  [COLLAPSE_FILTERS]: collapseFilters,
  [UPDATE_FILTERED_FILTERS]: updateFilteredFilters,
  [UPDATE_FILTERS_PAGINATION]: updateFiltersPagination,
  [UPDATE_ITEMS_PER_PAGE]: compose(
    resetPage,
    extractReportList,
    updateTotalPages,
    updateItemsPerPage,
  ),
  [UPDATE_SELECTED_FILTERS]: updateSelectedFilters,
  [SELECT_REPORT]: createSelectEntitiesReducer('reportList'),
  [UPDATE_WITH_ARCHIVED_PRODUCTS]: updateWithArchivedProducts,
  [UPDATE_ONLY_DUPLICATED_PRODUCTS]: updateDuplicatedProducts,
  [RESET]: (state) => state.merge(initialState),
  [START_REPORTING_LOADER]: (state) => state.set('loading', true),
  [STOP_REPORTING_LOADER]: (state) => state.set('loading', false),
});

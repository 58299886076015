import { useSelector } from 'react-redux';

import { PictureAssetV2 } from 'components/ui/form/asset-list/AssetV2/types';
import { validatePicture } from 'modules/assets/components/picture/utils';
import { selectHasJsonStoreInputFeature } from 'modules/feature-flag/selectors';

type PictureErrorsProps = {
  asset: PictureAssetV2;
};

export const PictureErrors = ({ asset }: PictureErrorsProps) => {
  const hasJsonStoreInputFeature = useSelector(selectHasJsonStoreInputFeature);

  const errors = validatePicture(asset);

  if (!errors.length || hasJsonStoreInputFeature) {
    return null;
  }

  return (
    <div className="FormField--raguelError">
      <div className="Raguel">
        {errors.map((error) => (
          <span className="Raguel__message" key={`${error}-${asset.crc32}`}>
            {' '}
            {error}{' '}
          </span>
        ))}
      </div>
    </div>
  );
};

import { throttle } from 'lodash';
import moment from 'moment';
import { Component } from 'react';

import { SETTING_UI_MAX_IDLE_TIME } from 'constants/organization-settings';
import { getOrganizationSettingByKey } from 'core/api/user';
import { logout } from 'routes';
import { UserImmutable } from 'types';
import { getPathname, getSearch, push } from 'utils/history';
import { withQuery } from 'utils/query';
import storage from 'utils/storage';

import { LAST_ACTIVITY_KEY } from './constants';
import { resetLastActivityStorage } from './utils';

export const MIN_IDLE_TIME = 10; // as minutes
export const THROTTLE_WAIT: number = moment
  .duration(10, 'seconds')
  .asMilliseconds();
export const INTERVAL_TIME: number = moment
  .duration(1, 'minutes')
  .asMilliseconds();

interface Props {
  user: UserImmutable;
}

export default class IdlenessTracker extends Component<Props> {
  public storeLastActivity = throttle(() => {
    storage.setItem(LAST_ACTIVITY_KEY, moment().toISOString());
  }, THROTTLE_WAIT);

  public interval?: NodeJS.Timeout;

  public componentDidMount() {
    this.initTracker();
  }

  public componentDidUpdate(prevProps) {
    if (this.props.user.get('id') !== prevProps.user.get('id')) {
      this.initTracker();
    }
  }

  public componentWillUnmount() {
    this.resetTracker();
  }

  public initTracker() {
    const maxIdleTime = getOrganizationSettingByKey(
      this.props.user,
      SETTING_UI_MAX_IDLE_TIME,
    );
    this.resetTracker();
    if (Number.isInteger(maxIdleTime) && maxIdleTime >= MIN_IDLE_TIME) {
      this.trackIdleness(
        moment.duration(maxIdleTime, 'minutes').asMilliseconds(),
      );
    }
  }

  public clearInterval(): void {
    if (this.interval) {
      clearInterval(this.interval);
    }
  }

  public isIdle(maxIdleTime: number): boolean {
    const lastActivity = moment(storage.getItem(LAST_ACTIVITY_KEY));
    const time = moment().diff(lastActivity);
    return time > maxIdleTime;
  }

  public checkIdleness(maxIdleTime: number): void {
    if (this.isIdle(maxIdleTime)) {
      this.resetTracker({ resetStorage: true });
      push(withQuery(logout, { next: `${getPathname()}${getSearch()}` }));
    }
  }

  public resetTracker({ resetStorage = false } = {}): void {
    this.clearInterval();
    this.removeActivityEventListeners();
    if (resetStorage) {
      resetLastActivityStorage();
    }
  }

  public addActivityEventListeners(): void {
    window.addEventListener('mousemove', this.storeLastActivity);
    window.addEventListener('keyup', this.storeLastActivity);
  }

  public removeActivityEventListeners(): void {
    window.removeEventListener('mousemove', this.storeLastActivity);
    window.removeEventListener('keyup', this.storeLastActivity);
  }

  public trackIdleness(maxIdleTime: number): void {
    this.checkIdleness(maxIdleTime);
    this.addActivityEventListeners();
    this.clearInterval();
    this.interval = setInterval(() => {
      this.checkIdleness(maxIdleTime);
    }, INTERVAL_TIME);
  }

  public render() {
    return null;
  }
}

import classNames from 'classnames';
import PropTypes from 'prop-types';
import { PureComponent } from 'react';
import ImmutablePropTypes from 'react-immutable-proptypes';

import { Authentication } from '@alkem/sdk-dashboard';

import HppModal from 'components/hpp/modal';
import Modal from 'components/ui/modal';
import {
  getIsDisplayableForTarget,
  getIsEligibleForSourcing,
  getOwnerId,
  getOwnerName,
  getOwnerUuid,
  getProductGTIN,
} from 'core/api/productversion';
import { isSuperAdmin } from 'core/api/user';
import { hasFeature } from 'modules/feature-flag';
import { getHPPSrcByGtin } from 'utils/hpp';
import i18n from 'utils/i18n';
import { get } from 'utils/immutable';
import { track } from 'utils/tracking';

import './index.scss';
import SourcingPreview from './sourcing-preview';

export default class SourcingCellHppActionWrapper extends PureComponent {
  static propTypes = {
    user: ImmutablePropTypes.map.isRequired,
    productVersion: ImmutablePropTypes.map.isRequired,
    className: PropTypes.string,
    children: PropTypes.node,
  };

  state = {
    previewOpened: false,
  };

  togglePreviewModal = () => {
    const { productVersion } = this.props;
    this.setState((prevState) => ({ previewOpened: !prevState.previewOpened }));
    if (!this.state.previewOpened) {
      track({
        category: 'sourcing',
        action: 'sourcing_hpp_viewed',
        reference: getProductGTIN(productVersion),
      });
    }
  };

  renderHPPModal() {
    const { productVersion, user } = this.props;
    const token = Authentication.getAccessTokenByPriority();
    const isDisplayableForTarget = getIsDisplayableForTarget(productVersion);
    const organizationId = isDisplayableForTarget
      ? 'default'
      : getOwnerId(productVersion);
    const hppSrc = getHPPSrcByGtin(
      getProductGTIN(productVersion),
      organizationId,
      token,
      user,
    );
    return <HppModal onClose={this.togglePreviewModal} hppSrc={hppSrc} />;
  }

  renderPreviewModal() {
    const { productVersion } = this.props;
    if (typeof get(productVersion, 'id') !== 'number') {
      return null;
    }
    return (
      <Modal
        hideFooter
        modalStyle="hpp"
        className="SourcingPreviewModal"
        title={i18n.t('Hosted product page')}
        onClose={this.togglePreviewModal}
      >
        <SourcingPreview
          productVersionId={get(productVersion, 'id')}
          organizationUuid={getOwnerUuid(productVersion)}
          organizationName={getOwnerName(productVersion)}
          reference={getProductGTIN(productVersion)}
        />
      </Modal>
    );
  }

  renderModal() {
    const { user } = this.props;
    return hasFeature(user, 'release-fnd-sourcing-product-preview')
      ? this.renderPreviewModal()
      : this.renderHPPModal();
  }

  render() {
    const { user, children, className, productVersion } = this.props;
    const { previewOpened } = this.state;

    if (!getIsEligibleForSourcing(productVersion) && !isSuperAdmin(user)) {
      return children;
    }

    return (
      <div>
        <span
          className={classNames(
            'CatalogCellActionWrapper',
            'CatalogCellActionWrapper--link',
            className,
          )}
          onClick={this.togglePreviewModal}
        >
          {children}
        </span>
        {previewOpened && this.renderModal()}
      </div>
    );
  }
}

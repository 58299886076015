import PropTypes from 'prop-types';
import { PureComponent } from 'react';
import ImmutablePropTypes from 'react-immutable-proptypes';

import ProductReference from 'components/ui/product-reference';
import { getDefaultDisplayName, getProductGTIN } from 'core/api/productversion';
import { getShortId } from 'core/api/retailerproductversion';

import './product-info.scss';

export default class BulkPrepareForChannelsProductInfo extends PureComponent {
  static propTypes = {
    productVersion: ImmutablePropTypes.map.isRequired,
    locales: PropTypes.object.isRequired,
  };

  render() {
    const { productVersion, locales } = this.props;
    const shortId = getShortId(productVersion);
    return (
      <li className="BulkPrepareForChannelsProductInfo">
        <strong>{getDefaultDisplayName(productVersion, locales)}</strong>
        <span className="BulkPrepareForChannelsProductInfo__separator">•</span>
        <ProductReference reference={getProductGTIN(productVersion)} />
        {!!shortId && (
          <span className="BulkPrepareForChannelsProductInfo__separator">
            •
          </span>
        )}
        {!!shortId && (
          <span className="BulkPrepareForChannelsProductInfo__shortid">
            {shortId}
          </span>
        )}
      </li>
    );
  }
}

import asyncComponent from 'core/modules/async/component';
import registerModule from 'core/modules/async/register';

export const NotificationList = asyncComponent(async () =>
  registerModule(
    await import(/* webpackChunkName: "notification-list" */ './async'),
  ),
);

export { default as notificationApi } from './resource';

import { List, Map } from 'immutable';
import { createReducer } from 'redux-create-reducer';

import { toComparableWithTrim } from 'utils/string';

import * as event from '../constants/types';

export const initialState = Map({
  isOpen: false,
  isLoading: false,
  form: Map({
    emails: List([]),
    allProducts: false,
    sourcingLabel: null,
    message: null,
  }),
});

export default createReducer(initialState, {
  [event.OPEN_MODAL]: (state) => state.set('isOpen', true),
  [event.CLOSE_MODAL]: () => initialState,
  [event.SET_LOADING]: (state, { payload }) => state.set('isLoading', payload),
  [event.UPDATE_MESSAGE]: (state, { payload }) =>
    state.setIn(['form', 'message'], payload),
  [event.UPDATE_ALL_PRODUCTS]: (state, { payload }) =>
    state.withMutations((newState) =>
      newState
        .setIn(['form', 'allProducts'], payload)
        .setIn(['form', 'sourcingLabel'], null),
    ),
  [event.UPDATE_SOURCINGLABEL]: (state, { payload }) =>
    state.setIn(['form', 'sourcingLabel'], payload),
  [event.ADD_EMAIL]: (state, { payload }) => {
    if (
      state
        .getIn(['form', 'emails'])
        .some(
          (curEmail) =>
            toComparableWithTrim(curEmail) === toComparableWithTrim(payload),
        )
    ) {
      return state;
    }
    return state.updateIn(['form', 'emails'], (emails) => emails.push(payload));
  },
  [event.REMOVE_EMAIL]: (state, { payload }) =>
    state.updateIn(['form', 'emails'], (emails) => emails.delete(payload)),
});

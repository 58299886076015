import { flatten, get } from 'lodash/fp';

import { notificationError } from 'actions/notification';
import { renderStepper } from 'core/components/errors-stepper';
import i18n from 'utils/i18n';
import { toJsIfImmutable } from 'utils/immutable';

function processVersionErrors(errors) {
  return errors.map((e) => ({
    ...e,
    path: e.path ? e.path.replace(/^\d+\.data\./, '') : e.path,
  }));
}

function processLogisticalHierarchiesErrors(errors, hierarchies) {
  const hierarchyData = hierarchies.map((h) => h.data);
  const rootPathsWithErrors = {};
  const cleanedErrors = errors.map((e) => {
    const errorPath = get(['path'], e) || '';
    const unitPathRes = errorPath.match(/^(?:\d|children|\.)+/);
    if (unitPathRes) {
      rootPathsWithErrors[unitPathRes[0][0]] = null;
    }
    if (!errorPath.includes('version.')) {
      return {
        ...e,
        path: `logisticalHierarchies.${errorPath}`,
      };
    }
    if (!unitPathRes) {
      return e;
    }
    const unitPath = unitPathRes[0].slice(0, unitPathRes[0].length - 1);
    const newErrorPath = errorPath.slice(unitPath.length + 1);
    const unit = get(unitPath.split('.'), hierarchyData);
    if (!unit || !get(['gtin'], unit)) {
      return e;
    }
    return {
      ...e,
      path: `${unit.gtin}.${newErrorPath.replace(/^version\./, '')}`,
    };
  });

  Object.keys(rootPathsWithErrors).forEach((p) => {
    cleanedErrors.unshift({
      message: i18n.t(
        'frontproductstream.save_utils.product.logistical_hierarchy_error',
        { defaultValue: 'Some errors were found in this logistical hierarchy' },
      ),
      path: `logisticalHierarchies.${p}.header`,
      displayable: true,
    });
  });

  return cleanedErrors;
}

function handlePriceWaterfallBrackets(path) {
  if (!path || !path.startsWith('priceWaterfalls')) {
    return path;
  }
  return path
    .replace(/\.brackets\.\d+(\.min)?$/, '.brackets')
    .replace(/\.values\.\d+\.value$/, '.values');
}

function processSharingUnitErrors(errors) {
  return flatten(
    errors.map((e) => {
      const suId = get(['sharingunit_id'], e);
      const errs = get(['errors'], e);
      if (suId && errs && errs.length) {
        return errs
          .filter((err) => err)
          .map((err) => ({
            ...err,
            path: `sharingunit.${suId}.${handlePriceWaterfallBrackets(
              err.path,
            )}`,
          }));
      }
      return [];
    }),
  );
}

function processSharingUnitTariffErrors(errors) {
  return errors.map((e) => ({
    ...e,
    path: handlePriceWaterfallBrackets(e.path),
  }));
}

function processPriceWaterfallLabelErrors(
  errors,
  sharingUnits,
  pricewaterfallLabels,
) {
  const seen = new Set();
  return errors
    .map((e) => {
      const labelIndex = (get(['path'], e) || '').split('.')[0];
      const label = get([labelIndex], pricewaterfallLabels.toJS());
      if (!label) {
        return null;
      }
      const sharingUnit = sharingUnits.find(
        (su) => su.id === label.sharingUnit_id,
      );
      if (!sharingUnit) {
        return null;
      }
      const pwIndex = sharingUnit.data.priceWaterfalls.findIndex(
        (pw) => pw.product.id === label.product_id,
      );
      if (pwIndex === -1) {
        return null;
      }
      return {
        ...e,
        path: `sharingunit.${sharingUnit.id}.priceWaterfalls.${pwIndex}.label`,
        value: label.label,
        displayable: true,
      };
    })
    .filter((e) => e)
    .filter((e) => {
      const duplicate = seen.has(e.id);
      seen.add(e.id);
      return !duplicate;
    });
}

export function mapSaveErrors(
  rawErrors,
  logisticalHierarchies,
  sharingUnits,
  pricewaterfallLabels,
) {
  return flatten(
    Object.entries(rawErrors).map(([key, errors]) => {
      switch (key) {
        case 'ProductVersion':
          return processVersionErrors(errors);
        case 'LogisticalHierarchies':
          return processLogisticalHierarchiesErrors(
            errors,
            logisticalHierarchies,
          );
        case 'SharingUnits':
          return processSharingUnitErrors(errors);
        case 'TemplateData':
          return processSharingUnitTariffErrors(errors);
        case 'PriceWaterfallLabels':
          return processPriceWaterfallLabelErrors(
            errors,
            sharingUnits,
            pricewaterfallLabels,
          );
        default:
          return errors;
      }
    }),
  );
}

export const processSaveErrors =
  ({ error, logisticalHierarchies, sharingUnits, pricewaterfallLabels }): any =>
  (dispatch) => {
    let errorMessage;
    if (error.status === 400 || error.status === 409) {
      errorMessage = i18n.t(
        'frontproductstream.save_utils.product.save_error',
        {
          defaultValue:
            'Some of the given information is incorrect, please correct before saving.',
        },
      );
      if (get(['data', 'errors'], error)) {
        try {
          const errors = mapSaveErrors(
            toJsIfImmutable(error.data.errors),
            logisticalHierarchies,
            sharingUnits,
            pricewaterfallLabels,
          );
          return dispatch(
            notificationError(renderStepper(errors), { sticky: true }),
          );
        } catch (e: any) {
          // At least display generic error message if something goes wrong.
          dispatch(notificationError(errorMessage, { error: e }));
          throw e;
        }
      } else if (pricewaterfallLabels.size > 0) {
        const labels = pricewaterfallLabels
          .map((e) => (e.present ? e.label : null))
          .toJS();
        if (labels) {
          errorMessage = `${i18n.t(
            'frontproductstream.save_utils.product.price_labels_save_error',
            {
              defaultValue:
                'Could not save price labels, the following price labels is already existing: ',
            },
          )} ${labels.join(', ')}`;
        }
      }
      // Display generic 400 message.
      return dispatch(notificationError(errorMessage, { error }));
    }

    if (error.status === 403) {
      errorMessage = i18n.t(
        'frontproductstream.save_utils.product.unauthorized_error',
        { defaultValue: 'You do not have the rights to save' },
      );
    } else {
      errorMessage = `${i18n.t(
        'frontproductstream.save_utils.product.generic_error',
        { defaultValue: 'An error occured while saving.' },
      )} ${i18n.t('frontproductstream.save_utils.product.retry_to_save', {
        defaultValue: 'Please retry',
      })}`;
    }
    return dispatch(notificationError(errorMessage, { error }));
  };

import classNames from 'classnames';
import PropTypes from 'prop-types';
import { Component } from 'react';
import { connect } from 'react-redux';
import { createStructuredSelector } from 'reselect';

import { Tipster } from '@alkem/react-ui-tipster';

import { Badge } from 'components/ui/badge';
import AssetList from 'components/ui/form/asset-list';
import {
  renderField,
  shouldRenderFieldBasedOnConditions,
} from 'components/ui/form/field/utils/render';
import OrganizationMedia from 'components/ui/form/media-organization';
import Description from 'components/ui/text/description';
import {
  DataOpsPatchPropType,
  DataOpsPatchesPropType,
  selectDataOpsPatch,
} from 'modules/data-ops';
import {
  FEATURE_DATA_OPS,
  FEATURE_FND_MULTILEVEL_FIELDS,
  FEATURE_PRO_RETAILER_FIELD_FLAG,
} from 'modules/feature-flag/constants';
import { selectHasReleaseProductpageFlywheelMedia } from 'modules/feature-flag/selectors';
import Textile from 'modules/product-page/modules/textile';
import {
  selectFlags,
  selectIsManufacturer,
  selectIsRetailer,
  selectOrganizationId,
} from 'modules/user';
import { selectAssetRuleErrorCount } from 'modules/validation';
import {
  selectCanPatchProduct,
  selectCanUpdateProduct,
  selectHasNormalizedCommentsPermission,
  selectProductOrganizationSourceId,
} from 'reducers/productVersion';
import i18n from 'utils/i18n';
import { get } from 'utils/immutable';

import CollapsibleFormGroupHeader from './collapsible-form-group-header';
import './form-group.scss';

const mapStateToProps = createStructuredSelector({
  flags: selectFlags,
  isRetailer: selectIsRetailer,
  isManufacturer: selectIsManufacturer,
  hasReleaseProductpageFlywheelMedia: selectHasReleaseProductpageFlywheelMedia,
  hasNormalizedCommentPermission: selectHasNormalizedCommentsPermission,
  hasProductUpdatePermission: selectCanUpdateProduct,
  hasProductPatchPermission: selectCanPatchProduct,
  assetRuleErrorCount: selectAssetRuleErrorCount,
  sourceOrganizationId: selectProductOrganizationSourceId,
  userOrganizationId: selectOrganizationId,
});

class FormGroup extends Component {
  static propTypes = {
    id: PropTypes.string,
    collapsible: PropTypes.bool,
    collapsed: PropTypes.bool,
    condensed: PropTypes.bool,
    deepIndex: PropTypes.number,
    entity: PropTypes.object.isRequired,
    entityId: PropTypes.oneOfType([PropTypes.string, PropTypes.number])
      .isRequired,
    entityKind: PropTypes.string.isRequired,
    formGroup: PropTypes.object.isRequired,
    borders: PropTypes.bool,
    excludeList: PropTypes.array,
    validate: PropTypes.bool,
    showTitle: PropTypes.bool,
    externalPlugins: PropTypes.array,
    flags: PropTypes.object.isRequired,
    customFieldRenderers: PropTypes.object,
    // Extra data that can cause a redender if a custom render is passed.
    customData: PropTypes.any,
    headerIndications: PropTypes.shape({
      errors: PropTypes.number,
      suggestions: PropTypes.number,
    }),
    recipientId: PropTypes.number,
    onChangeField: PropTypes.func,
    noDispatch: PropTypes.bool,
    entityIndex: PropTypes.number,
    patches: DataOpsPatchesPropType,
    patch: DataOpsPatchPropType,
    disableDataOps: PropTypes.bool,
    isRetailer: PropTypes.bool,
    sourceOrganizationId: PropTypes.number,
    userOrganizationId: PropTypes.number,
    isManufacturer: PropTypes.bool,
    isDisabled: PropTypes.bool,
    entityPermissions: PropTypes.object,
    hasNormalizedCommentPermission: PropTypes.bool,
    hasProductUpdatePermission: PropTypes.bool,
    hasProductPatchPermission: PropTypes.bool,
    hasReleaseProductpageFlywheelMedia: PropTypes.bool,
    assetRuleErrorCount: PropTypes.number,
    isInitialOnChangeDisabled: PropTypes.bool,
  };

  static defaultProps = {
    condensed: false,
    deepIndex: 0,
    borders: false,
    validate: true,
    showTitle: true,
    externalPlugins: null,
    flags: {},
    isDisabled: false,
    isInitialOnChangeDisabled: false,
  };

  state = {
    showHiddenFields: false,
  };

  shouldComponentUpdate(nextProps, nextState) {
    return (
      nextProps.entity !== this.props.entity ||
      nextProps.formGroup !== this.props.formGroup ||
      nextProps.collapsed !== this.props.collapsed ||
      nextState.showHiddenFields !== this.state.showHiddenFields ||
      nextProps.customFieldRenderers !== this.props.customFieldRenderers ||
      (!!nextProps.customFieldRenderers &&
        nextProps.customData !== this.props.customData) ||
      nextProps.patches !== this.props.patches ||
      nextProps.patch !== this.props.patch ||
      nextProps.onChangeField !== this.props.onChangeField ||
      nextProps.entityPermissions !== this.props.entityPermissions
    );
  }

  toggleShowHiddenFields = () => {
    this.setState((state) => ({
      showHiddenFields: !state.showHiddenFields,
    }));
  };

  renderItem(
    item,
    key,
    deepIndex,
    hasProductUpdatePermission,
    hasProductPatchPermission,
  ) {
    const itemWithDescription = {
      ...item,
      description: item.description ?? item.help,
    };

    switch (item.kind) {
      case 'DisplayGroup':
        return this.renderDisplayGroup(key, deepIndex, item);
      case 'Textile':
        return this.renderTextile(
          key,
          itemWithDescription,
          hasProductUpdatePermission,
          hasProductPatchPermission,
        );
      case 'AssetList':
        return this.renderAssetList(
          key,
          itemWithDescription,
          hasProductUpdatePermission,
          hasProductPatchPermission,
        );
      case 'Field':
        return this.renderField(
          key,
          itemWithDescription,
          hasProductUpdatePermission,
          hasProductPatchPermission,
        );
      case 'Message':
        return this.renderMessage(key, itemWithDescription);
      case 'MediaOrganization':
        return this.renderOrganizationMedia(key);
      default:
        return null;
    }
  }

  renderDisplayGroup(key, deepIndex, group) {
    const {
      entity,
      entityKind,
      entityId,
      excludeList,
      flags,
      validate,
      externalPlugins,
      customFieldRenderers,
      customData,
      onChangeField,
      noDispatch,
      entityIndex,
      patches,
      patch,
      disableDataOps,
      isRetailer,
      isManufacturer,
      isDisabled,
      recipientId,
      entityPermissions,
      hasNormalizedCommentPermission,
      hasProductUpdatePermission,
      hasProductPatchPermission,
    } = this.props;

    // Check if the field should be displayed if depending on other data.
    if (!shouldRenderFieldBasedOnConditions(group, entity)) {
      return null;
    }

    return (
      <FormGroup
        entity={entity}
        entityKind={entityKind}
        entityId={entityId}
        entityPermissions={entityPermissions}
        excludeList={excludeList}
        key={key}
        formGroup={group}
        deepIndex={deepIndex}
        validate={validate}
        externalPlugins={externalPlugins}
        flags={flags}
        customFieldRenderers={customFieldRenderers}
        customData={customData}
        onChangeField={onChangeField}
        noDispatch={noDispatch}
        entityIndex={entityIndex}
        patches={patches}
        patch={patch}
        disableDataOps={disableDataOps}
        isRetailer={isRetailer}
        isManufacturer={isManufacturer}
        isDisabled={isDisabled}
        recipientId={recipientId}
        hasNormalizedCommentPermission={hasNormalizedCommentPermission}
        hasProductUpdatePermission={hasProductUpdatePermission}
        hasProductPatchPermission={hasProductPatchPermission}
      />
    );
  }

  renderField(
    key,
    field,
    hasProductUpdatePermission,
    hasProductPatchPermission,
  ) {
    const {
      customFieldRenderers,
      entity,
      entityId,
      entityIndex,
      entityKind,
      entityPermissions,
      externalPlugins,
      flags,
      noDispatch,
      onChangeField,
      validate,
      patches,
      patch: patchProp,
      disableDataOps,
      isRetailer,
      sourceOrganizationId,
      userOrganizationId,
      isManufacturer,
      isDisabled,
      recipientId,
      hasNormalizedCommentPermission,
    } = this.props;
    let patch;
    let isPatched;
    let isPatchable;

    if (
      !disableDataOps &&
      field.level === 1 &&
      ((isRetailer && flags[FEATURE_DATA_OPS]) || isManufacturer)
    ) {
      patch = selectDataOpsPatch([entityId, field.model], patches);
    } else if (patchProp) {
      patch = patchProp;
    }

    if (patch) {
      isPatched = true;
      if (isRetailer) {
        isPatchable = patch.editable;
      }
    }

    const fieldExtraParams = {
      onChange: onChangeField,
      noDispatch,
      entityIndex,
      flags,
      patch,
      hasProductPatchPermission,
      isPatched,
      isPatchable,
      disableDataOps,
      isRetailer,
      isManufacturer,
      isDisabled,
      recipientId,
      entityPermissions,
      hasNormalizedCommentPermission,
      isInitialOnChangeDisabled: this.props.isInitialOnChangeDisabled,
      userOrganizationId,
    };
    if (isRetailer) {
      fieldExtraParams.sourceOrganizationId = sourceOrganizationId;
    }
    if (customFieldRenderers && customFieldRenderers[field.model]) {
      return customFieldRenderers[field.model](
        entity,
        entityKind,
        entityId,
        key,
        field,
        validate,
        hasProductUpdatePermission,
        externalPlugins,
        fieldExtraParams,
      );
    }
    return renderField(
      entity,
      entityKind,
      entityId,
      key,
      field,
      validate,
      hasProductUpdatePermission,
      externalPlugins,
      fieldExtraParams,
    );
  }

  renderOrganizationMedia(key) {
    const { entityId } = this.props;
    return <OrganizationMedia organizationId={entityId} key={key} />;
  }

  renderAssetList(key, assetList, hasProductUpdatePermission) {
    const { entity, entityKind, entityId } = this.props;
    return (
      <AssetList
        entity={entity}
        entityKind={entityKind}
        entityId={entityId}
        key={key}
        field={assetList}
        hasProductUpdatePermission={hasProductUpdatePermission}
      />
    );
  }

  renderTextile(key, displayGroup, hasProductUpdatePermission) {
    const { entity, entityKind, entityId } = this.props;
    return (
      <Textile
        entity={entity}
        entityKind={entityKind}
        entityId={entityId}
        key={key}
        displayGroup={displayGroup}
        hasProductUpdatePermission={hasProductUpdatePermission}
      />
    );
  }

  renderMessage(key, message) {
    const { type, label } = message;
    return (
      <Tipster
        className="FormGroupMessageTipster"
        key={key}
        type={type}
        info={label}
      />
    );
  }

  renderShowHiddenFieldsButton() {
    const { showHiddenFields } = this.state;
    return (
      <button
        key="button"
        type="button"
        className="FormGroup__showHiddenFieldsButton btn btn-secondary"
        onClick={this.toggleShowHiddenFields}
      >
        {showHiddenFields
          ? i18n.t(
              'frontproductstream.form_group.hide_extra_fields_button.label',
              { defaultValue: 'Hide additional fields' },
            )
          : i18n.t(
              'frontproductstream.form_group.show_extra_fields_button.label',
              { defaultValue: 'Show additional fields' },
            )}
      </button>
    );
  }

  render() {
    const {
      entity,
      entityId,
      flags,
      formGroup,
      condensed,
      borders,
      collapsible,
      collapsed,
      headerIndications,
      disableDataOps,
      isRetailer,
      hasProductPatchPermission,
      hasProductUpdatePermission,
      assetRuleErrorCount,
    } = this.props;

    const { showHiddenFields } = this.state;

    if (!formGroup) {
      return null;
    }

    const deepIndex = this.props.deepIndex + 1;
    const hasMultiLevel = flags[FEATURE_FND_MULTILEVEL_FIELDS];

    if (formGroup.kind && formGroup.kind !== 'DisplayGroup') {
      const key = `${entityId}-${
        formGroup.model || formGroup.label
      }-${deepIndex}`;
      return this.renderItem(
        formGroup,
        key,
        deepIndex,
        hasProductUpdatePermission,
        hasProductPatchPermission,
      );
    }

    const isHiddenByDefault = (field) => get(field, 'options.hiddenByDefault');

    const fields = formGroup.items
      .filter(
        (item) =>
          !hasMultiLevel || showHiddenFields || !isHiddenByDefault(item),
      )
      .map((item) => {
        const key = `${entityId}-${item.model || item.label}-${deepIndex}`;
        return this.renderItem(
          item,
          key,
          deepIndex,
          hasProductUpdatePermission,
          hasProductPatchPermission,
        );
      });

    const shouldRenderItem = formGroup.items.some((item) =>
      shouldRenderFieldBasedOnConditions(item, entity),
    );

    let title = (this.props.showTitle && formGroup.label) || '';
    // Set html block if title exists
    if (title) {
      switch (this.props.deepIndex) {
        case 0:
          title = collapsible ? (
            <CollapsibleFormGroupHeader
              formGroupId={formGroup.id}
              title={formGroup.label}
              collapsed={collapsed}
              headerIndications={headerIndications}
            />
          ) : this.props.hasReleaseProductpageFlywheelMedia &&
            formGroup.controller === 'media' &&
            assetRuleErrorCount ? (
            <div className="FormGroup__header-error-count">
              <h2 className="FormGroup__header-error-count__title">
                {formGroup.label}
              </h2>
              <Badge
                size="large"
                label={i18n.t(
                  'frontproductstream.form_group.error_count.label',
                  {
                    defaultValue: '{{count}} issues',
                    count: assetRuleErrorCount,
                  },
                )}
              />
            </div>
          ) : (
            <h2 className="FormGroup__header">{formGroup.label}</h2>
          );
          break;
        case 1:
          title = <h3>{formGroup.label}</h3>;
          break;
        default:
          title = null;
      }
    }

    const { description } = formGroup;
    // Deep index == 2 to handle synonyms block which is a form-group inception
    const containsElement = formGroup.items.length > 0;
    const shouldBeDisplayed = deepIndex === 2 && containsElement;

    let pluginsCount = 0;
    if (deepIndex === 1) {
      pluginsCount = [
        [
          FEATURE_DATA_OPS,
          isRetailer && !disableDataOps && hasProductPatchPermission,
        ],
        [FEATURE_PRO_RETAILER_FIELD_FLAG, hasProductUpdatePermission],
      ].reduce(
        (acc, [featureKey, hasPerm]) =>
          hasPerm && flags[featureKey] ? acc + 1 : acc,
        pluginsCount,
      );
    }

    return (
      <div
        id={
          this.props.id ||
          (formGroup.id ? `form-group-${formGroup.id}` : undefined)
        }
        className={classNames(
          'form-group',
          pluginsCount > 0 && `form-group--plugins${pluginsCount}`,
          {
            'card card-block': shouldBeDisplayed,
            'DisplayGroup--condensed': condensed,
            'DisplayGroup--borders': borders,
          },
        )}
        data-form-group-id={formGroup.id}
        data-deep-index={deepIndex}
        data-plugins-count={pluginsCount}
      >
        {shouldRenderItem && title}
        {collapsed ? null : (
          <>
            {shouldRenderItem && description && (
              <Description description={description} />
            )}
            {fields}
            {hasMultiLevel &&
              formGroup.items.some(isHiddenByDefault) &&
              this.renderShowHiddenFieldsButton()}
          </>
        )}
      </div>
    );
  }
}
export default connect(mapStateToProps)(FormGroup);

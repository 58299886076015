import PropTypes from 'prop-types';

import './description.scss';

function Description({ description }) {
  return <div className="text__description">{description}</div>;
}

Description.propTypes = {
  description: PropTypes.string.isRequired,
};

export default Description;

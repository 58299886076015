import { List, Map, Set, fromJS } from 'immutable';
import {
  call,
  put,
  putResolve,
  select,
  takeEvery,
  takeLatest,
} from 'redux-saga/effects';

import { updateSpecificAsset } from 'actions/media';
import { notificationError } from 'actions/notification';
import { CONSUMER_UNIT, DISPLAY_UNIT } from 'constants/fields';
import { ASSET_TYPE } from 'constants/media';
import {
  DISPLAY_GROUP_ROOTS,
  fetchSpecificDisplayGroups,
} from 'modules/display-groups/api';
import { RECEIVE_DISPLAY_GROUPS } from 'modules/display-groups/constants';
import {
  selectAreDisplayGroupsEditable,
  selectDefaultDisplayGroups,
  selectFieldByName,
} from 'modules/display-groups/selectors';
import { selectHasReleaseProductPageRefractStructureFetching } from 'modules/feature-flag/selectors';
import { RECEIVE_RECIPIENTS_LIST } from 'modules/recipients/constants';
import { getAll } from 'modules/recipients/reducers';
import { createSharingUnit } from 'modules/sharing-units/actions';
import { applyRulesForViewAsRecipients } from 'modules/validation';
import {
  selectCanUpdateProduct,
  selectCanUpdateSharingUnit,
  selectIsBaseUnit,
  selectIsConsumerUnit,
  selectIsDisplayUnit,
  selectKindId,
  selectProductKeyId,
  selectTypePackagingId,
} from 'reducers/productVersion';
import { fieldsApi, mediaApi } from 'resources';
import { requestWithHeaders } from 'utils/api';
import i18n from 'utils/i18n';
import { get } from 'utils/immutable';
import { logError } from 'utils/logging';
import { withQuery } from 'utils/query';
import { withCatch } from 'utils/saga';

import { activeAssetToggled, specificAssetsDeleted } from './actions';
import {
  ADD_OVERRIDE,
  ADD_RECIPIENT,
  DELETE_OVERRIDE,
  DELETE_SPECIFIC_ASSETS,
  RECEIVE_OVERRIDABLE_FIELDS,
  RECEIVE_SPECIFIC_FIELDS,
  STATUS_TOGGLED,
  TOGGLE_STATUS,
} from './events';

export function recursiveReadOnlyOverride(field, readOnly) {
  if (!field) {
    return null;
  }

  return field
    .set(
      'children',
      field
        .get('children', List())
        .map((f) => recursiveReadOnlyOverride(f, readOnly)),
    )
    .set('options', field.get('options', Map()).set('readOnly', readOnly));
}

export function* selectRecipientsOverridableFields() {
  const recipients = yield select(getAll);
  const isDisplayUnit = yield select(selectIsDisplayUnit);
  const fieldType = isDisplayUnit ? DISPLAY_UNIT : CONSUMER_UNIT;

  const fieldNames = fromJS(recipients).reduce(
    (acc, recipient) =>
      acc.union(
        recipient
          .get('usesFields', List())
          .filter(
            (f) => f.get('entityType') === fieldType && f.get('overridable'),
          )
          .map((f) => f.get('name')),
      ),
    Set(),
  );

  const dg = yield select(selectDefaultDisplayGroups);
  if (!dg.length) {
    // Not ready...
    return;
  }

  const canUpdateProduct = yield select(selectCanUpdateProduct);
  const fieldSelector = yield select(selectFieldByName);
  const fieldsPerName = fieldNames.reduce(
    (acc, f) =>
      acc.set(
        f,
        recursiveReadOnlyOverride(fromJS(fieldSelector(f)), !canUpdateProduct),
      ),
    Map(),
  );

  const fieldsPerRecipients = recipients
    .map((r) =>
      Map({
        id: r.get('id'),
        fields: r
          .get('usesFields')
          .filter(
            (f) => f.get('entityType') === fieldType && f.get('overridable'),
          )
          .map((f) => f.get('name')),
      }),
    )
    .reduce(
      (acc, r) =>
        acc.set(
          r.get('id'),
          r.update('fields', (fields) =>
            fields.map((f) => fieldsPerName.get(f)).filter((f) => f),
          ),
        ),
      Map(),
    );

  yield put({
    type: RECEIVE_OVERRIDABLE_FIELDS,
    fieldsPerRecipients,
  });
}

export function* fetchRecipientsSpecificFields() {
  const recipients = yield select(getAll);
  const recipientIds = recipients.map((r) => r.get('id')).toArray();

  if (!recipientIds.length) {
    return;
  }

  const canUpdateProduct = yield select(selectCanUpdateProduct);
  const isConsumerUnit = yield select(selectIsConsumerUnit);
  const isBaseUnit = yield select(selectIsBaseUnit);
  const isDisplayUnit = yield select(selectIsDisplayUnit);
  const kindId = yield select(selectKindId);
  const packagingTypeId = yield select(selectTypePackagingId);
  const areDisplayGroupsEditable = yield select(selectAreDisplayGroupsEditable);

  const hasReleaseProductPageRefractStructureFetching = yield select(
    selectHasReleaseProductPageRefractStructureFetching,
  );

  const isReadOnly = areDisplayGroupsEditable ? false : !canUpdateProduct;

  let specificDisplayGroups = [];

  if (hasReleaseProductPageRefractStructureFetching) {
    const displayGroupType = isConsumerUnit
      ? DISPLAY_GROUP_ROOTS.CONSUMER_UNIT
      : DISPLAY_GROUP_ROOTS.DISPLAY_UNIT;

    const response = yield call(fetchSpecificDisplayGroups, {
      recipientIds,
      displayGroupType,
      kindId,
      packagingTypeId,
      isReadOnly,
      isBaseUnit,
    });

    specificDisplayGroups = response.data;
  } else {
    const { result, error } = yield call(
      requestWithHeaders,
      fieldsApi,
      'post',
      withQuery('/core/v3/displaygroups/specific', {
        is_read_only: isReadOnly,
        is_consumer_unit: isConsumerUnit,
        is_display_unit: isDisplayUnit,
        kind_id: kindId,
        type_packaging_id: packagingTypeId,
      }),
      { target_organization_ids: recipientIds },
    );

    specificDisplayGroups = result.data.data;

    if (error) {
      yield put(
        notificationError(
          i18n.t(
            'frontproductstream.recipient_specific_block.fetch_recipients.error',
            { defaultValue: "Error fetching your recipients' specific fields" },
          ),
        ),
      );
      return;
    }
  }

  yield put({
    type: RECEIVE_SPECIFIC_FIELDS,
    fieldsByRecipient: specificDisplayGroups,
  });
}

export function* createSharingUnitSaga({ recipient, templateId }) {
  const canUpdateSharingUnit = yield select(selectCanUpdateSharingUnit);
  if (recipient.get('listing') && canUpdateSharingUnit) {
    yield putResolve(
      createSharingUnit({
        targetOrganizationId: recipient.get('id'),
        templateId,
      }),
    );
  }
}

const getMediaRoute = (category) => {
  switch (category) {
    case ASSET_TYPE.PICTURE:
      return mediaApi.UpsertSpecificPictureData;

    case ASSET_TYPE.VIDEO:
      return mediaApi.UpsertSpecificVideoData;

    case ASSET_TYPE.DOCUMENT:
      return mediaApi.UpsertSpecificDocumentData;

    case ASSET_TYPE.ENRICHED_CONTENT:
      return mediaApi.UpsertSpecificEnrichedContentData;

    default:
      throw new Error(`unsupported asset category: ${category}`);
  }
};

function* onToggleActiveAsset({ payload }) {
  const { assetId, category, isActive, recipientId } = payload;
  let response;

  try {
    response = yield call(
      getMediaRoute(category).bind(mediaApi),
      assetId,
      recipientId,
      { status: isActive ? 1 : 0 },
    );
  } catch (e) {
    yield put(
      notificationError(
        i18n.t('An error occured while changing the status for this asset.'),
      ),
    );
    logError(`ServerError ${e}`);
  } finally {
    yield put(activeAssetToggled(recipientId, category, assetId));
  }

  const asset = get(response, 'data.data');
  if (!asset) {
    logError('No asset update received from server or server error.');
    return;
  }
  if (asset.id !== assetId) {
    logError('Asset id does not match returned document.');
    return;
  }

  yield put(updateSpecificAsset(recipientId, category, asset));
}

function* onDeleteSpecificAssets({ payload }) {
  const { recipientId } = payload;
  const productKeyId = yield select(selectProductKeyId);

  let response;

  try {
    response = yield call(mediaApi.DeleteSpecificAssets.bind(mediaApi), {
      targetOrganizationId: recipientId,
      productKeyId,
    });
  } catch (e) {
    logError(`ServerError: ${e}`);
  }

  if (response) {
    yield put(specificAssetsDeleted(recipientId));
  }
}

function* revalidateProduct() {
  yield put(applyRulesForViewAsRecipients());
}

export default function* recipientSpecificBlockMainSaga() {
  yield takeLatest(
    [RECEIVE_RECIPIENTS_LIST, RECEIVE_DISPLAY_GROUPS],
    withCatch(selectRecipientsOverridableFields),
  );
  yield takeLatest(
    [RECEIVE_RECIPIENTS_LIST, RECEIVE_DISPLAY_GROUPS],
    withCatch(fetchRecipientsSpecificFields),
  );
  yield takeLatest(ADD_RECIPIENT, createSharingUnitSaga);
  yield takeEvery(TOGGLE_STATUS, onToggleActiveAsset);
  yield takeEvery(DELETE_SPECIFIC_ASSETS, onDeleteSpecificAssets);

  yield takeLatest(
    [ADD_OVERRIDE, STATUS_TOGGLED, DELETE_OVERRIDE],
    revalidateProduct,
  );
}

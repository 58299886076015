import classNames from 'classnames';
import { bool } from 'prop-types';

import i18n from 'utils/i18n';

export default function ListBoolCell({ data }) {
  let text = i18n.t('frontproductstream.core.list_cell_boolean.no_label', {
    defaultValue: 'No',
  });
  if (data) {
    text = i18n.t('frontproductstream.core.list_cell_boolean.yes_label', {
      defaultValue: 'Yes',
    });
  }

  return <div className={classNames('ListBoolCell')}>{text}</div>;
}

ListBoolCell.className = 'ListBoolCell';
ListBoolCell.propTypes = {
  data: bool,
};

import classNames from 'classnames';
import PropTypes from 'prop-types';
import { Component } from 'react';

import './tag.scss';

class Tag extends Component {
  static propTypes = {
    onDelete: PropTypes.func,
    onClick: PropTypes.func,
    label: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
    className: PropTypes.string,
    deleteClassName: PropTypes.string,
    value: PropTypes.object,
    readOnly: PropTypes.bool,
  };

  static defaultProps = {
    label: '',
    className: null,
    deleteClassName: 'TagsInput__delete',
    value: null,
    readOnly: false,
  };

  constructor(props) {
    super(props);
    this.onDelete = this.onDelete.bind(this);
    this.onClick = this.onClick.bind(this);
  }

  shouldComponentUpdate() {
    return true;
  }

  onDelete() {
    const { onDelete, label, value } = this.props;
    if (!onDelete) {
      return;
    }
    if (value) {
      onDelete(value);
    } else {
      onDelete(label);
    }
  }

  onClick() {
    const { onClick, label, value } = this.props;
    if (!onClick) {
      return;
    }
    if (value) {
      onClick(value);
    } else {
      onClick(label);
    }
  }

  renderDeleteButton() {
    const { deleteClassName, readOnly, onDelete } = this.props;
    if (readOnly || !onDelete) {
      return null;
    }
    return (
      <span
        onClick={this.onDelete}
        className={`${deleteClassName} mdi mdi-close-circle`}
      />
    );
  }

  render() {
    const { label, className, onClick, readOnly } = this.props;

    const styles = {
      TagsInput__tag: !className,
      'TagsInput__tag--readonly': readOnly,
    };
    if (className) {
      styles[className] = true;
    }

    const onClickFunction = !readOnly && onClick ? this.onClick : null;

    return (
      <div className={classNames(styles)} onClick={onClickFunction}>
        <span className="TagsInput_label">{label}</span>
        {this.renderDeleteButton()}
      </div>
    );
  }
}

export default Tag;

import {
  concat,
  flatten,
  flow,
  get,
  set,
  tail,
  times,
  update,
  zip,
} from 'lodash/fp';

import { traverseOn } from 'utils/fp';

export const buildItemPath = (indexes, displayGroupPath = ['default']) =>
  indexes?.length
    ? flow(
        zip(times(() => 'items', indexes.length)),
        flatten,
        tail,
        concat(displayGroupPath),
      )(indexes)
    : [];

export const findPathWhere = (check, items) =>
  items.reduce((acc, item, index) => {
    if (check(item)) {
      return [index];
    }
    if (item.items) {
      const result = findPathWhere(check, item.items);
      if (result.length) {
        return [index, ...result];
      }
    }
    return acc;
  }, []);

export const makeFieldEditable = (
  state,
  displayGroupPath,
  model,
  isReadOnly,
  keepPaths = true,
) => {
  const path = findPathWhere(
    (item) => item.model === model,
    get(displayGroupPath, state),
  );
  const updateFn = traverseOn(
    'children',
    set(['options', 'readOnly'], isReadOnly),
  );
  let newState = update(buildItemPath(path, displayGroupPath), updateFn, state);
  if (keepPaths) {
    newState = update(
      ['pathsOfWriteableFields'],
      (paths) => [...paths, path],
      newState,
    );
  }
  return newState;
};

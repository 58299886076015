import { flow } from 'lodash/fp';

import { selectMainState } from '.';

export const selectProductLabelsState = (state) =>
  selectMainState(state).productLabels;

export const selectProductUserLabels = flow(selectProductLabelsState, (state) =>
  state.get('labels'),
);

export const selectUserLabelsProductVersionId = flow(
  selectProductLabelsState,
  (state) => state.get('productVersionId'),
);

export const selectUserLabelsProductId = flow(
  selectProductLabelsState,
  (state) => state.get('productId'),
);

import './badge.scss';

type BadgeProps = {
  label: string;
  size: 'small' | 'large';
};

export const Badge = ({ label, size }: BadgeProps) => (
  <div className={`badge badge--${size}`}>
    <span>{label}</span>
  </div>
);

import { isManufacturer } from 'core/api/user';
import { UserImmutable } from 'types';

export enum FilterStatuses {
  error = 'error',
  success = 'success',
  all = 'all',
  none = 'none',
}

export const recipientsScenariosFilter = {
  key: 'recipients_scenarios',
  query: 'scenarios',
  isAvailable: ({ user }: { user?: UserImmutable } = {}) =>
    user != null && isManufacturer(user),
  customField: 'recipients_scenarios',
  valuePath: 'data',
  withCustomField: ({ user }) => isManufacturer(user),

  parseQueryValues: (queryStr = '') => {
    const [scenarioIdQuery, statusQuery = FilterStatuses.none] =
      queryStr.split(':');
    const scenarioId = Number(scenarioIdQuery);
    if (scenarioId > 0) {
      const status = FilterStatuses[statusQuery] || FilterStatuses.none;
      return { scenario_id: scenarioId, status };
    }
    // need an empty table to ignore the filter if not well formatted
    return [];
  },

  formatQueryValues: (value) => {
    if (value && !value.isEmpty()) {
      const scenarioId = value.get('scenario_id');
      const filter = value.get('status');
      return `${scenarioId}:${filter}`;
    }
    return '';
  },
};

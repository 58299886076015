import { ApiClient, getServiceUrl } from '@alkem/sdk-dashboard';

import prefs from 'constants/prefs';
import { getUserLanguage as acceptLanguage } from 'utils/i18n';

const options = {
  cacheEnabled: true,
  acceptLanguage,
  defaultTargetMarketId: prefs.defaultTargetMarket
    ? prefs.defaultTargetMarket.id
    : 250,
};

export const coreBaseApiNoCache = new ApiClient(getServiceUrl('core'), {
  ...options,
  cacheEnabled: false,
});
export default new ApiClient(getServiceUrl('core'), options);

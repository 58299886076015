import { withRestrictedFeature } from 'core/modules/restricted';
import { selectIsLoggedAs } from 'modules/user';
import { registerModuleReducer } from 'utils/modules/reducer';
import { registerSaga } from 'utils/modules/saga';

import { PRODUCT_ADMIN_STORE_KEY } from './constants';
import reducer from './reducers';
import productAdminSaga from './sagas';
import { ProductAdminView } from './view';

registerSaga(productAdminSaga);
registerModuleReducer(PRODUCT_ADMIN_STORE_KEY, reducer);

export default withRestrictedFeature({
  View: ProductAdminView,
  selectors: [selectIsLoggedAs],
});

import PropTypes from 'prop-types';
import { PureComponent } from 'react';

import { LanguageProvider } from './context';
import FormGroup from './form-group';

export default class Form extends PureComponent {
  static propTypes = {
    ...FormGroup.propTypes,
    currentLanguage: PropTypes.object,
  };

  render() {
    return (
      <LanguageProvider value={this.props.currentLanguage}>
        <FormGroup {...this.props} />
      </LanguageProvider>
    );
  }
}

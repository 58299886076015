import i18n from 'utils/i18n';

import './info.scss';

export default function ReportingDemoInfo() {
  return (
    <div className="ReportingDemoInfo">
      <div className="ReportingDemoInfo__label">
        {i18n.t('frontproductstream.reporting_page.demo.text', {
          defaultValue: 'Reporting demo',
        })}
      </div>
    </div>
  );
}

import { memo } from 'react';

import { size } from 'utils/immutable';

import { NavItem } from '../types';

import { AnchorNavItem } from './anchor-nav-item';
import './style.scss';

interface Props {
  hasLevel2: boolean;
  items: NavItem[];
  onNavItemClick: (itemId: string) => void;
}

export const AnchorNav = memo(function AnchorNav(props: Props) {
  if (size(props.items) === 0) {
    return null;
  }
  return (
    <ul className="AnchorNav">
      {props.items.map(({ active, className, iconName, id, level, name }) => (
        <li key={`${id || ''}${name}-${props.hasLevel2}`}>
          <AnchorNavItem
            active={active}
            className={className}
            content={name}
            iconName={iconName}
            level={props.hasLevel2 ? level : 2}
            name={name}
            navId={id || name}
            onNavItemClick={props.onNavItemClick}
          />
        </li>
      ))}
    </ul>
  );
});

import { getIn, isString } from 'lodash';
import { call, put, select } from 'redux-saga/effects';

import { notificationError, notificationSuccess } from 'actions/notification';
import etlApi from 'resources/etlApi';
import i18n from 'utils/i18n';

import {
  closeTargetCatalogImportModal,
  openFollowUpImportModal,
} from '../actions';
import { FILE_FORMAT_RANGE } from '../constants';
import { selectModalSelectedFile } from '../selectors';

export const uploadRangeFileToETL = (payload, file) =>
  etlApi.TargetCatalogUpload(payload, file);

export const uploadProductFileToETL = (payload, file) =>
  etlApi
    .ProductsImportUploadV2(payload, file)
    .then((response) => response.data.data.pif_id);

// TODO : Adapt this saga the day there are more than two import options
export function* uploadTargetCatalog(info) {
  try {
    const file = yield select(selectModalSelectedFile);

    const uploaderFunc =
      info.payload === FILE_FORMAT_RANGE
        ? uploadRangeFileToETL
        : uploadProductFileToETL;
    const payload =
      info.payload === FILE_FORMAT_RANGE
        ? {}
        : { spider: 'XLSX', merge_mode: 'deep_patch' };
    const response = yield call(uploaderFunc, payload, file);
    if (info.payload === FILE_FORMAT_RANGE) {
      yield call(processApiSuccess);
    } else {
      yield put(openFollowUpImportModal(response));
    }
  } catch (err) {
    yield call(processApiError, { err });
  }
}

export function* processApiSuccess() {
  yield put(closeTargetCatalogImportModal());
  yield put(notificationSuccess(i18n.t('Your request is being processed...')));
}

export function* processApiError({ error }) {
  yield put(closeTargetCatalogImportModal());
  let errorMessage = getIn(error, ['data', 'message']);
  if (!isString(errorMessage) || errorMessage.length === 0) {
    errorMessage = i18n.t('An error occured');
  }
  yield put(notificationError(errorMessage, { context: 'modal' }));
}

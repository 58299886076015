export const VIEW_AS_SET_PRESELECTED = 'VIEW_AS_SET_PRESELECTED';
export const VIEW_AS_INIT = 'VIEW_AS_INIT';
export const VIEW_AS_LOADING = 'VIEW_AS_LOADING';
export const VIEW_AS_ADD_RECIPIENT = 'VIEW_AS_ADD_RECIPIENT';
export const VIEW_AS_REMOVE_RECIPIENT = 'VIEW_AS_REMOVE_RECIPIENT';

export const VIEW_AS_RECEIVE_RULESETS = 'VIEW_AS_RECEIVE_RULESETS';
export const VIEW_AS_SELECT_RULESET = 'VIEW_AS_SELECT_RULESET';
export const VIEW_AS_UNSELECT_RULESET = 'VIEW_AS_UNSELECT_RULESET';
export const VIEW_AS_FILTER_RULESETS_FIELDS = 'VIEW_AS_FILTER_RULESETS_FIELDS';

export const VIEW_AS_FETCH_ALWAYS_DISPLAYED_FIELDS =
  'VIEW_AS_FETCH_ALWAYS_DISPLAYED_FIELDS';
export const VIEW_AS_RECEIVE_ALWAYS_DISPLAYED_FIELDS =
  'VIEW_AS_RECEIVE_ALWAYS_DISPLAYED_FIELDS';

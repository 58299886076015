import { all, call, put, select, takeLatest } from 'redux-saga/effects';

import { SourceProductStatuses } from 'constants/sourceProductStatus';
import {
  assignationStatusFilter,
  dataQualityFilter,
  messageFilter,
  packshotFilter,
  sourceProductStatusFilter,
} from 'core/modules/list/constants/filters';
import { SEARCH as SEARCH_FROM_CATALOG } from 'modules/catalog/product/actions/types';
import { buildSearch } from 'modules/catalog/shared/utils/list';
import { hasFeatureProductReview } from 'modules/product-review/utils';
import { selectUser } from 'modules/user';
import { fetchAssignations, fetchProductVersions } from 'resources/searchApi';
import { get } from 'utils/immutable';
import { withCatch } from 'utils/saga';

import { receiveAggregations } from '../actions';
import { FETCH_AGGREGATIONS } from '../actions/types';
import { selectSearch, selectUseCatalogWithMenu } from '../selectors';

export default function* catalogMenuSaga() {
  yield takeLatest(
    [FETCH_AGGREGATIONS, SEARCH_FROM_CATALOG],
    withCatch(fetchAggregationsSaga),
  );
}

export function* fetchAggregationsSaga() {
  const hasMenu = yield select(selectUseCatalogWithMenu);
  if (!hasMenu) {
    return;
  }
  const user = yield select(selectUser);
  const queryName = yield select(selectSearch);
  const search = buildSearch(queryName);
  const [assignationResponse, pvResponse, reviewResponse] = yield all([
    call(fetchAssignations, {
      limit: 0,
      queries: {
        ...search,
        advancedSearch: {
          must_not: [
            {
              query: 2,
              fields: [assignationStatusFilter.key],
            },
          ],
        },
      },
    }),
    call(fetchProductVersions, {
      queries: {
        ...search,
        aggregations: [
          {
            filter_name: 'actions',
            filter: {
              bool: {
                should: [
                  {
                    term: {
                      [sourceProductStatusFilter.key]:
                        SourceProductStatuses.INFORMATION_EXPECTED.id,
                    },
                  },
                  { term: { [messageFilter.key]: 'I_NEED_TO_ACK' } },
                  { term: { [dataQualityFilter.key]: 1 } },
                  { term: { [dataQualityFilter.key]: 3 } },
                  { term: { [packshotFilter.key]: false } },
                ],
              },
            },
          },
        ],
      },
      limit: 0,
      withSourceInclude: false,
      cache: false,
    }),
    hasFeatureProductReview(user) &&
      call(fetchProductVersions, {
        queries: {
          isReviewPending: true,
        },
        withSourceInclude: false,
        limit: 0,
      }),
  ]);
  yield put(
    receiveAggregations({
      assignations: get(assignationResponse, 'total'),
      all: get(pvResponse, ['aggregations', 'all']),
      actions: get(pvResponse, ['aggregations', 'actions']),
      archived: get(pvResponse, ['aggregations', 'archived']),
      review: get(reviewResponse, ['total']),
    }),
  );
}

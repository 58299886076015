export const FETCH_USERS = 'admin-user-list/fetch';
export const RECEIVE_USERS = 'admin-user-list/receive';
export const FETCH_USERS_ERROR = 'admin-user-list/fetch-error';
export const USERS_NEXT_PAGE = 'admin-user-list/next-page';
export const USERS_PREVIOUS_PAGE = 'admin-user-list/previous-page';
export const USERS_SET_FETCHING_OPTIONS = 'admin-user-list/set-options';
export const SEARCH_USERS = 'admin-user-list/search';
export const ADD_FILTER = 'admin-user-list/add-filter';
export const REMOVE_FILTER = 'admin-user-list/remove-filter';
export const REMOVE_ALL_FILTERS = 'admin-user-list/remove-all-filters';
export const ACCEPT_USER_FROM_LIST = 'admin-user-list/accept-user';
export const ACCEPT_USER_FROM_LIST_SUCCESS =
  'admin-user-list/accept-user-success';
export const ACCEPT_USER_FROM_LIST_ERROR = 'admin-user-list/accept-user-error';

export const FETCH_MANAGED_USER = 'admin-user/fetch';
export const RECEIVE_MANAGED_USER = 'admin-user/receive';
export const UPDATE_MANAGED_USER_FIELD = 'admin-user/update-field';
export const ENABLE_USER = 'admin-user/enable';
export const ENABLE_USER_SUCCESS = 'admin-user/enable-success';
export const ACCEPT_USER = 'admin-user/accept';
export const ACCEPT_USER_SUCCESS = 'admin-user/accept-success';
export const ACCEPT_USER_ERROR = 'admin-user/accept-error';
export const DISABLE_USER = 'admin-user/disable';
export const DISABLE_USER_SUCCESS = 'admin-user/disable-success';
export const UPDATE_PASSWORD = 'admin-user/update-password';
export const RESET_MANAGED_USER = 'admin-user/reset';
export const SAVE_PASSWORD = 'admin-user/save-password';
export const SAVE_PASSWORD_DONE = 'admin-user/save-password-done';
export const SAVE_PASSWORD_ERROR = 'admin-user/save-password-error';

export const FETCH_SEGMENTS = 'admin-user/fetch-segments';
export const RECEIVE_SEGMENTS = 'admin-user/receive-segments';
export const GET_PRODUCT_SEGMENT_PERMISSIONS =
  'admin-user/fetch-product-segment-permissions';
export const RECEIVE_PRODUCT_SEGMENT_PERMISSIONS =
  'admin-user/receive-product-segment-permissions';
export const UPDATE_PRODUCT_SEGMENT_PERMISSIONS =
  'admin-user/update-product-segment-permissions';
export const RESET_PRODUCT_SEGMENT_PERMISSIONS =
  'admin-user/reset-product-segment-permissions';

export const OPEN_INVITATION_MODAL = 'admin-user-invitation/open';
export const CLOSE_INVITATION_MODAL = 'admin-user-invitation/close';
export const INVITE_USER = 'admin-user-invitation/invite-user';
export const VALIDATE_INVITATION_FORM = 'admin-user-invitation/validate-form';
export const INVITATION_FORM_ERRORS = 'admin-user-invitation/form-erors';
export const CANCEL_INVITATION = 'admin-user-invitation/cancel';

export const UPDATE_USER_ROLE = 'UPDATE_USER_ROLE';
export const UPDATE_USER_PERMISSIONS = 'UPDATE_USER_PERMISSIONS';
export const RECEIVE_USER_PERMISSIONS = 'RECEIVE_USER_PERMISSIONS';

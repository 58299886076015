import { Moment } from 'moment';

export class RuleSetRequirement {
  constructor(ruleSetId: number, activated: boolean, deadline?: Moment) {
    this.ruleSetId = ruleSetId;
    this.activated = activated;
    this.deadline = deadline;
  }

  ruleSetId: number;
  activated: boolean;
  deadline?: Moment;
}

export interface PromptedMaturityRuleSet {
  id: number;
  name: string;
  withDeadline: boolean;
  defaultDeadline: string | null;
}

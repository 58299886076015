import { Map } from 'immutable';
import { useSelector } from 'react-redux';
import { Link } from 'react-router-dom';

import { UserImmutable } from '@alkem/lib-front-model/immutable';
import { Button } from '@alkem/react-ui-button';

import { hasFeature } from 'modules/feature-flag';
import { RELEASE_PW_TEMPLATE } from 'modules/feature-flag/constants';
import { selectUser } from 'modules/user';
import {
  selectProductGTINWithFallbackOnInternal,
  selectProductKeyId,
} from 'reducers/productVersion';
import * as routes from 'routes';
import i18n from 'utils/i18n';
import { get } from 'utils/immutable';
import { withQuery } from 'utils/query';
import { track } from 'utils/tracking';

import './styles.scss';

const CreateSharingUnitButton = ({
  onCreate,
  retailer,
}: {
  onCreate: (retailer: Map<string, any>) => any;
  retailer: Map<string, any>;
}) => {
  const user: UserImmutable = useSelector(selectUser);
  const reference = useSelector(selectProductGTINWithFallbackOnInternal) || '';
  const productKeyId = useSelector(selectProductKeyId);

  const isBulkAllowed: boolean = hasFeature(user, RELEASE_PW_TEMPLATE);
  const isRetailerAllowed: boolean =
    get(retailer, 'settings.listing', false) === 'enable';

  if (!isBulkAllowed || !isRetailerAllowed) {
    return (
      <button
        type="button"
        className="BigDottedButton AddSharingUnitButton"
        onClick={onCreate(retailer)}
      >
        <i className="mdi mdi-plus-circle" />
        {i18n.t(
          'frontproductstream.product_page.sharing_unit_create_listing.button',
          {
            defaultValue: 'Add new {{retailer}} listing',
            retailer: retailer.get('label'),
          },
        )}
      </button>
    );
  }

  return (
    <div className="AddSharingUnit">
      <h3>
        {i18n.t(
          'frontproductstream.product_page.sharing_unit_create_listing.title',
          { defaultValue: 'Create a new listing ?' },
        )}
      </h3>
      <div className="AddSharingUnit__body">
        <Button
          className="AddSharingUnit__button"
          secondary
          onClick={onCreate(retailer)}
        >
          <i className="mdi mdi-plus-circle" />
          {i18n.t(
            'frontproductstream.product_page.sharing_unit_create_listing_manually.button',
            { defaultValue: 'Create a new listing manually' },
          )}
        </Button>
        <span>
          {i18n.t(
            'frontproductstream.product_page.sharing_unit_create_listing.or',
            { defaultValue: 'or' },
          )}
        </span>
        <Link
          to={withQuery(routes.sharingUnitTemplatesList, {
            create_model_for: retailer.get('id'),
          })}
          onClick={() =>
            track({
              category: 'listing',
              action: 'bulknetpricePDP_clicked',
              retailerOrganizationId: retailer.get('id'),
              retailerName: retailer.get('label'),
              productKeyId: productKeyId,
              gtin: reference,
            })
          }
        >
          <Button className="AddSharingUnit__button" secondary link>
            <i className="mdi mdi-plus-circle" />
            {i18n.t(
              'frontproductstream.product_page.sharing_unit_create_listing_bulk.button',
              { defaultValue: 'Create a new listing in bulk' },
            )}
          </Button>
        </Link>
      </div>
    </div>
  );
};

export default CreateSharingUnitButton;

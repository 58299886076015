import { connect } from 'react-redux';
import { createStructuredSelector } from 'reselect';

import {
  selectIsManufacturer,
  selectIsPhysicalCheckManufacturer,
  selectIsPhysicalChecker,
  selectIsPrivateLabel,
  selectIsRetailer,
  selectIsSuperAdmin,
  selectIsThirdParty,
} from 'modules/user';

const mapStateToProps = createStructuredSelector({
  isSuperAdmin: selectIsSuperAdmin,
  isManufacturer: selectIsManufacturer,
  isPhysicalChecker: selectIsPhysicalChecker,
  isPhysicalCheckManufacturer: selectIsPhysicalCheckManufacturer,
  isPrivateLabel: selectIsPrivateLabel,
  isRetailer: selectIsRetailer,
  isThirdParty: selectIsThirdParty,
});

/**
 * Higher oder component (HOC) to make several props regarding user type
 * available in the wrapped component.
 *
 * How to use:
 * - simply pass your component to the higher order component:
 * ```js
 *   export default withUserType(MyComponent)
 * ```
 * - if you already use another HOC (like redux connect), use compose
 * ```js
 *   const enhance = compose(connect(mapStateToProps), withUserType)
 *   export default enhance(MyComponent)
 * ```
 * - then add any of the props above to the (wrapped) component's prop types
 *   and you're ready to go (you don't need to add all of them)
 */
const withUserType = (WrappedComponent) =>
  connect(mapStateToProps)(WrappedComponent);

export default withUserType;

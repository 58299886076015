import classNames from 'classnames';
import { useSelector } from 'react-redux';

import { clearSelectedIds } from 'actions/media';
import { useDispatch } from 'hooks/useDispatch';
import { selectCurrentAsset, selectSelectedIds } from 'reducers/media';
import i18n from 'utils/i18n';

import styles from './Aside.module.scss';
import { AssetDocumentForm, AssetPictureForm } from './asset-forms';

export const Aside = () => {
  const dispatch = useDispatch();

  const selectedIds = useSelector(selectSelectedIds);
  const onClearSelectedAssetIds = () => dispatch(clearSelectedIds());

  const asset = useSelector(selectCurrentAsset);

  const noDetailsWrapperClasses = classNames([
    styles.wrapper,
    styles['wrapper--no-details'],
  ]);

  if (selectedIds.length === 1) {
    if (['ProductPicture', 'ProductDocument'].includes(asset._type)) {
      return (
        <div className={styles.wrapper}>
          <div className={styles.header}>
            <h4 className={styles.title}>
              {i18n.t('frontproducstream.asset_list.modal.header.text', {
                defaultValue: 'Asset details',
              })}
            </h4>
            <button
              onClick={onClearSelectedAssetIds}
              className={styles['close-button']}
            >
              <i className="mdi mdi-close" />
            </button>
          </div>
          {asset._type === 'ProductPicture' && (
            <AssetPictureForm asset={asset} />
          )}
          {asset._type === 'ProductDocument' && (
            <AssetDocumentForm asset={asset} />
          )}
        </div>
      );
    }

    return (
      <div className={noDetailsWrapperClasses}>
        <p className={styles['help-text']}>Feature in development</p>
      </div>
    );
  }

  if (selectedIds.length > 1) {
    return (
      <div className={noDetailsWrapperClasses}>
        <p className={styles['help-text']}>
          {i18n.t('frontproducstream.asset_list.modal.bulk_action.text', {
            defaultValue:
              'Click on Actions to choose which bulk action you would like to perform',
          })}
        </p>
      </div>
    );
  }

  return (
    <div className={noDetailsWrapperClasses}>
      <p className={styles['help-text']}>
        {i18n.t('frontproducstream.asset_list.modal.no_asset_selected.text', {
          defaultValue:
            'Select from a library to see and edit the asset properties',
        })}
      </p>
    </div>
  );
};

import { createReducer } from 'redux-create-reducer';

import { RECEIVE_REFERENTIALS } from './events';

const initialState = {
  referentials: undefined,
};

export default createReducer(initialState, {
  [RECEIVE_REFERENTIALS]: (state, { referentials }) => ({
    ...state,
    referentials,
  }),
});

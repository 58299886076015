import 'modules/target-catalog-import';
// We import those files here to register the three reducers
import { registerModuleReducer } from 'utils/modules/reducer';
import { registerSaga } from 'utils/modules/saga';

import Catalog from './components';
import { KEY } from './constants';
import {
  ARCHIVED_PRODUCTS,
  ASSIGNATION,
  PRODUCTS,
  PRODUCTS_TO_REVIEW,
  PRODUCTS_WITH_MENU,
  SOURCING,
} from './constants/context';
import { withContext } from './context';
import reducers from './reducers';
import mainSaga from './sagas';

registerModuleReducer(KEY, reducers);
registerSaga(mainSaga);

export const ProductCatalog = withContext(PRODUCTS, Catalog);
export const ProductCatalogForMenu = withContext(PRODUCTS_WITH_MENU, Catalog);
export const AssignationCatalog = withContext(ASSIGNATION, Catalog);
export const ProductToReviewCatalog = withContext(PRODUCTS_TO_REVIEW, Catalog);
export const ArchivedProductCatalog = withContext(ARCHIVED_PRODUCTS, Catalog);
export const SourcingCatalog = withContext(SOURCING, Catalog);

import { createSelector } from '@reduxjs/toolkit';

const selectModuleState = (state) => state.module.dashboardPublicationSummary;

export const selectConfirmUnsyncModalOpened = createSelector(
  selectModuleState,
  (state) => state.get('confirmUnsyncModalOpened'),
);

export const selectUnavailableUnsyncModalOpened = createSelector(
  selectModuleState,
  (state) => state.get('unavailableUnsyncModalOpened'),
);

export const selectTargetRecipient = createSelector(
  selectModuleState,
  (state) => state.get('targetRecipient'),
);

export const selectLoadingInProgress = createSelector(
  selectModuleState,
  (state) => state.get('loadingInProgress'),
);

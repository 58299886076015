import { Map } from 'immutable';
import { get, isUndefined, map } from 'lodash/fp';

import { updateEntity } from 'actions/entity';
import {
  scrolledToAnchorNavigationSection,
  setAnchorNavigationSection,
} from 'actions/navigation';
import { notificationError, notificationSuccess } from 'actions/notification';
import { ENTITY_TYPE_SHARINGUNIT } from 'constants/entities';
import { ProductReferenceTypes } from 'constants/product-reference-types';
import { getDisplayName, getTypePackagingId } from 'core/api/productversion';
import { selectHasEditableHierarchies } from 'modules/feature-flag/selectors';
import {
  selectHierarchyReferenceBySharingUnit,
  selectUsedHierarchyReferences,
} from 'modules/sharing-units/selectors';
import { selectCurrentLanguage, selectIsMadeOf } from 'reducers/productVersion';
import { toGTIN } from 'utils';
import i18n from 'utils/i18n';
import { createAction } from 'utils/redux';

import { getReference } from '../../core/api/product';

import {
  BULK_UPDATE,
  LOAD_DISPLAY_GROUPS,
  LOGISTICAL_HIERARCHIES_CREATE_TEMPLATE,
  LOGISTICAL_HIERARCHIES_CREATE_UNIT,
  LOGISTICAL_HIERARCHIES_DELETE_UNIT,
  LOGISTICAL_HIERARCHIES_INIT,
  LOGISTICAL_HIERARCHIES_SET_DELETED_ROOT,
  LOGISTICAL_HIERARCHIES_SET_EDITED_UNIT,
  LOGISTICAL_HIERARCHIES_UPDATE_QUANTITY,
  LOGISTICAL_HIERARCHIES_UPDATE_REFERENCE,
  RESET,
  SECTION_TITLE,
  TOGGLE,
} from './constants';
import {
  extractReferencesFromHierarchies,
  getReferenceToInternalIds,
} from './helpers';
import {
  selectCurrentVersionInfo,
  selectEditedDataMap,
  selectEditedRoots,
} from './selectors';

export const reset = createAction(RESET);

export const toggle = (internalId) => (dispatch) =>
  dispatch({
    type: TOGGLE,
    internalId,
  });

export const init = () => (dispatch, getState) => {
  const state = getState();
  const isMadeOf = selectIsMadeOf(state);
  const children = map(
    (item) => ({
      gtin: get(['targetProduct', 'gtin'], item),
      version: get(['targetProduct', 'version'], item),
      quantity: get(['quantity'], item),
      isConsumerUnit: get(['isConsumerUnit'], item),
    }),
    isMadeOf,
  );
  return dispatch({
    type: LOGISTICAL_HIERARCHIES_INIT,
    currentVersionInfo: selectCurrentVersionInfo(state),
    children,
  });
};

export const createTemplate =
  (reference, versions, addCurrentProduct, insertFirst, isPatch = false) =>
  async (dispatch, getState) => {
    const state = getState();
    const currentVersionInfo = selectCurrentVersionInfo(state);
    await dispatch({
      type: LOGISTICAL_HIERARCHIES_CREATE_TEMPLATE,
      reference,
      versions,
      addCurrentProduct,
      insertFirst,
      currentVersionDisplayName: currentVersionInfo.displayName,
      currentVersionCurrentLanguage: currentVersionInfo.currentLanguage,
      isPatch,
    });
    await dispatch(setAnchorNavigationSection(SECTION_TITLE));
    dispatch(scrolledToAnchorNavigationSection());
  };

export const createUnit =
  (
    parentInternalId,
    gtin,
    productIdentifier,
    version,
    insertFirst = false,
    isPatch = false,
  ) =>
  (dispatch, getState) => {
    const state = getState();
    const currentVersionInfo = selectCurrentVersionInfo(state);
    const reference = gtin || productIdentifier;
    // Warn if hierarchies have been merged.
    // A hierachy is merged if a root is added in another unit.
    // This cannot happen when creating a new unit (no gtin given).
    let removeFromRoot = false;
    if (reference) {
      const dataMap = selectEditedDataMap(state);
      const [referencesToId, patchedReferenceToId] = getReferenceToInternalIds(
        dataMap,
        {
          splitByPatch: true,
        },
      );
      let internalId;
      if (!isPatch && referencesToId[reference]) {
        internalId = referencesToId[reference];
      } else if (isPatch && patchedReferenceToId[reference]) {
        internalId = patchedReferenceToId[reference];
      }
      if (internalId) {
        const editedRoots = selectEditedRoots(state);
        if (editedRoots.includes(internalId)) {
          removeFromRoot = true;
        }
      }
    }
    dispatch({
      type: LOGISTICAL_HIERARCHIES_CREATE_UNIT,
      parentInternalId,
      gtin,
      productIdentifier,
      version,
      insertFirst,
      currentVersionDisplayName: currentVersionInfo.displayName,
      currentVersionCurrentLanguage: currentVersionInfo.currentLanguage,
      removeFromRoot,
      isPatch,
    });
    if (removeFromRoot) {
      dispatch(
        notificationSuccess(
          i18n.t(
            'frontproductstream.logistical_hierarchy_actions.create_unit.notification',
            {
              defaultValue:
                'Some logistical hierarchies have been merged as they contain the same information',
            },
          ),
        ),
      );
    }
  };

export const deleteUnit =
  (
    currentVersionReference,
    parentInternalId,
    internalId,
    isUsedInAgreedSharingUnit,
  ) =>
  (dispatch, getState) => {
    const state = getState();
    const lhState = state.logisticalHierarchies;
    const usedReferences = selectUsedHierarchyReferences(state);
    const hasEditableHierarchies = selectHasEditableHierarchies(state);

    const roots = get(['edited', 'roots'], lhState) || [];
    const dataMap = get(['edited', 'dataMap'], lhState) || {};
    const root = roots.find((id) => id === internalId);
    const rootReference = root
      ? get(['gtin'], dataMap[root]) ||
        get(['productIdentifier'], dataMap[root])
      : null;
    if (
      rootReference &&
      usedReferences.includes(rootReference) &&
      !hasEditableHierarchies
    ) {
      dispatch(
        notificationError(
          i18n.t(
            'frontproductstream.logistical_hierarchy_actions.delete_unit.notification',
            {
              defaultValue:
                'This hierarchie is linked to a sharingUnit and cannot be deleted.',
            },
          ),
        ),
      );
    } else {
      dispatch({
        type: LOGISTICAL_HIERARCHIES_DELETE_UNIT,
        currentVersionReference,
        parentInternalId,
        internalId,
        isUsedInAgreedSharingUnit,
      });
    }
  };

export const updateQuantity =
  (parentInternalId, internalId, quantity, isUsedInAgreedSharingUnit) =>
  (dispatch) =>
    dispatch({
      type: LOGISTICAL_HIERARCHIES_UPDATE_QUANTITY,
      parentInternalId,
      internalId,
      quantity,
      isUsedInAgreedSharingUnit,
    });

export const updateReference =
  (
    internalId,
    reference,
    referenceType,
    parentInternalId,
    isUsedInAgreedSharingUnit,
  ) =>
  (dispatch, getState) => {
    if (isUndefined(referenceType)) {
      referenceType = ProductReferenceTypes.GTIN;
    }
    const state = getState();
    const dataMap = selectEditedDataMap(state);
    const [referenceMap, patchedReferenceMap] = getReferenceToInternalIds(
      dataMap,
      {
        splitByPatch: true,
      },
    );
    const unit = dataMap[internalId];
    const finalReference =
      referenceType === ProductReferenceTypes.GTIN
        ? toGTIN(reference)
        : reference;
    let usedInternalId = null;
    let copyVersionFromId = null;
    if (unit.isPatch) {
      usedInternalId =
        patchedReferenceMap[reference] || patchedReferenceMap[finalReference];
      const manufacturerInternalId =
        referenceMap[reference] || referenceMap[finalReference];
      const manufacturerUnit = dataMap[manufacturerInternalId];
      if (manufacturerUnit) {
        if (
          getTypePackagingId(unit.version) !==
          getTypePackagingId(manufacturerUnit.version)
        ) {
          dispatch(
            notificationError(
              i18n.t(
                'frontproductstream.logistical_hierarchy_actions.update_reference_type_of_packaging.notification',
                {
                  referenceTypeLabel: referenceType.displayValue(),
                  defaultValue:
                    'This {{referenceTypeLabel}} is already used by another type of packaging',
                },
              ),
            ),
          );
          return;
        }
        copyVersionFromId = manufacturerInternalId;
      }
    } else {
      usedInternalId = referenceMap[reference] || referenceMap[finalReference];
    }
    if (usedInternalId) {
      dispatch(
        notificationError(
          i18n.t(
            'frontproductstream.logistical_hierarchy_actions.update_reference_logistical_unit.notification',
            {
              referenceTypeLabel: referenceType.displayValue(),
              defaultValue:
                'This {{referenceTypeLabel}} is already used on another logistical unit.',
            },
          ),
        ),
      );
      return;
    }
    const productKeyId = unit.product_key_id;
    const unitReference = unit[referenceType.fieldName];
    if (unitReference && !parentInternalId && productKeyId) {
      // if LU already have a gtin and is root: delete this LU
      dispatch({
        type: LOGISTICAL_HIERARCHIES_SET_DELETED_ROOT,
        productKeyId,
      });
    }
    if ((unitReference && !unit.isPatch) || (reference && unit.isDirty)) {
      // If already used in an SU, update those.
      const sharingUnits = selectHierarchyReferenceBySharingUnit(state)
        .filter((g) => g === unitReference)
        .keySeq();
      for (const suID of sharingUnits) {
        const label = `${reference} - ${getDisplayName(
          unit.version,
          selectCurrentLanguage(state),
        )}`;
        dispatch(
          updateEntity(
            'hierarchyProduct',
            Map({
              [referenceType.fieldName]: reference,
              label,
            }),
            suID,
            ENTITY_TYPE_SHARINGUNIT,
          ),
        );
      }
    }
    dispatch({
      type: LOGISTICAL_HIERARCHIES_UPDATE_REFERENCE,
      internalId,
      reference: reference,
      productReferenceType: referenceType,
      isUsedInAgreedSharingUnit,
      copyVersionFromId,
    });
  };

export const fetchDisplayGroupsIfRequired = () => (dispatch) =>
  dispatch({
    type: LOAD_DISPLAY_GROUPS,
  });

export const setEditedUnit = (editedUnit) => (dispatch) =>
  dispatch({
    type: LOGISTICAL_HIERARCHIES_SET_EDITED_UNIT,
    editedUnit,
  });

export function ensureValidHierarchies(
  currentVersionReference,
  textileVariantList,
  units,
  path = '',
) {
  let errors = [];
  const getPath = (p) => (path ? `${path}.${p}` : p);

  units.forEach((unit, i) => {
    const index = unit.index ?? i;
    // Check they all have gtins.
    if (!getReference(unit)) {
      errors.push({
        message: i18n.t(
          'frontproductstream.logistical_hierarchy_actions.missing_reference.error',
          {
            defaultValue:
              'GTIN or IDENTIFIER is compulsory for all logistical unit',
          },
        ),
        path: getPath(`${index}.gtin`),
        value: unit.gtin,
        displayable: true,
      });
    }
    // Check the quantities are >= 1 (not for roots).
    if (path && (!unit.quantity || unit.quantity === '0')) {
      errors.push({
        message: i18n.t(
          'frontproductstream.logistical_hierarchy_actions.missing_unit_quantity.error',
          { defaultValue: 'All children must have a quantity of at least 1' },
        ),
        path: getPath(`${index}.quantity`),
        value: unit.quantity,
        displayable: true,
      });
    }
    // Ensure each root contains the main product or one of it's variants.
    if (!path) {
      const gtins = extractReferencesFromHierarchies([unit]);
      if (
        !gtins.includes(currentVersionReference) &&
        !(textileVariantList || []).some((v) =>
          gtins.includes(get(['textileVariant', 'gtin'], v)),
        )
      ) {
        errors.push({
          message: i18n.t(
            'frontproductstream.logistical_hierarchy_actions.missing_consumer_unit.error',
            {
              defaultValue:
                'All logistical hierarchy must contain at least one consumer unit',
            },
          ),
          path: getPath(`${index}.header`),
          value: unit.children,
          displayable: true,
        });
      }
    }

    errors = [
      ...errors,
      ...ensureValidHierarchies(
        currentVersionReference,
        textileVariantList,
        unit.children || [],
        getPath(`${index}.children`),
      ),
    ];
  });

  return errors;
}

export const bulkUpdate =
  (updates, isDirty = true, ignoreField = true) =>
  (dispatch) => {
    dispatch({
      type: BULK_UPDATE,
      updates,
      isDirty,
      ignoreField,
    });
  };

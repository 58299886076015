import { call, put, select, takeLatest } from 'redux-saga/effects';

import { notificationError } from 'actions/notification';
import serviceProductApi from 'resources/serviceProductApi';
import { requestWithHeaders } from 'utils/api';
import i18n from 'utils/i18n';
import { withCatch } from 'utils/saga';

import {
  BULK_PREPARE_ASSIGN,
  BULK_PREPARE_ASSIGN_FAILURE,
  BULK_PREPARE_ASSIGN_SUCCESS,
} from './constants';
import { selectProductVersions, selectSelectedGroupIds } from './selectors';

export function* assignProductsToChannels() {
  const productVersions = yield select(selectProductVersions);
  const groupIds = yield select(selectSelectedGroupIds);

  const productKeyIds = productVersions
    .map((p) => p.getIn(['product_key', 'id']))
    .valueSeq()
    .toArray();

  const { error } = yield call(
    requestWithHeaders,
    serviceProductApi,
    'post',
    '/product/v2/scopes/prepare',
    {
      product_key_ids: productKeyIds,
      group_ids: groupIds,
      remove: false,
    },
  );

  if (error) {
    yield put({ type: BULK_PREPARE_ASSIGN_FAILURE });
    yield put(
      notificationError(
        i18n.t(
          'frontproductstream.bulk_prepare_for_channels.assign_to_channel   .error',
          {
            defaultValue: 'An error occured while assigning the products',
          },
        ),
      ),
    );
    return;
  }

  // @TODO: handle success and all
  yield put({ type: BULK_PREPARE_ASSIGN_SUCCESS });
}

export default function* mainSaga() {
  yield takeLatest(BULK_PREPARE_ASSIGN, withCatch(assignProductsToChannels));
}

import { useCallback } from 'react';
import processString from 'react-process-string';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';

import { Spinner } from '@alkem/react-ui-spinner';

import Modal from 'components/ui/modal';
import { selectUser } from 'modules/user';
import { suppliersList as supplierListRoute } from 'routes';
import i18n from 'utils/i18n';
import { get } from 'utils/immutable';
import { withQuery } from 'utils/query';
import { track } from 'utils/tracking';

import { closeModal } from '../actions';
import { selectSupplier, selectSupplierLoading } from '../selectors';

export const InformationRequestReconcileModal = () => {
  const dispatch = useDispatch();
  const supplier = useSelector(selectSupplier);
  const isLoading = useSelector(selectSupplierLoading);
  const user = useSelector(selectUser);
  const navigate = useNavigate();

  const goToSupplierDirectory = useCallback(() => {
    track({
      category: 'request_status_modal',
      action: 'redirect_to_supplier_directory',
      retailer_name: get(user, ['belongsTo', 0, 'nameLegal']),
      retailer_id: get(user, ['belongsTo', 0, 'id']),
      user_id: get(user, 'id'),
      supplier_name: supplier && supplier.name,
      supplier_internal_id: supplier && supplier.internalId,
    });
    if (supplier && supplier.internalId && supplier.id) {
      const queries = {
        search: supplier.internalId,
        supplier_id: supplier.id,
      };
      navigate(withQuery(supplierListRoute, queries));
    } else {
      navigate(supplierListRoute);
    }
  }, [navigate, supplier, user]);

  const onClose = () => {
    dispatch(closeModal());
  };

  const supplierNameAndId = supplier
    ? supplier.name + ' - ' + supplier.internalId
    : '';

  return (
    <Modal
      modalStyle="dynamic"
      title={i18n.t('Your request could not be sent')}
      className="InformationRequestModal"
      confirmButtonText={i18n.t('Modify the reconciliation') as string}
      onConfirm={goToSupplierDirectory}
      closeButtonText={i18n.t('Close') as string}
      onClose={onClose}
    >
      {isLoading && (
        <div className="alk-flex alk-flex-v-center">
          <Spinner />
          <div>{i18n.t('Loading')}</div>
        </div>
      )}
      {!isLoading && (
        <>
          <p>
            {supplierNameAndId
              ? processString([
                  {
                    regex: new RegExp(supplierNameAndId, 'g'),
                    fn: () => (
                      <strong key={supplierNameAndId}>
                        {supplierNameAndId}
                      </strong>
                    ),
                  },
                ])(
                  i18n.t('Your supplier {{name}} is not reconciled.', {
                    name: supplierNameAndId,
                  }),
                )
              : i18n.t('Your supplier is not reconciled.')}
          </p>
          <p>
            {i18n.t(
              'Your request will be automatically sent to all Alkemics organizations reconciled with your supplier.',
            )}
          </p>
        </>
      )}
    </Modal>
  );
};

import { useEffect } from 'react';
import { useDispatch } from 'react-redux';

import { RESET_MODULE_ACTION } from '../../../../core/modules/async/constants';

import { AuditSection } from './components/audit-section';
import { IndexingSection } from './components/indexing-section';
import { PRODUCT_ADMIN_STORE_KEY } from './constants';

export const ProductAdminView = () => {
  const dispatchEvent = useDispatch();

  useEffect(() => {
    return () => {
      dispatchEvent({
        type: RESET_MODULE_ACTION,
        payload: PRODUCT_ADMIN_STORE_KEY,
      });
    };
  });
  return (
    <div className="alk-flex alk-flex-column ProductAdmin">
      <IndexingSection />
      <AuditSection />
    </div>
  );
};

export const matchAppCues = (destinationId: string) =>
  /appcues/i.test(destinationId);

export const matchCustomerIo = (destinationId: string) =>
  /customer[.]?io/i.test(destinationId);

export const matchElevio = (destinationId: string) =>
  /elevio/i.test(destinationId);

export const matchGoogleAnalytics = (destinationId: string) =>
  /google.*analytics/i.test(destinationId);

export const matchHupSpot = (destinationId: string) =>
  /hubspot/i.test(destinationId);

export const matchMixpanel = (destinationId: string) =>
  /mixpanel/i.test(destinationId);

export const matchWebhooks = (destinationId: string) =>
  /webhooks/i.test(destinationId);

export const matchIntercomWeb = (destinationId: string) =>
  /intercom.*web/i.test(destinationId);

export const matchIntercomCloud = (destinationId: string) =>
  /intercom.*cloud/i.test(destinationId);

export const matchMandatoryTools = (destinationId: string) =>
  [
    matchAppCues,
    matchCustomerIo,
    matchElevio,
    matchWebhooks,
    matchIntercomCloud,
    matchIntercomWeb,
  ].some((matcher) => matcher(destinationId));

export const matchInvisibleTools = (destinationId: string) =>
  [matchWebhooks, matchIntercomCloud].some((matcher) => matcher(destinationId));

import { get as _get } from 'lodash/fp';

import { ENTITY_TYPE_SHARINGUNIT } from 'constants/entities';
import { Types } from 'modules/sharing-unit-tariffs/constants';
import buyingUnitApi from 'resources/buyingUnitApi';
import DisplayGroupApi from 'resources/displayGroupApi';
import searchApi from 'resources/searchApi';
import ServiceProductApi from 'resources/serviceProductApi';

import type { listSharingUnitTemplatesApi } from './types';

export const listSharingUnitTemplates = ({
  pagination,
  filters,
}: listSharingUnitTemplatesApi) =>
  buyingUnitApi.post('/buyingunit/v3.5/templates/list', {
    sharingunit_template_type: Types.TEMPLATE.id,
    ...(pagination?.limit && { limit: pagination.limit }),
    ...(pagination?.limit && {
      offset: pagination.limit * (pagination.page - 1),
    }),
    ...(filters?.search && { search: filters.search }),
    ...(filters?.retailer && { target_organization_ids: filters.retailer }),
    ...(filters?.fromDate && {
      valid_from: filters.fromDate.format('YYYY-MM-DD'),
    }),
    ...(filters?.toDate && { valid_to: filters.toDate.format('YYYY-MM-DD') }),
    ...(filters?.statuses && { statuses: Object.keys(filters.statuses) }),
  });

export async function listRetailers() {
  try {
    const response = await buyingUnitApi.get('/buyingunit/v4/retailers/list');
    return _get('data', response);
  } catch (error) {
    return { error };
  }
}

export async function fetchDisplayGroups(
  targetOrganizationId: number,
  isTariff: boolean = false,
) {
  try {
    const response = await DisplayGroupApi.DisplayGroupSharingUnitList(
      targetOrganizationId,
      isTariff ? 'tariff' : 'sharingunit',
    );
    return _get('data.data', response);
  } catch (error) {
    return { error };
  }
}

export async function create(
  name: string,
  sourceOrganizationId: number,
  targetOrganizationId: number,
  data: object,
  type: number,
) {
  try {
    const response = await ServiceProductApi.post(
      '/product/v2/sharing-unit-templates',
      {
        name,
        sourceOrganization: { id: sourceOrganizationId },
        targetOrganization: { id: targetOrganizationId },
        data,
        type,
      },
    );
    return _get('data.data', response);
  } catch (error) {
    return { error };
  }
}

export async function get(id: number) {
  try {
    const response = await ServiceProductApi.get(
      `/product/v2/sharing-unit-templates/${id}`,
    );
    return _get('data.data', response);
  } catch (error) {
    return { error };
  }
}

export async function update(id: number, name: string, data: object) {
  try {
    const response = await ServiceProductApi.post(
      `/product/v2/sharing-unit-templates/${id}`,
      {
        name,
        data,
      },
    );
    return _get('data.data', response);
  } catch (error) {
    return { error };
  }
}

export const validateSharingUnit = async (
  sharingunit,
  sourceOrganizationId,
  targetOrganizationId,
  languages,
) => {
  try {
    const productVersion = _get('product.version', sharingunit);
    const response = await ServiceProductApi.post(
      `/product/v3/entities/check/multiple`,
      {
        entities: [
          {
            data: {
              _type: ENTITY_TYPE_SHARINGUNIT,
              id: sharingunit.id || 1,
              tags: { contentOwner: { id: sourceOrganizationId } },
              sharingUnits: [
                {
                  ...sharingunit,
                  ...{
                    targetOrganization: {
                      id: targetOrganizationId,
                    },
                    sourceOrganization: {
                      id: sourceOrganizationId,
                    },
                  },
                },
              ],
              ...(productVersion && {
                specificData: [
                  {
                    targetOrganization: { id: targetOrganizationId },
                    data: productVersion,
                  },
                ],
              }),
            },

            languages: languages,
            target_organization_ids: [targetOrganizationId],
          },
        ],
      },
    );
    return response.data;
  } catch (error) {
    return { error };
  }
};

export const validate = async (data: any) => {
  try {
    const response = await ServiceProductApi.post(
      `/product/v3/sharing-unit-templates/check`,
      data,
    );
    return response.data;
  } catch (error) {
    return { error };
  }
};

export async function upsert_product(
  template_id: number,
  sharing_units: any[],
) {
  try {
    const response = await buyingUnitApi.post(
      `/buyingunit/v3/templates/sharingunits/upsert`,
      {
        template_id,
        sharing_units,
      },
    );
    return _get('data', response);
  } catch (error) {
    return { error };
  }
}

export async function check_productshared(
  template_id: number,
  source_product_key_id: number,
) {
  try {
    const response = await buyingUnitApi.post(
      `/buyingunit/v3/templates/sharingunits/productshared`,
      {
        template_id,
        source_product_key_id,
      },
    );
    return _get('data', response);
  } catch (error) {
    return { error };
  }
}

export async function check_uniqproduct(
  template_id: number,
  source_product_key_id: number,
  hierarchy_id?: number,
  sharing_unit_id?: number,
) {
  try {
    const response = await buyingUnitApi.post(
      `/buyingunit/v3/templates/sharingunits/uniqproduct`,
      {
        template_id,
        source_product_key_id,
        ...(hierarchy_id ? { hierarchy_id } : {}),
        ...(sharing_unit_id ? { sharing_unit_id } : {}),
      },
    );
    return _get('data', response);
  } catch (error) {
    return { error };
  }
}

export async function fetchProducts(
  template_id: number,
  limit: number,
  offset: number,
  search: string,
  filterInError: boolean,
) {
  try {
    const response = await buyingUnitApi.post(
      `/buyingunit/v3/templates/sharingunits/list`,
      {
        template_id,
        limit,
        offset,
        search,
        only_with_errors: filterInError,
      },
    );
    return _get('data', response);
  } catch (error) {
    return { error };
  }
}

export async function fetchSharingUnit(
  id: number,
  isSource: boolean,
  forceSource: boolean = false,
) {
  try {
    const response = await ServiceProductApi.getSharingunit(id, {
      is_source: isSource,
      force_source: forceSource,
    });
    return _get('data.data', response);
  } catch (error) {
    return { error };
  }
}

export async function fetchProductVersion(
  sourceOrganizationId,
  targetOrganizationId,
  sourceProductKeyId,
) {
  try {
    const response = await searchApi.post('/search/v2/productversion', {
      filter_owners_in: [sourceOrganizationId],
      filter_shared_with_organization_ids: [targetOrganizationId],
      filter_product_key_id_in: [sourceProductKeyId],
      allow_heterogeneous_logistical_units: true,
      limit: 1,
    });
    return _get('data.data.0', response);
  } catch (error) {
    return { error };
  }
}

export async function deleteSharingUnits(payload: {
  sharing_unit_ids?: number[];
  template_id?: number;
}) {
  try {
    await buyingUnitApi.post(
      `/buyingunit/v3/sharingunits/delete-from-template`,
      {
        ...(payload.sharing_unit_ids
          ? { sharing_unit_ids: payload.sharing_unit_ids }
          : {}),
        ...(payload.template_id ? { template_id: payload.template_id } : {}),
      },
    );
    return { data: 'ok' };
  } catch (error) {
    return { error };
  }
}

export async function publish(id) {
  try {
    await ServiceProductApi.post(
      `/product/v2/sharing-unit-templates/${id}/publish`,
      {},
    );
    return {};
  } catch (error) {
    return { error };
  }
}

import { ChangeEvent } from 'react';

import { Text } from '@alkem/react-ui-inputs';

interface Props {
  id: string;
  index: number;
  value?: string;
  placeholder?: string;
  emptyValueString: string;
  onChange: (index: number, value: string) => void;
  onRemove: (index: number) => void;
  disabled?: boolean;
}

export default function StringListItem({
  disabled,
  emptyValueString,
  id,
  index,
  onChange,
  onRemove,
  placeholder,
  value,
}: Props) {
  const onLocalChange = (event: ChangeEvent) => {
    onChange(index, (event.target as HTMLInputElement).value);
  };

  const onLocalRemove = () => {
    onRemove(index);
  };

  return (
    <div className="StringList__item">
      <Text
        id={id}
        value={value}
        placeholder={placeholder}
        emptyValueString={emptyValueString}
        onChange={onLocalChange}
        disabled={disabled}
      />
      {!disabled && (
        <i
          className="mdi mdi-delete"
          onClick={onLocalRemove}
          data-testid="delete-icon"
        />
      )}
    </div>
  );
}

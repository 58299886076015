import { createAction } from 'utils/redux';

import {
  CANCEL_UNSYNC,
  OPEN_CONFIRM_UNSYNC_MODAL,
  OPEN_UNAVAILABLE_UNSYNC_MODAL,
  SEND_RETAILER_MESSAGE,
  SET_CONFIRM_UNSYNC_MODAL_OPENED,
  SET_LOADING_IN_PROGRESS,
  SET_TARGET_RECIPIENT,
  SET_UNAVAILABLE_UNSYNC_MODAL_OPENED,
  UNSYNC,
} from './constants';

// ======= ConfirmModal =======
export const setConfirmUnsyncModalOpened = createAction(
  SET_CONFIRM_UNSYNC_MODAL_OPENED,
);

// Sagas
export const openConfirmUnsyncModal = createAction(OPEN_CONFIRM_UNSYNC_MODAL);
export const cancelUnsync = createAction(CANCEL_UNSYNC);
export const unsync = createAction(UNSYNC);

// ===== Unavailable Modal =====
export const setUnavailableUnsyncModalOpened = createAction(
  SET_UNAVAILABLE_UNSYNC_MODAL_OPENED,
);
export const closeUnavailableUnsyncModal = () => (dispatch) =>
  dispatch(setUnavailableUnsyncModalOpened({ opened: false }));

// Sagas
export const openUnavailableUnsyncModal = createAction(
  OPEN_UNAVAILABLE_UNSYNC_MODAL,
);
export const sendRetailerMessage = createAction(SEND_RETAILER_MESSAGE);

// =========== Common ===========
export const setTargetRecipient = createAction(SET_TARGET_RECIPIENT);
export const setLoadingInProgress = createAction(SET_LOADING_IN_PROGRESS);

import { createSelector } from '@reduxjs/toolkit';

import { MODULE_REDUCER_KEY } from 'constants/reducer';

import { KEY } from '../reducers';

export const selectBulkValidateState = (state) =>
  state[MODULE_REDUCER_KEY][KEY];

export const selectBulkValidateVisible = createSelector(
  selectBulkValidateState,
  (moduleState) => moduleState.get('visible'),
);

export const selectProductVersionsToValidate = createSelector(
  selectBulkValidateState,
  (moduleState) => moduleState.get('productVersions'),
);

export const selectToValidateProductsInProgress = createSelector(
  selectBulkValidateState,
  (moduleState) => moduleState.get('inProgress'),
);

export const selectValidatedProductsInProgressCount = createSelector(
  selectBulkValidateState,
  (moduleState) => moduleState.get('count'),
);

export const selectValidatedProductsInProgressStatus = createSelector(
  selectBulkValidateState,
  (moduleState) => moduleState.get('status'),
);

export const selectToValidateProductsDone = createSelector(
  selectBulkValidateState,
  (moduleState) => moduleState.get('done'),
);

export const selectValidatedProductsErrors = createSelector(
  selectBulkValidateState,
  (moduleState) => moduleState.get('errors'),
);

export const selectValidatedProductsSuccess = createSelector(
  selectBulkValidateState,
  (moduleState) => moduleState.get('success'),
);

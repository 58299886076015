import { Map, fromJS } from 'immutable';
import { curry } from 'lodash/fp';

import {
  assignationStatusFilter,
  displayUnitsFilter,
  duplicatedProductsFilter,
  lifecycleFilter,
} from 'core/modules/list/constants/filters';
import { set, update } from 'utils/immutable';
import storage from 'utils/storage';

import { resetPage, resetSelection } from '../list';

export const initFiltersConfigState = curry((storageKey, state) =>
  set(state, 'filtersConfig', fromJS(storage.getItem(storageKey, {}))),
);

export const updateInFiltersConfig = (state, { payload }) =>
  update(state, 'filtersConfig', (filtersConfig) =>
    set(
      filtersConfig || Map(),
      [payload.key, payload.option.key],
      payload.option.value,
    ),
  );

export const collapseFilters = (state, { payload }) =>
  updateInFiltersConfig(state, {
    payload: {
      key: payload.key,
      option: { key: 'collapsed', value: payload.collapsed },
    },
  });

export const updateFilteredFilters = (state, { payload }) =>
  updateInFiltersConfig(state, {
    payload: {
      key: payload.key,
      option: {
        key: 'query',
        value: payload.query,
        type: payload.type,
        useAndOperator: payload.useAndOperator,
      },
    },
  });

export const updateFiltersPagination = (state, { payload }) =>
  updateInFiltersConfig(state, {
    payload: {
      key: payload.key,
      option: { key: 'page', value: payload.page },
    },
  });

export const updateDuplicatedProducts = (state, { payload } = {}) => {
  let newState = state;
  newState = resetPage(newState);
  newState = resetSelection(newState);

  return updateInFiltersConfig(newState, {
    payload: {
      key: duplicatedProductsFilter.key,
      option: {
        key: duplicatedProductsFilter.hasDuplicatedKey,
        value: payload,
      },
    },
  });
};

export const updateWithArchivedProducts = (state, { payload } = {}) => {
  let newState = state;
  newState = resetPage(newState);
  newState = resetSelection(newState);

  return updateInFiltersConfig(newState, {
    payload: {
      key: lifecycleFilter.key,
      option: { key: lifecycleFilter.withArchivedKey, value: payload },
    },
  });
};

export const updateWithAllTypes = (state, { payload } = {}) => {
  let newState = state;
  newState = resetPage(newState);
  newState = resetSelection(newState);

  return updateInFiltersConfig(newState, {
    payload: {
      key: displayUnitsFilter.key,
      option: { key: displayUnitsFilter.withAllTypesKey, value: payload },
    },
  });
};

export const updateWithRefusedAssignations = (state, { payload } = {}) => {
  let newState = state;
  newState = resetPage(newState);
  newState = resetSelection(newState);

  return updateInFiltersConfig(newState, {
    payload: {
      key: assignationStatusFilter.key,
      option: {
        key: assignationStatusFilter.withRefusedAssignation,
        value: payload,
      },
    },
  });
};

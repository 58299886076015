import classNames from 'classnames';
import { get } from 'lodash';
import { connect } from 'react-redux';

import Field from 'components/ui/form/field';
import Gln from 'components/ui/input/gln';
import i18n from 'utils/i18n';
import { isValidGln } from 'utils/validation';

export class FormGln extends Field {
  static propTypes = Object.assign({}, Field.propTypes);

  static defaultProps = Object.assign({}, Field.defaultProps);

  constructor(props) {
    super(props);
    this.state.errorMessage = '';
  }

  componentDidUpdate() {
    this.ensureDataIsPresent();
  }

  getValue = (event) => event.currentTarget.value;

  clearErrorMessage = () => {
    const { errorMessage } = this.state;
    if (errorMessage) {
      this.setState({ errorMessage: '' });
    }
  };

  validate = (value) => {
    if (!value) {
      this.clearErrorMessage();
      return;
    }
    if (isValidGln(value, true)) {
      this.clearErrorMessage();
    } else {
      this.setState({
        errorMessage: i18n.t('frontproductstream.gln.invalid_message.text', {
          defaultValue: 'This GLN is invalid',
        }),
      });
    }
  };

  handleChange = (event) => {
    this.validate(get(event, 'target.value'));
    super.handleChange(event);
  };

  render() {
    const { field, type, value } = this.props;
    const { errorMessage } = this.state;

    if (!field) {
      return null;
    }

    const displayPlaceholder = this.shouldDisplayItem('placeholder');
    const content = (
      <Gln
        id={this.getId()}
        onChange={this.handleChange}
        placeholder={displayPlaceholder ? field.placeholder : null}
        value={value || ''}
        disabled={this.isReadOnly()}
        maxLength={type === 'number' ? 20 : 3000}
      />
    );

    const renderedLabel = this.renderLabel('col-xs-4');
    const classes = {
      FormText__input: true,
      FormGln__input: true,
      'col-xs-8': !!renderedLabel,
      'col-xs-12': !renderedLabel,
    };

    return (
      <div className={classNames(this.getClasses({ 'FormGln row': true }))}>
        {renderedLabel}
        <div className={classNames(classes)}>
          {content}
          {this.renderPlugins()}
          {!!errorMessage && (
            <div className="FormField--error">
              <span>
                <strong>!</strong>{' '}
              </span>
              <span>{errorMessage}</span>
            </div>
          )}
        </div>
      </div>
    );
  }
}

export default connect()(FormGln);

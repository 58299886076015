import { hasFeature } from 'modules/feature-flag';
import { FEATURE_ALL_USERLABEL_MANAGEMENT } from 'modules/feature-flag/constants';
import { UserImmutable } from 'types';

export const hasUserLabelManagementFeature = (user: UserImmutable) =>
  hasFeature(user, FEATURE_ALL_USERLABEL_MANAGEMENT);

export const getLinksFromProductKeyIds = (
  productKeyIds: number[],
  userLabelIds: number[],
) =>
  productKeyIds.reduce(
    (acc: { product_key_id: number; label_id: number }[], pid: number) => {
      userLabelIds.forEach((userLabel: number) =>
        acc.push({ product_key_id: pid, label_id: userLabel }),
      );
      return acc;
    },
    [],
  );

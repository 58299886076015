import {
  // modals
  CANNOT_CHECK_MODAL_OPEN,
  CANNOT_CHECK_MODAL_RESET,
  CANNOT_CHECK_MODAL_SUBMIT,
  CONFIRM_REVIEW,
  CREATE_CONTRIBUTION,
  DIFF_MODAL_OPEN,
  DIFF_MODAL_RESET,
  DISPUTE_MODAL_OPEN,
  DISPUTE_MODAL_RESET,
  FETCH_CONTRIBUTION_DIFF, // actions
  FETCH_CONTRIBUTION_STATE_MACHINE,
  REMOVE_FIELD,
  ROLLBACK_FIELD,
  SELECT_FIELD,
  SEND_REVIEW,
  SET_CONTRIBUTIONS,
  SET_CONTRIBUTION_DIFF,
  SET_REVIEW_FIELD, // events
  SET_STATE_MACHINE,
  SUBMIT_PHYSICAL_CHECK,
  UPDATE_CONTRIBUTION_STATUS,
} from './constants';

// View Actions
export const fetchStateMachine = () => ({
  type: FETCH_CONTRIBUTION_STATE_MACHINE,
});

export const createContribution = (productVersion, contributor) => ({
  type: CREATE_CONTRIBUTION,
  payload: { productVersion, contributor },
});

export const updateStatus = (contributionId, status) => ({
  type: UPDATE_CONTRIBUTION_STATUS,
  payload: { contributionId, status },
});

export const selectField = (model, value) => ({
  type: SELECT_FIELD,
  payload: { model, value },
});

export const removeField = (model) => ({
  type: REMOVE_FIELD,
  payload: { model },
});

export const setReviewField = (transactionId, isAccepted) => ({
  type: SET_REVIEW_FIELD,
  payload: { transactionId, isAccepted },
});

export const rollbackField = (model) => ({
  type: ROLLBACK_FIELD,
  payload: { model },
});

export const submitPhysicalCheck = () => ({
  type: SUBMIT_PHYSICAL_CHECK,
});

export const confirmReview = () => ({
  type: CONFIRM_REVIEW,
});

export const sendReview = (message) => ({
  type: SEND_REVIEW,
  payload: { message },
});

// events (from saga)

export const setStateMachine = (stateMachine) => ({
  type: SET_STATE_MACHINE,
  payload: { stateMachine },
});

export const setContributions = (contributions) => ({
  type: SET_CONTRIBUTIONS,
  payload: { contributions },
});

// Cannot Check Modal Actions
export const openCannotCheckModal = () => ({
  type: CANNOT_CHECK_MODAL_OPEN,
});

export const resetCannotCheckModal = () => ({
  type: CANNOT_CHECK_MODAL_RESET,
});

export const submitCannotCheckChoice = (message) => ({
  type: CANNOT_CHECK_MODAL_SUBMIT,
  payload: { message },
});

// dispute modal actions
export const openDisputeModal = () => ({
  type: DISPUTE_MODAL_OPEN,
});

export const resetDisputeModal = () => ({
  type: DISPUTE_MODAL_RESET,
});

export const openDiffModal = () => ({
  type: DIFF_MODAL_OPEN,
});

export const resetDiffModal = () => ({
  type: DIFF_MODAL_RESET,
});

export const fetchContributionDiff = () => ({
  type: FETCH_CONTRIBUTION_DIFF,
});

export const setContributionDiff = (contributionDiff) => ({
  type: SET_CONTRIBUTION_DIFF,
  payload: { contributionDiff },
});

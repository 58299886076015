import { registerModuleReducer } from 'utils/modules/reducer';
import { registerSaga } from 'utils/modules/saga';

import { fetchHasRfpModule } from './actions';
import { SUB_MODULE_NAME } from './constants';
import { rfpUserInfoReducer } from './reducers';
import { UserInfoSaga } from './sagas';
import { selectHasRfpModule } from './selectors';

registerModuleReducer(SUB_MODULE_NAME, rfpUserInfoReducer);
registerSaga(UserInfoSaga);

export { fetchHasRfpModule, selectHasRfpModule };

export const DEFINE_ANCHOR_NAVIGATION_SECTION =
  'DEFINE_ANCHOR_NAVIGATION_SECTION';
export const SCROLLED_TO_ANCHOR_NAVIGATION_SECTION =
  'SCROLLED_TO_ANCHOR_NAVIGATION_SECTION';
export const SET_ANCHOR_NAVIGATION_SECTION = 'SET_ANCHOR_NAVIGATION_SECTION';
export const UPDATE_SCROLL_POSITION = 'UPDATE_SCROLL_POSITION';

export const START_LOADING = 'START_LOADING';
export const STOP_LOADING = 'STOP_LOADING';
export const RESET_LOADING = 'RESET_LOADING';

export const SHOW_ERROR_PAGE = 'SHOW_ERROR_PAGE';

export enum ScrollActionEnum {
  SCROLL_ACTION_GO_TO_ANCHOR = 'GO_TO_ANCHOR',
  SCROLL_ACTION_SCROLL = 'SCROLL',
}

import { Iterable } from 'immutable';
import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import Dropdown from 'components/ui/dropdown';
import { PictureSpecification } from 'components/ui/form/asset-list/buttons-bar';
import { loadReferentials } from 'modules/assets/actions';
import { selectReferentials } from 'modules/assets/selectors';
import { getAll as getAllRecipients } from 'modules/recipients/reducers';
import i18n from 'utils/i18n';

export const DownloadImageSpecsDropdown = () => {
  const recipients = useSelector(getAllRecipients);

  const referentials: {
    picturesspecification: PictureSpecification[];
  } = useSelector(selectReferentials);

  const dispatch = useDispatch();

  useEffect(() => {
    if (referentials) {
      return;
    }
    dispatch(loadReferentials());
  }, [dispatch, referentials]);

  const picturesSpecsOptions: PictureSpecification[] =
    referentials?.picturesspecification || [];

  // ImmutableJS typings are missing indexOf
  const recipientIds = recipients.map((r) => r?.get('id')) as Iterable<
    number,
    number | undefined
  > & {
    indexOf: (val: number) => number;
  };

  let options = picturesSpecsOptions.filter(
    (spec) => spec.data && recipientIds.indexOf(spec.data.organizationId) >= 0,
  );

  const downloadPicturesSpecs = (option: PictureSpecification) => {
    if (option.code === 'empty-state' || !option.data?.specsUrl) {
      return;
    }

    window.open(option.data.specsUrl);
  };

  if (options.length === 0) {
    options = [
      {
        code: 'empty-state',
        label: (
          <span className="NoResult">
            {i18n.t(
              'frontproductstream.asset_list.button_bar.retailer_specs.empty_text',
              { defaultValue: 'No image specification for your retailers' },
            )}
          </span>
        ),
      },
    ];
  }

  return (
    <Dropdown
      label={i18n.t(
        'frontproductstream.asset_list.button_bar.retailer_specs.download_button',
        { defaultValue: 'Download images specifications' },
      )}
      options={options}
      onClickOption={downloadPicturesSpecs}
      rightDropdown
    />
  );
};

import { notificationError } from 'actions/notification';
import { upsertUserSetting } from 'resources/authApi';
import i18n from 'utils/i18n';
import { fromJsIfNotImmutable } from 'utils/immutable';

import { selectUserSetting } from '../selectors';

import { UPDATE_USERSETTING } from './action-types';

export const updateUserSetting = (key, value) => async (dispatch, getState) => {
  value = fromJsIfNotImmutable(value);
  const oldValue = selectUserSetting(key, getState());
  try {
    dispatch({ type: UPDATE_USERSETTING, key, value });
    await upsertUserSetting({ key, value });
  } catch (error: any) {
    dispatch({ type: UPDATE_USERSETTING, key, value: oldValue });
    dispatch(
      notificationError(
        i18n.t('frontproductstream.user.update_setting_error.notification', {
          defaultValue: 'An error occured while updating your setting',
        }),
        { error },
      ),
    );
  }
};

import { PureComponent, ReactNode } from 'react';
import { connect } from 'react-redux';
import { compose } from 'redux';

import AnchoredBlock from 'components/ui/anchor-sticky-nav/anchored-block';
import withProps from 'hocs/with-props';
import { openFormGroup } from 'modules/display-groups/actions';
import { replaceLineBreaks } from 'utils/string';

import {
  registerBlockName,
  unregisterBlockName,
  updateBlockName,
} from '../../actions';

interface Props {
  id?: string | number;
  section: string;
  blockName: string;
  blockId?: string;
  position?: number;
  children: ReactNode;
  linked: boolean;
  collapsible?: boolean;
  collapsed?: boolean;
  'data-testid'?: string;
  registerBlockName: (...args: any[]) => void;
  updateBlockName: (...args: any[]) => void;
  unregisterBlockName: (...args: any[]) => void;
  openFormGroup?: (id?: string | number) => void;
}

const mapDispatchToProps = {
  registerBlockName,
  updateBlockName,
  unregisterBlockName,
  openFormGroup,
};

export class Item extends PureComponent<Props> {
  public static defaultProps = {
    linked: false,
    registerBlockName: () => {},
    unregisterBlockName: () => {},
  };

  public componentDidMount() {
    this.props.registerBlockName(
      this.props.section,
      this.props.blockName,
      this.props.blockId,
      this.props.position,
    );
  }

  public componentDidUpdate(prevProps) {
    const { blockName, blockId, section } = this.props;
    if (blockName !== prevProps.blockName) {
      this.props.updateBlockName(
        section,
        prevProps.blockName,
        blockName,
        blockId,
      );
    }
  }

  public componentWillUnmount() {
    this.props.unregisterBlockName(
      this.props.section,
      this.props.blockName,
      this.props.blockId,
    );
  }

  private toggleCollapsed = () => {
    if (
      this.props.collapsible &&
      this.props.collapsed &&
      this.props.openFormGroup
    ) {
      this.props.openFormGroup(this.props.id);
    }
  };

  public render() {
    const {
      blockId,
      blockName,
      children,
      linked,
      collapsible,
      collapsed,
      'data-testid': dataTestId,
    } = this.props;
    if (!linked) {
      return children;
    }
    return (
      <AnchoredBlock
        blockId={blockId}
        blockName={blockName}
        collapsible={collapsible}
        collapsed={collapsed}
        onCollapsedClick={this.toggleCollapsed}
        data-testid={dataTestId}
      >
        {children}
      </AnchoredBlock>
    );
  }
}

const enhance = compose(
  connect(null, mapDispatchToProps),
  withProps((props: Props) => ({
    blockName: replaceLineBreaks(props.blockName).trim(),
  })),
) as (component: any) => any;

export default enhance(Item);

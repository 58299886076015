import { OrderedMap, Set, fromJS } from 'immutable';
import { omit, update } from 'lodash/fp';

import { SHARING_UNIT, TARIFF } from 'constants/fields';
import {
  SHARING_UNIT_STATUS_AGREED,
  SHARING_UNIT_STATUS_INIT,
  SHARING_UNIT_STATUS_REFUSED,
} from 'modules/sharing-units/constants';
import { parseIfNumber } from 'utils';
import { get, set, size } from 'utils/immutable';

import { fromLinearHierarchy, toLinearHierarchy } from '../hierarchy';

export function getNextClientId(state) {
  if (state.get('sharingUnitsById').size === 0) {
    return 1;
  }
  return state.get('sharingUnitsById').last().get('clientId') + 1;
}

export const createOrderedMapById = (sharingUnits) =>
  sharingUnits.reduce((updatedSharingUnits, sharingUnit, index) => {
    const updatedSharingUnit = { ...sharingUnit };
    const data = get(sharingUnit, 'lastRequest.data') || sharingUnit.data || {};
    updatedSharingUnit.data = data;
    updatedSharingUnit.clientId = index + 1;
    return updatedSharingUnits.set(sharingUnit.id, fromJS(updatedSharingUnit));
  }, OrderedMap());

export const prepareForServer = (sharingUnits, ignoredFields = Set()) => {
  // create an array of keys and turn numbers into integers
  const removePaths = ignoredFields
    // convert Immutable.Set to JS array
    .toJS()
    // split string paths and convert key to number if possible,
    // so '1.a.b' becomes [1, 'a', 'b']
    .map((x) => x.split('.').map(parseIfNumber));

  // remove data from sharing units we don't want to send
  return (
    removePaths
      // remove fields that have been modified by the system (by initialization)
      .reduce((sus, ignoredField) => sus.deleteIn(ignoredField), sharingUnits)
      // convert to array
      .valueSeq()
      // TODO - PNZ (bobi) I could not find why those are sometimes 0, and I'm not sure yet
      // if the problem is in the front or in the back.
      //
      // > If the problem persists after this is merged, then the problem is in the back
      // > Sherlock Holmes
      // https://youtu.be/d_NhmyrcoQ4?t=58
      .filter(
        (su) =>
          su.getIn(['sourceOrganization', 'id']) &&
          su.getIn(['targetOrganization', 'id']) &&
          su.getIn(['product', 'id']),
      )
      .toJS()
      // remove clientId and lastRequest from each su
      .map(omit(['clientId', 'lastRequest']))
      .map((data) => ({ data }))
  );
};

export const canShareSharingUnit = (sharingUnit) =>
  get(sharingUnit, 'lastRequest.status') === 5;

export const isSharingUnitShared = (su) =>
  [
    SHARING_UNIT_STATUS_REFUSED,
    SHARING_UNIT_STATUS_AGREED,
    SHARING_UNIT_STATUS_INIT,
  ].includes(su.getIn(['status']));

export const withServerHierarchies = (sharingUnit) =>
  sharingUnit.hasIn(['data', 'hierarchy'])
    ? sharingUnit.updateIn(['data', 'hierarchy'], (v) => fromLinearHierarchy(v))
    : sharingUnit;

export function withLinearHierarchies(sharingUnit) {
  let updatedSharingUnit = sharingUnit;
  // Update data
  if (get(sharingUnit, 'data.hierarchy')) {
    updatedSharingUnit = update(
      'data.hierarchy',
      toLinearHierarchy,
      updatedSharingUnit,
    );
  }
  // Update last request
  if (get(sharingUnit, 'lastRequest.data.hierarchy')) {
    updatedSharingUnit = update(
      'lastRequest.data.hierarchy',
      toLinearHierarchy,
      updatedSharingUnit,
    );
  }
  return updatedSharingUnit;
}

export const selectFields = (recipient, isTariff, extraFilter) =>
  recipient
    ? recipient
        .get('usesFields')
        .filter(
          (f) => f.get('entityType') === (isTariff ? TARIFF : SHARING_UNIT),
        )
        .filter((f) => extraFilter(f) || f.get('name') === 'priceWaterfalls')
        .map((f) => f.get('name'))
        .toArray()
    : [];

export const baseFieldName = (path) => (path ? path.split('.')[0] : null);

export const traverseDisplaygGroups = (items, updateFunc, path = null) => {
  if (!items || !size(items)) {
    return items;
  }

  let _items = items;
  _items = _items
    .filter((i) => i)
    .map((item) => updateFunc(item, path))
    .filter((i) => i) // Refilter, to allow the updateFunc to return null to discard an item :boom:
    .map((item) => {
      if (get(item, 'kind') === 'DisplayGroup') {
        return set(
          item,
          'items',
          traverseDisplaygGroups(get(item, 'items'), updateFunc, path),
        );
      }

      return set(
        item,
        'children',
        traverseDisplaygGroups(
          get(item, 'children') || [],
          updateFunc,
          path ? `${path}.${get(item, 'model')}` : get(item, 'model'),
        ),
      );
    });

  return _items;
};

export const traverseAndSetReadOnlyForTemplated = (items) => {
  return traverseDisplaygGroups(items, (item) => {
    return set(item, 'options.readOnly', true);
  });
};

export const traverseAndSetReadOnlyForTariff = (items, recipient) => {
  const fields = selectFields(recipient, true, (f) => !!f.get('overridable'));
  return traverseDisplaygGroups(items, (item) =>
    fields.includes(item.model)
      ? set(item, 'options.isTariff', true)
      : set(item, 'options.readOnly', true),
  );
};

import { List, Map } from 'immutable';
import { memo, useMemo } from 'react';
import { useLocation } from 'react-router-dom';

import { Button } from '@alkem/react-ui-button';

import {
  TargetProductStatusAccepted,
  TargetProductStatusRefused,
  TargetProductStatusSubmitted,
  TargetProductStatusUpdated,
  TargetProductStatusWaitingToBeShared,
} from 'constants/targetProductStatus';
import { hasInformationRequest } from 'core/api/organization-settings';
import { getProductVersionTargetProductStatus } from 'core/api/productversion';
import { getOrganizationSettings, isRetailer } from 'core/api/user';
import ListTableCell from 'core/modules/list/components/table/cell';
import { PRODUCT_TO_REVIEW_STATUSES } from 'modules/product-review/constants';
import { catalogToReview as catalogToReviewRoute } from 'routes';
import { UserImmutable } from 'types';
import i18n from 'utils/i18n';
import { getOr } from 'utils/immutable';

import Action from '../action';

import './index.scss';

type Props = {
  productVersion: Map<string, any>;
  user: UserImmutable;
};

export const CatalogProductActionColumn = memo(
  ({ productVersion, user }: Props) => {
    const { pathname } = useLocation();
    const isCatalogToReview = useMemo(
      () => pathname.startsWith(catalogToReviewRoute),
      [pathname],
    );
    const targetProductStatus = useMemo(
      () =>
        isRetailer(user)
          ? getProductVersionTargetProductStatus(productVersion)
          : null,
      [user, productVersion],
    );
    const isProductToReview = useMemo(
      () =>
        Boolean(
          getOr(
            List(),
            ['alkemics', 'review_process', 'action_states'],
            productVersion,
          ).find((state) =>
            PRODUCT_TO_REVIEW_STATUSES.includes(state.get('status')),
          ),
        ),
      [productVersion],
    );
    const requestEngineEnabled = hasInformationRequest(
      getOrganizationSettings(user),
    );

    const {
      primary = false,
      secondary = false,
      requestEngineOnly = false,
      className,
      label,
    } = useMemo(() => {
      if (isCatalogToReview && isProductToReview) {
        return {
          primary: true,
          label: i18n.t('Verify product'),
          className: 'review',
        };
      }
      if (!isRetailer(user)) {
        return {
          primary: true,
          label: i18n.t('See product'),
          className: 'productpage',
        };
      }
      switch (targetProductStatus) {
        case TargetProductStatusWaitingToBeShared.id:
          return {
            secondary: true,
            label: i18n.t('See comments'),
            className: 'dashboard',
            requestEngineOnly: true,
          };
        case TargetProductStatusRefused.id:
        case TargetProductStatusSubmitted.id:
        case TargetProductStatusUpdated.id:
        case TargetProductStatusAccepted.id:
          return {
            primary: true,
            label: i18n.t('See product'),
            className: 'productpage',
          };
        default:
          return {};
      }
    }, [isCatalogToReview, isProductToReview, targetProductStatus, user]);

    if (!productVersion) {
      return <td className="CatalogProductActionColumn" />;
    }

    return (
      <ListTableCell className="CatalogProductActionColumn">
        {label && (!requestEngineOnly || requestEngineEnabled) && (
          <Action productVersion={productVersion} user={user}>
            <Button
              primary={primary}
              secondary={secondary}
              className={`CatalogProductActionColumn__${className}`}
            >
              <span className="CatalogProductActionColumn__btnTxt">
                {label}
              </span>
              <span className="mdi mdi-chevron-right CatalogProductActionColumn__btnChevron" />
            </Button>
          </Action>
        )}
      </ListTableCell>
    );
  },
);

export default CatalogProductActionColumn;

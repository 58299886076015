import { isArray, isFinite, isUndefined } from 'lodash';
import { put } from 'redux-saga/effects';

import { setPreselected } from 'modules/view-as/actions';

const asArray = (arrayOrString) => {
  if (isArray(arrayOrString)) {
    return arrayOrString;
  }
  return arrayOrString.split(',');
};

const parse = (ids) => {
  if (isUndefined(ids)) {
    return [];
  }
  return asArray(ids).map(Number).filter(isFinite);
};

export function* setViewAs({ payload: { organizationIds, ruleSetIds } }) {
  yield put(setPreselected(parse(organizationIds), parse(ruleSetIds)));
}

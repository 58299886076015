import classNames from 'classnames';
import PropTypes from 'prop-types';
import { PureComponent } from 'react';
import ImmutablePropTypes from 'react-immutable-proptypes';

import { Button } from '@alkem/react-ui-button';

import { RuleTemplateLabel } from 'modules/validation';
import i18n from 'utils/i18n';

import { shouldDisplayAcceptationButton } from '../../utils';
import '../../validator.scss';

import './warning.scss';

export default class WarningSuggestion extends PureComponent {
  static propTypes = {
    ruleResult: ImmutablePropTypes.map.isRequired,
    languageCode: PropTypes.string.isRequired,
    readOnly: PropTypes.bool,
    isRetailer: PropTypes.bool,
    displayActions: PropTypes.bool,
    onAcceptSuggestion: PropTypes.func.isRequired,
    onDismissSuggestion: PropTypes.func.isRequired,
    isNormalizedComment: PropTypes.bool.isRequired,
    isBlockingRule: PropTypes.bool.isRequired,
    accepted: PropTypes.bool.isRequired,
  };

  renderActions() {
    const {
      readOnly,
      isRetailer,
      ruleResult,
      displayActions,
      onAcceptSuggestion,
      onDismissSuggestion,
      isNormalizedComment,
      languageCode,
    } = this.props;
    const { templateLabel } = ruleResult;

    if (!displayActions || (readOnly && !isRetailer)) {
      return null;
    }

    let acceptLabel = i18n.t(
      'frontproductstream.plugins.suggestion.create_button.read_only_label',
      { defaultValue: 'Request change' },
    );
    if (!readOnly) {
      acceptLabel = [
        RuleTemplateLabel.SIMPLE,
        RuleTemplateLabel.VALUE,
      ].includes(templateLabel)
        ? i18n.t(
            'frontproductstream.plugins.suggestion.create_button.replace_label',
            { defaultValue: 'Replace' },
          )
        : i18n.t(
            'frontproductstream.plugins.suggestion.create_button.add_label',
            { defaultValue: 'Add' },
          );
    }

    let dismissLabel = i18n.t(
      'frontproductstream.plugins.suggestion.dissmiss_button.label',
      { defaultValue: 'Dismiss' },
    );
    if (readOnly && isNormalizedComment) {
      dismissLabel = i18n.t(
        'frontproductstream.plugins.suggestion.dissmiss_button.read_only_label',
        { defaultValue: 'Cancel' },
      );
    }

    return (
      <div className="RaguelWarning__suggestionActionContainer">
        {shouldDisplayAcceptationButton(ruleResult, languageCode) && (
          <Button
            link
            content={acceptLabel}
            className="RaguelWarning__suggestionAction RaguelWarning__suggestionAction--accept"
            onClick={onAcceptSuggestion}
          />
        )}
        <Button
          link
          content={dismissLabel}
          className="RaguelWarning__suggestionAction RaguelWarning__suggestionAction--dismiss"
          onClick={onDismissSuggestion}
        />
      </div>
    );
  }

  renderSuggestion(ruleResult, accepted, isNormalizedComment) {
    const { languageCode } = this.props;
    const classes = {
      RaguelWarning__suggestion: true,
      'RaguelWarning__suggestion--normalizedComment': isNormalizedComment,
    };
    return (
      <span
        id={`raguel-suggestion-message-${ruleResult.get('id')}`}
        className="RaguelWarning__suggestionContainer"
      >
        <div className={classNames(classes)}>
          <div className="RaguelWarning__suggestionLabel">
            <i className="mdi mdi-alert RaguelWarning__suggestionIcon" />
            <span className="RaguelWarning__suggestionMessage">
              {ruleResult.getIn([
                'error_by_language',
                languageCode,
                'errorMessage',
              ])}
            </span>
          </div>
          {!accepted && this.renderActions()}
        </div>
      </span>
    );
  }

  renderBlockingSuggestion(ruleResult, accepted) {
    const { languageCode } = this.props;
    return (
      <span
        className="Raguel__default"
        id={`raguel-suggestion-message-${ruleResult.get('id')}`}
      >
        <div className="Raguel__message">
          <div className="RaguelWarning__suggestionLabel">
            <i className="mdi mdi-cancel" />
            <span className="Raguel__message">
              {ruleResult.getIn([
                'error_by_language',
                languageCode,
                'errorMessage',
              ])}
            </span>
          </div>
          {!accepted && this.renderActions()}
        </div>
      </span>
    );
  }

  render() {
    const { ruleResult, accepted, isNormalizedComment, isBlockingRule } =
      this.props;
    if (isBlockingRule) {
      return this.renderBlockingSuggestion(ruleResult, accepted);
    } else {
      return this.renderSuggestion(ruleResult, accepted, isNormalizedComment);
    }
  }
}

import type { PayloadAction } from '@reduxjs/toolkit';
import { call } from 'redux-saga/effects';

import { navigate } from '@alkem/micro-frontend-tools';

import { resetLastActivityStorage } from 'modules/idle/utils';
import * as routes from 'routes';

import { loadProfileSaga } from './loadProfile';

export function* afterLogasSuccessSaga({
  payload: { next } = {},
}: PayloadAction<{ next?: string }>) {
  yield call(resetLastActivityStorage);
  yield call(loadProfileSaga, { payload: false });
  yield call(navigate, { to: routes.landing, queries: next ? { next } : {} });
}

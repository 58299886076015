export const WEB_OPTIMIZED = 5;
export const OUT_OF_PACKAGE = 0;
export const PACKED_PRODUCT = 1;
export const ASSEMBLED_PREPARED_PRODUCT = 2;
export const IN_SITUATION = 3;
export const IN_CASE = 6;
export const IN_INNERPACK = 7;
export const RAW_UNCOOKED = 8;
export const IN_PLATE = 9;
export const STYLED = 10;
export const STAGED_FOR_DISPLAY = 11;
export const HELD = 12;
export const WORN = 13;
export const WITH_RELATED_PRODUCTS = 14;
export const IN_OPEN_CASE = 15;
export const PRODUCT_ARTWORK = 16;
export const N_A = 4;

export const backgroundCheckContentTypes = [
  OUT_OF_PACKAGE,
  PACKED_PRODUCT,
  WEB_OPTIMIZED,
  IN_CASE,
  IN_INNERPACK,
];

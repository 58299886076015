import { hasInformationRequest } from 'core/api/organization-settings';
import { getOrganizationSettings, isAdmin, isRetailer } from 'core/api/user';
import { FilterAggregation, UserImmutable } from 'types';
import { get } from 'utils/immutable';

import { AggregationResponse } from './types';

export const hasFeatureInformationRequestList = (user: UserImmutable) =>
  isRetailer(user) &&
  hasInformationRequest(getOrganizationSettings(user)) &&
  isAdmin(user);

export const extractFilterValues = (selectedFilterMap, key) => {
  let data = [];
  const values = get(selectedFilterMap, key);
  if (values) {
    data = values.keySeq().toArray();
  }
  return data;
};

export const formatOnboardingAggregations = (aggregations: {
  [key: string]: AggregationResponse | FilterAggregation[];
}): { [key: string]: FilterAggregation[] } => {
  return Object.fromEntries(
    Object.entries(aggregations).map(([aggName, agg]) => {
      if (!agg || Array.isArray(agg)) {
        return [aggName, agg];
      }
      return [
        aggName,
        agg.buckets?.map((bucket) => ({
          id: bucket.key,
          name: bucket.key,
          doc_count: bucket.doc_count,
        })),
      ];
    }),
  );
};

import { createReducer } from 'redux-create-reducer';

import { RECEIVE_POLICY_LIST } from 'constants/events/policy';

const initialState = {
  list: [],
};

export default createReducer(initialState, {
  [RECEIVE_POLICY_LIST]: (state, action) => {
    const newState = { ...state };
    newState.list = action.list;
    return newState;
  },
});

import {
  ADMIN_LINK_DEV,
  ADMIN_LINK_PPR,
  ADMIN_LINK_PRD,
} from 'constants/links';

import {
  isDevelopment,
  isPreProduction,
  isProduction,
  isRealPreproduction,
  isRealProduction,
} from './index';

export const getAdminURL = () => {
  if (isDevelopment()) {
    return ADMIN_LINK_DEV;
  } else if (isProduction() || isRealProduction()) {
    return ADMIN_LINK_PRD;
  } else if (isPreProduction() || isRealPreproduction()) {
    return ADMIN_LINK_PPR;
  }
  return '';
};

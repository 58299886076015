import classNames from 'classnames';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';

import { InputDisabled } from '@alkem/react-ui-inputs';

import { updateEntity } from 'actions/entity';
import Field from 'components/ui/form/field';
import { cleanFieldLabel } from 'components/ui/form/field/utils/clean';
import { renderField } from 'components/ui/form/field/utils/render';
import { getDefaultValue } from 'components/ui/form/field/utils/seed';
import i18n from 'utils/i18n';
import { get, size } from 'utils/immutable';

import './declinable.scss';

export class DeclinableField extends Field {
  static propTypes = Object.assign({}, Field.propTypes, {
    currentLanguage: PropTypes.object,
  });

  constructor(props) {
    super(props);
    this.state.messages = [];
    this.languageHasChanged = false;
  }

  componentDidUpdate() {
    this.validate(this.props.value);
    this.ensureDataIsPresent();
  }

  shouldComponentUpdate(nextProps, nextState) {
    // A dispatch has been made, the update will be made in the next cycle.
    if (this.cleanValue(nextProps.value)) {
      return false;
    }
    this.languageHasChanged =
      nextProps.currentLanguage !== this.props.currentLanguage;
    return (
      super.shouldComponentUpdate(nextProps, nextState) ||
      this.languageHasChanged
    );
  }

  enrichFieldChildren(elementToDisplayIndex) {
    const { field } = this.props;
    const children = field.children.map((_f) => {
      const f = cleanFieldLabel(_f);
      f.model = `${field.model}.${elementToDisplayIndex}.${f.model}`;
      f.label = field.label; // information for data-ops
      return f;
    });
    return children;
  }

  isDeclinableByLang() {
    const { field } = this.props;
    return field.declinableBy.kind === 'languages';
  }

  validate(value) {
    if (!this.props.validate) {
      return null;
    }
    if (this.isDeclinableByLang() || !value || !size(value)) {
      return this.setMessages([]);
    }
    const valueToCheck = value[0];
    if (
      !!valueToCheck.expressedIn ||
      valueToCheck.data === null ||
      typeof valueToCheck.data === 'undefined' ||
      valueToCheck.data === ''
    ) {
      return this.setMessages([]);
    }
    return this.setMessages([
      i18n.t('frontproductstream.field.declinable.unit_error', {
        defaultValue: 'The unit is required when a value is present',
      }),
    ]);
  }

  setMessages(messages) {
    // Check if messages have changed before setting state
    if (
      messages.length !== this.state.messages.length ||
      messages.some((item, i) => item !== this.state.messages[i])
    ) {
      this.setState({ messages });
    }
  }

  isEmpty(value) {
    // Language declinabled will not have any issues because the lang is handled differently.
    if (this.isDeclinableByLang()) {
      return false;
    }
    // This is the default value filled by ensureDataIsPresent.
    // This is required to avoid an enless loop.
    if (
      value.expressedIn === null &&
      (value.data === null || value.data === '')
    ) {
      return false;
    } else if (!value.expressedIn && !value.data) {
      return true;
    }
    return false;
  }

  cleanValue(list) {
    const { dispatch, entityKind, entityId, field } = this.props;
    let value = [];
    if (Array.isArray(list)) {
      value = [...list];
    }

    let clean = false;

    for (let i = 0; i < size(value); i += 1) {
      if (this.isEmpty(value[i])) {
        // This is required to be able to clear a declinable.
        value.splice(i, 1);
        i -= 1;
        clean = true;
      }
    }

    if (clean) {
      dispatch(updateEntity(field.model, value, entityId, entityKind, true));
    }
    return clean;
  }

  getDataFieldIndex(field) {
    return field.children[0].model === 'data' ? 0 : 1;
  }

  ensureDataIsPresent() {
    const { field, value, currentLanguage } = this.props;
    const { defaultValue, changed } = getDefaultValue(
      field,
      value,
      currentLanguage,
    );
    if (changed) {
      this.dispatchChange(defaultValue, false); // Not dirty.
    }
    return changed;
  }

  getElementToDisplayIndexes(props) {
    const { value, currentLanguage } = props || this.props;

    let index = null;
    if (!value || !size(value)) {
      return index;
    }
    if (!this.isDeclinableByLang()) {
      return Array.from(value.keys());
    }
    value.forEach((elem, i) => {
      if (
        currentLanguage &&
        get(elem, 'expressedIn.id') === currentLanguage.id
      ) {
        index = [i];
      }
    });
    return index;
  }

  renderContent() {
    const {
      entity,
      entityKind,
      entityId,
      field,
      value,
      validate,
      hasProductUpdatePermission,
      extraParams,
    } = this.props;

    const shouldDisplayLabel = this.shouldDisplayItem('label');
    const isReadOnly = this.isReadOnly();
    const elementToDisplayIndexes = this.getElementToDisplayIndexes();

    if (
      !value ||
      !size(value) ||
      elementToDisplayIndexes === null ||
      elementToDisplayIndexes.length === 0
    ) {
      if (isReadOnly) {
        const classes = {
          'col-xs-8': shouldDisplayLabel,
          'col-xs-12': !shouldDisplayLabel,
          InputField__input: true,
        };
        return (
          <div className={classNames(classes)}>
            <InputDisabled id={this.getId()} />
          </div>
        );
      }
      return null;
    }

    const inputIndex = this.getDataFieldIndex(field);
    const isDeclinableByLang = this.isDeclinableByLang();

    return (
      <div
        className={classNames({
          'col-xs-8': shouldDisplayLabel,
          'col-xs-12': !shouldDisplayLabel,
          DeclinableField__contentContainer: true,
        })}
      >
        {elementToDisplayIndexes.map((i) => {
          const children = this.enrichFieldChildren(i);
          // If multiple elements, only display once.
          const isLast =
            i === elementToDisplayIndexes[elementToDisplayIndexes.length - 1];
          return (
            <div className="DeclinableField__content" key={i}>
              <div
                className={classNames(
                  'DeclinableField__innerContent',
                  !isDeclinableByLang && 'DeclinableField__innerContent--full',
                )}
              >
                {[
                  renderField(
                    entity,
                    entityKind,
                    entityId,
                    `${field.model}-0`,
                    children[inputIndex],
                    false,
                    hasProductUpdatePermission,
                    null,
                    extraParams,
                  ),
                  this.isDeclinableByLang()
                    ? null
                    : renderField(
                        entity,
                        entityKind,
                        entityId,
                        `${field.model}-1`,
                        children[inputIndex === 0 ? 1 : 0],
                        false,
                        hasProductUpdatePermission,
                        null,
                        extraParams,
                      ),
                ]}
              </div>
              {this.renderPlugins({
                withRaguel: isLast,
                withKushiel: isLast,
                withDataOps: isLast,
                raguelOpt: {
                  model: `${field.model}.${i}.data`,
                },
              })}
            </div>
          );
        })}
        {validate ? this.renderMessages() : null}
      </div>
    );
  }

  renderMessages() {
    const { messages } = this.state;
    if (!messages.length) {
      return null;
    }
    return (
      <div>
        {messages.map((message) => (
          <div className="FormField--error" key={message}>
            <span>
              <strong>!</strong>{' '}
            </span>
            <span>{message}</span>
          </div>
        ))}
      </div>
    );
  }

  render() {
    const { field } = this.props;
    if (field.children.length !== 2) {
      return null;
    }
    return (
      <div
        className={classNames(this.getClasses({ 'DeclinableField row': true }))}
      >
        {this.shouldDisplayItem('label') ? this.renderLabel('col-xs-4') : null}
        {this.renderContent()}
      </div>
    );
  }
}

export default connect()(DeclinableField);

import { fromJS } from 'immutable';
import { all, call, put, takeEvery, takeLatest } from 'redux-saga/effects';

import { CONSUMER_UNIT, SHARING_UNIT } from 'constants/fields';
import { RECEIVE_RECIPIENTS_LIST } from 'modules/recipients/constants';
import { fieldsApi } from 'resources';
import { requestWithHeaders } from 'utils/api';
import { withCatch } from 'utils/saga';

import { init as initViewAs } from './actions';
import { ALWAYS_DISPLAYED_TAG } from './constants';
import * as events from './events';

export default function* viewAsSaga() {
  yield takeLatest(
    events.VIEW_AS_FETCH_ALWAYS_DISPLAYED_FIELDS,
    withCatch(fetchAlwaysVisibleFields),
  );

  yield takeEvery(RECEIVE_RECIPIENTS_LIST, withCatch(initViewAsSaga));
}

export function* initViewAsSaga() {
  yield put(initViewAs());
}

export function* fetchAlwaysVisibleFields() {
  const allFields = yield all([
    call(fetchAlwaysVisibleFieldsForEntityType, CONSUMER_UNIT),
    call(fetchAlwaysVisibleFieldsForEntityType, SHARING_UNIT),
  ]);

  const fields = fromJS(
    allFields.reduce((acc, v) => Object.assign(acc, v), {}),
  );
  yield put({
    type: events.VIEW_AS_RECEIVE_ALWAYS_DISPLAYED_FIELDS,
    fields,
  });
}

export function* fetchAlwaysVisibleFieldsForEntityType(entityType) {
  const { result, error } = yield call(
    requestWithHeaders,
    fieldsApi,
    'getLabels',
    {
      entity_type: entityType,
      tag: ALWAYS_DISPLAYED_TAG,
    },
  );
  if (error) {
    return { [entityType]: [] };
  }

  return { [entityType]: Object.keys(result.data) };
}

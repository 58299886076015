import classNames from 'classnames';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { createStructuredSelector } from 'reselect';

import SearchAutocomplete from 'components/ui/autocomplete/search';
import Field from 'components/ui/form/field';
import { getDisplayName } from 'core/api/productversion';
import {
  selectCurrentLanguage,
  selectCurrentProductVersionGTIN,
  selectTargetMarketId,
} from 'reducers/productVersion';
import { get, toJsIfImmutable } from 'utils/immutable';

import { getEcxludedTypePackagings } from './selectors';

export class FormProductAutocomplete extends Field {
  static propTypes = {
    ...Field.propTypes,
    excludedTypesPackaging: PropTypes.array.isRequired,
    currentLanguage: PropTypes.object,
  };

  shouldComponentUpdate(nextProps, nextState) {
    // We override this method because we are checking the id within the value.
    return (
      nextState !== this.state ||
      get(nextProps, 'value.id') !== get(this.props, 'value.id') ||
      nextProps.field !== this.props.field ||
      nextProps.currentLanguage !== this.props.currentLanguage ||
      this.getPatch(nextProps) !== this.getPatch()
    );
  }

  componentDidUpdate() {
    this.ensureDataIsPresent();
  }

  getValue(data) {
    return data.value || null;
  }

  makeSearchQuery() {
    const {
      excludedTypesPackaging,
      currentProductVersionGTIN,
      currentTargetMarketID,
    } = this.props;
    const mustNotContainTypePackagings = excludedTypesPackaging.map(
      (typePackaging) => ({
        query: typePackaging,
        fields: ['typePackaging.id'],
      }),
    );

    return {
      filter_source_include: ['isConsumerUnit'],
      advanced_search: {
        must_not: [
          { query: 3, fields: ['alkemics.lifeCycle'] },
          ...mustNotContainTypePackagings,
        ],
        must: currentTargetMarketID
          ? [
              {
                query: currentTargetMarketID,
                fields: ['tags.targetMarket.id'],
                type: 'match',
              },
            ]
          : [],
      },
      filter_gtins_out: currentProductVersionGTIN
        ? [currentProductVersionGTIN]
        : [],
    };
  }

  formatLabel = (item) => {
    if (!item || !item.version) {
      return '';
    }
    const { currentLanguage } = this.props;
    const displayName = getDisplayName(item.version, currentLanguage);
    const separator = item.gtin && displayName ? ' - ' : '';
    return `${item.gtin || ''}${separator}${displayName || ''}`;
  };

  render() {
    const { value, field } = this.props;

    if (!this.isVisible()) {
      return null;
    }

    const hasLabel = this.shouldDisplayItem('label');
    const autocompleteClasses = `InputField__input FormAutocomplete__input ${
      hasLabel ? 'col-xs-8' : 'col-xs-12'
    }`;
    return (
      <div className={classNames(this.getClasses({ FormAutocomplete: true }))}>
        <div className="InputField row">
          {this.renderLabel('col-xs-4')}
          <span className={classNames(autocompleteClasses)}>
            <SearchAutocomplete
              id={this.getId()}
              value={value ? [toJsIfImmutable(value)] : []}
              onSelect={this.handleChange}
              onUnselect={this.handleChange}
              options={field.options}
              path="/search/v2/productversion"
              placeholder={field.placeholder || field.label}
              disabled={this.isReadOnly()}
              body={this.makeSearchQuery()}
              searchOnClick
              disableOnClickOutside
              delegateSelection
              currentLanguage={this.props.currentLanguage}
              formatLabel={this.formatLabel}
            />
          </span>
        </div>
        {this.renderPlugins({ offset: true })}
      </div>
    );
  }
}

const mapStateToProps = createStructuredSelector({
  excludedTypesPackaging: getEcxludedTypePackagings,
  currentProductVersionGTIN: selectCurrentProductVersionGTIN,
  currentTargetMarketID: selectTargetMarketId,
  currentLanguage: (state, { currentLanguage }) => {
    return currentLanguage || selectCurrentLanguage(state);
  },
});

export default connect(mapStateToProps)(FormProductAutocomplete);

import { combineReducers } from 'redux';

import modal from './modal';
import productLabels from './product-labels';

export const KEY = 'userLabelModule';

export default combineReducers({
  modal,
  productLabels,
});

import { List } from 'immutable';
import { number, oneOfType, string } from 'prop-types';
import { list } from 'react-immutable-proptypes';
import processString from 'react-process-string';

import { LazyTooltip } from '@alkem/react-ui-tooltip';

import i18n from 'utils/i18n';
import { get } from 'utils/immutable';

import './index.scss';

export default function SourceOrganizationsCell({ data, productVersionId }) {
  if (!List.isList(data) || data.isEmpty()) {
    return null;
  }
  const tooltipId = `source-org-tooltip-${productVersionId}`;
  const firstOrg = get(data, [0, 'name']);
  const otherOrgs = data.shift();
  const name = `__NAME__`;
  const count = `__COUNT__`;
  let text;
  switch (otherOrgs.size) {
    case 0:
      text = name;
      break;
    case 1:
      text = i18n.t('{{name}} and {{count}} other', { name, count });
      break;
    default:
      text = i18n.t('{{name}} and {{count}} others', { name, count });
  }
  return (
    <div className="SourceOrganizationsCell ListTextCell">
      {processString([
        {
          regex: /__NAME__/,
          fn: (key) => <strong key={key}>{firstOrg}</strong>,
        },
        {
          regex: /__COUNT__/,
          fn: (key) => (
            <LazyTooltip
              key={key}
              id={tooltipId}
              tooltipLabel={otherOrgs
                .map((org) => get(org, 'name'))
                .join('<br />')}
              html
            >
              <u>{otherOrgs.size}</u>
            </LazyTooltip>
          ),
        },
      ])(text)}
    </div>
  );
}

SourceOrganizationsCell.propTypes = {
  data: list,
  productVersionId: oneOfType([number, string]).isRequired,
};

import classNames from 'classnames';
import { Map, fromJS } from 'immutable';
import { inRange } from 'lodash';
import { bool, func } from 'prop-types';
import { Component } from 'react';
import { map } from 'react-immutable-proptypes';

import i18n from 'utils/i18n';
import { toPercentage } from 'utils/number';

import './index.scss';

export default class SimpleQualityCell extends Component {
  static className = 'SimpleQualityCell';

  static propTypes = {
    data: map,
    referential: map.isRequired,
    isAggregation: bool,
    onClickCell: func.isRequired,
  };

  static defaultProps = {
    data: Map({ ok: 0, doc_count: 0 }),
  };

  defaultConfig = fromJS({
    quality: {
      good: [80, 100],
      medium: [60, 80],
      low: [0, 60],
    },
    clickable: false,
  });

  onClick = () => {
    const { onClickCell, referential, isAggregation } = this.props;
    onClickCell({
      referential: referential.hasIn(['filter', 'fail'])
        ? referential.set('filter', referential.getIn(['filter', 'fail']))
        : referential,
      isAggregation,
    });
  };

  getQuality(quality) {
    switch (true) {
      case this.isQualityRange(quality, 'good'):
        return 'good';
      case this.isQualityRange(quality, 'medium'):
        return 'medium';
      case this.isQualityRange(quality, 'low'):
        return 'low';
      default:
        return 'neutral';
    }
  }

  isQualityRange(quality, level) {
    const { referential } = this.props;
    const config = referential.get('cell') || this.defaultConfig;
    if (config.getIn(['quality', level])) {
      const [min, max] = config.getIn(['quality', level]);
      return inRange(quality, min, max + 1);
    }
    return false;
  }

  isClickable(percentage) {
    const { referential } = this.props;
    const config = referential.get('cell') || this.defaultConfig;
    return config.get('clickable') && percentage < 100;
  }

  computeData(data) {
    const { isInteger } = Number;
    const ok = data.get('ok');
    const total = data.get('doc_count');
    let computed;
    if (isInteger(ok) && isInteger(total) && total > 0) {
      const p = Math.min(toPercentage(ok, total), 100);
      computed = {
        percentage: `${p}%`,
        count: `${ok} / ${total}`,
        quality: this.getQuality(p),
        isClickable: this.isClickable(p),
      };
    } else {
      computed = {
        percentage: '-',
        count: i18n.t(
          'frontproductstream.reporting_page_result_cell.simple_quality_not_applicable.text',
          { defaultValue: 'N.A.' },
        ),
      };
    }
    return computed;
  }

  render() {
    const { data, isAggregation } = this.props;
    if (!data || data.isEmpty()) {
      return null;
    }
    const { percentage, count, quality, isClickable } = this.computeData(data);
    return (
      <div
        className={classNames(
          'SimpleQualityCell',
          isClickable && 'SimpleQualityCell--clickable',
        )}
        onClick={isClickable ? this.onClick : undefined}
      >
        <div
          className={classNames(
            'SimpleQualityCell__percentage',
            quality && `SimpleQualityCell__percentage--${quality}`,
          )}
        >
          {percentage}
        </div>
        {isAggregation ? (
          <div className="SimpleQualityCell__chart">
            <div
              className={classNames(
                'SimpleBarChart__chartValue',
                quality && `SimpleBarChart__chartValue--${quality}`,
              )}
              style={{ width: percentage }}
            />
          </div>
        ) : (
          <div className="SimpleQualityCell__count">{count}</div>
        )}
      </div>
    );
  }
}

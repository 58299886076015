import { get } from 'lodash/fp';
import { PureComponent } from 'react';
import { connect } from 'react-redux';
import { createStructuredSelector } from 'reselect';

import Dropdown from 'components/ui/dropdown';
import * as lifeCycle from 'constants/filters/lifeCycle';
import {
  TypePackagingLabels,
  getLogisticalTypePackagingDemonstrative,
  getTypePackagingCode,
} from 'constants/typePackaging';
import { getDisplayName } from 'core/api/productversion';
import {
  CurrentVersionInfo,
  DataMap,
  HierarchyMap,
} from 'modules/logistical-hierarchies/structures';
import { Statuses } from 'modules/product-page/modules/textile/constants';
import {
  selectCurrentLanguage,
  selectTextileVariantList,
} from 'reducers/productVersion';
import i18n from 'utils/i18n';
import { separateActions } from 'utils/redux';

import { createUnit } from '../../actions';
import {
  getAllowedTypePackagingOptions,
  getMainHierarchyUnit,
  isBaseProductInTree,
} from '../../helpers';
import { selectBranchGtins, selectUnitCreationOptions } from '../../selectors';

import './create-unit.scss';

const mapStateToProps = createStructuredSelector({
  baseOptions: selectUnitCreationOptions,
  branchGtins: selectBranchGtins,
  textileVariantList: selectTextileVariantList,
  currentLanguage: selectCurrentLanguage,
});

const mapDispatchToProps = {
  createUnit,
};

type NamePublicLong = {
  data: string;
  expressedIn: {
    id: number;
  };
};

interface Option {
  gtin: string;
  productIdentifier?: string;
  label?: string;
  isCurrentVersion?: boolean;
  version: {
    isConsumerUnit?: boolean;
    isDespatchUnit?: boolean;
    lifeCycle?: number;
    displayName?: string;
    typePackaging: { id: number };
    namePublicLong?: NamePublicLong[];
  };
}

export interface LogisticalHierarchyCreateUnitDropdownProps {
  internalId: string;
  typePackagingId: number;
  isStandardTextileCase?: boolean;
  // from store
  baseOptions: {
    [id: string]: Option[];
  };
  branchGtins: {
    [id: string]: string[];
  };
  textileVariantList?: {
    textileVariant: {
      gtin: string;
    };
  }[];
  currentLanguage?: object;
  actions: {
    createUnit: (
      internalId: string,
      gtin: string,
      productIdentifier: string,
      version: Option['version'],
      insertFirst?: boolean,
      isPatchedHierarchy?: boolean,
    ) => void;
  };
  isPatchedHierarchy?: boolean;
  mainHierarchyUnitId?: string;
  hierarchyMap: HierarchyMap;
  dataMap: DataMap;
  currentVersionInfo: CurrentVersionInfo;
}

export class LogisticalHierarchyCreateUnitDropdown extends PureComponent<LogisticalHierarchyCreateUnitDropdownProps> {
  getEnrichedOptions() {
    const {
      internalId,
      typePackagingId,
      isStandardTextileCase,
      currentLanguage,
      baseOptions,
      branchGtins,
      textileVariantList,
      isPatchedHierarchy,
      mainHierarchyUnitId,
      hierarchyMap,
      currentVersionInfo,
      dataMap,
    } = this.props;

    const variantCreationOptions = (textileVariantList || [])
      .filter((v) => !!get(['textileVariant', 'gtin'], v))
      .filter((v) => get(['status'], v) === Statuses.ACTIVE.id)
      .map((v) => ({
        gtin: v.textileVariant.gtin,
        label: `${v.textileVariant.gtin} - ${getDisplayName(
          get(['textileVariant', 'version'], v),
          currentLanguage,
        )}`,
        version: get(['textileVariant', 'version'], v),
      }));

    let enrichedOptions: Option[] = [];

    if (isStandardTextileCase) {
      // If already standard hierarchy, only allow adding variants.
      enrichedOptions = variantCreationOptions;
    } else {
      const allowedPackagingTypes =
        getAllowedTypePackagingOptions(typePackagingId);

      for (const typePackaging of allowedPackagingTypes) {
        // Add new units.
        // Cannot create a new each here.
        if (typePackaging.id !== TypePackagingLabels.EACH.id) {
          enrichedOptions.push({
            gtin: '',
            productIdentifier: '',
            label: i18n.t(
              'frontproductstream.create_logistical_unit.create_label.text',
              {
                label: typePackaging.label,
                defaultValue: 'Create a new {{label}}',
              },
            ),
            version: {
              isConsumerUnit: false,
              isDespatchUnit: true,
              lifeCycle: lifeCycle.purchasable.id,
              typePackaging,
            },
          });
        }
        let unitOptions = baseOptions[typePackaging.id] || [];

        const hasBaseProductInTree = mainHierarchyUnitId
          ? isBaseProductInTree(hierarchyMap, mainHierarchyUnitId)
          : false;

        if (!hasBaseProductInTree) {
          unitOptions = unitOptions.filter(
            (unit) =>
              unit.gtin === currentVersionInfo.gtin ||
              unit.version.typePackaging.id !== TypePackagingLabels.EACH.id,
          );
        }

        unitOptions = unitOptions.filter((unit) => {
          const matchedUnit = Object.values(dataMap).find(
            (value) => value.gtin === unit.gtin,
          );

          const currentOptionInternalId = matchedUnit?.internalId;

          if (!currentOptionInternalId) {
            return false;
          }

          const currentOptionMainHierarchyUnit = getMainHierarchyUnit(
            currentOptionInternalId,
            dataMap,
            hierarchyMap,
            currentVersionInfo,
          );

          return (
            currentOptionMainHierarchyUnit?.reference ===
            currentVersionInfo.reference
          );
        });

        if (isPatchedHierarchy) {
          unitOptions = unitOptions.filter((opt) => opt.isCurrentVersion);
        }

        enrichedOptions = [
          ...enrichedOptions,
          ...unitOptions.map((unit) => ({
            gtin: unit.gtin,
            productIdentifier: unit.productIdentifier,
            label: `${unit.gtin || unit.productIdentifier} - ${
              unit.version?.displayName ||
              getDisplayName(unit.version, currentLanguage)
            }`,
            version: unit.version,
          })),
        ];
      }
      // Add options for variants if it's a case.
      if (typePackagingId === TypePackagingLabels.CASE.id) {
        enrichedOptions = [...enrichedOptions, ...variantCreationOptions];
      }
    }

    // Allow adding a unit if it is not already somewhere within this branch
    return enrichedOptions.filter((o) => {
      const optionReference = o.gtin || o.productIdentifier;
      return (
        !optionReference || !branchGtins[internalId].includes(optionReference)
      );
    });
  }

  onAddChild = (gtin, productIdentifier, version) => {
    const { actions, internalId, isPatchedHierarchy } = this.props;
    return actions.createUnit(
      internalId,
      gtin,
      productIdentifier,
      version,
      false,
      isPatchedHierarchy,
    );
  };

  renderLabel() {
    const { typePackagingId } = this.props;

    const label = getLogisticalTypePackagingDemonstrative(
      getTypePackagingCode({ id: typePackagingId }),
    );

    return (
      <span className="LogisticalHierarchyCreateUnitDropdown__label">
        {i18n.t('frontproductstream.create_logistical_unit.dropdown.text', {
          label: label,
          defaultValue: 'What is the next level in {{label}}?',
        })}
      </span>
    );
  }

  render() {
    const options = this.getEnrichedOptions();
    if (options.length === 0) {
      return null;
    }
    return (
      <div className="LogisticalHierarchyCreateUnitDropdown">
        <Dropdown
          label={this.renderLabel()}
          options={options.map((option) => ({
            label: option.label,
            onClick: () =>
              this.onAddChild(
                option.gtin,
                option.productIdentifier,
                option.version,
              ),
          }))}
          rightDropdown
        />
      </div>
    );
  }
}

export default connect(
  mapStateToProps,
  mapDispatchToProps,
  separateActions,
)(LogisticalHierarchyCreateUnitDropdown);

import { PureComponent } from 'react';
import { connect } from 'react-redux';

import { closeFormGroup } from 'modules/display-groups/actions';
import i18n from 'utils/i18n';

import CollapseButton from './collapse-button';

interface Props {
  formGroupId: number;
  title: string;
  collapsed: boolean;
  headerIndications: {
    errors: number;
    suggestions: number;
  };
  closeFormGroup: (id: number) => void;
}

const mapDispatchToProps = {
  closeFormGroup,
};

class CollapsibleFormGroupHeader extends PureComponent<Props> {
  private closeCollapsed = () => {
    const { collapsed, formGroupId } = this.props;
    if (!collapsed) {
      this.props.closeFormGroup(formGroupId);
    }
  };

  private renderHeaderIndications() {
    const { headerIndications } = this.props;

    if (!headerIndications) {
      return null;
    }

    const { errors, suggestions } = headerIndications;

    return (
      <>
        {!!suggestions && (
          <span className="FormGroup__headerSuggestions">
            <div className="RaguelWarning__suggestionLabel">
              <i className="mdi mdi-alert RaguelWarning__suggestionIcon" />
              <span className="RaguelWarning__suggestionMessage">
                {suggestions === 1
                  ? i18n.t(
                      'frontproductstream.form_group.field_suggestion.one_text',
                      { defaultValue: '1 suggestion' },
                    )
                  : i18n.t(
                      'frontproductstream.form_group.field_suggestion.many_text',
                      { defaultValue: '{{n}} suggestions', n: suggestions },
                    )}
              </span>
            </div>
          </span>
        )}
        {!!errors && (
          <span className="FormGroup__headerErrors">
            <span className="Raguel__default">
              <i className="mdi mdi-alert" />
              &nbsp;
              {errors === 1
                ? i18n.t('frontproductstream.form_group.field_error.one_text', {
                    defaultValue: '1 field with error',
                  })
                : i18n.t(
                    'frontproductstream.form_group.field_error.many_text',
                    { defaultValue: '{{n}} fields with errors', n: errors },
                  )}
            </span>
          </span>
        )}
      </>
    );
  }

  public render() {
    const { title, collapsed } = this.props;
    return (
      <header className="FormGroup__header" onClick={this.closeCollapsed}>
        <h2>{title}</h2>
        {this.renderHeaderIndications()}
        <span className="FormGroup__headerBuffer" />
        <CollapseButton collapsed={collapsed} />
      </header>
    );
  }
}

export default connect(null, mapDispatchToProps)(CollapsibleFormGroupHeader);

import { useController } from 'react-hook-form';

import InputText from 'components/ui/input/text';
import i18n from 'utils/i18n';

import { AssetInput } from './AssetInput';
import { ReadOnlyAssetInput } from './types';

export const SequenceNumber = ({ isReadOnly }: ReadOnlyAssetInput) => {
  const name = 'sequenceNumber';

  const {
    field: { onChange, value },
  } = useController({ name });

  return (
    <AssetInput
      childId="InputText-ProductPictureForm-sequenceNumber"
      label={i18n.t(
        'frontproducstream.asset_list.modal.sequence_number.label',
        { defaultValue: 'Sequence number' },
      )}
      help={i18n.t('frontproducstream.asset_list.modal.sequence_number.help', {
        defaultValue:
          'The sequence number is a technical number used to generate the gdsn image name. It will be automatically updated if picture metadata has changed. Note that errors can occur when there is no value.',
      })}
    >
      <InputText
        id="ProductPictureForm-sequenceNumber"
        value={value}
        type="number"
        disabled={isReadOnly}
        onChange={onChange}
      />
    </AssetInput>
  );
};

import Immutable from 'immutable';
import { once } from 'lodash/fp';
import { memo, useEffect, useMemo } from 'react';

import TurboSelect from '@alkem/react-ui-turbo-select';

import { recipientFilter } from 'core/modules/list/constants/filters';
import { buildFiltersFromQuery } from 'core/modules/list/utils/filters';
import { FilterOnChange } from 'types';
import { i18n } from 'utils/i18n';
import { sortAsc } from 'utils/sort';
import { asArray, asString } from 'utils/types';

import './index.scss';

type SelectedFilterMap = Immutable.Map<number, any>;

interface Recipient {
  doc_count: number;
  id: number;
  name: string;
}

interface RecipientImmutable extends Immutable.Map<string, any> {
  toJS(): Recipient;
  get<K extends keyof Recipient>(key: K): Recipient[K];
}

type Aggregations = Immutable.Map<string, RecipientImmutable>;

type Recipients = Immutable.List<RecipientImmutable>;

interface Props {
  filterQueryValues: string[];
  onChange: FilterOnChange;
  selectedFilterMap?: SelectedFilterMap;
  aggregations?: Aggregations;
}

export const RecipientFilter = memo(
  ({
    onChange,
    selectedFilterMap,
    filterQueryValues = [],
    aggregations,
  }: Props) => {
    const { key, filterLabel } = recipientFilter;

    const shouldUpdateRecipients = (aggregations?.size as number) > 0;

    const recipients: Recipients = useMemo<Recipients>(
      () => {
        if (aggregations) {
          return aggregations
            .toList()
            .sort((r1, r2) =>
              sortAsc(r1.get('name'), r2.get('name')),
            ) as Recipients;
        }
        return Immutable.List();
      },
      // eslint-disable-next-line react-hooks/exhaustive-deps
      [shouldUpdateRecipients],
    );

    const selectedRecipient: RecipientImmutable | undefined =
      selectedFilterMap?.first();

    const onSelectRecipient = (recipient: RecipientImmutable) => {
      onChange({
        key,
        value: recipient.get('id'),
        label: `${filterLabel}: ${recipient.get('name')}`,
        add: true,
        data: recipient,
        singleValue: true,
      });
    };

    const onUnselectRecipient = (recipient: RecipientImmutable) => {
      onChange({
        key,
        value: recipient.get('id'),
        add: false,
      });
    };

    const updateFilterFromQuery = useMemo(
      () =>
        once(
          async (data: {
            recipients: Recipients;
            filterQueryValues: string[];
            onChange: FilterOnChange;
            key: string;
            filterLabel: string;
          }) => {
            const filtersFromQuery = await buildFiltersFromQuery({
              filterQueryValues: data.filterQueryValues.slice(0, 1),
              filterList: data.recipients,
              filterKey: data.key,
              selectFilterValue: (filter: RecipientImmutable) =>
                filter.get('id'),
              selectFilterLabel: (filter: RecipientImmutable) =>
                `${data.filterLabel}: ${filter.get('name')}`,
              selectFilterData: (filter: RecipientImmutable) => filter,
              singleValue: true,
            });
            data.onChange(filtersFromQuery, true);
          },
        ),
      [],
    );

    useEffect(
      () => {
        if (filterQueryValues?.length > 0 && recipients?.size > 0) {
          updateFilterFromQuery({
            recipients,
            filterQueryValues,
            onChange,
            key,
            filterLabel,
          });
        }
      },
      // eslint-disable-next-line react-hooks/exhaustive-deps
      [recipients?.size > 0],
    );

    return (
      <div className="RecipientFilter">
        <div className="RecipientFilter__header">
          {i18n.t('frontproductstream.core.list_filter_recipient.label', {
            defaultValue: 'Filter by recipient',
          })}
        </div>
        <TurboSelect<RecipientImmutable>
          options={asArray<RecipientImmutable>(recipients)}
          value={selectedRecipient}
          placeholder={i18n.t(
            'frontproductstream.core.list_filter_recipient.autocomplete_placeholder',
            { defaultValue: 'Every recipients' },
          )}
          noOptionsMessage={() =>
            i18n.t(
              'frontproductstream.core.list_filter_recipient.no_option_message',
              { defaultValue: 'No available option' },
            )
          }
          getOptionLabel={(opt: RecipientImmutable) => opt.get('name')}
          getOptionValue={(opt: RecipientImmutable) => asString(opt.get('id'))}
          onChange={(opt) => {
            if (opt) {
              onSelectRecipient(opt as RecipientImmutable);
            } else if (selectedRecipient) {
              onUnselectRecipient(selectedRecipient);
            }
          }}
          isSearchable
        />
      </div>
    );
  },
);

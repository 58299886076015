import { get } from 'lodash';
import memoize from 'memoize-one';

import { ApiClient, getServiceUrl } from '@alkem/sdk-dashboard';

import { getUserLanguage as acceptLanguage } from 'utils/i18n';

const client = memoize(() => {
  const options = {
    cacheEnabled: true,
    acceptLanguage,
    immutable: false,
  };
  return new ApiClient(getServiceUrl('kpi'), options);
});

export const fetchReportReferentials = async () => {
  try {
    const endpoint = '/kpi/v2/reporting/list';
    const response = await client().get(endpoint, '', true);
    return { data: get(response, 'data.data') };
  } catch (error) {
    return { error };
  }
};

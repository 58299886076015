export const MODULE_NAME = 'contribution';

const moduleConst = (name) => `${MODULE_NAME}/${name}`;

// actions
export const FETCH_CONTRIBUTION_STATE_MACHINE = moduleConst(
  'FETCH_CONTRIBUTION_STATE_MACHINE',
);
export const CREATE_CONTRIBUTION = moduleConst('CREATE_CONTRIBUTION');
export const UPDATE_CONTRIBUTION_STATUS = moduleConst(
  'UPDATE_CONTRIBUTION_STATUS',
);
export const SELECT_FIELD = moduleConst('SELECT_FIELD');
export const REMOVE_FIELD = moduleConst('REMOVE_FIELD');
export const ROLLBACK_FIELD = moduleConst('ROLLBACK_FIELD');
export const SUBMIT_PHYSICAL_CHECK = moduleConst('SUBMIT_PHYSICAL_CHECK');
export const SET_REVIEW_FIELD = moduleConst('SET_REVIEW_FIELD');
export const CONFIRM_REVIEW = moduleConst('CONFIRM_REVIEW');
export const SEND_REVIEW = moduleConst('SEND_REVIEW');
export const FETCH_CONTRIBUTION_DIFF = moduleConst('FETCH_CONTRIBUTION_DIFF');

// events
export const SET_STATE_MACHINE = moduleConst('SET_STATE_MACHINE');
export const SET_CONTRIBUTIONS = moduleConst('SET_CONTRIBUTIONS');
export const SET_CONTRIBUTION_DIFF = moduleConst('SET_CONTRIBUTION_DIFF');

// modal
export const CANNOT_CHECK_MODAL_OPEN = moduleConst('CANNOT_CHECK_MODAL_OPEN');
export const CANNOT_CHECK_MODAL_RESET = moduleConst('CANNOT_CHECK_MODAL_RESET');
export const CANNOT_CHECK_MODAL_SUBMIT = moduleConst(
  'CANNOT_CHECK_MODAL_SUBMIT',
);

// modal
export const DISPUTE_MODAL_OPEN = moduleConst('DISPUTE_MODAL_OPEN');
export const DISPUTE_MODAL_RESET = moduleConst('DISPUTE_MODAL_RESET');
export const DIFF_MODAL_OPEN = moduleConst('DIFF_MODAL_OPEN');
export const DIFF_MODAL_RESET = moduleConst('DIFF_MODAL_RESET');
export const DIFF_MODAL_SUBMIT = moduleConst('DIFF_MODAL_SUBMIT');

// Physical Check Field
export const PHYSICAL_CHECK_FIELD = { FAIL: 'FAIL', PASS: 'PASS' };

// TEMPORARY
export const CONTRIBUTION_TYPE_PHYSICAL_CHECKER = 1;
export const PHYSICAL_CHECK_STAGE_GATE_ID = 17;

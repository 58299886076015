import { List, Map } from 'immutable';
import { PureComponent, ReactElement } from 'react';

import { Button } from '@alkem/react-ui-button';

import Pagination from 'core/components/pagination';
import { FilterAggregation, FilterChangeEvent } from 'types';
import { calculateOffset } from 'utils';
import i18n from 'utils/i18n';
import { get, size } from 'utils/immutable';
import { toComparable } from 'utils/string';

import './filter.scss';
import Item from './item';
import SearchInput from './search';

interface ListAdvancedFilterProps {
  filterKey: string;
  filterLabel: string;
  filterType?: string;
  filters: FilterAggregation[];
  selectedFilterMap?: Map<string, any>;
  aggregations?:
    | Map<string, any>
    | null
    | Record<string, { id: string; doc_count: number }>;
  searchQuery?: string;
  searchPlaceholder?: string;
  page?: number;
  itemsPerPage?: number;
  renderItem?: (filter: { id: string | number; name: string }) => ReactElement;
  selectors: {
    selectId: (filter: FilterAggregation) => void;
    selectLabel: (filter: FilterAggregation) => void;
    selectIconClass?: () => void;
    selectChildren?: () => void;
  };
  onFilter: (filterKey: string, query: string) => void;
  onChange: (change: FilterChangeEvent) => void;
  onChangePage?: (filterKey: string, page: number) => void;
  withPagination?: boolean;
  withTree?: boolean;
  withSearchInput?: boolean;
  onSort?: (filterKey: string) => void;
  hasDocCount?: boolean;
  isExactFilter?: boolean;
  hasMissingFilter?: boolean;
  missingFilterKey?: string;
}

export default class ListAdvancedFilter extends PureComponent<ListAdvancedFilterProps> {
  static defaultProps = {
    filters: List(),
    selectedFilterMap: Map(),
    aggregations: Map(),
    searchQuery: '',
    page: 1,
    itemsPerPage: 8,
    withPagination: true,
    withTree: false,
    isExactFilter: false,
    selectors: {
      selectId: (filter) => get(filter, 'id'),
      selectLabel: (filter) => get(filter, 'name'),
    },
    hasMissingFilter: false,
  };

  onSearch = (query) => {
    if ((this.props.page || 0) > 1) {
      this.props.onChangePage?.(this.props.filterKey, 1);
    }
    this.props.onFilter(this.props.filterKey, query);
  };

  onSelect = (filter, selected, path) => {
    const { selectId, selectLabel } = this.props.selectors;
    this.props.onChange({
      key: this.props.filterKey,
      value: selectId(filter),
      type: this.props.filterType,
      label: `${this.props.filterLabel}: ${selectLabel(filter)}`,
      add: selected,
      data: path,
    });
  };

  onChangePage = (newPage) => {
    this.props.onChangePage?.(this.props.filterKey, newPage);
  };

  onSort = () => {
    this.props.onSort?.(this.props.filterKey);
  };

  getAggregation = (filter) => {
    const path =
      this.props.isExactFilter ||
      this.props.filterKey === 'suppliers.internalIDWithName'
        ? [this.props.selectors.selectId(filter)]
        : this.props.selectors.selectId(filter);
    return get(this.props.aggregations, path);
  };

  getKey = (filter) =>
    `list-advanced-filter-${
      this.props.filterKey
    }-${this.props.selectors.selectId(filter)}`;

  getFilters() {
    const {
      searchQuery,
      selectors,
      withPagination,
      page,
      itemsPerPage = ListAdvancedFilter.defaultProps.itemsPerPage,
    } = this.props;
    let { filters } = this.props;

    filters = filters.filter((filter) => {
      if (
        !this.props.hasMissingFilter &&
        filter.get &&
        filter.get('id').toString() === this.props.missingFilterKey
      ) {
        return;
      }

      return filter;
    });

    if (size(filters) === 0) {
      return { filters, total: 0 };
    }
    if (searchQuery) {
      filters = this.filterByLabel(filters, searchQuery, selectors.selectLabel);
    }
    const total = size(filters);
    if (withPagination) {
      const offset = calculateOffset(page as number, itemsPerPage);
      filters = filters.slice(offset, offset + itemsPerPage);
    }
    return {
      filters,
      total,
    };
  }

  isSelected = (filter) => {
    const path =
      this.props.isExactFilter ||
      this.props.filterKey === 'suppliers.internalIDWithName'
        ? [this.props.selectors.selectId(filter)]
        : this.props.selectors.selectId(filter);
    return !!get(this.props.selectedFilterMap, path);
  };

  isIncludedInSelectedPath = (filter) =>
    this.props.withTree &&
    this.props.selectedFilterMap
      ?.valueSeq()
      .some((val) => val.includes(this.props.selectors.selectId(filter)));

  isPartiallySelected = (filter) =>
    this.props.withTree &&
    !this.isSelected(filter) &&
    this.isIncludedInSelectedPath(filter);

  filterByLabel(filters, searchQuery, selectLabel) {
    return filters.filter((filter) => {
      const label = toComparable(selectLabel(filter)) || '';
      const search = toComparable(searchQuery) || '';
      return label.includes(search);
    });
  }

  render() {
    const { filters, total } = this.getFilters();
    const { withSearchInput = true, hasDocCount } = this.props;
    const displayDocCount =
      typeof hasDocCount !== 'undefined'
        ? hasDocCount
        : filters.some(
            (filter) =>
              get(this.props.aggregations, [
                this.props.selectors.selectId(filter),
                'doc_count',
              ]) >= 0,
          );
    return (
      <div className="ListAdvancedFilter">
        {withSearchInput && (
          <SearchInput
            query={this.props.searchQuery}
            placeholder={this.props.searchPlaceholder}
            onSearch={this.onSearch}
          />
        )}
        {displayDocCount && this.props.onSort && (
          <div className="ListAdvancedFilter__sort">
            <Button link onClick={this.onSort}>
              <i className="mdi mdi-sort-descending" />
              {i18n.t(
                'frontproductstream.core.list_filter_advanced.sort_descending',
                { defaultValue: 'Sort descending' },
              )}
            </Button>
          </div>
        )}
        <ul className="ListAdvancedFilter__items">
          {filters.map(
            (filter) =>
              (this.props.renderItem && this.props.renderItem(filter)) || (
                <Item
                  key={this.getKey(filter)}
                  filterKey={this.props.filterKey}
                  filter={filter}
                  aggregation={this.getAggregation(filter)}
                  selected={this.isSelected(filter)}
                  partiallySelected={this.isPartiallySelected(filter)}
                  selectors={this.props.selectors}
                  onSelect={this.onSelect}
                  getKey={this.getKey}
                  getAggregation={this.getAggregation}
                  isSelected={this.isSelected}
                  isPartiallySelected={this.isPartiallySelected}
                  withTree={this.props.withTree}
                  hasDocCount={displayDocCount}
                  refreshTreePath={
                    this.isIncludedInSelectedPath(filter)
                      ? this.props.selectedFilterMap
                      : undefined
                  }
                />
              ),
          )}
        </ul>
        {size(filters) === 0 && (
          <p>
            {i18n.t('frontproductstream.core.list_filter_advanced.no_result', {
              defaultValue: 'No result found',
            })}
          </p>
        )}
        {this.props.withPagination && (
          <Pagination
            page={this.props.page}
            itemsPerPage={this.props.itemsPerPage}
            total={total}
            onChange={this.onChangePage}
          />
        )}
      </div>
    );
  }
}

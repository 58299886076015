import classNames from 'classnames';
import { Map, fromJS } from 'immutable';
import PropTypes from 'prop-types';
import { memo, useCallback, useMemo } from 'react';
import ImmutablePropTypes from 'react-immutable-proptypes';

import {
  TargetProductStatusMap,
  buildTargetProductStatusMap,
} from 'constants/targetProductStatus';
import {
  hasAutoAcceptShareCreate as hasAutoAcceptShareCreateFeature,
  hasAutoAcceptShareUpdate as hasAutoAcceptShareUpdateFeature,
  hasInformationRequest as hasInformationRequestFeature,
} from 'core/api/organization-settings';
import { getOrganizationSettings } from 'core/api/user';
import SimpleFilter from 'core/modules/list/components/filter/simple';
import { targetProductStatusFilter } from 'core/modules/list/constants/filters/target-product-status';
import i18n from 'utils/i18n';

import { useQueryFilter } from '../generic/utils';

import './index.scss';

const mapTargetProductStatusToFilter = ({ id, name, className }) => {
  return {
    id,
    label: name,
    className: classNames([
      'ListTargetProductStatusFilter',
      `ListTargetProductStatusFilter--${className}`,
    ]),
    iconClassName: classNames([
      'ListTargetProductStatusFilterIcon',
      'ProductStatusBar',
      'StatusBar',
      `StatusBar--${className}`,
    ]),
  };
};
function TargetProductStatusFilterComponent({
  aggregations = Map(),
  collapsed = false,
  filterQueryValues = [],
  onChange,
  onCollapse,
  selectedFilterMap = Map(),
  user,
}) {
  const filterKey = targetProductStatusFilter.key;
  const filterLabel = i18n.t(
    'frontproductstream.core.list_filter_target_product_status.label',
    { defaultValue: 'Product status' },
  );
  const statusAggregations = aggregations.get(filterKey);

  const onLocalCollapse = useCallback(
    (isCollapsed) => {
      onCollapse(filterKey, isCollapsed);
    },
    [onCollapse, filterKey],
  );

  const minimalStatuses = useMemo(() => {
    const settings = getOrganizationSettings(user);
    const hasAutoAcceptShareCreate = hasAutoAcceptShareCreateFeature(settings);
    const hasAutoAcceptShareUpdate = hasAutoAcceptShareUpdateFeature(settings);
    const hasInformationRequest = hasInformationRequestFeature(settings);
    return Object.keys(
      buildTargetProductStatusMap({
        hasAutoAcceptShareCreate,
        hasAutoAcceptShareUpdate,
        hasInformationRequest,
      }),
    );
  }, [user]);

  const fullFilterList = useMemo(
    () =>
      fromJS(
        Object.values(TargetProductStatusMap).map(
          mapTargetProductStatusToFilter,
        ),
      ),
    [],
  );

  const filterListFiltered = useMemo(() => {
    const filters = Object.values(TargetProductStatusMap).filter(
      (status) =>
        minimalStatuses.includes(status.id) ||
        (statusAggregations &&
          statusAggregations.getIn([status.id, 'doc_count']) > 0),
    );
    return fromJS(filters.map(mapTargetProductStatusToFilter));
  }, [statusAggregations, minimalStatuses]);

  useQueryFilter({
    options: filterListFiltered,
    filter: {
      key: filterKey,
      filterList: fullFilterList,
      selectFilterValue: (filter) => filter.get('id'),
      selectFilterLabel: (filter) => `${filterLabel}: ${filter.get('label')}`,
      selectFilterData: (filter) => filter,
    },
    filterQueryValues,
    selectedFilterMap,
    onChange,
  });

  return (
    <SimpleFilter
      id="list-filter-targetProductStatus"
      filterList={filterListFiltered}
      filterKey={filterKey}
      filterLabel={filterLabel}
      selectedFilterMap={selectedFilterMap}
      aggregations={statusAggregations}
      collapsed={collapsed}
      onChange={onChange}
      onCollapse={onLocalCollapse}
    />
  );
}

TargetProductStatusFilterComponent.propTypes = {
  user: ImmutablePropTypes.map.isRequired,
  selectedFilterMap: ImmutablePropTypes.map,
  aggregations: ImmutablePropTypes.map,
  collapsed: PropTypes.bool,
  filterQueryValues: PropTypes.array,
  onChange: PropTypes.func.isRequired,
  onCollapse: PropTypes.func.isRequired,
};

export const TargetProductStatusFilter = memo(
  TargetProductStatusFilterComponent,
);

import { Map, Set, fromJS } from 'immutable';
import { createReducer } from 'redux-create-reducer';

import { ENTITY_TYPE_PRODUCTVERSION_OVERRIDE } from 'constants/entities';
import { UPDATE_ENTITY } from 'constants/events/entity';
import { DELETE_PRODUCT_VERSION } from 'constants/events/productversion';
import { parseIfNumber } from 'utils';

import { MEDIA_KEY } from './constants';
import {
  ADD_OVERRIDE,
  ADD_RECIPIENT,
  DELETE_OVERRIDE,
  RECEIVE_OVERRIDABLE_FIELDS,
  RECEIVE_OVERRIDE_DATA,
  RECEIVE_SPECIFIC_FIELDS,
  STATUS_TOGGLED,
  TOGGLE_STATUS,
} from './events';

export const initialState = fromJS({
  overridableFieldsByRecipient: {},
  dataOverrideByRecipient: {},
  fieldsByRecipient: {},
  isDirty: false,
  addedRecipients: [],
  toggleAssetStatusInProgress: {},
  // This is used to track which targetOrganizationId has been its specific data updated
  recipientIdUpdated: Set(),
});

export default createReducer(initialState, {
  [RECEIVE_OVERRIDABLE_FIELDS]: (state, { fieldsPerRecipients }) =>
    state.set('overridableFieldsByRecipient', fieldsPerRecipients),

  [RECEIVE_OVERRIDE_DATA]: (state, { data }) =>
    state.withMutations((s) => {
      s.set(
        'dataOverrideByRecipient',
        fromJS(data).reduce(
          (acc, datum) =>
            acc.set(
              datum.getIn(['targetOrganization', 'id']),
              Map({ data: datum.get('data') }),
            ),
          Map(),
        ),
      );
      s.set('isDirty', false);
    }),

  [DELETE_PRODUCT_VERSION]: () => initialState,

  [ADD_OVERRIDE]: (state, { fieldName, recipientId }) =>
    state.withMutations((s) => {
      s.setIn(
        ['dataOverrideByRecipient', recipientId, 'data', fieldName],
        null,
      );
      s.update('recipientIdUpdated', (list) => list.add(recipientId));
      if (fieldName !== MEDIA_KEY) {
        s.set('isDirty', true);
      }
    }),

  [DELETE_OVERRIDE]: (state, { fieldName, recipientId }) =>
    state.withMutations((s) => {
      s.deleteIn(['dataOverrideByRecipient', recipientId, 'data', fieldName]);
      s.update('recipientIdUpdated', (list) => list.add(recipientId));
      if (fieldName !== MEDIA_KEY) {
        s.set('isDirty', true);
      }
    }),

  [UPDATE_ENTITY]: (
    state,
    { entityKind, entityId: recipientId, key, value, isDirty },
  ) => {
    if (entityKind !== ENTITY_TYPE_PRODUCTVERSION_OVERRIDE) {
      return state;
    }

    const path = key.split('.').map(parseIfNumber);

    const recipientOverride = (
      state.getIn(['dataOverrideByRecipient', recipientId]) ||
      fromJS({ data: {} })
    ).withMutations((override) => {
      // Update the data
      override.update('data', (data) => data.setIn(path, fromJS(value)));
    });

    return state.withMutations((s) => {
      s.setIn(['dataOverrideByRecipient', recipientId], recipientOverride);
      if (isDirty) {
        s.update('recipientIdUpdated', (list) => list.add(recipientId));
      }
      s.set('isDirty', s.get('isDirty') || isDirty);
    });
  },

  [RECEIVE_SPECIFIC_FIELDS]: (state, { fieldsByRecipient }) =>
    state.set(
      'fieldsByRecipient',
      fromJS(fieldsByRecipient).reduce(
        (acc, v, k) => acc.set(parseIfNumber(k), v),
        Map(),
      ),
    ),

  [ADD_RECIPIENT]: (state, { recipient }) =>
    state.update('addedRecipients', (addedRecipients) =>
      addedRecipients.push(recipient.get('id')),
    ),

  [TOGGLE_STATUS]: (state, { payload }) =>
    state.updateIn(
      ['toggleAssetStatusInProgress', payload.recipientId, payload.category],
      (assets) => (assets || Set()).add(payload.assetId),
    ),

  [STATUS_TOGGLED]: (state, { payload }) =>
    state.updateIn(
      ['toggleAssetStatusInProgress', payload.recipientId, payload.category],
      (assets) => (assets || Set()).delete(payload.assetId),
    ),
});

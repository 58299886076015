import { ROOM_TYPE_PRODUCTVERSION } from 'constants/room';
import {
  getSharedOrganizationId,
  getSharedProductVersionId,
} from 'core/api/retailerproductversion';
import { sendQuickMessage } from 'modules/chat';
import { selectSourceProductVersion } from 'reducers/productVersion';
import { createAction } from 'utils/redux';

import {
  ACCEPT_CONSUMER_UNIT,
  ACCEPT_LISTING_INIT,
  ACCEPT_LISTING_UPDATES,
  REFUSE_CONSUMER_UNIT,
  REFUSE_LISTING_UPDATES,
} from './constants';

export const acceptConsumerUnit = createAction(ACCEPT_CONSUMER_UNIT);
export const refuseConsumerUnit = createAction(REFUSE_CONSUMER_UNIT);
export const acceptListingUpdates = createAction(ACCEPT_LISTING_UPDATES);
export const refuseListingUpdates = createAction(REFUSE_LISTING_UPDATES);
export const acceptListingInit = createAction(ACCEPT_LISTING_INIT);

export const sendMessage = (message) => async (dispatch, getState) => {
  const productVersion = selectSourceProductVersion(getState());
  const sharedProductVersionId = getSharedProductVersionId(productVersion);
  const manufacturerId = getSharedOrganizationId(productVersion);
  try {
    await dispatch(
      sendQuickMessage(
        message,
        manufacturerId,
        sharedProductVersionId,
        ROOM_TYPE_PRODUCTVERSION,
      ),
    );
  } catch (error) {
    console.warn('sending message failed', error); // eslint-disable-line
  }
};

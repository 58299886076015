import { List as ImmutableList } from 'immutable';
import { call, put } from 'redux-saga/effects';

import { ResponseWithBody } from '@alkem/sdk-dashboard';

import { notificationError, notificationSuccess } from 'actions/notification';
import { buildSearch } from 'modules/catalog/shared/utils/list';
import validationApi from 'resources/validationApi';
import { ActionWithPayload } from 'types';
import { saveAs } from 'utils';
import { i18n } from 'utils/i18n';
import { track } from 'utils/tracking';

import { DownloadComplianceReportActionPayload } from '../actions';

import { prepareParameters } from './list';

function* buildAPIParams() {
  const {
    search,
    advancedSearch: { advancedSearch },
  } = yield call(prepareParameters, {
    // we are not exporting product here, no need to select fields, sheet columns are decided serverside
    referentials: ImmutableList(),
  });

  return {
    ...buildSearch(search),
    advanced_search: advancedSearch,
    format: 'xlsx',
  };
}

export function* complianceReportSaga(
  action: ActionWithPayload<DownloadComplianceReportActionPayload>,
) {
  const { payload = { productKeyIds: [] } } = action;
  const { productKeyIds } = payload;

  try {
    let apiParams;
    if (productKeyIds.length) {
      apiParams = {
        type: 'by_pkids',
        payload: { format: 'xlsx', product_key_ids: productKeyIds },
      };
    } else {
      apiParams = { type: 'by_filters', payload: yield call(buildAPIParams) };
    }

    const { data }: ResponseWithBody<Blob> = yield call(
      [validationApi, 'getComplianceReport'],
      apiParams,
    );

    yield call(saveAs, data, 'error_report.xlsx');

    yield put(
      notificationSuccess(i18n.t('The compliance report has been downloaded')),
    );

    track({
      category: 'product',
      action: 'product_errorreport_downloaded',
    });
  } catch (err: any) {
    let errorMessage;
    if (err?.status === 400) {
      const errorSpec = err.data?.data?.toJS();
      if (errorSpec) {
        const { error, failing_selected_products, product_limit } = errorSpec;
        if (error === 'PRODUCT_LIMIT_EXCEEDED') {
          errorMessage = i18n.t(
            'Amongst the selected products, {{failing_selected_products}} products contain error(s). We can only process {{product_limit}} products with errors, please use additional filters to narrow the selection of products.',
            {
              failing_selected_products,
              product_limit,
            },
          );
        }
      }
    }

    if (!errorMessage) {
      errorMessage = err.human_message
        ? err.human_message
        : i18n.t('An error occured while generating compliance report');
    }

    yield put(notificationError(errorMessage, { sticky: true }));
  }
}

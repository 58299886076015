import { List } from 'immutable';
import { useMemo } from 'react';
import processString from 'react-process-string';

import Ellitips from '@alkem/react-ui-ellitips';
import { LazyTooltip } from '@alkem/react-ui-tooltip';

import i18n from 'utils/i18n';
import { get, size } from 'utils/immutable';

import './index.scss';

/* 
provides a preview of the first key content of a list
ordered by id asc, will allow to see the rest of the keys using a tooltip
ex: [{"id":1, "name":"name1"},{"id":2, "name":"name2"},{"id":3, "name":"name3"}]
with key="name", the cell with have the following content: `name1 and 2 others` 
*/
export default function KeyListPreviewCell({
  values,
  keyPath,
  productVersionId,
}: {
  values: List<any>;
  keyPath: string;
  productVersionId: number;
}) {
  const sortedById = useMemo(
    () => values.sort((a, b) => a.id - b.id),
    [values],
  );
  if (size(values) === 0) {
    return null;
  }
  const firstElement = get(sortedById, [0, keyPath]);
  const otherElements = sortedById.slice(1);
  const count = otherElements ? size(otherElements) : 0;

  return (
    <div className="KeyListPreviewCell ListTextCell">
      <div
        className="KeyListPreviewElement"
        data-testid={`KeyListPreviewCell-${productVersionId}`}
      >
        <Ellitips id={`ellitips-${productVersionId}`}>{firstElement}</Ellitips>
      </div>
      {count > 0 &&
        processString([
          {
            regex: /__COUNT__/,
            fn: (key) => (
              <span key={`list-span-${productVersionId}`}>
                <LazyTooltip
                  key={key}
                  id={`list-tooltip-${productVersionId}`}
                  tooltipLabel={otherElements
                    .map((element) => get(element, keyPath))
                    .join('<br />')}
                  html
                >
                  <u>{count}</u>
                </LazyTooltip>
              </span>
            ),
          },
        ])(
          i18n.t('frontproductstream.catalog_cell.list_preview.text', {
            defaultValue: 'and {{formattedCount}} others',
            count,
            formattedCount: '__COUNT__',
          }),
        )}
    </div>
  );
}

import { takeLatest } from 'redux-saga/effects';

import {
  DETACH_USER_LABEL,
  FETCH_ALL_USER_LABELS,
  FETCH_LINKED_USERS_LABELS,
  SAVE_USER_LABELS,
  TRACK_USER_LABELS,
} from '../actions/types';

import detachUserLabels from './detach';
import { fetchAll, fetchLinked } from './fetch';
import saveUserLabels from './save';
import track from './tracking';

export default function* userLabelMainSaga() {
  yield takeLatest(SAVE_USER_LABELS, saveUserLabels);
  yield takeLatest(DETACH_USER_LABEL, detachUserLabels);
  yield takeLatest(FETCH_ALL_USER_LABELS, fetchAll);
  yield takeLatest(TRACK_USER_LABELS, track);
  yield takeLatest(FETCH_LINKED_USERS_LABELS, fetchLinked);
}

import { useCallback, useMemo } from 'react';

import i18n from 'utils/i18n';

import { ENTITY_TYPE_LOGISTICAL_HIERARCHY_WEIGHTS } from '../../constants';
import { BulkUpdate } from '../../structures';

import {
  LogisticalHierarchyGroupedFields,
  LogisticalHierarchyGroupedFieldsProps,
} from './grouped-fields';
import { DeepUnit, getConvertedValue, sum } from './utils';

export function LogisticalHierarchyWeights(
  props: LogisticalHierarchyGroupedFieldsProps,
) {
  const valuePaths = useMemo(() => [['grossWeight']], []);

  const columnNames = useMemo(
    () => [
      i18n.t(
        'frontproductstream.logistical_hierarchies_weights_table.gross_weight_column.text',
        { defaultValue: 'Gross weight' },
      ),
      i18n.t(
        'frontproductstream.logistical_hierarchies_weights_table.summed_gross_weight_column.text',
        { defaultValue: 'Summed weight of contained children' },
      ),
    ],
    [],
  );

  const getSeedDataUpdate = useCallback((unit: DeepUnit): BulkUpdate | null => {
    const { id, versionData } = unit;
    if (versionData?.grossWeight?.length > 0) {
      return null;
    }
    return {
      internalId: id,
      path: ['grossWeight'],
      value: [{ data: null, expressedIn: null }],
    };
  }, []);

  const getBaseComputedValue = useCallback(
    (versionData: object, paths: (string | number)[][]): number =>
      sum(...paths.map((p) => getConvertedValue(versionData, p))),
    [],
  );

  const getBestValue = useCallback(
    (
      baseValue: number,
    ): {
      value: number;
      unitCode: any;
    } => {
      let value = baseValue;
      let unitCode = 'g';
      if (value >= 1000) {
        value /= 1000;
        unitCode = 'kg';
      }
      return {
        value,
        unitCode,
      };
    },
    [],
  );

  return (
    <LogisticalHierarchyGroupedFields
      {...props}
      label={i18n.t(
        'frontproductstream.logistical_hierarchies_weights_table.weights.title',
        { defaultValue: 'Weights' },
      )}
      valuePaths={valuePaths}
      columnNames={columnNames}
      unitLabel={i18n.t(
        'frontproductstream.logistical_hierarchies_weights_table.gross_weight_unit.text',
        { defaultValue: 'Gross weigth unit of logistical units' },
      )}
      unitReferentialUri="/core/v3/referentials/weights"
      getSeedDataUpdate={getSeedDataUpdate}
      getBaseComputedValue={getBaseComputedValue}
      errorEntityKind={ENTITY_TYPE_LOGISTICAL_HIERARCHY_WEIGHTS}
      errorModel="grossWeight"
      getBestValue={getBestValue}
      warningMessage={i18n.t(
        'frontproductstream.logistical_hierarchies_weights_table.weight_warning.text',
        {
          defaultValue:
            'The weight of this unit is inferior to the summed weight of the contained children.',
        },
      )}
      model="grossWeight"
    />
  );
}

import classNames from 'classnames';
import { Map } from 'immutable';
import { useMemo } from 'react';
import { v4 as uuid } from 'uuid';

import HelpTooltip from '@alkem/react-ui-helptooltip';

import i18n from 'utils/i18n';
import { get } from 'utils/immutable';

import './index.scss';

const caseFormatMap = {
  SOLID_CASE: i18n.t('solid'),
  WATER_CASE: i18n.t('water'),
  BEVERAGE_CASE: i18n.t('beverage'),
  CHEESE_CASE: i18n.t('cheese'),
  FAT_CASE: i18n.t('fat'),
  UNKOWN_CASE: i18n.t('unknown'),
  EXCLUDED: i18n.t('excluded'),
};

const valueFormat = (data, path, unit = 'g') => {
  // if data.path = 0 we want 0, if data.path = null we want '-'
  const value = get(data, path);
  if (value == null) {
    return '-';
  }
  return `${value} ${unit}`;
};

const formatMetaData = (metadata) => {
  if (!metadata) {
    return null;
  }
  const nutriCase = caseFormatMap[get(metadata, 'case')];
  const extractedIngredients = get(metadata, 'extracted_ingredients');

  const text = i18n.t(
    'Score was computed based on following values for {{nutriscoreCase}} case.',
    { nutriscoreCase: i18n.t(nutriCase) },
  );

  return (
    <div>
      <table className="NutriScoreCell__tooltipTable">
        <thead>
          <tr>
            <th>{i18n.t('Ingredient')}</th>
            <th>{i18n.t('Value per 100g')}</th>
          </tr>
        </thead>
        <tbody>
          <tr>
            <td>{i18n.t('Energy')}</td>
            <td>{valueFormat(extractedIngredients, 'energy_kj', 'kj')}</td>
          </tr>
          <tr>
            <td>{i18n.t('Fat')}</td>
            <td>{valueFormat(extractedIngredients, 'all_fat')}</td>
          </tr>
          <tr>
            <td>{i18n.t('Saturated Fat')}</td>
            <td>{valueFormat(extractedIngredients, 'saturated_fat')}</td>
          </tr>
          <tr>
            <td>{i18n.t('Sugar')}</td>
            <td>{valueFormat(extractedIngredients, 'sugar')}</td>
          </tr>
          <tr>
            <td>{i18n.t('Salt')}</td>
            <td>{valueFormat(extractedIngredients, 'salt')}</td>
          </tr>
          <tr>
            <td>{i18n.t('Sodium')}</td>
            <td>{valueFormat(extractedIngredients, 'sodium')}</td>
          </tr>
          <tr>
            <td>{i18n.t('Fruit')}</td>
            <td>{valueFormat(extractedIngredients, 'fruit')}</td>
          </tr>
          <tr>
            <td>{i18n.t('Fiber')}</td>
            <td>{valueFormat(extractedIngredients, 'fiber')}</td>
          </tr>
          <tr>
            <td>{i18n.t('Protein')}</td>
            <td>{valueFormat(extractedIngredients, 'protein')}</td>
          </tr>
        </tbody>
      </table>
      <p>
        <i>{text}</i>
      </p>
    </div>
  );
};

const formatToolTip = (
  displaySuggestionOnly,
  metadata,
  suggested,
  suggestExempted,
) => {
  if (suggestExempted) {
    return (
      <p>
        {i18n.t(
          'Based on product category, this product seems to be exempt from nutriscore.',
        )}
      </p>
    );
  }
  const intro = displaySuggestionOnly
    ? i18n.t('Based on filled data the nutriscore is {{nutriscore}}.', {
        nutriscore: get(suggested, 'label'),
      })
    : i18n.t(
        'The current value seems incorrect, based on filled data we found a {{nutriscore}} score.',
        { nutriscore: get(suggested, 'label') },
      );
  const formattedMeta = formatMetaData(metadata);
  if (!formatMetaData) {
    return <p>{intro}</p>;
  }
  return (
    <div>
      <p>{intro}</p>
      {formattedMeta}
    </div>
  );
};

export default function NutriScoreCell({ data }: { data: Map<string, any> }) {
  const id = useMemo(() => uuid(), []);
  if (!data) {
    return null;
  }
  const difference = get(data, 'difference');
  const metadata = get(data, 'metadata');

  const current = get(data, 'currentValue');
  const suggested = get(data, 'suggestedValue');

  const displayed = current || suggested;
  if (!displayed) {
    return null;
  }
  if (
    ![
      'nutriScore_A',
      'nutriScore_B',
      'nutriScore_C',
      'nutriScore_D',
      'nutriScore_E',
      'NUTRISCORE_EXEMPT',
    ].includes(get(displayed, 'code'))
  ) {
    return null;
  }
  const displayedLabel = get(displayed, 'label');

  const displayedIsExempted = get(displayed, 'code') === 'NUTRISCORE_EXEMPT';
  const suggestedIsExempted = get(suggested, 'code') === 'NUTRISCORE_EXEMPT';

  const displayCurrentWithDifference = !!current && difference;
  const displaySuggestionOnly = !current && !!suggested;
  const suggestedOrCurrent = displaySuggestionOnly ? 'suggested' : 'current';

  const classes = {
    NutriScoreCell__letter: !displayedIsExempted,
    [`NutriScoreCell__${suggestedOrCurrent}`]: !displayedIsExempted,
    [`NutriScoreCell__${displayedLabel}__${suggestedOrCurrent}`]:
      !displayedIsExempted,
    NutriScoreCell__suggestedDifference: displayCurrentWithDifference,
    NutriScoreCell__suggestedOnly: displaySuggestionOnly,
  };

  const toolTipMessage = formatToolTip(
    displaySuggestionOnly,
    metadata,
    suggested,
    (displayCurrentWithDifference || displaySuggestionOnly) &&
      suggestedIsExempted,
  );

  return (
    <div className="NutriScoreCell">
      <div className={classNames(classes)}>
        {displayedIsExempted ? i18n.t('Exempt') : displayedLabel}
      </div>
      <div>
        {displayCurrentWithDifference && (
          <div className="NutriScoreCell__tooltipWarning">
            <HelpTooltip
              message={toolTipMessage}
              icon="alert-circle-outline"
              id={id}
              place="right"
            />
          </div>
        )}
        {displaySuggestionOnly && (
          <div className="NutriScoreCell__tooltipWarning">
            <HelpTooltip
              message={toolTipMessage}
              icon="information-outline"
              id={id}
              place="right"
            />
          </div>
        )}
      </div>
    </div>
  );
}

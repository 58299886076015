import { createAction } from 'utils/redux';

import * as events from './events';

export const show = createAction(events.SHOW);

export const bulkSetAsPublic = createAction(events.START);

export const updateProgress = createAction(events.UPDATE_PROGRESS);

const cancel = createAction(events.CANCEL);

export const done = createAction(events.DONE);

export const reset = () => (dispatch) => {
  dispatch(cancel());
  dispatch({ type: events.RESET });
};

import { isString } from 'lodash';

import { getUserLanguage } from 'utils/i18n';

export function truncPolyfill(x) {
  const n = x - (x % 1);
  return n === 0 && (x < 0 || (x === 0 && 1 / x !== 1 / 0)) ? -0 : n;
}

export function trunc(num) {
  return Math.trunc ? Math.trunc(num) : truncPolyfill(num);
}

export function toPercentage(
  num,
  total,
  { withSuffix = false, suffix = '%' } = {},
) {
  const p = trunc((num * 100) / total);
  if (withSuffix) {
    return `${p}${suffix}`;
  }
  return p;
}

/**
 * @param {string | number} value
 * @param {object} options
 * @param {number=} options.digits
 * @param {number=} options.digitsMin
 * @param {number=} options.digitsMax
 * @param {string=} options.currency
 * @returns {string}
 */
export const formatNumber = (
  value,
  { digits, digitsMin, digitsMax, currency } = {},
) => {
  try {
    const num = typeof value === 'string' ? parseFloat(value) : value;
    const options = { style: 'decimal' };
    if (Number.isInteger(digitsMin)) {
      options.minimumFractionDigits = digitsMin;
    } else if (Number.isInteger(digits)) {
      options.minimumFractionDigits = digits;
    }
    if (Number.isInteger(digitsMax)) {
      options.maximumFractionDigits = digitsMax;
    } else if (Number.isInteger(digits)) {
      options.maximumFractionDigits = digits;
    }
    if (isString(currency) && currency.length === 3) {
      Object.assign(options, {
        style: 'currency',
        currencyDisplay: 'symbol',
        currency: currency.toUpperCase(),
      });
    }
    return num.toLocaleString(getUserLanguage(), options);
  } catch (err) {
    return `${value}`;
  }
};

import { get } from 'lodash';
import { useDispatch, useSelector } from 'react-redux';

import { SwitchButton } from '@alkem/react-ui-button';

import {
  addTagToProductVersion,
  removeTagFromProductVersion,
} from 'actions/productversion';
import LongestWidthLabel from 'components/ui/longest-width-label';
import prefs from 'constants/prefs';
import { TAG_DATA_VALIDATED } from 'constants/tags';
import {
  selectCanValidateProduct,
  selectIsTaggedBy,
  selectProductKeyId,
  selectProductVersionId,
} from 'reducers/productVersion';
import i18n from 'utils/i18n';

const changeDataValidation = (
  dispatch,
  productKeyId,
  checked,
  _addTagToProductVersion,
  _removeTagFromProductVersion,
) => {
  checked
    ? dispatch(_addTagToProductVersion(productKeyId, TAG_DATA_VALIDATED))
    : dispatch(_removeTagFromProductVersion(productKeyId, TAG_DATA_VALIDATED));
};

const ValidateData = () => {
  const dispatch = useDispatch();
  const canValidate: boolean = useSelector(selectCanValidateProduct);
  const productKeyId: number = useSelector(selectProductKeyId);
  const productVersionId: number = useSelector(selectProductVersionId);
  const tags: any = useSelector(selectIsTaggedBy);

  if (
    !get(prefs, 'features.maker.product.dataValidation') ||
    !canValidate ||
    !productVersionId
  ) {
    return null;
  }

  const checked = (tags as any).includes(TAG_DATA_VALIDATED);
  const labels = [i18n.t('Validated'), i18n.t('To valid')];
  const content = <LongestWidthLabel labels={labels} index={checked ? 0 : 1} />;

  return (
    <SwitchButton
      content={content}
      checked={checked}
      onChange={() =>
        changeDataValidation(
          dispatch,
          productKeyId,
          checked,
          addTagToProductVersion,
          removeTagFromProductVersion,
        )
      }
    />
  );
};

export default ValidateData;

import { all, call, put, putResolve, select } from 'redux-saga/effects';

import { notificationError } from 'actions/notification';
import { fetchProductVersionByKeyId } from 'actions/productversion';
import { selectProductKeyId } from 'reducers/productVersion';
import serviceProductApi from 'resources/serviceProductApi';
import { requestWithHeaders } from 'utils/api';
import i18n from 'utils/i18n';

import { SAVE_VISIBILITY_ERROR, SAVE_VISIBILITY_SUCCESS } from '../constants';

export function* saveVisibility({ exclusive, allCatalog }) {
  const productKeyId = yield select(selectProductKeyId);

  // This first one is needed so it is immediate for the current
  // product
  const calls = [
    call(
      requestWithHeaders,
      serviceProductApi,
      'post',
      '/product/v2/products/visibility',
      {
        product_key_ids: [productKeyId],
        exclusive,
      },
    ),
  ];
  if (allCatalog) {
    calls.push(
      call(
        requestWithHeaders,
        serviceProductApi,
        'post',
        '/product/v2/products/visibility/all',
        {
          exclusive,
        },
      ),
    );
  }

  const { error } = yield all(calls);

  if (error) {
    yield put({ type: SAVE_VISIBILITY_ERROR });
    yield put(
      notificationError(
        i18n.t('An error occured while setting the visibility'),
      ),
    );
    return;
  }

  yield putResolve(fetchProductVersionByKeyId(productKeyId));

  yield put({ type: SAVE_VISIBILITY_SUCCESS });
}

export const OPEN_MODAL = 'promote-products-modal/open-modal';
export const CLOSE_MODAL = 'promote-products-modal/close-modal';
export const INIT = 'promote-products-modal/init';
export const SET_LOADING = 'promote-products-modal/set-loading';
export const SEND = 'promote-products-modal/send';
export const UPDATE_MESSAGE = 'promote-products-modal/update-message';
export const UPDATE_ALL_PRODUCTS = 'promote-products-modal/update-all-products';
export const UPDATE_SOURCINGLABEL =
  'promote-products-modal/update-sourcinglabel';
export const ADD_EMAIL = 'promote-products-modal/add-email';
export const REMOVE_EMAIL = 'promote-products-modal/remove-email';

import { useFormState } from 'react-hook-form';
import { useDispatch } from 'react-redux';

import { notificationError } from 'actions/notification';
import i18n from 'utils/i18n';

export const useValidatePackshotUpdate = () => {
  const dispatch = useDispatch();

  const formState = useFormState();

  const triggerVerification = () => {
    if (formState.defaultValues?.isPackshot) {
      dispatch(
        notificationError(
          i18n.t(
            'frontproducstream.asset_list.picture_modal.update_packshot_notification.error',
            {
              defaultValue:
                'This picture is the current packshot: choose another picture to change it.',
            },
          ),
          {
            context: 'modal',
          },
        ),
      );

      return false;
    }

    return true;
  };

  return triggerVerification;
};

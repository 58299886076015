import { useSelector } from 'react-redux';

import { usePictureForm } from 'components/ui/form/asset-list/AssetV2/hooks/usePictureForm';
import { useValidatePackshotUpdate } from 'components/ui/form/asset-list/AssetV2/hooks/useValidatePackshotUpdate';
import AssetContextualFormGroup from 'components/ui/form/asset-list/actions/form-group';
import { ENTITY_TYPE_PRODUCT_PICTURE } from 'constants/entities';
import {
  HIDDEN_PICTURE_FIELDS,
  MIGRATED_FIELDS,
} from 'constants/productPicture';
import { selectHasJsonStoreInputFeature } from 'modules/feature-flag/selectors';

import { ReadOnlyAssetInput } from '../AssetInputs/types';

const UNUSED_ENTITY_INDEX = -1;

type AssetContextualPictureFormGroupWrapperProps = {
  formMethods: ReturnType<typeof usePictureForm>['formMethods'];
} & ReadOnlyAssetInput;

export const AssetContextualPictureFormGroupWrapper = ({
  formMethods,
  isReadOnly,
}: AssetContextualPictureFormGroupWrapperProps) => {
  const hasJsonStoreInputFeature = useSelector(selectHasJsonStoreInputFeature);

  const canChangePackshot = useValidatePackshotUpdate();

  const handleChange = (name, value) => {
    if (name === 'isPackshot' && !canChangePackshot()) {
      return;
    }

    formMethods.setValue(name, value, { shouldDirty: true });
  };

  const allFormInputs = formMethods.watch();

  const ignoredFieldNames =
    isReadOnly && hasJsonStoreInputFeature
      ? HIDDEN_PICTURE_FIELDS
      : [...MIGRATED_FIELDS, ...HIDDEN_PICTURE_FIELDS];

  return (
    <AssetContextualFormGroup
      entity={allFormInputs}
      controller="picture"
      onChangeField={handleChange}
      entityIndex={UNUSED_ENTITY_INDEX}
      ignoreFieldNames={ignoredFieldNames}
      entityKind={ENTITY_TYPE_PRODUCT_PICTURE}
      noDispatch={false}
    />
  );
};

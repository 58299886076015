import PropTypes from 'prop-types';

import { LifeCycleMap } from 'constants/filters/lifeCycle';
import i18n from 'utils/i18n';

import './index.scss';

export default function LifecycleColumn({ data }) {
  if (data == null) {
    return <div className="lifecycleNA">{i18n.t('Not available')}</div>;
  }

  const lifecycleValue = Object.values(LifeCycleMap)[data];

  return (
    <div>
      <i className={`${lifecycleValue.icon} lifecycleIcon`} />
      {lifecycleValue.name}
    </div>
  );
}

LifecycleColumn.propTypes = {
  data: PropTypes.number,
};

import { createSelector } from '@reduxjs/toolkit';
import { get } from 'lodash';

import { MODULE_REDUCER_KEY } from 'constants/reducer';

import { REDUCER_KEY as REPORTING_REDUCER_KEY } from '../../constants';
import { REDUCER_KEY as WRAPPER_REDUCER_KEY } from '../constants';

const selectModuleState = (state) =>
  get(state, [MODULE_REDUCER_KEY, REPORTING_REDUCER_KEY, WRAPPER_REDUCER_KEY]);

export const selectReportList = createSelector(
  selectModuleState,
  (moduleState) => moduleState.get('reportList'),
);

export const selectIsReportListLoaded = createSelector(
  selectModuleState,
  (moduleState) => moduleState.get('isReportListLoaded'),
);

import { hasSourcingLabelShare } from 'core/api/organization-settings';
import { selectOrganizationSettings } from 'modules/user';
import i18n from 'utils/i18n';

import {
  selectAllProducts,
  selectEmails,
  selectSourcingLabel,
} from '../selectors';

export function canShareSourcingLabel(state) {
  return hasSourcingLabelShare(selectOrganizationSettings(state));
}

export function getModalName(state) {
  if (canShareSourcingLabel(state)) {
    return i18n.t(
      'frontproductstream.promote_products_modal.promote_my_products.text',
      {
        defaultValue: 'Promote my products',
      },
    );
  } else {
    return i18n.t(
      'frontproductstream.promote_products_modal.invite_discover_products.text',
      {
        defaultValue: 'Invite a retailer to discover my products',
      },
    );
  }
}

export function isFormValid(state) {
  return (
    !!selectEmails(state).size &&
    (selectAllProducts(state) || !!selectSourcingLabel(state))
  );
}

import { fromJS } from 'immutable';

import userLabelApiV4 from 'resources/userLabelApiV4';
import { size } from 'utils/immutable';

export const createUserLabelV4 = async ({
  organizationId,
  userLabels,
}: {
  organizationId: number;
  userLabels: string[];
}) => {
  try {
    const res = await userLabelApiV4.create({ organizationId, userLabels });
    return { labels: fromJS(res.data.data) };
  } catch (error) {
    return { error };
  }
};

export const listUserLabelV4 = async ({
  productKeyId,
  organizationId,
}: {
  productKeyId?: number;
  organizationId?: number;
}) => {
  try {
    const res = await userLabelApiV4.list({ productKeyId, organizationId });
    return { labels: fromJS(res.data) };
  } catch (error) {
    return { error };
  }
};

export const listAvailableUserLabelV4 = async ({
  productKeyIds,
  organizationIds,
}: {
  productKeyIds?: number[];
  organizationIds?: number[];
}) => {
  try {
    const res = await userLabelApiV4.list_available({
      product_key_ids: productKeyIds,
      organization_ids: organizationIds,
    });
    return { data: res.data };
  } catch (error) {
    return { error };
  }
};

export const updateUserLabelV4 = async ({
  labels,
}: {
  labels: { name: string; id: number }[];
}) => {
  try {
    return await userLabelApiV4.update({ labels });
  } catch (error) {
    return { error };
  }
};

export const deleteUserLabelV4 = async ({
  labelsIds,
}: {
  labelsIds: number[];
}) => {
  try {
    return await userLabelApiV4.remove({ labelsIds });
  } catch (error) {
    return { error };
  }
};

export const attachUserLabelV4 = async ({
  links,
}: {
  links: { product_key_id: number; label_id: number }[];
}) => {
  try {
    if (!size(links)) {
      return {};
    }
    return await userLabelApiV4.attach({ links });
  } catch (error) {
    return { error };
  }
};

export const detachUserLabelV4 = async ({
  links,
}: {
  links: { product_key_id: number; label_id: number }[];
}) => {
  try {
    if (!size(links)) {
      return {};
    }
    return await userLabelApiV4.detach({ links });
  } catch (error) {
    return { error };
  }
};

import { createReducer } from 'redux-create-reducer';

import { RECEIVE_SEGMENT_LIST } from 'constants/events/segment';

const initialState = {};
export default createReducer(initialState, {
  [RECEIVE_SEGMENT_LIST]: (state, action) => {
    const newState = {};

    action.list.forEach((segment) => {
      newState[segment.id] = segment;
      newState[segment.id].children = [];
    });

    action.list.forEach((segment) => {
      if (segment.parent_id && newState[segment.parent_id]) {
        newState[segment.parent_id].children.push(segment);
        newState[segment.id].parent = newState[segment.parent_id];
      } else {
        newState[segment.id] = segment;
      }
    });
    return newState;
  },
});

import { createSelector } from '@reduxjs/toolkit';

import { MODULE_REDUCER_KEY } from 'constants/reducer';

import { KEY } from '../reducers';

export const selectDisableProductsState = (state) =>
  state[MODULE_REDUCER_KEY][KEY];

export const selectIsDisableProductsVisible = createSelector(
  selectDisableProductsState,
  (moduleState) => moduleState.get('visible'),
);

export const selectProductVersionsToDisable = createSelector(
  selectDisableProductsState,
  (moduleState) => moduleState.get('productVersions'),
);

export const selectIsDisableProductsInProgress = createSelector(
  selectDisableProductsState,
  (moduleState) => moduleState.get('inProgress'),
);

export const selectDisableProductsInProgressCount = createSelector(
  selectDisableProductsState,
  (moduleState) => moduleState.get('count'),
);

export const selectDisableProductsInProgressStatus = createSelector(
  selectDisableProductsState,
  (moduleState) => moduleState.get('status'),
);

export const selectIsDisableProductsDone = createSelector(
  selectDisableProductsState,
  (moduleState) => moduleState.get('done'),
);

export const selectDisableProductsErrors = createSelector(
  selectDisableProductsState,
  (moduleState) => moduleState.get('errors'),
);

export const selectDisableProductsSuccess = createSelector(
  selectDisableProductsState,
  (moduleState) => moduleState.get('success'),
);

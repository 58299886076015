import { DevToolsEnhancerOptions, configureStore } from '@reduxjs/toolkit';

import createReducer from 'reducers';
import rootSaga from 'sagas';
import { getAppConfig } from 'utils/app-config';
import { sagaMiddleware, setSagaMiddlewareAsReady } from 'utils/modules/saga';
import { getReduxLogger } from 'utils/redux/logger';

function initReduxStore(preloadedState: any) {
  const middlewares = [sagaMiddleware];
  let devTools: boolean | DevToolsEnhancerOptions = false;
  const reduxDevtools = getAppConfig().reduxDevtools || { enabled: false };
  if (reduxDevtools.enabled) {
    middlewares.push(getReduxLogger());
    const { enabled, ...devtoolsOptions } = reduxDevtools;
    devTools = Object.keys(devtoolsOptions).length ? devtoolsOptions : true;
  }
  const reduxStore = configureStore({
    reducer: createReducer(),
    middleware: (getDefaultMiddleware) =>
      getDefaultMiddleware({
        actionCreatorCheck: true,
        immutableCheck: false,
        serializableCheck: false,
        thunk: true,
      }).concat(middlewares),
    preloadedState,
    devTools,
  });
  sagaMiddleware.run(rootSaga);
  setSagaMiddlewareAsReady();
  return reduxStore;
}

// WARNING: "var" declaration is required for DEV mode
// eslint-disable-next-line no-var
export var store = initReduxStore((window as any).__INITIAL_STATE__);

import { useMemo, useState } from 'react';
import processString from 'react-process-string';
import { useSelector } from 'react-redux';

import { Button } from '@alkem/react-ui-button';

import { RuleTemplateType, selectValidationResults } from 'modules/validation';
import i18n from 'utils/i18n';
import { get, toJsIfImmutable } from 'utils/immutable';

import { AdminRuleResultsModal } from './admin-rule-check-modal';
import './admin-rule-check.scss';
import { ValidationResult } from './types';

const AdminRuleCheck = () => {
  const [displayModal, setDisplayModal] = useState(false);

  const validationResults: any = useSelector(selectValidationResults);
  const rules: ValidationResult[] = useMemo(
    () =>
      toJsIfImmutable(
        get(validationResults, 'rules')
          ?.filter(
            (rule) => get(rule, 'templateType') === RuleTemplateType.REQUIRED,
          )
          .sort((ruleA, ruleB) => get(ruleB, 'status') - get(ruleA, 'status')),
      ),
    [validationResults],
  );
  const invalidRules = rules?.filter((rule) => rule.status === 4);

  if (!rules) {
    return null;
  }

  const renderDisplayModalButtonText = () =>
    processString([
      {
        regex: /__EXCEPTION__/,
        fn: (key) =>
          !!invalidRules.length && (
            <span key={key}>
              <i className="mdi mdi-alert" /> {invalidRules.length} exception(s)
            </span>
          ),
      },
    ])(
      i18n.t('Rules details (admin) {{exception}}', {
        exception: `__EXCEPTION__`,
      }),
    );

  return (
    <div data-testid="AdminRuleCheck">
      <Button
        onClick={() => setDisplayModal(!displayModal)}
        secondary
        className="AdminRuleCheck__modalToggle"
        testid="admin-rule-check-button"
      >
        {renderDisplayModalButtonText()}
      </Button>
      {displayModal && (
        <AdminRuleResultsModal
          ruleResults={rules}
          onClose={() => setDisplayModal(!displayModal)}
          onConfirm={() => setDisplayModal(!displayModal)}
        />
      )}
    </div>
  );
};

export { AdminRuleCheck };

import PropTypes from 'prop-types';
import { PureComponent } from 'react';

import { AddButton } from '@alkem/react-ui-button';
import { InputDisabled } from '@alkem/react-ui-inputs';

import i18n from 'utils/i18n';

import StringListItem from './item';
import './string-list.scss';

export default class StringList extends PureComponent {
  static propTypes = {
    id: PropTypes.string.isRequired,
    value: PropTypes.arrayOf(PropTypes.string),
    onChange: PropTypes.func.isRequired,
    placeholder: PropTypes.string,
    disabled: PropTypes.bool,
  };

  static defaultProps = {
    placeholder: '',
    disabled: false,
  };

  onAddItem = () => {
    const { value, onChange } = this.props;
    onChange([...value, '']);
  };

  onRemoveItem = (i) => {
    const { value, onChange } = this.props;
    if (value && value.length > i) {
      const newValue = value.filter((e, j) => j !== i);
      onChange(newValue);
    }
  };

  onUpdateItem = (i, updatedValue) => {
    const { value, onChange } = this.props;
    if (value && value.length > i) {
      const newValue = [...value];
      newValue[i] = updatedValue;
      onChange(newValue);
    }
  };

  render() {
    const { id, value, placeholder, disabled } = this.props;

    if (disabled && (!value || !value.length)) {
      return (
        <InputDisabled
          id={id}
          emptyValueString={`(${i18n.t(
            'frontproductstream.input.string_list.placeholder',
            { defaultValue: 'empty' },
          )})`}
        />
      );
    }

    return (
      <div id={id} className="StringList">
        {(value || []).map((e, i) => (
          <StringListItem
            // eslint-disable-next-line react/no-array-index-key
            key={`${i}-${value.length}`}
            id={`${id}-${i}`}
            index={i}
            value={e}
            disabled={disabled}
            emptyValueString={`(${i18n.t(
              'frontproductstream.input.string_list.placeholder',
              { defaultValue: 'empty' },
            )})`}
            placeholder={placeholder}
            onChange={this.onUpdateItem}
            onRemove={this.onRemoveItem}
          />
        ))}
        {!disabled && (
          <div className="StringList__addButton">
            <AddButton
              label={i18n.t(
                'frontproductstream.input.string_list.add_item_button',
                { defaultValue: 'Add item' },
              )}
              onClick={this.onAddItem}
            />
          </div>
        )}
      </div>
    );
  }
}

import { createSelector } from '@reduxjs/toolkit';
import { fromJS } from 'immutable';

const selectModuleState = (state) => state.module.productSuggestions;

export const selectSuggestions = createSelector(selectModuleState, (state) =>
  fromJS(state.get('diffs')),
);

export const selectSuggestionsBeingAccepted = createSelector(
  selectModuleState,
  (state) => state.get('accepting'),
);

export const selectSuggestionsBeingDismissed = createSelector(
  selectModuleState,
  (state) => state.get('dismissing'),
);

export const selectFieldsBeingUpdated = createSelector(
  selectModuleState,
  (state) => state.get('updatingFields'),
);

export const selectAcceptingAll = createSelector(selectModuleState, (state) =>
  state.get('acceptingAll'),
);

export const selectDismissingAll = createSelector(selectModuleState, (state) =>
  state.get('dismissingAll'),
);

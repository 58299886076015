import { List } from 'immutable';
import { reduce } from 'lodash';

export const isUserSharingSomethingToRecipientIds = ({
  allRecipients,
  hasSpecificAttributesByRecipient,
  hasSharingUnitsByRecipient,
  hasSpecificAssetsByRecipient,
  canHaveSpecificDataByRecipient,
  canHaveSpecificFieldsByRecipient,
  specificDataByRecipient,
  overriddenFieldsByRecipient,
  overridableFieldsByRecipient,
  specificFieldsByRecipient,
  sharingUnitsByRecipient,
  allowedPrimaryRecipients,
}) => {
  const byRecipient = (recipient, maps) =>
    reduce(
      maps,
      (acc, item, key) => ({
        ...acc,
        [key]: item && item.get(recipient.get('id')),
      }),
      {},
    );

  const filteredRecipients = allRecipients.filter((recipient) => {
    const isPrimary = !!allowedPrimaryRecipients.find(
      (r) => r.get('id') === recipient.get('id'),
    );

    const {
      hasSpecificAttributes = false,
      hasSharingUnits = false,
      hasSpecificAssets = false,
      canHaveSpecificFields = false,
      sharingUnitRules = List(),
    } = {
      ...byRecipient(recipient, {
        specificData: specificDataByRecipient,
        canHaveSpecificData: canHaveSpecificDataByRecipient,
        canHaveSpecificFields: canHaveSpecificFieldsByRecipient,
        hasSpecificAttributes: hasSpecificAttributesByRecipient,
        overriddenFields: overriddenFieldsByRecipient,
        overridableFields: overridableFieldsByRecipient,
        specificFields: specificFieldsByRecipient,
        hasSharingUnits: hasSharingUnitsByRecipient,
        sharingUnits: sharingUnitsByRecipient,
        hasSpecificAssets: hasSpecificAssetsByRecipient,
      }),
    };

    if (
      !hasSpecificAttributes &&
      !hasSharingUnits &&
      !hasSpecificAssets &&
      !(isPrimary && canHaveSpecificFields) &&
      !(isPrimary && sharingUnitRules && sharingUnitRules.size > 0)
    ) {
      return false;
    }

    return true;
  });

  return filteredRecipients.map((r) => r.get('id'));
};

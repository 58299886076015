import qs from 'query-string';

const stringify = (query, withPrefix, options) => {
  const stringified = qs.stringify(query, options);
  return `${withPrefix && stringified ? '?' : ''}${stringified}`;
};

export default {
  ...qs,
  stringify,
};

export const withQuery = (url, query) => `${url}${stringify(query, true)}`;

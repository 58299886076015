import { call, put, race, take } from 'redux-saga/effects';

import { startLoading, stopLoading } from 'actions/navigation';
import { notificationError } from 'actions/notification';
import i18n from 'utils/i18n';
import { size } from 'utils/immutable';
import { logError } from 'utils/logging';

import { detachUserLabelsDone, trackUserLabels } from '../actions';
import { detachUserLabelV4 } from '../actions/apiV4';
import { CANCEL_DETACH_USER_LABEL } from '../actions/types';
import { getLinksFromProductKeyIds } from '../utils';

export function* detach({ userLabelIds, productKeyIds }) {
  if (!size(userLabelIds) || !size(productKeyIds)) {
    return {};
  }
  const { error } = yield call(detachUserLabelV4, {
    links: getLinksFromProductKeyIds(productKeyIds, userLabelIds),
  });
  if (error) {
    return { error };
  }

  return {};
}

function* detachTask({ userLabelIds, productVersionIds, productKeyIds }) {
  try {
    yield put(startLoading());
    const { error } = yield call(detach, {
      userLabelIds,
      productVersionIds,
      ...(productKeyIds && { productKeyIds }),
    });
    if (error) {
      yield put(
        notificationError(i18n.t('An error occured while detaching your tags')),
      );
    } else {
      yield put(detachUserLabelsDone(userLabelIds));
      yield put(
        trackUserLabels({
          productVersionIds,
          userLabelIds,
        }),
      );
    }
  } catch (err) {
    logError(err);
  } finally {
    yield put(stopLoading());
  }
}

export default function* detachUserLabels(action) {
  yield race({
    task: call(detachTask, action.payload),
    cancel: take(CANCEL_DETACH_USER_LABEL),
  });
}

import {
  BULK_PREPARE_ASSIGN,
  BULK_PREPARE_RESET,
  BULK_PREPARE_SELECT_GROUP,
  BULK_PREPARE_SHOW,
  BULK_PREPARE_UNSELECT_GROUP,
} from './constants';

export const show = (productVersions) => ({
  type: BULK_PREPARE_SHOW,
  productVersions,
});

export const reset = () => ({
  type: BULK_PREPARE_RESET,
});

export const selectGroup = (groupId) => ({
  type: BULK_PREPARE_SELECT_GROUP,
  groupId,
});

export const unselectgroup = (groupId) => ({
  type: BULK_PREPARE_UNSELECT_GROUP,
  groupId,
});

export const assign = () => ({
  type: BULK_PREPARE_ASSIGN,
});

import i18n from 'utils/i18n';
import { get } from 'utils/immutable';

import { StatusReasons } from './types';

// Request response (cell Assignation Date and cell bulk action)

export const OROP_REFUSED = 'OROP_REFUSED';
export const PRODUCT_NOT_COMMERCIALIZED_ANYMORE =
  'PRODUCT_NOT_COMMERCIALIZED_ANYMORE';
export const INFORMATION_REQUEST_ACCEPTED = 'INFORMATION_REQUEST_ACCEPTED';
export const INFORMATION_REQUEST_REFUSED = 'INFORMATION_REQUEST_REFUSED';

export const OropRefusedReason = {
  reason: OROP_REFUSED,
  getMessage: (): string =>
    i18n.t(
      'onboarding.organization_information_request_response.refused_orop.text',
      {
        defaultValue:
          'This product was already refused by the manufacturer during a previous request',
      },
    ),
};

export const ProductNotCommercializedAnymoreReason = {
  reason: PRODUCT_NOT_COMMERCIALIZED_ANYMORE,
  getMessage: (statusReasons: StatusReasons): string => {
    const replaced_by_gtin = get(statusReasons, ['replaced_by_gtin']);
    if (replaced_by_gtin !== '') {
      return i18n.t(
        'onboarding.organization_information_request_response.not_commercialized_anymore_replaced.text',
        {
          defaultValue:
            'This product is no longer sold. It has been replaced with GTIN {{gtin}}',
          gtin: replaced_by_gtin,
        },
      );
    }
    return i18n.t(
      'onboarding.organization_information_request_response.not_commercialized_anymore.text',
      { defaultValue: 'This product is no longer sold.' },
    );
  },
};

export const InformationRequestAccepted = {
  reason: INFORMATION_REQUEST_ACCEPTED,
  getMessage: (): string => '',
};

export const InformationRequestRefused = {
  reason: INFORMATION_REQUEST_REFUSED,
  getMessage: (statusReasons: StatusReasons): string => {
    const message = get(statusReasons, ['message'], '');
    if (message !== '') {
      return message;
    }
    return '';
  },
};

export const allRequestAnswers = {
  PRODUCT_NOT_COMMERCIALIZED_ANYMORE: ProductNotCommercializedAnymoreReason,
  OROP_REFUSED: OropRefusedReason,
  INFORMATION_REQUEST_ACCEPTED: InformationRequestAccepted,
  INFORMATION_REQUEST_REFUSED: InformationRequestRefused,
};

import { isEqual } from 'lodash';
import { UseFormProps, useForm } from 'react-hook-form';
import { useDispatch } from 'react-redux';

import { ResponseWithData } from '@alkem/sdk-dashboard';

import { listAllAssets } from 'actions/media';
import { notificationError, notificationSuccess } from 'actions/notification';
import { mediaApi } from 'resources';
import { DispatchType } from 'types';
import i18n from 'utils/i18n';

import { DocumentAssetV2, EnhancedAssetV2 } from '../types';

type FormData = EnhancedAssetV2<DocumentAssetV2> & { recipientIds: number[] };

type useDocumentFormParams = UseFormProps<FormData> & {
  defaultValues: FormData;
};

export const useDocumentForm = (fieldValues: useDocumentFormParams) => {
  const formMethods = useForm<FormData>(fieldValues);

  const dispatch: DispatchType = useDispatch();

  const onSubmit = formMethods.handleSubmit(async (data) => {
    const postDocumentUpdate = mediaApi.ProductDocumentUpdate.bind(mediaApi);
    const updateShareStatus =
      mediaApi.UpsertSpecificDocumentData.bind(mediaApi);

    const { recipientIds, ...cleanData } = data;

    const { recipientIds: defaultRecipientIds, ...initialDefaultValues } =
      fieldValues.defaultValues;

    if (!isEqual(cleanData, initialDefaultValues)) {
      try {
        const postDocumentResponse: ResponseWithData<DocumentAssetV2> =
          await postDocumentUpdate(cleanData);

        formMethods.reset(postDocumentResponse.data.data);

        await dispatch(
          listAllAssets({
            product_key_id: cleanData.product_key_id,
          }),
        );

        dispatch(
          notificationSuccess(
            i18n.t(
              'frontproducstream.asset_list.document_modal.save_notification.edited_success',
              { defaultValue: 'Document successfully edited.' },
            ),
            {
              context: 'modal',
            },
          ),
        );
      } catch (e) {
        dispatch(
          notificationError(
            i18n.t(
              'frontproducstream.asset_list.document_modal.save_notification.error',
              { defaultValue: 'An error occured while creating the document.' },
            ),
            {
              context: 'modal',
            },
          ),
        );
      }
    }

    if (!isEqual(recipientIds, defaultRecipientIds)) {
      const deletedRecipients = defaultRecipientIds.filter(
        (recipientId) => !recipientIds.includes(recipientId),
      );
      const addedRecipients = recipientIds.filter(
        (recipientId) => !defaultRecipientIds.includes(recipientId),
      );

      try {
        await Promise.all([
          ...deletedRecipients.map((recipientId) =>
            updateShareStatus(data.id, recipientId, {
              status: 0,
            }),
          ),
          ...addedRecipients.map((recipientId) =>
            updateShareStatus(data.id, recipientId, {
              status: 1,
            }),
          ),
        ]);

        formMethods.reset();

        dispatch(
          notificationSuccess(
            i18n.t(
              'frontproducstream.asset_list.document_modal.save_recipients_notification.edited_success',
              { defaultValue: 'The document recipients have been updated' },
            ),
            {
              context: 'modal',
            },
          ),
        );
      } catch (e) {
        dispatch(
          notificationError(
            i18n.t(
              'frontproducstream.asset_list.document_modal.save_recipients_notification.error',
              {
                defaultValue:
                  'An error occured while updating the document recipients.',
              },
            ),
            {
              context: 'modal',
            },
          ),
        );
      }
    }
  });

  return {
    formMethods,
    onSubmit,
  };
};

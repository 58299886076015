import asyncComponent from 'core/modules/async/component';
import registerModule from 'core/modules/async/register';
import { registerModuleReducer } from 'utils/modules/reducer';
import { registerSaga } from 'utils/modules/saga';

import reducer from './reducer';
import mainSaga from './sagas';
import { MODULE_NAME } from './shared';

registerSaga(mainSaga);
registerModuleReducer(MODULE_NAME, reducer);

export { sendQuickMessage } from './shared';
export { sendQuickMessage as sendQuickMessageSaga } from './sagas';
export { default as CollaborationLink } from './components/link';

const chatModule = async () =>
  registerModule(await import(/* webpackChunkName: "chat" */ './async'));

export const Collaboration = asyncComponent(chatModule, {
  componentPath: 'Collaboration',
});
export const Chat = asyncComponent(chatModule, { componentPath: 'Chat' });

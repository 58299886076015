import messageApi from 'resources/messageApi';

import { MESSAGES_PER_PAGE } from './constants';

export async function listRecipients({
  recipientId,
  recipientNameLike,
  entityId,
  roomType,
}) {
  const response = await messageApi.ListRecipients({
    entityId,
    recipientId,
    recipientNameLike,
    roomType,
    limit: 12,
  });
  return response.data.data;
}

export async function listRooms({ entityId, roomType, offset, limit }) {
  const response = await messageApi.ListRooms({
    entityId,
    roomType,
    limit,
    offset,
  });
  return {
    rooms: response.data.data,
    totalRoomCount: response.data.totalResults,
  };
}

export async function getRoom({ roomType, roomId }) {
  const response = await messageApi.GetRoom({ roomType, roomId });
  return { room: response.data.data };
}

export async function createRoom(organizationId, entityId, roomType, message) {
  const response = await messageApi.CreateRoom(organizationId, {
    entityId: entityId,
    roomType,
    message,
  });
  return response.data.data;
}

export async function createMessage(roomId, message) {
  const response = await messageApi.CreateMessage(roomId, message);
  return response.data.data;
}

export async function listMessages({
  roomId,
  offset = 0,
  limit = MESSAGES_PER_PAGE,
} = {}) {
  const response = await messageApi.ListMessages(roomId, { offset, limit });
  return response.data.data;
}

export async function organizationCountUnacked() {
  const response = await messageApi.OrganizationCountUnacked();
  return response.data.data;
}

export async function roomEntityCountUnacked(
  roomType,
  entityType,
  entityId,
  recipientId,
) {
  const response = await messageApi.RoomEntityCountUnacked(
    roomType,
    entityType,
    entityId,
    recipientId !== null ? [recipientId] : null,
  );
  return response.data.data;
}

export async function acknowledgeRoom(roomId) {
  const response = await messageApi.AcknowledgeRoom(roomId);
  return response.data.data;
}

import classNames from 'classnames';
import moment from 'moment';

import { Image } from 'components/ui/image';
import { DiffType } from 'types/diff';
import { i18n } from 'utils/i18n';
import { toJsIfImmutable } from 'utils/immutable';

import { DiffList } from './list';
import { objectToArray } from './utils';
import './value.scss';

const EmptyValue = () => (
  <span className={`DiffValue__empty`}>{i18n.t('(empty)')}</span>
);

const UnknownValue = ({ value }: { value: any }) => (
  <>
    <div className={`DiffValue__notHandledDesc`}>
      {i18n.t(
        'This value is not well handled by the application, so here the raw data:',
      )}
    </div>
    <pre className={`DiffValue__notHandledValue`}>
      {JSON.stringify(value, null, 2)}
    </pre>
  </>
);

type Props = {
  value: any;
  type: DiffType;
  className?: string;
  ifEmpty?: typeof EmptyValue;
  ifUnknown?: typeof UnknownValue;
};

const UnwrappedDiffValue = ({
  value,
  type,
  ifEmpty = EmptyValue,
  ifUnknown = UnknownValue,
}: Props) => {
  switch (type) {
    case 'timestamp':
      return value ? <>{moment.unix(value).format('L LT')}</> : ifEmpty();
    case 'time':
      return value ? <>{moment.unix(value).format('L LT')}</> : ifEmpty();
    case 'date':
      return value ? <>{moment.unix(value).utc().format('L')}</> : ifEmpty();
    case 'iso_date':
      return value ? <>{moment.unix(value).format('L')}</> : ifEmpty();
    case 'boolean':
      if (value === true) {
        return <>{i18n.t('Yes')}</>;
      }
      if (value === false) {
        return <>{i18n.t('No')}</>;
      }
      return ifEmpty();
    case 'list':
    case 'string_list':
      if (!value || value.size === 0 || value.length === 0) {
        return ifEmpty();
      }
      return (
        <DiffList
          label={toJsIfImmutable(value)}
          type="ul"
          labelClassName={`DiffValue__label`}
        />
      );
    case 'picture':
      if (value === null) {
        return ifEmpty();
      }
      return <Image maxWidth={128} maxHeight={128} src={value} />;
    default: {
      if (value == null || value === '') {
        return ifEmpty();
      }
      if (['string', 'number'].includes(typeof value)) {
        return <>{value}</>;
      }
      const arrayValue = objectToArray(value);
      if (
        Array.isArray(arrayValue) &&
        arrayValue.every((val) => ['string', 'number'].includes(typeof val))
      ) {
        return <>{arrayValue.join()}</>;
      }
      return ifUnknown({ value });
    }
  }
};

export const DiffValue = ({ className, ...props }: Props) => (
  <div className={classNames(className, 'DiffValue')} data-testid="DiffValue">
    <UnwrappedDiffValue {...props} />
  </div>
);

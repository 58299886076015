import {
  CLOSE_ALL_FORM_GROUPS,
  CLOSE_FORM_GROUP,
  FETCH_DISPLAY_GROUPS,
  FORCE_EDITABLE_DISPLAY_GROUPS,
  OPEN_FORM_GROUP,
  RESET_DISPLAY_GROUPS,
  RESET_EDITABLE_DISPLAY_GROUPS,
} from './constants';

export const fetchDisplayGroups = () => ({
  type: FETCH_DISPLAY_GROUPS,
});

export const resetDisplayGroups = () => ({
  type: RESET_DISPLAY_GROUPS,
});

export const openFormGroup = (formGroupId) => ({
  type: OPEN_FORM_GROUP,
  payload: { formGroupId },
});

export const closeFormGroup = (formGroupId) => ({
  type: CLOSE_FORM_GROUP,
  payload: { formGroupId },
});

export const closeAllFormGroups = () => ({
  type: CLOSE_ALL_FORM_GROUPS,
});

export const forceEditableDisplayGroups = () => ({
  type: FORCE_EDITABLE_DISPLAY_GROUPS,
});

export const resetEditableDisplayGroups = () => ({
  type: RESET_EDITABLE_DISPLAY_GROUPS,
});

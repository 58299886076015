import { call, put } from 'redux-saga/effects';

import { navigate } from '@alkem/micro-frontend-tools';

import { resetLastActivityStorage } from 'modules/idle/utils';
import * as routes from 'routes';
import { reload } from 'utils/location';

import { resetAuthorized } from '../actions';

export function* afterLoginSuccessSaga({
  payload: { hasOrganizationSwapped = false, next },
}: {
  payload: {
    hasOrganizationSwapped?: boolean;
    next?: string;
  };
}) {
  yield call(resetLastActivityStorage);
  yield put(resetAuthorized());
  localStorage.removeItem('sxm_username');

  yield call(navigate, { to: routes.landing, queries: next ? { next } : {} });

  if (hasOrganizationSwapped) {
    yield call(reload);
  }
}

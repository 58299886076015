import { PureComponent } from 'react';

import { Button } from '@alkem/react-ui-button';

import DocumentModal from './document-modal';

interface Props {
  action: {
    items: any[];
    content: string;
  };
  contentOwnerId: number;
  productId: number;
  productKeyId: number;
  productVersionId: number;
  targetMarketId: number;
  updateEntities: (assetType: string) => void;
}

interface State {
  isOpen: boolean;
}

export default class ActionDocument extends PureComponent<Props, State> {
  public state = {
    isOpen: false,
  };

  private showModal = () => {
    this.setState({ isOpen: true });
  };

  private hideModal = () => {
    this.setState({ isOpen: false });
  };

  private onSuccess = () => {
    this.props.updateEntities('ProductDocument');
    this.hideModal();
  };

  public render() {
    const { action, productKeyId } = this.props;
    const { isOpen } = this.state;
    return (
      <div>
        <Button
          secondary
          className="Button--addDocument"
          onClick={this.showModal}
        >
          {action.content}
        </Button>
        {isOpen && (
          <DocumentModal
            title={action.content}
            action={action}
            productKeyId={productKeyId}
            onSuccess={this.onSuccess}
            onClose={this.hideModal}
          />
        )}
      </div>
    );
  }
}

import i18n from 'utils/i18n';

export const RECEIVE_EXPORT_SYNCHRONIZATION_STATUS =
  'RECEIVE_EXPORT_SYNCHRONIZATION_STATUS';

export const SYNCHRONIZATION_UNKNOWN = {
  const: 'UNKNOWN',
  className: 'Draft',
  label: i18n.t('frontproductstream.export_sync_status.unknown.label', {
    defaultValue: 'Synchronization unknown',
  }),
  help: i18n.t('frontproductstream.export_sync_status.unknown.help', {
    defaultValue: 'No synchronization was made',
  }),
};

export const SYNCHRONIZATION_IN_PROGRESS = {
  const: 'IN_PROGRESS',
  className: 'Pending',
  label: i18n.t('frontproductstream.export_sync_status.in_progress.label', {
    defaultValue: 'Synchronization in progress',
  }),
  help: i18n.t('frontproductstream.export_sync_status.in_progress.help', {
    defaultValue: 'Synchronization is in progress',
  }),
};

export const SYNCHRONIZATION_SUCCESS = {
  const: 'SUCCESS',
  className: 'Accepted',
  label: i18n.t('frontproductstream.export_sync_status.success.label', {
    defaultValue: 'Synchronization success',
  }),
  help: i18n.t('frontproductstream.export_sync_status.success.help', {
    defaultValue: 'Synchronization was a success',
  }),
};

export const SYNCHRONIZATION_ERROR = {
  const: 'ERROR',
  className: 'Refused',
  label: i18n.t('frontproductstream.export_sync_status.error.label', {
    defaultValue: 'Synchronization error',
  }),
  help: i18n.t('frontproductstream.export_sync_status.error.help', {
    defaultValue: 'There was an error during synchronization',
  }),
};

export const SYNCHRONIZATION_STATUSES = {
  [SYNCHRONIZATION_UNKNOWN.const]: SYNCHRONIZATION_UNKNOWN,
  [SYNCHRONIZATION_IN_PROGRESS.const]: SYNCHRONIZATION_IN_PROGRESS,
  [SYNCHRONIZATION_SUCCESS.const]: SYNCHRONIZATION_SUCCESS,
  [SYNCHRONIZATION_ERROR.const]: SYNCHRONIZATION_ERROR,
};

export const MISSING_SUBSCRIPTION = {
  const: 'MISSING_SUBSCRIPTION',
  className: 'Refused',
  label: i18n.t(
    'frontproductstream.export_sync_status_code.missing_subscription.label',
    {
      defaultValue: 'Missing Subscription',
    },
  ),
  help: i18n.t(
    'frontproductstream.export_sync_status_code.missing_subscription.help',
    {
      defaultValue:
        'Missing subscription from the following retailer, Alkemics is working on it and will come back to you.',
    },
  ),
};

export const MISSING_GTIN_REGISTER = {
  const: 'MISSING_GTIN_REGISTER',
  className: 'Refused',
  label: i18n.t(
    'frontproductstream.export_sync_status_code.missing_gtin_register.label',
    {
      defaultValue: 'Transmission error',
    },
  ),
  help: i18n.t(
    'frontproductstream.export_sync_status_code.missing_gtin_register.help',
    {
      defaultValue:
        'Transmission error for following retailers has been received, Alkemics is working on it and will come back to you.',
    },
  ),
};

export const TRANSMISSION_ERROR = {
  const: 'TRANSMISSION_ERROR',
  className: 'Refused',
  label: i18n.t(
    'frontproductstream.export_sync_status_code.transmission_error.label',
    {
      defaultValue: 'Transmission error',
    },
  ),
  help: i18n.t(
    'frontproductstream.export_sync_status_code.transmission_error.help',
    {
      defaultValue:
        'Transmission error for following retailers has been received, Alkemics is working on it and will come back to you.',
    },
  ),
};

export const SENT_TO_RECIPIENT = {
  const: 'SENT_TO_RECIPIENT',
  className: 'Pending',
  label: i18n.t(
    'frontproductstream.export_sync_status_code.sent_to_recipient.label',
    {
      defaultValue: 'Synchronization in progress',
    },
  ),
  help: i18n.t(
    'frontproductstream.export_sync_status_code.sent_to_recipient.help',
    {
      defaultValue: 'Synchronization is in progress',
    },
  ),
};

export const REJECTED_BY_RECIPIENT = {
  const: 'REJECTED_BY_RECIPIENT',
  className: 'Refused',
  label: i18n.t(
    'frontproductstream.export_sync_status_code.rejected_by_recipient.label',
    {
      defaultValue: 'Rejected',
    },
  ),
  help: i18n.t(
    'frontproductstream.export_sync_status_code.rejected_by_recipient.help',
    {
      defaultValue:
        'This product was rejected by following retailers, not possible to share anymore this product with those retailers.',
    },
  ),
};

export const CORRECTION_REQUESTED_BY_RECIPIENT = {
  const: 'CORRECTION_REQUESTED_BY_RECIPIENT',
  className: 'Warning',
  label: i18n.t(
    'frontproductstream.export_sync_status_code.correction_requested_by_recipient.label',
    {
      defaultValue: 'Review',
    },
  ),
  help: i18n.t(
    'frontproductstream.export_sync_status_code.correction_requested_by_recipient.help',
    {
      defaultValue:
        'Correction has been required by following recipients, check comments attached to this product.',
    },
  ),
};

export const RECEIVED_IN_RECIPIENT_SYSTEM = {
  const: 'RECEIVED_IN_RECIPIENT_SYSTEM',
  className: 'Accepted',
  label: i18n.t(
    'frontproductstream.export_sync_status_code.received_in_recipient_system.label',
    {
      defaultValue: 'Received',
    },
  ),
  help: i18n.t(
    'frontproductstream.export_sync_status_code.received_in_recipient_system.help',
    {
      defaultValue: 'Received',
    },
  ),
};

export const INTEGRATED_IN_RECIPIENT_SYSTEM = {
  const: 'INTEGRATED_IN_RECIPIENT_SYSTEM',
  className: 'Accepted',
  label: i18n.t(
    'frontproductstream.export_sync_status_code.integrated_in_recipient_system.label',
    {
      defaultValue: 'Synchronised',
    },
  ),
  help: i18n.t(
    'frontproductstream.export_sync_status_code.integrated_in_recipient_system.help',
    {
      defaultValue: 'Synchronised',
    },
  ),
};

export const INTERNAL_ERROR = {
  const: 'INTERNAL_ERROR',
  className: 'Refused',
  label: i18n.t(
    'frontproductstream.export_sync_status_code.internal_error.label',
    {
      defaultValue: 'Transmission error',
    },
  ),
  help: i18n.t(
    'frontproductstream.export_sync_status_code.internal_error.help',
    {
      defaultValue:
        'Transmission error for following retailers has been received, Alkemics is working on it and will come back to you.',
    },
  ),
};

export const MISSING_DATA_CONFIRMATION = {
  const: 'MISSING_DATA_CONFIRMATION',
  className: 'Pending',
  label: i18n.t(
    'frontproductstream.export_sync_status_code.missing_data_confirmation.label',
    {
      defaultValue: 'Synchronization in progress',
    },
  ),
  help: i18n.t(
    'frontproductstream.export_sync_status_code.missing_data_confirmation.help',
    {
      defaultValue: 'Synchronization is in progress',
    },
  ),
};

export const PRICE_SYNCHRONIZATION_IN_PROGRESS = {
  const: 'PRICE_SYNCHRONIZATION_IN_PROGRESS',
  className: 'Pending',
  label: i18n.t(
    'frontproductstream.export_sync_status_code.price_synchronization_in_progress.label',
    {
      defaultValue: 'Synchronization in progress',
    },
  ),
  help: i18n.t(
    'frontproductstream.export_sync_status_code.price_synchronization_in_progress.help',
    {
      defaultValue: 'Synchronization is in progress',
    },
  ),
};

export const PRICE_SYNCHRONIZATION_INITIATED = {
  const: 'PRICE_SYNCHRONIZATION_INITIATED',
  className: 'Pending',
  label: i18n.t(
    'frontproductstream.export_sync_status_code.price_synchronization_initiated.label',
    {
      defaultValue: 'Price synchronization initiated',
    },
  ),
  help: i18n.t(
    'frontproductstream.export_sync_status_code.price_synchronization_initiated.help',
    {
      defaultValue: 'Price synchronization initiated',
    },
  ),
};

export const PRICE_SYNCHRONIZATION_ITEM_IN_PROGRESS = {
  const: 'PRICE_SYNCHRONIZATION_ITEM_IN_PROGRESS',
  className: 'Pending',
  label: i18n.t(
    'frontproductstream.export_sync_status_code.price_synchronization_item_in_progress.label',
    {
      defaultValue: 'Price synchronization in progress',
    },
  ),
  help: i18n.t(
    'frontproductstream.export_sync_status_code.price_synchronization_item_in_progress.help',
    {
      defaultValue: 'Price synchronization is in progress',
    },
  ),
};

export const PRICE_SYNCHRONIZATION_REFUSED = {
  const: 'PRICE_SYNCHRONIZATION_REFUSED',
  className: 'Refused',
  label: i18n.t(
    'frontproductstream.export_sync_status_code.price_synchronization_refused.label',
    {
      defaultValue: 'Price synchronization refused',
    },
  ),
  help: i18n.t(
    'frontproductstream.export_sync_status_code.price_synchronization_refused.help',
    {
      defaultValue: 'Price synchronization refused',
    },
  ),
};

export const PRICE_SYNCHRONIZATION_ITEM_REFUSED = {
  const: 'PRICE_SYNCHRONIZATION_ITEM_REFUSED',
  className: 'Refused',
  label: i18n.t(
    'frontproductstream.export_sync_status_code.price_synchronization_item_refused.label',
    {
      defaultValue: 'Price synchronization item refused',
    },
  ),
  help: i18n.t(
    'frontproductstream.export_sync_status_code.price_synchronization_item_refused.help',
    {
      defaultValue: 'Price synchronization item refused',
    },
  ),
};

export const SYNCHRONIZATION_STATUSES_CODE = {
  [SENT_TO_RECIPIENT.const]: SENT_TO_RECIPIENT,
  [MISSING_SUBSCRIPTION.const]: MISSING_SUBSCRIPTION,
  [MISSING_GTIN_REGISTER.const]: MISSING_GTIN_REGISTER,
  [TRANSMISSION_ERROR.const]: TRANSMISSION_ERROR,
  [SENT_TO_RECIPIENT.const]: SENT_TO_RECIPIENT,
  [REJECTED_BY_RECIPIENT.const]: REJECTED_BY_RECIPIENT,
  [CORRECTION_REQUESTED_BY_RECIPIENT.const]: CORRECTION_REQUESTED_BY_RECIPIENT,
  [RECEIVED_IN_RECIPIENT_SYSTEM.const]: RECEIVED_IN_RECIPIENT_SYSTEM,
  [INTEGRATED_IN_RECIPIENT_SYSTEM.const]: INTEGRATED_IN_RECIPIENT_SYSTEM,
  [INTERNAL_ERROR.const]: INTERNAL_ERROR,
  [MISSING_DATA_CONFIRMATION.const]: MISSING_DATA_CONFIRMATION,
  [PRICE_SYNCHRONIZATION_IN_PROGRESS.const]: PRICE_SYNCHRONIZATION_IN_PROGRESS,
  [PRICE_SYNCHRONIZATION_INITIATED.const]: PRICE_SYNCHRONIZATION_INITIATED,
  [PRICE_SYNCHRONIZATION_ITEM_IN_PROGRESS.const]:
    PRICE_SYNCHRONIZATION_ITEM_IN_PROGRESS,
  [PRICE_SYNCHRONIZATION_ITEM_REFUSED.const]:
    PRICE_SYNCHRONIZATION_ITEM_REFUSED,
  [PRICE_SYNCHRONIZATION_REFUSED.const]: PRICE_SYNCHRONIZATION_REFUSED,
};

export const SSC_IN_PROGRESS = [
  SYNCHRONIZATION_IN_PROGRESS.const,
  SYNCHRONIZATION_UNKNOWN.const,
  MISSING_DATA_CONFIRMATION.const,
  PRICE_SYNCHRONIZATION_IN_PROGRESS.const,
  PRICE_SYNCHRONIZATION_INITIATED.const,
  PRICE_SYNCHRONIZATION_ITEM_IN_PROGRESS.const,
];

export const SSC_SUCCESS_WARNING = [
  CORRECTION_REQUESTED_BY_RECIPIENT.const,
  REJECTED_BY_RECIPIENT.const,
  PRICE_SYNCHRONIZATION_REFUSED.const,
  PRICE_SYNCHRONIZATION_ITEM_REFUSED.const,
];

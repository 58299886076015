import { List, Map } from 'immutable';

export const isAvailableForProductStatus = (statusMap, requiredList) => {
  if (!statusMap || !requiredList) {
    return true;
  }
  const status = Map(statusMap).keySeq();
  const required = List(requiredList);
  return (
    status.isEmpty() || !required.filter((s) => status.includes(s)).isEmpty()
  );
};

import { isRetailer } from 'core/api/user';
import { selectHasCatalogFilterExportableFeature } from 'modules/feature-flag/selectors';

import { isAvailableForProductStatus } from '../../utils/filters/product-status';

import { postShareProductStatus } from './share-status';
import { Filter } from './types';

export const exportableTagFilter: Filter = {
  key: 'alkemics.isAdvancedExportable',
  query: 'advancedExportable',
  isAvailable: (
    { user, selectedFilterTargetProductStatus } = {
      user: null,
      selectedFilterTargetProductStatus: {},
    },
  ) =>
    user != null &&
    selectHasCatalogFilterExportableFeature(user) &&
    isRetailer(user) &&
    isAvailableForProductStatus(
      selectedFilterTargetProductStatus,
      postShareProductStatus,
    ),
};

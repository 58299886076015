import classNames from 'classnames';
import { List, Map } from 'immutable';
import { times } from 'lodash';
import { ReactNode, Ref, memo } from 'react';

import { Referential } from '@alkem/sdk-dashboard';

import { get } from 'utils/immutable';

import ListTableHeaderCell, { ReferentialMap, Sorting } from './cell';
import './index.scss';

export type ListTableHeaderProps = {
  className?: string;
  headerRefsCallback?: (i: number) => Ref<any>;
  referentials: List<any> | any[];
  columnMap: Map<string, any> | object;
  sorting?: Sorting | Map<any, any> | object;
  onSort?: (referential: Referential | ReferentialMap, order: boolean) => void;
  getReferentialId?: (referential: Referential) => string;
  getReferentialSort?: (referential: Referential) => boolean;
  getReferentialWidth?: (referential: Referential) => number | null;
  getReferentialLabel?: (referential: Referential) => string;
  getReferentialHeader?: (referential: Referential) => boolean;
  getSortingId?: (sorting: Sorting) => string | undefined;
  getSortingAsc?: (sorting?: Sorting) => boolean | undefined;
  cellsOnLeft?: number | number[];
  cellsOnRight?: number | number[];
  withMultiLines?: boolean;
  sticky?: boolean;
  isSticky?: boolean;
  useEllitips?: boolean;
  ellitipsLines?: number;
  tooltipDefinitions?: Map<string, () => any>;
};

const ListTableHeader = memo((props: ListTableHeaderProps) => {
  const {
    className,
    headerRefsCallback,
    columnMap,
    sorting = {},
    onSort,
    getReferentialId = (referential: Referential) => get(referential, 'id'),
    getReferentialSort,
    getReferentialWidth,
    getReferentialLabel,
    getReferentialHeader,
    getSortingId = (_sorting: Sorting) => get(_sorting, 'id'),
    getSortingAsc,
    cellsOnLeft = 0,
    cellsOnRight = 0,
    withMultiLines,
    sticky,
    isSticky,
    useEllitips,
    ellitipsLines,
    tooltipDefinitions = Map(),
  } = props;
  const referentials = List<Referential>(props.referentials);

  const renderEmptyCell = (place, idx) => (
    <td
      key={`list-table-header-cells-${place}-${idx}`}
      data-testid="list-table-header-empty-cell"
      className={classNames({
        'ListTableHeaderCell--firstRowSticky': isSticky,
      })}
    />
  );

  const renderCells = (place, cellsToRender) => {
    if (typeof cellsToRender === 'number') {
      return times(cellsToRender, (idx) => renderEmptyCell(place, idx));
    }
    if (!Array.isArray(cellsToRender)) {
      return null;
    }
    return cellsToRender.map((label, idx) => {
      if (label) {
        return (
          <th
            data-testid="list-table-header-cell"
            key={`list-table-header-cells-${place}-${label}`}
            className="ListTableHeaderCell"
          >
            <span className="ListTableHeaderCell__content ">
              <span className="ListTableHeaderCell__label">{label}</span>
            </span>
          </th>
        );
      }
      return renderEmptyCell(place, idx);
    });
  };

  return (
    <tr
      data-testid="list-table-header"
      className={classNames('ListTableHeader', className, { sticky })}
    >
      {renderCells('left', cellsOnLeft)}
      {
        referentials.map((referential, i) => {
          if (!referential) {
            return null;
          }
          const refId: string = getReferentialId(referential);
          if (!get(columnMap, [refId])) {
            return null;
          }
          const tooltipDefinition = tooltipDefinitions.get(
            get(referential, 'tooltip_key'),
          );
          return (
            <ListTableHeaderCell
              ref={headerRefsCallback?.(i as number) || undefined}
              key={`list-table-header-cell-${refId}`}
              referential={referential}
              sorting={getSortingId(sorting) === refId ? sorting : undefined}
              withMultiLines={withMultiLines}
              onSort={onSort}
              getReferentialSort={getReferentialSort}
              getReferentialWidth={getReferentialWidth}
              getReferentialLabel={getReferentialLabel}
              getReferentialHeader={getReferentialHeader}
              getSortingAsc={getSortingAsc}
              tooltipContent={tooltipDefinition && tooltipDefinition()}
              isSticky={isSticky}
              useEllitips={useEllitips}
              ellitipsLines={ellitipsLines}
              zeroIndex={i === 0}
            />
          );
        }) as unknown as ReactNode
      }
      {renderCells('right', cellsOnRight)}
    </tr>
  );
});

export default ListTableHeader;
export { ListTableHeader };

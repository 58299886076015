import { MODULE_REDUCER_KEY } from 'constants/reducer';
import { get } from 'utils/fp';

// action types

export const MODULE_NAME = 'chat';

export const moduleConst = (name: string) => `${MODULE_NAME}/${name}`;

export const SEND_QUICK_MESSAGE = moduleConst('SEND_QUICK_MESSAGE');
export const CANCEL_FETCH_COUNT_UNACKED = moduleConst(
  'CANCEL_FETCH_COUNT_UNACKED',
);
export const FETCH_COUNT_UNACKED = moduleConst('FETCH_COUNT_UNACKED');

// actions

export const sendQuickMessage = (
  message,
  organizationId,
  entityId,
  roomType,
) => ({
  type: SEND_QUICK_MESSAGE,
  payload: { message, organizationId, entityId, roomType },
});

export const cancelFetchCountUnacked = () => ({
  type: CANCEL_FETCH_COUNT_UNACKED,
});

export const fetchCountUnacked = () => ({
  type: FETCH_COUNT_UNACKED,
});

// selectors

export const createMainLocalSelector = (path: string) =>
  get([MODULE_REDUCER_KEY, MODULE_NAME, 'main', path]);

export const getCountUnacked = createMainLocalSelector('countUnacked');

import * as constants from './constants';

export const open = () => (dispatch) =>
  new Promise((resolve, reject) => {
    dispatch({
      type: constants.OPEN,
      promise: { resolve, reject },
    });
  });

export const close = (confirmed) => ({
  type: constants.CLOSE,
  confirmed,
});

export const confirmHierarchyUpdates = (): any => async (dispatch) =>
  await dispatch(open());

import { call } from 'redux-saga/effects';

import {
  assignationStatusFilter,
  displayUnitsFilter,
  duplicatedProductsFilter,
  lifecycleFilter,
} from 'core/modules/list/constants/filters';
import storage from 'utils/storage';

const buildFiltersConfig = (configState, key, dict) => ({
  ...configState,
  [key]: {
    ...configState[key],
    ...dict,
  },
});

function* getConfig(storageKey) {
  return yield call(storage.getItem, storageKey, {});
}

export function* persistFiltersGenericOptions(storageKey, { payload } = {}) {
  const configState = yield call(getConfig, storageKey);
  yield call(
    storage.setItem,
    storageKey,
    buildFiltersConfig(configState, payload.key, {
      ...payload.options,
    }),
  );
}

export function* persistFiltersCollapsedState(storageKey, { payload } = {}) {
  yield call(persistFiltersGenericOptions, storageKey, {
    payload: {
      key: payload.key,
      options: {
        collapsed: payload.collapsed,
      },
    },
  });
}

export function* persistFilteredFiltersState(storageKey, { payload } = {}) {
  yield call(persistFiltersGenericOptions, storageKey, {
    payload: {
      key: payload.key,
      options: {
        query: payload.query,
      },
    },
  });
}

export function* persistFiltersPaginationState(storageKey, { payload } = {}) {
  yield call(persistFiltersGenericOptions, storageKey, {
    payload: {
      key: payload.key,
      options: {
        page: payload.page,
      },
    },
  });
}

export function* persistOnlyDuplicatedProducts(storageKey, { payload } = {}) {
  yield call(persistFiltersGenericOptions, storageKey, {
    payload: {
      key: duplicatedProductsFilter.key,
      options: {
        [duplicatedProductsFilter.hasDuplicatedKey]: payload,
      },
    },
  });
}

export function* persistWithArchivedProducts(storageKey, { payload } = {}) {
  yield call(persistFiltersGenericOptions, storageKey, {
    payload: {
      key: lifecycleFilter.key,
      options: {
        [lifecycleFilter.withArchivedKey]: payload,
      },
    },
  });
}

export function* persistWithAllTypes(storageKey, { payload } = {}) {
  yield call(persistFiltersGenericOptions, storageKey, {
    payload: {
      key: displayUnitsFilter.key,
      options: {
        [displayUnitsFilter.withAllTypesKey]: payload,
      },
    },
  });
}

export function* persistWithRefusedAssignations(storageKey, { payload } = {}) {
  yield call(persistFiltersGenericOptions, storageKey, {
    payload: {
      key: assignationStatusFilter.key,
      options: {
        [assignationStatusFilter.withRefusedAssignation]: payload,
      },
    },
  });
}

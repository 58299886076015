import classNames from 'classnames';
import PropTypes from 'prop-types';
import { PureComponent } from 'react';
import ImmutablePropTypes from 'react-immutable-proptypes';

import { Button, SwitchButton } from '@alkem/react-ui-button';

import Image from 'components/ui/image';
import Modal from 'components/ui/modal';
import i18n from 'utils/i18n';
import { formatMedia } from 'utils/links';

import { isGroupActive, isGroupAssigned } from '../../utils';

import './header.scss';

class GroupHeader extends PureComponent {
  static propTypes = {
    group: ImmutablePropTypes.map.isRequired,
    isExclusive: PropTypes.bool,
    canPrepareProductForGroup: PropTypes.bool,
    canUnprepareProductForGroup: PropTypes.bool,
    canDeactivateGroup: PropTypes.bool,
    isPreparing: PropTypes.bool,
    onPrepare: PropTypes.func,
    onDeactivateGroups: PropTypes.func,
  };

  static defaultProps = {
    canPrepareProductForGroup: false,
    canUnprepareProductForGroup: false,
    isPreparing: false,
    onPrepare() {},
  };

  state = {
    groupDeactivationModalOpened: false,
  };

  onPrepare = (checked) => {
    const { group, onPrepare } = this.props;
    onPrepare(group.getIn(['group', 'id']), checked);
  };

  openGroupDeactivationModal = () => {
    this.setState({ groupDeactivationModalOpened: true });
  };

  closeGroupDeactivationModal = () => {
    this.setState({ groupDeactivationModalOpened: false });
  };

  confirmGroupDeactivation = () => {
    const { group, onDeactivateGroups } = this.props;
    onDeactivateGroups([group.getIn(['group', 'id'])]);
    this.closeGroupDeactivationModal();
  };

  renderGroupDeactivationModal() {
    const { group } = this.props;
    return (
      <Modal
        modalStyle="dynamic"
        title={i18n.t(
          'frontproductstream.publication_summary.deactivate_retailer_modal.title',
          {
            defaultValue: 'Deactivate retailer',
          },
        )}
        confirmButtonText={
          /** @type {string} */ (
            i18n.t(
              'frontproductstream.publication_summary.deactivate_retailer_modal.button',
              {
                defaultValue: 'Deactivate',
              },
            )
          )
        }
        onConfirm={this.confirmGroupDeactivation}
        onClose={this.closeGroupDeactivationModal}
      >
        {i18n.t(
          'frontproductstream.publication_summary.deactivate_retailer_modal_warning.text',
          {
            defaultValue:
              'Are you sure you want to unsynchronise this product from all {{groupName}} recipients? Product updates will no longer be shared with any existing or future channels for this retailer.',
            groupName: group.getIn(['group', 'name']),
          },
        )}
        <br />
        <br />
        {i18n.t(
          'frontproductstream.publication_summary.deactivate_retailer_modal.text',
          { defaultValue: 'You can re-add recipients at any time' },
        )}
      </Modal>
    );
  }

  render() {
    const {
      group,
      isExclusive,
      canPrepareProductForGroup,
      canUnprepareProductForGroup,
      canDeactivateGroup,
      isPreparing,
    } = this.props;
    const { groupDeactivationModalOpened } = this.state;
    const isActive = isGroupActive(group) || !isExclusive;
    const isAssigned = isGroupAssigned(group);
    return (
      <div
        id={`publication-summary-group-header-${group.getIn(['group', 'id'])}`}
        className={classNames(
          'GroupPublicationSummaryHeader',
          !isActive && 'GroupPublicationSummaryHeader--inactive',
        )}
        data-is-active={isActive}
      >
        {groupDeactivationModalOpened && this.renderGroupDeactivationModal()}
        <div className="alk-flex alk-flex-center GroupPublicationSummaryHeader__Content">
          <div className="alk-flex alk-flex-center">
            <Image
              src={formatMedia(
                `/group/picture/${group.getIn(['group', 'id'])}/logo.png`,
              )}
              className="GroupPublicationSummaryHeader__GroupLogo"
            />
            <span className="GroupPublicationSummaryHeader__GroupName">
              {group.getIn(['group', 'name'])}
            </span>
          </div>
          {canDeactivateGroup && (
            <Button
              onClick={this.openGroupDeactivationModal}
              content={i18n.t(
                'frontproductstream.publication_summary.deactivate_retailer.button',
                {
                  defaultValue: 'Deactivate',
                },
              )}
              secondary
            />
          )}
          {isExclusive && canPrepareProductForGroup && (
            <div className="alk-flex alk-flex-center">
              <span
                className={classNames(
                  'GroupPublicationSummaryHeader__switchStatus',
                  {
                    'GroupPublicationSummaryHeader__switchStatus--preparing':
                      isPreparing,
                  },
                )}
              >
                {isPreparing && (
                  <>
                    {' '}
                    <i className="mdi mdi-loading mdi-spin" />
                    {i18n.t(
                      'frontproductstream.publication_summary.assigning_product.text',
                      {
                        defaultValue: 'Assigning product to the channel',
                      },
                    )}
                  </>
                )}
                {!isPreparing &&
                  isAssigned &&
                  i18n.t(
                    'frontproductstream.publication_summary.product_assigned.text',
                    {
                      defaultValue: 'Product assigned to the channel',
                    },
                  )}
                {!isPreparing &&
                  !isAssigned &&
                  i18n.t(
                    'frontproductstream.publication_summary.assign_product.text',
                    {
                      defaultValue: 'Assign product to the channel',
                    },
                  )}
              </span>
              <SwitchButton
                onChange={this.onPrepare}
                checked={isAssigned}
                disabled={isAssigned && !canUnprepareProductForGroup}
              />
            </div>
          )}
        </div>
      </div>
    );
  }
}

export default GroupHeader;

import { fromJS } from 'immutable';
import { createReducer } from 'redux-create-reducer';

import {
  CLOSE_TARGET_CATALOG_IMPORT_MODAL,
  OPEN_TARGET_CATALOG_IMPORT_FOLLOW_UP_MODAL,
  OPEN_TARGET_CATALOG_IMPORT_MODAL,
  RESTART_TARGET_CATALOG_IMPORT_MODAL,
  TARGET_CATALOG_IMPORT_SELECT_FILE,
  TARGET_CATALOG_IMPORT_UPLOAD,
} from './constants';

// -------
// REDUCER
// -------

export const initialState = fromJS({
  file: null,
  fileFormat: '',
  modalIsOpen: false,
  uploadingFile: false,
  reportID: null,
});

export default createReducer(initialState, {
  [OPEN_TARGET_CATALOG_IMPORT_MODAL]: (state, { format }) =>
    state.set('fileFormat', format).set('modalIsOpen', true),
  [CLOSE_TARGET_CATALOG_IMPORT_MODAL]: () => initialState,
  [RESTART_TARGET_CATALOG_IMPORT_MODAL]: (state) =>
    state.set('reportID', null).set('file', null).set('uploadingFile', false),
  [TARGET_CATALOG_IMPORT_UPLOAD]: (state) => state.set('uploadingFile', true),
  [TARGET_CATALOG_IMPORT_SELECT_FILE]: (state, action) =>
    state.set('file', action.payload),
  [OPEN_TARGET_CATALOG_IMPORT_FOLLOW_UP_MODAL]: (state, action) =>
    state.set('reportID', action.payload),
});

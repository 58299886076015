import i18n from 'utils/i18n';

export default function ConsentManagerModalHeaders() {
  return (
    <tr className="ConsentManagerModal__tool ConsentManagerModal__toolHeader">
      <td className="ConsentManagerModal__toolCell">{i18n.t('Tool')}</td>
      <td className="ConsentManagerModal__toolCell">{i18n.t('Description')}</td>
      <td className="ConsentManagerModal__toolCell">{i18n.t('Website')}</td>
    </tr>
  );
}

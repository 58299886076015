import { useSelector } from 'react-redux';

import {
  RuleApplicationStatus,
  restrictionTypes,
  selectValidationResultsByEntity,
} from 'modules/validation';
import { ValidationEntity } from 'modules/validation/types';
import i18n from 'utils/i18n';
import { get, size } from 'utils/immutable';

export function useErrorCount(
  entityId: number,
  model: string,
  entityType: string,
) {
  const validationResultsByEntity: ValidationEntity[] = useSelector(
    selectValidationResultsByEntity,
  );
  const validation = validationResultsByEntity.filter(
    (e: ValidationEntity) =>
      get(e, 'entity') &&
      get(e, 'entity._type') === entityType &&
      get(e, 'entity.id') === entityId,
  );
  let errorNumber: number = 0;
  let nonBlockingErrorNumber: number = 0;
  if (size(validation)) {
    validation.forEach((e: ValidationEntity) => {
      errorNumber += size(
        get(e, 'rules').filter(
          (rule) =>
            get(rule, 'status') === RuleApplicationStatus.KO &&
            get(rule, 'restrictionType') === restrictionTypes.BLOCKING &&
            get(rule, 'paths')?.some((path) => path.includes(model)),
        ),
      );
      nonBlockingErrorNumber += size(
        get(e, 'rules').filter(
          (rule) =>
            get(rule, 'status') === RuleApplicationStatus.KO &&
            get(rule, 'restrictionType') === restrictionTypes.REQUIRED &&
            get(rule, 'paths')?.some((path) => path.includes(model)),
        ),
      );
    });
  }

  return { errorNumber, nonBlockingErrorNumber };
}

interface Props {
  blocking: number;
  nonBlocking: number;
}

export function ErrorLabel({ blocking, nonBlocking }: Props) {
  const renderErrorMsg = () => {
    if (!nonBlocking && blocking) {
      return (
        <>
          <i className=" mdi mdi-cancel" />{' '}
          {i18n.t(
            'frontproductstream.price_waterfalls.collapsible_pricewaterfall_error_blocking.label',
            { defaultValue: '{{count}} blocking error', count: blocking },
          )}
        </>
      );
    }
    return i18n.t(
      'frontproductstream.price_waterfalls.collapsible_pricewaterfall_error_non_blocking.label',
      { defaultValue: '{{count}} error', count: blocking + nonBlocking },
    );
  };

  if (nonBlocking || blocking) {
    return <span className="PriceWaterfallTitleError">{renderErrorMsg()}</span>;
  }
  return null;
}

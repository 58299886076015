import { TargetMarketId } from '@alkem/lib-front-model';

/** @deprecated use TargetMarket from @alkem/lib-front-model */
export const FRANCE = TargetMarketId.FRANCE;
/** @deprecated use TargetMarket from @alkem/lib-front-model */
export const GERMANY = TargetMarketId.GERMANY;
/** @deprecated use TargetMarket from @alkem/lib-front-model */
export const IRELAND = TargetMarketId.IRELAND;
/** @deprecated use TargetMarket from @alkem/lib-front-model */
export const REUNION = TargetMarketId.REUNION;
/** @deprecated use TargetMarket from @alkem/lib-front-model */
export const SPAIN = TargetMarketId.SPAIN;
/** @deprecated use TargetMarket from @alkem/lib-front-model */
export const SWITZERLAND = TargetMarketId.SWITZERLAND;
/** @deprecated use TargetMarket from @alkem/lib-front-model */
export const UNITED_KINGDOM = TargetMarketId.UNITED_KINGDOM;
/** @deprecated use TargetMarket from @alkem/lib-front-model */
export const UNITED_STATES = TargetMarketId.UNITED_STATES;
/** @deprecated use TargetMarket from @alkem/lib-front-model */
export const PORTUGAL = TargetMarketId.PORTUGAL;
/** @deprecated use TargetMarket from @alkem/lib-front-model */
export const GLOBAL_MARKET = TargetMarketId.GLOBAL_MARKET;
/** @deprecated use TargetMarket from @alkem/lib-front-model */
export const AUSTRALIA = TargetMarketId.AUSTRALIA;
/** @deprecated use TargetMarket from @alkem/lib-front-model */
export const CHILE = TargetMarketId.CHILE;

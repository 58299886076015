import { Map, fromJS } from 'immutable';
import { once } from 'lodash';
import PropTypes from 'prop-types';
import { PureComponent } from 'react';
import ImmutablePropTypes from 'react-immutable-proptypes';

import { PhysicalCheckerStatusMap } from 'constants/physicalCheckerStatus';
import { physicalCheckStatusFilter } from 'core/modules/list/constants/filters/physical-check-status';
import { buildFiltersFromQuery } from 'core/modules/list/utils/filters';
import i18n from 'utils/i18n';

import SimpleFilter from '../simple';

export class PhysicalCheckStatusFilter extends PureComponent {
  static propTypes = {
    selectedFilterMap: ImmutablePropTypes.map,
    aggregations: ImmutablePropTypes.map,
    collapsed: PropTypes.bool,
    onChange: PropTypes.func.isRequired,
    onCollapse: PropTypes.func.isRequired,
    filterQueryValues: PropTypes.array,
  };

  static defaultProps = {
    selectedFilterMap: Map(),
    aggregations: Map(),
    collapsed: true,
    filterQueryValues: [],
  };

  filterKey = physicalCheckStatusFilter.key;
  filterLabel = i18n.t(
    'frontproductstream.core.list_filter_physical_check.label',
    { defaultValue: 'Physical Check' },
  );

  updateSelectionFromQuery = once(() => {
    buildFiltersFromQuery({
      filterQueryValues: this.props.filterQueryValues,
      filterList: this.getFilterList(),
      filterKey: this.filterKey,
      selectFilterValue: (filter) => filter.get('id'),
      selectFilterLabel: (filter) =>
        `${this.filterLabel}: ${filter.get('label')}`,
      selectFilterData: (filter) => filter,
    }).then((filtersFromQuery) => {
      this.props.onChange(filtersFromQuery, true);
    });
  });

  componentDidMount() {
    if (this.shouldUpdateSelectionFromQuery()) {
      this.updateSelectionFromQuery();
    }
  }

  componentDidUpdate() {
    if (this.shouldUpdateSelectionFromQuery()) {
      this.updateSelectionFromQuery();
    }
  }

  onCollapse = (collapsed) => {
    this.props.onCollapse(this.filterKey, collapsed);
  };

  shouldUpdateSelectionFromQuery = () => {
    const { aggregations, selectedFilterMap } = this.props;
    return !aggregations.isEmpty() && selectedFilterMap.isEmpty();
  };

  getFilterList = once(() =>
    fromJS(
      Object.entries(PhysicalCheckerStatusMap).map((entry) => ({
        id: entry[1].id,
        label: entry[1].name,
      })),
    ),
  );

  render() {
    return (
      <SimpleFilter
        id="list-filter-physical-check-status"
        filterList={this.getFilterList()}
        filterKey={this.filterKey}
        filterLabel={this.filterLabel}
        selectedFilterMap={this.props.selectedFilterMap}
        aggregations={this.props.aggregations.get(this.filterKey)}
        collapsed={this.props.collapsed}
        onChange={this.props.onChange}
        onCollapse={this.onCollapse}
      />
    );
  }
}

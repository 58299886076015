import { isBoolean } from 'lodash';

import { filters as allFilters } from 'core/modules/list/constants';
import { get, size, toJsIfImmutable } from 'utils/immutable';

export enum QueryType {
  Match = 'match',
  Range = 'range',
}

interface SearchFilter {
  key: string;
  isQueryReady?: boolean;
  value: any;
  exists?: boolean;
  valuePath?: string;
  not?: boolean;
  type?: QueryType;
}

function buildFilterQuery(key: string, filter: SearchFilter, customField) {
  const isMissingFilter = allFilters.some((f) => {
    return (
      f.key === filter.key && f.missingFilter?.key === filter.value.toString()
    );
  });
  if (filter.isQueryReady) {
    return filter.value;
  }

  if (isMissingFilter) {
    return {
      must: [
        {
          fields: [key],
          exists: false,
        },
      ],
    };
  }

  if (isBoolean(filter.exists)) {
    return {
      exists: filter.exists,
      fields: [key],
    };
  }

  let query;
  if (customField) {
    const valuePath = filter.valuePath || 'value';
    const value = filter[valuePath];
    query = {
      custom_field: customField,
      value: Number(value).toString() === value ? Number(value) : value,
    };
  } else {
    query = {
      fields: [key],
      query: filter.value,
    };
  }

  if (filter.not) {
    return {
      must_not: [query],
    };
  }

  if (customField) {
    return query;
  }

  return { ...query, type: filter.type ? filter.type : 'match' };
}

export function aggregateFilters(filters, simpleAggregations = false) {
  return filters.reduce((acc, filter) => {
    const key = get(filter, 'key');
    const value = simpleAggregations
      ? get(filter, 'value')
      : toJsIfImmutable(filter);
    if (Array.isArray(acc[key])) {
      acc[key].push(value);
    } else if (acc[key] != null) {
      acc[key] = [acc[key], value];
    } else {
      acc[key] = value;
    }
    return acc;
  }, {});
}

export function buildFiltersQuery(
  filters?: SearchFilter[],
  {
    customFields,
    filtersConfig,
  }: {
    customFields?: { [key: string]: string } | null;
    filtersConfig?: { [key: string]: { useAndOperator?: boolean } };
  } = {},
) {
  if (!filters || !size(filters)) {
    return null;
  }
  const filtersAggregated = aggregateFilters(filters);

  return {
    must: Object.keys(filtersAggregated).map((key) => {
      const values = filtersAggregated[key];
      const customField = customFields && customFields[key];
      if (Array.isArray(values)) {
        let operatorKeyWord = 'should';
        if (
          get(filtersConfig, [key, 'useAndOperator']) ||
          get(filtersAggregated, [key, 0, 'useAndOperator'])
        ) {
          operatorKeyWord = 'must';
        }
        return {
          [operatorKeyWord]: values.map((value) =>
            buildFilterQuery(key, value, customField),
          ),
        };
      }
      return buildFilterQuery(key, values, customField);
    }),
  };
}

export function buildAdvancedSearch(...queries) {
  const advancedSearches = queries.filter((q) => q);
  switch (advancedSearches.length) {
    case 0:
      return {};
    case 1:
      return { advancedSearch: advancedSearches[0] };
    default:
      return { advancedSearch: { must: advancedSearches } };
  }
}

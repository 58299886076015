import classnames from 'classnames';
import { get } from 'lodash/fp';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { createStructuredSelector } from 'reselect';

import { Select } from '@alkem/react-ui-select';
import { Spinner } from '@alkem/react-ui-spinner';

import Field from 'components/ui/form/field';
import { selectContentOwnerId } from 'reducers/productVersion';
import { regularReferentialApi as referentialApi } from 'resources/referentialApi';
import i18n from 'utils/i18n';
import { logError } from 'utils/logging';
import qs from 'utils/query';

import './supplier-id-rba.scss';

const mapStateToProps = createStructuredSelector({
  organizationId: selectContentOwnerId,
});

export class SupplierIDRBA extends Field {
  static propTypes = Object.assign({}, Field.props, {
    organizationId: PropTypes.number.isRequired,
  });

  constructor(props) {
    super(props);
    this.state = {
      supplierIds: [],
      isLoading: true,
    };
  }

  componentDidMount() {
    super.componentDidMount();
    this.loadValidIds();
  }

  componentDidUpdate(prevProps) {
    if (
      this.props.organizationId !== prevProps.organizationId ||
      this.isPatchableOnNow(prevProps)
    ) {
      this.loadValidIds();
    }
  }

  getValue(id) {
    return id;
  }

  onSelectItem = (item) => {
    this.handleChange(item.id || null);
  };

  onRemoveItem = () => {
    this.handleChange(null);
  };

  isPatchableOnNow = (prevProps) => {
    return (
      this.props.extraParams.isPatchable &&
      this.props.extraParams.isPatchable !== prevProps.extraParams.isPatchable
    );
  };

  loadValidIds = async (retry = true) => {
    if (this.isReadOnly()) {
      this.setState({ isLoading: false });
      return;
    }

    const { field } = this.props;
    const targetOrganizationID = get(
      ['options', 'targetOrganizationId'],
      field,
    );

    // Load the valid supplier IDs for this organization.
    if (!this.state.isLoading) {
      this.setState({ isLoading: true });
    }

    const apiQueryParams = this.props.extraParams.isRetailer
      ? {
          referential: 'supplierIDs',
          organization_id: this.props.organizationId,
          source_organization_id: this.props.extraParams.sourceOrganizationId,
        }
      : {
          referential: 'supplierIDs',
          organization_id: targetOrganizationID,
        };

    try {
      const response = await referentialApi.get(
        `/onboarding/v1/referentials${qs.stringify(apiQueryParams, true)}`,
      );
      const supplierIds = (get(['data', 'data'], response) || []).map(
        (ref) => ({
          id: ref.code,
          label: ref.code,
        }),
      );
      this.setState({
        supplierIds,
        isLoading: false,
      });
    } catch (e) {
      if (e.status === 404) {
        // Orga not found, the list is empty.
        this.setState({ isLoading: false, supplierIds: [] });
      } else {
        logError(e);
        if (retry) {
          // Other error, retry once.
          this.loadValidIds(false);
        }
      }
    }
  };

  render() {
    const { value, field } = this.props;
    const { supplierIds, isLoading } = this.state;
    if (!field) {
      return null;
    }
    let options = supplierIds;
    if (options.length === 0) {
      let label = i18n.t(
        'frontproductstream.supplier_id_rba.empty_state.label',
        {
          defaultValue:
            'We could not find any values for your organization. Please contact the support.',
        },
      );
      options = [{ label }];
    }

    const content = isLoading ? (
      <Spinner small />
    ) : (
      <Select
        id={this.getId()}
        onValueAdd={this.onSelectItem}
        onValueDelete={this.onRemoveItem}
        values={value ? [{ id: value, label: value }] : []}
        placeholder={field.placeholder || field.label}
        disabled={this.isReadOnly()}
        options={options}
      />
    );

    const renderedLabel = this.renderLabel('col-xs-4');
    const classes = {
      InputField__input: true,
      FormSelect__input: true,
      'col-xs-8': !!renderedLabel,
      'col-xs-12': !renderedLabel,
    };

    return (
      <div className={classnames(this.getClasses({ 'FormSelect row': true }))}>
        {renderedLabel}
        <div className={classnames(classes)}>
          {content}
          {this.renderPlugins()}
        </div>
      </div>
    );
  }
}

export default connect(mapStateToProps)(SupplierIDRBA);

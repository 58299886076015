import { useCallback } from 'react';

import {
  MFE_TRACK_EVENT,
  TrackingActionType,
  useSubscribe,
} from '@alkem/micro-frontend-tools';

import { track } from 'utils/tracking';

export function TrackerListener() {
  useSubscribe(
    MFE_TRACK_EVENT,
    useCallback((action: TrackingActionType) => {
      track(action);
    }, []),
  );
  return null;
}

import formatMultipleSearch from 'utils/formatMultipleSearch';

export const isEAN = (str) => /^\s*[0-9]+\s*(,\s*[0-9]+\s*)*$/.test(str);

export const constructFilters = (filters) => {
  const filterArgs = {};
  Object.keys(filters).forEach((key) => {
    if (Array.isArray(filters[key])) {
      filterArgs[key] = filters[key].map((filter) => filter.value).join(',');
    } else if (filters[key]) {
      if (Array.isArray(filters[key].value)) {
        filterArgs[key] = filters[key].value.join(',');
      } else {
        filterArgs[key] = filters[key].value;
      }
    }
  });
  return filterArgs;
};

export const constructQueries = (search) => {
  const queries = {};
  const trimmedSearch = formatMultipleSearch(search);
  if (isEAN(trimmedSearch)) {
    // Check if search is int => search by EAN
    queries.gtinsIn = trimmedSearch;
  } else {
    // Search by name
    queries.queryName = search;
  }
  return queries;
};

export const calculateOffset = (pagination) =>
  Math.max((pagination.currentPage - 1) * pagination.limit, 0);

import { resetSelectedFilters } from 'core/modules/list/reducers/filters';

import { getId } from '../selectors/referential';

export const fetchColumnReferentials = (state, { payload = {} } = {}) => {
  const { filtersQueryMap, initial } = payload;
  let newState = state;
  if (initial && filtersQueryMap && Object.keys(filtersQueryMap).length) {
    /*
    fetchColumnReferentials action is triggering initial fetchList action
    if we rederecting to catalog with some query params to filter the list,
    we want to reset current filters and search state (if there are some)
    to display the requested filtered list without extra filters/search
    */
    if (!state.get('selectedFilterList').isEmpty()) {
      newState = resetSelectedFilters(newState);
    }
    if (state.get('search')) {
      newState = newState.set('search', '');
    }
  }
  return newState.set('isFetchingReferentials', true);
};

export const cancelFetchColumnReferentials = (state) =>
  state.set('isFetchingReferentials', false);

export const receiveColumnReferentials = (state, { payload }) =>
  state.withMutations((newState) => {
    const { filteredReferentials, userPreferredColumns } = payload;
    newState
      .set('referentials', filteredReferentials)
      .set('isFetchingReferentials', false)
      // Select only the column prefered by the user
      .update('columnMap', (columnMap) =>
        columnMap.withMutations((col) => {
          filteredReferentials.forEach((ref) => {
            if (!col.has(getId(ref))) {
              col.set(
                getId(ref),
                !userPreferredColumns ||
                  userPreferredColumns.indexOf(getId(ref)) > -1,
              );
            }
          });
        }),
      );
  });

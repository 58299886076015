import { MouseEvent, useState } from 'react';

import Modal from 'components/ui/modal';
import { RuleApplicationStatus } from 'modules/validation/constants';

import { AdminRuleResultRow } from './admin-rule-result-row';
import { ValidationResult } from './types';
import { getStatusDisplayText } from './utils';

type Action = (e?: MouseEvent) => void;

export const AdminRuleResultsModal = ({
  ruleResults,
  onClose,
  onConfirm,
}: {
  ruleResults: Array<ValidationResult>;
  onClose: Action;
  onConfirm: Action;
}) => {
  const [selectedFilter, setSelectedFilter] = useState<number | null>(null);

  const handleStatusFilterSelect = (status: number | null) => {
    setSelectedFilter(status);
  };

  const renderRuleStatusFilters = () => (
    <div
      className="AdminRuleCheck__filterContainer"
      data-testid="admin-rule-check-modal-filters"
    >
      <div className="AdminRuleCheck__filterButtonsContainer">
        Filter By Status:
        {[
          RuleApplicationStatus.KO,
          RuleApplicationStatus.OK,
          RuleApplicationStatus.BYPASSED,
          RuleApplicationStatus.NOT_EXECUTED,
          RuleApplicationStatus.ERROR,
        ].map((status) => (
          <button
            key={`admin-rule-check-filter-button-${status}`}
            className="AdminRuleCheck__filterButton btn btn-secondary"
            onClick={() => handleStatusFilterSelect(status)}
          >
            {getStatusDisplayText(status)}
          </button>
        ))}
        <button
          className="AdminRuleCheck__filterButton btn btn-secondary"
          onClick={() => handleStatusFilterSelect(null)}
        >
          All
        </button>
      </div>
      <div className="AdminRuleCheck__selectedFilter">
        Showing:{' '}
        {selectedFilter !== null ? getStatusDisplayText(selectedFilter) : 'ALL'}
      </div>
    </div>
  );

  const renderRulesResults = () =>
    ruleResults
      .filter(
        (ruleResult) =>
          selectedFilter === null || ruleResult.status === selectedFilter,
      )
      .map((ruleResult) => (
        <AdminRuleResultRow
          key={`AdminRuleResultRow_${ruleResult.id}`}
          ruleResult={ruleResult}
        />
      ));

  return (
    <Modal
      modalStyle="dynamic"
      hideCloseButton
      className="AdminRuleCheckModal"
      title="Rules"
      onClose={onClose}
      onConfirm={onConfirm}
    >
      <div data-testid="admin-rule-check-modal-results">
        {renderRuleStatusFilters()}
        {renderRulesResults()}
      </div>
    </Modal>
  );
};

import classNames from 'classnames';
import PropTypes from 'prop-types';
import { Component } from 'react';

import HelpTooltip from '@alkem/react-ui-helptooltip';

import Tag from './tag';
import './tags.scss';

class TagsInput extends Component {
  static demoProps = [
    {
      id: 'tags1',
      matchTag: (val) => val.match(/([A-z0-9]+)@([A-z0-9]+)\.[a-z]+/),
      onChange: (tags) => alert(tags), // eslint-disable-line no-alert
      label: 'Tags',
      tags: ['tag 1', 'tag 2'],
      helpMessage: 'Help !',
      matchOnWhitespace: true,
    },
    {
      id: 'tags2',
      matchTag: (val) => val.match(/([A-z0-9]+)@([A-z0-9]+)\.[a-z]+/),
      onChange: (tags) => alert(tags), // eslint-disable-line no-alert
      tags: ['tag 1', 'tag 2'],
      label: 'Tags',
      helpMessage: 'Help !',
      matchOnWhitespace: true,
      disabled: true,
    },
  ];

  static propTypes = {
    id: PropTypes.string.isRequired,
    matchTag: PropTypes.func.isRequired,
    onChange: PropTypes.func.isRequired,
    label: PropTypes.string,
    tags: PropTypes.array,
    helpMessage: PropTypes.string,
    matchOnWhitespace: PropTypes.bool,
    disabled: PropTypes.bool,
  };

  static defaultProps = {
    label: '',
    matchOnWhitespace: true,
    tags: [],
    helpMessage: null,
    disabled: false,
  };

  constructor(props) {
    super(props);

    this.state = {
      currentTag: '',
    };

    this.handleChange = this.handleChange.bind(this);
    this.onDeleteTag = this.onDeleteTag.bind(this);
    this.handleKeyEvent = this.handleKeyEvent.bind(this);
  }

  shouldComponentUpdate() {
    return true;
  }

  onDeleteTag(label) {
    const { onChange } = this.props;
    const tags = this.props.tags.filter((tag) => tag !== label);
    onChange(tags);
  }

  handleChange(event) {
    const currentTag = event.target.value;
    this.setState({ currentTag });
  }

  handleKeyEvent(event) {
    const { matchOnWhitespace } = this.props;

    if (event.key === 'Enter') {
      this.findMatchingTag();

      event.preventDefault();
    } else if (
      matchOnWhitespace &&
      (event.type === 'paste' || [' ', ','].indexOf(event.key) !== -1)
    ) {
      setTimeout(() => {
        this.findMatchingTag();
      }, 100);
    }
  }

  findMatchingTag() {
    const { currentTag } = this.state;
    const { tags, onChange, matchTag, matchOnWhitespace } = this.props;

    if (matchOnWhitespace) {
      let currentTags = currentTag.replace(/,/g, ' ').split(' ');

      currentTags = currentTags.filter((tag) => tag.length > 0);
      const remainingTags = currentTags.filter((tag) => {
        if (matchTag(tag) && !this.isDuplicate(currentTag)) {
          tags.push(tag);
          return false;
        }
        return true;
      });

      if (remainingTags.length !== currentTags.length) {
        onChange(tags);
        this.setState({ currentTag: remainingTags.join(' ') });
      }
    } else if (matchTag(currentTag) && !this.isDuplicate(currentTag.trim())) {
      tags.push(currentTag.trim());
      onChange(tags);
      this.setState({ currentTag: ' ' });
    }
  }

  isDuplicate(currentTag) {
    return this.props.tags.includes(currentTag);
  }

  renderLabel() {
    const { label, id, helpMessage } = this.props;
    const htmlId = `Tags_${id}`;
    if (!label) {
      return null;
    }
    return (
      <label htmlFor={htmlId} className="col-md-4 InputField__label">
        <span className="InputTag__label">{label}</span>
        {helpMessage ? (
          <span className="InputTag__help">
            <HelpTooltip id={htmlId} message={helpMessage} />
          </span>
        ) : null}
      </label>
    );
  }

  render() {
    const { label, tags, disabled } = this.props;
    const { currentTag } = this.state;

    const input = !disabled ? (
      <input
        className="form-control InputText__input"
        type="text"
        value={currentTag}
        onChange={this.handleChange}
        onPaste={this.handleKeyEvent}
        onKeyUp={this.handleKeyEvent}
      />
    ) : null;

    const formattedTags = tags.map((tag) => (
      <Tag
        key={tag}
        label={tag}
        onDelete={this.onDeleteTag}
        readOnly={disabled}
      />
    ));

    const style = {
      InputField__input: true,
      'col-xs-8': !!label,
      'col-xs-12': !label,
    };

    return (
      <div>
        <div className="InputField InputTags row">
          {this.renderLabel()}
          <span className={classNames(style)}>
            {formattedTags}
            {input}
          </span>
        </div>
      </div>
    );
  }
}

export default TagsInput;

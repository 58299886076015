import { Map } from 'immutable';

import {
  OrganizationRecipient,
  OrganizationSettingSpecificExport,
  OrganizationSettingSpecificExportType,
} from '@alkem/sdk-dashboard';

import { get } from 'utils/immutable';

export const isSpecificExportByType = (
  recipient: OrganizationRecipient | Map<any, any>,
  type: OrganizationSettingSpecificExportType | string,
) => {
  const specificExports: OrganizationSettingSpecificExport[] =
    get(recipient, ['settings', 'specific_exports']) || [];
  return specificExports.some((exp) => get(exp, ['type']) === type);
};

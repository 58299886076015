import Immutable from 'immutable';

import { hasFeature } from 'modules/feature-flag';
import { FEATURE_SHARING_STATUS_FILTER } from 'modules/feature-flag/constants';
import { UserImmutable } from 'types';
import { i18n } from 'utils/i18n';

export const recipientFilter = {
  key: 'recipients_is_for',
  customField: 'recipients_is_for',
  query: 'recipient',
  filterLabel: i18n.t('frontproductstream.core.list_filter_recipient.label', {
    defaultValue: 'Recipient',
  }),
  isAvailable: ({ user }: { user?: UserImmutable } = {}) =>
    hasFeature(user, FEATURE_SHARING_STATUS_FILTER),
  hasRecipient: (selectedFilterMap?: Immutable.Map<string, any>) =>
    !!selectedFilterMap?.get(recipientFilter.key)?.first(),
};

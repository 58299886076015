import PropTypes from 'prop-types';
import { PureComponent } from 'react';

import { getId } from 'components/ui/form/field/utils/clean';
import InputWithLabel from 'components/ui/input/input-with-label';
import InputText from 'components/ui/input/text';
import i18n from 'utils/i18n';

import './currency.scss';

export class PriceWaterfallsCurrency extends PureComponent {
  static propTypes = {
    model: PropTypes.string.isRequired,
    entityId: PropTypes.number,
    entityType: PropTypes.string,
    value: PropTypes.string,
  };

  onUpdate = () => null;

  render() {
    const { model, entityId, entityType, value } = this.props;
    return (
      <div className="PriceWaterfallsCurrency">
        <InputWithLabel
          childId={getId(model, entityType, entityId)}
          label={i18n.t('Currency')}
        >
          <InputText
            id={getId(model, entityType, entityId)}
            value={value}
            onChange={this.onUpdate}
            disabled
          />
        </InputWithLabel>
      </div>
    );
  }
}

export default PriceWaterfallsCurrency;

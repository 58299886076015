import classNames from 'classnames';
import { noop } from 'lodash';
import { flatten, flow, get, reduce, set } from 'lodash/fp';
import memoize from 'memoize-one';
import { PureComponent } from 'react';

import { Tooltip } from '@alkem/react-ui-tooltip';

import Raguel from 'components/ui/form/plugins/validator';
import RaguelStatic from 'components/ui/form/plugins/validator/static';
import InputText from 'components/ui/input/text';
import ProductReference from 'components/ui/product-reference';
import { Body, Cell, Row, Table } from 'components/ui/table';
import {
  TypePackagingLabels,
  getTypePackagingCode,
} from 'constants/typePackaging';
import { getTypePackaging, getTypePackagingId } from 'core/api/productversion';
import {
  DataOpsEditPlugin,
  DataOpsInfoPlugin,
  DataOpsPatchState,
  DataOpsPatchesState,
  bulkPatchDataOpsField,
  dataOpsLuEntityId,
  patchDataOpsField,
  selectDataOpsPatch,
} from 'modules/data-ops';
import { hasPermissionsByEntity } from 'modules/permissions';
import {
  PATCH_PERMISSION,
  PRODUCT_PERMISSION,
} from 'modules/permissions/const';
import i18n from 'utils/i18n';

import { ENTITY_TYPE_LOGISTICAL_HIERARCHY_UNIT } from '../../constants';
import { isConsumerOrDisplayUnit } from '../../helpers';
import {
  BulkUpdate,
  CurrentVersionInfo,
  Data,
  DataMap,
  HierarchyMap,
  VersionData,
} from '../../structures';
import LogisticalHierarchyIcon from '../icon';

import { LogisticalHierarchyFieldValue } from './field-value';
import './grouped-fields.scss';
import { LogisticalHierarchyUnitField } from './unit-field';
import { DeepUnit, ObjectPath, multiply, sum } from './utils';

export interface LogisticalHierarchyGroupedFieldsProps {
  rootInternalId: string;
  path: string;
  dataMap: DataMap;
  hierarchyMap: HierarchyMap;
  currentVersionInfo: CurrentVersionInfo;
  targetMarketId: number;
  logisticalUnitsAsTree: boolean;
  readOnly: boolean;
  onBulkUpdate: any;
  patches: DataOpsPatchesState;
  hasDataOps: boolean;
  onPatch: typeof patchDataOpsField;
  onBulkPatch: typeof bulkPatchDataOpsField;
  isPatchedHierarchy?: boolean;
  isDataOpsPatcher?: boolean;
  isDataOpsReceiver?: boolean;
}

interface Props extends LogisticalHierarchyGroupedFieldsProps {
  // The label displayed as the section header.
  label: string;
  // The list of paths corresponding to values that can be updated for logistical units.
  valuePaths: ObjectPath[];
  // The label displayed along side the unit dropdown.
  unitLabel: string;
  // The referential used to set the unit for all logistical units.
  unitReferentialUri: string;
  // The function used to seed the field default data if missing.
  getSeedDataUpdate: (unit: DeepUnit) => BulkUpdate | null;
  // The function used to get the base computed value (volume, weigth) value (in the default unit).
  getBaseComputedValue: (versionData: object, paths: ObjectPath[]) => number;
  // Column names for the table, excluding the Unit one which is added
  columnNames: string[];
  // Render the computed values used to consistency checks.
  // Compute a potential warning message from the unit.
  warningMessage: string;
  getBestValue: (v: number) => { value: number; unitCode: any };
  // Raguel entityKind to group errors for this field.
  errorEntityKind: string;
  // Raguel model used to controll when to revalidate data.
  errorModel: string;
  model: string;
}

interface State {
  showErrorBlock: boolean;
  areErrorsNotRequested: boolean;
}

export class LogisticalHierarchyGroupedFields extends PureComponent<
  Props,
  State
> {
  state = {
    showErrorBlock: false,
    areErrorsNotRequested: false,
  };

  public componentDidMount() {
    this.onSeedData();
  }

  public componentDidUpdate() {
    this.onSeedData();
  }

  private onSeedData() {
    const {
      getSeedDataUpdate,
      rootInternalId,
      path,
      dataMap,
      valuePaths,
      getBaseComputedValue,
      patches,
    } = this.props;
    const updates = [] as BulkUpdate[];
    this.getFlattenedUnits(
      rootInternalId,
      path,
      dataMap,
      valuePaths,
      getBaseComputedValue,
      patches,
    )
      .filter((unit) => !unit.isReadOnly)
      .forEach((unit) => {
        const update = getSeedDataUpdate(unit);
        if (update) {
          updates.push(update);
        }
      });
    if (updates.length) {
      this.props.onBulkUpdate(updates, false);
    }
  }

  private onJudgmentDay = ({
    error,
    notRequested,
  }: {
    error: boolean;
    notRequested: boolean;
  }) => {
    this.setState({
      showErrorBlock: error,
      areErrorsNotRequested: notRequested,
    });
  };

  private onSetUnit = (event: any) => {
    const {
      rootInternalId,
      path,
      dataMap,
      valuePaths,
      getBaseComputedValue,
      hasDataOps,
      onBulkPatch,
      patches,
      isPatchedHierarchy,
      isDataOpsPatcher,
    } = this.props;
    // Update them all!
    const updates: BulkUpdate[] = [];
    const patchUpdates: [string, any, number | string, string][] = [];
    const value = event.value || null;
    const isPatchable = isDataOpsPatcher && hasDataOps && !isPatchedHierarchy;
    this.getFlattenedUnits(
      rootInternalId,
      path,
      dataMap,
      valuePaths,
      getBaseComputedValue,
      patches,
    )
      .filter((unit) =>
        isPatchable
          ? !isConsumerOrDisplayUnit(unit.versionData)
          : !unit.isReadOnly,
      )
      .forEach((unit) => {
        if (isPatchable) {
          const fieldName = valuePaths[0][0] as string;
          const entityId = dataOpsLuEntityId(
            rootInternalId,
            unit.reference as string,
          );
          const patch = selectDataOpsPatch([entityId, fieldName], patches);
          const patchData = patch?.data?.[0]?.dataNew;
          const entity =
            patchData !== undefined
              ? { [fieldName]: patchData }
              : unit.versionData;

          patchUpdates.push(
            flow<
              [typeof valuePaths],
              typeof entity,
              any,
              [string, any, number | string, string]
            >(
              reduce(
                (acc, localPath: ObjectPath) =>
                  set([...localPath, 0, 'expressedIn'], value, acc),
                entity,
              ),
              get([fieldName]),
              (data: any) => [
                fieldName,
                data,
                entityId,
                ENTITY_TYPE_LOGISTICAL_HIERARCHY_UNIT,
              ],
            )(valuePaths),
          );

          if (patchUpdates.length) {
            onBulkPatch(patchUpdates);
          }
        } else {
          for (const p of valuePaths) {
            updates.push({
              internalId: unit.id,
              path: [...p, 0, 'expressedIn'],
              value,
            });
          }

          if (updates.length) {
            this.props.onBulkUpdate(updates);
          }
        }
      });
  };

  private onUpdateValue = (
    internalId: string,
    path: ObjectPath,
    value: any,
  ) => {
    this.props.onBulkUpdate([
      {
        internalId,
        path: [...path, 0, 'data'],
        value,
      },
    ]);
  };

  private getVersionData(
    data: Data,
    patches: DataOpsPatchesState,
  ): VersionData {
    const {
      currentVersionInfo,
      hasDataOps,
      valuePaths,
      rootInternalId,
      isDataOpsPatcher,
    } = this.props;
    let versionData = data.version;

    if (
      (currentVersionInfo.reference &&
        data.reference === currentVersionInfo.reference) ||
      currentVersionInfo.textileVariantGTINs?.includes(data.gtin)
    ) {
      versionData = {
        ...versionData,
        ...currentVersionInfo,
        isCurrentVersion: true,
      };
    }

    if (isDataOpsPatcher && hasDataOps) {
      const fieldName = valuePaths[0][0] as string;
      const entityId =
        data.gtin === currentVersionInfo.gtin && data.version.id
          ? data.version.id
          : dataOpsLuEntityId(rootInternalId, data.gtin as string);
      const patch = selectDataOpsPatch([entityId, fieldName], patches);
      const patchData = patch?.data?.[0]?.dataNew;
      if (patchData !== undefined) {
        versionData = set([fieldName], patchData, versionData);
      }
    }

    return versionData;
  }

  private static getLineLabel(
    reference: Data['reference'],
    versionData: VersionData,
  ) {
    return (
      <div className="LogisticalHierarchyGroupedFields__lineLabel">
        <LogisticalHierarchyIcon
          packagingTypeId={getTypePackagingId(versionData)}
          small
        />
        <ProductReference reference={reference} inline />
      </div>
    );
  }

  private flattenUnit(unit: DeepUnit): DeepUnit[] {
    return [
      unit,
      ...flatten((unit.children || []).map((u) => this.flattenUnit(u))),
    ];
  }

  private getFlattenedUnits = memoize(
    (
      rootInternalId,
      path,
      dataMap,
      valuePaths,
      getBaseComputedValue,
      patches: DataOpsPatchesState,
    ): DeepUnit[] => {
      const rootReference = dataMap[rootInternalId].reference as string;
      const rootVersionData = this.getVersionData(
        dataMap[rootInternalId],
        patches,
      );
      const unit = this.computeUnit(
        {
          id: rootInternalId,
          path,
          reference: rootReference,
          versionData: rootVersionData,
          computedValue: getBaseComputedValue(rootVersionData, valuePaths),
          computedChildrenValue: null,
          isReadOnly: this.isReadOnly(rootReference, rootVersionData),
          children: null,
        },
        patches,
      );
      return this.flattenUnit(unit);
    },
  );

  private computeUnit(unit: DeepUnit, patches: DataOpsPatchesState): DeepUnit {
    const { dataMap, hierarchyMap, valuePaths, getBaseComputedValue } =
      this.props;

    const localUnit = unit;
    const childrenComputedValues = [] as number[];
    const children = hierarchyMap[localUnit.id] || [];
    localUnit.children = children.map((child, index) => {
      const versionData = this.getVersionData(dataMap[child.id], patches);
      const computedValue = getBaseComputedValue(versionData, valuePaths);
      childrenComputedValues.push(multiply(child.quantity, computedValue));

      return this.computeUnit(
        {
          id: child.id,
          path: `${localUnit.path}-children-${index}`,
          reference: dataMap[child.id].reference,
          versionData,
          computedValue,
          computedChildrenValue: null,
          isReadOnly: this.isReadOnly(dataMap[child.id].reference, versionData),
          children: null,
        },
        patches,
      );
    });
    if (childrenComputedValues.length > 0) {
      localUnit.computedChildrenValue = sum(...childrenComputedValues);
    }

    return localUnit;
  }

  private isReadOnly(reference: Data['reference'], versionData: VersionData) {
    const { currentVersionInfo, readOnly } = this.props;
    return (
      readOnly ||
      isConsumerOrDisplayUnit(versionData) ||
      currentVersionInfo.gtin === reference ||
      currentVersionInfo.productIdentifier === reference ||
      currentVersionInfo.textileVariantGTINs?.includes(reference)
    );
  }

  private renderWarningMessage = (unit: DeepUnit, hierarchyMap: any) => {
    const { warningMessage, getBestValue, errorModel } = this.props;
    if (
      !warningMessage ||
      !getBestValue ||
      !unit.children ||
      !unit.computedChildrenValue ||
      unit.computedValue >= unit.computedChildrenValue
    ) {
      return null;
    }

    // Display as many digits as possible here (round with lots of decimals and remove trailing zeroes).
    const round = (num: number): string =>
      num.toFixed(8).replace(/(?:\.)?0*$/, '');

    const allValues = [] as number[];
    const rows = unit.children.map((child) => {
      const link = hierarchyMap[unit.id].find((l) => l.id === child.id);
      const qtty = link ? link.quantity : 0;
      const { value, unitCode } = getBestValue(child.computedValue);
      const { value: computedValue, unitCode: computedUnitCode } = getBestValue(
        multiply(child.computedValue, qtty),
      );
      allValues.push(multiply(child.computedValue, qtty));
      return (
        <Row key={child.id}>
          <Cell>
            {child.reference}
            {i18n.t(
              'frontproductstream.logistical_hierarchies_grouped_fields.table.text',
              { defaultValue: ': ' },
            )}
          </Cell>
          <Cell>
            {round(value)} {unitCode}
          </Cell>
          <Cell>&times;</Cell>
          <Cell>{qtty}</Cell>
          <Cell>=</Cell>
          <Cell className="alk-text-align--right">
            {round(computedValue)} {computedUnitCode}
          </Cell>
        </Row>
      );
    });
    const { value: total, unitCode: totalUnitCode } = getBestValue(
      sum(...allValues),
    );
    rows.push(
      <Row key="total">
        <Cell colSpan={5} className="alk-text-align--right">
          {i18n.t(
            'frontproductstream.logistical_hierarchies_grouped_fields.table_total.text',
            { defaultValue: 'Total:' },
          )}
        </Cell>
        <Cell>
          {round(total)} {totalUnitCode}
        </Cell>
      </Row>,
    );

    return (
      <div className="LogisticalHierarchyGroupedFields__warning">
        <div className="row">
          <RaguelStatic
            className="offset-xs-4"
            message={
              <span>
                {warningMessage}
                <i
                  className="mdi mdi-help-circle"
                  data-for={`${errorModel}-computation-tooltip`}
                  data-tip
                />
                <Tooltip
                  id={`${errorModel}-computation-tooltip`}
                  place="left"
                  hoverable
                >
                  <Table>
                    <Body>{rows}</Body>
                  </Table>
                </Tooltip>
              </span>
            }
            warning
          />
        </div>
      </div>
    );
  };

  private renderComputedValue(
    path: string,
    idSuffix: string,
    baseValue: number | null,
    getBestValue: (value: number) => { value: number; unitCode: string },
  ) {
    if (baseValue === null) {
      // This means that there are no children, so nothing to display.
      return null;
    }
    const { value, unitCode } = getBestValue(baseValue);
    if (!value) {
      // No value could be computed, likely because of missing data.
      return (
        <div className="LogisticalHierarchyGroupedFields__value" key={idSuffix}>
          {i18n.t(
            'frontproductstream.logistical_hierarchies_grouped_fields.no_value.text',
            { defaultValue: 'N/A' },
          )}
        </div>
      );
    }

    return (
      <div className="LogisticalHierarchyGroupedFields__value" key={idSuffix}>
        <InputText
          id={`${path}-dimensions-${idSuffix}`}
          value={value.toFixed(2)}
          onChange={noop}
          disabled
        />
        <span className="LogisticalHierarchyGroupedFields__unitCode">
          {unitCode}
        </span>
      </div>
    );
  }

  private renderComputedValues = (unit: DeepUnit) => {
    const { valuePaths, errorModel, getBestValue } = this.props;
    const computedValues = [] as any[];
    if (valuePaths.length > 1) {
      computedValues.push(
        this.renderComputedValue(
          unit.path,
          `grouped-${errorModel}`,
          unit.computedValue,
          getBestValue,
        ),
      );
    }

    computedValues.push(
      this.renderComputedValue(
        unit.path,
        `grouped-${errorModel}-children`,
        unit.computedChildrenValue,
        getBestValue,
      ),
    );

    return computedValues;
  };

  private renderLine = (unit: DeepUnit) => {
    const {
      valuePaths,
      hierarchyMap,
      rootInternalId,
      hasDataOps,
      patches,
      onPatch,
      isPatchedHierarchy,
      isDataOpsPatcher,
      label,
    } = this.props;
    const warningMessage = this.renderWarningMessage(unit, hierarchyMap);
    const fieldName = valuePaths[0][0] as string;
    const canPatch: boolean =
      hasPermissionsByEntity({
        entity: unit.versionData,
        entityType: PRODUCT_PERMISSION,
        permissions: [PATCH_PERMISSION],
      }) && !isConsumerOrDisplayUnit(unit.versionData);
    const entityId: string | number = unit.versionData.isCurrentVersion
      ? (unit.versionData.id as number)
      : dataOpsLuEntityId(rootInternalId, unit.reference as string);
    let patch: DataOpsPatchState | undefined;

    const withDataOps: boolean = hasDataOps && !!unit.reference;

    if (withDataOps) {
      patch = selectDataOpsPatch([entityId, fieldName], patches);
    }

    const isEditable = !!patch?.editable;
    const isPatched = patch?.data !== undefined;
    const computedLabel = isDataOpsPatcher
      ? `${
          TypePackagingLabels[
            getTypePackagingCode(getTypePackaging(unit.versionData))
          ].label
        } - ${unit.reference} - ${label}`
      : '';
    return (
      <div
        className={classNames('FormField', {
          'FormField--raguelWarning': !!warningMessage,
          'FormField--editable': isEditable,
          'FormField--patched': isPatched,
        })}
        key={unit.id}
      >
        <div className="LogisticalHierarchyGroupedFields__line">
          {LogisticalHierarchyGroupedFields.getLineLabel(
            unit.reference,
            unit.versionData,
          )}
          {valuePaths.map((path) => (
            <LogisticalHierarchyFieldValue
              key={path.join('-')}
              unit={unit}
              path={path}
              onUpdate={this.onUpdateValue}
              onPatch={onPatch}
              patch={isDataOpsPatcher ? patch : null}
              entityId={entityId}
              label={computedLabel}
            />
          ))}
          {this.renderComputedValues(unit)}
        </div>
        {warningMessage}
        {this.renderPlugins({
          unit,
          withDataOps,
          canPatch,
          fieldName,
          patch,
          entityId,
          isEditable,
          isPatched,
          isPatchedHierarchy,
        })}
      </div>
    );
  };

  private renderPlugins({
    unit,
    withDataOps,
    canPatch,
    fieldName,
    patch,
    entityId,
    isEditable,
    isPatched,
    isPatchedHierarchy,
  }: {
    unit: DeepUnit;
    withDataOps: boolean;
    canPatch: boolean;
    fieldName: string;
    patch?: DataOpsPatchState;
    entityId: string | number;
    isEditable: boolean;
    isPatched: boolean;
    isPatchedHierarchy?: boolean;
  }) {
    if (!withDataOps) {
      return null;
    }
    return (
      <div
        className={classNames('FormField__plugins', 'FormField__plugins--1')}
        data-dataops={withDataOps}
        data-unit-id={unit.id}
        data-unit-reference={unit.reference}
        data-is-patched={isPatched}
        data-can-patch={canPatch}
      >
        {canPatch && !isPatchedHierarchy && (
          <div className="alk-flex alk-flex-center FormField__actions">
            <DataOpsEditPlugin
              field={{ model: fieldName }}
              entityId={entityId}
              entityKind={ENTITY_TYPE_LOGISTICAL_HIERARCHY_UNIT}
              isEditable={isEditable}
            />
          </div>
        )}
        {isPatched && patch?.data && (
          <div className="alk-flex alk-flex-justify-center">
            <div>
              <DataOpsInfoPlugin
                patches={patch.data}
                canPatch={canPatch}
                label={this.props.label}
              />
            </div>
          </div>
        )}
      </div>
    );
  }

  private renderErrorBlock() {
    const {
      rootInternalId,
      logisticalUnitsAsTree,
      label,
      errorEntityKind,
      errorModel,
    } = this.props;
    return (
      <div
        className={classNames('Raguel__block', {
          'FormField--raguelError': this.state.showErrorBlock,
          'FormField--notRequested': this.state.areErrorsNotRequested,
        })}
      >
        <Raguel
          entityId={rootInternalId}
          entityKind={errorEntityKind}
          label={label}
          model={errorModel}
          value={logisticalUnitsAsTree}
          onJudgmentDay={this.onJudgmentDay}
        />
      </div>
    );
  }

  private renderHeader() {
    const { columnNames } = this.props;
    return (
      <div className="LogisticalHierarchyGroupedFields__header LogisticalHierarchyGroupedFields__line">
        <span className="LogisticalHierarchyGroupedFields__lineLabel">
          {i18n.t(
            'frontproductstream.logistical_hierarchies_grouped_fields.unit.text',
            { defaultValue: 'Unit' },
          )}
        </span>
        {columnNames.map((n) => (
          <span key={n}>{n}</span>
        ))}
      </div>
    );
  }

  public render() {
    const {
      dataMap,
      getBaseComputedValue,
      hasDataOps,
      label,
      patches,
      path,
      rootInternalId,
      targetMarketId,
      unitLabel,
      unitReferentialUri,
      valuePaths,
      isPatchedHierarchy,
      model,
      isDataOpsPatcher,
      isDataOpsReceiver,
    } = this.props;
    const flattenedUnits = this.getFlattenedUnits(
      rootInternalId,
      path,
      dataMap,
      valuePaths,
      getBaseComputedValue,
      patches,
    );
    return (
      <div
        className={classNames(
          'LogisticalHierarchyGroupedFields',
          'form-group',
          isDataOpsPatcher && hasDataOps && 'form-group--plugins1',
        )}
      >
        <h2>{label}</h2>
        <LogisticalHierarchyUnitField
          flattenedUnits={flattenedUnits}
          hasDataOps={hasDataOps}
          isPatchedHierarchy={isPatchedHierarchy}
          onChange={this.onSetUnit}
          targetMarketId={targetMarketId}
          unitLabel={unitLabel}
          unitReferentialUri={unitReferentialUri}
          valuePaths={valuePaths}
          model={model}
          isDataOpsPatcher={isDataOpsPatcher}
          isDataOpsReceiver={isDataOpsReceiver}
        />
        {this.renderHeader()}
        {flattenedUnits.map(this.renderLine)}
        {this.renderErrorBlock()}
      </div>
    );
  }
}

import { List, fromJS } from 'immutable';

import { getFromStatusId } from 'constants/physicalCheckerStatus';

import { PHYSICAL_CHECK_STAGE_GATE_ID } from './constants';

export function getNextStatuses(stateMachine, productVersions) {
  const currentStatuses = productVersions
    .map((pv) =>
      pv.getIn(['alkemics', 'contribution', 'physicalChecker', 'status']),
    )
    .toSet()
    .toList();

  // if there is different statuses in product version selected we want do to nothing
  if (currentStatuses.size > 1) {
    return null;
  }
  if (!currentStatuses.get(0)) {
    return [];
  }
  // if status found is undefined
  const nextStatuses = stateMachine.get(String(currentStatuses.get(0)));
  if (!nextStatuses) {
    return null;
  }
  return nextStatuses.map((nextStatus) => getFromStatusId(nextStatus));
}

export function isSelectionEligibleForPhysicalCheck(productVersions) {
  const eligibleProductsForPhysicalCheck = productVersions.filter((pv) => {
    const sgs = pv.getIn(['alkemics', 'stage_gates', 'all']) || fromJS([]);
    return sgs.some(
      (sg) =>
        sg.get('id') === PHYSICAL_CHECK_STAGE_GATE_ID && sg.get('validated'),
    );
  });

  return eligibleProductsForPhysicalCheck.size === productVersions.size;
}

export const isEmptyListToListContainingEmptyList = (diff) =>
  diff.get('old') instanceof List &&
  diff.get('old').size === 0 &&
  diff.get('new') instanceof List &&
  diff.get('new').size === 1 &&
  diff.get('new').get(0) instanceof List &&
  diff.get('new').get(0).size === 0;

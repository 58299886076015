import { createSelector } from '@reduxjs/toolkit';

import { ReferentialState } from './types';

export const selectModuleState = (state) => state.assets as ReferentialState;

export const selectReferentials = createSelector(
  selectModuleState,
  (state) => state.referentials || {},
);

import { useMemo } from 'react';

import { useDocumentForm } from 'components/ui/form/asset-list/AssetV2/hooks/useDocumentForm';
import {
  DocumentAssetV2,
  EnhancedAssetV2,
} from 'components/ui/form/asset-list/AssetV2/types';
import { useAssetContext } from 'components/ui/form/asset-list/context';
import FormGroup from 'components/ui/form/form-group';
import { ENTITY_TYPE_PRODUCT_DOCUMENT } from 'constants/entities';

type AssetDocumentJsonInfoInputsProps = {
  asset: EnhancedAssetV2<DocumentAssetV2>;
  formMethods: ReturnType<typeof useDocumentForm>['formMethods'];
};

const UNUSED_ENTITY_INDEX = -1;

export const AssetDocumentJsonInfoInputs = ({
  asset,
  formMethods,
}: AssetDocumentJsonInfoInputsProps) => {
  const { formGroup, entityId } = useAssetContext();
  const handleChange = (name, value) => {
    formMethods.setValue(name, value, { shouldDirty: true });
  };

  const cleanFields = useMemo(() => {
    return formGroup.actions.find((action) => action.render === 'addDocument')
      ?.items;
  }, [formGroup.actions]);

  const allFormInputs = formMethods.watch();

  const parentFormGroup = useMemo(
    () => ({
      kind: 'DisplayGroup',
      label: 'Document',
      items: cleanFields,
      id: `documentForm-${asset.product_key_id}`,
    }),
    [asset.product_key_id, cleanFields],
  );

  if (!cleanFields) return null;

  return (
    <FormGroup
      entity={allFormInputs}
      entityId={entityId}
      entityKind={ENTITY_TYPE_PRODUCT_DOCUMENT}
      formGroup={parentFormGroup}
      deepIndex={2}
      showTitle={false}
      onChangeField={handleChange}
      noDispatch={true}
      entityIndex={UNUSED_ENTITY_INDEX}
      disableDataOps
    />
  );
};

import asyncComponent from 'core/modules/async/component';
import registerModule from 'core/modules/async/register';

const usersAdministrationModule = async () =>
  registerModule(await import(/* webpackChunkName: "admin-users" */ './async'));

export const UserListView = asyncComponent(usersAdministrationModule, {
  componentPath: 'UserListView',
});

export const UserProfileView = asyncComponent(usersAdministrationModule, {
  componentPath: 'UserProfileView',
});

export const UserOwnProfileView = asyncComponent(usersAdministrationModule, {
  componentPath: 'UserOwnProfileView',
});

export const UserSupportView = asyncComponent(usersAdministrationModule, {
  componentPath: 'UserSupportView',
});

export const UserProfileModal = asyncComponent(usersAdministrationModule, {
  componentPath: 'UserProfileModal',
});

export const UserInvitationModal = asyncComponent(usersAdministrationModule, {
  componentPath: 'UserInvitationModal',
});

export { selectList as selectUsers } from './selectors/user-list';
export { receiveProductSegmentPermissions } from './actions/managed-segments';
export { openInvitationModal } from './actions/invitation';

import { fromJS } from 'immutable';
import lodash, { curry } from 'lodash/fp';

export const isImmutable = (o) => !!o && !!o.getIn;

export const toArray = (path) => {
  if (Array.isArray(path)) {
    return path;
  }
  if (typeof path === 'string') {
    return path.split('.');
  }
  return [path];
};

const toDotted = (path) => {
  if (Array.isArray(path)) {
    return path.join('.');
  }
  return path;
};

export const get = (o, path, defaultValue?) =>
  isImmutable(o)
    ? o.getIn(toArray(path), defaultValue)
    : lodash.getOr(defaultValue, path, o);

export const getFp = curry((path, o) => get(o, path));

export const getOr = (defaultValue, path, o) => get(o, path, defaultValue);

export const getKey = (o, key) =>
  isImmutable(o) ? o.get(key) : lodash.get([key], o);

export const set = (o, path, value) =>
  isImmutable(o) ? o.setIn(toArray(path), value) : lodash.set(path, value, o);

export const setFp = curry((path, value, o) => set(o, path, value));

export const update = (o, path, value) =>
  isImmutable(o)
    ? o.updateIn(toArray(path), value)
    : lodash.update(path, value, o);

export const updateFp = curry((path, value, o) => update(o, path, value));

export const size = (o) => (isImmutable(o) ? o.size : lodash.size(o));

export const toJsIfImmutable = (o) => (o && o.toJS ? o.toJS() : o);
export const fromJsIfNotImmutable = (o) => (isImmutable(o) ? o : fromJS(o));

export const first = (o) => (isImmutable(o) ? o.first() : o[0]);

export const remove = (o, path) =>
  isImmutable(o) ? o.deleteIn(toArray(path)) : lodash.omit(toDotted(path), o);

export const removeFp = curry((path, o) => remove(o, path));

export const merge = (o, value) =>
  isImmutable(o) ? o.merge(value) : lodash.merge(o, value);

export const mergeFp = curry((value, o) => merge(o, value));

export const keys = (o) => (isImmutable(o) ? o.keySeq() : Object.keys(o));

export const values = (o) => (isImmutable(o) ? o.valueSeq() : Object.values(o));

/**
 * Passed in a Map (id => any) it returns a function that can be passed a key of
 * which it will return the value. Useful to map over a list of ids like
 *
 * myIds.map(getByKey(myMap))
 *
 * instead of
 *
 * myMap.filter(o => myIds.includes(o.get('id')))
 *
 */
export const getByKey = (m) => (key) => m.get(key);

export const isEmpty = (o) =>
  isImmutable(o) ? o.isEmpty() : !o || o.length === 0;

export const every = (o, callback) =>
  isImmutable(o) ? o.every(callback) : lodash.every(callback, o);

export const sortBy = (collection, comparator) =>
  isImmutable(collection)
    ? collection.sortBy(comparator)
    : lodash.sortBy(comparator, collection);

export const has = (o, path) =>
  isImmutable(o) ? o.hasIn(toArray(path)) : o.has(toDotted(path));

import { createSelector } from '@reduxjs/toolkit';

import { isRetailer, isThirdParty } from 'core/api/user';
import {
  assignationStatusFilter,
  displayUnitsFilter,
  duplicatedProductsFilter,
  lifecycleFilter,
} from 'core/modules/list/constants/filters';
import { selectUser } from 'modules/user';
import { get } from 'utils/immutable';

import { FILTERS_PANEL } from '../constants';

const selectLocalFiltersConfig = (moduleState) =>
  get(moduleState, 'filtersConfig');

const selectAreLocalFiltersCollapsed = (moduleState) =>
  selectLocalFiltersConfig(moduleState).getIn([FILTERS_PANEL, 'collapsed']);

const selectSelectedFilterList = (moduleState) =>
  get(moduleState, 'selectedFilterList');

const selectSelectedFilterMap = (moduleState) =>
  get(moduleState, 'selectedFilterMap');

const selectAggregations = (moduleState) => get(moduleState, 'aggregations');

const selectFullAggregations = (moduleState) =>
  get(moduleState, 'fullAggregations');

export const createSelectorFiltersConfig = (selectModuleState) =>
  createSelector(selectModuleState, selectLocalFiltersConfig);

export const createSelectorAreFiltersCollapsed = (selectModuleState) =>
  createSelector(selectModuleState, selectAreLocalFiltersCollapsed);

export const createSelectorSelectedFilterList = (selectModuleState) =>
  createSelector(selectModuleState, selectSelectedFilterList);

export const createSelectorSelectedFilterMap = (selectModuleState) =>
  createSelector(selectModuleState, selectSelectedFilterMap);

export const createSelectorAggregations = (selectModuleState) =>
  createSelector(selectModuleState, selectAggregations);

export const createSelectorFullAggregations = (selectModuleState) =>
  createSelector(selectModuleState, selectFullAggregations);

export const createSelectWithArchivedProducts = (selectModuleState) =>
  createSelector(selectUser, selectModuleState, (user, moduleState) => {
    const filtersConfig = selectLocalFiltersConfig(moduleState);
    const withArchived = filtersConfig.getIn([
      lifecycleFilter.key,
      lifecycleFilter.withArchivedKey,
    ]);
    if (typeof withArchived === 'boolean') {
      return withArchived;
    }
    return isRetailer(user);
  });

export const createSelectOnlyDuplicatedProducts = (selectModuleState) =>
  createSelector(selectUser, selectModuleState, (user, moduleState) => {
    const filtersConfig = selectLocalFiltersConfig(moduleState);
    const hasDuplicated = filtersConfig.getIn([
      duplicatedProductsFilter.key,
      duplicatedProductsFilter.hasDuplicatedKey,
    ]);
    if (typeof hasDuplicated === 'boolean') {
      return hasDuplicated;
    }
    return true;
  });

export const createSelectWithAllTypes = (selectModuleState) =>
  createSelector(selectUser, selectModuleState, (user, moduleState) => {
    const filtersConfig = selectLocalFiltersConfig(moduleState);
    const withAllTypes = filtersConfig.getIn([
      displayUnitsFilter.key,
      displayUnitsFilter.withAllTypesKey,
    ]);
    if (typeof withAllTypes === 'boolean') {
      return withAllTypes;
    }
    return isThirdParty(user);
  });

export const createSelectWithRefusedAssignations = (selectModuleState) =>
  createSelector(selectUser, selectModuleState, (user, moduleState) => {
    const filtersConfig = selectLocalFiltersConfig(moduleState);
    const withRefusedAssignations = filtersConfig.getIn([
      assignationStatusFilter.key,
      assignationStatusFilter.withRefusedAssignation,
    ]);
    if (typeof withRefusedAssignations === 'boolean') {
      return withRefusedAssignations;
    }
    return false;
  });

import { registerModuleReducer } from 'utils/modules/reducer';
import { registerSaga } from 'utils/modules/saga';

import { MODULE_NAME } from './constants';
import reducer from './reducer';
import mainSaga from './sagas';
import * as selectors from './selectors';

registerSaga(mainSaga);
registerModuleReducer(MODULE_NAME, reducer);

export { selectors, reducer, MODULE_NAME };

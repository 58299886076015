export const MODULE_NAME = 'sharing-unit-tariffs/edit-modal';

// Edit modal
export const OPEN_EDIT_MODAL = `${MODULE_NAME}/OPEN`;
export const CLOSE_EDIT_MODAL = `${MODULE_NAME}/CLOSE`;

export const FETCH_SHARING_UNIT_SUCCESS = `${MODULE_NAME}/FETCH_SHARING_UNIT_SUCCESS`;
export const FETCH_SHARING_UNIT_FAILURE = `${MODULE_NAME}/FETCH_SHARING_UNIT_FAILURE`;

export const SAVE = `${MODULE_NAME}/SAVE`;
export const SAVE_SUCCESS = `${MODULE_NAME}/SAVE_SUCCESS`;
export const SAVE_FAILURE = `${MODULE_NAME}/SAVE_FAILURE`;

export const VALIDATE = `${MODULE_NAME}/VALIDATE`;

import { useEffect } from 'react';
import { useController, useWatch } from 'react-hook-form';
import { useSelector } from 'react-redux';

import { Checkbox } from '@alkem/react-ui-checkbox';

import { useValidatePackshotUpdate } from 'components/ui/form/asset-list/AssetV2/hooks/useValidatePackshotUpdate';
import { ASSET_SCOPE } from 'constants/media';
import { selectHasJsonStoreInputFeature } from 'modules/feature-flag/selectors';
import i18n from 'utils/i18n';

import { ReadOnlyAssetInput } from './types';

export const PackshotToggle = ({ isReadOnly }: ReadOnlyAssetInput) => {
  const name = 'isPackshot';

  const {
    field: { onChange, value: isPackshot },
  } = useController({ name });

  const scopeController = useController({ name: 'scope' });

  useEffect(() => {
    if (isPackshot && scopeController.field.value !== ASSET_SCOPE.PUBLIC) {
      scopeController.field.onChange(ASSET_SCOPE.PUBLIC);
    }
  }, [isPackshot, scopeController.field]);

  const crc32 = useWatch({ name: 'crc32' });

  const hasJsonStoreInputFeature = useSelector(selectHasJsonStoreInputFeature);

  const canChangePackshot = useValidatePackshotUpdate();

  if (hasJsonStoreInputFeature) return null;

  const handleUpdatePackshot = () => {
    if (name === 'isPackshot' && !canChangePackshot()) {
      return;
    }

    onChange(!isPackshot);
  };

  return (
    <Checkbox
      label={i18n.t('frontproducstream.asset_list.modal.set_packshot.label', {
        defaultValue: 'Set as packshot',
      })}
      id={`packshot-${crc32}`}
      key={`packshot-${crc32}`}
      checked={isPackshot}
      onChange={handleUpdatePackshot}
      disabled={isReadOnly}
    />
  );
};

import classNames from 'classnames';
import { Map } from 'immutable';
import PropTypes from 'prop-types';
import { PureComponent } from 'react';
import ImmutablePropTypes from 'react-immutable-proptypes';
import { connect } from 'react-redux';
import { createStructuredSelector } from 'reselect';

import i18n from 'utils/i18n';

import { updateSharingUnitField } from '../../../../actions';
import { getSharingUnitErrors } from '../../../../selectors';
import { checkHierarchyGrid } from '../../actions';

import './hierarchy-grid.scss';

const mapStateToProps = createStructuredSelector({
  sharingUnitErrors: getSharingUnitErrors,
});

const mapDispatchToProps = {
  updateSharingUnitField,
  checkHierarchyGrid,
};

export class HierarchyGrid extends PureComponent {
  static propTypes = {
    id: PropTypes.string.isRequired,
    readOnly: PropTypes.bool,
    value: PropTypes.object,
    className: PropTypes.string,
    updateSharingUnitField: PropTypes.func.isRequired,
    checkHierarchyGrid: PropTypes.func.isRequired,
    sharingUnitErrors: ImmutablePropTypes.map.isRequired,
    sharingUnitId: PropTypes.number,
  };

  static defaultProps = {
    value: Map(),
    readOnly: false,
  };

  constructor(props) {
    super(props);

    this.onChange = this.onChange.bind(this);
  }

  onChange(identifier, linearHierarchy) {
    const reg = /^\d*$/;
    return ({ target }) => {
      if (reg.test(target.value)) {
        const newHierarchy = linearHierarchy.setIn([identifier], target.value);
        this.updateHierarchyGrid(newHierarchy);
      }
    };
  }

  onBlur = (linearHierarchy) => () => {
    this.props.checkHierarchyGrid(linearHierarchy, this.props.sharingUnitId);
  };

  updateHierarchyGrid(value) {
    const { sharingUnitId } = this.props;
    this.props.updateSharingUnitField({
      sharingUnitId,
      field: 'hierarchy',
      value,
    });
  }

  renderInput(identifier, linearHierarchy) {
    const { id, readOnly } = this.props;

    const value = linearHierarchy.getIn([identifier]);
    return (
      <input
        className={classNames(
          'HierarchyGrid__input',
          readOnly && 'HierarchyGrid__input--readOnly',
        )}
        id={`${id}-${identifier}`}
        name={identifier}
        onChange={this.onChange(identifier, linearHierarchy)}
        onBlur={this.onBlur(linearHierarchy)}
        value={value !== undefined ? value : ''}
        disabled={readOnly}
      />
    );
  }

  render() {
    const { value, sharingUnitErrors, sharingUnitId } = this.props;

    return (
      <div className={`row ${this.props.className}`}>
        <div className="col-xs-12">
          <div
            className={classNames('hierarchyTable', {
              'FormField--raguelError': sharingUnitErrors.has(sharingUnitId),
            })}
          >
            <div className="col-xs-4">
              <div className="HierarchyGrid__label--top">
                {i18n.t(
                  'frontproductstream.product_page.sharing_unit_hierarchy_grid_products_per.label',
                  { defaultValue: 'Products per' },
                )}
              </div>
              <div className="HierarchyGrid__label">
                {i18n.t(
                  'frontproductstream.product_page.sharing_unit_hierarchy_grid_gtin_of_element.label',
                  { defaultValue: 'GTIN of element (optional)' },
                )}
              </div>
            </div>
            <div className="col-xs-8">
              <table className="HierarchyGrid">
                <tbody>
                  <tr>
                    <td>
                      {i18n.t(
                        'frontproductstream.product_page.sharing_unit_hierarchy_grid_inner_pack.label',
                        { defaultValue: 'Inner pack' },
                      )}
                    </td>
                    <td>
                      {i18n.t(
                        'frontproductstream.product_page.sharing_unit_hierarchy_grid_case.label',
                        { defaultValue: 'Case' },
                      )}
                    </td>
                    <td>
                      {i18n.t(
                        'frontproductstream.product_page.sharing_unit_hierarchy_grid_pallet.label',
                        { defaultValue: 'Pallet' },
                      )}
                    </td>
                  </tr>
                  <tr>
                    <td>{this.renderInput('qttyPerPack', value)}</td>
                    <td>{this.renderInput('qttyPerCase', value)}</td>
                    <td>{this.renderInput('qttyPerPallet', value)}</td>
                  </tr>
                  <tr>
                    <td>{this.renderInput('gtinPack', value)}</td>
                    <td>{this.renderInput('gtinCase', value)}</td>
                    <td>{this.renderInput('gtinPallet', value)}</td>
                  </tr>
                </tbody>
              </table>
              {sharingUnitErrors.has(sharingUnitId) ? (
                <div className="Raguel">
                  {sharingUnitErrors.get(sharingUnitId).map((error) => (
                    <div className="Raguel__message" key={error}>
                      <span>{error}</span>
                    </div>
                  ))}
                </div>
              ) : (
                <div />
              )}
            </div>
          </div>
          <div className="col-xs-4">
            <div className="HierarchyGrid__label">
              {i18n.t(
                'frontproductstream.product_page.sharing_unit_hierarchy_grid_layer_per_pallet.label',
                { defaultValue: 'Layer per pallet' },
              )}
            </div>
          </div>
          <div className="col-xs-8">
            <input
              id={`${this.props.id}-qttyLayerPerPallet`}
              className="form-control"
              value={
                value.get('qttyLayerPerPallet') !== undefined
                  ? value.get('qttyLayerPerPallet')
                  : ''
              }
              onChange={this.onChange('qttyLayerPerPallet', value)}
              disabled={this.props.readOnly}
            />
          </div>
        </div>
      </div>
    );
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(HierarchyGrid);

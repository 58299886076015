import { hasFeature } from 'modules/feature-flag';
import { FEATURE_PRODUCT_REVIEW } from 'modules/feature-flag/constants';
import { UserImmutable } from 'types';
import { i18n } from 'utils/i18n';

export const reviewActionStatusFilter = {
  key: 'alkemics.review_process.action_states',
  filterLabel: i18n.t(
    'frontproductstream.core.list_filter_review_actions.label',
    { defaultValue: 'Review status' },
  ),
  query: 'review_action_statuses',
  customQuery: (selectedValues?) =>
    selectedValues
      ? {
          reviewActionStatuses: Object.keys(selectedValues).map(Number),
        }
      : null,
  isAvailable: ({ user }: { user?: UserImmutable } = {}) =>
    hasFeature(user, FEATURE_PRODUCT_REVIEW),
};

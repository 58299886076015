import asyncComponent from 'core/modules/async/component';
import registerModule from 'core/modules/async/register';

const companyProfileAdminModule = async () =>
  registerModule(
    await import(/* webpackChunkName: "company-profile-admin" */ './async'),
  );

export const CompanyProfileEdit = asyncComponent(companyProfileAdminModule, {
  componentPath: 'CompanyProfileEdit',
});

import classNames from 'classnames';
import { get } from 'lodash';
import PropTypes from 'prop-types';
import { PureComponent, createRef } from 'react';
import ImmutablePropTypes from 'react-immutable-proptypes';
import { Link } from 'react-router-dom';

import { ClickOutside } from '@alkem/react-ui-click-outside';

import prefs from 'constants/prefs';
import {
  getOrganizationId,
  getOrganizationUuid,
  hasId,
  isNotThirdParty,
  managesOrganization,
} from 'core/api/user';
import { hasCompanyProfile } from 'modules/company-profile/shared/utils';
import * as routes from 'routes';
import i18n from 'utils/i18n';
import { fill } from 'utils/routing';

export class NavbarProfile extends PureComponent {
  static propTypes = {
    user: ImmutablePropTypes.map.isRequired,
    onClick: PropTypes.func.isRequired,
    onClickOutside: PropTypes.func.isRequired,
    open: PropTypes.bool,
  };

  static defaultProps = {
    open: false,
  };

  mainRef = createRef();

  handleClickOutside = () => {
    const { open, onClickOutside } = this.props;
    if (open) {
      onClickOutside();
    }
  };

  render() {
    const { user, open, onClick } = this.props;
    const organizationId = getOrganizationId(user);
    const organizationUuid = getOrganizationUuid(user);
    if (!hasId(user)) {
      return null;
    }
    const profileLink = get(prefs, 'navbar.profileLink');
    const isManager =
      get(prefs, 'navbar.adminLink') && managesOrganization(user);
    const liClass = {
      dropdown: true,
      Navbar__dropdown: true,
      Navbar__user: true,
      open,
    };

    return (
      <ClickOutside ref={this.mainRef} onClickOutside={this.handleClickOutside}>
        <li
          ref={this.mainRef}
          id="navbar-profile"
          className={classNames(liClass)}
        >
          <a className="dropdown-toggle" onClick={onClick}>
            <i className="mdi mdi-account" />
            <i className="mdi mdi-chevron-down" />
          </a>
          <div className="dropdown-menu dropdown-menu-right" role="menu">
            {profileLink && (
              <Link
                className="dropdown-item"
                to={routes.userOwnProfile}
                onClick={this.handleClickOutside}
              >
                <i className="mdi mdi-account" />{' '}
                {i18n.t(
                  'frontproductstream.navbar_settings.user_profile.link',
                  { defaultValue: 'Profile' },
                )}
              </Link>
            )}
            {hasCompanyProfile(user) && (
              <Link
                className="dropdown-item"
                to={fill(routes.companyProfile, organizationUuid)}
                onClick={this.handleClickOutside}
              >
                <i className="mdi mdi-domain" />{' '}
                {i18n.t(
                  'frontproductstream.navbar_settings.company_profile.link',
                  { defaultValue: 'Company profile' },
                )}
              </Link>
            )}
            {isManager && isNotThirdParty(user) && (
              <Link
                className="dropdown-item"
                to={routes.organizationAdmin}
                onClick={this.handleClickOutside}
              >
                <i className="mdi mdi-settings" />{' '}
                {i18n.t(
                  'frontproductstream.navbar_settings.organization_administration.link',
                  { defaultValue: 'Administration' },
                )}
              </Link>
            )}
            {isManager && isNotThirdParty(user) && (
              <Link
                className="dropdown-item"
                to={fill(routes.userList, organizationId)}
                onClick={this.handleClickOutside}
                data-testid="permission-management"
              >
                <i className="mdi mdi-shield-lock" />{' '}
                {i18n.t(
                  'frontproductstream.navbar_settings.user_management.link',
                  { defaultValue: 'User Management' },
                )}
              </Link>
            )}
            <Link
              id="logout-link"
              className="dropdown-item dropdown-item-reverse"
              to={routes.logout}
            >
              <i className="mdi mdi-power" />{' '}
              {i18n.t('frontproductstream.navbar_settings.logout.link', {
                defaultValue: 'Logout',
              })}
            </Link>
          </div>
        </li>
      </ClickOutside>
    );
  }
}

export default NavbarProfile;

import { SourcingLabelApi } from '@alkem/sdk-dashboard';

import { getUserLanguage as acceptLanguage } from 'utils/i18n';

const options = {
  cacheEnabled: true,
  acceptLanguage,
};

export default new SourcingLabelApi(options);

export const sourcingLabelApiImmutable = new SourcingLabelApi({
  ...options,
  immutable: true,
});

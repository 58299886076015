import { List } from 'immutable';

import { get } from 'utils/immutable';

export const selectAssignationId = (assignation) => get(assignation, 'id');
export const selectAssignationGtin = (assignation) => get(assignation, 'gtin');
export const selectAssignationStatus = (assignation) =>
  get(assignation, 'alkemics.assignation.status');
export const selectAssignationSourceOrgs = (assignation) =>
  get(assignation, 'alkemics.assignation.source_organizations') || List();

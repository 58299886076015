import { fromJS } from 'immutable';
import {
  call,
  delay,
  put,
  putResolve,
  race,
  select,
  take,
  takeEvery,
  takeLatest,
} from 'redux-saga/effects';

import { notificationError } from 'actions/notification';
import { RECEIVE_PRODUCT_VERSION } from 'constants/events/productversion';
import { fetchProductDataByKeyId } from 'modules/product-page/actions';
import { ERROR_PRODUCT_LINKED_TO_PUBLISHED_TARIFF } from 'modules/product-page/modules/dashboard-publication-summary/constants';
import { selectToggledRecipients } from 'modules/publication-summary/selectors';
import {
  CREATE_SHARING_UNIT_SUCCESS,
  DELETE_SHARING_UNIT_SUCCESS,
} from 'modules/sharing-units/actionTypes';
import { selectIsManufacturer, selectIsPrivateLabel } from 'modules/user';
import {
  RECEIVE_RULE_RESULTS,
  applyRulesForSpecificRecipients,
} from 'modules/validation';
import {
  selectAvailableLanguages,
  selectCanReadProduct,
  selectCanReadSharingUnit,
  selectProductKeyId,
} from 'reducers/productVersion';
import serviceProductApi from 'resources/serviceProductApi';
import { requestWithHeaders } from 'utils/api';
import i18n from 'utils/i18n';
import { toJsIfImmutable } from 'utils/immutable';
import qs from 'utils/query';
import { withCatch } from 'utils/saga';

import { fetchSummary, unshareProductDone } from './actions';
import {
  ACTIVATE_GROUPS_SUCCESS, // Add recipient
  ADD_RECIPIENTS,
  ADD_RECIPIENTS_FAILURE,
  ADD_RECIPIENTS_SUCCESS, // Deactivate group
  DEACTIVATE_GROUPS,
  DEACTIVATE_GROUPS_FAILURE,
  DEACTIVATE_GROUPS_SUCCESS, // Summary
  FETCH_SUMMARY,
  FETCH_SUMMARY_FAILURE, // Preparing
  PREPARE_FOR_GROUP,
  PREPARE_FOR_GROUP_FAILURE,
  PREPARE_FOR_GROUP_SUCCESS,
  RECEIVE_SUMMARY, // Validation
  RECEIVE_VALIDATION_DATA,
  UNSHARE_PRODUCT,
  VALIDATE_PRODUCT,
} from './events';
import { selectRecipientToUnshare } from './selectors';
import { isGroupActive, splitGroupMembers } from './utils';

export function* fetchSummaryAfterReceivingVersionSaga() {
  const isManufacturer = yield select(selectIsManufacturer);
  const isPrivateLabel = yield select(selectIsPrivateLabel);
  const canReadProduct = yield select(selectCanReadProduct);
  const canReadSharingUnit = yield select(selectCanReadSharingUnit);
  if (
    (isManufacturer || isPrivateLabel) &&
    canReadProduct &&
    canReadSharingUnit
  ) {
    yield put({ type: FETCH_SUMMARY });
  }
}

export function* fetchSummarySaga() {
  const productKeyId = yield select(selectProductKeyId);

  const publications = yield call(
    requestWithHeaders,
    serviceProductApi,
    'get',
    '/product/v3/products/publications',
    qs.stringify({ product_key_id: productKeyId }, true),
  );

  if (publications.error) {
    yield put(
      notificationError(
        i18n.t(
          'frontproductstream.publication_summary.fetch_publication_status.error',
          {
            defaultValue:
              'An error occured while fetching the publication status',
          },
        ),
      ),
    );
    yield put({ type: FETCH_SUMMARY_FAILURE, error: publications.error });
    return;
  }

  const groups = fromJS(publications.result.data.groups || []).map(
    splitGroupMembers,
  );

  yield put({ type: RECEIVE_SUMMARY, groups });
  yield put({
    type: VALIDATE_PRODUCT,
    recipientIds: groups
      .map((group) =>
        group
          .get('importantRecipients')
          .map((recipient) => recipient.get('id')),
      )
      .flatten(),
  });
}

export function* deactivateGroups({ groupIds }) {
  const productKeyId = yield select(selectProductKeyId);

  const { error } = yield call(
    requestWithHeaders,
    serviceProductApi,
    'post',
    `/product/v2/scopes/deactivate`,
    {
      product_key_id: productKeyId,
      group_ids: groupIds,
    },
  );

  if (error) {
    yield put({ type: DEACTIVATE_GROUPS_FAILURE, groupIds });
    yield put(
      notificationError(
        i18n.t(
          'frontproductstream.publication_summary.deactivate_group.error',
          { defaultValue: 'An error occured while deactivating the channel' },
        ),
      ),
    );
    return;
  }

  yield put({ type: DEACTIVATE_GROUPS_SUCCESS, groupIds });
  yield put({ type: FETCH_SUMMARY });
}

export function* addRecipientsSaga({ payload }) {
  const recipientIds = payload.map((data) => data.recipient.get('id'));
  // optimistic
  yield put({ type: ADD_RECIPIENTS_SUCCESS, recipientIds });

  const templateIdForRecipient = payload.reduce((acc, data) => {
    if (data.templateId) {
      acc[data.recipient.get('id')] = data.templateId;
    }
    return acc;
  }, {});

  const groups = payload.map((data) => data.group);
  const productKeyId = yield select(selectProductKeyId);

  // We keep it only for optimistic update
  const inactiveGroupIds = groups
    .filter((group) => !isGroupActive(group))
    .map((group) => group.getIn(['group', 'id']));
  const hasInactiveGroups = inactiveGroupIds.length > 0;

  const { error } = yield call(
    requestWithHeaders,
    serviceProductApi,
    'post',
    '/product/v2/product/recipients',
    {
      product_key_id: productKeyId,
      target_organization_ids: recipientIds,
      target_organization_template: templateIdForRecipient,
    },
  );

  if (error) {
    yield put({ type: ADD_RECIPIENTS_FAILURE, recipientIds });
    yield put(
      notificationError(
        i18n.t('frontproductstream.publication_summary.add_recipients.error', {
          defaultValue: 'An error occured while activating the channel',
        }),
      ),
    );
    return;
  }
  yield put(fetchProductDataByKeyId(productKeyId));
  const toggledRecipients = yield select(selectToggledRecipients);
  yield put({ type: VALIDATE_PRODUCT, recipientIds: toggledRecipients.toJS() });
  // Wait for 7.5 seconds, though the `validated` usually comes back earlier
  yield race({
    check: take(RECEIVE_RULE_RESULTS),
    validated: take(RECEIVE_VALIDATION_DATA),
    delay: delay(7500),
  });

  if (hasInactiveGroups) {
    yield put({ type: ACTIVATE_GROUPS_SUCCESS, groupIds: inactiveGroupIds });
  }
  yield put({ type: FETCH_SUMMARY });
}

export function* validateProductSaga({ recipientIds }) {
  const languages = yield select(selectAvailableLanguages);
  const results = yield putResolve(
    applyRulesForSpecificRecipients({
      recipientIds: toJsIfImmutable(recipientIds),
      withHierarchy: true,
      languages,
    }),
  );
  yield put({ type: RECEIVE_VALIDATION_DATA, results });
}

export function* prepareForGroupSaga({ groupId, prepare }) {
  const productKeyId = yield select(selectProductKeyId);

  const { error } = yield call(
    requestWithHeaders,
    serviceProductApi,
    'post',
    '/product/v2/scopes/prepare',
    {
      product_key_ids: [productKeyId],
      group_ids: [groupId],
      remove: !prepare,
    },
  );

  if (error) {
    yield put({ type: PREPARE_FOR_GROUP_FAILURE, groupId, prepare });
    yield put(
      notificationError(
        i18n.t('frontproductstream.publication_summary.prepare_group.error', {
          defaultValue: 'An error occured while assigning the channel',
        }),
      ),
    );
    return;
  }

  // @TODO: handle success and all
  yield put({ type: PREPARE_FOR_GROUP_SUCCESS, groupId, prepare });
}

export function* unshareProductSaga() {
  try {
    const productKeyId = yield select(selectProductKeyId);
    const recipient = yield select(selectRecipientToUnshare);
    const productKeyIds = [productKeyId];
    const targetOrganizationIds = [recipient.get('id')];
    yield call(() =>
      serviceProductApi.unshareVersion(productKeyIds, targetOrganizationIds),
    );
    yield put(unshareProductDone());
    yield put(fetchSummary());
  } catch (error) {
    yield put(unshareProductDone());
    if (error.data?.error === ERROR_PRODUCT_LINKED_TO_PUBLISHED_TARIFF) {
      yield put(
        notificationError(
          i18n.t(
            'frontproductstream.publication_summary.unshare_product_lu_belongs_to_published_tariff.error',
            {
              defaultValue:
                'This product belongs to a logistical hierarchy linked to a published tariff for this recipient',
            },
          ),
        ),
      );
    } else {
      throw error;
    }
  }
}

export default function* publicationSummaryMainSaga() {
  yield takeLatest(
    [FETCH_SUMMARY, CREATE_SHARING_UNIT_SUCCESS, DELETE_SHARING_UNIT_SUCCESS],
    withCatch(fetchSummarySaga),
  );
  yield takeEvery(
    RECEIVE_PRODUCT_VERSION,
    withCatch(fetchSummaryAfterReceivingVersionSaga),
  );
  yield takeEvery(ADD_RECIPIENTS, withCatch(addRecipientsSaga));
  yield takeEvery(DEACTIVATE_GROUPS, withCatch(deactivateGroups));
  yield takeEvery(VALIDATE_PRODUCT, withCatch(validateProductSaga));
  yield takeEvery(PREPARE_FOR_GROUP, withCatch(prepareForGroupSaga));
  yield takeEvery(
    UNSHARE_PRODUCT,
    withCatch(unshareProductSaga, {
      withNotification: true,
      errorMessage: 'An error occured while unsharing product',
    }),
  );
}

import resources from 'app-translations';
import i18next, { type TOptions } from 'i18next';
import { defer, flow, identity, noop } from 'lodash/fp';

import { reload } from './location';
import localStorage from './storage';
import { safeSubstr, safeTrim, toSafeLowerCase } from './string';

export const SUPPORTED_LANGUAGES = ['en', 'fr', 'de', 'es', 'pt'];
export const DEFAULT_LANGUAGE = 'en';
function isSupportedLanguage(lang) {
  return SUPPORTED_LANGUAGES.includes(toSafeLowerCase(lang));
}

export const toShortLang: (lang: string) => string = flow([
  toSafeLowerCase,
  safeTrim,
  safeSubstr(0, 2),
]);

export function getUserLanguage(short: boolean = false): string {
  const processFunc = short
    ? toShortLang
    : (identity as (lang: string) => string);
  const localLang = localStorage.getItem('language');
  if (localLang) {
    // Trust this one.
    return processFunc(localLang);
  }

  // If navigator.languages is filled check this first.
  if (navigator.languages?.length > 0) {
    for (const lang of navigator.languages) {
      if (isSupportedLanguage(processFunc(lang))) {
        return processFunc(lang);
      }
    }
    // If nothing matched try the base languages.
    if (!short) {
      for (const lang of navigator.languages) {
        if (isSupportedLanguage(toShortLang(lang))) {
          return toShortLang(lang);
        }
      }
    }
  }

  // Try legacy places.
  const language = navigator.language || '';
  if (isSupportedLanguage(language)) {
    return processFunc(language);
  }

  return DEFAULT_LANGUAGE;
}

export function getByLocale(dict: { [lang: string]: string }): string {
  const locale = toShortLang(getUserLanguage() || DEFAULT_LANGUAGE);
  return dict[locale] || dict[DEFAULT_LANGUAGE];
}

export function setProfileLanguage(_language?: string) {
  const language = _language || DEFAULT_LANGUAGE;
  const initialLanguage = getUserLanguage();
  if (language !== localStorage.getItem('language')) {
    localStorage.setItem('language', language);
    if (
      language !== initialLanguage &&
      localStorage.getItem('sxm_reload_profile') !== false
    ) {
      return () => defer(() => reload());
    }
  }
  return noop;
}

const lng: string = getUserLanguage().replace('-', '_');

const options: Parameters<typeof i18next.init>[0] = {
  lng,
  fallbackLng: DEFAULT_LANGUAGE,
  returnEmptyString: false,
  nsSeparator: '__',
  keySeparator: '>>>',
  contextSeparator: ':::',
  resources: lng === 'noop' ? {} : resources.default || resources,
  interpolation: { escapeValue: false },
  compatibilityJSON: 'v3',
};

// eslint-disable-next-line import/no-named-as-default-member
i18next.init(options, undefined);

type $Dictionary<T = unknown> = { [key: string]: T };
type I18nextType = typeof i18next;
interface I18nType<TInterpolationMap extends object = $Dictionary>
  extends Omit<I18nextType, 't'> {
  t: (key: string, options?: TOptions<TInterpolationMap>) => string;
}

export const i18n = i18next as I18nType;
export default i18n;

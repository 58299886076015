import { get, isEmpty, set } from 'lodash';
import { call, put, select, takeEvery } from 'redux-saga/effects';

import { updateEntity } from 'actions/entity';
import { startLoading, stopLoading } from 'actions/navigation';
import { notificationClear, notificationSuccess } from 'actions/notification';
import { fetchProductVersionByKeyId } from 'actions/productversion';
import { PRODUCT_VERSION_RESET } from 'constants/events/productversion';
import { READY_FOR_CHECK } from 'constants/physicalCheckerStatus';
import { ROOM_TYPE_PRODUCTVERSION } from 'constants/room';
import { sendQuickMessage } from 'modules/chat';
import { RESET_DISPLAY_GROUPS_READONLY } from 'modules/display-groups/constants';
import { getOrganizationId } from 'reducers/organization';
import {
  selectContentOwnerId,
  selectEditedProductVersion,
  selectProductKeyId,
  selectProductVersionId,
  selectSourceProductVersion,
} from 'reducers/productVersion';
import { processSaveErrors } from 'utils/actions/saveErrors';
import i18n from 'utils/i18n';
import { logError } from 'utils/logging';
import { withCatch } from 'utils/saga';
import { selectEnrichedData } from 'utils/selectors/product-data';

import {
  openDiffModal,
  openDisputeModal,
  resetCannotCheckModal,
  resetDiffModal,
  resetDisputeModal,
  sendReview as sendReviewAction,
  setContributionDiff,
  setContributions,
  setStateMachine,
} from '../actions';
import {
  acceptReviewApi,
  checkValidationApi,
  createContributionApi,
  fetchContributionDiffApi,
  fetchStateMachineApi,
  refuseReviewApi,
  submitFieldsApi,
  updateStatusApi,
  validateDataApi,
} from '../api';
import {
  CANNOT_CHECK_MODAL_SUBMIT,
  CONFIRM_REVIEW,
  CONTRIBUTION_TYPE_PHYSICAL_CHECKER,
  CREATE_CONTRIBUTION,
  FETCH_CONTRIBUTION_DIFF,
  FETCH_CONTRIBUTION_STATE_MACHINE,
  ROLLBACK_FIELD,
  SEND_REVIEW,
  SUBMIT_PHYSICAL_CHECK,
  UPDATE_CONTRIBUTION_STATUS,
} from '../constants';
import {
  getPhysicalCheckContribution,
  getPhysicalCheckFailedFieldNames,
  getProductVersionDataForFailedField,
  getReviewFields,
} from '../selectors';
import { getFailedFieldFiltered } from '../selectors/check-fields';

export default function* collaborationWatcher() {
  const withNotif = { withNotification: true };
  yield takeEvery(
    FETCH_CONTRIBUTION_STATE_MACHINE,
    withCatch(fetchStateMachine, withNotif),
  );
  yield takeEvery(CREATE_CONTRIBUTION, createContribution);
  yield takeEvery(UPDATE_CONTRIBUTION_STATUS, updateStatus);
  yield takeEvery(CANNOT_CHECK_MODAL_SUBMIT, cannotCheck);
  yield takeEvery(ROLLBACK_FIELD, withCatch(rollbackField, withNotif));
  yield takeEvery(SUBMIT_PHYSICAL_CHECK, submitPhysicalCheck);
  yield takeEvery(CONFIRM_REVIEW, withCatch(confirmReview, withNotif));
  yield takeEvery(SEND_REVIEW, sendReview);
  yield takeEvery(FETCH_CONTRIBUTION_DIFF, withCatch(fetchDiff, withNotif));
}

export function* fetchStateMachine() {
  const stateMachine = yield call(fetchStateMachineApi);
  yield put(setStateMachine(stateMachine));
}

export function* createContribution({ payload }) {
  try {
    yield put(startLoading());
    const pv = payload.productVersion;
    const { contributor } = payload;
    const contribution = {
      productId: pv.getIn(['specializes', 'id']),
      sourceOrganization: { id: contributor },
      targetOrganization: { id: pv.getIn(['tags', 'contentOwner', 'id']) },
      type: CONTRIBUTION_TYPE_PHYSICAL_CHECKER,
    };
    yield call(createContributionApi, contribution);
    yield put(
      notificationSuccess(
        i18n.t(
          'frontproductstream.physical_check.successful_activation.notification',
          { defaultValue: 'Contribution successfully created' },
        ),
      ),
    );
  } catch (err) {
    logError(err);
  } finally {
    yield put(stopLoading());
  }
}

export function* updateStatus({ payload }) {
  try {
    yield put(startLoading());
    const contribution = yield call(
      updateStatusApi,
      payload.contributionId,
      payload.status,
    );
    yield put(
      notificationSuccess(
        i18n.t(
          'frontproductstream.physical_check.successful_status_update.notification',
          { defaultValue: 'Contribution status successfully updated' },
        ),
      ),
    );
    yield put(setContributions([contribution]));
    yield put({ type: PRODUCT_VERSION_RESET });
    yield put(resetDiffModal());
  } catch (err) {
    logError(err);
  } finally {
    yield put(stopLoading());
  }
}

export function* rollbackField({ payload }) {
  const { model } = payload;
  // if the field was previously failed we need to rollback the potential update
  const failedField = yield select(getPhysicalCheckFailedFieldNames);
  if (failedField.includes(model)) {
    const pv = yield select(selectSourceProductVersion);
    yield put(updateEntity(model, pv[model], pv.id, 'ProductVersion'));
  }
}

export function* cannotCheck({ payload }) {
  try {
    yield put(startLoading());

    const productVersionId = yield select(selectProductVersionId);
    const contentOwnerId = yield select(selectContentOwnerId);
    const currentContribution = yield select(getPhysicalCheckContribution);

    const contribution = yield call(
      updateStatusApi,
      currentContribution.get('id'),
      READY_FOR_CHECK,
    );

    yield put(
      sendQuickMessage(
        payload.message,
        contentOwnerId,
        productVersionId,
        ROOM_TYPE_PRODUCTVERSION,
      ),
    );

    yield put(
      notificationSuccess(
        i18n.t(
          'frontproductstream.physical_check._successful_cannot_check_message_sent.notification',
          { defaultValue: 'Contribution status successfully updated' },
        ),
      ),
    );

    yield put(resetCannotCheckModal());
    yield put(setContributions([contribution]));
    yield put({ type: PRODUCT_VERSION_RESET });
  } catch (err) {
    logError(err);
  } finally {
    yield put(stopLoading());
  }
}

/**
 * We accept tolerance on some field, so we have to check the update made by the physicalChecker.
 * If the field is under a tolerance rule we revert the modification
 */
export function* handleTolerance() {
  const source = yield select(selectSourceProductVersion);
  const edited = yield select(selectEditedProductVersion);
  const refData = yield select(getProductVersionDataForFailedField);

  // prepare validation call
  const { data, language } = yield select(selectEnrichedData);
  if (!data || !language) {
    return null;
  }
  // add referentialData
  data.refData = refData;
  const physicalCheckerOrgaId = yield select(getOrganizationId);
  const productKeyId = yield select(selectProductKeyId);
  const body = {
    data,
    languages: [language],
    target_organization_ids: [physicalCheckerOrgaId],
    rule_set_ids: 35,
    filter_unsolvable_rules: false,
    product_key_id: productKeyId,
  };
  // get tolerance rule
  const validation = yield call(checkValidationApi, body);
  // for each failed (status = 1) tolerance rule rollback the data

  for (const rule of validation.data.rule_results) {
    if (rule.status === 1) {
      set(edited, rule.paths.v[0], get(source, rule.paths.v[0]));
    }
  }
  return { source, edited };
}

export function* fetchDiff() {
  const { source, edited } = yield call(handleTolerance);
  yield put(notificationClear());
  // validate data
  try {
    yield call(validateDataApi, edited);
  } catch (err) {
    yield put(resetDiffModal());
    yield put(
      processSaveErrors({
        status: err.status,
        data: { errors: { ProductVersion: err.data.errors } },
      }),
    );
    return;
  }

  const contributionDiff = yield call(fetchContributionDiffApi, source, edited);
  yield put(setContributionDiff(contributionDiff));
  yield put(openDiffModal());
}

export function* submitPhysicalCheck() {
  try {
    yield put(startLoading());
    const currentContribution = yield select(getPhysicalCheckContribution);
    const failedField = yield select(getFailedFieldFiltered);
    const productVersion = yield select(selectEditedProductVersion);

    const updatedFields = failedField
      .get('failed')
      .keySeq()
      .map((fieldName) => ({
        name: fieldName,
        action: 'CREATE',
        data: productVersion[fieldName],
      }));
    const contribution = yield call(
      submitFieldsApi,
      currentContribution.get('id'),
      updatedFields,
    );

    yield put(setContributions([contribution]));

    yield put(resetDiffModal());
    yield put({ type: PRODUCT_VERSION_RESET });
    yield put({ type: RESET_DISPLAY_GROUPS_READONLY, readOnly: true });

    if (updatedFields.count() > 0) {
      yield put(
        notificationSuccess(
          i18n.t(
            'frontproductstream.physical_check.succesful_mark_check_as_failed.notification',
            { defaultValue: 'Contribution failed successfully' },
          ),
        ),
      );
    } else {
      yield put(
        notificationSuccess(
          i18n.t(
            'frontproductstream.physical_check.succesful_mark_check_as_passed.notification',
            { defaultValue: 'Contribution passed successfully' },
          ),
        ),
      );
    }
  } catch (err) {
    logError(err);
  } finally {
    yield put(stopLoading());
  }
}

export function* confirmReview() {
  const reviewFields = yield select(getReviewFields);
  const hasDisputedFields = reviewFields
    .valueSeq()
    .some((isAccepted) => !isAccepted);

  if (hasDisputedFields) {
    yield put(openDisputeModal());
  } else {
    yield put(sendReviewAction());
  }
}

export function* sendReview({ payload }) {
  try {
    yield put(startLoading());

    const reviewFields = yield select(getReviewFields);
    const productVersionId = yield select(selectProductVersionId);
    const productKeyId = yield select(selectProductKeyId);
    const currentContribution = yield select(getPhysicalCheckContribution);

    let apiCall = acceptReviewApi;
    const fields = reviewFields.reduce((acc, isAccepted, transactionId) => {
      if (!isAccepted) {
        apiCall = refuseReviewApi;
      }
      acc.push({ transaction_id: transactionId, isAccepted });
      return acc;
    }, []);

    const contribution = yield call(
      apiCall,
      currentContribution.get('id'),
      fields,
    );

    if (!isEmpty(payload.message)) {
      yield put(
        sendQuickMessage(
          payload.message,
          currentContribution.get('sourceOrganizationId'),
          productVersionId,
          ROOM_TYPE_PRODUCTVERSION,
        ),
      );
    }

    yield put(resetDisputeModal());
    yield put(
      notificationSuccess(
        i18n.t(
          'frontproductstream.physical_check.successful_review_confirmation.notification',
          { defaultValue: 'Review confirmed successfully' },
        ),
      ),
    );
    yield put(setContributions([contribution]));
    yield put(fetchProductVersionByKeyId(productKeyId));
  } catch (err) {
    logError(err);
  } finally {
    yield put(stopLoading());
  }
}

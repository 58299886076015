import { set } from 'lodash/fp';

import { ActionPayload } from 'types';
import { createFpReducer } from 'utils/redux';

import { receiveHasRfpModule } from './actions';
import { RECEIVE_RFP_MODULE_AVAILABLITY } from './constants';

export interface RfpUserInfoState {
  hasRfpModule: boolean;
}

export const initialState: RfpUserInfoState = {
  hasRfpModule: false,
};

export const rfpUserInfoReducer = createFpReducer<RfpUserInfoState>(
  initialState,
  {
    [RECEIVE_RFP_MODULE_AVAILABLITY]: (
      value: ActionPayload<typeof receiveHasRfpModule>,
    ) => set('hasRfpModule', value),
  },
);

import { useNavigate } from 'react-router-dom';

import { Button } from '@alkem/react-ui-button';

import * as routes from 'routes';
import i18n from 'utils/i18n';
import { track } from 'utils/tracking';

export const ContactButton = () => {
  const navigate = useNavigate();

  const onClick = () => {
    track({ category: 'appstore', action: 'contact_partner_requested' });
    navigate(routes.appStore);
  };

  return (
    <Button secondary onClick={onClick}>
      {i18n.t('frontproductstream.media_errors.contact_button.label', {
        defaultValue: 'More information',
      })}
    </Button>
  );
};

export default ContactButton;

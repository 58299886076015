import PropTypes from 'prop-types';
import { Component } from 'react';
import { connect } from 'react-redux';
import { createStructuredSelector } from 'reselect';

import { Radio } from '@alkem/react-ui-inputs';

import Dropdown from 'components/ui/dropdown';
import Modal from 'components/ui/modal';
import ProductReference from 'components/ui/product-reference';
import * as lifeCycle from 'constants/filters/lifeCycle';
import { UNITED_KINGDOM } from 'constants/targetMarkets';
import {
  TypePackagingLabels,
  getLogisticalTypePackagingLabel,
  getTypePackagingCode,
} from 'constants/typePackaging';
import {
  selectCurrentProductVersionGTIN,
  selectHasTextileVariants,
  selectIsConsumerUnit,
  selectProductGTINWithFallbackOnInternal,
  selectTargetMarketId,
  selectTypePackagingId,
} from 'reducers/productVersion';
import { parseBoolean } from 'utils';
import i18n from 'utils/i18n';
import { separateActions } from 'utils/redux';

import { createTemplate, createUnit } from '../../actions';
import { typePackagingOptions } from '../../helpers';
import LogisticalHierarchyIcon from '../icon';

import './create-modal.scss';

const templateOptions = [
  {
    label: i18n.t(
      'frontproductstream.logistical_hierarchy_modal.select_template.text',
      { defaultValue: 'Select a template' },
    ),
    value: true,
  },
  {
    label: i18n.t(
      'frontproductstream.logistical_hierarchy_modal.create_template.text',
      { defaultValue: 'Create from scratch' },
    ),
    value: false,
  },
];

const { PALLET, CASE, PACK, EACH } = TypePackagingLabels;

const mapStateToProps = createStructuredSelector({
  isConsumerUnit: selectIsConsumerUnit,
  gtin: selectCurrentProductVersionGTIN, // todo should be remove
  reference: selectProductGTINWithFallbackOnInternal,
  targetMarketId: selectTargetMarketId,
  packagingTypeId: selectTypePackagingId,
  hasTextileVariants: selectHasTextileVariants,
});

const mapDispatchToProps = {
  createUnit,
  createTemplate,
};

export class CreateHierarchyModal extends Component {
  static propTypes = {
    insertFirst: PropTypes.bool,
    onClose: PropTypes.func.isRequired,
    gtin: PropTypes.string,
    reference: PropTypes.string,
    targetMarketId: PropTypes.number.isRequired,
    packagingTypeId: PropTypes.number,
    isConsumerUnit: PropTypes.bool,
    hasTextileVariants: PropTypes.bool,
    isPatch: PropTypes.bool,
    actions: PropTypes.shape({
      createUnit: PropTypes.func.isRequired,
      createTemplate: PropTypes.func.isRequired,
    }).isRequired,
  };

  static defaultProps = {
    insertFirst: false,
  };

  state = {
    fromTemplate: true,
    isStandardHierarchy: null,
  };

  availableTemplates = {
    [CASE.id]: [[PALLET, CASE], [PALLET], [CASE]],
    [PACK.id]: [[PALLET, CASE], [PALLET], [CASE]],
    [EACH.id]: [
      [PALLET, CASE, PACK],
      [PALLET, CASE],
      [PALLET],
      [CASE, PACK],
      [CASE],
    ],
  };

  updateFromTemplate = (e) => {
    this.setState({
      fromTemplate: parseBoolean(e.target.value),
    });
  };

  updateIsStandardHierarchy = (isStandardHierarchy) => () => {
    this.setState({
      isStandardHierarchy,
    });
  };

  isCaseConsumerUnit = (packagingTypeId, isConsumerUnit) => {
    return CASE.id === packagingTypeId && isConsumerUnit;
  };

  addTemplate = (hierarchy, addCurrentProduct) => () => {
    const { insertFirst, reference, isPatch } = this.props;
    const elements = hierarchy.map((typePackaging) => ({
      isConsumerUnit: false,
      isDespatchUnit: true,
      lifeCycle: lifeCycle.purchasable.id,
      typePackaging,
    }));
    this.props.actions.createTemplate(
      reference,
      elements,
      addCurrentProduct,
      insertFirst,
      isPatch,
    );
    this.props.onClose();
  };

  shouldRemovePalletLevel(targetMarketId) {
    return targetMarketId === UNITED_KINGDOM;
  }

  renderAddRoot() {
    const { actions, targetMarketId, insertFirst, isPatch, onClose } =
      this.props;
    const dropdownOptions = typePackagingOptions
      .filter((typePackaging) => {
        let included = typePackaging.id !== EACH.id;
        if (this.shouldRemovePalletLevel(targetMarketId)) {
          included = included && typePackaging.id !== PALLET.id;
        }
        return included;
      })
      .map((typePackaging) => ({
        label: typePackaging.label,
        onClick: () => {
          actions.createUnit(
            null,
            '',
            '',
            {
              isConsumerUnit: false,
              isDespatchUnit: true,
              lifeCycle: lifeCycle.purchasable.id,
              typePackaging,
            },
            insertFirst,
            isPatch,
          );
          onClose();
        },
      }));
    return (
      <div
        id="logistical-hierarchy-add-root"
        className="LogisticalHierarchies__addRoot"
      >
        <i className="mdi mdi-plus-circle" />
        <div className="LogisticalHierarchies__addRootLabel">
          {i18n.t(
            'frontproductstream.logistical_hierarchy_modal.create_hierarchy.text',
            { defaultValue: 'Create a new hierarchy' },
          )}
        </div>
        <Dropdown
          label={i18n.t(
            'frontproductstream.logistical_hierarchy_modal.create_hierarchy.help',
            { defaultValue: 'What is the highest level of the hierarchy?' },
          )}
          options={dropdownOptions}
        />
      </div>
    );
  }

  renderHierarchyElement = (typePackaging) => {
    return (
      <div className="CreateHierarchyModal-element" key={typePackaging.id}>
        <LogisticalHierarchyIcon packagingTypeId={typePackaging.id} />
        {getLogisticalTypePackagingLabel(
          getTypePackagingCode(typePackaging),
          true,
          true,
        )}
        <i className="mdi mdi-chevron-right" />
      </div>
    );
  };

  renderHierarchyLine = (elements, i) => {
    const { reference, packagingTypeId, hasTextileVariants } = this.props;
    const { isStandardHierarchy } = this.state;
    // If textile standard hierarchy then we don't add the model to put variants inside.
    const addCurrentProduct = !hasTextileVariants || !isStandardHierarchy;
    return (
      <div
        className="CreateHierarchyModal-line"
        key={`CreateHierarchyModal-line-${i}`}
        onClick={this.addTemplate(elements, addCurrentProduct)}
      >
        {elements.map(this.renderHierarchyElement)}
        {addCurrentProduct ? (
          <div className="CreateHierarchyModal-element">
            <LogisticalHierarchyIcon packagingTypeId={packagingTypeId} />
            <ProductReference reference={reference} inline />
          </div>
        ) : (
          <i
            key="more"
            className="mdi mdi-dots-horizontal CreateHierarchyModal__more"
          />
        )}
      </div>
    );
  };

  renderTemplates() {
    const { targetMarketId, hasTextileVariants, packagingTypeId } = this.props;

    let excludedLevelTypes = [];
    let requiredLevelTypes = [];
    if (this.shouldRemovePalletLevel(targetMarketId)) {
      excludedLevelTypes = [...excludedLevelTypes, PALLET.id];
    }
    if (hasTextileVariants) {
      excludedLevelTypes = [...excludedLevelTypes, PACK.id];
      requiredLevelTypes = [...requiredLevelTypes, CASE.id];
    }

    const lines = this.availableTemplates[packagingTypeId]
      .filter(
        (line) =>
          !excludedLevelTypes.length ||
          !line.find((e) => excludedLevelTypes.includes(e.id)),
      )
      .filter(
        (line) =>
          !requiredLevelTypes ||
          requiredLevelTypes.every((id) => !!line.find((e) => e.id === id)),
      );

    return (
      <div className="CreateHierarchyModal-list">
        {lines.map(this.renderHierarchyLine)}
      </div>
    );
  }

  renderBody() {
    const { fromTemplate } = this.state;
    return (
      <div className="CreateHierarchyModal">
        <Radio
          id="CreateHierarchyModal-radio"
          value={fromTemplate}
          onChange={this.updateFromTemplate}
          className="CreateHierarchyModal-Radio"
          options={templateOptions}
        />
        {fromTemplate && this.renderTemplates()}
        {!fromTemplate && this.renderAddRoot()}
      </div>
    );
  }

  renderTextileItem(title, description, value) {
    return (
      <div
        className="CreateHierarchyModal__textileChoiceItem"
        onClick={this.updateIsStandardHierarchy(value)}
      >
        <h3 className="CreateHierarchyModal__textileChoiceItemTitle">
          <strong>{title}</strong>
        </h3>
        <p>{description}</p>
      </div>
    );
  }

  renderTextileVariantChoice() {
    return (
      <div className="CreateHierarchyModal__textileChoice">
        {this.renderTextileItem(
          i18n.t(
            'frontproductstream.logistical_hierarchy_modal.textile_standard_case.text',
            { defaultValue: 'Standard case' },
          ),
          i18n.t(
            'frontproductstream.logistical_hierarchy_modal.textile_standard_case.help',
            {
              defaultValue:
                'The composition of the case cannot change. You indicate the number and quantity of variants composing it.',
            },
          ),
          true,
        )}
        {this.renderTextileItem(
          i18n.t(
            'frontproductstream.logistical_hierarchy_modal.textile_generic_case.text',
            { defaultValue: 'Generic case' },
          ),
          i18n.t(
            'frontproductstream.logistical_hierarchy_modal.textile_generic_case.help',
            {
              defaultValue:
                'The composition of the case is to be defined when ordering. You indicate the rules of the case composition.',
            },
          ),
          false,
        )}
      </div>
    );
  }

  render() {
    const { onClose, packagingTypeId, hasTextileVariants, isConsumerUnit } =
      this.props;
    const { isStandardHierarchy } = this.state;
    const showTextileChoice =
      isStandardHierarchy === null && hasTextileVariants;
    if (
      ![EACH.id, PACK.id].includes(packagingTypeId) &&
      !this.isCaseConsumerUnit(packagingTypeId, isConsumerUnit)
    ) {
      return null;
    }
    return (
      <Modal
        modalStyle="fullHeight"
        title={i18n.t(
          'frontproductstream.logistical_hierarchy_modal.create_hierarchy.title',
          { defaultValue: 'Create logistical hierachies' },
        )}
        onClose={onClose}
        hideFooter
      >
        {showTextileChoice
          ? this.renderTextileVariantChoice()
          : this.renderBody()}
      </Modal>
    );
  }
}

export default connect(
  mapStateToProps,
  mapDispatchToProps,
  separateActions,
)(CreateHierarchyModal);

import { createSelector } from '@reduxjs/toolkit';

const selectState = (state) => state.module.unsyncableRecipientsModal;

export const selectIsOpen = createSelector(selectState, (state) =>
  state.get('opened'),
);

export const selectUnsyncableRecipients = createSelector(selectState, (state) =>
  state.get('unsyncableRecipients'),
);

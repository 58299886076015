import ProductHeaderElements from './product-header-elements';
import ProductHeaderNavigation from './product-header-navigation';
import './product-header.scss';

const ProductHeader = () => (
  <div className="ProductHeader">
    <ProductHeaderElements />
    <ProductHeaderNavigation />
  </div>
);

export default ProductHeader;

import { selectHasStageGatesFilterFeature } from 'modules/feature-flag/selectors';

export const requiredFieldsCategoryFilter = {
  key: 'alkemics.stage_gates.current.id',
  aggregation: {
    value_field: 'alkemics.stage_gates.current.label.raw',
  },
  query: 'stagegates',
  isAvailable: ({ user } = {}) => selectHasStageGatesFilterFeature(user),
};

import classNames from 'classnames';
import { ReactNode, Ref, forwardRef } from 'react';

import './table.scss';

interface Props {
  children: ReactNode;
  className?: string;
  scrollable?: boolean;
  margin?: number;
  isSticky?: boolean;
  noPadding?: boolean;
}

interface InnerProps {
  children: ReactNode;
  className?: string;
  scrollable: boolean;
  margin: number;
  innerRef?: Ref<HTMLDivElement>;
  isSticky?: boolean;
  noPadding?: boolean;
}

const _TableBody = ({
  children,
  className,
  scrollable,
  margin,
  innerRef,
  isSticky,
  noPadding,
}: InnerProps) => {
  const withMargin = scrollable && margin > 0;
  return (
    <div
      ref={innerRef}
      className={classNames(
        'TableBody',
        className,
        scrollable && 'TableBody--scrollable',
        withMargin && 'TableBody--withMargin',
      )}
    >
      {scrollable ? (
        <div
          className={classNames({
            TableBody__scroller: scrollable && !isSticky,
            TableBody__scroller__sticky: scrollable && isSticky,
            'TableBody__scroller--padding': !noPadding,
          })}
        >
          {withMargin ? (
            <div
              className="TableBody__scrollerWithMargin"
              style={{ marginRight: `calc(${margin}px + 0.5rem)` }}
            >
              {children}
            </div>
          ) : (
            children
          )}
        </div>
      ) : (
        children
      )}
    </div>
  );
};

const TableBody = forwardRef<HTMLDivElement, Props>(
  ({ children, ...props }, ref) => {
    const defaultedProps = { scrollable: false, margin: 0, ...props };
    return (
      <_TableBody {...defaultedProps} innerRef={ref}>
        {children}
      </_TableBody>
    );
  },
);

export { TableBody };
export default TableBody;

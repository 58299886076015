import { formatDownload } from 'utils/links';
import { getAppHostname } from 'utils/location';

const hostname = getAppHostname();

export const TECHNICAL_DOCUMENTATION_LINK = `https://docs.${hostname}/`;

export const TERMS_AND_CONDITIONS_LINK =
  'https://www.salsify.com/fr/terms-of-service-2022-v1';

export const GDSN_MANUFACTURER_DOC_LINK = formatDownload(
  '/stream/brand/onboarding/Doc_GDSN_Industriels.pdf',
);

export const ADMIN_LINK_DEV = `http://ppr-localadmin.${hostname}:9006`;
export const ADMIN_LINK_PPR = `https://ppr-admin.${hostname}`;
export const ADMIN_LINK_PRD = `https://admin.${hostname}`;

import {
  STATUS_BAR_DANGER,
  STATUS_BAR_DEFAULT,
  STATUS_BAR_SUCCESS,
} from 'components/ui/status-bar';
import i18n from 'utils/i18n';

export const SourceProductStatuses = {
  DRAFT: {
    name: i18n.t(
      'frontproductstream.constants.source_product_status.not_published',
      { defaultValue: 'Not published' },
    ),
    className: 'DRAFT',
    id: 'DRAFT',
    statusBarType: STATUS_BAR_DEFAULT,
  },
  PUBLISHED: {
    name: i18n.t(
      'frontproductstream.constants.source_product_status.published',
      { defaultValue: 'Published' },
    ),
    className: 'PUBLISHED',
    id: 'PUBLISHED',
    statusBarType: STATUS_BAR_SUCCESS,
  },
  INFORMATION_EXPECTED: {
    name: i18n.t(
      'frontproductstream.constants.source_product_status.information_expected',
      { defaultValue: 'Information expected' },
    ),
    className: 'INFORMATION_EXPECTED',
    id: 'INFORMATION_EXPECTED',
    statusBarType: STATUS_BAR_DANGER,
  },
};

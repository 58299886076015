export const RECEIVE_OVERRIDABLE_FIELDS =
  'recipient-specific-block/RECEIVE_OVERRIDABLE_FIELDS';
export const ADD_OVERRIDE = 'recipient-specific-block/ADD_OVERRIDE';
export const DELETE_OVERRIDE = 'recipient-specific-block/DELETE_OVERRIDE';
export const RECEIVE_OVERRIDE_DATA =
  'recipient-specific-block/RECEIVE_OVERRIDE_DATA';

export const RECEIVE_SPECIFIC_FIELDS =
  'recipient-specific-block/RECEIVE_SPECIFIC_FIELDS';

export const ADD_RECIPIENT = 'recipient-specific-block/ADD_RECIPIENT';

export const TOGGLE_STATUS = 'specific-assets/TOGGLE_STATUS';
export const STATUS_TOGGLED = 'specific-assets/STATUS_TOGGLED';
export const ADD_SPECIFIC_ASSETS = 'specific-assets/ADD_SPECIFIC_ASSETS';
export const DELETE_SPECIFIC_ASSETS = 'specific-assets/DELETE_SPECIFIC_ASSETS';
export const SPECIFIC_ASSETS_DELETED =
  'specific-assets/SPECIFIC_ASSETS_DELETED';

import { Map, fromJS } from 'immutable';
import { once } from 'lodash';
import PropTypes from 'prop-types';
import { PureComponent } from 'react';
import ImmutablePropTypes from 'react-immutable-proptypes';

import { SwitchButton } from '@alkem/react-ui-button';

import { LifeCycleMap } from 'constants/filters/lifeCycle';
import { lifecycleFilter } from 'core/modules/list/constants/filters/lifecycle';
import { buildFiltersFromQuery } from 'core/modules/list/utils/filters';
import i18n from 'utils/i18n';

import CollapsibleFilter from '../collapsible';
import FilterItem from '../simple/item';

import './index.scss';

const { prelaunch, purchasable, notPurchasable, archived } = LifeCycleMap;

export class LifecycleFilter extends PureComponent {
  static propTypes = {
    selectedFilterMap: ImmutablePropTypes.map,
    aggregations: ImmutablePropTypes.map,
    collapsed: PropTypes.bool,
    onChange: PropTypes.func.isRequired,
    onCollapse: PropTypes.func.isRequired,
    filterQueryValues: PropTypes.array,
    withArchived: PropTypes.bool,
    onChangeWithArchived: PropTypes.func.isRequired,
  };

  static defaultProps = {
    selectedFilterMap: Map(),
    aggregations: Map(),
    collapsed: true,
    filterQueryValues: [],
    withArchived: false,
  };

  filterKey = lifecycleFilter.key;

  updateSelectionFromQuery = once(() => {
    buildFiltersFromQuery({
      filterQueryValues: this.props.filterQueryValues,
      filterList: this.filterList,
      filterKey: this.filterKey,
      selectFilterValue: (filter) => filter.get('id'),
      selectFilterLabel: (filter) =>
        `${this.filterLabel}: ${filter.get('label')}`,
      selectFilterData: (filter) => filter,
    }).then((filtersFromQuery) => {
      this.props.onChange(filtersFromQuery, true);
    });
  });

  filterList = fromJS(
    [prelaunch, purchasable, notPurchasable, archived].map((filter) => ({
      id: filter.id,
      label: filter.name,
      iconClassName: `ListLifecycleFilterIcon ${filter.icon}`,
    })),
  );

  filterLabel = i18n.t('frontproductstream.core.list_filter_lifecycle.label', {
    defaultValue: 'Life Cycle',
  });

  componentDidMount() {
    if (this.shouldUpdateSelectionFromQuery()) {
      this.updateSelectionFromQuery();
    }
  }

  componentDidUpdate() {
    if (this.shouldUpdateSelectionFromQuery()) {
      this.updateSelectionFromQuery();
    }
  }

  onCollapse = (collapsed) => {
    this.props.onCollapse(this.filterKey, collapsed);
  };

  shouldUpdateSelectionFromQuery = () => {
    const { aggregations, selectedFilterMap } = this.props;
    return !aggregations.isEmpty() && selectedFilterMap.isEmpty();
  };

  onChange = (filter, selected) => {
    this.props.onChange({
      key: this.filterKey,
      value: filter.get('id'),
      label: `${this.filterLabel}: ${filter.get('label')}`,
      add: selected,
      data: filter,
    });
  };

  onChangeShowArchived = (isSelected) => {
    const { onChangeWithArchived, selectedFilterMap, onChange } = this.props;
    onChangeWithArchived(isSelected);
    if (!isSelected && selectedFilterMap.get(archived.id)) {
      onChange(
        {
          key: this.filterKey,
          value: archived.id,
          add: false,
        },
        true,
      );
    }
  };

  render() {
    const { collapsed, aggregations, selectedFilterMap, withArchived } =
      this.props;
    const hasDocCount = this.filterList.some(
      (filter) =>
        aggregations.getIn([this.filterKey, filter.get('id'), 'doc_count']) >=
        0,
    );
    return (
      <CollapsibleFilter
        id="list-filter-lifecycle"
        label={this.filterLabel}
        collapsed={collapsed}
        onCollapse={this.onCollapse}
      >
        <div className="ListLifecycleFilter__switch">
          <SwitchButton
            content={i18n.t(
              'frontproductstream.core.list_filter_lifecycle.show_archived_button',
              { defaultValue: 'Show archived products' },
            )}
            onChange={this.onChangeShowArchived}
            checked={withArchived}
          />
        </div>
        {this.filterList.map((filter) =>
          filter.get('id') === archived.id ? (
            <FilterItem
              key={`list-simple-filter-${this.filterKey}-${filter.get('id')}`}
              filterKey={this.filterKey}
              filter={filter}
              aggregation={aggregations.getIn([
                this.filterKey,
                filter.get('id'),
              ])}
              selected={
                !!selectedFilterMap.get(filter.get('id')) && withArchived
              }
              disabled={!withArchived}
              hasDocCount={withArchived && hasDocCount}
              onChange={this.onChange}
            />
          ) : (
            <FilterItem
              key={`list-simple-filter-${this.filterKey}-${filter.get('id')}`}
              filterKey={this.filterKey}
              filter={filter}
              aggregation={aggregations.getIn([
                this.filterKey,
                filter.get('id'),
              ])}
              selected={!!selectedFilterMap.get(filter.get('id'))}
              hasDocCount={hasDocCount}
              onChange={this.onChange}
            />
          ),
        )}
      </CollapsibleFilter>
    );
  }
}

import { call } from 'redux-saga/effects';

import { size } from 'utils/immutable';

import { attachUserLabelV4 } from '../actions/apiV4';
import { getLinksFromProductKeyIds } from '../utils';

export default function* attach({
  userLabelIds,
  productKeyIds,
}: {
  userLabelIds: number[];
  productKeyIds: number[];
}) {
  if (!size(userLabelIds) || !size(productKeyIds)) {
    return {};
  }

  const { error } = yield call(attachUserLabelV4, {
    links: getLinksFromProductKeyIds(productKeyIds, userLabelIds),
  });
  if (error) {
    return { error };
  }
  return {};
}

import { func, string } from 'prop-types';

import { Button } from '@alkem/react-ui-button';

import SimpleModal from 'components/ui/simple-modal';
import i18n from 'utils/i18n';

import './upsell.scss';

function UpsellModal({
  onUpsell,
  title = i18n.t(
    'frontproductstream.reporting_page.upsell_call_to_action.title',
    { defaultValue: 'Want more reportings?' },
  ),
  content = i18n.t(
    'frontproductstream.reporting_page.upsell_call_to_action.body',
    {
      defaultValue:
        'Alkemics offers unique insights into your products that make collaboration more effective.',
    },
  ),
}) {
  return (
    <SimpleModal size="small">
      <h1>{title}</h1>
      <p>{content}</p>
      <Button
        content={i18n.t(
          'frontproductstream.reporting_page.upsell_call_to_action.button',
          { defaultValue: 'Request more information' },
        )}
        onClick={onUpsell}
        primary
      />
    </SimpleModal>
  );
}

UpsellModal.displayName = 'ReportingUpsellModal';
UpsellModal.propTypes = {
  title: string,
  content: string,
  onUpsell: func,
};

export default UpsellModal;

import i18n from 'utils/i18n';

export interface ProductReferenceType {
  fieldName: string;
  displayValue: () => string;
}

export const ProductReferenceTypes = {
  GTIN: {
    fieldName: 'gtin',
    displayValue: () =>
      i18n.t('frontproductstream.constants.product_reference_type.gtin', {
        defaultValue: 'GTIN',
      }),
  },
  IDENTIFIER: {
    fieldName: 'productIdentifier',
    displayValue: () =>
      i18n.t('frontproductstream.constants.product_reference_type.identifier', {
        defaultValue: 'IDENTIFIER',
      }),
  },
  UNKNOWN: {
    fieldName: '',
    displayValue: () =>
      i18n.t('frontproductstream.constants.product_reference_type.unknown', {
        defaultValue: 'UNKNOWN_PRODUCT_REFERENCE_TYPE',
      }),
  },
};

import { useController, useWatch } from 'react-hook-form';
import { useSelector } from 'react-redux';

import { Select } from '@alkem/react-ui-select';

import { PictureAssetV2 } from 'components/ui/form/asset-list/AssetV2/types';
import { selectReferentials } from 'modules/assets/selectors';
import i18n from 'utils/i18n';
import { get } from 'utils/immutable';

type AssetAutocompleteProps = {
  name: keyof PictureAssetV2;
  slug: string;
  isReadOnly: boolean;
};

export const AssetAutoComplete = ({
  name,
  slug,
  isReadOnly,
}: AssetAutocompleteProps) => {
  const referentials = useSelector(selectReferentials);

  const crc32 = useWatch({ name: 'crc32' });

  const {
    field: { onChange, value },
  } = useController({ name: name.toString() });

  const options = get(referentials, slug) || [];
  const selectedValue = options.find((e) => e.id === value);
  const key = `ProductPictureForm-${crc32}-${name}`;

  return (
    <Select
      id={key}
      key={key}
      options={options}
      values={selectedValue ? [selectedValue] : []}
      onValueAdd={({ id }: { id: number }) => onChange(id)}
      onValueDelete={() => onChange(null)}
      disabled={isReadOnly}
      inputable
      placeholder={i18n.t(
        'frontproducstream.asset_list.modal.select.placeholder',
        { defaultValue: 'Select...' },
      )}
    />
  );
};

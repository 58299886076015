import { call, put, select, takeLatest } from 'redux-saga/effects';

import { ResponseWithData } from '@alkem/sdk-dashboard';

import { selectUser } from 'modules/user';
import funnel, { Options as FunnelOptions } from 'utils/funnel';
import { BaseFunnelTask, FunnelProgress } from 'utils/funnel/type';
import { get } from 'utils/immutable';
import { withCatch } from 'utils/saga';
import { track } from 'utils/tracking';

import { sendMessageDone, sendMessageInProgress } from '../actions';
import { SEND_MESSAGE_START } from '../events';
import { selectSupplier } from '../selectors';
import {
  ManufacturerInfoEventTracking,
  SendMessageInProgressActionPayload,
  SendMessageStartPayload,
} from '../types';

import sendMessage from './call-send-message';

export type SendMessageTaskResult = ResponseWithData<any>;

export type SendMessageTask = BaseFunnelTask<SendMessageTaskResult> & {
  recipientId: number;
  recipientName: string;
  message: string;
};

export function* bulkSendMessageMainSaga() {
  yield takeLatest(SEND_MESSAGE_START, withCatch(SendMessageSaga));
}

function* onProgress({
  outcome,
  task: { recipientId, recipientName, message },
}: FunnelProgress<ResponseWithData<any>, SendMessageTask>) {
  const progress: SendMessageInProgressActionPayload = {
    entityId: recipientId,
    hasError: false,
    resultMessage: `Message successfully sent to manufacturer ${recipientName}`,
    message: message,
    recipientName: recipientName,
  };
  switch (outcome.status) {
    case 'failure': {
      const {
        error: { message: ErrorMessage },
      } = outcome;
      progress.hasError = true;
      progress.resultMessage = `${ErrorMessage}`;
    }
  }
  yield put(sendMessageInProgress(progress));
}

function* SendMessageSaga(payload: SendMessageStartPayload) {
  const { messagesToSend = [] } = payload;
  if (messagesToSend.length === 0) {
    return;
  }
  const supplier = yield select(selectSupplier);
  const user = yield select(selectUser);

  const manufacturerInfoToTrack: ManufacturerInfoEventTracking[] = [];
  const tasks: SendMessageTask[] = messagesToSend.map((messageToSend) => {
    const recipientId = messageToSend.recipientId;
    manufacturerInfoToTrack.push({
      manufacturer_id: messageToSend.recipientId,
      manufacturer_name: messageToSend.recipientName,
    });
    return {
      id: `task for message to manufacturer ${recipientId}`,
      runner: sendMessage(messageToSend),
      recipientId: messageToSend.recipientId,
      recipientName: messageToSend.recipientName,
      message: messageToSend.message,
    };
  });

  // same event when you try for the first time or when you retry
  yield call(track, {
    category: 'request_status_modal',
    action: 'send_answer_when_request_refused',
    retailer_name: get(user, ['belongsTo', 0, 'nameLegal']),
    retailer_id: get(user, ['belongsTo', 0, 'id']),
    user_id: get(user, 'id'),
    supplier_name: supplier && supplier.name,
    supplier_internal_id: supplier && supplier.internalId,
    manufacturers: manufacturerInfoToTrack,
  });

  try {
    const options: FunnelOptions<SendMessageTaskResult, SendMessageTask> = {
      onProgress,
    };

    yield call<
      (
        tasks: SendMessageTask[],
        options: FunnelOptions<SendMessageTaskResult, SendMessageTask>,
      ) => any
    >(funnel, tasks, options);
  } finally {
    yield put(sendMessageDone());
  }
}

import { fork } from 'redux-saga/effects';

import applySagas from './apply';
import { watchArchive } from './archive';
import { watchDelete } from './delete';
import { watchFetchDisplayGroups } from './display-groups';
import duplicateSaga from './duplicate';
import { watchExport } from './export';
import { watchFetch } from './get';
import productsSaga from './products';
import { watchPublish } from './publish';
import { watchSave } from './save';
import { watchValidate } from './validate';

export default function* sharingUnitsTemplateSaga() {
  yield fork(watchFetchDisplayGroups);
  yield fork(watchFetch);
  yield fork(watchSave);
  yield fork(watchPublish);
  yield fork(watchDelete);
  yield fork(watchValidate);
  yield fork(watchArchive);
  yield fork(productsSaga);
  yield fork(duplicateSaga);
  yield fork(applySagas);
  yield fork(watchExport);
}

import PropTypes from 'prop-types';
import { PureComponent } from 'react';
import ImmutablePropTypes from 'react-immutable-proptypes';
import { connect } from 'react-redux';

import { Spinner } from '@alkem/react-ui-spinner';

import {
  selectSharingUnitsAgreed,
  selectSharingUnitsLoading,
} from '../selectors';

import SharingUnits from '.';
import './agreed.scss';

const mapStateToProps = (state) => ({
  sharingUnitsAgreedIds: selectSharingUnitsAgreed(state),
  sharingUnitsLoading: selectSharingUnitsLoading(state),
});

export class SharingUnitsAgreed extends PureComponent {
  static propTypes = {
    // own props
    order: PropTypes.number.isRequired,

    // props from store
    sharingUnitsAgreedIds: ImmutablePropTypes.list.isRequired,
    sharingUnitsLoading: PropTypes.bool,
  };

  render() {
    const { order, sharingUnitsAgreedIds, sharingUnitsLoading } = this.props;
    if (sharingUnitsLoading) {
      return (
        <div className="SharingUnitsAgreed__loading" data-testid="spinner">
          <Spinner big />
        </div>
      );
    }

    if (sharingUnitsAgreedIds.size < 1) {
      return null;
    }

    return (
      <SharingUnits
        id="SharingUnit__agreed"
        sharingUnitIds={sharingUnitsAgreedIds}
        order={order}
      />
    );
  }
}

export default connect(mapStateToProps)(SharingUnitsAgreed);

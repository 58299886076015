import { call, put, select } from 'redux-saga/effects';

import { notificationClear } from 'actions/notification';
import { selectOrganizationId } from 'modules/user';
import { SharingUnitTariffsDetails } from 'routes';
import { processSaveErrors } from 'utils/actions/saveErrors';
import { replace } from 'utils/history';
import { logError } from 'utils/logging';
import { fill } from 'utils/routing';
import { takeEverySafe } from 'utils/saga';
import { track } from 'utils/tracking';

import api from '../api';
import { SAVE, SAVE_FAILURE, SAVE_SUCCESS } from '../constants';
import {
  selectId,
  selectName,
  selectSelectedRecipientId,
  selectSharingUnitTariffData,
  selectType,
} from '../selectors';

export function* watchSave() {
  yield takeEverySafe(SAVE, save);
}

export function* save() {
  const selectedRecipientId = yield select(selectSelectedRecipientId);
  if (!selectedRecipientId) {
    return;
  }
  yield put(notificationClear());

  const id = yield select(selectId);
  const name = yield select(selectName);
  const type = yield select(selectType);
  const sourceOrganizationId = yield select(selectOrganizationId);
  const data = yield select(selectSharingUnitTariffData);
  let result;
  let error;

  let redirect = false;
  if (id) {
    ({ result, error } = yield call(api.update, id, name, data));
  } else {
    ({ result, error } = yield call(
      api.create,
      name,
      sourceOrganizationId,
      selectedRecipientId,
      data,
      type,
    ));
    redirect = true;

    yield call(track, {
      category: 'tariff',
      action: 'tariff_created',
      label: selectedRecipientId,
    });
  }

  if (result) {
    const template = result.data;
    yield put({
      type: SAVE_SUCCESS,
      template,
    });
    if (redirect) {
      yield call(replace, fill(SharingUnitTariffsDetails, template.id));
    }
  } else {
    yield put({ type: SAVE_FAILURE, error });
    yield put(processSaveErrors(/** @type {any} */ ({ error })));
    yield call(logError, error);
  }
}

import classNames from 'classnames';
import { RefObject, useMemo, useState } from 'react';

import ListTablePanel from 'core/modules/list/components/table/panel';
import i18n from 'utils/i18n';

import type { MaturitiesSummary } from '../types';
import { isScenarioComplete } from '../types';

import './index.scss';
import { ActiveScenarios } from './maturity-panel-active-scenarios';
import { UpcomingDeadlines } from './maturity-panel-deadlines';

interface Props {
  data: MaturitiesSummary;
  rowIdentifier?: number;
  cellRef: RefObject<HTMLDivElement>;
  onClose: () => void;
  selectedTab?: MaturityPanelTabIndex;
}
export enum MaturityPanelTabIndex {
  COMPLETION = 0,
  UPCOMING = 1,
}

const MaturityPanelBody = ({
  data,
  selectedTab,
}: {
  data: MaturitiesSummary;
  selectedTab: MaturityPanelTabIndex;
}) => {
  const [selectedTabIndex, setSelectedTabIndex] = useState(selectedTab);

  const isScenarioCompletionTabSelected = useMemo(() => {
    return selectedTabIndex === MaturityPanelTabIndex.COMPLETION;
  }, [selectedTabIndex]);

  const isUpcomingDeadlinesTabSelected = useMemo(() => {
    return selectedTabIndex === MaturityPanelTabIndex.UPCOMING;
  }, [selectedTabIndex]);

  const selectScenarioCompletionTab = () => {
    setSelectedTabIndex(MaturityPanelTabIndex.COMPLETION);
  };

  const selectUpcomingDeadlinesTab = () => {
    setSelectedTabIndex(MaturityPanelTabIndex.UPCOMING);
  };

  const badgeCount = (data.upcoming_scenarios || []).filter(
    (maturity) => !isScenarioComplete(maturity),
  ).length;

  return (
    <div className="MaturityPanel alk-flex alk-flex-column">
      <div className="MaturityPanel__tabs">
        <div
          className={classNames('MaturityPanel__tab', 'alk-flex-1', {
            MaturityPanel__tab__selected: isScenarioCompletionTabSelected,
          })}
          onClick={() => selectScenarioCompletionTab()}
        >
          {i18n.t(
            'frontproductstream.maturity_panel.active_scenarios_tab.title',
            {
              defaultValue: 'Active scenarios',
            },
          )}
        </div>
        <div
          className={classNames('MaturityPanel__tab', 'alk-flex-1', {
            MaturityPanel__tab__selected: isUpcomingDeadlinesTabSelected,
          })}
          onClick={() => selectUpcomingDeadlinesTab()}
        >
          {i18n.t(
            'frontproductstream.maturity_panel.upcoming_deadlines_tab.title',
            {
              defaultValue: 'Upcoming updates',
            },
          )}
          {!!badgeCount && (
            <span className="MaturityPanel__badge" data-testid="upcomingBadge">
              {badgeCount}
            </span>
          )}
        </div>
      </div>
      <div className="MaturityPanel__tabsContents">
        {isScenarioCompletionTabSelected && <ActiveScenarios data={data} />}
        {isUpcomingDeadlinesTabSelected && (
          <UpcomingDeadlines upcomingScenarios={data.upcoming_scenarios} />
        )}
      </div>
    </div>
  );
};

export const MaturityPanel = ({
  cellRef,
  rowIdentifier,
  onClose,
  data,
  selectedTab,
}: Props) => {
  const header = (
    <div className="alk-flex alk-flex-center">
      <h3 className="MaturityPanel__title">
        {i18n.t('frontproductstream.maturity_panel.title.text', {
          defaultValue: 'Scenarios completion',
        })}
      </h3>
    </div>
  );

  const body = (
    <MaturityPanelBody
      data={data}
      selectedTab={selectedTab || MaturityPanelTabIndex.COMPLETION}
    />
  );

  return (
    <ListTablePanel
      cellRef={cellRef}
      rowIdentifier={rowIdentifier}
      onClose={onClose}
      body={body}
      header={header}
    />
  );
};

import classNames from 'classnames';
import PropTyps from 'prop-types';

import i18n from 'utils/i18n';

import './published-badge.scss';

const PublishedBadge = ({ isPublished }) => (
  <span
    className={classNames('PublishedBadge', {
      'PublishedBadge--published': isPublished,
      'PublishedBadge--draft': !isPublished,
    })}
  >
    {isPublished ? i18n.t('Published') : i18n.t('Not published')}
  </span>
);

PublishedBadge.propTypes = {
  isPublished: PropTyps.bool.isRequired,
};

export default PublishedBadge;

import PropTypes from 'prop-types';
import { PureComponent } from 'react';
import ImmutablePropTypes from 'react-immutable-proptypes';
import { connect } from 'react-redux';

import {
  TargetProductStatusSubmitted,
  TargetProductStatusUpdated,
} from 'constants/targetProductStatus';
import { getTargetProductStatus } from 'core/api/retailerproductversion';
import { isRetailer } from 'core/api/user';
import i18n from 'utils/i18n';

import { acceptBulk } from './action';

const mapDispatchToProps = {
  acceptBulk,
};

const canBeValidated = (pv) =>
  [TargetProductStatusSubmitted.id, TargetProductStatusUpdated.id].includes(
    getTargetProductStatus(pv),
  );

const filterProdutsToValidate = ({ selectedMap, productMap }) =>
  productMap
    .filter((pv, i) => selectedMap.get(i) === true)
    .filter(canBeValidated);

export class BulkValidation extends PureComponent {
  static propTypes = {
    acceptBulk: PropTypes.func,
    selectedMap: ImmutablePropTypes.map.isRequired,
    productMap: ImmutablePropTypes.map.isRequired,
    disabled: PropTypes.bool,
  };

  static shouldBeDisplayed = ({ user }) => isRetailer(user);

  static isEnabled = ({ productMap, selectedMap }) =>
    productMap.filter((pv, i) => selectedMap?.get(i) === true).size > 0 &&
    productMap
      .filter((pv, i) => selectedMap.get(i) === true)
      .filter((pv) => !canBeValidated(pv)).size === 0;

  acceptBulk = () => {
    const { selectedMap, productMap, disabled } = this.props;
    if (disabled) {
      return;
    }
    const productKeyIds = filterProdutsToValidate({ selectedMap, productMap })
      .valueSeq()
      .map((productVersion) => productVersion.getIn(['product_key', 'id']));
    this.props.acceptBulk(productKeyIds);
  };

  render() {
    const { selectedMap, productMap } = this.props;
    return (
      <div className="ActionOption" onClick={this.acceptBulk}>
        {i18n.t('Validate {{count}} products', {
          count: filterProdutsToValidate({ selectedMap, productMap }).size,
        })}
      </div>
    );
  }
}

export default connect(null, mapDispatchToProps)(BulkValidation);

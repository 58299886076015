import { createAction } from 'utils/redux';

import {
  ACCEPT,
  ADD_PRODUCT_SUCCESS,
  ARCHIVE,
  CANCEL_PRODUCTS_POLLING,
  CLEAR_STORE,
  CLOSE_DUPLICATE_MODAL,
  CLOSE_PUBLISH_MODAL,
  CLOSE_REJECT_MODAL,
  COLLAPSE_FILTERS,
  DELETE,
  DELETE_SHARING_UNIT,
  DUPLICATE,
  ERROR_SEARCHING_PRODUCT,
  EXPORT,
  EXPORT_BULK,
  EXPORT_BULK_TARIFF_ONE_FILE,
  EXPORT_CANCEL,
  EXPORT_FORMAT, // Products
  FETCH_PRODUCTS_SUCCESS,
  FETCH_PUBLICATION_RESULTS,
  FETCH_TEMPLATE,
  FETCH_TEMPLATES,
  OPEN_DUPLICATE_MODAL,
  OPEN_PUBLISH_MODAL,
  OPEN_REJECT_MODAL,
  PUBLISH,
  REJECT,
  RELOAD_SHARING_UNIT,
  SAVE,
  SEARCH_LIST,
  SELECT_RECIPIENT,
  SELECT_TARIFFS_IN_LIST,
  UNSELECT_TARIFFS_IN_LIST,
  UPDATE_CURRENT_PAGE,
  UPDATE_CURRENT_PRODUCTS_PAGE,
  UPDATE_FILTER_IN_ERROR,
  UPDATE_FILTER_PAGE,
  UPDATE_FILTER_SEARCH,
  UPDATE_SEARCH,
  UPDATE_SELECTED_FILTERS,
  VALIDATE,
} from './constants';

export const selectCurrentRecipient = (
  recipientId: number,
  type?: string | number,
) => ({
  type: SELECT_RECIPIENT,
  recipientId,
  templateType: type,
});

export const clearStore = () => ({
  type: CLEAR_STORE,
});

export const save = () => ({
  type: SAVE,
});

export const fetchTemplate = (id) => ({
  type: FETCH_TEMPLATE,
  id,
});

export const openPublishModal = () => ({
  type: OPEN_PUBLISH_MODAL,
});

export const closePublishModal = () => ({
  type: CLOSE_PUBLISH_MODAL,
});

export const publish = () => ({
  type: PUBLISH,
});

export const deleteTemplate = () => ({
  type: DELETE,
});

export const openDuplicateModal = () => ({
  type: OPEN_DUPLICATE_MODAL,
});

export const closeDuplicateModal = () => ({
  type: CLOSE_DUPLICATE_MODAL,
});

export const duplicateTemplate = (name, duplicateSharingUnits) => ({
  type: DUPLICATE,
  name,
  duplicateSharingUnits,
});

export const validate = (checkImports = false) => ({
  type: VALIDATE,
  checkImports,
});

export const archive = () => ({
  type: ARCHIVE,
});

// Products
export const receiveProducts = createAction(FETCH_PRODUCTS_SUCCESS);
export const updateCurrentProductsPage = createAction(
  UPDATE_CURRENT_PRODUCTS_PAGE,
);
export const updateSearch = createAction(UPDATE_SEARCH);
export const errorSearchingProduct = createAction(ERROR_SEARCHING_PRODUCT);
export const updateFilterInError = createAction(UPDATE_FILTER_IN_ERROR);
export const deleteSharingUnit = createAction(DELETE_SHARING_UNIT);
export const reloadSharingUnit = createAction(RELOAD_SHARING_UNIT);
export const cancelProductsPolling = createAction(CANCEL_PRODUCTS_POLLING);

// Product
export const productAdded = createAction(ADD_PRODUCT_SUCCESS);

/* List */
export const updateFilterSearch = createAction(UPDATE_FILTER_SEARCH);
export const fetchTemplates = createAction(FETCH_TEMPLATES);
export const collapseFilters = createAction(COLLAPSE_FILTERS);
export const updateFilters = createAction(UPDATE_SELECTED_FILTERS);
export const updateFilterPage = createAction(UPDATE_FILTER_PAGE);
export const updateCurrentPage = createAction(UPDATE_CURRENT_PAGE);
export const selectTariffsInList = (tariffIds, tariffUuids) => ({
  type: SELECT_TARIFFS_IN_LIST,
  tariffIds,
  tariffUuids,
});
export const unselectTariffsInList = (tariffIds, tariffUuids) => ({
  type: UNSELECT_TARIFFS_IN_LIST,
  tariffIds,
  tariffUuids,
});
export const searchList = createAction<string>(SEARCH_LIST);

// Export
export const exportTariff = createAction(EXPORT);
export const cancelTariffExport = createAction(EXPORT_CANCEL);
export const exportTariffs = (tariffs) => ({
  type: EXPORT_BULK,
  tariffs,
});

export const updateExportFormat = createAction<string>(EXPORT_FORMAT);
export const exportBulkTariffOneFile = createAction<string>(
  EXPORT_BULK_TARIFF_ONE_FILE,
);

// Reject
export const openRejectModal = createAction(OPEN_REJECT_MODAL);
export const closeRejectModal = createAction(CLOSE_REJECT_MODAL);
export const reject = (reason) => ({ type: REJECT, reason });

// Accept
export const accept = createAction(ACCEPT);

// Publication report
export const fetchPublicationResults = (offset) => ({
  type: FETCH_PUBLICATION_RESULTS,
  offset,
});

import { selectHasTariffFilterSearch } from 'modules/feature-flag/selectors';
import { UserImmutable } from 'types';

export const sharingUnitTariffSupplierCodesFilter = {
  key: 'supplierCodes',
  query: 'supplierCodes',
  isAvailable: ({
    isRetailer,
    user,
  }: {
    isRetailer: boolean;
    user: UserImmutable;
  }) => isRetailer && selectHasTariffFilterSearch(user),
  aggregationKey: 'supplier_codes',
};

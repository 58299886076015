import { startLoading, stopLoading } from 'actions/loading';
import { notificationError } from 'actions/notification';
import { authApiImmutable } from 'resources/authApi';
import { segmentHierarchyApiImmutable } from 'resources/segmentHierarchyApi';
import i18n from 'utils/i18n';
import { createAction } from 'utils/redux';

import {
  RECEIVE_PRODUCT_SEGMENT_PERMISSIONS,
  RECEIVE_SEGMENTS,
  RESET_PRODUCT_SEGMENT_PERMISSIONS,
  UPDATE_PRODUCT_SEGMENT_PERMISSIONS,
} from './types';

export const receiveSegments = createAction(RECEIVE_SEGMENTS);

export function receiveProductSegmentPermissions(permissions) {
  return {
    type: RECEIVE_PRODUCT_SEGMENT_PERMISSIONS,
    payload: { permissions },
  };
}

export function resetProductSegmentPermissions() {
  return { type: RESET_PRODUCT_SEGMENT_PERMISSIONS };
}

export function getProductSegmentsWithPermissions(userId, organizationId) {
  return (dispatch) => {
    dispatch(startLoading());
    dispatch(resetProductSegmentPermissions());
    return Promise.all([
      segmentHierarchyApiImmutable.list({
        queries: { organizationId, withUnclassified: true },
        useCache: false,
      }),
      authApiImmutable.ListProductSegmentsPermissions(userId),
    ]).then(
      (results) => {
        dispatch(receiveSegments(results[0].data.data));
        dispatch(
          receiveProductSegmentPermissions(
            results[1].data.data.get('productsegments'),
          ),
        );
        dispatch(stopLoading());
      },
      () => {
        dispatch(stopLoading());
        dispatch(
          notificationError(
            i18n.t(
              'frontproductstream.admin_user_management.managed_segments_retrieving_notification.error',
              {
                defaultValue:
                  'An error occured while retrieving user segments with permissions',
              },
            ),
          ),
        );
      },
    );
  };
}

export const updateProductSegmentPermissions = (
  segmentId,
  right,
  add,
  path,
) => ({
  type: UPDATE_PRODUCT_SEGMENT_PERMISSIONS,
  segmentId,
  right,
  add,
  path,
});

import { noop } from 'lodash';
import { useSelector } from 'react-redux';

import InputWithLabel from 'components/ui/input/input-with-label';
import InputText from 'components/ui/input/text';
import { selectCustomSkuGtinZpckSetting } from 'modules/user';
import i18n from 'utils/i18n';

type ExtraInfosProps = {
  sku?: string;
  gtins?: string[];
  zpck?: string;
};

export const ExtraInfos = ({ sku, gtins, zpck }: ExtraInfosProps) => {
  const hasCustomSkuGtinZpckSetting = useSelector(
    selectCustomSkuGtinZpckSetting,
  );

  if (!hasCustomSkuGtinZpckSetting) return null;

  return (
    <>
      <InputWithLabel
        childId="ProductPictureForm-sku"
        label={i18n.t('frontproducstream.asset_list.modal.skus.label', {
          defaultValue: 'Associated SAP Material Numbers (SKUs)',
        })}
      >
        <InputText
          id="ProductPictureForm-sku"
          value={sku ?? ''}
          disabled
          onChange={noop}
        />
      </InputWithLabel>
      <InputWithLabel
        childId="ProductPictureForm-gtins"
        label={i18n.t(
          'frontproducstream.asset_list.modal.associated_gtins.label',
          { defaultValue: 'Associated GTINs' },
        )}
      >
        <InputText
          id="ProductPictureForm-gtins"
          value={gtins ?? ''}
          disabled
          onChange={noop}
        />
      </InputWithLabel>
      <InputWithLabel
        childId="ProductPictureForm-zpck"
        label={i18n.t(
          'frontproducstream.asset_list.modal.associated_zpcks.label',
          { defaultValue: 'Associated ZPCKs' },
        )}
      >
        <InputText
          id="ProductPictureForm-zpck"
          value={zpck ?? ''}
          disabled
          onChange={noop}
        />
      </InputWithLabel>
    </>
  );
};

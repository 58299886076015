import { isPreProduction } from 'utils';
import qs from 'utils/query';

import { getEnv } from './env';

const productPageOrigin = () => {
  if (isPreProduction()) {
    return 'https://ppr-front.productpage.io/';
  } else if (['int', 'pillar'].includes(getEnv())) {
    const { origin, hostname } = document.location;
    return `${origin.replace(hostname, `hpp-${hostname}`)}/`;
  }
  return 'https://front.productpage.io/';
};

export function getHPPSrc(productId, productVersionId, language, token) {
  const urlParams = {
    client_id: 'dashboard_stream',
    productversion_id: productVersionId,
    language,
    authentication_token: token,
  };
  return `${productPageOrigin()}${qs.stringify(urlParams, true)}`;
}

export function getHPPSrcByGtin(gtin, organizationId, token) {
  const urlParams = {
    client_id: 'dashboard_stream',
    gtin,
    organization_id: organizationId,
    authentication_token: token,
  };
  return `${productPageOrigin()}${qs.stringify(urlParams, true)}`;
}

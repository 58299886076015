export const CONSUMER_UNIT = 'consumerUnit';
export const LOGISTICAL_UNIT = 'logisticalUnit';
export const SHARING_UNIT = 'sharingUnit';
export const DISPLAY_UNIT = 'displayUnit';
export const TARIFF = 'tariff';
export const PRODUCT_PICTURE = 'productPicture';
export const PRODUCT_DOCUMENT = 'productDocument';
export const PRODUCT_VIDEO = 'productVideo';
export const RFP_EVENT = 'rfpEvent';
export const RFP_BUSINESS_UNIT = 'rfpBU';
export const RFP_ANSWER = 'rfpAnswer';

import { call, put, select } from 'redux-saga/effects';

import { processSaveErrors } from 'utils/actions/saveErrors';
import i18n from 'utils/i18n';
import moment from 'utils/moment';
import { takeEverySafe } from 'utils/saga';

import { notificationSuccess } from '../../../actions/notification';
import { logError } from '../../../utils/logging';
import api from '../api';
import { ARCHIVE, FETCH_TEMPLATE_SUCCESS } from '../constants';
import {
  selectId,
  selectName,
  selectSelectedRecipientId,
  selectSharingUnitTariffData,
} from '../selectors';

export function* watchArchive() {
  yield takeEverySafe(ARCHIVE, archive);
}

export function* archive() {
  const selectedRecipientId = yield select(selectSelectedRecipientId);
  if (!selectedRecipientId) {
    return;
  }

  const id = yield select(selectId);
  const name = yield select(selectName);
  const data = yield select(selectSharingUnitTariffData);

  data.orderEndDate = moment(new Date()).format('YYYY-MM-DD');
  const { result, error } = yield call(api.update, id, name, data);

  if (!error) {
    yield put({
      type: FETCH_TEMPLATE_SUCCESS,
      template: result.data,
    });
    yield put(
      notificationSuccess(
        i18n.t(
          'frontproductstream.sharingunit_tariff.archive_notification.success',
          { defaultValue: 'Product archived successfully' },
        ),
      ),
    );
  } else {
    yield put(processSaveErrors({ error }));
    yield call(logError, error);
  }
}

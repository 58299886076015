import classnames from 'classnames';
import { PureComponent } from 'react';

import {
  TypePackagingIcons,
  getTypePackagingCode,
} from 'constants/typePackaging';

import './icon.scss';

interface Props {
  className?: string;
  packagingTypeId: number;
  small?: boolean;
  extra_small?: boolean;
  quantity?: string | number | null;
  disabled?: boolean;
}

export default class LogisticalHierarchyIcon extends PureComponent<Props> {
  public render() {
    const {
      className,
      packagingTypeId,
      small,
      extra_small,
      quantity,
      disabled,
    } = this.props;
    const typePackagingCode = getTypePackagingCode({ id: packagingTypeId });
    const classNames = classnames(
      className,
      `LogisticalHierarchyIcon`,
      { 'LogisticalHierarchyIcon--small': !!small },
      { 'LogisticalHierarchyIcon--extra-small': !!extra_small },
      { [`LogisticalHierarchyIcon--${typePackagingCode}`]: !disabled },
    );
    return (
      <div className={classNames}>
        <img
          className="LogisticalHierarchyIcon__image"
          src={TypePackagingIcons[packagingTypeId]}
          alt=""
        />
        {!!quantity && quantity !== 0 && (
          <div className="LogisticalHierarchyIcon__quantity">{quantity}</div>
        )}
      </div>
    );
  }
}
export { LogisticalHierarchyIcon };

import { List } from 'immutable';

import { get } from 'utils/immutable';
import { formatMedia } from 'utils/links';

import CatalogPictureColumn from '../picture';

import './index.scss';

export default function CatalogSourceOrganizationPictureColumn({
  data = List(),
}) {
  const organizationId = get(data, [0, 'id']);
  return (
    <CatalogPictureColumn
      uri={formatMedia(`/organization/picture/${organizationId}/square.png`)}
    />
  );
}

CatalogSourceOrganizationPictureColumn.className =
  'CatalogSourceOrganizationPictureColumn';

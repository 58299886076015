import { fromJS } from 'immutable';
import { call, put, take, takeLatest } from 'redux-saga/effects';

import { ResponseWithData } from '@alkem/sdk-dashboard';

import {
  bulkActionDone,
  bulkActionInProgress,
  showBulkAction,
} from 'modules/bulk-action-modal/actions';
import {
  BULK_ACTION_RESET,
  BulkActionInProgressActionPayload,
} from 'modules/bulk-action-modal/actions/types';
import funnel, { Options as FunnelOptions } from 'utils/funnel';
import { BaseFunnelTask, FunnelProgress } from 'utils/funnel/type';
import { withCatch } from 'utils/saga';

import {
  BULK_UNSHARE_SHOW,
  BULK_UNSHARE_START,
  BulkUnshareShowAction,
} from '../actions/types';

import unshare from './unshare';

export type UnsharetaskResult = ResponseWithData<any>;

export type UnshareTask = BaseFunnelTask<UnsharetaskResult> & {
  // id of the product to unshare
  pvid: number;
};

export default function* main() {
  yield takeLatest(BULK_UNSHARE_SHOW, withCatch(bulkUnshareSaga));
}

// translating progress action from the funnel to target the bulk-action-modal module
function* onProgress({
  outcome,
  task: { pvid },
  nbTasksDone,
}: FunnelProgress<ResponseWithData<any>, UnshareTask>) {
  const progress: BulkActionInProgressActionPayload = {
    entityId: pvid,
    count: nbTasksDone,
    status: {
      code: 'success',
      message: `Product ${pvid} sucessfully unshared`,
    },
  };
  switch (outcome.status) {
    case 'cancelled':
      return; // this is not a progression per se, funnel is about to terminate anyway
    case 'failure': {
      const {
        error: { message },
      } = outcome;
      progress.status.code = 'danger';
      progress.status.message = `Product ${pvid} failed being unshared : ${message}`;
    }
  }
  yield put(bulkActionInProgress(progress));
}

function* bulkUnshareSaga(showAction: BulkUnshareShowAction) {
  const { payload: { productVersions = fromJS([]), recipientId = null } = {} } =
    showAction;

  // telling the bulk action modal to display
  yield put(showBulkAction(productVersions));

  const tasks: UnshareTask[] = productVersions
    .toIndexedSeq()
    .toArray()
    .map((pv) => {
      const pvid = pv.get('id') as number;
      return {
        id: `task for pv ${pvid}`,
        runner: unshare(pv, recipientId),
        pvid,
      };
    });

  yield take(BULK_UNSHARE_START);

  try {
    const options: FunnelOptions<UnsharetaskResult, UnshareTask> = {
      cancelActionType: BULK_ACTION_RESET,
      onProgress,
    };

    yield call<
      (
        tasks: UnshareTask[],
        options: FunnelOptions<UnsharetaskResult, UnshareTask>,
      ) => any
    >(funnel, tasks, options);
  } finally {
    yield put(bulkActionDone());
  }
}

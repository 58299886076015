import PropTypes from 'prop-types';
import { PureComponent } from 'react';

import { AddButton, Button } from '@alkem/react-ui-button';

import i18n from 'utils/i18n';

import './header.scss';

export default class PriceWaterfallHeader extends PureComponent {
  static propTypes = {
    brackets: PropTypes.array.isRequired,
    canDelete: PropTypes.bool.isRequired,
    onAddBracket: PropTypes.func.isRequired,
    onDelete: PropTypes.func,
    disabled: PropTypes.bool.isRequired,
  };

  hasBrackets() {
    const { brackets } = this.props;
    return brackets && brackets.length > 1;
  }

  render = () => (
    <div className="PriceWaterfallHeader">
      <div className="PriceWaterfallHeader__addBracket">
        <AddButton
          label={
            this.hasBrackets()
              ? i18n.t('Add a bracket price')
              : i18n.t('Bracket price')
          }
          onClick={this.props.onAddBracket}
          disabled={this.props.disabled}
        />
      </div>
      {this.props.canDelete && !this.props.disabled && this.props.onDelete && (
        <div className="PriceWaterfallHeader__delete">
          <Button
            content={i18n.t('Delete')}
            onClick={this.props.onDelete}
            warning
          />
        </div>
      )}
    </div>
  );
}

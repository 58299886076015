import { createSelector } from '@reduxjs/toolkit';
import { List, Map } from 'immutable';

import { getOrganizationsForTargetMarket } from 'core/api/organization';
import {
  getTargetOrganizationId,
  hasTargetOrganizationId,
} from 'core/api/sharing-unit';
import { getB2BRecipients } from 'modules/recipients/reducers';
import {
  SHARING_UNIT_STATUS_AGREED,
  SHARING_UNIT_STATUS_DRAFT,
  SHARING_UNIT_STATUS_INIT,
} from 'modules/sharing-units/constants';
import { selectTargetMarketId } from 'reducers/productVersion';
import { getByKey } from 'utils/immutable';

import {
  canShareSharingUnit,
  prepareForServer,
  withServerHierarchies,
} from '../utils/core';

export const KEY = 'sharingUnits';

export const sharingUnitsSelector = (state) => state[KEY];

const getIgnoredFields = createSelector(sharingUnitsSelector, (state) =>
  state.get('ignoredFields'),
);

export const getSharingUnits = createSelector(
  sharingUnitsSelector,
  (sharingUnits) => sharingUnits.get('sharingUnitsById'),
);

export const getAgreedSharingUnits = createSelector(
  getSharingUnits,
  (sharingUnits) =>
    sharingUnits.filter(
      (su) => su.get('status') === SHARING_UNIT_STATUS_AGREED,
    ),
);

export const getSharingUnitsIndexed = createSelector(
  getSharingUnits,
  (sharingUnits) => sharingUnits.toList().toMap(),
);

export const getSharingUnitTargetOrganizationId = createSelector(
  getSharingUnits,
  (sharingUnits) => (suId) => getTargetOrganizationId(sharingUnits.get(suId)),
);

export const getPricewaterfallLabels = createSelector(
  sharingUnitsSelector,
  (state) => state.get('pricewaterfallLabels'),
);

export const getAllPresentPriceWaterfallLabels = createSelector(
  getPricewaterfallLabels,
  (labelsBySharingUnit) =>
    List().concat(
      ...labelsBySharingUnit
        .map((labels) => labels.filter((label) => label.present))
        .valueSeq(),
    ),
);

export const hasPriceWaterfallLabelEdited = createSelector(
  sharingUnitsSelector,
  (state) => state.get('hasPriceWaterfallLabelEdited'),
);

export const hasSharingUnits = createSelector(
  getSharingUnits,
  (sharingUnits) => sharingUnits.size > 0,
);

export const selectEditedSharingUnitIds = createSelector(
  sharingUnitsSelector,
  (sharingUnits) => sharingUnits.get('hasEdited'),
);

export const selectHasEditedSharingUnit = createSelector(
  selectEditedSharingUnitIds,
  hasPriceWaterfallLabelEdited,
  (editedSharingUnits, labelsEdited) =>
    editedSharingUnits.size > 0 || labelsEdited,
);

export const sharingUnitsByRetailerId = createSelector(
  getSharingUnits,
  (sharingUnits) =>
    sharingUnits.reduce(
      (acc, sharingUnit) =>
        acc.update(getTargetOrganizationId(sharingUnit), List(), (list) =>
          list.push(sharingUnit.get('id')),
        ),
      Map(),
    ),
);

export const canShareByRetailerId = createSelector(
  getSharingUnits,
  sharingUnitsByRetailerId,
  (sharingUnits, susByRId) =>
    susByRId.map((sharingUnitIds) =>
      sharingUnitIds.reduce(
        (acc, suid) => acc || canShareSharingUnit(sharingUnits.get(suid)),
        false,
      ),
    ),
);

export const isAlreadySharedByRetailerId = createSelector(
  getB2BRecipients,
  sharingUnitsByRetailerId,
  getSharingUnits,
  (sharingUnitRetailers, sharingUnitsByRId, sharingUnits) =>
    sharingUnitRetailers.reduce(
      (acc, sharingUnitRetailer) =>
        acc.set(
          sharingUnitRetailer.get('id'),
          !!sharingUnitsByRId.get(sharingUnitRetailer.get('id')) &&
            sharingUnitsByRId
              .get(sharingUnitRetailer.get('id'))
              .some(
                (suId) =>
                  sharingUnits.get(suId).get('status') !==
                  SHARING_UNIT_STATUS_DRAFT,
              ),
        ),
      Map(),
    ),
);

export const isListingNeededByRetailerId = createSelector(
  getB2BRecipients,
  sharingUnitsByRetailerId,
  getSharingUnits,
  (sharingUnitRetailers, sharingUnitsByRId, sharingUnits) =>
    sharingUnitRetailers.reduce(
      (acc, sharingUnitRetailer) =>
        acc.set(
          sharingUnitRetailer.get('id'),
          !sharingUnitsByRId.get(sharingUnitRetailer.get('id')) ||
            sharingUnitsByRId
              .get(sharingUnitRetailer.get('id'))
              .some(
                (suId) =>
                  sharingUnits.get(suId).get('status') ===
                    SHARING_UNIT_STATUS_DRAFT &&
                  sharingUnits.get(suId).get('lastRequest').size === 0,
              ),
        ),
      Map(),
    ),
);

/**
 * Returns true if the product already has a sharing unit created for the
 * current retailer.
 *
 * @param sharingUnits immutable map of all sharing units by id
 * @param retailer immutable object of retailer
 *
 * @returns {boolean}
 */
const hasSharingUnit = (sharingUnits) => (retailer) =>
  sharingUnits.filter(hasTargetOrganizationId(retailer.get('id'))).size > 0;

/**
 * Returns a list of retailers for whose no sharing unit has been created yet.
 *
 * @returns immutable list of retailers
 */
export const selectRemainingSharingUnitRetailers = createSelector(
  getSharingUnits,
  getB2BRecipients,
  selectTargetMarketId,
  (sharingUnits, retailers, targetMarketID) =>
    getOrganizationsForTargetMarket(retailers, targetMarketID).filterNot(
      hasSharingUnit(sharingUnits),
    ),
);

/**
 * Passed in a map of sharingUnits and a list of retailers it returns a function
 * that takes a list of sharing unit ids and a retailer id to return a record of
 * a retailer (from the list of retailers found by the retailer id) and a list
 * of sharing units (from the map of sharing units by the list of sharing unit
 * ids).
 */
const createRetailerSharingUnitPair =
  (sharingUnits, retailers) => (sharingUnitIds, retailerId) =>
    Map()
      .set(
        'retailer',
        retailers.find((r) => r.get('id') === retailerId),
      )
      .set('sharingUnits', sharingUnitIds.map(getByKey(sharingUnits)));

/**
 * Returns an immutable list of records that contain a retailer and its sharing
 * units.
 */
export const selectSharingUnitsByRetailer = createSelector(
  getSharingUnits,
  getB2BRecipients,
  sharingUnitsByRetailerId,
  (sharingUnits, recipients, sharingUnitIdsByRetailerId) =>
    sharingUnitIdsByRetailerId
      .map(createRetailerSharingUnitPair(sharingUnits, recipients))
      .filter((o) => o.get('retailer'))
      .valueSeq(),
);

/**
 * Returns sharing units in server format
 */
export const selectSharingUnitsToSave = createSelector(
  getSharingUnits,
  selectEditedSharingUnitIds,
  getIgnoredFields,
  (sus, editedSUIds, ignoredFields) =>
    prepareForServer(
      sus
        .filter((su) => editedSUIds.includes(su.get('id')))
        .map(withServerHierarchies),
      ignoredFields,
    ),
);

/**
 * return error with a sharingUnit.
 */
export const getSharingUnitErrors = createSelector(
  sharingUnitsSelector,
  (state) => state.get('hierarchyGridErrors'),
);

export const getHasSharingUnitErrors = createSelector(
  getSharingUnitErrors,
  (sharingUnitErrors) => sharingUnitErrors.size > 0,
);

/**
 * return used hierarchies
 */
export const selectHierarchyIdBySharingUnit = createSelector(
  getSharingUnits,
  (sharingUnits) =>
    sharingUnits
      .map(
        (sharingUnit) =>
          sharingUnit.getIn([
            'lastRequest',
            'data',
            'hierarchyProduct',
            'id',
          ]) || sharingUnit.getIn(['data', 'hierarchyProduct', 'id']),
      )
      .filter((id) => !!id),
);

export const selectHierarchyReferenceBySharingUnit = createSelector(
  getSharingUnits,
  (sharingUnits) =>
    sharingUnits
      .map(
        (sharingUnit) =>
          sharingUnit.getIn([
            'lastRequest',
            'data',
            'hierarchyProduct',
            'gtin',
          ]) ||
          sharingUnit.getIn(['data', 'hierarchyProduct', 'gtin']) ||
          sharingUnit.getIn([
            'lastRequest',
            'data',
            'hiearchyProduct',
            'productIdentifier',
          ]) ||
          sharingUnit.getIn(['data', 'hierarchyProduct', 'productIdentifier']),
      )
      .filter((reference) => !!reference || reference === ''),
);

/**
 * returns hierarchies used in sharing units.
 * helps determine if hierarchy can be deleted
 */
export const selectUsedHierarchyIds = createSelector(
  selectHierarchyIdBySharingUnit,
  (hierarchyIdBySharingUnit) => hierarchyIdBySharingUnit.valueSeq().toJS(),
);
export const selectUsedHierarchyReferences = createSelector(
  selectHierarchyReferenceBySharingUnit,
  (hierarchyReferenceBySharingUnit) =>
    hierarchyReferenceBySharingUnit.valueSeq().toJS(),
);

/**
 * returns hierarchies used in sharing units.
 * helps determine if top level gtin can be changed
 */
export const selectAlreadyAgreedHierarchyIds = createSelector(
  getSharingUnits,
  selectHierarchyIdBySharingUnit,
  (sharingUnits, usedHierarchies) => {
    const sharedSharingUnits = sharingUnits.filter(
      (sharingUnit) =>
        sharingUnit.getIn(['status']) !== SHARING_UNIT_STATUS_DRAFT &&
        sharingUnit.getIn(['status']) !== SHARING_UNIT_STATUS_INIT,
    );
    return usedHierarchies
      .filter((hierarchyIds, sharingUnitId) =>
        sharedSharingUnits.has(sharingUnitId),
      )
      .valueSeq()
      .toJS();
  },
);

export const selectSharingUnitIsAgreed = createSelector(
  getSharingUnits,
  (sharingUnits) => (suId) => {
    const sharingUnit = sharingUnits.get(suId) || Map();
    return sharingUnit.get('status') === SHARING_UNIT_STATUS_AGREED;
  },
);

export const selectDisplayWarningOnSave = createSelector(
  sharingUnitsSelector,
  (state) => state.get('displayWarningOnSave'),
);

// aliases
export const selectSharingUnits = getSharingUnits;
export const selectSharingUnitsByRetailerId = sharingUnitsByRetailerId;

import { List, Map } from 'immutable';
import { call, put, select } from 'redux-saga/effects';

import { WorkflowSharingStatuses } from 'constants/workflowSharingStatus';
import { sharingStatusFilter } from 'core/modules/list/constants/filters/sharing-status';
import { workflowSharingStatusFilter } from 'core/modules/list/constants/filters/workflow-sharing-status';
import { selectUseCatalogWithMenu } from 'modules/catalog/menu/selectors';
import { setFiltersFromReporting } from 'modules/catalog/product/actions';
import * as routes from 'routes';
import { push } from 'utils/history';
import i18n from 'utils/i18n';

import {
  selectAggregationGroupByMeta,
  selectGroupByMeta,
  selectSelectedFilterList,
  selectSelectedFilterMap,
  selectWithArchivedProducts,
} from '../../selectors';

const formatQuery = (filter, value) => {
  const template = JSON.stringify(filter.toJS());
  const computed = JSON.parse(template.replace(/%\(value\)s/gi, value));
  return computed;
};

const formatReportData = (data, idx) => {
  if (List.isList(data)) {
    return data.get(idx);
  }
  return data;
};

export const buildFilters = (groupByMetas, selectMeta, rowData) => {
  // currently supports one level of nested
  let nestedPath;
  const rootConditions = [];
  const nestedConditions = [];

  for (const groupByMeta of groupByMetas) {
    const cellValue = rowData.get(groupByMeta.get('key'));
    const filtersMap = groupByMeta.get('filter');

    let appliedFilter;
    // when term aggregation
    if (filtersMap.has('%(value)s')) {
      if ([-1, '-1'].includes(cellValue) && filtersMap.has('missing')) {
        // -1 is used for missing term
        appliedFilter = filtersMap.get('missing').toJS();
      } else {
        appliedFilter = formatQuery(filtersMap.get('%(value)s'), cellValue);
      }
    } else {
      appliedFilter =
        filtersMap.get(cellValue) || filtersMap.get(`${cellValue}`);
      appliedFilter = appliedFilter ? appliedFilter.toJS() : null;
    }

    // should always be same
    nestedPath = nestedPath || groupByMeta.get('nested_path');
    if (appliedFilter) {
      if (nestedPath) {
        nestedConditions.push(appliedFilter);
      } else {
        rootConditions.push(appliedFilter);
      }
    }
  }

  // selected columns
  if (selectMeta.get('filter')) {
    const columnQuery = formatQuery(
      selectMeta.get('filter'),
      selectMeta.get('keyOrigin'),
    );

    if (selectMeta.get('nested_path')) {
      nestedConditions.push(columnQuery);
    } else {
      rootConditions.push(columnQuery);
    }
  }

  const allConditions = [];
  allConditions.push(...rootConditions);
  if (nestedConditions.length) {
    allConditions.push({
      nested: { path: nestedPath, must: nestedConditions },
    });
  }
  return { filter: allConditions };
};

export function* redirectToCatalogSaga(action) {
  // report ->  reporting row data
  // referential -> selected facet meta
  // isAggregation -> means total row
  const { referential, report, isAggregation } = action.payload;
  const withArchivedProducts = yield select(selectWithArchivedProducts);
  let filterList = yield select(selectSelectedFilterList);
  const filterMap = yield select(selectSelectedFilterMap);

  const translatedSharingStatusValues = filterList
    .filter((filter) => filter.get('key') === workflowSharingStatusFilter.key)
    .map((filter) => WorkflowSharingStatuses[filter.get('value')].name);

  // Remove sharing status filter from filter list
  filterList = filterList.filter(
    (filter) => filter.get('key') !== workflowSharingStatusFilter.key,
  );

  let groupByMeta;
  if (isAggregation) {
    groupByMeta = yield select(selectAggregationGroupByMeta);
  } else {
    groupByMeta = yield select(selectGroupByMeta);
  }

  const query = buildFilters(groupByMeta, referential, report);

  const reportingFilter = Map({
    add: true,
    key: 'reportingFilter',
    label: isAggregation
      ? i18n.t(
          'frontproductstream.product_catalog_filter_summary.redirect_from_reporting_total_row.text',
          {
            defaultValue: 'Not: {{filter}}',
            filter: referential.get('label'),
          },
        )
      : i18n.t(
          'frontproductstream.product_catalog_filter_summary.redirect_from_reporting_detail_row.text',
          {
            defaultValue: 'Not: {{filter}}, grouped by: {{- entity}}',
            filter: referential.get('label'),
            entity: [groupByMeta.find((meta) => !meta.get('disable'))]
              .reduce(
                (str, meta) =>
                  `${str} ${formatReportData(report.get(meta.get('key')), 1)}`,
                '',
              )
              .concat(
                translatedSharingStatusValues.size > 0
                  ? `, ${sharingStatusFilter.filterLabel}: `.concat(
                      translatedSharingStatusValues.join(', '),
                    )
                  : '',
              ),
          },
        ),

    value: query,
    isQueryReady: true,
  });
  yield put(
    setFiltersFromReporting({
      withArchivedProducts,
      filterList,
      filterMap,
      reportingFilter,
    }),
  );
  const hasCatalogWithMenu = yield select(selectUseCatalogWithMenu);
  yield call(push, hasCatalogWithMenu ? routes.catalogAll : routes.catalog, {
    reportingFilter: true,
  });
}

import { useDispatch } from 'react-redux';

import EmptyState from '@alkem/react-ui-empty-state';

import { updateSelectedFilters } from 'modules/catalog/product/actions';
import i18n from 'utils/i18n';

const CatalogEmptyClearFiltersTable = () => {
  const dispatch = useDispatch();
  return (
    <EmptyState
      title={i18n.t('Your search did not yield any results')}
      text={i18n.t('Try with less filters')}
      action={{
        label: i18n.t('Clear filters'),
        onClick: () => dispatch(updateSelectedFilters({ reset: true })),
      }}
    />
  );
};

export { CatalogEmptyClearFiltersTable };

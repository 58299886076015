import PropTypes from 'prop-types';

import i18n from 'utils/i18n';

import { FEATURE_MANUFACTURER_VIDEO } from '../../constants';
import FeatureModal from '../modal';

import Preview from './preview';

const FeatureManufacturerVideoModal = ({ onClose }) => (
  <FeatureModal
    onClose={onClose}
    title={i18n.t('Engage shoppers by adding videos to your products')}
    flag={FEATURE_MANUFACTURER_VIDEO}
  >
    <Preview />
  </FeatureModal>
);

FeatureManufacturerVideoModal.propTypes = {
  onClose: PropTypes.func.isRequired,
};

export default FeatureManufacturerVideoModal;

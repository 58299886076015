import { Map, fromJS } from 'immutable';
import { createReducer } from 'redux-create-reducer';

import { keyBy } from 'utils/fn';

import * as constants from './constants';

const initialState = Map();

// Recursive function to flatten the tree
const flattenTree = (node, flattenedList, parentId = null) => {
  flattenedList.push({ ...node, parent: { id: parentId } }); // Add the current node to the flattened list

  // If the node has children, recursively flatten each child
  if (node.children && node.children.length > 0) {
    node.children.forEach((child) =>
      flattenTree(child, flattenedList, node.id),
    );
  }
};

export default createReducer(initialState, {
  [constants.RECEIVE_REFERENTIAL]: (
    state,
    { payload: { slug, entities, asTree } },
  ) => {
    const entitiesById = keyBy(entities, (entity) => entity.get('id'));

    if (asTree) {
      const flattenedTreeEntities = entities.reduce((flattenedList, entity) => {
        flattenTree(entity.toJS(), flattenedList);
        return flattenedList;
      }, []);

      const flattenedTreeEntitiesById = keyBy(
        flattenedTreeEntities,
        (entity) => entity.id,
      );

      return state.set(
        slug,
        fromJS({
          entities,
          entitiesById,
          flattenedTreeEntities,
          flattenedTreeEntitiesById,
        }),
      );
    }

    return state.set(
      slug,
      fromJS({
        entities,
        entitiesById,
      }),
    );
  },
});

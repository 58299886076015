import { useDispatch } from 'react-redux';

import Tooltip from '@alkem/react-ui-tooltip';

import { TargetProductStatusNotAssigned } from 'constants/targetProductStatus';
import { hasOrganizationChatSetting } from 'core/api/organization-settings';
import { getOrganizationSettings, isRetailer } from 'core/api/user';
import { BulkMessageModal, showBulkMessageModal } from 'modules/bulk-message';
import { ProductsMap, SelectedMap } from 'modules/bulk-message/types';
import { FEATURE_PERMISSIONS_V3_ORGANIZATION } from 'modules/feature-flag/constants';
import { hasPermissionModule, hasPermissionV3 } from 'modules/permissions';
import {
  CHAT_PERMISSION,
  ORGANIZATION_PERMISSION,
} from 'modules/permissions/const';
import i18n from 'utils/i18n';

import './style.scss';

export const bulkMessageDisplayable = ({ user }: { user: any }) => {
  const hasChatSetting = hasOrganizationChatSetting(
    getOrganizationSettings(user),
  );

  const canSendMessage = hasPermissionV3(
    user,
    FEATURE_PERMISSIONS_V3_ORGANIZATION,
  )
    ? hasPermissionModule(user, ORGANIZATION_PERMISSION, CHAT_PERMISSION) &&
      hasChatSetting
    : hasChatSetting;

  return (
    // check for retailer, chat permissions, not assignation
    isRetailer(user) && canSendMessage
  );
};

export const BulkMessage = ({
  productMap,
  selectedMap,
}: {
  productMap: ProductsMap;
  selectedMap: SelectedMap;
}) => {
  const dispatch = useDispatch();

  // find out products status in the map of selected products
  const isDisabled =
    selectedMap.isEmpty() ||
    selectedMap.some(
      (_, pvId) =>
        productMap.getIn([pvId, 'targetProductStatus']) ===
        TargetProductStatusNotAssigned.id,
    );

  const selectedProductVersions = selectedMap
    .filter((isSelected) => isSelected !== undefined && isSelected)
    .map((_, pvId) => productMap.get(pvId))
    .toList();

  const openModal = () => {
    dispatch(showBulkMessageModal());
  };

  return (
    <>
      <button
        type="button"
        className="ActionOption"
        onClick={openModal}
        disabled={isDisabled}
      >
        <div data-for="bulk-message-disabled" data-tip id="Action_bulkMessage">
          {i18n.t('Send message for {{count}} product selected', {
            count: selectedMap.size,
          })}
          {isDisabled && (
            <Tooltip id="bulk-message-disabled">
              {i18n.t(
                'All products selected must be attributed. Ensure the status of the selected products',
              )}
            </Tooltip>
          )}
        </div>
      </button>
      <BulkMessageModal productVersions={selectedProductVersions} />
    </>
  );
};

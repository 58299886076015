import classNames from 'classnames';
import { Link } from 'react-router-dom';

import UnreadCount from 'components/unread-count';

import './tab.scss';

type Props = {
  route: any;
  label: string;
  count?: number;
  isSelected?: boolean;
  showAsUnread?: boolean;
};

function CatalogMenuTab({
  route,
  label,
  count = -1,
  isSelected = false,
  showAsUnread = false,
}: Props) {
  return (
    <Link
      to={route}
      className={classNames(
        'CatalogMenuTab',
        isSelected && 'CatalogMenuTab--selected',
      )}
      data-testid="Link"
    >
      {label}
      {count >= 0 &&
        (showAsUnread ? <UnreadCount count={count} /> : ` (${count})`)}
    </Link>
  );
}

export { CatalogMenuTab };

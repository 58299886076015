export const moduleName = 'app-store';

const moduleConst = (name) => `${moduleName}/${name}`;

export const FETCH_APPLICATIONS = moduleConst('FETCH_APPLICATIONS');
export const FETCH_APPLICATIONS_ERROR = moduleConst('FETCH_APPLICATIONS_ERROR');
export const FETCH_APPLICATIONS_SUCCESS = moduleConst(
  'FETCH_APPLICATIONS_SUCCESS',
);
export const ACTIVATE_APPLICATION = moduleConst('ACTIVATE_APPLICATION');
export const ACTIVATE_APPLICATION_ERROR = moduleConst(
  'ACTIVATE_APPLICATION_ERROR',
);
export const ACTIVATE_APPLICATION_SUCCESS = moduleConst(
  'ACTIVATE_APPLICATION_SUCCESS',
);
export const DEACTIVATE_APPLICATION = moduleConst('DEACTIVATE_APPLICATION');
export const DEACTIVATE_APPLICATION_ERROR = moduleConst(
  'DEACTIVATE_APPLICATION_ERROR',
);
export const DEACTIVATE_APPLICATION_SUCCESS = moduleConst(
  'DEACTIVATE_APPLICATION_SUCCESS',
);

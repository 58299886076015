import { compose } from 'lodash/fp';
import { createReducer } from 'redux-create-reducer';

import {
  RECEIVE_BRAND_CLAIMS_LIST,
  RECEIVE_BRAND_LIST,
} from 'constants/events/brand';
import { compareByLabelOrName, sortBy } from 'utils';
import { reorderBrandTypes } from 'utils/reducers/brands';
import { sortAsc } from 'utils/sort';

const buildBrandList = compose(reorderBrandTypes, sortBy(compareByLabelOrName));

const initialState = {
  list: [],
  claims: [],
};
export default createReducer(initialState, {
  [RECEIVE_BRAND_LIST]: (state, action) => {
    const newState = { ...state };
    const sortedBrands = [];
    const brandObj = {};
    const brandList = {};
    action.list.forEach((b) => {
      const brand = b;
      brandList[brand.id] = brand;

      brand.children = [];
      // Filter tree configuration
      brandObj[brand.id] = brand;

      let parent = brand.isSubBrandOf;
      // While parent is not ProductBrand aka none
      while (parent && parent.id && parent.id !== 1) {
        if (!brandObj[parent.id]) {
          parent.children = [];
          brandObj[parent.id] = parent;
          brandList[parent.id] = parent;
        }
        parent = parent.isSubBrandOf;
      }
    });

    Object.keys(brandObj).forEach((index) => {
      const brand = brandObj[index];
      const parentBrand = brand.isSubBrandOf;
      if (parentBrand && parentBrand.id !== 1 && brandObj[parentBrand.id]) {
        brandObj[parentBrand.id].children.push(brand);
      } else {
        sortedBrands.push(brand);
      }
    });
    newState.hierarchy = buildBrandList(sortedBrands);
    newState.list = buildBrandList(Object.values(brandList));
    return newState;
  },

  [RECEIVE_BRAND_CLAIMS_LIST]: (state, action) => {
    const newState = { ...state };
    newState.claims = action.list.sort((a, b) =>
      sortAsc(a.get('value'), b.get('value')),
    );
    return newState;
  },
});

import classNames from 'classnames';
import { Map } from 'immutable';
import { bool, number, object, oneOfType, string } from 'prop-types';
import { PureComponent } from 'react';
import { connect } from 'react-redux';

import Raguel from 'components/ui/form/plugins/validator';
import { ASSET_TYPE, ASSET_TYPES } from 'constants/media';
import { selectIsRetailer } from 'modules/user';

import ContactBlock from './contact-block';
import './styles.scss';

const mapStateToProps = (state) => ({
  isRetailer: selectIsRetailer(state),
});

export class MediaErrors extends PureComponent {
  static propTypes = {
    // props
    entityId: oneOfType([string, number]).isRequired,
    entityKind: string.isRequired,
    hasUpdatePermission: bool,
    value: object,
    withContactBlock: bool,
    isReadOnly: bool,
    picturesReadOnly: bool,
    videosReadOnly: bool,
    recipesReadOnly: bool,
    documentsReadOnly: bool,
    recipientId: number,

    // store
    isRetailer: bool.isRequired,
  };

  static defaultProps = {
    hasUpdatePermission: false,
    isReadOnly: false,
    picturesReadOnly: false,
    videosReadOnly: false,
    recipesReadOnly: false,
    documentsReadOnly: false,
    withContactBlock: false,
  };

  state = {
    validationErrors: Map({
      pictures: false,
      videos: false,
      documents: false,
      enriched_contents: false,
    }),
  };

  validationByType = ASSET_TYPES.reduce(
    (acc, assetType) => ({
      ...acc,
      [assetType]: ({ error, notRequested }) => {
        this.setState((state) => ({
          validationErrors: state.validationErrors.set(assetType, error),
          areErrorsNotRequested: notRequested,
        }));
      },
    }),
    {},
  );

  hasValidationErrors = () =>
    ASSET_TYPES.some((assetType) => this.state.validationErrors.get(assetType));

  matchPaths(paths, model) {
    return paths.some((list) => list.some((path) => path.startsWith(model)));
  }

  renderContactBlock() {
    const { validationErrors } = this.state;
    const { isRetailer, withContactBlock } = this.props;
    // NOTE we might need to elaborate that better in future, for now there is
    // only one error possible - the one about the picture quality
    if (withContactBlock && !isRetailer && validationErrors.get('pictures')) {
      return <ContactBlock />;
    } else {
      return null;
    }
  }

  render() {
    const {
      entityId,
      entityKind,
      isReadOnly,
      picturesReadOnly,
      videosReadOnly,
      recipesReadOnly,
      documentsReadOnly,
      hasUpdatePermission,
      value,
      recipientId,
    } = this.props;
    const assetsReadOnly = {
      [ASSET_TYPE.PICTURE]: picturesReadOnly,
      [ASSET_TYPE.VIDEO]: videosReadOnly,
      [ASSET_TYPE.ENRICHED_CONTENT]: recipesReadOnly,
      [ASSET_TYPE.DOCUMENT]: documentsReadOnly,
    };
    return (
      <div className="media-errors">
        <div
          className={classNames('Raguel__block', {
            'FormField--raguelError': this.hasValidationErrors(),
            'FormField--notRequested': this.state.areErrorsNotRequested,
          })}
        >
          {ASSET_TYPES.map((assetType) => (
            <Raguel
              key={assetType}
              entityId={entityId}
              entityKind={entityKind}
              label="assets"
              model={`assets.${assetType}`}
              value={value}
              onJudgmentDay={this.validationByType[assetType]}
              readOnly={assetsReadOnly[assetType] || isReadOnly}
              displayActions={hasUpdatePermission}
              matchPaths={this.matchPaths}
              recipientId={recipientId}
              force
            />
          ))}
        </div>
        {this.renderContactBlock()}
      </div>
    );
  }
}

export default connect(mapStateToProps)(MediaErrors);

import { getOrganizationsForTargetMarket } from 'core/api/organization';
import { isRetailer, isThirdParty } from 'core/api/user';
import { getRecipients } from 'modules/recipients/actions';
import { selectRecipientsInAGroup } from 'modules/recipients/reducers';
import { selectUser } from 'modules/user';
import { applyRulesForViewAsRecipients } from 'modules/validation';
import { selectTargetMarketId } from 'reducers/productVersion';

import {
  VIEW_AS_ADD_RECIPIENT,
  VIEW_AS_FETCH_ALWAYS_DISPLAYED_FIELDS,
  VIEW_AS_FILTER_RULESETS_FIELDS,
  VIEW_AS_INIT,
  VIEW_AS_LOADING,
  VIEW_AS_REMOVE_RECIPIENT,
  VIEW_AS_SELECT_RULESET,
  VIEW_AS_SET_PRESELECTED,
  VIEW_AS_UNSELECT_RULESET,
} from './events';
import {
  selectPreselectedRecipients,
  selectPreselectedRuleSetIds,
} from './selectors';

export const setPreselected = (recipientsIds, ruleSetIds) => ({
  type: VIEW_AS_SET_PRESELECTED,
  recipientsIds,
  ruleSetIds,
});

export const init = (): any => async (dispatch, getState) => {
  const statePreRecipients = getState();

  const recipients = selectRecipientsInAGroup(statePreRecipients);
  const user = selectUser(statePreRecipients);
  dispatch({ type: VIEW_AS_LOADING, loading: true });
  dispatch({ type: VIEW_AS_FETCH_ALWAYS_DISPLAYED_FIELDS });

  // TODO: PON-304: change this to use the publication status stuff rather than call the recipients when released.
  // We will be able to stop calling the recipients then.
  // TODO: recipients are fetched with profile, stop doing this.
  if (
    !isRetailer(user) &&
    !isThirdParty(user) &&
    (!recipients || !recipients.size)
  ) {
    await dispatch(getRecipients());
  }

  const statePostRecipients = getState();
  const preselectedRecipients =
    selectPreselectedRecipients(statePostRecipients);
  const preselectedRuleSetIds = selectPreselectedRuleSetIds(getState());

  const targetMarketId = selectTargetMarketId(statePostRecipients);
  // Filter the recipients to show only those with active range or listing as primary ones.
  const allRecipients = getOrganizationsForTargetMarket(
    selectRecipientsInAGroup(statePostRecipients),
    targetMarketId,
  );

  dispatch({ type: VIEW_AS_LOADING, loading: false });
  return dispatch({
    type: VIEW_AS_INIT,
    recipients: allRecipients,
    preselectedRecipients,
    preselectedRuleSetIds,
    user,
  });
};

export const addRecipient = (recipient, user) => ({
  type: VIEW_AS_ADD_RECIPIENT,
  recipient,
  user,
});

export const removeRecipient = (recipient, user) => ({
  type: VIEW_AS_REMOVE_RECIPIENT,
  recipient,
  user,
});

export const addRuleSet = (ruleSet, _, user) => (dispatch) => {
  dispatch({ type: VIEW_AS_SELECT_RULESET, ruleSet, user });
  return dispatch(applyRulesForViewAsRecipients());
};

export const removeRuleSet = (ruleSet, _, user) => (dispatch) => {
  dispatch({ type: VIEW_AS_UNSELECT_RULESET, ruleSet, user });
  return dispatch(applyRulesForViewAsRecipients());
};

export const setFilterRuleSetsFields = (filter: boolean, user?) => ({
  type: VIEW_AS_FILTER_RULESETS_FIELDS,
  filter,
  user,
});

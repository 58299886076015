import { get } from 'lodash/fp';
import { useEffect, useRef, useState } from 'react';
import { useDispatch } from 'react-redux';

import { ErrorFallback } from 'core/components/error/fallback';
import CoreLayout from 'layouts/CoreLayout';
import BodyLayout from 'layouts/body';
import { logError } from 'utils/logging';

import { RESET_MODULE_ACTION } from './constants';

export default function asyncComponent(
  fetchModule,
  { withReset = true, componentPath = 'component' } = {},
) {
  function AsyncComponent(props) {
    const dispatch = useDispatch();
    const isMountedRef = useRef(true);
    const reducerKeyRef = useRef(null);
    const [ModuleComponent, setModuleComponent] = useState(null);

    useEffect(() => {
      const loadModule = async () => {
        try {
          const { reducerKey, ...Module } = await fetchModule();
          if (isMountedRef.current) {
            reducerKeyRef.current = reducerKey;
            setModuleComponent(() => get(componentPath, Module));
          }
        } catch (error) {
          if (isMountedRef.current) {
            logError(error);
            const ErrorComponent = () => (
              <CoreLayout>
                <BodyLayout>
                  <ErrorFallback error={error} origin="async component" />
                </BodyLayout>
              </CoreLayout>
            );
            setModuleComponent(() => ErrorComponent);
          }
        }
      };
      loadModule();
    }, []);

    useEffect(
      () => () => {
        isMountedRef.current = false;
        if (withReset && reducerKeyRef.current) {
          dispatch({
            type: RESET_MODULE_ACTION,
            payload: reducerKeyRef.current,
          });
        }
      },
      [dispatch],
    );

    return ModuleComponent ? <ModuleComponent {...props} /> : null;
  }

  return AsyncComponent;
}

import { List } from 'immutable';
import moment from 'moment';

export const MATURITY_COLOR_RANGE = List([100, 75]);
export interface MaturitiesSummary {
  total: number;
  successful: number;
  featured_upcoming_scenario: MaturitySummary | null;
  active_scenarios: MaturitySummary[];
  upcoming_scenarios: MaturitySummary[];
  not_requested?: boolean;
}

export interface MaturitySummary {
  id: number;
  label: string;
  deadline: string | null;
  successful: number;
  total: number;
  // recipient_id and recipient_name are only filled for manufacturers
  recipient_id?: number;
  recipient_name?: string;
}

export function parseDeadline(maturity: MaturitySummary): moment.Moment {
  return moment.utc(maturity.deadline).local().endOf('day');
}

export function isScenarioDeadlineBehind(deadline: moment.Moment) {
  const startOfToday = moment().startOf('day');
  return moment(deadline).endOf('day').isBefore(startOfToday);
}
export function isScenarioComplete(maturity: MaturitySummary): boolean {
  return maturity.successful === maturity.total;
}

import { useState } from 'react';

import { openHelpCenter } from '../actions';

export function HelpLinks() {
  const [article, setArticle] = useState<string | number>();
  const [module, setModule] = useState<string | number>();
  return (
    <div style={{ padding: 'calc(40px + 2rem) 2rem 2rem 2rem' }}>
      <h1>Help center links</h1>
      <h2>Articles</h2>
      <ul>
        {['index', 33, 70, 97, 119, 172, 206, 364].map((a) => (
          <li key={a}>
            <a
              href={document.location.pathname}
              data-elevio-article={a}
              onClick={(event) => event.preventDefault()}
            >
              Open article {a}
            </a>
          </li>
        ))}
        <li>
          Article{' '}
          <input
            type="text"
            value={article}
            onChange={(event) => setArticle(event.target.value)}
            style={{ width: '5rem' }}
          />{' '}
          <button
            onClick={() => {
              if (article) openHelpCenter({ article });
            }}
          >
            Open
          </button>
        </li>
      </ul>
      <h2>Modules</h2>
      <ul>
        <li>
          <a
            href={document.location.pathname}
            onClick={(event) => {
              event.preventDefault();
              openHelpCenter({ module: 20 });
            }}
          >
            Open module 20
          </a>
        </li>
        <li>
          Module{' '}
          <input
            type="text"
            value={module}
            onChange={(event) => setModule(event.target.value)}
            style={{ width: '5rem' }}
          />{' '}
          <button
            onClick={() => {
              if (module) openHelpCenter({ module });
            }}
          >
            Open
          </button>
        </li>
      </ul>
    </div>
  );
}

import { connect } from 'react-redux';

import { isSuperAdmin, isSuperAdminLoggedAs } from 'core/api/user';
import { hasFeature } from 'modules/feature-flag';
import { isProduction } from 'utils';

import InputReference from './reference';

const mapStateToProps = (state) => ({
  enableGenerator:
    (!isProduction() || hasFeature(state.user, 'feature-has-gtin-generator')) &&
    (isSuperAdmin(state.user) || isSuperAdminLoggedAs(state.user)),
});

export default connect(mapStateToProps)(InputReference);

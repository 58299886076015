import i18n from 'utils/i18n';

export const all = {
  name: i18n.t('frontproductstream.constants.shared_status.all_products', {
    defaultValue: 'All products',
  }),
  id: -1,
};

export const deleted = {
  name: i18n.t('frontproductstream.constants.shared_status.deleted', {
    defaultValue: 'Deleted',
  }),
  id: 0,
};

export const DELETED = { id: 0 };

export const archived = {
  name: i18n.t('frontproductstream.constants.shared_status.archived', {
    defaultValue: 'Archived',
  }),
  id: 1,
};

export const ARCHIVED = { id: 1 };

export const draft = {
  name: i18n.t('frontproductstream.constants.shared_status.in_progress', {
    defaultValue: 'In progress',
  }),
  id: 2,
  className: 'Draft',
};

export const DRAFT = { id: 2 };

export const pending = {
  name: i18n.t('frontproductstream.constants.shared_status.pending', {
    defaultValue: 'Shared',
  }),
  id: 3,
  className: 'Pending',
};

export const PENDING = { id: 3 };

export const accepted = {
  name: i18n.t('frontproductstream.constants.shared_status.accepted', {
    defaultValue: 'Accepted',
  }),
  id: 4,
  className: 'Accepted',
};

export const ACCEPTED = { id: 4 };

export const refused = {
  name: i18n.t('frontproductstream.constants.shared_status.refused', {
    defaultValue: 'Refused',
  }),
  id: 5,
  className: 'Refused',
};

export const REFUSED = { id: 5 };

import classNames from 'classnames';
import PropTypes from 'prop-types';
import { PureComponent } from 'react';

import { Button } from '@alkem/react-ui-button';

import i18n from 'utils/i18n';

import CollapsedListFilters from './collapsed';
import ExpandedListFilters from './expanded';
import './index.scss';

export default class ListFilters extends PureComponent {
  static propTypes = {
    collapsed: PropTypes.bool,
    children: PropTypes.node.isRequired,
    selectedFilters: PropTypes.number,
    onCollapse: PropTypes.func.isRequired,
    action: PropTypes.shape({
      link: PropTypes.string,
      onClick: PropTypes.func,
      label: PropTypes.string.isRequired,
    }),
  };

  static defaultProps = {
    collapsed: false,
  };

  onCollapse = () => {
    const { onCollapse, collapsed } = this.props;
    onCollapse(!collapsed);
  };

  onExpand = () => {
    this.props.onCollapse(false);
  };

  render() {
    const { collapsed, action, selectedFilters, children } = this.props;
    return (
      <div className="ListFilters">
        {collapsed ? (
          <CollapsedListFilters
            selectedFilters={selectedFilters}
            onExpand={this.onExpand}
            action={action}
          />
        ) : (
          <ExpandedListFilters action={action}>{children}</ExpandedListFilters>
        )}
        <Button
          id="list-filters-collapse"
          className="ListFilters__collapse"
          onClick={this.onCollapse}
        >
          <i
            className={classNames('mdi', {
              'mdi-chevron-double-left': !collapsed,
              'mdi-chevron-double-right': collapsed,
            })}
          />
          {!collapsed && (
            <span className="ListFilters__collapseLabel">
              {i18n.t('frontproductstream.core.list_filters.collapse_button', {
                defaultValue: 'Collapse',
              })}
            </span>
          )}
        </Button>
      </div>
    );
  }
}

import { createAction } from 'utils/redux';

import {
  INIT_ASSIGNATION,
  PROCESS_REFUSAL,
  SET_ASSIGNATION_INFO,
  SET_LOADING,
  SET_PROCESSING,
} from './constants';

// Sagas
export const initializeAssignation = createAction(INIT_ASSIGNATION);
export const processAssignationRefusal = createAction(PROCESS_REFUSAL);

// Reducers
export const setAssignationInfo = createAction(SET_ASSIGNATION_INFO);
export const setLoading = createAction(SET_LOADING);
export const setProcessing = createAction(SET_PROCESSING);

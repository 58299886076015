import copyToClipboard from 'clipboard-copy';
import { Map } from 'immutable';
import { PureComponent } from 'react';
import ImmutablePropTypes from 'react-immutable-proptypes';
import { connect } from 'react-redux';

import { LazyTooltip } from '@alkem/react-ui-tooltip';
import { Authentication } from '@alkem/sdk-dashboard';

import {
  getLegalOrganizationName,
  getOrganizationId,
  isAtLeastPlatformAdmin,
  isLoggedAs,
} from 'core/api/user';
import authApi from 'resources/authApi';
import * as routes from 'routes';
import { getHash, getOrigin, reload } from 'utils/location';
import { logError } from 'utils/logging';

import Dropdown from './dropdown';
import './index.scss';

export function hasConnectAs(user = Map()) {
  return isAtLeastPlatformAdmin(user) || isLoggedAs(user);
}

const mapStateToProps = (state) => ({
  user: state.user,
});

class ConnectAs extends PureComponent {
  static propTypes = {
    user: ImmutablePropTypes.map.isRequired,
  };

  constructor(props) {
    super(props);
    this.logout = this.logout.bind(this);
    this.showDropdown = this.toggleDropdown.bind(this, true);
    this.hideDropdown = this.toggleDropdown.bind(this, false);
    this.state = { dropdown: false };
  }

  onCopyUsername = () => {
    const { user } = this.props;
    copyToClipboard(user.get('username'));
  };

  onCopyLogasLink = () => {
    const logAsUrl = `${getOrigin()}/#${routes.logAs}`;
    const target = encodeURIComponent(this.props.user.get('username'));
    const redirect = encodeURIComponent(getHash(true));
    copyToClipboard(`${logAsUrl}?target=${target}&redirect=${redirect}`);
  };

  logout() {
    authApi
      .UserLogout()
      .catch(logError)
      .finally(() => {
        Authentication.resetTokensAfterUnlogAs();
        reload();
      });
  }

  toggleDropdown(open, event) {
    this.stopPropagation(event);
    this.setState(({ dropdown }) => ({ dropdown: !dropdown }));
  }

  stopPropagation(event) {
    if (event) {
      event.stopPropagation();
    }
  }

  render() {
    const { user } = this.props;
    const { dropdown } = this.state;
    const loggedAs = user.getIn(['loggedAs', 'adminId']);
    if (!hasConnectAs(user)) {
      return <noscript />;
    }
    if (loggedAs) {
      const tooltipLabel = (
        <ul className="ConnectAs__moreInfo">
          <li>
            <span>ID</span>
            <span>{user.get('id')}</span>
          </li>
          <li>
            <span>Organization</span>
            <span>
              {getLegalOrganizationName(user)} / {getOrganizationId(user)}
            </span>
          </li>
          <li>
            <span>Username</span>
            <span className="ConnectAs__username" onClick={this.onCopyUsername}>
              {user.get('username')}
              <i className="mdi mdi-content-copy" />
            </span>
          </li>
          <li>
            <span>Firstname</span>
            <span>{user.get('firstname')}</span>
          </li>
          <li>
            <span>Lastname</span>
            <span>{user.get('lastname')}</span>
          </li>
          <li>
            <span>Log as link</span>
            <span
              className="ConnectAs__logasLink"
              onClick={this.onCopyLogasLink}
            >
              Copy to clipboard
              <i className="mdi mdi-content-copy" />
            </span>
          </li>
        </ul>
      );
      return (
        <div className="ConnectAs ConnectAs--logged">
          <LazyTooltip
            id="logged-as"
            className="ConnectAs__loggedAs"
            tooltipLabel={tooltipLabel}
            place="bottom"
            hoverable
            block
          >
            <span className="ConnectAs__loggedAsTitle">Logged as</span>
            <span className="ConnectAs__loggedAsName">
              {user.get('lastname') || user.get('id')}
            </span>
          </LazyTooltip>
          <i
            className="ConnectAs__icon mdi mdi-logout"
            onClick={this.logout}
            data-testid="logout"
          />
        </div>
      );
    }
    return (
      <div
        className="ConnectAs ConnectAs--notLogged"
        onClick={this.showDropdown}
      >
        <span className="ConnectAs__label">Log as</span>
        <i className="ConnectAs__icon mdi mdi-login" />
        {dropdown && <Dropdown user={user} hideDropdown={this.hideDropdown} />}
      </div>
    );
  }
}

export default connect(mapStateToProps)(ConnectAs);

import { Map } from 'immutable';
import { ComponentProps, useCallback, useEffect, useMemo } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { Referential } from '@alkem/sdk-dashboard';

import { gpcStatusFilter } from 'core/modules/list/constants/filters/gpc';
import { buildFiltersFromQuery } from 'core/modules/list/utils/filters';
import { fetchReferential } from 'core/modules/with-referential/actions';
import { selectReferential } from 'core/modules/with-referential/selectors';
import { FilterAggregation } from 'types';
import i18n from 'utils/i18n';
import { get, toJsIfImmutable } from 'utils/immutable';

import CollapsibleAdvancedFilter from '../advanced';

const REFERENTIAL = 'gpc';
const filterKey = gpcStatusFilter.key;

interface GpcFilterProps {
  selectedFilterMap?: Map<string, any>;
  aggregations?: Map<string, FilterAggregation>;
  filterConfig: Map<string, any>;
  onChange: ComponentProps<typeof CollapsibleAdvancedFilter>['onChange'];
  onCollapse: ComponentProps<typeof CollapsibleAdvancedFilter>['onCollapse'];
  onFilter: ComponentProps<typeof CollapsibleAdvancedFilter>['onFilter'];
  onChangePage: ComponentProps<
    typeof CollapsibleAdvancedFilter
  >['onChangePage'];
  onSort?: ComponentProps<typeof CollapsibleAdvancedFilter>['onSort'];
  filterQueryValues?: any[];
}

export function GpcFilter(props: GpcFilterProps) {
  const {
    aggregations = Map(),
    filterQueryValues = [],
    onChange,
    selectedFilterMap = Map(),
    onSort,
    onFilter,
    filterConfig = Map(),
    onCollapse,
    onChangePage,
  } = props;

  const dispatch = useDispatch();

  const filterList: FilterAggregation[] | undefined = useMemo(
    () => aggregations?.valueSeq().toList().toJS(),
    [aggregations],
  );

  const filterLabel = i18n.t('frontproductstream.core.list_filter_gpc.label', {
    defaultValue: 'GPC',
  });

  const referentialEntities: { entitiesById: { [key: string]: Referential } } =
    useSelector<any, Function>(selectReferential)(REFERENTIAL);

  const referentialEntitiesById: { [key: string]: Referential } | undefined =
    useMemo(
      () => toJsIfImmutable(get(referentialEntities, ['entitiesById'])),
      [referentialEntities],
    );

  const selectors = useMemo(() => {
    const selectId = (filter) => get(filter, 'id');
    const selectLabel = (filter) =>
      referentialEntitiesById?.[selectId(filter)]?.label || '';
    return { selectId, selectLabel };
  }, [referentialEntitiesById]);

  useEffect(() => {
    referentialEntities || dispatch(fetchReferential(REFERENTIAL));
  }, [dispatch, referentialEntities]);

  const updateSelectionFromQuery = useCallback(() => {
    buildFiltersFromQuery({
      filterQueryValues,
      filterList,
      filterKey,
      selectFilterValue: (filter) => selectors.selectId(filter),
      selectFilterLabel: (filter) =>
        `${filterLabel}: ${selectors.selectLabel(filter)}`,
      selectFilterData: (filter) => [selectors.selectId(filter)],
    }).then((filtersFromQuery) => {
      onChange(filtersFromQuery, true);
    });
  }, [filterLabel, filterList, filterQueryValues, onChange, selectors]);

  useEffect(() => {
    if (
      !aggregations?.isEmpty() &&
      selectedFilterMap?.isEmpty() &&
      referentialEntitiesById
    ) {
      updateSelectionFromQuery();
    }
  }, [
    aggregations,
    referentialEntitiesById,
    selectedFilterMap,
    updateSelectionFromQuery,
  ]);

  return (
    <CollapsibleAdvancedFilter
      id="list-filter-gpc"
      filterKey={filterKey}
      filterLabel={filterLabel}
      filters={filterList}
      selectedFilterMap={selectedFilterMap}
      aggregations={aggregations}
      searchPlaceholder={i18n.t(
        'frontproductstream.core.list_filter_gpc.search_placeholder',
        { defaultValue: 'Search for gpc' },
      )}
      searchQuery={get(filterConfig, ['query'])}
      page={get(filterConfig, ['page'])}
      collapsed={filterConfig.get('collapsed')}
      onChange={onChange}
      onCollapse={onCollapse}
      onFilter={onFilter}
      onChangePage={onChangePage}
      onSort={onSort}
      withPagination
      selectors={selectors}
    />
  );
}

import PropTypes from 'prop-types';
import { PureComponent } from 'react';
import ImmutablePropTypes from 'react-immutable-proptypes';
import { connect } from 'react-redux';
import { createStructuredSelector } from 'reselect';

import { Textarea } from '@alkem/react-ui-inputs';

import Modal from 'components/ui/modal';
import i18n from 'utils/i18n';
import { separateActions } from 'utils/redux';

import {
  closeUnavailableUnsyncModal,
  sendRetailerMessage,
} from '../../actions';
import {
  selectLoadingInProgress,
  selectTargetRecipient,
} from '../../selectors';
import '../index.scss';

const mapStateToProps = createStructuredSelector({
  targetRecipient: selectTargetRecipient,
  loading: selectLoadingInProgress,
});

const mapDispatchToProps = {
  closeModal: closeUnavailableUnsyncModal,
  sendMessage: sendRetailerMessage,
};

export class UnavailableUnsynchronizationModal extends PureComponent {
  static propTypes = {
    targetRecipient: ImmutablePropTypes.map.isRequired,
    loading: PropTypes.bool.isRequired,
    actions: PropTypes.shape({
      closeModal: PropTypes.func.isRequired,
      sendMessage: PropTypes.func.isRequired,
    }).isRequired,
  };

  state = {
    message: i18n.t(
      'Hello,\nThis product is no longer available. Could you please remove it from your active range ?',
    ),
  };

  sendMessage = () => {
    const { actions, loading } = this.props;
    const { message } = this.state;
    if (loading || !this.validateMessage(message)) {
      return;
    }
    actions.sendMessage({ message });
  };

  onMessageChange = ({ target: { value } }) => {
    this.setState({ message: value });
  };

  validateMessage(message) {
    return message.length > 0;
  }

  renderBody() {
    const { targetRecipient, loading } = this.props;
    const { message } = this.state;
    return (
      <div className="UnavailableUnsynchronizationModal__content">
        {i18n.t(
          "{{recipientName}} is stating that he is still selling this product. Therefore, we can't unsynchronize this product.",
          { recipientName: targetRecipient.get('nameLegal') },
        )}
        <br />
        <br />
        {i18n.t(
          'If you think that it is a mistake, use the following field to send a message:',
        )}
        <Textarea
          id="retailer-message"
          value={message}
          onChange={this.onMessageChange}
          disabled={loading}
        />
      </div>
    );
  }

  render() {
    const { actions, loading } = this.props;
    const { message } = this.state;
    return (
      <Modal
        modalStyle="dynamic"
        title={i18n.t('This product is in the recipient active range')}
        confirmButtonText={i18n.t('Send')}
        confirmDisabled={!this.validateMessage(message)}
        isProcessing={loading}
        onConfirm={this.sendMessage}
        onClose={actions.closeModal}
      >
        {this.renderBody()}
      </Modal>
    );
  }
}

export default connect(
  mapStateToProps,
  mapDispatchToProps,
  separateActions,
)(UnavailableUnsynchronizationModal);

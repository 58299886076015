import { Map } from 'immutable';
import { memo, useCallback, useMemo } from 'react';
import { useSelector } from 'react-redux';

import { isRetailer } from 'core/api/user';
import {
  patchAcceptedFilter,
  patchDisputedFilter,
  patchFilter,
  patchIgnoredFilter,
  patchPendingFilter,
  patchRefusedFilter,
} from 'core/modules/list/constants/filters/patch';
import {
  RELEASE_B2K_COLLAB_EDITS_NEWFILTER,
  hasFeature,
} from 'modules/feature-flag';
import { selectUser } from 'modules/user';
import {
  FilterOnChange,
  FilterOnCollapse,
  ListAggregationItemImmubtale,
  ListAggregations,
} from 'types';
import i18n from 'utils/i18n';

import CollapsibleFilter from '../collapsible';
import { SelectedFilterMap } from '../generic/types';
import { useQueryFilter } from '../generic/utils';
import FilterItem from '../simple/item';

import './index.scss';

interface PatchFilterInfos {
  id: string;
  label: string;
}

interface PatchStatusFilterInfos {
  id: string;
  key: string;
  label: string;
}

interface PatchFilterProps {
  selectedFilterMap: Map<string, any>;
  aggregations: ListAggregations;
  collapsed: boolean;
  getFilterQueryValues: (key: string) => string[];
  onChange: FilterOnChange;
  onCollapse: FilterOnCollapse;
}

interface PatchStatusesFilterProps {
  selectedFilterMap: Map<string, any>;
  getFilterQueryValues: (key: string) => string[];
  onChange: FilterOnChange;
  parentFilterLabel: string;
  aggregations: ListAggregations;
}

interface PatchStatusFilterProps {
  filter: PatchStatusFilterInfos;
  onChange: FilterOnChange;
  selectedFilterMap: Map<string, any>;
  filterQueryValues: string[];
  parentFilterLabel: string;
  aggregation: ListAggregationItemImmubtale;
}

const PatchStatusFilter = memo(
  ({
    filter,
    onChange,
    selectedFilterMap,
    filterQueryValues,
    parentFilterLabel,
    aggregation,
  }: PatchStatusFilterProps) => {
    const getFilterLabel = (filt: PatchStatusFilterInfos) =>
      `${parentFilterLabel}: ${filt.label}`;

    useQueryFilter({
      options: [filter],
      filter: {
        key: filter.key,
        selectFilterValue: (filt: PatchStatusFilterInfos) => filt.id,
        selectFilterLabel: getFilterLabel,
        selectFilterData: (filt: PatchStatusFilterInfos) => filt,
      },
      filterQueryValues,
      selectedFilterMap,
      onChange,
    });

    const onLocalChange = (filt: PatchStatusFilterInfos, selected: boolean) => {
      onChange({
        key: filt.key,
        value: filt.id,
        label: getFilterLabel(filt),
        add: selected,
        data: filt,
      });
    };

    return (
      <FilterItem
        filterKey={filter.key}
        filter={filter}
        selected={!!selectedFilterMap.get(filter.id)}
        onChange={onLocalChange}
        aggregation={aggregation}
        hasDocCount
      />
    );
  },
);

const PatchStatusesFilter = memo(
  ({
    selectedFilterMap,
    getFilterQueryValues,
    onChange,
    parentFilterLabel,
    aggregations,
  }: PatchStatusesFilterProps) => {
    const filterList = useMemo(
      () => [
        {
          id: 'true',
          key: patchAcceptedFilter.key,
          label: i18n.t(
            'frontproductstream.core.list_filter_patch.has_patch_accepted_option',
            { defaultValue: 'patch accepted' },
          ),
        },
        {
          id: 'true',
          key: patchRefusedFilter.key,
          label: i18n.t(
            'frontproductstream.core.list_filter_patch.has_patch_refused_option',
            { defaultValue: 'patch refused' },
          ),
        },
        {
          id: 'true',
          key: patchPendingFilter.key,
          label: i18n.t(
            'frontproductstream.core.list_filter_patch.has_patch_pending_option',
            { defaultValue: 'patch pending' },
          ),
        },
        {
          id: 'true',
          key: patchDisputedFilter.key,
          label: i18n.t(
            'frontproductstream.core.list_filter_patch.has_patch_disputed_option',
            { defaultValue: 'patch disputed' },
          ),
        },
        {
          id: 'true',
          key: patchIgnoredFilter.key,
          label: i18n.t(
            'frontproductstream.core.list_filter_patch.has_patch_ignored_option',
            { defaultValue: 'patch ignored' },
          ),
        },
      ],
      [],
    );

    return (
      <>
        {filterList.map((filter: PatchStatusFilterInfos) => {
          return (
            <PatchStatusFilter
              key={`list-filter-${filter.key}`}
              filter={filter}
              onChange={onChange}
              selectedFilterMap={selectedFilterMap.get(filter.key) || Map()}
              parentFilterLabel={parentFilterLabel}
              filterQueryValues={getFilterQueryValues(filter.key)}
              aggregation={aggregations.get(filter.key)?.get(filter.id as any)}
            />
          );
        })}
      </>
    );
  },
);

export const PatchFilter = memo(
  ({
    collapsed = false,
    aggregations = Map(),
    getFilterQueryValues,
    onChange,
    onCollapse,
    selectedFilterMap,
  }: PatchFilterProps) => {
    const filterKey = patchFilter.key;
    const filterLabel = i18n.t(
      'frontproductstream.core.list_filter_patch.label',
      { defaultValue: 'Patch' },
    );
    const patchFilterQueryValues = getFilterQueryValues(filterKey) || [];
    const patchSelectedFilterMap: SelectedFilterMap =
      selectedFilterMap.get(filterKey) || Map();

    const onLocalCollapse = useCallback(
      (isCollapsed) => {
        onCollapse(filterKey, isCollapsed);
      },
      [onCollapse, filterKey],
    );

    const filterList = useMemo(
      () => [
        {
          id: 'false',
          label: i18n.t(
            'frontproductstream.core.list_filter_patch.has_no_patch_option',
            { defaultValue: 'hasnopatch' },
          ),
        },
        {
          id: 'true',
          label: i18n.t(
            'frontproductstream.core.list_filter_patch.has_patch_option',
            { defaultValue: 'haspatch' },
          ),
        },
      ],
      [],
    );

    useQueryFilter({
      options: filterList,
      filter: {
        key: filterKey,
        selectFilterValue: (filter: PatchFilterInfos) => filter.id,
        selectFilterLabel: (filter: PatchFilterInfos) =>
          `${filterLabel}: ${filter.label}`,
        selectFilterData: (filter: PatchFilterInfos) => filter,
      },
      filterQueryValues: patchFilterQueryValues,
      selectedFilterMap: patchSelectedFilterMap,
      onChange,
    });

    const onLocalChange = (filter: PatchFilterInfos, selected: boolean) => {
      onChange({
        key: filterKey,
        value: filter.id,
        label: `${filterLabel}: ${filter.label}`,
        add: selected,
        data: filter,
      });
    };

    const user = useSelector(selectUser);
    const isRetailerWithReleaseFlag =
      isRetailer(user) && hasFeature(user, RELEASE_B2K_COLLAB_EDITS_NEWFILTER);

    const hasDocCount = filterList.some(
      (filter: PatchFilterInfos) =>
        aggregations.get(filterKey)?.getIn([filter.id, 'doc_count']) >= 0,
    );

    return (
      <CollapsibleFilter
        id={`list-filter-${filterKey}`}
        label={filterLabel}
        collapsed={collapsed}
        onCollapse={onLocalCollapse}
      >
        <div>
          {filterList.map((filter: PatchFilterInfos) => (
            <FilterItem
              key={`list-filter-${filterKey}-${filter.id}`}
              filterKey={filterKey}
              filter={filter}
              aggregation={aggregations.get(filterKey)?.get(filter.id as any)}
              selected={!!patchSelectedFilterMap.get(filter.id as any)}
              hasDocCount={hasDocCount}
              onChange={onLocalChange}
            />
          ))}
          {isRetailerWithReleaseFlag && (
            <div className="FilterByPatchStatuses">
              <PatchStatusesFilter
                getFilterQueryValues={getFilterQueryValues}
                onChange={onChange}
                selectedFilterMap={selectedFilterMap}
                parentFilterLabel={filterLabel}
                aggregations={aggregations}
              />
            </div>
          )}
        </div>
      </CollapsibleFilter>
    );
  },
);

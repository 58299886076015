import PropTypes from 'prop-types';
import { PureComponent } from 'react';
import ImmutablePropTypes from 'react-immutable-proptypes';

import Dropdown from 'components/ui/dropdown';
import i18n from 'utils/i18n';

import { getId } from '../../../../selectors/referential';

import './index.scss';
import Option from './option';

export default class CatalogColumnSelector extends PureComponent {
  static propTypes = {
    referentials: ImmutablePropTypes.list.isRequired,
    columnMap: ImmutablePropTypes.map.isRequired,
    onChange: PropTypes.func.isRequired,
  };

  constructor(props) {
    super(props);
    this.onClickOption = this.onClickOption.bind(this);
    this.selectLabel = this.selectLabel.bind(this);
  }

  onClickOption(referential, selected) {
    this.props.onChange(referential, selected);
  }

  selectId(referential) {
    return `catalog-column-selector-option-${getId(referential)}`;
  }

  selectLabel(referential) {
    return (
      <Option
        referential={referential}
        onChange={this.onClickOption}
        selected={this.props.columnMap.get(getId(referential))}
      />
    );
  }

  render() {
    return (
      <div className="CatalogColumnSelector">
        <Dropdown
          label={i18n.t('Columns')}
          options={this.props.referentials}
          selectKey={this.selectId}
          selectLabel={this.selectLabel}
          closeDropdownOnClickElement={false}
          rightDropdown
        />
      </div>
    );
  }
}

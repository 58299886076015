import { List } from 'immutable';

import { getActiveShares } from 'core/api/productversion';
import { applyRulesForSpecificRecipients } from 'modules/validation';
import { selectEditedProductVersion } from 'reducers/productVersion';
import { keyBy } from 'utils/fn';
import { get, set, size } from 'utils/immutable';

import * as constants from './constants';

export const open = (unsyncableRecipients) => (dispatch) =>
  new Promise((resolve, reject) => {
    dispatch({
      type: constants.OPEN_UNSYNCABLE_RECIPIENTS_MODAL,
      unsyncableRecipients,
      promise: { resolve, reject },
    });
  });

export const close = (confirmed) => ({
  type: constants.CLOSE_UNSYNCABLE_RECIPIENTS_MODAL,
  confirmed,
});

export const getUnsyncableRecipients = () => async (dispatch, getState) => {
  const state = getState();
  const productVersionEdited = selectEditedProductVersion(state);
  const activeShares = getActiveShares(productVersionEdited);
  if (!size(activeShares)) {
    return List();
  }

  const activeRecipientsById = keyBy(
    activeShares.map((share) => get(share, 'targetOrganization')),
    'id',
  );

  const response = await dispatch(
    applyRulesForSpecificRecipients({
      recipientIds: Object.keys(activeRecipientsById).map(Number), // casting ids to numbers
    }),
  );

  const unsyncableRecipients = get(response, 'recipients')
    .filter(
      (r) =>
        get(r, 'id') in activeRecipientsById && get(r, 'failedBlocking') > 0,
    )
    // let's add the nameLegal to the payload
    .map((r) =>
      set(
        r,
        'nameLegal',
        get(activeRecipientsById, [get(r, 'id'), 'nameLegal']),
      ),
    );

  return unsyncableRecipients;
};

export const confirmUnsyncableRecipients = () => async (dispatch) => {
  const unsyncableRecipients = await dispatch(getUnsyncableRecipients());
  if (!unsyncableRecipients || !size(unsyncableRecipients)) {
    return true;
  }
  const confirmed = await dispatch(open(unsyncableRecipients));
  return confirmed;
};

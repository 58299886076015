import cn from 'classnames';
import { useLocation, useNavigate } from 'react-router-dom';

import Dropdown from 'components/ui/dropdown';
import Label, { LABEL_DEFAULT } from 'components/ui/label';
import i18n from 'utils/i18n';
import { track } from 'utils/tracking';

import './index.scss';

const SharingUnitDropdown = () => {
  const navigate = useNavigate();
  const { pathname } = useLocation();
  const trackThenNavigate = (optionKey) => {
    if (optionKey === '/templates')
      track({ category: 'listing', action: 'bulknetprice_clicked' });
    navigate(optionKey);
  };

  return (
    <a
      id="nav-link-sharingunit"
      className={cn(
        'nav-link',
        {
          active:
            pathname.includes('/listings') || pathname.includes('/templates'),
        },
        'SharingUnitDropdown__NavLink',
      )}
    >
      <Dropdown
        dropdownClassNames={{
          dropdownList: 'SharingUnitDropdown__DropdownList',
          dropdown: 'SharingUnitDropdown__Dropdown',
          dropdownButton: 'SharingUnitDropdown__DropdownButton',
        }}
        onClickOption={(option) => trackThenNavigate(option.key)}
        label={
          <div
            className={cn('nav-item', 'SharingUnitDropdown__DropdownLabel', {
              SharingUnitDropdown__DropdownLabelActive:
                pathname.includes('/listings') ||
                pathname.includes('/templates'),
            })}
          >
            {i18n.t('frontproductstream.navbar.sharing_unit_dropdown.label', {
              defaultValue: 'Tarifs et conditions',
            })}
            <i className="mdi mdi-chevron-down SharingUnitDropdown__Chevron" />
          </div>
        }
        options={[
          {
            key: '/listings',
            label: (
              <div className="SharingUnitDropdown__Option">
                <div className="SharingUnitDropdown__OptionHeader SharingUnitDropdown__OptionHeader--noPadding SharingUnitDropdown__OptionTitle">
                  {i18n.t(
                    'frontproductstream.navbar.sharing_unit_dropdown.listing_title',
                    {
                      defaultValue: 'Share my gross prices with Leclerc',
                    },
                  )}
                </div>
                <div className="SharingUnitDropdown__OptionText">
                  {i18n.t(
                    'frontproductstream.navbar.sharing_unit_dropdown.listing_content',
                    {
                      defaultValue:
                        'Create and share your tariffs (gross price catalog) with E.Leclerc',
                    },
                  )}
                </div>
              </div>
            ),
          },
          {
            key: '/templates',
            label: (
              <div className="SharingUnitDropdown__Option SharingUnitDropdown__Option--noBorder">
                <div className="SharingUnitDropdown__OptionHeader">
                  <span className="SharingUnitDropdown__OptionTitle">
                    {i18n.t(
                      'frontproductstream.navbar.sharing_unit_dropdown.templates_title',
                      {
                        defaultValue: 'Share my net prices in bulk',
                      },
                    )}
                  </span>
                  <Label
                    filled
                    type={LABEL_DEFAULT}
                    className="SharingUnitDropdown__OptionLabelWrapper"
                  >
                    <span className="SharingUnitDropdown__OptionLabel">
                      {i18n.t(
                        'frontproductstream.navbar.sharing_unit_dropdown.templates_new',
                        {
                          defaultValue: 'NEW',
                        },
                      )}
                    </span>
                  </Label>
                </div>
                <div className="SharingUnitDropdown__OptionText">
                  {i18n.t(
                    'frontproductstream.navbar.sharing_unit_dropdown.templates_content',
                    {
                      defaultValue:
                        'Create price waterfalls (via referencing) to share your prices in bulk on your product pages',
                    },
                  )}
                </div>
              </div>
            ),
          },
        ]}
      />
    </a>
  );
};

export { SharingUnitDropdown };

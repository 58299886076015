import { Map, fromJS } from 'immutable';
import { once } from 'lodash';
import PropTypes from 'prop-types';
import { PureComponent } from 'react';
import ImmutablePropTypes from 'react-immutable-proptypes';

import { worksWithSourcingFilter } from 'core/modules/list/constants/filters/works-with-sourcing';
import { buildFiltersFromQuery } from 'core/modules/list/utils/filters';
import i18n from 'utils/i18n';

import SimpleFilter from '../simple';

export class WorksWithSourcingFilter extends PureComponent {
  static propTypes = {
    selectedFilterMap: ImmutablePropTypes.map,
    filterQueryValues: PropTypes.array,
    aggregations: ImmutablePropTypes.map,
    collapsed: PropTypes.bool,
    onChange: PropTypes.func.isRequired,
    onCollapse: PropTypes.func.isRequired,
  };

  static defaultProps = {
    selectedFilterMap: Map(),
    aggregations: Map(),
    collapsed: false,
  };

  filterKey = worksWithSourcingFilter.key;

  updateSelectionFromQuery = once(() => {
    buildFiltersFromQuery({
      filterQueryValues: this.props.filterQueryValues,
      filterList: this.filterList,
      filterKey: this.filterKey,
      selectFilterValue: (filter) => filter.get('id'),
      selectFilterLabel: (filter) =>
        `${this.filterLabel}: ${filter.get('label')}`,
      selectFilterData: (filter) => filter,
    }).then((filtersFromQuery) => {
      this.props.onChange(filtersFromQuery, true);
    });
  });

  constructor(props) {
    super(props);
    this.filterList = fromJS([
      {
        id: 'true',
        label: i18n.t(
          'frontproductstream.core.list_filter_works_with_sourcing.yes_option',
          { defaultValue: 'Yes' },
        ),
      },
      {
        id: 'false',
        label: i18n.t(
          'frontproductstream.core.list_filter_works_with_sourcing.yes_option',
          { defaultValue: 'No' },
        ),
      },
    ]);
    this.filterLabel = i18n.t(
      'frontproductstream.core.list_filter_works_with_sourcing.label',
      { defaultValue: 'Listed manufacturers' },
    );
  }

  componentDidMount() {
    if (this.shouldUpdateSelectionFromQuery()) {
      this.updateSelectionFromQuery();
    }
  }

  componentDidUpdate() {
    if (this.shouldUpdateSelectionFromQuery()) {
      this.updateSelectionFromQuery();
    }
  }

  onCollapse = (collapsed) => {
    this.props.onCollapse(this.filterKey, collapsed);
  };

  shouldUpdateSelectionFromQuery = () => {
    const { aggregations, selectedFilterMap } = this.props;
    return !aggregations.isEmpty() && selectedFilterMap.isEmpty();
  };

  render() {
    return (
      <SimpleFilter
        id="list-filter-workswith-sourcing"
        filterList={this.filterList}
        filterKey={this.filterKey}
        filterLabel={this.filterLabel}
        selectedFilterMap={this.props.selectedFilterMap}
        aggregations={this.props.aggregations}
        collapsed={this.props.collapsed}
        onChange={this.props.onChange}
        onCollapse={this.onCollapse}
      />
    );
  }
}

import classNames from 'classnames';
import { bool, func } from 'prop-types';
import { Component } from 'react';
import { map } from 'react-immutable-proptypes';

import ListTableCell from 'core/modules/list/components/table/cell';
import EmptyCell from 'core/modules/list/components/table/cell/empty';
import NumberCell from 'core/modules/list/components/table/cell/number';
import TextCell from 'core/modules/list/components/table/cell/text';

import {
  AVERAGE_TIME_CELL,
  FRACTION_CELL,
  NEUTRAL_CELL,
  NUMBER_CELL,
  NUMBER_CLICKABLE_CELL,
  SIMPLE_QUALITY_CELL,
  TEXT_CELL,
  TOTAL_PERCENTAGE_CELL,
} from '../../../../constants';
import { getReportData } from '../../../../utils';

import AverageTimeCell from './average-time';
import FractionCell from './fraction';
import './index.scss';
import { NumberClickableCell } from './number';
import SimpleQualityCell from './simple-quality';

export default class ReportingTableCell extends Component {
  static propTypes = {
    report: map.isRequired,
    referential: map.isRequired,
    isAggregation: bool,
    isFirst: bool,
    onClickCell: func.isRequired,
    isClickable: bool,
    isSticky: bool,
  };

  static defaultProps = {
    isAggregation: false,
    isFirst: false,
  };

  CELLS = {
    [TEXT_CELL]: TextCell,
    [NUMBER_CELL]: NumberCell,
    [SIMPLE_QUALITY_CELL]: SimpleQualityCell,
    [TOTAL_PERCENTAGE_CELL]: SimpleQualityCell,
    [FRACTION_CELL]: FractionCell,
    [AVERAGE_TIME_CELL]: AverageTimeCell,
    [NEUTRAL_CELL]: SimpleQualityCell,
    [NUMBER_CLICKABLE_CELL]: NumberClickableCell,
  };

  getCell() {
    const { report, referential } = this.props;
    const code = referential.get('key');
    const type = referential.getIn(['cell', 'type']);
    const props = { code, center: true };
    const data = getReportData(report, code, type);
    const Cell = this.CELLS[type] || EmptyCell;
    if (type === TEXT_CELL) {
      props.center = false;
      props.tooltipOverCharacters = 20;
      props.maxWidth = '170px';
    }
    return { Cell, data, props };
  }

  render() {
    const {
      referential,
      isAggregation,
      isFirst,
      onClickCell,
      isClickable,
      isSticky,
    } = this.props;
    const { Cell, data, props } = this.getCell();
    const { center, code, ...columnProps } = props;
    return (
      <ListTableCell
        className={classNames(
          'ReportingTableCell',
          isAggregation && 'ReportingTableCell--aggregation',
          isFirst && 'ReportingTableCell--first',
        )}
        center={center}
        data={{ code }}
        isSticky={isSticky}
      >
        <Cell
          data={data}
          referential={referential}
          isAggregation={isAggregation}
          onClickCell={onClickCell}
          isClickable={isClickable}
          {...columnProps}
        />
      </ListTableCell>
    );
  }
}

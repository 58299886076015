import { flatten } from 'lodash/fp';

import { get, set, size, update } from 'utils/immutable';

export const getAsReadOnly = (item, ignoreFields = []) => {
  let localItem = item;
  let childrenKey = 'items';
  if (get(localItem, 'kind') === 'Field') {
    if (ignoreFields.includes(get(localItem, 'model'))) {
      return localItem;
    }

    childrenKey = 'children';
    if (!get(localItem, 'options')) {
      localItem = set(localItem, ['options'], {});
    }
    if (!get(localItem, 'isPrivate', false)) {
      localItem = set(localItem, ['options', 'readOnly'], true);
    }
  }
  if (!get(localItem, childrenKey) || !size(get(localItem, childrenKey))) {
    return localItem;
  }
  return update(localItem, [childrenKey], (items) =>
    items.map((e) => getAsReadOnly(e, ignoreFields)),
  );
};

export const findFieldByModel = (items, model) => {
  let found;
  if (!items) {
    return found;
  }
  items.forEach((item) => {
    if (found) {
      return;
    }
    if (item.model && item.model === model) {
      found = item;
      return;
    }
    let childrenKey = 'items';
    if (item.kind === 'Field') {
      childrenKey = 'children';
    }
    found = findFieldByModel(item[childrenKey], model);
  });
  return found;
};

export const flattenedFields = (items) => {
  const fields = [];
  if (!items || !size(items)) {
    return fields;
  }
  for (const item of items) {
    if (get(item, 'kind') === 'Field') {
      fields.push(item);
    } else {
      fields.push(flattenedFields(get(item, 'items')));
    }
  }
  return flatten(fields);
};

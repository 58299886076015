import { flow } from 'lodash/fp';

import { MODULE_REDUCER_KEY } from 'constants/reducer';

import { NORMALIZED_COMMENTS_STORE_KEY } from './constants';
import { NormalizedCommentsState } from './reducers';

const selectModuleState = (state) =>
  state[MODULE_REDUCER_KEY][
    NORMALIZED_COMMENTS_STORE_KEY
  ] as NormalizedCommentsState;

export const selectPendingMessages = flow(
  selectModuleState,
  (state) => state.pendingMessages,
);

export const selectIsSending = flow(
  selectModuleState,
  (state) => state.isSending,
);

export const selectIsMessageModalOpen = flow(
  selectModuleState,
  (state) => state.isMessageModalOpen,
);

export const selectDeprecatedNormalizedComments = flow(
  selectModuleState,
  (state) => state.deprecatedNormalizedComments,
);

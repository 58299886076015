import { flow } from 'lodash/fp';
import { createReducer } from 'redux-create-reducer';

import { setFp } from 'utils/immutable';

import {
  CLOSE_BULK_MESSAGE_MODAL,
  SHOW_BULK_MESSAGE_MODAL,
} from '../actions/types';

export const KEY = 'bulkMessageModule';

export interface ModuleState {
  visible: boolean;
}

export const initialState: ModuleState = {
  visible: false,
};

export default createReducer<ModuleState>(initialState, {
  [SHOW_BULK_MESSAGE_MODAL]: (state) => flow(setFp('visible', true))(state),
  [CLOSE_BULK_MESSAGE_MODAL]: (state) => flow(setFp('visible', false))(state),
});

import { fromJS } from 'immutable';
import { useCallback } from 'react';

import NumberCell from 'core/modules/list/components/table/cell/number';

type Props = {
  data?: number;
  isDefaultData?: boolean;
  onClickCell: (data: any) => void;
  referential: Map<string, any>;
  isAggregation: boolean;
  isClickable?: boolean;
};

export const NumberClickableCell = (props: Props) => {
  const { data, isDefaultData, referential } = props;

  const isClickable = useCallback((): boolean => {
    const defaultConfig = fromJS({
      clickable: false,
    });
    const config = referential.get('cell') || defaultConfig;
    return config.get('clickable') && data && data > 0;
  }, [data, referential]);

  const onClick = () => {
    const { onClickCell, isAggregation } = props;
    onClickCell({
      referential,
      isAggregation,
    });
  };

  return (
    <NumberCell
      isClickable={isClickable()}
      isDefaultData={isDefaultData}
      data={data || 0}
      onClickCell={onClick}
    />
  );
};

import { createSelector } from '@reduxjs/toolkit';

import { MODULE_REDUCER_KEY } from 'constants/reducer';

import { REDUCER_KEY } from '../constants';

const selectModuleState = (state) => state[MODULE_REDUCER_KEY][REDUCER_KEY];

export const selectIsOpen = createSelector(selectModuleState, (state) =>
  state.get('isOpen'),
);

export const selectForm = createSelector(selectModuleState, (state) =>
  state.get('form'),
);

export const selectEmails = createSelector(selectForm, (state) =>
  state.get('emails'),
);

export const selectMessage = createSelector(selectForm, (state) =>
  state.get('message'),
);

export const selectSourcingLabel = createSelector(selectForm, (state) =>
  state.get('sourcingLabel'),
);

export const selectAllProducts = createSelector(selectForm, (state) =>
  state.get('allProducts'),
);

export const selectIsLoading = createSelector(selectModuleState, (state) =>
  state.get('isLoading'),
);

export const selectIsFormValid = (state) =>
  selectAllProducts(state) || !!selectSourcingLabel(state);

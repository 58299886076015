import { useDispatch } from 'react-redux';

import {
  MFE_RESET_LOADING_EVENT,
  MFE_START_LOADING_EVENT,
  MFE_STOP_LOADING_EVENT,
  useSubscribe,
} from '@alkem/micro-frontend-tools';

import { resetLoading, startLoading, stopLoading } from 'actions/loading';

export function LoadingListener() {
  const dispatch = useDispatch();

  useSubscribe<void>(MFE_START_LOADING_EVENT, () => {
    dispatch(startLoading());
  });

  useSubscribe<void>(MFE_STOP_LOADING_EVENT, () => {
    dispatch(stopLoading());
  });

  useSubscribe<void>(MFE_RESET_LOADING_EVENT, () => {
    dispatch(resetLoading());
  });

  return null;
}

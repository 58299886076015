import PropTypes from 'prop-types';
import { PureComponent } from 'react';
import ImmutablePropTypes from 'react-immutable-proptypes';

import PaginationInfo from '@alkem/react-ui-list/src/pagination/info';

import i18n from 'utils/i18n';

import './pagination-info.scss';

export default class CatalogPaginationInfo extends PureComponent {
  static propTypes = {
    pagination: ImmutablePropTypes.map.isRequired,
    selectedMap: ImmutablePropTypes.map.isRequired,
    withoutSelection: PropTypes.bool,
  };

  constructor(props) {
    super(props);
    this.getPageLabel = this.getPageLabel.bind(this);
    this.getSelectedLabel = this.getSelectedLabel.bind(this);
    this.getEntityLabel = this.getEntityLabel.bind(this);
  }

  getPageLabel(page, total) {
    const data = { page: page || 0, total: total || 0 };
    if (total > 1) {
      return i18n.t('{{page}} / {{total}} pages', data);
    }
    return i18n.t('{{page}} / {{total}} page', data);
  }

  getSelectedLabel(count) {
    if (count > 1) {
      return i18n.t('{{count}} selected (plural)', { count });
    }
    return i18n.t('{{count}} selected', { count });
  }

  getEntityLabel(count) {
    const entityLabels = {
      singular: i18n.t('{{count}} product in total', { count }),
      plural: i18n.t('{{count}} products in total', { count }),
    };
    return count > 1 ? entityLabels.plural : entityLabels.singular;
  }

  getSelectedCount() {
    if (this.props.withoutSelection) {
      return -1;
    }
    return this.props.selectedMap.filter((selected) => selected).size;
  }

  render() {
    return (
      <div className="CatalogPaginationInfo">
        <PaginationInfo
          currentPage={this.props.pagination.get('page')}
          totalPages={this.props.pagination.get('pages')}
          totalResults={this.props.pagination.get('total')}
          selected={this.getSelectedCount()}
          getPageLabel={this.getPageLabel}
          getSelectedLabel={this.getSelectedLabel}
          getEntityLabel={this.getEntityLabel}
        />
      </div>
    );
  }
}

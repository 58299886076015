import classNames from 'classnames';
import PropTypes from 'prop-types';
import { PureComponent } from 'react';
import { connect } from 'react-redux';

import {
  defineAnchorNavigationSection,
  scrolledToAnchorNavigationSection,
} from 'actions/navigation';
import { scrollSmoothly } from 'utils/scroll';

const mapStateToProps = (state) => ({
  scrollHeight: state.navigation.scrollHeight,
  selectedAnchoredSection: state.navigation.selectedAnchoredSection,
});

const DEFAULT_ANCHOR_OFFSET = 300;
const ANCHOR_CLICK_OFFSET = 50;

class AnchoredBlock extends PureComponent {
  static propTypes = {
    scrollHeight: PropTypes.number.isRequired,
    selectedAnchoredSection: PropTypes.string,
    dispatch: PropTypes.func.isRequired,
    children: PropTypes.node,
    blockId: PropTypes.string,
    blockName: PropTypes.string.isRequired,
    collapsible: PropTypes.bool,
    collapsed: PropTypes.bool,
    onCollapsedClick: PropTypes.func,
    'data-testid': PropTypes.string,
  };

  componentDidMount() {
    // Ensure the component has been drawn to avoid bad position
    const positionY = this.computeNewPositionY(DEFAULT_ANCHOR_OFFSET);
    this.props.dispatch(
      defineAnchorNavigationSection(
        this.props.blockName,
        positionY,
        this.props.blockId,
      ),
    );
  }

  componentDidUpdate(prevProps) {
    const { selectedAnchoredSection } = this.props;
    const { blockId, blockName } = prevProps;
    if (
      selectedAnchoredSection &&
      [blockId, blockName].includes(selectedAnchoredSection)
    ) {
      // Scroll to the top of the block
      this.scrollTo(this.computeNewPositionY(ANCHOR_CLICK_OFFSET));
    }
    if (this.props.scrollHeight !== prevProps.scrollHeight) {
      const positionY = this.computeNewPositionY(DEFAULT_ANCHOR_OFFSET);

      this.props.dispatch(
        defineAnchorNavigationSection(
          this.props.blockName,
          positionY,
          this.props.blockId,
        ),
      );
    }
  }

  setNode = (node) => {
    this.node = node;
  };

  computeNewPositionY(anchorOffet = DEFAULT_ANCHOR_OFFSET) {
    const nodeRect = this.node.getBoundingClientRect();
    if (nodeRect.top === 0) {
      // element is hidden
      return null;
    }
    const bodyRect = document.body.getBoundingClientRect();
    return nodeRect.top - bodyRect.top - anchorOffet;
  }

  scrollTo(top) {
    scrollSmoothly({ top });
    this.props.dispatch(scrolledToAnchorNavigationSection());
  }

  render() {
    const { collapsible, collapsed, 'data-testid': dataTestId } = this.props;
    return (
      <div
        ref={this.setNode}
        className={classNames('AnchoredBlock', {
          'AnchoredBlock--collapsed': collapsed,
          'AnchoredBlock--open': collapsible && !collapsed,
        })}
        onClick={this.props.onCollapsedClick}
        data-testid={dataTestId}
      >
        {this.props.children}
      </div>
    );
  }
}

export default connect(mapStateToProps)(AnchoredBlock);

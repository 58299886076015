import { registerModuleReducer } from 'utils/modules/reducer';
import { registerSaga } from 'utils/modules/saga';

import PromoteProductsButton from './components/button';
import PromoteProductsModal from './components/modal';
import { REDUCER_KEY } from './constants';
import mainReducer from './reducers';
import mainSaga from './sagas';

registerSaga(mainSaga);
registerModuleReducer(REDUCER_KEY, mainReducer);

export { PromoteProductsModal, PromoteProductsButton };
export default PromoteProductsModal;

import { find } from 'lodash';
import { useSelector } from 'react-redux';

import { selectWorksOnTargetMarkets } from 'modules/user';
import { selectProductKeyTargetMarketId } from 'reducers/productVersion';
import i18n from 'utils/i18n';
import { toJsIfImmutable } from 'utils/immutable';

import ContactButton from './contact-button';
import './index.scss';

const COMPATIBLE_TARGET_MARKETS = ['FR', 'GB', 'ES', 'PT', 'CN', 'JP', 'IN'];

const ContactBlock = () => {
  const productTargetMarketId = useSelector(selectProductKeyTargetMarketId);
  const organizationsWorksOnTargetMakets = toJsIfImmutable(
    useSelector(selectWorksOnTargetMarkets),
  );
  const countryCode = find(organizationsWorksOnTargetMakets, [
    'id',
    productTargetMarketId,
  ])?.country_code;

  if (COMPATIBLE_TARGET_MARKETS.includes(countryCode)) {
    return (
      <div className="contact-block">
        <div>
          <p className="contact-block-p1">
            {i18n.t('frontproductstream.media_errors.contact_block.title', {
              defaultValue: "Don't have any pictures? We've got your back.",
            })}
          </p>
          <p className="contact-block-p2">
            {i18n.t('frontproductstream.media_errors.contact_block.text', {
              defaultValue:
                'Connect with our partners - Meero, Klee - to take professional pictures',
            })}
          </p>
        </div>
        <ContactButton />
      </div>
    );
  }
  return null;
};

export default ContactBlock;

import classNames from 'classnames';
import { bool, func, object, shape, string } from 'prop-types';
import { PureComponent } from 'react';
import { list } from 'react-immutable-proptypes';
import { connect } from 'react-redux';

import { Button } from '@alkem/react-ui-button';
import { Textarea } from '@alkem/react-ui-inputs';

import SourcingLabelAutocomplete from 'components/ui/autocomplete/productsegment/sourcinglabel';
import Email from 'components/ui/input/email';
import i18n from 'utils/i18n';
import { separateActions } from 'utils/redux';

import {
  addEmail,
  init,
  removeEmail,
  updateAllProducts,
  updateMessage,
  updateSourcingLabel,
} from '../../../actions';
import {
  selectAllProducts,
  selectEmails,
  selectMessage,
  selectSourcingLabel,
} from '../../../selectors';
import { canShareSourcingLabel } from '../../../utils';

import './index.scss';
import Preview from './preview';

const mapStateToProps = (state) => ({
  emails: selectEmails(state),
  message: selectMessage(state),
  allProducts: selectAllProducts(state),
  sourcingLabel: selectSourcingLabel(state),
  sharingAvailable: canShareSourcingLabel(state),
});

const mapDispatchToProps = {
  init,
  updateMessage,
  updateAllProducts,
  updateSourcingLabel,
  addEmail,
  removeEmail,
};

export class PromoteProductsModalBody extends PureComponent {
  static propTypes = {
    message: string,
    emails: list.isRequired,
    sourcingLabel: object,
    allProducts: bool,
    sharingAvailable: bool,
    actions: shape({
      init: func.isRequired,
      updateAllProducts: func.isRequired,
      updateSourcingLabel: func.isRequired,
      updateMessage: func.isRequired,
      addEmail: func.isRequired,
      removeEmail: func.isRequired,
    }).isRequired,
  };

  componentDidMount() {
    const { actions } = this.props;
    actions.init();
  }

  updateMessage = (e) => {
    this.props.actions.updateMessage(e.target.value);
  };

  setProductScopeToAll = () => {
    const { actions } = this.props;
    actions.updateAllProducts(true);
  };

  setProductScopeToSelection = () => {
    const { actions } = this.props;
    actions.updateAllProducts(false);
  };

  renderEmailInput() {
    const { actions, emails } = this.props;
    return (
      <div className="PromoteModalBody__row">
        <div className="PromoteModalBody__label">
          {i18n.t(
            'frontproductstream.promote_products_modal.recipients_emails.text',
            {
              defaultValue: 'Recipient(s) email(s)',
            },
          )}
        </div>
        <div className="PromoteModalBody__field">
          <Email
            onAdd={actions.addEmail}
            onDelete={actions.removeEmail}
            placeholder={i18n.t('Emails')}
            emails={emails.toJS()}
            id="toEmail"
            labelWidth={0}
          />
        </div>
      </div>
    );
  }

  renderMessageInput() {
    const { message } = this.props;
    return (
      <div className="PromoteModalBody__row">
        <div className="PromoteModalBody__label">{i18n.t('Message')}</div>
        <div className="PromoteModalBody__field">
          <Textarea
            id="request-modal-message"
            placeholder={i18n.t(
              'frontproductstream.promote_products_modal.add_message.placeholder',
              {
                defaultValue: 'Add a message (optional)',
              },
            )}
            rows={6}
            value={message}
            onChange={this.updateMessage}
          />
        </div>
      </div>
    );
  }

  renderProductScopeSelection() {
    const { allProducts } = this.props;
    return (
      <div className="PromoteModalBody__row">
        <div className="PromoteModalBody__label">
          {i18n.t('frontproductstream.promote_products_modal.to_promote.text', {
            defaultValue: 'Products to promote',
          })}
        </div>
        <div className="PromoteModalBody__field PromoteModalBody__fieldSelection btn-group">
          <Button
            secondary
            onClick={this.setProductScopeToAll}
            content={i18n.t(
              'frontproductstream.promote_products_modal.all_products.text',
              {
                defaultValue: 'All my products',
              },
            )}
            className={classNames({
              selected: allProducts,
            })}
          />
          <Button
            secondary
            onClick={this.setProductScopeToSelection}
            content={i18n.t(
              'frontproductstream.promote_products_modal.selection_of_products.text',
              {
                defaultValue: 'A selection of products',
              },
            )}
            className={classNames({
              selected: !allProducts,
            })}
          />
        </div>
      </div>
    );
  }

  renderSourcingLabelsAutocomplete() {
    const { actions, sourcingLabel, allProducts } = this.props;
    return (
      <div className="PromoteModalBody__row">
        <div className="PromoteModalBody__label">
          {i18n.t(
            'frontproductstream.promote_products_modal.choose_products.text',
            {
              defaultValue: 'Choose a product selection',
            },
          )}
        </div>
        <div className="PromoteModalBody__field">
          <SourcingLabelAutocomplete
            id="sourcingLabelAutocomplete"
            value={sourcingLabel ? [sourcingLabel] : []}
            onSelect={actions.updateSourcingLabel}
            disabled={allProducts}
            searchOnClick
            filters={{
              withSynchronized: false,
            }}
          />
        </div>
      </div>
    );
  }

  render() {
    const { sharingAvailable } = this.props;

    return (
      <div className="PromoteModalBody">
        <div className="PromoteModalBody__form">
          <div className="PromoteModalBody__text">
            {i18n.t(
              'frontproductstream.promote_products_modal.enter_emails_to_invite.text',
              {
                defaultValue:
                  'Enter one or several emails to invite retailers to discover your products',
              },
            )}
          </div>
          {this.renderEmailInput()}
          {sharingAvailable && (
            <>
              {this.renderProductScopeSelection()}
              {this.renderSourcingLabelsAutocomplete()}
            </>
          )}
          {this.renderMessageInput()}
        </div>
        {sharingAvailable && (
          <div className="PromoteModalBody__preview">
            <div className="PromoteModalBody__previewTitle">
              {i18n.t(
                'frontproductstream.promote_products_modal.generic_example.text',
                {
                  defaultValue:
                    'Generic example of the e-mail that will be sent',
                },
              )}
            </div>
            <Preview />
          </div>
        )}
      </div>
    );
  }
}

export default connect(
  mapStateToProps,
  mapDispatchToProps,
  separateActions,
)(PromoteProductsModalBody);

import { Map } from 'immutable';

import { logError } from 'utils/logging';

import { UserImmutable } from '../../types';

export * from './constants';

/**
 * Implement fallback. Works only for binary feature. Hence used only in hasFeature
 * Returns true for feature-xyz and false otherwise
 */
export const getFallBackBinaryValue = function (featureKey) {
  if (!featureKey) {
    logError('Feature requested is not defined');
    return false;
  }
  return featureKey.startsWith('feature-');
};

export const hasFeature = function (user?: UserImmutable, ...featureKeys) {
  if (!user) {
    logError('Feature requested for undefined user');
    return false;
  }

  const features = Map(user.get('features'));

  return featureKeys.every((featureKey) =>
    features.has(featureKey)
      ? Boolean(features.get(featureKey))
      : getFallBackBinaryValue(featureKey),
  );
};

/**
 * For features returning string values
 */
export const getFeatureValue = function (user, featureKey) {
  if (!user) {
    return undefined;
  }

  const features = user.get('features');
  if (!features) {
    return undefined;
  }

  return features.get(featureKey);
};

/**
 * Returns true if feature (string value) equals featureValue
 */
export const hasFeatureValue = function (user, featureKey, featureValue) {
  const value = getFeatureValue(user, featureKey);
  if (value) {
    return value.toLowerCase() === featureValue.toLowerCase();
  }
  return false;
};

//
// Dude, where are all the constants???
//
// -> ./constants.js

export const getAllFeatures = (user): string[] => {
  return Object.entries(user.get('features').toJS()).reduce(
    (acc: string[], [key, value]: any) => {
      if (value) {
        return acc.concat([key]);
      }
      return acc;
    },
    [],
  );
};

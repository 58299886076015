import PropTypes from 'prop-types';
import { PureComponent } from 'react';
import ImmutablePropTypes from 'react-immutable-proptypes';

import Dropdown from 'components/ui/dropdown';
import i18n from 'utils/i18n';

import SourcingLabelAction from './actions/sourcinglabel';
import './index.scss';

class SourcingBulkAction extends PureComponent {
  static propTypes = {
    user: PropTypes.object.isRequired,
    productMap: ImmutablePropTypes.map.isRequired,
    selectedMap: ImmutablePropTypes.map.isRequired,
  };

  allOptions = [
    {
      name: 'action-SourcingLabel',
      className: 'BulkAction_SourcingLabel',
      node: SourcingLabelAction,
    },
  ];

  selectKey = (opt) => opt.key;

  formatOptions() {
    const { productMap, selectedMap, user } = this.props;
    if (selectedMap.isEmpty()) {
      return [
        {
          key: 'osef',
          label: (
            <span className="NoResult">
              {i18n.t('You must select at least 1 product')}
            </span>
          ),
        },
      ];
    }
    const options = this.allOptions.reduce((acc, opt) => {
      // ensure flag is on for user if needed
      if (!opt.featureFlag) {
        if (
          opt.node.shouldBeDisplayed({
            productMap,
            selectedMap,
            user,
          })
        ) {
          acc.push({
            key: opt.name,
            label: (
              <opt.node
                selectedMap={selectedMap}
                productMap={productMap}
                user={user}
              />
            ),
            classNames: { [opt.className]: true },
          });
        }
      }
      return acc;
    }, []);
    if (options.length === 0) {
      return [
        {
          key: 'osef',
          label: (
            <span className="NoResult">{i18n.t('No action available')}</span>
          ),
        },
      ];
    }
    return options;
  }

  render() {
    return (
      <div className="SourcingBulkActionSelector">
        <Dropdown
          label={i18n.t('Options')}
          options={this.formatOptions()}
          selectKey={this.selectKey}
          closeDropdownOnClickElement
          rightDropdown
          withoutWrappers
        />
      </div>
    );
  }
}

export default SourcingBulkAction;

import { is } from 'immutable';
import PropTypes from 'prop-types';
import { PureComponent } from 'react';
import ImmutablePropTypes from 'react-immutable-proptypes';
import { connect } from 'react-redux';
import { createStructuredSelector } from 'reselect';

import { updateCurrentLanguage } from 'actions/productversion';
import { selectValidationResultsHidden } from 'modules/anchored/selectors';
import { selectHasMultipleLocales } from 'modules/feature-flag/selectors';
import {
  ValidationResults,
  applyRulesForViewAsRecipients,
} from 'modules/validation';
import ValidationStepper from 'modules/validation-stepper';
import { selectValidationData } from 'modules/validation-stepper/selectors';
import { selectSelectedRecipients } from 'modules/view-as/selectors';
import { selectAvailableFields } from 'modules/view-as/selectors/fields';
import {
  selectAvailableLanguages,
  selectCurrentLanguage,
  selectKindId,
  selectProductVersionId,
  selectReadyForValidation,
} from 'reducers/productVersion';
import { get, isImmutable } from 'utils/immutable';

const mapStateToProps = createStructuredSelector({
  productVersionId: selectProductVersionId,
  kindId: selectKindId,
  validationData: selectValidationData,
  validationResultsHidden: selectValidationResultsHidden,
  selectedRecipients: selectSelectedRecipients,
  withMultiLocale: selectHasMultipleLocales,
  availableLanguages: selectAvailableLanguages,
  currentLanguage: selectCurrentLanguage,
  readyForValidation: selectReadyForValidation,
  availableFields: selectAvailableFields,
});

const mapDispatchToProps = {
  updateCurrentLanguage,
  applyRulesForViewAsRecipients,
};

export class ProductFooterValidation extends PureComponent {
  static propTypes = {
    productVersionId: PropTypes.number,
    kindId: PropTypes.number,
    validationData: ImmutablePropTypes.map.isRequired,
    validationResultsHidden: PropTypes.bool,
    selectedRecipients: PropTypes.object,

    withMultiLocale: PropTypes.bool,
    availableLanguages: PropTypes.arrayOf(PropTypes.object),
    currentLanguage: PropTypes.object,
    readyForValidation: PropTypes.bool,
    availableFields: PropTypes.object,

    updateCurrentLanguage: PropTypes.func.isRequired,
    applyRulesForViewAsRecipients: PropTypes.func.isRequired,
  };

  componentDidMount() {
    const { currentLanguage, readyForValidation } = this.props;
    // product data and language are mandatory for validation
    if (currentLanguage && readyForValidation) {
      this.props.applyRulesForViewAsRecipients();
    }
  }

  componentDidUpdate({
    productVersionId,
    kindId,
    currentLanguage,
    selectedRecipients,
    readyForValidation,
  }) {
    if (!this.props.readyForValidation) {
      return;
    }
    if (
      !readyForValidation ||
      this.props.productVersionId !== productVersionId ||
      this.props.kindId !== kindId ||
      get(this.props.currentLanguage, 'code') !==
        get(currentLanguage, 'code') ||
      (isImmutable(this.props.selectedRecipients)
        ? !is(this.props.selectedRecipients, selectedRecipients)
        : this.props.selectedRecipients !== selectedRecipients)
    ) {
      this.props.applyRulesForViewAsRecipients();
    }
  }

  render() {
    const {
      availableLanguages,
      currentLanguage,
      selectedRecipients,
      validationData,
      validationResultsHidden,
      withMultiLocale,
      availableFields,
    } = this.props;

    if (validationResultsHidden || !validationData.size) {
      return <span />; // For right alignment of save btn reason
    }

    return (
      <div className="FooterActions__validation">
        <ValidationResults
          results={validationData}
          availableFields={availableFields}
          selectedRecipients={selectedRecipients}
          byLanguage={withMultiLocale}
          availableLanguages={availableLanguages}
          currentLanguage={currentLanguage}
          onLanguageSelection={this.props.updateCurrentLanguage}
        />
        <ValidationStepper />
      </div>
    );
  }
}

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(ProductFooterValidation);

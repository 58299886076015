export const login = '/login';
export const loginSSO = `${login}/:organizationIdentifier`;
export const logout = '/logout';
export const signup = '/signup';
export const signupComplete = '/signup/complete';
export const forgotPassword = '/forgot-password';
export const createPassword = '/create-password';
export const validateAccount = '/validate-account';
export const logAs = '/logas';

export const landing = '/';
export const redirect = '/redirect';
export const r = '/r';
export const privateRedirect = '/private-redirect';

export const catalog = '/catalog';
export const catalogAssignation = `${catalog}/assignation`;
export const catalogAll = `${catalog}/all`;
export const catalogArchived = `${catalog}/archived`;
export const catalogToReview = `${catalog}/review`;
export const informationRequestList = `${catalog}/requests`;

export const productNetwork = '/productnetwork';

export const rfp = '/rfp';
export const rfpBU = `${rfp}/bu`;
export const rfpBUCreate = `${rfp}/bu/create`;
export const rfpEventCreate = `${rfp}/event/create`;
export const rfpBUUpdate = `${rfp}/bus/:buId`;
export const rfpAnswers = `${rfp}/answers`;
export const rfpEvent = `${rfp}/:eventId`;
export const rfpEventBUDetails = `${rfp}/:eventId/bu/:buId`;

export const reporting = '/reporting';
export const reportingOverview = '/reporting/overview';
export const companyProfile = '/company/:companyUuid';
export const companyProfileEdit = '/company/edit';

export const appStore = '/app-store';
export const nielsen = '/nielsen';
export const chat = '/organizations/collaboration';
export const notifications = '/notifications';
export const productCreate = '/maker/products/create';
export const unsubscribe = '/unsubscribe';
export const assignation = '/assignation';

export const productBrandEdit = '/maker/brand/:brandId';
export const organizationAdmin = '/organization/administration';
export const userList = '/organization/users';
export const userProfile = '/organization/users/:userId';
export const userOwnProfile = '/user/profile';
export const userSupport = '/user/support';

export const organization = '/organization';
export const organizationScopes = '/organization/scopes';
export const organizationRoles = '/organization/roles';
export const organizationGroups = '/organization/groups';

export const productPageLegacy = '/product/:reference';
export const productDashboardLegacy = '/product/:reference/dashboard';
export const productHistoryLegacy = '/product/:reference/history';
export const productChatLegacy = '/product/:reference/chat';

export const productPageWithUuid = '/products/uuid/:uuid';
export const productPage = '/products/:productKeyId';
export const productDashboard = '/products/:productKeyId/dashboard';
export const productHistory = '/products/:productKeyId/history';
export const productReview = '/products/:productKeyId/review';
export const productStatusHistory = '/products/:productKeyId/statushistory';
export const productChat = '/products/:productKeyId/chat';
export const productInsights = '/products/:productKeyId/insights';
export const productAdmin = '/products/:productKeyId/admin';

export const SharingUnitTariffsList = '/listings';
export const SharingUnitTariffsDetails = '/listings/:id';
export const sharingUnitTemplatesList = '/templates';
export const SharingUnitTemplatesDetails = '/templates/:id';

export const suppliersList = '/suppliers';

export const imports = '/imports';

export const importsGeneric = '/imports/generic';
export const importsGenericSuppliersReport = '/imports/generic/suppliers';
export const importsGenericInformationRequestReport =
  '/imports/generic/requests';

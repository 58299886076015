import { find, isArray, isNumber } from 'lodash';
import moment, { Moment } from 'moment';
import {
  ComponentProps,
  ForwardedRef,
  forwardRef,
  useCallback,
  useEffect,
  useImperativeHandle,
  useMemo,
  useState,
} from 'react';

import { Button } from '@alkem/react-ui-button';
import { Spinner } from '@alkem/react-ui-spinner';

import InputDate from 'components/ui/input/date';
import { scheduledApplicationDateFilter } from 'core/modules/list/constants';
import { usePrevious } from 'hooks/usePrevious';
import { FilterAggregation, FilterChangeEvent } from 'types';
import i18n from 'utils/i18n';
import { get, size, toJsIfImmutable } from 'utils/immutable';

import ListCollapsibleAdvancedFilter from '../advanced';
import Filter from '../advanced/filter';
import ListCollapsibleFilter from '../collapsible';
import TabbedFilters from '../tabbed';

import './style.scss';

type Range = { gte?: string; lte?: string };
interface ScheduledApplicationDateProps {
  specificFilterQueryValues?: string[] | Range | Range[];
  // We want to fire some events only after referentials are fetch
  isFetchingReferentials?: boolean;
}

export const ScheduledApplicationDate = forwardRef(
  function ScheduledApplicationDate(props, ref) {
    const {
      onCollapse,
      filterKey,
      aggregations,
      onChange,
      specificFilterQueryValues,
      isFetchingReferentials,
    } = props;
    const onCollapseWithFilterKey = useCallback(
      (collapsed) => {
        onCollapse(filterKey, collapsed);
      },
      [onCollapse, filterKey],
    );
    const filters = useMemo(
      () =>
        Object.values<FilterAggregation>(toJsIfImmutable(aggregations || {})),
      [aggregations],
    );
    const [fromDate, setFromDate] = useState<Moment | null>(null);
    const [toDate, setToDate] = useState<Moment | null>(null);

    useImperativeHandle(ref, () => ({
      clearFromDate: () => {
        setFromDate(null);
      },
      clearToDate: () => {
        setToDate(null);
      },
      clearAll: () => {
        setFromDate(null);
        setToDate(null);
      },
    }));

    const selectors: ComponentProps<typeof Filter>['selectors'] = useMemo(
      () => ({
        ...props.selectors,
        selectId: (filter) => parseInt(get(filter, 'id'), 10),
        selectLabel: (filter) => moment(get(filter, 'name')).format('L'),
      }),
      [props.selectors],
    );

    // This function allows us to get different action to fire depending on the
    // value of the filter. Since user can select a timestamp from `All` tab
    // or a start/end date fron `Interval` tab, we need to format
    // the query and label differently for each case
    const getActionByValue = useCallback(
      (
        value: string | number | { gte?: string; lte?: string },
      ): FilterChangeEvent => {
        if (typeof value === 'object') {
          if (value?.gte) {
            return {
              key: filterKey,
              value: {
                gte: value.gte,
              },
              label: scheduledApplicationDateFilter.formatLabel?.(value),
              add: true,
              type: 'range',
              useAndOperator: true,
            };
          }

          if (value?.lte) {
            return {
              key: filterKey,
              value: {
                lte: value.lte,
              },
              label: scheduledApplicationDateFilter.formatLabel?.(value),
              add: true,
              type: 'range',
              useAndOperator: true,
            };
          }
        }

        return {
          key: filterKey,
          value,
          add: !!value,
          label: scheduledApplicationDateFilter.formatLabel?.(value),
        };
      },
      [filterKey],
    );

    const resetSelectedFilters = useCallback(() => {
      onChange({
        key: filterKey,
        add: false,
        value: null,
        singleValue: true,
      });
    }, [onChange, filterKey]);

    const handleFilterChange: ComponentProps<typeof Filter>['onChange'] =
      useCallback(
        (event) => {
          // clean any filters set from 'Interval' tab
          if (fromDate || toDate) {
            resetSelectedFilters();
            setFromDate(null);
            setToDate(null);
          }
          onChange(event);
        },
        [fromDate, toDate, onChange, resetSelectedFilters],
      );

    const handleFromDateUpdate = useCallback(
      (date) => {
        setFromDate(date);
        // clear existing from selected date in 'All' tab
        resetSelectedFilters();
        const actions: FilterChangeEvent[] = [];
        if (date) {
          actions.push(
            getActionByValue({
              gte: date.format('YYYY-MM-DD'),
            }),
          );
        }
        if (toDate) {
          actions.push(
            getActionByValue({
              lte: toDate?.format('YYYY-MM-DD'),
            }),
          );
        }
        onChange(actions);
      },
      [resetSelectedFilters, getActionByValue, toDate, onChange],
    );

    const handleToDateUpdate = useCallback(
      (date) => {
        setToDate(date);
        const actions: FilterChangeEvent[] = [];
        // clear existing from selected date in 'All' tab
        resetSelectedFilters();
        if (date) {
          actions.push(
            getActionByValue({
              lte: date.format('YYYY-MM-DD'),
            }),
          );
        }
        if (fromDate) {
          actions.push(
            getActionByValue({
              gte: fromDate?.format('YYYY-MM-DD'),
            }),
          );
        }
        onChange(actions);
      },
      [setToDate, resetSelectedFilters, getActionByValue, fromDate, onChange],
    );

    const gte = useMemo(() => {
      if (!isArray(specificFilterQueryValues)) {
        if (specificFilterQueryValues?.gte) {
          return specificFilterQueryValues.gte;
        }
      } else {
        const filterWithGte = find(
          specificFilterQueryValues,
          (filter) => !!get(filter, 'gte'),
        );
        if (filterWithGte) {
          return get(filterWithGte, 'gte');
        }
      }
    }, [specificFilterQueryValues]);

    const lte = useMemo(() => {
      if (!isArray(specificFilterQueryValues)) {
        if (specificFilterQueryValues?.lte) {
          return specificFilterQueryValues.lte;
        }
      } else {
        const filterWithLte = find(
          specificFilterQueryValues,
          (filter) => !!get(filter, 'lte'),
        );
        if (filterWithLte) {
          return get(filterWithLte, 'lte');
        }
      }
    }, [specificFilterQueryValues]);

    useEffect(() => {
      if (!fromDate && gte) {
        setFromDate(moment(gte));
      }

      if (!toDate && lte) {
        setToDate(moment(lte));
      }
    }, [fromDate, toDate, gte, lte]);

    // When the user removes the selected filters, we want
    // to clear values inside the date pickers
    const previousFromDate = usePrevious(gte);
    const previousToDate = usePrevious(lte);
    useEffect(() => {
      if (previousFromDate && !gte) {
        setFromDate(null);
      }
      if (previousToDate && !lte) {
        setToDate(null);
      }
    }, [gte, lte, previousFromDate, previousToDate, setFromDate]);

    // We need to wait for referentials to be loaded before firing these events
    // because theses values are reset when receiving referentials
    useEffect(() => {
      if (!isFetchingReferentials) {
        if (isArray(specificFilterQueryValues)) {
          const selectedFiltersFromQuery: FilterChangeEvent[] =
            specificFilterQueryValues.map((query) => {
              return getActionByValue(query);
            });
          onChange(selectedFiltersFromQuery, true);
        } else {
          if (
            specificFilterQueryValues?.gte ||
            specificFilterQueryValues?.lte ||
            isNumber(specificFilterQueryValues)
          ) {
            onChange(getActionByValue(specificFilterQueryValues), true);
          }
        }
      }
    }, [
      filterKey,
      specificFilterQueryValues,
      onChange,
      getActionByValue,
      isFetchingReferentials,
    ]);

    return (
      <ListCollapsibleFilter
        id={props.id}
        label={props.filterLabel}
        collapsed={props.collapsed}
        onCollapse={onCollapseWithFilterKey}
      >
        <TabbedFilters
          tabTitles={[
            i18n.t(
              'frontproductstream.core.list_filter_scheduled_application_date.all_tab',
              { defaultValue: 'All' },
            ),
            i18n.t(
              'frontproductstream.core.list_filter_scheduled_application_date.interval_tab',
              { defaultValue: 'Interval' },
            ),
          ]}
        >
          <div>
            {props.isLoading ? (
              <div className="loader">
                <Spinner small />
                {i18n.t(
                  'frontproductstream.core.list_filter_scheduled_application_date.loading',
                  { defaultValue: 'Loading...' },
                )}
              </div>
            ) : (
              <>
                {size(props.selectedFilterMap) > 0 && !toDate && !fromDate && (
                  <Button
                    className="ScheduledApplicationFilter_DeselectAll"
                    onClick={resetSelectedFilters}
                    link
                  >
                    <i className={`mdi mdi-close`} />
                    {i18n.t(
                      'frontproductstream.core.list_filter_scheduled_application_date.deselect_all_button',
                      { defaultValue: 'Deselect all' },
                    )}
                  </Button>
                )}
                <Filter
                  filterKey={props.filterKey}
                  filterLabel={props.filterLabel}
                  selectedFilterMap={props.selectedFilterMap}
                  aggregations={props.aggregations}
                  searchQuery={props.searchQuery}
                  searchPlaceholder={props.searchPlaceholder}
                  page={props.page}
                  itemsPerPage={props.itemsPerPage}
                  renderItem={props.renderItem}
                  selectors={selectors}
                  onFilter={props.onFilter}
                  onChange={handleFilterChange}
                  onChangePage={props.onChangePage}
                  withPagination={props.withPagination}
                  withTree={props.withTree}
                  onSort={props.onSort}
                  withSearchInput={false}
                  filters={filters}
                  hasDocCount={false}
                />
              </>
            )}
          </div>
          <div>
            <div className="ScheduledApplicationFilter_DatePicker">
              <InputDate
                placeholder={i18n.t(
                  'frontproductstream.core.list_filter_scheduled_application_date.start_date_placeholder',
                  { defaultValue: 'Start date' },
                )}
                id="scheduled-application-filter-start-date"
                value={fromDate}
                onSelect={handleFromDateUpdate}
              />
            </div>
            <div className="ScheduledApplicationFilter_DatePicker">
              <InputDate
                placeholder={i18n.t(
                  'frontproductstream.core.list_filter_scheduled_application_date.end_date_placeholder',
                  { defaultValue: 'End date' },
                )}
                id="scheduled-application-filter-start-date"
                value={toDate}
                onSelect={handleToDateUpdate}
              />
            </div>
          </div>
        </TabbedFilters>
      </ListCollapsibleFilter>
    );
  },
) as (
  props: Omit<ComponentProps<typeof ListCollapsibleAdvancedFilter>, 'filters'> &
    ScheduledApplicationDateProps & {
      ref?: ForwardedRef<{
        clearFromDate(): void;
        clearToDate(): void;
        clearAll(): void;
      }>;
    },
) => ReturnType<any>;

import { Component } from 'react';
import { connect } from 'react-redux';
import { createStructuredSelector } from 'reselect';

import { Button, SplitButton } from '@alkem/react-ui-button';

import { TypePackagingLabels } from 'constants/typePackaging';
import { FEATURE_HETEROGENEOUS_LOGISTICAL_UNIT } from 'modules/feature-flag/constants';
import { selectHasFeature } from 'modules/feature-flag/selectors';
import {
  selectHasLogisticalHierarchyPrivateFieldsEdited,
  selectHasLogisticalUnitEdited,
} from 'modules/logistical-hierarchies/selectors';
import {
  selectIsHeterogeneousUnitSelected,
  selectIsSavingInProgress,
} from 'modules/product-page/selectors';
import { selectHasSpecificFieldEdited } from 'modules/recipient-specific-block/selectors';
import { selectHasEditedSharingUnit } from 'modules/sharing-units/selectors';
import {
  selectCanShareProduct,
  selectCanUpdateProduct,
  selectCanUpdateSharingUnit,
  selectIsDirty,
  selectIsHeterogeneousLogisticalUnit,
  selectIsLogisticalUnit,
  selectIsPublished,
  selectProductVersionIsMadeOf,
  selectTypePackagingId,
} from 'reducers/productVersion';
import i18n from 'utils/i18n';

import * as actions from '../../../actions';
import LogisticalHierarchiesModal from '../../../modules/logistical-hierarchies-modal';
import UnsyncableRecipientsModal from '../../../modules/unsyncable-recipients-modal';
import PublishedBadge from '../../basic-information/published-badge';

import './product-footer-actions.scss';
import ProductFooterValidation from './validation';

const mapStateToProps = createStructuredSelector({
  canShareProduct: selectCanShareProduct,
  canUpdateProduct: selectCanUpdateProduct,
  canUpdateSharingUnit: selectCanUpdateSharingUnit,
  hasLogisticalUnitEdited: selectHasLogisticalUnitEdited,
  hasLogisticalHierarchiesPrivateFieldEdit:
    selectHasLogisticalHierarchyPrivateFieldsEdited,
  hasProductVersionEdited: selectIsDirty,
  savingInProgress: selectIsSavingInProgress,
  hasSharingUnitEdited: selectHasEditedSharingUnit,
  isPublished: selectIsPublished,
  hasSpecificFieldEdited: selectHasSpecificFieldEdited,
  isLogisticalUnit: selectIsLogisticalUnit,
  isHeterogeneousLogisticalUnit: selectIsHeterogeneousLogisticalUnit,
  packagingTypeId: selectTypePackagingId,
  isHeterogeneousUnitSelected: (state) =>
    selectIsHeterogeneousUnitSelected(state),
  childProductsCount: (state) =>
    selectProductVersionIsMadeOf(state)?.length ?? 0,
});

const mapDispatchToProps = {
  saveProduct: actions.saveProduct,
  publish: actions.publish,
};

type Props = {
  canUpdateProduct?: boolean;
  canUpdateSharingUnit?: boolean;
  canShareProduct?: boolean;
  hasLogisticalUnitEdited: boolean;
  hasProductVersionEdited?: boolean;
  savingInProgress: boolean;
  hasSharingUnitEdited: boolean;
  isPublished: boolean;
  hasSpecificFieldEdited: boolean;
  isLogisticalUnit: boolean;
  isHeterogeneousLogisticalUnit: boolean;
  hasLogisticalHierarchiesPrivateFieldEdit?: boolean;
  saveProduct(...args: unknown[]): unknown;
  publish(...args: unknown[]): unknown;
  isHeterogeneousUnitSelected: boolean;
  packagingTypeId?: number;
  childProductsCount: number;
};

export class ProductFooterActions extends Component<Props> {
  saveAndPublish: () => Promise<void>;
  saveOnly: () => Promise<void>;

  constructor(props) {
    super(props);
    this.saveAndPublish = this.save.bind(this, true);
    this.saveOnly = this.save.bind(this, false);
  }

  publish = async () => {
    this.props.publish();
  };

  canSaveProduct() {
    const { canUpdateProduct, canUpdateSharingUnit } = this.props;
    return canUpdateProduct || canUpdateSharingUnit;
  }

  requiresSaving() {
    const {
      hasProductVersionEdited,
      hasSharingUnitEdited,
      hasLogisticalUnitEdited,
      hasSpecificFieldEdited,
      hasLogisticalHierarchiesPrivateFieldEdit,
    } = this.props;
    return (
      hasProductVersionEdited ||
      hasSharingUnitEdited ||
      hasLogisticalUnitEdited ||
      hasSpecificFieldEdited ||
      hasLogisticalHierarchiesPrivateFieldEdit
    );
  }

  async save(publish) {
    this.props.saveProduct(publish, null);
  }

  isHeterogeneousLogisticalUnitWithFlag() {
    const { isHeterogeneousLogisticalUnit } = this.props;
    return (
      isHeterogeneousLogisticalUnit &&
      selectHasFeature(FEATURE_HETEROGENEOUS_LOGISTICAL_UNIT)
    );
  }

  renderPublishButtonText() {
    const { isLogisticalUnit } = this.props;
    if (this.isHeterogeneousLogisticalUnitWithFlag() || !isLogisticalUnit) {
      return i18n.t('Publish');
    }
    return null;
  }

  renderSaveOnly() {
    const {
      savingInProgress,
      isHeterogeneousUnitSelected,
      isHeterogeneousLogisticalUnit,
      packagingTypeId,
      isLogisticalUnit,
      childProductsCount,
    } = this.props;
    const disabled =
      savingInProgress ||
      !this.requiresSaving() ||
      (selectHasFeature(FEATURE_HETEROGENEOUS_LOGISTICAL_UNIT) &&
        isHeterogeneousUnitSelected &&
        !isHeterogeneousLogisticalUnit &&
        packagingTypeId === TypePackagingLabels.CASE.id) ||
      (!isHeterogeneousUnitSelected &&
        isLogisticalUnit &&
        childProductsCount > 1);
    const text = savingInProgress ? i18n.t('Saving') : i18n.t('Save');
    return (
      <Button
        id="product-footer-save-button"
        testid="save-only-button"
        className="ProductFooterAction_saveButton"
        primary
        disabled={disabled}
        content={text}
        displaySpinner={savingInProgress}
        onClick={this.saveOnly}
      />
    );
  }

  renderSaveAndShareButton() {
    const {
      canShareProduct,
      savingInProgress,
      isPublished,
      isLogisticalUnit,
      isHeterogeneousLogisticalUnit,
    } = this.props;

    if (!this.canSaveProduct() && !canShareProduct) {
      return null;
    }

    if (!canShareProduct || isPublished) {
      /* Only render a save button, disabled if no change */
      return this.renderSaveOnly();
    }

    /* If save and share available, render Save&Share if change needed
       otherwise just render the Share button */
    if (this.canSaveProduct() && this.requiresSaving()) {
      return isLogisticalUnit && !isHeterogeneousLogisticalUnit ? (
        this.renderSaveOnly()
      ) : (
        <SplitButton
          id="product-footer-save-and-publish-button"
          testid="save-and-publish-button"
          className="ProductFooterAction_saveAndShareButton"
          disabled={savingInProgress}
          dropup
          text={i18n.t('Save and publish')}
          onClick={this.saveAndPublish}
          alternativeOptions={[
            {
              id: 'product-footer-save-only-button',
              text: i18n.t('Save'),
              onClick: this.saveOnly,
            },
          ]}
        />
      );
    }

    const text = this.renderPublishButtonText();
    if (!text) {
      return null;
    }
    return (
      <Button
        id="product-footer-publish-button"
        testid="publish-button"
        className="ProductFooterAction_shareButton"
        primary
        content={text}
        onClick={this.publish}
      />
    );
  }

  render() {
    const { isPublished, isLogisticalUnit, isHeterogeneousUnitSelected } =
      this.props;

    return (
      <div className="FooterActions ProductFooterActions">
        <ProductFooterValidation />
        <div className="ProductFooterAction_rightSection">
          {((this.isHeterogeneousLogisticalUnitWithFlag() &&
            isHeterogeneousUnitSelected) ||
            !isLogisticalUnit) && <PublishedBadge isPublished={isPublished} />}
          {this.renderSaveAndShareButton()}
        </div>
        <UnsyncableRecipientsModal />
        <LogisticalHierarchiesModal />
      </div>
    );
  }
}

const enhance = connect(mapStateToProps, mapDispatchToProps);

export default enhance(ProductFooterActions);

import i18n from 'utils/i18n';

import './header.scss';

const AllowanceHeader = () => (
  <div className="AllowanceHeader">
    <div className="AllowanceHeader__rank">{i18n.t('Rank')}</div>
    <div className="AllowanceHeader__metadata">
      <div className="AllowanceHeader__type">
        {i18n.t('Discounts and charge')}
      </div>
      <div className="AllowanceHeader__unit">{i18n.t('Type')}</div>
      <div className="AllowanceHeader__description">
        {i18n.t('Description')}
      </div>
    </div>
    <div className="AllowanceHeader__value">{i18n.t('Value')}</div>
  </div>
);

export default AllowanceHeader;

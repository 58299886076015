import { registerModuleReducer } from 'utils/modules/reducer';
import { registerSaga } from 'utils/modules/saga';

import { AssignationRefuseModal } from './components';
import { MODULE_NAME } from './constants';
import reducer from './reducer';
import mainSaga from './sagas';

registerSaga(mainSaga);
registerModuleReducer(MODULE_NAME, reducer);

export default AssignationRefuseModal;

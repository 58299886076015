import classnames from 'classnames';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { createStructuredSelector } from 'reselect';

import { Select } from '@alkem/react-ui-select';

import Field from 'components/ui/form/field';
import { selectActiveTextileVariantList } from 'reducers/productVersion';

const mapStateToProps = createStructuredSelector({
  textileVariantList: selectActiveTextileVariantList,
});

export class FormTextileVariantsList extends Field {
  static propTypes = Object.assign({}, Field.propTypes, {
    textileVariantList: PropTypes.array,
  });

  shouldComponentUpdate(nextProps, nextState) {
    return (
      nextProps.textileVariantList !== this.props.textileVariantList ||
      super.shouldComponentUpdate(nextProps, nextState)
    );
  }

  onSelect = ({ id }) => {
    const { value } = this.props;
    this.dispatchChange([...(value || []), { productKeyId: id }]);
  };

  onUnselect = (index) => {
    const { value } = this.props;
    this.dispatchChange(value.filter((e, i) => i !== index));
  };

  render() {
    const { value, field, textileVariantList } = this.props;
    const selectedProductKeyIds = (value || []).map((v) => v.productKeyId);
    if (
      !this.isVisible() ||
      (!selectedProductKeyIds &&
        (!textileVariantList || textileVariantList.length === 0))
    ) {
      return null;
    }
    const renderedLabel = this.renderLabel('col-xs-4');
    const classes = {
      InputField__input: true,
      'col-xs-8': !!renderedLabel,
      'col-xs-12': !renderedLabel,
    };
    const options = textileVariantList.map((v) => ({
      label: v.textileVariant.label,
      id: v.textileVariant.product_key_id,
    }));
    const selectedValues = options.filter((e) =>
      selectedProductKeyIds.includes(e.id),
    );
    return (
      <div
        className={classnames(
          this.getClasses({ FormTextileVariantStringList: true, row: true }),
        )}
      >
        {this.renderLabel('col-xs-4')}
        <div className={classnames(classes)}>
          <Select
            id={this.getId()}
            onValueAdd={this.onSelect}
            onValueDelete={this.onUnselect}
            values={selectedValues}
            placeholder={field.placeholder}
            disabled={this.isReadOnly()}
            options={options.filter(
              (v) => !selectedProductKeyIds.includes(v.id),
            )}
            multiple
          />
          {this.renderPlugins()}
        </div>
      </div>
    );
  }
}

export default connect(mapStateToProps)(FormTextileVariantsList);

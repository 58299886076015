import { useSelector } from 'react-redux';
import { useSearchParams } from 'react-router-dom';

import { selectDefaultTargetMarket } from 'modules/user';
import { parseNumber } from 'utils';

export function useTargetMarket(): number {
  const defaultTargetMarket = useSelector(selectDefaultTargetMarket);
  const [queries] = useSearchParams();
  return parseNumber(
    queries.get('targetMarket') || defaultTargetMarket.get('id'),
  ) as number;
}

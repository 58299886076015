import { Map } from 'immutable';

import * as routes from 'routes';
import { replace } from 'utils/history';

export const NAVIGATION = 'nav';
export const navigate = (query) => () => {
  const path = Map(query).get('path');
  replace(path || routes.landing);
  return Promise.resolve();
};

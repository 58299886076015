import i18n from 'utils/i18n';

export const getBulkTooltipMessage = () =>
  i18n.t('Select only assigned products');

export const getBulkTooltipAtLeastOneProduct = () =>
  i18n.t('Select at least one product');

export const getBulkTooltipOnlyOneProduct = () => i18n.t('Select one product');

export const getBulkTooltipValidate = () =>
  i18n.t('Select only "To be validated" or "Updated" products');

export const getBulkTooltipExportable = () =>
  i18n.t('Select only "Updated" or "Accepted" products');

export const getBulkTooltipImage = () =>
  i18n.t('Select at least one product with an image');

export const getBulkTooltipSameTM = () =>
  i18n.t(
    'Please select products in the same target market to perform this action.',
  );

export const getBulkTooltipSelectOneTM = () =>
  i18n.t('Select one target market');

export const getBulkTooltipAllProductsFiltered = () =>
  i18n.t('Check your filters: you currently filter all the products');

import i18n from 'utils/i18n';

import './header.scss';

function HistoryDiffHeader() {
  return (
    <div className="ProductHistoryDiffCard__header row">
      <div className="col-xs-4">{i18n.t('Field name')}</div>
      <div className="col-xs-4">{i18n.t('Old value')}</div>
      <div className="col-xs-4">{i18n.t('New value')}</div>
    </div>
  );
}

export default HistoryDiffHeader;

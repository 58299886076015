import PropTypes from 'prop-types';

import { SPECIAL_BRANDS } from 'constants/brands';

export default function CatalogBrandColumn({ data }) {
  const SPECIAL_BRAND_NAME_LABEL_MAP = {};
  SPECIAL_BRANDS.forEach((brand) => {
    SPECIAL_BRAND_NAME_LABEL_MAP[brand.name] = brand.label;
  });
  const displayedBrand = Object.keys(SPECIAL_BRAND_NAME_LABEL_MAP).includes(
    data,
  )
    ? SPECIAL_BRAND_NAME_LABEL_MAP[data]
    : data;
  return <div>{displayedBrand}</div>;
}

CatalogBrandColumn.className = 'CatalogBrandColumn';

CatalogBrandColumn.propTypes = {
  data: PropTypes.string,
};

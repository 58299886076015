import classnames from 'classnames';
import { get, isObject, noop, reduce, uniqBy } from 'lodash/fp';
import memoize from 'memoize-one';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { createStructuredSelector } from 'reselect';

import { Text } from '@alkem/react-ui-inputs';
import { Select } from '@alkem/react-ui-select';

import Field from 'components/ui/form/field';
import { selectVariantFields } from 'modules/product-page/modules/textile/selectors';
import { selectTextileVariantList } from 'reducers/productVersion';
import i18n from 'utils/i18n';

const allSelectedOptions = [
  {
    label: (
      <em>
        {i18n.t(
          'frontproductstream.textile_variant_string_list.all_selected.label',
          { defaultValue: 'All values selected' },
        )}
      </em>
    ),
  },
];
const noAvailableOptions = [
  {
    label: (
      <em>
        {i18n.t(
          'frontproductstream.textile_variant_string_list.empty_state.label',
          { defaultValue: 'No values available' },
        )}
      </em>
    ),
  },
];

const mapStateToProps = createStructuredSelector({
  textileVariantList: selectTextileVariantList,
  variantFields: selectVariantFields,
});

export class FormTextileVariantStringList extends Field {
  static propTypes = Object.assign({}, Field.propTypes, {
    textileVariantList: PropTypes.array,
    variantFields: PropTypes.object.isRequired,
  });

  shouldComponentUpdate(nextProps, nextState) {
    return (
      nextProps.textileVariantList !== this.props.textileVariantList ||
      nextProps.variantFields !== this.props.variantFields ||
      super.shouldComponentUpdate(nextProps, nextState)
    );
  }

  componentDidUpdate(prevProps) {
    const { value, field, textileVariantList, variantFields } = this.props;
    if (
      prevProps.value !== value ||
      prevProps.textileVariantList !== textileVariantList ||
      prevProps.variantFields !== variantFields
    ) {
      let newValue = value || [];
      const { allOptions } = this.getAllOptions(
        field.inputKind.mainKey,
        textileVariantList,
        variantFields,
      );
      const allowedValues = allOptions.map(get(['string']));
      // Remove values that are no longer present.
      const cleanedValue = newValue.filter((e) => allowedValues.includes(e));
      if (newValue.length !== cleanedValue.length) {
        newValue = cleanedValue;
      }
      if (newValue !== value) {
        this.dispatchChange(newValue);
      }
    }
  }

  onSelect = ({ string }) => {
    const { value } = this.props;
    if (string && (!value || !value.includes(string))) {
      this.dispatchChange([...(value || []), string]);
    }
  };

  onUnselect = (index) => {
    const { value } = this.props;
    this.dispatchChange(value.filter((e, i) => i !== index));
  };

  getAllOptions = memoize((mainKey, textileVariantList, variantFields) => {
    if (!mainKey) {
      return { allOptions: [], cannotBeFilled: false };
    }
    // Only take into account variant fields here.
    // If the field is set at the model, the list will be empty, hence all variants will be available.
    const variantFieldNames = Object.keys(variantFields);
    const mainKeys = mainKey
      .split(',')
      .filter((k) => variantFieldNames.includes(k.split('.')[0]));
    const options = reduce(
      (acc, key) => [
        ...acc,
        ...(textileVariantList || [])
          .map(get(['textileVariant', 'version', ...key.split('.')]))
          .filter((e) => !!e)
          .map((e) =>
            isObject(e)
              ? { label: e.label, string: e.code }
              : { label: e, string: e },
          ),
      ],
      [],
      mainKeys,
    );
    return {
      allOptions: uniqBy(get(['string']), options),
      cannotBeFilled: mainKeys.length === 0,
    };
  });

  filterValueAndOptions = memoize((value, allOptions) => {
    let values = [];
    if (value && value.length) {
      values = value
        .map((v) => allOptions.find((o) => o.string === v))
        .filter((v) => !!v);
    }
    let filteredOptions = allOptions.filter((o) => !values.includes(o));
    if (filteredOptions.length === 0) {
      filteredOptions =
        allOptions.length > 0 ? allSelectedOptions : noAvailableOptions;
    }
    return { values, filteredOptions };
  });

  render() {
    const { value, field, textileVariantList, variantFields } = this.props;
    if (!this.isVisible()) {
      return null;
    }
    const renderedLabel = this.renderLabel('col-xs-4');
    const classes = {
      InputField__input: true,
      'col-xs-8': !!renderedLabel,
      'col-xs-12': !renderedLabel,
    };
    const { allOptions, cannotBeFilled } = this.getAllOptions(
      field.inputKind.mainKey,
      textileVariantList,
      variantFields,
    );
    if (cannotBeFilled) {
      // Render field as read only as all variants will be available.
      return (
        <div
          className={classnames(
            this.getClasses({ FormTextileVariantStringList: true, row: true }),
          )}
        >
          {this.renderLabel('col-xs-4')}
          <div className={classnames(classes)}>
            <Text
              id={this.getId()}
              value={i18n.t(
                'frontproductstream.textile_variant_string_list.inherited.text',
                { defaultValue: 'Identical for all variants.' },
              )}
              onChange={noop}
              disabled
            />
            {this.renderPlugins()}
          </div>
        </div>
      );
    }

    const { values, filteredOptions } = this.filterValueAndOptions(
      value,
      allOptions,
    );
    return (
      <div
        className={classnames(
          this.getClasses({ FormTextileVariantStringList: true, row: true }),
        )}
      >
        {this.renderLabel('col-xs-4')}
        <div className={classnames(classes)}>
          <Select
            id={this.getId()}
            onValueAdd={this.onSelect}
            onValueDelete={this.onUnselect}
            values={values}
            placeholder={field.placeholder}
            disabled={this.isReadOnly()}
            options={filteredOptions}
            multiple
          />
          {this.renderPlugins()}
        </div>
      </div>
    );
  }
}

export default connect(mapStateToProps)(FormTextileVariantStringList);

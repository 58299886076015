import { flow } from 'lodash/fp';

import { GlobalState } from 'types';

import { USER_AUTHORIZED_REDUCER_KEY } from '../constants';
import type { UserAuthorizedState } from '../types';

export const selectState = (state: GlobalState): UserAuthorizedState =>
  state[USER_AUTHORIZED_REDUCER_KEY];

export const selectIsAuthorized = flow(
  selectState,
  (state) => state?.authorized,
);

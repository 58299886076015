import {
  ADD_OVERRIDE,
  ADD_RECIPIENT,
  ADD_SPECIFIC_ASSETS,
  DELETE_OVERRIDE,
  DELETE_SPECIFIC_ASSETS,
  SPECIFIC_ASSETS_DELETED,
  STATUS_TOGGLED,
  TOGGLE_STATUS,
} from './events';

export const overrideField = (fieldName, recipientId) => ({
  type: ADD_OVERRIDE,
  fieldName,
  recipientId,
});

export const deleteFieldOverride = (fieldName, recipientId) => ({
  type: DELETE_OVERRIDE,
  fieldName,
  recipientId,
});

export const addRecipient = (recipient, templateId) => ({
  type: ADD_RECIPIENT,
  recipient,
  templateId,
});

export const toggleStatus = (recipientId, category, assetId, isActive) => ({
  type: TOGGLE_STATUS,
  payload: { assetId, category, isActive, recipientId },
});

export const activeAssetToggled = (recipientId, category, assetId) => ({
  type: STATUS_TOGGLED,
  payload: { assetId, category, recipientId },
});

export const addSpecificAssets = (recipientId) => ({
  type: ADD_SPECIFIC_ASSETS,
  payload: { recipientId },
});

export const deleteSpecificAssets = (recipientId) => ({
  type: DELETE_SPECIFIC_ASSETS,
  payload: { recipientId },
});

export const specificAssetsDeleted = (recipientId) => ({
  type: SPECIFIC_ASSETS_DELETED,
  payload: { recipientId },
});

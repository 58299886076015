import { takeLatest } from 'redux-saga/effects';

import { FORM_FIELD_UPDATED } from 'constants/events/entity';
import {
  ACCEPT_SUGGESTION,
  DISMISS_NORMALIZED_COMMENT,
  DISMISS_SUGGESTION,
  NORMALIZED_COMMENT_CHANGES_REQUESTED,
} from 'constants/events/suggestions';
import { takeEverySafe, takeLatestSafe, withCatch } from 'utils/saga';

import {
  FETCH_PRODUCT_DATA_BY_KEY_ID,
  FETCH_PRODUCT_DATA_BY_UUID,
  FETCH_REJECTED_FIELDS,
  PUBLISH,
  REQUIRE_MATURITY_RULES,
  REQUIRE_MATURITY_RULES_WITH_DEADLINES,
  SAVE_PRODUCT,
  SAVE_RETAILER_PRODUCT_VERSION,
  SAVE_VISIBILITY,
  SET_EXPORTABLE,
  SET_VIEW_AS,
} from '../constants';
import { NORMALIZED_COMMENTS_REQUEST_CHANGES } from '../modules/normalized-comments/constants';
import {
  createNormalizedComments,
  dismissNormalizedComment,
  requestChanges,
} from '../modules/normalized-comments/sagas';
import {
  acceptSuggestion,
  dismissSuggestion,
} from '../modules/product-suggestions/sagas';

import { handleFormFieldUpdate } from './form';
import {
  requireMaturityRuleSetsWithDeadlinesSaga,
  requireMaturityRulesSaga,
} from './maturity';
import {
  fetchProductDataByKeyIdSaga,
  fetchProductDataByUuidSaga,
  publishSaga,
  saveProductSaga,
  saveRetailerProductVersionSaga,
  setExportableSaga,
} from './product';
import { fetchRejectedFieldsSaga } from './review';
import { setViewAs } from './view-as';
import { saveVisibility } from './visibility';

export default function* productPageMainSaga() {
  // product
  yield takeLatestSafe(
    FETCH_PRODUCT_DATA_BY_KEY_ID,
    fetchProductDataByKeyIdSaga,
  );
  // product
  yield takeLatestSafe(FETCH_PRODUCT_DATA_BY_UUID, fetchProductDataByUuidSaga);
  yield takeEverySafe(SAVE_PRODUCT, saveProductSaga);
  yield takeEverySafe(
    SAVE_RETAILER_PRODUCT_VERSION,
    saveRetailerProductVersionSaga,
  );
  yield takeEverySafe(PUBLISH, publishSaga);
  yield takeEverySafe(SET_EXPORTABLE, setExportableSaga);
  // form
  yield takeEverySafe(FORM_FIELD_UPDATED, handleFormFieldUpdate);

  // comments / suggestions
  yield takeLatestSafe(
    NORMALIZED_COMMENT_CHANGES_REQUESTED,
    createNormalizedComments,
  );
  yield takeLatestSafe(DISMISS_NORMALIZED_COMMENT, dismissNormalizedComment);
  yield takeLatestSafe(NORMALIZED_COMMENTS_REQUEST_CHANGES, requestChanges);
  yield takeEverySafe(DISMISS_SUGGESTION, dismissSuggestion);
  yield takeEverySafe(ACCEPT_SUGGESTION, acceptSuggestion);
  yield takeLatestSafe(SET_VIEW_AS, setViewAs);
  yield takeLatestSafe(FETCH_REJECTED_FIELDS, fetchRejectedFieldsSaga);

  // visibility
  yield takeLatestSafe(SAVE_VISIBILITY, saveVisibility);

  yield takeEverySafe(
    REQUIRE_MATURITY_RULES,
    withCatch(requireMaturityRulesSaga),
  );
  yield takeLatest(
    REQUIRE_MATURITY_RULES_WITH_DEADLINES,
    withCatch(requireMaturityRuleSetsWithDeadlinesSaga),
  );
}

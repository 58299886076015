import moment from 'moment';
import { useController, useWatch } from 'react-hook-form';
import { useSelector } from 'react-redux';

import DateInput from 'components/ui/input/date';
import InputWithLabel from 'components/ui/input/input-with-label';
import { selectHasJsonStoreInputFeature } from 'modules/feature-flag/selectors';
import i18n from 'utils/i18n';

import { ReadOnlyAssetInput } from './types';

export const ValidityStartDate = ({ isReadOnly }: ReadOnlyAssetInput) => {
  const name = 'fileEffectiveStartDateTime';

  const {
    field: { onChange, value },
  } = useController({ name });

  const crc32 = useWatch({ name: 'crc32' });

  const hasJsonStoreInputFeature = useSelector(selectHasJsonStoreInputFeature);

  if (hasJsonStoreInputFeature) return null;

  return (
    <InputWithLabel
      childId={`ProductPictureForm-${crc32}-${name}`}
      label={i18n.t(
        'frontproducstream.asset_list.modal.validity_start_date.label',
        { defaultValue: 'Starting date of validity' },
      )}
    >
      <DateInput
        id={`ProductPictureForm-${crc32}-${name}`}
        value={value ? moment(value) : null}
        onSelect={onChange}
        readOnly={isReadOnly}
      />
    </InputWithLabel>
  );
};

import { isRetailer, isSuperAdmin } from 'core/api/user';

export const ownersFilter = {
  key: 'owner.id',
  aggregation: {
    value_field: 'owner.name.raw',
  },
  query: 'owner',
  isAvailable: ({ user }) => isRetailer(user) || isSuperAdmin(user),
};

import PropTypes from 'prop-types';
import { PureComponent } from 'react';

import { findFieldByModel } from 'utils/displayGroup';

import './product-error.scss';

export default class ProductError extends PureComponent {
  static propTypes = {
    displayGroups: PropTypes.array,
    field: PropTypes.string,
    error: PropTypes.string.isRequired,
  };

  getFieldLabel(field) {
    // We need to find the field among the display group to fetch it's translated label.
    const fieldObject = findFieldByModel(this.props.displayGroups, field);
    return fieldObject ? fieldObject.label : field;
  }

  render() {
    const { field, error } = this.props;
    if (!field) {
      return (
        <div className="ProductError">
          <span className="ProductError__error">{error}</span>
        </div>
      );
    }
    return (
      <div className="ProductError">
        <span className="ProductError__field">{this.getFieldLabel(field)}</span>
        <span className="ProductError__separator">-</span>
        <span className="ProductError__error">{error}</span>
      </div>
    );
  }
}

import classNames from 'classnames';
import PropTypes from 'prop-types';
import { Component } from 'react';
import ImmutablePropTypes from 'react-immutable-proptypes';

import { ClickOutside } from '@alkem/react-ui-click-outside';

import './dropdown.scss';

class Dropdown extends Component {
  static propTypes = {
    id: PropTypes.string,
    options: PropTypes.oneOfType([
      PropTypes.array,
      PropTypes.func,
      ImmutablePropTypes.list,
    ]).isRequired,
    label: PropTypes.node.isRequired,
    theme: PropTypes.string,
    darker: PropTypes.bool,
    closeDropdownOnClickElement: PropTypes.bool,
    rightDropdown: PropTypes.bool,
    selectKey: PropTypes.func.isRequired,
    selectAttrs: PropTypes.func.isRequired,
    selectLabel: PropTypes.func.isRequired,
    selectOptions: PropTypes.func.isRequired,
    selectOnClick: PropTypes.func.isRequired,
    selectClassNames: PropTypes.func,
    onClickOption: PropTypes.func,
    onClickCallback: PropTypes.func,
    withoutWrappers: PropTypes.bool,
    disabled: PropTypes.bool,
    dataFor: PropTypes.string,
    alwaysRenderDropdown: PropTypes.bool,
    dropdownClassNames: PropTypes.object,
  };

  static defaultProps = {
    theme: 'default',
    darker: false,
    closeDropdownOnClickElement: true,
    rightDropdown: false,
    selectKey: (opt, key) => key,
    selectLabel: (opt) => opt.label,
    selectAttrs: (opt) => opt.attrs,
    selectOptions: (opt) => opt.options,
    selectOnClick: (opt) => opt.onClick,
    selectClassNames: (opt) => opt.classNames,
    withoutWrappers: false,
    alwaysRenderDropdown: true,
  };

  constructor(props) {
    super(props);
    this.toggle = this.toggle.bind(this);
    this.closeDropdown = this.closeDropdown.bind(this);
    this.onClickOption = this.onClickOption.bind(this);
    this.state = {
      open: false,
    };
  }

  onClickElement(callback) {
    return (evt) => {
      callback(evt);
      if (this.props.onClickCallback) {
        this.props.onClickCallback(evt);
      }
    };
  }

  onClickOption(option, event) {
    this.props.onClickOption(option, event);
  }

  onClickUl = () => {
    if (this.props.closeDropdownOnClickElement) {
      this.closeDropdown();
    }
  };

  handleClickOutside = () => {
    this.closeDropdown();
  };

  stopPropagation(event) {
    event.stopPropagation();
  }

  closeDropdown() {
    if (this.state.open) {
      this.setState({ open: false });
    }
  }

  toggle() {
    this.setState((prevState) => ({ open: !prevState.open }));
  }

  renderChildren(children) {
    if (!children || children.length === 0 || children.size === 0) {
      return null;
    }
    return <ul>{this.renderNode(children)}</ul>;
  }

  renderNode(options) {
    const {
      selectKey,
      selectLabel,
      selectAttrs,
      selectOptions,
      selectOnClick,
      onClickOption,
      selectClassNames,
      withoutWrappers,
    } = this.props;
    if (typeof options === 'function') {
      options = options();
    }
    return options.map((option, index) => {
      const onClickOpt = selectOnClick(option);
      const attrs = selectAttrs(option) || {};
      const children = selectOptions(option);
      const label = selectLabel(option);
      const key = selectKey(option, index);
      const className = selectClassNames(option)
        ? classNames(selectClassNames(option))
        : '';

      if (onClickOpt || onClickOption) {
        let onClick;
        if (onClickOption) {
          onClick = this.onClickOption.bind(this, option);
        } else {
          onClick = this.onClickElement(onClickOpt).bind(this);
        }
        return (
          <li key={key} className={className}>
            <button type="button" onClick={onClick} {...attrs}>
              {label}
            </button>
            {this.renderChildren(children)}
          </li>
        );
      }
      return (
        <li key={key} className={className}>
          {withoutWrappers ? label : <span {...attrs}>{label}</span>}
          {this.renderChildren(children)}
        </li>
      );
    });
  }

  render() {
    const componentClasses = {
      Dropdown: true,
      [`Dropdown--${this.props.theme}`]: this.props.theme,
      [this.props.dropdownClassNames?.dropdown]:
        this.props.dropdownClassNames?.dropdown,
    };

    let dropdown = null;
    if (this.props.alwaysRenderDropdown || this.state.open) {
      dropdown = (
        <ul
          className={classNames({
            Dropdown__list: true,
            'Dropdown__list--hidden': !this.state.open,
            rightDropdown: this.props.rightDropdown,
            [this.props.dropdownClassNames?.dropdownList]:
              this.props.dropdownClassNames?.dropdownList,
          })}
          onClick={this.onClickUl}
          role="presentation"
        >
          {this.renderNode(this.props.options)}
        </ul>
      );
    }

    return (
      <ClickOutside
        id={this.props.id}
        className={classNames(componentClasses)}
        onClick={this.stopPropagation}
        onClickOutside={this.handleClickOutside}
      >
        <button
          type="button"
          className={classNames('btn', 'Dropdown__button', {
            'Dropdown__button--darker': this.props.darker,
            [this.props.dropdownClassNames?.dropdownButton]:
              this.props.dropdownClassNames?.dropdownButton,
          })}
          onClick={this.toggle}
          disabled={this.props.disabled}
          data-for={this.props.dataFor}
          data-tip={!!this.props.dataFor && this.props.dataFor.length > 0}
        >
          {this.props.label}
        </button>
        {dropdown}
      </ClickOutside>
    );
  }
}

export default Dropdown;

import { get } from 'lodash/fp';
import { Action } from 'redux';

import { Field } from '@alkem/sdk-dashboard';

import { updateEntity } from 'actions/entity';
import { bulkSeedData } from 'actions/productversion';
import { seedData } from 'components/ui/form/field/utils/seed';
import { ENTITY_TYPE_PRODUCTVERSION } from 'constants/entities';
import { selectHasMultiLevel } from 'modules/feature-flag/selectors';
import { applyRulesForViewAsRecipients } from 'modules/validation';
import {
  selectCurrentLanguage,
  selectProductVersionId,
  selectTextileVariantList,
} from 'reducers/productVersion';
import { createAction } from 'utils/redux';

import {
  ADD_VARIANT_FIELD,
  CREATE,
  CREATE_FAILURE,
  CREATE_SUCCESS,
  REMOVE_VARIANT_FIELD,
  SET_VARIANT_FIELDS,
  Statuses,
  TOGGLE_VARIANT,
} from './constants';

const seedVariantData = (variantFields: Field[]) => (dispatch, getState) => {
  // Seed all the variant specific fields on active variants to be able to validate properly.
  const state = getState();
  const variantList = selectTextileVariantList(state);
  const currentLanguage = selectCurrentLanguage(state);
  const multiLevel = selectHasMultiLevel(state);

  interface Update {
    key: string;
  }
  const updates = [] as Update[];
  variantList.forEach((v, i) => {
    if (get(['status'], v) === Statuses.ACTIVE.id) {
      seedData(
        get(['textileVariant', 'version'], v),
        variantFields,
        currentLanguage,
        [],
        multiLevel,
      ).forEach((u) => {
        updates.push({
          ...u,
          key: `textileVariantList.${i}.textileVariant.version.${u.key}`,
        });
      });
    }
  });
  if (updates.length > 0) {
    dispatch(bulkSeedData(updates));
    dispatch(applyRulesForViewAsRecipients());
  }
};

export const createVariant = createAction(CREATE);

export const createVariantFailure = createAction(CREATE_FAILURE);

export const createVariantSuccess = createAction(CREATE_SUCCESS);

export const toggleVariant = createAction(TOGGLE_VARIANT);

export const addVariant = (variant) =>
  ((dispatch, getState) => {
    const state = getState();
    const productVersionId = selectProductVersionId(state);
    const variantList = selectTextileVariantList(state);
    dispatch(
      updateEntity(
        'textileVariantList',
        [
          ...variantList,
          { textileVariant: variant, status: Statuses.ACTIVE.id },
        ],
        productVersionId,
        ENTITY_TYPE_PRODUCTVERSION,
      ),
    );
    dispatch(toggleVariant(variant.product_key_id));
  }) as unknown as Action;

export const setVariantFields = (variantFields: Field[]) => (dispatch) => {
  dispatch(seedVariantData(variantFields));
  dispatch({
    type: SET_VARIANT_FIELDS,
    variantFields,
  });
};

export const addVariantField = (field: Field) => (dispatch) => {
  dispatch(seedVariantData([field]));
  dispatch({
    type: ADD_VARIANT_FIELD,
    field,
  });
};

export const removeVariantField = (field: Field) => (dispatch) => {
  dispatch({
    type: REMOVE_VARIANT_FIELD,
    field,
  });
};

import { createReducer } from 'redux-create-reducer';

import {
  DEFINE_ANCHOR_NAVIGATION_SECTION,
  RESET_LOADING,
  SCROLLED_TO_ANCHOR_NAVIGATION_SECTION,
  SET_ANCHOR_NAVIGATION_SECTION,
  SHOW_ERROR_PAGE,
  START_LOADING,
  STOP_LOADING,
  ScrollActionEnum,
  UPDATE_SCROLL_POSITION,
} from 'constants/events/navigation';
import { replaceAt } from 'utils';

const initialState = {
  anchors: [],
  count: 0,
  isLoading: false,
  scrollPosition: 0,
  scrollHeight: 0,
  selectedAnchoredSection: undefined,
  hasLoadingFailed: false,
  prev: 0,
  next: 0,
};

export default createReducer(initialState, {
  [DEFINE_ANCHOR_NAVIGATION_SECTION]: (state, action) => {
    const newState = { ...state };
    const { anchors } = state;
    const positionTop = Math.floor(action.positionTop);

    const existingAnchor = anchors.find((a) =>
      action.blockId ? a.id === action.blockId : a.name === action.blockName,
    );

    if (existingAnchor) {
      const updatedAnchor = {
        ...existingAnchor,
        top: positionTop,
      };
      const index = anchors.indexOf(existingAnchor);
      newState.anchors = replaceAt(anchors, index, updatedAnchor);
    } else {
      const newAnchor = {
        id: action.blockId,
        name: action.blockName,
        top: positionTop,
        active: false,
      };
      newState.anchors = [...anchors, newAnchor];
    }

    newState.anchors = newState.anchors
      .filter((a) => a.top !== null) // filtering out thoses invisible
      .sort((a, b) => a.top - b.top);

    return newState;
  },

  [UPDATE_SCROLL_POSITION]: (state, action) => {
    const { scrollTop, scrollHeight } = action.payload;
    const newState = {
      ...state,
      prev: action.payload.prev,
      next: action.payload.next,
      scrollHeight,
      lastScrollAction: ScrollActionEnum.SCROLL_ACTION_SCROLL,
    };
    if (
      state.lastScrollAction === ScrollActionEnum.SCROLL_ACTION_GO_TO_ANCHOR
    ) {
      return newState;
    }

    newState.anchors = state.anchors.map((anchor, i) => {
      const newAnchor = { ...anchor };
      const nextAnchor = state.anchors[i + 1];
      if (scrollTop < anchor.top) {
        newAnchor.active = false;
      } else if (nextAnchor && scrollTop >= nextAnchor.top) {
        newAnchor.active = false;
      } else {
        newAnchor.active = true;
      }

      if (anchor.active !== newAnchor.active) {
        return newAnchor;
      }

      return anchor;
    });

    return newState;
  },

  [SET_ANCHOR_NAVIGATION_SECTION]: (state, action) => {
    const newState = { ...state };
    newState.selectedAnchoredSection = action.selectedAnchoredSection;
    return newState;
  },

  [SCROLLED_TO_ANCHOR_NAVIGATION_SECTION]: (state) => {
    const newState = { ...state };
    if (state.selectedAnchoredSection) {
      newState.anchors = state.anchors.map((anchor) => ({
        ...anchor,
        active:
          state.selectedAnchoredSection &&
          [anchor.id, anchor.name].includes(state.selectedAnchoredSection),
      }));
    }
    newState.lastScrollAction = ScrollActionEnum.SCROLL_ACTION_GO_TO_ANCHOR;
    newState.selectedAnchoredSection = undefined;
    return newState;
  },

  [START_LOADING]: (state) => {
    const newState = { ...state };
    newState.isLoading = true;
    newState.hasLoadingFailed = false;
    newState.count += 1;
    return newState;
  },

  [STOP_LOADING]: (state) => {
    const newState = { ...state };
    newState.count -= 1;
    if (newState.count < 1) {
      newState.isLoading = false;
      newState.count = 0;
    }
    newState.hasLoadingFailed = false;
    return newState;
  },

  [RESET_LOADING]: (state) => {
    const newState = { ...state };
    newState.hasLoadingFailed = false;
    newState.count = 0;
    newState.isLoading = false;
    return newState;
  },

  [SHOW_ERROR_PAGE]: (state) => {
    const newState = { ...state };
    newState.count -= 1;
    if (newState.count < 1) {
      newState.isLoading = false;
      newState.count = 0;
    }
    newState.hasLoadingFailed = true;
    return newState;
  },
});

// Selectors
export const selectHasLoadingFailed = (state) =>
  state.navigation.hasLoadingFailed;

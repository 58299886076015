import classNames from 'classnames';
import { memo, useCallback, useEffect, useRef, useState } from 'react';
import { useSelector } from 'react-redux';
import { Link } from 'react-router-dom';

import { getOrganization } from 'actions/organization';
import HelpCenter from 'components/navbar/help-center';
import NavbarProfile from 'components/navbar/navbarProfile';
import ConnectAs, { hasConnectAs } from 'components/ui/connect-as';
import {
  SOURCE_ALKEMICS,
  SOURCE_PRODUCTDNA,
} from 'constants/organization-source';
import prefs from 'constants/prefs';
import { MAKER, RETAILER, THIRD_PARTY } from 'constants/types';
import { hasOrganizationChatSetting } from 'core/api/organization-settings';
import {
  getOrganizationId,
  getOrganizationSettings,
  getOrganizationSource,
  hasId,
  hasOrganization,
  isRetailer,
  isThirdParty,
} from 'core/api/user';
import { useDispatch } from 'hooks/useDispatch';
import productDnaLogo from 'images/logo-productdna-white.png';
import salsifyLogo from 'images/logo-salsify.png';
import { MultipleOrganizations } from 'modules/auth/login';
import { CollaborationLink } from 'modules/chat';
import { hasFeature } from 'modules/feature-flag';
import {
  FEATURE_APPSTORE,
  FEATURE_NOTIFICATION_CENTER,
  FEATURE_PERMISSIONS_V3_ORGANIZATION,
} from 'modules/feature-flag/constants';
import NotificationUnread from 'modules/notification-unread';
import { hasPermissionModule, hasPermissionV3 } from 'modules/permissions';
import {
  CHAT_PERMISSION,
  ORGANIZATION_PERMISSION,
} from 'modules/permissions/const';
import { buildNavigationPath } from 'modules/redirect';
import { selectUser } from 'modules/user';
import { isProduction } from 'utils';

import { AppStoreLink } from './app-store-link';
import { NavbarTabs } from './components/tabs';
import './navbar.scss';

export const Navbar = memo(() => {
  const dispatch = useDispatch();
  const user = useSelector(selectUser);
  const currentUser = useRef(user);
  const [profileOpen, setProfileOpen] = useState(false);

  useEffect(() => {
    const { current: previousUser } = currentUser;
    if (
      hasId(user) &&
      hasOrganization(user) &&
      (!hasId(previousUser) ||
        getOrganizationId(user) !== getOrganizationId(previousUser))
    ) {
      dispatch(getOrganization(getOrganizationId(user)));
    }
    currentUser.current = user;
  }, [dispatch, user]);

  const getNavbarType = () => {
    if (isRetailer(user)) {
      return RETAILER;
    }
    if (isThirdParty(user)) {
      return THIRD_PARTY;
    }
    return MAKER;
  };

  const profileNavToggle = useCallback(() => {
    setProfileOpen(!profileOpen);
  }, [profileOpen]);

  const Collaboration = () => {
    if (
      isThirdParty(user) ||
      !hasOrganizationChatSetting(getOrganizationSettings(user))
    ) {
      return null;
    }
    if (hasPermissionV3(user, FEATURE_PERMISSIONS_V3_ORGANIZATION)) {
      if (
        !hasPermissionModule(user, ORGANIZATION_PERMISSION, CHAT_PERMISSION)
      ) {
        return null;
      }
    }
    return <CollaborationLink />;
  };

  const Notification = () => {
    if (
      !prefs.navbar.notificationsLink ||
      !hasFeature(user, FEATURE_NOTIFICATION_CENTER)
    ) {
      return null;
    }
    return (
      <li className="Navbar__notification">
        <NotificationUnread />
      </li>
    );
  };

  const source = getOrganizationSource(user);

  return (
    <div
      className={classNames(
        'Navbar navbar navbar-dark bg-inverse navbar-fixed-top',
        { 'Navbar--PPR': !isProduction() },
      )}
      role="navigation"
    >
      <Link
        to={buildNavigationPath()}
        className="Navbar__logoLink navbar-brand"
      >
        <img
          className={classNames({
            Navbar__productDnaLogo: source === SOURCE_PRODUCTDNA,
            Navbar__logo: source === SOURCE_ALKEMICS,
          })}
          src={source === SOURCE_PRODUCTDNA ? productDnaLogo : salsifyLogo}
          alt={source === SOURCE_PRODUCTDNA ? 'ProductDNA' : 'Salsify'}
        />
      </Link>
      <NavbarTabs navBarType={getNavbarType()} user={user} />
      <ul className="Navbar__menuright nav navbar-nav pull-xs-right">
        {hasFeature(user, FEATURE_APPSTORE) && <AppStoreLink />}
        {hasConnectAs(user) && (
          <li className="nav-item">
            <ConnectAs />
          </li>
        )}
        <HelpCenter />
        <Collaboration />
        <Notification />
        <NavbarProfile
          user={user}
          open={profileOpen}
          onClick={profileNavToggle}
          onClickOutside={profileNavToggle}
        />
        {user?.get('organizations') && <MultipleOrganizations user={user} />}
      </ul>
    </div>
  );
});

export default Navbar;

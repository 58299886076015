import { workflowSharingStatusFilter } from 'core/modules/list/constants/filters';

import * as allFilters from './filters';
import { Filter } from './filters/types';

export const FILTERS_PANEL = 'filters-panel';

export const ITEMS_PER_PAGE_OPTIONS = [20, 50, 100];

export const FILTERS_SIMPLE_AGGREGATION_KEYS = [
  workflowSharingStatusFilter.key,
];

export const filters = Object.values(allFilters) as Filter[];
export * from './filters';

import { createSelector } from '@reduxjs/toolkit';

import { MODULE_REDUCER_KEY } from 'constants/reducer';

import { KEY } from './reducers';

export const selectModuleState = (state) => state[MODULE_REDUCER_KEY][KEY];

export const selectIsVisible = createSelector(
  selectModuleState,
  (moduleState) => moduleState.get('visible'),
);

export const selectProductVersions = createSelector(
  selectModuleState,
  (moduleState) => moduleState.get('productVersions'),
);

export const selectInProgress = createSelector(
  selectModuleState,
  (moduleState) => moduleState.get('inProgress'),
);

export const selectCount = createSelector(selectModuleState, (moduleState) =>
  moduleState.get('count'),
);

export const selectStatus = createSelector(selectModuleState, (moduleState) =>
  moduleState.get('status'),
);

export const selectIsDone = createSelector(selectModuleState, (moduleState) =>
  moduleState.get('done'),
);

export const selectVersionsInError = createSelector(
  selectModuleState,
  (moduleState) => moduleState.get('errors'),
);

export const selectVersionsSuccessful = createSelector(
  selectModuleState,
  (moduleState) => moduleState.get('success'),
);

import PropTypes from 'prop-types';
import { PureComponent } from 'react';
import ImmutablePropTypes from 'react-immutable-proptypes';

import Dropdown from 'components/ui/dropdown';
import { hasFeature } from 'modules/feature-flag';
import {
  FEATURE_ALL_USERLABEL_MANAGEMENT,
  FEATURE_DATA_MATURITY_MANAGEMENT,
  FEATURE_MANUFACTURER_BULK_ADD_RECIPIENTS,
  FEATURE_MANUFACTURER_BULK_PUBLISH,
  FEATURE_MULTI_TARGETMARKET_CATALOG,
  FEATURE_PNZ_BULK_VALIDATE,
  FEATURE_THIRD_PARTY_PHYSICAL_CHECK,
  RELEASE_BULK_ACTIVATE_RECIPIENTS,
  RELEASE_LOGISTICAL_HIERARCHIES_MASS_EDIT,
} from 'modules/feature-flag/constants';
import i18n from 'utils/i18n';

import BulkActionAddRecipients from './actions/add-recipients';
import BulkEditAction from './actions/bulk-edit';
import { BulkMessage, bulkMessageDisplayable } from './actions/bulk-message';
import BulkShareLogisticalHierarchy from './actions/bulk-share-logistical-hierarchies';
import BulkValidate from './actions/bulk-validation';
import ContributionAction from './actions/contribution';
import DownloadPackshotsAction from './actions/download-pictures/packshots';
import DownloadPicturesAction from './actions/download-pictures/pictures';
import DuplicateAction from './actions/duplicate';
import BulkActionMaturityRules from './actions/maturity-rulesets';
import AssignToNewMarketAction from './actions/new-market';
import BulkPrepareForChannels from './actions/prepare-for-channels';
import ProductSegmentsAction from './actions/product-segments';
import BulkActionPublish from './actions/publish';
import RemoveFromActiveRangeAction from './actions/remove-from-active-range';
import SetAsExportable from './actions/set-as-exportable';
import SetAsPublicAction from './actions/set-as-public';
import UserLabelAction from './actions/userlabel';
import {
  getBulkTooltipAtLeastOneProduct,
  getBulkTooltipExportable,
  getBulkTooltipImage,
  getBulkTooltipMessage,
  getBulkTooltipOnlyOneProduct,
  getBulkTooltipValidate,
} from './constants';
import disableWrapper from './hocs/disable-wrapper';
import './index.scss';

/**
 * @typedef {import("react").ComponentType<any> & { shouldBeDisplayed?: (...args: any[]) => boolean }} ReactComponent
 */

/**
 * @typedef {object} Option
 * @property {string} name
 * @property {string} className
 * @property {string=} featureFlag
 * @property {ReactComponent} node
 * @property {() => string} tooltip
 * @property {(...args: any[]) => boolean} isEnabled
 * @property {(...args: any[]) => boolean=} isDisplayable
 * @property {boolean=} checkMultiTm
 * @property {boolean=} onAllProductsWithFilters
 * @property {boolean=} isAsync
 */

class CatalogBulkAction extends PureComponent {
  static propTypes = {
    user: PropTypes.object.isRequired,
    productMap: ImmutablePropTypes.map.isRequired,
    selectedMap: ImmutablePropTypes.map.isRequired,
    filtersQueryMap: PropTypes.object,
    nbFilteredProducts: PropTypes.number,
  };

  enabledSelected = ({ selectedMap, onAllProductsWithFilters }) =>
    !selectedMap?.isEmpty() || onAllProductsWithFilters;

  // if you need to release a feature to everyone, you have to put featureFlag to null
  // isEnabled => condition to fulfil in the selection / products showing to show the option as enabled
  // tooltip => message showing in overlay in case of disabled option, to explain why
  // checkMultiTM => set as true if the option is incompatible with multiTm, will do the verification and show appropriate error
  // onAllProductsWithFilters => set as true if the option can be enabled without any product selected and will be apply on all the products in the catalog (if there are filters, only the filtered products)
  /** @type {Option[]} */
  allOptions = [
    {
      name: 'action-addRecipients',
      className: 'BulkAction_AddRecipients',
      featureFlag: FEATURE_MANUFACTURER_BULK_ADD_RECIPIENTS,
      node: disableWrapper(BulkActionAddRecipients),
      tooltip: getBulkTooltipAtLeastOneProduct,
      isEnabled: this.enabledSelected,
      checkMultiTm: true,
      onAllProductsWithFilters: true,
    },
    {
      name: 'action-downloadPictures',
      className: 'BulkAction_DownloadPictures',
      node: disableWrapper(DownloadPicturesAction),
      tooltip: getBulkTooltipImage,
      isEnabled: DownloadPicturesAction.isEnabled,
    },
    {
      name: 'action-downloadPackshots',
      className: 'BulkAction_DownloadPackshots',
      node: disableWrapper(DownloadPackshotsAction),
      tooltip: getBulkTooltipImage,
      isEnabled: DownloadPackshotsAction.isEnabled,
    },
    {
      name: 'action-removeFromActiveRange',
      className: 'BulkAction_RemoveFromActiveRange',
      node: disableWrapper(RemoveFromActiveRangeAction),
      tooltip: getBulkTooltipAtLeastOneProduct,
      isEnabled: this.enabledSelected,
    },
    {
      name: 'action-userlabel',
      className: 'BulkAction_UserLabel',
      featureFlag: FEATURE_ALL_USERLABEL_MANAGEMENT,
      node: disableWrapper(UserLabelAction),
      tooltip: getBulkTooltipAtLeastOneProduct,
      isEnabled: this.enabledSelected,
    },
    {
      name: 'action-validate',
      className: 'BulkAction_SetAsExportable',
      node: disableWrapper(SetAsExportable),
      tooltip: getBulkTooltipExportable,
      isEnabled: SetAsExportable.isEnabled,
    },
    {
      name: 'action-bulk-edit',
      className: 'BulkAction__edit',
      node: disableWrapper(BulkEditAction),
      tooltip: getBulkTooltipAtLeastOneProduct,
      isEnabled: this.enabledSelected,
      checkMultiTm: true,
    },
    {
      name: 'action-duplicate',
      className: 'BulkAction__duplicate',
      node: disableWrapper(DuplicateAction),
      tooltip: getBulkTooltipOnlyOneProduct,
      isEnabled: /** @type {any} */ (DuplicateAction).isEnabled,
    },
    {
      name: 'action-product-segments',
      className: 'BulkAction__productSegments',
      node: disableWrapper(ProductSegmentsAction),
      tooltip: getBulkTooltipAtLeastOneProduct,
      isEnabled: this.enabledSelected,
    },
    {
      name: 'action-contribution',
      className: 'BulkAction__contribution',
      featureFlag: FEATURE_THIRD_PARTY_PHYSICAL_CHECK,
      node: disableWrapper(ContributionAction),
      tooltip: getBulkTooltipAtLeastOneProduct,
      isEnabled: this.enabledSelected,
    },
    {
      name: 'action-set-as-public',
      className: 'BulkAction__setAsPublic',
      node: disableWrapper(SetAsPublicAction),
      tooltip: getBulkTooltipAtLeastOneProduct,
      isEnabled: this.enabledSelected,
    },
    {
      name: 'action-assign-new-market',
      className: 'BulkAction__assignToNewMarket',
      featureFlag: FEATURE_MULTI_TARGETMARKET_CATALOG,
      node: disableWrapper(AssignToNewMarketAction),
      tooltip: getBulkTooltipOnlyOneProduct,
      isEnabled: AssignToNewMarketAction.isEnabled,
    },
    {
      name: 'action-bulk-validate',
      className: 'BulkAction__bulkValidate',
      featureFlag: FEATURE_PNZ_BULK_VALIDATE,
      node: disableWrapper(BulkValidate),
      tooltip: getBulkTooltipValidate,
      isEnabled: BulkValidate.isEnabled,
    },
    {
      name: 'action-bulk-prepare-for-channel',
      className: 'BulkAction__bulkPrepareForChannel',
      node: disableWrapper(BulkPrepareForChannels),
      tooltip: getBulkTooltipAtLeastOneProduct,
      isEnabled: this.enabledSelected,
    },
    {
      name: 'action-bulk-share-logistical-hierarchy',
      className: 'BulkAction__bulkPrepareForChannel',
      featureFlag: RELEASE_LOGISTICAL_HIERARCHIES_MASS_EDIT,
      node: disableWrapper(BulkShareLogisticalHierarchy),
      tooltip: getBulkTooltipAtLeastOneProduct,
      isEnabled: this.enabledSelected,
    },
    {
      name: 'action-publish',
      className: 'BulkAction_Publish',
      featureFlag: FEATURE_MANUFACTURER_BULK_PUBLISH,
      node: disableWrapper(BulkActionPublish),
      tooltip: getBulkTooltipAtLeastOneProduct,
      isEnabled: this.enabledSelected,
    },
    {
      name: 'maturity-rulesets',
      className: 'BulkAction_Maturity',
      featureFlag: FEATURE_DATA_MATURITY_MANAGEMENT,
      node: disableWrapper(BulkActionMaturityRules),
      tooltip: getBulkTooltipAtLeastOneProduct,
      isEnabled: this.enabledSelected,
    },
    {
      name: 'bulk-message',
      className: 'BulkAction_BulkMessage',
      node: BulkMessage,
      isDisplayable: bulkMessageDisplayable,
      tooltip: getBulkTooltipMessage,
      isEnabled: this.enabledSelected,
    },
  ];

  selectKey = (opt) => opt.key;

  formatOptions() {
    const {
      productMap,
      selectedMap,
      filtersQueryMap,
      nbFilteredProducts,
      user,
    } = this.props;
    let options;

    // without RELEASE_BULK_ACTIVATE_RECIPIENTS => Old behavior: will not show disabled options, but will
    // only show options if they meet both shouldBeDisplayed and isEnabled requirements
    // with RELEASE_BULK_ACTIVATE_RECIPIENTS => show option if shouldBeDisplayed is true, but will show the
    // element as disabled if isEnabled is not true. New behavior that should become the standard soon
    if (!hasFeature(user, RELEASE_BULK_ACTIVATE_RECIPIENTS)) {
      if (selectedMap.isEmpty()) {
        return [
          {
            key: 'osef',
            label: (
              <span className="NoResult">
                {i18n.t('You must select at least 1 product')}
              </span>
            ),
          },
        ];
      }

      options = this.allOptions.reduce((acc, opt) => {
        // ensure flag is on for user if needed
        if (!opt.featureFlag || hasFeature(user, opt.featureFlag)) {
          if (
            opt.node.shouldBeDisplayed
              ? opt.node.shouldBeDisplayed({
                  user,
                }) &&
                opt.isEnabled({
                  productMap,
                  selectedMap,
                  user,
                  onAllProductsWithFilters: false,
                })
              : opt.isDisplayable?.({
                  selectedMap,
                  user,
                })
          ) {
            acc.push({
              key: opt.name,
              label: (
                <opt.node
                  selectedMap={selectedMap}
                  productMap={productMap}
                  user={user}
                  checkDisabled={false}
                  checkMultiTm={opt.checkMultiTm}
                  isAsync={opt.isAsync}
                />
              ),
              classNames: { [opt.className]: true },
            });
          }
        }
        return acc;
      }, /** @type {any[]} */ ([]));
      if (options.length === 0) {
        return [
          {
            key: 'osef',
            label: (
              <span className="NoResult">{i18n.t('No action available')}</span>
            ),
          },
        ];
      }
    } else {
      options = this.allOptions.reduce((acc, opt) => {
        // ensure flag is on for user if needed
        if (!opt.featureFlag || hasFeature(user, opt.featureFlag)) {
          if (
            opt.node.shouldBeDisplayed
              ? opt.node.shouldBeDisplayed({
                  user,
                })
              : opt.isDisplayable?.({
                  selectedMap,
                  user,
                })
          ) {
            acc.push({
              key: opt.name,
              label: (
                <opt.node
                  selectedMap={selectedMap}
                  productMap={productMap}
                  user={user}
                  tooltipMessage={opt.tooltip()}
                  checkDisabled={true} // until this is the default behavior
                  isDisabled={
                    !opt.isEnabled({
                      productMap,
                      selectedMap,
                      user,
                      onAllProductsWithFilters: opt.onAllProductsWithFilters,
                    })
                  }
                  checkMultiTm={/** @type {boolean} */ (opt.checkMultiTm)}
                  filtersQueryMap={filtersQueryMap}
                  nbFilteredProducts={nbFilteredProducts}
                  name={opt.name}
                  isAsync={opt.isAsync}
                />
              ),
              classNames: { [opt.className]: true },
            });
          }
        }
        return acc;
      }, /** @type {any[]} */ ([]));
    }
    return options;
  }

  render() {
    return (
      <div className="CatalogBulkActionSelector">
        <Dropdown
          label={i18n.t('Bulk actions')}
          options={this.formatOptions()}
          selectKey={this.selectKey}
          closeDropdownOnClickElement
          rightDropdown
          withoutWrappers
        />
      </div>
    );
  }
}

export default CatalogBulkAction;

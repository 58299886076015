import { flow } from 'lodash/fp';
import PropTypes from 'prop-types';
import { PureComponent } from 'react';
import { connect } from 'react-redux';
import { createStructuredSelector } from 'reselect';

import { ROOM_TYPE_PRODUCTVERSION } from 'constants/room';
import { withUserType } from 'hocs';
import { Chat } from 'modules/chat';
import {
  selectProductVersionId,
  selectSharedProductVersionId,
} from 'reducers/productVersion';

const mapStateToProps = createStructuredSelector({
  productVersionId: selectProductVersionId,
  sharedProductVersionId: selectSharedProductVersionId,
});

class ProductChat extends PureComponent {
  static propTypes = {
    // from store
    productVersionId: PropTypes.number.isRequired,
    sharedProductVersionId: PropTypes.number,

    // withUserType
    isRetailer: PropTypes.bool.isRequired,
  };

  render() {
    const { isRetailer, productVersionId, sharedProductVersionId } = this.props;

    return (
      <div>
        <div className="ProductChat">
          <Chat
            roomType={ROOM_TYPE_PRODUCTVERSION}
            entityId={isRetailer ? sharedProductVersionId : productVersionId}
          />
        </div>
      </div>
    );
  }
}

const enhance = flow(withUserType, connect(mapStateToProps));

export default enhance(ProductChat);

import { flow, get, isUndefined, map, set, update } from 'lodash/fp';

import {
  initSelectedFilterState,
  resetSelectedFilters,
  updateSelectedFilters,
} from 'core/modules/list/reducers/filters';
import { updateFilteredFilters } from 'core/modules/list/reducers/filters-config';
import { getSubProducts } from 'modules/price-waterfalls/components/price-waterfall/PriceWaterfallWithChildren';
import { RuleApplicationStatus, restrictionTypes } from 'modules/validation';
import { calculatePages } from 'utils';
import { createReducer } from 'utils/redux';

import {
  FETCH_DISPLAY_GROUP_SHARING_UNIT_TEMPLATES_DONE,
  FETCH_SHARING_UNIT_PRODUCT_DATA_DONE,
  FETCH_SHARING_UNIT_TEMPLATES_PRODUCT_DONE,
  FETCH_SHARING_UNIT_TEMPLATES_PRODUCT_ERROR,
  GET_SHARING_UNIT_TEMPLATES,
  GET_SHARING_UNIT_TEMPLATES_DONE,
  INIT_SHARING_UNIT_TEMPLATES_PRODUCT,
  LIST_RETAILERS_DONE,
  LIST_SHARING_UNIT_TEMPLATES_DONE,
  LOAD_SHARING_UNIT_TEMPLATES,
  LOAD_SHARING_UNIT_TEMPLATES_FETCH_PRODUCT,
  PUBLISH_SHARING_UNIT_TEMPLATES,
  PUBLISH_SHARING_UNIT_TEMPLATES_DONE,
  RESET_SEARCH_LIST_SHARING_UNIT_TEMPLATES,
  RESET_SELECTED_FILTERS,
  SEARCH_LIST_SHARING_UNIT_TEMPLATES,
  SEARCH_SHARING_UNIT_TEMPLATES_PRODUCT,
  SET_ERROR_ON_SAVE,
  SHARING_UNIT_TEMPLATES_FETCH_PRODUCT_DONE,
  SHARING_UNIT_TEMPLATE_IS_CREATING,
  START_LOAD_SHARING_UNIT_TEMPLATES_PRODUCT,
  STOP_LOAD_SHARING_UNIT_TEMPLATES,
  STOP_LOAD_SHARING_UNIT_TEMPLATES_FETCH_PRODUCT,
  STOP_LOAD_SHARING_UNIT_TEMPLATES_PRODUCT,
  TOGGLE_SHARING_UNIT_TEMPLATES_PRODUCT_IN_ERROR_FILTER,
  TOGGLE_SHARING_UNIT_TEMPLATES_PRODUCT_MODAL,
  UPDATE_FILTERED_FILTER,
  UPDATE_PAGINATION_SHARING_UNIT_TEMPLATES,
  UPDATE_PAGINATION_SHARING_UNIT_TEMPLATES_PRODUCT,
  UPDATE_SELECTED_FILTERS,
  UPDATE_SHARING_UNIT_TEMPLATES,
  UPDATE_SHARING_UNIT_TEMPLATES_PRODUCT,
  UPDATE_SHARING_UNIT_TEMPLATES_PRODUCT_ERRORS,
  VALIDATE_SHARING_UNIT_TEMPLATE_DONE,
} from './actions';
import type { SharingUnitTemplatesReducer as SharingUnitTemplatesReducerType } from './types';

export const SHARING_UNIT_TEMPLATES_REDUCER_KEY = 'sharing-unit-templates';

export const initialState: SharingUnitTemplatesReducerType = {
  isLoading: false,
  pagination: {
    limit: 20,
    pages: 0,
    page: 1,
    total: 0,
  },
  templates: [],
  search: '',
  retailer_list: null,
  selected_template: null,
  selected_template_products: {
    isLoading: false,
    pagination: {
      limit: 20,
      pages: 0,
      page: 1,
      total: 0,
    },
    filterInError: false,
    searchQuery: '',
    sharing_units: [],
    sharingUnitsInErrorCount: 0,
    hasPendingValidation: true,
    hasPriceWaterfalls: false,
    sharingUnitsHasBlockingErrors: false,
  },
  attach_product_modal: {
    isOpen: false,
    product: null,
    isDirty: false,
    isLoading: false,
    errors: {
      isProductShared: null,
      isProductUniq: null,
    },
  },
  display_groups: {},
  isDirty: false,
  isPublishing: false,
  validationResult: {},
  failedBlockingRules: [],
  failedNonBlockingRules: [],
  // used to know if we come from the creation modal on the sharing unit template form
  templateIsCreating: false,
  errorsOnSave: undefined,
};

const generateEmptyPriceWaterfall = (templatePriceWaterfall) =>
  update(
    ['levels'],
    map((level: { type: { code: string }; items: any[] }) =>
      level.type.code === 'allowance'
        ? update(['items'], map(set(['fromTemplate'], true)), level)
        : level,
    ),
    templatePriceWaterfall,
  );

export const sharingUnitTemplatesReducer = createReducer(
  initSelectedFilterState(initialState, { withImmutable: false }),
  {
    [LOAD_SHARING_UNIT_TEMPLATES]: (state) => set('isLoading', true, state),
    [SHARING_UNIT_TEMPLATE_IS_CREATING]: (state) =>
      set('templateIsCreating', true, state),
    [STOP_LOAD_SHARING_UNIT_TEMPLATES]: (state) =>
      set('isLoading', false, state),
    [UPDATE_PAGINATION_SHARING_UNIT_TEMPLATES]: (state, { payload }) => {
      return flow(
        (s) =>
          !isUndefined(payload.total)
            ? set(['pagination', 'total'], payload.total, s)
            : s,
        (s) =>
          !isUndefined(payload.limit)
            ? set(['pagination', 'limit'], payload.limit, s)
            : s,
        (s) =>
          !isUndefined(payload.page)
            ? set(['pagination', 'page'], payload.page, s)
            : s,
      )(state);
    },
    [LIST_SHARING_UNIT_TEMPLATES_DONE]: (state, { payload }) =>
      flow(
        set('templates', payload.data),
        set('pagination.total', payload.totalResults),
        set(
          'pagination.pages',
          calculatePages(payload.totalResults, state.pagination.limit),
        ),
      )(state),
    [UPDATE_SELECTED_FILTERS]: (state, { payload }) =>
      updateSelectedFilters(state, { payload }),
    [RESET_SELECTED_FILTERS]: (state) => resetSelectedFilters(state),
    [LIST_RETAILERS_DONE]: (state, { payload }) =>
      set('retailer_list', payload, state),
    [SEARCH_LIST_SHARING_UNIT_TEMPLATES]: (state, { payload }) =>
      set('search', payload)(state),
    [RESET_SEARCH_LIST_SHARING_UNIT_TEMPLATES]: (state) =>
      set('search', initialState.search)(state),
    [UPDATE_FILTERED_FILTER]: (state, { payload }) =>
      updateFilteredFilters(state, { payload }),
    [GET_SHARING_UNIT_TEMPLATES]: (state) =>
      flow(
        set(['selected_template_products', 'searchQuery'], ''),
        set(['selected_template_products', 'pagination', 'page'], 1),
      )(state),
    [GET_SHARING_UNIT_TEMPLATES_DONE]: (state, { payload }) =>
      flow(
        set('selected_template', payload),
        set('isDirty', false),
        set('isPublishing', false),
      )(state),
    [UPDATE_SHARING_UNIT_TEMPLATES]: (
      state,
      { payload: { path, value, isDirty } },
    ) => {
      const funcs = [set(['selected_template', ...path], value)];
      if (isDirty) {
        funcs.push(set('isDirty', true));
      }
      return flow(funcs)(state);
    },
    [FETCH_DISPLAY_GROUP_SHARING_UNIT_TEMPLATES_DONE]: (
      state,
      { payload: { retailer_id, data } },
    ) => set(['display_groups', retailer_id], data)(state),
    [VALIDATE_SHARING_UNIT_TEMPLATE_DONE]: (state, { payload }) => {
      return flow(
        set('validationResult', payload),
        set(
          'failedBlockingRules',
          payload.result.rules.filter(
            (r) =>
              r.status === RuleApplicationStatus.KO &&
              r.restrictionType === restrictionTypes.BLOCKING,
          ),
        ),
        set(
          'failedNonBlockingRules',
          payload.result.rules.filter(
            (r) =>
              r.status === RuleApplicationStatus.KO &&
              r.restrictionType !== restrictionTypes.BLOCKING,
          ),
        ),
      )(state);
    },
    [FETCH_SHARING_UNIT_PRODUCT_DATA_DONE]: (state, { payload }) => {
      const funcs = [
        set(
          ['attach_product_modal', 'product', 'product', 'version'],
          payload.productVersion,
        ),
      ];
      if (payload.hasPriceWaterfalls) {
        const templatePriceWaterfall = get(
          ['selected_template', 'data', 'priceWaterfalls', '0'],
          state,
        );
        const subProducts = getSubProducts(payload.productVersion).map(
          (product) => {
            return { id: get('targetProduct.id', product) };
          },
        );
        const priceWaterfalls = [
          { id: get('product_key.product_id', payload.productVersion) },
        ]
          .concat(subProducts)
          .map((product) => {
            const emptyPW = generateEmptyPriceWaterfall(templatePriceWaterfall);
            emptyPW.product = product;
            return emptyPW;
          });
        funcs.push(
          set(
            ['attach_product_modal', 'product', 'data', 'priceWaterfalls'],
            priceWaterfalls,
          ),
        );
      }
      return flow(funcs)(state);
    },
    [LOAD_SHARING_UNIT_TEMPLATES_FETCH_PRODUCT]: (state) =>
      set(['selected_template_products', 'isLoading'], true, state),
    [STOP_LOAD_SHARING_UNIT_TEMPLATES_FETCH_PRODUCT]: (state) =>
      set(['selected_template_products', 'isLoading'], false, state),
    [UPDATE_PAGINATION_SHARING_UNIT_TEMPLATES_PRODUCT]: (
      state,
      { payload },
    ) => {
      return flow(
        (s) =>
          !isUndefined(payload.total)
            ? set(
                ['selected_template_products', 'pagination', 'total'],
                payload.total,
                s,
              )
            : s,
        (s) =>
          !isUndefined(payload.limit)
            ? set(
                ['selected_template_products', 'pagination', 'limit'],
                payload.limit,
                s,
              )
            : s,
        (s) =>
          !isUndefined(payload.page)
            ? set(
                ['selected_template_products', 'pagination', 'page'],
                payload.page,
                s,
              )
            : s,
        set(
          ['selected_template_products', 'pagination', 'pages'],
          Math.ceil(
            (payload.total ||
              get(
                ['selected_template_products', 'pagination', 'total'],
                state,
              )) /
              (payload.limit ||
                get(
                  ['selected_template_products', 'pagination', 'limit'],
                  state,
                )),
          ),
        ),
      )(state);
    },
    [SEARCH_SHARING_UNIT_TEMPLATES_PRODUCT]: (state, { payload }) =>
      flow(
        set(['selected_template_products', 'searchQuery'], payload),
        set(['selected_template_products', 'pagination', 'page'], 1),
      )(state),
    [TOGGLE_SHARING_UNIT_TEMPLATES_PRODUCT_IN_ERROR_FILTER]: (
      state,
      { payload },
    ) =>
      flow(
        set(['selected_template_products', 'filterInError'], payload),
        set(['selected_template_products', 'pagination', 'page'], 1),
      )(state),
    [SHARING_UNIT_TEMPLATES_FETCH_PRODUCT_DONE]: (state, { payload }) =>
      flow(
        set(['selected_template_products', 'sharing_units'], payload.data),
        set(
          ['selected_template_products', 'pagination', 'total'],
          payload.totalResults,
        ),
        set(
          ['selected_template_products', 'pagination', 'pages'],
          Math.ceil(
            payload.totalResults /
              get(['selected_template_products', 'pagination', 'limit'], state),
          ),
        ),
        set(
          ['selected_template_products', 'sharingUnitsInErrorCount'],
          payload.sharingUnitsInErrorCount,
        ),
        set(
          ['selected_template_products', 'hasPendingValidation'],
          payload.hasPendingValidation,
        ),
        set(
          ['selected_template_products', 'sharingUnitsHasBlockingErrors'],
          payload.sharingUnitsHasBlockingErrors,
        ),
      )(state),
    [TOGGLE_SHARING_UNIT_TEMPLATES_PRODUCT_MODAL]: (state, { payload }) => {
      const funcs = [set(['attach_product_modal', 'isOpen'], payload.isOpen)];
      if (!payload.isOpen) {
        funcs.push(
          set(['attach_product_modal', 'product'], null),
          set(['attach_product_modal', 'isDirty'], false),
          set(['attach_product_modal', 'isLoading'], false),
          set(['attach_product_modal', 'errors'], {
            isProductShared: null,
            isProductUniq: null,
          }),
        );
      }
      return flow(funcs)(state);
    },
    [INIT_SHARING_UNIT_TEMPLATES_PRODUCT]: (state) => {
      return set(
        ['attach_product_modal', 'product'],
        {
          product: { value: { product_key_id: null } },
          data: {
            hierarchy: {},
          },
        },
        state,
      );
    },
    [FETCH_SHARING_UNIT_TEMPLATES_PRODUCT_DONE]: (state, { payload }) =>
      set(['attach_product_modal', 'product'], payload, state),
    [FETCH_SHARING_UNIT_TEMPLATES_PRODUCT_ERROR]: (state) =>
      set(['attach_product_modal', 'product'], { error: true }, state),
    [UPDATE_SHARING_UNIT_TEMPLATES_PRODUCT]: (
      state,
      { payload: { path, value, isDirty } },
    ) => {
      const funcs = [set(['attach_product_modal', 'product', ...path], value)];
      if (isDirty) {
        funcs.push(set(['attach_product_modal', 'isDirty'], true));
      }
      return flow(funcs)(state);
    },
    [SET_ERROR_ON_SAVE]: (state, { payload }) =>
      set('errorsOnSave', payload, state),
    [START_LOAD_SHARING_UNIT_TEMPLATES_PRODUCT]: (state) =>
      set(['attach_product_modal', 'isLoading'], true, state),
    [STOP_LOAD_SHARING_UNIT_TEMPLATES_PRODUCT]: (state) =>
      set(['attach_product_modal', 'isLoading'], false, state),
    [UPDATE_SHARING_UNIT_TEMPLATES_PRODUCT_ERRORS]: (
      state,
      {
        payload,
      }: {
        payload: {
          isProductShared?: boolean | null;
          isProductUniq?: boolean | null;
        };
      },
    ) => {
      return flow(
        (s) =>
          !isUndefined(payload.isProductShared)
            ? set(
                ['attach_product_modal', 'errors', 'isProductShared'],
                payload.isProductShared,
                s,
              )
            : s,
        (s) =>
          !isUndefined(payload.isProductUniq)
            ? set(
                ['attach_product_modal', 'errors', 'isProductUniq'],
                payload.isProductUniq,
                s,
              )
            : s,
      )(state);
    },
    [PUBLISH_SHARING_UNIT_TEMPLATES]: (state) =>
      set('isPublishing', true, state),
    [PUBLISH_SHARING_UNIT_TEMPLATES_DONE]: (state) =>
      set('isPublishing', false, state),
  },
);

import i18n from 'utils/i18n';

export const PENDING_ASSIGNATION_STATUS = {
  id: 0,
  label: i18n.t('frontproductstream.constants.assignation_status.pending', {
    defaultValue: 'Pending',
  }),
};
export const REFUSED_ASSIGNATION_STATUS = {
  id: 2,
  label: i18n.t('frontproductstream.constants.assignation_status.refused', {
    defaultValue: 'Refused',
  }),
};

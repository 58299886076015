export const PRODUCT_ADMIN_STORE_KEY = 'product-admin';

export const PRODUCT_ADMIN_REINDEX_PRODUCT = 'product-admin/reindex-product';
export const PRODUCT_ADMIN_REINDEX_PRODUCT_SUCCESS =
  'product-admin/reindex-product-success';
export const PRODUCT_ADMIN_REINDEX_PRODUCT_FAILURE =
  'product-admin/reindex-product-failure';

export const PRODUCT_ADMIN_LOAD_PRODUCT_INDEXED_AT_DATE =
  'product-admin/load-product-indexed-at-date';
export const PRODUCT_ADMIN_LOAD_PRODUCT_INDEXED_AT_DATE_DONE =
  'product-admin/load-product-indexed-at-date-done';

export const PRODUCT_ADMIN_RESYNC_PRODUCT = 'product-admin/resync-product';
export const PRODUCT_ADMIN_RESYNC_PRODUCT_DONE =
  'product-admin/resync-product-done';

export const PRODUCT_ADMIN_START_AUDIT = 'product-admin/start-audit';
export const PRODUCT_ADMIN_START_AUDIT_DONE = 'product-admin/start-audit-done';
export const PRODUCT_ADMIN_GET_AUDIT_DATAS = 'product-admin/get-audit-data';
export const PRODUCT_ADMIN_SET_AUDIT_DATAS = 'product-admin/set-audit-data';
export const PRODUCT_ADMIN_DELETE_AUDIT_DATAS =
  'product-admin/delete-audit-data';
export const PRODUCT_ADMIN_GET_AUDIT_CONTEXT =
  'product-admin/get-audit-context';
export const PRODUCT_ADMIN_SET_AUDIT_CONTEXT =
  'product-admin/set-audit-context';
export const PRODUCT_ADMIN_GET_AUDIT_CONTEXT_DATAS =
  'product-admin/get-audit-context-data';

export enum TemplateStatus {
  DRAFT = 'DRAFT',
  PUBLISHED = 'PUBLISHED',
  ARCHIVED = 'ARCHIVED',
  UPDATE_TO_BE_PUBLISHED = 'UPDATE_TO_BE_PUBLISHED',
}

export enum SharingUnitStatus {
  INIT = 'INIT',
  AGREED = 'AGREED',
  DELETED = 'DELETED',
  DRAFT = 'DRAFT',
  REFUSED = 'REFUSED',
}

import { createSelector } from '@reduxjs/toolkit';
import { flow, get, isEmpty } from 'lodash/fp';

export const selectModuleState = (state) => state.displayGroups;

export const selectDefaultDisplayGroups = createSelector(
  selectModuleState,
  get('default'),
);

export const selectNonSpecificFieldsDisplayGroups = createSelector(
  selectModuleState,
  get('nonSpecificFields'),
);

export const selectSpecificFieldsDisplayGroups = createSelector(
  selectModuleState,
  get('specificFields'),
);

export const selectHasSpecificFields = createSelector(
  selectSpecificFieldsDisplayGroups,
  (specificFields) => !isEmpty(specificFields),
);

export const selectCollapsedFormGroups = createSelector(
  selectModuleState,
  get('collapsedFormGroups'),
);

export const findFieldByName = (displayGroup, fieldName) =>
  displayGroup.reduce((acc, dp) => {
    if (dp.kind === 'Field' && dp.model === fieldName) {
      acc.push(dp);
    }
    if (dp.items) {
      return acc.concat(findFieldByName(dp.items, fieldName));
    }
    return acc;
  }, []);

export const selectFieldByName = createSelector(
  selectDefaultDisplayGroups,
  (displayGroups) => (fieldName) =>
    findFieldByName(displayGroups, fieldName)[0],
);

export const selectAreDisplayGroupsEditable = flow(
  selectModuleState,
  get(['editable']),
);

export const selectShouldFetchHeterogeneousUnitFields = flow(
  selectModuleState,
  get(['shouldFetchHeterogeneousFields']),
);

export const getId = (ref) => ref.get('id');
export const getCode = (ref) => ref.get('code');
export const getLabel = (ref) => ref.get('label');
export const getData = (ref) => ref.get('data');
export const getPath = (ref) => ref.getIn(['data', 'path']);
export const isLocalized = (ref) => ref.getIn(['data', 'localized']);
export const getType = (ref) => ref.getIn(['data', 'type']);
export const getDefault = (ref) => ref.getIn(['data', 'default']);
export const getDefaultForSourcing = (ref, isEligible) =>
  isEligible
    ? ref.getIn(['data', 'default'])
    : ref.getIn(['data', 'defaultNotEligible']) ||
      ref.getIn(['data', 'default']);
export const getSort = (ref) => ref.getIn(['data', 'sort']);
export const getWidth = (ref) => ref.getIn(['data', 'width']);
export const isClickable = (ref) => ref.getIn(['data', 'clickable']);
export const isBold = (ref) => ref.getIn(['data', 'bold']);
export const getHeader = (ref) => ref.getIn(['data', 'header']);

import { get } from 'lodash';
import { useEffect, useRef } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';

import { publish, useSubscribe } from '@alkem/micro-frontend-tools';

import qs, { withQuery } from 'utils/query';

import { OPEN_HELP_CENTER_EVENT } from '../constants';
import { HelpState } from '../types';

export const ELEVIO_ARTICLE = 'data-elevio-article';

export function HelpHandler({
  hasSalesforceKnowledgeBase,
}: {
  hasSalesforceKnowledgeBase: boolean;
}) {
  const location = useLocation();
  const navigate = useNavigate();
  const hasSalesforceKBRef = useRef(hasSalesforceKnowledgeBase);

  hasSalesforceKBRef.current = hasSalesforceKnowledgeBase;

  useEffect(() => {
    const search = qs.parse(location.search);
    let article = search[ELEVIO_ARTICLE];
    if (!article) return;
    article = Array.isArray(article) ? article[0] : article;
    if (hasSalesforceKBRef.current) {
      publish<HelpState>(OPEN_HELP_CENTER_EVENT, { isOpen: true, article });
    } else {
      get(global, ['_elev', 'openArticle'])?.(article);
    }
    const searchWithoutElevioArticle = Object.keys(search)
      .filter((key) => key !== ELEVIO_ARTICLE)
      .reduce((acc, key) => Object.assign(acc, { [key]: search[key] }), {});
    navigate(withQuery(location.pathname, searchWithoutElevioArticle), {
      replace: true,
    });
  }, [location.pathname, location.search, navigate]);

  useEffect(() => {
    const listener: (event: MouseEvent) => void = (event) => {
      const el = event.target as HTMLElement | null;
      if (!el?.getAttribute) return;
      const article =
        el.getAttribute(ELEVIO_ARTICLE) || el.getAttribute('data-help-article');
      if (article) {
        event.preventDefault();
        if (hasSalesforceKBRef.current) {
          publish<HelpState>(OPEN_HELP_CENTER_EVENT, { isOpen: true, article });
        } else {
          get(global, ['_elev', 'openArticle'])?.(article);
        }
      }
    };
    document.addEventListener('click', listener);
    return () => {
      document.removeEventListener('click', listener);
    };
  }, []);

  useSubscribe<HelpState>(
    OPEN_HELP_CENTER_EVENT,
    ({ isOpen, article, module }) => {
      if (!hasSalesforceKBRef.current && isOpen) {
        if (article) {
          get(global, ['_elev', 'openArticle'])?.(article);
        } else if (module) {
          get(global, ['_elev', 'openModule'])?.(module);
        }
      }
    },
  );

  return null;
}

import { get, isArray, isEqual, isUndefined, sortBy } from 'lodash/fp';
import { PureComponent } from 'react';
import { connect } from 'react-redux';
import { createStructuredSelector } from 'reselect';

import { Checkbox } from '@alkem/react-ui-checkbox';
import { DisplayGroup, Field } from '@alkem/sdk-dashboard';

import { updateEntity } from 'actions/entity';
import { renderHelpMessage } from 'components/ui/form/field';
import {
  renderField,
  shouldRenderFieldBasedOnConditions,
} from 'components/ui/form/field/utils/render';
import { getDefaultValue } from 'components/ui/form/field/utils/seed';
import InputWithLabel from 'components/ui/input/input-with-label';
import {
  selectHasMultiLevel,
  selectHasTextileRelease,
} from 'modules/feature-flag/selectors';
import {
  selectActiveTextileVariantList,
  selectCurrentLanguage,
  selectTextileVariantList,
} from 'reducers/productVersion';
import { Selector } from 'types';
import i18n from 'utils/i18n';
import { separateActions } from 'utils/redux';

import {
  addVariantField,
  removeVariantField,
  setVariantFields,
} from './actions';
import { selectVariantFields } from './selectors';
import './textile.scss';

interface Props {
  hasProductUpdatePermission: boolean;
  entity: any;
  displayGroup: DisplayGroup;
  entityId: number;
  entityKind: string;
  textileVariants: any;
  activeTextileVariants: any;
  hasTextileRelease: boolean;
  hasMultiLevel: boolean;
  variantFields: any;
  currentLanguage: any;
  actions: {
    updateEntity(
      key: string,
      value: any,
      entityId: number,
      entityKind: string,
      isDirty?: boolean,
      ignoreField?: boolean,
    );
    setVariantFields(variantFields: Field[]);
    addVariantField(field: Field);
    removeVariantField(field: Field);
  };
}

const mapStateToProps = createStructuredSelector({
  textileVariants: selectTextileVariantList as Selector<any>,
  activeTextileVariants: selectActiveTextileVariantList,
  hasTextileRelease: selectHasTextileRelease as Selector<boolean>,
  hasMultiLevel: selectHasMultiLevel,
  variantFields: selectVariantFields as Selector<any>,
  currentLanguage: selectCurrentLanguage,
});

const mapDispatchToProps = {
  updateEntity,
  setVariantFields,
  addVariantField,
  removeVariantField,
};

export class Textile extends PureComponent<Props> {
  componentDidMount(): void {
    const { addedFields } = this.setInitialVariantFields();
    this.props.actions.setVariantFields(addedFields);
  }

  componentDidUpdate(prevProps: Readonly<Props>): void {
    const { actions } = this.props;
    if (
      get(['items'], prevProps.displayGroup) !==
      get(['items'], this.props.displayGroup)
    ) {
      const { addedFields, removedFields } = this.setInitialVariantFields(
        get(['items'], prevProps.displayGroup),
      );
      for (const field of addedFields) {
        actions.addVariantField(field);
      }
      for (const field of removedFields) {
        actions.removeVariantField(field);
      }
    }
  }

  private setInitialVariantFields(previousItems: Field[] = []): {
    addedFields: Field[];
    removedFields: Field[];
  } {
    const { entity, displayGroup, currentLanguage, hasMultiLevel } = this.props;
    const previousItemModels = previousItems.map(get(['model']));
    const newItemModels = displayGroup.items.map(get(['model']));
    const addedFields: Field[] = [];
    for (const item of displayGroup.items) {
      if (
        !previousItemModels.includes(item.model) &&
        item.tags &&
        item.tags.includes('textile_variant')
      ) {
        const value = get(item.model, entity.edited);
        if (
          isUndefined(value) ||
          value === null ||
          (isArray(value) && value.length === 0) ||
          isEqual(
            getDefaultValue(item, undefined, currentLanguage, hasMultiLevel)
              .defaultValue,
            value,
          )
        ) {
          addedFields.push(item);
        }
      }
    }
    const removedFields: Field[] = [];
    for (const item of previousItems) {
      if (!newItemModels.includes(item.model)) {
        removedFields.push(item);
      }
    }
    return {
      addedFields: this.processConditionsForVariantFields(
        get(['items'], displayGroup),
        addedFields,
      ),
      removedFields,
    };
  }

  private processConditionsForVariantFields(
    allFields: Field[],
    variantFields: Field[],
  ): Field[] {
    const enrichedVariantFields = [...variantFields];
    const variantFieldModels = variantFields.map(get(['model']));
    const conditionalFields = allFields.filter(
      (f) =>
        !get(['filterApplicableCondition', 'relativeSource'], f) &&
        variantFieldModels.includes(
          get(['filterApplicableCondition', 'conditionSource'], f),
        ),
    );
    for (const conditionalField of conditionalFields) {
      if (!variantFieldModels.includes(conditionalField.model)) {
        enrichedVariantFields.push(conditionalField);
      }
    }
    return enrichedVariantFields;
  }

  private updateVariantField = (field: Field) => (checked) => {
    const { displayGroup, entityId, entityKind, actions } = this.props;
    if (!checked) {
      for (const f of this.processConditionsForVariantFields(
        get(['items'], displayGroup),
        [field],
      )) {
        actions.addVariantField(f);
        actions.updateEntity(f.model, undefined, entityId, entityKind);
      }
    } else {
      actions.removeVariantField(field);
    }
  };

  private renderField = (field: Field) => {
    const {
      entity,
      entityKind,
      entityId,
      variantFields,
      activeTextileVariants,
    } = this.props;
    if (!shouldRenderFieldBasedOnConditions(field, entity)) {
      return null;
    }
    const isVariantField = Object.keys(variantFields || {}).includes(
      field.model,
    );
    const isReadOnly = get(['options', 'readOnly'], field);
    const checkbox =
      field.tags &&
      field.tags.includes('textile_variant') &&
      Array.isArray(activeTextileVariants) &&
      activeTextileVariants.length > 0 ? (
        <Checkbox
          id={get('model', field)}
          checked={!isVariantField}
          onChange={this.updateVariantField(field)}
          label={i18n.t('This attribute has the same value for all variants')}
          disabled={isReadOnly}
        />
      ) : null;
    if (checkbox && isVariantField) {
      // Don't display the field value, only the checkbox since we can't edit the field anyway.
      return (
        <InputWithLabel
          key={field.model}
          label={field.label}
          help={renderHelpMessage(field)}
          className="alk-flex-center"
        >
          <div className="Textile__variantField">{checkbox}</div>
        </InputWithLabel>
      );
    }
    return (
      <div key={field.model}>
        {renderField(entity, entityKind, entityId, field.model, field)}
        <div className="row">
          <div className="col-xs-8 offset-xs-4">{checkbox}</div>
        </div>
      </div>
    );
  };

  public render() {
    const { displayGroup, entity, entityKind, entityId } = this.props;
    if (!this.props.hasTextileRelease) {
      // Regularly render all fields except the variant list.
      return displayGroup.items
        .filter((f) => f.model !== 'textileVariantList')
        .map((f) => renderField(entity, entityKind, entityId, f.model, f));
    }
    // Display by order:
    //  - regular fields
    //  - variant fields
    //  - variant list
    const subFields = sortBy(
      [
        (f) => {
          let res = 1;
          if (f.model === 'textileVariantList') {
            res = 10000;
          } else if (f.tags && f.tags.includes('textile_variant')) {
            res = 100;
          }
          return get('rank', f) * res;
        },
      ],
      displayGroup.items,
    );
    return subFields.map(this.renderField);
  }
}

export default connect(
  mapStateToProps,
  mapDispatchToProps,
  separateActions,
)(Textile);

import classNames from 'classnames';
import copyToClipboard from 'clipboard-copy';
import PropTypes from 'prop-types';
import { PureComponent } from 'react';
import ImmutablePropTypes from 'react-immutable-proptypes';

import { Button } from '@alkem/react-ui-button';
import HelpTooltip from '@alkem/react-ui-helptooltip';
import Tooltip from '@alkem/react-ui-tooltip';

import i18n from 'utils/i18n';

import './internal-taxonomy.scss';

export default class InternalTaxonomySuggestion extends PureComponent {
  static propTypes = {
    ruleResult: ImmutablePropTypes.map.isRequired,
    readOnly: PropTypes.bool,
    isRetailer: PropTypes.bool,
    displayActions: PropTypes.bool,
    onDismissSuggestion: PropTypes.func.isRequired,
    languageCode: PropTypes.string.isRequired,
    index: PropTypes.number.isRequired,
  };

  constructor(props) {
    super(props);
    this.state = {
      copied: false,
    };
  }

  componentDidMount() {
    this._isMounted = true;
  }

  componentWillUnmount() {
    this._isMounted = false;
  }

  onCopy = () => {
    const { ruleResult, languageCode } = this.props;
    const code = ruleResult.getIn([
      'data',
      'suggestedValueByLanguage',
      languageCode,
      'code',
    ]);
    copyToClipboard(code);
    this.setState({ copied: true });
    global.setTimeout(() => {
      if (this._isMounted) {
        this.setState({ copied: false });
      }
    }, 5000);
  };

  renderActions() {
    const { readOnly, isRetailer, displayActions, onDismissSuggestion } =
      this.props;

    if (!displayActions || (readOnly && !isRetailer)) {
      return null;
    }

    let acceptLabel = i18n.t(
      'frontproductstream.plugins.suggestion.create_button.read_only_label',
      { defaultValue: 'Request change' },
    );
    let dismissLabel = i18n.t(
      'frontproductstream.plugins.suggestion.dissmiss_button.read_only_label',
      { defaultValue: 'Cancel' },
    );
    if (!readOnly) {
      acceptLabel = i18n.t(
        'frontproductstream.plugins.suggestion.create_button.copy_label',
        { defaultValue: 'Copy' },
      );
      dismissLabel = i18n.t(
        'frontproductstream.plugins.suggestion.dissmiss_button.label',
        { defaultValue: 'Dismiss' },
      );
    }

    return (
      <div className="RaguelHelp__suggestionActionContainer">
        <Button
          link
          content={acceptLabel}
          className="RaguelHelp__suggestionAction RaguelHelp__suggestionAction--accept"
          onClick={this.onCopy}
        />
        <Button
          link
          content={dismissLabel}
          className="RaguelHelp__suggestionAction RaguelHelp__suggestionAction--dismiss"
          onClick={onDismissSuggestion}
        />
      </div>
    );
  }

  renderHelp() {
    return (
      <div>
        <span className="RaguelInternalTaxonomy__suggestionTitle">
          {i18n.t('frontproductstream.plugins.suggestion_taxonomy.help.label', {
            defaultValue: 'Suggestions',
          })}
        </span>
        <span className="RaguelInternalTaxonomy__suggestionTooltip">
          <HelpTooltip
            id="RaguelInternalTaxonomy__tooltip"
            message={i18n.t(
              'frontproductstream.plugins.suggestion_taxonomy.help.tooltip',
              { defaultValue: 'Possible categories for your product' },
            )}
          />
        </span>
      </div>
    );
  }

  render() {
    const { ruleResult, languageCode, index } = this.props;
    const { copied } = this.state;
    const entity = ruleResult.getIn([
      'data',
      'suggestedValueByLanguage',
      languageCode,
    ]);

    const copyClasses = {
      ProductReference__copy: true,
      ProductReference__copyVisible: copied,
    };
    return (
      <span
        id={`raguel-suggestion-message-${ruleResult.get('id')}`}
        className="RaguelInternalTaxonomy__suggestionContainer"
      >
        {index === 0 ? this.renderHelp() : null}
        <div className="RaguelInternalTaxonomy__suggestion">
          <div className="RaguelInternalTaxonomy__suggestionLabel">
            <i className="mdi mdi-information-outline RaguelInternalTaxonomy__suggestionIcon" />
            {`${entity.get('path').join(' > ')}  (${entity.get('code')})`}
            <span className={classNames(copyClasses)}>
              <span className="RaguelInternalTaxonomy__copyMessage">
                <i className="mdi mdi-checkbox-marked-circle-outline RaguelInternalTaxonomy__copyIcon" />{' '}
                {i18n.t(
                  'frontproductstream.plugins.suggestion.copy_to_clipboard.feedback',
                  {
                    defaultValue:
                      'Copied to clipboard! You may paste the value below.',
                  },
                )}
              </span>
            </span>
            {copied ? null : <Tooltip id={`category-${entity.get('code')}`} />}
          </div>
          {this.renderActions()}
        </div>
      </span>
    );
  }
}

import { fromJS } from 'immutable';
import { filter, get, map, update } from 'lodash/fp';
import { all, call, delay, put, select, takeLatest } from 'redux-saga/effects';

import { startLoading, stopLoading } from 'actions/navigation';
import { notificationError, notificationSuccess } from 'actions/notification';
import { ENTITY_TYPE_SHARINGUNIT } from 'constants/entities';
import tariffApi from 'modules/sharing-unit-tariffs/api';
import { Types } from 'modules/sharing-unit-tariffs/constants';
import { selectLocales, selectOrganizationId } from 'modules/user';
import {
  RECEIVE_RULE_RESULTS,
  RECEIVE_SINGLE_ENTITY_RULE_RESULTS,
} from 'modules/validation';
import * as routes from 'routes';
import { processSaveErrors } from 'utils/actions/saveErrors';
import { getPathname, push } from 'utils/history';
import i18n from 'utils/i18n';
import { fill } from 'utils/routing';
import { takeLatestSafe, withCancel, withCatch } from 'utils/saga';
import { track } from 'utils/tracking';

import {
  CHECK_SHARING_UNIT_TEMPLATES_PRODUCT_SHARED,
  CHECK_SHARING_UNIT_TEMPLATES_PRODUCT_UNIQ,
  CREATE_SHARING_UNIT_TEMPLATES,
  DELETE_SHARING_UNITS_TEMPLATES_PRODUCT,
  DELETE_SHARING_UNIT_TEMPLATES,
  FETCH_DISPLAY_GROUP_SHARING_UNIT_TEMPLATES,
  GET_SHARING_UNIT_TEMPLATES,
  LIST_RETAILERS,
  LIST_SHARING_UNIT_TEMPLATES,
  PUBLISH_SHARING_UNIT_TEMPLATES,
  SAVE_SHARING_UNIT_TEMPLATES,
  SAVE_SHARING_UNIT_TEMPLATES_PRODUCT,
  SET_SHARING_UNIT_PRODUCT_VERSION,
  SHARING_UNIT_TEMPLATES_CANCEL_PRODUCTS_POLLING,
  SHARING_UNIT_TEMPLATES_FETCH_PRODUCT,
  TOGGLE_SHARING_UNIT_TEMPLATES_PRODUCT_MODAL,
  VALIDATE_SHARING_UNIT_TEMPLATE,
  VALIDATE_SHARING_UNIT_TEMPLATE_PRODUCT,
  sharingUnitTemplatesFetchProduct as actionFetchProducts,
  checkSharingUnitTemplatesProductShared,
  checkSharingUnitTemplatesProductUniq,
  fetchDisplayGroupSharingUnitTemplatesDone,
  fetchProductVersionDone,
  fetchSharingUnitTemplatesProductDone,
  fetchSharingUnitTemplatesProductError,
  getSharingUnitTemplates as getSharingUnitTemplatesAction,
  getSharingUnitTemplatesDone,
  initSharingUnitTemplatesProduct,
  listRetailersDone,
  listSharingUnitTemplatesDone,
  listSharingUnitTemplates as listTemplatesAction,
  loadSharingUnitTemplates,
  loadSharingUnitTemplatesFetchProduct,
  publishSharingUnitTemplatesDone,
  setErrorOnSave,
  sharingUnitTemplateIsCreating,
  sharingUnitTemplatesFetchProductDone,
  startLoadSharingUnitTemplatesProduct,
  stopLoadSharingUnitTemplates,
  stopLoadSharingUnitTemplatesFetchProduct,
  stopLoadSharingUnitTemplatesProduct,
  toggleSharingUnitTemplatesProductModal,
  updateSharingUnitTemplatesProductErrors,
  validateSharingUnitTemplateDone,
} from './actions';
import {
  fetchDisplayGroups as apiFetchDisplayGroup,
  check_productshared,
  check_uniqproduct,
  create as createTemplates,
  deleteSharingUnits,
  fetchProductVersion,
  fetchProducts,
  fetchSharingUnit,
  get as getTemplates,
  listRetailers,
  listSharingUnitTemplates,
  publish,
  update as updateTemplates,
  upsert_product,
  validate,
  validateSharingUnit,
} from './api';
import {
  DATE_FILTER_KEY,
  STATUS_FILTER_KEY,
  retailerFilter,
} from './components/views/list/filters/constants';
import {
  selectCurrentAttachedProduct,
  selectIsDirty,
  selectPagination,
  selectSearch,
  selectSelectedFilterMap,
  selectSelectedSUTProductsInErrorFilter,
  selectSelectedSUTProductsPagination,
  selectSelectedSUTProductsSearchQuery,
  selectSelectedSharingUnitTemplates,
} from './selectors';

const errorStrategy = {
  withNotification: true,
  stopLoadingOnCatch: true,
  errorMessage: (err: any) =>
    get(['data', 'message'], err) || get(['message'], err),
  stopLoadingEvent: stopLoadSharingUnitTemplates,
};

export function* fetchSharingUnitTemplatesSaga() {
  yield put(loadSharingUnitTemplates());
  const pagination = yield select(selectPagination);
  const search = yield select(selectSearch);

  const selectedFilterMap = yield select(selectSelectedFilterMap);
  const selectedRetailers = get(retailerFilter.key, selectedFilterMap);

  let retailersFilter: any[] = [];
  if (selectedRetailers) {
    retailersFilter = Object.keys(selectedRetailers);
  }
  const res = yield call(listSharingUnitTemplates, {
    pagination,
    filters: {
      retailer: retailersFilter,
      search,
      fromDate: get(`${DATE_FILTER_KEY}.from`, selectedFilterMap),
      toDate: get(`${DATE_FILTER_KEY}.to`, selectedFilterMap),
      statuses: get(STATUS_FILTER_KEY, selectedFilterMap),
    },
  });
  yield put(
    listSharingUnitTemplatesDone({
      data: res.data.data,
      totalResults: res.data.totalResults,
    }),
  );
  yield put(stopLoadSharingUnitTemplates());
}

export function* fetchRetailers() {
  const res = yield call(listRetailers);
  yield put(listRetailersDone(res));
}

export function* createSharingUnitTemplates({
  payload: { name, retailer },
}: {
  payload: { name: string; retailer: number };
}) {
  const org_id = yield select(selectOrganizationId);
  const data = yield call(
    createTemplates,
    name,
    org_id,
    retailer,
    {},
    Types.TEMPLATE.id,
  );
  yield put(sharingUnitTemplateIsCreating());
  yield call(push, fill(routes.SharingUnitTemplatesDetails, data.id));
}

export function* deleteSharingUnitTemplates({ payload }: { payload: number }) {
  const { error } = yield call(tariffApi.deleteTemplate, payload);
  if (!error) {
    if ((yield call(getPathname)) !== routes.sharingUnitTemplatesList) {
      yield call(push, routes.sharingUnitTemplatesList);
    }
    yield put(listTemplatesAction({}));

    yield put(
      notificationSuccess(
        i18n.t(
          'frontproductstream.sharing_unit_templates_listing.delete_notification.success',
          { defaultValue: 'The model has been deleted' },
        ),
      ),
    );
  } else {
    yield put(
      notificationError(
        i18n.t(
          'frontproductstream.sharing_unit_templates_listing.delete_notification.error',
          { defaultValue: 'An error occured deleting the model' },
        ),
      ),
    );
  }
}

export function* getSharingUnitTemplates({ payload }: { payload: number }) {
  yield put(loadSharingUnitTemplates());
  yield put(getSharingUnitTemplatesDone(yield call(getTemplates, payload)));
  yield call(validateSharingUnitTemplateSaga);
  yield put(stopLoadSharingUnitTemplates());
}

export function* saveSharingUnitTemplates() {
  const sut = yield select(selectSelectedSharingUnitTemplates);
  const res = yield call(updateTemplates, sut.id, sut.name, sut.data);
  if (res.error) {
    yield put(
      processSaveErrors({
        error: res.error,
        logisticalHierarchies: null,
        sharingUnits: null,
        pricewaterfallLabels: null,
      }),
    );
    return;
  }
  yield put(getSharingUnitTemplatesDone(res));
  yield put(setErrorOnSave(false));
  yield put(
    notificationSuccess(
      i18n.t(
        'frontproductstream.sharing_unit_templates_listing.save_model_success.notification',
        { defaultValue: 'Model saved' },
      ),
    ),
  );
}

export function* fetchDisplayGroups({ payload }: { payload: number }) {
  yield put(
    fetchDisplayGroupSharingUnitTemplatesDone({
      retailer_id: payload,
      data: yield call(apiFetchDisplayGroup, payload),
    }),
  );
}

export function* validateSharingUnitTemplateProductSaga() {
  yield delay(500); // used for debouncing in conjunction with takeLatest;
  const sharingUnit = yield select(selectCurrentAttachedProduct);
  const template = yield select(selectSelectedSharingUnitTemplates);
  const languages = yield select(selectLocales);
  const sourceOrganizationId = yield select(selectOrganizationId);
  const { priceWaterfalls, ...templateDataWithoutPW } = template.data;
  const mergedData = { ...sharingUnit.data, ...templateDataWithoutPW };
  const response = yield call(
    validateSharingUnit,
    { ...sharingUnit, ...{ data: mergedData } },
    sourceOrganizationId,
    template.targetOrganization.id,
    languages.toJS(),
  );

  const result = get(['result', 'data', '0'], response);

  if (!get(['result', 'rules'], result)) return;

  result.result.rules.forEach((r) => {
    if (r.paths)
      Object.keys(r.paths).forEach(
        (key) =>
          (r.paths[key] = r.paths[key].map((path) =>
            path
              .replace(/sharingUnits.0.data./, '')
              .replace(/priceWaterfalls/, 'priceWaterfalls.0'),
          )),
      );
  });
  yield put({
    type: RECEIVE_SINGLE_ENTITY_RULE_RESULTS,
    ruleResult: fromJS(result),
    entity_type: ENTITY_TYPE_SHARINGUNIT,
  });
}

export function* validateSharingUnitTemplateSaga() {
  yield delay(500); // used for debouncing in conjunction with takeLatest;
  const sut = yield select(selectSelectedSharingUnitTemplates);
  const languages = yield select(selectLocales);

  const res = yield call(validate, {
    data: sut,
    languages: languages.toJS(),
  });

  yield put({
    type: RECEIVE_RULE_RESULTS,
    data: fromJS(res.result).setIn(['entity', 'id'], sut.id),
    versionId: sut.id,
    sharingUnits: [],
    logisticalUnits: [],
  });
  yield put(validateSharingUnitTemplateDone(res));
}

export function* saveSharingUnitTemplatesProduct() {
  yield put(startLoadSharingUnitTemplatesProduct());

  const sut = yield select(selectSelectedSharingUnitTemplates);
  const attachedProduct = yield select(selectCurrentAttachedProduct);
  const priceWaterfalls = (
    get(['data', 'priceWaterfalls'], attachedProduct) || []
  ).map((pricewaterfall) =>
    update(
      ['levels'],
      map(
        update(
          ['items'],
          filter((item: { fromTemplate?: boolean }) => !item.fromTemplate),
        ),
      ),
      pricewaterfall,
    ),
  );

  const hierarchyProductId = get(
    ['data', 'hierarchyProduct', 'id'],
    attachedProduct,
  );
  const res = yield call(upsert_product, sut.id, [
    {
      ...(attachedProduct.id ? { id: attachedProduct.id } : {}),
      source_product_key: {
        id: get(['product', 'product_key_id'], attachedProduct),
      },
      data: {
        ...attachedProduct.data,
        ...(hierarchyProductId && {
          hierarchyProduct: {
            id: hierarchyProductId,
          },
        }),
        ...(priceWaterfalls.length > 0 && priceWaterfalls),
      },
    },
  ]);

  if (res.error) {
    yield put(
      notificationError(
        i18n.t(
          'frontproductstream.sharing_unit_template_detail.add_product_modal.error',
          { defaultValue: 'Unable to attach this product' },
        ),
        { context: 'modal' },
      ),
    );
    yield put(stopLoadSharingUnitTemplatesProduct());
  } else {
    yield all([
      put(toggleSharingUnitTemplatesProductModal(false)),
      put(
        notificationSuccess(
          i18n.t(
            'frontproductstream.sharing_unit_template_detail.add_product_modal.success',
            { defaultValue: 'Product successfully attached' },
          ),
        ),
      ),
      put(actionFetchProducts()),
    ]);
  }
}

export function* sharingUnitTemplatesFetchProduct() {
  yield put(startLoading());
  yield put(loadSharingUnitTemplatesFetchProduct());

  const sut = yield select(selectSelectedSharingUnitTemplates);
  const pagination = yield select(selectSelectedSUTProductsPagination);
  const isShowErrors = yield select(selectSelectedSUTProductsInErrorFilter);
  const searchQuery = yield select(selectSelectedSUTProductsSearchQuery);

  const res = yield call(
    fetchProducts,
    sut.id,
    pagination.limit,
    (pagination.page - 1) * pagination.limit,
    searchQuery,
    isShowErrors,
  );
  yield put(
    sharingUnitTemplatesFetchProductDone({
      data: res.data,
      totalResults: res.totalResults,
      sharingUnitsInErrorCount: res.sharingUnitsInErrorCount,
      hasPendingValidation: res.hasPendingValidation,
    }),
  );
  yield put(stopLoadSharingUnitTemplatesFetchProduct());
  yield put(stopLoading());
  if (res.hasPendingValidation) {
    yield call(pollProducts, sut, pagination, searchQuery, isShowErrors);
  }
}

export function* pollProducts(sut, pagination, searchQuery, isShowErrors) {
  while (true) {
    yield delay(5000);
    const res = yield call(
      fetchProducts,
      sut.id,
      pagination.limit,
      (pagination.page - 1) * pagination.limit,
      searchQuery,
      isShowErrors,
    );
    if (!res.hasPendingValidation) {
      yield put(
        sharingUnitTemplatesFetchProductDone({
          data: res.data,
          totalResults: res.totalResults,
          sharingUnitsInErrorCount: res.sharingUnitsInErrorCount,
          hasPendingValidation: res.hasPendingValidation,
        }),
      );
      return;
    }
  }
}

export function* checkSharingUnitTemplatesProductSharedSaga({
  payload,
}: {
  payload: { template_id: number; source_product_key_id: number };
}) {
  yield put(startLoadSharingUnitTemplatesProduct());
  const res = yield call(
    check_productshared,
    payload.template_id,
    payload.source_product_key_id,
  );
  yield put(
    updateSharingUnitTemplatesProductErrors({
      isProductShared: res.shared === true,
    }),
  );
  yield put(stopLoadSharingUnitTemplatesProduct());
}

export function* checkSharingUnitTemplatesProductUniqSaga({
  payload,
}: {
  payload: {
    template_id: number;
    source_product_key_id: number;
    hierarchy_id?: number;
    sharing_unit_id?: number;
  };
}) {
  yield put(startLoadSharingUnitTemplatesProduct());
  const res = yield call(
    check_uniqproduct,
    payload.template_id,
    payload.source_product_key_id,
    payload.hierarchy_id,
    payload.sharing_unit_id,
  );
  yield put(
    updateSharingUnitTemplatesProductErrors({
      isProductUniq: res.uniq === true,
    }),
  );
  yield put(stopLoadSharingUnitTemplatesProduct());
}

export function* fetchProductVersionSaga({ payload }) {
  const sourceOrganizationId = yield select(selectOrganizationId);
  const productVersion = yield call(
    fetchProductVersion,
    sourceOrganizationId,
    payload.targetOrganizationId,
    payload.productKeyId,
  );
  if (!productVersion.error) {
    yield put(
      fetchProductVersionDone({
        productVersion,
        targetOrganizationId: payload.targetOrganizationId,
        hasPriceWaterfalls: payload.hasPriceWaterfalls,
      }),
    );
  } else {
    yield put(fetchSharingUnitTemplatesProductError());
  }
}

export function* loadSharingUnitForAttachModal({
  payload,
}: {
  payload: { isOpen: boolean; sharingUnitId?: number };
}) {
  if (payload.isOpen !== true) {
    return;
  } else if (!payload.sharingUnitId) {
    yield put(initSharingUnitTemplatesProduct());
  } else {
    const res = yield call(fetchSharingUnit, payload.sharingUnitId, true, true);

    if (res.error) {
      yield put(fetchSharingUnitTemplatesProductError());
      return;
    }

    const productVersion = yield call(
      fetchProductVersion,
      get(['sourceOrganization', 'id'], res),
      get(['targetOrganization', 'id'], res),
      get(['source_product_key', 'id'], res),
    );
    if (!productVersion.error) {
      res.product.version = productVersion;
      const sut = yield select(selectSelectedSharingUnitTemplates);
      yield put(fetchSharingUnitTemplatesProductDone(res));
      yield put(
        checkSharingUnitTemplatesProductShared({
          template_id: sut.id,
          source_product_key_id: get(['product', 'product_key_id'], res),
        }),
      );
      yield put(
        checkSharingUnitTemplatesProductUniq({
          template_id: sut.id,
          source_product_key_id: get(['product', 'product_key_id'], res),
          hierarchy_id: get(['data', 'hierarchyProduct', 'id'], res) || null,
          sharing_unit_id: get(['id'], res),
        }),
      );
    } else {
      yield put(fetchSharingUnitTemplatesProductError());
    }
  }
}

export function* deleteSharingUnitSaga({ payload }) {
  yield put(startLoading());
  const { error } = yield call(deleteSharingUnits, payload);
  if (error) {
    yield put(
      notificationError(
        i18n.t(
          'frontproductstream.sharing_unit_templates_listing.delete_products_notification.error',
          { defaultValue: 'An error occured while removing the product' },
        ),
      ),
    );
  } else {
    yield all([
      put(
        notificationSuccess(
          i18n.t(
            'frontproductstream.sharing_unit_template_detail.delete_product.success',
            { defaultValue: 'Product successfully deleted' },
          ),
        ),
      ),
      put(actionFetchProducts()),
    ]);
  }
  yield put(stopLoading());
}

export function* publishTemplate() {
  const isDirty = yield select(selectIsDirty);
  if (isDirty) {
    return;
  }

  const sut = yield select(selectSelectedSharingUnitTemplates);

  const { error } = yield call(publish, sut.id);

  if (!error) {
    yield all([
      call(track, {
        category: 'tariff',
        action: 'tariff_published',
        label: get('targetOrganization.id', sut),
      }),
      put(
        notificationSuccess(
          i18n.t(
            'frontproductstream.sharing_unit_template_detail.publish_notification.success',
            { defaultValue: 'Template successfully published' },
          ),
        ),
      ),
      put(getSharingUnitTemplatesAction(sut.id)),
    ]);
  } else {
    yield put(
      notificationError(
        i18n.t(
          'frontproductstream.sharing_unit_template_detail.publish_notification.error',
          { defaultValue: 'An error occured publishing this template' },
        ),
        { context: 'modal' },
      ),
    );
  }
  yield put(publishSharingUnitTemplatesDone());
}

export function* sharingUnitTemplatesSaga() {
  yield takeLatest(
    [LIST_SHARING_UNIT_TEMPLATES],
    withCatch(fetchSharingUnitTemplatesSaga, errorStrategy),
  );
  yield takeLatest(LIST_RETAILERS, fetchRetailers);
  yield takeLatestSafe(
    CREATE_SHARING_UNIT_TEMPLATES,
    createSharingUnitTemplates,
    {
      withNotification: true,
      errorMessage: i18n.t(
        'frontproductstream.sharing_unit_templates_listing.create_model_error.notification',
        { defaultValue: 'Unable to create a new model' },
      ),
    },
  );
  yield takeLatestSafe(
    DELETE_SHARING_UNIT_TEMPLATES,
    deleteSharingUnitTemplates,
  );
  yield takeLatestSafe(
    GET_SHARING_UNIT_TEMPLATES,
    withCatch(getSharingUnitTemplates, errorStrategy),
  );
  yield takeLatestSafe(
    FETCH_DISPLAY_GROUP_SHARING_UNIT_TEMPLATES,
    fetchDisplayGroups,
  );
  yield takeLatestSafe(SAVE_SHARING_UNIT_TEMPLATES, saveSharingUnitTemplates, {
    withNotification: true,
    errorMessage: i18n.t(
      'frontproductstream.sharing_unit_templates_listing.save_model_error.notification',
      { defaultValue: 'Unable to save this model' },
    ),
  });
  yield takeLatestSafe(
    SAVE_SHARING_UNIT_TEMPLATES_PRODUCT,
    saveSharingUnitTemplatesProduct,
  );
  yield takeLatestSafe(
    SHARING_UNIT_TEMPLATES_FETCH_PRODUCT,
    withCancel(
      sharingUnitTemplatesFetchProduct,
      SHARING_UNIT_TEMPLATES_CANCEL_PRODUCTS_POLLING,
    ),
  );
  yield takeLatestSafe(
    VALIDATE_SHARING_UNIT_TEMPLATE,
    validateSharingUnitTemplateSaga,
    {
      withNotification: true,
      errorMessage: i18n.t(
        'frontproductstream.sharing_unit_templates_listing.validate_model_error.notification',
        { defaultValue: 'Unable to validate this model' },
      ),
    },
  );
  yield takeLatestSafe(
    VALIDATE_SHARING_UNIT_TEMPLATE_PRODUCT,
    validateSharingUnitTemplateProductSaga,
    {
      withNotification: true,
      errorMessage: i18n.t(
        'frontproductstream.sharing_unit_templates_listing.validate_model_error.notification',
        { defaultValue: 'Unable to validate this model' },
      ),
      context: 'modal',
    },
  );
  yield takeLatestSafe(
    CHECK_SHARING_UNIT_TEMPLATES_PRODUCT_SHARED,
    checkSharingUnitTemplatesProductSharedSaga,
  );
  yield takeLatestSafe(
    CHECK_SHARING_UNIT_TEMPLATES_PRODUCT_UNIQ,
    checkSharingUnitTemplatesProductUniqSaga,
  );
  yield takeLatestSafe(
    TOGGLE_SHARING_UNIT_TEMPLATES_PRODUCT_MODAL,
    loadSharingUnitForAttachModal,
  );
  yield takeLatestSafe(
    DELETE_SHARING_UNITS_TEMPLATES_PRODUCT,
    deleteSharingUnitSaga,
  );
  yield takeLatestSafe(PUBLISH_SHARING_UNIT_TEMPLATES, publishTemplate);
  yield takeLatestSafe(
    SET_SHARING_UNIT_PRODUCT_VERSION,
    fetchProductVersionSaga,
  );
}

import classNames from 'classnames';
import { List, Map } from 'immutable';
import moment from 'moment';
import { useMemo } from 'react';
import { useDispatch } from 'react-redux';
import { Link, useLocation } from 'react-router-dom';

import {
  PRODUCTS_RECIPIENT_NOT_RECONCILED,
  PRODUCTS_REQUEST_REFUSED,
} from 'constants/requested-products-status';
import {
  TargetProductStatusNotAssigned,
  TargetProductStatusPendingAssignation,
  TargetProductStatusWaitingToBeShared,
} from 'constants/targetProductStatus';
import {
  getProductProductKeyId,
  getProductVersionTargetProductStatus,
} from 'core/api/productversion';
import {
  getRequestedProductStatus,
  getSupplierId,
} from 'core/api/retailerproductversion';
import { isRetailer } from 'core/api/user';
import {
  fetchSupplier,
  getRefusedInformation,
  openModal as openInformationRequestModal,
} from 'modules/catalog-requests-actions/actions';
import {
  MODAL_FALLBACK,
  MODAL_REFUSED_BY_RECIPIENT,
  MODAL_TO_RECONCILE,
} from 'modules/catalog-requests-actions/constants';
import { PRODUCT_TO_REVIEW_STATUSES } from 'modules/product-review/constants';
import * as routes from 'routes';
import { catalogToReview as catalogToReviewRoute } from 'routes';
import { UserImmutable } from 'types/user';
import { get, getOr } from 'utils/immutable';
import { fill } from 'utils/routing';

import { getAnswerData } from '../utils';

import './index.scss';

type Props = {
  productVersion: Map<string, any>;
  className?: string;
  children: any;
  user: UserImmutable;
};

export const CatalogCellActionWrapper = ({
  user,
  productVersion,
  children,
  className,
}: Props) => {
  const dispatch = useDispatch();
  const { pathname } = useLocation();
  const productKeyId = useMemo(
    () => getProductProductKeyId(productVersion),
    [productVersion],
  );
  const targetProductStatus = useMemo(
    () => getProductVersionTargetProductStatus(productVersion),
    [productVersion],
  );
  const isCatalogToReview = useMemo(
    () => pathname.startsWith(catalogToReviewRoute),
    [pathname],
  );
  const isProductToReview = useMemo(
    () =>
      Boolean(
        getOr(
          List(),
          ['alkemics', 'review_process', 'action_states'],
          productVersion,
        ).find((state) =>
          PRODUCT_TO_REVIEW_STATUSES.includes(state.get('status')),
        ),
      ),
    [productVersion],
  );

  const href = useMemo(() => {
    if (!isRetailer(user)) {
      return fill(routes.productPage, productKeyId);
    }
    if (isCatalogToReview && isProductToReview) {
      return fill(routes.productReview, productKeyId);
    }
    switch (targetProductStatus) {
      case TargetProductStatusNotAssigned.id:
      case TargetProductStatusPendingAssignation.id:
        return false;
      case TargetProductStatusWaitingToBeShared.id:
        return fill(routes.productDashboard, productKeyId);
      default:
        return fill(routes.productPage, productKeyId);
    }
  }, [
    user,
    targetProductStatus,
    productKeyId,
    isProductToReview,
    isCatalogToReview,
  ]);

  const onClick = () => {
    const requestedProductStatus = getRequestedProductStatus(productVersion);

    if (
      getProductVersionTargetProductStatus(productVersion) ===
      TargetProductStatusNotAssigned.id
    ) {
      if (requestedProductStatus === PRODUCTS_RECIPIENT_NOT_RECONCILED.id) {
        dispatch(fetchSupplier(getSupplierId(productVersion)));
        dispatch(openInformationRequestModal(MODAL_TO_RECONCILE));
      } else if (requestedProductStatus === PRODUCTS_REQUEST_REFUSED.id) {
        const responses = get(productVersion, 'assignationEvents');
        const gtin = get(productVersion, [
          'specializes',
          'isIdentifiedBy',
          '0',
          'reference',
        ]);
        const refusedRecipients = responses.map((response) => {
          const [answerMessage, answerReason] = getAnswerData(response);
          return {
            organizationName: get(response, ['targetOrganization', 'name']),
            creationDate: moment(get(response, 'createdAt')).format(
              'YYYY/MM/DD HH:mm',
            ),
            answerMessage: answerMessage,
            organizationId: get(response, ['targetOrganization', 'id']),
            answerReason: answerReason,
          };
        });
        dispatch(getRefusedInformation(refusedRecipients, gtin));
        dispatch(fetchSupplier(getSupplierId(productVersion)));
        dispatch(openInformationRequestModal(MODAL_REFUSED_BY_RECIPIENT));
      } else {
        dispatch(openInformationRequestModal(MODAL_FALLBACK));
      }
    }
  };

  return href ? (
    <Link
      to={href}
      className={classNames(
        'CatalogCellActionWrapper',
        'CatalogCellActionWrapper--link',
        className,
      )}
    >
      {children}
    </Link>
  ) : (
    <div
      onClick={onClick}
      className={classNames('CatalogCellActionWrapper', className)}
    >
      {children}
    </div>
  );
};

export default CatalogCellActionWrapper;

import { isFunction } from 'lodash';

// TODO use recompose?
const withProps = (mapper) => (WrappedComponent) => (props) => {
  const newProps = isFunction(mapper) ? mapper(props) : mapper;
  const mergedProps = { ...props, ...newProps };
  return <WrappedComponent {...mergedProps} />;
};

export default withProps;

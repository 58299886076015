import { ChangeEvent, useCallback } from 'react';

import { Checkbox } from '@alkem/react-ui-inputs';

import Anchor from 'components/ui/basic/anchor';

import { TrackingDestination } from '../types';

interface Props {
  destination: TrackingDestination;
  customName?: string;
  preference?: boolean;
  isMandatory?: boolean;
  onChange?: (destination: TrackingDestination, isChecked: boolean) => void;
}

export default function ConsentManagerModalTool({
  destination,
  customName,
  preference,
  isMandatory,
  onChange,
}: Props) {
  const onLocalChange = useCallback(
    (event: ChangeEvent<any>) => {
      onChange?.(destination, event.target.checked);
    },
    [destination, onChange],
  );

  return (
    <tr key={destination.id} className="ConsentManagerModal__tool">
      <td className="ConsentManagerModal__toolCell">
        <Checkbox
          id={`consent-manager-tool-${destination.id}`}
          label={customName || destination.name}
          checked={preference}
          disabled={isMandatory}
          onChange={onLocalChange}
        />
      </td>
      <td className="ConsentManagerModal__toolCell ConsentManagerModal__toolDesc">
        {destination.description}
      </td>
      <td className="ConsentManagerModal__toolCell">
        {destination.website && (
          <Anchor href={destination.website}>{destination.website}</Anchor>
        )}
      </td>
    </tr>
  );
}

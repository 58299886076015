import { takeEvery } from 'redux-saga/effects';

import {
  ACTIVATE_APPLICATION,
  DEACTIVATE_APPLICATION,
  FETCH_APPLICATIONS,
} from '../constants';

import activateApplication from './activate-application';
import deactivateApplication from './deactivate-application';
import fetchApplications from './fetch-applications';

export default function* appStoreMainSaga() {
  yield takeEvery(FETCH_APPLICATIONS, fetchApplications);
  yield takeEvery(ACTIVATE_APPLICATION, activateApplication);
  yield takeEvery(DEACTIVATE_APPLICATION, deactivateApplication);
}

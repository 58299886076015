import { get } from 'lodash';
import memoize from 'memoize-one';

import { ApiClient, getServiceUrl } from '@alkem/sdk-dashboard';

import { workflowSharingStatusFilter } from 'core/modules/list/constants/filters/workflow-sharing-status';
import { getUserLanguage as acceptLanguage } from 'utils/i18n';

const client = memoize(() => {
  const options = {
    cacheEnabled: true,
    acceptLanguage,
    immutable: false,
  };
  return new ApiClient(getServiceUrl('kpi'), options);
});

export const fetchReportById = async ({
  report,
  paginationKey,
  limit,
  advancedSearch,
  xls,
  ...extra_params
}) => {
  try {
    if (!report || !report.get('id')) {
      throw new Error(`Invalid report: ${JSON.stringify(report)}`);
    }
    const endpoint = `/kpi/v2/reporting/${report.get('id')}`;
    const params = {
      es_extra_params: {},
    };
    if (advancedSearch) {
      params.es_extra_params = { advanced_search: advancedSearch };
    }

    if (paginationKey || limit) {
      params.pagination_key = paginationKey;
      params.limit = limit;
    }

    if (xls) {
      params.xls = true;
      const blob = await client().getBlob(endpoint, '', params, true);
      return { response: blob };
    }

    if (extra_params) {
      if (workflowSharingStatusFilter.key in extra_params) {
        params[workflowSharingStatusFilter.key] =
          extra_params[workflowSharingStatusFilter.key];
      }
    }

    const response = await client().post(endpoint, params);
    return {
      data: get(response, 'data.data'),
    };
  } catch (error) {
    return { error };
  }
};

import { useSelector } from 'react-redux';

import InputWithLabel from 'components/ui/input/input-with-label';
import { selectHasJsonStoreInputFeature } from 'modules/feature-flag/selectors';

type AssetInputProps = {
  children: React.ReactNode;
  childId: string;
  label: string;
  help?: string;
};

export const AssetInput = ({ children, ...props }: AssetInputProps) => {
  const hasJsonStoreInputFeature = useSelector(selectHasJsonStoreInputFeature);

  if (hasJsonStoreInputFeature) return null;

  return <InputWithLabel {...props}>{children}</InputWithLabel>;
};

import { useState } from 'react';

import Date from 'components/ui/input/date';
import i18n from 'utils/i18n';
import { Moment } from 'utils/moment';

import './deadlineSelector.scss';

type Deadline = Moment | null;

interface Props {
  onChange: (deadline: Deadline) => void;
  defaultDeadline: Deadline;
  minimumDateMoment: Moment;
}

export const DeadlineSelector = (props: Props) => {
  const { onChange, defaultDeadline, minimumDateMoment } = props;

  const minimumDate = minimumDateMoment.toDate();
  const [deadline, setDeadline] = useState<Deadline>(defaultDeadline);

  const handleSelect = (m: Moment | null) => {
    if (m) {
      const endofDay = m.clone().endOf('day').subtract(1, 'minute');
      setDeadline(endofDay);
      onChange(endofDay);
    }
  };

  return (
    <div
      className="DeadlineSelector alk-flex alk-flex-row alk-flex-start"
      data-testid="deadline-selector"
    >
      <label
        htmlFor="deadline_selector_datepicker"
        data-testid="deadline-selector-label"
      >
        {i18n.t('with deadline')}
      </label>
      <Date
        id="deadline_selector_datepicker"
        value={deadline}
        withDeleteSelectionButton={false}
        minimumDate={minimumDate}
        onSelect={handleSelect}
      />
    </div>
  );
};

import { List } from 'immutable';
import { negate } from 'lodash/fp';

import { get } from 'utils/immutable';
import { sortAsc } from 'utils/sort';

import {
  GROUP_STATUS_ACTIVE,
  GROUP_STATUS_PREPARING,
  GROUP_STATUS_WILL_ACTIVATE,
} from './constants';

export const isGroupActive = (groupOrStatus) =>
  [GROUP_STATUS_ACTIVE, GROUP_STATUS_WILL_ACTIVATE].includes(
    get(groupOrStatus, ['status']) || groupOrStatus,
  );

export const recipientByName = (a, b) =>
  sortAsc(a.get('nameLegal'), b.get('nameLegal'));

export const isGroupAssigned = (group) =>
  [
    GROUP_STATUS_ACTIVE,
    GROUP_STATUS_WILL_ACTIVATE,
    GROUP_STATUS_PREPARING,
  ].includes(group.get('status'));

export const splitGroupMembers = (g) => {
  const group = g.update('members', (members) => members || List());
  const isImportant = (m) => m.get('canBeToggled') || m.get('toggled');
  const isDisplayable = (m) => m.get('recipientAlwaysDisplayable');
  const importantRecipients = group
    .get('members')
    .filter(isImportant)
    .sort(recipientByName);
  const alwaysDisplayableRecipients = group
    .get('members')
    .filter(isDisplayable)
    .filter(negate(isImportant))
    .sort(recipientByName);
  const additionalRecipients = group
    .get('members')
    .filter(negate(isImportant))
    .filter(negate(isDisplayable))
    .sort(recipientByName);
  return group
    .set('importantRecipients', importantRecipients)
    .set('alwaysDisplayableRecipients', alwaysDisplayableRecipients)
    .set('additionalRecipients', additionalRecipients);
};

import { track } from 'utils/tracking';

const proxy = (action) => (id) =>
  track({
    category: 'listing',
    action: `listing_${action}`,
    label: `listing#${id}`,
  });

export default {
  create: proxy('created'),
  save: proxy('saved'),
  share: proxy('submitted'),
};

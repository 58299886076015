import { curry, reduce, set, update } from 'lodash/fp';

import { ActionPayload } from 'types/redux';

import { acceptDataOpsPatches } from '../actions';
import { DataOpsAmendingStatus } from '../types';

export const dataOpsAmendingStatusReducer = curry(
  (
    action: 'isAccepting' | 'isRefusing',
    value: boolean,
    { patches, all }: ActionPayload<typeof acceptDataOpsPatches>,
  ) =>
    all
      ? set(['amendingStatus', 0, action], value)
      : update(['amendingStatus'], (amendingStatus: DataOpsAmendingStatus) =>
          reduce(
            (acc, patch) => set([patch.id, action], value, acc),
            amendingStatus,
            patches,
          ),
        ),
);

import classNames from 'classnames';
import { Children, PureComponent, ReactNode } from 'react';

import './index.scss';

interface TabbedFiltersProps {
  title?: string;
  children: ReactNode[] | ReactNode;
  tabTitles: string[];
}

interface TabbedFiltersState {
  selectedTabIndex: number;
}

export default class TabbedFilters extends PureComponent<
  TabbedFiltersProps,
  TabbedFiltersState
> {
  constructor(props) {
    super(props);
    this.state = {
      selectedTabIndex: 0,
    };
    this.renderTab = this.renderTab.bind(this);
  }

  onTabClick(index) {
    this.setState({
      selectedTabIndex: index,
    });
  }

  renderTitle() {
    const { title } = this.props;
    if (title) {
      return <h3 className="TabbedFilters__title">{title}</h3>;
    }
    return null;
  }

  renderTab(tab, index: number) {
    const { selectedTabIndex } = this.state;
    const classes = {
      TabbedFilters__tab: true,
      'TabbedFilters__tab--selected': selectedTabIndex === index,
    };
    const onClick = () => this.onTabClick(index);
    return (
      <li key={`tab-${index}`} className={classNames(classes)}>
        <div onClick={onClick}>
          {tab.props.tabTitle || this.props.tabTitles[index]}
        </div>
      </li>
    );
  }

  renderTabs() {
    const children = Children.toArray(this.props.children);
    return (
      <ul className="TabbedFilters__tabList">{children.map(this.renderTab)}</ul>
    );
  }

  renderSelectedTab() {
    const children = Children.toArray(this.props.children);
    const { selectedTabIndex } = this.state;
    return (
      <div className="TabbedFilters__filter">{children[selectedTabIndex]}</div>
    );
  }

  render() {
    return (
      <div className="TabbedFilters">
        <div className="TabbedFilters__fieldset">
          {this.renderTitle()}
          {this.renderTabs()}
          {this.renderSelectedTab()}
        </div>
      </div>
    );
  }
}

import { OnboardingApi } from '@alkem/sdk-dashboard';

import { getUserLanguage as acceptLanguage } from 'utils/i18n';

const options = {
  cacheEnabled: false,
  acceptLanguage,
};

const serviceOnboardingApi = new OnboardingApi(options);
export default serviceOnboardingApi;

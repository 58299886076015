import { isEqual } from 'lodash/fp';
import { MutableRefObject, useLayoutEffect, useRef, useState } from 'react';

import { TabItemType } from './types';

export const useTabs = (
  navigationTabs: TabItemType[],
  tabsRef: MutableRefObject<HTMLUListElement | null>,
  fullTabsRef: MutableRefObject<HTMLUListElement | null>,
  windowWidth?: number,
) => {
  const [tabs, setTabs] = useState<{
    visibleTabs: TabItemType[];
    hiddenTabs: TabItemType[];
  }>({ visibleTabs: navigationTabs, hiddenTabs: [] });

  const lastCounts = useRef<{
    visible: number;
    navigationTabs: TabItemType[];
  }>({
    visible: 0,
    navigationTabs: [],
  });

  const offsetWidth = tabsRef.current?.offsetWidth;

  useLayoutEffect(() => {
    if (tabsRef.current && fullTabsRef.current && offsetWidth) {
      const children = Array.from(fullTabsRef.current.children);
      let fullWidth = 0;
      let visibleCount = 0;
      for (const child of children) {
        const elem = child as HTMLElement;
        fullWidth += elem.offsetWidth;
        if (fullWidth <= offsetWidth - 40) {
          visibleCount += 1;
        }
      }
      if (
        lastCounts.current.visible !== visibleCount ||
        !isEqual(lastCounts.current.navigationTabs, navigationTabs)
      ) {
        setTabs({
          visibleTabs: navigationTabs.slice(0, visibleCount),
          hiddenTabs: navigationTabs.slice(visibleCount, navigationTabs.length),
        });
        lastCounts.current = {
          visible: visibleCount,
          navigationTabs,
        };
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [offsetWidth, navigationTabs, windowWidth]);

  return tabs;
};

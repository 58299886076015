import { matchPath, useLocation } from 'react-router-dom';

import * as routePatternsModule from 'routes';

const routePatterns: string[] = Object.values(routePatternsModule);

export const useMatchPattern = () => {
  const { pathname } = useLocation();

  const matches = routePatterns.filter((routePattern) =>
    matchPath(routePattern, pathname),
  );

  return matches[0] || pathname;
};

import { useEffect, useRef } from 'react';

// This hooks is useful to store previous
// values inside React hooks, similar to
// prevProps or prevState in Class components
export function usePrevious(value) {
  const ref = useRef();
  useEffect(() => {
    ref.current = value;
  });
  return ref.current;
}

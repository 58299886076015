import classNames from 'classnames';
import { isEqual } from 'lodash/fp';
import { useCallback, useMemo, useState } from 'react';

import Anchor from 'components/ui/basic/anchor';
import Modal from 'components/ui/modal';
import { userContentUpsert } from 'resources/userConsentApi';
import i18n, { getByLocale } from 'utils/i18n';
import { logError } from 'utils/logging';
import { sortAsc } from 'utils/sort';

import { saveTrackingPreferences } from '../actions';
import {
  matchAppCues,
  matchCustomerIo,
  matchElevio,
  matchGoogleAnalytics,
  matchHupSpot,
  matchIntercomWeb,
  matchInvisibleTools,
  matchMandatoryTools,
  matchMixpanel,
} from '../matchers';
import { ConsentManagerData, TrackingDestination } from '../types';

import ConsentManagerModalHeaders from './modal-headers';
import ConsentManagerModalTool from './modal-tool';
import './modal.scss';

interface Props
  extends Pick<
    ConsentManagerData,
    'destinations' | 'preferences' | 'saveConsent' | 'setPreferences'
  > {
  actions: {
    saveTrackingPreferences: typeof saveTrackingPreferences;
  };
  hasSalesforceKnowledgeBase: boolean;
}

type Preferences = Props['preferences'];

export default function ConsentManagerModal({
  destinations,
  preferences,
  setPreferences,
  saveConsent,
  actions,
  hasSalesforceKnowledgeBase,
}: Props) {
  const getCustomDescription = (destinationId: string) => {
    switch (true) {
      case matchAppCues(destinationId):
        return i18n.t(
          "Create personalized user onboarding flows without changing any code that will improve your product's adoption and retention rates.",
        );
      case matchCustomerIo(destinationId):
        return i18n.t(
          'We use Customer.io in order to communicate information to you and to keep your profile in sync with our internal tooling. This makes it a mandatory tool to use our platform.',
        );
      case matchElevio(destinationId):
        return i18n.t(
          'Use elevio to show an entire knowledge base, on every page of your site.',
        );
      case matchGoogleAnalytics(destinationId):
        return i18n.t(
          'Google Analytics is the most popular analytics tool for the web. It’s free and provides a wide range of features. It’s especially good at measuring traffic sources and ad campaigns.',
        );
      case matchHupSpot(destinationId):
        return i18n.t(
          'HubSpot is an all-in-one marketing tool that helps attract new leads and convert them into paying customers, with features like landing page creation and email automation.',
        );
      case matchMixpanel(destinationId):
        return i18n.t(
          'Mixpanel is an event tracking tool targeted at web apps with lots of features: funnel, retention and people tracking; advanced segmentation; and sending email and notifications.',
        );
      case matchIntercomWeb(destinationId):
        return i18n.t(
          'frontproductstream.consent_manager.modal.intercom_web_description',
          {
            defaultValue:
              'Intercom is one place for every team in an internet business to communicate with customers, personally, at scale - on your website, inside web and mobile apps, and by email.',
          },
        );
      default:
        return '';
    }
  };

  const getCustomName = (destinationId: string) => {
    switch (true) {
      case matchIntercomWeb(destinationId):
        return 'Intercom';
      default:
        return '';
    }
  };

  const [arePreferencesVisible, setPreferencesVisibility] = useState(false);

  const [localPreferences, setLocalPreferences] = useState<Preferences>(() =>
    destinations.reduce((acc, destination) => {
      if (hasSalesforceKnowledgeBase && matchElevio(destination.id)) {
        acc[destination.id] = false;
      } else {
        acc[destination.id] = matchMandatoryTools(destination.id)
          ? true
          : (preferences[destination.id] ?? true);
      }
      return acc;
    }, {}),
  );

  const [mandatoryToolsFromSegment, optionalTools] = useMemo(
    () =>
      destinations
        .filter((dest) => {
          if (hasSalesforceKnowledgeBase && matchElevio(dest.id)) {
            return false;
          }
          return !matchInvisibleTools(dest.id);
        })
        .reduce(
          (acc, _destination) => {
            let destination = _destination;
            const customDescription = getCustomDescription(destination.id);
            if (customDescription) {
              destination = {
                ...destination,
                description: customDescription,
              };
            }
            if (matchMandatoryTools(destination.id)) {
              acc[0].push(destination);
            } else {
              acc[1].push(destination);
            }
            return acc;
          },
          [[], []] as [TrackingDestination[], TrackingDestination[]],
        ),
    [destinations, hasSalesforceKnowledgeBase],
  );

  const mandatoryTools = useMemo(
    () =>
      [
        ...mandatoryToolsFromSegment,
        {
          category: 'monitoring',
          creationName: 'datadog',
          description: i18n.t(
            'frontproductstream.consent_manager.modal.datadog_description',
            {
              defaultValue:
                'Datadog is used to monitor our application performance, and to troubleshoot and resolve errors.',
            },
          ),
          id: 'datadog',
          name: 'Datadog',
          website: 'https://www.datadoghq.com',
        },
      ].sort((toolA, toolB) => sortAsc(toolA.name, toolB.name)),
    [mandatoryToolsFromSegment],
  );

  const showPreferences = useCallback(() => {
    setLocalPreferences((prefs) => ({
      ...prefs,
      ...optionalTools.reduce(
        (acc, tool) => ({
          ...acc,
          [tool.id]: tool.id in preferences ? preferences[tool.id] : false,
        }),
        {},
      ),
    }));
    setPreferencesVisibility(true);
  }, [preferences, optionalTools]);

  const onChange = useCallback(
    (destination: TrackingDestination, isChecked: boolean) => {
      setLocalPreferences((prefs) => ({
        ...prefs,
        [destination.id]: isChecked,
      }));
    },
    [setLocalPreferences],
  );

  const hasChanges = (newPrefs: Preferences, oldPrefs: Preferences) =>
    !isEqual(newPrefs, oldPrefs);

  const onSave = useCallback(() => {
    setPreferences(localPreferences);
    if (hasChanges(localPreferences, preferences)) {
      saveConsent();
    }
    actions.saveTrackingPreferences();
    userContentUpsert({ consents: localPreferences }).catch(logError);
  }, [actions, localPreferences, preferences, saveConsent, setPreferences]);

  const onAcceptAll = useCallback(() => {
    const newPrefs = Object.fromEntries(
      Object.entries(localPreferences).map(([destId]) => [destId, true]),
    );
    setLocalPreferences(newPrefs);
    setPreferences(newPrefs);
    if (hasChanges(newPrefs, preferences)) {
      saveConsent();
    }
    actions.saveTrackingPreferences();
    userContentUpsert({ consents: newPrefs }).catch(logError);
  }, [actions, localPreferences, preferences, saveConsent, setPreferences]);

  return (
    <Modal
      modalStyle="dynamic"
      className={classNames('ConsentManagerModal', {
        'ConsentManagerModal--prefs': arePreferencesVisible,
      })}
      title={i18n.t('GDPR')}
      confirmButtonText={
        arePreferencesVisible
          ? i18n.t('Save preferences')
          : i18n.t('Accept all')
      }
      onConfirm={arePreferencesVisible ? onSave : onAcceptAll}
      hideCloseButton
      secondaryAction={
        arePreferencesVisible
          ? undefined
          : [i18n.t('Update preferences'), showPreferences]
      }
      additionalFooterContent={
        <Anchor
          href={getByLocale({
            en: 'https://www.salsify.com/privacy-policy',
            fr: 'https://www.salsify.com/fr/confidentialit%C3%A9',
          })}
        >
          {i18n.t('Our privacy policy')}
        </Anchor>
      }
    >
      {!arePreferencesVisible ? (
        <p>
          {i18n.t(
            'We use cookies (and other similar technologies) to collect data for internal purposes only to improve your experience on our site. You can either accept them all, or pick and chose by selection "Update preferences".',
          )}
        </p>
      ) : (
        <table className="ConsentManagerModal__tools">
          <tbody>
            <tr className="ConsentManagerModal__toolsDesc">
              <td colSpan={3}>
                <p>
                  {i18n.t(
                    'These technical tools are used in order to provide primary functions of the platform, from onboarding to communication and support. They are mandatory in order for the platform to be used:',
                  )}
                </p>
              </td>
            </tr>
            <ConsentManagerModalHeaders />
            {mandatoryTools.map((destination: TrackingDestination) => (
              <ConsentManagerModalTool
                key={destination.id}
                destination={destination}
                customName={getCustomName(destination.id)}
                preference
                isMandatory
              />
            ))}
            <tr className="ConsentManagerModal__toolsDesc">
              <td colSpan={3}>
                <p>
                  {i18n.t(
                    'These marketing tools are used to improve user experience. The data that are recorded are only used internally and will never be used nor sold to another company:',
                  )}
                </p>
              </td>
            </tr>
            <ConsentManagerModalHeaders />
            {optionalTools.map((destination: TrackingDestination) => (
              <ConsentManagerModalTool
                key={destination.id}
                destination={destination}
                preference={!!localPreferences[destination.id]}
                onChange={onChange}
              />
            ))}
          </tbody>
        </table>
      )}
    </Modal>
  );
}

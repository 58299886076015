import { isEmpty, isNumber } from 'lodash';
import PropTypes from 'prop-types';
import { PureComponent } from 'react';
import ImmutablePropTypes from 'react-immutable-proptypes';
import { connect } from 'react-redux';

import { Checkbox } from '@alkem/react-ui-checkbox';

import {
  acceptOrganizationCgu,
  getMyOrganization,
  getOrganizationCgu,
} from 'actions/organization';
import Anchor from 'components/ui/basic/anchor';
import Modal from 'components/ui/modal';
import { SOURCE_PRODUCTDNA } from 'constants/organization-source';
import {
  CGU_TYPE_ALKEMICS,
  CGU_TYPE_GDSN,
  CGU_TYPE_PRODUCTDNA,
} from 'constants/permissions';
import { getOrganizationSource, isSuperAdminLoggedAs } from 'core/api/user';
import { withNavigate } from 'hocs';
import * as routes from 'routes';
import i18n from 'utils/i18n';

import './index.scss';

const messages = {
  [CGU_TYPE_GDSN]: {
    part1: i18n.t('frontproductstream.terms_and_conditions.gdsn.part1', {
      defaultValue: `Alkemics, certified GDSN data pool, provides an access to the GDSN, administrated by GS1, so you can access to all product information already published by your suppliers. GS1 asks now every suppliers and retailers that access the GDSN to sign the 'GDSN Terms of Participation Agreement'.`,
    }),
    part2: i18n.t('frontproductstream.terms_and_conditions.gdsn.part2', {
      defaultValue: `This agreement is standard and is imposed to the 40 000 suppliers and retailers accessing the GDSN all over the world. If you need any more information, please go on this link.`,
    }),
  },
};

const mapStateToProps = (state) => ({
  acceptedLastCGU: state.organization.source.acceptedLastCGU,
  acceptedLastGDSNTerms: state.organization.source.acceptedLastGDSNTerms,
  orgaId: state.organization.source.id,
  cgus: state.organization.cgus,
  acceptedCgus: state.organization.acceptedCgus,
  user: state.user,
});

const mapDispatchToProps = {
  getOrga: getMyOrganization,
  getCgus: getOrganizationCgu,
  acceptCgu: acceptOrganizationCgu,
};

export class Cgu extends PureComponent {
  static propTypes = {
    acceptedLastCGU: PropTypes.number,
    acceptedLastGDSNTerms: PropTypes.number,
    orgaId: PropTypes.number,
    cgus: PropTypes.object,
    acceptedCgus: PropTypes.object.isRequired,
    user: ImmutablePropTypes.map,

    getOrga: PropTypes.func.isRequired,
    getCgus: PropTypes.func.isRequired,
    acceptCgu: PropTypes.func.isRequired,
    navigate: PropTypes.func.isRequired,
  };

  static getDerivedStateFromProps(props, state) {
    const source = getOrganizationSource(props.user);
    const isProductDNA = source === SOURCE_PRODUCTDNA;
    const cguType = isProductDNA ? CGU_TYPE_PRODUCTDNA : CGU_TYPE_ALKEMICS;
    return { ...state, isProductDNA, cguType };
  }

  state = {
    checkedCgus: {},
  };

  componentDidMount() {
    const { orgaId, getOrga, cgus, getCgus } = this.props;
    if (!isNumber(orgaId)) {
      getOrga();
    }
    if (isEmpty(cgus)) {
      getCgus();
    }
  }

  onRefuse = () => {
    const { isProductDNA } = this.state;

    if (isProductDNA) {
      this.onContinue();
    } else {
      this.props.navigate(routes.logout);
    }
  };

  onContinue = () => {
    const { isProductDNA } = this.state;
    const { orgaId, cgus } = this.props;

    if (isProductDNA) {
      this.props.acceptCgu(orgaId, cgus[CGU_TYPE_PRODUCTDNA]);
    }
    for (const cguType of Object.keys(this.state.checkedCgus)) {
      this.props.acceptCgu(orgaId, cgus[cguType]);
    }
  };

  _checkCgu(cgu, checked) {
    const { checkedCgus } = this.state;
    this.setState({
      checkedCgus: { ...checkedCgus, [cgu.type]: checked },
    });
  }

  checkCgu = (checked) => {
    return this._checkCgu(this.props.cgus[this.state.cguType], checked);
  };

  checkCguGDSN = (checked) => {
    return this._checkCgu(this.props.cgus[CGU_TYPE_GDSN], checked);
  };

  shouldAcceptCgu() {
    const { acceptedLastCGU, cgus, acceptedCgus, user } = this.props;
    const { cguType } = this.state;
    return (
      !acceptedCgus[cguType] &&
      !!cgus[cguType] &&
      acceptedLastCGU === 0 &&
      !isSuperAdminLoggedAs(user)
    );
  }

  shouldAcceptCguGDSN() {
    const { acceptedLastGDSNTerms, cgus, acceptedCgus, user } = this.props;
    // TODO: add LD flag
    return (
      !acceptedCgus[CGU_TYPE_GDSN] &&
      !!cgus[CGU_TYPE_GDSN] &&
      acceptedLastGDSNTerms === 0 &&
      !isSuperAdminLoggedAs(user)
    );
  }

  canContinue() {
    const { checkedCgus, cguType, isProductDNA } = this.state;

    if (isProductDNA) {
      return true;
    }
    let canContinue = true;
    if (this.shouldAcceptCgu()) {
      canContinue = canContinue && checkedCgus[cguType];
    }
    if (this.shouldAcceptCguGDSN()) {
      canContinue = canContinue && checkedCgus[CGU_TYPE_GDSN];
    }
    return canContinue;
  }

  renderCgu(cgu, accepted) {
    const { isProductDNA } = this.state;
    return (
      <div key={cgu.id}>
        {!isProductDNA && (
          <h3>
            {i18n.t(
              'frontproductstream.terms_and_conditions.supplierxm.label',
              { defaultValue: 'Alkemics terms and conditions' },
            )}
          </h3>
        )}
        <p>
          {isProductDNA
            ? i18n.t(
                'frontproductstream.terms_and_conditions.pdna.text_intro',
                { defaultValue: 'Are you ready?' },
              )
            : i18n.t(
                'frontproductstream.terms_and_conditions.gdpr.text_intro',
                {
                  defaultValue:
                    'In anticipation of the General Data Protection Regulation (GDPR) coming into effect, we are updating our terms and conditions of use.',
                },
              )}
        </p>
        <p>
          {isProductDNA
            ? i18n.t(
                'frontproductstream.terms_and_conditions.pdna.onboarding',
                {
                  defaultValue:
                    "We've created a new onboarding guide to help you get started. Follow our step-by-step guide to create your products, publish them and then share them with your retailers",
                },
              )
            : i18n.t(
                'frontproductstream.terms_and_conditions.gdpr.changes_question',
                { defaultValue: 'What are the principal changes ?' },
              )}
        </p>
        {!isProductDNA && (
          <ul>
            <li>
              {i18n.t(
                'frontproductstream.terms_and_conditions.gdpr.changes_part1',
                {
                  defaultValue:
                    'We are adding mention of your new rights and giving you the contact details of our Data Protection Officer',
                },
              )}
            </li>
            <li>
              {i18n.t(
                'frontproductstream.terms_and_conditions.gdpr.changes_part2',
                {
                  defaultValue:
                    'We are adjusting our subscription conditions to enable us to propose offers to all our users that best meet their needs.',
                },
              )}
            </li>
          </ul>
        )}
        <p>
          {isProductDNA
            ? i18n.t('frontproductstream.terms_and_conditions.pdna.bookmark', {
                defaultValue:
                  'You can just bookmark the link and use it whenever you use productDNA.',
              })
            : i18n.t(
                'frontproductstream.terms_and_conditions.gdpr.changes_part3',
                {
                  defaultValue:
                    'We invite you to review the entirety of the updated terms and conditions',
                },
              )}
        </p>
        <p>
          <Anchor href={cgu.link}>
            {isProductDNA
              ? i18n.t(
                  'frontproductstream.terms_and_conditions.productdna.link',
                  { defaultValue: 'Get started here!' },
                )
              : i18n.t(
                  'frontproductstream.terms_and_conditions.supplierxm.link',
                  { defaultValue: 'Alkemics terms and conditions' },
                )}
          </Anchor>
        </p>
        {!isProductDNA && (
          <p>
            {i18n.t('frontproductstream.terms_and_conditions.thank_you.text', {
              defaultValue: 'Thank you for your confidence.',
            })}
          </p>
        )}
        {!isProductDNA && (
          <p>
            <Checkbox
              id="checkbox-cgu-alkemics"
              label={
                isProductDNA
                  ? i18n.t(
                      'frontproductstream.terms_and_conditions.productdna_accept.checkbox',
                      {
                        defaultValue:
                          'I accept Product DNA terms and conditions.',
                      },
                    )
                  : i18n.t(
                      'frontproductstream.terms_and_conditions.accept.checkbox',
                      {
                        defaultValue: 'I accept Alkemics terms and conditions.',
                      },
                    )
              }
              checked={accepted}
              onChange={this.checkCgu}
            />
          </p>
        )}
      </div>
    );
  }

  renderCguGDSN(cgu, accepted) {
    if (!this.shouldAcceptCguGDSN()) {
      return null;
    }
    return (
      <div key={cgu.id}>
        <h3>
          {i18n.t('frontproductstream.terms_and_conditions.gdsn.title', {
            defaultValue: 'GDSN Terms of Participation',
          })}
        </h3>
        <p>{messages[CGU_TYPE_GDSN].part1}</p>
        <p>
          {messages[CGU_TYPE_GDSN].part2}{' '}
          <Anchor href={cgu.link}>
            {i18n.t('frontproductstream.terms_and_conditions.gdsn.link', {
              defaultValue: 'GDSN Terms of Participation',
            })}
          </Anchor>
        </p>
        <p>
          <Checkbox
            id="checkbox-cgu-gdsn"
            label={i18n.t(
              'frontproductstream.terms_and_conditions.gdsn_accept.checkbox',
              { defaultValue: 'I accept GDSN Terms of Participation' },
            )}
            checked={accepted}
            onChange={this.checkCguGDSN}
          />
        </p>
      </div>
    );
  }

  renderCgus() {
    const { cgus } = this.props;
    const { checkedCgus, cguType } = this.state;
    return [
      this.renderCgu(
        cgus[cguType],
        (checkedCgus && checkedCgus[cguType]) || false,
      ),
      this.renderCguGDSN(
        cgus[CGU_TYPE_GDSN],
        (checkedCgus && checkedCgus[CGU_TYPE_GDSN]) || false,
      ),
    ];
  }

  render() {
    const { isProductDNA } = this.state;
    if (!this.shouldAcceptCgu() && !this.shouldAcceptCguGDSN()) {
      return null;
    }
    return (
      <Modal
        modalStyle="dynamic"
        title={
          this.state.isProductDNA
            ? i18n.t(
                'frontproductstream.terms_and_conditions.productdna_modal.title',
                { defaultValue: 'Welcome to productDNA' },
              )
            : i18n.t('frontproductstream.terms_and_conditions.modal.title', {
                defaultValue: 'Alkemics Terms and Conditions',
              })
        }
        className="CguModal"
        confirmButtonText={
          /** @type {string} */ (
            i18n.t(
              'frontproductstream.terms_and_conditions.modal_accept.button',
              { defaultValue: 'Continue' },
            )
          )
        }
        confirmDisabled={!this.canContinue()}
        closeButtonText={
          isProductDNA
            ? ''
            : /** @type {string} */ (
                i18n.t(
                  'frontproductstream.terms_and_conditions.modal_refuse.button',
                  { defaultValue: 'Logout' },
                )
              )
        }
        onConfirm={this.onContinue}
        onClose={this.onRefuse}
      >
        {this.renderCgus()}
      </Modal>
    );
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(withNavigate(Cgu));

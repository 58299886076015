import { createSelector } from '@reduxjs/toolkit';
import { List, Map, Set } from 'immutable';
import { curry, flow } from 'lodash/fp';

import {
  CONSUMER_UNIT,
  DISPLAY_UNIT,
  LOGISTICAL_UNIT,
  PRODUCT_DOCUMENT,
  PRODUCT_PICTURE,
  PRODUCT_VIDEO,
  SHARING_UNIT,
} from 'constants/fields';
import { getMyFields, getOrganizationId } from 'core/api/user';
import { getDisputedFields } from 'modules/contribution/selectors';
import {
  selectDefaultDisplayGroups,
  selectNonSpecificFieldsDisplayGroups,
} from 'modules/display-groups/selectors';
import { TEXTILE_ONLY_FIELDS } from 'modules/product-page/modules/textile/constants';
import {
  selectIsPhysicalChecker,
  selectIsRetailer,
  selectIsThirdParty,
  selectUser,
} from 'modules/user';
import {
  selectIsConsumerUnit,
  selectIsDisplayUnit,
  selectIsHeterogeneousLogisticalUnit,
  selectIsLogisticalUnit,
  selectProductVersion,
} from 'reducers/productVersion';
import { get } from 'utils/immutable';

import { mandatoryDisplayUnitFields, mandatoryFields } from '../constants';
import {
  excludeFilter,
  filterDisplayGroups,
  physicalCheckerFilter,
  viewAsByTypeFilter,
} from '../utils';

import {
  selectFilterRuleSetsFields,
  selectRecipients,
  selectRuleSets,
  selectSelectedRecipients,
  selectState,
} from '.';

const initFields = () =>
  Map({
    [CONSUMER_UNIT]: Set(),
    [LOGISTICAL_UNIT]: Set(),
    [SHARING_UNIT]: Map(),
    [DISPLAY_UNIT]: Set(),
    [PRODUCT_PICTURE]: Set(),
    [PRODUCT_DOCUMENT]: Set(),
    [PRODUCT_VIDEO]: Set(),
  });

const getRecipientsFieldsByType = (type, recipients) => {
  if (type === SHARING_UNIT) {
    return recipients
      .toMap()
      .mapEntries(([, r]) => [
        r.get('id'),
        (get(r, ['fields', type]) || Set()).toSet(),
      ]);
  }
  return recipients
    .map((r) => get(r, ['fields', type]) || List())
    .flatten()
    .toSet();
};

const reduceRuleSetsSharingUnitFields = (dict, fields, orgId) =>
  dict.update(orgId, (names) => Set(names).union(Set(fields)));

const reduceRuleSetsFields = (dict, fields, entityType) => {
  if (entityType === SHARING_UNIT) {
    return dict.update(SHARING_UNIT, (suFields) =>
      Map(fields).reduce(reduceRuleSetsSharingUnitFields, suFields),
    );
  }
  return dict.update(entityType, (names) => Set(names).union(Set(fields)));
};

const filterSharingUnitFields = (
  fields,
  ruleSetsFields,
  alwaysVisibleFields,
  bypassRuleSets,
) => {
  let dict = Map(fields);

  if (!bypassRuleSets && dict.get(SHARING_UNIT).isEmpty()) {
    dict = dict.set(SHARING_UNIT, Map(ruleSetsFields.get(SHARING_UNIT)));
  }

  return dict.update(SHARING_UNIT, (suFields) => {
    let fieldNames = suFields;
    if (!bypassRuleSets) {
      fieldNames = fieldNames.map((names, orgId) =>
        Set(names).filter((name) =>
          Set(ruleSetsFields.getIn([SHARING_UNIT, `${orgId}`])).includes(name),
        ),
      );
    }
    return fieldNames.map((names) => names.union(Set(alwaysVisibleFields)));
  });
};

const filterFields = curry(
  (
    {
      entityType,
      ruleSetsFields,
      alwaysVisibleFields,
      extraFields,
      withNoneIfEmpty,
      bypassRuleSets,
    },
    fields,
  ) => {
    if (entityType === SHARING_UNIT) {
      return filterSharingUnitFields(
        fields,
        ruleSetsFields,
        alwaysVisibleFields,
        bypassRuleSets,
      );
    }

    let dict = Map(fields);

    if (!bypassRuleSets && Set(dict.get(entityType)).isEmpty()) {
      dict = dict.set(entityType, Set(ruleSetsFields.get(entityType)));
    }

    dict = dict.update(entityType, (names) => {
      let fieldNames = Set(names);
      if (!bypassRuleSets) {
        fieldNames = fieldNames.filter((name) =>
          Set(ruleSetsFields.get(entityType)).includes(name),
        );
      }
      return fieldNames.union(Set(alwaysVisibleFields)).union(Set(extraFields));
    });

    if (!bypassRuleSets && withNoneIfEmpty && dict.get(entityType).isEmpty()) {
      dict = dict.set(entityType, Set(['__none__']));
    }

    return dict;
  },
);

export const filterAllFields = (
  fields,
  ruleSets,
  alwaysVisibleFields = Map(),
  bypassRuleSets,
) => {
  let ruleSetsFields = initFields();

  if (!bypassRuleSets) {
    let selectedRuleSets = ruleSets.filter((r) => r.get('checked'));
    if (selectedRuleSets.size === 0) {
      // If none is selected, consider them all selected
      selectedRuleSets = ruleSets;
    }

    ruleSetsFields = selectedRuleSets.reduce(
      (dict, ruleSet) =>
        Map(ruleSet.get('fields')).reduce(reduceRuleSetsFields, dict),
      ruleSetsFields,
    );
  }

  return flow(
    filterFields({
      entityType: CONSUMER_UNIT,
      ruleSetsFields,
      alwaysVisibleFields: alwaysVisibleFields.get(CONSUMER_UNIT),
      extraFields: mandatoryFields,
      bypassRuleSets,
    }),
    filterFields({
      entityType: SHARING_UNIT,
      ruleSetsFields,
      alwaysVisibleFields: alwaysVisibleFields.get(SHARING_UNIT),
      bypassRuleSets,
    }),
    filterFields({
      entityType: DISPLAY_UNIT,
      ruleSetsFields,
      alwaysVisibleFields: alwaysVisibleFields.get(CONSUMER_UNIT),
      extraFields: mandatoryDisplayUnitFields,
      withNoneIfEmpty: true,
      bypassRuleSets,
    }),
    filterFields({
      entityType: LOGISTICAL_UNIT,
      ruleSetsFields,
      extraFields: mandatoryFields,
      withNoneIfEmpty: true,
      bypassRuleSets,
    }),
    filterFields({
      entityType: PRODUCT_PICTURE,
      ruleSetsFields,
      alwaysVisibleFields: alwaysVisibleFields.get(PRODUCT_PICTURE),
      bypassRuleSets,
    }),
    filterFields({
      entityType: PRODUCT_DOCUMENT,
      ruleSetsFields,
      alwaysVisibleFields: alwaysVisibleFields.get(PRODUCT_DOCUMENT),
      bypassRuleSets,
    }),
    filterFields({
      entityType: PRODUCT_VIDEO,
      ruleSetsFields,
      alwaysVisibleFields: alwaysVisibleFields.get(PRODUCT_VIDEO),
      bypassRuleSets,
    }),
  )(fields);
};

const selectAlwaysVisibleFields = createSelector(selectState, (state) =>
  state.get('alwaysVisibleFields'),
);

export const getAvailableFields = (
  recipients,
  selectedRecipients,
  filterRuleSetsFields,
  ruleSets,
  alwaysVisibleFields,
  isThirdParty,
  isRetailer,
  user,
) => {
  let filteredFields = initFields().withMutations((fields) => {
    if (isRetailer || isThirdParty) {
      const myFields = getMyFields(user);
      const orgId = getOrganizationId(user);

      myFields.forEach((value, key) => {
        if (key === SHARING_UNIT) {
          fields.setIn([SHARING_UNIT, orgId], value);
        } else {
          fields.set(key, value);
        }
      });
    } else if (selectedRecipients.size > 0) {
      fields.forEach((value, key) => {
        fields.set(key, getRecipientsFieldsByType(key, selectedRecipients));
      });
    } else {
      fields.forEach((value, key) => {
        fields.set(key, getRecipientsFieldsByType(key, recipients));
      });
    }
  });

  if (!isThirdParty) {
    filteredFields = filterAllFields(
      filteredFields,
      ruleSets,
      alwaysVisibleFields,
      !filterRuleSetsFields,
    );
  }

  return filteredFields;
};

export const getAllAvailableFieldsForProductType = (
  recipients,
  ruleSets,
  isConsumerUnit,
  isDisplayUnit,
  isLogisticalUnit,
  alwaysVisibleFields,
  isThirdParty,
  isRetailer,
  user,
) => {
  let type;
  if (isConsumerUnit) {
    type = CONSUMER_UNIT;
  }
  if (isDisplayUnit) {
    type = DISPLAY_UNIT;
  } else if (isLogisticalUnit) {
    type = LOGISTICAL_UNIT;
  }

  // Get all fields without filters
  const selectedRecipients = Map();
  const filterRuleSetsFields = false;

  return get(
    getAvailableFields(
      recipients,
      selectedRecipients,
      filterRuleSetsFields,
      ruleSets,
      alwaysVisibleFields,
      isThirdParty,
      isRetailer,
      user,
    ),
    [type],
  );
};

export const selectAvailableFields = createSelector(
  selectRecipients,
  selectSelectedRecipients,
  selectFilterRuleSetsFields,
  selectRuleSets,
  selectAlwaysVisibleFields,
  selectIsThirdParty,
  selectIsRetailer,
  selectUser,
  getAvailableFields,
);

// This selector is needed and do not reuse selectAvailableFields
// because we want to retrieve all available fields
// without any filter applied and as if show additionnal field was toggled
export const selectAllAvailableFieldsForProductType = createSelector(
  selectRecipients,
  selectRuleSets,
  selectIsConsumerUnit,
  selectIsDisplayUnit,
  selectIsLogisticalUnit,
  selectAlwaysVisibleFields,
  selectIsThirdParty,
  selectIsRetailer,
  selectUser,
  getAllAvailableFieldsForProductType,
);

export const selectLogisticalUnitFields = createSelector(
  selectAvailableFields,
  (fields) => get(fields, [LOGISTICAL_UNIT]),
);

// DISPLAY GROUPS
export const getFilteredDisplayGroups = (
  isThirdParty,
  isPhysicalChecker,
  displayGroups = [],
  isConsumerUnit,
  isDisplayUnit,
  isLogisticalUnit,
  allFields,
  productVersion,
  disputedFields,
  isHeterogeneousLogisticalUnit = false,
) => {
  let defaultType;
  if (isConsumerUnit) {
    defaultType = CONSUMER_UNIT;
  }
  if (isDisplayUnit) {
    defaultType = DISPLAY_UNIT;
  } else if (isLogisticalUnit) {
    defaultType = LOGISTICAL_UNIT;
  }
  const filters = [viewAsByTypeFilter(allFields, defaultType)];
  if (isHeterogeneousLogisticalUnit) {
    filters.push(excludeFilter({ fields: TEXTILE_ONLY_FIELDS }));
  }
  if (isPhysicalChecker) {
    filters.push(
      physicalCheckerFilter({
        productVersion,
        disputedFields,
      }),
    );
  }
  if (filters) {
    return filterDisplayGroups(filters, displayGroups);
  }
  return displayGroups;
};

export const selectFilteredDisplayGroups = createSelector(
  selectIsThirdParty,
  selectIsPhysicalChecker,
  selectDefaultDisplayGroups,
  selectIsConsumerUnit,
  selectIsDisplayUnit,
  selectIsLogisticalUnit,
  selectAvailableFields,
  selectProductVersion,
  getDisputedFields,
  selectIsHeterogeneousLogisticalUnit,
  getFilteredDisplayGroups,
);

export const getFilteredNonSpecificDisplayGroups = (
  displayGroups,
  isConsumerUnit,
  isDisplayUnit,
  allFields,
  isHeterogeneousLogisticalUnit = false,
) => {
  if (isConsumerUnit) {
    return filterDisplayGroups([viewAsByTypeFilter(allFields)], displayGroups);
  } else if (isDisplayUnit) {
    return filterDisplayGroups(
      [viewAsByTypeFilter(allFields, DISPLAY_UNIT)],
      displayGroups,
    );
  } else if (isHeterogeneousLogisticalUnit) {
    return filterDisplayGroups(
      [excludeFilter({ fields: TEXTILE_ONLY_FIELDS })],
      displayGroups,
    );
  }
  return displayGroups;
};

export const selectFilteredNonSpecificFieldsDisplayGroups = createSelector(
  selectNonSpecificFieldsDisplayGroups,
  selectIsConsumerUnit,
  selectIsDisplayUnit,
  selectAvailableFields,
  selectIsHeterogeneousLogisticalUnit,
  getFilteredNonSpecificDisplayGroups,
);

export const selectDisplayGroups = (state) => {
  if (selectIsRetailer(state)) {
    return selectFilteredNonSpecificFieldsDisplayGroups(state);
  }

  return selectFilteredDisplayGroups(state);
};

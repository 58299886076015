import classNames from 'classnames';
import { ReactNode, useRef, useState } from 'react';

import { track } from 'utils/tracking';

import { MaturityPanel, MaturityPanelTabIndex } from '../maturity-panel';
import type { MaturitiesSummary } from '../types';

import styles from './index.module.scss';

interface MaturityPanelManagerProps {
  maturitiesSummary: MaturitiesSummary;
  selectedTab: MaturityPanelTabIndex;
  children: ReactNode;
  rowIdentifier?: number;
}

export function MaturityPanelManager({
  rowIdentifier,
  maturitiesSummary,
  selectedTab,
  children,
}: MaturityPanelManagerProps) {
  const ref = useRef<HTMLDivElement>(null);
  const [isPanelVisible, setPanelVisibilityTo] = useState<boolean>(false);

  const trackDisplayMaturitiesInfo = (displayType: string) => {
    track({
      category: 'product',
      action: 'display_scenarios_info_on_catalog',
      modal_type: displayType,
    });
  };

  const openPanel = () => {
    trackDisplayMaturitiesInfo('panel');
    setPanelVisibilityTo(true);
  };

  const closePanel = () => {
    setPanelVisibilityTo(false);
  };

  return (
    <>
      <div
        className={classNames(
          styles.maturityPanelManager,
          'alk-flex alk-flex-center alk-flex-justify-center',
          isPanelVisible && styles.maturityPanelManager__clicked,
        )}
        ref={ref}
        onClick={openPanel}
      >
        {children}
      </div>
      {isPanelVisible && (
        <MaturityPanel
          cellRef={ref}
          rowIdentifier={rowIdentifier}
          onClose={closePanel}
          data={maturitiesSummary}
          selectedTab={selectedTab}
        />
      )}
    </>
  );
}

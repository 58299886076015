import classNames from 'classnames';
import { Map } from 'immutable';
import memoize from 'memoize-one';
import { ReactNode } from 'react';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import { createStructuredSelector } from 'reselect';

import { Tipster } from '@alkem/react-ui-tipster';
import Tooltip from '@alkem/react-ui-tooltip';

import Field from 'components/ui/form/field';
import { TypePackagingLabels } from 'constants/typePackaging';
import {
  AMBIGOUS_UNIT,
  CONSUMER_UNIT,
  DISPLAY_UNIT,
  HETEROGENEOUS_UNIT,
  LOGISTICAL_UNIT,
} from 'constants/unitTypes';
import { SELECTED_HETEROGENEOUS_UNIT } from 'modules/display-groups/constants';
import { RELEASE_NEW_PRODUCT_TYPE_ICONS } from 'modules/feature-flag';
import { selectHasThirdPartyPhysicalCheckStatus } from 'modules/feature-flag/selectors';
import { selectRFPs } from 'modules/product-page/modules/rfps/selectors';
import { ProductRFPs, RfpData } from 'modules/product-page/modules/rfps/types';
import { selectSharingUnitsByRecipient } from 'modules/recipient-specific-block/selectors';
import { Statuses } from 'modules/sharing-unit-tariffs/constants';
import { isSharingUnitShared } from 'modules/sharing-units/utils/core';
import { selectFlags } from 'modules/user';
import {
  selectCurrentProductVersionGTIN,
  selectIsConsumerUnit,
  selectIsDespatchUnit,
  selectIsDisplayUnit,
  selectIsHeterogeneousLogisticalUnit,
  selectIsMadeOf,
  selectIsOriginalProductHeterogeneousUnit,
} from 'reducers/productVersion';
import { GlobalState } from 'types';
import i18n from 'utils/i18n';

import './FormTypePackaging.scss';
import {
  CanChangeProductTypeData,
  Features,
  OnTypePackagingChange,
  OnUnitTypeChange,
  RenderRfpSuLinkTooltip,
} from './FormTypePackaging.types';
import { TypePackagings } from './TypePackagings';
import { UnitTypes } from './UnitTypes';

interface ConnectedProps {
  isConsumerUnit: boolean;
  isDisplayUnit: boolean;
  isDespatchUnit?: boolean;
  isHetereogeneousUnit?: boolean;
  isOriginalProductHeterogeneousUnit: boolean;
  hasThirdPartyPhysicalCheckStatus?: boolean;
  gtin: string;
  sharingUnits: Map<string, any>;
  rfps: ProductRFPs[] | null;
  features?: Features;
  childProductsCount: number;
}

type Props = ConnectedProps;

type State = {
  isHeterogeneousUnitSelected: boolean;
};

export class FormTypePackaging extends Field<Props, State> {
  static defaultProps = {
    ...Field.defaultProps,
    hasThirdPartyPhysicalCheckStatus: false,
  };

  constructor(props) {
    super(props, { isHeterogeneousUnitSelected: props.isHetereogeneousUnit });
  }

  shouldComponentUpdate(nextProps, nextState) {
    const { isConsumerUnit, isDisplayUnit, isHetereogeneousUnit } = this.props;
    return (
      super.shouldComponentUpdate(nextProps, nextState) ||
      isConsumerUnit !== nextProps.isConsumerUnit ||
      isDisplayUnit !== nextProps.isDisplayUnit ||
      isHetereogeneousUnit !== nextProps.isHetereogeneousUnit ||
      this.state.isHeterogeneousUnitSelected !==
        nextState.heterogeneousUnitSelected
    );
  }

  computeHasSharedSU(sharingUnitsByRecipient) {
    if (!sharingUnitsByRecipient || sharingUnitsByRecipient.size === 0)
      return false;

    for (const sharingUnits of sharingUnitsByRecipient.values()) {
      for (const su of sharingUnits) {
        const template = su.get('template');
        if (isSharingUnitShared(su) && !template) return true;
      }
    }

    return false;
  }

  canChangeProductType(gtin, isReadOnly, sharingUnitsByRecipient, rfps) {
    let hasNoRfpSuLinks = true;
    let errorMessage: ReactNode | undefined = undefined;
    let hasLink = false;

    // If the field is as read-only (for a retailer for example),
    // there is not need to check if any rfp/sharing units are linked
    // and use grey icons
    if (isReadOnly) {
      return {
        hasNoRfpSuLinks,
        errorMessage,
        hasLink,
      };
    }

    const errorMessages: ReactNode[] = [];
    // Check sharing unit tariff
    if (sharingUnitsByRecipient && sharingUnitsByRecipient.size) {
      sharingUnitsByRecipient.forEach((suForRecipient) => {
        if (hasNoRfpSuLinks) {
          for (const su of suForRecipient) {
            // If a sharing unit has a template, it means it's a tariff
            const template = su.get('template');
            if (
              template &&
              ![Statuses.ARCHIVED.id].includes(template.get('status'))
            ) {
              hasNoRfpSuLinks = false;
              errorMessages.push(
                i18n.t(
                  'frontproductstream.packaging_type.change_type_notification.tariff_error',
                  {
                    defaultValue:
                      'This product is linked to a tariff. Please archive the tariff or remove the product from the tariff if you wish to change the product type of this product',
                  },
                ),
              );
              break;
            }
          }
        }
      });

      // Check if any shared sharing unit
      const hasSharedSU = this.computeHasSharedSU(sharingUnitsByRecipient);
      if (hasSharedSU) {
        hasNoRfpSuLinks = false;
        errorMessages.push(
          i18n.t(
            'frontproductstream.packaging_type.change_type_notification.listing_error',
            {
              defaultValue:
                'The product type cannot be changed as a listing has been shared for this product',
            },
          ),
        );
      }
    }

    // Check RFP
    if (rfps && rfps.length) {
      // Extract first RFP data
      let firstAnswerRfp: RfpData | undefined = undefined;
      for (const rfp of rfps) {
        for (const a of rfp.answers) {
          if (a.rfp && a.rfp.status !== 'CLOSED') {
            firstAnswerRfp = a.rfp;
            break;
          }
        }
        if (firstAnswerRfp) break;
      }

      if (firstAnswerRfp) {
        hasNoRfpSuLinks = false;
        hasLink = true;

        errorMessages.push(
          <>
            {i18n.t(
              'frontproductstream.packaging_type.change_type_notification.rfp_error',
              {
                defaultValue:
                  'This product is linked to a shared proposal to the RFP for the "{{eventName}}" and the unit of need "{{buName}}". Please delete the proposal if you wish to change the product type of this product :',
                eventName: Object.values(firstAnswerRfp.eventName)[0],
                buName: Object.values(firstAnswerRfp.buName)[0],
              },
            )}{' '}
            <Link
              to={`/rfp/${firstAnswerRfp.eventId}/bu/${firstAnswerRfp.buId}?search=${gtin}`}
            >
              {i18n.t(
                'frontproductstream.packaging_type.change_type_notification.view_rfp_proposal_error',
                { defaultValue: 'See the proposal' },
              )}
            </Link>
          </>,
        );
      }
    }

    if (errorMessages.length === 1) {
      errorMessage = errorMessages[0];
    } else if (errorMessages.length > 1) {
      errorMessage = (
        <ul style={{ listStyleType: 'none', paddingLeft: 0, marginBottom: 0 }}>
          {errorMessages.map((msg, i) => (
            <li style={{ paddingBottom: '4px' }} key={i}>
              {msg}
            </li>
          ))}
        </ul>
      );
    }

    return { hasNoRfpSuLinks, errorMessage, hasLink };
  }

  onUnitTypeChange: OnUnitTypeChange = (evt) => {
    this.changeUnitTypeTo(evt.target.value);
  };

  changeUnitTypeTo = (id: string) => {
    let isConsumerUnit = false;
    let isDisplayUnit = false;
    let isDespatchUnit = false;
    switch (parseInt(id)) {
      case CONSUMER_UNIT.id:
        isConsumerUnit = true;
        break;
      case DISPLAY_UNIT.id:
        isDisplayUnit = true;
        this.dispatchChangeWithModel('isBaseUnit', null);
        break;
      case LOGISTICAL_UNIT.id:
      case HETEROGENEOUS_UNIT.id:
        isDespatchUnit = true;
        this.dispatchChangeWithModel('isBaseUnit', null);
        break;
      default:
        break;
    }
    this.dispatchChangeWithModel('isDisplayUnit', isDisplayUnit);
    this.dispatchChangeWithModel('isConsumerUnit', isConsumerUnit);
    this.dispatchChangeWithModel('isDespatchUnit', isDespatchUnit);
  };

  onTypePackagingChange: OnTypePackagingChange = memoize(
    (typePackagingId, disabled) => () => {
      if (!disabled) {
        if (typePackagingId === TypePackagingLabels.EACH.id) {
          this.dispatchChangeWithModel('isBaseUnit', true);
        } else {
          this.dispatchChangeWithModel('isBaseUnit', false);
        }
        this.dispatchChangeWithModel('typePackaging', { id: typePackagingId });
      }
    },
  );

  getSelectedUnitTypeId = () => {
    const { isConsumerUnit, isDisplayUnit } = this.props;
    const { isHeterogeneousUnitSelected } = this.state;

    if (isDisplayUnit && isConsumerUnit) {
      return AMBIGOUS_UNIT.id;
    } else if (isDisplayUnit) {
      return DISPLAY_UNIT.id;
    } else if (isConsumerUnit) {
      return CONSUMER_UNIT.id;
    } else if (isHeterogeneousUnitSelected) {
      return HETEROGENEOUS_UNIT.id;
    }
    return LOGISTICAL_UNIT.id;
  };

  getCanChangeProductTypeData = (): CanChangeProductTypeData =>
    this.canChangeProductType(
      this.props.gtin,
      this.isReadOnly(),
      this.props.sharingUnits,
      this.props.rfps,
    );

  getAreUnitTypesDisabled = () =>
    this.isReadOnly() || !this.getCanChangeProductTypeData().hasNoRfpSuLinks;

  onUnitTypeClick = (typeId: number) => {
    const selectedUnitTypeId = this.getSelectedUnitTypeId();
    const areUnitTypesDisabled = this.getAreUnitTypesDisabled();
    if (!areUnitTypesDisabled && selectedUnitTypeId !== typeId) {
      const isHeterogeneousUnitSelected = typeId === HETEROGENEOUS_UNIT.id;
      this.props.dispatch({
        type: SELECTED_HETEROGENEOUS_UNIT,
        value: isHeterogeneousUnitSelected,
      });
      this.changeUnitTypeTo(typeId.toString());
      this.setState({
        isHeterogeneousUnitSelected,
      });
      if (isHeterogeneousUnitSelected) {
        this.onTypePackagingChange(
          TypePackagingLabels.CASE.id,
          areUnitTypesDisabled,
        )();
      }
    }
  };

  renderRfpSuLinkTooltip: RenderRfpSuLinkTooltip = (
    canChangeProductTypeData,
    id,
    isCurrentValue = false,
  ) => {
    /* No tooltip for the current value */
    return (
      !canChangeProductTypeData.hasNoRfpSuLinks &&
      !isCurrentValue && (
        <Tooltip
          id={id}
          key={id}
          place="bottom"
          hoverable={canChangeProductTypeData.hasLink}
          hoverableDelayHide={1500}
        >
          {canChangeProductTypeData.errorMessage}
        </Tooltip>
      )
    );
  };

  renderWarning(isReadOnly) {
    const {
      hasThirdPartyPhysicalCheckStatus,
      isConsumerUnit,
      isDisplayUnit,
      isHetereogeneousUnit,
      isDespatchUnit,
      childProductsCount,
    } = this.props;
    const { isHeterogeneousUnitSelected } = this.state;
    if (
      isReadOnly ||
      hasThirdPartyPhysicalCheckStatus ||
      isConsumerUnit ||
      isDisplayUnit
    ) {
      return null;
    }

    if (isHeterogeneousUnitSelected && !isHetereogeneousUnit) {
      return (
        <Tipster
          info={i18n.t(
            'frontproductstream.packaging_type.invalid_heterogenous.warning',
            {
              defaultValue:
                'You selected “Heterogeneous unit” but this product does not meet all requirements. It needs at least 2 "Consumer unit" child products.',
            },
          )}
          type="warning"
        />
      );
    }

    if (
      !isHeterogeneousUnitSelected &&
      isDespatchUnit &&
      childProductsCount > 1
    ) {
      return (
        <Tipster
          info={i18n.t(
            'frontproductstream.packaging_type.invalid_logistical.warning',
            {
              defaultValue:
                'You selected "Logistical unit" but this product does not meet all requirements. It needs only one "Consumer unit" child product.',
            },
          )}
          type="warning"
        />
      );
    }

    if (isHeterogeneousUnitSelected) return null;

    return (
      <Tipster
        info={i18n.t('frontproductstream.packaging_type.logistical.warning', {
          defaultValue:
            'If you select “Logistical unit” and save, this GTIN will become available to be added in the logistical hierarchy of another product and this page will not be accessible from the catalog anymore.',
        })}
        type="warning"
      />
    );
  }

  render() {
    const renderedLabel = this.renderLabel();

    const isReadOnly = this.isReadOnly();

    const features = this.props.features;

    const classes = {
      'col-xs-8': !!renderedLabel,
      'col-xs-8 FormTypePackaging__RowDisplay':
        !!renderedLabel &&
        isReadOnly &&
        features?.[RELEASE_NEW_PRODUCT_TYPE_ICONS],
      'col-xs-12': !renderedLabel,
    };

    const canChangeProductTypeData = this.getCanChangeProductTypeData();

    const selectedUnitTypeId = this.getSelectedUnitTypeId();

    const { renderRfpSuLinkTooltip } = this;

    return (
      <div
        className={classNames(
          this.getClasses({ 'InputField FormTypePackaging row': true }),
          {
            'FormField--raguelError': selectedUnitTypeId === AMBIGOUS_UNIT.id,
          },
        )}
      >
        {this.renderLabel('col-xs-4')}
        <div className={classNames(classes)}>
          <UnitTypes
            isReadOnly={isReadOnly}
            canChangeProductTypeData={canChangeProductTypeData}
            features={features}
            selectedUnitTypeId={selectedUnitTypeId}
            id={`${this.getId()}-unitType`}
            onChange={this.onUnitTypeChange}
            isHetereogeneousUnit={this.props.isHetereogeneousUnit}
            isHeterogeneousUnitSelected={this.state.isHeterogeneousUnitSelected}
            onUnitTypeClick={this.onUnitTypeClick}
            renderRfpSuLinkTooltip={renderRfpSuLinkTooltip}
          />
          <TypePackagings
            isReadOnly={isReadOnly}
            canChangeProductTypeData={canChangeProductTypeData}
            valueId={this.props.value.id}
            features={features}
            selectedUnitTypeId={selectedUnitTypeId}
            onTypePackagingChange={this.onTypePackagingChange}
            renderRfpSuLinkTooltip={renderRfpSuLinkTooltip}
          />
          {this.renderWarning(isReadOnly)}
          {this.renderPlugins()}
        </div>
      </div>
    );
  }
}

const mapStateToProps = createStructuredSelector<GlobalState, ConnectedProps>({
  gtin: selectCurrentProductVersionGTIN,
  isConsumerUnit: selectIsConsumerUnit,
  isDisplayUnit: selectIsDisplayUnit,
  isDespatchUnit: selectIsDespatchUnit,
  isHetereogeneousUnit: selectIsHeterogeneousLogisticalUnit,
  isOriginalProductHeterogeneousUnit: selectIsOriginalProductHeterogeneousUnit,
  hasThirdPartyPhysicalCheckStatus: selectHasThirdPartyPhysicalCheckStatus,
  sharingUnits: selectSharingUnitsByRecipient,
  rfps: selectRFPs,
  features: selectFlags,
  childProductsCount: (state) => selectIsMadeOf(state)?.length ?? 0,
});
export default connect<ConnectedProps, {}, {}, GlobalState>(mapStateToProps)(
  FormTypePackaging,
);

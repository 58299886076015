import { useDocumentForm } from 'components/ui/form/asset-list/AssetV2/hooks/useDocumentForm';
import AssetContextualFormGroup from 'components/ui/form/asset-list/actions/form-group';
import { ENTITY_TYPE_PRODUCT_DOCUMENT } from 'constants/entities';

const UNUSED_ENTITY_INDEX = -1;

type AssetContextualDocumentFormGroupWrapperProps = {
  formMethods: ReturnType<typeof useDocumentForm>['formMethods'];
};

export const AssetContextualDocumentFormGroupWrapper = ({
  formMethods,
}: AssetContextualDocumentFormGroupWrapperProps) => {
  const handleChange = (name, value) => {
    formMethods.setValue(name, value, { shouldDirty: true });
  };

  const allFormInputs = formMethods.watch();

  return (
    <AssetContextualFormGroup
      entity={allFormInputs}
      controller="document"
      onChangeField={handleChange}
      entityIndex={UNUSED_ENTITY_INDEX}
      ignoreFieldNames={['tags', 'documentTypeCode', 'url']}
      entityKind={ENTITY_TYPE_PRODUCT_DOCUMENT}
      noDispatch={false}
    />
  );
};

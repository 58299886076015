import PropTypes from 'prop-types';
import { Component } from 'react';

import MissingImage from 'images/missing.png';

export class Image extends Component {
  static propTypes = {
    src: PropTypes.string.isRequired,
    fallbackSrc: PropTypes.string,
    width: PropTypes.string,
    height: PropTypes.string,
    maxWidth: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    maxHeight: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    className: PropTypes.string,
    statusHandler: PropTypes.func,
    alt: PropTypes.node,
  };

  static defaultProps = {
    maxWidth: '100%',
    maxHeight: '100%',
    fallbackSrc: null,
    className: '',
    statusHandler() {},
    alt: null,
  };

  constructor(props) {
    super(props);
    this.state = {
      displayFallback: false,
      displayMissingImage: false,
    };
  }

  shouldComponentUpdate(nextProps, nextState) {
    const { displayMissingImage } = nextState.displayMissingImage;
    if (displayMissingImage !== this.state.displayMissingImage) {
      return true;
    }
    return Object.keys(nextProps).some(
      (key) => nextProps[key] !== this.props[key],
    );
  }

  componentDidUpdate(prevProps) {
    const { src } = this.props;
    if (prevProps.src !== src) {
      this.setState({ displayMissingImage: false, displayFallback: false }); // eslint-disable-line
    }
  }

  handleError = () => {
    const { displayFallback } = this.state;
    const shouldDisplayFallback =
      !displayFallback && this.props.fallbackSrc !== null;
    this.setState({
      displayMissingImage: !shouldDisplayFallback,
      displayFallback: shouldDisplayFallback,
    });
  };

  handleSuccess = () => {
    if (this.state.displayMissingImage || this.state.displayFallback) {
      this.props.statusHandler(false);
    } else {
      this.props.statusHandler(true);
    }
  };

  convertNumber(value) {
    if (typeof value === 'number' || parseInt(value, 10).toString() === value) {
      return `${value}px`;
    }
    return value;
  }

  render() {
    const {
      width,
      height,
      maxWidth,
      maxHeight,
      className,
      src,
      fallbackSrc,
      alt,
    } = this.props;
    const { displayMissingImage, displayFallback } = this.state;

    const styles = {
      maxHeight: this.convertNumber(maxHeight),
      maxWidth: this.convertNumber(maxWidth),
    };

    let imgSrc;
    if (displayMissingImage) {
      imgSrc = MissingImage;
    } else if (displayFallback) {
      imgSrc = fallbackSrc;
    } else {
      imgSrc = src;
    }

    if (alt && (displayFallback || displayMissingImage)) {
      return alt;
    }

    return (
      <img
        src={imgSrc}
        width={width}
        height={height}
        style={styles}
        onLoad={this.handleSuccess}
        onError={this.handleError}
        className={className}
        alt=""
      />
    );
  }
}

export default Image;

import {
  TargetProductStatusAccepted,
  TargetProductStatusNotAssigned,
  TargetProductStatusPendingAssignation,
  TargetProductStatusRefused,
  TargetProductStatusSubmitted,
  TargetProductStatusUpdated,
  TargetProductStatusWaitingToBeShared,
} from 'constants/targetProductStatus';

export const preShareProductStatus = [
  TargetProductStatusNotAssigned.id,
  TargetProductStatusPendingAssignation.id,
  TargetProductStatusWaitingToBeShared.id,
];

export const postShareProductStatus = [
  TargetProductStatusRefused.id,
  TargetProductStatusSubmitted.id,
  TargetProductStatusUpdated.id,
  TargetProductStatusAccepted.id,
];

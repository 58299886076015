import ReduxSaga from 'redux-saga';

const sagas = [];
let isReady = false;

export const sagaMiddleware =
  typeof ReduxSaga.default === 'function' ? ReduxSaga.default() : ReduxSaga();

export const setSagaMiddlewareAsReady = () => {
  isReady = true;
  sagas.forEach((saga) => sagaMiddleware.run(saga));
};

export const registerSaga = (saga) => {
  if (!sagas.includes(saga)) {
    sagas.push(saga);
    if (isReady) {
      sagaMiddleware.run(saga);
    }
  }
};

import classNames from 'classnames';
import { ReactNode } from 'react';

import './table.scss';

interface Props {
  children: ReactNode;
  className?: string;
}

export const Table = ({ children, className }: Props) => (
  <table className={classNames('GenericTable', className)}>{children}</table>
);

export const Header = ({ children, className }: Props) => (
  <thead className={classNames('GenericTable__header', className)}>
    <tr>{children}</tr>
  </thead>
);

export const Body = ({ children, className }: Props) => (
  <tbody className={classNames('GenericTable__body', className)}>
    {children}
  </tbody>
);

export const Row = ({ children, className }: Props) => (
  <tr className={classNames('GenericTable__row', className)}>{children}</tr>
);

interface CellProps {
  children?: ReactNode;
  className?: string;
  colSpan?: number;
}

export const Cell = ({ children, className, colSpan }: CellProps) => (
  <td
    colSpan={colSpan || 1}
    className={classNames('GenericTable__cell', className)}
  >
    {children}
  </td>
);

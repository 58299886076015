import { ResponseWithBody } from '@alkem/sdk-dashboard';

import onboardingApi from 'resources/onboarding';
import { FilterAggregation } from 'types/filters';

import { Field, Supplier, SupplierStatus } from './types';

export const listSuppliers = async (body: {
  organizationId: number;
  limit?: number;
  offset?: number;
  search?: string;
  statuses?: SupplierStatus[];
  with_archived?: boolean;
  waves?: string[];
  classifications?: string[];
  aggregations?: any;
  with_matches_names?: boolean;
  sort_order?: string;
  sort_by?: string;
}): Promise<{
  suppliers: Supplier[];
  aggregations: { [key: string]: FilterAggregation[] };
  totalResults: number;
}> => {
  const response = await onboardingApi.post<
    any,
    ResponseWithBody<{
      data: Supplier[];
      aggregations: { [key: string]: FilterAggregation[] };
      totalResults: number;
    }>
  >('/onboarding/v1/suppliers/search', body);
  const { data, aggregations, totalResults } = response.data;
  return { suppliers: data, aggregations, totalResults };
};

export const getSupplier = async (
  id: number,
): Promise<{
  supplier: Supplier;
}> => {
  const response = await onboardingApi.get(`/onboarding/v1/suppliers/${id}`);
  const { data: supplier } = response.data;
  return { supplier };
};

export const getDisplayGroup = async (): Promise<Field> => {
  const response = await onboardingApi.get('/onboarding/v1/displayGroups');
  const { data: displayGroup } = response.data;
  return displayGroup;
};

export const getDisplayGroups = async (): Promise<{
  reconciliationKeysDisplayGroup: Field[];
  dataDisplayGroup: Field[];
}> => {
  const response = await onboardingApi.get<
    any,
    { data: { reconciliationKeys: Field[]; data: Field[] } }
  >('/onboarding/v1/displayGroups');
  const {
    reconciliationKeys: reconciliationKeysDisplayGroup,
    data: dataDisplayGroup,
  } = response.data;
  return { reconciliationKeysDisplayGroup, dataDisplayGroup };
};

export const createMatch = async (
  supplierId: number,
  organizationId: number,
) => {
  await onboardingApi.post(
    `/onboarding/v1/suppliers/${supplierId}/organization`,
    {
      organization_id: organizationId,
    },
  );
};

export const updateMatchStatus = async (
  supplierId: number,
  organizationId: number,
  action: string,
) => {
  await onboardingApi.post(
    `/onboarding/v1/suppliers/${supplierId}/organizations/${organizationId}`,
    {
      action,
    },
  );
};

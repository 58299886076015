import { get } from 'lodash';
import { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';

import { updateCurrentLanguage } from 'actions/productversion';
import Dropdown from 'components/ui/dropdown';
import prefs from 'constants/prefs';
import {
  forceEditableDisplayGroups,
  resetEditableDisplayGroups,
} from 'modules/display-groups/actions';
import { selectAreDisplayGroupsEditable } from 'modules/display-groups/selectors';
import FeatureProductDuplicationModal from 'modules/feature-flag/components/product-duplication/modal';
import {
  selectHasDuplicationFeature,
  selectHasMultipleLocales,
} from 'modules/feature-flag/selectors';
import {
  selectHasAnyProductUpdatePermission,
  selectIsLoggedAs,
} from 'modules/user';
import {
  selectAvailableLanguages,
  selectCanUpdateProduct,
  selectCurrentLanguage,
  selectIsConsumerUnit,
  selectIsDisplayUnit,
  selectProductGTINWithFallbackOnId,
  selectTargetMarketId,
} from 'reducers/productVersion';
import * as routes from 'routes';
import i18n from 'utils/i18n';
import { withQuery } from 'utils/query';
import { track } from 'utils/tracking';

import UpgradeLabel from './upgrade-label';

interface Props {
  isRetailer: boolean;
}

const MoreActions = ({ isRetailer }: Props) => {
  const dispatch = useDispatch();
  const [featureModalOpen, setFeatureModalOpen] = useState(false);
  const availableLanguages: any[] = useSelector(selectAvailableLanguages);
  const currentLanguage: any = useSelector(selectCurrentLanguage);
  const hasDuplicationEnabled: boolean = useSelector(
    selectHasDuplicationFeature,
  );
  const hasMultipleLocalesEnabled: boolean = useSelector(
    selectHasMultipleLocales,
  );
  const isConsumerUnit: boolean = useSelector(selectIsConsumerUnit);
  const isDisplayUnit: boolean = useSelector(selectIsDisplayUnit);
  const hasAnyProductUpdatePermission: boolean = useSelector(
    selectHasAnyProductUpdatePermission,
  );
  const isLoggedAs: boolean = useSelector(selectIsLoggedAs);
  const areDisplayGroupsEditable: boolean = useSelector(
    selectAreDisplayGroupsEditable,
  );
  const canUpdateProduct: boolean = useSelector(selectCanUpdateProduct);
  const targetMarketId: number = useSelector(selectTargetMarketId);
  const productReference: number = useSelector(
    selectProductGTINWithFallbackOnId,
  );
  const navigate = useNavigate();

  if (
    (isRetailer && !hasMultipleLocalesEnabled) ||
    !get(prefs, 'product.header.moreActions')
  ) {
    return null;
  }

  const availableLanguagesOptions = availableLanguages.map((language) => ({
    label: (
      <div className="moreActions_label">
        <div className="moreActions_label_language">
          {language.description || language.label || language.normalizedCode}
        </div>
        <div className="moreActions_label_language_code">
          {hasMultipleLocalesEnabled ? `(${language.code})` : ''}
        </div>
      </div>
    ),
    key: language.id,
    onClick: () => dispatch(updateCurrentLanguage(language)),
    ...(currentLanguage &&
      language.id === currentLanguage.id && { attrs: { disabled: true } }),
  }));
  const duplicateOption =
    !isRetailer &&
    hasAnyProductUpdatePermission &&
    get(prefs, 'features.maker.productCreate') &&
    (isConsumerUnit || isDisplayUnit)
      ? [
          {
            label: (
              <UpgradeLabel
                label={i18n.t('Duplicate')}
                upgradeRequired={!hasDuplicationEnabled}
              />
            ),
            attrs: {
              id: 'product-page-btn-duplicate',
            },
            onClick: () => {
              if (hasDuplicationEnabled) {
                navigate(
                  withQuery(routes.productCreate, {
                    copy: productReference,
                    tm: targetMarketId,
                  }),
                );
              } else {
                setFeatureModalOpen(true);
              }
            },
          },
        ]
      : [];
  const loggedAsOption =
    isLoggedAs && !canUpdateProduct
      ? [
          {
            label: areDisplayGroupsEditable
              ? 'Reset editable'
              : 'Make editable',
            attrs: { className: 'ProductHeaderActions__admin' },
            onClick: () => {
              if (areDisplayGroupsEditable) {
                dispatch(resetEditableDisplayGroups());
              } else {
                dispatch(forceEditableDisplayGroups());
              }
            },
          },
        ]
      : [];

  return (
    <>
      <Dropdown
        label={i18n.t('More')}
        options={[
          ...availableLanguagesOptions,
          ...duplicateOption,
          ...loggedAsOption,
        ]}
        rightDropdown
        onClickCallback={(event) => {
          track({
            category: 'product-page-tracking',
            action: 'more options',
            label: get(event, 'target.innerText'),
          });
        }}
      />
      {featureModalOpen && (
        <FeatureProductDuplicationModal
          onClose={() => setFeatureModalOpen(false)}
        />
      )}
    </>
  );
};

export default MoreActions;

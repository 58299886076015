import { i18n } from 'utils/i18n';

export const PRODUCTS_IS_REQUESTED = {
  label: i18n.t(
    'frontproductstream.constants.requested_product_status.requested_label',
    {
      defaultValue: 'Yes',
    },
  ),
  name: i18n.t(
    'frontproductstream.constants.requested_product_status.requested_name',
    {
      defaultValue: 'Product requested',
    },
  ),
  id: true,
};

export const PRODUCTS_IS_NOT_REQUESTED = {
  label: i18n.t(
    'frontproductstream.constants.requested_product_status.not_requested_label',
    {
      defaultValue: 'No',
    },
  ),
  name: i18n.t(
    'frontproductstream.constants.requested_product_status.not_requested_name',
    {
      defaultValue: 'Product not requested',
    },
  ),
  id: false,
};

export const PRODUCTS_RECIPIENT_NOT_RECONCILED = {
  label: i18n.t(
    'frontproductstream.constants.requested_product_status.recipient_not_reconciled',
    {
      defaultValue: 'Recipient not reconciled',
    },
  ),
  id: 'RECIPIENT_NOT_RECONCILED',
};

export const PRODUCTS_PENDING_ACCEPTANCE = {
  label: i18n.t(
    'frontproductstream.constants.requested_product_status.pending_acceptance',
    {
      defaultValue: 'Pending acceptance',
    },
  ),
  id: 'PENDING',
};

export const PRODUCTS_REQUEST_REFUSED = {
  label: i18n.t(
    'frontproductstream.constants.requested_product_status.request_refused',
    {
      defaultValue: 'Request refused',
    },
  ),
  id: 'REFUSED',
};

export const PRODUCTS_REQUEST_ACCEPTED = {
  label: i18n.t(
    'frontproductstream.constants.requested_product_status.request_accepted',
    {
      defaultValue: 'Request accepted',
    },
  ),
  id: 'ACCEPTED',
};

export const basicFilterStatusList = [
  PRODUCTS_IS_NOT_REQUESTED,
  PRODUCTS_IS_REQUESTED,
];
export const basicFilterStatusAdvancedList = [
  PRODUCTS_RECIPIENT_NOT_RECONCILED,
  PRODUCTS_PENDING_ACCEPTANCE,
  PRODUCTS_REQUEST_ACCEPTED,
  PRODUCTS_REQUEST_REFUSED,
];

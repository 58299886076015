export enum RuleApplicationStatus {
  UNKNOWN = -1,
  OK = 0,
  KO = 1,
  NOT_EXECUTED = 2,
  BYPASSED = 3,
  ERROR = 4,
}

export enum RuleSetType {
  REGULATORY = 'REGULATORY',
  STAGE_GATE = 'STAGE_GATE',
  MATURITY = 'MATURITY',
  OTHER = 'OTHER',
  DRAFT = 'DRAFT',
}

export enum RuleTemplateType {
  SUGGESTED_FROM_CLASSIFICATION_RULE = 'suggested_from_classification_rule',
  NORMALIZED_COMMENT = 'suggested',
  REQUIRED = 'required',
  SUGGESTED_PARSING_ALLERGENS = 'suggested_parsing_allergens',
  SUGGESTED_PARSING_PACKAGING = 'suggested_parsing_packaging',
  SUGGESTED_ML_VALIDATED = 'suggested_ml_validated',
  SUGGESTED_ML_REALTIME = 'suggested_ml_realtime',
  SUGGESTED_PARSING_ALLERGEN_CASE = 'suggested_parsing_allergen_case',
}

export const ruleEntities = {
  CONSUMER_UNIT: 0,
  SHARING_UNIT: 1,
  LOGISTICAL_UNIT: 2,
  LOGISTICAL_HIERARCHY: 3,
  DISPLAY_UNIT: 4,
  PRICE_WATERFALL: 5,
  LOGISTICAL_HIERARCHY_TOP_UNIT: 6,
  SHARING_UNITS: 7,
  TARIFF: 10,
  PRODUCT_PICTURE: 11,
  PRODUCT_DOCUMENT: 12,
  TEXTILE_VARIANT: 13,
  PRODUCT_VIDEO: 14,
  TARIFFS: 15,
  RFP_ANSWER: 16,
  PRODUCT_PICTURES: 17,
  PRODUCT_DOCUMENTS: 18,
  PRODUCT_VIDEOS: 19,
};

export enum RuleTemplateLabel {
  VALUE = 'suggested-value',
  SIMPLE = 'suggested-simple',
  CONCEPTUALIZED = 'suggested-conceptualized-list',
  LANG_DECLINABLE = 'suggested-language-declinable',
  MEASURABLE = 'suggested-measurable',
  ALLERGENTYPELIST = 'suggested-allergen-type-list',
  NOTABLEINGREDIENTTYPELIST = 'suggested-notableingredient-type-list',
  FULL_LIST = 'suggested-full-list',
  ALLERGENCASE = 'suggested-allergen-case',
  INTERNAL_TAXONOMY = 'suggested-internal-taxonomy',
}

export enum ValidationWorkflowType {
  NORMALIZED_COMMENT = 'normalizedComment',
  PATCH = 'patch',
  SUGGESTION = 'suggestion',
}

export const RESTRICTION_TYPE_BLOCKING = 1;

export const ISLABELEDBY_FMD_ID = 97;
export const KIND_FMD_ID = 579;
export const LECLERC_HIERARCHY_FMD_ID = 4465;

export const restrictionTypes = {
  REQUIRED: 0,
  BLOCKING: 1,
  SUGGESTED: 2,
  HELP: 3,
};

export const NEW_TO_OLD_FIELD_NAME_MAPPING = {
  pictureEffectiveStartDate: 'fileEffectiveStartDateTime',
  pictureEffectiveEndDate: 'fileEffectiveEndDateTime',
  pictureAngleVerticalCode: 'angleVertical',
  pictureAngleHorizontalCode: 'angleHorizontal',
  fileTypeCode: 'fileType',
  productFaceCode: 'productFace',
  fileContentTypeCode: 'contentType',
  webOptimizedTypeCode: 'webOptimizedType',
};

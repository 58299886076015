import { get, union } from 'lodash';

import { SearchApi } from '@alkem/sdk-dashboard';

import {
  exportableTagFilter,
  sourceProductStatusFilter,
  suppliersFilter,
  userLabelsFilter,
} from 'core/modules/list/constants/filters';
import { getUserLanguage as acceptLanguage } from 'utils/i18n';
import { toJsIfImmutable } from 'utils/immutable';

const options = {
  cacheEnabled: true,
  acceptLanguage,
};

export const searchApi = new SearchApi(options);

export const searchApiImmutable = new SearchApi({
  ...options,
  immutable: true,
});

export const createAdminSearchApi = () =>
  new SearchApi({ ...options, useAdminToken: true });

export const fetchProductVersions = async ({
  queries = {},
  offset = 0,
  limit = 20,
  sort,
  sourceInclude = [],
  cache = true,
  withSourceInclude = true,
}: any = {}) => {
  try {
    const response = await searchApiImmutable.GetProductVersionList({
      queries: {
        ...queries,
        sourceInclude: withSourceInclude
          ? union(
              [
                'id',
                'product_key.id',
                'alkemics.icon_url',
                'alkemics.contribution',
                'alkemics.recipients',
                'alkemics.recipients.publicationSummary',
                'alkemics.recipients.validation.rules_by_status.failed',
                'alkemics.recipients.validation.rules_by_status.failedBlocking',
                'targetProductStatus',
                sourceProductStatusFilter.key,
                'isSharedFrom',
                'assignationEvents',
                'specializes.id',
                'isTaggedBy',
                'tags.contentOwner.id',
                'tags.targetMarket.id',
                'isConsumerUnit',
                'isDisplayUnit',
                'suppliers',
                userLabelsFilter.key,
                'assets.pictures.url',
                'hasDuplicated',
                'alkemics.information_request.supplier_id',
                'alkemics.information_request.requested_product_status',
                exportableTagFilter.key,
                suppliersFilter.key,
              ],
              toJsIfImmutable(sourceInclude),
            )
          : [],
      },
      sort,
      offset,
      limit,
      cache,
    });
    return {
      list: get(response, 'data.data'),
      total: get(response, 'data.totalResults'),
      aggregations: get(response, 'data.aggregations'),
    };
  } catch (error) {
    return { error };
  }
};

export const fetchSourcing = async ({
  queries = {},
  offset = 0,
  limit = 20,
  sort,
  sourceInclude = [],
  cache = true,
  withSourceInclude = true,
}: any = {}) => {
  try {
    const response = await searchApiImmutable.GetSourcingList({
      queries: {
        ...queries,
        sourceInclude: withSourceInclude
          ? union(
              [
                'id',
                'alkemics.icon_url',
                'owner.id',
                'specializes.isIdentifiedBy',
              ],
              toJsIfImmutable(sourceInclude),
            )
          : [],
      },
      sort,
      offset,
      limit,
      cache,
    });
    return {
      list: get(response, 'data.data'),
      total: get(response, 'data.totalResults'),
      aggregations: get(response, 'data.aggregations'),
    };
  } catch (error) {
    return { error };
  }
};

export const fetchAssignations = async ({
  queries = {},
  offset = 0,
  limit = 20,
  sort,
  cache = true,
}: any = {}) => {
  try {
    const response = await searchApiImmutable.GetAssignationList({
      queries,
      sort,
      offset,
      limit,
      cache,
    });
    return {
      list: get(response, 'data.data'),
      total: get(response, 'data.totalResults'),
      aggregations: get(response, 'data.aggregations'),
    };
  } catch (error) {
    return { error };
  }
};

export default searchApi;

import { BrandApi } from '@alkem/sdk-dashboard';

import { getUserLanguage as acceptLanguage } from 'utils/i18n';

const options = {
  cacheEnabled: true,
  acceptLanguage,
};

export default new BrandApi(options);
export const ImmutableBrandApi = new BrandApi({ ...options, immutable: true });

import { get } from 'lodash';

import { isDevelopment, isTest } from 'utils';

import { logToDatadogIfApplicable } from './tracking/datadog';

/**
 * Prints error, warning, trace messages to console in development or test.
 */
function logMessage(
  logFn: 'error' | 'warn' | 'trace',
  { force = false } = {},
  ...errors
) {
  if (isDevelopment() || isTest() || force) {
    const [firstError, ...otherErrors] = errors;
    // eslint-disable-next-line no-console
    console[logFn](
      get(firstError, 'stack') ||
        get(firstError, 'message') ||
        get(firstError, 'data.message') ||
        firstError,
      ...otherErrors,
    );
  }
  if (logFn === 'error') {
    logToDatadogIfApplicable(errors);
  }
}

export function logError(...args) {
  logMessage('error', { force: true }, ...args);
}
export function logWarning(...args) {
  logMessage('warn', {}, ...args);
}
export function logTrace(...args) {
  logMessage('trace', {}, ...args);
}

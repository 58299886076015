export const PRODUCT_VERSION_BULK_EDIT_INIT = 'product-version-bulk-edit/init';
export const PRODUCT_VERSION_BULK_EDIT_SELECT_DISPLAY_GROUP =
  'product-version-bulk-edit/select-display-group';
export const PRODUCT_VERSION_BULK_EDIT_DELETE_DISPLAY_GROUP =
  'product-version-bulk-edit/delete-display-group';
export const PRODUCT_VERSION_BULK_EDIT_SAVE = 'product-version-bulk-edit/save';
export const PRODUCT_VERSION_BULK_EDIT_CANCEL_SAVE =
  'product-version-bulk-edit/cancel-save';
export const PRODUCT_VERSION_BULK_EDIT_PROGRESS =
  'product-version-bulk-edit/in-progress';
export const PRODUCT_VERSION_BULK_EDIT_COMPLETE =
  'product-version-bulk-edit/complete';
export const PRODUCT_VERSION_BULK_EDIT_DISPLAY_GROUP_GET =
  'product-version-bulk-edit/get-display-group';
export const OPEN_PRODUCT_VERSION_BULK_EDIT = 'product-version-bulk-edit/open';
export const CLOSE_PRODUCT_VERSION_BULK_EDIT =
  'product-version-bulk-edit/close';
export const RESET_PRODUCT_VERSION_BULK_EDIT =
  'product-version-bulk-edit/reset';

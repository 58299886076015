import {
  roomOrganizationType,
  roomProductversionType,
  roomRFPType,
} from 'constants/message';
import { ROOM_TYPE_PRODUCTVERSION, ROOM_TYPE_RFP } from 'constants/room';
import { track } from 'utils/tracking';

const getParticipantLabel = (participant) => {
  const participantName = participant.get('acked_name');
  const organizationId = participant.get('organization_id');
  return `participant#${participantName}(org ${organizationId})`;
};

export const trackGtinClick = (roomType, action) => {
  const category =
    roomType === ROOM_TYPE_PRODUCTVERSION
      ? 'product'
      : roomType === ROOM_TYPE_RFP
        ? 'rfp'
        : roomType;
  track({
    category,
    action: `${roomType}_${action}`,
  });
};

export const trackAck = (room) => {
  const category =
    room.get('type') === roomOrganizationType
      ? 'organization'
      : room.get('type') === roomProductversionType
        ? 'product'
        : 'rfp';
  const entityId =
    room.get('type') !== roomOrganizationType ? room.get('entity_id') : null;
  const label =
    room.get('type') === roomOrganizationType
      ? getParticipantLabel(room.get('participant'))
      : room.get('type') === roomRFPType
        ? `rfp#${entityId}`
        : `product_version#${entityId}`;
  track({
    category,
    action: `${category}_message_acked`,
    room_id: room.get('id'),
    label: label,
  });
};

export const trackRFPComment = (room, roomId, message, businessUnitId) => {
  const recipientOrgId = room.getIn(['other_participant', 'organization_id']);
  track({
    category: 'rfp',
    action: 'rfp_commented',
    comment: message,
    room_id: roomId,
    business_unit_id: businessUnitId,
    recipient_organization_id: recipientOrgId,
  });
};

export const trackProductComment = (
  room,
  message,
  entityId,
  isNormalizedComment = false,
) => {
  const recipientOrgId = room.getIn(['other_participant', 'organization_id']);
  track({
    category: 'product',
    action: 'product_commented',
    comment: message,
    label: `product_version#${entityId}`,
    recipient_organization_id: recipientOrgId,
    isNormalizedComment,
  });
};

export const trackOrganizationMessageSent = (room, message) => {
  const recipientOrgId = room.getIn(['other_participant', 'organization_id']);
  track({
    category: 'organization',
    action: 'organization_message_sent',
    comment: message,
    recipient_organization_id: recipientOrgId,
  });
};

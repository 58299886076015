import { Map } from 'immutable';
import { memo } from 'react';

import { Diff, DiffImmutable } from 'types/diff';
import { textDirection } from 'utils/localization';

import './history-diff-line.scss';
import {
  DiffLabel,
  DiffValue,
  IsPartitionedByHistoryDiffLine,
  PriceWaterfallHistoryDiffLine,
} from './line';

type Props = {
  diff: Diff;
  cause?: object;
  withCurrent?: boolean;
  notHidden?: boolean;
  currentPosition?: 'middle' | 'last';
};

export const DefaultHistoryDiffLine = memo((props: Props) => {
  const { diff, cause, withCurrent, currentPosition = 'middle' } = props;

  let colSize = 4;
  if (withCurrent) {
    colSize = 3;
  }

  const dir = textDirection(diff.language);

  const currentCol = withCurrent && (
    <div
      className={`col-xs-${colSize} ProductHistoryDiffCard__new`}
      data-testid="diff-line-current-value"
      dir={dir}
    >
      <DiffValue
        value={diff.current}
        type={diff.type}
        className="ProductHistoryDiffCard__value"
      />
    </div>
  );

  return (
    <div className="ProductHistoryDiffCard__row row">
      <div className={`col-xs-${colSize} ProductHistoryDiffCard__key`}>
        <DiffLabel
          diff={diff}
          cause={cause}
          className="ProductHistoryDiffCard__label"
        />
      </div>
      <div
        className={`col-xs-${colSize} ProductHistoryDiffCard__old`}
        data-testid="diff-line-old-value"
        dir={dir}
      >
        <DiffValue
          value={diff.old}
          type={diff.type}
          className="ProductHistoryDiffCard__value"
        />
      </div>
      {currentPosition === 'middle' && currentCol}
      <div
        className={`col-xs-${colSize} ProductHistoryDiffCard__new`}
        data-testid="diff-line-new-value"
        dir={dir}
      >
        <DiffValue
          value={diff.new}
          type={diff.type}
          className="ProductHistoryDiffCard__value"
        />
      </div>
      {currentPosition === 'last' && currentCol}
    </div>
  );
});

type HistoryDiffLineProps = {
  diff: DiffImmutable;
  cause?: Map<string, any>;
  withCurrent?: boolean;
  notHidden?: boolean;
  currentPosition?: 'middle' | 'last';
};

export const HistoryDiffLine = memo(
  ({ diff, cause, ...props }: HistoryDiffLineProps) => {
    if (diff.get('type') === 'priceWaterfall') {
      return (
        <div className="ProductHistoryDiffCard__row">
          <PriceWaterfallHistoryDiffLine diff={diff} {...props} />
        </div>
      );
    } else if (
      diff.get('type') === 'fullComponent' &&
      (diff.get('name') === 'isPartitionedBy' ||
        diff.get('model').endsWith('isPartitionedBy'))
    ) {
      return (
        <div className="ProductHistoryDiffCard__row">
          <IsPartitionedByHistoryDiffLine diff={diff} {...props} />
        </div>
      );
    }
    return (
      <DefaultHistoryDiffLine
        diff={diff.toJS()}
        cause={cause?.toJS()}
        {...props}
      />
    );
  },
);

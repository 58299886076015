import { registerModuleReducer } from 'utils/modules/reducer';
import { registerSaga } from 'utils/modules/saga';

import reducers, { KEY } from './reducers';
import mainSaga from './sagas';

registerModuleReducer(KEY, reducers);
registerSaga(mainSaga);

export { UserLabelModal } from './components/modal';
export { ProductUserLabels } from './components/product-labels';
export { SAVE_USER_LABELS_DONE } from './actions/types';
export { showUserLabelModal } from './actions';
export { hasUserLabelManagementFeature } from './utils';

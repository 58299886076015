/* ProductSegments */
export const PRODUCT_SHOW = 'product.show';
export const PRODUCT_UPDATE = 'product.update';
export const PRODUCT_VALIDATE = 'product.validate';
export const PRODUCT_CERTIFY = 'product.certify';
export const PRODUCT_SHARE = 'product.certify';
export const PRODUCT_PATCH = 'product.patch';

export const SHARINGUNIT_SHOW = 'sharingunit.show';
export const SHARINGUNIT_UPDATE = 'sharingunit.update';

/* Organization */
export const RFP_USE = 'rfp.use';

/* CGUs */
export const CGU_TYPE_ALKEMICS = 0;
export const CGU_TYPE_GDSN = 1;
export const CGU_TYPE_PRODUCTDNA = 2;

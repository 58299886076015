import { extractValue } from './render';

export const enrichChildFields = function (field, index, excludeList = null) {
  // Enrich the relative condition models with the full base model.
  return field.children.map((f) => {
    const enrichedField = { ...f };
    if (
      enrichedField.filterApplicableCondition &&
      enrichedField.filterApplicableCondition.relativeSource &&
      enrichedField.filterApplicableCondition.conditionSource
    ) {
      enrichedField.filterApplicableCondition = {
        ...enrichedField.filterApplicableCondition,
      };
      const newConditionSource = `${field.model}.${index}.${enrichedField.filterApplicableCondition.conditionSource}`;
      enrichedField.filterApplicableCondition.conditionSource =
        newConditionSource;
    }
    enrichedField.model = `${field.model}.${index}.${f.model}`;
    if (excludeList) {
      if (field.options && field.options.noRepetition) {
        enrichedField.options = { ...enrichedField.options };
        enrichedField.options.noRepetition = true;
      }
      enrichedField.excludeList = excludeList;
    }
    return enrichedField;
  });
};

export const filterChildFields = function (
  entity,
  field,
  children,
  overrides = {},
) {
  // Filter out not applicable conditional fields.
  return children.filter((f) => {
    if (
      !Array.isArray(f.applicableConditionValues) ||
      !f.filterApplicableCondition ||
      !f.filterApplicableCondition.conditionSource
    ) {
      return true;
    }

    let currentCondition = null;
    const rootConditionSource =
      f.filterApplicableCondition.conditionSource.split('.')[0];
    if (rootConditionSource in overrides) {
      currentCondition = extractValue(
        overrides,
        f.filterApplicableCondition.conditionSource,
      );
    } else {
      currentCondition = extractValue(
        entity,
        f.filterApplicableCondition.conditionSource,
      );
    }

    if (f.applicableConditionValues.indexOf(currentCondition) !== -1) {
      return true;
    }
    return false;
  });
};

import { List, Map } from 'immutable';
import { put, select } from 'redux-saga/effects';

import { logError } from 'utils/logging';
import qs from 'utils/query';

import { selectRoute } from '../../menu/selectors';
import {
  replaceFilters,
  setCatalogContext,
  setCatalogUuid,
  updateSearchFromQuery,
  updateSelectedFilters,
  updateWithArchivedProducts,
} from '../actions';
import { selectReportingFilters, selectSearch } from '../selectors';

export function* initCatalogSaga({
  payload: { context, route, uuid, search },
}) {
  const previousRoute = yield select(selectRoute);
  yield put(setCatalogContext({ context, route }));
  try {
    const reportingFilters = yield select(selectReportingFilters);
    const searchQuery = yield select(selectSearch);
    const query = qs.parse(search);
    const hasSearchQueryWiped = !query.search && searchQuery;
    const isAlreadyBrowsingCatalog = previousRoute !== route;
    if (hasSearchQueryWiped && !isAlreadyBrowsingCatalog) {
      yield put(updateSearchFromQuery(''));
    }
    if (reportingFilters && query.reportingFilter) {
      const { withArchivedProducts, reportingFilter } = reportingFilters;
      let { filterList, filterMap } = reportingFilters;
      yield put(updateSelectedFilters(Map({ reset: true })));
      if (typeof withArchivedProducts === 'boolean') {
        yield put(updateWithArchivedProducts(withArchivedProducts));
      }
      filterList = List.isList(filterList) ? filterList : List();
      filterMap = Map.isMap(filterMap) ? filterMap : Map();
      if (reportingFilter) {
        filterList = filterList.push(reportingFilter);
      }
      if (!filterList.isEmpty()) {
        yield put(replaceFilters({ filterList, filterMap }));
      }
    }
  } catch (error) {
    logError(error);
  }
  yield put(setCatalogUuid(uuid));
}

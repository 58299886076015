import { useSelector } from 'react-redux';

import {
  LIST_IR_MODALS,
  MODAL_CHAT_RECIPIENT,
  MODAL_CHAT_SENT,
  MODAL_REFUSED_BY_RECIPIENT,
  MODAL_TO_RECONCILE,
} from '../constants';
import { selectIsModalOpen, selectTypeModal } from '../selectors';

import { ChatSentModal } from './chat-sent';
import { ChatWithRecipientsModal } from './chat-with-recipients';
import { FallbackInformationRequestModal } from './fallback';
import { InformationRequestReconcileModal } from './reconcile';
import { RefusedByRecipientModal } from './refused-by-recipients';

export const WrapperRequestsActionsModal = () => {
  const isOpen = useSelector(selectIsModalOpen);
  const typeModal = useSelector(selectTypeModal);

  if (!isOpen || !LIST_IR_MODALS.includes(typeModal)) {
    return null;
  }
  if (typeModal === MODAL_TO_RECONCILE) {
    return <InformationRequestReconcileModal />;
  } else if (typeModal === MODAL_REFUSED_BY_RECIPIENT) {
    return <RefusedByRecipientModal />;
  } else if (typeModal === MODAL_CHAT_RECIPIENT) {
    return <ChatWithRecipientsModal />;
  } else if (typeModal === MODAL_CHAT_SENT) {
    return <ChatSentModal />;
  }
  return <FallbackInformationRequestModal />;
};

import { List } from 'immutable';

import {
  CORRECTION_REQUESTED_BY_RECIPIENT,
  REJECTED_BY_RECIPIENT,
  SSC_SUCCESS_WARNING,
  SYNCHRONIZATION_ERROR,
  SYNCHRONIZATION_IN_PROGRESS,
} from './constants';

export function getPrioritizedStatus(statuses) {
  const statusesNotUndefined = statuses.filter((elem) => elem);
  if (statusesNotUndefined !== undefined) {
    const error = statusesNotUndefined.find(
      (elem) => elem.get('status') === SYNCHRONIZATION_ERROR.const,
    );
    if (error) {
      return error;
    }
    const rejected = statusesNotUndefined.find(
      (elem) => elem.getIn(['context', 'code']) === REJECTED_BY_RECIPIENT.const,
    );
    if (rejected) {
      return rejected;
    }
    const successWarning = statusesNotUndefined.find((elem) =>
      SSC_SUCCESS_WARNING.includes(elem.getIn(['context', 'code'])),
    );
    if (successWarning) {
      return successWarning;
    }
    const inProgress = statusesNotUndefined.find(
      (elem) => elem.get('status') === SYNCHRONIZATION_IN_PROGRESS.const,
    );
    if (inProgress) {
      return inProgress;
    }
    return statusesNotUndefined.last();
  }
  return undefined;
}

export function getPriorityErrorFromGDSNStatus(statuses) {
  const gdsnStatus = getPrioritizedStatus(
    statuses.filter((elem) => elem.get('format') === 'GDSN'),
  );
  const gdsnPriceStatus = getPrioritizedStatus(
    statuses.filter((elem) => elem.get('format') === 'GDSN_PRICE'),
  );

  if (
    gdsnStatus &&
    gdsnStatus.getIn(['context', 'code']) ===
      CORRECTION_REQUESTED_BY_RECIPIENT.const
  ) {
    return gdsnStatus;
  }

  const GDSNErrorstatuses = List([gdsnStatus, gdsnPriceStatus]);
  const inProgress = GDSNErrorstatuses.find(
    (elem) => elem && elem.get('status') === SYNCHRONIZATION_IN_PROGRESS.const,
  );
  if (inProgress) {
    return inProgress;
  }

  return getPrioritizedStatus(GDSNErrorstatuses);
}

export const compareStatusesDates = (status1, status2) =>
  new Date(status2.get('date')).valueOf() -
  new Date(status1.get('date')).valueOf();

import { FRANCE } from 'constants/targetMarkets';
import { getOrganizationTargetMarketIds } from 'core/api/user';
import { selectHasBrandFilterFeature } from 'modules/feature-flag/selectors';

export const brandsFilter = {
  key: 'brand.id',
  aggregation: { value_field: 'brand.label.raw' },
  query: 'brand',
  isAvailable: ({ user }) =>
    (getOrganizationTargetMarketIds(user).includes(FRANCE) &&
      getOrganizationTargetMarketIds(user).size === 1) ||
    selectHasBrandFilterFeature(user),
};

import { createSelector } from '@reduxjs/toolkit';
import { List, Map, fromJS } from 'immutable';

import { selectHierarchiesDiffs } from 'modules/logistical-hierarchies/selectors';
import { SHARING_UNIT_STATUS_AGREED } from 'modules/sharing-units/constants';

import { selectSharingunitsMap } from '../retailer-sharing-units/selectors';

const selectModuleState = (state) => state.module.productShareDiff;

export const selectConsumerUnitDiff = createSelector(
  selectModuleState,
  (state) => state.get('consumerUnitRequestsDiff'),
);

export const selectSharingUnitDiffs = createSelector(
  selectModuleState,
  selectHierarchiesDiffs,
  selectSharingunitsMap,
  (state, hierarchiesDiffs, sharingUnitsMap) => {
    // To replicate current behavior, append hierarchies diffs in corresponding sharing unit diffs.
    // Show diff for agreed sharingunits only
    const agreedSharingUnitsMap = sharingUnitsMap.filter(
      (sharingUnit) => sharingUnit.get('status') === SHARING_UNIT_STATUS_AGREED,
    );
    let allDiffs = Map();
    state
      .get('sharingUnitRequestsDiff')
      .entrySeq()
      .forEach(([suId, suDiff]) => {
        if (agreedSharingUnitsMap.get(suId)) {
          allDiffs = allDiffs.set(suId, suDiff);
        }
      });
    hierarchiesDiffs.forEach((lhDiff) => {
      const sharingUnit = agreedSharingUnitsMap
        .valueSeq()
        .find(
          (su) =>
            su.getIn(['data', 'hierarchyProduct', 'id']) === lhDiff.hierarchyId,
        );
      if (sharingUnit) {
        const suId = sharingUnit.get('id').toString();
        if (!allDiffs.get(suId)) {
          allDiffs = allDiffs.set(suId, fromJS({ diff: lhDiff.diff }));
        } else {
          allDiffs = allDiffs.setIn(
            [suId, 'diff'],
            allDiffs.getIn([suId, 'diff']).concat(fromJS(lhDiff.diff)),
          );
        }
      }
    });
    return allDiffs;
  },
);

/**
 *  New apply.
 * */

export const selectConsumerUnitTransactionIds = createSelector(
  selectModuleState,
  (state) => state.getIn(['transactionIds', 'consumerUnit']),
);

export const selectListingTransactionIds = createSelector(
  selectModuleState,
  (state) => state.getIn(['transactionIds', 'listing']),
);

// TODO fix naming (selectHasAppliedRequestsInProgress?)
export const selectApplyRequestsInProgress = createSelector(
  selectModuleState,
  (state) => state.get('acceptOrRefuseInProgress'),
);

export const selectTransactionIds =
  (consumerUnit, sharingUnitId) => (state) => {
    let transactionIds = List();
    if (consumerUnit) {
      transactionIds = transactionIds.concat(
        selectConsumerUnitTransactionIds(state),
      );
    }
    if (sharingUnitId !== null) {
      // Apply sharing unit and hierarchy transactions.
      transactionIds = transactionIds.concat(
        selectListingTransactionIds(state).get(sharingUnitId.toString()) ||
          List(),
      );
    }
    return transactionIds;
  };

import PropTypes from 'prop-types';
import { Component, createRef } from 'react';
import { Spinner } from 'spin.js';

import './styles.scss';

class ReactSpinner extends Component {
  static propTypes = {
    stopped: PropTypes.bool,
    // props directly passed into spin.js Spinner
    // (complete list on http://spin.js.org)
    lines: PropTypes.number,
    length: PropTypes.number,
    position: PropTypes.oneOf(['absolute', 'relative']),
    color: PropTypes.string,
    scale: PropTypes.number,
  };

  static defaultProps = {
    stopped: false,
    lines: 12,
    length: 0,
    position: 'absolute',
    color: '#ccc',
    scale: 0.75,
  };

  spinnerRef = createRef();

  componentDidMount() {
    const { stopped, ...props } = this.props;
    this.spinner = new Spinner(props);
    if (!stopped) {
      this.spinner.spin(this.spinnerRef.current);
    }
  }

  componentDidUpdate(prevProps) {
    const { stopped } = this.props;
    if (stopped === true && !prevProps.stopped) {
      this.spinner.stop();
    } else if (!stopped && prevProps.stopped === true) {
      this.spinner.spin(this.spinnerRef.current);
    }
  }

  componentWillUnmount() {
    this.spinner.stop();
  }

  render() {
    return (
      <div
        className="alkemics-spinner"
        data-testid="spinner"
        ref={this.spinnerRef}
      />
    );
  }
}

export default ReactSpinner;

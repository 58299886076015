import memoize from 'memoize-one';

import Autocomplete from 'components/ui/autocomplete';

export default class MaturityAutocomplete extends Autocomplete {
  static defaultProps = Object.assign({}, Autocomplete.defaultProps, {
    sort: 'alphabetical',
    searchOnClick: true,
    maturities: [],
  });

  getSelected = memoize((value) => value || []);

  getList(search) {
    let { maturities } = this.props;
    if (search) {
      const regexp = new RegExp(search, 'i');
      maturities = maturities.filter((maturity) => regexp.test(maturity.label));
    }
    this.updateList(maturities);
  }
}

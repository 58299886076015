/**
 ** Tag string constants
 ** See Referential id = 346 and corresponding ReferencialEntity(ies)
 */

// The data quality was validated by the owner
export const TAG_DATA_VALIDATED = 'datavalidated';

// The product has been marked to be exportable by the owner
export const TAG_EXPORTABLE = 'exportable';

// The product is NOT available for sourcing
export const TAG_IS_EXCLUDED_FROM_SOURCING = 'isexcludedfromsourcing';

// The product is published
export const TAG_PUBLISHED = 'published';

// The product has not been marked as public or exclusive
export const TAG_PENDING_EXCLUSIVITY = 'pending_exclusivity';

// The product has been marked as exclusive
export const TAG_EXCLUSIVE = 'exclusive';

import { List, fromJS } from 'immutable';
import { createReducer } from 'redux-create-reducer';

import {
  DELETE_PRODUCT_VERSION,
  RECEIVE_PRODUCT_KEY,
  RECEIVE_PRODUCT_VERSION,
} from 'constants/events/productversion';
import { getProductVersionId, getUserLabels } from 'core/api/productversion';

import {
  DELETE_USER_LABEL,
  DETACH_USER_LABEL_DONE,
  FETCH_LINKED_USERS_LABELS_DONE,
  SAVE_USER_LABELS_DONE,
  UPDATE_USER_LABEL,
} from '../actions/types';
import { UserLabel } from '../types';

const initialState = fromJS({
  labels: [],
  productVersionId: null,
  productId: null,
});

export default createReducer(initialState, {
  [UPDATE_USER_LABEL]: (state, { payload }) => {
    const labels = state
      .get('labels')
      .map((label: UserLabel) =>
        label.get('id') === payload.get('id')
          ? label.set('name', payload.get('name'))
          : label,
      );

    return state.set('labels', labels);
  },
  [FETCH_LINKED_USERS_LABELS_DONE]: (state, { payload }) =>
    state.set('labels', payload),
  [RECEIVE_PRODUCT_VERSION]: (state, { productVersion }) => {
    const userLabels = getUserLabels(productVersion);
    const productId = getProductVersionId(productVersion);
    let newState = state;
    if (userLabels && Array.isArray(userLabels)) {
      newState = newState.set('labels', fromJS(userLabels));
    }
    if (productId !== state.get('productVersionId')) {
      newState = newState.set('productVersionId', productId);
    }
    return newState;
  },
  [RECEIVE_PRODUCT_KEY]: (state, { payload }) =>
    state.set('productId', payload.productKey.id),
  [DELETE_PRODUCT_VERSION]: () => initialState,

  [SAVE_USER_LABELS_DONE]: (state, { payload }) => {
    const { attachedLabels, detachedLabels } = payload;
    if (List.isList(attachedLabels)) {
      return state.updateIn(['labels'], (list) =>
        list
          .concat(
            attachedLabels.filter(
              (attached) =>
                !list.some((label) => attached.get('id') === label.get('id')),
            ),
          )
          .filter(
            (label) =>
              !detachedLabels.some(
                (detached) => label.get('id') === detached.get('id'),
              ),
          ),
      );
    }
    return state;
  },
  [DETACH_USER_LABEL_DONE]: (state, { payload: userLabelIds }) => {
    if (List.isList(userLabelIds) && userLabelIds.size) {
      return state.updateIn(['labels'], (list) =>
        list.filter((label) => !userLabelIds.includes(label.get('id'))),
      );
    }
    return state;
  },
  [DELETE_USER_LABEL]: (state, { payload }) =>
    state.updateIn(['labels'], (list) =>
      list.filter((label) => label.get('id') !== payload.get('id')),
    ),
} as any);

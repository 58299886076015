import moment from 'moment';

import { hasFeature } from 'modules/feature-flag';
import { FEATURE_SCHEDULED_APPLICATION_DATE_FILTER } from 'modules/feature-flag/constants';
import { UserImmutable } from 'types';
import { i18n } from 'utils/i18n';
import { toJsIfImmutable } from 'utils/immutable';

import { Filter } from './types';

const key = 'alkemics.uEnseigne.uEnseigneScheduleApplicationDate';

const parseQueryValues = (value) => {
  try {
    return JSON.parse(decodeURIComponent(value));
  } catch {
    return value
      .split(',')
      .map(parseQueryValues)
      .filter((f) => f);
  }
};

export const scheduledApplicationDateFilter: Filter = {
  key,
  aggregation: {
    value_field: key,
  },
  filterLabel: i18n.t(
    'frontproductstream.core.list_filter_scheduled_application_date.label',
    { defaultValue: 'Scheduled application date' },
  ),
  query: key,
  isAvailable: ({ user }: { user?: UserImmutable } = {}) =>
    hasFeature(user, FEATURE_SCHEDULED_APPLICATION_DATE_FILTER),
  formatLabel: (value) => {
    if (value.gte) {
      return i18n.t(
        'frontproductstream.core.list_filter_scheduled_application_date.date_from_label',
        {
          defaultValue: 'Scheduled application date from {{date}}',
          date: moment(value.gte).format('L'),
        },
      );
    }
    if (value.lte) {
      return i18n.t(
        'frontproductstream.core.list_filter_scheduled_application_date.date_to_label',
        {
          defaultValue: 'Scheduled application date to {{date}}',
          date: moment(value.lte).format('L'),
        },
      );
    }
    return `${i18n.t(
      'frontproductstream.core.list_filter_scheduled_application_date.date_label',
      { defaultValue: 'Scheduled application date' },
    )} ${moment(value * 1000).format('L')}`;
  },
  formatQueryValues: (value) => {
    if (typeof value === 'object')
      return encodeURIComponent(JSON.stringify(toJsIfImmutable(value)));
    return value;
  },
  parseQueryValues,
  getTypeByValue: (value) => {
    if (value.gte || value.lte || value[0]?.gte || value[0]?.lte)
      return 'range';
    return 'match';
  },
};

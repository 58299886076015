// Actions
export const SET_CONFIRM_UNSYNC_MODAL_OPENED =
  'dashboardPublicationSummary/setConfirmUnsyncModalOpened';
export const SET_UNAVAILABLE_UNSYNC_MODAL_OPENED =
  'dashboardPublicationSummary/setUnavailableUnsyncModalOpened';

export const SET_LOADING_IN_PROGRESS =
  'dashboardPublicationSummary/setLoadingInProgress';
export const SET_TARGET_RECIPIENT =
  'dashboardPublicationSummary/setTargetRecipient';

// Sagas
export const UNSYNC = 'dashboardPublicationSummary/unsync';
export const CANCEL_UNSYNC = 'dashboardPublicationSummary/cancelUnsync';
export const SEND_RETAILER_MESSAGE =
  'dashboardPublicationSummary/sendRetailerMessage';

export const OPEN_CONFIRM_UNSYNC_MODAL =
  'dashboardPublicationSummary/openConfirmUnsyncModal';
export const OPEN_UNAVAILABLE_UNSYNC_MODAL =
  'dashboardPublicationSummary/openUnavailableUnsyncModal';

// Error constants
export const ERROR_PRODUCT_LINKED_TO_PUBLISHED_TARIFF =
  'PRODUCT_LINKED_TO_PUBLISHED_TARIFF';

import { notificationError } from 'actions/notification';
import { authApiImmutable } from 'resources/authApi';
import i18n from 'utils/i18n';
import { logError } from 'utils/logging';

import { receiveProductSegmentsPermissions } from './action-builders';

export const fetchProductSegmentPermissions = (userId) => async (dispatch) => {
  try {
    const {
      data: { data },
    } = await authApiImmutable.ListProductSegmentsPermissions(userId);
    dispatch(receiveProductSegmentsPermissions(data.get('productsegments')));
  } catch (error) {
    logError(error);
    dispatch(
      notificationError(
        i18n.t(
          'frontproductstream.user.fetch_product_segment_permissions_error.notification',
          {
            defaultValues:
              'An error occured while fetching product segments permissions',
          },
        ),
      ),
    );
  }
};

import classNames from 'classnames';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';

import Field from 'components/ui/form/field';
import Text from 'components/ui/input/text';

import './text.scss';

export class FormText extends Field {
  static propTypes = Object.assign({}, Field.propTypes, {
    type: PropTypes.string,
  });

  static defaultProps = Object.assign({}, Field.defaultProps, {
    type: 'text',
  });

  componentDidUpdate() {
    this.ensureDataIsPresent();
  }

  getValue(event) {
    const { value } = event.currentTarget;
    const { type } = this.props;
    if (type === 'number') {
      return value.replace(/,/g, '.');
    }
    return value;
  }

  render() {
    const { value, field, type } = this.props;
    if (!field) {
      return null;
    }

    const displayPlaceholder = this.shouldDisplayItem('placeholder');

    const content = (
      <Text
        id={this.getId()}
        type={type}
        onChange={this.handleChange}
        placeholder={displayPlaceholder ? field.placeholder : null}
        value={value}
        disabled={this.isReadOnly()}
        maxLength={type === 'number' ? 20 : 3000}
      />
    );

    const renderedLabel = this.renderLabel('col-xs-4');
    const classes = {
      FormText__input: true,
      'col-xs-8': !!renderedLabel,
      'col-xs-12': !renderedLabel,
    };

    return (
      <div className={classNames(this.getClasses({ 'FormText row': true }))}>
        {renderedLabel}
        <div className={classNames(classes)}>
          {content}
          {this.renderPlugins()}
        </div>
      </div>
    );
  }
}

export default connect()(FormText);

import { useController } from 'react-hook-form';

import { Tags } from '@alkem/react-ui-inputs';

import i18n from 'utils/i18n';

import { AssetInput } from './AssetInput';
import { ReadOnlyAssetInput } from './types';

export const TagsInput = ({ isReadOnly }: ReadOnlyAssetInput) => {
  const name = 'tags';

  const {
    field: { onChange, value },
  } = useController({ name });

  const matchTag = (tag) => {
    // Matches anything that has a non-whitespace character in it
    const re = /\S/;
    return re.test(tag);
  };

  return (
    <AssetInput
      childId="ProductPictureForm-tags"
      label={i18n.t('frontproducstream.asset_list.modal.tags.label', {
        defaultValue: 'Tags',
      })}
    >
      <Tags
        id="ProductPictureForm-tags"
        matchTag={matchTag}
        tags={value || []}
        onChange={onChange}
        disabled={isReadOnly}
      />
    </AssetInput>
  );
};

import PropTypes from 'prop-types';
import { PureComponent } from 'react';
import ImmutablePropTypes from 'react-immutable-proptypes';
import { connect } from 'react-redux';
import { createStructuredSelector } from 'reselect';

import * as contribution from 'modules/contribution';
import {
  createContribution,
  fetchStateMachine,
  updateStatus,
} from 'modules/contribution/actions';
import {
  getNextStatuses,
  isSelectionEligibleForPhysicalCheck,
} from 'modules/contribution/utils';
import { selectHasPhysicalCheckTriggerGateFeature } from 'modules/feature-flag/selectors';
import i18n from 'utils/i18n';
import { get, size } from 'utils/immutable';
import { separateActions } from 'utils/redux';

const mapDispatchToProps = {
  fetchStateMachine,
  createContribution,
  updateStatus,
};

const mapStateToProps = createStructuredSelector({
  checkStageGate: selectHasPhysicalCheckTriggerGateFeature,
  stateMachine: contribution.selectors.getStateMachine,
  contributionApplications:
    contribution.selectors.getActiveApplicationsTypeContribution,
});

class ContributionAction extends PureComponent {
  static propTypes = {
    selectedMap: ImmutablePropTypes.map.isRequired,
    productMap: ImmutablePropTypes.map.isRequired,
    checkStageGate: PropTypes.bool.isRequired,
    stateMachine: ImmutablePropTypes.map.isRequired,
    contributionApplications: ImmutablePropTypes.list.isRequired,
    actions: PropTypes.shape({
      fetchStateMachine: PropTypes.func.isRequired,
      createContribution: PropTypes.func.isRequired,
      updateStatus: PropTypes.func.isRequired,
    }).isRequired,
    disabled: PropTypes.bool,
  };

  static shouldBeDisplayed = () => true;

  componentDidMount() {
    this.props.actions.fetchStateMachine();
  }

  createContribution = (productversions, contributor) => () => {
    const { disabled } = this.props;
    if (disabled) {
      return;
    }
    productversions.map((pv) =>
      this.props.actions.createContribution(pv, contributor),
    );
  };

  updateStatus = (productVersions, status) => () => {
    const { disabled } = this.props;
    if (disabled) {
      return;
    }
    productVersions.map((pv) =>
      this.props.actions.updateStatus(
        pv.getIn(['alkemics', 'contribution', 'physicalChecker', 'id']),
        status,
      ),
    );
  };

  render() {
    const { selectedMap, productMap, stateMachine, contributionApplications } =
      this.props;
    if (!stateMachine.size) {
      return null;
    }
    const productVersions = selectedMap
      .map((isSelected, pvId) => productMap.get(pvId))
      .toList();
    const statuses = getNextStatuses(stateMachine, productVersions);
    // if null is returned we don't want to display anything
    if (!statuses) {
      return null;
    }
    // if empty map is returned no contribution we have to create one
    if (!statuses.size) {
      if (
        (this.props.checkStageGate &&
          !isSelectionEligibleForPhysicalCheck(productVersions)) ||
        size(contributionApplications) === 0
      ) {
        return null;
      }

      // We handle Only one Contribution for now
      const contributionApplication = get(contributionApplications, [0]);

      return (
        <div
          className="ActionOption"
          onClick={this.createContribution(
            productVersions,
            get(contributionApplication, ['organizationId']),
          )}
        >
          {i18n.t('Create contribution for these products')}{' '}
          {`(${get(contributionApplication, ['name'])})`}
        </div>
      );
    }
    return (
      <>
        {statuses.map((status) => (
          <div
            className="ActionOption"
            onClick={this.updateStatus(productVersions, status)}
            key={status.id}
          >
            {i18n.t('Update contribution status to')} {status.name}
          </div>
        ))}
      </>
    );
  }
}

export default connect(
  mapStateToProps,
  mapDispatchToProps,
  separateActions,
)(ContributionAction);

import classNames from 'classnames';
import { isFunction } from 'lodash';
import PropTypes from 'prop-types';
import { PureComponent } from 'react';

import { isValidEmail } from 'utils/validation';

import './email.scss';

class Email extends PureComponent {
  static propTypes = {
    className: PropTypes.string,
    id: PropTypes.string.isRequired,
    inputClassName: PropTypes.string,
    onAdd: PropTypes.func.isRequired,
    onInvalidEmail: PropTypes.func,
    onDelete: PropTypes.func.isRequired,
    label: PropTypes.string,
    labelWidth: PropTypes.number,
    placeholder: PropTypes.string,
    emails: PropTypes.arrayOf(
      PropTypes.oneOfType([
        PropTypes.string,
        PropTypes.shape({
          label: PropTypes.string,
          value: PropTypes.any,
        }),
      ]),
    ),
    readOnly: PropTypes.bool,
  };

  static defaultProps = {
    className: '',
    inputClassName: '',
    label: '',
    labelWidth: 4,
    emails: [],
  };

  constructor(props) {
    super(props);
    this.onChange = this.onChange.bind(this);
    this.onKeyPress = this.onKeyPress.bind(this);
    this.onBlur = this.onBlur.bind(this);
    this.state = { value: '' };
  }

  onDelete = (email, i) => () => {
    if (this.props.onDelete) {
      this.props.onDelete(i, email);
    }
  };

  onChange(event) {
    const { value } = event.target;
    const separators = [' ', ',', ';'];
    const lastChar = value.substr(value.length - 1);
    if (separators.indexOf(lastChar) !== -1) {
      this.validateEmail(value.substr(0, value.length - 1).toLowerCase());
    } else {
      this.setState({ value });
    }
  }

  onKeyPress(event) {
    const { value } = this.state;
    if (value && event.charCode === 13) {
      // on press enter
      this.validateEmail(value);
    }
  }

  onBlur() {
    const { value } = this.state;
    if (value) {
      this.validateEmail(value);
    }
  }

  validateEmail(value) {
    const { onAdd, onInvalidEmail } = this.props;
    if (isValidEmail(value)) {
      if (isFunction(onAdd)) {
        onAdd(value);
      }
      this.setState({ value: '' });
    } else {
      if (isFunction(onInvalidEmail)) {
        onInvalidEmail(value);
      }
      this.setState({ value });
    }
  }

  renderEmail() {
    const { emails, readOnly } = this.props;
    const classes = {
      InputEmail__label: true,
      'InputEmail__label--readOnly': readOnly,
    };
    return emails.map((email, i) => (
      <span className="InputEmail__email" key={email.label || email}>
        {typeof email === 'object' && (
          <span className="mdi-account-multiple mdi" />
        )}
        <span className={classNames(classes)}>{email.label || email}</span>
        {!readOnly && (
          <span
            className="InputEmail__delete"
            onClick={this.onDelete(email, i)}
          >
            X
          </span>
        )}
      </span>
    ));
  }

  render() {
    const { id, label, placeholder, labelWidth, emails, readOnly } = this.props;
    const { value } = this.state;
    const htmlId = `InputEmail-${id}`;

    return (
      <p
        className={classNames(
          this.props.className,
          'InputField',
          'InputEmail',
          'row',
        )}
      >
        <label
          htmlFor={htmlId}
          className={classNames({
            InputField__label: true,
            InputText__label: true,
            [`col-xs-${labelWidth}`]: true,
          })}
        >
          {label}
        </label>
        <span
          className={classNames(
            'InputField__input',
            'InputEmail__wrapper',
            `col-xs-${12 - labelWidth}`,
          )}
        >
          {this.renderEmail()}
          {(!readOnly || !emails.length) && (
            <input
              id={htmlId}
              type="text"
              value={value}
              disabled={readOnly}
              placeholder={placeholder}
              className={classNames(
                this.props.inputClassName,
                'InputEmail__input',
                'form-control',
              )}
              onChange={this.onChange}
              onKeyPress={this.onKeyPress}
              onBlur={this.onBlur}
            />
          )}
        </span>
      </p>
    );
  }
}

export default Email;

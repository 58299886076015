import { registerModuleReducer } from 'utils/modules/reducer';
import { registerSaga } from 'utils/modules/saga';

import reducer, { KEY } from './reducers';
import mainSaga from './sagas';

registerModuleReducer(KEY, reducer);
registerSaga(mainSaga);

export { BulkMessageModal } from './components/modal';
export { showBulkMessageModal } from './actions';

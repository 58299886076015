export const TYPE_UNKNOWN = {
  id: 0,
  name: 'TYPE_UNKNOWN',
};
export const TYPE_NOT_COMERCIALIZED_ANYMORE = {
  id: 1,
  name: 'TYPE_NOT_COMERCIALIZED_ANYMORE',
};
export const TYPE_NOT_THE_OWNER = {
  id: 2,
  name: 'TYPE_NOT_THE_OWNER',
};
export const TYPE_PRODUCT_ACCEPTED = {
  id: 3,
  name: 'TYPE_PRODUCT_ACCEPTED',
};

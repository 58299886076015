export const SHOW_USER_LABEL_MODAL = 'user-label/modal/show';
export const RESET_USER_LABEL_MODAL = 'user-label/modal/reset';
export const SELECT_USER_LABEL = 'user-label/select';
export const USER_LABELS_CREATED = 'user-label/created';
export const SAVE_USER_LABELS = 'user-label/save';
export const SAVE_USER_LABELS_DONE = 'user-label/save-done';
export const SAVE_USER_LABELS_ERROR = 'user-label/save-error';
export const CANCEL_SAVE_USER_LABELS = 'user-label/cancel-save';
export const DETACH_USER_LABEL = 'user-label/detach';
export const DETACH_USER_LABEL_DONE = 'user-label/detach-done';
export const CANCEL_DETACH_USER_LABEL = 'user-label/cancel-detach';
export const FETCH_ALL_USER_LABELS = 'user-label/fetch-all';
export const RECEIVE_ALL_USER_LABELS = 'user-label/receive-all';
export const CANCEL_FETCH_ALL_USER_LABELS = 'user-label/fetch-all/cancel';
export const TRACK_USER_LABELS = 'user-label/track';
export const SET_USER_LABELS_CONTEXT = 'user-label/set-context';
export const UPDATE_USER_LABEL = 'user-label/update';
export const DELETE_USER_LABEL = 'user-label/delete';
export const FETCH_LINKED_USERS_LABELS = 'user-label/fetch-linked';
export const FETCH_LINKED_USERS_LABELS_DONE = 'user-label/fetch-linked/done';

import { createReducer } from 'utils/redux';

import {
  AUTHORIZED,
  LOGOUT,
  NOT_AUTHORIZED,
  RESET_AUTHORIZED,
} from '../actions';
import type { UserAuthorizedState } from '../types';

export const initialState: UserAuthorizedState = {
  authorized: undefined,
};

export const userAuthorizedReducer = createReducer(initialState, {
  [AUTHORIZED]: () => ({ authorized: true }),
  [NOT_AUTHORIZED]: () => ({ authorized: false }),
  [RESET_AUTHORIZED]: () => initialState,
  [LOGOUT]: () => initialState,
});

import { withUserType } from 'hocs';

import MoreActions from './more-actions';
import PreviewModal from './preview-modal';
import './product-header-actions.scss';
import ValidateData from './validate-data';

interface Props {
  isRetailer: boolean;
  isThirdParty: boolean;
}

export const ProductHeaderActions = ({ isRetailer, isThirdParty }: Props) => {
  if (isThirdParty) {
    return null;
  }
  return (
    <div className="ProductHeaderActions">
      <MoreActions isRetailer={isRetailer} />
      <PreviewModal />
      <ValidateData />
    </div>
  );
};

export default withUserType(ProductHeaderActions);

import moment from 'moment';
import { useController, useWatch } from 'react-hook-form';
import { useSelector } from 'react-redux';

import DateInput from 'components/ui/input/date';
import InputWithLabel from 'components/ui/input/input-with-label';
import { selectHasJsonStoreInputFeature } from 'modules/feature-flag/selectors';
import i18n from 'utils/i18n';

import { ReadOnlyAssetInput } from './types';

export const ExpirationDate = ({ isReadOnly }: ReadOnlyAssetInput) => {
  const name = 'fileEffectiveEndDateTime';

  const {
    field: { onChange, value },
  } = useController({ name });

  const crc32 = useWatch({ name: 'crc32' });

  const hasJsonStoreInputFeature = useSelector(selectHasJsonStoreInputFeature);

  if (hasJsonStoreInputFeature) return null;

  return (
    <InputWithLabel
      childId={`ProductPictureForm-${crc32}-${name}`}
      label={i18n.t(
        'frontproducstream.asset_list.modal.expiration_date.label',
        { defaultValue: 'Expiration date' },
      )}
      help={i18n.t('frontproducstream.asset_list.modal.expiration_date.help', {
        defaultValue: 'Optional. Leave empty to indicate a permanent validity.',
      })}
    >
      <DateInput
        id={`ProductPictureForm-${crc32}-${name}`}
        value={value ? moment(value) : null}
        onSelect={onChange}
        readOnly={isReadOnly}
      />
    </InputWithLabel>
  );
};

import classNames from 'classnames';
import { useRef, useState } from 'react';

import { ClickOutside } from '@alkem/react-ui-click-outside';

import i18n from 'utils/i18n';

import './help-center.scss';

const HelpCenterDropdown = () => {
  return (
    <div className="dropdown-menu dropdown-menu-right" role="menu">
      <div>
        <a
          className="dropdown-item"
          href="https://help.salsify.com/SupplierXM/s"
          target="_blank"
          rel="noreferrer"
        >
          <i className="mdi mdi-school" />{' '}
          {i18n.t('frontproductstream.navbar_help.academy.link', {
            defaultValue: 'SupplierXM Portal',
          })}
        </a>
      </div>
    </div>
  );
};

export const HelpCenter = () => {
  const [helpCenterOpen, setHelpCenterOpen] = useState(false);
  const mainRef = useRef(null);

  return (
    <li
      ref={mainRef}
      id="academy-access"
      data-testid="helpCenter"
      className={classNames('dropdown Navbar__dropdown HelpCenter', {
        open,
      })}
    >
      <button
        className="dropdown-toggle HelpCenter__button"
        data-testid="HelpCenterButton"
        onClick={() => setHelpCenterOpen((isOpen) => !isOpen)}
      >
        <i className="mdi mdi-help-circle HelpCenter__helpIcon " />
        <i className="mdi mdi-chevron-down" />
      </button>
      {helpCenterOpen && (
        <ClickOutside
          ref={mainRef}
          onClickOutside={() => setHelpCenterOpen(false)}
        >
          <HelpCenterDropdown />
        </ClickOutside>
      )}
    </li>
  );
};

export default HelpCenter;

const buildChart = (config, aggregations) => {
  const { aggregation, keys, type, colors } = config || {};
  if (!aggregation || !keys || !keys.length || !type) {
    return null;
  }
  if (aggregation === 'all') {
    const { meta: metaList, row_values: rowValues } = aggregations.all;
    if (!rowValues || !rowValues.length || !metaList || !metaList.length) {
      return null;
    }
    const values = rowValues[0];
    const safeColors = Object.keys(colors || {}).reduce(
      (acc, key) => ({ ...acc, [key.toUpperCase()]: colors[key] }),
      {},
    );
    const chartValues = metaList.reduce((acc, meta) => {
      if (keys.includes(meta.key)) {
        const {
          columns: columnKeys,
          columns_label: labels,
          filter,
          ...metaCol
        } = meta;
        if (Array.isArray(columnKeys)) {
          columnKeys.forEach((subColKey) => {
            const key = `${meta.key}.${subColKey}`;
            acc.push({
              ...metaCol,
              key,
              keyOrigin: subColKey,
              label: labels[key] || `${meta.key}: ${subColKey}`,
              value: values[key],
              color: safeColors[subColKey.toUpperCase()],
            });
          });
        } else {
          acc.push({
            ...metaCol,
            keyOrigin: meta.key,
            label: labels[meta.key] || meta.key,
            value: values[meta.key],
            color: safeColors[meta.key.toUpperCase()],
          });
        }
      }
      return acc;
    }, []);
    return {
      ...config,
      values: chartValues,
    };
  }
  return null;
};

export const buildCharts = (configList, aggregations) => {
  if (configList && configList.length && aggregations) {
    return configList
      .map((config) => buildChart(config, aggregations))
      .filter((chart) => chart);
  }
  return [];
};

import {
  ACK_ROOM,
  ADD_LOCAL_ROOM,
  ADD_MESSAGES_TO_ROOM,
  ADD_MESSAGE_TO_ROOM,
  ADD_REMOTE_ROOM,
  ADD_ROOMS,
  CHANGE_ROOM_PAGE,
  CONVERT_TO_REMOTE_ROOM,
  ERASE_DATA, // actions
  FETCH_DATA,
  FETCH_DATA_ERROR,
  FETCH_ROOMS,
  FILTER_RECIPIENTS,
  GO_TO_FIRST_PAGE,
  LOAD_MORE_MESSAGES,
  NOTIFY_COUNT_UNACKED,
  NO_MORE_MESSAGES,
  ON_SELECT_ROOM,
  SELECT_RECIPIENT,
  SELECT_ROOM,
  SEND_MESSAGE,
  SET_AS_FIRST_ROOM, // events
  SET_INITIAL_DATA,
  SET_MESSAGES_FOR_ROOM,
  UPDATE_RECIPIENTS,
  UPDATE_ROOM,
} from './constants';

// actions (from view)

export const fetchData = (entityId, roomType, recipientId) => ({
  type: FETCH_DATA,
  payload: { entityId, roomType, recipientId },
});

export const eraseData = () => ({
  type: ERASE_DATA,
});

export const onSelectRoom = (roomId) => ({
  type: ON_SELECT_ROOM,
  payload: { roomId },
});

export const selectRecipient = (recipient, roomType) => ({
  type: SELECT_RECIPIENT,
  payload: { recipient, roomType },
});

export const filterRecipients = (recipientNameLike, entityId, roomType) => ({
  type: FILTER_RECIPIENTS,
  payload: { recipientNameLike, entityId, roomType },
});

export const sendMessage = (message, room, entityId, roomType) => ({
  type: SEND_MESSAGE,
  payload: { message, room, entityId, roomType },
});

export const ackRoom = (room) => ({
  type: ACK_ROOM,
  payload: { room },
});

export const changeRoomPage = (page, entityId, roomType) => ({
  type: CHANGE_ROOM_PAGE,
  payload: { page, entityId, roomType },
});

export const loadMoreMessages = () => ({
  type: LOAD_MORE_MESSAGES,
});

// events (from saga)

export const setInitialData = (
  recipients,
  rooms,
  totalRoomCount,
  selectedRoomId,
  messages,
) => ({
  type: SET_INITIAL_DATA,
  payload: { recipients, rooms, totalRoomCount, selectedRoomId, messages },
});

export const addLocalRoom = (recipient) => ({
  type: ADD_LOCAL_ROOM,
  payload: { recipient },
});

export const selectRoom = (roomId) => ({
  type: SELECT_ROOM,
  payload: { roomId },
});

export const setMessagesForRoom = (roomId, messages) => ({
  type: SET_MESSAGES_FOR_ROOM,
  payload: { roomId, messages },
});

export const addMessageToRoom = (roomId, message) => ({
  type: ADD_MESSAGE_TO_ROOM,
  payload: { roomId, message },
});

export const addMessagesToRoom = (roomId, messages) => ({
  type: ADD_MESSAGES_TO_ROOM,
  payload: { roomId, messages },
});

export const convertToRemoteRoom = (room, organizationId) => ({
  type: CONVERT_TO_REMOTE_ROOM,
  payload: { room, organizationId },
});

export const notifyUnackedCount = (countUnacked) => ({
  type: NOTIFY_COUNT_UNACKED,
  payload: { countUnacked },
});

export const updateRecipients = (recipients) => ({
  type: UPDATE_RECIPIENTS,
  payload: { recipients },
});

export const updateRoom = (roomId, ackedAt, ackedBy, lastMessage) => ({
  type: UPDATE_ROOM,
  payload: { roomId, ackedAt, ackedBy, lastMessage },
});

export const fetchRooms = () => ({
  type: FETCH_ROOMS,
});

export const addRooms = (rooms) => ({
  type: ADD_ROOMS,
  payload: { rooms },
});

export const goToFirstPage = () => ({
  type: GO_TO_FIRST_PAGE,
});

export const setAsFirstRoom = (roomId) => ({
  type: SET_AS_FIRST_ROOM,
  payload: { roomId },
});

export const addRemoteRoom = (room) => ({
  type: ADD_REMOTE_ROOM,
  payload: { room },
});

export const fetchDataError = () => ({
  type: FETCH_DATA_ERROR,
});

export const noMoreMessages = () => ({
  type: NO_MORE_MESSAGES,
});

import PropTypes from 'prop-types';
import { PureComponent } from 'react';
import { list } from 'react-immutable-proptypes';
import { connect } from 'react-redux';
import { createStructuredSelector } from 'reselect';

import Modal from 'components/ui/modal';
import i18n from 'utils/i18n';
import { get } from 'utils/immutable';

import * as actions from './actions';
import { selectIsOpen, selectUnsyncableRecipients } from './selectors';

const mapStateToProps = createStructuredSelector({
  isOpen: selectIsOpen,
  unsyncableRecipients: selectUnsyncableRecipients,
});

const mapDispatchToProps = {
  close: actions.close,
};

export class UnsyncableRecipientsModal extends PureComponent {
  static propTypes = {
    isOpen: PropTypes.bool.isRequired,
    unsyncableRecipients: list.isRequired,
    close: PropTypes.func.isRequired,
  };

  confirm = () => this.props.close(true);

  cancel = () => this.props.close(false);

  render() {
    const { isOpen, unsyncableRecipients } = this.props;
    if (!isOpen) {
      return null;
    }
    return (
      <Modal
        modalStyle="dynamic"
        title={i18n.t('Mandatory fields are missing')}
        className="UnsyncableRecipientsModal"
        confirmButtonText={i18n.t('Save changes')}
        onConfirm={this.confirm}
        closeButtonText={i18n.t('Go back to product page')}
        onClose={this.cancel}
      >
        <p>
          {i18n.t(
            'Some of the fields are demanded by retailers this product has already been shared with. The changes will not be sent over to:',
          )}
        </p>
        <ul>
          {unsyncableRecipients.map((r) => (
            <li key={get(r, 'id')}> {get(r, 'nameLegal')}</li>
          ))}
        </ul>
      </Modal>
    );
  }
}

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(UnsyncableRecipientsModal);

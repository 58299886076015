import { PureComponent } from 'react';
import { map } from 'react-immutable-proptypes';

import PaginationInfo from '@alkem/react-ui-list/src/pagination/info';

import i18n from 'utils/i18n';

import './info.scss';

export class ReportingPaginationInfo extends PureComponent {
  static propTypes = {
    pagination: map.isRequired,
    selectedMap: map.isRequired,
  };

  getPageLabel = (page, total) => {
    return i18n.t(
      'frontproductstream.reporting_page_pagination_info.current_page_among_pages.label',
      {
        page: page || 0,
        count: total || 0,
        defaultValue: '{{page}} / {{count}} page',
      },
    );
  };

  getSelectedLabel = (count) => {
    return i18n.t(
      'frontproductstream.reporting_page_pagination_info.count_of_selected_rows.label',
      { count, defaultValue: '{{count}} selected' },
    );
  };

  getEntityLabel = (count) => {
    return i18n.t(
      'frontproductstream.reporting_page_pagination_info.total_rows.label',
      { count, defaultValue: '{{count}} reports in total' },
    );
  };

  getSelectedCount() {
    return (
      this.props.selectedMap.toList().filter((selected) => selected).size || -1
    );
  }

  render() {
    const { pagination } = this.props;
    return (
      <PaginationInfo
        currentPage={pagination.get('page')}
        totalPages={pagination.get('pages')}
        totalResults={pagination.get('total')}
        selected={this.getSelectedCount()}
        getPageLabel={this.getPageLabel}
        getSelectedLabel={this.getSelectedLabel}
        getEntityLabel={this.getEntityLabel}
      />
    );
  }
}

import { OrderedMap, Record } from 'immutable';
import { flow } from 'lodash/fp';

import { keyBy, set } from 'utils/fp';
import { createFpReducer } from 'utils/redux';

import {
  ERASE_DATA,
  FETCH_DATA,
  FETCH_DATA_ERROR,
  FILTER_RECIPIENTS_REQUEST_END,
  FILTER_RECIPIENTS_REQUEST_START,
  NOTIFY_COUNT_UNACKED,
  SET_INITIAL_DATA,
  UPDATE_RECIPIENTS,
} from '../constants';

// ---------------------------------------------------------

export const initialState = Record({
  recipients: OrderedMap(),
  isFetchingData: false,
  isFetchingRecipients: false,
  countUnacked: 0,
  totalRecipients: 0,
  hasError: false,
})();

// ---------------------------------------------------------

export default createFpReducer(initialState, {
  [FETCH_DATA]: () => flow(set('isFetchingData', true), set('hasError', false)),

  [SET_INITIAL_DATA]: ({ recipients }) =>
    flow(
      set('isFetchingData', false),
      set('recipients', keyBy('id', recipients)),
      set('totalRecipients', recipients.size),
    ),

  [ERASE_DATA]: () => () => initialState,

  [FETCH_DATA_ERROR]: () =>
    flow(set('isFetchingData', false), set('hasError', true)),

  [NOTIFY_COUNT_UNACKED]: ({ countUnacked }) =>
    set('countUnacked', countUnacked),

  [UPDATE_RECIPIENTS]: ({ recipients }) =>
    set('recipients', keyBy('id', recipients)),

  [FILTER_RECIPIENTS_REQUEST_START]: () => set('isFetchingRecipients', true),

  [FILTER_RECIPIENTS_REQUEST_END]: () => set('isFetchingRecipients', false),
});

import { mandatoryFields } from 'modules/view-as/constants';
import displayGroupApi from 'resources/displayGroupApi';
import qs from 'utils/query';

export const DISPLAY_GROUP_ROOTS = {
  CONSUMER_UNIT: 1,
  PICTURE: 2,
  VIDEO: 3,
  ENRICHED_CONTENT: 4,
  DOCUMENT: 5,
  SHARING_UNIT: 6,
  DISPLAY_UNIT: 7,
  LOGISTICAL_UNIT: 8,
};

export const fetchLegacyDisplayGroups = (queries) => {
  return displayGroupApi.get(
    `/core/v3/displaygroups${qs.stringify(queries, true)}`,
  );
};

type FetchDisplayGroupsParams = {
  hideAdditionalFields: boolean;
  recipientIds: number[];
  ruleSetsByEntity: any;
  displayGroupType: number;
  kindId: number;
  typePackagingId: number;
  isRetailer?: boolean;
  isBaseUnit: boolean;
};

export const fetchDisplayGroups = (queries: FetchDisplayGroupsParams) => {
  const {
    hideAdditionalFields,
    recipientIds,
    ruleSetsByEntity,
    displayGroupType,
    kindId,
    typePackagingId,
    isRetailer,
    isBaseUnit,
  } = queries;

  const getExtraFields = () => {
    if (!Object.keys(ruleSetsByEntity).length) {
      return [];
    }

    switch (displayGroupType) {
      case DISPLAY_GROUP_ROOTS.CONSUMER_UNIT:
        return ruleSetsByEntity.consumerUnit;
      case DISPLAY_GROUP_ROOTS.PICTURE:
        return ruleSetsByEntity.productPicture;
      case DISPLAY_GROUP_ROOTS.VIDEO:
        return ruleSetsByEntity.productVideo;
      case DISPLAY_GROUP_ROOTS.DOCUMENT:
        return ruleSetsByEntity.productDocument;
      case DISPLAY_GROUP_ROOTS.SHARING_UNIT:
        return ruleSetsByEntity.sharingUnit[recipientIds[0]] ?? [];
      case DISPLAY_GROUP_ROOTS.LOGISTICAL_UNIT:
        return ruleSetsByEntity.logisticalUnit;
      default:
        return [];
    }
  };

  const extraFields = hideAdditionalFields
    ? [...mandatoryFields, ...getExtraFields()]
    : mandatoryFields;

  return displayGroupApi.post('/field/displaygroup/v1/list', {
    with_fields: true,
    field_filters: {
      root: displayGroupType,
      kind_id: kindId,
      packaging_type_ids: [typePackagingId],
      all_recipients: !recipientIds.length && !hideAdditionalFields,
      recipient_ids: recipientIds,
      extra_fields: extraFields,
      is_retailer: isRetailer,
      is_base_unit: isBaseUnit,
    },
  });
};

type FetchSpecificDisplayGroupsParams = {
  recipientIds: number[];
  displayGroupType: number;
  kindId: number;
  packagingTypeId: number;
  isReadOnly: boolean;
  isBaseUnit: boolean;
};

export const fetchSpecificDisplayGroups = (
  queries: FetchSpecificDisplayGroupsParams,
) => {
  const {
    recipientIds,
    displayGroupType,
    kindId,
    packagingTypeId,
    isReadOnly,
    isBaseUnit,
  } = queries;

  const packagingTypeIds = packagingTypeId == null ? [] : [packagingTypeId];

  return displayGroupApi.post('/field/field/v1/specific/list', {
    recipient_ids: recipientIds,
    root: displayGroupType,
    kind_id: kindId,
    packaging_type_ids: packagingTypeIds,
    is_read_only: isReadOnly,
    is_base_unit: isBaseUnit,
  });
};

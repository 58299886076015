import { set } from 'lodash/fp';
import { call, put, select } from 'redux-saga/effects';

import { updateEntity } from 'actions/entity';
import contributionApi from 'resources/contributionApi';
import { ActionPayload } from 'types';
import { track } from 'utils/tracking';

import {
  acceptDataOpsPatches,
  acceptDataOpsPatchesDone,
  dataOpsPatchesReceived,
  refuseDataOpsPatches,
  refuseDataOpsPatchesDone,
  removeDataOpsPatches,
} from '../actions';
import {
  selectDataOpsProductKeyIdToEntityId,
  selectIsDataOpsPatcher,
} from '../selectors';
import { DataOpsDiffStatus, DataOpsPatch } from '../types';

import { cancelPatches } from './cancel-patch';
import { hasPatchPermission } from './utils';

interface AcceptOrRefuseBodyItem {
  id: number;
  productKeyId: number;
  sourceProductKeyId: number;
  isSpecific?: boolean;
}

const patchesToBody = (patches: DataOpsPatch[]): AcceptOrRefuseBodyItem[] =>
  patches.map((patch) => {
    const mappedPatch: AcceptOrRefuseBodyItem = {
      id: patch.id,
      productKeyId: patch.retailerProductKeyId as number,
      sourceProductKeyId: patch.sourceProductKeyId as number,
    };

    if (patch.isSpecific) {
      mappedPatch.isSpecific = true;
    }

    return mappedPatch;
  });

export function* acceptPatches({
  payload,
}: {
  payload: ActionPayload<typeof acceptDataOpsPatches>;
}) {
  const canPatch = yield call(hasPatchPermission, { withNotif: true });
  const isPatcher = yield select(selectIsDataOpsPatcher);
  if (!canPatch) {
    return;
  }
  try {
    const { patches } = payload;
    if (patches.length) {
      if (isPatcher) {
        yield call(cancelPatches, { payload: { patches: payload.patches } });
      } else {
        yield call(() =>
          contributionApi.post('/contribution/v1/product/patches/accept', {
            patches: patchesToBody(patches),
          }),
        );
        yield put(removeDataOpsPatches(patches));
        const productKeyIdToEntityId = yield select(
          selectDataOpsProductKeyIdToEntityId,
        );
        for (const patch of patches) {
          const entityId = productKeyIdToEntityId[patch.productKeyId];
          yield put(
            updateEntity(
              patch.fieldName,
              patch.dataNew,
              patch.appliesOnEntityId || entityId,
              patch.entityType.toString(),
              false,
              false,
            ),
          );
        }
      }
    }
  } finally {
    yield put(acceptDataOpsPatchesDone({ ...payload, isPatcher }));
  }
}

export function* refusePatches({
  payload,
}: {
  payload: ActionPayload<typeof refuseDataOpsPatches>;
}) {
  const canPatch = yield call(hasPatchPermission, { withNotif: true });
  if (!canPatch) {
    return;
  }
  const isPatcher = yield select(selectIsDataOpsPatcher);
  try {
    const { patches } = payload;
    if (patches.length) {
      if (isPatcher) {
        yield call(() =>
          contributionApi.post(
            '/contribution/v1/product/patches/ignore',
            patches.reduce(
              (acc, patch) => {
                acc.data[patch.id] = patch.currentValue;
                return acc;
              },
              { data: {} } as { data: { patchId: number; currentValue: any } },
            ),
          ),
        );

        yield put(
          dataOpsPatchesReceived({
            patches: patches.map<DataOpsPatch>(
              set(['diffStatus'], DataOpsDiffStatus.PATCH_IGNORED_STATUS),
            ),
          }),
        );
      } else {
        yield call(() =>
          contributionApi.post('/contribution/v1/product/patches/refuse', {
            patches: patchesToBody(patches),
          }),
        );
        yield put(removeDataOpsPatches(patches));
      }
    }
  } finally {
    yield put(refuseDataOpsPatchesDone({ ...payload, isPatcher }));
  }
}

export function trackPatchAccept({ payload }) {
  payload.patches?.forEach((patch) => {
    track({
      category: 'product',
      action: payload.isPatcher
        ? 'product_patch_update_accepted'
        : 'product_patch_accepted',
      product_key_id: patch.productKeyId,
      patch_id: patch.id,
      field_name: patch.fieldName,
    });
  });
}

export function trackPatchRefuse({ payload }) {
  payload.patches?.forEach((patch) => {
    track({
      category: 'product',
      action: payload.isPatcher
        ? 'product_patch_update_refused'
        : 'product_patch_refused',
      product_key_id: patch.productKeyId,
      patch_id: patch.id,
      field_name: patch.fieldName,
    });
  });
}

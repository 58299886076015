import { set } from 'lodash/fp';

import { createFpReducer } from 'utils/redux';

import {
  PRODUCT_ADMIN_LOAD_PRODUCT_INDEXED_AT_DATE_DONE,
  PRODUCT_ADMIN_REINDEX_PRODUCT,
  PRODUCT_ADMIN_REINDEX_PRODUCT_FAILURE,
  PRODUCT_ADMIN_REINDEX_PRODUCT_SUCCESS,
  PRODUCT_ADMIN_RESYNC_PRODUCT,
  PRODUCT_ADMIN_RESYNC_PRODUCT_DONE,
  PRODUCT_ADMIN_SET_AUDIT_CONTEXT,
  PRODUCT_ADMIN_SET_AUDIT_DATAS,
  PRODUCT_ADMIN_START_AUDIT,
  PRODUCT_ADMIN_START_AUDIT_DONE,
} from './constants';

export const initialState = {
  isReindexing: false,
  isResyncing: false,
  isStartingAudit: false,
  indexedAt: '',
  audits: [],
  auditContextByAuditId: {},
};

export default createFpReducer(initialState, {
  [PRODUCT_ADMIN_REINDEX_PRODUCT]: () => set('isReindexing', true),
  [PRODUCT_ADMIN_REINDEX_PRODUCT_FAILURE]: () => set('isReindexing', false),
  [PRODUCT_ADMIN_REINDEX_PRODUCT_SUCCESS]: () => set('isReindexing', false),
  [PRODUCT_ADMIN_LOAD_PRODUCT_INDEXED_AT_DATE_DONE]: (payload) => {
    return set('indexedAt', payload);
  },
  [PRODUCT_ADMIN_RESYNC_PRODUCT]: () => set('isResyncing', true),
  [PRODUCT_ADMIN_RESYNC_PRODUCT_DONE]: () => set('isResyncing', false),
  [PRODUCT_ADMIN_START_AUDIT]: () => set('isStartingAudit', true),
  [PRODUCT_ADMIN_START_AUDIT_DONE]: () => set('isStartingAudit', false),
  [PRODUCT_ADMIN_SET_AUDIT_DATAS]: ({ audits }) => set('audits', audits),
  [PRODUCT_ADMIN_SET_AUDIT_CONTEXT]: ({ contexts, id }) =>
    set(['auditContextByAuditId', id], contexts),
});

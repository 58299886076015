import { createContext, useContext } from 'react';

import { Field } from 'components/ui/form/asset-list/AssetV2/types';

type AssetContextValue = {
  formGroup: Field;
  entityId: number;
};

export const AssetContext = createContext<AssetContextValue | null>(null);

export const useAssetContext = () => {
  const contextValue = useContext(AssetContext);

  if (!contextValue) {
    throw new Error(
      'useAssetContext must be used within an AssetContextProvider',
    );
  }

  return contextValue;
};

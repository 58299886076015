import i18n from 'utils/i18n';

export const SUPPORTED_LANGUAGES = {
  fr: {
    name: i18n.t('French'),
  },
  en: {
    name: i18n.t('English'),
  },
};

export const DATA_OPS_REDUCER = 'dataops';

// Actions
export const DATA_OPS_INIT = 'data-ops/init';
export const DATA_OPS_PATCHES_RECEIVED = 'data-ops/patches-received';
export const DATA_OPS_REPLACE_PATCHES = 'data-ops/replace-patches';
export const DATA_OPS_EDIT_FIELD = 'data-ops/edit-field';
export const DATA_OPS_PATCH_FIELD = 'data-ops/patch-field';
export const DATA_OPS_BULK_PATCH_FIELD = 'data-ops/bulk-patch-field';
export const DATA_OPS_STORE_PATCH = 'data-ops/store-patch';
export const DATA_OPS_BULK_STORE_PATCH = 'data-ops/bulk-store-patch';
export const DATA_OPS_SAVE_PATCHES = 'data-ops/save-patches';
export const DATA_OPS_CANCEL_PATCH = 'data-ops/cancel-patch';
export const DATA_OPS_CANCEL_PATCH_DONE = 'data-ops/cancel-patch-done';
export const DATA_OPS_SAVE_PATCHES_DONE = 'data-ops/save-patches-done';
export const DATA_OPS_ACCEPT_PATCHES = 'data-ops/accept-patches';
export const DATA_OPS_ACCEPT_PATCHES_DONE = 'data-ops/accept-patches-done';
export const DATA_OPS_REFUSE_PATCHES = 'data-ops/refuse-patches';
export const DATA_OPS_REFUSE_PATCHES_DONE = 'data-ops/refuse-patches-done';
export const DATA_OPS_REMOVE_PATCHES = 'data-ops/remove-patches';

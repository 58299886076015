import Tooltip from '@alkem/react-ui-tooltip';

import { sanitize } from 'utils/DOMPurify';
import { getEnv } from 'utils/env';

global.env = getEnv();

global.VERSION = Object.assign(
  { stream: global.FPS_VERSION, sdk: global.SDK_VERSION },
  global.VERSION,
);

Tooltip.setDefaultSanitizer(sanitize);

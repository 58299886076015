import { isNotThirdParty } from 'core/api/user';
import { hasUserLabelManagementFeature } from 'modules/user-label';
import i18n from 'utils/i18n';

export const userLabelsFilter = {
  key: 'isUserLabeledByName',
  query: 'userlabel',
  filterLabel: i18n.t('frontproductstream.core.list_filter_user_labels.label', {
    defaultValue: 'UserLabels',
  }),
  filterSearchPlaceHolder: i18n.t(
    'frontproductstream.core.list_filter_user_labels.search_placeholder',
    { defaultValue: 'Search for user labels' },
  ),
  filterNameKey: 'id',
  missingFilter: {
    key: 'USER_LABEL_MISSING',
    label: i18n.t(
      'frontproductstream.catalog.filter_user_labels.missing_key_label',
      { defaultValue: 'No label' },
    ),
  },
  isAvailable: ({ user } = {}) =>
    hasUserLabelManagementFeature(user) && isNotThirdParty(user),
};

import i18n from 'utils/i18n';

// TODO CCS - it's tech-debit right now we use `id` everywhere.
// We should move to using the `code` instead.

// Face displayed
const PRODUCT_FACE_DISPLAYED_FRONT = {
  id: 0,
  code: '0',
  label: i18n.t('frontproductstream.constants.product_picture_face.front', {
    defaultValue: 'Front',
  }),
};
const PRODUCT_FACE_DISPLAYED_LEFT_SIDE = {
  id: 1,
  code: '1',
  label: i18n.t('frontproductstream.constants.product_picture_face.left_side', {
    defaultValue: 'Left side',
  }),
};
const PRODUCT_FACE_DISPLAYED_TOP = {
  id: 2,
  code: '2',
  label: i18n.t('frontproductstream.constants.product_picture_face.top_side', {
    defaultValue: 'Top side',
  }),
};
const PRODUCT_FACE_DISPLAYED_BACK = {
  id: 3,
  code: '3',
  label: i18n.t('frontproductstream.constants.product_picture_face.back_side', {
    defaultValue: 'Back side',
  }),
};
const PRODUCT_FACE_DISPLAYED_RIGHT_SIDE = {
  id: 4,
  label: i18n.t(
    'frontproductstream.constants.product_picture_face.right_side',
    {
      defaultValue: 'Right side',
    },
  ),
};
const PRODUCT_FACE_DISPLAYED_BOTTOM = {
  id: 5,
  code: '5',
  label: i18n.t('frontproductstream.constants.product_picture_face.bottom', {
    defaultValue: 'Bottom',
  }),
};
const PRODUCT_FACE_DISPLAYED_NA = {
  id: 6,
  code: '6',
  label: i18n.t(
    'frontproductstream.constants.product_picture_face.not_applicable',
    {
      defaultValue: 'N/A',
    },
  ),
};

export const PRODUCT_FACE = [
  PRODUCT_FACE_DISPLAYED_FRONT,
  PRODUCT_FACE_DISPLAYED_LEFT_SIDE,
  PRODUCT_FACE_DISPLAYED_TOP,
  PRODUCT_FACE_DISPLAYED_BACK,
  PRODUCT_FACE_DISPLAYED_RIGHT_SIDE,
  PRODUCT_FACE_DISPLAYED_BOTTOM,
  PRODUCT_FACE_DISPLAYED_NA,
];

// Angle vertical
const ANGLE_VERTICAL_PARALLEL = {
  id: 0,
  code: '0',
  label: i18n.t(
    'frontproductstream.constants.product_picture_vertical_angle.parallel',
    {
      defaultValue: 'Parallel',
    },
  ),
};
const ANGLE_VERTICAL_PLONGEANTE = {
  id: 1,
  code: '1',
  label: i18n.t(
    'frontproductstream.constants.product_picture_vertical_angle.top_view',
    {
      defaultValue: 'Top view',
    },
  ),
};
const ANGLE_VERTICAL_CONTREPLONGEANTE = {
  id: 2,
  code: '2',
  label: i18n.t(
    'frontproductstream.constants.product_picture_vertical_angle.low_angle',
    {
      defaultValue: 'Low angle',
    },
  ),
};

export const ANGLE_VERTICAL = [
  ANGLE_VERTICAL_PARALLEL,
  ANGLE_VERTICAL_PLONGEANTE,
  ANGLE_VERTICAL_CONTREPLONGEANTE,
];

// Angle horizontal
const ANGLE_HORIZONTAL_LEFT = {
  id: 0,
  code: '0',
  label: i18n.t(
    'frontproductstream.constants.product_picture_horizontal_angle.left',
    {
      defaultValue: 'Left',
    },
  ),
};
const ANGLE_HORIZONTAL_CENTER = {
  id: 1,
  code: '1',
  label: i18n.t(
    'frontproductstream.constants.product_picture_horizontal_angle.center',
    {
      defaultValue: 'Center',
    },
  ),
};
const ANGLE_HORIZONTAL_RIGHT = {
  id: 2,
  code: '2',
  label: i18n.t(
    'frontproductstream.constants.product_picture_horizontal_angle.right',
    {
      defaultValue: 'Right',
    },
  ),
};

export const ANGLE_HORIZONTAL = [
  ANGLE_HORIZONTAL_LEFT,
  ANGLE_HORIZONTAL_CENTER,
  ANGLE_HORIZONTAL_RIGHT,
];

// File Type
const STANDARD_RESOLUTION = {
  id: 0,
  code: '0',
  label: i18n.t(
    'frontproductstream.constants.product_picture_type.standard_resolution',
    {
      defaultValue: 'Standard resolution',
    },
  ),
};
const HIGH_RESOLUTION = {
  id: 1,
  code: '1',
  label: i18n.t(
    'frontproductstream.constants.product_picture_type.high_resolution',
    {
      defaultValue: 'High resolution',
    },
  ),
};
const OTHER = {
  id: 2,
  code: '2',
  label: i18n.t('frontproductstream.constants.product_picture_type.other', {
    defaultValue: 'Other',
  }),
};
const STANDARD_RESOLUTION_WITH_SUPPORTING_ELEMENTS = {
  id: 3,
  code: '3',
  label: i18n.t(
    'frontproductstream.constants.product_picture_type.standard_res_with_support',
    {
      defaultValue: 'Standard resolution with supporting elements',
    },
  ),
};
const HIGH_RESOLUTION_WITH_SUPPORTING_ELEMENTS = {
  id: 4,
  code: '4',
  label: i18n.t(
    'frontproductstream.constants.product_picture_type.high_res_with_support',
    {
      defaultValue: 'High resolution with supporting elements',
    },
  ),
};
const THREE_SIXTY_360 = {
  id: 5,
  code: '5',
  label: i18n.t('frontproductstream.constants.product_picture_type.360deg', {
    defaultValue: '360°',
  }),
};
const DETAIL = {
  id: 6,
  code: '6',
  label: i18n.t('frontproductstream.constants.product_picture_type.detail', {
    defaultValue: 'Detail',
  }),
};
const MONTAGE = {
  id: 7,
  code: '7',
  label: i18n.t('frontproductstream.constants.product_picture_type.montage', {
    defaultValue: 'Montage',
  }),
};
const HERO_IMAGE = {
  id: 8,
  code: '8',
  label: i18n.t(
    'frontproductstream.constants.product_picture_type.hero_image',
    {
      defaultValue: 'Hero image',
    },
  ),
};
const PRODUCT_PACKAGING = {
  id: 9,
  code: '9',
  label: i18n.t(
    'frontproductstream.constants.product_picture_type.product_packaging',
    {
      defaultValue: 'Product packaging',
    },
  ),
};

export const FILE_TYPE = [
  STANDARD_RESOLUTION,
  HIGH_RESOLUTION,
  OTHER,
  STANDARD_RESOLUTION_WITH_SUPPORTING_ELEMENTS,
  HIGH_RESOLUTION_WITH_SUPPORTING_ELEMENTS,
  THREE_SIXTY_360,
  DETAIL,
  MONTAGE,
  HERO_IMAGE,
  PRODUCT_PACKAGING,
];

// JSON fields that were migrated from legacy to json store and are displayed on the front
export const MIGRATED_FIELDS = [
  'isPackshot',
  'fileContentTypeCode',
  'pictureEffectiveStartDate',
  'pictureEffectiveEndDate',
  'productFaceCode',
  'pictureAngleVerticalCode',
  'pictureAngleHorizontalCode',
  'webOptimizedTypeCode',
  'fileTypeCode',
  'tags',
  'sequenceNumber',
  'ipUsageRights',
  // Those two fields will not be displayed, they have historically been back-only
  'canFilesBeEdited',
  'isFileBackgroundTransparent',
];

export const HIDDEN_PICTURE_FIELDS = [
  'ipUsageRights',
  'canFilesBeEdited',
  'isFileBackgroundTransparent',
];

// Json to legacy maps of fields whose name changed in the migration
export const JSON_TO_LEGACY_FIELDS_MAP = {
  pictureEffectiveStartDate: 'fileEffectiveStartDateTime',
  pictureEffectiveEndDate: 'fileEffectiveEndDateTime',
  pictureAngleVerticalCode: 'angleVertical',
  pictureAngleHorizontalCode: 'angleHorizontal',
  fileTypeCode: 'fileType',
  productFaceCode: 'productFace',
  fileContentTypeCode: 'contentType',
  webOptimizedTypeCode: 'webOptimizedType',
};

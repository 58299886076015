import classNames from 'classnames';
import copyToClipboard from 'clipboard-copy';
import PropTypes from 'prop-types';
import { PureComponent } from 'react';

import { Tooltip } from '@alkem/react-ui-tooltip';

import i18n from 'utils/i18n';
import { track } from 'utils/tracking';

import './product-reference.scss';

export default class ProductReference extends PureComponent {
  static propTypes = {
    reference: PropTypes.string,
    copy: PropTypes.bool,
    inline: PropTypes.bool,
    iconDisplayable: PropTypes.bool,
    iconCopyDisplayable: PropTypes.bool,
    isExportable: PropTypes.bool,
    onClick: PropTypes.func,
    versionId: PropTypes.any,
    hasSingleExportableGTIN: PropTypes.bool,
  };

  static defaultProps = {
    copy: false,
    iconDisplayable: false,
    hasSingleExportableGTIN: false,
  };

  static prefixLength = 6;

  constructor(props) {
    super(props);
    this.state = {
      copied: false,
    };
  }

  componentDidMount() {
    this._isMounted = true;
  }

  componentWillUnmount() {
    this._isMounted = false;
  }

  onCopy = () => {
    const { reference } = this.props;
    copyToClipboard(reference);
    this.setState({ copied: true });
    global.setTimeout(() => {
      if (this._isMounted) {
        this.setState({ copied: false });
      }
    }, 2000);
  };

  formatReference(reference) {
    let firstHalf = '';
    let secondHalf = '';
    // Only highlight if the reference is a GTIN.
    if (reference.length === 14) {
      firstHalf = reference.substring(0, ProductReference.prefixLength + 1);
      secondHalf = reference.substring(ProductReference.prefixLength + 1, 14);
    } else {
      firstHalf = reference;
    }

    return { firstHalf, secondHalf };
  }

  trackDuplicatedProductIconClicked(isExportable) {
    track({
      action: 'duplicate_product_icon_clicked',
      value: isExportable,
    });
  }
  render() {
    const {
      copy,
      iconCopyDisplayable,
      reference,
      inline,
      iconDisplayable,
      isExportable,
      onClick,
      versionId,
      hasSingleExportableGTIN,
    } = this.props;
    if (!reference) {
      return null;
    }
    const { copied } = this.state;
    const { firstHalf, secondHalf } = this.formatReference(reference);
    const duplicatedIconClassName = classNames(
      'ProductReference__duplicatedIcon',
      {
        'ProductReference__duplicatedIcon--exportable': isExportable,
      },
    );
    const textFirsthalf = i18n.t(
      'frontproductstream.product-reference.shared_product.text_info',
      {
        defaultValue:
          'This product has been shared with you by several manufacturers.',
      },
    );
    const textSecondhalf = isExportable
      ? hasSingleExportableGTIN
        ? i18n.t(
            'frontproductstream.product-reference.shared_product.single_version_exported',
            { defaultValue: 'This is the exported version.' },
          )
        : i18n.t(
            'frontproductstream.product-reference.shared_product.version_exported',
            { defaultValue: 'This version is exported.' },
          )
      : hasSingleExportableGTIN
        ? i18n.t(
            'frontproductstream.product-reference.shared_product.single_version_not_exported',
            {
              defaultValue: 'This is not the exported version.',
            },
          )
        : i18n.t(
            'frontproductstream.product-reference.shared_product.version_not_exported',
            {
              defaultValue: 'This version is not exported.',
            },
          );
    return (
      <div
        className={classNames('ProductReference', {
          ProductReference__inline: inline,
        })}
        data-testid="ProductReference"
        data-reference={reference}
      >
        <span
          {...(copy
            ? {
                className: 'ProductReference__copyWrapper',
                onClick: this.onCopy,
                'data-tip': i18n.t(
                  'frontproductstream.product-reference.copy_to_clipboard.tooltip',
                  { defaultValue: 'Click to copy' },
                ),
                'data-for': `gtin-${reference}`,
                'data-testid': 'ProductReference__copyWrapper',
              }
            : {})}
        >
          <span
            data-testid="ProductReference__part"
            className="ProductReference__firstHalf"
          >
            {firstHalf}
          </span>
          <span
            data-testid="ProductReference__part"
            className="ProductReference__highlight"
          >
            {secondHalf}
          </span>
          {iconCopyDisplayable && (
            <i
              className="mdi mdi-content-copy ProductReference__iconCopyDisplayable"
              data-testid={`see-copy-icon-${reference}`}
              onClick={this.onCopy}
            />
          )}
        </span>
        {iconDisplayable && (
          <span
            onClick={() => this.trackDuplicatedProductIconClicked(isExportable)}
          >
            <i
              className={`mdi mdi-layers ${duplicatedIconClassName}`}
              data-testid={`see-duplicated-icon-${versionId}`}
              onClick={onClick}
              data-for={`duplicated-tooltip-${versionId}`}
              data-tip
            />
            <Tooltip
              id={`duplicated-tooltip-${versionId}`}
              className="ProductReference__Tooltip"
            >
              <div> {textFirsthalf}</div>
              <div> {textSecondhalf}</div>
            </Tooltip>
          </span>
        )}
        {copy && (
          <>
            <span
              className={classNames({
                ProductReference__copy: true,
                ProductReference__copyVisible: copied,
              })}
            >
              <span className="ProductReference__copyMessage">
                <i className="mdi mdi-checkbox-marked-circle-outline ProductReference__copyIcon" />{' '}
                {i18n.t(
                  'frontproductstream.product-reference.copy_to_clipboard.done_tooltip',
                  { defaultValue: 'Copied to clipboard' },
                )}
              </span>
            </span>
            {copied && <Tooltip id={`gtin-${reference}-${versionId}`} />}
          </>
        )}
      </div>
    );
  }
}

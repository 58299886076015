import storage from 'utils/storage';

export const fetchHistory = () => {
  const lastUsers = storage.getItem('logas-history');
  if (lastUsers) {
    try {
      const history = JSON.parse(lastUsers);
      return history;
    } catch {
      return [];
    }
  }
  return [];
};

export const addToHistory = (user) => {
  const lastUsers = storage.getItem('logas-history');
  let newLastUsers;
  if (lastUsers) {
    try {
      const history = JSON.parse(lastUsers);
      const cleanedHistory = history.filter((usr) => usr.id !== user.id);
      newLastUsers = [user, ...cleanedHistory].slice(0, 5);
    } catch {
      newLastUsers = [user];
    }
  } else {
    newLastUsers = [user];
  }
  storage.setItem('logas-history', JSON.stringify(newLastUsers));
};

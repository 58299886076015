export const REDUCER_KEY = 'generic';

// localStorage keys
export const FILTERS_CONFIG_KEY = 'reportingFiltersConfig';
export const ITEMS_PER_PAGE_KEY = 'reportingItemsPerPage';

// cell types
export const TEXT_CELL = 'text';
export const NUMBER_CELL = 'number';
export const SIMPLE_QUALITY_CELL = 'simple-quality';
export const FRACTION_CELL = 'fraction';
export const AVERAGE_TIME_CELL = 'avg-time';
export const TOTAL_PERCENTAGE_CELL = 'total-percentage';
export const NEUTRAL_CELL = 'neutral-quality';
export const NUMBER_CLICKABLE_CELL = 'number-clickable';

// report types
export const MANUFACTURER_VALIDATION_BY_RETAILER = {
  name: 'ValidationManuByRetailer',
  aggregation: 'ValidationManuAll',
};
export const RETAILER_VALIDATION_BY_MANUFACTURER = {
  name: 'ValidationRetailerByManu',
  aggregation: 'ValidationRetailerAll',
};

import { List, Map } from 'immutable';
import { all, call, put, select, takeEvery } from 'redux-saga/effects';
import { v4 as uuid } from 'uuid';

import { updateEntity } from 'actions/entity';
import { notificationError } from 'actions/notification';
import { ENTITY_TYPE_SHARINGUNIT } from 'constants/entities';
import { selectSharingUnits } from 'modules/sharing-units/selectors';
import { selectOrganization } from 'modules/user';
import {
  selectCurrentProductVersionGTIN,
  selectDisplayName,
  selectEditedProductVersion,
  selectProductId,
  selectProductKeyId,
} from 'reducers/productVersion';
import { referentialApi } from 'resources';
import buyingUnitApi from 'resources/buyingUnitApi';
import { fetchReferentials } from 'resources/referentialApi';
import searchApi from 'resources/searchApi';
import serviceProductApi from 'resources/serviceProductApi';
import { requestWithHeaders } from 'utils/api';
import i18n from 'utils/i18n';
import { get, toJsIfImmutable } from 'utils/immutable';
import { logError } from 'utils/logging';
import { takeFirst, withCatch } from 'utils/saga';
import { track } from 'utils/tracking';

import { defaultPriceWaterfall, levelTypePrice, priceGross } from './constants';
import {
  CREATED_PRICE_WATERFALL,
  CREATE_PRICE_WATERFALL,
  GET_BRACKET_TYPES,
  GET_LEVEL_TYPES,
  GET_TAX_LABELS,
  RECEIVE_BRACKET_TYPES,
  RECEIVE_LEVEL_ITEM_TYPES,
  RECEIVE_LEVEL_TYPES,
  RECEIVE_TAX_LABELS,
} from './events';
import { selectCurrencyForSharingUnit, selectTaxLabels } from './selectors';
import { getDefaultGrossPriceValue } from './utils';

export default function* priveWaterfallsMainSaga() {
  yield takeFirst(GET_TAX_LABELS, withCatch(getTaxLabels));
  yield takeFirst(GET_BRACKET_TYPES, withCatch(getBracketTypes));
  yield takeFirst(GET_LEVEL_TYPES, withCatch(getLevelTypes));
  yield takeEvery(CREATE_PRICE_WATERFALL, withCatch(createNewPriceWaterfall));
}

function* getTaxLabels() {
  const taxLabels = yield select(selectTaxLabels);
  if (taxLabels) {
    return;
  }
  const { referentials } = yield call(fetchReferentials, 'dutyfeetaxes');
  const referentialLabels = referentials
    .reduce((acc, r) => acc.set(r.get('code'), r.get('label')), Map())
    .toJS();
  const oldNames = referentials
    .reduce(
      (acc, r) =>
        r.getIn(['data', 'old_name'])
          ? acc.set(r.get('code'), r.getIn(['data', 'old_name']))
          : acc,
      Map(),
    )
    .toJS();
  const fieldNames = referentials
    .filter((r) => !!r.getIn(['data', 'old_name']))
    .reduce(
      (acc, r) => acc.set(r.getIn(['data', 'old_name']), r.get('label')),
      Map(),
    )
    .toJS();
  yield put({
    type: RECEIVE_TAX_LABELS,
    taxLabels: {
      fieldNames,
      referentialLabels,
      oldNames,
    },
  });
}

function* getBracketTypes() {
  const { result } = yield call(
    requestWithHeaders,
    referentialApi,
    'ReferentialGetList',
    'pricewaterfallbrackettypes',
  );
  yield put({ type: RECEIVE_BRACKET_TYPES, bracketTypes: result.data.data });
}

function* getLevelTypes() {
  const [priceWaterfallLevelTypes, buyingUnitPriceWaterfalItemTypes] =
    yield all([
      call(
        requestWithHeaders,
        referentialApi,
        'ReferentialGetList',
        'pricewaterfallleveltypes',
      ),
      call(requestWithHeaders, buyingUnitApi, 'ListReferentials', {
        referential: 'priceWatterFallLevelItemTypes',
      }),
    ]);
  yield put({
    type: RECEIVE_LEVEL_TYPES,
    levelTypes: toJsIfImmutable(priceWaterfallLevelTypes.result.data.data),
  });
  yield put({
    type: RECEIVE_LEVEL_ITEM_TYPES,
    levelItemTypes: buyingUnitPriceWaterfalItemTypes.result.data.data,
  });
}

const cleanPriceWaterfallForDuplication = (
  priceWaterfall,
  keepTypes,
  grossPrice,
) => {
  /* eslint-disable no-param-reassign */
  // Remove the product, just to be sure
  priceWaterfall.product.id = -1;

  // Here we:
  // - change the uuids
  // - remove the item types
  priceWaterfall.levels.forEach((level) => {
    level.uuid = uuid();
    level.items.forEach((item) => {
      item.uuid = uuid();
      if (!keepTypes) {
        delete item.type;
      }

      item.values.forEach((value) => {
        value.uuid = uuid();
        if (level.type.id === levelTypePrice.id) {
          if (item.type.id === priceGross.id && grossPrice) {
            value.value = grossPrice;
          } else {
            value.value = '';
          }
        }
      });
    });
  });

  // Clean the taxes
  priceWaterfall.taxes = [];
  priceWaterfall.hasLabel = false;
  priceWaterfall.label = '';
  /* eslint-enable no-param-reassign */
};

export const duplicatePriceWaterfall = async (
  { sharingUnitId, productId, organizationId },
  newProductId,
  targetOrganizationId,
  grossPrice,
) => {
  const res = await serviceProductApi.getSharingunit(sharingUnitId, {
    organization_id: organizationId,
  });
  const { sourceOrganization, targetOrganization, data, lastRequest } =
    res.data.data;
  let pw;
  if (
    organizationId === sourceOrganization.id &&
    lastRequest &&
    lastRequest.data &&
    lastRequest.data.priceWaterfalls &&
    lastRequest.data.priceWaterfalls.length > 0
  ) {
    pw = lastRequest.data.priceWaterfalls.find(
      (p) => p.product.id === productId,
    );
  } else if (data && data.priceWaterfalls && data.priceWaterfalls.length > 0) {
    pw = data.priceWaterfalls.find((p) => p.product.id === productId);
  }

  if (targetOrganization.id !== targetOrganizationId) {
    throw new Error('NO DUPLICATION');
  }

  cleanPriceWaterfallForDuplication(
    pw,
    targetOrganization.id === targetOrganizationId,
    grossPrice,
  );
  pw.product.id = newProductId;
  return pw;
};

export function* createNewPriceWaterfall({
  productId,
  sharingUnitId,
  targetOrganizationId,
  duplicateFrom,
}) {
  const sharingUnitCurrencyGetter = yield select(selectCurrencyForSharingUnit);
  const sharingUnitCurrency = sharingUnitCurrencyGetter(sharingUnitId);
  const sharingUnitCurrencyCode = sharingUnitCurrency.get('code');
  const organization = yield select(selectOrganization);
  const listingName = yield select(selectDisplayName);
  const productGtin = yield select(selectCurrentProductVersionGTIN);
  const currentProductId = yield select(selectProductId);
  const productKeyId = yield select(selectProductKeyId);

  let catalogPrice;
  if (currentProductId === productId) {
    // If we are creating a price waterfall for the current product, get the catalog price from
    // the store
    const versionData = yield select(selectEditedProductVersion);
    ({ catalogPrice } = versionData);
  } else {
    try {
      // Otherwise call search to retrieve it
      const response = yield call(
        searchApi.GetProductVersionList.bind(searchApi),
        {
          queries: {
            productIdsIn: [productId],
            sourceInclude: ['catalogPrice'],
          },
          limit: 1,
        },
      );
      catalogPrice = get(response, ['data', 'data', 0, 'catalogPrice']);
    } catch (e) {
      logError('An error occured while getting the catalog price.', e);
    }
  }

  const defaultGrossPriceValue = getDefaultGrossPriceValue(
    catalogPrice,
    sharingUnitCurrencyCode,
  );

  // Add the price waterfall to the sharing unit
  const sharingUnitsById = yield select(selectSharingUnits);
  const sharingUnit = sharingUnitsById.get(sharingUnitId);

  let newPriceWaterfall;
  let trackingSource;
  if (!duplicateFrom) {
    newPriceWaterfall = defaultPriceWaterfall(
      productId,
      defaultGrossPriceValue,
    );
    trackingSource = 'NEW';
  } else {
    try {
      newPriceWaterfall = yield call(
        duplicatePriceWaterfall,
        {
          sharingUnitId: duplicateFrom.value.sharingUnit_id,
          productId: duplicateFrom.value.product_id,
          organizationId: duplicateFrom.value.organization_id,
        },
        productId,
        targetOrganizationId,
        defaultGrossPriceValue,
      );

      yield call(track, {
        category: 'Price Waterfall',
        action: 'price_waterfall_duplicated',
        retailer_organization_id: targetOrganizationId,
        retailer_name: organization.get('nameLegal'),
        product_key_id: productKeyId,
        gtin: productGtin,
        listing_name: listingName,
        sharing_unit_id: sharingUnitId,
        duplicated_product_id: duplicateFrom.value.product_id,
        duplicated_sharing_unit_id: duplicateFrom.value.sharingUnit_id,
        duplicated_organization_id: duplicateFrom.value.organization_id,
      });

      trackingSource = 'DUPLICATED';
    } catch (e) {
      if (e.message === 'NO DUPLICATION') {
        yield put(
          notificationError(
            i18n.t(
              'Unable to duplicate price waterfall for a different target organization',
            ),
          ),
        );
        yield put({
          type: CREATED_PRICE_WATERFALL,
          productId,
        });
      }
      throw e;
    }
  }
  const model = 'priceWaterfalls';
  const value = (sharingUnit.getIn(['data', 'priceWaterfalls']) || List())
    .push(newPriceWaterfall)
    .toJS();
  yield put(
    updateEntity(
      model,
      value,
      sharingUnitId,
      ENTITY_TYPE_SHARINGUNIT,
      true, // isDirty
      true, // ignoreField
    ),
  );

  yield call(track, {
    category: 'pricewaterfall',
    action: 'pricewaterfall_created',
    product_id: productId,
    source: trackingSource,
  });

  yield put({
    type: CREATED_PRICE_WATERFALL,
    productId,
  });
}

export const RECEIVE_RULE_RESULTS = 'RECEIVE_RULE_RESULTS';
export const RECEIVE_MULTIPLE_ENTITY_RULE_RESULTS =
  'RECEIVE_MULTIPLE_ENTITY_RULE_RESULTS';
export const RECEIVE_SINGLE_ENTITY_RULE_RESULTS =
  'RECEIVE_SINGLE_ENTITY_RULE_RESULTS';
export const RECEIVE_RULE_RESULTS_FOR_SELECTED_RECIPIENTS =
  'RECEIVE_RULE_RESULTS_FOR_SELECTED_RECIPIENTS';
export const RECEIVE_RULE_RESULTS_FOR_ACTIVE_SHARES_RECIPIENTS =
  'RECEIVE_RULE_RESULTS_FOR_ACTIVE_SHARES_RECIPIENTS';
export const VALIDATION_RESET = 'VALIDATION_RESET';
export const ADD_STATIC_WARNING = 'ADD_STATIC_WARNING';
export const DELETE_STATIC_WARNING = 'DELETE_STATIC_WARNING';
export const UPDATE_ASSET_HAS_ERROR_BY_ASSET_ID =
  'UPDATE_ASSET_HAS_ERROR_BY_ASSET_ID';
export const UPDATE_MEDIA_HAS_ERROR_BY_RULE_ID =
  'UPDATE_MEDIA_HAS_ERROR_BY_RULE_ID';

import {
  all,
  call,
  cancelled,
  put,
  select,
  takeLatest,
} from 'redux-saga/effects';

import { bulkSeedData, setReadyForValidation } from 'actions/productversion';
import { seedData } from 'components/ui/form/field/utils/seed';
import { START_LOADING, STOP_LOADING } from 'constants/events/navigation';
import {
  FEATURE_FND_MULTILEVEL_FIELDS,
  FEATURE_HETEROGENEOUS_LOGISTICAL_UNIT,
  RELEASE_CASE_AS_BASE_UNIT,
  RELEASE_PACK_AS_BASE_UNIT,
} from 'modules/feature-flag/constants';
import {
  selectHasJsonStoreInputFeature,
  selectHasReleaseProductPageRefractStructureFetching,
  selectHasTextileRelease,
} from 'modules/feature-flag/selectors';
import {
  selectFlags,
  selectIsRetailer,
  selectIsThirdParty,
  selectOrganizationId,
} from 'modules/user';
import { VIEW_AS_FILTER_RULESETS_FIELDS } from 'modules/view-as/events';
import {
  selectFilterRuleSetsFields,
  selectSelectedRecipients,
} from 'modules/view-as/selectors';
import { selectAvailableFields } from 'modules/view-as/selectors/fields';
import {
  selectCanUpdateProduct,
  selectContentOwnerId,
  selectCurrentLanguage,
  selectEditedProductVersion,
  selectIsBaseUnit,
  selectIsConsumerUnit,
  selectIsDisplayUnit,
  selectIsHeterogeneousLogisticalUnit,
  selectIsMadeOf,
  selectIsOriginalProductHeterogeneousUnit,
  selectKindId,
  selectTargetMarketId,
  selectTypePackagingId,
} from 'reducers/productVersion';
import { size } from 'utils/immutable';
import { withCatch } from 'utils/saga';

import {
  VIEW_AS_ADD_RECIPIENT,
  VIEW_AS_REMOVE_RECIPIENT,
} from '../../view-as/events';
import {
  DISPLAY_GROUP_ROOTS,
  fetchDisplayGroups,
  fetchLegacyDisplayGroups,
} from '../api';
import {
  FETCH_DISPLAY_GROUPS,
  FORCE_EDITABLE_DISPLAY_GROUPS,
  RECEIVE_DISPLAY_GROUPS,
  RESET_EDITABLE_DISPLAY_GROUPS,
  RESET_SELECTED_HETEROGENEOUS_UNIT,
} from '../constants';
import {
  selectAreDisplayGroupsEditable,
  selectShouldFetchHeterogeneousUnitFields,
} from '../selectors';

const ACTION_TO_NOT_FETCH_WITH_LEGACY_DISPLAY_GROUPS = [
  VIEW_AS_ADD_RECIPIENT,
  VIEW_AS_REMOVE_RECIPIENT,
  VIEW_AS_FILTER_RULESETS_FIELDS,
];

export function* fetchDisplayGroupsSaga(eventType) {
  let started = false;
  try {
    const isThirdParty = yield select(selectIsThirdParty);
    const organizationId = yield select(selectOrganizationId);
    const contentOwnerId = yield select(selectContentOwnerId);
    const canUpdateProduct = yield select(selectCanUpdateProduct);
    const isConsumerUnit = yield select(selectIsConsumerUnit);
    const isBaseUnit = yield select(selectIsBaseUnit);
    const isMadeOf = yield select(selectIsMadeOf);
    const isDisplayUnit = yield select(selectIsDisplayUnit);
    const isRetailer = yield select(selectIsRetailer);
    const kindId = yield select(selectKindId);
    const targetMarketId = yield select(selectTargetMarketId);
    const typePackagingId = yield select(selectTypePackagingId);
    const hasTextileRelease = yield select(selectHasTextileRelease);
    const areDisplayGroupsEditable = yield select(
      selectAreDisplayGroupsEditable,
    );
    const isHeterogeneousLogisticalUnit = yield select(
      selectIsHeterogeneousLogisticalUnit,
    );
    const isOriginalProductHeterogeneousLogisticalUnit = yield select(
      selectIsOriginalProductHeterogeneousUnit,
    );
    const flags = yield select(selectFlags);
    const shouldFetchHeterogeneousUnitFields = yield select(
      selectShouldFetchHeterogeneousUnitFields,
    );
    const hasJsonStoreInput = yield select(selectHasJsonStoreInputFeature);
    const hasReleaseProductPageRefractStructureFetching = yield select(
      selectHasReleaseProductPageRefractStructureFetching,
    );

    const hideAdditionalFields = yield select(selectFilterRuleSetsFields);
    const recipientIds = yield select(selectSelectedRecipients);
    const immutableRuleSetsByEntity = yield select(selectAvailableFields);

    const ruleSetsByEntity = immutableRuleSetsByEntity.toJS();

    const queries = {
      is_consumer_unit: isConsumerUnit,
      is_display_unit: isDisplayUnit,
      kind_id: kindId,
      type_packaging_id: typePackagingId,
      targetmarket_ids: [targetMarketId],
      override_display_groups: hasTextileRelease,
      organization_id: isThirdParty ? organizationId : contentOwnerId,
      is_read_only: areDisplayGroupsEditable ? false : !canUpdateProduct,
      is_retailer: isRetailer,
      is_heterogeneous_unit:
        flags[FEATURE_HETEROGENEOUS_LOGISTICAL_UNIT] &&
        (isHeterogeneousLogisticalUnit ||
          isOriginalProductHeterogeneousLogisticalUnit ||
          shouldFetchHeterogeneousUnitFields),
      is_base_unit:
        (flags[RELEASE_CASE_AS_BASE_UNIT] ||
          flags[RELEASE_PACK_AS_BASE_UNIT]) &&
        isBaseUnit &&
        size(isMadeOf) === 0,
      use_json_store_input: hasJsonStoreInput,
    };

    if (
      !hasReleaseProductPageRefractStructureFetching &&
      ACTION_TO_NOT_FETCH_WITH_LEGACY_DISPLAY_GROUPS.includes(eventType)
    ) {
      return;
    }

    yield put({ type: START_LOADING });
    started = true;

    let displayGroups = [];

    const callFetchDisplayGroups = (displayGroupType) =>
      call(fetchDisplayGroups, {
        hideAdditionalFields,
        recipientIds: recipientIds.map((obj) => obj.get('id')).toArray(),
        ruleSetsByEntity,
        displayGroupType,
        kindId,
        typePackagingId,
        isRetailer: isRetailer,
        isBaseUnit,
      });

    const unitType = isConsumerUnit
      ? DISPLAY_GROUP_ROOTS.CONSUMER_UNIT
      : DISPLAY_GROUP_ROOTS.DISPLAY_UNIT;

    if (hasReleaseProductPageRefractStructureFetching) {
      const [responseLegacyDisplayGroups, responseUnitDisplayGroups] =
        yield all([
          call(fetchLegacyDisplayGroups, queries),
          callFetchDisplayGroups(unitType),
        ]);

      const MEDIA_DISPLAY_GROUP_ID = 230;
      const SPECIFIC_FIELDS_GROUP_ID = 259;

      const UNWANTED_LIST_DISPLAY_GROUP_IDS = [
        MEDIA_DISPLAY_GROUP_ID,
        SPECIFIC_FIELDS_GROUP_ID,
      ];

      const legacyMediaDisplayGroup = responseLegacyDisplayGroups.data.filter(
        (d) => UNWANTED_LIST_DISPLAY_GROUP_IDS.includes(d.id),
      );

      const responseUnitDisplayGroupsData =
        responseUnitDisplayGroups.data.filter(
          (d) => !UNWANTED_LIST_DISPLAY_GROUP_IDS.includes(d.id),
        );

      displayGroups = [
        ...responseUnitDisplayGroupsData,
        ...legacyMediaDisplayGroup,
      ];
    } else {
      const legacyDisplayGroups = yield call(fetchLegacyDisplayGroups, queries);

      displayGroups = legacyDisplayGroups.data;
    }

    yield put({ type: STOP_LOADING });
    started = false;

    // Uncomment this to test the page as readOnly.
    // displayGroups = displayGroups.map(getAsReadOnly);

    const multiLevel = flags[FEATURE_FND_MULTILEVEL_FIELDS];

    const versionData = yield select(selectEditedProductVersion);
    const currentLanguage = yield select(selectCurrentLanguage);
    const updates = seedData(
      versionData,
      displayGroups,
      currentLanguage,
      [],
      multiLevel,
    );
    yield put(bulkSeedData(updates));
    yield put(setReadyForValidation());
    yield put({ type: RECEIVE_DISPLAY_GROUPS, list: displayGroups });

    if (shouldFetchHeterogeneousUnitFields) {
      yield put({ type: RESET_SELECTED_HETEROGENEOUS_UNIT });
    }
  } finally {
    if ((yield cancelled()) && started) {
      yield put({ type: STOP_LOADING });
    }
  }
}

export function* fetchSaga() {
  yield takeLatest([FETCH_DISPLAY_GROUPS], withCatch(fetchDisplayGroupsSaga));
  yield takeLatest(
    [FORCE_EDITABLE_DISPLAY_GROUPS, RESET_EDITABLE_DISPLAY_GROUPS],
    withCatch(fetchDisplayGroupsSaga),
  );
  yield takeLatest(
    [VIEW_AS_FILTER_RULESETS_FIELDS],
    withCatch((action) => fetchDisplayGroupsSaga(action.type)),
  );
  yield takeLatest(
    [VIEW_AS_ADD_RECIPIENT, VIEW_AS_REMOVE_RECIPIENT],
    withCatch((action) => fetchDisplayGroupsSaga(action.type)),
  );
}

export default fetchSaga;

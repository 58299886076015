export const APPLICATION_TYPE_UNKNOWN = {
  name: 'Unknown',
  id: 0,
};
export const APPLICATION_TYPE_FEATURE = {
  name: 'Feature',
  id: 1,
};
export const APPLICATION_TYPE_CONTRIBUTION = {
  name: 'Contribution',
  id: 2,
};
export const APPLICATION_TYPE_CATALOG_ACCESS = {
  name: 'Catalog Access',
  id: 3,
};
export const APPLICATION_TYPE_ANALYTICS = {
  name: 'Analytics',
  id: 4,
};
export const APPLICATION_TYPE_CONNECTOR = {
  name: 'Connector',
  id: 5,
};

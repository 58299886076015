import { call, put, select, takeEvery, takeLatest } from 'redux-saga/effects';

import { notificationError, notificationSuccess } from 'actions/notification';
import { notificationApi } from 'modules/notification-list';
import { sourcingLabelApiImmutable } from 'resources/sourcingLabelApi';
import i18n from 'utils/i18n';
import { logError } from 'utils/logging';

import { closeModal, setLoading, updateAllProducts } from '../actions';
import { INIT, SEND } from '../constants/types';
import {
  selectAllProducts,
  selectEmails,
  selectMessage,
  selectSourcingLabel,
} from '../selectors';
import { canShareSourcingLabel } from '../utils';

function* sendEvent() {
  try {
    const emails = yield select(selectEmails);
    const message = yield select(selectMessage);
    const sourcingLabel = yield select(selectSourcingLabel);
    const allProducts = yield select(selectAllProducts);

    yield put(setLoading(true));
    if (allProducts) {
      yield call(
        notificationApi.promoteProducts.bind(notificationApi),
        emails.toJS(),
        message,
      );
    } else {
      yield call(
        sourcingLabelApiImmutable.share.bind(sourcingLabelApiImmutable),
        {
          emails,
          message,
          productSegmentIds: [sourcingLabel.id],
        },
      );
    }
    yield put(closeModal());
    yield put(
      notificationSuccess(
        i18n.t(
          'frontproductstream.promote_products_modal.invitation_sent.text',
          {
            defaultValue: 'Your invitations have been sent',
          },
        ),
      ),
    );
  } catch (exc) {
    yield put(
      notificationError(
        i18n.t(
          'frontproductstream.promote_products_modal.invitation_sent.error',
          {
            defaultValue: 'An error occured while sending your invitations',
            context: 'modal',
          },
        ),
      ),
    );
    logError(exc);
  }
  yield put(setLoading(false));
}

function* initModal() {
  const sourcingLabelShare = yield select(canShareSourcingLabel);
  if (!sourcingLabelShare) {
    yield put(updateAllProducts(true));
  }
}

export default function* promoteProductsModalMainSaga() {
  yield takeLatest(SEND, sendEvent);
  yield takeEvery(INIT, initModal);
}

import { registerModuleReducer } from 'utils/modules/reducer';
import { registerSaga } from 'utils/modules/saga';

import { ProductShareDiff } from './product-share-diff';
import reducer from './reducer';
import mainSaga from './sagas';

registerModuleReducer('productShareDiff', reducer);
registerSaga(mainSaga);

export { ProductShareDiff };

import { cancelPromise } from 'utils';
import { createAction } from 'utils/redux';

import {
  BULK_ACTION_DONE,
  BULK_ACTION_IN_PROGRESS,
  BULK_ACTION_RESET,
  BULK_ACTION_SHOW,
  BulkActionResetAction,
} from './types';

let promise;

export const showBulkAction = createAction(BULK_ACTION_SHOW);
export const bulkActionInProgress = createAction(BULK_ACTION_IN_PROGRESS);
export const bulkActionDone = createAction(BULK_ACTION_DONE);

export const resetBulkAction = (): BulkActionResetAction => {
  cancelPromise(promise);
  return { type: BULK_ACTION_RESET };
};

import { createPortal } from 'react-dom';
import { useSelector } from 'react-redux';

import { Button } from '@alkem/react-ui-button';

import Notification from 'components/ui/notification';
import { selectCurrentAssetTab } from 'reducers/media';
import i18n from 'utils/i18n';

import { AssetTypeV2 } from '../../../AssetV2/types';

import { AssetLibrary } from './AssetLibrary';
import { AssetModalSubHeader } from './AssetModalSubHeader';
import { ImportAsset } from './ImportAsset';
import styles from './seeEditAllAsset.module.scss';

type SeeEditAllAssetProps = {
  onClose: () => void;
  items: AssetTypeV2[];
  productKeyId: number;
  statusIsPending: boolean;
  onToggleArchived: () => void;
  updateEntities: (mediaType?: string, assetType?: number) => void;
  isShowingExpiredMedia: boolean;
  expiredPictureCount: number;
};

export const SeeEditAllAsset = ({
  onClose,
  items,
  productKeyId,
  updateEntities,
}: SeeEditAllAssetProps) => {
  const currentAssetTab = useSelector(selectCurrentAssetTab);

  return createPortal(
    <div className={styles.modalOverlay} onClick={onClose}>
      <div className={styles.modal} onClick={(e) => e.stopPropagation()}>
        <div className={styles.header}>
          <span>
            {i18n.t('frontproductstream.asset_list.asset_modal.title', {
              defaultValue: 'Select a digital asset',
            })}
          </span>
          <button className={styles.close} onClick={onClose}>
            <i className="mdi mdi-close" />
          </button>
        </div>
        <AssetModalSubHeader />
        <Notification context="modal" />
        {currentAssetTab === 'DIGITAL_ASSET_LIBRARY_TAB' && (
          <AssetLibrary
            productKeyId={productKeyId}
            items={items}
            updateEntities={updateEntities}
          />
        )}
        {currentAssetTab === 'IMPORT_TAB' && <ImportAsset />}
        <div className={styles.footer}>
          <Button
            link
            onClick={onClose}
            content={i18n.t(
              'frontproductstream.asset_list.see_and_edit_all_media.button',
              { defaultValue: 'Close' },
            )}
          />
        </div>
      </div>
    </div>,
    document.body,
  );
};

import { get } from 'lodash';
import { MemoizedFn } from 'memoize-one';
import { useSelector } from 'react-redux';

import { priceVAT } from 'modules/price-waterfalls/constants';
import { selectCurrencyForRecipient } from 'modules/price-waterfalls/selectors';
import { displayPriceValue } from 'modules/price-waterfalls/utils';
import i18n from 'utils/i18n';
import { get as getImmut } from 'utils/immutable';

interface Props {
  pricewaterfall: any;
  targetOrganizationId: number;
}

export function NetPriceLabel({ pricewaterfall, targetOrganizationId }: Props) {
  const netPrice = pricewaterfall.levels?.find(
    (level) => get(level, 'items.0.type.id') === priceVAT.id,
  );
  const userCurrencySymbol = getImmut(
    (useSelector(selectCurrencyForRecipient) as MemoizedFn<any>)(
      targetOrganizationId,
    ),
    'symbol',
  );
  const value = get(netPrice, 'items.0.values.0.value');
  if (!value || !`${value}`.length) {
    return null;
  }
  if (!Number(value)) {
    return (
      <span>
        {' '}
        {`${priceVAT.label}: ${i18n.t(
          'frontproductstream.price_waterfalls.collapsible_pricewaterfall_price_encrypted.label',
          { defaultValue: 'Encrypted' },
        )}`}
      </span>
    );
  }
  return (
    <span>{`${priceVAT.label}: ${displayPriceValue(value)} ${
      isNaN(Number(value)) ? '' : userCurrencySymbol
    }`}</span>
  );
}

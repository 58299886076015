import { dataOpsPatchKey } from '../selectors';
import { DataOpsNewPatch, DataOpsPatch, DataOpsState } from '../types';

export const patchKey = (
  patch: DataOpsPatch | DataOpsNewPatch,
  state: DataOpsState,
) =>
  dataOpsPatchKey(
    patch['appliesOnEntityId'] ||
      state.productKeyIdToEntityId[patch.productKeyId],
    patch.fieldName,
  );

import { NotificationContextEnum } from '@alkem/micro-frontend-tools';

import {
  ADD_NOTIFICATION,
  NOTIFICATION_CLEAR,
  NOTIFICATION_CLEAR_RESET,
  NOTIFICATION_DISPLAYED,
  NOTIFICATION_HIDDEN,
  REMOVE_NOTIFICATION,
} from 'constants/events/notification';
import { ERROR, SUCCESS } from 'constants/notification';

/**
 * @param {import('react').ReactNode} message
 * @param {object} options
 * @param {string=} options.context
 * @param {boolean=} options.sticky
 * @param {any=} options.error
 */
export const notificationError = (
  message,
  { context = NotificationContextEnum.BASE, sticky = false, error = null } = {},
) => ({
  type: ADD_NOTIFICATION,
  style: ERROR,
  message,
  context,
  sticky,
  error,
});

/**
 * @param {import('react').ReactNode} message
 * @param {object} options
 * @param {string=} options.context
 * @param {boolean=} options.sticky
 */
export const notificationSuccess = (
  message,
  { context = NotificationContextEnum.BASE, sticky = false } = {},
) => ({
  type: ADD_NOTIFICATION,
  style: SUCCESS,
  message,
  context,
  sticky,
});

export const notificationRemove = (index) => ({
  type: REMOVE_NOTIFICATION,
  index,
});

export const notificationDisplayed = () => ({
  type: NOTIFICATION_DISPLAYED,
});

export const notificationHidden = () => ({
  type: NOTIFICATION_HIDDEN,
});

export const notificationClear = () => ({
  type: NOTIFICATION_CLEAR,
});

export const notificationClearReset = () => ({
  type: NOTIFICATION_CLEAR_RESET,
});

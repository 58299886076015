import { Moment } from 'moment';

import { ResponseWithData } from '@alkem/sdk-dashboard';

import RfpApi from 'resources/rfpApi';

import { Event } from '../../types';

export const list = async (
  organization_id: number,
  aggregationQuery?: any,
  eventsStatuses?: string[],
  answersStatuses?: string[],
  fromDate?: Moment,
  toDate?: Moment,
  search?: string,
  suppliers?: number[],
  limit?: number,
  offset?: number,
): Promise<
  ResponseWithData<{
    events: Event[];
    totalResults: number;
  }>
> => {
  const body: any = {
    organization_id,
  };
  if (eventsStatuses && eventsStatuses.length > 0) {
    body.statuses = eventsStatuses.join(',');
  }
  if (answersStatuses && answersStatuses.length > 0) {
    body.answer_statuses = answersStatuses.join(',');
  }
  if (fromDate) {
    body.from_date = fromDate.format('YYYY-MM-DD');
  }
  if (toDate) {
    body.to_date = toDate.format('YYYY-MM-DD');
  }
  if (search) {
    body.search = search;
  }
  if (suppliers) {
    body.source_ids = suppliers;
  }
  if (limit) {
    body.limit = limit;
  }
  if (offset) {
    body.offset = offset;
  }
  if (aggregationQuery) {
    body.aggregations = aggregationQuery;
  }
  body.with_referentials = true;
  return RfpApi.post('/rfp/v2/event/list', body);
};

export const post = async (
  uri: string,
  body: any,
): Promise<ResponseWithData<any>> => {
  return RfpApi.post(uri, body);
};

export const deleteEvent = async (
  eventId: number,
): Promise<ResponseWithData<any>> => {
  return RfpApi.post('/rfp/v1/events/delete', { event_ids: [eventId] });
};

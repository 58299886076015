import classNames from 'classnames';
import { useRef } from 'react';
import { v4 as uuid } from 'uuid';

import { Ellitips } from '@alkem/react-ui-ellitips';
import { Spinner } from '@alkem/react-ui-spinner';

import Image from 'components/ui/image';
import { getDefaultDisplayName } from 'core/api/productversion';
import i18n from 'utils/i18n';

import HierarchiesSelector from './hierarchiesSelector';
import './listProductHierarchies.scss';

interface ListProductHierarchiesProps {
  productKeyIds: number[];
  recipientId: number;
  productsHierarchies: {
    gtin: string;
    product_key_id: number;
    icon_url: string;
    hierarchies: {
      is_shared: boolean;
      typePackaging: {
        code: string;
      };
    }[];
  }[];
  onHierarchyChange(product_key_id: number): () => void;
  onRemoveProduct(product_key_id: number): void;
  locales: object;
}

function ListProductHierarchies(props: ListProductHierarchiesProps) {
  const tipRefId = useRef<string | null>(null);
  if (!tipRefId.current) {
    tipRefId.current = uuid();
  }
  const { productsHierarchies, locales } = props;

  const shouldSetBackgroundToGray = (product) => {
    // Yes if there is no hierarchy
    if (product.hierarchies.length === 0) {
      return true;
    }

    // Yes if all hierarchies are shared
    return product.hierarchies.filter((h) => !h.is_shared).length === 0;
  };

  const renderHierarchies = () => {
    return (
      <table>
        <thead>
          <tr>
            <th></th>
            <th>{i18n.t('Product')}</th>
            <th>{i18n.t('Product GTIN')}</th>
            <th>{i18n.t('Available Logistical Hierarchies')}</th>
            <th></th>
          </tr>
        </thead>
        <tbody>
          {productsHierarchies.map((product) => {
            return (
              <tr
                key={product.gtin}
                className={classNames({
                  'ListProductHierarchies__row--grayed':
                    shouldSetBackgroundToGray(product),
                })}
              >
                <td className="ListProductHierarchies__image">
                  <Image src={product.icon_url} width="64" height="64" />
                </td>
                <td className="ListProductHierarchies__name">
                  <Ellitips
                    label={getDefaultDisplayName(product, locales)}
                    lines={2}
                    id={tipRefId.current || ''}
                  />
                </td>
                <td className="ListProductHierarchies__gtin">{product.gtin}</td>
                <td className="ListProductHierarchies__hierarchies">
                  <HierarchiesSelector
                    hierarchies={product.hierarchies}
                    onChange={props.onHierarchyChange(product.product_key_id)}
                  />
                </td>
                <td
                  className="ListProductHierarchies__removeButton"
                  onClick={() => props.onRemoveProduct(product.product_key_id)}
                >
                  <i className="mdi mdi-close" />
                </td>
              </tr>
            );
          })}
        </tbody>
      </table>
    );
  };

  return (
    <div className="ListProductHierarchies">
      {productsHierarchies.length ? (
        <>
          <div className="ListProductHierarchies__Title">
            {i18n.t(
              'Select a logistical hierarchy from each product to be attributed to the retailer in a new listing.',
            )}
          </div>
          <div className="ListProductHierarchies__body">
            {renderHierarchies()}
          </div>
        </>
      ) : (
        <div className="ListProductHierarchies__spinner">
          <Spinner big />
        </div>
      )}
    </div>
  );
}

export default ListProductHierarchies;

import { LazyTooltip } from '@alkem/react-ui-tooltip';

import { ExportableStatus } from 'types';
import i18n from 'utils/i18n';

import './index.scss';

interface CatalogExportableTagColumnProps {
  productVersionId: number;
  status: ExportableStatus;
}

const ClassNameByStatus = {
  [ExportableStatus.NOT_EXPORTABLE]: 'NotExportable',
  [ExportableStatus.NOT_APPLICABLE]: 'NotApplicable',
  [ExportableStatus.EXPORTABLE]: 'Exportable',
};

const TitleByStatus = {
  [ExportableStatus.NOT_EXPORTABLE]: i18n.t('Not Exportable'),
  [ExportableStatus.NOT_APPLICABLE]: i18n.t('Not applicable'),
  [ExportableStatus.EXPORTABLE]: i18n.t('Exportable'),
};

export default function CatalogExportableTagColumn({
  productVersionId,
  status,
}: CatalogExportableTagColumnProps) {
  const tooltipId = `catalog-exportable-tag-column-tooltip-${productVersionId}`;
  return (
    <LazyTooltip
      id={tooltipId}
      className={`CatalogExportableTagColumn CatalogExportableTagColumn--${ClassNameByStatus[status]}`}
      tooltipLabel={TitleByStatus[status]}
    >
      <i className="mdi mdi-export" />
    </LazyTooltip>
  );
}

CatalogExportableTagColumn.center = true;

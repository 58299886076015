import { Map } from 'immutable';
import { once } from 'lodash';
import memoize from 'memoize-one';
import { memo, useCallback, useEffect, useState } from 'react';

import { buildFiltersFromQuery } from 'core/modules/list/utils/filters';
import { FilterChangeEvent } from 'types';
import { get, size } from 'utils/immutable';

import CollapsibleAdvancedFilter from '../advanced';

type MissingFilter = {
  label: string;
  key: string;
};
type Props = {
  selectedFilterMap?: Map<string, any>;
  aggregations?: Map<string, any>;
  filterKey: string;
  filterLabel: string;
  filterConfig: any;
  onFilter: (key: string, query: string) => void;
  onChange: (
    filters: FilterChangeEvent | FilterChangeEvent[],
    fromQuery?: boolean,
  ) => void;
  onChangePage?: (key: string, page: number) => void;
  onCollapse: (filterKey: string, collapsed: string) => void;
  onSort?: (key: string) => void;
  filterQueryValues?: any[];
  searchPlaceholder?: string;
  missingFilter?: MissingFilter;
};

export const SuppliersFilter = memo((props: Props) => {
  const {
    aggregations = Map(),
    selectedFilterMap = Map(),
    filterQueryValues = [],
    filterKey,
    filterLabel,
    filterConfig = Map(),
    searchPlaceholder,
    missingFilter,
    onChange,
    onCollapse,
    onFilter,
    onChangePage,
    onSort,
  } = props;

  const [isSortByDocCount, setIsSortByDocCount] = useState(false);

  const selectOptions = isSortByDocCount
    ? memoize((aggs) => aggs.valueSeq().toList())
    : memoize((aggs) => {
        const listAggs = aggs.valueSeq().toList();
        const supNoMatched = listAggs.find(
          (item) => get(item, 'id').toString() === '0',
        );
        if (!supNoMatched) {
          return listAggs;
        }
        const spliceList = listAggs.delete(
          listAggs.findIndex((item) => get(item, 'id').toString() === '0'),
        );
        return spliceList.unshift(supNoMatched);
      });

  const shouldUpdateSelectionFromQuery = useCallback(
    () =>
      aggregations && !aggregations.isEmpty() && size(selectedFilterMap) === 0,
    [aggregations, selectedFilterMap],
  );

  const updateSelectionFromQuery = once(() => {
    buildFiltersFromQuery({
      filterQueryValues,
      filterList: selectOptions(aggregations),
      filterKey: filterKey,
      selectFilterValue: (filter) => filter.get('id'),
      selectFilterLabel: (filter) => `${filterLabel}: ${filter.get('id')}`,
      selectFilterData: (filter) => [filter.get('id')],
    }).then((filtersFromQuery) => {
      onChange(filtersFromQuery, true);
    });
  });

  const getSelectLabel = (filter) => {
    const id = filter.get('id');
    if (id.toString() === missingFilter?.key) {
      return missingFilter?.label;
    }

    return filter.get('id');
  };

  useEffect(() => {
    if (shouldUpdateSelectionFromQuery()) {
      updateSelectionFromQuery();
    }
  }, [aggregations, shouldUpdateSelectionFromQuery, updateSelectionFromQuery]);

  const onSortClicked = useCallback(
    (fk: string) => {
      setIsSortByDocCount(true);
      return onSort && onSort(fk);
    },
    [onSort],
  );

  return (
    <CollapsibleAdvancedFilter
      id="list-filter-suppliers"
      filterKey={filterKey}
      filterLabel={filterLabel}
      filters={selectOptions(aggregations)}
      selectedFilterMap={selectedFilterMap}
      aggregations={aggregations}
      searchPlaceholder={searchPlaceholder}
      searchQuery={filterConfig.get('query')}
      page={filterConfig.get('page')}
      collapsed={filterConfig.get('collapsed')}
      onChange={onChange}
      onCollapse={onCollapse}
      onFilter={onFilter}
      onChangePage={onChangePage}
      onSort={onSortClicked}
      withPagination
      hasMissingFilter
      missingFilterKey={missingFilter?.key}
      selectors={{
        selectId: (filter) => filter.get('id'),
        selectLabel: getSelectLabel,
      }}
    />
  );
});

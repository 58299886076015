import { Action } from 'redux';

import { ProductsMap } from '../types';

export const BULK_UNSHARE_SHOW = 'BULK_UNSHARE_SHOW';
export const BULK_UNSHARE_START = 'BULK_UNSHARE_START';

export type Payload<P> = { payload: P };

type ActionWithGuaranteedPayload<T, P = unknown> = Action<T> & {
  payload: P;
};

export type ProductsPayload = {
  // products to unshare
  productVersions: ProductsMap;
  // unshare only with retailer id==recipientId, unshare with all if recipientId == null
  recipientId: number | null;
};

export type BulkUnshareShowAction = ActionWithGuaranteedPayload<
  typeof BULK_UNSHARE_SHOW,
  ProductsPayload
>;

export type BulkUnshareStartAction = ActionWithGuaranteedPayload<
  typeof BULK_UNSHARE_START
>;

import { RuleApplicationStatus } from 'modules/validation/constants';

export const getStatusDisplayText = (status: RuleApplicationStatus) => {
  return {
    [RuleApplicationStatus.OK]: 'OK',
    [RuleApplicationStatus.KO]: 'KO',
    [RuleApplicationStatus.NOT_EXECUTED]: 'NOT EXECUTED',
    [RuleApplicationStatus.BYPASSED]: 'BYPASSED',
    [RuleApplicationStatus.ERROR]: 'ERROR',
  }[status];
};

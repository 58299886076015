import { list, mapContains } from 'react-immutable-proptypes';

import ReportingStackedBarChartItem from './bar-item';
import './index.scss';

function ReportingStackedBarChart({ chart }) {
  const values = chart.get('values');
  const total = values.reduce((acc, data) => acc + data.get('value'), 0);
  if (total <= 0) {
    return null;
  }
  const formattedValues = values
    .filter((data) => data.get('value') >= 0)
    .map((data) => data.set('percentage', (data.get('value') * 100) / total));
  return (
    <>
      <div className="ReportingChart ReportingStackedBarChart__values">
        {formattedValues.map((data) => (
          <ReportingStackedBarChartItem
            key={`stacked-bar-chart-${JSON.stringify(data)}`}
            value={data.get('value')}
            percentage={data.get('percentage')}
            color={data.get('color')}
          />
        ))}
      </div>
      <div className="ReportingStackedBarChart__legend">
        {formattedValues.map((data) => (
          <span
            key={`stacked-bar-legend-${JSON.stringify(data)}`}
            className="ReportingStackedBarChart__legendItem"
          >
            <i style={{ backgroundColor: data.get('color') }} />
            <span>{data.get('label')}</span>
          </span>
        ))}
      </div>
    </>
  );
}

ReportingStackedBarChart.propTypes = {
  chart: mapContains({
    values: list.isRequired,
  }).isRequired,
};

export default ReportingStackedBarChart;

import { useMemo } from 'react';

import { usePictureForm } from 'components/ui/form/asset-list/AssetV2/hooks/usePictureForm';
import { useValidatePackshotUpdate } from 'components/ui/form/asset-list/AssetV2/hooks/useValidatePackshotUpdate';
import {
  EnhancedAssetV2,
  PictureAssetV2,
} from 'components/ui/form/asset-list/AssetV2/types';
import { useAssetContext } from 'components/ui/form/asset-list/context';
import FormGroup from 'components/ui/form/form-group';
import { ENTITY_TYPE_PRODUCT_PICTURE } from 'constants/entities';
import { HIDDEN_PICTURE_FIELDS } from 'constants/productPicture';

type AssetPictureJsonInfoInputsProps = {
  asset: EnhancedAssetV2<PictureAssetV2>;
  formMethods: ReturnType<typeof usePictureForm>['formMethods'];
};

const UNUSED_ENTITY_INDEX = -1;

export const AssetPictureJsonInfoInputs = ({
  asset,
  formMethods,
}: AssetPictureJsonInfoInputsProps) => {
  const { formGroup, entityId } = useAssetContext();

  const canChangePackshot = useValidatePackshotUpdate();

  const handleChange = (name, value) => {
    if (name === 'isPackshot' && !canChangePackshot()) {
      return;
    }

    formMethods.setValue(name, value, { shouldDirty: true });
  };

  const cleanFields = useMemo(() => {
    const formGroupItems = formGroup.actions.find(
      (action) => action.render === 'addPicture',
    )?.items;

    return formGroupItems
      ? formGroupItems.filter((localField) => {
          if (localField.model === 'sequenceNumber') {
            return !!asset.id;
          }

          if (HIDDEN_PICTURE_FIELDS.includes(localField.model || '')) {
            return false;
          }

          return true;
        })
      : [];
  }, [asset.id, formGroup.actions]);

  const allFormInputs = formMethods.watch();

  const parentFormGroup = useMemo(
    () => ({
      kind: 'DisplayGroup',
      label: 'Picture',
      items: cleanFields,
      id: `pictureForm-${asset.product_key_id}`,
    }),
    [asset.product_key_id, cleanFields],
  );

  if (!cleanFields) return null;

  return (
    <FormGroup
      entity={allFormInputs}
      entityId={entityId}
      entityKind={ENTITY_TYPE_PRODUCT_PICTURE}
      formGroup={parentFormGroup}
      deepIndex={2}
      showTitle={false}
      onChangeField={handleChange}
      noDispatch={true}
      entityIndex={UNUSED_ENTITY_INDEX}
      disableDataOps
    />
  );
};

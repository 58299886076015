import { List, Map, Set, fromJS } from 'immutable';
import { createReducer } from 'redux-create-reducer';

import { DELETE_PRODUCT_VERSION } from 'constants/events/productversion';

import {
  GROUP_STATUS_ACTIVE,
  GROUP_STATUS_INACTIVE,
  GROUP_STATUS_PREPARING,
} from './constants';
import {
  ACTIVATE_GROUPS_SUCCESS,
  ADD_RECIPIENTS,
  ADD_RECIPIENTS_FAILURE,
  ADD_RECIPIENTS_SUCCESS,
  DEACTIVATE_GROUPS_FAILURE,
  DEACTIVATE_GROUPS_SUCCESS,
  FETCH_SUMMARY,
  FETCH_SUMMARY_FAILURE,
  OPEN_UNSHARE_PRODUCT,
  PREPARE_FOR_GROUP,
  PREPARE_FOR_GROUP_FAILURE,
  PREPARE_FOR_GROUP_SUCCESS,
  RECEIVE_SUMMARY,
  RECEIVE_VALIDATION_DATA,
  UNSHARE_PRODUCT,
  UNSHARE_PRODUCT_DONE,
} from './events';
import { splitGroupMembers } from './utils';

const initialState = fromJS({
  isLoading: false,
  failedFetching: false,
  groups: [],
  activatingGroups: Set(),
  deactivatingGroups: Set(),
  addingRecipients: Set(),
  validationByRecipientId: Map(),
  preparingGroups: Set(),
  unshare: {
    isModalOpened: false,
    isUnsharing: false,
    recipient: null,
  },
});

const updateRecipient = (recipientIds, toggled) => (state) => {
  state.update('addingRecipients', (addingRecipients) =>
    addingRecipients.filter(
      (recipientsId) => !recipientIds.includes(recipientsId),
    ),
  );
  state.update('groups', (groups) =>
    groups
      .map((group) =>
        group.update('members', (members) =>
          members.map((member) =>
            recipientIds.includes(member.get('id'))
              ? member.set('toggled', toggled)
              : member,
          ),
        ),
      )
      .map(splitGroupMembers),
  );
};

export default createReducer(initialState, {
  [DELETE_PRODUCT_VERSION]: () => initialState,

  [FETCH_SUMMARY]: (state) => state.set('isLoading', true),
  [FETCH_SUMMARY_FAILURE]: (state) =>
    state.set('isLoading', false).set('failedFetching', true),
  [RECEIVE_SUMMARY]: (state, { groups }) =>
    state
      .set('groups', fromJS(groups).map(splitGroupMembers))
      .set('isLoading', false)
      .set('failedFetching', false),

  [RECEIVE_VALIDATION_DATA]: (state, { results }) => {
    const recipients = (results && results.get('recipients')) || List();
    return state.update('validationByRecipientId', (validationByRecipientId) =>
      validationByRecipientId.merge(
        Map(recipients.map((r) => [r.get('id'), r])),
      ),
    );
  },

  // @TODO: optimistic update
  [ACTIVATE_GROUPS_SUCCESS]: (state, { groupIds }) =>
    state.withMutations((s) => {
      s.update('activatingGroups', (activateGroups) =>
        activateGroups.filter((groupId) => !groupIds.includes(groupId)),
      );
      s.update('groups', (groups) =>
        groups.map((group) =>
          groupIds.includes(group.getIn(['group', 'id']))
            ? group.set('status', GROUP_STATUS_ACTIVE)
            : group,
        ),
      );
    }),

  // @TODO: optimistic update
  [DEACTIVATE_GROUPS_SUCCESS]: (state, { groupIds }) =>
    state.withMutations((s) => {
      s.update('deactivatingGroups', (deactivateGroups) =>
        deactivateGroups.filter((groupId) => !groupIds.includes(groupId)),
      );
      s.update('groups', (groups) =>
        groups.map((group) =>
          groupIds.includes(group.getIn(['group', 'id']))
            ? group.set('status', GROUP_STATUS_INACTIVE)
            : group,
        ),
      );
    }),
  [DEACTIVATE_GROUPS_FAILURE]: (state, { groupIds }) =>
    state.update('deactivatingGroups', (deactivateGroups) =>
      deactivateGroups.filter((groupId) => !groupIds.includes(groupId)),
    ),

  [ADD_RECIPIENTS]: (state, { payload }) =>
    state.withMutations((s) => {
      s.update('addingRecipients', (addingRecipients) =>
        addingRecipients.union(payload.map((data) => data.recipient.get('id'))),
      );
    }),

  [ADD_RECIPIENTS_SUCCESS]: (state, { recipientIds }) =>
    state.withMutations(updateRecipient(recipientIds, true)),
  [ADD_RECIPIENTS_FAILURE]: (state, { recipientIds }) =>
    state.withMutations(updateRecipient(recipientIds, false)),

  // Preparation - assortment management
  [PREPARE_FOR_GROUP]: (state, { groupId }) =>
    state.update('preparingGroups', (pg) => pg.add(groupId)),
  [PREPARE_FOR_GROUP_SUCCESS]: (state, { groupId, prepare }) =>
    state
      .update('preparingGroups', (pg) => pg.delete(groupId))
      .update('groups', (groups) =>
        groups.map((group) =>
          groupId === group.getIn(['group', 'id'])
            ? group.set(
                'status',
                prepare ? GROUP_STATUS_PREPARING : GROUP_STATUS_INACTIVE,
              )
            : group,
        ),
      ),
  [PREPARE_FOR_GROUP_FAILURE]: (state, { groupId }) =>
    state.update('preparingGroups', (pg) => pg.delete(groupId)),

  [OPEN_UNSHARE_PRODUCT]: (state, { payload: recipient }) =>
    state.update('unshare', (unshare) =>
      unshare.set('isModalOpened', true).set('recipient', recipient),
    ),
  [UNSHARE_PRODUCT]: (state) => state.setIn(['unshare', 'isUnsharing'], true),
  [UNSHARE_PRODUCT_DONE]: (state) =>
    state.update('unshare', (unshare) =>
      unshare
        .set('isModalOpened', false)
        .set('isUnsharing', false)
        .set('recipient', null),
    ),
});

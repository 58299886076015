import PropTypes from 'prop-types';

import Image from 'components/ui/image';
import MissingImage from 'images/missing.png';

import './index.scss';

export default function SourcingPictureColumn({
  uri,
  width,
  height,
  isEligible,
}) {
  const src = uri || MissingImage;

  const className = isEligible
    ? 'CatalogSourcingPictureColumnEligible'
    : 'CatalogSourcingPictureColumnNotEligible';

  return (
    <Image
      width={width}
      height={height}
      src={src}
      fallbackSrc={MissingImage}
      maxWidth={width}
      maxHeight={height}
      className={className}
    />
  );
}

SourcingPictureColumn.className = 'CatalogSourcingPictureColumn';
SourcingPictureColumn.propTypes = {
  uri: PropTypes.string,
  width: PropTypes.string,
  height: PropTypes.string,
  isEligible: PropTypes.bool,
};

import { call, put, select } from 'redux-saga/effects';

import { getAsReadOnly } from 'utils/displayGroup';
import { logError } from 'utils/logging';
import { takeEverySafe } from 'utils/saga';

import api from '../api';
import {
  FETCH_DISPLAY_GROUPS_FAILURE,
  FETCH_DISPLAY_GROUPS_SUCCESS,
  SELECT_RECIPIENT,
} from '../constants';
import {
  selectIsTarget,
  selectIsTariff,
  selectSelectedRecipientId,
} from '../selectors';

export function* watchFetchDisplayGroups() {
  yield takeEverySafe(SELECT_RECIPIENT, fetchDisplayGroups);
}

export function* fetchDisplayGroups() {
  const selectedRecipientId = yield select(selectSelectedRecipientId);
  if (!selectedRecipientId) {
    return;
  }

  const isTariff = yield select(selectIsTariff);

  const { result, error } = yield call(
    api.fetchDisplayGroups,
    selectedRecipientId,
    isTariff,
  );

  if (!result) {
    yield put({ type: FETCH_DISPLAY_GROUPS_FAILURE, error });
    yield call(logError, error);
    return;
  }

  let { displayGroups } = result;
  if (yield select(selectIsTarget)) {
    displayGroups = displayGroups.map((dg) => getAsReadOnly(dg));
  }

  yield put({
    type: FETCH_DISPLAY_GROUPS_SUCCESS,
    displayGroups,
  });
}

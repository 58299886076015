export const FETCH_PRODUCT_DATA_BY_KEY_ID =
  'product-page/FETCH_PRODUCT_DATA_BY_KEY_ID';
export const FETCH_PRODUCT_DATA_BY_UUID =
  'product-page/FETCH_PRODUCT_DATA_BY_UUID';

export const SET_LOADING = 'product-page/SET_LOADING';

export const SET_VIEW_AS = 'product-page/SET_VIEW_AS';

export const SAVE_PRODUCT = 'product-page/SAVE_PRODUCT';
export const SAVE_PRODUCT_REQUEST = 'product-page/SAVE_PRODUCT_REQUEST';
export const SAVE_PRODUCT_SUCCESS = 'product-page/SAVE_PRODUCT_SUCCESS';
export const SAVE_PRODUCT_ERROR = 'product-page/SAVE_PRODUCT_ERROR';

export const SAVE_RETAILER_PRODUCT_VERSION =
  'product-page/SAVE_RETAILER_PRODUCT_VERSION';

export const PUBLISH = 'product-page/PUBLISH';
export const SAVE_VISIBILITY = 'product-page/SAVE_VISIBILITY';
export const SAVE_VISIBILITY_SUCCESS = 'product-page/SAVE_VISIBILITY_SUCCESS';
export const SAVE_VISIBILITY_ERROR = 'product-page/SAVE_VISIBILITY_ERROR';

export const REQUIRE_MATURITY_RULES = 'product-page/require-maturity-rules';
export const REQUIRE_MATURITY_RULES_DONE =
  'product-page/require-maturity-rules-done';
export const PROMPT_MATURITY_RULE_SETS_WITH_DEADLINES =
  'product-page/prompt-maturity-rule-sets-with-deadlines';
export const CLEAR_PROMPTED_MATURITY_RULE_SETS_WITH_DEADLINES =
  'product-page/clear-maturity-rule-sets-with-deadlines';
export const REQUIRE_MATURITY_RULES_WITH_DEADLINES =
  'product-page/require-maturity-rules-with-deadlines';
export const REQUIRE_MATURITY_RULES_WITH_DEADLINES_DONE =
  'product-page/require-maturity-rules-with-deadlines-done';

export const SET_EXPORTABLE = 'product-page/set-exportable';

export const FETCH_REJECTED_FIELDS = 'product-page/rejected-fields/fetch';
export const RECEIVE_REJECTED_FIELDS = 'product-page/rejected-fields/receive';

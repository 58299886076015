import PropTypes from 'prop-types';

import { LazyTooltip } from '@alkem/react-ui-tooltip';

import {
  ProductValidationStatusMap,
  ProductValidationStatusUnknown,
} from 'constants/productValidationStatus';

import './index.scss';

export default function CatalogValidationColumn({ data, productVersionId }) {
  const tooltipId = `catalog-validation-column-tooltip-${productVersionId}`;
  const status =
    ProductValidationStatusMap[+data] || ProductValidationStatusUnknown;
  return (
    <LazyTooltip id={tooltipId} tooltipLabel={status.name} block>
      <i className={`mdi ${status.iconClass}`} />
    </LazyTooltip>
  );
}

CatalogValidationColumn.center = true;
CatalogValidationColumn.propTypes = {
  data: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  productVersionId: PropTypes.number.isRequired,
};

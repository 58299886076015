import { node, string } from 'prop-types';

import './addWrapper.scss';

export default function AddWrapper({ label, children }) {
  return (
    <div className="AddWrapper__Wrapper">
      <div className="row AddWrapper__select">
        <div className="col-xs-4 InputField__label">
          <i className="AddWrapper__Icon mdi mdi-plus-circle" />
          {label}
        </div>
        <div className="col-xs-8 InputField__input AddWrapper__Input">
          {children}
        </div>
      </div>
    </div>
  );
}

AddWrapper.propTypes = {
  label: string.isRequired,
  children: node,
};

import { call, put, select, takeEvery } from 'redux-saga/effects';

import { isRetailer } from 'core/api/user';
import { selectUser } from 'modules/user';
import { logError } from 'utils/logging';

import {
  FETCH_DISPLAY_GROUPS_FAILURE,
  FETCH_DISPLAY_GROUPS_REQUEST,
  FETCH_DISPLAY_GROUPS_SUCCESS,
} from '../actionTypes';
import api from '../api';
import {
  getDisplayGroups,
  getTariffDisplayGroups,
} from '../selectors/display-groups';

export function* watchFetch() {
  yield takeEvery(FETCH_DISPLAY_GROUPS_REQUEST, fetchDisplayGroupsIfRequired);
}

export function* fetchDisplayGroupsIfRequired(action) {
  let selectDisplayGroupsFunc = getDisplayGroups;
  if (action.payload.isTariff) {
    selectDisplayGroupsFunc = getTariffDisplayGroups;
  }

  const displayGroups = yield select(selectDisplayGroupsFunc);
  if (!displayGroups.has(action.payload.targetOrganizationId)) {
    yield call(
      fetchSharingUnitDisplayGroupsSaga,
      action.payload.targetOrganizationId,
      action.payload.isTariff,
      action.payload.readOnly,
    );
  }
}

export const fetchSharingUnitDisplayGroupsHandler = async (
  targetOrganizationId,
  isTariff,
) => {
  return api.fetchLegacySharingUnitDisplayGroups(
    targetOrganizationId,
    isTariff,
  );
};

export function* fetchSharingUnitDisplayGroupsSaga(
  targetOrganizationId,
  isTariff,
  readOnly = false,
) {
  const { result, error } = yield call(
    fetchSharingUnitDisplayGroupsHandler,
    targetOrganizationId,
    isTariff,
  );

  if (result) {
    const { displayGroups } = result;
    const user = yield select(selectUser);
    yield put({
      type: FETCH_DISPLAY_GROUPS_SUCCESS,
      payload: {
        displayGroups,
        targetOrganizationId,
        isTariff,
        readOnly: !!(readOnly || isRetailer(user)),
      },
    });
  } else {
    yield put({ type: FETCH_DISPLAY_GROUPS_FAILURE, error });
    yield call(logError, error);
  }
}

import { flow, get } from 'lodash/fp';

import { MODULE_REDUCER_KEY } from 'constants/reducer';
import { GlobalState } from 'types';

import { SUB_MODULE_NAME } from './constants';
import { RfpUserInfoState } from './reducers';

export const selectModuleState: (state: GlobalState) => RfpUserInfoState = get([
  MODULE_REDUCER_KEY,
  SUB_MODULE_NAME,
]);

export const selectHasRfpModule = flow<
  [GlobalState],
  RfpUserInfoState,
  boolean
>(selectModuleState, get(['hasRfpModule']));

import asyncComponent from 'core/modules/async/component';
import registerModule from 'core/modules/async/register';
import { registerModuleReducer } from 'utils/modules/reducer';
import { registerSaga } from 'utils/modules/saga';

import {
  SHARING_UNIT_TEMPLATES_REDUCER_KEY,
  sharingUnitTemplatesReducer,
} from './reducer';
import { sharingUnitTemplatesSaga } from './sagas';

registerModuleReducer(
  SHARING_UNIT_TEMPLATES_REDUCER_KEY,
  sharingUnitTemplatesReducer,
);
registerSaga(sharingUnitTemplatesSaga);

export const sharingUnitTemplatesModule = async () =>
  registerModule(
    await import(/* webpackChunkName: "sharing-unit-templates" */ './async'),
  );

export const SharingUnitTemplatesView = asyncComponent(
  sharingUnitTemplatesModule,
  {
    componentPath: 'SharingUnitTemplatesView',
  },
);

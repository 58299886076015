import { createAction, createActionWithPayload } from 'utils/redux';

import {
  AUTHORIZED,
  LOGOUT,
  NOT_AUTHORIZED,
  RECEIVE_PRODUCT_SEGMENTS_PERMISSIONS,
  RESET_AUTHORIZED,
  TRY_AUTHORIZATION,
  UPDATE_USER_FIELD,
  USER_PROFILE_LOADED,
} from './action-types';

export const receiveProductSegmentsPermissions = (productSegments) => ({
  type: RECEIVE_PRODUCT_SEGMENTS_PERMISSIONS,
  payload: { productSegments },
});

export const updateUserField = createAction(UPDATE_USER_FIELD);

export const tryAuthorization = createAction(TRY_AUTHORIZATION);

export const authorized = createAction(AUTHORIZED);

export const notAuthorized = createAction(NOT_AUTHORIZED);

export const resetAuthorized = createAction(RESET_AUTHORIZED);

export const logout = createActionWithPayload<{ next: string } | undefined>(
  LOGOUT,
);

export const userProfileLoaded = createAction(USER_PROFILE_LOADED);

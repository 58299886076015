import { createSelector } from '@reduxjs/toolkit';
import { List } from 'immutable';
import { flow } from 'lodash';

import { MODULE_REDUCER_KEY } from 'constants/reducer';
import { hasInformationRequest } from 'core/api/organization-settings';
import {
  getOrganizationSettings,
  getOrganizationTargetMarketIds,
} from 'core/api/user';
import {
  createSelectOnlyDuplicatedProducts,
  createSelectWithAllTypes,
  createSelectWithArchivedProducts,
  createSelectWithRefusedAssignations,
  createSelectorAreFiltersCollapsed,
  createSelectorFiltersConfig,
  createSelectorSelectedFilterList,
  createSelectorSelectedFilterMap,
} from 'core/modules/list/selectors';
import { selectUser } from 'modules/user';

import { KEY } from '../constants';

export const selectModuleState = (state) => state[MODULE_REDUCER_KEY][KEY];

export const selectProductList: (state: any) => List<any> = createSelector(
  selectModuleState,
  (moduleState) => moduleState.get('productList'),
);

export const selectProductMap = createSelector(
  selectModuleState,
  (moduleState) => moduleState.get('productMap'),
);

export const selectSelectedMap = createSelector(
  selectModuleState,
  (moduleState) => moduleState.get('selectedMap'),
);

export const selectColumnMap = createSelector(
  selectModuleState,
  (moduleState) => moduleState.get('columnMap'),
);

export const selectSorting = createSelector(selectModuleState, (moduleState) =>
  moduleState.get('sorting'),
);

export const selectReferentials = createSelector(
  selectModuleState,
  (moduleState) => moduleState.get('referentials'),
);

export const selectIsFetchingList = createSelector(
  selectModuleState,
  (moduleState) => moduleState.get('isFetchingList'),
);

export const selectIsFetchingReferentials = createSelector(
  selectModuleState,
  (moduleState) => moduleState.get('isFetchingReferentials'),
);

export const selectPagination = createSelector(
  selectModuleState,
  (moduleState) => moduleState.get('pagination'),
);

export const selectQueries = createSelector(selectModuleState, (moduleState) =>
  moduleState.get('queries'),
);

export const selectSearch: (state: any) => string = createSelector(
  selectModuleState,
  (moduleState) => moduleState.get('search'),
);

export const selectSelectedFilterList =
  createSelectorSelectedFilterList(selectModuleState);

export const selectSelectedFilterMap =
  createSelectorSelectedFilterMap(selectModuleState);

export const selectAggregations = createSelector(
  selectModuleState,
  (moduleState) => moduleState.get('aggregations'),
);

export const selectFiltersConfig =
  createSelectorFiltersConfig(selectModuleState);

export const selectAreFiltersCollapsed =
  createSelectorAreFiltersCollapsed(selectModuleState);

export const selectExportInProgress = createSelector(
  selectModuleState,
  (moduleState) => moduleState.get('exportInProgress'),
);

export const selectExportDesiredProductsInProgress = createSelector(
  selectModuleState,
  (moduleState) => moduleState.get('exportDesiredProductsInProgress'),
);

export const selectIsExportDesiredProductsModalOpen = createSelector(
  selectModuleState,
  (moduleState) => moduleState.get('isExportDesiredProductsModalOpen'),
);

export const selectIsExportModalOpen = createSelector(
  selectModuleState,
  (moduleState) => moduleState.get('isExportModalOpen'),
);

export const selectExportFileFormats = flow([
  selectModuleState,
  (moduleState) => moduleState.get('exportFileFormats'),
]);

const filterFormats = (fileFormats, user) =>
  (fileFormats || [])
    .filter((ref) => user.getIn(['features', ref.flag]) || !ref.flag)
    .filter((ref) => {
      return (
        !Number.isInteger(ref.extraOptions?.filter_target_market) ||
        getOrganizationTargetMarketIds(user).has(
          ref.extraOptions.filter_target_market,
        )
      );
    });

export const selectFilteredExportFileFormats = createSelector(
  selectExportFileFormats,
  selectUser,
  filterFormats,
);

export const selectExportFormatV1 = createSelector(
  selectExportFileFormats,
  selectUser,
  selectModuleState,
  (fileFormats, user, moduleState) =>
    moduleState.get('exportFormat') || filterFormats(fileFormats, user)[0],
);

export const selectExportFormat = createSelector(
  selectModuleState,
  (moduleState) => moduleState.get('exportFormat'),
);
export const selectWithArchivedProducts =
  createSelectWithArchivedProducts(selectModuleState);

export const selectOnlyDuplicatedProducts =
  createSelectOnlyDuplicatedProducts(selectModuleState);

export const selectWithAllTypes = createSelectWithAllTypes(selectModuleState);

export const selectWithRefusedAssignations =
  createSelectWithRefusedAssignations(selectModuleState);

export const selectCatalogContext = createSelector(
  selectModuleState,
  (moduleState) => moduleState.get('context'),
);

export const selectIsFirstTime = createSelector(
  selectModuleState,
  (moduleState) => moduleState.get('isFirstTime'),
);

export const selectReportingFilters = createSelector(
  selectModuleState,
  (moduleState) => moduleState.get('reportingFilters'),
);

export const selectIsCatalogReady = createSelector(
  selectModuleState,
  (moduleState) => moduleState.get('isReady'),
);

export const selectCatalogUuid = createSelector(
  selectModuleState,
  (moduleState) => moduleState.get('uuid'),
);

export const selectProductsToExport = createSelector(
  selectModuleState,
  (moduleState) => moduleState.get('productsToExport'),
);

export const selectExportProducts = createSelector(
  selectModuleState,
  (moduleState) => moduleState.get('exportProducts'),
);

export const selectMaturityRulesets = createSelector(
  selectModuleState,
  (moduleState) => moduleState.get('maturityRulesets'),
);

export const selectHasInformationRequest = createSelector(selectUser, (user) =>
  hasInformationRequest(getOrganizationSettings(user)),
);

export const selectHighlightedRowIdentifier = createSelector(
  selectModuleState,
  (moduleState) => moduleState.get('highlightedRowIdentifier'),
);

import PropTypes from 'prop-types';
import ImmutablePropTypes from 'react-immutable-proptypes';

export default function CatalogLink({ gtins, children }) {
  return (
    <a
      target="_blank"
      rel="noopener noreferrer"
      href={`/#/catalog?search=${gtins.join(',')}`}
    >
      {children}
    </a>
  );
}

CatalogLink.propTypes = {
  gtins: PropTypes.oneOfType([PropTypes.array, ImmutablePropTypes.list])
    .isRequired,
  children: PropTypes.node,
};

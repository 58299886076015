export const OrganizationDefault = { id: 1, name: 'Default Manufacturer' };
export const OrganizationAlkemics = { id: 7, name: 'Alkemics' };
export const OrganizationAlkemicsDemo = { id: 603, name: 'Alkemics - Démo' };
export const OrganizationAlkemicsSource = {
  id: 618,
  name: 'Alkemics - Source',
};
export const OrganizationAlkemicsBeta = { id: 646, name: 'Alkemics Bêta' };
export const OrganizationAlkefour = { id: 669, name: 'Alkefour' };
export const OrganizationAlkemicsRecipient = {
  id: 675,
  name: 'Alkemics - Recipient',
};
export const OrganizationAlkestic = { id: 676, name: 'Alkestic' };
export const OrganizationAlkemicsTest = { id: 677, name: 'Alkemics Test' };
export const OrganizationAlkemicsTestRetailer = {
  id: 698,
  name: 'Alkemics Test - Retailer',
};
export const OrganizationAlkemicsTestManufacturer = {
  id: 703,
  name: 'Alkemics Test Manufacturer',
};
export const OrganizationAlkemicsDemoManufacturer = {
  id: 729,
  name: 'Alkemics Demo Manufacturer',
};
export const OrganizationAlkemicsDemoManufacturerUK = {
  id: 1338,
  name: 'Alkemics Demo Manufacturer UK',
};
export const OrganizationAlkemicsDemoRetailerUK = {
  id: 1339,
  name: 'Alkemics Demo Retailer UK',
};
export const OrganizationAlkemicsRetailer = {
  id: 730,
  name: 'Alkemics Demo - Retailer',
};
export const OrganizationDemoAlkemicsCom = {
  id: 780,
  name: 'Demo.Alkemics.Com',
};
export const OrganizationAlkemicsLiveDemo = {
  id: 781,
  name: 'Alkemics Live démo',
};
export const OrganizationAlkemicsDemoUSManufacturer = {
  id: 1125,
  name: 'Alkemics Demo US Manufacturer',
};
export const OrganizationAlkemicsDevB2BManufacturer = {
  id: 1199,
  name: 'Alkemics Dev B2B - Manufacturer',
};
export const OrganizationAlkemicsTestB2BManufacturer = {
  id: 1307,
  name: 'Alkemics Test - Manufacturer B2B',
};
export const OrganizationParisPharmaRetailerTest = {
  id: 2137,
  name: 'Test_Retailer_ParisPharma',
};
export const OrganizationParisPharmaRetailer = {
  id: 2610,
  name: 'ParisPharma',
};

export const AlkemicsOrganizations = [
  OrganizationAlkemics,
  OrganizationAlkemicsDemo,
  OrganizationAlkemicsSource,
  OrganizationAlkemicsBeta,
  OrganizationAlkefour,
  OrganizationAlkemicsRecipient,
  OrganizationAlkestic,
  OrganizationAlkemicsTest,
  OrganizationAlkemicsTestRetailer,
  OrganizationAlkemicsTestManufacturer,
  OrganizationAlkemicsDemoManufacturer,
  OrganizationAlkemicsDemoRetailerUK,
  OrganizationAlkemicsDemoManufacturerUK,
  OrganizationAlkemicsRetailer,
  OrganizationDemoAlkemicsCom,
  OrganizationAlkemicsLiveDemo,
  OrganizationAlkemicsDemoUSManufacturer,
  OrganizationAlkemicsDevB2BManufacturer,
  OrganizationAlkemicsTestB2BManufacturer,
];

export const AlkemicsOrganizationsIds = AlkemicsOrganizations.map((o) => o.id);

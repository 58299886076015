import { i18n } from 'utils/i18n';

export const SHARING_STATUS_NOT_USED = {
  id: 'NOT_USED',
  label: i18n.t('frontproductstream.constants.sharing_status.not_used', {
    defaultValue: 'Not used',
  }),
};

export const SHARING_STATUS_DRAFT = {
  id: 'DRAFT',
  label: i18n.t('frontproductstream.constants.sharing_status.draft', {
    defaultValue: 'Draft',
  }),
};

export const SHARING_STATUS_EXPECTED = {
  id: 'EXPECTED',
  label: i18n.t('frontproductstream.constants.sharing_status.expected', {
    defaultValue: 'Expected',
  }),
};

export const SHARING_STATUS_UNSYNCHRONIZED = {
  id: 'UN_SYNCHRONIZED',
  label: i18n.t('frontproductstream.constants.sharing_status.unsynchronized', {
    defaultValue: 'Unsynchronized',
  }),
};

export const SHARING_STATUS_RECIPIENT_ADDED = {
  id: 'RECIPIENT_ADDED',
  label: i18n.t('frontproductstream.constants.sharing_status.recipient_added', {
    defaultValue: 'Recipient added',
  }),
};

export const SHARING_STATUS_SHARED = {
  id: 'SHARED',
  label: i18n.t('frontproductstream.constants.sharing_status.shared', {
    defaultValue: 'Shared',
  }),
};

export const SHARING_STATUS_BLOCKED = {
  id: 'BLOCKED',
  label: i18n.t('frontproductstream.constants.sharing_status.blocked', {
    defaultValue: 'Blocked',
  }),
};

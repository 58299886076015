import classNames from 'classnames';
import moment from 'moment';
import PropTypes from 'prop-types';
import { PureComponent } from 'react';

import Anchor from 'components/ui/basic/anchor';
import Image from 'components/ui/image';
import MissingImage from 'images/missing.png';
import { formatMedia } from 'utils/links';

export default class Picture extends PureComponent {
  static propTypes = {
    kind: PropTypes.string.isRequired,
    entity: PropTypes.object.isRequired,
    width: PropTypes.string,
    height: PropTypes.string,
    maxWidth: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    maxHeight: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    className: PropTypes.string,
    link: PropTypes.string,
  };

  cachebuster = moment().format('YYYYMMDD');
  sizes = [128, 256];

  renderImage() {
    const { entity, kind, width, height, maxWidth, maxHeight } = this.props;
    const { id } = entity;
    let size = this.sizes.reduce((sizeSelected, currentSize) => {
      if (sizeSelected) {
        return sizeSelected;
      }
      if (
        (!maxWidth || currentSize >= maxWidth) &&
        (!maxHeight || currentSize >= maxHeight) &&
        (!width || currentSize >= width) &&
        (!height || currentSize >= height)
      ) {
        return currentSize;
      }
      return sizeSelected;
    }, null);

    if (!size) {
      size = this.sizes[this.sizes.length - 1];
    }

    let url = '';
    switch (kind) {
      case 'Brand':
        url = formatMedia(
          `/brand/${id}/picture/logo/original.png?${this.cachebuster}`,
        );
        break;
      case 'Label':
        url = formatMedia(
          `/api/1/concept/${id}/picture/logo/original.png?${this.cachebuster}`,
        );
        break;
      default:
        url = MissingImage;
    }

    return (
      <Image
        width={width}
        height={height}
        maxWidth={maxWidth}
        maxHeight={maxHeight}
        src={url}
      />
    );
  }

  render() {
    const { link } = this.props;
    const image = this.renderImage();
    const classes = classNames('Picture', this.props.className);

    if (link) {
      return (
        <Anchor className={classes} href={link}>
          {image}
        </Anchor>
      );
    }

    return <span className={classes}>{image}</span>;
  }
}

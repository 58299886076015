import { List } from 'immutable';
import { call, put, select } from 'redux-saga/effects';

import { notificationError, notificationSuccess } from 'actions/notification';
import { isNormalizedComment } from 'components/ui/form/plugins/validator/utils';
import { ENTITY_TYPE_PRODUCTVERSION } from 'constants/entities';
import { NORMALIZED_COMMENT_CHANGES_REQUESTED } from 'constants/events/suggestions';
import { ROOM_TYPE_PRODUCTVERSION } from 'constants/room';
import { getContentOwnerId, getProductId } from 'core/api/productversion';
import {
  getSharedOrganizationId,
  getSharedProductVersionId,
} from 'core/api/retailerproductversion';
import { sendQuickMessageSaga } from 'modules/chat';
import { savePatchesSaga } from 'modules/data-ops';
import { RECEIVE_DISPLAY_GROUPS } from 'modules/display-groups/constants';
import { selectFieldByName } from 'modules/display-groups/selectors';
import { selectPendingMessages } from 'modules/product-page/modules/normalized-comments/selectors';
import {
  RECEIVE_RULE_RESULTS,
  RECEIVE_RULE_RESULTS_FOR_ACTIVE_SHARES_RECIPIENTS,
  RECEIVE_RULE_RESULTS_FOR_SELECTED_RECIPIENTS,
  ValidationWorkflowType,
  applyRulesSaga,
  selectValidationResultsByEntity,
} from 'modules/validation';
import { selectAllAvailableFieldsForProductType } from 'modules/view-as/selectors/fields';
import {
  selectCurrentLanguage,
  selectProductKeyId,
  selectSourceProductVersion,
} from 'reducers/productVersion';
import ValidationApi from 'resources/validationApi';
import { request } from 'utils/api';
import i18n from 'utils/i18n';
import { get } from 'utils/immutable';
import { takeLatestSafe } from 'utils/saga';

import {
  openMessageModal,
  requestChangesDone,
  storeDeprecatedNormalizedComments,
} from './actions';

// Be careful with that one it's in normalized-comments but it's also dealing with data-ops
export function* requestChanges({ payload: { message, canSendMessage } }) {
  const productVersion = yield select(selectSourceProductVersion);
  const sharedProductVersionId = getSharedProductVersionId(productVersion);
  const manufacturerId = getSharedOrganizationId(productVersion);
  const productId = getProductId(productVersion);
  const retailerId = getContentOwnerId(productVersion);
  const productKeyId = yield select(selectProductKeyId);

  try {
    yield call(savePatchesSaga);
    if (canSendMessage) {
      yield call(sendQuickMessageSaga, {
        payload: {
          message,
          entityId: sharedProductVersionId,
          roomType: ROOM_TYPE_PRODUCTVERSION,
          organizationId: manufacturerId,
        },
      });
    }
    yield put({
      type: NORMALIZED_COMMENT_CHANGES_REQUESTED,
      productKeyId,
      productVersionId: productVersion.id,
      productId,
      retailerId,
    });
    yield put(openMessageModal(false));
    yield put(requestChangesDone());
    yield put(notificationSuccess(i18n.t('Successfully sent change requests')));
  } catch (error: any) {
    yield put(requestChangesDone());
    let errorMsg = i18n.t('Something went wrong while sending request changes');
    if (error.data?.status === 400) {
      errorMsg = i18n.t(error.data.message);
    }
    yield put(notificationError(errorMsg, { context: 'modal', error }));
    // pass the error on so that we don't close the modal
    throw error;
  }
}

export function* createNormalizedComments({
  productKeyId,
  productVersionId,
  productId,
  retailerId,
}) {
  let pendingMessages = yield select(selectPendingMessages);
  const currentLanguage = yield select(selectCurrentLanguage);
  pendingMessages = pendingMessages[productVersionId];
  if (!pendingMessages) {
    return;
  }

  const normalizedComments = pendingMessages.filter(
    (m) => m.type === ValidationWorkflowType.NORMALIZED_COMMENT,
  );
  if (!normalizedComments.length) {
    return;
  }

  const body = {
    product_key_id: productKeyId,
    product_id: productId,
    target_organization_id: retailerId,
    language_id: get(currentLanguage, 'id'),
    data: normalizedComments.map((c) => ({
      path: c.path,
      value: c.currentValue,
    })),
  };

  yield call(request, ValidationApi, 'bulkCreateNormalizedComment', body);
}

export function* dismissNormalizedComment({ payload: { id } }) {
  try {
    yield call(request, ValidationApi, 'dismissNormalizedComment', id);
    // Refresh rule results
    yield call(applyRulesSaga, {
      payload: { entityKind: ENTITY_TYPE_PRODUCTVERSION },
    });
  } catch (e) {
    yield put(
      notificationError(
        i18n.t('Something went wrong while dismissing the suggestion'),
      ),
    );
  }
}

export function* extractDeprecatedNormalizedCommentsFromRuleResults() {
  const currentLanguage = yield select(selectCurrentLanguage);

  if (!currentLanguage) {
    return;
  }

  const ruleResults = yield select(selectValidationResultsByEntity);
  const availableFields = yield select(selectAllAvailableFieldsForProductType);
  const getFieldByName = yield select(selectFieldByName);

  const normalizedCommentsRulesMap = (ruleResults || List())
    // merge all results into one big map of rules
    .reduce((acc, results) => acc.concat(results.get('rules')), List())
    // keep only the normalized comments that the user can accept on that language
    .filter(
      (r) =>
        r &&
        r.getIn(['error_by_language', currentLanguage.code, 'errorMessage']) &&
        isNormalizedComment(r) &&
        (!availableFields.includes(r.get('root_field')) ||
          !getFieldByName(r.get('root_field'))),
    );

  const normalizedComments = normalizedCommentsRulesMap
    .map((r) => ({
      id: r.get('id'),
      fieldName: r.get('root_field'),
    }))
    .sortBy((a) => a.fieldName);

  yield put(storeDeprecatedNormalizedComments(normalizedComments.toJS()));
}

export function* normalizedCommentsSaga() {
  yield takeLatestSafe(
    [
      RECEIVE_RULE_RESULTS,
      RECEIVE_RULE_RESULTS_FOR_SELECTED_RECIPIENTS,
      RECEIVE_RULE_RESULTS_FOR_ACTIVE_SHARES_RECIPIENTS,
      RECEIVE_DISPLAY_GROUPS,
    ],
    extractDeprecatedNormalizedCommentsFromRuleResults,
  );
}

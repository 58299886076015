import type { Moment } from 'moment';
import { useMemo } from 'react';

import i18n, { getUserLanguage } from 'utils/i18n';
import { sortAsc } from 'utils/sort';
import { capitalize } from 'utils/string';

import {
  MaturitySummary,
  isScenarioDeadlineBehind,
  parseDeadline,
} from '../../types';
import { MaturityCompletion } from '../maturity-completion';
import { MaturityPanelEmptyTabContents } from '../maturity-panel-empty-tab';

import './index.scss';

interface RecipientGroup {
  recipientId?: number;
  recipientName?: string;
  scenarios: MaturitySummary[];
}
interface DateGroup {
  date: Moment;
  recipients: RecipientGroup[];
  scenariosWithoutRecipient: MaturitySummary[];
}

export const UpcomingDeadlines = ({
  upcomingScenarios,
}: {
  upcomingScenarios: MaturitySummary[];
}) => {
  const scenariosByDateThenRecipient = useMemo(() => {
    return upcomingScenarios.reduce((dateGroups: DateGroup[], scenario) => {
      const date = parseDeadline(scenario);

      let dateGroup = dateGroups.find((candidate) =>
        candidate.date.isSame(date),
      );
      if (!dateGroup) {
        dateGroup = {
          date,
          recipients: [],
          scenariosWithoutRecipient: [],
        };
        dateGroups.push(dateGroup);
      }

      if (!scenario.recipient_id) {
        dateGroup.scenariosWithoutRecipient.push(scenario);
      } else {
        let recipientGroup = dateGroup.recipients.find(
          (candidate) => candidate.recipientId === scenario.recipient_id,
        );
        if (!recipientGroup) {
          recipientGroup = {
            recipientId: scenario.recipient_id,
            recipientName: scenario.recipient_name,
            scenarios: [],
          };
          dateGroup.recipients.push(recipientGroup);
        }
        recipientGroup.scenarios.push(scenario);
      }
      return dateGroups;
    }, []);
  }, [upcomingScenarios]);

  if (upcomingScenarios?.length) {
    const sortedDateGroups = [...scenariosByDateThenRecipient].sort(
      (a, b) => a.date.unix() - b.date.unix(),
    );
    return (
      <div className="MaturityPanelDeadlines__dateSections alk-flex alk-flex-column">
        {sortedDateGroups.map((dateGroup) => (
          <DateSection
            key={dateGroup.date.toISOString()}
            dateGroup={dateGroup}
          />
        ))}
      </div>
    );
  }

  const emptyTabTitle = i18n.t(
    'frontproductstream.maturity_panel.noupcoming.title',
    {
      defaultValue: 'Nothing to come',
    },
  );
  const emptyTabText = i18n.t(
    'frontproductstream.maturity_panel.noupcoming.text',
    {
      defaultValue: 'You currently have no upcoming update',
    },
  );
  return (
    <MaturityPanelEmptyTabContents title={emptyTabTitle} text={emptyTabText} />
  );
};

function RecipientSection({
  recipientGroup,
}: {
  recipientGroup: RecipientGroup;
}) {
  const recipientGroupScenarios = useMemo(
    () =>
      [...recipientGroup.scenarios].sort((a, b) => sortAsc(a.label, b.label)),
    [recipientGroup.scenarios],
  );
  return (
    <div>
      <div
        className="MaturityPanelDeadlines__recipientHeader"
        data-testid="recipient-header"
      >
        {i18n.t('frontproductstream.constants.label.colon', {
          defaultValue: '{{label}}:',
          label: recipientGroup.recipientName,
        })}
      </div>
      <div className="MaturityPanelDeadlines_maturities alk-flex alk-flex-column">
        {recipientGroupScenarios.map((scenario) => {
          return (
            <MaturityCompletion
              key={scenario.id}
              maturity={scenario}
              includeLateLabel={false}
            />
          );
        })}
      </div>
    </div>
  );
}

function DateSection({ dateGroup }) {
  const dateFormatOptions: Intl.DateTimeFormatOptions = {
    weekday: 'long',
    year: 'numeric',
    month: 'short',
    day: 'numeric',
  };
  const formattedDate = capitalize(
    dateGroup.date
      .toDate()
      .toLocaleString(getUserLanguage(), dateFormatOptions),
  );

  const hasDatePassed = isScenarioDeadlineBehind(dateGroup.date);

  const sortedScenariosWithoutRecipient = useMemo(
    () =>
      [...dateGroup.scenariosWithoutRecipient].sort((a, b) =>
        sortAsc(a.label, b.label),
      ),
    [dateGroup.scenariosWithoutRecipient],
  );

  const sortedRecipientGroups = useMemo(
    () =>
      [...dateGroup.recipients].sort((a, b) =>
        sortAsc(a.recipientName, b.recipientName),
      ),
    [dateGroup.recipients],
  );

  return (
    <div>
      <div
        className="MaturityPanelDeadlines__dateHeader"
        data-testid="date-header"
      >
        <span>{formattedDate}</span>
        {hasDatePassed && (
          <span className="MaturityPanelDeadlines__overdueFlag">
            {' '}
            {i18n.t('frontproductstream.maturity_panel.deadlines.overdueText', {
              defaultValue: 'Overdue',
            })}
          </span>
        )}
      </div>
      <div className="MaturityPanelDeadlines_maturities alk-flex alk-flex-column">
        {/* case of retailers */}
        {sortedScenariosWithoutRecipient.map((scenario) => {
          return (
            <MaturityCompletion
              key={scenario.id}
              maturity={scenario}
              includeLateLabel={false}
            />
          );
        })}
      </div>
      <div className="MaturityPanelDeadlines_recipients alk-flex alk-flex-column">
        {/* case of manufacturers */}
        {sortedRecipientGroups.map((recipientGroup) => {
          return (
            <RecipientSection
              key={recipientGroup.recipientId}
              recipientGroup={recipientGroup}
            />
          );
        })}
      </div>
    </div>
  );
}

export const SUGGESTIONS_DIFFS_COMPUTED =
  'productSuggestions/SUGGESTIONS_DIFFS_COMPUTED';

export const ACCEPT_SUGGESTION = 'productSuggestions/ACCEPT_SUGGESTION';
export const ACCEPTING_SUGGESTION = 'productSuggestions/ACCEPTING_SUGGESTION';
export const ACCEPTED_SUGGESTION = 'productSuggestions/ACCEPTED_SUGGESTION';

export const ACCEPT_ALL_SUGGESTIONS =
  'productSuggestions/ACCEPT_ALL_SUGGESTIONS';
export const ACCEPTING_ALL_SUGGESTIONS =
  'productSuggestions/ACCEPTING_ALL_SUGGESTIONS';
export const ACCEPTED_ALL_SUGGESTIONS =
  'productSuggestions/ACCEPTED_ALL_SUGGESTIONS';

export const DISMISS_SUGGESTION = 'productSuggestions/DISMISS_SUGGESTION';
export const DISMISSING_SUGGESTION = 'productSuggestions/DISMISSING_SUGGESTION';
export const DISMISSED_SUGGESTION = 'productSuggestions/DISMISSED_SUGGESTION';

export const DISMISS_ALL_SUGGESTIONS =
  'productSuggestions/DISMISS_ALL_SUGGESTIONS';
export const DISMISSING_ALL_SUGGESTIONS =
  'productSuggestions/DISMISSING_ALL_SUGGESTIONS';
export const DISMISSED_ALL_SUGGESTIONS =
  'productSuggestions/DISMISSED_ALL_SUGGESTIONS';

import { createReducer } from 'redux-create-reducer';

import { RECEIVE_SEGMENT_LIST } from 'constants/events/segment';

const initialState = {
  list: [],
  tree: [],
};
export default createReducer(initialState, {
  [RECEIVE_SEGMENT_LIST]: (state, action) => {
    const newState = { ...state };
    const segments = {};

    newState.list = [...action.list];
    newState.tree = [];

    newState.list.forEach((segment) => {
      segments[segment.id] = segment;
      segments[segment.id].children = [];
    });

    newState.list.forEach((segment) => {
      if (segment.parent_id) {
        segments[segment.parent_id].children.push(segment);
        segments[segment.id].parent = segments[segment.parent_id];
      } else {
        newState.tree.push(segment);
      }
    });

    return newState;
  },
});

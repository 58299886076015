import { registerModuleReducer } from 'utils/modules/reducer';
import { registerSaga } from 'utils/modules/saga';

import { CatalogBody, CatalogMenu } from './components';
import { REDUCER_KEY } from './constants';
import reducers from './reducers';
import sagas from './sagas';

registerModuleReducer(REDUCER_KEY, reducers);
registerSaga(sagas);

export { CatalogMenu, CatalogBody };

import { ServiceProductApi, getPriority } from '@alkem/sdk-dashboard';

import { getUserLanguage as acceptLanguage } from 'utils/i18n';
import qs from 'utils/query';

const options = {
  cacheEnabled: false,
  acceptLanguage,
};

const serviceProductApi = new ServiceProductApi(options);
const lowPriorityServiceProductApi = new ServiceProductApi({
  ...options,
  alkPriority: getPriority() - 1,
});

export const immutableServiceProductApi = new ServiceProductApi({
  ...options,
  immutable: true,
});

export default serviceProductApi;

export const saveProduct = async (payload, lowPriority = false) => {
  const api = lowPriority ? lowPriorityServiceProductApi : serviceProductApi;
  try {
    await api.saveProductV3(payload);
    return {};
  } catch (error) {
    return { error };
  }
};

export const getProductKeys = async ({
  productIdOrReference,
  targetMarketId = null,
}) => {
  try {
    const params = {
      product_id_or_reference: productIdOrReference,
    };
    if (targetMarketId) {
      params.target_market_id = targetMarketId;
    }
    const response = await serviceProductApi.get(
      `/product/v2/products/keys/available${qs.stringify(params, true)}`,
    );
    const { data } = response.data;
    return { productKeys: data };
  } catch (error) {
    return { error };
  }
};

export const addProductToTemplate = async ({
  templateId,
  productKeyId,
  targetOrganizationId,
}) => {
  try {
    const response = await serviceProductApi.post(
      `/product/v2/sharing-unit-templates/${templateId}/products/add`,
      {
        source_product_key_id: productKeyId,
        target_organization_id: targetOrganizationId,
      },
    );
    const { data } = response.data;
    return { sharingUnit: data };
  } catch (error) {
    return { error };
  }
};

export const acceptBulk = async (productKeyIds, reason) => {
  try {
    await serviceProductApi.post('/product/v2/products/accept/bulk', {
      product_key_ids: productKeyIds,
      reason,
    });
    return {};
  } catch (error) {
    return { error };
  }
};

export const acceptTransactions = async (productKeyId, transactionIds) => {
  await serviceProductApi.post('/product/v2/products/accept', {
    product_key_id: productKeyId,
    reason: 'manual',
    transaction_ids: transactionIds,
  });
};

export const refuseTransactions = async (productKeyId, transactionIds) => {
  await serviceProductApi.post('/product/v2/products/refuse', {
    product_key_id: productKeyId,
    reason: 'manual',
    transaction_ids: transactionIds,
  });
};

export const sendBulkMessageReminder = async (
  reminderData,
  message,
  retailerId,
) => {
  await serviceProductApi.post(
    '/product/v2/products/message/bulk_message_reminder',
    {
      data: reminderData,
      message: message,
      retailer_id: retailerId,
    },
  );
};

export const bulkActivateRecipients = async (advancedSearch, recipientIds) => {
  await serviceProductApi.post('/product/v2/products/recipient/bulk_activate', {
    advanced_search: advancedSearch,
    recipient_ids: recipientIds,
  });
};

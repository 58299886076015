import {
  MFE_PUBLISH_ERROR_TO_DATADOG,
  useSubscribe,
} from '@alkem/micro-frontend-tools';

import { logToDatadogIfApplicable } from 'utils/tracking/datadog';

export function DatadogListener() {
  useSubscribe<any>(MFE_PUBLISH_ERROR_TO_DATADOG, (error) => {
    logToDatadogIfApplicable(error);
  });

  return null;
}

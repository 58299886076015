import { DisplayGroupApi } from '@alkem/sdk-dashboard';

import { getUserLanguage as acceptLanguage } from 'utils/i18n';

const options = {
  cacheEnabled: true,
  acceptLanguage,
};

export default new DisplayGroupApi(options);

export const DisplayGroupApiImmutable = new DisplayGroupApi({
  ...options,
  immutable: true,
});

import { fromJS } from 'immutable';
import { useEffect, useMemo } from 'react';

import { buildFiltersFromQuery } from 'core/modules/list/utils/filters';

import SimpleFilter from '../../simple';
import { Filter, FilterValue } from '../types';

interface Props {
  selectedFilterMap: any;
  onChange: (filter: FilterValue, selected: boolean) => void;
  onCollapse: (key: string, collapsed: boolean) => void;
  collapsed: boolean;
  filter: Filter;
}

export function GenericCheckBoxFilter({
  selectedFilterMap,
  onChange,
  onCollapse,
  collapsed = false,
  filter,
}: Props) {
  useEffect(() => {
    buildFiltersFromQuery({
      filterList: filter.values,
      filterKey: filter.key,
      selectFilterValue: (f: Map<string, any>) => f.get('id'),
      selectFilterLabel: (f: Map<string, any>) =>
        `${filter.label}: ${f.get('label')}`,
      selectFilterData: (f: any) => f,
    }).then((filtersFromQuery) => {
      onChange(filtersFromQuery, true);
    });
  }, [filter.key, filter.label, filter.values, onChange]);

  const onChangeCollapse = (isCollapse: boolean) => {
    onCollapse(filter.key, isCollapse);
  };

  const filterValues = useMemo(() => fromJS(filter.values), [filter.values]);

  return (
    <SimpleFilter
      id="list-filter-rfp-bu-status"
      filterList={filterValues}
      filterKey={filter.key}
      filterLabel={filter.label}
      selectedFilterMap={selectedFilterMap.get(filter.key)}
      collapsed={collapsed}
      onChange={onChange}
      onCollapse={onChangeCollapse}
    />
  );
}

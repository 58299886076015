import { ReactNode, memo } from 'react';
import { connect } from 'react-redux';
import { createStructuredSelector } from 'reselect';

import { Cookie } from '@alkem/sdk-dashboard';

import { getAppConfig } from 'utils/app-config';
import { separateActions } from 'utils/redux';

import { saveTrackingPreferences } from '../actions';
import { selectIsConsentModalOpened } from '../selectors';
import { ReducerState } from '../types';

import { ConsentManagerConsumer } from './builder';
import ConsentManagerModal from './modal';

type ConnectedProps = Pick<ReducerState, 'isConsentModalOpened'>;

interface Actions {
  saveTrackingPreferences: typeof saveTrackingPreferences;
}

interface Props extends ConnectedProps {
  actions: Actions;
  children: ReactNode;
  bypass: boolean;
  hasSalesforceKnowledgeBase: boolean;
}

const mapStateToProps = createStructuredSelector<
  ConnectedProps,
  ConnectedProps
>({
  isConsentModalOpened: selectIsConsentModalOpened,
});

const mapDispatchToProps: Actions = {
  saveTrackingPreferences,
};

export function ConsentManagerChecker({
  actions,
  isConsentModalOpened,
  children,
  bypass,
  hasSalesforceKnowledgeBase,
}: Props) {
  // FIXME: remove this after Salesforce remplaced by Elevio in production
  if (hasSalesforceKnowledgeBase && !global._elev?.isSXM) {
    Object.defineProperty(global, '_elev', {
      configurable: false,
      writable: false,
      enumerable: true,
      value: Object.freeze({ isSXM: true, openArticle() {}, openModule() {} }),
    });
  }

  if (
    bypass ||
    Cookie.getCookie('noConsentManager') ||
    !getAppConfig().segment?.enabled
  ) {
    return <>{children}</>;
  }

  return (
    <ConsentManagerConsumer>
      {({
        destinations,
        preferences,
        setPreferences,
        saveConsent,
        isTrackingReviewed,
      }) => (
        <>
          {isTrackingReviewed && children}
          {(!isTrackingReviewed || isConsentModalOpened) && (
            <ConsentManagerModal
              destinations={destinations}
              preferences={preferences}
              setPreferences={setPreferences}
              saveConsent={saveConsent}
              actions={actions}
              hasSalesforceKnowledgeBase={hasSalesforceKnowledgeBase}
            />
          )}
        </>
      )}
    </ConsentManagerConsumer>
  );
}

export default connect(
  mapStateToProps,
  mapDispatchToProps,
  separateActions,
)(memo(ConsentManagerChecker));

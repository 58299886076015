import { Map, fromJS } from 'immutable';
import { createReducer } from 'redux-create-reducer';

import {
  SEARCH as SEARCH_FROM_CATALOG,
  SET_CATALOG_CONTEXT,
} from 'modules/catalog/product/actions/types';

import { RECEIVE_AGGREGATIONS } from '../actions/types';

const initialState = fromJS({
  count: {},
  search: '',
  route: '',
});

export default createReducer(initialState, {
  [RECEIVE_AGGREGATIONS]: (state, { payload: count }) =>
    state.set('count', Map(count)),
  [SEARCH_FROM_CATALOG]: (state, { payload: search }) =>
    state.set('search', search),
  [SET_CATALOG_CONTEXT]: (state, { payload: { route } }) =>
    state.set('route', route),
});

import { SET_HIERARCHY_GRID_ERROR } from '../../actionTypes';
import { checkLinearHierarchy } from '../../utils/hierarchy';

export const checkHierarchyGrid = (hierarchyGrid, sharingUnitId) => ({
  type: SET_HIERARCHY_GRID_ERROR,
  payload: {
    errors: checkLinearHierarchy(hierarchyGrid),
    sharingUnitId,
  },
});

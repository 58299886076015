import classNames from 'classnames';

import './style.scss';

interface Props {
  count: number;
  absolute?: boolean;
  top?: boolean;
}

export const UnreadCount = ({ count, absolute, top }: Props) =>
  count > 0 ? (
    <span
      className={classNames('UnreadCount', {
        'UnreadCount--absolute': absolute,
        'UnreadCount--top': top,
      })}
    >
      {count > 99 ? '99+' : count}
    </span>
  ) : null;

export const UnreadDot = () => <span className="UnreadDot"> </span>;

export default UnreadCount;

import { List, Map, fromJS } from 'immutable';
import { PureComponent, createRef } from 'react';
import { connect } from 'react-redux';

import {
  BrandsFilter,
  DataQualityFilter,
  DisplayUnitsFilter,
  DuplicatedProductsFilter,
  ExportableTagFilter,
  GpcFilter,
  HaveDoesntHaveFilter,
  InternalCategorizationFilter,
  LifecycleFilter,
  ManufacturersFilter,
  MessageFilter,
  PackshotFilter,
  ProductHierarchyFilter,
  RequestedProductsStatusFilter,
  SourceProductStatusFilter,
  TargetMarketFilter,
  WorkflowSharingStatusFilter,
} from 'core/modules/list/components/filter';
import { ScheduledApplicationDate } from 'core/modules/list/components/filter/scheduled-application-date';
import SelectedFilters from 'core/modules/list/components/filters/selected';
import {
  brandsFilter,
  dataQualityFilter,
  displayUnitsFilter,
  duplicatedProductsFilter,
  exportableTagFilter,
  gpcStatusFilter,
  internalCategorizationFilter,
  lifecycleFilter,
  manufacturersFilter,
  messageFilter,
  packshotFilter,
  productHierarchyFilter,
  requestedProductsFilter,
  requestedProductsStatusFilter,
  scheduledApplicationDateFilter,
  sourceProductStatusFilter,
  targetMarketFilter,
  targetProductStatusFilter,
  userLabelsFilter,
  workflowSharingStatusFilter,
} from 'core/modules/list/constants/filters';
import { selectUser } from 'modules/user';
import { UserImmutable } from 'types';
import i18n from 'utils/i18n';
import { get, toJsIfImmutable } from 'utils/immutable';
import { separateActions } from 'utils/redux';

import {
  collapseFilters,
  updateFilteredFilters,
  updateFiltersPagination,
  updateOnlyDuplicatedProducts,
  updateSelectedFilters,
  updateWithArchivedProducts,
} from '../../actions';
import {
  selectAggregations,
  selectFiltersConfig,
  selectOnlyDuplicatedProducts,
  selectSelectedFilterList,
  selectSelectedFilterMap,
  selectWithArchivedProducts,
} from '../../selectors';

const mapStateToProps = (state) => ({
  selectedFilterList: selectSelectedFilterList(state),
  selectedFilterMap: selectSelectedFilterMap(state),
  aggregations: selectAggregations(state),
  user: selectUser(state),
  filtersConfig: selectFiltersConfig(state),
  withArchivedProducts: selectWithArchivedProducts(state),
  onlyDuplicatedProducts: selectOnlyDuplicatedProducts(state),
});

const mapDispatchToProps = {
  updateSelectedFilters,
  collapseFilters,
  updateFilteredFilters,
  updateFiltersPagination,
  updateWithArchivedProducts,
  updateOnlyDuplicatedProducts,
};

interface ReportingExpandedFiltersProps {
  aggregations: Map<string, any>;
  selectedFilterList: List<any>;
  selectedFilterMap: Map<string, any>;
  filtersConfig: Map<string, any>;
  user: UserImmutable;
  filtersQueryMap: object;
  withArchivedProducts: boolean;
  onlyDuplicatedProducts: boolean;
  actions: {
    updateSelectedFilters: () => void;
    collapseFilters: (options: { key: string; collapsed: boolean }) => void;
    updateFilteredFilters: (options: { key: string; query: string }) => void;
    updateFiltersPagination: (options: { key: string; page: number }) => void;
    updateOnlyDuplicatedProducts: (onlyDuplicatedProducts: boolean) => void;
    updateWithArchivedProducts: (withArchived: boolean) => void;
  };
}

export class ReportingExpandedFilters extends PureComponent<ReportingExpandedFiltersProps> {
  scheduledApplicationFilterRef;

  static defaultProps = {
    filtersQueryMap: {},
  };

  constructor(props) {
    super(props);
    this.scheduledApplicationFilterRef = createRef();
  }

  /**
   * When reseting all filters, clear ScheduledApplicationDateFilter state
   * that are normally reset from URL change, but on reporting page
   * no data are passed from URL
   */
  clearScheduledApplicationDateIfNeeded = (filter) => {
    const jsFilter = toJsIfImmutable(filter);
    if (
      (jsFilter.reset && !jsFilter.key) ||
      (jsFilter.key === scheduledApplicationDateFilter.key &&
        !jsFilter.add &&
        jsFilter.value)
    ) {
      if (jsFilter.value?.lte) {
        this.scheduledApplicationFilterRef?.current?.clearToDate?.();
      } else if (jsFilter.value?.gte) {
        this.scheduledApplicationFilterRef?.current?.clearFromDate?.();
      } else {
        this.scheduledApplicationFilterRef?.current?.clearAll?.();
      }
    }
  };

  onChangeFilter = (filter, fromQuery = false) => {
    this.props.actions[
      fromQuery ? 'updateSelectedFiltersFromQuery' : 'updateSelectedFilters'
    ](fromJS(filter));
    this.clearScheduledApplicationDateIfNeeded(filter);
  };

  onCollapseFilter = (key, collapsed) => {
    this.props.actions.collapseFilters({ key, collapsed });
  };

  onFilterFilters = (key, query) => {
    this.props.actions.updateFilteredFilters({ key, query });
  };

  onChangePage = (key, page) => {
    this.props.actions.updateFiltersPagination({ key, page });
  };

  onChangeWithArchived = (withArchived) => {
    this.props.actions.updateWithArchivedProducts(withArchived);
  };

  onChangeOnlyDuplicated = (onlyDuplicatedProducts) => {
    this.props.actions.updateOnlyDuplicatedProducts(onlyDuplicatedProducts);
  };

  render() {
    const { selectedFilterList } = this.props;
    const isFilterAvailableOptions = {
      user: this.props.user,
      selectedFilterTargetProductStatus: this.props.selectedFilterMap.get(
        targetProductStatusFilter.key,
      ),
    };
    return (
      <div className="ReportingExpandedFilters">
        <SelectedFilters
          selectedFilterList={selectedFilterList}
          onRemoveFilter={this.onChangeFilter}
        />
        {workflowSharingStatusFilter.isAvailable(isFilterAvailableOptions) && (
          <WorkflowSharingStatusFilter
            selectedFilterMap={this.props.selectedFilterMap}
            aggregations={this.props.aggregations}
            collapsed={this.props.filtersConfig.getIn([
              workflowSharingStatusFilter.key,
              'collapsed',
            ])}
            onChange={this.onChangeFilter}
            onCollapse={this.onCollapseFilter}
            filterQueryValues={
              this.props.filtersQueryMap[workflowSharingStatusFilter.key]
            }
          />
        )}
        {sourceProductStatusFilter.isAvailable(isFilterAvailableOptions) && (
          <SourceProductStatusFilter
            selectedFilterMap={get(this.props.selectedFilterMap, [
              sourceProductStatusFilter.key,
            ])}
            aggregations={this.props.aggregations}
            collapsed={this.props.filtersConfig.getIn([
              sourceProductStatusFilter.key,
              'collapsed',
            ])}
            onChange={this.onChangeFilter}
            onCollapse={this.onCollapseFilter}
            filterQueryValues={
              this.props.filtersQueryMap[sourceProductStatusFilter.key]
            }
          />
        )}
        {scheduledApplicationDateFilter.isAvailable({
          user: this.props.user,
        }) && (
          <ScheduledApplicationDate
            key={scheduledApplicationDateFilter.key}
            id={scheduledApplicationDateFilter.key}
            onChange={this.onChangeFilter}
            onFilter={this.onFilterFilters}
            onCollapse={this.onCollapseFilter}
            onChangePage={this.onChangePage}
            filterKey={scheduledApplicationDateFilter.key}
            filterLabel={i18n.t(
              'frontproductstream.reporting_page_filters.scheduled_application_date_filter.title',
              { defaultValue: 'Scheduled application date' },
            )}
            specificFilterQueryValues={toJsIfImmutable(
              get(this.props.selectedFilterMap, [
                scheduledApplicationDateFilter.key,
              ]),
            )}
            collapsed={get(this.props.filtersConfig, [
              scheduledApplicationDateFilter.key,
              'collapsed',
            ])}
            aggregations={get(this.props.aggregations, [
              scheduledApplicationDateFilter.key,
            ])}
            selectedFilterMap={get(this.props.selectedFilterMap, [
              scheduledApplicationDateFilter.key,
            ])}
            page={get(this.props.filtersConfig, [
              scheduledApplicationDateFilter.key,
              'page',
            ])}
            ref={this.scheduledApplicationFilterRef}
          />
        )}
        {displayUnitsFilter.isAvailable() && (
          <DisplayUnitsFilter
            selectedFilterMap={this.props.selectedFilterMap}
            aggregations={this.props.aggregations}
            user={this.props.user}
            collapsed={this.props.filtersConfig.getIn([
              displayUnitsFilter.key,
              'collapsed',
            ])}
            onChange={this.onChangeFilter}
            onCollapse={this.onCollapseFilter}
            filterQueryValues={
              this.props.filtersQueryMap[displayUnitsFilter.key]
            }
          />
        )}
        {exportableTagFilter.isAvailable(isFilterAvailableOptions) && (
          <ExportableTagFilter
            selectedFilterMap={get(this.props.selectedFilterMap, [
              exportableTagFilter.key,
            ])}
            aggregations={this.props.aggregations}
            user={this.props.user}
            collapsed={this.props.filtersConfig.getIn([
              exportableTagFilter.key,
              'collapsed',
            ])}
            onChange={this.onChangeFilter}
            onCollapse={this.onCollapseFilter}
            filterQueryValues={
              this.props.filtersQueryMap[exportableTagFilter.key]
            }
          />
        )}
        {lifecycleFilter.isAvailable(isFilterAvailableOptions) && (
          <LifecycleFilter
            selectedFilterMap={get(this.props.selectedFilterMap, [
              lifecycleFilter.key,
            ])}
            aggregations={this.props.aggregations}
            user={this.props.user}
            collapsed={this.props.filtersConfig.getIn([
              lifecycleFilter.key,
              'collapsed',
            ])}
            onChange={this.onChangeFilter}
            onCollapse={this.onCollapseFilter}
            filterQueryValues={this.props.filtersQueryMap[lifecycleFilter.key]}
            withArchived={this.props.withArchivedProducts}
            onChangeWithArchived={this.onChangeWithArchived}
          />
        )}
        {messageFilter.isAvailable(isFilterAvailableOptions) && (
          <MessageFilter
            filterKey={messageFilter.key}
            filterLabel={i18n.t(
              'frontproductstream.reporting_page_filters.message_status_filter.title',
              { defaultValue: 'Product Comment' },
            )}
            selectedFilterMap={get(this.props.selectedFilterMap, [
              messageFilter.key,
            ])}
            aggregations={this.props.aggregations}
            collapsed={this.props.filtersConfig.getIn([
              messageFilter.key,
              'collapsed',
            ])}
            onChange={this.onChangeFilter}
            onCollapse={this.onCollapseFilter}
            filterQueryValues={this.props.filtersQueryMap[messageFilter.key]}
          />
        )}
        {dataQualityFilter.isAvailable(isFilterAvailableOptions) && (
          <DataQualityFilter
            selectedFilterMap={get(this.props.selectedFilterMap, [
              dataQualityFilter.key,
            ])}
            aggregations={this.props.aggregations}
            user={this.props.user}
            collapsed={this.props.filtersConfig.getIn([
              dataQualityFilter.key,
              'collapsed',
            ])}
            onChange={this.onChangeFilter}
            onCollapse={this.onCollapseFilter}
            filterQueryValues={
              this.props.filtersQueryMap[dataQualityFilter.key]
            }
          />
        )}
        {packshotFilter.isAvailable(isFilterAvailableOptions) && (
          <PackshotFilter
            selectedFilterMap={get(this.props.selectedFilterMap, [
              packshotFilter.key,
            ])}
            aggregations={this.props.aggregations}
            collapsed={this.props.filtersConfig.getIn([
              packshotFilter.key,
              'collapsed',
            ])}
            onChange={this.onChangeFilter}
            onCollapse={this.onCollapseFilter}
            filterQueryValues={this.props.filtersQueryMap[packshotFilter.key]}
          />
        )}
        {internalCategorizationFilter.isAvailable(isFilterAvailableOptions) && (
          <InternalCategorizationFilter
            selectedFilterMap={this.props.selectedFilterMap.get(
              internalCategorizationFilter.key,
            )}
            aggregations={this.props.aggregations.get(
              internalCategorizationFilter.key,
            )}
            filterConfig={this.props.filtersConfig.get(
              internalCategorizationFilter.key,
            )}
            onChange={this.onChangeFilter}
            onCollapse={this.onCollapseFilter}
            onFilter={this.onFilterFilters}
            onChangePage={this.onChangePage}
            filterQueryValues={
              this.props.filtersQueryMap[internalCategorizationFilter.key]
            }
          />
        )}
        {productHierarchyFilter.isAvailable(isFilterAvailableOptions) && (
          <ProductHierarchyFilter
            selectedFilterMap={this.props.selectedFilterMap.get(
              productHierarchyFilter.key,
            )}
            aggregations={this.props.aggregations.get(
              productHierarchyFilter.key,
            )}
            user={this.props.user}
            filterConfig={this.props.filtersConfig.get(
              productHierarchyFilter.key,
            )}
            onChange={this.onChangeFilter}
            onCollapse={this.onCollapseFilter}
            onFilter={this.onFilterFilters}
            onChangePage={this.onChangePage}
            filterQueryValues={
              this.props.filtersQueryMap[productHierarchyFilter.key]
            }
          />
        )}
        {gpcStatusFilter.isAvailable(isFilterAvailableOptions) && (
          <GpcFilter
            selectedFilterMap={this.props.selectedFilterMap.get(
              gpcStatusFilter.key,
            )}
            aggregations={this.props.aggregations.get(gpcStatusFilter.key)}
            filterConfig={this.props.filtersConfig.get(gpcStatusFilter.key)}
            onChange={this.onChangeFilter}
            onCollapse={this.onCollapseFilter}
            onFilter={this.onFilterFilters}
            onChangePage={this.onChangePage}
            filterQueryValues={this.props.filtersQueryMap[gpcStatusFilter.key]}
          />
        )}
        {manufacturersFilter.isAvailable(isFilterAvailableOptions) && (
          <ManufacturersFilter
            selectedFilterMap={this.props.selectedFilterMap.get(
              manufacturersFilter.key,
            )}
            aggregations={this.props.aggregations.get(manufacturersFilter.key)}
            filterConfig={this.props.filtersConfig.get(manufacturersFilter.key)}
            onChange={this.onChangeFilter}
            onCollapse={this.onCollapseFilter}
            onFilter={this.onFilterFilters}
            onChangePage={this.onChangePage}
            filterQueryValues={
              this.props.filtersQueryMap[manufacturersFilter.key]
            }
          />
        )}
        {brandsFilter.isAvailable(isFilterAvailableOptions) && (
          <BrandsFilter
            selectedFilterMap={this.props.selectedFilterMap.get(
              brandsFilter.key,
            )}
            aggregations={this.props.aggregations.get(brandsFilter.key)}
            user={this.props.user}
            filterConfig={this.props.filtersConfig.get(brandsFilter.key)}
            onChange={this.onChangeFilter}
            onCollapse={this.onCollapseFilter}
            onFilter={this.onFilterFilters}
            onChangePage={this.onChangePage}
            filterQueryValues={this.props.filtersQueryMap[brandsFilter.key]}
          />
        )}
        {userLabelsFilter.isAvailable(isFilterAvailableOptions) && (
          <HaveDoesntHaveFilter
            selectedFilterMap={this.props.selectedFilterMap.get(
              userLabelsFilter.key,
            )}
            aggregations={this.props.aggregations.get(userLabelsFilter.key)}
            filterConfig={this.props.filtersConfig.get(userLabelsFilter.key)}
            onChange={this.onChangeFilter}
            onCollapse={this.onCollapseFilter}
            onFilter={this.onFilterFilters}
            onChangePage={this.onChangePage}
            filterQueryValues={this.props.filtersQueryMap[userLabelsFilter.key]}
            filterKey={userLabelsFilter.key}
            filterLabel={userLabelsFilter.filterLabel}
            filterSearchPlaceHolder={userLabelsFilter.filterSearchPlaceHolder}
            filterNameKey={userLabelsFilter.filterNameKey}
          />
        )}
        {targetMarketFilter.isAvailable(isFilterAvailableOptions) && (
          <TargetMarketFilter
            selectedFilterMap={this.props.selectedFilterMap.get(
              targetMarketFilter.key,
            )}
            aggregations={this.props.aggregations.get(targetMarketFilter.key)}
            filterConfig={this.props.filtersConfig.get(targetMarketFilter.key)}
            onChange={this.onChangeFilter}
            onCollapse={this.onCollapseFilter}
            onFilter={this.onFilterFilters}
            onChangePage={this.onChangePage}
            filterQueryValues={
              this.props.filtersQueryMap[targetMarketFilter.key]
            }
          />
        )}
        {duplicatedProductsFilter.isAvailable(isFilterAvailableOptions) && (
          <DuplicatedProductsFilter
            key={duplicatedProductsFilter.key}
            selectedFilterMap={this.props.selectedFilterMap.get(
              duplicatedProductsFilter.key,
            )}
            aggregations={this.props.aggregations}
            collapsed={this.props.filtersConfig.getIn([
              duplicatedProductsFilter.key,
              'collapsed',
            ])}
            onChange={this.onChangeFilter}
            onCollapse={this.onCollapseFilter}
            filterQueryValues={
              this.props.filtersQueryMap[duplicatedProductsFilter.key]
            }
            duplicatedProducts={this.props.onlyDuplicatedProducts}
            onChangeOnlyDuplicatedProducts={this.onChangeOnlyDuplicated}
          />
        )}
        {requestedProductsStatusFilter.isAvailable(
          isFilterAvailableOptions,
        ) && (
          <RequestedProductsStatusFilter
            key={requestedProductsStatusFilter.key}
            selectedFilterMapRequested={this.props.selectedFilterMap.get(
              requestedProductsFilter.key,
            )}
            selectedFilterMapRequestedStatuses={this.props.selectedFilterMap.get(
              requestedProductsStatusFilter.key,
            )}
            aggregations={this.props.aggregations.get(
              requestedProductsFilter.key,
            )}
            collapsed={this.props.filtersConfig.getIn([
              requestedProductsFilter.key,
              'collapsed',
            ])}
            onChange={this.onChangeFilter}
            onCollapse={this.onCollapseFilter}
            filterQueryValuesRequested={
              this.props.filtersQueryMap[requestedProductsFilter.key]
            }
            filterQueryValuesRequestedStatuses={
              this.props.filtersQueryMap[requestedProductsStatusFilter.key]
            }
          />
        )}
      </div>
    );
  }
}

export default connect(
  mapStateToProps,
  mapDispatchToProps,
  separateActions,
)(ReportingExpandedFilters);

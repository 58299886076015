import { fromJS } from 'immutable';

import { START_LOADING, STOP_LOADING } from 'constants/events/navigation';
import etlApi from 'resources/etlApi';
import { logError } from 'utils/logging';

import { RECEIVE_EXPORT_SYNCHRONIZATION_STATUS } from './constants';

export const listExportSynchronizationStatus =
  (productReference) => async (dispatch) => {
    dispatch({ type: START_LOADING });
    try {
      const response = await etlApi.ExportSynchronizationStatus({
        filter_gtins_in: [productReference],
      });
      dispatch({ type: STOP_LOADING });
      const exportSynchronizationStatuses = fromJS(response.data.data);
      dispatch({
        type: RECEIVE_EXPORT_SYNCHRONIZATION_STATUS,
        statuses: exportSynchronizationStatuses,
      });
      return exportSynchronizationStatuses;
    } catch (error) {
      dispatch({ type: STOP_LOADING });
      logError(error);
      return error;
    }
  };

import moment from 'moment';

import { startLoading, stopLoading } from 'actions/navigation';
import { notificationError, notificationSuccess } from 'actions/notification';
import {
  ASSETS_RECEIVE_ALL,
  CLEAR_SELECTED_IDS,
  SET_CURRENT_ASSET_TAB,
  UPDATE_NEW_PACKSHOT,
  UPDATE_NEW_SPECIFIC_PACKSHOT,
  UPDATE_SELECTED_IDS,
  UPDATE_SPECIFIC_ASSET,
} from 'constants/events/media';
import { pending } from 'constants/sharedStatus';
import { getLastShareStatus } from 'core/api/retailerproductversion';
import { selectSourceProductVersion } from 'reducers/productVersion';
import mediaApi from 'resources/mediaApi';
import i18n from 'utils/i18n';
import { logError } from 'utils/logging';

export const downloadPictures = (() => {
  let promise = null;
  return (opt) => async (dispatch) => {
    if (promise) {
      return promise;
    }
    const {
      productKeyIds,
      expectedArchives,
      packshotOnly = true,
      limit = 20,
      offset = 0,
      derivatives,
      withOrignal,
    } = opt;
    if (expectedArchives > 0) {
      dispatch(startLoading());

      const params = {
        product_key_ids: productKeyIds,
        use_product_key_ids: true,
        packshot_only: packshotOnly,
        limit,
        offset,
      };

      if (derivatives?.length) {
        params.derivatives = derivatives;
      }

      if (withOrignal) {
        params.with_original = true;
      }

      try {
        promise = mediaApi.ProductPictureDownloadAll(params);
        const response = await promise;
        const filename = `${moment().format('YYYY-MM-DD')}.zip`;
        window.saveAs(response.data, filename);
        dispatch(stopLoading());
        promise = null;
        return dispatch(
          downloadPictures({
            ...opt,
            offset: offset + limit,
            expectedArchives: expectedArchives - 1,
          }),
        );
      } catch (error) {
        logError(error);
        if (error.status === 404) {
          dispatch(
            notificationError(
              i18n.t(
                'frontproductstream.download_pictures.not_found_error.notification',
                { defaultValue: 'No pictures found' },
              ),
            ),
          );
        } else {
          dispatch(
            notificationError(
              i18n.t(
                'frontproductstream.download_pictures.unknown_error.notification',
                { defaultValue: 'An error occured while downloading pictures' },
              ),
            ),
          );
        }
        dispatch(stopLoading());
        promise = null;
      }
    } else {
      dispatch(
        notificationSuccess(
          i18n.t('frontproductstream.download_pictures.success.notification', {
            defaultValue: 'All your pictures were downloaded',
          }),
        ),
      );
    }
    return promise;
  };
})();

export const listAllAssets =
  (params = {}) =>
  async (dispatch, getState) => {
    const {
      data: { data, lastRequests, specificData, totalExpired },
    } = await mediaApi.getAssets(params);
    const sharingStatus = getLastShareStatus(
      selectSourceProductVersion(getState()),
    );
    const assets =
      sharingStatus && sharingStatus.id === pending.id ? lastRequests : data;
    if (assets) {
      dispatch({
        type: ASSETS_RECEIVE_ALL,
        payload: { assets, specificData, totalExpired },
      });
    }
  };

export const updateNewSpecificPackshot = (
  pictureId,
  recipientId,
  newSpecificPackshot,
) => ({
  type: UPDATE_NEW_SPECIFIC_PACKSHOT,
  payload: { pictureId, recipientId, newSpecificPackshot },
});

export const updateNewPackshot = (pictureId) => ({
  type: UPDATE_NEW_PACKSHOT,
  payload: { pictureId },
});

export const updateSpecificAsset = (recipientId, category, asset) => ({
  type: UPDATE_SPECIFIC_ASSET,
  payload: { recipientId, category, asset },
});

export const updateSelectedIds = (id) => ({
  type: UPDATE_SELECTED_IDS,
  payload: id,
});

export const clearSelectedIds = () => ({
  type: CLEAR_SELECTED_IDS,
});

export const setCurrentAssetTab = (tab) => ({
  type: SET_CURRENT_ASSET_TAB,
  payload: tab,
});

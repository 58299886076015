import { hasAssortmentManagement } from 'core/api/organization-settings';
import { getOrganizationSettings } from 'core/api/user';
import i18n from 'utils/i18n';

export const groupsFilter = {
  key: 'alkemics.groups.preparing_or_active',
  query: 'group_status',
  isAvailable: ({ user } = {}) =>
    hasAssortmentManagement(getOrganizationSettings(user)),
  filterLabel: i18n.t('frontproductstream.core.list_filter_groups.label', {
    defaultValue: 'Groups',
  }),
  filterSearchPlaceHolder: i18n.t(
    'frontproductstream.core.list_filter_groups.search_placeholder',
    { defaultValue: 'Search for groups' },
  ),
  filterNameKey: 'id',
};

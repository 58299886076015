/* eslint-disable react/jsx-no-bind */
import PropTypes from 'prop-types';
import { useMemo } from 'react';
import ImmutablePropTypes from 'react-immutable-proptypes';

import TurboSelect from '@alkem/react-ui-turbo-select';

import { sortAsc } from 'utils/sort';

import './progress-by-language.scss';

export const formatLabel = (language, values) =>
  `${language.code} ${
    values.getIn([language.code, 'successful']) +
      values.getIn([language.code, 'failed']) >
    0
      ? `• ${values.getIn([language.code, 'successful'])} / ${
          values.getIn([language.code, 'successful']) +
          values.getIn([language.code, 'failed'])
        }`
      : ''
  }`;

const dot = (color = '#d3d3d3') => ({
  alignItems: 'center',
  display: 'flex',

  ':before': {
    backgroundColor: color,
    borderRadius: 10,
    content: '" "',
    display: 'block',
    marginRight: 8,
    height: 10,
    width: 10,
  },
});

const ProgressByLanguage = ({
  values,
  onLanguageClick,
  languages,
  selected,
}) => {
  const memoizedLanguage = useMemo(
    () => [...languages].sort((a, b) => sortAsc(a.label, b.label)),
    [languages],
  );

  const styleLanguage = (styles, { data }) => {
    const status = values.getIn([data.code, 'failed']);
    let color;
    if (Number.isInteger(status)) {
      color = status > 0 ? '#e64c42' : '#48b071';
    }
    return {
      ...styles,
      ...dot(color),
    };
  };

  const colourStyles = {
    singleValue: styleLanguage,
    option: styleLanguage,
  };

  return (
    <div className="ProgressByLanguage">
      <TurboSelect
        options={memoizedLanguage}
        onChange={onLanguageClick}
        isClearable={false}
        className="ProgressByLanguageDropdown"
        styles={colourStyles}
        value={selected}
        getOptionLabel={(language) => formatLabel(language, values)}
        getOptionValue={(language) => language.id}
      />
    </div>
  );
};

ProgressByLanguage.propTypes = {
  values: ImmutablePropTypes.mapOf(
    ImmutablePropTypes.mapContains({
      successful: PropTypes.number.isRequired,
      failed: PropTypes.number.isRequired,
    }),
  ).isRequired,
  languages: PropTypes.arrayOf(
    PropTypes.shape({
      code: PropTypes.string.isRequired,
    }),
  ),
  onLanguageClick: PropTypes.func.isRequired,
  selected: PropTypes.shape({
    code: PropTypes.string.isRequired,
  }),
};

export default ProgressByLanguage;

import { Moment } from 'moment';

import { ResponseWithBody, ResponseWithData } from '@alkem/sdk-dashboard';

import { HistoryTransaction } from 'components/history/types';
import { Column } from 'core/modules/list/components/table/cell/smart';
import ETLApi from 'resources/etlApi';
import RfpApi from 'resources/rfpApi';

import { Answer, BusinessUnit, RfpHistoryAction } from '../../types';

import { translatePriceWaterfall } from './utils';

export const getBU = async (
  buId: string,
): Promise<ResponseWithData<ResponseWithData<BusinessUnit>>> => {
  return RfpApi.get(`/rfp/v2/business_unit/${buId}?with_referentials=true`);
};

export const listAnswer = async (
  limit: number,
  offset: number,
  aggregations?: any[],
  answerStatuses?: string[],
  search?: string,
  supplierIds?: number[],
  buIds?: number[],
  eventDateFrom?: Moment,
  eventDateTo?: Moment,
  eventIds?: number[],
  eventCodes?: string[],
  buCodes?: string[],
  sortingField?: string,
  sortingOrder?: string,
): Promise<ResponseWithData<Answer[]>> => {
  const body: any = {
    with_referentials: true,
    limit,
    offset,
    statuses: answerStatuses,
    search,
    aggregations,
    supplier_ids: supplierIds,
    business_unit_ids: buIds,
    event_ids: eventIds,
    event_codes: eventCodes,
    business_unit_codes: buCodes,
  };
  if (sortingField !== undefined && sortingOrder !== undefined) {
    body.ordering = { field: sortingField, order: sortingOrder };
  }
  if (eventDateFrom) {
    body.event_date_from = eventDateFrom.format('YYYY-MM-DD');
  }
  if (eventDateTo) {
    body.event_date_to = eventDateTo.format('YYYY-MM-DD');
  }

  return RfpApi.post('/rfp/v2/answers', body);
};

export const listAnswerFullAggregation = async (
  aggregations: any[],
): Promise<ResponseWithData<Answer[]>> => {
  const body: any = {
    aggregations,
  };
  return RfpApi.post('/rfp/v2/answers/full-aggregation', body);
};

export const answersExport = async (
  buID: number,
  format: string,
  filterAnswersId: number[],
  search?: string,
  answerStatuses?: string[],
  supplierIds?: number[],
  eventDateFrom?: string[],
  eventDateTo?: string[],
  buIds?: number[],
  eventIds?: number[],
  eventCodes?: string[],
  buCodes?: string[],
): Promise<ResponseWithData<any>> => {
  const body: any = {
    bu_id: buID,
    format,
    filter_answers_id_in: filterAnswersId,
    search,
    answer_statuses: answerStatuses,
    supplier_ids: supplierIds,
    event_date_from: eventDateFrom,
    event_date_to: eventDateTo,
    business_unit_ids: buIds,
    event_ids: eventIds,
    event_codes: eventCodes,
    business_unit_codes: buCodes,
  };
  return ETLApi.post('/etl/v2/answer/rfp', body);
};

export const updateRFPLimitDate = async (
  rfps: {
    id: number;
    businessUnitId: number;
    supplierId: number;
    limitDate: Moment;
  }[],
): Promise<ResponseWithData<any>> => {
  const body = rfps.map(
    ({
      businessUnitId: business_unit_id,
      supplierId: supplier_id,
      limitDate,
      ...rest
    }) => ({
      business_unit_id,
      supplier_id,
      data: { limitDate: limitDate },
      ...rest,
    }),
  );
  return RfpApi.post('/rfp/v1/rfps/upsert', { rfps: body });
};

export const answersUpdateStatus = async (
  answerIds: number[],
  status: string,
  message: string | null,
): Promise<ResponseWithData<any>> => {
  const body: any = {
    answer_ids: answerIds,
    status,
    ...(message !== null && {
      update_requested_message: message,
    }),
  };
  return RfpApi.post('/rfp/v1/answers/status/update', body);
};

export const getHistory = async (
  answerId: number,
  limit: number,
  offset: number,
  myChanges: boolean,
): Promise<{ transactions?: HistoryTransaction[]; error?: any }> => {
  const role = myChanges ? 0 : 1;
  const body: object = {
    answer_ids: [answerId],
    role,
    limit,
    offset,
  };
  try {
    const response = (await RfpApi.post('/rfp/v1/answers/history', body)) as {
      data: HistoryTransaction[];
    };
    return { transactions: response.data };
  } catch (error) {
    return { error };
  }
};

export const getStatusHistory = async (
  answerId: number,
): Promise<{ data?: RfpHistoryAction[]; error?: any }> => {
  try {
    const response = (await RfpApi.post('/rfp/v1/answer/status_history', {
      answer_id: answerId,
    })) as {
      data: RfpHistoryAction[];
    };
    return { data: response.data };
  } catch (error) {
    return { error };
  }
};

export const getAnswersColumns = async (): Promise<
  ResponseWithBody<Column[]>
> => {
  const result = (await RfpApi.get(
    '/rfp/v1/answers/list_columns',
  )) as ResponseWithBody<Column[]>;
  return { ...result, ...{ data: translatePriceWaterfall(result.data) } };
};

export const getChildrenGtinsShared = async (
  gtins: string[],
  businessUnitId: number,
) => {
  return (
    await RfpApi.post('/rfp/v1/answers/gtins-in-du', {
      business_unit_id: businessUnitId,
      gtins: gtins,
    })
  ).data;
};

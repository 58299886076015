import { isManufacturer } from 'core/api/user';
import { selectHasRetailerLifeCycleFilterFeature } from 'modules/feature-flag/selectors';

export const lifecycleFilter = {
  key: 'alkemics.lifeCycle',
  query: 'lifecycle',
  isAvailable: ({ user } = {}) =>
    isManufacturer(user) || selectHasRetailerLifeCycleFilterFeature(user),
  withArchivedKey: 'withArchived',
};

import classNames from 'classnames';

import { Ellitips } from '@alkem/react-ui-ellitips';

import './label.scss';

interface Props {
  id: number;
  name: string;
  onDelete?: null | ((name: string, id: number) => void);
  isNew?: boolean;
}

export const UserLabel = ({ id, name, onDelete, isNew = false }: Props) => {
  return (
    <div
      className={classNames({
        UserLabel__label: true,
        'UserLabel__label--new': isNew,
      })}
    >
      <Ellitips label={name} id={`user-label-${id}`} />
      {onDelete && (
        <i
          data-testid="user-label-delete-icon"
          className="mdi mdi-close"
          onClick={() => {
            onDelete(name, id);
          }}
        />
      )}
    </div>
  );
};

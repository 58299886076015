import { defer, get } from 'lodash';
import PropTypes from 'prop-types';
import { PureComponent, createRef } from 'react';
import ImmutablePropTypes from 'react-immutable-proptypes';
import { connect } from 'react-redux';

import { UserStatus } from '@alkem/lib-front-model';
import { Button } from '@alkem/react-ui-button';
import { ClickOutside } from '@alkem/react-ui-click-outside';
import { Authentication } from '@alkem/sdk-dashboard';

import { notificationError } from 'actions/notification';
import authApi from 'resources/authApi';
import { cancelPromise } from 'utils';
import i18n from 'utils/i18n';
import { reload } from 'utils/location';

import UsersAutocomplete from './users';
import { addToHistory, fetchHistory } from './utils';

const mapDispatchToProps = {
  notifyError: notificationError,
};

class ConnectAsDropdown extends PureComponent {
  static propTypes = {
    user: ImmutablePropTypes.map.isRequired,
    notifyError: PropTypes.func.isRequired,
    hideDropdown: PropTypes.func.isRequired,
  };

  state = {
    username: this.props.user.get('username'),
    password: '',
    targetUser: '',
    targetUserInput: [],
    loading: false,
    history: [],
  };

  mainRef = createRef();

  componentDidMount() {
    this.fetchHistory();
  }

  onChangeUsername = (event) => {
    this.setState({ username: event.target.value });
  };

  onChangePassword = (event) => {
    const { targetUser, username, password } = this.state;
    this.setState({ password: event.target.value });
    if (
      targetUser &&
      username &&
      get(event, 'target.value') &&
      event.target.value.length - password.length > 6
    ) {
      defer(this.connectAs);
    }
  };

  onUserSelect = (user) => {
    this.setState({
      targetUser: user.label,
      targetUserInput: [user],
    });
    addToHistory(user);
  };

  onUserUnselect = () => {
    this.setState({
      targetUser: '',
      targetUserInput: [],
    });
  };

  isDisabled() {
    const { loading, password, targetUser } = this.state;
    return loading || !password || !targetUser;
  }

  connectAs = async () => {
    const { user, notifyError } = this.props;
    const { username, password, targetUser } = this.state;
    if (this.isDisabled()) {
      return;
    }
    try {
      const email = username || user.get('username');
      cancelPromise(this.connectAsPromise);
      this.setState({ loading: true });
      this.connectAsPromise = authApi.ConnectAs(email, password, targetUser);
      const response = await this.connectAsPromise;
      Authentication.storeTokensAfterLogAs(
        response.data.access_token,
        response.data.refresh_token,
      );
      reload();
    } catch (error) {
      this.setState({ loading: false });
      notifyError(
        get(error, 'data.message') || error.message || 'unknown error',
      );
    }
  };

  stopPropagation = (event) => {
    if (event) {
      event.stopPropagation();
    }
  };

  handleClickOutside = () => {
    this.props.hideDropdown();
  };

  fetchHistory = () => {
    const history = fetchHistory();
    if (history.length) {
      this.setState({ history });
    }
  };

  render() {
    const { user } = this.props;
    const { history, loading, password, targetUserInput, username } =
      this.state;
    return (
      <ClickOutside ref={this.mainRef} onClickOutside={this.handleClickOutside}>
        <form
          ref={this.mainRef}
          id="login-form"
          onSubmit={(event) => event.preventDefault()}
        >
          <div className="ConnectAs__dropdown" onClick={this.stopPropagation}>
            <div className="ConnectAs__dropdownRow">
              <div className="ConnectAs__label">
                {i18n.t('frontproductstream.connect_as.target.label', {
                  defaultValue: 'Log as user',
                })}
              </div>
              <UsersAutocomplete
                id="ConnectAs__targetUser"
                className="ConnectAs__targetUser"
                onSelect={this.onUserSelect}
                onUnselect={this.onUserUnselect}
                value={targetUserInput}
                filterStatus={UserStatus.ACTIVE.id}
                placeholder={i18n.t(
                  'frontproductstream.connect_as.target_email.placeholder',
                  { defaultValue: 'Log as e-mail' },
                )}
                autoFocus
                prefetchValues={history}
              />
            </div>
            <div className="ConnectAs__dropdownRow">
              <div className="ConnectAs__label">
                {i18n.t('frontproductstream.connect_as.credentials.label', {
                  defaultValue: 'Your credentials',
                })}
              </div>
              <input
                type="text"
                id="username"
                className="ConnectAs__input"
                value={username || user.get('username')}
                onChange={this.onChangeUsername}
                placeholder={i18n.t(
                  'frontproductstream.connect_as.credentials_email.placeholder',
                  { defaultValue: 'Your email' },
                )}
              />
            </div>
            <div className="ConnectAs__dropdownRow">
              <input
                type="password"
                id="password"
                className="ConnectAs__input ConnectAs__password"
                value={password}
                onChange={this.onChangePassword}
                placeholder={i18n.t(
                  'frontproductstream.connect_as.credentials_password.placeholder',
                  { defaultValue: 'Password' },
                )}
              />
            </div>
            <div className="ConnectAs__dropdownRow ConnectAs__dropdownRow--connect">
              <Button
                type="submit"
                onClick={this.connectAs}
                disabled={this.isDisabled()}
                displaySpinner={loading}
                primary
              >
                {i18n.t('frontproductstream.connect_as.button.label', {
                  defaultValue: 'Connect',
                })}
              </Button>
            </div>
          </div>
        </form>
      </ClickOutside>
    );
  }
}

export default connect(null, mapDispatchToProps)(ConnectAsDropdown);

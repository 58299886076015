import { UserLabelApiV4 } from '@alkem/sdk-dashboard';

import { getUserLanguage as acceptLanguage } from 'utils/i18n';

const options = {
  cacheEnabled: true,
  acceptLanguage,
};

export default new UserLabelApiV4(options);

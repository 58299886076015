import moment from 'moment';
import { call, put, select } from 'redux-saga/effects';

import { startLoading, stopLoading } from 'actions/navigation';
import { notificationError } from 'actions/notification';
import { FILTERS_SIMPLE_AGGREGATION_KEYS } from 'core/modules/list/constants';
import {
  addArchivedProductsFilter,
  addDuplicatedProductsFilter,
} from 'core/modules/list/utils/filters';
import {
  aggregateFilters,
  buildAdvancedSearch,
  buildFiltersQuery,
} from 'core/modules/list/utils/search';
import { selectUser } from 'modules/user';
import i18n from 'utils/i18n';
import { logError } from 'utils/logging';
import { track } from 'utils/tracking';

import {
  exportReportDone,
  receiveReport,
  startReportingLoader,
  stopReportingLoader,
} from '../../actions';
import { fetchReportById } from '../../api';
import {
  selectBackendPaginationKeys,
  selectIsBackendPagination,
  selectOnlyDuplicatedProducts,
  selectPagination,
  selectReportConfig,
  selectSelectedFilterList,
  selectWithArchivedProducts,
} from '../../selectors';

function* buildParams() {
  const user = yield select(selectUser);
  const reportConfig = yield select(selectReportConfig);
  const withArchivedProducts = yield select(selectWithArchivedProducts);
  const hasDuplicatedProducts = yield select(selectOnlyDuplicatedProducts);
  let selectedFilters = yield select(selectSelectedFilterList);
  const isBackendPagination = yield select(selectIsBackendPagination);
  const paginationKeys = yield select(selectBackendPaginationKeys);
  const pagination = yield select(selectPagination);
  let paginatedSearch = {};

  selectedFilters = addArchivedProductsFilter({
    user,
    withArchivedProducts,
    selectedFilters,
  });

  selectedFilters = addDuplicatedProductsFilter({
    user,
    hasDuplicatedProducts,
    selectedFilters,
  });

  // Some filters may have parameters to pass at the top level of the query
  const selectedFiltersWithSimpleAggregations = selectedFilters.filter(
    (filter) => FILTERS_SIMPLE_AGGREGATION_KEYS.includes(filter.get('key')),
  );

  selectedFilters = selectedFilters.filter(
    (filter) => !FILTERS_SIMPLE_AGGREGATION_KEYS.includes(filter.get('key')),
  );

  const queries = buildFiltersQuery(selectedFilters);
  const advancedSearch = buildAdvancedSearch(queries);
  const filterSimpleAggregations = aggregateFilters(
    selectedFiltersWithSimpleAggregations,
    true,
  );

  if (isBackendPagination) {
    paginatedSearch = {
      paginationKey: paginationKeys.get(pagination.get('page')) || null,
      limit: pagination.get('limit'),
    };
  }

  return {
    ...filterSimpleAggregations,
    ...advancedSearch,
    ...paginatedSearch,
    isBackendPagination,
    report: reportConfig,
  };
}

export function* fetchReportSaga() {
  try {
    yield put(startLoading());
    yield put(startReportingLoader());
    const { isBackendPagination, ...params } = yield call(buildParams);
    const { data, error } = yield call(fetchReportById, params);
    if (error) {
      throw error;
    }
    yield put(receiveReport({ ...data, isBackendPagination }));
  } catch (err) {
    if (err.status === 400) {
      yield put(notificationError(err.data.message));
    } else {
      logError(err);
      yield put(
        notificationError(
          i18n.t(
            'frontproductstream.reporting_page.fetch_report_error.notification',
            { defaultValue: 'There was an error while fetching the report.' },
          ),
        ),
      );
    }
  } finally {
    yield put(stopLoading());
    yield put(stopReportingLoader());
  }
}

export function* exportReportSaga() {
  try {
    const { isBackendPagination, ...params } = yield call(buildParams);
    const { response, error } = yield call(fetchReportById, {
      ...params,
      xls: true,
    });
    if (error) {
      throw error;
    }
    const filename = `${i18n.t(
      'frontproductstream.reporting_page.exported_report.filename_prefix',
      { defaultValue: 'Reports' },
    )} ${moment().format('lll')}.xlsx`;
    global.saveAs(response.data, filename);
    yield call(track, {
      category: 'reporting',
      action: 'reporting_exported',
    });
  } catch (err) {
    logError(err);
    yield put(
      notificationError(
        i18n.t(
          'frontproductstream.reporting_page.exported_report_error.notification',
          { defaultValue: 'There was an error while exporting the report.' },
        ),
      ),
    );
  } finally {
    yield put(exportReportDone());
  }
}

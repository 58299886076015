import { registerModuleReducer } from 'utils/modules/reducer';
import { registerSaga } from 'utils/modules/saga';

import DashboardPublicationSummary from './components';
import reducer from './reducer';
import mainSaga from './sagas';

registerModuleReducer('dashboardPublicationSummary', reducer);
registerSaga(mainSaga);

export default DashboardPublicationSummary;

import PropTypes from 'prop-types';
import { PureComponent } from 'react';

import { BRACKET_COLUMNS_DISPLAYED } from '../../../constants';

import './carousel.scss';

export default class BracketsCarousel extends PureComponent {
  static propTypes = {
    value: PropTypes.array.isRequired,
    bracketStartIndex: PropTypes.number.isRequired,
    bracketsNumDisplayed: PropTypes.number,

    onBracketStartIndexChange: PropTypes.func.isRequired,
    onDeleteBracket: PropTypes.func,
    disabled: PropTypes.bool.isRequired,
  };

  static defaultProps = {
    bracketsNumDisplayed: BRACKET_COLUMNS_DISPLAYED,
  };

  onRight = () => {
    this.props.onBracketStartIndexChange(1);
  };

  onLeft = () => {
    this.props.onBracketStartIndexChange(-1);
  };

  render = () => {
    const {
      bracketStartIndex,
      bracketsNumDisplayed,
      value,
      onDeleteBracket,
      disabled,
    } = this.props;
    return (
      <div className="BracketsCarousel">
        {value
          .slice(bracketStartIndex, bracketStartIndex + bracketsNumDisplayed)
          .map((bracket, index) => (
            // eslint-disable-next-line react/no-array-index-key
            <div key={index} className="BracketsCarousel__headerItem">
              {index === 0 && bracketStartIndex > 0 && (
                <div className="BracketsCarousel__headerLeftArrow">
                  <i
                    className="mdi mdi-arrow-left-bold"
                    onClick={this.onLeft}
                  />
                </div>
              )}
              <div className="BracketsCarousel__headerValue">
                {index + bracketStartIndex + 1}
              </div>
              {index === bracketsNumDisplayed - 1 &&
                bracketStartIndex + bracketsNumDisplayed !== value.length && (
                  <div className="BracketsCarousel__headerRightArrow">
                    <i
                      className="mdi mdi-arrow-right-bold"
                      onClick={this.onRight}
                    />
                  </div>
                )}
              {!disabled &&
                index + bracketStartIndex === value.length - 1 &&
                index !== 0 && (
                  <span
                    className="BracketsCarousel__delete"
                    onClick={onDeleteBracket}
                  >
                    &times;
                  </span>
                )}
            </div>
          ))}
      </div>
    );
  };
}

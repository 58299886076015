import { useEffect, useRef, useState } from 'react';
import { useDispatch } from 'react-redux';

import { Button } from '@alkem/react-ui-button';

import { notificationError } from 'actions/notification';
import i18n from 'utils/i18n';
import { logError } from 'utils/logging';

import { userLabelsCreated } from '../../../actions';
import { createUserLabelV4 } from '../../../actions/apiV4';

import './add.scss';

interface Props {
  disabled?: boolean;
  organizationId: number;
  canAttachOrDetachLabel?: boolean;
}

export const AddUserLabelFormInput = ({
  disabled = false,
  organizationId,
  canAttachOrDetachLabel,
}: Props) => {
  const dispatch = useDispatch();
  const [name, setName] = useState<string>('');
  const [isCreating, setCreating] = useState<boolean>(false);
  const isMountedRef = useRef<boolean>(true);

  const createLabel = async (finalName: string) => {
    if (isCreating) {
      return;
    }
    setCreating(true);

    const { labels, error } = await createUserLabelV4({
      organizationId,
      userLabels: [finalName],
    });
    if (error) {
      logError(error);
    }
    if (isMountedRef.current) {
      if (error) {
        dispatch(
          notificationError(
            i18n.t('An error occurred while creating your label.'),
            { context: 'modal', error },
          ),
        );
      } else if (labels) {
        dispatch(
          userLabelsCreated({
            labels: labels,
            selected: canAttachOrDetachLabel,
          }),
        );
      }
      setCreating(false);
    }
  };

  const onAddLocal = () => {
    const finalName = name.trim();
    if (finalName && !disabled && !isCreating) {
      createLabel(finalName);
      setName('');
    }
  };

  useEffect(
    () => () => {
      isMountedRef.current = false;
    },
    [],
  );

  return (
    <span className="AddUserLabelFormInput">
      <input
        id="add-user-label-input"
        data-testid="add-user-label-input"
        className="form-control"
        value={name}
        disabled={disabled || isCreating}
        onChange={(event) => {
          if (!disabled && !isCreating) {
            setName(event.target.value);
          }
        }}
        onKeyUp={(event) => {
          if (!disabled && !isCreating && event.key === 'Enter') {
            onAddLocal();
          }
        }}
      />
      <Button
        content={i18n.t('Create')}
        onClick={onAddLocal}
        disabled={disabled || isCreating}
        primary
      />
    </span>
  );
};

import { PermissionModule } from '@alkem/sdk-dashboard';

import { createActionWithPayload } from '../../utils/redux';

import { PermissionData } from './types';

export const REGISTER_PERMISSION = 'permission/user/register';
export const REGISTER_MANY_PERMISSION = 'permission/user/register-many';

export const registerPermission = createActionWithPayload<
  {
    entity_type: PermissionModule;
    id: string | number;
  } & PermissionData
>(REGISTER_PERMISSION);

export const registerManyPermissions = createActionWithPayload<{
  entity_type: PermissionModule;
  permissions: Array<
    {
      entity_type: PermissionModule;
      id: string | number;
    } & PermissionData
  >;
}>(REGISTER_MANY_PERMISSION);

import {
  RESET_LOADING,
  START_LOADING,
  STOP_LOADING,
} from 'constants/events/navigation';
import { createAction } from 'utils/redux';

export const startLoading = createAction(START_LOADING);
export const stopLoading = createAction(STOP_LOADING);
export const resetLoading = createAction(RESET_LOADING);

import { get } from 'lodash/fp';

import { parseNumber } from 'utils';

import { Data, VersionData } from '../../structures';

export interface DeepUnit {
  id: string;
  path: string;
  reference: Data['reference'];
  versionData: VersionData;
  computedValue: number;
  computedChildrenValue: number | null;
  isReadOnly?: boolean;
  children: DeepUnit[] | null;
}

export type ObjectPath = (string | number)[];

function precision(a) {
  if (!isFinite(a)) return 0;
  let e = 1,
    p = 0;
  while (Math.round(a * e) / e !== a) {
    e *= 10;
    p++;
  }
  return p;
}

export function multiply(...args: (string | number | null)[]): number {
  let result = 1;
  let result_precision = 0;
  args.forEach((e) => {
    const num = parseNumber(e);
    if (num) {
      result *= num;
      result_precision += precision(num);
    } else {
      result = 0;
    }
  });
  if (!(0 < result_precision && result_precision < 100)) {
    result_precision = 0;
  }
  return +result.toFixed(result_precision);
}

export function sum(...args: number[]): number {
  let result = 0;
  let isPartial = false;
  args.forEach((e) => {
    if (e) {
      result += e;
    } else {
      isPartial = true;
    }
  });
  return isPartial ? 0 : result;
}

export function getConvertedValue(
  versionData: object,
  path: (string | number)[],
): number {
  const value = get([...path, 0], versionData);
  return multiply(
    get(['data'], value),
    get(['expressedIn', 'data', 'conversion'], value),
  );
}

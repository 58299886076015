import { createSelector } from '@reduxjs/toolkit';
import { curry } from 'lodash/fp';

import { or } from 'utils/fp';

import { hasFeature } from '.';
import {
  EDITABLE_FIRST_BRACKET_LISTING,
  EDITABLE_FIRST_BRACKET_TARIFF,
  EDITABLE_HIERARCHIES,
  EXCLUSIVE_PRODUCT_ACTIVE_RANGE_SKIP_VALIDATION,
  FEATURE_ALL_CATALOG_FILTER_EXPORTABLE,
  FEATURE_ALL_CATALOG_FILTER_QUALITY,
  FEATURE_ALL_CATALOG_FILTER_STATUS,
  FEATURE_BRAND_FILTER,
  FEATURE_FND_MULTILEVEL_FIELDS,
  FEATURE_HPP,
  FEATURE_INTERCOM_CHATBOT,
  FEATURE_IN_SYNCHRONIZATION_STATUS,
  FEATURE_MANUFACTURER_DUPLICATION,
  FEATURE_MANUFACTURER_RECIPE,
  FEATURE_MANUFACTURER_VIDEO,
  FEATURE_MEDIA_IP_RIGHTS,
  FEATURE_MESSAGE_CATALOG,
  FEATURE_MULTI_LOCALES,
  FEATURE_MULTI_TARGETMARKET_CATALOG,
  FEATURE_NIELSEN_MARKET,
  FEATURE_NO_GTIN_PRODUCTS,
  FEATURE_NPT_NIELSEN_UPSELL,
  FEATURE_PERMISSION_MANAGEMENT,
  FEATURE_PERMISSION_MANAGEMENT_GOVERNANCE,
  FEATURE_PERMISSION_MANAGEMENT_HIERARCHY,
  FEATURE_PERMISSION_MANAGEMENT_NONE,
  FEATURE_PHT_RFP_EXPORT_ALICE,
  FEATURE_PHT_RFP_EXPORT_ISIS,
  FEATURE_PHT_RFP_EXPORT_SELECTION,
  FEATURE_PHT_RFP_EXPORT_SELECTION_v2,
  FEATURE_PHT_RFP_MATURITIES,
  FEATURE_PHT_TARIFF_EXPORT_CSV,
  FEATURE_PHT_TARIFF_FILTER_SEARCH,
  FEATURE_PHYSICAL_CHECK_TRIGGER_GATE,
  FEATURE_PRODUCTPICTURE_JSON_STORE_INPUT,
  FEATURE_PRODUCT_PAGE_ACCORDION,
  FEATURE_PRODUCT_UNSYNCHRONIZATION,
  FEATURE_PRO_SUGGESTION_BLOCK,
  FEATURE_REPORTING_OVERVIEW,
  FEATURE_RETAILER_IMPORT_FROM_UI,
  FEATURE_RETAILER_INTERNAL_MULTI_CATEGORIZATION,
  FEATURE_RETAILER_LIFECYCLEFILTER,
  FEATURE_RULE_SETS_SELECTION,
  FEATURE_SHARING_STATUS_FILTER,
  FEATURE_SPECIFIC_MEDIA,
  FEATURE_SPECIFIC_PACKSHOT,
  FEATURE_SPECIFIC_VALUES,
  FEATURE_STAGE_GATES_FILTER,
  FEATURE_SYNCHRONIZATION_STATUS,
  FEATURE_THIRD_PARTY_PHYSICAL_CHECK,
  FLAT_HIERARCHIES,
  PALPA_LISTING_TEMPLATE_TYPE,
  PRODUCT_PAGE_LOAD_ON_DEMAND,
  RELEASE_BRAINSONIC_DECOMMISSION,
  RELEASE_ENHANCED_API_DOCUMENTATION,
  RELEASE_LOGISTICAL_HIERARCHIES_ADVANCED_PRIVATE_FIELDS,
  RELEASE_PHT_TARIFF_NEW_COLUMNS,
  RELEASE_PRODUCTPAGE_FLYWHEEL_MEDIA,
  RELEASE_PRODUCT_PAGE_REFRACT_STRUCTURE_FETCHING,
  RELEASE_RETAILER_DISPLAY_CONTAINING_LH_ON_BU,
  RELEASE_SUPPLIER_DIRECTORY_CLASSIFICATIONS_FILTER,
  RELEASE_SUPPLIER_DIRECTORY_WAVES_FILTER,
  RELEASE_TEXTILE,
  RELEASE_TEXTILE_VARIANTS_IMPORT_V2,
} from './constants';
import {
  createFeatureHasValueSelector,
  createFeatureSelector,
  userSelector,
} from './utils';

// FEATURE SELECTORS

/**
 * @type {_.CurriedFunction2<string, any, boolean>}
 */
export const selectUserFeature = curry((FLAG, state) =>
  hasFeature(userSelector(state), FLAG),
);

export const selectHasFeature = selectUserFeature;

export const selectHasRetailerInternalMultiCategorizationFeature =
  createFeatureSelector(FEATURE_RETAILER_INTERNAL_MULTI_CATEGORIZATION);

export const selectHasRetailerLifeCycleFilterFeature = createFeatureSelector(
  FEATURE_RETAILER_LIFECYCLEFILTER,
);

export const selectHasProductsWithoutGtinsReleased = createFeatureSelector(
  FEATURE_NO_GTIN_PRODUCTS,
);

export const selectHasNielsenMarketFeature = createFeatureSelector(
  FEATURE_NIELSEN_MARKET,
);

export const selectHasReportingOverviewFeature = createFeatureSelector(
  FEATURE_REPORTING_OVERVIEW,
);

export const selectHasMessageCatalogFeature = createFeatureSelector(
  FEATURE_MESSAGE_CATALOG,
);

export const selectHasCatalogFilterExportableFeature = createFeatureSelector(
  FEATURE_ALL_CATALOG_FILTER_EXPORTABLE,
);

export const selectHasCatalogFilterQualityFeature = createFeatureSelector(
  FEATURE_ALL_CATALOG_FILTER_QUALITY,
);

export const selectHasCatalogFilterStatusFeature = createFeatureSelector(
  FEATURE_ALL_CATALOG_FILTER_STATUS,
);

export const selectHasThirdPartyPhysicalCheckStatus = createFeatureSelector(
  FEATURE_THIRD_PARTY_PHYSICAL_CHECK,
);

export const selectHasNielsenUpsellFeature = createFeatureSelector(
  FEATURE_NPT_NIELSEN_UPSELL,
);

export const selectHasRuleSetsSelectionFeature = createFeatureSelector(
  FEATURE_RULE_SETS_SELECTION,
);

export const selectHasStageGatesFilterFeature = createFeatureSelector(
  FEATURE_STAGE_GATES_FILTER,
);

export const selectHasSynchronizationStatusFeature = createFeatureSelector(
  FEATURE_IN_SYNCHRONIZATION_STATUS,
);

export const selectHasPhysicalCheckTriggerGateFeature = createFeatureSelector(
  FEATURE_PHYSICAL_CHECK_TRIGGER_GATE,
);

export const selectHasDuplicationFeature = createFeatureSelector(
  FEATURE_MANUFACTURER_DUPLICATION,
);

export const selectHasSynchronizationStatusCatalogFeature =
  createFeatureSelector(FEATURE_SYNCHRONIZATION_STATUS);

export const selectHasMultipleLocales = createFeatureSelector(
  FEATURE_MULTI_LOCALES,
);

export const selectHasSuggestionBlockFeature = createFeatureSelector(
  FEATURE_PRO_SUGGESTION_BLOCK,
);

export const selectHasMultiTargetMarketFeature = createFeatureSelector(
  FEATURE_MULTI_TARGETMARKET_CATALOG,
);

export const selectHasBrandFilterFeature =
  createFeatureSelector(FEATURE_BRAND_FILTER);

export const selectHasSpecificMediaFeature = createFeatureSelector(
  FEATURE_SPECIFIC_MEDIA,
);

export const selectHasJsonStoreInputFeature = createFeatureSelector(
  FEATURE_PRODUCTPICTURE_JSON_STORE_INPUT,
);

export const selectHasSpecificPackshotFeature = createFeatureSelector(
  FEATURE_SPECIFIC_PACKSHOT,
);

export const selectHasMediaIpRights = createFeatureSelector(
  FEATURE_MEDIA_IP_RIGHTS,
);

export const selectHasHpp = createFeatureSelector(FEATURE_HPP);

export const selectHasDisplayContainingHierarchiesOnBURetailerRelease =
  createFeatureSelector(RELEASE_RETAILER_DISPLAY_CONTAINING_LH_ON_BU);

// FEATURE VALUE SELECTORS

export const selectPermissionManagementFeatureHasValueNone =
  createFeatureHasValueSelector(
    FEATURE_PERMISSION_MANAGEMENT,
    FEATURE_PERMISSION_MANAGEMENT_NONE,
  );

export const selectHasGovernancePlan = createFeatureHasValueSelector(
  FEATURE_PERMISSION_MANAGEMENT,
  FEATURE_PERMISSION_MANAGEMENT_GOVERNANCE,
);

export const selectHasHierarchyPlan = createFeatureHasValueSelector(
  FEATURE_PERMISSION_MANAGEMENT,
  FEATURE_PERMISSION_MANAGEMENT_HIERARCHY,
);

export const selectHasGovernanceOrHierarchyPlan = createSelector(
  selectHasGovernancePlan,
  selectHasHierarchyPlan,
  or,
);

export const selectHasProductUnsynchronization = createFeatureSelector(
  FEATURE_PRODUCT_UNSYNCHRONIZATION,
);

export const selectHasTemplateTypeRelease = createFeatureSelector(
  PALPA_LISTING_TEMPLATE_TYPE,
);

export const selectHasLoadOnDemandEnabled = createFeatureSelector(
  PRODUCT_PAGE_LOAD_ON_DEMAND,
);

export const selectHasEnhancedAPIDocumentationReleased = createFeatureSelector(
  RELEASE_ENHANCED_API_DOCUMENTATION,
);

export const selectHasFlatHierarchies = createFeatureSelector(FLAT_HIERARCHIES);
export const selectHasEditableHierarchies =
  createFeatureSelector(EDITABLE_HIERARCHIES);

export const selectHasProductPageAccordionEnabled = createFeatureSelector(
  FEATURE_PRODUCT_PAGE_ACCORDION,
);

export const selectHasTextileRelease = createFeatureSelector(RELEASE_TEXTILE);

export const selectHasTextileVariantImportRelease = createFeatureSelector(
  RELEASE_TEXTILE_VARIANTS_IMPORT_V2,
);

export const selectHasMultiLevel = createFeatureSelector(
  FEATURE_FND_MULTILEVEL_FIELDS,
);

export const selectHasSpecificValues = createFeatureSelector(
  FEATURE_SPECIFIC_VALUES,
);

export const selectHasSharingStatusFilter = createFeatureSelector(
  FEATURE_SHARING_STATUS_FILTER,
);

export const selectHasExclusiveProductActiveRangeSkipValidation =
  createFeatureSelector(EXCLUSIVE_PRODUCT_ACTIVE_RANGE_SKIP_VALIDATION);

export const selectHasEditableFirstBracketListing = createFeatureSelector(
  EDITABLE_FIRST_BRACKET_LISTING,
);

export const selectHasEditableFirstBracketTariff = createFeatureSelector(
  EDITABLE_FIRST_BRACKET_TARIFF,
);

export const selectHasPhtTariffNewColumns = createFeatureSelector(
  RELEASE_PHT_TARIFF_NEW_COLUMNS,
);

export const selectHasTariffFilterSearch = createFeatureSelector(
  FEATURE_PHT_TARIFF_FILTER_SEARCH,
);

export const selectHasRfpExportSelection = createFeatureSelector(
  FEATURE_PHT_RFP_EXPORT_SELECTION,
);
export const selectHasRfpExportSelection2 = createFeatureSelector(
  FEATURE_PHT_RFP_EXPORT_SELECTION_v2,
);
export const selectHasRfpExportIsis = createFeatureSelector(
  FEATURE_PHT_RFP_EXPORT_ISIS,
);
export const selectHasRfpExportAlice = createFeatureSelector(
  FEATURE_PHT_RFP_EXPORT_ALICE,
);
export const selectHasPhtTariffExportCSV = createFeatureSelector(
  FEATURE_PHT_TARIFF_EXPORT_CSV,
);

export const selectHasWavesFilter = createFeatureSelector(
  RELEASE_SUPPLIER_DIRECTORY_WAVES_FILTER,
);

export const selectHasSupplierDirectoryClassificationsFilter =
  createFeatureSelector(RELEASE_SUPPLIER_DIRECTORY_CLASSIFICATIONS_FILTER);

export const selectHasPhtRfpMaturities = createFeatureSelector(
  FEATURE_PHT_RFP_MATURITIES,
);

export const selectHasLogisticalHierarchiesAdvancedPrivateFields =
  createFeatureSelector(RELEASE_LOGISTICAL_HIERARCHIES_ADVANCED_PRIVATE_FIELDS);

export const selectHasFeatureRetailerImportFromUI = createFeatureSelector(
  FEATURE_RETAILER_IMPORT_FROM_UI,
);

export const selectHasReleaseProductpageFlywheelMedia = createFeatureSelector(
  RELEASE_PRODUCTPAGE_FLYWHEEL_MEDIA,
);

export const selectHasRecipeFeature = createFeatureSelector(
  FEATURE_MANUFACTURER_RECIPE,
);

export const selectHasVideoFeature = createFeatureSelector(
  FEATURE_MANUFACTURER_VIDEO,
);

export const selectHasBrainsonicDecommissionRelease = createFeatureSelector(
  RELEASE_BRAINSONIC_DECOMMISSION,
);

export const selectHasReleaseProductPageRefractStructureFetching =
  createFeatureSelector(RELEASE_PRODUCT_PAGE_REFRACT_STRUCTURE_FETCHING);

export const selectHasIntercomFeature = createFeatureSelector(
  FEATURE_INTERCOM_CHATBOT,
);

import { useDispatch, useSelector } from 'react-redux';

import { Button } from '@alkem/react-ui-button';

import {
  hasAnyProductUpdatePermission,
  isManufacturer,
  isPrivateLabel,
  isThirdParty,
} from 'core/api/user';
import { hasFeature } from 'modules/feature-flag';
import { RELEASE_MANUFACTURER_IMPORT_TAB } from 'modules/feature-flag/constants';
import {
  ImportModal,
  openImportModal,
  selectIsImportModalOpen,
} from 'modules/import-modal';
import { UserImmutable } from 'types';
import i18n from 'utils/i18n';

interface Props {
  user: UserImmutable;
}

export default function CatalogImportButton({ user }: Props) {
  const dispatch = useDispatch();
  const isModalOpen = !!useSelector(selectIsImportModalOpen);

  if (
    !(isManufacturer(user) || isPrivateLabel(user) || isThirdParty(user)) ||
    !hasAnyProductUpdatePermission(user) ||
    hasFeature(user, RELEASE_MANUFACTURER_IMPORT_TAB)
  ) {
    return null;
  }

  return (
    <>
      <Button
        id="catalog-actions-import"
        onClick={() => dispatch(openImportModal())}
        secondary
      >
        {i18n.t('Import')}
      </Button>
      {isModalOpen && <ImportModal withAutoClose />}
    </>
  );
}

import classNames from 'classnames';
import { List } from 'immutable';
import { bool, func, node, number, oneOfType, string } from 'prop-types';
import { useRef } from 'react';
import { list } from 'react-immutable-proptypes';
import { v4 as uuid } from 'uuid';

import { Ellitips } from '@alkem/react-ui-ellitips';

import { rightToLeftLocales } from 'constants/localization';

import './index.scss';

export default function ListTextCell({
  data,
  isDefaultData,
  isBold,
  children,
  className,
  isClickable,
  onClickCell,
  tooltipOverCharacters,
  ellitipsed,
  ellitipsedLineNumber = 1,
  maxWidth,
  localeCode,
}) {
  let content;
  if (children) {
    content = children;
  } else {
    content = List.isList(data) ? data.get(1) : data;
  }
  const displayToolTip =
    ellitipsed ||
    (tooltipOverCharacters !== null &&
      content &&
      content.length > tooltipOverCharacters);

  const strongContent = isBold ? <strong>{content}</strong> : content;
  const isRtl = rightToLeftLocales.includes(localeCode);

  const tipRefId = useRef(null);
  if (displayToolTip && !tipRefId.current) {
    tipRefId.current = uuid();
  }

  return (
    <div
      className={classNames('ListTextCell', className, {
        'ListTextCell--default': isDefaultData,
        'ListTextCell--clickable': isClickable,
        'ListTextCell--RTL': isRtl,
      })}
      onClick={isClickable ? onClickCell : undefined}
      style={{ maxWidth }}
    >
      {displayToolTip ? (
        <Ellitips
          label={strongContent}
          lines={ellitipsedLineNumber}
          id={tipRefId.current}
        />
      ) : (
        strongContent
      )}
    </div>
  );
}

ListTextCell.className = 'ListTextCell';
ListTextCell.propTypes = {
  data: oneOfType([string, list]),
  isDefaultData: bool,
  isBold: bool,
  children: node,
  className: string,
  isClickable: bool,
  onClickCell: func,
  tooltipOverCharacters: number,
  ellitipsed: bool,
  ellitipsedLineNumber: number,
  maxWidth: string,
  localeCode: string,
};

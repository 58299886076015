// Rooms
export const ROOM_TYPE_ORGANIZATION = 'organization';
export const ROOM_TYPE_PRODUCTVERSION = 'productversion';
export const ROOM_TYPE_PRODUCTVERSION_SOURCING = 'sourcing';
export const ROOM_TYPE_RFP = 'rfp';

export const ROOM_TYPES = [
  ROOM_TYPE_ORGANIZATION,
  ROOM_TYPE_PRODUCTVERSION,
  ROOM_TYPE_PRODUCTVERSION_SOURCING,
  ROOM_TYPE_RFP,
];

// Entity types
export const ENTITY_TYPE_RFP = 'rfp';

import { func, shape } from 'prop-types';
import { PureComponent } from 'react';
import { map } from 'react-immutable-proptypes';
import { connect } from 'react-redux';

import { Button } from '@alkem/react-ui-button';
import { LazyTooltip } from '@alkem/react-ui-tooltip';

import {
  STATUS_ACCEPTED,
  STATUS_PENDING,
  STATUS_REFUSED,
} from 'constants/assignation';
import { hasProductCreation } from 'core/api/user';
import AssignationRefuseModal from 'modules/assignation-refuse-modal';
import i18n from 'utils/i18n';
import { get } from 'utils/immutable';
import { separateActions } from 'utils/redux';

import { updateAssignationStatus } from '../../../../../actions';
import { ASSIGNATION } from '../../../../../constants/context';
import {
  selectAssignationGtin,
  selectAssignationId,
  selectAssignationSourceOrgs,
  selectAssignationStatus,
} from '../../../../../selectors/assignation';

import './index.scss';
import ProductAction from './product-action';

const mapDispatchToProps = {
  updateAssignationStatus,
};

export class CatalogAssignationActionColumn extends PureComponent {
  static propTypes = {
    assignation: map.isRequired,
    actions: shape({
      updateAssignationStatus: func.isRequired,
    }).isRequired,
    user: map.isRequired,
  };

  state = { refuseAttribution: false };

  onShowRefuseAttribution = () => this.setState({ refuseAttribution: true });

  onHideRefuseAttribution = () => this.setState({ refuseAttribution: false });

  onRefuseSuccess = () => {
    const { actions, assignation } = this.props;
    actions.updateAssignationStatus({
      id: selectAssignationId(assignation),
      status: STATUS_REFUSED,
    });
  };

  getRecipientId(assignation) {
    return get(selectAssignationSourceOrgs(assignation), [0, 'id']);
  }

  getRecipientName(assignation) {
    return get(selectAssignationSourceOrgs(assignation), [0, 'name']);
  }

  renderSimpleText(text) {
    return (
      <div className="CatalogAssignationActionColumn__question ListTextCell">
        {text}
      </div>
    );
  }
  renderPending() {
    const { assignation, user } = this.props;
    return (
      <>
        {this.renderSimpleText(i18n.t('Is it your product?'))}
        <div className="CatalogAssignationActionColumn__answer">
          {hasProductCreation(user) ? (
            <ProductAction
              reference={selectAssignationGtin(assignation)}
              recipient={assignation}
              action="attribution"
              fromCatalog
            >
              <Button link className="CatalogAssignationActionColumn__btn">
                <strong>{i18n.t('Yes')}</strong>
              </Button>
            </ProductAction>
          ) : (
            <LazyTooltip
              id={`accept-assignation-disabled-${selectAssignationId(
                assignation,
              )}`}
              className="CatalogAssignationActionColumn__disabledAction"
              tooltipLabel={i18n.t(
                'You need to push this product from your internal system to Alkemics',
              )}
              block
            >
              <strong>{i18n.t('Yes')} </strong>
              <i className="mdi mdi-help-circle" />
            </LazyTooltip>
          )}
          <span> | </span>
          <Button
            link
            className="CatalogAssignationActionColumn__btn"
            onClick={this.onShowRefuseAttribution}
          >
            {i18n.t('No')}
          </Button>
          {this.state.refuseAttribution && (
            <AssignationRefuseModal
              context={{
                from: 'catalog',
                assignation: assignation.toJS(),
              }}
              onClose={this.onHideRefuseAttribution}
              onReload={this.onRefuseSuccess}
              withoutNotification
            />
          )}
        </div>
      </>
    );
  }

  renderRefused() {
    return this.renderSimpleText(i18n.t('Rejected'));
  }

  renderAccepted() {
    return this.renderSimpleText(i18n.t('Accepted'));
  }

  renderUnknown() {
    return this.renderSimpleText(i18n.t('Unknown status'));
  }

  renderByStatus() {
    const { assignation } = this.props;
    switch (selectAssignationStatus(assignation)) {
      case STATUS_PENDING:
        return this.renderPending();
      case STATUS_REFUSED:
        return this.renderRefused();
      // next cases should never happen, but since you never know...
      case STATUS_ACCEPTED:
        return this.renderAccepted();
      default:
        return this.renderUnknown();
    }
  }

  render() {
    return (
      <td className="CatalogAssignationActionColumn">
        <div
          className="CatalogAssignationActionColumn__content"
          style={{ width: `${ASSIGNATION.size.action.width}px` }}
        >
          {this.renderByStatus()}
        </div>
      </td>
    );
  }
}

export default connect(
  null,
  mapDispatchToProps,
  separateActions,
)(CatalogAssignationActionColumn);

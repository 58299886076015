import { createAction } from 'utils/redux';

import * as event from '../constants/types';

export const openModal = createAction(event.OPEN_MODAL);
export const closeModal = createAction(event.CLOSE_MODAL);
export const updateMessage = createAction(event.UPDATE_MESSAGE);
export const updateAllProducts = createAction(event.UPDATE_ALL_PRODUCTS);
export const updateSourcingLabel = createAction(event.UPDATE_SOURCINGLABEL);
export const sendPromote = createAction(event.SEND);
export const addEmail = createAction(event.ADD_EMAIL);
export const removeEmail = createAction(event.REMOVE_EMAIL);
export const init = createAction(event.INIT);
export const setLoading = createAction(event.SET_LOADING);

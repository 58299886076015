import { Button } from '@alkem/react-ui-button';

import './collapse-button.scss';

interface Props {
  collapsed: boolean;
}

export default function CollapseButton({ collapsed }: Props) {
  return (
    <Button link className="DisplayGroup__openButton">
      <i className={`mdi mdi-chevron-${collapsed ? 'down' : 'up'}`} />
    </Button>
  );
}

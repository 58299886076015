import { get } from 'utils/immutable';

/**
 * Takes any object that has a target organization and returns it.
 *
 * @param {immutable map} object that has a target organization
 * @returns {number} id of that target organization
 */
export const getTargetOrganizationId = (entity) =>
  get(entity, 'targetOrganization.id');

/**
 * Takes an id and returns a function that takes an object and finally returns
 * true if the target organization id of that object matches this id.
 *
 * @param {number} id of the target organization
 * @param {immutable map} object of which to compare the id
 * @returns {boolean} true if ids are matching, false otherwise
 */
export const hasTargetOrganizationId = (id) => (entity) =>
  getTargetOrganizationId(entity) === id;

import { call, put, takeLatest } from 'redux-saga/effects';

import { getSupplier } from 'modules/supplier-directory/api';
import { Supplier } from 'modules/supplier-directory/types';
import { i18n } from 'utils/i18n';
import { withCatch } from 'utils/saga';

import { fetchSupplierSuccess } from '../actions';
import { FETCH_SUPPLIER_NOT_RECONCILED } from '../events';

export function* fetchIRSupplierSaga({ supplierId }) {
  if (!supplierId) {
    yield put(fetchSupplierSuccess(null, false));
    return;
  }

  const {
    supplier,
  }: {
    supplier: Supplier;
  } = yield call(getSupplier, supplierId);
  yield put(fetchSupplierSuccess(supplier, false));
}

export function* watchFetchIRSupplier() {
  yield takeLatest(
    FETCH_SUPPLIER_NOT_RECONCILED,
    withCatch(fetchIRSupplierSaga, {
      withNotification: true,
      errorMessage: i18n.t('An error occured while fetching the supplier'),
    }),
  );
}

import PropTypes from 'prop-types';
import { PureComponent } from 'react';
import ImmutablePropTypes from 'react-immutable-proptypes';
import { connect } from 'react-redux';

import { hasAssortmentManagement } from 'core/api/organization-settings';
import { getOrganizationSettings, managesOrganization } from 'core/api/user';
import BulkPrepareForChannels from 'modules/bulk-prepare-for-channels';
import { show } from 'modules/bulk-prepare-for-channels/actions';
import i18n from 'utils/i18n';

const mapDispatchToProps = {
  show,
};

export class PrepareForChannelAction extends PureComponent {
  static propTypes = {
    show: PropTypes.func,
    selectedMap: ImmutablePropTypes.map.isRequired,
    productMap: ImmutablePropTypes.map.isRequired,
  };

  static shouldBeDisplayed = ({ user }) =>
    hasAssortmentManagement(getOrganizationSettings(user)) &&
    managesOrganization(user);

  getLabel(nb) {
    i18n.t(
      'frontproductstream.bulk_action_prepare_for_channels.assign_products.label',
      {
        defaultValue: 'Assign {{count}} products to channels',
        count: nb,
      },
    );
  }

  openPrepareModal = () => {
    const { selectedMap, productMap } = this.props;
    const checkedProducts = productMap.filter(
      (pv, i) => selectedMap.get(i) === true,
    );

    this.props.show(checkedProducts);
  };

  render() {
    const label = this.getLabel(this.props.selectedMap.size);

    return (
      <>
        <div className="ActionOption" onClick={this.openPrepareModal}>
          {label}
        </div>
        <BulkPrepareForChannels />
      </>
    );
  }
}

export default connect(null, mapDispatchToProps)(PrepareForChannelAction);

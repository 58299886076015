import { List, Map } from 'immutable';
import { createReducer } from 'redux-create-reducer';

import * as constants from './constants';

const initialState = Map({
  opened: false,
  unsyncableRecipients: List(),
  promise: null,
});

export default createReducer(initialState, {
  [constants.OPEN_UNSYNCABLE_RECIPIENTS_MODAL]: (
    state,
    { promise, unsyncableRecipients },
  ) =>
    state
      .set('opened', true)
      .set('unsyncableRecipients', unsyncableRecipients)
      .set('promise', promise),
  [constants.CLOSE_UNSYNCABLE_RECIPIENTS_MODAL]: (state, { confirmed }) => {
    const promise = state.get('promise');
    if (confirmed) {
      promise.resolve(true);
    } else {
      promise.resolve(false);
    }
    return initialState;
  },
});

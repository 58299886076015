import i18n from 'utils/i18n';

export default function ReportingForbiddenView() {
  return (
    <h3>
      {i18n.t(
        'frontproductstream.reporting_page.advanced_reporting_forbidden_access.text',
        {
          defaultValue:
            'Interested in advanced reporting? Check this page soon!',
        },
      )}
    </h3>
  );
}

import { fromJS } from 'immutable';
import { call, put, race, select, take } from 'redux-saga/effects';

import { notificationError } from 'actions/notification';
import { selectOrganizationId } from 'modules/user';
import { selectProductKeyId } from 'reducers/productVersion';
import i18n from 'utils/i18n';
import { size } from 'utils/immutable';
import { logError } from 'utils/logging';

import { fetchLinkedUserLabelsDone, receiveAllUserLabels } from '../actions';
import { listAvailableUserLabelV4, listUserLabelV4 } from '../actions/apiV4';
import { CANCEL_FETCH_ALL_USER_LABELS } from '../actions/types';
import { selectProductKeyIds } from '../selectors/modal';

function* all() {
  try {
    const organizationId = yield select(selectOrganizationId);
    const productKeyId = yield select(selectProductKeyId);
    const productKeyIds = yield select(selectProductKeyIds);
    let labels: any[] = [];
    let error = null;
    if (productKeyIds && size(productKeyIds)) {
      const out = yield call(listAvailableUserLabelV4, {
        productKeyIds,
      });
      for (const pkey in out.data.product_key_ids) {
        for (const label of out.data.product_key_ids[pkey]) {
          if (!labels.find((l) => l.id === label.id)) {
            labels.push(label);
          }
        }
      }
      labels = fromJS(labels);
      error = out.error;
    } else if (productKeyId || organizationId) {
      const out = yield call(listUserLabelV4, {
        organizationId,
        productKeyId,
      });
      labels = out.labels;
      error = out.error;
    }

    if (labels) {
      yield put(receiveAllUserLabels(labels));
    } else if (error) {
      yield put(
        notificationError(i18n.t('An error occured while fetching your tags'), {
          context: 'modal',
        }),
      );
    }
  } catch (err) {
    logError(err);
  }
}

export function* fetchAll() {
  yield race({
    task: call(all),
    cancel: take(CANCEL_FETCH_ALL_USER_LABELS),
  });
}

export function* fetchLinked(data) {
  const { labels, error } = yield call(listUserLabelV4, {
    productKeyId: data.payload,
  });
  if (error) {
    yield put(
      notificationError(i18n.t('An error occured while fetching your tags'), {
        context: 'modal',
      }),
    );
  }
  yield put(fetchLinkedUserLabelsDone(labels));
}

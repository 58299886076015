export async function request(api, method, ...params) {
  try {
    const response = await api[method](...params);
    return { result: response.data.data };
  } catch (error) {
    return { error };
  }
}

export async function requestWithHeaders(api, method, ...params) {
  try {
    const response = await api[method](...params);
    return { result: response };
  } catch (error) {
    return { error };
  }
}

import PropTypes from 'prop-types';
import { PureComponent } from 'react';
import ImmutablePropTypes from 'react-immutable-proptypes';
import { connect } from 'react-redux';

import { SOURCING } from 'constants/user-labels';
import { getProductKeyId } from 'core/api/product';
import {
  getProductVersionId,
  getSourcingLabels,
} from 'core/api/productversion';
import { UserLabelModal, showUserLabelModal } from 'modules/user-label';
import i18n from 'utils/i18n';

import '../index.scss';

const mapDispatchToProps = {
  showUserLabelModal,
};

class SourcingLabelAction extends PureComponent {
  static propTypes = {
    showUserLabelModal: PropTypes.func,
    selectedMap: ImmutablePropTypes.map.isRequired,
    productMap: ImmutablePropTypes.map.isRequired,
  };

  static shouldBeDisplayed() {
    return true;
  }

  constructor(props) {
    super(props);
    this.edit = this.edit.bind(this);
  }

  getLabel(nb) {
    return i18n.t('Edit tags for {{count}} sheet(s)', { count: nb });
  }

  prepareSourcingLabelProps() {
    const { selectedMap, productMap } = this.props;
    const checkedProducts = selectedMap.reduce((arr, selected, pvId) => {
      if (selected) {
        arr.push(productMap.get(pvId));
      }
      return arr;
    }, []);
    const productVersionIds = [];
    const productKeyIds = [];
    const currentLabels = {};
    const selectedLabelIds = [];
    checkedProducts.forEach((pv) => {
      productKeyIds.push(getProductKeyId(pv));
      productVersionIds.push(getProductVersionId(pv));
      const pvLabels = getSourcingLabels(pv) || [];
      pvLabels.forEach((l) => {
        const labelId = l.get('id').toString();
        if (labelId in currentLabels) {
          currentLabels[labelId] += 1;
        } else {
          currentLabels[labelId] = 1;
        }
      });
    });
    Object.keys(currentLabels).forEach((k) => {
      selectedLabelIds.push({
        id: +k,
        partial: currentLabels[k] !== checkedProducts.length,
      });
    });
    return { productVersionIds, selectedLabelIds, productKeyIds };
  }

  edit() {
    const { productVersionIds, selectedLabelIds, productKeyIds } =
      this.prepareSourcingLabelProps();
    this.props.showUserLabelModal({
      productVersionIds: productVersionIds,
      selectedLabelIds: selectedLabelIds,
      productKeyIds,
    });
  }

  render() {
    const label = this.getLabel(this.props.selectedMap.size);
    return (
      <>
        <div className="ActionOption" onClick={this.edit}>
          {label}
        </div>
        <UserLabelModal context={SOURCING} bulk />
      </>
    );
  }
}

export default connect(null, mapDispatchToProps)(SourcingLabelAction);

export const MODULE_NAME = 'ASSIGNATION_REFUSE_MODAL';

/* Refuse action types */

export const ACTION_DISCARD = 'discardAttribution';
export const ACTION_NOT_COMERCIALIZED = 'notCommercializedAnymore';
/* Events */

export const INIT_ASSIGNATION = 'ASSIGNATION_REFUSE_MODAL/INIT_ASSIGNATION';
export const PROCESS_REFUSAL = 'ASSIGNATION_REFUSE_MODAL/PROCESS_REFUSAL';
export const SET_PROCESSING = 'ASSIGNATION_REFUSE_MODAL/SET_PROCESSING';
export const SET_LOADING = 'ASSIGNATION_REFUSE_MODAL/SET_LOADING';
export const SET_ASSIGNATION_INFO =
  'ASSIGNATION_REFUSE_MODAL/SET_ASSIGNATION_INFO';

import { ProductVersionApi } from '@alkem/sdk-dashboard';

import { getUserLanguage as acceptLanguage } from 'utils/i18n';

const options = {
  cacheEnabled: true,
  acceptLanguage,
  immutable: true,
};

export default new ProductVersionApi(options);

export const productVersionApiImmutable = new ProductVersionApi({
  ...options,
  immutable: true,
});

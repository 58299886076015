import { Map, fromJS } from 'immutable';
import { once } from 'lodash';
import { memo, useEffect, useMemo } from 'react';

import { SynchronizationStatusMap } from 'constants/synchronization-status';
import { buildFiltersFromQuery } from 'core/modules/list/utils/filters';

import SimpleFilter from '../simple';

import './index.scss';

type OnChangeEvent = {
  key: string;
  value: any;
  label: string;
  add: boolean;
  data: any;
};

type Props = {
  selectedFilterMap?: Map<string, any>;
  aggregations?: Map<string, any>;
  filterKey: string;
  filterLabel: string;
  collapsed?: boolean;
  onChange: (event: OnChangeEvent, add: boolean) => void;
  onCollapse: (filterKey: string, collapsed: boolean) => void;
  filterQueryValues?: any[];
};

const SynchronizationStatusFilter = memo((props: Props) => {
  const {
    selectedFilterMap = Map(),
    aggregations = Map(),
    collapsed = true,
    filterQueryValues = [],
    filterKey,
    filterLabel,
    onChange,
    onCollapse,
  } = props;

  const filterList = useMemo(
    () =>
      fromJS(
        Object.values(SynchronizationStatusMap).map((filter: any) => ({
          id: filter.id,
          label: filter.name,
          iconClassName: `ListSynchronizationStatusFilterIcon ${filter.icon} ListCatalogSynchronizationStatusColumn ListCatalogSynchronizationStatusColumn--${filter.id}`,
        })),
      ),
    [],
  );

  const updateSelectionFromQuery = once(() => {
    buildFiltersFromQuery({
      filterQueryValues: filterQueryValues,
      filterList: filterList,
      filterKey: filterKey,
      selectFilterValue: (filter) => filter.get('id'),
      selectFilterLabel: (filter) => `${filterLabel}: ${filter.get('label')}`,
      selectFilterData: (filter) => filter,
    }).then((filtersFromQuery) => {
      onChange(filtersFromQuery, true);
    });
  });

  useEffect(
    () => {
      updateSelectionFromQuery();
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [],
  );

  const _onCollapse = (isCollapsed: boolean) =>
    onCollapse(filterKey, isCollapsed);

  return (
    <SimpleFilter
      id="list-filter-synchronization-status"
      filterList={filterList}
      filterKey={filterKey}
      filterLabel={filterLabel}
      selectedFilterMap={selectedFilterMap.get(filterKey)}
      aggregations={aggregations.get(filterKey)}
      collapsed={collapsed}
      onChange={onChange}
      onCollapse={_onCollapse}
    />
  );
});

export default SynchronizationStatusFilter;
export { SynchronizationStatusFilter };

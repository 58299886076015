import { List } from 'immutable';
import { useState } from 'react';
import processString from 'react-process-string';
import { useDispatch, useSelector } from 'react-redux';

import { Textarea } from '@alkem/react-ui-inputs';
import { Tipster } from '@alkem/react-ui-tipster';

import Modal from 'components/ui/modal';
import {
  TargetProductStatusAccepted,
  TargetProductStatusPendingAssignation,
  TargetProductStatusSubmitted,
  TargetProductStatusUpdated,
  TargetProductStatusWaitingToBeShared,
} from 'constants/targetProductStatus';
import {
  getProductGTIN,
  getProductIdentifier,
  getProductProductKeyId,
  getProductVersionTargetProductStatus,
} from 'core/api/productversion';
import { isLoggedAs } from 'core/api/user';
import { selectUser } from 'modules/user';
import i18n from 'utils/i18n';

import { closeBulkMessageModal, sendBulkMessage } from '../../actions';
import { selectIsVisible } from '../../selectors';
import { ReminderData, ReminderProductInfo } from '../../types';

import './index.scss';

interface Props {
  productVersions?: List<any>;
}

export const BulkMessageModal = ({ productVersions }: Props) => {
  const isVisible: boolean = useSelector(selectIsVisible);
  const [message, setMessage] = useState('');
  const dispatch = useDispatch();
  const nbProductsSelected = productVersions?.size || 0;
  const user = useSelector(selectUser);

  if (!isVisible) {
    return null;
  }

  const closeModal = () => {
    setMessage('');
    dispatch(closeBulkMessageModal());
  };

  const onMessageChange = (event) => {
    setMessage(event.target.value);
  };

  const onConfirm = () => {
    const initialPayload: ReminderData = {
      gtins_requested: [],
      gtins_catalog: [],
      identifiers_catalog: [],
    };
    const formattedInfoBulkMessage =
      productVersions?.reduce((payload, pv) => {
        if (payload === undefined) {
          return initialPayload;
        }
        const pkId = getProductProductKeyId(pv);
        const gtin = getProductGTIN(pv);
        const identifier = getProductIdentifier(pv);
        const targetProductStatus = getProductVersionTargetProductStatus(pv);
        if (gtin) {
          const dataGrouped: ReminderProductInfo = {
            identifier: gtin,
            product_key_id: pkId,
          };

          if (
            targetProductStatus === TargetProductStatusPendingAssignation.id
          ) {
            payload?.gtins_requested.push(dataGrouped);
          } else if (
            targetProductStatus === TargetProductStatusWaitingToBeShared.id ||
            targetProductStatus === TargetProductStatusAccepted.id ||
            targetProductStatus === TargetProductStatusSubmitted.id ||
            targetProductStatus === TargetProductStatusUpdated.id
          ) {
            payload?.gtins_catalog.push(dataGrouped);
          }
        } else if (identifier) {
          const dataGrouped: ReminderProductInfo = {
            identifier: identifier,
            product_key_id: pkId,
          };
          // Identifier are only possible for retailer in ALLOW_PUSH for the moment
          // therefore, it will be already be shared by the manufacturer, thus in its catalog
          payload?.identifiers_catalog.push(dataGrouped);
        }

        return payload;
      }, initialPayload) ?? initialPayload;

    dispatch(sendBulkMessage(formattedInfoBulkMessage, message));
    closeModal();
  };

  return (
    <Modal
      modalStyle="dynamic"
      className="BulkMessage__body"
      title={i18n.t('suggestion: frontproductstream.bulk_message.modal.title', {
        defaultValue: 'Send message regarding multiple products',
      })}
      confirmButtonText={i18n.t(
        'frontproductstream.bulk_message.modal_confirmation.button',
        { defaultValue: 'Send' },
      )}
      onClose={closeModal}
      onConfirm={onConfirm}
      confirmDisabled={message.length === 0 || isLoggedAs(user)}
    >
      <div className="BulkMessage__titleMessage">
        <p>
          {processString([
            {
              regex: new RegExp(nbProductsSelected.toString(), 'g'),
              fn: () => (
                <strong key={nbProductsSelected}>
                  {i18n.t(
                    'frontproductstream.bulk_message.products_selected.text',
                    {
                      defaultValue: '{{count}} product',
                      count: nbProductsSelected,
                    },
                  )}
                </strong>
              ),
            },
          ])(
            i18n.t(
              'frontproductstream.bulk_message.suppliers_recipients.text',
              {
                defaultValue:
                  'Your message will be sent to all suppliers for {{count}} selected.',
                count: nbProductsSelected,
              },
            ),
          )}
        </p>
      </div>
      <div className="InputField__input InputTextarea InputField BulkMessage__textArea">
        <Textarea
          id="bulk-message"
          value={message}
          placeholder={i18n.t(
            'frontproductstream.bulk_message.modal_text_area.placeholder',
            { defaultValue: 'Enter your message' },
          )}
          onChange={onMessageChange}
          maxLength={2000}
          rows={10}
        />
      </div>
      <Tipster
        info={i18n.t(
          'frontproductstream.bulk_message.modal_message_attachements.help',
          {
            defaultValue:
              'Each supplier will automatically receive the list of selected products.',
          },
        )}
      />
    </Modal>
  );
};

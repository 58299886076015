import { createAction } from 'utils/redux';

import {
  COLLAPSE_FILTERS,
  EXPORT_REPORT,
  EXPORT_REPORT_DONE,
  FETCH_PRODUCTS_AGGREGATIONS,
  FETCH_REPORT,
  NEXT_PAGE,
  PREVIOUS_PAGE,
  RECEIVE_PRODUCTS_AGGREGATIONS,
  RECEIVE_REPORT,
  REDIRECT_TO_CATALOG,
  RESET,
  SAVE_REPORT_CONFIG,
  SELECT_REPORT,
  SORT_REPORT,
  START_REPORTING_LOADER,
  STOP_REPORTING_LOADER,
  UPDATE_FILTERED_FILTERS,
  UPDATE_FILTERS_PAGINATION,
  UPDATE_ITEMS_PER_PAGE,
  UPDATE_ONLY_DUPLICATED_PRODUCTS,
  UPDATE_SELECTED_FILTERS,
  UPDATE_WITH_ARCHIVED_PRODUCTS,
} from './types';

export const collapseFilters = createAction(COLLAPSE_FILTERS);
export const updateFilteredFilters = createAction(UPDATE_FILTERED_FILTERS);
export const updateFiltersPagination = createAction(UPDATE_FILTERS_PAGINATION);
export const updateItemsPerPage = createAction(UPDATE_ITEMS_PER_PAGE);
export const fetchReport = createAction(FETCH_REPORT);
export const receiveReport = createAction(RECEIVE_REPORT);
export const previousPage = createAction(PREVIOUS_PAGE);
export const nextPage = createAction(NEXT_PAGE);
export const updateSelectedFilters = createAction(UPDATE_SELECTED_FILTERS);
export const fetchProductsAggregations = createAction(
  FETCH_PRODUCTS_AGGREGATIONS,
);
export const receiveProductsAggregations = createAction(
  RECEIVE_PRODUCTS_AGGREGATIONS,
);
export const sortReport = createAction(SORT_REPORT);
export const exportReport = createAction(EXPORT_REPORT);
export const exportReportDone = createAction(EXPORT_REPORT_DONE);
export const selectReport = createAction(SELECT_REPORT);
export const resetReporting = createAction(RESET);
export const updateWithArchivedProducts = createAction(
  UPDATE_WITH_ARCHIVED_PRODUCTS,
);
export const updateOnlyDuplicatedProducts = createAction(
  UPDATE_ONLY_DUPLICATED_PRODUCTS,
);
export const redirectToCatalog = createAction(REDIRECT_TO_CATALOG);
export const saveReportConfig = createAction(SAVE_REPORT_CONFIG);
export const startReportingLoader = createAction(START_REPORTING_LOADER);
export const stopReportingLoader = createAction(STOP_REPORTING_LOADER);

import i18n from 'utils/i18n';

type FileSizeInfoProps = {
  fileSize: number;
};

export const FileSizeInfo = ({ fileSize }: FileSizeInfoProps) => {
  const fileSizeKo = Math.round(fileSize / 1000);
  return (
    <>
      <div className="ProductPictureForm__imageMetadataDetail">
        <div className="ProductPictureForm__imageMetadataTitle">
          {i18n.t('frontproducstream.asset_list.modal_v2.file_size.label', {
            defaultValue: 'File size:',
          })}
        </div>
        <div className="ProductPictureForm__imageMetadataValue">
          {`${Math.round(fileSizeKo)} ${i18n.t(
            'frontproducstream.asset_list.modal_unit.text',
            { defaultValue: 'ko' },
          )}`}
        </div>
      </div>
    </>
  );
};

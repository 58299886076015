import classNames from 'classnames';

import './index.scss';

type Props = {
  data: number | null;
  isDefaultData?: boolean;
  isClickable?: boolean;
  onClickCell?: (data: any) => void;
};

export default function ListNumberCell({
  data,
  isDefaultData,
  isClickable,
  onClickCell,
}: Props) {
  const getNum = (): string | null => {
    if (data?.toLocaleString) {
      return data.toLocaleString();
    }
    return null;
  };

  return (
    <div
      className={classNames('ListNumberCell', {
        'ListNumberCell--default': isDefaultData,
        'ListNumberCell--clickable': isClickable,
      })}
      onClick={isClickable ? onClickCell : undefined}
    >
      {getNum() || ''}
    </div>
  );
}

import { get } from 'lodash';

import applicationApi from 'resources/applicationApi';

export async function fetchApplications(filters) {
  const response = await applicationApi.ApplicationList(filters);
  return get(response, 'data.data');
}

export async function fetchCategories() {
  const response = await applicationApi.ApplicationCategories();
  return get(response, 'data.data');
}

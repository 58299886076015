export const SHARING_UNIT_TARIFF_PERMISSION = 'sharingunittemplate';
export const RFP_BUSINESSUNIT_PERMISSION = 'rfp-businessunit';
export const RFP_EVENT_PERMISSION = 'rfp-event';
export const PRODUCT_PERMISSION = 'product';
export const SHARING_UNIT_PERMISSION = 'sharingunit';
export const ORGANIZATION_PERMISSION = 'organization';

export const CREATE_PERMISSION = 'create';
export const UPDATE_PERMISSION = 'update';
export const SHOW_PERMISSION = 'show';
export const DELETE_PERMISSION = 'delete';
export const VALIDATE_PERMISSION = 'validate';
export const PATCH_PERMISSION = 'patch';
export const CERTIFY_PERMISSION = 'certify';
export const SHARE_PERMISSION = 'share';
export const DISABLE_PERMISSION = 'disable';

export const CHAT_PERMISSION = 'chat';
export const MANAGE_MATURITY_PERMISSION = 'maturity.manage';
export const UPDATES_VALIDATE_PERMISSION = 'updates.validate';
export const USERLABEL_MANAGE_PERMISSION = 'userlabel.manage';
export const HISTORY_PERMISSION = 'history';
export const NORMALIZEDCOMMENT_PERMISSION = 'normalizedcomment';
export const MANAGE_SUPPLIER_PERMISSION = 'supplier.manage';

import { flow } from 'lodash/fp';
import { memo } from 'react';
import { useSelector } from 'react-redux';

import Form from 'components/ui/form';
import { ENTITY_TYPE_PRODUCTVERSION } from 'constants/entities';
import { hasListing } from 'core/api/organization-settings';
import { AnchoredSection, AnchoredSectionItem } from 'modules/anchored';
import { selectDataOpsPatches } from 'modules/data-ops';
import { selectCollapsedFormGroups } from 'modules/display-groups/selectors';
import { selectHasLoadOnDemandEnabled } from 'modules/feature-flag/selectors';
import { selectShouldShowLogisticalHierarchies } from 'modules/logistical-hierarchies/selectors';
import { usePermission } from 'modules/permissions';
import {
  PATCH_PERMISSION,
  PRODUCT_PERMISSION,
} from 'modules/permissions/const';
import {
  selectIsManufacturer,
  selectIsRetailer,
  selectOrganizationSettings,
} from 'modules/user';
import { selectErrorTypesByDisplayGroup } from 'modules/validation-stepper/selectors';
import { selectDisplayGroups } from 'modules/view-as/selectors/fields';
import {
  selectCurrentLanguage,
  selectProductKeyId,
  selectProductVersion,
} from 'reducers/productVersion';
import i18n from 'utils/i18n';

type DisplayGroup = any; // FIXME
type ProductVersion = any; // FIXME
type Language = any; // FIXME

interface Props {
  order?: number;
}

export const ProductInformation = memo(function ProductInformationView({
  order,
}: Props) {
  const SECTION_TITLE = i18n.t('Product information');
  const SECTION_ICON = 'tag';
  const isRetailer: boolean = useSelector(selectIsRetailer);
  const isManufacturer: boolean = useSelector(selectIsManufacturer);
  const hasListingSetting: boolean = useSelector(
    flow(selectOrganizationSettings, hasListing),
  );
  const hasLoadOnDemand: boolean = useSelector(selectHasLoadOnDemandEnabled);
  const hasMatureListing: boolean = useSelector(
    selectShouldShowLogisticalHierarchies,
  );
  const currentLanguage: Language = useSelector(selectCurrentLanguage);
  const productVersion: ProductVersion = useSelector(selectProductVersion);
  const displayGroups: DisplayGroup[] = useSelector(selectDisplayGroups);
  const errorsByDisplayGroups: any = useSelector(
    selectErrorTypesByDisplayGroup,
  );
  const collapsedFormGroups: any = useSelector(selectCollapsedFormGroups);
  const patches = useSelector(selectDataOpsPatches);
  const productKeyId = useSelector(selectProductKeyId);

  const sectionCollapsed =
    isRetailer && (hasListingSetting || hasMatureListing);
  const headerContent = isManufacturer
    ? {
        icon: 'eye',
        text: i18n.t('Visible to every retailer you share the product with'),
      }
    : null;

  const { getPermission } = usePermission({
    module: PRODUCT_PERMISSION,
    entityId: productKeyId,
  });

  return (
    <div id="ProductInformation">
      <AnchoredSection
        collapsible
        collapsed={sectionCollapsed}
        icon={SECTION_ICON}
        section={SECTION_TITLE}
        order={order}
        header={headerContent}
      >
        {displayGroups.map((displayGroup, i) => {
          const collapsed =
            hasLoadOnDemand && collapsedFormGroups[displayGroup.id] !== false;
          return (
            <AnchoredSectionItem
              key={displayGroup.id}
              id={displayGroup.id}
              linked={displayGroup.kind === 'DisplayGroup'}
              section={SECTION_TITLE}
              blockName={displayGroup.label}
              position={i}
              collapsible={hasLoadOnDemand}
              collapsed={collapsed}
            >
              <Form
                collapsed={collapsed}
                collapsible={hasLoadOnDemand}
                entity={productVersion}
                entityKind={ENTITY_TYPE_PRODUCTVERSION}
                entityId={productVersion.source.id}
                formGroup={displayGroup}
                currentLanguage={currentLanguage}
                headerIndications={errorsByDisplayGroups[displayGroup.id]}
                patches={patches}
                entityPermissions={getPermission(PATCH_PERMISSION)}
              />
            </AnchoredSectionItem>
          );
        })}
      </AnchoredSection>
    </div>
  );
});

export default ProductInformation;

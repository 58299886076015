import { createSelector } from '@reduxjs/toolkit';
import { get } from 'lodash/fp';

import { MODULE_REDUCER_KEY } from 'constants/reducer';

import { PRODUCT_ADMIN_STORE_KEY } from './constants';

const selectState = get([MODULE_REDUCER_KEY, PRODUCT_ADMIN_STORE_KEY]);

export const selectIsReindexing = createSelector(
  selectState,
  (state) => state.isReindexing,
);

export const selectIndexedAt = createSelector(
  selectState,
  (state) => state.indexedAt,
);

export const selectIsResyncing = createSelector(
  selectState,
  (state) => state.isResyncing,
);

export const selectIsStartingAudit = createSelector(
  selectState,
  (state) => state.isStartingAudit,
);

export const selectAudits = createSelector(
  selectState,
  (state) => state.audits,
);

export const selectAuditContext = createSelector(
  selectState,
  (state) => state.auditContextByAuditId,
);

import ReactRouterPrompt from 'react-router-prompt';

import Modal from 'components/ui/modal';
import { i18n } from 'utils/i18n';

interface Props {
  when: Parameters<typeof ReactRouterPrompt>[0]['when'];
  children: React.ReactNode;
  title?: string | null;
}

const LeavePagePrompt = ({ when, children, title }: Props) => {
  return (
    <ReactRouterPrompt when={when}>
      {({ isActive, onConfirm, onCancel }) =>
        isActive ? (
          <Modal
            danger
            modalStyle="dynamic"
            title={
              title ||
              i18n.t('frontproductstream.leave_page_prompt.modal.title', {
                defaultValue: 'You are about to leave the page',
              })
            }
            confirmButtonText={
              i18n.t(
                'frontproductstream.leave_page_prompt.modal.confirm_button',
                { defaultValue: 'Leave the page' },
              ) as string
            }
            onConfirm={onConfirm}
            closeButtonText={
              i18n.t(
                'rontproductstream.leave_page_prompt.modal.cancel_button',
                { defaultValue: 'Cancel' },
              ) as string
            }
            onClose={onCancel}
          >
            {children}
          </Modal>
        ) : null
      }
    </ReactRouterPrompt>
  );
};

export { LeavePagePrompt };

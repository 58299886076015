import { ProductApi } from '@alkem/sdk-dashboard';

import prefs from 'constants/prefs';
import { getUserLanguage as acceptLanguage } from 'utils/i18n';

const options = {
  cacheEnabled: true,
  acceptLanguage,
  defaultTargetMarketId: prefs.defaultTargetMarket
    ? prefs.defaultTargetMarket.id
    : 250,
};

export default new ProductApi(options);

export const productApiImmutable = new ProductApi({
  ...options,
  immutable: true,
});

export const createAdminProductApi = () =>
  new ProductApi({ ...options, useAdminToken: true });

import classNames from 'classnames';
import { func, node, string } from 'prop-types';

import './style.scss';

function SimpleModal({ children, className, size, onClose }) {
  return (
    <div
      className={classNames(
        'SimpleModal',
        size && `SimpleModal--${size}`,
        className,
      )}
    >
      {children}
      {!!onClose && (
        <button className="SimpleModal__close" onClick={onClose}>
          <i className="mdi mdi-close" />
        </button>
      )}
    </div>
  );
}

SimpleModal.propTypes = {
  children: node,
  className: string,
  size: string,
  onClose: func,
};

export default SimpleModal;

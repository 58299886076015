import PropTypes from 'prop-types';
import { Fragment, PureComponent } from 'react';

import HelpTooltip from '@alkem/react-ui-helptooltip';

import i18n from 'utils/i18n';
import { toJsIfImmutable } from 'utils/immutable';

import './suggestions.scss';

class SuggestionsAutocomplete extends PureComponent {
  static propTypes = {
    suggestedEntities: PropTypes.array,
    onSuggestionClick: PropTypes.func.isRequired,
    toolTipMessage: PropTypes.string.isRequired,
  };

  render() {
    const { suggestedEntities, onSuggestionClick, toolTipMessage } = this.props;

    const buttons = suggestedEntities.map((entity, index) => (
      <Fragment key={`entity_fragment_${entity.id}`}>
        <a
          key={`entity_a_${entity.id}`}
          className="SuggestionsAutocomplete__topEntity__button"
          onClick={() =>
            onSuggestionClick({ value: toJsIfImmutable(entity) }, index)
          }
        >
          {entity.label}
        </a>
        <span
          key={`entity_span_${entity.id}`}
          className="SuggestionsAutocomplete__separator"
        >
          {' '}
          •{' '}
        </span>
      </Fragment>
    ));

    return (
      <div className="SuggestionsAutocomplete__topEntity row">
        <div className="offset-xs-4 col-xs-8">
          <div className="SuggestionsAutocomplete__topEntity__suggestion">
            <span className="SuggestionsAutocomplete__topEntity__suggestion__title">
              {i18n.t(
                'frontproductstream.autocomplete_plugin.suggestions.label',
                { defaultValue: 'Suggestions' },
              )}
            </span>
            <div className="SuggestionsAutocomplete__topEntity__suggestion__tooltip InputLabel__help">
              <HelpTooltip
                id="SuggestionsAutocomplete__topEntity__suggestion"
                message={toolTipMessage}
              />
            </div>
            {buttons}
          </div>
        </div>
      </div>
    );
  }
}

export default SuggestionsAutocomplete;

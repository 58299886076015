import { registerModuleReducer } from 'utils/modules/reducer';
import { registerSaga } from 'utils/modules/saga';

export default function registerModule(Module) {
  const { reducer, reducerKey, saga } = Module;
  if (saga) {
    registerSaga(saga);
  }
  if (reducerKey && reducer) {
    registerModuleReducer(reducerKey, reducer);
  }
  return Module;
}

import classNames from 'classnames';
import { useEffect, useRef, useState } from 'react';
import { useDispatch } from 'react-redux';

import Checkbox from '@alkem/react-ui-checkbox';

import { notificationError } from 'actions/notification';
import { CATALOG } from 'constants/user-labels';
import {
  deleteUserLabelV4,
  updateUserLabelV4,
} from 'modules/user-label/actions/apiV4';
import i18n from 'utils/i18n';
import { get } from 'utils/immutable';
import { logError } from 'utils/logging';
import { safeTrim } from 'utils/string';

import { deleteUserLabel, updateUserLabel } from '../../../actions';
import { UserLabel } from '../../../types';

import './label.scss';

function NameInput({
  value,
  placeholder,
  disabled,
  onChange,
  onEnter,
  onEscape,
}: {
  value: string;
  placeholder: string;
  disabled?: boolean;
  onChange: (name: string) => void;
  onEnter: () => void;
  onEscape: () => void;
}) {
  const inputRef = useRef<null | HTMLInputElement>(null);

  useEffect(() => {
    inputRef.current?.focus();
  }, []);

  return (
    <input
      data-testid="name-input"
      ref={inputRef}
      type="text"
      className="UserLabelFormItem__input"
      value={value}
      placeholder={placeholder}
      disabled={disabled}
      onChange={(event) => {
        if (!disabled) {
          onChange(event.target.value);
        }
      }}
      onKeyDown={(event) => {
        if (!disabled && event.key === 'Escape') {
          event.stopPropagation();
          onEscape();
        }
      }}
      onKeyPress={(event) => {
        if (!disabled && event.key === 'Enter') {
          onEnter();
        }
      }}
    />
  );
}

export const UserLabelFormItem = ({
  label,
  selected = false,
  partial = false,
  disabled = false,
  canAttachOrDetachLabel,
  canCreateUserLabel,
  context = CATALOG,
  onChange,
}: {
  label: UserLabel;
  selected?: boolean;
  partial?: boolean;
  disabled?: boolean;
  canAttachOrDetachLabel?: boolean;
  canCreateUserLabel?: boolean;
  context?: string;
  onChange: (userLabel: UserLabel, isSelected: boolean) => void;
}) => {
  const dispatch = useDispatch();
  const mountedRef = useRef(true);
  const isMounted = mountedRef.current;
  const [editable, setEditable] = useState(false);
  const [loading, setLoading] = useState('');
  const [name, setName] = useState('');
  const [showDelete, setDelete] = useState(false);

  const canEdit = canCreateUserLabel && context === CATALOG;
  const isLoading = !!loading;
  const isDisabled = disabled || isLoading;

  useEffect(
    () => () => {
      mountedRef.current = false;
    },
    [],
  );

  const resetEdition = () => {
    setName('');
    setEditable(false);
  };

  const onUpdate = async (updatedName: string) => {
    if (get(label, ['isNew'])) {
      resetEdition();
      dispatch(updateUserLabel(label.set('name', updatedName)));
    } else {
      try {
        setLoading('update');
        await updateUserLabelV4({
          labels: [{ name: updatedName, id: get(label, ['id']) }],
        });
        if (isMounted) {
          resetEdition();
          dispatch(updateUserLabel(label.set('name', updatedName)));
        }
      } catch (error: any) {
        logError(error);
        if (isMounted) {
          dispatch(
            notificationError(
              i18n.t('An error occurred while updating your label.'),
              { context: 'modal', error },
            ),
          );
        }
      } finally {
        if (isMounted) {
          setLoading('');
        }
      }
    }
  };

  const onDelete = async () => {
    if (get(label, ['isNew'])) {
      dispatch(deleteUserLabel(label));
    } else {
      try {
        setLoading('delete');
        await deleteUserLabelV4({ labelsIds: [get(label, ['id'])] });

        if (isMounted) {
          dispatch(deleteUserLabel(label));
        }
      } catch (error: any) {
        logError(error);
        if (isMounted) {
          dispatch(
            notificationError(
              i18n.t('An error occurred while deleting your label.'),
              { context: 'modal', error },
            ),
          );
        }
      } finally {
        if (isMounted) {
          setLoading('');
        }
      }
    }
  };

  const onConfirmName = () => {
    if (!isDisabled) {
      const trimmed = safeTrim(name);
      if (trimmed) {
        onUpdate(trimmed);
      }
    }
  };

  const onCancelEdit = () => {
    if (!isDisabled) {
      resetEdition();
    }
  };

  return (
    <li
      className={classNames(
        'UserLabelFormItem alk-flex alk-flex-center',
        get(label, ['isNew']) && 'UserLabelFormItem--new',
      )}
    >
      {editable ? (
        <NameInput
          value={name}
          placeholder={get(label, ['name'])}
          disabled={isDisabled}
          onChange={setName}
          onEnter={onConfirmName}
          onEscape={onCancelEdit}
        />
      ) : (
        <Checkbox
          id={`user-label-form-item-${get(label, ['id'])}`}
          label={get(label, ['name'])}
          partiallyChecked={partial}
          checked={!partial ? selected : undefined}
          disabled={isDisabled || !canAttachOrDetachLabel}
          onChange={(checked: boolean) => {
            if (!disabled && canAttachOrDetachLabel) {
              onChange(label, checked);
            }
          }}
        />
      )}
      {canEdit ? (
        <div
          className={classNames(
            'UserLabelFormItem__actions',
            editable && 'UserLabelFormItem__actions--editable',
            showDelete && 'UserLabelFormItem__actions--delete',
          )}
        >
          {editable ? (
            <>
              <button
                data-testid="update-btn"
                data-updating={loading === 'update'}
                className="UserLabelFormItem__action"
                disabled={isDisabled}
                onClick={onConfirmName}
              >
                <i
                  className={classNames(
                    'mdi',
                    loading === 'update'
                      ? 'mdi-loading mdi-spin'
                      : 'mdi-check-bold',
                  )}
                />
              </button>
              <button
                data-testid="cancel-btn"
                className="UserLabelFormItem__action"
                disabled={isDisabled}
                onClick={onCancelEdit}
              >
                <i className="mdi mdi-cancel" />
              </button>
            </>
          ) : (
            <>
              <button
                data-testid="edit-btn"
                className="UserLabelFormItem__action"
                disabled={isDisabled}
                onClick={() => {
                  if (!isDisabled) {
                    setName(get(label, 'name'));
                    setEditable(true);
                  }
                }}
              >
                <i className="mdi mdi-pen" />
              </button>
              <button
                data-testid="delete-btn"
                data-updating={loading === 'delete'}
                className="UserLabelFormItem__action"
                disabled={isDisabled}
                onClick={() => {
                  if (!isDisabled) {
                    setDelete(true);
                  }
                }}
              >
                <i
                  className={classNames(
                    'mdi',
                    loading === 'delete'
                      ? 'mdi-loading mdi-spin'
                      : 'mdi-delete',
                  )}
                />
              </button>
              {showDelete ? (
                <div className="UserLabelFormItem__confirmDelete">
                  <button
                    className="UserLabelFormItem__delete"
                    disabled={isDisabled}
                    onClick={() => {
                      if (!isDisabled) {
                        setDelete(false);
                        onDelete();
                      }
                    }}
                  >
                    {i18n.t('Delete')}
                  </button>
                  <button
                    className="UserLabelFormItem__cancelDelete"
                    onClick={() => {
                      setDelete(false);
                    }}
                  >
                    {i18n.t('Cancel')}
                  </button>
                </div>
              ) : null}
            </>
          )}
        </div>
      ) : null}
    </li>
  );
};

import { flow } from 'lodash/fp';

import { MODULE_REDUCER_KEY } from 'constants/reducer';

import { REDUCER_KEY } from '../constants';
import { ReducerState } from '../types';

export const selectState: (state: any) => ReducerState = (state) =>
  state[MODULE_REDUCER_KEY][REDUCER_KEY];

export const selectIsConsentModalOpened = flow(
  selectState,
  (state) => state.isConsentModalOpened,
);

import classNames from 'classnames';
import { List } from 'immutable';
import PropTypes from 'prop-types';
import { PureComponent } from 'react';
import ImmutablePropTypes from 'react-immutable-proptypes';

import { Ellitips } from '@alkem/react-ui-ellitips';
import { LazyTooltip } from '@alkem/react-ui-tooltip';

import i18n from 'utils/i18n';

import './index.scss';

export default class SourcingLabelsColumn extends PureComponent {
  static propTypes = {
    productVersionId: PropTypes.oneOfType([PropTypes.number, PropTypes.string])
      .isRequired,
    data: ImmutablePropTypes.list.isRequired,
  };

  static defaultProps = {
    data: List(),
  };

  static className = 'CatalogSourcingLabelsColumn';

  sourcinglabelMax = 6;

  setBlockRef = (element) => {
    this.blockElem = element;
  };

  renderLabel = (label) => {
    const { productVersionId } = this.props;
    const id = label;
    const name = label;
    return (
      <div key={id} className="CatalogSourcingLabel__label">
        <Ellitips label={name} id={`label-${productVersionId}-${id}`} />
      </div>
    );
  };

  renderMoreLabels = (hiddenLabels) => {
    const { productVersionId } = this.props;
    const id = `morelabels-${productVersionId}`;
    const message = hiddenLabels.map((label) => label).join('<br />');
    return (
      <LazyTooltip
        id={id}
        className="CatalogSourcingLabel__moreLabels"
        tooltipLabel={message}
        place="bottom"
        block
        html
      >
        {i18n.t('+{{count}} more', { count: hiddenLabels.length })}
      </LazyTooltip>
    );
  };

  render() {
    const { data } = this.props;
    let labels = data;
    const nonDisplayedLabels = [];
    const truncate = data.size > this.sourcinglabelMax;
    if (truncate) {
      labels = [];
      data.forEach((label, idx) => {
        if (idx < this.sourcinglabelMax - 1) {
          labels.push(label);
        } else {
          nonDisplayedLabels.push(label);
        }
      });
    }
    return (
      <div
        className={classNames(
          'CatalogSourcingLabel__list',
          this.blockElem?.offsetWidth > 300 &&
            'CatalogSourcingLabel__list--restrict',
        )}
        ref={this.setBlockRef}
      >
        {labels.map(this.renderLabel)}
        {truncate && this.renderMoreLabels(nonDisplayedLabels)}
      </div>
    );
  }
}

export default function formatMultipleSearch(s) {
  if (!s) {
    return '';
  }
  return s
    .split(/[\s|,|;]+/)
    .map((item) => item.replace(/[\t|\n|\s]/g, '').replace(/[,]+/, ','))
    .filter((item) => !!item)
    .join(',');
}

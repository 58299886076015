import { registerModuleReducer } from 'utils/modules/reducer';
import { registerSaga } from 'utils/modules/saga';

import PublicationSummary from './components';
import { REDUCER_KEY } from './constants';
import mainReducer from './reducer';
import mainSaga from './sagas';

registerSaga(mainSaga);
registerModuleReducer(REDUCER_KEY, mainReducer);

export default PublicationSummary;

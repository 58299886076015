import type { RumInitConfiguration } from '@datadog/browser-rum-core';
import type { DevToolsEnhancerOptions } from '@reduxjs/toolkit';

import type { AppConfigType } from '@alkem/sdk-dashboard/src/types/config';

interface DatadogConfig extends RumInitConfiguration {
  enabled: boolean;
}

export interface AppConfig extends AppConfigType {
  datadog?: DatadogConfig;
  segment?: {
    clientToken: string;
    enabled: boolean;
  };
  reduxDevtools?: { enabled: boolean } & DevToolsEnhancerOptions;
}

export const getAppConfig = (): AppConfig => global.appconfig || {};

import { useCallback } from 'react';
import { useSelector } from 'react-redux';

import { startLoading, stopLoading } from 'actions/loading';
import { clearSelectedIds } from 'actions/media';
import { notificationError, notificationSuccess } from 'actions/notification';
import { AssetTypeV2 } from 'components/ui/form/asset-list/AssetV2/types';
import { useDispatch } from 'hooks/useDispatch';
import { selectSelectedIds } from 'reducers/media';
import mediaApi from 'resources/mediaApi';
import i18n from 'utils/i18n';

type UseDeleteAssetsParams = {
  closeDeleteModal: () => void;
  updateEntities?: () => void;
  setIsProcessing: (isProcessing: boolean) => void;
  items: AssetTypeV2[];
};

export const useDeleteAssets = ({
  closeDeleteModal,
  updateEntities,
  setIsProcessing,
  items,
}: UseDeleteAssetsParams) => {
  const selectedIds = useSelector(selectSelectedIds);

  const dispatch = useDispatch();

  const onClearSelectedAssetIds = useCallback(
    () => dispatch(clearSelectedIds()),
    [dispatch],
  );

  const handleDeleteSelectedAssets = useCallback(async () => {
    closeDeleteModal();

    dispatch(startLoading());

    let handledError = false;

    const promises = selectedIds.map((entityId) => {
      const asset = items.find((item) => item.id === entityId);

      if (!asset) {
        return;
      }

      const assetType = asset?._type;

      if (assetType === 'ProductPicture' && asset?.isPackshot) {
        handledError = true;

        dispatch(
          notificationError(
            i18n.t(
              'frontproductstream.asset_list.button_bar.delete_packshot.error',
              {
                defaultValue:
                  'Choose an other packshot before deleting the current one.',
              },
            ),
            {
              context: 'modal',
            },
          ),
        );

        return;
      }

      if (assetType === 'ProductPicture') {
        return mediaApi.EntityPictureDelete(entityId);
      } else if (assetType === 'ProductDocument') {
        return mediaApi.ProductDocumentDelete(entityId);
      } else if (assetType === 'ProductVideo') {
        return mediaApi.ProductVideoDelete(entityId);
      } else if (assetType === 'ProductEnrichedContent') {
        return mediaApi.EnrichedContentDelete(entityId);
      }

      return;
    });

    try {
      setIsProcessing(true);

      await Promise.all(promises);

      onClearSelectedAssetIds();

      if (handledError) {
        return;
      }

      dispatch(
        notificationSuccess(
          i18n.t(
            'frontproductstream.asset_list.button_bar.delete_multiple_assets_notification.success',
            { defaultValue: 'Selected assets deleted successfully' },
          ),
          {
            context: 'modal',
          },
        ),
      );
    } catch {
      dispatch(
        notificationError(
          i18n.t(
            'frontproductstream.asset_list.button_bar.delete_multiple_assets_notification.error',
            {
              defaultValue:
                'An error occured while deleting the selected assets',
            },
          ),
          {
            context: 'modal',
          },
        ),
      );
    } finally {
      updateEntities && updateEntities();
      dispatch(stopLoading());
      setIsProcessing(false);
    }
  }, [
    closeDeleteModal,
    dispatch,
    items,
    onClearSelectedAssetIds,
    selectedIds,
    setIsProcessing,
    updateEntities,
  ]);

  return handleDeleteSelectedAssets;
};

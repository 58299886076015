import { Set } from 'immutable';
import { call, put, select, takeLatest } from 'redux-saga/effects';

import { notificationError, notificationSuccess } from 'actions/notification';
import * as OrganizationRulesOnProductHistory from 'constants/OrganizationRulesOnProductHistory';
import { getAllOrganizationTargetMarketIds } from 'core/api/user';
import { assignationStatusFilter } from 'core/modules/list/constants/filters';
import { selectUser } from 'modules/user';
import { fetchAssignations } from 'resources/searchApi';
import { i18n } from 'utils/i18n';
import { get } from 'utils/immutable';
import { logError } from 'utils/logging';
import { withCatch } from 'utils/saga';
import { track } from 'utils/tracking';

import { setAssignationInfo, setLoading, setProcessing } from './actions';
import {
  ACTION_DISCARD,
  ACTION_NOT_COMERCIALIZED,
  INIT_ASSIGNATION,
  PROCESS_REFUSAL,
} from './constants';
import { createProductAsArchived, declineProduct } from './resources';
import {
  selectAssignationFromCatalog,
  selectAssignationReference,
  selectAssignationTargetMarketId,
} from './selectors';

export default function* mainSaga() {
  yield takeLatest(INIT_ASSIGNATION, withCatch(initAssignation));
  yield takeLatest(PROCESS_REFUSAL, withCatch(processRefusal));
}

export const getNotCommercializedAnymoreMessage = (gtinOfReplacement) => {
  let message = i18n.t(
    'frontproductstream.assignation_refuse_modal.no_longer_sold.text',
    {
      defaultValue: 'This product is no longer sold.',
    },
  );
  if (gtinOfReplacement) {
    message += ` ${i18n.t(
      'frontproductstream.assignation_refuse_modal.replaced_by_GTIN.text',
      {
        defaultValue: 'It has been replaced with GTIN {{gtinOfReplacement}}',
        gtinOfReplacement,
      },
    )}`;
  }
  return message;
};

export function* initAssignation({ payload: { context, onClose } }) {
  try {
    yield put(setLoading({ loading: true }));

    let assignation = null;
    let reference = null;
    let informationRequestsOnly = false;
    let fromCatalog = false;

    const extractAssignationRetailers = (_assignation) =>
      get(_assignation, 'alkemics.assignation.source_organizations', []);

    if (context.from === 'catalog') {
      // We have already the index assignation data in that case
      assignation = context.assignation;
      reference = assignation.gtin;
      fromCatalog = true;
      informationRequestsOnly = extractAssignationRetailers(assignation).every(
        (org) => !!org.inforeq_id,
      );
    } else {
      // If we come from the notification list, fetch the assignation
      reference = context.reference;
      assignation = yield call(fetchAssignationFromReference, reference);
    }

    // Get manufacturer target markets
    const user = yield select(selectUser);
    const manufacturerTargetMarketIds = getAllOrganizationTargetMarketIds(user);
    let targetMarketId = null;
    if (assignation != null) {
      // Try to find a common TM between the manufacturer and one of the retailer
      // who made the assignation
      const retailers = extractAssignationRetailers(assignation);
      for (const retailer of retailers) {
        const commonTargetMarketIds = Set(
          get(retailer, 'target_markets', []).map((tm) => tm.id),
        ).intersect(manufacturerTargetMarketIds);
        if (commonTargetMarketIds.size > 0) {
          targetMarketId = commonTargetMarketIds.first();
          break;
        }
      }
    }

    // If we didn't find a target TM already, fallback on a manufacturer market
    if (!targetMarketId) {
      targetMarketId = manufacturerTargetMarketIds.first();
    }
    yield put(
      setAssignationInfo({
        reference,
        targetMarketId,
        informationRequestsOnly,
        fromCatalog,
      }),
    );
    yield put(setLoading({ loading: false }));
  } catch (error) {
    logError(error);
    yield put(
      notificationError(
        i18n.t(
          'frontproductstream.assignation_refuse_modal.fetching_assignation.error',
          {
            defaultValue: 'An error occured while fetching assignations',
          },
        ),
      ),
    );
    onClose();
  }
}

export function* fetchAssignationFromReference(reference) {
  const response = yield call(fetchAssignations, {
    limit: 1,
    queries: {
      queries: { gtinsIn: [reference] },
      advancedSearch: {
        must_not: [
          {
            query: 2,
            fields: [assignationStatusFilter.key],
          },
        ],
      },
    },
  });
  const assignations = response.list.toJS();
  if (assignations.length > 0) {
    return assignations[0];
  }
  return null;
}

export function* processRefusal({
  payload: { action, refusalMessage, gtinOfReplacement, onClose },
}) {
  try {
    yield put(setProcessing({ processing: true }));
    const reference = yield select(selectAssignationReference);
    const targetMarketId = yield select(selectAssignationTargetMarketId);
    const fromCatalog = yield select(selectAssignationFromCatalog);
    switch (action) {
      case ACTION_DISCARD:
        yield call(
          declineProduct,
          reference,
          targetMarketId,
          OrganizationRulesOnProductHistory.TYPE_NOT_THE_OWNER.id,
          refusalMessage,
        );
        yield put(
          notificationSuccess(
            i18n.t(
              'frontproductstream.assignation_refuse_modal.assignation_rejected.text',
              {
                defaultValue: 'The assignation has been rejected',
              },
            ),
          ),
        );
        break;
      case ACTION_NOT_COMERCIALIZED:
        yield call(
          createProductAsArchived,
          reference,
          targetMarketId,
          OrganizationRulesOnProductHistory.TYPE_NOT_COMERCIALIZED_ANYMORE.id,
          getNotCommercializedAnymoreMessage(gtinOfReplacement),
        );
        yield put(
          notificationSuccess(
            i18n.t(
              'frontproductstream.assignation_refuse_modal.no_longer_sold_archived.text',
              {
                defaultValue:
                  'This product is not sold any more, it has been created in your catalogue with the "archived" status. The retailer has been notified so that he updates his catalog.',
              },
            ),
          ),
        );
        break;
    }

    yield call(track, {
      category: 'assignation',
      action: 'assignation_refused',
      label: `reference#${reference}`,
      reason: action,
    });
    if (fromCatalog) {
      yield call(track, {
        category: 'assignation',
        action: 'assignation_refused_from_catalog',
        label: `reference#${reference}`,
        reason: action,
      });
    }
  } catch (error) {
    const errorMessage = get(error, 'data.message')
      ? error.data.message
      : i18n.t(
          'frontproductstream.assignation_refuse_modal.rejection_attribution.error',
          {
            defaultValue: 'An error occured while rejecting the attribution',
          },
        );
    yield put(notificationError(errorMessage, { context: 'modal' }));
    yield put(setProcessing({ processing: false }));
    logError(error);
    return;
  }
  yield put(setProcessing({ processing: false }));
  onClose();
}

import styles from './index.module.scss';

interface Props {
  title: string;
  text: string;
}

export const MaturityPanelEmptyTabContents = ({ title, text }: Props) => {
  return (
    <div className={styles.emptyTabContents}>
      <div className={styles.emptyTabContentsTitle}>{title}</div>
      <div className={styles.emptyTabContentsText}>{text}</div>
    </div>
  );
};

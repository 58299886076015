import { fromJS } from 'immutable';
import { createReducer } from 'redux-create-reducer';

import {
  SET_CONFIRM_UNSYNC_MODAL_OPENED,
  SET_LOADING_IN_PROGRESS,
  SET_TARGET_RECIPIENT,
  SET_UNAVAILABLE_UNSYNC_MODAL_OPENED,
} from './constants';

const initialState = fromJS({
  // Confirm Modal
  confirmUnsyncModalOpened: false,
  targetRecipient: null,

  // Unavailable Modal
  unavailableUnsyncModalOpened: false,

  // Common
  loadingInProgress: false,
});

export default createReducer(initialState, {
  // Confirm Modal
  [SET_CONFIRM_UNSYNC_MODAL_OPENED]: (state, { payload }) =>
    state.set('confirmUnsyncModalOpened', payload.opened),

  // Unavailable Modal
  [SET_UNAVAILABLE_UNSYNC_MODAL_OPENED]: (state, { payload }) =>
    state.set('unavailableUnsyncModalOpened', payload.opened),

  // Common
  [SET_TARGET_RECIPIENT]: (state, { payload }) =>
    state.set('targetRecipient', payload.targetRecipient),
  [SET_LOADING_IN_PROGRESS]: (state, { payload }) =>
    state.set('loadingInProgress', payload.loading),
});

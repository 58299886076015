import PropTypes from 'prop-types';
import { Component } from 'react';

import i18n from 'utils/i18n';
import { logError } from 'utils/logging';

import './error.scss';

export class FieldErrorBoundary extends Component {
  static propTypes = {
    children: PropTypes.node,
    field: PropTypes.any,
  };

  static getDerivedStateFromError(error) {
    return { error };
  }

  state = { error: null };

  componentDidCatch(error, info) {
    logError(error, info.componentStack);
  }

  render() {
    const { field, children } = this.props;
    const { error } = this.state;
    if (error) {
      return (
        <div className="row FormField">
          <div className="col-xs-4">
            <div className="InputLabel">{field.label || field.model}</div>
          </div>
          <div className="col-xs-8">
            <div className="FieldErrorBoundary__label InputLabel InputLabel--left">
              {i18n.t('frontproductstream.field.render.error', {
                defaultValue:
                  'Something went wrong with this field, please contact our support team',
              })}
            </div>
          </div>
        </div>
      );
    }
    return children;
  }
}

import { List } from 'immutable';
import { noop } from 'lodash';
import PropTypes from 'prop-types';
import { memo, useMemo } from 'react';
import ImmutablePropTypes from 'react-immutable-proptypes';
import { connect } from 'react-redux';

import { Select } from '@alkem/react-ui-select';

import Form from 'components/ui/form';
import { ENTITY_TYPE_PRODUCTVERSION_OVERRIDE } from 'constants/entities';
import AddWrapper from 'core/components/wrapper/addWrapper';
import { DataOpsPatchesPropType, selectDataOpsPatches } from 'modules/data-ops';
import i18n from 'utils/i18n';
import { toJsIfImmutable } from 'utils/immutable';
import { separateActions } from 'utils/redux';

import { addSpecificAssets, deleteSpecificAssets } from '../../actions';
import { MEDIA_KEY } from '../../constants';
import SpecificAssets from '../specific-assets';

import './specific-values.scss';

const mapDispatchToProps = {
  addSpecificAssets,
  deleteSpecificAssets,
};

const mapStateToProps = (state) => ({
  patches: selectDataOpsPatches(state),
});

export const SpecificValues = memo(
  ({
    actions,
    currentLanguage,
    data,
    hasSpecificAssets = false,
    onDeleteFieldOverride,
    onOverrideField,
    overridableFields = [],
    overriddenFields = List(),
    permissions,
    readOnly,
    recipientId,
    patches,
  }) => {
    const entity = useMemo(
      () => ({
        edited: toJsIfImmutable(data) || {},
        permissions,
      }),
      [data, permissions],
    );

    const formGroup = useMemo(
      () => ({
        kind: 'DisplayGroup',
        items: [
          {
            kind: 'DisplayGroup',
            items: toJsIfImmutable(overriddenFields) || [],
            label: i18n.t(
              'frontproductstream.recipient_specific_block.localized_values.label',
              { defaultValue: 'Localized values' },
            ),
          },
        ],
        label: i18n.t(
          'frontproductstream.recipient_specific_block.specific_information.label',
          { defaultValue: 'Recipient specific information' },
        ),
      }),
      [overriddenFields],
    );

    const onAddField = ({ value: fieldName }) => {
      onOverrideField(fieldName, recipientId);
      if (fieldName === MEDIA_KEY) {
        actions.addSpecificAssets(recipientId);
      }
    };

    const onDeleteField = (fieldName) => () => {
      onDeleteFieldOverride(fieldName, recipientId);
      if (fieldName === MEDIA_KEY) {
        actions.deleteSpecificAssets(recipientId);
      }
    };

    const extraPlugins = useMemo(
      () =>
        readOnly
          ? []
          : [
              ({ field }) => (
                <div key="key" className="SpecificValues__DeletePlugin">
                  <button
                    type="button"
                    className="SpecificValues__DeleteButton"
                    onClick={onDeleteField(field.model)}
                  >
                    <i className="mdi mdi-delete" />
                  </button>
                </div>
              ),
            ],
      [readOnly], // eslint-disable-line react-hooks/exhaustive-deps
    );

    const withDisplayGroup =
      overridableFields?.length > 0 || overriddenFields?.size > 0;
    const withSpecificMedia = hasSpecificAssets;
    const withAddField = !readOnly && overridableFields?.length > 0;

    return (
      <div className="specific-values">
        {withDisplayGroup && (
          <Form
            id={`recipient-specific-values-${recipientId}`}
            entity={entity}
            entityKind={ENTITY_TYPE_PRODUCTVERSION_OVERRIDE}
            entityId={recipientId}
            formGroup={formGroup}
            showTitle={false}
            currentLanguage={currentLanguage}
            patches={patches}
            externalPlugins={extraPlugins}
          />
        )}
        {withSpecificMedia && (
          <div className="form-group card card-block MediaBlock">
            <h3>
              {i18n.t(
                'frontproductstream.recipient_specific_block.media.label',
                { defaultValue: 'Media' },
              )}
            </h3>
            <div className="SpecificValues__DeletePlugin delete-media">
              {!readOnly && (
                <button
                  type="button"
                  className="SpecificValues__DeleteButton"
                  onClick={onDeleteField(MEDIA_KEY)}
                >
                  <i className="mdi mdi-delete" />
                </button>
              )}
            </div>
            <SpecificAssets recipientId={recipientId} readOnly={readOnly} />
          </div>
        )}
        {withAddField && (
          <AddWrapper
            label={i18n.t(
              'frontproductstream.recipient_specific_block.fields_edit.label',
              { defaultValue: 'Fields to edit' },
            )}
          >
            <Select
              id={`specific-values-select-field-${recipientId}`}
              placeholder={i18n.t(
                'frontproductstream.recipient_specific_block.add_field.placeholder',
                { defaultValue: 'Add a field...' },
              )}
              options={overridableFields}
              onValueAdd={onAddField}
              onValueDelete={noop}
              inputable
            />
          </AddWrapper>
        )}
      </div>
    );
  },
);

SpecificValues.propTypes = {
  actions: PropTypes.shape({
    addSpecificAssets: PropTypes.func.isRequired,
    deleteSpecificAssets: PropTypes.func.isRequired,
  }).isRequired,
  currentLanguage: PropTypes.object,
  data: ImmutablePropTypes.map,
  hasSpecificAssets: PropTypes.bool,
  onDeleteFieldOverride: PropTypes.func.isRequired,
  onOverrideField: PropTypes.func.isRequired,
  overridableFields: PropTypes.array,
  overriddenFields: ImmutablePropTypes.list,
  permissions: PropTypes.array,
  readOnly: PropTypes.bool.isRequired,
  recipientId: PropTypes.number.isRequired,
  patches: DataOpsPatchesPropType,
};

export default connect(
  mapStateToProps,
  mapDispatchToProps,
  separateActions,
)(SpecificValues);

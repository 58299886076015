import PropTypes from 'prop-types';

export function Anchor({ children, ...props }) {
  return (
    <a target="_blank" rel="noopener noreferrer" {...props}>
      {children}
    </a>
  );
}

Anchor.propTypes = {
  children: PropTypes.node,
};

export default Anchor;

import { isManufacturer } from 'core/api/user';
import { Filter } from 'core/modules/list/constants/filters/types';
import { UserImmutable } from 'types';

// only used for aggregation
export const manufacturerScenariosAgg: Filter = {
  key: 'manufacturer_scenarios',
  customField: 'manufacturer_scenarios',
  query: 'never used',
  isAvailable: ({ user }: { user?: UserImmutable } = {}) =>
    user != null && isManufacturer(user),
};

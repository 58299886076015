import PropTypes from 'prop-types';
import { PureComponent } from 'react';
import { connect } from 'react-redux';
import { createStructuredSelector } from 'reselect';

import Modal from 'components/ui/modal';
import i18n from 'utils/i18n';

import * as actions from './actions';
import { selectIsOpen } from './selectors';

const mapStateToProps = createStructuredSelector({
  isOpen: selectIsOpen,
});

const mapDispatchToProps = {
  close: actions.close,
};

export class ConfirmModal extends PureComponent {
  static propTypes = {
    isOpen: PropTypes.bool.isRequired,
    close: PropTypes.func.isRequired,
  };

  confirm = () => this.props.close(true);

  cancel = () => this.props.close(false);

  render() {
    const { isOpen } = this.props;
    if (!isOpen) {
      return null;
    }

    return (
      <Modal
        danger
        modalStyle="dynamic"
        title={i18n.t('Major changes on the logistical hierarchies')}
        className="ConfirmModal"
        confirmButtonText={/** @type {string} */ (i18n.t('Save changes'))}
        onConfirm={this.confirm}
        closeButtonText={
          /** @type {string} */ (i18n.t('Go back to product page'))
        }
        onClose={this.cancel}
      >
        <p>{i18n.t('You are about to change some sensitive data')}</p>
        <p>
          {i18n.t(
            'This could have an impact for retailers you have already shared your product with.',
          )}
        </p>
        <p>{i18n.t('Are you sure about these changes?')}</p>
      </Modal>
    );
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(ConfirmModal);

import { curry, flow, set } from 'lodash/fp';

import { registerModuleReducer } from 'utils/modules/reducer';
import { createReducer } from 'utils/redux';

import {
  REGISTER_MANY_PERMISSION,
  REGISTER_PERMISSION,
  registerManyPermissions,
  registerPermission,
} from './actions';
import { PermissionState } from './types';

export const REDUCER_KEY = 'permissions';

export const initialState = {
  entities: {},
};

const setPermission = curry(
  (payload: Parameters<typeof registerPermission>[0], state: PermissionState) =>
    set(
      ['entities', payload.entity_type, `${payload.id}`],
      payload.permissions,
      state,
    ),
);

export const permissionReducer = createReducer<PermissionState>(initialState, {
  [REGISTER_PERMISSION]: (
    state: PermissionState,
    { payload }: { payload?: Parameters<typeof registerPermission>[0] },
  ) => {
    if (!payload) {
      return state;
    }
    return flow(
      set(['entities', payload.entity_type], {}),
      setPermission(payload),
    )(state);
  },
  [REGISTER_MANY_PERMISSION]: (
    state,
    { payload }: { payload?: Parameters<typeof registerManyPermissions>[0] },
  ) => {
    if (!payload) {
      return state;
    }
    let newState = state;
    for (const permPayload of payload.permissions) {
      newState = setPermission(permPayload, newState);
    }
    return newState;
  },
});

registerModuleReducer(REDUCER_KEY, permissionReducer);

export default permissionReducer;

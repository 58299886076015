import PropTypes from 'prop-types';
import { useCallback, useMemo } from 'react';

import Anchor from 'components/ui/basic/anchor';
import FormGroup from 'components/ui/form/form-group';
import { ENTITY_TYPE_PRODUCT_MEDIA } from 'constants/entities';

import { AssetContext } from '../../context';
import AssetFormGroup from '../form-group';

function DocumentFormFields({
  fields,
  index,
  documentItem,
  onChangeField,
  entityIndex,
}) {
  const updateField = useCallback(
    (...args) => {
      onChangeField(index, ...args);
    },
    [onChangeField, index],
  );

  const formGroup = useMemo(
    () => ({
      kind: 'DisplayGroup',
      label: 'Document',
      items: fields,
      id: `documentForm-${documentItem.product_key_id}`,
    }),
    [documentItem.product_key_id, fields],
  );

  const ignoreFieldNames = fields.map((field) => field.model);

  return (
    <>
      <AssetContext.Consumer>
        {(assetContextValue) =>
          assetContextValue && (
            <FormGroup
              entity={documentItem}
              entityId={assetContextValue.entityId}
              entityKind={ENTITY_TYPE_PRODUCT_MEDIA}
              formGroup={formGroup}
              deepIndex={2}
              showTitle={false}
              onChangeField={updateField}
              noDispatch
              entityIndex={entityIndex}
              disableDataOps
            />
          )
        }
      </AssetContext.Consumer>

      <AssetFormGroup
        entity={documentItem}
        controller="document"
        onChangeField={updateField}
        entityIndex={entityIndex}
        ignoreFieldNames={ignoreFieldNames}
      />
    </>
  );
}

DocumentFormFields.propTypes = {
  fields: PropTypes.array,
  index: PropTypes.number,
  documentItem: PropTypes.object,
  onChangeField: PropTypes.func,
  entityIndex: PropTypes.number,
};

function DocumentForm({
  documentList,
  uploadedDocs,
  fields,
  onChangeField,
  entityIndex,
}) {
  return documentList.map((documentItem, index) => {
    const uploadedDoc = uploadedDocs[index];
    return (
      <div
        key={`DocumentModal__container-${documentItem.product_key_id}`}
        className="row DocumentModal__container"
      >
        <div className="col-xs-12 DocumentModal__fileName">
          {uploadedDoc.fileName}
        </div>
        <div className="col-xs-7">
          <DocumentFormFields
            fields={fields}
            index={index}
            documentItem={documentItem}
            onChangeField={onChangeField}
            entityIndex={entityIndex}
          />
        </div>
        <Anchor
          href={documentItem.path}
          className="col-xs-5 DocumentModal__objectContainer"
        >
          {uploadedDoc.fileType === 'application/pdf' ? (
            <object
              data={`${documentItem.path}#toolbar=0&scrollbar=0&messages=0&status=0&navpanes=0&page=1`}
              type="application/pdf"
              width="100%"
              height="100%"
              className="Asset__object"
            >
              <i className="mdi mdi-file Asset__document" />
            </object>
          ) : (
            <i className="mdi mdi-file Asset__object" />
          )}
        </Anchor>
      </div>
    );
  });
}

DocumentForm.propTypes = {
  documentList: PropTypes.array,
  uploadedDocs: PropTypes.array,
  fields: PropTypes.array,
  onChangeField: PropTypes.func,
  entityIndex: PropTypes.number,
};

export default DocumentForm;

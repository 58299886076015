import { string } from 'prop-types';

import i18n from 'utils/i18n';

import PreviewTemplate from '../preview';

import previewImage from './upgrade-feature-manufacturer-video.png';

const FeatureManufacturerVideoPreview = ({ imageSrc = previewImage }) => (
  <PreviewTemplate
    previewImage={imageSrc}
    paragraphs={[
      i18n.t(
        "Adding videos to your products allows you to showcase your products on our partner retailers' websites!",
      ),
      i18n.t(
        'Improve shopper engagement by upgrading to one of our enterprise packages !',
      ),
      i18n.t('Recipes, tutorials, tips, videos, ...'),
      i18n.t(
        'Store and share brand content with your retailers thanks to Alkemics extended features.',
      ),
    ]}
    withStrongText
  />
);

FeatureManufacturerVideoPreview.propTypes = {
  imageSrc: string,
};

export default FeatureManufacturerVideoPreview;

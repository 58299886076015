import classNames from 'classnames';
import { Map, fromJS } from 'immutable';
import { isEqual, pick } from 'lodash';
import PropTypes from 'prop-types';
import { PureComponent } from 'react';
import ImmutablePropTypes from 'react-immutable-proptypes';
import { connect } from 'react-redux';
import { createStructuredSelector } from 'reselect';

import { Checkbox } from '@alkem/react-ui-checkbox';

import {
  ENTITY_TYPE_PRODUCTVERSION,
  ENTITY_TYPE_PRODUCTVERSION_OVERRIDE,
  ENTITY_TYPE_PRODUCT_MEDIA,
  ENTITY_TYPE_PRODUCT_PICTURE,
  ENTITY_TYPE_SHARINGUNIT_FOR_TEMPLATE,
  ENTITY_TYPE_SHARINGUNIT_TARIFF,
  ENTITY_TYPE_SHARINGUNIT_TEMPLATE,
  MEDIA_ENTITIES_TO_VALIDATE,
} from 'constants/entities';
import { getOrganizationId, isLoggedAs, isRetailer } from 'core/api/user';
import { FEATURE_PRODUCTPICTURE_JSON_STORE_INPUT } from 'modules/feature-flag';
import {
  selectHasFeature,
  selectHasMultipleLocales,
} from 'modules/feature-flag/selectors';
import { dismissNormalizedComment } from 'modules/product-page/modules/normalized-comments/actions';
import { dismissSuggestion } from 'modules/product-page/modules/product-suggestions/actions';
import {
  validateSUForTemplate,
  validateSUTariff,
} from 'modules/sharing-unit-tariffs';
import { selectUser } from 'modules/user';
import {
  RESTRICTION_TYPE_BLOCKING,
  RuleApplicationStatus,
  applyRulesForActiveSharedRecipients,
  applyRulesForViewAsRecipients,
  isMigratedMediaFieldRule,
  ruleEntities,
  selectValidationResultsByEntity,
  toggleRuleBypassForProduct,
  updateMediaHasErrorByRuleId,
} from 'modules/validation';
import { selectCurrentLanguage } from 'reducers/productVersion';
import { sanitize } from 'utils/DOMPurify';
import { getAdminURL } from 'utils/admin';
import i18n from 'utils/i18n';
import { toJsIfImmutable } from 'utils/immutable';

import RaguelFailedGtins from './failed-gtins';
import { renderRuleSetLabels } from './renderers';
import RaguelSuggestion from './suggestion';
import {
  areRulesNotRequested,
  groupRuleSets,
  isBlocking,
  isBypassed,
  isKO,
  isSuggestion,
  isSuggestionDisplayedAsWarning,
  sortSuggestions,
  splitRules,
} from './utils';
import './validator.scss';

const entityTypeIsCompatible = ({ entityKind, entityId }, ruleResult) => {
  if (
    entityKind === ENTITY_TYPE_PRODUCTVERSION_OVERRIDE &&
    ruleResult.getIn(['entity', '_type']) === ENTITY_TYPE_PRODUCTVERSION
  ) {
    return ruleResult
      .get('rules')
      .some((rule) =>
        rule.hasIn(['restrictionTypesByOrganizations'], entityId),
      );
  }

  if (
    entityKind === ENTITY_TYPE_SHARINGUNIT_FOR_TEMPLATE &&
    ruleResult.getIn(['entity', '_type']) === 'SharingUnit'
  ) {
    return entityId === ruleResult.getIn(['entity', 'id']);
  }

  if (
    entityKind === ENTITY_TYPE_SHARINGUNIT_TEMPLATE &&
    ruleResult.getIn(['entity', '_type']) === ENTITY_TYPE_SHARINGUNIT_TARIFF
  ) {
    return entityId === ruleResult.getIn(['entity', 'id']);
  }

  if (
    [ENTITY_TYPE_PRODUCT_PICTURE, ENTITY_TYPE_PRODUCT_MEDIA].includes(
      entityKind,
    ) &&
    [ENTITY_TYPE_PRODUCTVERSION, ENTITY_TYPE_PRODUCT_MEDIA].includes(
      ruleResult.getIn(['entity', '_type']),
    )
  ) {
    return true;
  }

  return (
    entityKind === ruleResult.getIn(['entity', '_type']) &&
    entityId === ruleResult.getIn(['entity', 'id'])
  );
};

const mapStateToProps = createStructuredSelector({
  user: selectUser,
  ruleResults: selectValidationResultsByEntity,
  currentLanguage: selectCurrentLanguage,
  hasMultipleLocalesEnabled: selectHasMultipleLocales,
  hasMediaFieldsMigrationFlag: selectHasFeature(
    FEATURE_PRODUCTPICTURE_JSON_STORE_INPUT,
  ),
});

class Raguel extends PureComponent {
  static propTypes = {
    user: PropTypes.object.isRequired,
    dispatch: PropTypes.func.isRequired,
    ruleResults: ImmutablePropTypes.listOf(ImmutablePropTypes.map),
    entityId: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
    entityKind: PropTypes.string.isRequired,
    recipientId: PropTypes.number,
    label: PropTypes.oneOfType([PropTypes.string, PropTypes.node]).isRequired,
    model: PropTypes.string.isRequired,
    value: PropTypes.any,
    onJudgmentDay: PropTypes.func,
    readOnly: PropTypes.bool,
    displayActions: PropTypes.bool,
    onGtinClick: PropTypes.func,
    hasMultipleLocalesEnabled: PropTypes.bool,
    hasMediaFieldsMigrationFlag: PropTypes.bool,
    currentLanguage: PropTypes.shape({
      code: PropTypes.string.isRequired,
    }),
    matchPaths: PropTypes.func,
    entityIndex: PropTypes.number,
    hidden: PropTypes.bool,
    force: PropTypes.bool,
    forceUpdateErrors: PropTypes.bool,
  };

  static defaultProps = {
    value: undefined,
    onJudgmentDay: () => null,
    readOnly: false,
    displayActions: true,
    forceUpdateErrors: false,
  };

  state = {
    applicableRuleResults: Map(),
    lastValidatedValue: this.props.value,
  };

  componentDidMount() {
    this.updateApplicableRuleResults();
  }

  componentDidUpdate(prevProps, prevState) {
    const { applicableRuleResults } = this.state;
    if (
      this.props.model !== prevProps.model ||
      this.props.ruleResults !== prevProps.ruleResults ||
      this.props.entityId !== prevProps.entityId ||
      this.props.value !== prevProps.value
    ) {
      this.updateApplicableRuleResults();
    }
    if (
      prevState.applicableRuleResults !== applicableRuleResults ||
      this.props.forceUpdateErrors
    ) {
      if (this.props.label === 'assets') {
        const hasErrorByRuleId = [...applicableRuleResults.keys()].reduce(
          (acc, ruleId) => ({
            ...acc,
            [ruleId]: this.hasErrors(applicableRuleResults).error,
          }),
          {},
        );

        this.props.dispatch(updateMediaHasErrorByRuleId(hasErrorByRuleId));
      }

      // Update raguel status for field.
      this.onJudgmentDay({
        ...this.hasErrors(applicableRuleResults),
        pendingSuggestions: this.hasPendingWarningSuggestions(
          applicableRuleResults,
        ),
        hideField:
          !applicableRuleResults.isEmpty() &&
          applicableRuleResults
            .valueSeq()
            .every(
              (result) =>
                result.get('shouldHideFieldIfBypassed') &&
                result.get('bypassed'),
            ),
      });
    }
  }

  onJudgmentDay(options) {
    // Filter the options based on the user features.
    if (!options) {
      return;
    }
    this.props.onJudgmentDay(
      pick(options, 'hideField', 'error', 'pendingSuggestions', 'notRequested'),
    );
  }

  onToggleRuleBypass = (rules, unbypass, shouldHideField) => () => {
    const { entityKind, dispatch } = this.props;

    // Bypass the first rule that are not bypassed, it will automatically bypass similar rules.
    const firstToggleableRule = rules.find(
      (r) =>
        r.get('bypassable') &&
        ((!unbypass && r.get('status') !== RuleApplicationStatus.BYPASSED) ||
          (unbypass && r.get('status') === RuleApplicationStatus.BYPASSED)),
    );
    if (!firstToggleableRule) {
      return;
    }

    dispatch(
      toggleRuleBypassForProduct(firstToggleableRule.get('id'), unbypass),
    );
    dispatch(applyRulesForActiveSharedRecipients(entityKind));

    // Eagerly update state.
    const newStatus = unbypass
      ? RuleApplicationStatus.KO
      : RuleApplicationStatus.BYPASSED;
    // Add an empty message while waiting for the real message if unbypassing.
    const newErrorMessage = unbypass ? ' ' : null;
    this.setState((prevState) => {
      const applicableRuleResults =
        prevState.applicableRuleResults.withMutations((arr) => {
          let updated = arr;
          rules.forEach((r) => {
            updated = updated
              .setIn([r.get('id'), 'bypassed'], !unbypass)
              .setIn([r.get('id'), 'status'], newStatus)
              .setIn([r.get('id'), 'errorMessage'], newErrorMessage);
          });
          return updated;
        });
      this.onJudgmentDay({
        ...this.hasErrors(applicableRuleResults),
        hideField: !unbypass && shouldHideField,
      });
      return { applicableRuleResults };
    });
  };

  getApplicableRuleResults({
    model,
    ruleResults,
    entityKind,
    entityId,
    applicableRuleResults,
  }) {
    /*
    Check if the model is included in the matching paths in the result.

             (_(
            ('')
          _  "\ )>,_     .-=>
          _}--w/((_ >,_.'
                 ///
                 "`"

    Life is so much simpler now.
    */
    const { entityIndex, hasMediaFieldsMigrationFlag } = this.props;
    let rules = ruleResults
      // find all results relevant to current (entityKind, entityId)
      .filter((results) =>
        entityTypeIsCompatible({ entityKind, entityId }, results),
      )
      // merge all results into one big map of rules for this (entityKind, entityId)
      .reduce((all, results) => all.merge(results.get('rules')), Map());
    // keep only rules relevant to the current field
    rules = rules.filter((rule) => {
      const entityType = rule.get('entityType');
      let matchPaths;
      if (this.props.matchPaths) {
        matchPaths = this.props.matchPaths;
      } else {
        matchPaths = this.matchPaths;
        if (
          [
            ruleEntities.PRODUCT_PICTURE,
            ruleEntities.PRODUCT_DOCUMENT,
            ruleEntities.PRODUCT_VIDEO,
            ruleEntities.PRODUCT_PICTURES,
            ruleEntities.PRODUCT_DOCUMENTS,
            ruleEntities.PRODUCT_VIDEOS,
          ].includes(entityType) ||
          (hasMediaFieldsMigrationFlag &&
            isMigratedMediaFieldRule(toJsIfImmutable(rule)))
        ) {
          matchPaths = this.matchMediaPaths;
        }
      }
      return (
        (this.isKO(rule) || this.isBypassed(rule)) &&
        matchPaths(rule.get('paths'), model, { entityType, entityIndex })
      );
    });

    return (
      applicableRuleResults
        // First pop the rules that are no longer applicable.
        .filter((result) => rules.keySeq().includes(result.get('id')))
        // Then deep merge.
        .merge(rules)
    );
  }

  getRuleStatuses = (rule) => {
    if (
      this.props.entityKind === ENTITY_TYPE_PRODUCTVERSION_OVERRIDE &&
      rule.getIn([
        'status_by_recipients',
        'specific',
        String(this.props.entityId),
      ]) &&
      rule.getIn([
        'status_by_recipients',
        'specific',
        String(this.props.entityId),
      ]).size > 0
    ) {
      return rule.getIn([
        'status_by_recipients',
        'specific',
        String(this.props.entityId),
      ]);
    }
    if (
      rule.getIn(['status_by_recipients', 'generic']) &&
      rule.getIn(['status_by_recipients', 'generic']).size > 0
    ) {
      return rule.getIn(['status_by_recipients', 'generic']);
    }
    return fromJS({
      status: rule.get('status'),
      status_by_language: rule.get('status_by_language'),
    });
  };

  isKO = (rule) => {
    const { currentLanguage, hasMultipleLocalesEnabled } = this.props;
    const languageCode = currentLanguage ? currentLanguage.code : null;
    return isKO(
      this.getRuleStatuses(rule),
      languageCode,
      hasMultipleLocalesEnabled,
    );
  };

  isBypassed = (rule) => {
    const { currentLanguage, hasMultipleLocalesEnabled } = this.props;
    const languageCode = currentLanguage ? currentLanguage.code : null;
    return isBypassed(
      this.getRuleStatuses(rule),
      languageCode,
      hasMultipleLocalesEnabled,
    );
  };

  regexp = (str) => {
    const escaped = (str || '').replace(/\./g, '\\.');
    return new RegExp(escaped);
  };

  matchPaths = (paths, model) => paths.some((path) => path.includes(model));

  matchMediaPaths = (paths, model, { entityType, entityIndex } = {}) =>
    paths.some((path) => {
      const entityPath = {
        [ruleEntities.PRODUCT_PICTURE]: 'pictures',
        [ruleEntities.PRODUCT_DOCUMENT]: 'documents',
        [ruleEntities.PRODUCT_VIDEO]: 'videos',
      }[entityType];
      return (
        this.regexp(`assets.${entityPath}.${entityIndex}.${model}`).test(
          path,
        ) ||
        new RegExp(`assets.+\\.${model}`).test(path) ||
        path.includes(model)
      );
    });

  dismissSuggestion = (ruleResult, isNormalizedComment = false) => {
    // Dismiss suggestions, real time suggestions and normalized comments.
    const { dispatch } = this.props;
    const id = ruleResult.get('id');
    // Normalized comments' id are prefixed with nc
    const ruleId = isNormalizedComment ? `nc-${id}` : id;
    this.setState((prevState) => {
      const applicableRuleResults =
        prevState.applicableRuleResults.withMutations((arr) =>
          arr
            .setIn([ruleId, 'bypassed'], true)
            .setIn([ruleId, 'status'], RuleApplicationStatus.BYPASSED),
        );
      if (isNormalizedComment) {
        dispatch(dismissNormalizedComment({ id }));
      } else {
        dispatch(dismissSuggestion(ruleResult));
      }
      // Update raguel status for field.
      this.onJudgmentDay({
        pendingSuggestions: this.hasPendingWarningSuggestions(
          applicableRuleResults,
        ),
      });
      return { applicableRuleResults };
    });
  };

  canBypass = (rules) => rules.every((rule) => rule.get('bypassable'));

  areBypassed = (rules) => rules.every(this.isBypassed);

  isFieldValidated(rootField, fields) {
    // Note: if the root field is __full_data__, this means that we are forcing the validation.
    return rootField === '__full_data__' || fields.includes(rootField);
  }

  hasErrors(applicableRuleResults) {
    const errorRules = applicableRuleResults
      .valueSeq()
      .filter(
        (result) =>
          (this.isKO(result) || this.isBypassed(result)) &&
          result.get('errorMessage') &&
          (!isSuggestion(result) || isBlocking(result)),
      );
    return {
      error: !errorRules.isEmpty(),
      notRequested: areRulesNotRequested(errorRules),
    };
  }

  hasPendingWarningSuggestions(applicableRuleResults) {
    return applicableRuleResults
      .valueSeq()
      .some(
        (result) =>
          result.get('errorMessage') && isSuggestionDisplayedAsWarning(result),
      );
  }

  updateApplicableRuleResults() {
    const { model, ruleResults, entityKind, entityId, value, force } =
      this.props;
    const { applicableRuleResults, lastValidatedValue } = this.state;
    const rootField = model && model.split('.')[0];

    if (!rootField || !ruleResults || !ruleResults.size) {
      return;
    }

    let newApplicableRuleResults = Map();

    let refreshed = false;
    for (const ruleResult of ruleResults) {
      if (
        !ruleResult.size ||
        !entityTypeIsCompatible({ entityKind, entityId }, ruleResult)
      ) {
        continue; // eslint-disable-line no-continue
      }

      const fieldIsValidated =
        force ||
        MEDIA_ENTITIES_TO_VALIDATE.includes(entityKind) ||
        this.isFieldValidated(rootField, ruleResult.get('fields'));

      // Validate the new value.
      if (fieldIsValidated && !isEqual(value, lastValidatedValue)) {
        this.judge({ entityKind, entityId, value });
      }

      // Recompute the applicable rule results if needed.
      if (fieldIsValidated || applicableRuleResults.size) {
        refreshed = true;
        newApplicableRuleResults = this.getApplicableRuleResults({
          model,
          ruleResults,
          entityKind,
          entityId,
          applicableRuleResults,
        });
      }
    }

    // No rules match this entity, clear up the local results.
    if (!refreshed && applicableRuleResults.size) {
      this.getApplicableRuleResults({
        model,
        ruleResults,
        entityKind,
        entityId,
        applicableRuleResults,
      });
    }

    this.setState({
      applicableRuleResults: newApplicableRuleResults,
    });
  }

  judge({ entityKind, entityId, value }) {
    const { dispatch } = this.props;
    this.setState({ lastValidatedValue: value });
    if (entityKind === ENTITY_TYPE_SHARINGUNIT_TARIFF) {
      dispatch(validateSUTariff());
    } else if (entityKind === ENTITY_TYPE_SHARINGUNIT_FOR_TEMPLATE) {
      dispatch(validateSUForTemplate());
    } else {
      dispatch(applyRulesForViewAsRecipients(entityKind, entityId));
    }
  }

  renderRuleSetLabels(rules, bypassed = false) {
    const { user, recipientId } = this.props;

    const scopeToRecipientId = isRetailer(user)
      ? getOrganizationId(user)
      : recipientId;

    const ruleSets = groupRuleSets(rules);

    if (ruleSets.isEmpty()) {
      return null;
    }

    return renderRuleSetLabels(
      rules.first(),
      ruleSets,
      bypassed,
      scopeToRecipientId,
    );
  }

  renderRuleId = (rule) => {
    const { user } = this.props;
    const firstRuleId = rule.get('id');
    const firstRuleRuleSetId = rule.getIn(['ruleSet', 'id']);

    if (!isLoggedAs(user)) {
      return null;
    }

    return (
      <div>
        {firstRuleId &&
          i18n.t('frontproductstream.plugins.validator.admin_rule_id.text', {
            defaultValue: 'Rule ID {{firstRuleId}}',
            firstRuleId,
          })}
        {firstRuleId && firstRuleRuleSetId && ', '}
        {firstRuleRuleSetId &&
          i18n.t('frontproductstream.plugins.validator.admin_ruleset_id.text', {
            defaultValue: 'Ruleset ID {{firstRuleRuleSetId}}',
            firstRuleRuleSetId,
          })}
        &nbsp;
        <a
          target="_blank"
          rel="noopener noreferrer"
          href={`${getAdminURL()}/validation/rule?q=${firstRuleId}`}
        >
          (See rule)
        </a>
        &nbsp;
        {i18n.t('frontproductstream.common.admin_feature.label', {
          defaultValue: '(Admin)',
        })}
      </div>
    );
  };

  renderMessage(rules) {
    const { recipientId } = this.props;
    const blockingRules = recipientId
      ? rules.filter(
          (rule) =>
            rule.getIn([
              'restrictionTypesByOrganizations',
              `${recipientId}`,
            ]) === RESTRICTION_TYPE_BLOCKING,
        )
      : rules.filter(isBlocking);
    const notRequested = areRulesNotRequested(rules);

    /*
    Scenario rules are checked even if the scenario is not requested for the product.
    Priority is given to requested rulesets. If no ruleset is requested for this product, then non-requested rules
    can be displayed.
     */
    const selectRuleByPriority = (rule) =>
      notRequested
        ? rule.get('errorMessage')
        : rule.get('errorMessage') &&
          rule.getIn(['ruleSet', 'requested']) !== false;

    const ruleForErrorMessage =
      blockingRules.find(selectRuleByPriority) ||
      rules.find(selectRuleByPriority) ||
      Map();

    let errorMessage = ruleForErrorMessage.get('errorMessage', '');
    if (!errorMessage) {
      return null;
    }

    if (recipientId) {
      errorMessage = ruleForErrorMessage.getIn(
        ['errorMessageByOrganizations', `${recipientId}`],
        errorMessage,
      );
    }

    const blocking = blockingRules.filter(selectRuleByPriority).size > 0;
    const iconClasses = {
      mdi: true,
      'mdi-cancel': blocking,
      'mdi-alert': !blocking,
    };

    const mandatoryDisplayedRules = rules.filter(
      (rule) => rule.getIn(['ruleSet', 'requested'], true) === true,
    );

    const notMandatoryDisplayedRules = rules.filter(
      (rule) => rule.getIn(['ruleSet', 'requested']) === false,
    );

    let displayedRules;
    if (mandatoryDisplayedRules.size > 0) {
      displayedRules = mandatoryDisplayedRules;
    } else {
      displayedRules = notMandatoryDisplayedRules;
    }

    const failedGtinCounts = rules.reduce(
      (acc, r) =>
        acc.mergeDeepWith(
          (o, n) => o + n,
          r.getIn(['paths', 'failed_gtin_counts']) || Map(),
        ),
      Map(),
    );

    return (
      <div className={classNames(notRequested && 'Raguel__notRequestedErrors')}>
        <span className="Raguel__default">
          <i className={classNames(iconClasses)} />
          <span
            className="Raguel__default__message"
            dangerouslySetInnerHTML={{
              __html: sanitize(errorMessage, {
                ADD_ATTR: ['target'],
              }),
            }}
          />
          {this.renderRuleSetLabels(displayedRules)}
        </span>
        {this.renderRuleId(rules.first())}
        {!!failedGtinCounts && failedGtinCounts.size > 0 && (
          <RaguelFailedGtins
            gtinErrors={failedGtinCounts}
            onGtinClick={this.props.onGtinClick}
          />
        )}
      </div>
    );
  }

  renderBypassable(rules) {
    const { label, readOnly, user } = this.props;
    const isReadOnly = readOnly || isRetailer(user);

    const bypassed = this.areBypassed(rules);
    if (!this.canBypass(rules) || (!bypassed && isReadOnly)) {
      return null;
    }

    const rule = rules.first();
    const id = rule.get('id');
    const shouldHideField = rules.every((r) =>
      r.get('shouldHideFieldIfBypassed'),
    );
    const errorMessage =
      (
        rules.find((r) => r.get('errorMessage')) || Map({ errorMessage: '' })
      ).get('errorMessage').length > 0;
    const bypassMessage =
      bypassed && isReadOnly
        ? i18n.t(
            'frontproductstream.plugins.validator.bypass_checkbox.bypassed_label',
            { defaultValue: '{{label}} has been marked as non needed.', label },
          )
        : i18n.t('frontproductstream.plugins.validator.bypass_checkbox.label', {
            defaultValue: 'I declare that my product does not need {{label}}.',
            label,
          });

    return (
      <span
        id={`raguel-error-message-rule-button-${id}`}
        className="Raguel__bypass"
      >
        {!errorMessage && this.renderRuleSetLabels(rules, bypassed)}
        {isReadOnly ? (
          <span className="Raguel__readonly">{bypassMessage}</span>
        ) : (
          <Checkbox
            id={`raguel-error-message-rule-checkbox-${id}`}
            checked={bypassed}
            onChange={this.onToggleRuleBypass(rules, bypassed, shouldHideField)}
            label={bypassMessage}
          />
        )}
      </span>
    );
  }

  renderRules = (rules) => {
    const key = rules.getIn([0, 'id']);

    const bypassed = this.canBypass(rules) && this.areBypassed(rules);
    const classes = {
      Raguel__message: true,
      'Raguel__message--disabled': bypassed,
    };

    return (
      <div
        className={classNames(classes)}
        key={key}
        id={`raguel-error-message-rule-${key}`}
        data-rule-id={key}
      >
        <div className="Raguel__message__line">{this.renderMessage(rules)}</div>
        <div className="Raguel__message__line">
          {this.renderBypassable(rules)}
        </div>
      </div>
    );
  };

  renderSuggestion = (suggestion, index) => {
    const { user, entityId, readOnly, displayActions, label } = this.props;

    const classes = {
      // so that the validation stepper does not take into account help suggestions
      Raguel__message: isSuggestionDisplayedAsWarning(suggestion),
      'Raguel__message--disabled': suggestion.get('bypassed'),
    };

    const key = suggestion.get('id');

    return (
      <div
        className={classNames(classes)}
        key={key}
        id={`raguel-error-message-rule-${key}-suggestion`}
      >
        <RaguelSuggestion
          ruleResult={suggestion}
          entityId={entityId}
          dismiss={this.dismissSuggestion}
          readOnly={readOnly}
          displayActions={displayActions}
          label={label}
          isRetailer={isRetailer(user)}
          index={index}
        />
      </div>
    );
  };

  render() {
    const { currentLanguage, hasMultipleLocalesEnabled, hidden } = this.props;
    const { applicableRuleResults } = this.state;
    const languageCode = currentLanguage ? currentLanguage.code : null;
    if (!applicableRuleResults.size || hidden) {
      return null;
    }
    const { rules, suggestions } = splitRules(
      applicableRuleResults,
      languageCode,
      hasMultipleLocalesEnabled,
    );
    return (
      <div className="Raguel">
        <div>{rules.map(this.renderRules).toArray()}</div>
        <div>
          {suggestions
            .valueSeq()
            .sort(sortSuggestions)
            .map(this.renderSuggestion)}
        </div>
      </div>
    );
  }
}

export { Raguel };

/**
 * @type {any}
 */
export default connect(mapStateToProps)(Raguel);

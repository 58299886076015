import { get } from 'lodash';
import { all, call, select } from 'redux-saga/effects';

import { fetchAssignations, fetchProductVersions } from 'resources/searchApi';

import { ASSIGNATION } from '../constants/context';
import { selectCatalogContext, selectIsFirstTime } from '../selectors';

export default function* fetchIsFirstTimeForUser() {
  const isFirstTime = yield select(selectIsFirstTime);
  if (isFirstTime != null) {
    return isFirstTime;
  }
  const context = yield select(selectCatalogContext);
  const queries = { limit: 0, withSourceInclude: false };
  const requests = [call(fetchProductVersions, queries)];
  if (context === ASSIGNATION) {
    requests.push(call(fetchAssignations, queries));
  }
  const responses = yield all(requests);
  return responses.every((response) => get(response, 'total') === 0);
}

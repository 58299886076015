import lodash from 'lodash/fp';

import { isImmutable } from 'utils/immutable';
import { logTrace } from 'utils/logging';

/**
 * Takes a list and a prop and returns a map with value on keyPath of each item
 * as keys and the items itself as values.
 */
export function keyBy(list, pred) {
  if (lodash.isUndefined(list)) {
    logTrace('keyBy - list is undefined');
  }
  if (isImmutable(list)) {
    const p = lodash.isFunction(pred) ? pred : (o) => o.get(pred);
    return list.toMap().mapKeys((_, value) => p(value));
  } else {
    return lodash.keyBy(pred, list);
  }
}

import {
  hasDesiredProducts,
  hasInformationRequest,
  hasSupplierDirectory,
} from 'core/api/organization-settings';
import {
  importsGenericInformationRequestReport,
  importsGenericSuppliersReport,
} from 'routes';
import i18n from 'utils/i18n';

export const REDUCER_KEY = 'imports-generic';

// Pagination
export const PAGINATION_LOCALSTORAGE_KEY = `${REDUCER_KEY}/PAGINATION_GENERIC_IMPORTS`;

// Type import
export const INFORMATION_REQUEST_IMPORT = 'Request-engine-import';
export const SUPPLIER_DIRECTORY_IMPORT = 'Supplier-directory-import';

// Type report
export const SUPPLIER_DIRECTORY_REPORT = 'Supplier';
export const INFORMATION_REQUEST_REPORT = 'Information Request';
export const ERROR_STATUS_TYPES = ['ERROR', 'TIMEOUT'];

export const EMPTY_STATUS = 'EMPTY';

// Name modal
export const CHOICE_MODAL = 'generic-imports-choice-modal';
export const UPLOAD_MODAL = 'generic-imports-upload-modal';

// Import available
const displayInfoReqImportTab = (settings) =>
  (hasSupplierDirectory(settings) && hasInformationRequest(settings)) ||
  hasDesiredProducts(settings);

export const allImportsAvailable = [
  {
    typeImport: INFORMATION_REQUEST_IMPORT,
    isAvailable: [displayInfoReqImportTab],
    getModalLabel: (settings) => {
      if (hasInformationRequest(settings)) {
        return i18n.t(
          'frontproductstream.imports_generic.available_imports.information_request_label',
          { defaultValue: 'Request engine' },
        );
      }
      return i18n.t(
        'frontproductstream.imports_generic.available_imports.desired_products_label',
        { defaultValue: 'Desired products' },
      );
    },
  },
  {
    typeImport: SUPPLIER_DIRECTORY_IMPORT,
    isAvailable: [hasSupplierDirectory],
    getModalLabel: () =>
      i18n.t(
        'frontproductstream.imports_generic.available_imports.supplier_directory_label',
        { defaultValue: 'Supplier directory' },
      ),
  },
];

// Report available
export const allReportsAvailable = [
  {
    typeReport: INFORMATION_REQUEST_REPORT,
    isAvailable: [displayInfoReqImportTab],
    getLabel: (settings) => {
      if (hasInformationRequest(settings)) {
        return i18n.t(
          'frontproductstream.imports_generic.available_reports.information_request_label',
          { defaultValue: 'Requests' },
        );
      }
      return i18n.t(
        'frontproductstream.imports_generic.available_reports.desired_products_label',
        { defaultValue: 'Desired products' },
      );
    },
    pathname: importsGenericInformationRequestReport,
  },
  {
    typeReport: SUPPLIER_DIRECTORY_REPORT,
    isAvailable: [hasSupplierDirectory],
    getLabel: () =>
      i18n.t(
        'frontproductstream.imports_generic.available_reports.supplier_directory_label',
        { defaultValue: 'Suppliers' },
      ),
    pathname: importsGenericSuppliersReport,
  },
];

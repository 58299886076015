import { isEqual } from 'lodash';
import { UseFormProps, useForm } from 'react-hook-form';
import { useDispatch, useSelector } from 'react-redux';

import { ResponseWithData } from '@alkem/sdk-dashboard';

import { listAllAssets } from 'actions/media';
import { notificationError, notificationSuccess } from 'actions/notification';
import { JSON_TO_LEGACY_FIELDS_MAP } from 'constants/productPicture';
import { selectHasJsonStoreInputFeature } from 'modules/feature-flag/selectors';
import { mediaApi } from 'resources';
import { DispatchType } from 'types';
import i18n from 'utils/i18n';

import { EnhancedAssetV2, PictureAssetV2 } from '../types';

type FormData = EnhancedAssetV2<PictureAssetV2> & { recipientIds: number[] };

type usePictureFormParams = UseFormProps<FormData> & {
  defaultValues: FormData;
};

export const usePictureForm = (fieldValues: usePictureFormParams) => {
  const formMethods = useForm<FormData>(fieldValues);

  const hasJsonStoreInputFeature = useSelector(selectHasJsonStoreInputFeature);
  const dispatch: DispatchType = useDispatch();

  const onSubmit = formMethods.handleSubmit(async (data) => {
    const postPictureUpdate = mediaApi.ProductPictureUpdate.bind(mediaApi);
    const updateShareStatus = mediaApi.UpsertSpecificPictureData.bind(mediaApi);

    const { recipientIds, ...cleanData } = data;

    const dataWithOrWithoutLegacyFields = Object.keys(cleanData).reduce(
      (acc, key) => {
        const keysToDelete = (
          hasJsonStoreInputFeature ? Object.values : Object.keys
        )(JSON_TO_LEGACY_FIELDS_MAP);

        if (keysToDelete.includes(key)) {
          return acc;
        }

        return {
          ...acc,
          [key]: cleanData[key],
        };
      },
      {},
    );

    const { recipientIds: defaultRecipientIds, ...initialDefaultValues } =
      fieldValues.defaultValues;

    if (!isEqual(cleanData, initialDefaultValues)) {
      try {
        const postPictureResponse: ResponseWithData<PictureAssetV2> =
          await postPictureUpdate(dataWithOrWithoutLegacyFields);

        formMethods.reset(postPictureResponse.data.data);

        await dispatch(
          listAllAssets({
            product_key_id: cleanData.product_key_id,
          }),
        );

        dispatch(
          notificationSuccess(
            i18n.t(
              'frontproducstream.asset_list.picture_modal.save_notification.edited_success',
              { defaultValue: 'Picture successfully edited.' },
            ),
            {
              context: 'modal',
            },
          ),
        );
      } catch (e) {
        dispatch(
          notificationError(
            i18n.t(
              'frontproducstream.asset_list.picture_modal.save_notification.error',
              { defaultValue: 'An error occured while creating the picture.' },
            ),
            {
              context: 'modal',
            },
          ),
        );
      }
    }

    if (!isEqual(recipientIds, defaultRecipientIds)) {
      const deletedRecipients = defaultRecipientIds.filter(
        (recipientId) => !recipientIds.includes(recipientId),
      );
      const addedRecipients = recipientIds.filter(
        (recipientId) => !defaultRecipientIds.includes(recipientId),
      );

      try {
        await Promise.all([
          ...deletedRecipients.map((recipientId) =>
            updateShareStatus(data.id, recipientId, {
              status: 0,
            }),
          ),
          ...addedRecipients.map((recipientId) =>
            updateShareStatus(data.id, recipientId, {
              status: 1,
            }),
          ),
        ]);

        formMethods.reset();

        dispatch(
          notificationSuccess(
            i18n.t(
              'frontproducstream.asset_list.picture_modal.save_recipients_notification.edited_success',
              { defaultValue: 'The picture recipients have been updated' },
            ),
            {
              context: 'modal',
            },
          ),
        );
      } catch (e) {
        dispatch(
          notificationError(
            i18n.t(
              'frontproducstream.asset_list.picture_modal.save_recipients_notification.error',
              {
                defaultValue:
                  'An error occured while updating the picture recipients.',
              },
            ),
            {
              context: 'modal',
            },
          ),
        );
      }
    }
  });

  return {
    formMethods,
    onSubmit,
  };
};

import { Spinner } from '@alkem/react-ui-spinner';

import i18n from 'utils/i18n';

import './product-page-spinner.scss';

const ProductPageSpinner = () => (
  <div className="ProductPage__loading">
    <Spinner medium />
    <div className="ProductPage__loadingText">
      {i18n.t('Loading product ...')}
    </div>
  </div>
);

export default ProductPageSpinner;

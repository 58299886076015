import { get } from 'utils/immutable';

import { allRequestAnswers } from './constants';
import { AssignationEvent } from './types';

// Request response (cell Assignation Date and cell bulk action)

export const getAnswerData = (data: AssignationEvent): [string, string] => {
  const statusReasons = get(data, 'status_reasons');
  const answerReason = get(statusReasons, 'reason');
  const answer = allRequestAnswers[answerReason];
  const answerMessage = answer.getMessage(statusReasons);
  return [answerMessage, answerReason];
};

import PropTypes from 'prop-types';

import i18n from 'utils/i18n';

import { FEATURE_MANUFACTURER_RECIPE } from '../../constants';
import FeatureModal from '../modal';

import Preview from './preview';

const FeatureManufacturerRecipeModal = ({ onClose }) => (
  <FeatureModal
    onClose={onClose}
    title={i18n.t('Engage shoppers by adding recipes and how-to tutorials')}
    flag={FEATURE_MANUFACTURER_RECIPE}
  >
    <Preview />
  </FeatureModal>
);

FeatureManufacturerRecipeModal.propTypes = {
  onClose: PropTypes.func.isRequired,
};

export default FeatureManufacturerRecipeModal;

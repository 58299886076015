import { registerModuleReducer } from 'utils/modules/reducer';
import { registerSaga } from 'utils/modules/saga';

import TargetCatalogImportModalButton from './TargetCatalogImportModalButton';
import { TARGET_CATALOG_IMPORT_MODAL_STORE_KEY } from './constants';
import { ImportActiveRangeModal } from './import-modal/ImportActiveRangeModal';
import { ImportProductDataModal } from './import-modal/ImportProductDataModal';
import reducers from './reducers';
import mainSaga from './sagas';

registerModuleReducer(TARGET_CATALOG_IMPORT_MODAL_STORE_KEY, reducers);
registerSaga(mainSaga);

export {
  TargetCatalogImportModalButton,
  ImportActiveRangeModal,
  ImportProductDataModal,
};

export const FETCH_SUMMARY = 'publication-summary/FETCH_SUMMARY';
export const FETCH_SUMMARY_SUCCESS =
  'publication-summary/FETCH_SUMMARY_SUCCESS';
export const FETCH_SUMMARY_FAILURE =
  'publication-summary/FETCH_SUMMARY_FAILURE';
export const RECEIVE_SUMMARY = 'publication-summary/RECEIVE_SUMMARY';
export const VALIDATE_PRODUCT = 'publication-summary/VALIDATE_PRODUCT';
export const RECEIVE_VALIDATION_DATA =
  'publication-summary/RECEIVE_VALIDATION_DATA';
export const DEACTIVATE_GROUPS = 'publication-summary/DEACTIVATE_GROUPS';
export const ACTIVATE_GROUPS_SUCCESS =
  'publication-summary/ACTIVATE_GROUPS_SUCCESS';

export const DEACTIVATE_GROUPS_SUCCESS =
  'publication-summary/DEACTIVATE_GROUPS_SUCCESS';
export const DEACTIVATE_GROUPS_FAILURE =
  'publication-summary/DEACTIVATE_GROUPS_FAILURE';

export const ADD_RECIPIENTS = 'publication-summary/ADD_RECIPIENTS';
export const ADD_RECIPIENTS_SUCCESS =
  'publication-summary/ADD_RECIPIENTS_SUCCESS';
export const ADD_RECIPIENTS_FAILURE =
  'publication-summary/ADD_RECIPIENTS_FAILURE';

export const PREPARE_FOR_GROUP = 'publication-summary/PREPARE_FOR_GROUP';
export const PREPARE_FOR_GROUP_SUCCESS =
  'publication-summary/PREPARE_FOR_GROUP_SUCCESS';
export const PREPARE_FOR_GROUP_FAILURE =
  'publication-summary/PREPARE_FOR_GROUP_FAILURE';

export const OPEN_UNSHARE_PRODUCT = 'publication-summary/open-unshare';
export const UNSHARE_PRODUCT = 'publication-summary/unshare';
export const UNSHARE_PRODUCT_DONE = 'publication-summary/unshare-done';

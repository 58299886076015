import { List } from 'immutable';

import { createAction } from 'utils/redux';

import {
  CLEAR_PROMPTED_MATURITY_RULE_SETS_WITH_DEADLINES,
  FETCH_PRODUCT_DATA_BY_KEY_ID,
  FETCH_PRODUCT_DATA_BY_UUID,
  FETCH_REJECTED_FIELDS,
  PROMPT_MATURITY_RULE_SETS_WITH_DEADLINES,
  PUBLISH,
  RECEIVE_REJECTED_FIELDS,
  REQUIRE_MATURITY_RULES,
  REQUIRE_MATURITY_RULES_DONE,
  REQUIRE_MATURITY_RULES_WITH_DEADLINES,
  REQUIRE_MATURITY_RULES_WITH_DEADLINES_DONE,
  SAVE_PRODUCT,
  SAVE_RETAILER_PRODUCT_VERSION,
  SAVE_VISIBILITY,
  SET_EXPORTABLE,
  SET_LOADING,
  SET_VIEW_AS,
} from './constants';
import { PromptedMaturityRuleSet, RuleSetRequirement } from './types';

// FETCHING

export const setLoading = (isLoading) => ({
  type: SET_LOADING,
  payload: { isLoading },
});

export const fetchProductDataByKeyId = (productKeyId, withDeleteOld?) => ({
  type: FETCH_PRODUCT_DATA_BY_KEY_ID,
  payload: { productKeyId, withDeleteOld },
});

export const fetchProductDataByUuid = (uuid, withDeleteOld) => ({
  type: FETCH_PRODUCT_DATA_BY_UUID,
  payload: { uuid, withDeleteOld },
});

export const setViewAs = (organizationIds, ruleSetIds) => ({
  type: SET_VIEW_AS,
  payload: { organizationIds, ruleSetIds },
});

// SAVING

export const saveProduct = (_publish, redirectToRoute = null) => ({
  type: SAVE_PRODUCT,
  publish: _publish,
  redirectToRoute,
});

// RETAILER SIDE

export const saveRetailerProductVersion = (_setExportable) => ({
  type: SAVE_RETAILER_PRODUCT_VERSION,
  payload: { setExportable: _setExportable },
});

export const setExportable = () => ({
  type: SET_EXPORTABLE,
});

// PUBLISH

export const publish = () => ({
  type: PUBLISH,
});

export const saveVisibility = (exclusive, allCatalog) => ({
  type: SAVE_VISIBILITY,
  exclusive,
  allCatalog,
});

export const requireMaturityRules = createAction(REQUIRE_MATURITY_RULES);
export const requireMaturityRulesDone = createAction(
  REQUIRE_MATURITY_RULES_DONE,
);

export const requireMaturityRuleSetsWithDeadlines = createAction<
  Array<RuleSetRequirement>
>(REQUIRE_MATURITY_RULES_WITH_DEADLINES);
export const requireMaturityRuleSetsWithDeadlinesDone = createAction(
  REQUIRE_MATURITY_RULES_WITH_DEADLINES_DONE,
);

export const fetchRejectedFields = createAction(FETCH_REJECTED_FIELDS);
export const receiveRejectedFields = createAction<{
  [fieldModel: string]: any[];
}>(RECEIVE_REJECTED_FIELDS);

export const promptMaturityRuleSetsWithDeadlines = createAction<
  List<PromptedMaturityRuleSet>
>(PROMPT_MATURITY_RULE_SETS_WITH_DEADLINES);
export const clearPromptedMaturityRuleSetsWithDeadlines = createAction(
  CLEAR_PROMPTED_MATURITY_RULE_SETS_WITH_DEADLINES,
);

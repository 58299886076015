import classNames from 'classnames';
import { useCallback, useEffect } from 'react';
import { useSelector } from 'react-redux';

import { Anchored } from 'modules/anchored';
import PhysicalCheckDiff from 'modules/contribution/components/diff';
import { DataOpsDiffBlocks } from 'modules/data-ops';
import { DataOpsSummary } from 'modules/data-ops/components/data-ops-summary';
import { DeprecatedDataOpsPatches } from 'modules/data-ops/components/deprecated-data-ops';
import { UnsynchedWarning } from 'modules/export-synchronization-status';
import LogisticalHierarchies from 'modules/logistical-hierarchies';
import ProductInformation from 'modules/product-page/components/product-information';
import DeprecatedNormalizedComments from 'modules/product-page/modules/normalized-comments';
import { ProductShareDiff } from 'modules/product-page/modules/product-share-diff';
import ProductSuggestions from 'modules/product-page/modules/product-suggestions';
import RefuseModal from 'modules/product-page/modules/refuse-modal';
import { selectIsPhysicalChecker, selectIsRetailer } from 'modules/user';
import { selectIsRightToLeft } from 'reducers/productVersion';
import { track } from 'utils/tracking';

import LeavingConfirmation from '../leaving-confirmation';
import ProductFooter from '../product-footer';
import RetailerSpecificFields from '../retailer-specific-fields';
import SharingUnits from '../sharing-units';

import './index.scss';

// TODO CCS-244 handle redirct to new route /edit: one day...
export const ProductPage = () => {
  const isRightToLeft = useSelector(selectIsRightToLeft);
  const isPhysicalChecker = useSelector(selectIsPhysicalChecker);
  const isRetailer = useSelector(selectIsRetailer);

  useEffect(() => {
    if (isRightToLeft) {
      document.body.classList.add('Layout--rtl');
    } else {
      document.body.classList.remove('Layout--rtl');
    }
    return () => document.body.classList.remove('Layout--rtl');
  }, [isRightToLeft]);

  const trackNavigation = useCallback((item: any) => {
    track({
      category: 'product-page-tracking',
      action: 'sidebar navigation',
      label: item,
    });
  }, []);

  return (
    <div
      className={classNames('ProductPage__content', {
        PhysicalCheckProductPage: isPhysicalChecker,
        RetailerProductPage: isRetailer,
        'ProductPage__content--rtl': isRightToLeft,
      })}
    >
      <Anchored withViewAs onItemClick={trackNavigation}>
        <UnsynchedWarning />
        <ProductShareDiff order={100} />
        <ProductSuggestions order={200} />
        <PhysicalCheckDiff order={300} />
        <DataOpsDiffBlocks order={400} />
        <DeprecatedDataOpsPatches order={450} />
        <DataOpsSummary order={500} />
        <DeprecatedNormalizedComments order={550} />
        <ProductInformation order={600} />
        {/* order = 1000 */}
        <LogisticalHierarchies />
        {/* order = 2000, 3000, 4000 */}
        <SharingUnits />
        <RetailerSpecificFields order={5000} collapsed />
      </Anchored>
      <ProductFooter />
      <LeavingConfirmation />
      <RefuseModal />
    </div>
  );
};

export default ProductPage;

import PropTypes from 'prop-types';
import { connect } from 'react-redux';

import CoreLayout from 'layouts/CoreLayout';
import BodyLayout from 'layouts/body';
import i18n from 'utils/i18n';

import './index.scss';

export function ForbiddenModule({ children }) {
  return (
    <CoreLayout containerClass="ForbiddenModule" absolute>
      <BodyLayout className="ForbiddenModule__body">
        {children || (
          <h3>
            {i18n.t('frontproductstream.core.forbidden_module.message', {
              defaultValue: 'Sorry, you do not have access to this page.',
            })}
          </h3>
        )}
      </BodyLayout>
    </CoreLayout>
  );
}

ForbiddenModule.propTypes = {
  children: PropTypes.node,
};

export const withRestrictedFeature = ({
  View,
  selectors = [],
  ForbiddenView,
}: {
  View: any;
  selectors?: any[];
  ForbiddenView?: any;
}) => {
  function RestrictedModuleHOC({ hasModule, ...props }) {
    if (hasModule) {
      return <View {...props} />;
    }
    return (
      <ForbiddenModule>
        {ForbiddenView && <ForbiddenView {...props} />}
      </ForbiddenModule>
    );
  }
  RestrictedModuleHOC.propTypes = {
    hasModule: PropTypes.bool,
  };
  return connect((state) => ({
    hasModule: selectors.every((selector) => selector(state)),
  }))(RestrictedModuleHOC);
};

export default ForbiddenModule;

// These actions are filtered by default. If you want to see them in the logger
// or devtools comment out or remove them.

// eslint-disable-next-line
const actionsBlacklistProd = [
  'chat/FETCH_COUNT_UNACKED',
  'GET_NOTIFICATION_COUNTER',
  'RECEIVE_NOTIFICATION_COUNTER',
];

// eslint-disable-next-line
const actionsBlacklistDev = [
  'anchored/SET_BLOCK_POSITION',
  'DEFINE_ANCHOR_NAVIGATION_SECTION',
  'FORM_FIELD_UPDATED',
  'REGISTER_BLOCKNAME',
  'REGISTER_SECTION',
  'RESET_LOADING',
  'SCROLLED_TO_ANCHOR_NAVIGATION_SECTION',
  'SET_ANCHOR_NAVIGATION_SECTION',
  'START_LOADING',
  'STOP_LOADING',
  'UNREGISTER_BLOCKNAME',
  'UNREGISTER_SECTION',
  'UPDATE_ENTITY',
  'UPDATE_SCROLL_POSITION',
];

export default actionsBlacklistProd;

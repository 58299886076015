import PropTypes from 'prop-types';

import Autocomplete from 'components/ui/autocomplete';
import authApi from 'resources/authApi';

export default class UsersAutocomplete extends Autocomplete {
  static propTypes = {
    filterStatus: PropTypes.number,
  };

  static defaultProps = Object.assign({}, Autocomplete.defaultProps, {
    filterStatus: null,
  });

  getList(username) {
    if (!!username && username.length < 3) {
      return null;
    }
    const { filterStatus } = this.props;
    const filters = {
      withPermissions: false,
      platform: 0,
      status: filterStatus,
    };

    return authApi.UserList({ username }, filters, 0, 20).then((response) => {
      this.updateList(
        response.data.data.map((user) => ({
          label: user.username,
          id: user.id,
        })),
      );
    });
  }
}

import { createAction } from 'utils/redux';

import {
  BULK_ACTIVATE_RECIPIENTS,
  CANCEL_EXPORT_DESIRED_PRODUCTS,
  CANCEL_EXPORT_PRODUCTS,
  CANCEL_FETCH_COLUMN_REFERENTIALS,
  CANCEL_FETCH_LIST,
  CANCEL_UPSERT_LIST_COLUMNS,
  CLOSE_EXPORT_DESIRED_PRODUCTS_MODAL,
  CLOSE_EXPORT_MODAL,
  COLLAPSE_FILTERS,
  DISPATCH_PRODUCTS,
  DOWNLOAD_COMPLIANCE_REPORT,
  EXPORT_DESIRED_PRODUCTS,
  EXPORT_DESIRED_PRODUCTS_DONE,
  EXPORT_PRODUCTS,
  EXPORT_PRODUCTS_DONE,
  FETCH_COLUMN_REFERENTIALS,
  FETCH_EXPORT_FILE_FORMATS,
  FETCH_LIST,
  FETCH_MATURITY_RULESETS,
  INIT_CATALOG,
  OPEN_EXPORT_DESIRED_PRODUCTS_MODAL,
  OPEN_EXPORT_MODAL,
  PRODUCT_SELECTION,
  RECEIVE_COLUMN_REFERENTIALS,
  RECEIVE_EXPORT_FILE_FORMATS,
  RECEIVE_LIST,
  RECEIVE_MATURITY_RULESETS,
  REPLACE_FILTERS,
  RESET_CATALOG,
  SEARCH,
  SELECT_COLUMN,
  SET_CATALOG_CONTEXT,
  SET_CATALOG_UUID,
  SET_FILTERS_FROM_REPORTING,
  SET_HIGHLIGHTED_ROW_IDENTIFIER,
  SORT_FILTER_BY_DOC_COUNT,
  SORT_LIST,
  TRACK_CATALOG_ACTION,
  TRACK_SEARCH,
  UPDATE_ASSIGNATION_STATUS,
  UPDATE_COLUMN_SELECTION,
  UPDATE_EXPORT_FORMAT,
  UPDATE_FILTERED_FILTERS,
  UPDATE_FILTERS_PAGINATION,
  UPDATE_ITEMS_PER_PAGE,
  UPDATE_ONLY_DUPLICATED_PRODUCTS,
  UPDATE_PRODUCTS_TO_EXPORT,
  UPDATE_SEARCH_FROM_QUERY,
  UPDATE_SELECTED_FILTERS,
  UPDATE_SELECTED_FILTERS_FROM_QUERY,
  UPDATE_WITH_ALL_TYPES,
  UPDATE_WITH_ARCHIVED_PRODUCTS,
  UPDATE_WITH_REFUSED_ASSIGNATIONS,
  UPSERT_LIST_COLUMNS,
} from './types';

export const fetchList = createAction(FETCH_LIST);
export const cancelFetchList = createAction(CANCEL_FETCH_LIST);
export const receiveList = createAction(RECEIVE_LIST);
export const resetCatalog = createAction(RESET_CATALOG);
export const fetchColumnReferentials = createAction(FETCH_COLUMN_REFERENTIALS);
export const resetFetchColumnReferentials = createAction(
  CANCEL_FETCH_COLUMN_REFERENTIALS,
);
export const receiveColumnReferentials = createAction(
  RECEIVE_COLUMN_REFERENTIALS,
);
export const fetchMaturityRulesets = createAction(FETCH_MATURITY_RULESETS);
export const receiveMaturityRulesets = createAction(RECEIVE_MATURITY_RULESETS);
export const productSelection = createAction(PRODUCT_SELECTION);
export const selectColumn = createAction(SELECT_COLUMN);
export const updateColumnSelection = createAction(UPDATE_COLUMN_SELECTION);
export const upsertListColumns = createAction(UPSERT_LIST_COLUMNS);
export const cancelUpsertListColumns = createAction(CANCEL_UPSERT_LIST_COLUMNS);
export const sortFilterByDocCount = createAction(SORT_FILTER_BY_DOC_COUNT);
export const sortList = createAction(SORT_LIST);
export const search = createAction(SEARCH);
export const trackAction = createAction(TRACK_CATALOG_ACTION);
export const trackSearch = createAction(TRACK_SEARCH);
export const collapseFilters = createAction(COLLAPSE_FILTERS);
export const updateSelectedFilters = createAction(UPDATE_SELECTED_FILTERS);
export const updateSelectedFiltersFromQuery = createAction(
  UPDATE_SELECTED_FILTERS_FROM_QUERY,
);
export const updateFilteredFilters = createAction(UPDATE_FILTERED_FILTERS);
export const updateFiltersPagination = createAction(UPDATE_FILTERS_PAGINATION);
export const updateItemsPerPage = createAction(UPDATE_ITEMS_PER_PAGE);
export const exportProducts = createAction(EXPORT_PRODUCTS);
export const exportProductsDone = createAction(EXPORT_PRODUCTS_DONE);
export const exportDesiredProducts = createAction(EXPORT_DESIRED_PRODUCTS);
export const exportDesiredProductsDone = createAction(
  EXPORT_DESIRED_PRODUCTS_DONE,
);
export const cancelExportProducts = createAction(CANCEL_EXPORT_PRODUCTS);
export const cancelExportDesiredProducts = createAction(
  CANCEL_EXPORT_DESIRED_PRODUCTS,
);
export const updateSearchFromQuery = createAction(UPDATE_SEARCH_FROM_QUERY);
export const openExportModal = createAction(OPEN_EXPORT_MODAL);
export const openExportDesiredProductdModal = createAction(
  OPEN_EXPORT_DESIRED_PRODUCTS_MODAL,
);
export const closeExportModal = createAction(CLOSE_EXPORT_MODAL);
export const closeExportDesiredProductsModal = createAction(
  CLOSE_EXPORT_DESIRED_PRODUCTS_MODAL,
);
export const updateExportFormat = createAction(UPDATE_EXPORT_FORMAT);
export const updateWithArchivedProducts = createAction(
  UPDATE_WITH_ARCHIVED_PRODUCTS,
);
export const updateOnlyDuplicatedProducts = createAction(
  UPDATE_ONLY_DUPLICATED_PRODUCTS,
);
export const updateWithAllTypes = createAction(UPDATE_WITH_ALL_TYPES);
export const replaceFilters = createAction(REPLACE_FILTERS);
export const setCatalogContext = createAction(SET_CATALOG_CONTEXT);
export const updateWithRefusedAssignations = createAction(
  UPDATE_WITH_REFUSED_ASSIGNATIONS,
);
export const updateAssignationStatus = createAction(UPDATE_ASSIGNATION_STATUS);
export const initCatalog = createAction(INIT_CATALOG);
export const setFiltersFromReporting = createAction(SET_FILTERS_FROM_REPORTING);
export const setCatalogUuid = createAction(SET_CATALOG_UUID);
export const fetchExportFileFormats = createAction(FETCH_EXPORT_FILE_FORMATS);
export const receiveExportFileFormats = createAction(
  RECEIVE_EXPORT_FILE_FORMATS,
);
export const updateProductsToExport = createAction(UPDATE_PRODUCTS_TO_EXPORT);
export const dispatchProducts = createAction(DISPATCH_PRODUCTS);

export interface DownloadComplianceReportActionPayload {
  productKeyIds: number[];
}
export const downloadComplianceReport =
  createAction<DownloadComplianceReportActionPayload>(
    DOWNLOAD_COMPLIANCE_REPORT,
  );

export interface BulkActivateRecipientsActionPayload {
  recipientIds: number[];
}

export const bulkActivateRecipients =
  createAction<BulkActivateRecipientsActionPayload>(BULK_ACTIVATE_RECIPIENTS);

export const setHighlightedRowIdentifier = createAction<number | null>(
  SET_HIGHLIGHTED_ROW_IDENTIFIER,
);

import { getDisplayName as getProductVersionDisplayName } from 'core/api/productversion';
import { get } from 'utils/immutable';

export const getReference = (product) =>
  get(product, 'gtin') ||
  get(product, 'isIdentifiedBy.0.reference') ||
  get(product, 'productIdentifier');

export const getDisplayName = (product, currentLanguage) =>
  getProductVersionDisplayName(
    get(product, 'defaultSpecialization'),
    currentLanguage,
  );

// Usable on products fetched from index.
export const getProductKeyId = (product) => get(product, 'product_key.id');

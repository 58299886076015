import { selectHasTariffFilterSearch } from 'modules/feature-flag/selectors';
import { UserImmutable } from 'types';

export const sharingUnitTariffTypesFilter = {
  key: 'types',
  query: 'types',
  isAvailable: ({
    isRetailer,
    user,
  }: {
    isRetailer: boolean;
    user: UserImmutable;
  }) => isRetailer && selectHasTariffFilterSearch(user),
  aggregationKey: 'tariff_types',
};

import PropTypes from 'prop-types';

import Image from 'components/ui/image';
import MissingImage from 'images/missing.png';

import './index.scss';

export default function CatalogPictureColumn({ uri, width, height }) {
  const src = uri || MissingImage;

  return (
    <Image
      width={width}
      height={height}
      src={src}
      fallbackSrc={MissingImage}
      maxWidth={width}
      maxHeight={height}
    />
  );
}

CatalogPictureColumn.className = 'CatalogPictureColumn';
CatalogPictureColumn.propTypes = {
  uri: PropTypes.string,
  width: PropTypes.string,
  height: PropTypes.string,
};

import { hasFeature } from 'modules/feature-flag';
import { FEATURE_ALL_CATALOG_FILTER_GPC } from 'modules/feature-flag/constants';

export const gpcStatusFilter = {
  key: 'GPC.categoryCode.id',
  aggregation: {
    value_field: 'GPC.categoryCode.label.raw',
  },
  query: 'gpc',
  isAvailable: ({ user } = {}) =>
    hasFeature(user, FEATURE_ALL_CATALOG_FILTER_GPC),
};

import { List, Map, fromJS } from 'immutable';
import { createReducer } from 'redux-create-reducer';

import {
  RETAILER_RECEIVE_REQUESTS,
  RETAILER_RECEIVE_SHARINGUNITS_REQUESTS,
  RETAILER_REQUESTS_RECEIVE_TRANSACTION_IDS,
  RETAILER_REQUESTS_TOGGLE_PROGRESS,
} from './constants';

const initialState = fromJS({
  consumerUnitRequestsDiff: [],
  sharingUnitRequestsDiff: {},
  // New apply.
  transactionIds: {
    consumerUnit: [],
    listing: {},
  },
  acceptOrRefuseInProgress: false,
});

export default createReducer(initialState, {
  [RETAILER_RECEIVE_REQUESTS]: (state, { requests }) => {
    if (List.isList(requests)) {
      const list = requests.reduce(
        (l, request) => l.concat(request.get('diff')),
        List(),
      );

      return state.set('consumerUnitRequestsDiff', list);
    }
    return state;
  },
  [RETAILER_RECEIVE_SHARINGUNITS_REQUESTS]: (state, { sharingunits }) => {
    if (Map.isMap(sharingunits)) {
      return state.set('sharingUnitRequestsDiff', sharingunits);
    }
    return state;
  },
  // New apply.
  [RETAILER_REQUESTS_RECEIVE_TRANSACTION_IDS]: (
    state,
    { consumerUnit, listing },
  ) =>
    state.set(
      'transactionIds',
      fromJS({
        consumerUnit,
        listing,
      }),
    ),
  [RETAILER_REQUESTS_TOGGLE_PROGRESS]: (state, { inProgress }) =>
    state.set('acceptOrRefuseInProgress', inProgress),
});

import classNames from 'classnames';
import { useCallback, useMemo, useRef, useState } from 'react';
import { useSelector } from 'react-redux';

import {
  getUserOrganizationId,
  getUserOrganizationType,
} from '@alkem/lib-front-model/immutable';
import { useSubscribe } from '@alkem/micro-frontend-tools';
import { ClickOutside } from '@alkem/react-ui-click-outside';

import { getOrganizationTargetMarketIds, getUsername } from 'core/api/user';
import { selectUser } from 'modules/user';
import { getAppConfig } from 'utils/app-config';
import i18n, { getUserLanguage } from 'utils/i18n';

import { OPEN_HELP_CENTER_EVENT } from '../constants';
import type { HelpState } from '../types';

import styles from './HelpPanel.module.scss';

export function HelpPanel() {
  const user = useSelector(selectUser);
  const [panelState, setPanelState] = useState<HelpState>({ isOpen: false });
  const panelRef = useRef<HTMLDivElement>(null);

  const title = i18n.t('frontproductstream.help.panel.title', {
    defaultValue: 'Knowledge Base Help Panel',
  });

  const params = useMemo(() => {
    const userLang = getUserLanguage().replace('-', '_');
    const orgId = getUserOrganizationId(user);
    const orgType = getUserOrganizationType(user);
    const targetMarket = getOrganizationTargetMarketIds(user).first();
    const email = getUsername(user);
    return `lang=${userLang}&group=org-id-${orgId}|org-type-${orgType}|target-market-${targetMarket}&emailAddress=${email}`;
  }, [user]);

  const closePanel = useCallback(() => {
    setPanelState({ isOpen: false });
  }, []);

  useSubscribe<HelpState>(
    OPEN_HELP_CENTER_EVENT,
    ({ isOpen, article, module }) => {
      if (isOpen) {
        setPanelState({ isOpen: true, article, module });
      } else {
        closePanel();
      }
    },
  );

  const salesforceUrl = getAppConfig().salesforceKnowledgeBase?.url;
  let frameUrl = `${salesforceUrl}?${params}`;
  if (panelState.article) frameUrl += `&article=${panelState.article}`;
  if (panelState.module) frameUrl += `&module=${panelState.module}`;

  return panelState.isOpen ? (
    <ClickOutside ref={panelRef} onClickOutside={closePanel}>
      <div ref={panelRef} id="app-widget-container" className={styles.main}>
        <div className={classNames('nav-container', styles.navContainer)}>
          <h1 className={classNames('nav-title', styles.navTitle)}>{title}</h1>
          <button
            className={classNames('close-button', styles.closeButton)}
            onClick={closePanel}
            aria-label={i18n.t('frontproductstream.help.panel.close_button', {
              defaultValue: 'Close knowledge base help panel',
            })}
          >
            <i className="mdi mdi-close" />
          </button>
        </div>
        {salesforceUrl ? (
          <iframe
            src={frameUrl}
            className={styles.iframe}
            title={title}
            role="article"
          />
        ) : (
          <p className={styles.invalidSalesforceUrl}>
            {i18n.t('frontproductstream.help.panel.invalid_salesforce_url', {
              defaultValue: 'Salesforce Knowledge Base URL is invalid',
            })}
          </p>
        )}
      </div>
    </ClickOutside>
  ) : null;
}

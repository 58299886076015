import classNames from 'classnames';
import { debounce } from 'lodash';
import { useEffect, useMemo, useState } from 'react';

import formatMultipleSearch from 'utils/formatMultipleSearch';
import i18n from 'utils/i18n';

import './search.scss';

interface Props {
  query?: string;
  updateSearchQuery: (search: string) => void;
  placeholder?: string | null;
  small?: boolean;
  withPasteHandler?: boolean;
  isTransparent?: boolean;
  className?: string;
}

export const UPDATE_SEARCH_QUERY_DELAY_MS = 500;

export const Search = (props: Props) => {
  const {
    query = '',
    small = false,
    isTransparent = false,
    placeholder,
    updateSearchQuery,
    withPasteHandler = true,
    className,
  } = props;

  const [search, setSearch] = useState(query);

  useEffect(() => setSearch(query), [query]);

  const updateSearch = useMemo(
    () =>
      debounce((q: string) => {
        updateSearchQuery(q);
      }, UPDATE_SEARCH_QUERY_DELAY_MS),
    [updateSearchQuery],
  );

  const onSearch = (value) => {
    if (value !== search) {
      setSearch(value);
      updateSearch(value);
    }
  };

  const onSearchInput = (event) => {
    onSearch(event.target.value);
  };

  const onSearchClear = () => {
    onSearch('');
  };

  const onSearchPaste = (event) => {
    if (!withPasteHandler) {
      return;
    }
    const items = event.clipboardData
      ? event.clipboardData.getData('text')
      : null;

    if (!items || typeof items !== 'string') {
      return;
    }
    event.preventDefault();

    // Get the current value and if the user has selected some text.
    const currentValue = event.target.value;
    const { selectionStart, selectionEnd } = event.target;
    const searchString =
      currentValue.substring(0, selectionStart) +
      formatMultipleSearch(items) +
      currentValue.substring(selectionEnd);

    onSearch(searchString);
  };

  const inputClass = {
    Search__input: true,
    'Search__input--small': small,
  };

  const searchIcon = {
    mdi: true,
    'mdi-magnify': true,
    Search__icon: true,
    'Search__icon--small': small,
  };

  const clearSearchIcon = {
    mdi: true,
    'mdi-close': true,
    ClearSearch__icon: true,
    'ClearSearch__icon--small': small,
  };

  const clearSearchLabel = i18n.t(
    'frontproductstream.input.search.clear_action_tooltip',
    { defaultValue: 'Clear search' },
  );

  return (
    <div
      className={classNames(
        'Search',
        isTransparent && 'Search--transparent',
        search && 'Search--inProgress',
        className,
      )}
    >
      <span className={classNames(searchIcon)} />
      <input
        type="text"
        className={classNames(inputClass)}
        placeholder={placeholder || ''}
        value={search}
        onChange={onSearchInput}
        onPaste={onSearchPaste}
      />
      {search && (
        <span
          className={classNames(clearSearchIcon)}
          title={clearSearchLabel}
          aria-label={clearSearchLabel}
          onClick={onSearchClear}
        />
      )}
    </div>
  );
};

export default Search;

import i18n from 'utils/i18n';

export interface DisplayUnitFilter {
  id: string;
  name: string;
  onlyThirdParty?: boolean;
}

export const consumerUnit: DisplayUnitFilter = {
  id: 'consumerUnit',
  name: i18n.t('frontproductstream.constants.display_units.consumer_units', {
    defaultValue: 'Consumer Units',
  }),
};

export const displayUnit: DisplayUnitFilter = {
  id: 'displayUnit',
  name: i18n.t('frontproductstream.constants.display_units.display_units', {
    defaultValue: 'Display Units',
  }),
};

export const heterogeneousHierarchyUnit: DisplayUnitFilter = {
  id: 'heterogeneousHierarchyUnit',
  name: i18n.t(
    'frontproductstream.constants.display_units.heterogeneous_logistical_units',
    {
      defaultValue: 'Heterogeneous Logistical Units',
    },
  ),
};

export const packCasePallet: DisplayUnitFilter = {
  id: 'other',
  name: `${i18n.t('frontproductstream.constants.display_units.pack', {
    defaultValue: 'Pack',
  })}, ${i18n.t('frontproductstream.constants.display_units.case', {
    defaultValue: 'Case',
  })}, ${i18n.t('frontproductstream.constants.display_units.pallet', {
    defaultValue: 'Pallet',
  })}`,
  onlyThirdParty: true,
};

export const DisplayUnitFilters = {
  consumerUnit,
  displayUnit,
  packCasePallet,
  heterogeneousHierarchyUnit,
};

import PropTypes from 'prop-types';
import { PureComponent } from 'react';
import ImmutablePropTypes from 'react-immutable-proptypes';

import { get } from 'utils/immutable';

import './item.scss';

export default class ListSelectedFilterItem extends PureComponent {
  static propTypes = {
    filter: PropTypes.oneOfType([ImmutablePropTypes.map, PropTypes.object])
      .isRequired,
    onRemove: PropTypes.func.isRequired,
  };

  constructor(props) {
    super(props);
    this.onRemove = this.onRemove.bind(this);
  }

  onRemove() {
    this.props.onRemove(this.props.filter);
  }

  render() {
    const { filter } = this.props;
    const key = get(filter, 'key');
    const value = get(filter, 'value');
    const id = `list-selected-filter-${key}-${value}`;
    return (
      <li id={id} className="ListSelectedFilterItem">
        <label htmlFor={id}>{get(filter, 'label')}</label>
        <i className="mdi mdi-close" onClick={this.onRemove} />
      </li>
    );
  }
}

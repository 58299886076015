import { List, Map } from 'immutable';
import { ComponentProps, ReactElement, useMemo, useState } from 'react';
import { useSelector } from 'react-redux';

import { Modal } from '@alkem/react-ui-modal';
import TurboSelect from '@alkem/react-ui-turbo-select';

import { selectUser } from 'modules/user';
import { UserImmutable } from 'types';
import i18n from 'utils/i18n';
import { asString } from 'utils/types';

import {
  ProductVersion,
  ProductsMap,
  displayName,
  pvIsSharedWith,
} from '../types';

import './recipientSelectionModal.scss';

type ModalProps = ComponentProps<typeof Modal>;

export type Props = Pick<ModalProps, 'onClose'> & {
  onConfirm: (pvs: ProductsMap, recipientId: number | null) => void;
  unshareables: ProductsMap;
  notUnshareables: ProductsMap;
};

type ListItemProps = {
  pv: ProductVersion;
};

type ListProps = {
  pvs: ProductsMap;
};

const PvListItem = ({ pv }: ListItemProps) => {
  const user: UserImmutable = useSelector(selectUser);
  return (
    <li>
      {displayName(pv, user)} (product key id ={' '}
      {pv.getIn(['product_key', 'id'], '?')})
    </li>
  );
};

const PvList = ({ pvs }: ListProps) => (
  <ul>
    {pvs
      .map((pv) => (
        <PvListItem
          pv={pv as ProductVersion}
          key={(pv as ProductVersion).get('id') as string}
        />
      ))
      .toArray()}
  </ul>
);

type Recipient = {
  id: number;
  nameLegal: string;
  nbSharedProducts: number;
};

type RecipientMap = {
  [id: number]: Recipient;
};

const extractRecipientsFromPV = (pv: ProductVersion): Recipient[] => {
  const recipientList = pv['alkemics']['recipients'];
  return recipientList.map(({ targetOrganization: { id, nameLegal } }) => ({
    id,
    nameLegal,
    nbSharedProducts: 1,
  }));
};

const extractAllRecipientsFromProductsMap = (pvs: ProductsMap): RecipientMap =>
  List(pvs.values())
    .toJS()
    .reduce((acc: RecipientMap, pv) => {
      const pvRecipients = extractRecipientsFromPV(pv);
      for (const recipient of pvRecipients) {
        if (acc[recipient.id]) {
          acc[recipient.id].nbSharedProducts++;
        } else {
          acc[recipient.id] = recipient;
        }
      }
      return acc;
    }, {} as RecipientMap);

export function getNoOptionsMessage() {
  return i18n.t('frontproductstream.bulk_unshare.modal_no_option.placeholder', {
    defaultValue: 'No available option',
  });
}

const RecipientSelectionModal = (props: Props) => {
  const { unshareables, notUnshareables, onConfirm, onClose } = props;

  const [selectedRecipient, setSelectedRecipient] = useState<Recipient | null>(
    null,
  );

  const [splittedBySelectedRecipient, setSplittedBySelectedRecipient] =
    useState<[ProductsMap, ProductsMap]>([unshareables, Map()]);
  const [pvsSharedWithSelectedRecipient, pvsNotSharedWithSelectedRecipient] =
    splittedBySelectedRecipient;

  const recipients = useMemo(() => {
    const r = Object.values(extractAllRecipientsFromProductsMap(unshareables));
    r.sort(({ nameLegal: n1 }, { nameLegal: n2 }) => n1.localeCompare(n2));
    return r;
  }, [unshareables]);

  useMemo(() => {
    if (selectedRecipient === null) {
      setSplittedBySelectedRecipient([unshareables, Map()]);
    } else {
      const isSharedWithRecipient = pvIsSharedWith(selectedRecipient.id);
      const sharedOnes = Map<number, ProductVersion>(
        unshareables.filter(isSharedWithRecipient),
      );
      const unsharedOnes = Map<number, ProductVersion>(
        unshareables.filterNot(isSharedWithRecipient),
      );
      setSplittedBySelectedRecipient([sharedOnes, unsharedOnes]);
    }
  }, [selectedRecipient, unshareables]);

  const hasNoUnshareables = !unshareables.size;

  const handleSelectChange = (option) => {
    let o: Recipient | null = null;
    if (!option) {
      o = null;
    } else if (Array.isArray(option)) {
      o = option[0];
    } else {
      o = option;
    }
    setSelectedRecipient(o);
  };

  let modalBody;
  if (hasNoUnshareables) {
    modalBody = (
      <div>
        {i18n.t(
          'frontproductstream.bulk_unshare.modal_product_already_unshared.error',
          {
            defaultValue:
              'All the selected products are already unshared. There is nothing to do.',
          },
        )}
      </div>
    );
  } else {
    const wontBeShared = notUnshareables.merge(
      pvsNotSharedWithSelectedRecipient,
    );

    let wontBeSharedInfoElem: ReactElement | null = null;
    if (wontBeShared.size) {
      wontBeSharedInfoElem = (
        <>
          <strong>
            {selectedRecipient
              ? // a retailer is selected
                i18n.t(
                  'frontproductstream.bulk_unshare.modal.product_not_shared_with_selected_retailer.text',
                  {
                    defaultValue:
                      'Some products you selected are not shared with the selected retailer, and therefore will not be processed, such as',
                  },
                )
              : // no retailer selected => unshare with all retailers
                i18n.t(
                  'frontproductstream.bulk_unshare.modal_product_not_shared_with_any_channel.text',
                  {
                    defaultValue:
                      'Some products you selected are not shared with any channel, and therefore will not be processed, such as',
                  },
                )}{' '}
            :
          </strong>
          <PvList pvs={wontBeShared} />
        </>
      );
    }

    modalBody = (
      <div>
        <TurboSelect<Recipient>
          options={recipients}
          value={selectedRecipient}
          placeholder={i18n.t(
            'frontproductstream.bulk_unshare.modal_selected_recipient.placeholder',
            {
              defaultValue: 'Every recipients',
            },
          )}
          noOptionsMessage={getNoOptionsMessage}
          getOptionLabel={(opt: Recipient) =>
            `${opt.nameLegal} (${opt.nbSharedProducts})`
          }
          getOptionValue={(opt: Recipient) => asString(opt.id)}
          onChange={handleSelectChange}
          className="BulkUnshare__RecipientSelectionModal__Selector"
          menuPlacement="bottom"
        />
        <div data-testid="notabene" className="BulkUnshare__Size">
          {wontBeSharedInfoElem}
        </div>
      </div>
    );
  }

  const handleConfirm = () => {
    onConfirm(
      pvsSharedWithSelectedRecipient,
      (selectedRecipient && selectedRecipient.id) || null,
    );
  };

  return (
    <Modal
      modalStyle="dynamic"
      title={i18n.t(
        'frontproductstream.bulk_unshare.bulk_unshare_info_modal_title.text',
        {
          defaultValue: 'Info',
        },
      )}
      confirmButtonText={i18n.t(
        'frontproductstream.bulk_unshare.bulk_unshare_info_modal.button',
        {
          defaultValue: 'Ok',
        },
      )}
      onConfirm={handleConfirm}
      onClose={onClose}
      confirmDisabled={hasNoUnshareables}
    >
      {modalBody}
    </Modal>
  );
};

export default RecipientSelectionModal;

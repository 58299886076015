import { useCallback } from 'react';
import { useDispatch } from 'react-redux';
import { Link, useNavigate } from 'react-router-dom';

import Modal from 'components/ui/modal';
import { suppliersList as supplierListRoute } from 'routes';
import i18n from 'utils/i18n';

import { closeModal } from '../actions';

export const FallbackInformationRequestModal = () => {
  const navigate = useNavigate();
  const goToSupplierDirectory = useCallback(
    () => navigate(supplierListRoute),
    [navigate],
  );
  const dispatch = useDispatch();

  const onClose = () => {
    dispatch(closeModal());
  };

  return (
    <Modal
      modalStyle="dynamic"
      title={i18n.t('Your request could not be sent')}
      className="InformationRequestModal"
      confirmButtonText={i18n.t('Go to my Supplier Directory') as string}
      onConfirm={goToSupplierDirectory}
      closeButtonText={i18n.t('Close') as string}
      onClose={onClose}
    >
      <p>
        {i18n.t(
          'Your supplier isn’t reconciled or your request has been refused.',
        )}
      </p>
      <p>
        {i18n.t('Head to your ')}
        <Link to={supplierListRoute}>{i18n.t('Supplier Directory')}</Link>
        {i18n.t(' to update the reconciliation.')}
      </p>
      <p>
        {i18n.t(
          'Your request will be automatically sent to all Alkemics organisations reconciled with your supplier.',
        )}
      </p>
    </Modal>
  );
};

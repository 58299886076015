import Immutable from 'immutable';
import { put, select } from 'redux-saga/effects';

import { getOrganizationId } from 'core/api/user';
import { RELEASE_B2K_COLLAB_EDITS_SV_SF } from 'modules/feature-flag';
import { selectHasFeature } from 'modules/feature-flag/selectors';
import { selectSpecificFieldsByRecipient } from 'modules/recipient-specific-block/selectors';
import { selectIsManufacturer } from 'modules/user';

import { dataOpsReplacePatches } from '../actions';
import { dataOpsPatchKey, selectDataOpsPatches } from '../selectors';
import { DataOpsEntityType, DataOpsPatch, DataOpsPatchState } from '../types';

export function* replaceSpecificFieldKeysInPatchesSaga() {
  const patchesByField = yield select(selectDataOpsPatches);

  const isManufacturer = yield select(selectIsManufacturer);
  const hasReleaseDataOpsOnSpecificValue = yield select(
    selectHasFeature(RELEASE_B2K_COLLAB_EDITS_SV_SF),
  );
  if (!isManufacturer || !hasReleaseDataOpsOnSpecificValue) {
    // consolidation is only needed for manufacturers with release flag enabled, as we need to get information about private fields
    return;
  }

  const specificFieldsByRecipient = yield select(
    selectSpecificFieldsByRecipient,
  );
  const consolidatedPatchesByField = {};

  Object.keys(patchesByField || {}).forEach((key) => {
    const keyParts = key.split('/');
    const fieldName = keyParts[1];

    const patchGroup: DataOpsPatchState = patchesByField[key];
    consolidatePatchesForKey(
      consolidatedPatchesByField,
      key,
      patchGroup,
      fieldName,
      specificFieldsByRecipient,
    );
  });

  yield put(dataOpsReplacePatches({ patches: consolidatedPatchesByField }));
}

function consolidatePatchesForKey(
  consolidatedPatchesByField: {},
  key: string,
  sourcePatchGroup: DataOpsPatchState,
  fieldName: string,
  specificFieldsByRecipient,
) {
  // mainly to copy "editing" property used on retailer side
  consolidatedPatchesByField[key] = { ...sourcePatchGroup, data: [] };

  const patches = sourcePatchGroup?.data || [];

  patches.forEach((iteratedPatch) => {
    // as we are on manuf side, there can be no DataOpsNewPatch : cast is safe (and we need to get user's orga)
    const patch = iteratedPatch as DataOpsPatch;
    const targetOrganizationId = getOrganizationId(patch.user);
    const patchIsOnSpecificField = hasPrivateField(
      targetOrganizationId,
      fieldName,
      specificFieldsByRecipient,
    );

    if (patchIsOnSpecificField) {
      const consolidatedKey = dataOpsPatchKey(targetOrganizationId, fieldName);
      const consolidatedPatch = {
        ...patch,
        key: consolidatedKey,
        appliesOnEntityId: targetOrganizationId,
        entityType: DataOpsEntityType.PRODUCTVERSION_OVERRIDE,
        isSpecific: true,
      };
      appendPatch(
        consolidatedPatchesByField,
        sourcePatchGroup,
        consolidatedKey,
        consolidatedPatch,
      );
    } else {
      appendPatch(consolidatedPatchesByField, sourcePatchGroup, key, patch);
    }
  });
}

function hasPrivateField(
  targetOrganizationId: number,
  fieldName: string,
  specificFieldsByRecipient: Immutable.Map<number, any>,
): boolean {
  const specificFields: Array<any> = specificFieldsByRecipient.get(
    targetOrganizationId,
    [],
  );
  return specificFields.some((specificField) => {
    return specificField.get('model') === fieldName;
  });
}

function appendPatch(
  consolidatedPatchesByField,
  originPatchGroup: DataOpsPatchState,
  consolidatedKey: string,
  patch: DataOpsPatch,
) {
  let targetPatchGroup: DataOpsPatchState;
  if (consolidatedKey in consolidatedPatchesByField) {
    targetPatchGroup = consolidatedPatchesByField[consolidatedKey];
  } else {
    targetPatchGroup = { ...originPatchGroup, data: [] };
    consolidatedPatchesByField[consolidatedKey] = targetPatchGroup;
  }
  targetPatchGroup.data.push(patch);
}

import { isEmpty } from 'lodash';
import memoize from 'memoize-one';
import PropTypes from 'prop-types';
import { PureComponent } from 'react';
import ImmutablePropTypes from 'react-immutable-proptypes';
import { connect } from 'react-redux';
import { createStructuredSelector } from 'reselect';

import { SimpleSelect } from '@alkem/react-ui-select';
import { Tipster } from '@alkem/react-ui-tipster';

import Modal from 'components/ui/modal';
import i18n from 'utils/i18n';
import { get } from 'utils/immutable';
import { separateActions } from 'utils/redux';

import {
  cancelExportProducts,
  closeExportModal,
  exportProducts,
  fetchExportFileFormats,
  updateExportFormat,
  updateProductsToExport,
} from '../../../../../actions';
import {
  selectExportFormatV1,
  selectExportInProgress,
  selectFilteredExportFileFormats,
  selectIsExportModalOpen,
  selectPagination,
  selectProductMap,
  selectSelectedMap,
} from '../../../../../selectors';

import './index.scss';

const mapStateToProps = createStructuredSelector({
  productMap: selectProductMap,
  selectedProducts: selectSelectedMap,
  totalProducts: (state) => selectPagination(state).get('total'),
  selectedFormat: selectExportFormatV1,
  isOpen: selectIsExportModalOpen,
  isExportInProgress: selectExportInProgress,
  fileFormats: selectFilteredExportFileFormats,
  user: (state) => state.user,
  recipients: (state) => state.recipients,
});

const mapDispatchToProps = {
  closeExportModal,
  updateExportFormat,
  exportProducts,
  cancelExportProducts,
  fetchExportFileFormats,
  updateProductsToExport,
};

export class CatalogExportModalV1 extends PureComponent {
  static propTypes = {
    productMap: ImmutablePropTypes.map.isRequired,
    selectedProducts: ImmutablePropTypes.map.isRequired,
    totalProducts: PropTypes.number.isRequired,
    selectedFormat: PropTypes.object,
    fileFormats: PropTypes.array,
    isOpen: PropTypes.bool,
    isExportInProgress: PropTypes.bool,
    actions: PropTypes.shape({
      exportProducts: PropTypes.func.isRequired,
      closeExportModal: PropTypes.func.isRequired,
      updateExportFormat: PropTypes.func.isRequired,
      cancelExportProducts: PropTypes.func.isRequired,
      fetchExportFileFormats: PropTypes.func.isRequired,
      updateProductsToExport: PropTypes.func.isRequired,
    }),
  };

  static defaultProps = {
    isExportInProgress: false,
    totalProducts: 0,
    fileFormats: [],
  };

  componentDidMount() {
    this.props.actions.fetchExportFileFormats();
  }

  onExport = () => {
    const {
      selectedProducts,
      productMap,
      actions,
      fileFormats,
      selectedFormat,
    } = this.props;
    actions.updateExportFormat(selectedFormat || fileFormats[0]);
    actions.updateProductsToExport(
      selectedProducts
        .filter((v) => v)
        .map((v, k) => productMap.get(k))
        .valueSeq()
        .map((product) => get(product, ['product_key', 'id']))
        .toJS(),
    );
    actions.exportProducts();
  };

  onClose = () => {
    this.props.actions.cancelExportProducts();
    this.props.actions.closeExportModal();
  };

  onSelectFormat = (option) => {
    this.props.actions.updateExportFormat(option);
  };

  getSelectedProduts = memoize((selectedProducts) =>
    selectedProducts.filter((s) => s),
  );

  canExport = () => {
    const { totalProducts, selectedProducts, selectedFormat } = this.props;
    const totalProductsToExport =
      selectedProducts.size > 0 ? selectedProducts.size : totalProducts;
    return (
      selectedFormat.maxTotalProducts == null ||
      totalProductsToExport <= selectedFormat.maxTotalProducts
    );
  };

  render = () => {
    const {
      selectedProducts,
      totalProducts,
      isOpen,
      selectedFormat,
      isExportInProgress,
      fileFormats,
    } = this.props;
    if (!isOpen) {
      return null;
    }
    const exportedProducts =
      this.getSelectedProduts(selectedProducts).size || totalProducts;
    const canExport = this.canExport();
    return (
      <Modal
        modalStyle="dynamic"
        className="CatalogExportModal CatalogExportModalV1"
        title={
          exportedProducts <= 1
            ? i18n.t('Export {{exportedProducts}} product', {
                exportedProducts,
              })
            : i18n.t('Export {{exportedProducts}} products', {
                exportedProducts,
              })
        }
        confirmButtonText={i18n.t('Export')}
        confirmDisabled={isEmpty(fileFormats) || !canExport}
        isProcessing={isExportInProgress}
        onConfirm={this.onExport}
        onClose={this.onClose}
      >
        <div className="CatalogExportModal__label">
          {i18n.t('Select a format:')}
        </div>
        <div className="CatalogExportModal__format">
          <SimpleSelect
            id="catalog-export-modal-format-selector"
            options={fileFormats}
            value={selectedFormat}
            onSelect={this.onSelectFormat}
            autoSize
          />
        </div>
        {!canExport && (
          <Tipster type="warning">
            <div>
              <span>
                {i18n.t(
                  "You can't export more than {{maxTotalProducts}} products when selecting {{formatLabel}} format",
                  {
                    maxTotalProducts: selectedFormat.maxTotalProducts,
                    formatLabel: selectedFormat.label,
                  },
                )}
              </span>
            </div>
          </Tipster>
        )}
      </Modal>
    );
  };
}

export default connect(
  mapStateToProps,
  mapDispatchToProps,
  separateActions,
)(CatalogExportModalV1);

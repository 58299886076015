import { Map } from 'immutable';
import { useCallback, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { ProgressBar } from '@alkem/react-ui-progress';
import { Tipster } from '@alkem/react-ui-tipster';

import Modal from 'components/ui/modal';
import { selectLocalesByTargetMarket } from 'modules/user';
import i18n from 'utils/i18n';

import { resetBulkAction, showBulkAction } from '../actions';
import { selectBulkActionState } from '../selectors';

import BulkActionModalInfo from './action-modal-info';
import './index.scss';
import RowComponent from './rowComponent';

type ID = number | string;

type Props = {
  bulkEntities: () => void; // the actual bulk operation processor
  rowComponent: RowComponent;
  retryOption?: boolean;
  onClose?: (didStart: boolean) => void;

  // Wording for the modal
  title: string;
  bulkSuccessfulMessage?: string;
  contentLabel: (nbEntities: number) => string;
  buttonLabel: (nbEntities: number) => string;
  errorLabel: (nbErrors: number) => string;
  retryLabel?: (nbErrors: number) => string;
};

const BulkActionModal = (props: Props) => {
  const {
    onClose = () => {},
    retryOption = true,
    bulkEntities,
    rowComponent,
    title,
    bulkSuccessfulMessage,
    contentLabel,
    buttonLabel,
    errorLabel,
    retryLabel,
  } = props;

  const dispatch = useDispatch();

  const {
    entities,
    visible,
    done,
    inProgress,
    count: inProgressCount,
    status: inProgressStatus,
    errors,
    success,
  } = (useSelector(selectBulkActionState) as any).toObject();

  const locales = useSelector(selectLocalesByTargetMarket);

  const reset = useCallback(() => dispatch(resetBulkAction()), [dispatch]);

  // ensure modal is reset after all
  useEffect(() => () => reset() && undefined, [reset]);

  if (!visible) {
    return null;
  }

  const close = () => {
    reset();
    onClose(inProgress || done);
  };

  const retry = () => {
    reset();
    dispatch(
      showBulkAction(
        errors
          .keySeq()
          .reduce(
            (map, entityId) =>
              (map as Map<unknown, unknown>).set(
                entityId,
                entities.get(entityId as ID),
              ),
            Map(),
          ),
      ),
    );
    bulkEntities();
  };

  // computing wording
  const count = entities.size;
  let retryLabeltext;
  let errorLabeltext;
  const contentLabeltext = contentLabel(count);
  const buttonLabeltext = buttonLabel(count);
  if (done && errors.size > 0) {
    if (retryOption && retryLabel) {
      // prb with props : both should be tied together
      retryLabeltext = retryLabel(errors.size);
    }
    errorLabeltext = errorLabel(errors.size);
  }

  const hasErrors = errors.size > 0;

  return (
    <Modal
      modalStyle="dynamic"
      className="BulkActionModal"
      title={title}
      confirmButtonText={
        (!done && buttonLabeltext) ||
        (hasErrors && retryOption && retryLabeltext) ||
        i18n.t(
          'frontproductstream.bulk_action_modal.close_button.default_label',
          { defaultValue: 'Close modal' },
        )
      }
      isProcessing={inProgress}
      onConfirm={
        (!done && bulkEntities) || (hasErrors && retryOption && retry) || close
      }
      onClose={close}
    >
      <div>{contentLabeltext}</div>
      <ul className="BulkActionModal__entities">
        {entities.keySeq().map((entityId) => (
          <BulkActionModalInfo
            key={entityId}
            entity={entities.get(entityId)}
            locales={locales}
            success={success.has(entityId)}
            error={errors.has(entityId)}
            rowComponent={rowComponent}
          />
        ))}
      </ul>
      <ProgressBar
        value={inProgressCount}
        max={count}
        color={inProgressStatus}
        height="medium"
      />
      {hasErrors && done && (
        <>
          <div className="BulkActionModal__errorsLabel">{errorLabeltext}</div>
          <ul className="BulkActionModal__entities BulkActionModal__errors">
            {errors.keySeq().map((entityId) => (
              <BulkActionModalInfo
                key={entityId}
                entity={entities.get(entityId)}
                locales={locales}
                rowComponent={rowComponent}
                message={errors.get(entityId)}
                error
              />
            ))}
          </ul>
        </>
      )}
      {done && !hasErrors && (
        <div className="BulkActionModal__success">
          <Tipster
            info={
              bulkSuccessfulMessage ||
              i18n.t(
                'frontproductstream.bulk_action_modal.info_tip.bulk_success',
                {
                  defaultValue:
                    'Bulk successful. Updates can take few seconds to show.',
                },
              )
            }
            type="success"
          />
        </div>
      )}
    </Modal>
  );
};

export default BulkActionModal;

import { number, string } from 'prop-types';

import './bar-item.scss';

function ReportingStackedBarChartItem({ percentage, color }) {
  return (
    <div
      className="ReportingStackedBarChartItem"
      style={{ flexBasis: `${percentage}%`, backgroundColor: color }}
    >
      <span>{Math.round(percentage)}%</span>
    </div>
  );
}

ReportingStackedBarChartItem.propTypes = {
  percentage: number.isRequired,
  color: string.isRequired,
};

export default ReportingStackedBarChartItem;

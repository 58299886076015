import { call, put, putResolve } from 'redux-saga/effects';

import { UserStatus } from '@alkem/lib-front-model';
import { UserImmutable } from '@alkem/lib-front-model/immutable';
import { navigate, withQueryParams } from '@alkem/micro-frontend-tools';
import { Authentication } from '@alkem/sdk-dashboard';

import { config } from 'constants/context';
import { loadPrefs } from 'constants/prefs';
import { getOrganization } from 'core/api/user';
import * as routes from 'routes';
import { asAction } from 'utils/actions';

import {
  authorized,
  fetchProductSegmentPermissions,
  getOrganizationPermissions,
  getProfile,
  notAuthorized,
  userProfileLoaded,
} from '../actions';

export function* loadProfileSaga({ payload: useCache = true } = {}) {
  const { user, error }: { user: UserImmutable; error?: Error } =
    yield putResolve(asAction(getProfile(useCache)));

  if (error) {
    yield put(notAuthorized());
    throw error;
  }

  if (user.get('status') !== UserStatus.ACTIVE.id) {
    yield call(navigate, {
      to: withQueryParams(routes.logout, { reason: 'deactivated_user' }),
    });
    return;
  }

  if (!getOrganization(user)) {
    yield call(navigate, {
      to: withQueryParams(routes.logout, { reason: 'no_organization' }),
    });
    return;
  }

  yield call(Authentication.initTokenStorageOnLoad);
  yield call(loadPrefs, user, config);
  yield put(authorized());

  yield put(asAction(fetchProductSegmentPermissions(user.get('id'))));
  yield put(asAction(getOrganizationPermissions()));
  yield put(userProfileLoaded({ user }));

  return user;
}

import classNames from 'classnames';
import PropTypes from 'prop-types';
import { Component } from 'react';

import { Button } from '@alkem/react-ui-button';

import './buttonBar.scss';

const className = 'ButtonBar';

class ButtonBar extends Component {
  static propTypes = {
    addButtonConf: PropTypes.object,
    buttons: PropTypes.array.isRequired,
    classModifier: PropTypes.string,
    label: PropTypes.string,
    onSelect: PropTypes.func,
    onAddButton: PropTypes.func,
    onRemoveButton: PropTypes.func,
    removable: PropTypes.bool,
    selectedIndex: PropTypes.number,
    readOnly: PropTypes.bool,
  };

  static defaultProps = {
    buttons: [],
    classModifier: null,
    onSelect: () => null,
    removable: false,
    selectedIndex: 0,
    readOnly: false,
  };

  constructor(props) {
    super(props);
    this.addButton = this.addButton.bind(this);
    this.onButtonClick = this.onButtonClick.bind(this);
    this.removeButton = this.removeButton.bind(this);
    this.state = {
      selected: this.props.selectedIndex,
      buttons: [...this.props.buttons],
    };
  }

  componentDidUpdate(prevProps) {
    const { buttons, selectedIndex } = this.props;
    if (prevProps.buttons !== buttons) {
      this.setState({ buttons }); // eslint-disable-line
    }
    if (prevProps.selectedIndex !== selectedIndex) {
      this.setState({ buttons, selected: selectedIndex }); // eslint-disable-line
    }
  }

  onButtonClick(event, data) {
    event.preventDefault();
    this.setState({ selected: data.index });
    this.props.onSelect(data);
  }

  addButton(event) {
    event.preventDefault();
    const promise = this.props.addButtonConf.onAddButton();
    if (promise && promise.then) {
      promise.then((data) => {
        if (data) {
          this.setState((prevState) => ({
            buttons: [...prevState.buttons, data],
          }));
        }
      });
    }
  }

  removeButton(data, index) {
    const { onRemoveButton } = this.props;
    if (onRemoveButton) {
      onRemoveButton(data, index);
    }
  }

  renderAddButton() {
    const { addButtonConf, readOnly } = this.props;
    if (readOnly || !addButtonConf) {
      return null;
    }
    return (
      <li className={`${className}__item`}>
        <Button
          secondary
          content={addButtonConf.label}
          className={`${className}__button`}
          onClick={this.addButton}
        />
      </li>
    );
  }

  renderLabel() {
    const { label } = this.props;
    if (label) {
      return (
        <li className={`${className}__item`}>
          <span className={`${className}__label`}>{label}</span>
        </li>
      );
    }
    return null;
  }

  renderButton = (data, index) => {
    const selected = index === this.state.selected;
    return (
      <li className={`${className}__item`} key={`${data.label}-${index}`}>
        <Button
          secondary
          content={data.label}
          className={classNames(`${className}__button`, { selected })}
          index={index}
          onClick={this.onButtonClick}
        />
        {this.renderRemove(data, index, selected)}
      </li>
    );
  };

  renderRemove(data, index, selected) {
    const { removable, readOnly } = this.props;
    if (removable && !readOnly) {
      const remove = () => this.removeButton(data, index);
      return (
        <i
          className={`${className}__remove mdi mdi-close ${
            selected ? 'selected' : ''
          }`}
          onClick={remove}
          data-testid={`button-bar-remove-button-${index}`}
        />
      );
    }
    return '';
  }

  render() {
    const { classModifier } = this.props;
    return (
      <ul className={`${className} ${classModifier}`}>
        {this.renderLabel()}
        {this.state.buttons.map(this.renderButton)}
        {this.renderAddButton()}
      </ul>
    );
  }
}

export default ButtonBar;

import { Filter } from 'core/modules/list/constants/filters/types';
import { hasFeature } from 'modules/feature-flag';
import { FEATURE_DIET_TYPE_FILTER } from 'modules/feature-flag/constants';
import { UserImmutable } from 'types';
import { i18n } from 'utils/i18n';

export const dietTypeFilter: Filter = {
  key: 'alkemics.flattened.isPracticeCompatible',
  filterLabel: i18n.t('frontproductstream.core.list_filter_diet_type.label', {
    defaultValue: 'Diet type',
  }),
  query: 'alkemics.flattened.isPracticeCompatible',
  isAvailable: ({ user }: { user?: UserImmutable } = {}) =>
    hasFeature(user, FEATURE_DIET_TYPE_FILTER),
};

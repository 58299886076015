import { put } from 'redux-saga/effects';

import {
  UserImmutable,
  getUserOrganizationId,
  isUserManufacturer,
} from '@alkem/lib-front-model/immutable';

import { USER_PROFILE_LOADED } from 'modules/user';
import rfpApi from 'resources/rfpApi';
import { logError } from 'utils/logging';
import { takeLatestSafe } from 'utils/saga';

import { fetchHasRfpModule, receiveHasRfpModule } from './actions';
import { FETCH_RFP_MODULE_AVAILABLITY } from './constants';

export function* UserInfoSaga() {
  yield takeLatestSafe(
    [FETCH_RFP_MODULE_AVAILABLITY],
    fetchRfpModuleAvailablity,
  );
  yield takeLatestSafe(USER_PROFILE_LOADED, userProfileLoadedSaga);
}

function* fetchRfpModuleAvailablity({ payload: { organizationId } }) {
  try {
    const {
      data: { data },
    } = yield rfpApi.post('/rfp/v2/event/list', {
      organization_id: organizationId,
      limit: 1,
    });
    yield put(receiveHasRfpModule(data.length > 0));
  } catch (error) {
    logError(error);
  }
}

function* userProfileLoadedSaga({
  payload: { user },
}: {
  payload: { user: UserImmutable };
}) {
  if (isUserManufacturer(user)) {
    yield put(
      fetchHasRfpModule({ organizationId: getUserOrganizationId(user) }),
    );
  }
}

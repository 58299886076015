import i18n from 'utils/i18n';

export const TargetProductStatusNotAssigned = {
  name: i18n.t(
    'frontproductstream.constants.target_product_status.not_assigned',
    { defaultValue: 'Not assigned' },
  ),
  className: 'NOT_ASSIGNED',
  id: 'NOT_ASSIGNED',
};

export const TargetProductStatusPendingAssignation = {
  name: i18n.t(
    'frontproductstream.constants.target_product_status.pending_assignation',
    { defaultValue: 'Pending acceptation' },
  ),
  className: 'ASSIGNATION_PENDING',
  id: 'ASSIGNATION_PENDING',
};

export const TargetProductStatusWaitingToBeShared = {
  name: i18n.t(
    'frontproductstream.constants.target_product_status.waiting_shared',
    { defaultValue: 'Waiting to be shared' },
  ),
  className: 'WAITING_TO_BE_SHARED',
  id: 'WAITING_TO_BE_SHARED',
};

export const TargetProductStatusRefused = {
  name: i18n.t('frontproductstream.constants.target_product_status.refused', {
    defaultValue: 'Refused',
  }),
  className: 'REFUSED',
  id: 'REFUSED',
};

export const TargetProductStatusSubmitted = {
  name: i18n.t('frontproductstream.constants.target_product_status.submitted', {
    defaultValue: 'To be validated',
  }),
  className: 'SUBMITTED',
  id: 'SUBMITTED',
};

export const TargetProductStatusUpdated = {
  name: i18n.t('frontproductstream.constants.target_product_status.updated', {
    defaultValue: 'Updated',
  }),
  className: 'UPDATED',
  id: 'UPDATED',
};

export const TargetProductStatusAccepted = {
  name: i18n.t('frontproductstream.constants.target_product_status.accepted', {
    defaultValue: 'Accepted',
  }),
  className: 'ACCEPTED',
  id: 'ACCEPTED',
};

export const TargetProductStatusMap = {
  [TargetProductStatusNotAssigned.id]: TargetProductStatusNotAssigned,
  [TargetProductStatusPendingAssignation.id]:
    TargetProductStatusPendingAssignation,
  [TargetProductStatusWaitingToBeShared.id]:
    TargetProductStatusWaitingToBeShared,
  [TargetProductStatusRefused.id]: TargetProductStatusRefused,
  [TargetProductStatusSubmitted.id]: TargetProductStatusSubmitted,
  [TargetProductStatusUpdated.id]: TargetProductStatusUpdated,
  [TargetProductStatusAccepted.id]: TargetProductStatusAccepted,
};

export const TargetProductStatusPostAssignationMap = {
  [TargetProductStatusRefused.id]: TargetProductStatusRefused,
  [TargetProductStatusSubmitted.id]: TargetProductStatusSubmitted,
  [TargetProductStatusUpdated.id]: TargetProductStatusUpdated,
  [TargetProductStatusAccepted.id]: TargetProductStatusAccepted,
};

export const buildTargetProductStatusMap = ({
  hasAutoAcceptShareCreate,
  hasAutoAcceptShareUpdate,
  hasInformationRequest,
}) => {
  const basicMap = {};
  if (hasInformationRequest) {
    Object.assign(basicMap, {
      [TargetProductStatusNotAssigned.id]: TargetProductStatusNotAssigned,
      [TargetProductStatusPendingAssignation.id]:
        TargetProductStatusPendingAssignation,
      [TargetProductStatusWaitingToBeShared.id]:
        TargetProductStatusWaitingToBeShared,
    });
  }
  if (!hasAutoAcceptShareCreate) {
    Object.assign(basicMap, {
      [TargetProductStatusRefused.id]: TargetProductStatusRefused,
      [TargetProductStatusSubmitted.id]: TargetProductStatusSubmitted,
    });
  }
  if (!hasAutoAcceptShareUpdate) {
    Object.assign(basicMap, {
      [TargetProductStatusUpdated.id]: TargetProductStatusUpdated,
    });
  }
  Object.assign(basicMap, {
    [TargetProductStatusAccepted.id]: TargetProductStatusAccepted,
  });
  return basicMap;
};

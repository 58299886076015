import { createAction } from 'utils/redux';

import {
  ACCEPT_ALL_SUGGESTIONS,
  ACCEPT_SUGGESTION,
  DISMISS_ALL_SUGGESTIONS,
  DISMISS_SUGGESTION,
} from './constants';

export const acceptSuggestion = createAction(ACCEPT_SUGGESTION);
export const dismissSuggestion = createAction(DISMISS_SUGGESTION);

export const acceptAllSuggestions = createAction(ACCEPT_ALL_SUGGESTIONS);
export const dismissAllSuggestions = createAction(DISMISS_ALL_SUGGESTIONS);

import { first, get, size } from 'utils/immutable';

export const getPackshotURL = (assets, original = false) => {
  const pictures = get(assets, 'pictures') || [];
  const packshot = pictures.find((p) => get(p, 'isPackshot'));
  if (!packshot) {
    return null;
  }

  // Return the original size if no exportables yet or if we want it.
  const exportables = get(packshot, 'exportables') || [];
  if (original || size(exportables) === 0) {
    const packshotUrl = get(packshot, 'url');
    if (!packshotUrl) {
      return null;
    }

    return `${get(packshot, 'url')}?cachebuster=${Date.now()}`;
  }

  // Return the 64x64 exportable or the first one.
  const exportable =
    exportables.find((e) => get(e, 'width') === 128) || first(exportables);
  return `${get(
    exportable,
    'uniformResourceIdentifier',
  )}?cachebuster=${Date.now()}`;
};

import { Map } from 'immutable';
import { createReducer } from 'redux-create-reducer';

import * as constants from './constants';

const initialState = Map({
  opened: false,
  promise: null,
});

export default createReducer(initialState, {
  [constants.OPEN]: (state, { promise }) =>
    state.set('opened', true).set('promise', promise),
  [constants.CLOSE]: (state, { confirmed }) => {
    const promise = state.get('promise');
    if (confirmed) {
      promise.resolve(true);
    } else {
      promise.resolve(false);
    }
    return state.set('opened', false).set('promise', null);
  },
});

import { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { Checkbox } from '@alkem/react-ui-checkbox';
import { Textarea } from '@alkem/react-ui-inputs';

import Modal from 'components/ui/modal';
import { selectOrganizationId } from 'modules/user';
import i18n from 'utils/i18n';
import { toJsIfImmutable } from 'utils/immutable';

import { closeModal, sendMessageStart } from '../actions';
import { defaultUserMessage } from '../constants';
import {
  selectGtin,
  selectRefusedRecipients,
  selectSupplierLoading,
} from '../selectors';

import './index.scss';
import { renderSupplierInfo } from './supplier-info';

export const ChatWithRecipientsModal = () => {
  const dispatch = useDispatch();
  const retailerId = useSelector(selectOrganizationId);
  const isLoading = useSelector(selectSupplierLoading);
  const refusedRecipients = toJsIfImmutable(
    useSelector(selectRefusedRecipients),
  );
  const gtin = useSelector(selectGtin);
  const [manufacturersSelected, setManufacturersSelected] = useState<number[]>(
    () =>
      refusedRecipients.map(
        (refusedRecipient) => refusedRecipient.organizationId,
      ),
  );
  const [sendDisabled, setSendDisabled] = useState(false);
  const [message, setMessage] = useState(defaultUserMessage);

  const defaultInformativeMessage = (refusedRecipient) => {
    return refusedRecipient.answerMessage
      ? i18n.t(
          'The creation of product {{reference}} was refused by the supplier on {{date}}: "{{answerMessage}}".\n\n',
          {
            reference: gtin,
            date: refusedRecipient.creationDate,
            // answer message truncated if too long to ensure retailer will have enough chars to answer
            answerMessage:
              refusedRecipient.answerMessage.length < 400
                ? refusedRecipient.answerMessage
                : refusedRecipient.answerMessage.slice(0, 400).concat('[...]'),
          },
        )
      : i18n.t(
          'The creation of product {{reference}} was refused by the supplier on {{date}}.\n\n',
          {
            reference: gtin,
            date: refusedRecipient.creationDate,
          },
        );
  };

  const onConfirm = () => {
    const messagesToSend = refusedRecipients
      .filter((refusedRecipient) =>
        manufacturersSelected.includes(refusedRecipient.organizationId),
      )
      .map((refusedRecipient) => {
        return {
          retailerId: retailerId,
          recipientId: refusedRecipient.organizationId,
          recipientName: refusedRecipient.organizationName,
          message:
            defaultInformativeMessage(refusedRecipient) +
            (message.length > 0 ? message : defaultUserMessage),
        };
      });
    dispatch(sendMessageStart(messagesToSend));
  };

  const onChangeSelectedManufacturer = (
    checked: boolean,
    manufacturerId: number,
  ) => {
    const newManufacturersSelected = [...manufacturersSelected];
    checked
      ? newManufacturersSelected.push(manufacturerId)
      : newManufacturersSelected.splice(
          newManufacturersSelected.indexOf(manufacturerId),
          1,
        );
    setManufacturersSelected(newManufacturersSelected);
    // disable "send" button if nothing is selected
    newManufacturersSelected.length === 0
      ? setSendDisabled(true)
      : setSendDisabled(false);
  };

  const onMessageChange = (event) => {
    setMessage(event.target.value);
  };

  const onClose = () => {
    dispatch(closeModal());
  };
  if (isLoading) {
    return null;
  }

  return (
    <Modal
      modalStyle="dynamic"
      title={i18n.t('Your request was refused by the supplier')}
      confirmButtonText={i18n.t('Send')}
      onClose={onClose}
      closeButtonText={i18n.t('Cancel')}
      onConfirm={onConfirm}
      confirmDisabled={sendDisabled}
    >
      <div>
        {refusedRecipients.length > 1
          ? i18n.t('Select the recipient to contact:')
          : i18n.t('Your message will be sent to the following recipient:')}
        {refusedRecipients.map((refusedRecipient) => (
          <div
            key={refusedRecipient.organizationId}
            className="InformationRequests__supplierDetails InformationRequests__supplierDetailsSpacing"
          >
            {refusedRecipients.length > 1 ? (
              <Checkbox
                id={
                  'manufacturer-selected-message-' +
                  refusedRecipient.organizationId
                }
                label={renderSupplierInfo(refusedRecipient)}
                checked={manufacturersSelected.includes(
                  refusedRecipient.organizationId,
                )}
                onChange={(checked) =>
                  onChangeSelectedManufacturer(
                    checked,
                    refusedRecipient.organizationId,
                  )
                }
              />
            ) : (
              renderSupplierInfo(refusedRecipient)
            )}
          </div>
        ))}
        <div className="InformationRequests__titleMessage">
          {i18n.t(`Message to the recipient:`)}
        </div>
        <div className="InformationRequests__hintMessage">
          {i18n.t(`Please add any relevant information`)}
        </div>
        <div className="InputField__input InputTextarea InputField">
          <Textarea
            id="request-refused-chat-with-recipients"
            value={message}
            onChange={onMessageChange}
            maxLength={1500}
            rows={5}
          />
        </div>
      </div>
    </Modal>
  );
};

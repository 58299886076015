import PropTypes from 'prop-types';

import './index.scss';

export default function LongestWidthLabel({ labels, index }) {
  return (
    <span className="LongestWidthLabel">
      {labels.map((label) => (
        <span key={label} className="LongestLabelWidth__hidden">
          {label}
        </span>
      ))}
      <span className="LongestLabelWidth__label">{labels[index]}</span>
    </span>
  );
}

LongestWidthLabel.propTypes = {
  labels: PropTypes.array.isRequired,
  index: PropTypes.number.isRequired,
};

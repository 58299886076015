import { List, Map } from 'immutable';
import moment from 'moment';
import PropTypes from 'prop-types';
import { PureComponent } from 'react';
import ImmutablePropTypes from 'react-immutable-proptypes';
import { connect } from 'react-redux';
import { createStructuredSelector } from 'reselect';

import {
  REJECTED_BY_RECIPIENT,
  SSC_IN_PROGRESS,
  SSC_SUCCESS_WARNING,
  SYNCHRONIZATION_ERROR,
  SYNCHRONIZATION_IN_PROGRESS,
  SYNCHRONIZATION_STATUSES,
  SYNCHRONIZATION_STATUSES_CODE,
} from './constants';
import { getStatuses } from './selectors';
import { compareStatusesDates } from './utils';

const sharingUnitIsInMetadata = (status, suid) =>
  status
    .get('metadata', List())
    .filter(
      (e) =>
        e.get('entity_type') === 'SharingUnit' && e.get('entity_id') === suid,
    ).size > 0;

const sharingUnitIsInContextCodes = (status, suid) => {
  const contextCodes = status
    .get('context', Map())
    .get('uuids_to_context_code', Map());
  return (
    contextCodes
      .valueSeq()
      .filter((c) => c.get('sharingUnit_id') === suid)
      .cacheResult().size > 0
  );
};

const getNewestStatus = (statuses, suId, format) => {
  for (const status of statuses
    .filter((s) => s.get('format') === format)
    .sort(compareStatusesDates)) {
    if (
      sharingUnitIsInMetadata(status, suId) ||
      sharingUnitIsInContextCodes(status, suId)
    ) {
      return status;
    }
  }
  return null;
};

export class ExportSynchronizationStatus extends PureComponent {
  static propTypes = {
    sharingUnit: PropTypes.object,
    synchronizationStatuses: ImmutablePropTypes.list,
  };

  getPrioritizedStatus(statusesFromFormat) {
    const { sharingUnit } = this.props;
    const suId = sharingUnit.get('id');
    const statuses = statusesFromFormat.filter((elem) => {
      if (!elem) {
        return undefined;
      }
      const sharingUnitsId = elem
        .get('metadata', List())
        .filter((e) => e.get('entity_type') === 'SharingUnit');
      return (
        sharingUnitsId.find((e) => e.get('entity_id') === suId) ||
        sharingUnitIsInContextCodes(elem, suId)
      );
    });
    if (statuses !== undefined) {
      const error = statuses.find(
        (elem) => elem.get('status') === SYNCHRONIZATION_ERROR.const,
      );
      if (error) {
        return error;
      }
      const rejected = statuses.find(
        (elem) =>
          elem.getIn(['context', 'code']) === REJECTED_BY_RECIPIENT.const,
      );
      if (rejected) {
        return rejected;
      }
      const successWarning = statuses.find((elem) =>
        SSC_SUCCESS_WARNING.includes(elem.getIn(['context', 'code'])),
      );
      if (successWarning) {
        return successWarning;
      }
      const inProgress = statuses.find(
        (elem) => elem.get('status') === SYNCHRONIZATION_IN_PROGRESS.const,
      );
      if (inProgress) {
        return inProgress;
      }
      return statuses.last();
    }
    return undefined;
  }

  getSynchronizationStatus() {
    // Check if rejected in SU in recipient
    // if yes all should be rejected
    const { sharingUnit, synchronizationStatuses } = this.props;
    const recipientId = sharingUnit.getIn(['targetOrganization', 'id']);
    const rejectedOnRecipient = synchronizationStatuses.find(
      (elem) =>
        elem.getIn(['targetOrganization', 'id']) === recipientId &&
        elem.getIn(['context', 'code']) === REJECTED_BY_RECIPIENT.const,
    );
    if (rejectedOnRecipient) {
      return rejectedOnRecipient;
    }

    const gdsnStatus = this.getPrioritizedStatus(
      List([
        getNewestStatus(
          this.props.synchronizationStatuses,
          sharingUnit.get('id'),
          'GDSN',
        ),
      ]),
    );
    const gdsnPriceStatus = this.getPrioritizedStatus(
      List([
        getNewestStatus(
          this.props.synchronizationStatuses,
          sharingUnit.get('id'),
          'GDSN_PRICE',
        ),
      ]),
    );
    // IN-963
    const statuses = List([gdsnStatus, gdsnPriceStatus]);

    return this.getPrioritizedStatus(statuses);
  }

  getStatus() {
    const status = this.getSynchronizationStatus();
    if (
      status &&
      status.getIn(['context', 'code']) &&
      status.getIn(['context', 'code']) in SYNCHRONIZATION_STATUSES_CODE &&
      !SSC_IN_PROGRESS.includes(status.get('status'))
    ) {
      return SYNCHRONIZATION_STATUSES_CODE[status.getIn(['context', 'code'])];
    }
    return status
      ? SYNCHRONIZATION_STATUSES[status.get('status')]
      : SYNCHRONIZATION_IN_PROGRESS;
  }

  render() {
    const { sharingUnit } = this.props;
    if (!sharingUnit) {
      return null;
    }
    const syncStatus = this.getSynchronizationStatus();
    let lastSyncStatusDate = null;
    if (syncStatus && syncStatus.getIn(['context', 'date'])) {
      lastSyncStatusDate = moment(
        `${syncStatus.getIn(['context', 'date'])}Z`,
      ).format('L LT');
    }
    const status = this.getStatus();

    return (
      <div className="SharingUnit__SyncStatus">
        <span className={status.className}>{status.label}</span>
        {lastSyncStatusDate && <span>{` (${lastSyncStatusDate})`}</span>}
      </div>
    );
  }
}

const mapStateToProps = createStructuredSelector({
  synchronizationStatuses: getStatuses,
});

export default connect(mapStateToProps)(ExportSynchronizationStatus);

import { createAction } from 'utils/redux';

import {
  BULK_UNSHARE_SHOW,
  BULK_UNSHARE_START,
  ProductsPayload,
} from './types';

export const showBulkUnshare = createAction<ProductsPayload>(BULK_UNSHARE_SHOW);
export const bulkUnshareStart = createAction(BULK_UNSHARE_START);

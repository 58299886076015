import { memo } from 'react';

import { ConnectedPriceWaterfall } from 'modules/price-waterfalls/components/price-waterfall';

import { CustomHistoryDiffLine } from './custom-history-diff-line';

export const PriceWaterfallHistoryDiffLine = memo((props: any) => (
  <CustomHistoryDiffLine {...props} isFieldNeeded={false}>
    <ConnectedPriceWaterfall
      model="priceWaterfalls-0"
      disabled
      forcePriceDisabled
      taxInformation={props?.taxInformation}
    />
  </CustomHistoryDiffLine>
));

import { backgroundCheckContentTypes } from 'constants/assets';
import { getHasBackgroundWarning } from 'modules/assets/warning-utils';
import i18n from 'utils/i18n';

type BackgroundInfoProps = {
  contentType: number;
  backgroundScore: number;
};

export const BackgroundInfo = ({
  contentType,
  backgroundScore,
}: BackgroundInfoProps) => {
  const hasBackgroundWarning = getHasBackgroundWarning(backgroundScore);
  return backgroundCheckContentTypes.includes(contentType) ? (
    <div className="ProductPictureForm__imageMetadataDetail">
      <div className="ProductPictureForm__imageMetadataTitle">
        {i18n.t('frontproducstream.asset_list.modal.image_background.label', {
          defaultValue: 'Background:',
        })}
      </div>
      <div className="ProductPictureForm__imageMetadataValue">
        {hasBackgroundWarning
          ? i18n.t(
              'frontproducstream.asset_list.modal.background_not_white.error',
              { defaultValue: 'Background neither white nor transparent' },
            )
          : i18n.t('frontproducstream.asset_list.modal.background_white.text', {
              defaultValue: 'Background white or transparent',
            })}
      </div>
      {hasBackgroundWarning ? (
        <div className="ProductPictureForm__imageMetadataSuggestion">
          <i className="mdi mdi-alert" />
          {i18n.t(
            'frontproducstream.asset_list.modal.background_not_white.warning',
            {
              defaultValue:
                'Careful this picture background is neither transparent nor white',
            },
          )}
        </div>
      ) : null}
    </div>
  ) : null;
};

import { func, object } from 'prop-types';
import { PureComponent } from 'react';

import Checkbox from '@alkem/react-ui-checkbox';

import { get, keys, size, values } from 'utils/immutable';

import './index.scss';

export default class ListActionsSelectAll extends PureComponent {
  static propTypes = {
    entityMap: object.isRequired,
    selectedMap: object.isRequired,
    onSelectAll: func.isRequired,
  };

  getSelectionStatus() {
    const selected = this.isSelected();
    let partiallySelected = false;
    if (!selected) {
      partiallySelected = this.isPartiallySelected();
    }
    return { selected, partiallySelected };
  }

  isSelected() {
    const { selectedMap, entityMap } = this.props;
    return (
      size(selectedMap) > 0 &&
      size(selectedMap) >= size(entityMap) &&
      keys(entityMap).every((id) => get(selectedMap, `${id}`))
    );
  }

  isPartiallySelected() {
    const { selectedMap } = this.props;
    return (
      size(selectedMap) > 0 && values(selectedMap).some((selected) => selected)
    );
  }

  render() {
    const { selected, partiallySelected } = this.getSelectionStatus();
    return (
      <div className="ListActionsSelectAll">
        <Checkbox
          id="list-actions-select-all"
          checked={selected}
          partiallyChecked={partiallySelected}
          onChange={this.props.onSelectAll}
        />
      </div>
    );
  }
}

import { getHasResolutionWarning } from 'modules/assets/warning-utils';
import i18n from 'utils/i18n';

type AssetDefinitionProps = {
  definition: string;
  height: number;
  width: number;
};

export const AssetDefinition = ({
  definition,
  height,
  width,
}: AssetDefinitionProps) => (
  <div className="asset definition">
    <div className="ProductPictureForm__imageMetadataTitle">
      {i18n.t('frontproducstream.asset_list.modal.image_definition.label', {
        defaultValue: 'Definition:',
      })}
    </div>
    <div className="ProductPictureForm__imageMetadataValue">
      {definition +
        i18n.t('frontproducstream.asset_list.modal.px_unit.text', {
          defaultValue: 'px',
        })}
    </div>
    {getHasResolutionWarning({ height, width }) ? (
      <div className="ProductPictureForm__imageMetadataSuggestion">
        <i className="mdi mdi-alert" />
        {i18n.t('frontproducstream.asset_list.modal.image_definition.error', {
          defaultValue: 'Careful this picture definition is too small',
        })}
      </div>
    ) : null}
  </div>
);

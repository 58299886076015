import { memo, useCallback, useMemo, useRef } from 'react';
import { useSelector } from 'react-redux';
import {
  Navigate,
  RouteObject,
  RouterProvider,
  createHashRouter,
} from 'react-router-dom';

import { MFE_NAVIGATE_EVENT, useSubscribe } from '@alkem/micro-frontend-tools';

import Notification from 'components/ui/notification';
import RedirectLegacyRoutes from 'legacy/redirects';
import { selectIsAuthorized } from 'modules/user';
import { NavigatePayload } from 'utils/history';

import { App } from './App';
import PrivateRoutes from './Private';
import PublicRoutes from './Public';

type Router = ReturnType<typeof createHashRouter>;

export const AppRouter = memo(() => {
  const isAuthorized = useSelector(selectIsAuthorized);

  /**
   * WARNING: keep the router in this Ref object
   * A ref is used to store the router instance for the navigation event subscription,
   * otherwise the navigation to the landing page does not occur on login success.
   */
  const router = useRef<Router>() as React.MutableRefObject<Router>;

  const publicRoutes = useMemo(
    () =>
      PublicRoutes.map((route) =>
        toRouteData(Object.assign(route, { isPublic: true })),
      ),
    [],
  );

  const privateRoutes = useMemo(
    () =>
      isAuthorized
        ? PrivateRoutes.map(toRouteData)
        : [{ path: '/*', element: null }],
    [isAuthorized],
  );

  const redirectedRoutes = useMemo<RouteObject[]>(
    () =>
      RedirectLegacyRoutes.map(({ from, to, exact }) => ({
        path: exact ? from : `${from}/*`,
        element: <Navigate to={to} replace />,
      })),
    [],
  );

  router.current = useMemo(
    () =>
      createHashRouter([
        {
          path: '/',
          element: <App />,
          children: [...publicRoutes, ...privateRoutes, ...redirectedRoutes],
        },
      ]),
    [privateRoutes, publicRoutes, redirectedRoutes],
  );

  useSubscribe<NavigatePayload>(
    MFE_NAVIGATE_EVENT,
    useCallback(({ to, replace }: NavigatePayload) => {
      router.current.navigate(to, { replace });
    }, []),
  );

  return <RouterProvider router={router.current} />;
});

function toRouteData({
  path,
  component: RouteComponent,
  element,
  exact,
  isPublic,
}: {
  path: string;
  component?: React.ComponentType<any>;
  element?: React.ReactNode;
  exact?: boolean;
  isPublic?: boolean;
}) {
  return {
    path: exact ? path : `${path}/*`,
    element: (
      <>
        {RouteComponent ? <RouteComponent /> : element ? element : null}
        <Notification context={isPublic ? 'public' : 'base'} />
      </>
    ),
  };
}

import { useCallback, useMemo, useState } from 'react';
import { useSelector } from 'react-redux';

import { DownloadPicturesModal } from 'components/download-pictures-modal';
import Dropdown from 'components/ui/dropdown';
import { AssetTypeV2 } from 'components/ui/form/asset-list/AssetV2/types';
import Modal from 'components/ui/modal';
import { FEATURE_PICTURE_DERIVATIVES_DOWNLOAD } from 'modules/feature-flag';
import { selectUserFeature } from 'modules/feature-flag/selectors';
import { selectIsRetailer } from 'modules/user';
import { selectSelectedIds } from 'reducers/media';
import i18n from 'utils/i18n';

import { useDeleteAssets } from './useDeleteAssets';
import { useDownloadPictures } from './useDownloadPictures';

type ActionsDropdownProps = {
  productKeyId: number;
  items: AssetTypeV2[];
  updateEntities: () => void;
};

export const ActionsDropdown = ({
  productKeyId,
  items,
  updateEntities,
}: ActionsDropdownProps) => {
  const [isDownloadDerivativesMediaOpen, setIsDownloadDerivativesMediaOpen] =
    useState(false);
  const [isDeleteModalOpen, setIsDeleteModalOpen] = useState(false);
  const [isProcessing, setIsProcessing] = useState(false);

  const hasPictureDerivativesFeature = useSelector(
    selectUserFeature(FEATURE_PICTURE_DERIVATIVES_DOWNLOAD),
  );

  const isRetailer = useSelector(selectIsRetailer);

  const selectedIds = useSelector(selectSelectedIds);

  const productKeyIds = useMemo(
    () => (productKeyId ? [productKeyId] : []),
    [productKeyId],
  );

  const handleDownloadPictures = useDownloadPictures({ productKeyIds });

  const handleOpenDownloadPictureModal = useCallback(() => {
    setIsDownloadDerivativesMediaOpen(true);
  }, []);

  const handleDownloadPicturesPress = useCallback(() => {
    hasPictureDerivativesFeature
      ? handleOpenDownloadPictureModal()
      : handleDownloadPictures();
  }, [
    handleDownloadPictures,
    handleOpenDownloadPictureModal,
    hasPictureDerivativesFeature,
  ]);

  const handleCloseDeleteModal = useCallback(() => {
    setIsDeleteModalOpen(false);
  }, []);

  const handleDeleteAssets = useDeleteAssets({
    closeDeleteModal: handleCloseDeleteModal,
    updateEntities,
    setIsProcessing,
    items,
  });

  const downloadOption = {
    label: i18n.t(
      'frontproductstream.asset_list.see_and_edit_all_media.actions_dropdown.download_pictures',
      {
        defaultValue: 'Download pictures',
      },
    ),
    onClick: handleDownloadPicturesPress,
  };

  const deleteOption = {
    label: i18n.t(
      'frontproductstream.asset_list.see_and_edit_all_media.actions_dropdown.delete_assets',
      {
        defaultValue: 'Delete assets',
      },
    ),
    onClick: () => setIsDeleteModalOpen(true),
  };

  let options = [downloadOption];

  if (!isRetailer && selectedIds.length > 0) {
    options = [...options, deleteOption];
  }

  const { deleteModalTitle, deleteModalConfirmMessage } = (() => {
    if (selectedIds.length === 1) {
      return {
        deleteModalTitle: i18n.t(
          'frontproductstream.asset_list.button_bar.delete_multiple_assets_modal.one_title',
          { defaultValue: 'Delete 1 asset' },
        ),
        deleteModalConfirmMessage: i18n.t(
          'frontproductstream.asset_list.button_bar.delete_multiple_assets_modal.one_content',
          { defaultValue: 'Are you sure you want to delete this asset ?' },
        ),
      };
    } else {
      return {
        deleteModalTitle: i18n.t(
          'frontproductstream.asset_list.button_bar.delete_multiple_assets_modal.many_title',
          {
            defaultValue: 'Delete {{count}} assets',
            count: selectedIds.length,
          },
        ),
        deleteModalConfirmMessage: i18n.t(
          'frontproductstream.asset_list.button_bar.delete_multiple_assets_modal.many_content',
          {
            defaultValue:
              'Are you sure you want to delete these {{count}} assets ?',
            count: selectedIds.length,
          },
        ),
      };
    }
  })();

  return (
    <>
      <div>
        <Dropdown
          label={
            <span>
              {i18n.t(
                'frontproductstream.asset_list.see_and_edit_all_media.actions_dropdown.label',
                {
                  defaultValue: 'Actions',
                },
              )}
            </span>
          }
          options={options}
        />
      </div>
      {isDownloadDerivativesMediaOpen && (
        <DownloadPicturesModal
          onClose={() => setIsDownloadDerivativesMediaOpen(false)}
          productKeyIds={productKeyIds}
        />
      )}
      {!isRetailer && isDeleteModalOpen && (
        <Modal
          modalStyle="dynamic"
          title={deleteModalTitle}
          confirmButtonText={i18n.t(
            'frontproductstream.asset_list.delete_button.label',
            { defaultValue: 'Delete' },
          )}
          isProcessing={isProcessing}
          onConfirm={handleDeleteAssets}
          onClose={handleCloseDeleteModal}
        >
          {deleteModalConfirmMessage}
        </Modal>
      )}
    </>
  );
};

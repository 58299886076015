import PropTypes from 'prop-types';
import { PureComponent } from 'react';
import { connect } from 'react-redux';

import { SETTING_ENABLE_VALUE } from 'constants/organization-settings';
import { hasFeatureValue } from 'modules/feature-flag';
import { FEATURE_PRODUCT_DOCUMENTS } from 'modules/feature-flag/constants';
import { StringOrNumber } from 'types';

import ActionDocument from './document/action-document';
import './dropzone.scss';
import ActionPicture from './picture/action-picture';
import ActionRecipe from './recipe/action-recipe';
import ActionVideo from './video/action-video';

const mapStateToProps = (state) => ({
  user: state.user,
});

class Actions extends PureComponent {
  static propTypes = {
    user: PropTypes.object.isRequired,
    actions: PropTypes.array.isRequired,
    productKeyId: StringOrNumber.isRequired,
    items: PropTypes.array,
    updateEntities: PropTypes.func,
  };

  addPicture(action) {
    const { productKeyId } = this.props;
    return (
      <ActionPicture
        key={`ActionPicture-${productKeyId}`}
        action={action}
        {...this.props}
      />
    );
  }

  addVideo(action) {
    const { productKeyId } = this.props;
    return (
      <ActionVideo
        key={`ActionVideo-${productKeyId}`}
        action={action}
        {...this.props}
      />
    );
  }

  addRecipe(action) {
    const { productKeyId } = this.props;
    return (
      <ActionRecipe
        key={`ActionRecipe-${productKeyId}`}
        action={action}
        {...this.props}
      />
    );
  }

  addDocument(action) {
    const { user, productKeyId } = this.props;
    if (
      !hasFeatureValue(user, FEATURE_PRODUCT_DOCUMENTS, SETTING_ENABLE_VALUE)
    ) {
      return null;
    }
    return (
      <ActionDocument
        key={`ActionDocument-${productKeyId}`}
        action={action}
        {...this.props}
      />
    );
  }

  render() {
    const { actions } = this.props;
    const renderedActions = actions
      .map((action) => this[action.render](action))
      .filter((x) => !!x);
    if (!renderedActions.length) {
      return null;
    }
    return (
      <div className="col-xs-4">
        <div className="AssetList__actionsContainer AssetList__col">
          <div className="AssetList__actions">{renderedActions}</div>
        </div>
      </div>
    );
  }
}

export default connect(mapStateToProps)(Actions);

import { takeLatest } from 'redux-saga/effects';

import { logError } from 'utils/logging';

import { TARGET_CATALOG_IMPORT_UPLOAD } from '../constants';

import { uploadTargetCatalog } from './upload';

export default function* targetCatalogImportMainSaga() {
  try {
    yield takeLatest(TARGET_CATALOG_IMPORT_UPLOAD, uploadTargetCatalog);
  } catch (e) {
    logError(e);
  }
}

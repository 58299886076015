import fetch from 'isomorphic-fetch';
import JSZip from 'jszip';
import { once, throttle, times } from 'lodash/fp';
import moment from 'moment';
import { ChangeEvent, ReactNode, useEffect, useRef, useState } from 'react';
import { connect } from 'react-redux';
import { createStructuredSelector } from 'reselect';

import { Button } from '@alkem/react-ui-button';
import { Checkbox } from '@alkem/react-ui-checkbox';
import { Radio } from '@alkem/react-ui-inputs';
import { ProgressBar } from '@alkem/react-ui-progress';
import { Spinner } from '@alkem/react-ui-spinner';
import { Referential, ResponseWithData } from '@alkem/sdk-dashboard';

import { downloadPictures } from 'actions/media';
import Modal from 'components/ui/modal';
import { getOrganizationSettingByKey } from 'core/api/user';
import { hasFeature } from 'modules/feature-flag';
import { selectUser } from 'modules/user';
import mediaApi from 'resources/mediaApi';
import { regularReferentialApi } from 'resources/referentialApi';
import { UserImmutable } from 'types';
import { GlobalState } from 'types/redux';
import { saveAs } from 'utils';
import i18n from 'utils/i18n';
import { logError } from 'utils/logging';
import { separateActions } from 'utils/redux';
import { track } from 'utils/tracking';

import { PictureDerivative } from './derivative';
import './index.scss';

interface ConnectedProps {
  user: UserImmutable;
}

interface OwnProps {
  onClose: () => void;
  productKeyIds: number[];
}

interface Actions {
  downloadPictures: typeof downloadPictures;
}

type Props = ConnectedProps & OwnProps & { actions: Actions };

interface Derivative
  extends Referential<{
    common: boolean;
    height: number;
    path_name: string;
    width: number;
  }> {
  id: number;
}

interface DerivativeSelection {
  [derivativeId: number]: boolean;
}

interface Picture {
  uri: string;
  filename: string;
}

const mapState = createStructuredSelector<GlobalState, ConnectedProps>({
  user: selectUser,
});

const mapDispatch: Actions = {
  downloadPictures,
};

export const DownloadPicturesModal = connect<
  ConnectedProps,
  Actions,
  OwnProps,
  Props,
  GlobalState
>(
  mapState,
  mapDispatch,
  separateActions,
)(({ onClose, actions, productKeyIds, user }: Props) => {
  const isMounted = useRef(true);
  const packshotOnlyOptions = useRef([
    {
      label: i18n.t(
        'frontproductstream.download_pictures.download_all.option',
        { defaultValue: 'Download all pictures' },
      ),
      value: false,
    },
    {
      label: i18n.t(
        'frontproductstream.download_pictures.download_packshot.option',
        { defaultValue: 'Download packshot pictures only' },
      ),
      value: true,
    },
  ]);
  const [packshotOnly, setPackshotOnly] = useState(false);
  const [withOrignal, setWithOriginal] = useState(false);
  const [isDownloading, setDownloading] = useState(false);
  const [derivatives, setDerivatives] = useState<Derivative[]>([]);
  const [isFetching, setFetching] = useState(false);
  const [derivativeSelection, setDerivativeSelection] =
    useState<DerivativeSelection>({});
  const [downloadProgress, setDownloadProgress] = useState<{
    downloadedPictures?: number;
    picturesTotal?: number;
    zipPercentage?: number;
  }>({});

  const selectAllDerivatives = (isSelected: boolean) => {
    setDerivativeSelection(
      derivatives.reduce(
        (acc, derivative) => ({ ...acc, [derivative.id]: isSelected }),
        {},
      ),
    );
  };

  const onDownload = async () => {
    const limit = 20;
    const total = productKeyIds.length;
    const expectedArchives = Math.floor((total - 1) / limit + 1);

    setDownloading(true);

    const selectedDerivatives = Object.entries(derivativeSelection)
      .filter(([, isSelected]) => isSelected)
      .map(([derivativeId]) => Number(derivativeId));

    const onDone = (withClose?: boolean) => {
      if (!isMounted.current) {
        return;
      }

      track({
        category: 'product',
        action: 'product_pictures_exported',
        label: `productKeyIds=${productKeyIds.join()}&packshotOnly=${packshotOnly}&derivatives=${selectedDerivatives.join()}`,
      });
      setDownloading(false);
      if (withClose) {
        onClose();
      }
    };

    if (hasFeature(user, 'download-and-zip-pictures')) {
      try {
        const timings: {
          urls: number[];
          download: number[];
          zip: number[];
        } = { urls: [], download: [], zip: [] };
        const addTiming = (key: 'urls' | 'download' | 'zip') => {
          timings[key].push(Date.now());
        };
        setDownloadProgress({});

        addTiming('urls');
        const response: ResponseWithData<Picture[]> = await mediaApi.post(
          '/media/v3/product/pictures/download/urls',
          {
            product_key_ids: productKeyIds,
            packshot_only: packshotOnly,
            with_original: withOrignal,
            derivatives: selectedDerivatives,
            offset: 0,
            limit: productKeyIds.length,
          },
        );
        addTiming('urls');

        if (!isMounted.current) {
          return;
        }

        const pictures = response.data.data;
        const picturesTotal = pictures.length;
        const zip = new JSZip();
        const downloadThreads = 5;
        let downloadedPictures = 0;
        let zipPercentage: number;

        const updateProgress = throttle(500, () => {
          if (!isMounted.current) {
            return;
          }
          setDownloadProgress({
            picturesTotal,
            downloadedPictures,
            zipPercentage,
          });
        });

        updateProgress();

        const urlToPromise = (url: string) =>
          fetch(url, { cache: 'no-cache' }).then((resp: Response) =>
            resp.arrayBuffer(),
          );

        const fetchImage = async (picture: Picture) => {
          try {
            const url = picture.uri;
            const picturePromise = urlToPromise(url);
            await picturePromise;
            zip.file(picture.filename, picturePromise, { binary: true });
          } catch (err) {
            logError('failed to fetch picture', picture);
            logError(err);
          }
          downloadedPictures += 1;
          if (downloadedPictures === picturesTotal) {
            addTiming('download');
            updateProgress.cancel();
          }
          updateProgress();
        };

        const done = once(async () => {
          zipPercentage = 0;
          addTiming('zip');
          const zipBlob = await zip.generateAsync(
            {
              type: 'blob',
              compression: 'STORE',
              streamFiles: true,
            },
            (metadata: any) => {
              if (zipPercentage < 100) {
                zipPercentage = Math.floor(metadata.percent);
                if (zipPercentage === 100) {
                  updateProgress.cancel();
                }
                updateProgress();
              }
            },
          );
          addTiming('zip');
          saveAs(zipBlob, `${moment().format('YYYY-MM-DD')}.zip`);
          onDone();
          const trackedData = Object.entries(timings).reduce(
            (acc, [key, [start, end]]) => {
              const duration = moment.duration(end - start).asSeconds();
              return {
                total: acc.total + duration,
                label: `${acc.label}${key}=${duration}s,`,
              };
            },
            { total: 0, label: '' },
          );
          track({
            category: 'product',
            action: 'product_pictures_zip',
            label: `pictures=${picturesTotal},${trackedData.label},total=${trackedData.total}s`,
          });
        });

        const next = async () => {
          if (!isMounted.current) {
            return;
          }
          const picture = pictures.pop();
          if (picture) {
            await fetchImage(picture);
            setTimeout(next, 1);
          } else if (downloadedPictures >= picturesTotal) {
            done();
          }
        };

        addTiming('download');
        times(next, downloadThreads);
      } catch (error) {
        logError(error);
        if (!isMounted.current) {
          return;
        }
        setDownloading(false);
      }
    } else {
      await actions.downloadPictures({
        productKeyIds,
        limit,
        expectedArchives,
        packshotOnly,
        withOrignal,
        derivatives: selectedDerivatives,
      });
      onDone(true);
    }
  };

  const onSetPackshotOnly = (event: ChangeEvent<HTMLInputElement>) => {
    setPackshotOnly(event.target.value === 'true');
  };

  const onSetWithOriginal = (isChecked: boolean) => {
    setWithOriginal(isChecked);
  };

  const onChangeDerivative = (derivativeId: number, checked: boolean) => {
    setDerivativeSelection((selection) => ({
      ...selection,
      [derivativeId]: checked,
    }));
  };

  const onSelectAllDerivatives = () => {
    selectAllDerivatives(true);
  };

  const onUnselectAllDerivatives = () => {
    selectAllDerivatives(false);
  };

  useEffect(
    () => {
      const fetchDerivatives = async () => {
        try {
          setFetching(true);
          const response: ResponseWithData<Derivative[]> =
            await regularReferentialApi.ReferentialGetList(
              'picturederivativeconfigurations',
              {},
            );
          if (isMounted.current) {
            const allDerivatives = response.data.data;
            const derivativeIds: number[] =
              getOrganizationSettingByKey(user, 'picturederivatives') || [];
            const filteredDerivatives = allDerivatives.filter(
              (derivative) =>
                derivative.data.common || derivativeIds.includes(derivative.id),
            );
            setDerivatives(filteredDerivatives);
            selectAllDerivatives(false);
          }
        } catch (error) {
          logError(error);
        } finally {
          if (isMounted.current) {
            setFetching(false);
          }
        }
      };
      fetchDerivatives();
      return () => {
        isMounted.current = false;
      };
    },
    [], // eslint-disable-line react-hooks/exhaustive-deps
  );

  let progressBar: ReactNode = null;

  if (typeof downloadProgress.zipPercentage === 'number') {
    progressBar = (
      <div key="zip" className="DownloadPicturesModal__progress">
        <div>
          {i18n.t(
            'frontproductstream.download_pictures.building_pictures_archive.label',
            { defaultValue: 'Building pictures archive' },
          )}
        </div>
        <ProgressBar percentage={downloadProgress.zipPercentage} color="info" />
      </div>
    );
  } else if (typeof downloadProgress.downloadedPictures === 'number') {
    progressBar = (
      <div key="download" className="DownloadPicturesModal__progress">
        <div>
          {i18n.t(
            'frontproductstream.download_pictures.downloading_pictures.label',
            { defaultValue: 'Downloading pictures' },
          )}
        </div>
        <ProgressBar
          value={downloadProgress.downloadedPictures}
          max={downloadProgress.picturesTotal}
          color="info"
        />
      </div>
    );
  }

  return (
    <Modal
      modalStyle="dynamic"
      title={i18n.t('frontproductstream.download_pictures.modal.title', {
        count: productKeyIds.length,
        defaultValue: 'Download shared images {{count}} sheet(s)',
      })}
      className="DownloadPicturesAction__modal"
      confirmButtonText={
        isDownloading
          ? i18n.t('frontproductstream.download_pictures.downloading.label', {
              defaultValue: 'Downloading',
            })
          : i18n.t('frontproductstream.download_pictures.download.button', {
              defaultValue: 'Download',
            })
      }
      isProcessing={isDownloading}
      onConfirm={onDownload}
      onClose={onClose}
      additionalFooterContent={progressBar}
    >
      <Radio
        id="download-pictures-modal-packshot-only"
        value={packshotOnly}
        onChange={onSetPackshotOnly as (event: ChangeEvent) => void}
        options={packshotOnlyOptions.current}
        vertical
      />
      {isFetching ? (
        <div className="DownloadPicturesModal__derivativeLabel alk-flex alk-flex-center">
          <span>
            <Spinner small />
            &nbsp;
            {i18n.t(
              'frontproductstream.download_pictures.fetching_derivative_format.label',
              { defaultValue: 'Fetching picture derivative format' },
            )}
          </span>
        </div>
      ) : (
        <>
          <div className="DownloadPicturesModal__derivativeLabel alk-flex alk-flex-center">
            <span>
              {i18n.t(
                'frontproductstream.download_pictures.derivative_formats.label',
                { defaultValue: 'Picture derivative formats:' },
              )}
            </span>
            <span>
              <Button link onClick={onSelectAllDerivatives}>
                {i18n.t(
                  'frontproductstream.download_pictures.select_all_derivatives.button',
                  { defaultValue: 'Select all' },
                )}
              </Button>
              <span> / </span>
              <Button link onClick={onUnselectAllDerivatives}>
                {i18n.t(
                  'frontproductstream.download_pictures.unselect_all_derivatives.button',
                  { defaultValue: 'Unselect all' },
                )}
              </Button>
            </span>
          </div>
          <div className="DownloadPicturesModal__derivatives">
            <Checkbox
              id="picture-derivative-original"
              label={i18n.t(
                'frontproductstream.download_pictures.original_picture.checkbox',
                { defaultValue: 'Original' },
              )}
              checked={withOrignal}
              onChange={onSetWithOriginal}
            />
            {derivatives.map((derivative) => (
              <PictureDerivative
                key={derivative.id}
                derivative={derivative}
                checked={derivativeSelection[derivative.id]}
                onChange={onChangeDerivative}
              />
            ))}
          </div>
        </>
      )}
    </Modal>
  );
});

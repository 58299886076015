import { createRoot } from 'react-dom/client';
import { ErrorBoundary } from 'react-error-boundary';
import { Provider } from 'react-redux';

// NOTE imports order is important here, please keep it as is
import 'setup';
import { store } from 'store';
import 'styles/app.scss';

import { AppRouter } from 'containers/AppRouter';
import { ErrorFallback } from 'core/components/error/fallback';
import { ConsentManagerBuilder } from 'modules/consent-manager';
import { initStatusPageIO } from 'utils/statuspage.io';
import { initDataDogRUM } from 'utils/tracking/datadog';

initDataDogRUM();
initStatusPageIO();

const container = document.getElementById('app');
const root = createRoot(container!);

root.render(
  <Provider store={store}>
    <ErrorBoundary
      FallbackComponent={({ error }) => (
        <ErrorFallback error={error} origin="root" withLogo />
      )}
    >
      <ConsentManagerBuilder>
        <AppRouter />
      </ConsentManagerBuilder>
    </ErrorBoundary>
  </Provider>,
);

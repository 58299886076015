import { fork } from 'redux-saga/effects';

import * as displayGroups from './display-groups';
import * as sharingUnits from './sharing-units';
import * as validation from './validation';

export default function* sharingUnitsSaga() {
  yield fork(sharingUnits.watchCreate);
  yield fork(sharingUnits.watchPolling);
  yield fork(sharingUnits.watchPollingCancel);
  yield fork(sharingUnits.watchRemove);
  yield fork(sharingUnits.watchLoadLastExportFile);
  yield fork(sharingUnits.watchHierarchyGTINUpdates);
  yield fork(displayGroups.watchFetch);
  yield fork(validation.watchCreationOrDeletion);
}

import * as routes from 'routes';

export default [
  /* Auth */
  { from: '/account/activate', to: routes.validateAccount, exact: true },
  { from: '/account/password_reset', to: routes.createPassword, exact: true },

  /* Catalog */
  { from: '/retailer/products', to: routes.catalog, exact: true },
  { from: '/retailer/consumerunits', to: routes.catalog, exact: true },
  { from: '/maker/products', to: routes.catalog, exact: true },

  /* Reporting */
  { from: '/maker/reporting', to: routes.reporting, exact: true },
  { from: '/retailer/reporting', to: routes.reporting, exact: true },

  /* Users */
  {
    from: '/organization/:organizationId/users/:userId',
    to: routes.userProfile,
  },
  {
    from: '/organization/:organizationId/users',
    to: routes.userList,
  },

  /* Retailer consumer unit pages */
  {
    from: '/retailer/consumerunit/:reference',
    to: routes.productPageLegacy,
    exact: true,
  },
  {
    from: '/retailer/consumerunit/:reference/general',
    to: routes.productPageLegacy,
    exact: true,
  },
  {
    from: '/retailer/consumerunit/:reference/dashboard',
    to: routes.productDashboardLegacy,
    exact: true,
  },
  {
    from: '/retailer/consumerunit/:reference/history',
    to: routes.productHistoryLegacy,
    exact: true,
  },

  /* Retailer product pages */
  {
    from: '/retailer/product/:reference/general',
    to: routes.productPageLegacy,
    exact: true,
  },
  {
    from: '/retailer/product/:reference/dashboard',
    to: routes.productDashboardLegacy,
    exact: true,
  },
  {
    from: '/retailer/product/:reference/history',
    to: routes.productHistoryLegacy,
    exact: true,
  },

  /* Manufacturer product pages */
  {
    from: '/maker/product/:reference',
    to: routes.productPageLegacy,
    exact: true,
  },
  {
    from: '/maker/product/:reference/general',
    to: routes.productPageLegacy,
    exact: true,
  },
  {
    from: '/maker/product/:reference/dashboard',
    to: routes.productDashboardLegacy,
    exact: true,
  },
  {
    from: '/maker/product/:reference/history',
    to: routes.productHistoryLegacy,
    exact: true,
  },

  { from: '/maker/activity', to: routes.landing },

  { from: '/permissions', to: routes.organization },
  { from: '/permissions/roles', to: routes.organizationRoles },
  { from: '/permissions/scopes', to: routes.organizationScopes },
  { from: '/permissions/users', to: routes.userList },
];

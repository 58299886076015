import { hasSupplierDirectory } from 'core/api/organization-settings';
import { getOrganizationSettings, isRetailer } from 'core/api/user';
import { UserImmutable } from 'types';
import i18n from 'utils/i18n';

export const suppliersFilter = {
  key: 'suppliers.internalIDWithName',
  aggregation: {},
  filterLabel: i18n.t('frontproductstream.catalog.filter_suppliers.label', {
    defaultValue: 'Suppliers',
  }),
  query: 'supplier',
  filterSearchPlaceHolder: i18n.t(
    'frontproductstream.core.list_filter_suppliers.search_placeholder',
    { defaultValue: 'Search for suppliers' },
  ),
  isAvailable: ({ user }: { user?: UserImmutable } = {}) =>
    user != null &&
    isRetailer(user) &&
    hasSupplierDirectory(getOrganizationSettings(user)),
  missingFilter: {
    key: '0',
    label: i18n.t(
      'frontproductstream.catalog.filter_suppliers.missing_key_label',
      { defaultValue: 'No supplier matched' },
    ),
  },
};

import { createSelector } from '@reduxjs/toolkit';
import { compose } from 'redux';

import { MODULE_REDUCER_KEY } from 'constants/reducer';
import { hasFeatureInformationRequestList } from 'modules/information-requests/utils';
import { hasFeatureProductReview } from 'modules/product-review/utils';
import {
  selectIsManufacturer,
  selectIsPrivateLabel,
  selectUser,
} from 'modules/user';

import { REDUCER_KEY } from './constants';

export const selectModuleState = (state) =>
  state[MODULE_REDUCER_KEY][REDUCER_KEY];

export const selectCountDict = compose(
  (state) => state.get('count'),
  selectModuleState,
);

export const selectSearch = compose(
  (state) => state.get('search'),
  selectModuleState,
);

export const selectRoute = compose(
  (state) => state.get('route') as string,
  selectModuleState,
);

export const selectUseCatalogWithMenu = createSelector(
  selectUser,
  selectIsManufacturer,
  selectIsPrivateLabel,
  (user, isManufacturer, isPrivateLabel) =>
    isManufacturer ||
    isPrivateLabel ||
    hasFeatureProductReview(user) ||
    hasFeatureInformationRequestList(user),
);

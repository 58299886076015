import { takeLatest } from 'redux-saga/effects';

import { logError } from 'utils/logging';

import { FETCH_REPORTS } from '../actions/types';

import fetchReportsaga from './referentials/reports';

export default function* reportingWrapperMainSaga() {
  try {
    yield takeLatest(FETCH_REPORTS, fetchReportsaga);
  } catch (err) {
    logError(err);
  }
}

import { List } from 'immutable';
import React, { useCallback, useState } from 'react';

import { AnchoredSection } from 'modules/anchored';
import { SelectMultiple } from 'modules/view-as/components/select-multiple';
import i18n from 'utils/i18n';

import './add-recipient.scss';

type Props = {
  onSelect?: (e: any) => void;
  primaryRecipients: List<any>;
  secondaryRecipients: List<any>;
};

const AddRecipientSpecificInformation = React.memo(
  ({ onSelect, primaryRecipients, secondaryRecipients }: Props) => {
    const [isOpen, setOpen] = useState(false);

    const onSelectRecipient = useCallback(
      (e) => {
        onSelect?.(e);
        setOpen(false);
      },
      [onSelect],
    );

    if (primaryRecipients.size === 0 && secondaryRecipients.size === 0) {
      return null;
    }

    const id = 'select-retailer-to-add-specific-information';

    const buttonText = i18n.t(
      'frontproductstream.recipient_specific_block.recipient_selection.button',
      { defaultValue: 'Add specific information for a recipient' },
    );

    return (
      <AnchoredSection section={buttonText} order={Infinity}>
        <div id={id} className="AddRecipientButton row">
          <div className="ButtonText col-xs-6" onClick={() => setOpen(true)}>
            <i className="mdi mdi-plus-circle" />
            {buttonText}
          </div>
          <div className="Dropdown col-xs-6">
            <SelectMultiple
              id={`${id}--SelectMultiple`}
              options={primaryRecipients}
              secondaryOptions={secondaryRecipients}
              placeholder={i18n.t(
                'frontproductstream.recipient_specific_block.recipient_selection_dropdown.placeholder',
                { defaultValue: 'Select a retailer' },
              )}
              pluralLabel={i18n.t(
                'frontproductstream.recipient_specific_block.recipients_selection.text',
                { defaultValue: 'recipients' },
              )}
              headers={{
                primary: i18n.t(
                  'frontproductstream.recipient_specific_block.recipient_selection_product_visible.text',
                  { defaultValue: 'Product visible by recipient' },
                ),
                secondary: i18n.t(
                  'frontproductstream.recipient_specific_block.additional_recipients.text',
                  { defaultValue: 'Additional recipients' },
                ),
                secondaryHidden: i18n.t(
                  'frontproductstream.recipient_specific_block.view_additional_recipients.text',
                  { defaultValue: 'View additional recipients' },
                ),
                secondaryVisible: i18n.t(
                  'frontproductstream.recipient_specific_block.hide_additional_recipients.text',
                  { defaultValue: 'Hide additional recipients' },
                ),
              }}
              multiple={false}
              isOpen={isOpen}
              setOpen={setOpen}
              onSelect={onSelectRecipient}
            />
          </div>
        </div>
      </AnchoredSection>
    );
  },
);

export default AddRecipientSpecificInformation;

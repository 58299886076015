import { registerModuleReducer } from 'utils/modules/reducer';
import { registerSaga } from 'utils/modules/saga';

import { MODULE_NAME } from './constants';
import reducer from './reducer';
import mainSaga from './sagas';
import Textile from './textile';

registerModuleReducer(MODULE_NAME, reducer);
registerSaga(mainSaga);

export default Textile;

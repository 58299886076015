import { useCallback, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { Radio, Text, Textarea } from '@alkem/react-ui-inputs';
import { Spinner } from '@alkem/react-ui-spinner';
import { Tipster } from '@alkem/react-ui-tipster';

import Modal from 'components/ui/modal';
import { getLegalOrganizationName } from 'core/api/user';
import { selectUser } from 'modules/user';
import i18n from 'utils/i18n';

import { initializeAssignation, processAssignationRefusal } from '../actions';
import { ACTION_DISCARD, ACTION_NOT_COMERCIALIZED } from '../constants';
import {
  selectAssignationReference,
  selectLoading,
  selectProcessing,
} from '../selectors';

import './index.scss';

type Props = {
  context: any;
  withoutNotification: boolean;
  onClose: () => void;
};

export const AssignationRefuseModal = ({
  context,
  withoutNotification,
  onClose,
}: Props) => {
  const dispatch = useDispatch();

  const user = useSelector(selectUser);
  const isLoading = useSelector(selectLoading);
  const isProcessing = useSelector<boolean>(selectProcessing);
  const reference = useSelector(selectAssignationReference);

  const [action, setAction] = useState('');
  const [refusalMessage, setRefusalMessage] = useState('');
  const [gtinOfReplacement, setGtinOfReplacement] = useState('');

  useEffect(() => {
    dispatch(initializeAssignation({ context, onClose }));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const getOptions = useCallback(() => {
    const notBelong = {
      label: i18n.t(
        'frontproductstream.assignation_refuse_modal.does_not_belong_to_org.label',
        {
          defaultValue: 'This product does not belong to {{organization}}',
          organization: getLegalOrganizationName(user),
        },
      ),
      value: ACTION_DISCARD,
    };
    const notSold = {
      label: i18n.t(
        'frontproductstream.assignation_refuse_modal.not_sold_anymore.label',
        {
          defaultValue: 'This product is not sold anymore',
        },
      ),
      value: ACTION_NOT_COMERCIALIZED,
    };

    return [notBelong, notSold];
  }, [user]);

  /* Sub fields updates */

  const refusalMessageChange = (event) => {
    setRefusalMessage(event.target.value);
  };

  const gtinOfReplacementChange = (event) => {
    setGtinOfReplacement(event.target.value);
  };

  /* Main radio update */

  const changeRadio = (event) => {
    setAction(event.target.value);
  };

  /* Refuse trigger */

  const processRefusal = () => {
    dispatch(
      processAssignationRefusal({
        action,
        withoutNotification,
        refusalMessage,
        gtinOfReplacement,
        onClose,
      }),
    );
  };

  /* Renderers */

  const renderSubFields = () => {
    if (action === ACTION_DISCARD) {
      return (
        <div className="NotificationRowModal__value arrow_box row">
          <label
            htmlFor="refusalMessage"
            className="InputField__label col-xs-3"
          >
            {i18n.t('Message')}
          </label>
          <div className="InputField__input InputTextarea InputField col-xs-9">
            <Textarea
              id="refusalMessage"
              placeholder={i18n.t(
                'frontproductstream.assignation_refuse_modal.refusal_message.placeholder',
                {
                  defaultValue: 'Add a message (optional)',
                },
              )}
              value={refusalMessage}
              onChange={refusalMessageChange}
            />
          </div>
        </div>
      );
    }
    if (action === ACTION_NOT_COMERCIALIZED) {
      return (
        <div className="NotificationRowModal__value arrow_box">
          <div>
            <Tipster type="warning">
              <div>
                <span className="Notification__label--strong">
                  {i18n.t(
                    'frontproductstream.assignation_refuse_modal.created_and_archived.text',
                    {
                      defaultValue:
                        'This product will be created and archived in your catalog.',
                    },
                  )}
                </span>{' '}
                {i18n.t(
                  'frontproductstream.assignation_refuse_modal.archived_retailer_info.text',
                  {
                    defaultValue:
                      'The retailer will be informed that this product has been discontinued and will be able to update its product range accordingly.',
                  },
                )}
              </div>
            </Tipster>
            <div>
              <label
                htmlFor="notCommercializedAnymore"
                className="InputField__label col-xs-3"
              >
                {i18n.t(
                  'frontproductstream.assignation_refuse_modal.gtin_replacing_product.text',
                  {
                    defaultValue: 'GTIN of the replacing product',
                  },
                )}
              </label>
              <div className="InputField__input InputTextarea InputField col-xs-9">
                <Text
                  id="notCommercializedAnymore"
                  type="number"
                  maxLength={14}
                  placeholder={i18n.t(
                    'frontproductstream.assignation_refuse_modal.gtin_replacing_examples.placeholder',
                    {
                      defaultValue:
                        '31234567894561 or 031234567894561 (optional)',
                    },
                  )}
                  value={gtinOfReplacement}
                  onChange={gtinOfReplacementChange}
                />
              </div>
            </div>
          </div>
        </div>
      );
    }
    return null;
  };

  const renderBody = () => {
    return (
      <div>
        {isLoading ? (
          <div className="AssignationRefuseModal__Loader">
            <Spinner big />
          </div>
        ) : (
          <>
            <Radio
              id="radio"
              label={i18n.t(
                'frontproductstream.assignation_refuse_modal.reason_of_refusal.label',
                {
                  defaultValue:
                    'Why are you declining the assignation of: {{reference}}?',
                  reference,
                },
              )}
              value={action}
              onChange={changeRadio}
              options={getOptions()}
              vertical
            />
            {renderSubFields()}
          </>
        )}
      </div>
    );
  };

  return (
    <Modal
      title={i18n.t(
        'frontproductstream.assignation_refuse_modal.reject.title',
        {
          defaultValue: 'Reject attribution',
        },
      )}
      confirmButtonText={i18n.t(
        'frontproductstream.assignation_refuse_modal.reject.button',
        {
          defaultValue: 'Reject assignation',
        },
      )}
      confirmDisabled={!action}
      isProcessing={isProcessing as boolean}
      onConfirm={processRefusal}
      onClose={onClose}
    >
      {renderBody()}
    </Modal>
  );
};

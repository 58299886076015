export const ADD_PRODUCT_VERSION_TAG = 'ADD_PRODUCT_VERSION_TAG';
export const DELETE_PRODUCT_VERSION = 'DELETE_PRODUCT_VERSION';
export const PRODUCT_VERSION_RESET = 'PRODUCT_VERSION_RESET';
export const RECEIVE_PRODUCT_VERSION = 'RECEIVE_PRODUCT_VERSION';
export const RECEIVE_PRODUCT_SHARES = 'RECEIVE_PRODUCT_SHARES';
export const RECEIVE_PRODUCT_VERSION_PERMISSIONS =
  'RECEIVE_PRODUCT_VERSION_PERMISSIONS';
export const RECEIVE_PRODUCT_KEY = 'RECEIVE_PRODUCT_KEY';
export const REMOVE_PRODUCT_VERSION_TAG = 'REMOVE_PRODUCT_VERSION_TAG';
export const SET_CONTENT_OWNERS = 'SET_CONTENT_OWNERS';
export const SET_KIND_PARENTS = 'SET_KIND_PARENTS';
export const UPDATE_CURRENT_LANGUAGE = 'UPDATE_CURRENT_LANGUAGE';

export const BULK_SEED_DATA = 'productVersion/BULK_SEED_DATA';
export const READY_FOR_VALIDATION = 'productVersion/READY_FOR_VALIDATION';
export const RECEIVE_PRODUCT_DATA = 'productVersion/receive-data';

export const RECEIVE_GROUP_SCOPES = 'productVersion/RECEIVE_GROUP_SCOPES';

import CancelablePromise from 'cancelable-promise';
import { isFunction } from 'lodash';

import displayGroupApi from 'resources/displayGroupApi';
import { saveProduct } from 'resources/serviceProductApi';
import { isPromiseCanceled } from 'utils';
import { processSaveErrors } from 'utils/actions/saveErrors';
import i18n from 'utils/i18n';
import { createAction } from 'utils/redux';
import { track } from 'utils/tracking';

import {
  CLOSE_PRODUCT_VERSION_BULK_EDIT,
  OPEN_PRODUCT_VERSION_BULK_EDIT,
  PRODUCT_VERSION_BULK_EDIT_CANCEL_SAVE,
  PRODUCT_VERSION_BULK_EDIT_COMPLETE,
  PRODUCT_VERSION_BULK_EDIT_DELETE_DISPLAY_GROUP,
  PRODUCT_VERSION_BULK_EDIT_DISPLAY_GROUP_GET,
  PRODUCT_VERSION_BULK_EDIT_INIT,
  PRODUCT_VERSION_BULK_EDIT_PROGRESS,
  PRODUCT_VERSION_BULK_EDIT_SAVE,
  PRODUCT_VERSION_BULK_EDIT_SELECT_DISPLAY_GROUP,
  RESET_PRODUCT_VERSION_BULK_EDIT,
} from '../constants/events';
import { selectEntity } from '../selectors';

let displayGroupPromise;
// Do not use, exported for testing purposes only.
export let savePromise; // eslint-disable-line

export const init = () => ({
  type: PRODUCT_VERSION_BULK_EDIT_INIT,
});

export const selectDisplayGroup = (displayGroup) => ({
  type: PRODUCT_VERSION_BULK_EDIT_SELECT_DISPLAY_GROUP,
  displayGroup,
});

export const deleteDisplayGroup = (displayGroupId) => ({
  type: PRODUCT_VERSION_BULK_EDIT_DELETE_DISPLAY_GROUP,
  displayGroupId,
});

export const displayGroupGet = () => (dispatch) => {
  if (!!displayGroupPromise && isFunction(displayGroupPromise.cancel)) {
    displayGroupPromise.cancel();
  }

  displayGroupPromise = displayGroupApi.DisplayGroupBulkEdit();
  displayGroupPromise.then((response) => {
    dispatch({
      type: PRODUCT_VERSION_BULK_EDIT_DISPLAY_GROUP_GET,
      data: response.data.data,
    });
  });
};

export const parseErrors = (errors) => {
  const processedFields = [];
  let notDisplayedErrors = 0;
  const processedErrors = errors
    .map((e) => {
      const field = e.path.split('.')[0];
      // Do not display the same warning twice.
      if (processedFields.includes(field)) {
        return null;
      }
      // Limit at 20 errors.
      if (processedFields.length === 20) {
        notDisplayedErrors += 1;
        return null;
      }
      processedFields.push(field);
      return {
        field,
        error:
          field === 'lifeCycle'
            ? i18n.t(
                'frontproductstream.product_bulk_edit.field_errors.lifecycle_forbidden_transition_error',
                { defaultValue: 'Forbidden transition' },
              )
            : i18n.t(
                'frontproductstream.product_bulk_edit.field_errors.lifecycle_invalid_value_error',
                { defaultValue: 'Information is invalid' },
              ),
      };
    })
    .filter((e) => !!e);
  if (notDisplayedErrors) {
    processedErrors.push({
      error: i18n.t(
        'frontproductstream.product_bulk_edit.field_errors.count_not_displayed_errors_label',
        {
          defaultValue: '+ {{count}} errors not displayed',
          count: notDisplayedErrors,
        },
      ),
    });
  }
  return processedErrors;
};

// Do not use, exported for testing purposes only.
export const saveOne =
  (productVersion, callback) => (dispatch, getState) => async () => {
    /*
  We need to wrap these in this way to:
    - avoid having them launching at the same time
    - always return a success to continue the process
  */
    const payload = {
      product: {
        key: productVersion.get('product_key'),
        data: { productVersion: { data: selectEntity(getState()) } },
      },
      patch: true,
      deepPatchAttributes: ['contact', 'contactInformationList'],
    };
    const { error } = await saveProduct(payload, true);
    try {
      if (error) {
        throw error;
      }
      dispatch({ type: PRODUCT_VERSION_BULK_EDIT_PROGRESS });
    } catch (e) {
      let errors = [];
      if (e.status === 403) {
        errors = [
          i18n.t(
            'frontproductstream.product_bulk_edit.action_save_error.permission_error_message',
            {
              defaultValue: 'You do not have the rights to edit this product.',
            },
          ),
        ];
      } else if (e.status === 400) {
        if (e.data && e.data.errors) {
          errors = parseErrors(processSaveErrors(e));
        } else {
          errors = [
            i18n.t(
              'frontproductstream.product_bulk_edit.action_save_error.known_generic_error_message',
              {
                defaultValue: 'There is a problem with the given information.',
              },
            ),
          ];
        }
      } else {
        errors = [
          i18n.t(
            'frontproductstream.product_bulk_edit.action_save_error.default_error_message',
            { defaultValue: 'Something went wrong while saving the product.' },
          ),
        ];
      }
      dispatch({
        type: PRODUCT_VERSION_BULK_EDIT_PROGRESS,
        error: {
          productVersion,
          errors,
        },
      });
    } finally {
      // Do not execute the next callback if being canceled.
      if (
        (!savePromise || !isPromiseCanceled(savePromise)) &&
        isFunction(callback)
      ) {
        await callback();
      }
    }
  };

export const save = (productVersions) => (dispatch) => {
  if (!!savePromise && isFunction(savePromise.cancel)) {
    savePromise.cancel();
  }

  if (!productVersions || !productVersions.size) {
    return undefined;
  }

  dispatch({
    type: PRODUCT_VERSION_BULK_EDIT_SAVE,
    count: productVersions.size,
  });

  // Iterate on each product version.
  const productVersionIds = productVersions.map((pv) => pv.get('id')).join();
  savePromise = new CancelablePromise(async (resolve) => {
    let callback;
    productVersions.forEach((productVersion) => {
      // The calls are made in the inverse order.
      // First product is saved last and resolves the full promise.
      callback = dispatch(saveOne(productVersion, callback || resolve));
    });
    if (callback) {
      await callback();
    }
  }).then(
    () => {
      track({
        category: 'product',
        action: 'product_bulkedited',
        label: `productVersions#${productVersionIds}`,
        value: productVersions.size,
      });
      dispatch({ type: PRODUCT_VERSION_BULK_EDIT_COMPLETE });
    },
    () => {
      track({
        category: 'product',
        action: 'product_bulkedit_failed',
        label: `productVersions#${productVersionIds}`,
        value: productVersions.size,
      });
      dispatch({ type: PRODUCT_VERSION_BULK_EDIT_COMPLETE });
    },
  );

  return savePromise;
};

export const cancelSave = () => (dispatch) => {
  if (!!savePromise && isFunction(savePromise.cancel)) {
    savePromise.cancel();
  }
  dispatch({ type: PRODUCT_VERSION_BULK_EDIT_CANCEL_SAVE });
};

export const openProductVersionBulkEdit = createAction(
  OPEN_PRODUCT_VERSION_BULK_EDIT,
);
export const closeProductVersionBulkEdit = createAction(
  CLOSE_PRODUCT_VERSION_BULK_EDIT,
);
export const resetProductVersionBulkEdit = createAction(
  RESET_PRODUCT_VERSION_BULK_EDIT,
);

import prefs from 'constants/prefs';
import * as sharedStatusConstants from 'constants/sharedStatus';
import { TAG_EXPORTABLE } from 'constants/tags';
import {
  TargetProductStatusAccepted,
  TargetProductStatusUpdated,
} from 'constants/targetProductStatus';
import { first, get } from 'utils/immutable';
import moment from 'utils/moment';

import { hasTag } from './productversion';

// ************************************************************
// Retailer specific data methods
// For methods that do not change, see ../productversion
// ************************************************************

export const getShortId = (pv) => {
  const internalReference = pv.get('primaryInternalReference'); // FieldMetaData 519
  // fallback backward compatibility
  if (!internalReference) {
    const internalIdKey = get(prefs, 'productList.internalId') || 'shortIdOut';
    return pv.getIn(['retailerIdentification', 0, internalIdKey]);
  }
  return internalReference;
};

// returns the name of hierarchy in retailer internal fields or null
export const getRetailerCategoryName = (pv) => get(pv, 'isClassifiedIn.name');

/**
 * Referencing project: retailer product status
 */
export const getTargetProductStatus = (pv) => get(pv, 'targetProductStatus');

export const getImportDate = (pv) => {
  const createdAt = pv.get('createdAt');
  return createdAt ? moment(createdAt) : null;
};

// ************************************************************
// Information Request methods.
// ************************************************************

export const getInformationRequest = (pv) =>
  get(pv, 'alkemics.information_request');

export const getSupplierId = (pv) => {
  const ir = getInformationRequest(pv);
  if (!ir) {
    return null;
  }
  return get(ir, 'supplier_id');
};

export const getRequestedProductStatus = (pv) => {
  const ir = getInformationRequest(pv);
  if (!ir) {
    return null;
  }
  return get(ir, 'requested_product_status');
};

// ************************************************************
// OROP methods.
// ************************************************************

export const getAcceptationDate = (pv) => {
  const orops = pv.get('acceptedOrops');
  if (!orops || !orops.size) {
    return null;
  }
  const orop = orops
    .sort((a, b) => moment(a.get('createdAt')).diff(moment(b.get('createdAt'))))
    .first();
  if (!orop) {
    return null;
  }
  return moment(orop.get('createdAt'));
};

// ************************************************************
// Sharing methods.
// ************************************************************

/**
 * Get organization shared from
 */
// ISO with: alk_service_search/model/helper/share_picker.py#L14
const shareStateToImportance = {
  [sharedStatusConstants.deleted.id]: 0,
  [sharedStatusConstants.archived.id]: 1,
  [sharedStatusConstants.refused.id]: 2,
  [sharedStatusConstants.draft.id]: 3,
  [sharedStatusConstants.pending.id]: 4,
  [sharedStatusConstants.accepted.id]: 5,
};

// Sort highest by highest status and pick the first one
export function pickMostAdvancedShare(shares) {
  const isf = shares.sort(
    (a, b) =>
      shareStateToImportance[get(b, 'status.id')] -
      shareStateToImportance[get(a, 'status.id')],
  );
  return first(isf);
}

// get source organization shared from
export const getSharedOrganizationId = (pv) => {
  if (!get(pv, 'isSharedFrom')) {
    return null;
  }
  return get(pickMostAdvancedShare(get(pv, 'isSharedFrom')), [
    'sourceOrganization',
    'id',
  ]);
};

// get source pv id shared from
export const getSharedProductVersionId = (pv) => {
  if (!get(pv, 'isSharedFrom')) {
    return null;
  }
  return get(pickMostAdvancedShare(get(pv, 'isSharedFrom')), [
    'sourceProductVersion',
    'id',
  ]);
};

export const getLastShareDate = (pv) => {
  if (!get(pv, 'isSharedFrom')) {
    return null;
  }
  const lastShareDate = get(
    pickMostAdvancedShare(get(pv, 'isSharedFrom')),
    'lastShareDate',
  );
  if (!lastShareDate) {
    return null;
  }
  return moment(lastShareDate);
};

export const getLastShareStatus = (pv) => {
  if (!get(pv, 'isSharedFrom')) {
    return null;
  }
  return get(pickMostAdvancedShare(get(pv, 'isSharedFrom')), 'status');
};

export const getManufacturerName = (pv) => get(pv, 'manufacturerInfo.name');

export const isActivelyShared = (pv) =>
  sharedStatusConstants.accepted.id === get(getLastShareStatus(pv), ['id']);

export const isExportable = (pv) => hasTag(pv, TAG_EXPORTABLE);

// product versions having different target product status cannot be set exportable
export const exportableEligibleTargetProductStatuses = [
  TargetProductStatusAccepted.id,
  TargetProductStatusUpdated.id,
];

export const canSetExportable = (pv) =>
  !isExportable(pv) &&
  exportableEligibleTargetProductStatuses.includes(getTargetProductStatus(pv));

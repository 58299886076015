import { set } from 'lodash/fp';

import { createReducer } from 'utils/redux';

import {
  OPEN_CONSENT_MODAL,
  SAVE_TRACKING_PREFERENCES,
} from '../actions/constants';
import { ReducerState } from '../types';

const initialState: ReducerState = {
  isConsentModalOpened: false,
};

export default createReducer<ReducerState>(initialState, {
  [OPEN_CONSENT_MODAL]: set('isConsentModalOpened', true),
  [SAVE_TRACKING_PREFERENCES]: set('isConsentModalOpened', false),
});

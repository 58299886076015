import { FormProvider } from 'react-hook-form';
import { useDispatch, useSelector } from 'react-redux';

import { Button, SwitchButton } from '@alkem/react-ui-button';

import Anchor from 'components/ui/basic/anchor';
import { useDocumentForm } from 'components/ui/form/asset-list/AssetV2/hooks/useDocumentForm';
import {
  DocumentAssetV2,
  EnhancedAssetV2,
} from 'components/ui/form/asset-list/AssetV2/types';
import { selectRecipientIdsSharingSomething } from 'modules/recipient-specific-block/selectors';
import { selectUser } from 'modules/user';
import { setFilterRuleSetsFields } from 'modules/view-as/actions';
import { selectFilterRuleSetsFields } from 'modules/view-as/selectors';
import i18n from 'utils/i18n';

import { AssetRecipients, FileSizeInfo } from '../AssetInputs';

import { AssetContextualDocumentFormGroupWrapper } from './AssetContextualDocumentFormGroupWrapper';
import styles from './AssetDocumentForm.module.scss';
import { AssetDocumentJsonInfoInputs } from './AssetDocumentJsonInfoInputs';

type AssetFormProps = {
  asset: EnhancedAssetV2<DocumentAssetV2>;
};

export const AssetDocumentForm = ({ asset }: AssetFormProps) => {
  const user = useSelector(selectUser);
  const filterRuleSetsFields = useSelector(selectFilterRuleSetsFields);

  const recipientStatuses = asset.recipientStatusById;

  const recipientIdsSharingSomething: number[] = useSelector(
    selectRecipientIdsSharingSomething,
  );

  const recipientIdsSharingAsset = recipientIdsSharingSomething.filter(
    (recipientId) => !(recipientStatuses[recipientId] === 0),
  );

  const { formMethods, onSubmit } = useDocumentForm({
    defaultValues: {
      ...asset,
      recipientIds: recipientIdsSharingAsset,
    },
  });

  const dispatch = useDispatch();

  const handleCheckOptionalField = (checked: boolean) => {
    dispatch(setFilterRuleSetsFields(!checked, user));
  };

  return (
    <FormProvider {...formMethods}>
      <form className={styles.form} onSubmit={onSubmit}>
        <div className={styles.inputs}>
          <div className={styles.previewWithMetadataWrapper}>
            {asset.fileType === 'application/pdf' ? (
              <div className={styles.previewCanvas}>
                <Anchor
                  href={asset.uniformResourceIdentifier}
                  className={styles.preview}
                >
                  <iframe
                    src={`${asset.uniformResourceIdentifier}#toolbar=0`}
                    title="PDF Preview"
                    width="100%"
                    height="100%"
                  />
                </Anchor>
              </div>
            ) : (
              <Anchor href={asset.url} className={styles.previewNotAvailable}>
                <i
                  className={`mdi mdi-file-document ${styles.previewPlaceholder}`}
                />
              </Anchor>
            )}
            <div className={styles.metadataWrapper}>
              <FileSizeInfo fileSize={asset.fileSize} />
            </div>
          </div>
          <div className={styles.marginBottom}>
            <AssetRecipients />
          </div>
          <AssetDocumentJsonInfoInputs
            formMethods={formMethods}
            asset={asset}
          />
          <AssetContextualDocumentFormGroupWrapper formMethods={formMethods} />
        </div>
        <div className={styles.controls}>
          <SwitchButton
            content={i18n.t(
              'frontproducstream.asset_list.modal.show_more_fields_button.label',
              {
                defaultValue:
                  'Show optional fields to market more efficiently your product',
              },
            )}
            onChange={handleCheckOptionalField}
            checked={!filterRuleSetsFields}
          />
          <Button
            displaySpinner={formMethods.formState.isSubmitting}
            primary
            type="submit"
            disabled={
              !formMethods.formState.isDirty ||
              formMethods.formState.isSubmitting
            }
          >
            {i18n.t('frontproducstream.asset.modal.save_button.label', {
              defaultValue: 'Save',
            })}
          </Button>
        </div>
      </form>
    </FormProvider>
  );
};

import moment from 'moment';
import PropTypes from 'prop-types';
import { PureComponent } from 'react';

import { LazyTooltip } from '@alkem/react-ui-tooltip';

import {
  ProductValidationStatusKo,
  ProductValidationStatusOk,
} from 'constants/productValidationStatus';
import { ALL_COMPLETED_STAGE_GATE_ID } from 'constants/requiredFieldsCategory';
import i18n from 'utils/i18n';
import { get } from 'utils/immutable';

import './index.scss';

export default class RequiredFieldsCategoryColumn extends PureComponent {
  static propTypes = {
    data: PropTypes.object,
    productVersionId: PropTypes.number.isRequired,
  };

  getClass = (deadline, validated) => {
    if (!deadline) {
      return 'normal';
    }
    const deadlineDate = moment(deadline);
    if (validated || deadlineDate.isAfter(moment().add(3, 'days'))) {
      return 'normal';
    } else if (deadlineDate.isAfter(moment())) {
      return 'warning';
    } else {
      return 'error';
    }
  };

  renderToolTipRow = (stageGate) => {
    const name = get(stageGate, 'label');
    const validated = get(stageGate, 'validated');
    const deadline = get(stageGate, 'deadline');

    const iconClass = validated
      ? ProductValidationStatusOk.iconClass
      : ProductValidationStatusKo.iconClass;
    const colorClass = this.getClass(deadline, validated);
    const nameClass = `tooltip-required-fields-category__name-deadline tooltip-required-fields-category__name-deadline--${colorClass}`;
    const deadlineClass = deadline
      ? nameClass
      : `tooltip-required-fields-category__name-no-deadline`;

    return (
      <tr
        key={`tooltip-required-fields-category-${name}`}
        data-testid="tooltip-required-fields-category-row"
      >
        <td className={nameClass}>{name}</td>
        <td className={deadlineClass}>
          {deadline
            ? moment(deadline).format('DD/MM/YYYY')
            : i18n.t('No deadline')}
        </td>
        <td>
          <i className={`mdi ${iconClass}`} />
        </td>
      </tr>
    );
  };

  renderToolTip = (all, children) => {
    // Sort by ascending deadline and no deadline at the end
    const orderedAll = all.sort((a, b) => {
      const deadlineA = get(a, 'deadline');
      const deadlineB = get(b, 'deadline');
      if (!deadlineA) {
        return 1;
      } else if (!deadlineB) {
        return -1;
      } else if (moment(deadlineA).isAfter(moment(deadlineB))) {
        return 1;
      } else if (moment(deadlineA).isBefore(moment(deadlineB))) {
        return -1;
      }
      return 0;
    });
    const label = (
      <table>
        <tbody>{orderedAll.map(this.renderToolTipRow)}</tbody>
      </table>
    );
    return (
      <LazyTooltip
        id={`tooltip-required-fields-category-${this.props.productVersionId}`}
        tooltipLabel={label}
      >
        {children}
      </LazyTooltip>
    );
  };

  renderAllComplete(name, all) {
    const icon = (
      <i className="RequiredFieldsCategoryColumn__calendar mdi mdi-calendar-clock" />
    );
    return (
      <div className="RequiredFieldsCategoryColumn">
        <span className="RequiredFieldsCategoryColumn__name RequiredFieldsCategoryColumn__name--allComplete">
          <i className="mdi mdi-check" />
          {i18n.t('All categories completed')}
        </span>
        {all && all.size !== 0 ? this.renderToolTip(all, icon) : icon}
      </div>
    );
  }

  render() {
    const { data } = this.props;
    if (!data) {
      return null;
    }

    const currentStageGate = get(data, 'current');
    if (!currentStageGate) {
      return null;
    }

    const id = get(currentStageGate, 'id');
    const name = get(currentStageGate, 'label');
    const validated = get(currentStageGate, 'validated');
    const deadline = get(currentStageGate, 'deadline');
    const all = get(data, 'all');

    if (id === ALL_COMPLETED_STAGE_GATE_ID) {
      return this.renderAllComplete(name, all);
    }

    const colorClass = this.getClass(deadline, validated);
    const icon = (
      <i className="RequiredFieldsCategoryColumn__calendar mdi mdi-calendar-clock" />
    );

    return (
      <div className="RequiredFieldsCategoryColumn" data-type={colorClass}>
        <span
          className={`RequiredFieldsCategoryColumn__name RequiredFieldsCategoryColumn__name--${colorClass}`}
        >
          {name}
        </span>
        {all && all.size !== 0 ? this.renderToolTip(all, icon) : icon}
        <br />
        <div className="RequiredFieldsCategoryColumn__deadline">
          <span>{`${i18n.t('Before')}:`}</span>
          <span
            className={`RequiredFieldsCategoryColumn__date RequiredFieldsCategoryColumn__date--${colorClass}`}
          >
            {deadline
              ? moment(deadline).format('DD/MM/YYYY')
              : i18n.t('No deadline')}
          </span>
        </div>
      </div>
    );
  }
}

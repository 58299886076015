import {
  DEFINE_ANCHOR_NAVIGATION_SECTION,
  RESET_LOADING,
  SCROLLED_TO_ANCHOR_NAVIGATION_SECTION,
  SET_ANCHOR_NAVIGATION_SECTION,
  SHOW_ERROR_PAGE,
  START_LOADING,
  STOP_LOADING,
  UPDATE_SCROLL_POSITION,
} from 'constants/events/navigation';
import { setBlockPosition } from 'modules/anchored/actions';
import { createAction } from 'utils/redux';

export const setAnchorNavigationSection = (selectedAnchoredSection) => ({
  type: SET_ANCHOR_NAVIGATION_SECTION,
  selectedAnchoredSection,
});

export const defineAnchorNavigationSection =
  (blockName, positionTop, blockId) => (dispatch) => {
    dispatch({
      type: DEFINE_ANCHOR_NAVIGATION_SECTION,
      blockId,
      blockName,
      positionTop,
    });
    dispatch(setBlockPosition(blockName, positionTop, blockId));
  };

export const scrolledToAnchorNavigationSection = createAction(
  SCROLLED_TO_ANCHOR_NAVIGATION_SECTION,
);

export const resetLoading = createAction(RESET_LOADING);
export const startLoading = createAction(START_LOADING);
export const stopLoading = createAction(STOP_LOADING);
export const showErrorPage = createAction(SHOW_ERROR_PAGE);

export const updateScrollPosition = (anchors, scrollTop, scrollHeight) => {
  const anchorPosition = anchors.reduce(
    (acc, anchor, i, a) => {
      return {
        prev: scrollTop >= anchor.top ? anchor.top : acc.prev,
        next: scrollTop >= anchor.top ? a[i + 1]?.top : acc.next,
      };
    },
    {
      prev: 0,
      next: anchors[0]?.top,
    },
  );

  return {
    type: UPDATE_SCROLL_POSITION,
    payload: { scrollTop, scrollHeight, ...anchorPosition },
  };
};

import classNames from 'classnames';
import { ReactNode } from 'react';

import './index.scss';

type Props = {
  id?: string;
  selected?: boolean;
  className?: string;
  children: ReactNode;
  withBackground?: boolean;
  [key: string]: any;
};

export function ListTableRow({
  id,
  selected = false,
  className = '',
  children,
  withBackground,
  ...props
}: Props) {
  return (
    <tr
      id={id}
      className={classNames(
        'ListTableRow',
        selected && 'ListTableRow--selected',
        className,
        withBackground && 'ListTableRow--background',
      )}
      {...props}
    >
      {children}
    </tr>
  );
}

export default ListTableRow;

import { track } from 'utils/tracking';

export const isActive = (route) => (_, location) =>
  location.pathname + location.search === route;

export const trackNavigation = (route) => () => {
  track({
    category: 'product-page-tracking',
    action: 'header navigation',
    label: route,
  });
};

import { IndexerApi } from '@alkem/sdk-dashboard';

import { getUserLanguage as acceptLanguage } from 'utils/i18n';

const options = {
  cacheEnabled: false,
  acceptLanguage,
};

export default new IndexerApi(options);

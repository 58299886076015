import { createSelector } from '@reduxjs/toolkit';
import { concat, groupBy, some, uniqBy, values } from 'lodash';
import { flow, get, over, set, update } from 'lodash/fp';
import memoize from 'memoize-one';

import { hasMatureListing as hasMatureListingSettings } from 'core/api/organization-settings';
import { getTypePackagingId } from 'core/api/productversion';
import { selectHasEditableHierarchies } from 'modules/feature-flag/selectors';
import { getAll } from 'modules/recipients/reducers';
import {
  selectAlreadyAgreedHierarchyIds,
  selectHierarchyIdBySharingUnit,
  sharingUnitsByRetailerId,
} from 'modules/sharing-units/selectors';
import { selectIsRetailer, selectOrganization } from 'modules/user';
import { selectSelectedRecipients } from 'modules/view-as/selectors';
import { selectLogisticalUnitFields } from 'modules/view-as/selectors/fields';
import { filterDisplayGroups, viewAsFilter } from 'modules/view-as/utils';
import {
  selectCurrentLanguage,
  selectCurrentProductVersionGTIN,
  selectCurrentProductVersionProductIdentifier,
  selectDisplayNameWithoutFallback,
  selectGrossWeight,
  selectIsConsumerUnit,
  selectIsDisplayUnit,
  selectIsSizedBy,
  selectProductGTINWithFallbackOnInternal,
  selectTextileVariantList,
  selectTypePackaging,
} from 'reducers/productVersion';

import * as helpers from './helpers';

export const LOGISTICAL_HIERARCHIES_STORE_KEY = 'logisticalHierarchies';

const selectModuleState = get(LOGISTICAL_HIERARCHIES_STORE_KEY);

export const selectIsDirty = flow(selectModuleState, (state) => state.isDirty);

export const selectSource = flow(selectModuleState, (state) => state.source);

export const selectEdited = flow(selectModuleState, (state) => state.edited);

export const selectSourceDataMap = flow(selectSource, (source) =>
  source ? source.dataMap : {},
);

export const selectEditedDataMap = flow(selectEdited, (edited) =>
  edited ? edited.dataMap : {},
);

export const selectSourceHierarchyMap = flow(selectSource, (source) =>
  source ? source.hierarchyMap : {},
);

export const selectEditedHierarchyMap = flow(selectEdited, (edited) =>
  edited ? edited.hierarchyMap : {},
);

export const selectSourceRoots = flow(selectSource, (source) =>
  source ? source.roots : [],
);

export const selectEditedRoots = flow(selectEdited, (edited) =>
  edited ? edited.roots : [],
);

export const selectEditedRootsFilteredByViewAs = createSelector(
  selectEdited,
  selectEditedDataMap,
  selectSelectedRecipients,
  selectHierarchyIdBySharingUnit,
  sharingUnitsByRetailerId,
  (
    edited,
    dataMap,
    selectedRecipientsViewAs,
    hierarchyBySharingUnit,
    sharingUnitsByRetailerid,
  ) => {
    const selectedRetailerIds = selectedRecipientsViewAs.map((r) =>
      r.get('id'),
    );
    const hierarchyByRetailer = sharingUnitsByRetailerid.map(
      (sharingUnitsIds) =>
        sharingUnitsIds
          .map((sharingUnitId) => hierarchyBySharingUnit.get(sharingUnitId))
          .filter((id) => !!id),
    );
    if (!edited) {
      return [];
    }

    // if no selected retailer, return everything
    if (selectedRetailerIds.size === 0) {
      return edited.roots;
    }
    return edited.roots.filter((uuid) => {
      const hierarchyId = dataMap[uuid].id;

      // if hierarchy is not attached yet, show it
      const isNotAttachedToSharingUnit = !hierarchyByRetailer.some(
        (hierarchyIds) => hierarchyIds.includes(hierarchyId),
      );

      // if hierarchy is attached to a sharing unit for one of the selected retailers, show it
      const attachedToSharingUnitsOfCurrentSelectedRetailers =
        hierarchyByRetailer.some(
          (hierarchyIds, retailerId) =>
            selectedRetailerIds.includes(retailerId) &&
            hierarchyIds.includes(hierarchyId),
        );

      return (
        isNotAttachedToSharingUnit ||
        attachedToSharingUnitsOfCurrentSelectedRetailers
      );
    });
  },
);

export const buildCurrentVersionInfo = memoize(
  (
    gtin,
    productIdentifier,
    reference,
    isConsumerUnit,
    isDisplayUnit,
    typePackaging,
    isSizedBy,
    grossWeight,
    textileVariantList,
    displayName,
    currentLanguage,
  ) => ({
    gtin,
    productIdentifier,
    reference,
    isConsumerUnit,
    isDisplayUnit,
    typePackaging,
    isSizedBy,
    grossWeight,
    textileVariantGTINs: (textileVariantList || [])
      .map(get(['textileVariant', 'gtin']))
      .filter((g) => !!g),
    displayName,
    currentLanguage,
  }),
);

export const selectCurrentVersionInfo = flow(
  over([
    selectCurrentProductVersionGTIN,
    selectCurrentProductVersionProductIdentifier,
    selectProductGTINWithFallbackOnInternal,
    selectIsConsumerUnit,
    selectIsDisplayUnit,
    selectTypePackaging,
    selectIsSizedBy,
    selectGrossWeight,
    selectTextileVariantList,
    selectDisplayNameWithoutFallback,
    selectCurrentLanguage,
  ]),
  (args) => buildCurrentVersionInfo(...args),
);

export const selectUnitCreationOptions = createSelector(
  selectEditedDataMap,
  selectHasEditableHierarchies,
  selectAlreadyAgreedHierarchyIds,
  selectCurrentProductVersionGTIN,
  selectCurrentProductVersionProductIdentifier,
  (
    dataMap,
    hasEditableHierarchies,
    alreadyAgreedHierarchyIds,
    currentVersionGtin,
    currentVersionIdentifier,
  ) =>
    groupBy(
      uniqBy(
        Object.values(dataMap).filter(
          (unit) =>
            // Include if there is a GTIN or productIdentifier and
            (!!unit.gtin || !!unit.productIdentifier) &&
            // don't include if not logistical unit and not the current unit and
            (!helpers.isConsumerOrDisplayUnit(unit.version) ||
              unit.gtin === currentVersionGtin ||
              unit.productIdentifier === currentVersionIdentifier) &&
            // don't include if the unit is an already shared logistical unit.
            (hasEditableHierarchies ||
              !unit.id ||
              !alreadyAgreedHierarchyIds.includes(unit.id)),
        ),
        'id',
      ).map((unit) => ({
        gtin: unit.gtin,
        productIdentifier: unit.productIdentifier,
        packagingTypeId: getTypePackagingId(unit.version),
        version: unit.version,
        isCurrentVersion:
          unit.gtin === currentVersionGtin ||
          (Boolean(unit.productIdentifier) &&
            unit.productIdentifier === currentVersionIdentifier),
      })),
      (o) => o.packagingTypeId,
    ),
);

export const selectBranchGtins = createSelector(
  selectEditedDataMap,
  selectEditedHierarchyMap,
  (dataMap, hierarchyMap) => {
    // First construct 2 maps linking parents to children.
    const parentToChildrenIds = {};
    const childToParentsIds = {};
    Object.entries(hierarchyMap).forEach(([parentId, children]) => {
      parentToChildrenIds[parentId] = children.map((child) => child.id);
      parentToChildrenIds[parentId].forEach((childId) => {
        childToParentsIds[childId] = [
          ...(childToParentsIds[childId] || []),
          parentId,
        ];
      });
    });
    // Then recurse to get all the parents.
    const getParents = (id) =>
      concat(id, ...(childToParentsIds[id] || []).map(getParents));
    const branchGtins = {};
    Object.entries(parentToChildrenIds).forEach(([id, childrenIds]) => {
      branchGtins[id] = [...getParents(id), ...childrenIds].map(
        (i) => dataMap[i].gtin,
      );
    });
    return branchGtins;
  },
);

export const selectEditedUnit = flow(
  selectModuleState,
  (state) => state.editedUnit,
);

export const getEditedLogisticalUnitsAsTree = createSelector(
  selectEditedRoots,
  selectEditedDataMap,
  selectEditedHierarchyMap,
  (roots, dataMap, hierarchyMap) =>
    helpers.convertBackToTree(roots, dataMap, hierarchyMap),
);

const selectRootPathsToIgnore = flow(
  selectModuleState,
  (state) => state.rootPathsToIgnore,
);

const selectRootWithUpdatedPrivateFields = flow(
  selectModuleState,
  (state) => state.updatedPrivateFields,
);

export const cleanEditedDataMap = (
  sourceDataMap,
  editedDataMap,
  rootPathsToIgnore,
) =>
  // Loop on all edited units.
  Object.keys(editedDataMap).reduce(
    (dataMapToSave, internalId) =>
      update(
        [internalId, 'version'],
        // Loop in all paths to ignore.
        (version) =>
          (rootPathsToIgnore[internalId] || []).reduce(
            // Replace edited data with source data.
            (v, path) =>
              set([path], get([internalId, 'version', path], sourceDataMap), v),
            version,
          ),
        dataMapToSave,
      ),
    editedDataMap,
  );

const isLogisticalHierarchyDirty = (unit) => {
  if (unit.isDirty) {
    return true;
  } else if (unit.children) {
    return unit.children.some((child) => isLogisticalHierarchyDirty(child));
  }
  return false;
};

const logisticalHierarchiesToSave =
  (filterPatches = false) =>
  ([
    roots,
    sourceDataMap,
    editedDataMap,
    hierarchyMap,
    rootPathsToIgnore,
    rootsWithUpdatedPrivateFields,
  ]) => {
    const hierarchies = helpers.convertBackToTree(
      roots,
      cleanEditedDataMap(sourceDataMap, editedDataMap, rootPathsToIgnore),
      hierarchyMap,
    );
    if (filterPatches) {
      return hierarchies.reduce((acc, unit, index) => {
        if (
          (unit.isPatch && isLogisticalHierarchyDirty(unit)) ||
          some(
            values(rootsWithUpdatedPrivateFields?.[unit.internalId]),
            (hasChanges) => hasChanges === true,
          )
        ) {
          acc.push(set(['index'], index, unit));
        }
        return acc;
      }, []);
    }
    return hierarchies;
  };

export const selectLogisticalHierarchiesToSave = flow(
  over([
    selectEditedRoots,
    selectSourceDataMap,
    selectEditedDataMap,
    selectEditedHierarchyMap,
    selectRootPathsToIgnore,
    selectRootWithUpdatedPrivateFields,
  ]),
  logisticalHierarchiesToSave(),
);

export const selectPatchedLogisticalHierarchiesToSave = flow(
  over([
    selectEditedRoots,
    selectSourceDataMap,
    selectEditedDataMap,
    selectEditedHierarchyMap,
    selectRootPathsToIgnore,
    selectRootWithUpdatedPrivateFields,
  ]),
  logisticalHierarchiesToSave(true),
);

const deletedRootsSelector =
  (patches = false) =>
  ([state, sourceDataMap, editedDataMap, sourceRoots]) => {
    const { deletedRoots } = state;
    // Grab all used GTINs in edited data.
    const allUsedReferences = Object.values(editedDataMap)
      .filter((data) => data.gtin || data.productIdentifier)
      .map((data) => data.gtin || data.productIdentifier);
    const test = sourceRoots
      .filter(
        (internalId) =>
          // Must be deleted.
          deletedRoots.includes(
            get([internalId, 'product_key_id'], sourceDataMap),
          ) &&
          // Must not be still used.
          (patches ||
            (!allUsedReferences.includes(
              get([internalId, 'gtin'], sourceDataMap),
            ) &&
              !allUsedReferences.includes(
                get([internalId, 'productIdentifier'], sourceDataMap),
              ))) &&
          // Must be patch
          (!patches || get([internalId, 'isPatch'], sourceDataMap)),
      )
      .map((internalId) =>
        get(
          [internalId, patches ? 'patch_id' : 'product_key_id'],
          sourceDataMap,
        ),
      );

    return test;
  };

export const selectDeletedRoots = flow(
  over([
    selectModuleState,
    selectSourceDataMap,
    selectEditedDataMap,
    selectSourceRoots,
  ]),
  deletedRootsSelector(),
);

export const selectPatchedDeletedRoots = flow(
  over([
    selectModuleState,
    selectSourceDataMap,
    selectEditedDataMap,
    selectSourceRoots,
  ]),
  deletedRootsSelector(true),
);

export const selectArePatchedLogisticalUnitsDirty = flow(
  over([selectPatchedLogisticalHierarchiesToSave, selectPatchedDeletedRoots]),
  ([hierarchies, deletedRoots]) =>
    [hierarchies, deletedRoots].some((arr) => arr.length > 0),
);

export const selectSharableLogisticalHierarchies = createSelector(
  selectCurrentVersionInfo,
  selectEditedRoots,
  selectEditedDataMap,
  selectEditedHierarchyMap,
  helpers.filterSharableHierarchies,
);

export const selectHierarchiesDiffs = flow(
  selectModuleState,
  (state) => state.diffs || [],
);

export const selectHasLogisticalHierarchyPrivateFieldsEdited = createSelector(
  selectModuleState,
  (state) => {
    return some(state.updatedPrivateFields, (fields) =>
      some(values(fields), (hasChange) => hasChange === true),
    );
  },
);

export const selectShouldShowLogisticalHierarchies = createSelector(
  selectIsRetailer,
  selectOrganization,
  getAll,
  selectSelectedRecipients,
  (isRetailer, organization, allRecipients, selectedRecipientsInViewAs) => {
    if (isRetailer) {
      return hasMatureListingSettings(organization.get('settings'));
    }
    const recipients =
      selectedRecipientsInViewAs.size > 0
        ? selectedRecipientsInViewAs
        : allRecipients;
    return (
      recipients.filter((recipient) => hasMatureListingSettings(recipient))
        .size > 0
    );
  },
);

export const selectHasLogisticalUnitEdited = createSelector(
  selectShouldShowLogisticalHierarchies,
  selectIsDirty,
  (hasMatureListing, isDirty) => hasMatureListing && isDirty,
);

export const selectArePatchedLogisticalUnitsEdited = flow(
  over([selectSourceDataMap, selectEditedDataMap]),
  ([source, edited]) => {
    const patchedSource = Object.values(source.dataMap).filter(
      (unit) => unit.isPatch,
    );
    const patchedEdited = Object.values(edited.dataMap).filter(
      (unit) => unit.isPatch,
    );
    return (
      patchedSource.length !== patchedEdited.length ||
      new Set(patchedSource.concat(patchedEdited)).size !== patchedSource.length
    );
  },
);

export const selectDisplayGroups = flow(
  selectModuleState,
  (state) => state.displayGroups || {},
);

export const selectFilteredDisplayGroups = createSelector(
  selectDisplayGroups,
  selectLogisticalUnitFields,
  (allDisplayGroups, fields) =>
    Object.entries(allDisplayGroups).reduce(
      (filtered, [packagingTypeId, displayGroups]) => ({
        ...filtered,
        [packagingTypeId]: filterDisplayGroups(
          [viewAsFilter({ fields })],
          displayGroups,
        ),
      }),
      {},
    ),
);

export const selectAgreedEditedInternalIds = flow(
  selectModuleState,
  (state) => state.agreedEditedUnits,
);

export const selectExpandedInternalIds = flow(
  selectModuleState,
  (state) => state.expandedInternalIds,
);

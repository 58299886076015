import PropTypes from 'prop-types';
import { PureComponent } from 'react';
import ImmutablePropTypes from 'react-immutable-proptypes';

import Checkbox from '@alkem/react-ui-checkbox';

import { getId, getLabel } from '../../../../selectors/referential';

import './option.scss';

export default class CatalogColumnSelectorOption extends PureComponent {
  static propTypes = {
    referential: ImmutablePropTypes.map.isRequired,
    selected: PropTypes.bool,
    onChange: PropTypes.func.isRequired,
  };

  constructor(props) {
    super(props);
    this.onChange = this.onChange.bind(this);
  }

  onChange(selected) {
    this.props.onChange(this.props.referential, selected);
  }

  render() {
    return (
      <div className="CatalogColumnSelectorOption">
        <Checkbox
          id={`catalog-column-selector-option-${getId(this.props.referential)}`}
          label={getLabel(this.props.referential)}
          checked={this.props.selected}
          onChange={this.onChange}
        />
      </div>
    );
  }
}

import classNames from 'classnames';
import { isFunction } from 'lodash/fp';
import { ReactNode, useState } from 'react';
import { useSelector } from 'react-redux';

import { Button } from '@alkem/react-ui-button';

import { SUPPORT_EMAIL } from 'constants/emails';
import { ERROR, SUCCESS } from 'constants/notification';
import { selectUser } from 'modules/user';
import { UserImmutable } from 'types';
import i18n from 'utils/i18n';
import { get } from 'utils/immutable';
import { formatMailTo } from 'utils/mailto';

import './notification.scss';

interface NotificationError extends Error {
  data?: any;
  status?: number;
}

interface Props {
  absolute?: boolean;
  children?: ReactNode;
  context?: string;
  error?: NotificationError;
  message?: ReactNode;
  onDiscard?: () => void;
  onShowDetails?: () => void;
  sticky?: boolean;
  type?: string;
  additionalInfo?: [string, any][];
}

export function Notification({
  absolute,
  children,
  context,
  error,
  message,
  onDiscard,
  onShowDetails,
  sticky,
  type,
  additionalInfo,
}: Props) {
  const [showDetails, setShowDetails] = useState(false);
  const user: UserImmutable = useSelector(selectUser);
  const withDiscard = isFunction(onDiscard);

  const onLocalShowDetails = () => {
    setShowDetails(true);
    if (onShowDetails) {
      onShowDetails();
    }
  };

  return (
    <div
      className={classNames('Notification', {
        'Notification--base': context === 'base',
        'Notification--public': context === 'public',
        'Notification--modal': context === 'modal',
        'Notification--block': context === 'block',
        'Notification--error': type === ERROR,
        'Notification--success': type === SUCCESS,
        'Notification--sticky': !!sticky,
        'Notification--absolute': absolute,
      })}
      data-testid="notification"
    >
      {withDiscard && (
        <div
          className="Notification__discard Notification__discard--invisible"
          data-testid="close-hidden"
        >
          <i className="mdi mdi-close" />
        </div>
      )}
      <div className="Notification__message">
        <div>{children || message}</div>
        {error && (
          <>
            {showDetails ? (
              <>
                <div>
                  <div>
                    <pre className="Notification__stacktrace">
                      {error.stack || JSON.stringify(error, null, 2)}
                    </pre>
                  </div>
                </div>
                <div>
                  <Button
                    href={formatMailTo(
                      i18n.t(
                        'frontproductstream.notification.error.link_email_subject',
                        {
                          defaultValue:
                            'I had this error on the Alkemics platform',
                        },
                      ),
                      SUPPORT_EMAIL,
                      [
                        ['origin', 'notification'],
                        ['username', get(user, ['username']) || 'unknown'],
                        ['location', document.location.href],
                        ['status', error.status || 'none'],
                        ['message', message],
                        [
                          'custom message',
                          get(error.data, ['message']) || 'none',
                        ],
                        ['stack', error.stack || JSON.stringify(error)],
                        ...(additionalInfo || []),
                      ],
                    )}
                    primary
                  >
                    {i18n.t(
                      'frontproductstream.notification.error.link_label',
                      { defaultValue: 'Send e-mail to our support team' },
                    )}
                  </Button>
                </div>
              </>
            ) : (
              <div>
                <Button onClick={onLocalShowDetails} link>
                  {i18n.t(
                    'frontproductstream.notification.error.details_button',
                    { defaultValue: 'Show details' },
                  )}
                </Button>
              </div>
            )}
          </>
        )}
      </div>
      {withDiscard && (
        <button
          className="Notification__discard"
          onClick={onDiscard}
          aria-label={i18n.t(
            'frontproductstream.notification.error.close_button',
            { defaultValue: 'Close' },
          )}
        >
          <i className="mdi mdi-close" />
        </button>
      )}
    </div>
  );
}

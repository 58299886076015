import { get } from 'lodash/fp';

import { ResponseWithBody } from '@alkem/sdk-dashboard';

import { responseWithPermissions } from 'modules/permissions';
import { Types } from 'modules/sharing-unit-tariffs/constants';
import BuyingUnitApi from 'resources/buyingUnitApi';
import DisplayGroupApi from 'resources/displayGroupApi';
import ETLApi from 'resources/etlApi';
import ServiceProductApi from 'resources/serviceProductApi';
import qs from 'utils/query';

import { SharingUnitTariffHistory } from './types';

export const transformParamsForList = (filter) => {
  const parameters: {
    [key: string]: any;
  } = {
    limit: filter['limit'] || 20,
    offset: filter['offset'],
  };

  parameters.sharingunit_template_type = Types.TARIFF.id;

  if (filter['aggregations']) {
    parameters.aggregations = filter['aggregations'];
  }
  if (filter['targetOrganizationIds']?.length) {
    parameters.target_organization_ids = filter['targetOrganizationIds'];
  }
  if (filter['sourceOrganizationIds']?.length) {
    parameters.source_organization_ids = filter['sourceOrganizationIds'];
  }
  if (filter['statuses']?.length) {
    parameters.statuses = filter['statuses'];
  }
  if (filter['updatedFrom']) {
    parameters.updated_from = filter['updatedFrom'];
  }
  if (filter['updatedTo']) {
    parameters.updated_to = filter['updatedTo'];
  }
  if (filter['supplierCodes']?.length) {
    parameters.supplier_codes = filter['supplierCodes'];
  }
  if (filter['searchQuery'] != null) {
    parameters.search = filter['searchQuery'];
  }
  if (filter['types']?.length) {
    parameters.tariff_types = filter['types'];
  }
  if (filter['validFrom']) {
    parameters.valid_from = filter['validFrom'];
  }
  if (filter['validTo']) {
    parameters.valid_to = filter['validTo'];
  }
  if (filter['uuids']?.length) {
    parameters.uuids = filter['uuids'];
  }
  return parameters;
};

export default {
  async fetchDisplayGroups(targetOrganizationId, isTariff) {
    try {
      const response = await DisplayGroupApi.DisplayGroupSharingUnitList(
        targetOrganizationId,
        isTariff ? 'tariff' : 'sharingunit',
      );
      return { result: { displayGroups: get('data.data', response) } };
    } catch (error) {
      return { error };
    }
  },

  async create(name, sourceOrganizationId, targetOrganizationId, data, type) {
    try {
      const response = await ServiceProductApi.post(
        '/product/v2/sharing-unit-templates',
        {
          name,
          sourceOrganization: { id: sourceOrganizationId },
          targetOrganization: { id: targetOrganizationId },
          data,
          type,
        },
      );
      return { result: { data: get('data.data', response) } };
    } catch (error) {
      return { error };
    }
  },

  async update(id, name, data) {
    try {
      const response = await ServiceProductApi.post(
        `/product/v2/sharing-unit-templates/${id}`,
        {
          name,
          data,
        },
      );
      return { result: { data: get('data.data', response) } };
    } catch (error) {
      return { error };
    }
  },

  async publish(id) {
    try {
      await ServiceProductApi.post(
        `/product/v2/sharing-unit-templates/${id}/publish`,
        {},
      );
      return {};
    } catch (error) {
      return { error };
    }
  },

  async validate(_id, data) {
    try {
      const response = await ServiceProductApi.post(
        `/product/v3/sharing-unit-templates/check`,
        data,
      );
      return { result: get('data', response) };
    } catch (error) {
      return { error };
    }
  },

  async get(id) {
    try {
      const response = await ServiceProductApi.get(
        `/product/v2/sharing-unit-templates/${id}`,
      );
      return { result: { data: get('data.data', response) } };
    } catch (error) {
      return { error };
    }
  },

  async getV3_5(id) {
    try {
      return await responseWithPermissions(
        BuyingUnitApi.get(`/buyingunit/v3.5/templates/${id}`),
      );
    } catch (error) {
      return { error };
    }
  },

  async list(filters) {
    try {
      const parameters = transformParamsForList(filters);
      const listUrl = '/buyingunit/v3.5/templates/list';
      const response = await BuyingUnitApi.post(listUrl, parameters);
      return { result: response.data };
    } catch (error) {
      return { error };
    }
  },

  async deleteTemplate(id) {
    try {
      await ServiceProductApi.delete(
        `/product/v2/sharing-unit-templates/${id}`,
        {},
      );
      return {};
    } catch (error) {
      return { error };
    }
  },

  async duplicateTemplate(id, name, duplicateSharingUnits) {
    try {
      const response = await ServiceProductApi.post(
        `/product/v2/sharing-unit-templates/duplicate`,
        {
          template_id: id,
          name,
          duplicate_sharing_units: duplicateSharingUnits,
        },
      );
      return { result: { data: get('data.data', response) } };
    } catch (error) {
      return { error };
    }
  },

  async exportTariff(uuid) {
    try {
      const { data: result } = await ETLApi.get(
        `/etl/v2/tariffs/${uuid}/export?use_async=1`,
      );
      return { result };
    } catch (error) {
      return { error };
    }
  },

  async exportBulkTariff(data) {
    try {
      const response = await ETLApi.post('/etl/v2/tariffs/export', data);
      return { result: { data: get('data', response) } };
    } catch (error) {
      return { error };
    }
  },

  async reject(id, reason) {
    try {
      await ServiceProductApi.post(
        `/product/v2/sharing-unit-templates/${id}/reject`,
        {
          reason,
        },
      );
      return {};
    } catch (error) {
      return { error };
    }
  },

  async accept(id) {
    try {
      await ServiceProductApi.post(
        `/product/v2/sharing-unit-templates/${id}/accept`,
      );
      return {};
    } catch (error) {
      return { error };
    }
  },

  async getPublicationResults(id, parameters) {
    try {
      const response = await ServiceProductApi.get(
        `/product/v2/sharing-unit-templates/${id}/publish/results${qs.stringify(
          parameters,
          true,
        )}`,
      );
      return {
        result: get('data', response),
      };
    } catch (error) {
      return { error };
    }
  },

  async listHistory(templateId) {
    const response = await BuyingUnitApi.post<
      SharingUnitTariffHistory[],
      ResponseWithBody<SharingUnitTariffHistory[]>
    >('/buyingunit/v3.5/templates/history', { template_id: templateId });
    return response.data;
  },
};

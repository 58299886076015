import classNames from 'classnames';
import { Map, fromJS } from 'immutable';
import { bool, func } from 'prop-types';
import { Component } from 'react';
import { map } from 'react-immutable-proptypes';

import './index.scss';

export default class FractionCell extends Component {
  static className = 'FractionCell';

  static propTypes = {
    data: map,
    referential: map.isRequired,
    isAggregation: bool,
    onClickCell: func.isRequired,
  };

  static defaultProps = {
    data: Map({ num: 0, den: 0 }),
  };

  defaultConfig = fromJS({
    quality: {
      good: [0, 0],
      medium: [0.001, 30],
      low: [30, 100],
    },
  });

  onClick = () => {
    const { onClickCell, referential, isAggregation } = this.props;
    onClickCell({
      referential: referential.hasIn(['filter', 'num'])
        ? referential.set('filter', referential.getIn(['filter', 'num']))
        : referential,
      isAggregation,
    });
  };

  getQualityLevel(level) {
    const { referential } = this.props;
    const config = referential.get('cell') || this.defaultConfig;
    const path = ['quality', level, 0];
    return config.getIn(path) || this.defaultConfig.getIn(path);
  }

  getQuality(quality) {
    switch (true) {
      case quality >= this.getQualityLevel('low'):
        return 'low';
      case quality >= this.getQualityLevel('medium'):
        return 'medium';
      default:
        return 'good';
    }
  }

  computeData(data) {
    const num = data.get('num');
    const den = data.get('den');
    const p = (num * 100) / den;
    return {
      num,
      den,
      quality: this.getQuality(p),
      onClick: this.onClick,
    };
  }

  render() {
    const { data, isAggregation } = this.props;
    if (!data || data.isEmpty()) {
      return null;
    }
    const { num, den, quality, onClick } = this.computeData(data);
    return (
      <div
        className={classNames(
          'FractionCell',
          onClick && 'FractionCell--clickable',
        )}
        onClick={onClick}
      >
        <div
          className={classNames(
            'FractionCell__quality',
            quality && `FractionCell__quality--${quality}`,
          )}
        >
          {num}
        </div>

        {!isAggregation && (
          <div className="FractionCell__den">
            <span>/</span>
            <span>{den}</span>
          </div>
        )}
      </div>
    );
  }
}

import { SourceProductStatuses } from 'constants/sourceProductStatus';
import { isMaker } from 'core/api/user';

export const sourceProductStatusFilter = {
  key: 'alkemics.statuses.source',
  query: 'sourceProductStatus',
  isAvailable: ({ user } = {}) => isMaker(user),
  statusList: [
    SourceProductStatuses.DRAFT,
    SourceProductStatuses.PUBLISHED,
    SourceProductStatuses.INFORMATION_EXPECTED,
  ],
  getSourceProductStatusById: (id, defaultValue = {}) =>
    SourceProductStatuses[id] || defaultValue,
  customField: 'recipients_source_status',
};

import { get } from 'lodash';

import { notificationError } from 'actions/notification';
import { START_LOADING, STOP_LOADING } from 'constants/events/navigation';
import { getOrganizationId, getUserId } from 'core/api/user';
import { FEATURE_PERMISSIONS_V3_PRODUCT } from 'modules/feature-flag/constants';
import { getPermissionKey, hasPermissionV3 } from 'modules/permissions';
import { PRODUCT_PERMISSION, SHOW_PERMISSION } from 'modules/permissions/const';
import { selectUser } from 'modules/user';
import authApi from 'resources/authApi';
import segmentHierarchyApi from 'resources/segmentHierarchyApi';
import i18n from 'utils/i18n';
import { logError } from 'utils/logging';
import qs from 'utils/query';

let productHierarchyPromise;

export const RECEIVE_PRODUCT_HIERARCHY = 'RECEIVE_PRODUCT_HIERARCHY';

export const getProductHierarchy =
  ({ includeUnclassified = false, useCache = true } = {}) =>
  async (dispatch, getState) => {
    if (productHierarchyPromise) {
      return;
    }
    try {
      dispatch({ type: START_LOADING });
      const user = selectUser(getState());
      const organizationId = getOrganizationId(user);
      const permissionKey = getPermissionKey(
        PRODUCT_PERMISSION,
        SHOW_PERMISSION,
      );

      productHierarchyPromise = Promise.all([
        hasPermissionV3(user, FEATURE_PERMISSIONS_V3_PRODUCT)
          ? authApi.get(
              '/auth/v3/permissions/segments/products',
              qs.stringify({ permission: permissionKey }, true),
              useCache,
            )
          : authApi.AllowedProductSegmentsIds({
              queries: {
                userId: getUserId(user),
                organizationId,
                permission: permissionKey,
              },
              useCache,
            }),
        segmentHierarchyApi.list({
          queries: { organizationId, withUnclassified: includeUnclassified },
          useCache,
        }),
      ]);

      const results = await productHierarchyPromise;
      const allowedSegments = get(results, '0.data.data.productsegment_ids');
      const allSegments = get(results, '1.data.data');
      dispatch({
        type: RECEIVE_PRODUCT_HIERARCHY,
        allowedSegments,
        allSegments,
      });
    } catch (err) {
      logError(err);
      dispatch(
        notificationError(
          i18n.t('An error occurred while retrieving product hierarchy'),
        ),
      );
    } finally {
      dispatch({ type: STOP_LOADING });
      productHierarchyPromise = null;
    }
  };

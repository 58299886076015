import classNames from 'classnames';
import { ReactNode } from 'react';

import './index.scss';

type Props = {
  isSticky?: boolean;
  className?: string;
  center?: boolean;
  data?: object;
  children?: ReactNode;
  colspan?: number;
  [key: string]: any;
};

export function ListTableCell({
  className,
  center = false,
  data = {},
  children,
  colspan = 1,
  isSticky,
  ...props
}: Props) {
  return (
    <td
      className={classNames(
        'ListTableCell',
        center && 'ListTableCell--center',
        isSticky && 'ListTableCell--firstColumnSticky',
        className,
      )}
      {...Object.keys(data).reduce(
        (dict, key) =>
          Object.assign(dict, {
            [`data-${key}`]: data[key],
          }),
        {},
      )}
      colSpan={colspan}
      {...props}
    >
      {children}
    </td>
  );
}

export default ListTableCell;

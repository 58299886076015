import { AlloyEditor } from 'alloyeditor';
import classNames from 'classnames';
import { debounce } from 'lodash';
import PropTypes from 'prop-types';
import { Component } from 'react';

import './rich-content.scss';

class RichContent extends Component {
  static demoProps = [
    {
      id: 'demo1',
      value: `
        <p>There is <u>some</u> <em>kiwis</em></p>
        <p><img src="http://www.kiwibird.org/wordpress/wp-content/uploads/kiwi_bird.jpg" /></p>
        <div>
          <table border="1" cellpadding="0" cellspacing="0" style="width: 100%;">
            <tbody>
              <tr>
                <td>1- 1</td>
                <td>1-2</td>
                <td>1-3</td>
              </tr>
              <tr>
                <td>2-1</td>
                <td>2-2</td>
                <td> </td>
              </tr>
              <tr>
                <td> </td>
                <td> </td>
                <td>3-3-</td>
              </tr>
            </tbody>
          </table>
        </div>
      `,
    },
    { id: 'demo2', value: 'This <b>text</b> is bold', lightToolbars: true },
    {
      id: 'demo3',
      value: 'This <b>text</b> is bold',
      lightToolbars: true,
      disabled: true,
    },
  ];

  static propTypes = {
    disabled: PropTypes.bool,
    id: PropTypes.string.isRequired,
    onChange: PropTypes.func.isRequired,
    large: PropTypes.bool,
    lightToolbars: PropTypes.bool,
    imageHandler: PropTypes.func,
    value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  };

  static defaultProps = {
    disabled: false,
    large: false,
    lightToolbars: false,
    value: '',
  };

  constructor(props) {
    super(props);
    this._editor = null;
    this._isMounted = false;
  }

  componentDidMount() {
    const { id, value, imageHandler, disabled } = this.props;

    this._isMounted = true;

    let usedConfig;
    if (this.props.lightToolbars) {
      usedConfig = {
        toolbars: {
          styles: {
            selections: [
              {
                name: 'text',
                buttons: ['bold'],
                test: AlloyEditor.SelectionTest.text,
              },
              {
                name: 'image',
                buttons: ['imageLeft', 'imageCenter', 'imageRight'],
                test: AlloyEditor.SelectionTest.image,
              },
            ],
          },
        },
        allowedContent: 'p{text-align}; b strong',
      };
    } else {
      usedConfig = {
        toolbars: {
          styles: {
            selections: [
              {
                name: 'text',
                buttons: [
                  'bold',
                  'italic',
                  'underline',
                  'ul',
                  'ol',
                  'paragraphLeft',
                  'paragraphCenter',
                  'paragraphRight',
                ],
                test: AlloyEditor.SelectionTest.text,
              },
              {
                name: 'title',
                buttons: ['h1', 'h2'],
                test: AlloyEditor.SelectionTest.text,
              },
              {
                name: 'text',
                buttons: [],
                test: AlloyEditor.SelectionTest.text,
              },
              {
                name: 'image',
                buttons: ['imageLeft', 'imageCenter', 'imageRight'],
                test: AlloyEditor.SelectionTest.image,
              },
            ],
          },
          add: {
            buttons: ['table'],
          },
        },
        allowedContent:
          'div p li{text-align}; ul ol b strong em u; img[!src]' +
          '; img{width, height, float}; table[*]; tbody tr td',
      };
      if (imageHandler) {
        usedConfig.toolbars.add.buttons.push('image');
      }
    }
    usedConfig.readOnly = disabled;

    this._editor = AlloyEditor.editable(id, usedConfig);
    const nativeEditor = this._editor.get('nativeEditor');
    nativeEditor.setData(value);
    if (imageHandler) {
      nativeEditor.on('imageAdd', imageHandler);
    }

    nativeEditor.on(
      'change',
      debounce(() => {
        const content = this._editor.get('nativeEditor').getData();
        if (this.props.value !== content) {
          this.onChange(content);
        }
      }, 500),
    );
  }

  shouldComponentUpdate(nextProps) {
    const isChanged = Object.keys(nextProps).some((key) =>
      key === 'onChange' ? false : nextProps[key] !== this.props[key],
    );
    if (isChanged) {
      return true;
    }
    return false;
  }

  componentDidUpdate(previousProps) {
    if (previousProps.disabled !== this.props.disabled) {
      const nativeEditor = this._editor.get('nativeEditor');
      // Ensure that the CKEditor instance is ready
      if (nativeEditor.status === 'ready') {
        nativeEditor.setReadOnly(this.props.disabled);
      } else {
        nativeEditor.on('instanceReady', () => {
          if (this._isMounted) {
            this.nativeEditor.setReadOnly(this.props.disabled);
          }
        });
      }
    }
  }

  componentWillUnmount() {
    this._isMounted = false;
  }

  onChange = (value) => {
    this.props.onChange(value, true, false);
  };

  render() {
    const { id, large, value, disabled } = this.props;
    if (this._editor) {
      const nativeEditor = this._editor.get('nativeEditor');
      if (value !== nativeEditor.getData()) {
        nativeEditor.setData(value);
      }
    }

    const inputClasses = {
      InputRichContent__richContent: true,
      'InputRichContent__richContent--large': large,
      'InputRichContent__richContent--disabled': disabled,
    };
    return (
      <div className="InputRichContent InputField InputField__input">
        <div id={id} className={classNames(inputClasses)} />
      </div>
    );
  }
}

export default RichContent;

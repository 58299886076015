import moment from 'moment';
import PropTypes from 'prop-types';

export default function CatalogDateTimeColumn({ data }) {
  const m = Number.isInteger(data) ? moment.unix(data) : moment(data);
  return <div>{data ? m.format('L LT') : <noscript />}</div>;
}

CatalogDateTimeColumn.center = true;
CatalogDateTimeColumn.propTypes = {
  data: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
};

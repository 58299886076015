import { createActionWithPayload } from 'utils/redux';

import {
  PRODUCT_ADMIN_DELETE_AUDIT_DATAS,
  PRODUCT_ADMIN_GET_AUDIT_CONTEXT,
  PRODUCT_ADMIN_GET_AUDIT_DATAS,
  PRODUCT_ADMIN_LOAD_PRODUCT_INDEXED_AT_DATE,
  PRODUCT_ADMIN_LOAD_PRODUCT_INDEXED_AT_DATE_DONE,
  PRODUCT_ADMIN_REINDEX_PRODUCT,
  PRODUCT_ADMIN_RESYNC_PRODUCT,
  PRODUCT_ADMIN_SET_AUDIT_CONTEXT,
  PRODUCT_ADMIN_SET_AUDIT_DATAS,
  PRODUCT_ADMIN_START_AUDIT,
} from './constants';
import { Audit, AuditContext } from './types';

export const productAdminReindexProduct = createActionWithPayload<number>(
  PRODUCT_ADMIN_REINDEX_PRODUCT,
);

export const productAdminResyncProduct = createActionWithPayload<number>(
  PRODUCT_ADMIN_RESYNC_PRODUCT,
);

export const productAdminLoadProductIndexedAtDate =
  createActionWithPayload<number>(PRODUCT_ADMIN_LOAD_PRODUCT_INDEXED_AT_DATE);

export const productAdminLoadProductIndexedAtDateDone =
  createActionWithPayload<string>(
    PRODUCT_ADMIN_LOAD_PRODUCT_INDEXED_AT_DATE_DONE,
  );

export const productAdminStartAudit = createActionWithPayload<number>(
  PRODUCT_ADMIN_START_AUDIT,
);

export const productAdminGetAuditDatas = createActionWithPayload<number>(
  PRODUCT_ADMIN_GET_AUDIT_DATAS,
);

export const productAdminDeleteAuditDatas = createActionWithPayload<{
  auditID: number | string;
  productKeyId: string;
}>(PRODUCT_ADMIN_DELETE_AUDIT_DATAS);

export const productAdminSetAuditDatas = createActionWithPayload<Audit[]>(
  PRODUCT_ADMIN_SET_AUDIT_DATAS,
);

export const productAdminGetAuditContext = createActionWithPayload<string>(
  PRODUCT_ADMIN_GET_AUDIT_CONTEXT,
);

export const productAdminUpdateAuditContext = createActionWithPayload<{
  [key: string]: AuditContext[];
}>(PRODUCT_ADMIN_SET_AUDIT_CONTEXT);

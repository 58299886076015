import classNames from 'classnames';
import { Collection } from 'immutable';
import { isArray } from 'lodash';
import { useCallback, useEffect, useMemo } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { Button } from '@alkem/react-ui-button';

import { shouldRenderFieldBasedOnConditions } from 'components/ui/form/field/utils/render';
import { AnchoredSection } from 'modules/anchored';
import { cancelDataOpsPatch } from 'modules/data-ops/actions';
import 'modules/data-ops/components/deprecated-data-ops/index.module.scss';
import { selectDataOpsPatches } from 'modules/data-ops/selectors';
import { DataOpsPatch, DataOpsPatchState } from 'modules/data-ops/types';
import {
  selectDefaultDisplayGroups,
  selectFieldByName,
} from 'modules/display-groups/selectors';
import { selectIsLoggedAs, selectIsRetailer } from 'modules/user';
import { selectAllAvailableFieldsForProductType } from 'modules/view-as/selectors/fields';
import { selectProductVersion } from 'reducers/productVersion';
import i18n from 'utils/i18n';
import { track } from 'utils/tracking';

import styles from './index.module.scss';

interface Props {
  order: number;
}

export const DeprecatedDataOpsPatches = ({ order }: Props) => {
  const isRetailer = useSelector(selectIsRetailer);
  const isLoggedAs = useSelector(selectIsLoggedAs);
  const patches = useSelector(selectDataOpsPatches);
  const availableFields: Collection.Set<string> = useSelector(
    selectAllAvailableFieldsForProductType,
  );
  const defaultDisplayGroups = useSelector(selectDefaultDisplayGroups);
  const getFieldByName: (fieldName: string) => any =
    useSelector(selectFieldByName);
  const productVersion = useSelector(selectProductVersion);

  const dispatch = useDispatch();

  const isFieldDisplayable = useCallback(
    (fieldName: string) => {
      const field = getFieldByName(fieldName);
      if (!field) {
        return false;
      }
      return shouldRenderFieldBasedOnConditions(field, productVersion);
    },
    [getFieldByName, productVersion],
  );

  const isFieldAvailable = useCallback(
    (patch: DataOpsPatch) => {
      // both conditions are needed, though we don't know exactly why.
      return (
        availableFields.includes(patch.fieldName) &&
        isFieldDisplayable(patch.fieldName)
      );
    },
    [availableFields, isFieldDisplayable],
  );

  const deprecatedPatches = useMemo(() => {
    const oldPatches: DataOpsPatch[] = [];

    Object.keys(patches || {}).forEach((key: string) => {
      const patchGroup: DataOpsPatchState = patches[key];
      (patchGroup?.data || []).forEach((patch) => {
        if ('id' in patch && !isFieldAvailable(patch)) {
          oldPatches.push(patch);
        }
      });
    });

    return oldPatches;
  }, [patches, isFieldAvailable]);

  const hasEnoughDataToCheckFieldDisplayability =
    isArray(defaultDisplayGroups) && defaultDisplayGroups.length;
  const isLoggedAsRetailer = isRetailer && isLoggedAs;
  const shouldShow =
    hasEnoughDataToCheckFieldDisplayability &&
    isLoggedAsRetailer &&
    deprecatedPatches.length;

  useEffect(() => {
    if (shouldShow) {
      track({
        category: 'product',
        action: 'deprecated_patches_displayed',
      });
    }
  }, [shouldShow]);

  if (!shouldShow) {
    return null;
  }
  const cancelPatch = (patch) => {
    dispatch(cancelDataOpsPatch({ patch, label: 'Deprecated patch' }));
    track({
      category: 'product',
      action: 'deprecated_patch_cancel',
    });
  };

  const deprecatedPatchesFieldNameColumnLabel = i18n.t(
    'frontproductstream.product_deprecated_dataops_patches.table_column_field.label',
    {
      defaultValue: 'Field',
    },
  );

  const deprecatedPatchesDescriptionColumnLabel = i18n.t(
    'frontproductstream.product_deprecated_dataops_patches.table_column_description.label',
    {
      defaultValue: 'Description',
    },
  );

  const deprecatedPatchesDescriptionColumnText = i18n.t(
    'frontproductstream.product_deprecated_dataops_patches.table_column_description.text',
    {
      defaultValue:
        'A collaborative edit is attached to this field that is not visible anymore',
    },
  );

  const headerContent = {
    text: i18n.t(
      'frontproductstream.product_deprecated_dataops_patches.header.text',
      { defaultValue: 'Only visible by SupplierXM Admin' },
    ),
  };

  const cancelButtonText = i18n.t(
    'frontproductstream.product_deprecated_dataops_patches.cancel.button',
    { defaultValue: 'Cancel' },
  );

  const title = i18n.t(
    'frontproductstream.product_deprecated_dataops_patches.section.title',
    { defaultValue: 'Deprecated patches' },
  );
  return (
    <AnchoredSection
      collapsible
      section={title}
      id="deprecated-patches"
      order={order}
      header={headerContent}
    >
      <div className={styles.deprecatedPatches}>
        <table className={styles.deprecatedPatchesTable}>
          <tbody>
            <tr className={classNames(styles.deprecatedPatchesHeader, 'row')}>
              <th className="col-xs-5">
                {deprecatedPatchesFieldNameColumnLabel}
              </th>
              <th className="col-xs-5">
                {deprecatedPatchesDescriptionColumnLabel}
              </th>
            </tr>
            {deprecatedPatches.map((patch) => (
              <tr
                key={`patch-${patch.id}`}
                className={classNames(styles.deprecatedPatchesLine, 'row')}
              >
                <td className="col-xs-5">{patch.fieldName}</td>
                <td className="col-xs-5">
                  {deprecatedPatchesDescriptionColumnText}
                </td>
                <td className="col-xs-2">
                  <Button
                    link
                    content={cancelButtonText}
                    onClick={() => cancelPatch(patch)}
                  />
                </td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>
    </AnchoredSection>
  );
};

import { get, sortBy } from 'lodash/fp';
import { v4 as uuid } from 'uuid';

import { TypePackagingLabels } from 'constants/typePackaging';
import { getIsConsumerUnit, getIsDisplayUnit } from 'core/api/productversion';
import { get as immutableGet, size, toJsIfImmutable } from 'utils/immutable';

import { getReference } from '../../core/api/product';

import {
  Child,
  CurrentVersionInfo,
  DataMap,
  Data as Hierarchy,
  HierarchyMap,
  MainHierarchyUnit,
} from './structures';

const LOGISTICAL_UNIT_ENTITY_ID_SEPARATOR = '_';

export const typePackagingOptions = [
  {
    id: TypePackagingLabels.PALLET.id,
    label: TypePackagingLabels.PALLET.singular,
  },
  { id: TypePackagingLabels.CASE.id, label: TypePackagingLabels.CASE.singular },
  {
    id: TypePackagingLabels.PACK.id,
    label: TypePackagingLabels.PACK.logisticalSingular,
  },
  { id: TypePackagingLabels.EACH.id, label: TypePackagingLabels.EACH.singular },
];

export const isBaseProductInTree = (
  hierarchyMap: HierarchyMap,
  mainHierarchyUnitId: string,
): boolean => {
  return Object.values(hierarchyMap).some((children: Child[]) =>
    children.some((child: Child) => child.id === mainHierarchyUnitId),
  );
};

export const getAllowedTypePackagingOptions = (typePackagingId: number) =>
  typePackagingOptions.filter(
    (option) =>
      option.id <= typePackagingId &&
      option.id !== TypePackagingLabels.PALLET.id, // Pallet is only allowed at the root level.
  );

/**
 * @param {Object} options
 * @param {string} options.internalRootId
 * @param {string} options.gtin
 * @returns {string} internalRootId_gtin
 */
export const formatLogisticalUnitEntityId = (
  internalRootId,
  gtin,
  productIdentifier,
) => {
  const reference = gtin || productIdentifier;
  return `${internalRootId}${LOGISTICAL_UNIT_ENTITY_ID_SEPARATOR}${reference}`;
};

/**
 * @param {string} entityId
 * @returns {[string, string]} [rootInternalId, reference (gtin or productIdentifier)]
 */
export const parseLogisticalUnitEntityId = (entityId) => [
  entityId.slice(0, entityId.indexOf(LOGISTICAL_UNIT_ENTITY_ID_SEPARATOR)),
  entityId.slice(entityId.indexOf(LOGISTICAL_UNIT_ENTITY_ID_SEPARATOR) + 1), // Because productIdentifier can include some "_"
];
export function extractDataFromHierarchies(
  hierarchies: Hierarchy[] = [],
  permissions = [],
  previousDataMap = {} as DataMap,
): {
  roots: string[];
  dataMap: DataMap;
  hierarchyMap: HierarchyMap;
} {
  const queue: {
    unit: Hierarchy;
    level: number;
    isPatchedHierarchy: boolean;
    rootId?: string;
    parentId?: string;
  }[] = sortBy((unit) => Boolean(unit.isPatch), hierarchies).map((unit) => ({
    unit,
    level: 0,
    isPatchedHierarchy: Boolean(unit.isPatch),
  }));
  const roots = new Set();
  const notPatchedGtins = new Set();
  const dataMap: any = {};
  const hierarchyMap: any = {};
  const addedToHierarchy = {};
  // @ts-expect-error: getGtinToInternalIdsArray conditinally returns array or object, making typing not safe
  const [notPachedGtinToId, patchedGtinToId] = getGtinToInternalIdsArray(
    previousDataMap,
    { splitByPatch: true },
  );

  const getInternalId = (unit) => {
    const internalIds = (unit.isPatch ? patchedGtinToId : notPachedGtinToId)[
      unit.gtin
    ];
    if (Array.isArray(internalIds) && internalIds.length) {
      return internalIds.shift();
    }
    return uuid();
  };

  while (queue.length > 0) {
    const { unit, level, rootId, parentId, isPatchedHierarchy } =
      queue.shift() || { isPatchedHierarchy: false };
    if (unit) {
      const { version, children, quantity, ...unitData } = unit;
      let internalId = getInternalId(unit);
      const internalRootId = rootId || internalId;
      const isRoot = level === 0;
      const rootAlreadyExists = isRoot && roots.has(internalId);

      if (rootAlreadyExists) {
        internalId = uuid();
      }

      if (isRoot) {
        roots.add(internalId);
      }

      dataMap[internalId] = {
        ...unitData,
        gtin: version.gtin || unitData.gtin,
        productIdentifier:
          version.productIdentifier || unitData.productIdentifier,
        reference:
          version.gtin ||
          unitData.gtin ||
          version.productIdentifier ||
          unitData.productIdentifier,
        internalId,
        level,
        isRoot,
        internalRootId,
        parentId,
        isAnomaly: rootAlreadyExists,
        isPatchedHierarchy,
        isPatchableVersion:
          isPatchedHierarchy && notPatchedGtins.has(version.gtin),
        version: { ...version, permissions },
      };

      hierarchyMap[internalId] = [];

      if (!unit?.isPatch) {
        notPatchedGtins.add(version.gtin);
      }

      if (children?.length > 0) {
        queue.push(
          ...children.map((unitChild) => ({
            unit: unitChild,
            level: (level || 0) + 1,
            rootId: internalRootId,
            parentId: internalId,
            isPatchedHierarchy,
          })),
        );
      }

      const parentChildKey = `${parentId}/${internalId}`;
      if (
        parentId &&
        hierarchyMap[parentId] &&
        !addedToHierarchy[parentChildKey]
      ) {
        hierarchyMap[parentId].push({
          id: internalId,
          quantity,
          isConsumerUnit: version.isConsumerUnit,
        });
        addedToHierarchy[parentChildKey] = true;
      }
    }
  }
  return { roots: [...roots] as string[], dataMap, hierarchyMap };
}

export function getGtinToInternalIdsArray(
  dataMap: DataMap,
  {
    filterByPatch,
    splitByPatch,
  }: { filterByPatch?: boolean; splitByPatch?: boolean } = {
    filterByPatch: false,
    splitByPatch: false,
  },
) {
  const notPatched = {};
  const patched = {};
  for (const [internalId, unit] of Object.entries(dataMap)) {
    if (internalId && unit.gtin) {
      if (unit.isPatch) {
        if (patched[unit.gtin]) {
          patched[unit.gtin].push(internalId);
        } else {
          patched[unit.gtin] = [internalId];
        }
      } else {
        if (notPatched[unit.gtin]) {
          notPatched[unit.gtin].push(internalId);
        } else {
          notPatched[unit.gtin] = [internalId];
        }
      }
    }
  }
  if (typeof filterByPatch === 'boolean') {
    return filterByPatch ? patched : notPatched;
  }
  if (splitByPatch) {
    return [notPatched, patched];
  }
  return Object.assign(notPatched, patched);
}

export function getReferenceToInternalIds(
  dataMap: { [key: string]: Hierarchy },
  { filterByPatch, splitByPatch } = {
    filterByPatch: false,
    splitByPatch: false,
  },
) {
  const notPatched = {};
  const patched = {};
  for (const [internalId, unit] of Object.entries(dataMap)) {
    const reference = unit.gtin || unit.productIdentifier;
    if (internalId && reference) {
      if (unit.isPatch) {
        patched[reference] = internalId;
      } else {
        notPatched[reference] = internalId;
      }
    }
  }
  if (typeof filterByPatch === 'boolean') {
    return filterByPatch ? patched : notPatched;
  }
  if (splitByPatch) {
    return [notPatched, patched];
  }
  return Object.assign(notPatched, patched);
}

/**
 * @param {any[]} hierarchies
 * @returns {string[]} gtins
 */
export function extractReferencesFromHierarchies(
  hierarchies: Hierarchy[] = [],
) {
  const references = new Set();
  let units = [...hierarchies];
  while (units.length > 0) {
    const unit = units.shift();
    const reference = getReference(unit);
    if (reference) {
      references.add(reference);
    } else if (unit?.productIdentifier) {
      references.add(unit.productIdentifier);
    }
    if (unit?.children && Array.isArray(unit.children)) {
      units = units.concat(unit.children);
    }
  }
  return [...references];
}

export function findUnitByRootAndReference({
  dataMap = {},
  hierarchyMap = {},
  rootInternalId,
  reference,
}) {
  if (rootInternalId && reference) {
    const queue = [rootInternalId];
    while (queue.length > 0) {
      const internalId = queue.shift();
      const unit = dataMap[internalId];
      if (unit?.gtin === reference || unit?.productIdentifier === reference) {
        return [unit, internalId];
      }
      const level = hierarchyMap[internalId] || [];
      queue.push(...level.map((child) => child.id));
    }
  }
  return [];
}

export function convertBackToTree(roots, dataMap, hierarchyMap) {
  if (!roots) {
    return [];
  }
  return roots.map((rootId) => {
    const children = hierarchyMap[rootId];
    const childrenQuantities = children.map((child) => child.quantity);
    const childrenRoots = children.map((child) => child.id);
    const childrenTree = convertBackToTree(
      childrenRoots,
      dataMap,
      hierarchyMap,
    ).map((child, index) => ({
      ...child,
      quantity: childrenQuantities[index],
    }));
    const data = dataMap[rootId];
    return { children: childrenTree, ...data };
  });
}

export function isConsumerOrDisplayUnit(version) {
  return !!getIsConsumerUnit(version) || !!getIsDisplayUnit(version);
}

export function isMainHierarchyUnit(version, children) {
  return (
    isConsumerOrDisplayUnit(version) || (!!children && children.length > 1)
  );
}

function containsOnlyVariants(id, dataMap, hierarchyMap, currentVersionInfo) {
  return (
    hierarchyMap[id] &&
    hierarchyMap[id].length &&
    hierarchyMap[id]
      .map((c) => dataMap[c.id].gtin)
      .every((g) => currentVersionInfo.textileVariantGTINs?.includes(g))
  );
}

export function getMainHierarchyUnit(
  internalId: string,
  dataMap: DataMap,
  hierarchyMap: HierarchyMap,
  currentVersionInfo: CurrentVersionInfo,
  depth = 0,
): MainHierarchyUnit | null {
  if (
    containsOnlyVariants(internalId, dataMap, hierarchyMap, currentVersionInfo)
  ) {
    // All children are variants of the current version, return the current version (model);
    const foundModel = Object.entries(dataMap).find(
      ([, d]) => d.gtin === currentVersionInfo.gtin,
    );
    if (!foundModel) {
      return null;
    }
    const [modelId] = foundModel;
    return {
      id: modelId,
      reference:
        currentVersionInfo.gtin || currentVersionInfo.productIdentifier,
      gtin: currentVersionInfo.gtin,
      depth: depth + 1,
    };
  }

  if (
    isMainHierarchyUnit(
      get([internalId, 'version'], dataMap),
      hierarchyMap[internalId],
    )
  ) {
    const gtin = get([internalId, 'gtin'], dataMap);
    const productIdentifier = get([internalId, 'productIdentifier'], dataMap);
    return {
      id: internalId,
      gtin,
      productIdentifier,
      reference: gtin || productIdentifier,
      depth,
    };
  }

  const mainHierarchyUnits = hierarchyMap[internalId].map((child) =>
    getMainHierarchyUnit(
      child.id,
      dataMap,
      hierarchyMap,
      currentVersionInfo,
      depth + 1,
    ),
  );

  if (mainHierarchyUnits.length) {
    return mainHierarchyUnits[0];
  }
  return null;
}

export function isStandardTextileHierarchy(
  id,
  dataMap,
  hierarchyMap,
  currentVersionInfo,
) {
  if (containsOnlyVariants(id, dataMap, hierarchyMap, currentVersionInfo)) {
    return true;
  }
  if (isMainHierarchyUnit(get([id, 'version'], dataMap), hierarchyMap[id])) {
    return false;
  }
  return hierarchyMap[id]
    .map((child) =>
      isStandardTextileHierarchy(
        child.id,
        dataMap,
        hierarchyMap,
        currentVersionInfo,
      ),
    )
    .some((e) => e);
}

export function filterSharableHierarchies(
  currentVersionInfo,
  roots,
  dataMap: DataMap,
  hierarchyMap,
) {
  if (!roots) {
    return [];
  }
  const sharableHierarchies: Hierarchy[] = [];
  roots.forEach((id) => {
    const mainHierarchyUnit = getMainHierarchyUnit(
      id,
      dataMap,
      hierarchyMap,
      currentVersionInfo,
    );
    if (
      !!mainHierarchyUnit &&
      getReference(dataMap[id]) &&
      getReference(mainHierarchyUnit) === getReference(currentVersionInfo)
    ) {
      sharableHierarchies.push(dataMap[id]);
    }
  });
  return sharableHierarchies;
}

export function isHeterogeneousLogisticalUnit(
  isDespatchUnit: boolean,
  isDisplayUnit: boolean,
  isConsumerUnit: boolean,
  typePackagingId: number,
  isMadeOf: [
    {
      targetProduct?: {
        version: {
          isConsumerUnit: boolean;
        };
      };
    },
  ],
) {
  return (
    isDespatchUnit &&
    !isDisplayUnit &&
    !isConsumerUnit &&
    typePackagingId === TypePackagingLabels.CASE.id &&
    size(isMadeOf) > 1 &&
    isMadeOf.every((child) => {
      return (
        immutableGet(child, 'targetProduct.version.isConsumerUnit', false) ||
        immutableGet(child, 'targetProduct')?.isConsumerUnit ||
        immutableGet(child, 'targetProduct') === null
      );
    })
  );
}

export function productIsHeterogeneousLogisticalUnit(
  product,
  path = 'versionData',
) {
  const versionData = toJsIfImmutable(immutableGet(product, path));
  return isHeterogeneousLogisticalUnit(
    versionData?.isDespatchUnit,
    versionData?.isDisplayUnit,
    versionData?.isConsumerUnit,
    versionData?.typePackaging?.id,
    versionData?.isMadeOf,
  );
}

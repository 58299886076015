export const PRODUCTS = {
  type: 'products',
  menu: false,
  search: true,
  createProduct: true,
  userColumns: 'prefered',
  orgColumns: 'allowed',
  emptyCells: { left: 3, right: 2 },
  size: { row: { height: 66 } },
};

export const PRODUCTS_WITH_MENU = {
  type: PRODUCTS.type,
  menu: true,
  search: true,
  createProduct: true,
  userColumns: 'prefered',
  orgColumns: 'allowed',
  emptyCells: { left: 3, right: 2 },
  size: { row: { height: 66 } },
};

export const PRODUCTS_TO_REVIEW = {
  type: 'review',
  menu: true,
  search: true,
  createProduct: false,
  userColumns: 'review',
  orgColumns: 'review',
  emptyCells: { left: 2, right: 2 },
  size: { row: { height: 66 } },
};

export const ASSIGNATION = {
  type: 'assignation',
  menu: true,
  search: true,
  createProduct: true,
  userColumns: 'assignation',
  orgColumns: 'assignation',
  emptyCells: { left: 0, right: 1 },
  size: {
    row: { height: 66 },
    action: { width: 160, margin: 10 },
  },
};

export const ARCHIVED_PRODUCTS = {
  type: 'archived',
  menu: true,
  search: true,
  createProduct: true,
  userColumns: 'prefered',
  orgColumns: 'allowed',
  emptyCells: { left: 3, right: 2 },
  size: { row: { height: 66 } },
};

export const SOURCING = {
  type: 'sourcing',
  menu: false,
  search: true,
  createProduct: false,
  userColumns: 'sourcing',
  orgColumns: 'sourcing',
  emptyCells: { left: 2, right: 1 },
  size: {
    row: { height: 100 },
    action: { width: 120, margin: 10 },
  },
};

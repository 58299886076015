import classNames from 'classnames';
import PropTypes from 'prop-types';

import './index.scss';

export function CoreBodyLayout({ className, children }) {
  return (
    <div className={classNames('CoreBodyLayout', className)}>{children}</div>
  );
}

CoreBodyLayout.propTypes = {
  className: PropTypes.string,
  children: PropTypes.node,
};

export default CoreBodyLayout;

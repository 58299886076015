import { createSelector } from '@reduxjs/toolkit';

import { MODULE_REDUCER_KEY } from 'constants/reducer';

import { KEY } from '../reducers';

export const selectBulkActionState = (state) => state[MODULE_REDUCER_KEY][KEY];

export const selectBulkActionVisible = createSelector(
  selectBulkActionState,
  (moduleState) => moduleState.get('visible'),
);

export const selectEntitiesToBulk = createSelector(
  selectBulkActionState,
  (moduleState) => moduleState.get('entities'),
);

export const selectEntitiesToBulkInProgress = createSelector(
  selectBulkActionState,
  (moduleState) => moduleState.get('inProgress'),
);

export const selectBulkedEntitiesInProgressCount = createSelector(
  selectBulkActionState,
  (moduleState) => moduleState.get('count'),
);

export const selectBulkedEntitiesInProgressStatus = createSelector(
  selectBulkActionState,
  (moduleState) => moduleState.get('status'),
);

export const selectEntitiesToBulkDone = createSelector(
  selectBulkActionState,
  (moduleState) => moduleState.get('done'),
);

export const selectBulkedEntitiesErrors = createSelector(
  selectBulkActionState,
  (moduleState) => moduleState.get('errors'),
);

export const selectBulkedEntitiesSuccess = createSelector(
  selectBulkActionState,
  (moduleState) => moduleState.get('success'),
);

import PropTypes from 'prop-types';
import { PureComponent } from 'react';
import ImmutablePropTypes from 'react-immutable-proptypes';
import { connect } from 'react-redux';
import { createStructuredSelector } from 'reselect';

import { openRefuseModal } from 'modules/product-page/modules/refuse-modal/actions';
import { REFUSED_SHARING_UNIT } from 'modules/product-page/modules/refuse-modal/constants';
import i18n from 'utils/i18n';

import { selectSharingUnitsInit } from '../selectors';

import SharingUnits from '.';

const mapStateToProps = createStructuredSelector({
  sharingUnitsInitIds: selectSharingUnitsInit,
});

const mapDispatchToProps = {
  openRefuseModal,
};

class SharingUnitsInit extends PureComponent {
  static propTypes = {
    // own props
    isReadOnly: PropTypes.bool,
    order: PropTypes.number.isRequired,

    // props from store
    applyRequestsInProgress: PropTypes.bool.isRequired,
    sharingUnitsInitIds: ImmutablePropTypes.list.isRequired,

    // handlers
    acceptListingInit: PropTypes.func.isRequired,

    // actions
    openRefuseModal: PropTypes.func.isRequired,
    hasFeatureReviewEngine: PropTypes.bool,
  };

  onAccept = (sharingUnitId) => {
    const { acceptListingInit } = this.props;
    acceptListingInit({ sharingUnitId });
  };

  onRefuse = (sharingUnitId) => {
    this.props.openRefuseModal({
      entityId: sharingUnitId,
      entityType: REFUSED_SHARING_UNIT,
      title: i18n.t('Listing refusal'),
    });
  };

  render() {
    const {
      applyRequestsInProgress,
      isReadOnly,
      order,
      sharingUnitsInitIds,
      hasFeatureReviewEngine,
    } = this.props;
    if (sharingUnitsInitIds.size < 1) {
      return null;
    }

    return (
      <SharingUnits
        applyRequestsInProgress={applyRequestsInProgress}
        collapsed={false}
        id="SharingUnit__init"
        isNew
        isReadOnly={isReadOnly}
        onAccept={this.onAccept}
        onRefuse={this.onRefuse}
        order={order}
        sharingUnitIds={sharingUnitsInitIds}
        hasFeatureReviewEngine={hasFeatureReviewEngine}
      />
    );
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(SharingUnitsInit);

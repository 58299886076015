import { List, Map } from 'immutable';

export const formatCause = (cause) => {
  // If there is no cause or the cause already is an immutable map
  if (!cause || cause.get) {
    return cause;
  }

  return Map({
    action: cause,
  });
};

export const isEmptyDiff = (diff: Map<string, any>) => {
  if (!diff) {
    return true;
  }
  const isEmpty = (value) => {
    if (value == null || value === '' || (value.isEmpty && value.isEmpty())) {
      return true;
    }
    if (!List.isList(value)) {
      return false;
    }
    return value.every(isEmpty);
  };
  return !(isEmpty(diff.get('new')) && isEmpty(diff.get('old')));
};

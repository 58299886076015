import { List, Map, Set } from 'immutable';
import PropTypes from 'prop-types';
import { PureComponent } from 'react';
import ImmutablePropTypes from 'react-immutable-proptypes';
import { connect } from 'react-redux';
import { createStructuredSelector } from 'reselect';

import Search from 'components/ui/input/search';
import {
  hasAssortmentManagement,
  isBigManufacturer,
  isEligibleForSourcing,
} from 'core/api/organization-settings';
import { getOrganizationSettings, managesOrganization } from 'core/api/user';
import { saveVisibility } from 'modules/product-page/actions';
import {
  selectHasOnlyExclusiveProducts,
  selectHasUnshareProduct,
  selectUser,
} from 'modules/user';
import { init } from 'modules/view-as/actions';
import {
  selectCanUpdateSharingUnit,
  selectIsExclusive,
  selectIsPublished,
  selectProductId,
} from 'reducers/productVersion';
import i18n from 'utils/i18n';
import { separateActions } from 'utils/redux';
import { sortAsc } from 'utils/sort';

import {
  addRecipients,
  deactivateGroups,
  openUnshareProduct,
  prepareForGroup,
  validateProduct,
} from '../actions';
import {
  selectAddingRecipients,
  selectGroups,
  selectIsUnshareModalOpened,
  selectPreparingGroups,
  selectValidationByRecipientId,
} from '../selectors';

import GroupPublicationSummary from './group';
import './index.scss';
import PublicationSummaryNetworkVisibility from './network-visibility';
import UnshareModal from './unshare/modal';

const mapStateToProps = createStructuredSelector({
  productId: selectProductId,
  isPublished: selectIsPublished,
  isExclusive: selectIsExclusive,
  groups: selectGroups,
  canUpdateSharingUnit: selectCanUpdateSharingUnit,
  validationByRecipientId: selectValidationByRecipientId,
  isEligibleForSourcing: (state) =>
    isEligibleForSourcing(getOrganizationSettings(selectUser(state))),
  hasOnlyExclusiveProducts: selectHasOnlyExclusiveProducts,
  canPrepareProductForGroup: (state) =>
    hasAssortmentManagement(getOrganizationSettings(selectUser(state))) &&
    managesOrganization(selectUser(state)),
  addingRecipients: selectAddingRecipients,
  preparingGroups: selectPreparingGroups,
  hasUnshareProduct: selectHasUnshareProduct,
  isUnshareModalOpened: selectIsUnshareModalOpened,
  bigManufacturer: (state) =>
    isBigManufacturer(getOrganizationSettings(selectUser(state))),
});

const mapDispatchToProps = {
  validateProduct,
  addRecipient: (payload) => addRecipients([payload]),
  deactivateGroups,
  saveVisibility,
  prepareForGroup,
  openUnshareProduct,
  init,
};

export class PublicationSummary extends PureComponent {
  static propTypes = {
    productId: PropTypes.number.isRequired,
    isPublished: PropTypes.bool.isRequired,
    isExclusive: PropTypes.bool.isRequired,
    groups: ImmutablePropTypes.list.isRequired,
    canUpdateSharingUnit: PropTypes.bool.isRequired,
    addingRecipients: ImmutablePropTypes.set,
    preparingGroups: ImmutablePropTypes.set,
    recipientsToAdd: ImmutablePropTypes.set,
    validationByRecipientId: ImmutablePropTypes.map,
    isEligibleForSourcing: PropTypes.bool,
    hasOnlyExclusiveProducts: PropTypes.bool,
    canPrepareProductForGroup: PropTypes.bool,
    hasUnshareProduct: PropTypes.bool,
    canRemoveRecipient: PropTypes.func,
    onRemoveRecipient: PropTypes.func,
    isUnshareModalOpened: PropTypes.bool,
    actions: PropTypes.shape({
      init: PropTypes.func,
      validateProduct: PropTypes.func,
      addRecipient: PropTypes.func.isRequired,
      saveVisibility: PropTypes.func,
      prepareForGroup: PropTypes.func,
      openUnshareProduct: PropTypes.func,
      deactivateGroups: PropTypes.func,
    }).isRequired,
    bigManufacturer: PropTypes.bool,
  };

  static defaultProps = {
    addingRecipients: Set(),
    preparingGroups: Set(),
    isExclusive: null,
    isEligibleForSourcing: false,
  };

  state = {
    searchQuery: '',
    filteredGroupIDs: null,
  };

  componentDidMount() {
    const { actions, groups } = this.props;

    actions.init?.();
    if (actions.validateProduct && groups.size > 0) {
      const recipientIds = this.getRecipientIdsToValidate(groups);
      if (recipientIds.size > 0) {
        actions.validateProduct(recipientIds);
      }
    }
  }

  onAddRecipient = ({ recipient, group }) => {
    this.props.actions.addRecipient({ recipient, group });
  };

  onRemoveRecipient = ({ recipient, group }) => {
    this.props.onRemoveRecipient({ recipient, group });
  };

  onPrepare = (groupId, prepare) => {
    if (this.props.actions.prepareForGroup) {
      this.props.actions.prepareForGroup(groupId, prepare);
    }
  };

  onDeactivateGroups = (groupIds) => {
    this.props.actions.deactivateGroups(groupIds);
  };

  onUnshare = (recipient) => {
    this.props.actions.openUnshareProduct(recipient);
  };

  getRecipientIdsToValidate(groups) {
    return groups
      .reduce((acc, g) => acc.concat(g.get('members')), List())
      .filter((r) => r.get('toggled'))
      .map((r) => r.get('id'));
  }

  filterGroups = () => (search) => {
    this.setState({ searchQuery: search });
    if (!search) {
      return this.setState({ filteredGroupIDs: null });
    }
    const filteredGroupIDs = this.props.groups
      .filter(
        (group) =>
          group
            .getIn(['group', 'name'])
            .toLowerCase()
            .includes(search.toLowerCase()) ||
          group
            .get('members')
            .filter((member) =>
              member
                .get('nameLegal')
                .toLowerCase()
                .includes(search.toLowerCase()),
            ).size > 0,
      )
      .map((group) => group.getIn(['group', 'id']));
    return this.setState({ filteredGroupIDs });
  };

  sortGroups(groups) {
    // Sort by importance based on recipients then alphabetically:
    //  - 5: contains recipients with product in active range
    //  - 10: others
    const getGroupRank = (g) => {
      const members = g.get('members');
      if (members.some((m) => m.get('hasProductInActiveRange'))) {
        return 5;
      }
      return 10;
    };
    const groupsWithRanks = groups.map((g) => g.set('rank', getGroupRank(g)));
    return groupsWithRanks
      .sort((a, b) =>
        sortAsc(a.getIn(['group', 'name']), b.getIn(['group', 'name'])),
      )
      .sort((a, b) => sortAsc(a.get('rank'), b.get('rank')));
  }

  render() {
    const {
      productId,
      isPublished,
      isExclusive,
      canUpdateSharingUnit,
      canPrepareProductForGroup,
      groups,
      validationByRecipientId,
      addingRecipients,
      recipientsToAdd,
      preparingGroups,
      canRemoveRecipient,
      onRemoveRecipient,
      actions,
      hasUnshareProduct,
      isUnshareModalOpened,
      bigManufacturer,
    } = this.props;

    let groupsToDisplay = groups;
    if (this.state.filteredGroupIDs) {
      groupsToDisplay = this.props.groups.filter((group) =>
        this.state.filteredGroupIDs.includes(group.getIn(['group', 'id'])),
      );
    }

    return (
      <div className="PublicationSummary">
        <div className="PublicationSummary__search">
          <Search
            query={this.state.searchQuery}
            placeholder={i18n.t(
              'frontproductstream.publication_summary.search_channel.placeholder',
              {
                defaultValue: 'Search for a channel',
              },
            )}
            updateSearchQuery={this.filterGroups()}
            isTransparent
          />
        </div>
        {!this.props.hasOnlyExclusiveProducts && (
          <PublicationSummaryNetworkVisibility
            isExclusive={isExclusive}
            isEligibleForSourcing={this.props.isEligibleForSourcing}
            saveVisibility={actions.saveVisibility}
          />
        )}
        <div>
          {this.sortGroups(groupsToDisplay).map((group) => (
            <GroupPublicationSummary
              key={group.getIn(['group', 'id'])}
              productId={productId}
              isPublished={isPublished}
              isExclusive={isExclusive}
              group={group}
              validationByRecipientId={validationByRecipientId || Map()}
              canUpdateSharingUnit={canUpdateSharingUnit}
              addingRecipients={addingRecipients}
              onAddRecipient={this.onAddRecipient}
              canRemoveRecipient={canRemoveRecipient}
              onRemoveRecipient={
                onRemoveRecipient ? this.onRemoveRecipient : null
              }
              recipientsToAdd={recipientsToAdd}
              canPrepareProductForGroup={canPrepareProductForGroup}
              isPreparing={preparingGroups.includes(
                group.getIn(['group', 'id']),
              )}
              onPrepare={this.onPrepare}
              onUnshare={hasUnshareProduct ? this.onUnshare : null}
              onDeactivateGroups={this.onDeactivateGroups}
              isBigManufacturer={bigManufacturer}
            />
          ))}
          {hasUnshareProduct && isUnshareModalOpened && <UnshareModal />}
        </div>
      </div>
    );
  }
}

export default connect(
  mapStateToProps,
  mapDispatchToProps,
  separateActions,
)(PublicationSummary);

import PropTypes from 'prop-types';
import { PureComponent } from 'react';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import { createStructuredSelector } from 'reselect';

import {
  selectProductKeyId,
  selectTargetMarketId,
} from 'reducers/productVersion';
import * as routes from 'routes';
import { withQuery } from 'utils/query';
import { fill } from 'utils/routing';

export class CorrectLink extends PureComponent {
  static propTypes = {
    productKeyId: PropTypes.number,
    targetOrganizationIds: PropTypes.arrayOf(PropTypes.number).isRequired,
    children: PropTypes.node,
  };

  formatProductPageGeneralUrl() {
    const { productKeyId, targetOrganizationIds } = this.props;

    return withQuery(fill(routes.productPage, productKeyId), {
      viewAs: targetOrganizationIds,
    });
  }

  render() {
    const url = this.formatProductPageGeneralUrl();
    return <Link to={url}> {this.props.children} </Link>;
  }
}

const mapStateToProps = createStructuredSelector({
  targetMarketId: selectTargetMarketId,
  productKeyId: selectProductKeyId,
});
export default connect(mapStateToProps)(CorrectLink);

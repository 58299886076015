import { notificationError } from 'actions/notification';
import { START_LOADING, STOP_LOADING } from 'constants/events/navigation';
import { getOrganizationId } from 'core/api/user';
import { authApiImmutable } from 'resources/authApi';
import i18n from 'utils/i18n';

import { RECEIVE_USER_ORGANIZATION_PERMISSIONS } from './action-types';

export const getOrganizationPermissions = () => (dispatch, getState) => {
  const { user } = getState();
  const organizationId = getOrganizationId(user);
  if (!organizationId) {
    return Promise.reject();
  }
  dispatch({ type: START_LOADING });

  return authApiImmutable
    .userManagesOrganizationShow(organizationId)
    .then((response) => {
      dispatch({ type: STOP_LOADING });
      dispatch({
        type: RECEIVE_USER_ORGANIZATION_PERMISSIONS,
        payload: {
          permissions: response.data.data.get('permissions') || [],
        },
      });
    })
    .catch(() => {
      dispatch({ type: STOP_LOADING });
      dispatch(
        notificationError(
          i18n.t(
            'frontproductstream.user.fetch_organization_permissions_error.notification',
            {
              defaultValue:
                "An error occured while retrieving your organization's permissions",
            },
          ),
        ),
      );
    });
};

import asyncComponent from 'core/modules/async/component';
import registerModule from 'core/modules/async/register';

export const productReviewModule = async () =>
  registerModule(
    await import(/* webpackChunkName: "product-review" */ './async'),
  );

export const ProductReview = asyncComponent(productReviewModule, {
  componentPath: 'ProductReview',
  withReset: false,
});

export const ProductReviewFieldInfo = asyncComponent(productReviewModule, {
  componentPath: 'ProductReviewFieldInfo',
  withReset: false,
});

export const ProductReviewDot = asyncComponent(productReviewModule, {
  componentPath: 'ProductReviewDot',
  withReset: false,
});

import classNames from 'classnames';
import { Map } from 'immutable';
import { set } from 'lodash/fp';
import { array, bool, func, object, shape } from 'prop-types';
import { Component } from 'react';
import ImmutablePropTypes from 'react-immutable-proptypes';
import { connect } from 'react-redux';

import { SwitchButton } from '@alkem/react-ui-button';
import { Tooltip } from '@alkem/react-ui-tooltip';

import { updateNewSpecificPackshot } from 'actions/media';
import DocumentAsset from 'components/ui/form/asset-list/asset/document';
import PictureAsset from 'components/ui/form/asset-list/asset/picture';
import RecipeAsset from 'components/ui/form/asset-list/asset/recipe';
import VideoAsset from 'components/ui/form/asset-list/asset/video';
import MediaErrors from 'components/ui/form/media-errors';
import { ENTITY_TYPE_PRODUCTVERSION_OVERRIDE } from 'constants/entities';
import { ASSET_TYPE } from 'constants/media';
import { selectAssets, selectSpecificAssets } from 'reducers/media';
import { StringOrNumber } from 'types';
import i18n from 'utils/i18n';
import { get } from 'utils/immutable';

import { toggleStatus } from '../../actions';
import {
  selectActivateAssetInProgress,
  selectAssetStatusByRecipient,
  selectMasterAssetStatus,
} from '../../selectors';

import './specific-assets.scss';

const mapStateToProps = (state) => ({
  master: selectAssets(state),
  masterStatus: selectMasterAssetStatus(state),
  statusByRecipient: selectAssetStatusByRecipient(state),
  requestsInProgress: selectActivateAssetInProgress(state),
  specificAssets: selectSpecificAssets(state),
});

const mapDispatchToProps = {
  toggleStatus,
  updateNewSpecificPackshot,
};

const AssetTypes = (itemType) =>
  shape({
    documents: itemType,
    enriched_contents: itemType,
    pictures: itemType,
    videos: itemType,
  });

export class SpecificAssets extends Component {
  static propTypes = {
    // props
    recipientId: StringOrNumber.isRequired,
    readOnly: bool,
    // store
    master: object,
    specificAssets: array,
    masterStatus: AssetTypes(object).isRequired,
    statusByRecipient: ImmutablePropTypes.map.isRequired,
    requestsInProgress: ImmutablePropTypes.map.isRequired,
    // actions
    toggleStatus: func.isRequired,
    updateNewSpecificPackshot: func,
  };

  static defaultProps = {
    readOnly: false,
  };

  toggleStatus = (category, assetId) => (isActive) => {
    const { recipientId } = this.props;
    this.props.toggleStatus(recipientId, category, assetId, isActive);
  };

  getStatus = (category, assetId) => {
    const { masterStatus, recipientId, statusByRecipient } = this.props;

    const specificOfType = statusByRecipient.getIn([recipientId, category]);
    const mainOfType = get(masterStatus, category) || Map();

    return specificOfType && specificOfType.has(assetId)
      ? specificOfType.getIn([assetId, 'isActive'])
      : mainOfType.getIn([assetId, 'isActive']);
  };

  updateSpecificEntities = (pictureId, recipientId, newSpecificPackshot) => {
    this.props.updateNewSpecificPackshot(
      pictureId,
      recipientId,
      newSpecificPackshot,
    );
  };

  renderErrors() {
    const { recipientId } = this.props;
    return (
      <MediaErrors
        entityId={recipientId}
        entityKind={ENTITY_TYPE_PRODUCTVERSION_OVERRIDE}
        isReadOnly
        recipientId={recipientId}
      />
    );
  }

  renderSpecificContent = (category, asset) => {
    switch (category) {
      case ASSET_TYPE.PICTURE:
        return (
          <PictureAsset
            updateSpecificEntities={this.updateSpecificEntities}
            readOnly
            specificPicture
            asset={asset}
            recipientId={this.props.recipientId}
            productKeyId={asset.product_key_id}
          />
        );

      case ASSET_TYPE.VIDEO:
        return <VideoAsset readOnly asset={asset} />;

      case ASSET_TYPE.DOCUMENT:
        return (
          <DocumentAsset
            readOnly
            asset={asset}
            productKeyId={asset.product_key_id}
          />
        );

      case ASSET_TYPE.ENRICHED_CONTENT:
        return (
          <RecipeAsset
            readOnly
            asset={asset}
            productKeyId={asset.product_key_id}
          />
        );

      default:
        throw new Error(`unsupported asset category: ${category}`);
    }
  };

  renderAsset =
    (category, packshotId = -1) =>
    (asset) => {
      const { readOnly, recipientId, requestsInProgress } = this.props;
      const isPackshot = packshotId === asset.id;
      const localAsset = set('isPackshot', isPackshot, asset);
      const isActive = isPackshot || this.getStatus(category, asset.id);
      return (
        <li
          key={`${localAsset.type}-${localAsset.id}`}
          className={classNames('specific-asset', {
            'specific-asset-disabled': !isActive,
          })}
        >
          <div className="specific-asset-wrapper">
            {this.renderSpecificContent(category, localAsset)}
            <div className="specific-asset-toggle-active">
              <div
                data-tip
                data-for={`toggle-asset-${localAsset.id}-${recipientId}`}
                data-asset-crc32={asset.crc32}
              >
                <SwitchButton
                  testid={`use-asset-${localAsset.id}`}
                  checked={isActive}
                  content={i18n.t(
                    'frontproductstream.recipient_specific_block.send_asset.button',
                    { defaultValue: 'Send asset' },
                  )}
                  onChange={this.toggleStatus(category, localAsset.id)}
                  disabled={
                    readOnly ||
                    localAsset.isPackshot ||
                    requestsInProgress.hasIn([
                      recipientId,
                      category,
                      localAsset.id,
                    ])
                  }
                />
                {localAsset.isPackshot && (
                  <Tooltip
                    place="bottom"
                    id={`toggle-asset-${localAsset.id}-${recipientId}`}
                  >
                    {i18n.t(
                      'frontproductstream.recipient_specific_block.packshot_disabled.tooltip',
                      { defaultValue: 'Packshot cannot be disabled' },
                    )}
                  </Tooltip>
                )}
              </div>
              <div className="asset-not-active">
                {isActive
                  ? ''
                  : i18n.t(
                      'frontproductstream.recipient_specific_block.asset_not_active.text',
                      { defaultValue: 'Asset not sent' },
                    )}
              </div>
            </div>
          </div>
        </li>
      );
    };

  findPackshotId = (specificPictures, masterPictures) => {
    const specificPackshot = specificPictures.filter(
      (picture) => picture.isPackshot,
    );
    const mainPackshot = masterPictures.filter((picture) => picture.isPackshot);

    if (specificPackshot.length > 0 && specificPackshot[0].overriden === true) {
      return specificPackshot[0].id;
    }
    if (mainPackshot.length > 0 && mainPackshot[0]) {
      return mainPackshot[0].id;
    }
    return -1;
  };

  render() {
    const { recipientId, specificAssets, master } = this.props;
    const recipientIndex = specificAssets.findIndex(
      (elem) => elem.targetOrganization.id === recipientId,
    );
    const packshotId = this.findPackshotId(
      specificAssets[recipientIndex].data[ASSET_TYPE.PICTURE],
      master ? master[ASSET_TYPE.PICTURE] : [],
    );
    return (
      <>
        {this.renderErrors()}
        <ul className="specific-asset-row" data-recipient={recipientId}>
          {/* Separate pictures from other assets because of the introduction of specificPackshots */}
          {get(master, ASSET_TYPE.PICTURE, []).map(
            this.renderAsset(ASSET_TYPE.PICTURE, packshotId),
          )}
          {[
            ASSET_TYPE.VIDEO,
            ASSET_TYPE.DOCUMENT,
            ASSET_TYPE.ENRICHED_CONTENT,
          ].map((category) =>
            get(master, category, []).map(this.renderAsset(category)),
          )}
        </ul>
      </>
    );
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(SpecificAssets);

import { withRestrictedFeature } from 'core/modules/restricted';

import ReportingForbiddenView from '../wrapper/components/forbidden';

function ReportingForbidden() {
  return null;
}

export default withRestrictedFeature({
  View: ReportingForbidden,
  selectors: [() => false],
  ForbiddenView: ReportingForbiddenView,
});

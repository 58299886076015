import { List } from 'immutable';

import { hasTariff } from 'core/api/organization-settings';
import { getOrganizationSettings, managesOrganization } from 'core/api/user';
import { hasFeature } from 'modules/feature-flag';
import {
  FEATURE_PERMISSIONS_V3_SHARINGUNITTARIFF,
  PALPA_LISTING_TEMPLATE_TYPE,
} from 'modules/feature-flag/constants';
import { hasPermissionModule, hasPermissionV3 } from 'modules/permissions';
import {
  SHARING_UNIT_TARIFF_PERMISSION,
  SHOW_PERMISSION,
} from 'modules/permissions/const';
import { RecipientsImmutable, UserImmutable } from 'types';

import {
  filterRecipientsEligibleForTemplate,
  filterRecipientsWithTariffs,
} from './selectors';

const hasBasicSUTDisplay = (
  user: UserImmutable,
  recipients: RecipientsImmutable = List(),
) =>
  // Retailers
  hasTariff(getOrganizationSettings(user)) ||
  // Manufacturers:
  // Has a tariff recipient
  filterRecipientsWithTariffs(recipients).size > 0 ||
  // Can create templates + has an eliglibe recipient
  (hasFeature(user, PALPA_LISTING_TEMPLATE_TYPE) &&
    filterRecipientsEligibleForTemplate(recipients).size > 0);

export const hasSUTPermissions = (user, recipients) =>
  hasBasicSUTDisplay(user, recipients) &&
  (hasPermissionV3(user, FEATURE_PERMISSIONS_V3_SHARINGUNITTARIFF)
    ? hasPermissionModule(user, SHARING_UNIT_TARIFF_PERMISSION, SHOW_PERMISSION)
    : managesOrganization(user));

import classNames from 'classnames';
import { connect } from 'react-redux';

import HierarchySegmentAutocomplete from 'components/ui/autocomplete/productsegment/hierarchy';
import Field from 'components/ui/form/field';

class FormProductSegmentClassifier extends Field {
  getValue(data) {
    return data.value;
  }

  render() {
    const { value, params } = this.props;

    if (!this.isVisible()) {
      return null;
    }
    const hasLabel = this.shouldDisplayItem('label');

    const autocompleteClasses = `InputField__input FormAutocomplete__input ${
      hasLabel ? 'col-xs-8' : 'col-xs-12'
    }`;

    return (
      <div className={classNames(this.getClasses({ FormAutocomplete: true }))}>
        <div className="InputField row">
          {this.renderLabel('col-xs-4')}
          <span className={classNames(autocompleteClasses)}>
            <HierarchySegmentAutocomplete
              inputId={this.getId()}
              onChange={this.handleChange}
              isReadOnly={this.isReadOnly()}
              value={value}
              params={params}
            />
            {this.renderPlugins()}
          </span>
        </div>
      </div>
    );
  }
}

export default connect()(FormProductSegmentClassifier);

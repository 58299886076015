import { Button } from '@alkem/react-ui-button';

import {
  REFUSED_CONSUMER_UNIT,
  REFUSED_SHARING_UNIT,
} from 'modules/product-page/modules/refuse-modal/constants';
import i18n from 'utils/i18n';

export const CONSUMER_UNIT_TYPE = 'cu';
export const SHARING_UNIT_TYPE = 'su';

interface Props {
  type: string;
  entityId: number | string;
  applyInProgress: boolean;
  readOnly: boolean;
  onAccept: (entityId: number | string) => void;
  onRefuse: (parmas: { entityId: number | string; entityType: string }) => void;
}

export function ProductShareDiffActions({
  type,
  entityId,
  applyInProgress,
  readOnly = false,
  onAccept,
  onRefuse,
}: Props) {
  const refuseId = `product-share-diff-refuse-button-${type}-${entityId}`;
  const acceptId = `product-share-diff-accept-button-${type}-${entityId}`;
  return (
    <div className="RetailerProductPage__buttonBar">
      <Button
        id={refuseId}
        testid={refuseId}
        link
        onClick={() => {
          if (!applyInProgress) {
            if (type === CONSUMER_UNIT_TYPE) {
              onRefuse({
                entityId,
                entityType: REFUSED_CONSUMER_UNIT,
              });
            } else if (type === SHARING_UNIT_TYPE) {
              onRefuse({
                entityId,
                entityType: REFUSED_SHARING_UNIT,
              });
            }
          }
        }}
        content={i18n.t('Refuse this update')}
        disabled={readOnly || applyInProgress}
      />
      <Button
        id={acceptId}
        testid={acceptId}
        primary
        onClick={() => {
          if (!applyInProgress) {
            onAccept(entityId);
          }
        }}
        content={i18n.t('Accept this update')}
        disabled={readOnly || applyInProgress}
      />
    </div>
  );
}

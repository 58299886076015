import { call, put, race, select, take } from 'redux-saga/effects';

import { startLoading, stopLoading } from 'actions/navigation';
import { isAtLeastSupportLoggedAs, isSuperAdmin } from 'core/api/user';
import { selectUser } from 'modules/user';
import { logError } from 'utils/logging';

import { receiveReports } from '../../actions';
import { CANCEL_FETCH_REPORTS } from '../../actions/types';
import { fetchReportReferentials } from '../../api';

function* fetchReportsTask() {
  try {
    yield put(startLoading());
    const { data, error } = yield call(fetchReportReferentials);
    if (data) {
      const user = yield select(selectUser);
      yield put(
        receiveReports({
          reports: data,
          hasAdminReports: isSuperAdmin(user) || isAtLeastSupportLoggedAs(user),
        }),
      );
    }
    if (error) {
      throw error;
    }
  } catch (err) {
    logError(err);
  } finally {
    yield put(stopLoading());
  }
}

export default function* Reportsaga() {
  yield race({
    task: call(fetchReportsTask),
    cancel: take(CANCEL_FETCH_REPORTS),
  });
}

import { createSelector } from '@reduxjs/toolkit';
import { List } from 'immutable';
import { get as _get, flow } from 'lodash/fp';

import {
  createSelectorAreFiltersCollapsed,
  createSelectorSelectedFilterList,
  createSelectorSelectedFilterMap,
} from 'core/modules/list/selectors';
import {
  getAll as selectAllRecipients,
  getB2BRecipients as selectListingRecipients,
} from 'modules/recipients/reducers';
import {
  baseFieldName,
  selectFields,
  traverseDisplaygGroups,
} from 'modules/sharing-units/utils/core';
import { selectIsRetailer, selectOrganization } from 'modules/user';
import { RecipientImmutable, RecipientsImmutable } from 'types';
import { get } from 'utils/immutable';
import { MODULE_REDUCER_KEY } from 'utils/modules/reducer';

import { retailerFilter } from './components/views/list/filters/constants';
import { SHARING_UNIT_TEMPLATES_REDUCER_KEY, initialState } from './reducer';

const selectSharingUnitTemplatesState = (state: any): typeof initialState =>
  state[MODULE_REDUCER_KEY][SHARING_UNIT_TEMPLATES_REDUCER_KEY];

export const selectIsLoading = flow(
  selectSharingUnitTemplatesState,
  (state) => state.isLoading,
);

export const selectPagination = flow(
  selectSharingUnitTemplatesState,
  (state) => state.pagination,
);

export const selectSearch = flow(
  selectSharingUnitTemplatesState,
  (state) => state.search,
);

export const selectSharingUnitTemplates = flow(
  selectSharingUnitTemplatesState,
  (state) => state.templates,
);

export const selectRetailerList = flow(
  selectSharingUnitTemplatesState,
  (state) => state.retailer_list,
);
export const selectAreFiltersCollapsed = createSelectorAreFiltersCollapsed(
  selectSharingUnitTemplatesState,
);

export const selectSelectedFilterList = createSelectorSelectedFilterList(
  selectSharingUnitTemplatesState,
);

export const selectSelectedFilterMap = createSelectorSelectedFilterMap(
  selectSharingUnitTemplatesState,
);

export const selectSelectedRetailer = flow(
  selectSelectedFilterMap,
  (selectedFilterMap) => get(selectedFilterMap, retailerFilter.key),
);

export const selectSelectedSharingUnitTemplates = flow(
  selectSharingUnitTemplatesState,
  (state) => state.selected_template,
);

export const selectSelectedSharingUnitTemplatesId = flow(
  selectSelectedSharingUnitTemplates,
  _get(['id']),
);

export const selectSelectedSUTTargetOrganizationId = flow(
  selectSelectedSharingUnitTemplates,
  _get(['targetOrganization', 'id']),
);

export const selectIsDirty = flow(
  selectSharingUnitTemplatesState,
  (state) => state.isDirty,
);

export const selectIsPublishing = flow(
  selectSharingUnitTemplatesState,
  (state) => state.isPublishing,
);

export const selectDisplayGroups = flow(
  selectSharingUnitTemplatesState,
  _get(['display_groups']),
);

export const selectSelectedTemplateRecipientId = flow(
  selectSelectedSharingUnitTemplates,
  (selected_template) =>
    _get(['targetOrganization', 'id'], selected_template) || -1,
);
export const selectSourceOrganizationIds = flow(
  selectSelectedSharingUnitTemplates,
  (selected_template) => [
    _get(['sourceOrganization', 'id'], selected_template) || -1,
  ],
);

export const selectCurrentDisplayGroups = (state) => {
  const display_groups = selectDisplayGroups(state);
  const recipient_id = selectSelectedTemplateRecipientId(state);
  return _get(recipient_id, display_groups) || null;
};

export const selectFailedBlockingRules = flow(
  selectSharingUnitTemplatesState,
  (state) => state.failedBlockingRules,
);

export const selectFailedNonBlockingRules = flow(
  selectSharingUnitTemplatesState,
  (state) => state.failedNonBlockingRules,
);

export const selectSelectedSUTProducts = flow(
  selectSharingUnitTemplatesState,
  (state) => state.selected_template_products,
);

export const selectSelectedSUTProductsPagination = flow(
  selectSelectedSUTProducts,
  (state) => state.pagination,
);

export const selectSelectedSUTProductsSearchQuery = flow(
  selectSelectedSUTProducts,
  (state) => state.searchQuery,
);

export const selectSelectedSUTProductsInErrorFilter = flow(
  selectSelectedSUTProducts,
  (state) => state.filterInError,
);

export const selectSelectedSUTSharingUnitsInErrorCount = flow(
  selectSelectedSUTProducts,
  (state) => state.sharingUnitsInErrorCount,
);

export const selectSelectedSUTHasPendingValidation = flow(
  selectSelectedSUTProducts,
  (state) => state.hasPendingValidation,
);

export const selectSelectedSUTHasBlockingErrors = flow(
  selectSelectedSUTProducts,
  (state) => state.sharingUnitsHasBlockingErrors,
);

export const selectSelectedSUTProductsIsLoading = flow(
  selectSelectedSUTProducts,
  (state) => state.isLoading,
);

export const selectSelectedSUTProductsList = flow(
  selectSelectedSUTProducts,
  (state) => (state ? state.sharing_units : []),
);

export const selectAttachProductModal = flow(
  selectSharingUnitTemplatesState,
  (state) => state.attach_product_modal,
);

export const selectAttachProductModalState = flow(
  selectAttachProductModal,
  (state) => state.isOpen,
);

export const selectCurrentAttachedProduct = flow(
  selectAttachProductModal,
  (state) => state.product,
);

export const selectCurrentAttachedProductIsDirty = flow(
  selectAttachProductModal,
  (state) => state.isDirty,
);

export const selectCurrentAttachedProductErrors = flow(
  selectAttachProductModal,
  (state) => state.errors,
);

export const selectCurrentAttachedProductIsLoading = flow(
  selectAttachProductModal,
  (state) => state.isLoading,
);

export const selectAvailableRecipients = (state): List<RecipientImmutable> => {
  const isRetailer = selectIsRetailer(state);
  const allRecipients = selectAllRecipients(state);
  const listingRecipients = selectListingRecipients(state);
  const sourceOrganizationIds = selectSourceOrganizationIds(state);

  if (isRetailer) {
    return allRecipients.filter(
      (r) => (r && sourceOrganizationIds.includes(r.get('id'))) || false,
    ) as RecipientsImmutable;
  }

  return listingRecipients || List();
};

export const selectSelectedRecipient = createSelector(
  selectSelectedTemplateRecipientId,
  selectAvailableRecipients,
  selectOrganization,
  (recipientId, recipients, organization) => {
    // Target organization case.
    if (organization.get('id') === recipientId) {
      return organization;
    }
    // Source organization case.
    return recipients.find((r) => r?.get('id') === recipientId);
  },
);

export const selectTemplateFields = flow(
  selectSelectedRecipient,
  (recipient) => {
    return selectFields(recipient, false, (f) => !f?.get('overridable'));
  },
);

export const selectProductFields = flow(
  selectSelectedRecipient,
  (recipient) => {
    return selectFields(recipient, false, (f) => f.get('overridable'));
  },
);

const keepFields = (fieldNames) => (item, path) =>
  _get('kind', item) !== 'Field' ||
  fieldNames.includes(baseFieldName(path) || _get('model', item))
    ? item
    : null;

export const selectTemplateDisplayGroups = createSelector(
  selectCurrentDisplayGroups,
  selectTemplateFields,
  (displayGroups, fieldNames) =>
    traverseDisplaygGroups(displayGroups, keepFields(fieldNames)),
);

export const selectProductDisplayGroups = createSelector(
  selectCurrentDisplayGroups,
  selectProductFields,
  (displayGroups, fieldNames) =>
    traverseDisplaygGroups(displayGroups, keepFields(fieldNames)),
);

export const selectErrorOnSave = flow(
  selectSharingUnitTemplatesState,
  (state) => state.errorsOnSave,
);

import { get } from 'lodash';

import prefs from 'constants/prefs';
import { hasHierarchyFeature } from 'modules/feature-flag/permissions';

export const productHierarchyFilter = {
  key: 'alkemics.permission',
  query: 'hierarchy',
  isAvailable: ({ user } = {}) =>
    hasHierarchyFeature(user) &&
    get(prefs, 'productList.retailer.showFilters.hierarchy'),
};

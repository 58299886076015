import type { Dispatch } from 'redux';

import { Application } from '@alkem/lib-front-model';
import { ResponseWithData } from '@alkem/sdk-dashboard';

import { START_LOADING, STOP_LOADING } from 'constants/events/navigation';
import applicationApi from 'resources/applicationApi';
import { authApiImmutable } from 'resources/authApi';
import { UserImmutable } from 'types';
import { setProfileLanguage } from 'utils/i18n';
import { fromJsIfNotImmutable } from 'utils/immutable';
import { logError } from 'utils/logging';
import { updateTracking } from 'utils/tracking';
import { identifyDataDogSession } from 'utils/tracking/datadog';

import { RECEIVE_PROFILE } from './action-types';

export const getProfile =
  (useCache = true) =>
  async (dispatch: Dispatch) => {
    dispatch({ type: START_LOADING });
    try {
      const [userRes, appsRes]: [
        ResponseWithData<UserImmutable>,
        ResponseWithData<Application[]>,
      ] = await Promise.all([
        authApiImmutable.UserMe(
          {
            withOrganizationSettings: true,
            withUserSettings: true,
            withNetwork: true,
            withSsoInfo: true,
            withOrganizationLegalIdentifiers: true,
            withModules: true,
            withOrganizationList: true,
          },
          useCache,
        ) as any,
        applicationApi
          .ApplicationList({
            visibilities: false,
            isEnabled: true,
            isActive: true,
          })
          .catch((err) => {
            logError(err);
            return { data: { data: [] as Application[] } };
          }) as any,
      ]);
      const user: UserImmutable = userRes.data.data.set(
        'applications',
        fromJsIfNotImmutable(appsRes.data.data || []),
      );
      const reloadIfLangChanged = setProfileLanguage(user.get('language'));

      updateTracking(user);
      identifyDataDogSession(user);
      dispatch({ type: RECEIVE_PROFILE, user });
      dispatch({ type: STOP_LOADING });
      reloadIfLangChanged();

      return { user };
    } catch (error) {
      dispatch({ type: STOP_LOADING });
      return { error };
    }
  };

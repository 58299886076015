import Immutable, { fromJS } from 'immutable';
import { createReducer } from 'redux-create-reducer';

import { keyBy } from 'utils/fn';

import {
  ACTIVATE_APPLICATION_SUCCESS,
  DEACTIVATE_APPLICATION_SUCCESS,
  FETCH_APPLICATIONS_SUCCESS,
} from './constants';

export const AppStoreState = Immutable.Record({
  apps: Immutable.Map(),
  categories: Immutable.Map(),
});

export const initialState = AppStoreState();

export default createReducer(initialState, {
  [FETCH_APPLICATIONS_SUCCESS]: (state, action) =>
    state
      .set('categories', fromJS(action.payload.categories), state.categories)
      .set('apps', transformAppsToClient(action.payload.applications)),

  [ACTIVATE_APPLICATION_SUCCESS]: (state, action) =>
    state.setIn(['apps', String(action.payload.id), 'active'], true),

  [DEACTIVATE_APPLICATION_SUCCESS]: (state, action) =>
    state.setIn(['apps', String(action.payload.id), 'active'], false),
});

function transformAppsToClient(applications) {
  const appsWithCategoryIds = convertCategoryIds(applications);
  const appsAsMap = keyBy(appsWithCategoryIds, 'id');
  return fromJS(appsAsMap);
}

function convertCategoryIds(applications) {
  return (applications || []).map((app) => {
    const newApp = { ...app };
    if (app.categories) {
      newApp.categories = app.categories.map((c) => c.id);
    }
    return newApp;
  });
}

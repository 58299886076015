import { remove as removeDiacritics } from 'diacritics';
import { isNumber, isString } from 'lodash';

import { get } from './immutable';

/*
 * Callbacks for Array.sort method
 */

function isValid(value) {
  return isString(value) || isNumber(value);
}

/**
 * @param {any} valueA
 * @param {any} valueB
 * @returns number
 */
export function sortDesc(valueA, valueB, check = true) {
  if (check) {
    // invalid objects will be sorted to the end of the array
    if (!isValid(valueA)) {
      return 1;
    }
    if (!isValid(valueB)) {
      return -1;
    }
  }
  // NB: String.localeCompare is not supported in Safari <= 9
  // So to handle special characters, diacritics are removed
  const hasString = isString(valueA) || isString(valueB);
  const _valueA = hasString
    ? removeDiacritics(`${valueA}`.toLowerCase())
    : valueA;
  const _valueB = hasString
    ? removeDiacritics(`${valueB}`.toLowerCase())
    : valueB;
  if (_valueA === _valueB) {
    return 0;
  }
  return _valueA < _valueB ? 1 : -1;
}

export const sortDescFp = ([valueA, valueB]) => sortDesc(valueA, valueB);

/**
 * @param {any} valueA
 * @param {any} valueB
 * @returns number
 */
export function sortAsc(valueA, valueB) {
  // invalid objects will be sorted to the end of the array
  if (!isValid(valueA)) {
    return 1;
  }
  if (!isValid(valueB)) {
    return -1;
  }
  return sortDesc(valueB, valueA, false);
}

export const sortAscFp = ([valueA, valueB]) => sortAsc(valueA, valueB);

export const byPath = (path) => (a, b) => sortAsc(get(a, path), get(b, path));

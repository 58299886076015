import classNames from 'classnames';
import { PureComponent, ReactNode } from 'react';

import './helper.scss';

interface HelperProps {
  left?: ReactNode;
  right?: ReactNode;
  type?: 'Info' | 'Warning';
}

export default class Helper extends PureComponent<HelperProps> {
  render() {
    const { left, right, type } = this.props;
    return (
      <div className={classNames('Helper', type)}>
        {!!left && <div className="Helper__left">{left}</div>}
        {!!right && <div className="Helper__right">{right}</div>}
      </div>
    );
  }
}

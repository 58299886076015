import { hasFeature } from 'modules/feature-flag';
import { FEATURE_MULTI_TARGETMARKET_CATALOG } from 'modules/feature-flag/constants';

export const targetMarketFilter = {
  key: 'tags.targetMarket.id',
  aggregation: {
    value_field: 'tags.targetMarket.id',
  },
  query: 'targetMarket',
  isAvailable: ({ user } = {}) =>
    hasFeature(user, FEATURE_MULTI_TARGETMARKET_CATALOG),
};

import { Modal as BaseModal } from '@alkem/react-ui-modal';

import Notification from 'components/ui/notification';
import i18n from 'utils/i18n';

type Props = typeof BaseModal.prototype.props;

export function Modal({
  children,
  confirmButtonText = i18n.t(
    'frontproductstream.modal.action.confirm_default_label',
    { defaultValue: 'OK' },
  ),
  closeButtonText = i18n.t(
    'frontproductstream.modal.action.cancel_default_label',
    { defaultValue: 'Cancel' },
  ),
  ...props
}: Props) {
  return (
    <BaseModal
      closeButtonText={closeButtonText}
      confirmButtonText={confirmButtonText}
      {...props}
    >
      <Notification context="modal" absolute />
      {children}
    </BaseModal>
  );
}

export default Modal;

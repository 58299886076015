import classNames from 'classnames';
import PropTypes from 'prop-types';
import { Component } from 'react';
import ImmutablePropTypes from 'react-immutable-proptypes';
import { connect } from 'react-redux';

import { Button } from '@alkem/react-ui-button';

import {
  DISPUTE_1,
  DISPUTE_2,
  DISPUTE_3,
  RECEIVED,
} from 'constants/physicalCheckerStatus';
import {
  removeField,
  rollbackField,
  selectField,
} from 'modules/contribution/actions';
import {
  getPhysicalCheckContribution,
  getSelectedFields,
} from 'modules/contribution/selectors';
import { selectIsPhysicalChecker } from 'modules/user';

import { PHYSICAL_CHECK_FIELD } from '../../constants';

import './physicalCheck.scss';

const mapStateToProps = (state) => ({
  selectedFields: getSelectedFields(state),
  contribution: getPhysicalCheckContribution(state),
  isPhysicalChecker: selectIsPhysicalChecker(state),
});

const mapDispatchToProps = {
  selectField,
  removeField,
  rollbackField,
};

export class PhysicalCheck extends Component {
  static propTypes = {
    field: PropTypes.object.isRequired,
    selectedFields: ImmutablePropTypes.map,
    contribution: ImmutablePropTypes.map,
    selectField: PropTypes.func,
    removeField: PropTypes.func,
    rollbackField: PropTypes.func,
    isPhysicalChecker: PropTypes.bool,
  };

  componentWillUnmount() {
    if (this.shouldRender()) {
      const { field } = this.props;
      this.props.removeField(field.model);
    }
  }

  select = (model, value) => () => {
    if ([PHYSICAL_CHECK_FIELD.PASS].includes(value)) {
      this.props.rollbackField(model);
    }
    this.props.selectField(model, value);
  };

  shouldRender() {
    const { field, contribution } = this.props;
    return (
      this.props.isPhysicalChecker &&
      field.level === 1 &&
      [RECEIVED.id, DISPUTE_1.id, DISPUTE_2.id, DISPUTE_3.id].includes(
        contribution.get('status'),
      )
    );
  }

  render() {
    const { selectedFields, field } = this.props;
    if (!this.shouldRender()) {
      return null;
    }
    return (
      <div className="physical-check-plugin btn-group">
        <Button
          secondary
          className={classNames('physical-check-plugin-btn-pass', {
            selected:
              selectedFields.has(field.model) &&
              selectedFields.get(field.model) === PHYSICAL_CHECK_FIELD.PASS,
          })}
          onClick={this.select(field.model, PHYSICAL_CHECK_FIELD.PASS)}
          id={`physical-check-plugin-btn-pass--${field.model}`}
        >
          <i className="mdi mdi-check" />
        </Button>
        <Button
          secondary
          className={classNames('physical-check-plugin-btn-fail', {
            selected:
              selectedFields.has(field.model) &&
              selectedFields.get(field.model) === PHYSICAL_CHECK_FIELD.FAIL,
          })}
          onClick={this.select(field.model, PHYSICAL_CHECK_FIELD.FAIL)}
          id={`physical-check-plugin-btn-fail--${field.model}`}
        >
          <i className="mdi mdi-close" />
        </Button>
      </div>
    );
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(PhysicalCheck);

import { useSelector } from 'react-redux';

import { Ellitips } from '@alkem/react-ui-ellitips';

import ProductReference from 'components/ui/product-reference';
import { selectDataOpsDisplayName } from 'modules/data-ops';
import { selectIsManufacturer, selectIsSuperAdminLoggedAs } from 'modules/user';
import {
  selectHasLifeCycle,
  selectIsLogisticalUnit,
  selectIsPendingExclusivity,
  selectIsPublished,
  selectIsRightToLeft,
  selectProductGTINWithFallbackOnInternal,
  selectProductOrganizationSourceName,
} from 'reducers/productVersion';
import i18n from 'utils/i18n';

import { selectIsLoading } from '../../selectors';

import './basic-information.scss';
import PublishedBadge from './published-badge';

type Props = {
  noPublishStatus?: boolean;
};

const BasicInformation = ({ noPublishStatus = false }: Props) => {
  const displayName = useSelector(selectDataOpsDisplayName);
  const hasLifeCycle = useSelector(selectHasLifeCycle);
  const isLoading = useSelector(selectIsLoading);
  const reference = useSelector(selectProductGTINWithFallbackOnInternal) || '';
  const isManufacturer = useSelector<any, boolean>(selectIsManufacturer);
  const isPublished = useSelector(selectIsPublished);
  const isSuperAdminLoggedAs = useSelector(selectIsSuperAdminLoggedAs);
  const isPendingExclusivity = useSelector(selectIsPendingExclusivity);
  const isLogisticalUnit = useSelector(selectIsLogisticalUnit);
  const sourceName = useSelector(selectProductOrganizationSourceName);
  const isRightToLeft = useSelector(selectIsRightToLeft);

  if (!hasLifeCycle) {
    return null;
  }
  return (
    <div className="BasicInformation">
      <div className="BasicInformation__content">
        <div className="BasicInformation__content__left">
          <div
            data-testid="basic-informations"
            dir={isRightToLeft ? 'rtl' : 'initial'}
          >
            <Ellitips
              label={isLoading ? '' : displayName}
              id={`BI_${reference}`}
            />
            <ProductReference reference={reference} />
          </div>
          {sourceName && (
            <div className="BasicInformation__content__left__shared_by">
              {i18n.t('Shared by {{name}}', {
                name: sourceName,
              })}
            </div>
          )}
        </div>
        <div className="BasicInformation__content__right">
          {!noPublishStatus && !isLogisticalUnit && isManufacturer && (
            <>
              <PublishedBadge isPublished={isPublished} />
              {isSuperAdminLoggedAs && isPendingExclusivity && (
                <span className="PendingExclusivityBadge">
                  {i18n.t('Visibility not defined: not synced')}
                </span>
              )}
            </>
          )}
        </div>
      </div>
    </div>
  );
};

export { BasicInformation };

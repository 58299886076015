import { Map } from 'immutable';

import { UserImmutable } from '@alkem/lib-front-model/immutable';

import { createReducer } from 'utils/redux';

import {
  LOGOUT,
  RECEIVE_PRODUCT_SEGMENTS_PERMISSIONS,
  RECEIVE_PROFILE,
  RECEIVE_USER_ORGANIZATION_PERMISSIONS,
  UPDATE_USERSETTING,
  UPDATE_USER_FIELD,
} from '../actions/action-types';

const initialState = Map() as UserImmutable;

export default createReducer<UserImmutable>(initialState, {
  [RECEIVE_PROFILE]: (state, action) => state.merge(action.user),

  [UPDATE_USERSETTING]: (state, { key, value }) =>
    state.setIn(['settings', key], value),

  [UPDATE_USER_FIELD]: (state, { payload: { path, value } }) =>
    state.setIn(path, value),

  [RECEIVE_PRODUCT_SEGMENTS_PERMISSIONS]: (
    state,
    { payload: { productSegments } },
  ) => state.setIn(['permissions', 'productversion'], productSegments),

  [RECEIVE_USER_ORGANIZATION_PERMISSIONS]: (
    state,
    { payload: { permissions } },
  ) => state.setIn(['organizationPermissions'], permissions),

  [LOGOUT]: () => initialState,
});

import { call } from 'redux-saga/effects';

import { isUserSuperAdmin } from '@alkem/lib-front-model/immutable';
import { navigate } from '@alkem/micro-frontend-tools';

import { getUsername, isLoggedAs } from 'core/api/user';
import * as routes from 'routes';

import { loadProfileSaga } from './loadProfile';

export function* tryAuthorizationSaga({ payload: location }) {
  try {
    const user = yield call(loadProfileSaga);

    if (isUserSuperAdmin(user) && !isLoggedAs(user)) {
      localStorage.setItem('sxm_username', getUsername(user));
    }
  } catch (error) {
    const { pathname, search } = location;
    const { status } = error as { status: number };
    if (status >= 400 && status < 500) {
      navigate({ to: routes.login, queries: { next: `${pathname}${search}` } });
    } else {
      throw error;
    }
  }
}

import classNames from 'classnames';
import { useCallback, useState } from 'react';

import { useToggle } from '@alkem/react-hooks';
import Tooltip from '@alkem/react-ui-tooltip/src/index';

import { Collapsible } from 'components/collapsible';
import { ConnectedPriceWaterfall as PriceWaterfall } from 'modules/price-waterfalls/components/price-waterfall';
import { PriceWaterfallDisplayType } from 'modules/price-waterfalls/constants';
import i18n from 'utils/i18n';

import { AllowanceLabel } from './Labels/allowanceLabel';
import { ErrorLabel, useErrorCount } from './Labels/errorLabel';
import { NetPriceLabel } from './Labels/netPriceLabel';
import './collapsible-price-waterfall.scss';
import { PriceWaterfallDeleteModal } from './delete-modal';

interface Props {
  value: any;
  model: string;
  entityId: number;
  targetOrganizationId: number;
  onChange: (model: string, value: any, _, __, isDirty: boolean) => void;
  editableFirstBracket: boolean;
  taxInformation: any;
  canDelete: boolean;
  isFirstOfMultiple: boolean;
  openByDefault?: boolean;
  title: any;
  entityType: string;
  disabled?: boolean;
  taxesNotIncludedByDefault: boolean;
  displayType: PriceWaterfallDisplayType;
}

export function CollapsiblePriceWaterfall({
  value,
  model,
  entityId,
  targetOrganizationId,
  onChange,
  editableFirstBracket,
  taxInformation,
  canDelete,
  isFirstOfMultiple,
  title,
  entityType,
  disabled = false,
  openByDefault = false,
  taxesNotIncludedByDefault,
  displayType,
}: Props) {
  const [isExpanded, toggleExpanded] = useToggle(openByDefault);
  const [isDeleteModalOpen, setIsDeleteModalOpen] = useState<boolean>(false);
  const { errorNumber, nonBlockingErrorNumber } = useErrorCount(
    entityId,
    model,
    entityType,
  );

  const openDeleteModal = useCallback(
    (e) => {
      e.stopPropagation();
      setIsDeleteModalOpen(true);
    },
    [setIsDeleteModalOpen],
  );
  const closeDeleteModal = useCallback(() => {
    setIsDeleteModalOpen(false);
  }, [setIsDeleteModalOpen]);

  const deletePW = useCallback(() => {
    onChange('priceWaterfalls', null, null, null, true);
  }, [onChange]);

  const onDelete = useCallback(
    (e) => {
      if (isFirstOfMultiple) {
        openDeleteModal(e);
      } else {
        deletePW();
      }
    },
    [deletePW, isFirstOfMultiple, openDeleteModal],
  );

  return (
    <Collapsible
      className={classNames('price-waterfall-collapsible', {
        'price-waterfall-collapsible-error': !!(
          errorNumber + nonBlockingErrorNumber
        ),
      })}
      title={
        <div className="price-waterfall-collapsibleTitle">
          <span className="alk-font-weight--bold">{title}</span>
          <NetPriceLabel
            pricewaterfall={value}
            targetOrganizationId={targetOrganizationId}
          />
          <AllowanceLabel pricewaterfall={value} />
          <ErrorLabel
            blocking={errorNumber}
            nonBlocking={nonBlockingErrorNumber}
          />
          {canDelete && (
            <button
              type="button"
              className="alk-btn-phantom"
              onClick={onDelete}
              data-testid="deleteButton"
              disabled={disabled}
            >
              <i
                className="mdi mdi-delete alk-clickable-icon"
                data-for="delete-pricewaterfall-tooltip"
                data-tip
              />
              {disabled && (
                <Tooltip id="delete-pricewaterfall-tooltip">
                  {i18n.t(
                    'frontproductstream.price_waterfalls.collapsible_pricewaterfall_input_disabled.tooltip',
                    {
                      defaultValue:
                        'You cannot edit the proposal anymore, contact the retailer if needed',
                    },
                  )}
                </Tooltip>
              )}
            </button>
          )}
        </div>
      }
      toggle={toggleExpanded}
      collapsed={!isExpanded}
      noMargins
      big
      heading={false}
    >
      <PriceWaterfall
        model={model}
        value={value}
        entityId={entityId}
        entityType={entityType}
        onUpdate={onChange}
        targetOrganizationId={targetOrganizationId}
        canDelete={false}
        disabled={disabled}
        label={null}
        hasEstimationFeature={false}
        editableFirstBracket={editableFirstBracket}
        taxInformation={taxInformation}
        taxesNotIncludedByDefault={taxesNotIncludedByDefault}
        displayType={displayType}
      />
      {isDeleteModalOpen && (
        <PriceWaterfallDeleteModal
          onClose={closeDeleteModal}
          onConfirm={deletePW}
        />
      )}
    </Collapsible>
  );
}

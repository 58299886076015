import { createReducer } from 'redux-create-reducer';

import {
  ADD_NOTIFICATION,
  NOTIFICATION_CLEAR,
  NOTIFICATION_CLEAR_RESET,
  NOTIFICATION_DISPLAYED,
  NOTIFICATION_HIDDEN,
  REMOVE_NOTIFICATION,
} from 'constants/events/notification';

const initialState = { displayed: false, messages: [], clear: false };
export default createReducer(initialState, {
  [ADD_NOTIFICATION]: (state, { message, style, context, sticky, error }) => ({
    messages: [
      ...state.messages,
      {
        context,
        message,
        sticky,
        style,
        error,
      },
    ],
  }),
  [REMOVE_NOTIFICATION]: (state, { index }) => ({
    ...state,
    messages: [
      ...state.messages.slice(0, index),
      ...state.messages.slice(index + 1),
    ],
  }),
  [NOTIFICATION_DISPLAYED]: (state) => ({
    ...state,
    context: state.messages[state.messages.length - 1].context,
    displayed: true,
  }),
  [NOTIFICATION_HIDDEN]: ({ context, ...state }) => ({
    ...state,
    displayed: false,
  }),
  [NOTIFICATION_CLEAR]: () => ({
    ...initialState,
    clear: true,
  }),
  [NOTIFICATION_CLEAR_RESET]: (state) => ({
    ...state,
    clear: false,
  }),
});

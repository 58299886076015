import { memo } from 'react';

import Cgu from 'components/cgu';
import Navbar from 'components/navbar';
import Loader from 'components/ui/loader';
import { login } from 'routes';

interface Props {
  pathname: string;
}

export default memo(function PageTop({ pathname }: Props) {
  if ([login].includes(pathname)) {
    return null;
  }
  return (
    <div className="PageTop">
      <Navbar />
      <Loader />
      <Cgu />
    </div>
  );
});

import classNames from 'classnames';
import moment from 'moment';
import { any } from 'prop-types';

export default function DateCell({ data }) {
  if (data != null) {
    const date = !isNaN(data) ? moment.unix(data) : moment(data);
    return (
      <div className={classNames('DateCell')}>
        <div data-testid="DateCell-value">{date.format('L')}</div>
      </div>
    );
  }
  return <div />;
}

DateCell.className = 'DateCell';
DateCell.propTypes = {
  data: any,
};

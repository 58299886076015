import { get, set } from 'lodash/fp';
import { compose } from 'redux';
import { Channel } from 'redux-saga';
import { call, put, select, takeEvery } from 'redux-saga/effects';

import {
  notificationClear,
  notificationError,
  notificationSuccess,
} from 'actions/notification';
import { OROPHistoryTypeClaim } from 'modules/product-creation';
import {
  selectEditedProductVersion,
  selectProductKeyId,
  selectTargetMarketId,
} from 'reducers/productVersion';
import searchApi from 'resources/searchApi';
import serviceProductApi from 'resources/serviceProductApi';
import i18n from 'utils/i18n';
import { logError } from 'utils/logging';

import {
  addVariant,
  createVariantFailure,
  createVariantSuccess,
} from '../actions';
import creationError from '../components/creation-error';
import { CREATE } from '../constants';

const variantFieldsToCopy: string[] = [
  'isConsumerUnit',
  'isDisplayUnit',
  'isDespatchUnit',
  'kind',
  'typePackaging',
  'brand',
];

function* createSaga({ payload: gtin }: { payload: string }) {
  yield put(notificationClear());
  const targetMarketId = yield select(selectTargetMarketId);
  const productVersion = yield select(selectEditedProductVersion);
  const productKeyId = yield select(selectProductKeyId);
  // copy base data from model
  const version = compose(
    ...variantFieldsToCopy.map((f) => set([f], get([f], productVersion))),
  )({
    textileModel: { product_key_id: productKeyId },
  });
  try {
    const response = yield call(() =>
      serviceProductApi.createProduct(
        gtin,
        targetMarketId,
        OROPHistoryTypeClaim,
        {
          productVersion: { data: version },
        },
      ),
    );
    const {
      product_id: newproductId,
      product_key_id: newProductKeyId,
      already_owned,
    } = response.data;
    if (already_owned) {
      // Fetch the product to check what is wrong.
      let existingProduct = null;
      try {
        const existingProductResponse = yield call(() =>
          searchApi.post('/search/v2/productversion', {
            filter_source_include: [
              'id',
              'textileModel.product_key_id',
              'tags.targetMarket.id',
              'lifeCycle',
              'isConsumerUnit',
              'isDisplayUnit',
              'kind.id',
              'typePackaging.id',
              'alkemics.textileVariants.gtins',
            ],
            allow_not_consumer_units: true,
            allow_textile_variants: true,
            filter_unit_type_in: ['consumerUnit', 'displayUnit', 'other'],
            advanced_search: {
              must: [{ query: newProductKeyId, fields: ['product_key.id'] }],
            },
            limit: 1,
          }),
        );
        [existingProduct] = existingProductResponse.data.data;
      } catch (error) {
        logError(error);
      }
      yield put(createVariantFailure());
      yield put(
        notificationError(
          creationError(
            newProductKeyId,
            existingProduct,
            productKeyId,
            productVersion,
          ),
          { sticky: true },
        ),
      );
      return;
    }
    yield put(
      addVariant({
        id: newproductId,
        gtin,
        product_key_id: newProductKeyId,
        version,
      }),
    );
    yield put(createVariantSuccess());
    yield put(notificationSuccess(i18n.t('The variant has been added')));
  } catch (error) {
    yield put(createVariantFailure());
    yield put(notificationError(i18n.t('An error occured adding the variant')));
    yield call(logError, error);
  }
}

export default function* saga() {
  try {
    yield takeEvery(
      CREATE as unknown as Channel<{ payload: string }>,
      createSaga,
    );
  } catch (error) {
    logError(error);
  }
}

import { RECEIVE_PRODUCT_DATA } from 'constants/events/productversion';
import { RECEIVE_SPECIFIC_FIELDS } from 'modules/recipient-specific-block/events';
import { i18n } from 'utils/i18n';
import { takeEverySafe, takeLatestSafe } from 'utils/saga';

import {
  DATA_OPS_ACCEPT_PATCHES,
  DATA_OPS_ACCEPT_PATCHES_DONE,
  DATA_OPS_BULK_PATCH_FIELD,
  DATA_OPS_CANCEL_PATCH,
  DATA_OPS_PATCHES_RECEIVED,
  DATA_OPS_PATCH_FIELD,
  DATA_OPS_REFUSE_PATCHES,
  DATA_OPS_REFUSE_PATCHES_DONE,
  DATA_OPS_SAVE_PATCHES,
} from '../constants';

import {
  acceptPatches,
  refusePatches,
  trackPatchAccept,
  trackPatchRefuse,
} from './amend-product';
import { cancelPatch } from './cancel-patch';
import { replaceSpecificFieldKeysInPatchesSaga } from './consolidate-patches';
import { bulkPatchFieldSaga, patchFieldSaga } from './patch-field';
import { processProductDataSaga } from './product-data';
import { savePatches } from './save-patch';

export function* dataOpsSagas() {
  yield takeLatestSafe(RECEIVE_PRODUCT_DATA, processProductDataSaga, {
    withNotification: true,
    errorMessage: i18n.t(
      'frontproductstream.product_collaborative_edit.error_fetching_patch.notification',
      { defaultValue: 'An error occured while fetching product patches' },
    ),
  });
  yield takeLatestSafe(
    [DATA_OPS_PATCHES_RECEIVED, RECEIVE_SPECIFIC_FIELDS],
    replaceSpecificFieldKeysInPatchesSaga,
  );
  yield takeEverySafe(DATA_OPS_PATCH_FIELD, patchFieldSaga);
  yield takeEverySafe(DATA_OPS_BULK_PATCH_FIELD, bulkPatchFieldSaga);
  yield takeLatestSafe(DATA_OPS_SAVE_PATCHES, savePatches, {
    withNotification: true,
    errorMessage: i18n.t(
      'frontproductstream.product_collaborative_edit.error_saving_patch.notification',
      { defaultValue: 'An error occured while saving product patches' },
    ),
  });
  yield takeLatestSafe(DATA_OPS_CANCEL_PATCH, cancelPatch, {
    withNotification: true,
    errorMessage: i18n.t(
      'frontproductstream.product_collaborative_edit.error_cancelling_patch.notification',
      { defaultValue: 'An error occured while cancelling product patch' },
    ),
  });
  yield takeEverySafe(DATA_OPS_ACCEPT_PATCHES, acceptPatches, {
    withNotification: true,
    errorMessage: i18n.t(
      'frontproductstream.product_collaborative_edit.error_accepting_patch.notification',
      { defaultValue: 'An error occured while accepting product patches' },
    ),
  });
  yield takeEverySafe(DATA_OPS_REFUSE_PATCHES, refusePatches, {
    withNotification: true,
    errorMessage: i18n.t(
      'frontproductstream.product_collaborative_edit.error_refusing_patch.notification',
      { defaultValue: 'An error occured while refusing product patches' },
    ),
  });
  yield takeEverySafe(DATA_OPS_ACCEPT_PATCHES_DONE, trackPatchAccept);
  yield takeEverySafe(DATA_OPS_REFUSE_PATCHES_DONE, trackPatchRefuse);
}

export { savePatches };

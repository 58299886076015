import { registerModuleReducer } from 'utils/modules/reducer';
import { registerSaga } from 'utils/modules/saga';

import mainComponent from './components';
import reducers, { KEY } from './reducers';
import mainSaga from './sagas';

registerSaga(mainSaga);
registerModuleReducer(KEY, reducers);

export default mainComponent;

import i18n from 'utils/i18n';

import styles from './HelpButton.module.scss';

export function HelpButton() {
  return (
    <>
      <button
        id="help-center-button"
        className={styles.button}
        data-help-article="index"
      >
        {i18n.t('frontproductstream.help.button.label', {
          defaultValue: 'Help center',
        })}
      </button>
      <style>{`
        #_elev_io {
          display: none !important;
        }
      `}</style>
    </>
  );
}

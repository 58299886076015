import classNames from 'classnames';
import PropTypes from 'prop-types';
import { Component } from 'react';
import { connect } from 'react-redux';

import { hasFeature } from 'modules/feature-flag';
import { FEATURE_PRO_RETAILER_FIELD_FLAG } from 'modules/feature-flag/constants';
import {
  addFieldCommentToPendingMessage,
  removeFieldCommentFromPendingMessage,
} from 'modules/product-page/modules/normalized-comments/actions';
import { selectUser } from 'modules/user';
import { ValidationWorkflowType } from 'modules/validation';

const mapStateToProps = (state) => ({
  hasFieldFlagFeature: hasFeature(
    selectUser(state),
    FEATURE_PRO_RETAILER_FIELD_FLAG,
  ),
});

class Kushiel extends Component {
  // https://en.wikipedia.org/wiki/Kushiel : punishes people in Hell !!

  static propTypes = {
    dispatch: PropTypes.func.isRequired,
    label: PropTypes.string.isRequired,
    productVersionId: PropTypes.number.isRequired,
    punish: PropTypes.func.isRequired,
    forgive: PropTypes.func.isRequired,
    model: PropTypes.string.isRequired,
    value: PropTypes.any,
    isFlagged: PropTypes.bool,
    hasFieldFlagFeature: PropTypes.bool,
  };

  static defaultProps = {
    isFlagged: false,
    hasFieldFlagFeature: false,
  };

  togglePunishment = () => {
    const {
      dispatch,
      label,
      isFlagged,
      productVersionId,
      forgive,
      punish,
      model,
      value,
    } = this.props;
    if (!isFlagged && punish()) {
      const msgData = {
        type: ValidationWorkflowType.NORMALIZED_COMMENT,
        label,
        currentValue: value,
        path: model,
        formattedMessage: label,
      };
      dispatch(addFieldCommentToPendingMessage(msgData, productVersionId));
    } else if (isFlagged && forgive()) {
      dispatch(removeFieldCommentFromPendingMessage(label, productVersionId));
    }
  };

  render() {
    const { hasFieldFlagFeature, model, isFlagged } = this.props;

    if (!hasFieldFlagFeature) {
      return null;
    }

    return (
      <i
        id={`kushiel-flag-${model}`}
        className={classNames(
          'mdi mdi-flag FormField__action Kushiel',
          isFlagged && 'FormField__action--selected',
        )}
        onClick={this.togglePunishment}
      />
    );
  }
}

export { Kushiel };
export default connect(mapStateToProps)(Kushiel);

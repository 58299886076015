import { Map } from 'immutable';

import * as routes from 'routes';
import { push } from 'utils/history';
import i18n from 'utils/i18n';

import { NAVIGATION, navigate as navigateAction } from '../actions/navigation';
import {
  productChat,
  productDashboard,
  productHistory,
  productPage,
} from '../actions/product';
import { RFPPage } from '../actions/rfp';
import {
  PRODUCT_CHAT,
  PRODUCT_DASHBOARD,
  PRODUCT_HISTORY,
  PRODUCT_PAGE,
  RFP_PAGE,
} from '../constants';
import { buildError } from '../utils';

export const REDIRECT_ERROR = 'REDIRECT_ERROR';
export const redirectError = (error) => ({ type: REDIRECT_ERROR, error });

export const REDIRECT_REQUEST = 'REDIRECT_REQUEST';
export const requestRedirect = (_query, location) => (dispatch) => {
  const query = Map(_query);
  const action = query.get('action', 'nav');
  return Promise.resolve()
    .then(() => {
      switch (action) {
        case NAVIGATION:
          return dispatch(navigateAction(query));
        case PRODUCT_CHAT:
          // This action requires private routes logic
          // cf. productChat action
          if (location.pathname !== routes.privateRedirect) {
            push(routes.privateRedirect, _query);
            return true;
          }
          return dispatch(productChat(query));
        case PRODUCT_DASHBOARD:
          // This action requires private routes logic
          // cf. productDashboard action
          if (location.pathname !== routes.privateRedirect) {
            push(routes.privateRedirect, _query);
            return true;
          }
          return dispatch(productDashboard(query));
        case PRODUCT_HISTORY:
          // This action requires private routes logic
          // cf. producthistory action
          if (location.pathname !== routes.privateRedirect) {
            push(routes.privateRedirect, _query);
            return true;
          }
          return dispatch(productHistory(query));
        case PRODUCT_PAGE:
          // This action requires private routes logic
          // cf. productPage action
          if (location.pathname !== routes.privateRedirect) {
            push(routes.privateRedirect, _query);
            return true;
          }
          return dispatch(productPage(query));
        case RFP_PAGE:
          // This action requires private routes logic
          // cf. RFPPage action
          if (location.pathname !== routes.privateRedirect) {
            push(routes.privateRedirect, _query);
            return true;
          }
          return dispatch(RFPPage(query));
        default:
          return Promise.reject(
            buildError(
              /** @type {string} */ (
                i18n.t('frontproductstream.redirect.error.unknown_action', {
                  defaultValue: 'Unknown action',
                })
              ),
            ),
          );
      }
    })
    .catch((e) => dispatch(redirectError(e)));
};

import { fromJS } from 'immutable';
import { createReducer } from 'redux-create-reducer';

import { RECEIVE_NOTIFICATION_COUNTER } from '../constants/events';

// immutable
const initialState = fromJS({
  total: 0,
});

export default createReducer(initialState, {
  [RECEIVE_NOTIFICATION_COUNTER]: (state, action) =>
    state.set('total', action.total),
});

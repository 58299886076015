import { memo } from 'react';

import { PSQEditor } from 'components/ui/form/psq-editor';

import { CustomHistoryDiffLine } from './custom-history-diff-line';

export const IsPartitionedByHistoryDiffLine = memo((props: any) => (
  <CustomHistoryDiffLine {...props}>
    <PSQEditor />
  </CustomHistoryDiffLine>
));

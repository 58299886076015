import { flow, identity, mapValues, unset, update } from 'lodash/fp';

import { ActionPayload } from 'types/redux';

import { saveDataOpsPatchesDone } from '../actions';
import { DataOpsPatchState, PendingMessage } from '../types';

export const dataOpsSavePatchesDone = ({
  error,
}: ActionPayload<typeof saveDataOpsPatchesDone>) =>
  error
    ? identity
    : flow(
        update(
          ['patches'],
          mapValues((patchState: DataOpsPatchState) =>
            patchState.dirty ? unset(['dirty'], patchState) : patchState,
          ),
        ),
        update(['pendingMessages'], (pendingMessages) => {
          const newPM = {};
          if (pendingMessages) {
            Object.keys(pendingMessages).forEach((pvId) => {
              newPM[pvId] = pendingMessages[pvId].map((m: PendingMessage) => ({
                ...m,
                sent: true,
              }));
            });
          }
          return newPM;
        }),
      );

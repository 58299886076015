export const NORMALIZED_COMMENTS_STORE_KEY = 'productNormalizedComments';

export const NORMALIZED_COMMENTS_REQUEST_CHANGES =
  'normalized-comments/request-changes';
export const NORMALIZED_COMMENTS_REQUEST_CHANGES_DONE =
  'normalized-comments/request-changes-done';
export const NORMALIZED_COMMENTS_OPEN_MODAL = 'normalized-comments/open-modal';

export const NORMALIZED_COMMENTS_STORE_DEPRECATEDS =
  'normalized-comments/store-deprecateds';

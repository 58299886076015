import classNames from 'classnames';
import { bool } from 'prop-types';
import { PureComponent } from 'react';
import { list } from 'react-immutable-proptypes';

import { logError } from 'utils/logging';

import './index.scss';
import ReportingStackedBarChart from './stacked-bar';

export default class ReportingCharts extends PureComponent {
  static propTypes = {
    charts: list.isRequired,
    isSticky: bool,
  };

  componentDidCatch(error, info) {
    logError(error, info);
  }

  renderChart(chart, index) {
    const chartType = chart.get('type');
    const key = `${chartType}-${chart.get('keys').join('-')}-${index}`;
    const values = chart.get('values');
    if (!values || !values.size) {
      return null;
    }
    switch (chartType) {
      case 'stacked-bar':
        return <ReportingStackedBarChart key={key} chart={chart} />;
      default:
        return null;
    }
  }

  render() {
    const isSticky = this.props.isSticky;
    const charts = this.props.charts
      .map(this.renderChart)
      .filter((chart) => chart);
    return (
      !charts.isEmpty() && (
        <div
          className={classNames({
            ReportingCharts: !isSticky,
            ReportingChartsSticky: isSticky,
          })}
        >
          {charts}
        </div>
      )
    );
  }
}

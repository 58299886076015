type getHasResolutionWarningParams = {
  height: number;
  width: number;
};

export const getHasResolutionWarning = ({
  height,
  width,
}: getHasResolutionWarningParams) => height < 1500 && width < 1500;

export const getHasBackgroundWarning = (backgroundScore: number) =>
  backgroundScore < 75;

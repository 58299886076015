import { call, put, select } from 'redux-saga/effects';

import { hasFeature } from 'modules/feature-flag';
import { FEATURE_DATA_MATURITY_MANAGEMENT } from 'modules/feature-flag/constants';
import { selectUser } from 'modules/user';
import ValidationApi from 'resources/validationApi';
import { request } from 'utils/api';

import { receiveMaturityRulesets } from '../actions';

export function* fetchMaturityRulesets() {
  const user = yield select(selectUser);
  if (hasFeature(user, FEATURE_DATA_MATURITY_MANAGEMENT)) {
    const response = yield call(
      request,
      ValidationApi,
      'getRequestableRulesets',
    );
    yield put(
      receiveMaturityRulesets({
        maturityRulesets: response.result,
      }),
    );
  }
}

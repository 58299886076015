import { flow } from 'lodash/fp';

import { get } from 'utils/immutable';
import { MODULE_REDUCER_KEY } from 'utils/modules/reducer';

import { MODULE_NAME } from './constants';
import { ModuleState } from './reducer';

export const selectModuleState = (state): ModuleState =>
  state[MODULE_REDUCER_KEY][MODULE_NAME];

export const selectIsModalOpen = flow(selectModuleState, (state) =>
  get(state, 'isModalOpen'),
);

export const selectTypeModal = flow(selectModuleState, (state) =>
  get(state, 'typeModal'),
);

export const selectSupplier = flow(selectModuleState, (state) =>
  get(state, 'supplier'),
);

export const selectSupplierId = flow(selectModuleState, (state) =>
  get(state, 'supplierId'),
);

export const selectSupplierLoading = flow(selectModuleState, (state) =>
  get(state, 'supplierLoading'),
);

export const selectRefusedRecipients = flow(selectModuleState, (state) =>
  get(state, 'refusedRecipients'),
);

export const selectGtin = flow(selectModuleState, (state) =>
  get(state, 'gtin'),
);

export const selectMessagesResult = flow(selectModuleState, (state) =>
  get(state, 'messagesResult'),
);

export const selectInProgress = flow(selectModuleState, (state) =>
  get(state, 'inProgress'),
);

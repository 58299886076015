import classNames from 'classnames';
import { Map } from 'immutable';
import PropTypes from 'prop-types';
import { Component } from 'react';
import ImmutablePropTypes from 'react-immutable-proptypes';

import Checkbox from '@alkem/react-ui-checkbox';

import { get } from 'utils/immutable';

import './item.scss';

export default class ListSimpleFilterItem extends Component {
  static propTypes = {
    filterKey: PropTypes.string.isRequired,
    filter: PropTypes.oneOfType([PropTypes.object, ImmutablePropTypes.map])
      .isRequired,
    aggregation: ImmutablePropTypes.map,
    selected: PropTypes.bool,
    onChange: PropTypes.func.isRequired,
    disabled: PropTypes.bool,
    hasDocCount: PropTypes.bool,
    selectId: PropTypes.func,
    selectLabel: PropTypes.func,
  };

  static defaultProps = {
    selected: false,
    hasDocCount: false,
    aggregation: Map(),
    selectId: (filter) => get(filter, 'id'),
    selectLabel: (filter) => get(filter, 'label'),
  };

  constructor(props) {
    super(props);
    this.onChange = this.onChange.bind(this);
  }

  onChange(selected) {
    const { disabled, onChange, filter } = this.props;
    if (!disabled) {
      onChange(filter, selected);
    }
  }

  render() {
    const {
      aggregation,
      disabled,
      filter,
      filterKey,
      hasDocCount,
      selected,
      selectId,
      selectLabel,
    } = this.props;

    return (
      <div
        className={classNames('ListSimpleFilterItem', get(filter, 'className'))}
      >
        <Checkbox
          id={`list-simple-filter-${filterKey}-${selectId(filter)}`}
          label={
            <span className="ListSimpleFilterItem__content">
              {!!get(filter, 'iconClassName') && (
                <i
                  className={classNames(
                    'ListSimpleFilterItem__icon',
                    get(filter, 'iconClassName'),
                  )}
                />
              )}
              <span className="ListSimpleFilterItem__label">
                <span>{selectLabel(filter)}</span>
                {hasDocCount && (
                  <span>{` (${aggregation.get('doc_count', 0)})`}</span>
                )}
              </span>
            </span>
          }
          checked={selected}
          onChange={this.onChange}
          disabled={disabled}
        />
      </div>
    );
  }
}

import { useCallback } from 'react';
import processString from 'react-process-string';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';

import { Spinner } from '@alkem/react-ui-spinner';

import Modal from 'components/ui/modal';
import { hasOrganizationChatSetting } from 'core/api/organization-settings';
import { getOrganizationSettings } from 'core/api/user';
import { FEATURE_PERMISSIONS_V3_ORGANIZATION } from 'modules/feature-flag/constants';
import { hasPermissionModule, hasPermissionV3 } from 'modules/permissions';
import {
  CHAT_PERMISSION,
  ORGANIZATION_PERMISSION,
} from 'modules/permissions/const';
import { selectUser } from 'modules/user';
import { suppliersList } from 'routes';
import i18n from 'utils/i18n';
import { get, toJsIfImmutable } from 'utils/immutable';
import { withQuery } from 'utils/query';
import { track } from 'utils/tracking';

import { closeModal, openModal } from '../actions';
import { MODAL_CHAT_RECIPIENT } from '../constants';
import {
  selectRefusedRecipients,
  selectSupplier,
  selectSupplierLoading,
} from '../selectors';

import './index.scss';
import { renderSupplierInfo } from './supplier-info';

export const RefusedByRecipientModal = () => {
  const dispatch = useDispatch();
  const supplier = useSelector(selectSupplier);
  const isLoading = useSelector(selectSupplierLoading);
  const supplierName = get(supplier, 'name');
  const supplierInternalId = get(supplier, 'internalId');
  const supplierId = get(supplier, 'id');
  const navigate = useNavigate();

  const refusedRecipients = toJsIfImmutable(
    useSelector(selectRefusedRecipients),
  );
  const user = useSelector(selectUser);
  const hasChatSetting = hasOrganizationChatSetting(
    getOrganizationSettings(user),
  );
  const canSendMessage = hasPermissionV3(
    user,
    FEATURE_PERMISSIONS_V3_ORGANIZATION,
  )
    ? hasPermissionModule(user, ORGANIZATION_PERMISSION, CHAT_PERMISSION) &&
      hasChatSetting
    : hasChatSetting;
  const goToAnswerModal = () => {
    dispatch(openModal(MODAL_CHAT_RECIPIENT));
  };
  const goToSupplierDirectory = useCallback(() => {
    track({
      category: 'request_status_modal',
      action: 'redirect_to_supplier_directory',
      retailer_name: get(user, ['belongsTo', 0, 'nameLegal']),
      retailer_id: get(user, ['belongsTo', 0, 'id']),
      user_id: get(user, 'id'),
      supplier_name: supplierName,
      supplier_internal_id: supplierInternalId,
    });
    const queries = {
      search: supplierInternalId,
      supplier_id: supplierId,
    };
    navigate(withQuery(suppliersList, queries));
  }, [user, supplierName, supplierInternalId, supplierId, navigate]);

  const onClose = () => {
    dispatch(closeModal());
  };

  const processedSupplier = processString([
    {
      regex: /__SUPPLIER__/,
      fn: () => (
        <span
          key={get(supplier, 'id')}
          className="InformationRequests__supplierManuf"
        >
          {supplierInternalId} - {get(supplier, 'name')}
        </span>
      ),
    },
  ])(
    i18n.t('Your supplier {{supplier}} refused to share this product', {
      supplier: '__SUPPLIER__',
    }),
  );

  return (
    <Modal
      modalStyle="dynamic"
      className="InformationRequestModal"
      title={i18n.t('Your request was refused by the supplier')}
      confirmButtonText={i18n.t('Update reconciliation') as string}
      onClose={onClose}
      secondaryAction={
        canSendMessage
          ? [i18n.t('Answer to the manufacturer'), goToAnswerModal]
          : undefined
      }
      closeButtonText=""
      onConfirm={goToSupplierDirectory}
    >
      {isLoading && (
        <div className="alk-flex alk-flex-v-center">
          <Spinner />
          <div>{i18n.t('Loading')}</div>
        </div>
      )}
      {!isLoading && (
        <>
          <p key={`${supplierId}-${supplierInternalId}`}>{processedSupplier}</p>
          {refusedRecipients.map((refusedRecipient) => (
            <div key={`pid-${refusedRecipient.organizationName}`}>
              <p className="InformationRequests__supplierDetails">
                {renderSupplierInfo(refusedRecipient)}
                <br />
                {refusedRecipient.answerMessage !== '' && (
                  <span
                    key={`msg-${refusedRecipient.organizationName}`}
                    className="InformationRequests__supplierMessage"
                  >
                    {refusedRecipient.answerMessage}
                  </span>
                )}
              </p>
            </div>
          ))}
        </>
      )}
    </Modal>
  );
};

import { array, func, object } from 'prop-types';
import { PureComponent } from 'react';

import { Select } from '@alkem/react-ui-select';

import InputWithLabel from 'components/ui/input/input-with-label';
import i18n from 'utils/i18n';

import './locale-select.scss';

export default class LocaleSelect extends PureComponent {
  static propTypes = {
    currentLanguage: object,
    languages: array.isRequired,
    setLanguage: func.isRequired,
  };

  render() {
    const { languages, currentLanguage, setLanguage } = this.props;
    return (
      <InputWithLabel
        className="BulkEditModal__localeSelect"
        childId="bulk-edition-locale"
        label={i18n.t(
          'frontproductstream.product_bulk_edit.locale_select.label',
          { defaultValue: 'Language of the edited products' },
        )}
        help={i18n.t(
          'frontproductstream.product_bulk_edit.locale_select.help',
          {
            defaultValue:
              'This will define the language for the fields that you are about to bulk edit',
          },
        )}
      >
        <div className="InputField__input">
          <Select
            placeholder={i18n.t(
              'frontproductstream.product_bulk_edit.locale_select.placeholder',
              { defaultValue: 'Select...' },
            )}
            id="bulk-edition-locale"
            options={languages}
            values={currentLanguage ? [currentLanguage] : []}
            onValueAdd={setLanguage}
            disabled={languages.length === 1}
          />
        </div>
      </InputWithLabel>
    );
  }
}

import classNames from 'classnames';
import { get } from 'lodash';
import PropTypes from 'prop-types';
import { Component } from 'react';

import { Ellitips } from '@alkem/react-ui-ellitips';

import { cleanFieldLabel } from 'components/ui/form/field/utils/clean';
import { renderField } from 'components/ui/form/field/utils/render';
import { MEASUREMENT_PRECISION_TRACES } from 'constants/referentials';
import i18n from 'utils/i18n';
import { capitalize } from 'utils/string';

import './pnq-item.scss';

export class PNQItem extends Component {
  static propTypes = {
    onChange: PropTypes.func,
    entity: PropTypes.object.isRequired,
    entityKind: PropTypes.string.isRequired,
    entityId: PropTypes.oneOfType([PropTypes.string, PropTypes.number])
      .isRequired,
    field: PropTypes.object.isRequired,
    index: PropTypes.number.isRequired,
    value: PropTypes.object.isRequired,
    onDelete: PropTypes.func.isRequired,
    fieldExtraParams: PropTypes.object,
  };

  state = {
    isTraces: false,
  };

  componentDidMount() {
    this.handleTraces();
  }

  shouldComponentUpdate(nextProps, nextState) {
    return (
      nextProps.value !== this.props.value ||
      nextProps.field !== this.props.field ||
      nextState.isTraces !== this.state.isTraces ||
      nextProps.fieldExtraParams !== this.props.fieldExtraParams
    );
  }

  componentDidUpdate() {
    this.handleTraces();
  }

  onDelete = () => {
    const { index, onDelete } = this.props;
    onDelete(index);
  };

  isReadOnly() {
    const { field } = this.props;
    return !!(field && field.options && field.options.readOnly);
  }

  handleTraces() {
    const { value, field, index, onChange } = this.props;
    const isTraces =
      value &&
      value.quantityMeasurementPrecision &&
      value.quantityMeasurementPrecision.id === MEASUREMENT_PRECISION_TRACES.id;
    if (isTraces !== this.state.isTraces) {
      this.setState({ isTraces });
      // If traces, erase the values of the pnq.
      if (isTraces) {
        const newValue = {
          nutrientCode: value.nutrientCode,
          quantityMeasurementPrecision: value.quantityMeasurementPrecision,
          // TODO: remove this fake quantity once v1 is kicked.
          // There is also some cleaning that can be removed in selectProductVersionToSave.
          quantity: [
            {
              data: 0,
              expressedIn: get(value, 'quantity.0.expressedIn'),
            },
          ],
        };
        onChange(`${field.model}.${index}`, newValue, false, false);
      }
    }
  }

  renderColumn = (field, index) => {
    if (field.kind === 'Field') {
      // Hide most of the fields if traces is selected as a measurement precision.
      if (
        this.state.isTraces &&
        field.model.indexOf('nutrientCode') === -1 &&
        !field.model.endsWith('quantityMeasurementPrecision')
      ) {
        return null;
      }
      if (field.model.endsWith('percentageOfDailyValueIntake')) {
        return this.renderPercentageOfDailyValueIntake(field, index);
      }
      switch (field.inputKind.kind) {
        case 'label':
          return this.renderLabel(field, index);
        case 'list':
          return this.renderList(field, index);
        case 'select':
          return this.renderSelect(field, index);
        default:
          return null;
      }
    }
    return null;
  };

  renderLabel(field, index) {
    const { entity } = this.props;
    let { model } = field;
    if (
      field.model.indexOf('nutrientCode') !== -1 &&
      !field.model.endsWith('description')
    ) {
      model = `${model}.label`;
    }
    let value = get(entity.edited, model);
    value = value ? capitalize(value) : '';
    return (
      <div className="PNQItem__column PNQItem__label" key={index}>
        <Ellitips id={`pnq-label-${index}`} label={value} />
      </div>
    );
  }

  renderFloat(field, index) {
    const { entity, entityKind, entityId, fieldExtraParams } = this.props;
    const cleanedField = cleanFieldLabel(field, true);
    return (
      <div className="PNQItem__column PNQItem__float" key={index}>
        {renderField(
          entity,
          entityKind,
          entityId,
          index,
          cleanedField,
          false,
          undefined,
          undefined,
          fieldExtraParams,
        )}
      </div>
    );
  }

  renderList(field, index) {
    const { entity, entityKind, entityId, fieldExtraParams } = this.props;
    const cleanedField = cleanFieldLabel(field, true);
    return (
      <div className="PNQItem__column PNQItem__list" key={index}>
        {renderField(
          entity,
          entityKind,
          entityId,
          index,
          cleanedField,
          false,
          undefined,
          undefined,
          fieldExtraParams,
        )}
      </div>
    );
  }

  renderSelect(field, index) {
    const { entity, entityKind, entityId, fieldExtraParams } = this.props;
    const cleanedField = cleanFieldLabel(field, true);
    let text = null;
    if (field.model.endsWith('percentageDVIMeasurementPrecision')) {
      text = this.renderText(
        i18n.t('frontproductstream.psq_editor.pnq_item.precision_prefix', {
          defaultValue: 'that is',
        }),
        index,
        false,
      );
    }
    return [
      text,
      <div className="PNQItem__column PNQItem__select" key={index}>
        {renderField(
          entity,
          entityKind,
          entityId,
          index,
          cleanedField,
          false,
          undefined,
          undefined,
          fieldExtraParams,
        )}
      </div>,
    ];
  }

  renderText(text, index, alignLeft) {
    const classes = {
      PNQItem__column: true,
      PNQItem__text: true,
      'PNQItem__text-alignLeft': alignLeft,
    };
    return (
      <div className={classNames(classes)} key={`${index}-text`}>
        {text}
      </div>
    );
  }

  renderPercentageOfDailyValueIntake(field, index) {
    return [
      this.renderFloat(field, index),
      this.renderText(field.label, index, true),
    ];
  }

  renderRemoveButton() {
    if (this.isReadOnly()) {
      return null;
    }
    return (
      <div className="PNQItem__column PNQItem__delete" onClick={this.onDelete}>
        <i className="mdi mdi-delete" onClick={this.onDelete} />
      </div>
    );
  }

  render() {
    const { field } = this.props;
    return (
      <div className="PNQItem">
        {field.children.map(this.renderColumn)}
        {this.renderRemoveButton()}
      </div>
    );
  }
}

export default PNQItem;

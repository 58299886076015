import { List, Map } from 'immutable';
import { isUndefined } from 'lodash';
import { createReducer } from 'redux-create-reducer';

import { get } from 'utils/immutable';

import {
  REGISTER_BLOCKNAME,
  REGISTER_SECTION,
  SET_BLOCK_POSITION,
  UNREGISTER_BLOCKNAME,
  UNREGISTER_SECTION,
  UPDATE_BLOCKNAME,
} from '../constants';

const findIndexBlockByIdOrName = (blocks, id, name) => {
  if (id) {
    const index = blocks.findIndex((b) => b.get('id') === id);
    if (index >= 0) {
      return index;
    }
  }

  return blocks.findIndex((b) => b.get('name') === name);
};

export const initialState = Map({
  sectionsByName: Map(),
});

const registerSection = (state, { sectionName, sectionOrder, sectionIcon }) => {
  let section =
    state.getIn(['sectionsByName', sectionName]) ||
    Map({
      name: sectionName,
      blocks: List(),
    });

  if (!isUndefined(sectionOrder)) {
    section = section.set('order', sectionOrder);
  }
  if (!isUndefined(sectionIcon)) {
    section = section.set('icon', sectionIcon);
  }

  return state.setIn(['sectionsByName', sectionName], section);
};

export default createReducer(initialState, {
  [REGISTER_SECTION]: (state, { payload }) => registerSection(state, payload),

  [UNREGISTER_SECTION]: (state, { payload }) =>
    state.deleteIn(['sectionsByName', payload.sectionName]),

  [REGISTER_BLOCKNAME]: (
    state,
    { payload: { sectionName, blockId, blockName, position } },
  ) => {
    const blockPath = ['sectionsByName', sectionName, 'blocks'];
    const sectionExists = state.hasIn(blockPath);

    let newState = state;
    if (!sectionExists) {
      newState = registerSection(newState, { sectionName });
    }

    return newState.updateIn(blockPath, (blocks) =>
      blocks.push(
        Map({
          id: blockId,
          name: blockName,
          position: position || 0,
        }),
      ),
    );
  },

  [UPDATE_BLOCKNAME]: (state, { payload }) => {
    const blockPath = ['sectionsByName', payload.sectionName, 'blocks'];
    const sectionExists = state.hasIn(blockPath);

    if (!sectionExists) {
      return state;
    }
    const blockIndex = findIndexBlockByIdOrName(
      state.getIn(blockPath),
      payload.blockId,
      payload.blockName,
    );

    if (blockIndex < 0) {
      return state;
    }
    return state.updateIn(
      [...blockPath, blockIndex, 'name'],
      () => payload.newBlockName,
    );
  },

  [UNREGISTER_BLOCKNAME]: (state, { payload }) => {
    const blockPath = ['sectionsByName', payload.sectionName, 'blocks'];
    const sectionExists = state.hasIn(blockPath);

    if (!sectionExists) {
      return state;
    }
    const blockIndex = findIndexBlockByIdOrName(
      state.getIn(blockPath),
      payload.blockId,
      payload.blockName,
    );

    if (blockIndex < 0) {
      return state;
    }
    return state.removeIn([...blockPath, blockIndex], () => false);
  },

  [SET_BLOCK_POSITION]: (state, { blockId, blockName, position }) => {
    // This means that the section has been collapsed and the element is hidden, keep original position.
    if (position === null) {
      return state;
    }

    const sectionName = state
      .get('sectionsByName', Map())
      .findKey((v) =>
        get(v, ['blocks'], List()).some((b) => get(b, ['name']) === blockName),
      );
    if (!sectionName) {
      return state;
    }

    const blockPath = ['sectionsByName', sectionName, 'blocks'];
    const blockIndex = findIndexBlockByIdOrName(
      state.getIn(blockPath),
      blockId,
      blockName,
    );
    if (blockIndex < 0) {
      return state;
    }

    return state.setIn([...blockPath, blockIndex, 'position'], position);
  },
});

export const GET_TAX_LABELS = 'price-waterfalls/GET_TAX_LABELS';
export const GET_BRACKET_TYPES = 'price-waterfalls/GET_BRACKET_TYPES';
export const GET_LEVEL_TYPES = 'price-waterfalls/GET_LEVEL_TYPES';

export const RECEIVE_TAX_LABELS = 'price-waterfalls/RECEIVE_TAX_LABELS';
export const RECEIVE_BRACKET_TYPES = 'price-waterfalls/RECEIVE_BRACKET_TYPES';
export const RECEIVE_LEVEL_TYPES = 'price-waterfalls/RECEIVE_LEVEL_TYPES';
export const RECEIVE_LEVEL_ITEM_TYPES =
  'price-waterfalls/RECEIVE_LEVEL_ITEM_TYPES';

export const CREATE_PRICE_WATERFALL = 'price-waterfalls/CREATE_PRICE_WATERFALL';
export const CREATED_PRICE_WATERFALL =
  'price-waterfalls/CREATED_PRICE_WATERFALL';

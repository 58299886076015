import { SearchQueryType } from '@alkem/sdk-dashboard/src/search/search.api';

import { isEAN } from 'utils/actions/list';
import formatMultipleSearch from 'utils/formatMultipleSearch';

export const buildSearch = (query: string): SearchQueryType => {
  if (!query) {
    return {};
  }
  const cleanSearch = formatMultipleSearch(query);
  if (isEAN(cleanSearch)) {
    return { gtinsIn: cleanSearch, productIdentifiersIn: cleanSearch };
  }
  return { queryName: query, productIdentifiersIn: cleanSearch };
};

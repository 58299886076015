import PropTypes from 'prop-types';
import { PureComponent } from 'react';

import MediaOrganizationCard from './card';

export class MediaOrganization extends PureComponent {
  static propTypes = {
    organizationId: PropTypes.number.isRequired,
  };

  render() {
    const { organizationId } = this.props;
    return (
      <div className="card card-block MediaOrganization__container">
        <div className="MediaOrganization__list">
          <MediaOrganizationCard
            organizationId={organizationId}
            type="profile"
          />
          <MediaOrganizationCard organizationId={organizationId} type="cover" />
        </div>
      </div>
    );
  }
}
export default MediaOrganization;

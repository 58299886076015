import Immutable from 'immutable';
import { memo } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { TargetProductStatusUpdated } from 'constants/targetProductStatus';
import { withRetailer } from 'hocs';
import { AnchoredSection } from 'modules/anchored';
import { HistoryDiffHeader, HistoryDiffLine } from 'modules/history';
import { getPermissionKey, usePermission } from 'modules/permissions';
import {
  PRODUCT_PERMISSION,
  SHARING_UNIT_PERMISSION,
  UPDATES_VALIDATE_PERMISSION,
} from 'modules/permissions/const';
import { openRefuseModal } from 'modules/product-page/modules/refuse-modal/actions';
import {
  selectCanUpdateProduct,
  selectCanUpdateSharingUnit,
  selectIsDisplayUnit,
  selectIsShareAccepted,
  selectProductKeyId,
  selectProductVersionId,
  selectTargetProductStatus,
} from 'reducers/productVersion';
import i18n from 'utils/i18n';

import { selectSharingunitsMap } from '../retailer-sharing-units/selectors';

import { acceptConsumerUnit, acceptListingUpdates } from './actions';
import {
  CONSUMER_UNIT_TYPE,
  ProductShareDiffActions,
  SHARING_UNIT_TYPE,
} from './components/button-bar';
import './product-share-diff.scss';
import {
  selectApplyRequestsInProgress,
  selectConsumerUnitDiff,
  selectSharingUnitDiffs,
} from './selectors';

interface Props {
  order?: number;
}

export const ProductShareDiff = withRetailer<Props>(
  memo(({ order }: Props) => {
    const dispatch = useDispatch();

    const applyRequestsInProgress: boolean = useSelector(
      selectApplyRequestsInProgress,
    );
    const consumerUnitDiff: Immutable.Map<string, any> = useSelector(
      selectConsumerUnitDiff,
    );
    const isDisplayUnit: boolean = useSelector(selectIsDisplayUnit);
    const isShareAccepted: boolean = useSelector(selectIsShareAccepted);
    const productStatus: string = useSelector(selectTargetProductStatus);
    const productVersionId: number = useSelector(selectProductVersionId);
    const sharingUnitDiffs: Immutable.Map<number, any> = useSelector(
      selectSharingUnitDiffs,
    );
    const sharingUnitsMap: Immutable.Map<string, any> = useSelector(
      selectSharingunitsMap,
    );
    const productKeyId = useSelector(selectProductKeyId);

    // Permissions V2
    const canUpdateProduct: boolean = useSelector(selectCanUpdateProduct);
    const canUpdateSharingUnit: boolean = useSelector(
      selectCanUpdateSharingUnit,
    );
    // Permissions V3
    const { hasLegacyPermissions, hasPermission } = usePermission({
      module: PRODUCT_PERMISSION,
    });

    if (!isShareAccepted || productStatus !== TargetProductStatusUpdated.id) {
      return null;
    }

    if (consumerUnitDiff.isEmpty() && sharingUnitDiffs.isEmpty()) {
      return null;
    }

    const canValidateProductUpdates = hasLegacyPermissions
      ? canUpdateProduct
      : hasPermission({
          permissions: UPDATES_VALIDATE_PERMISSION,
          entityId: productKeyId,
        });
    const canValidateSharingUnitUpdates = hasLegacyPermissions
      ? canUpdateSharingUnit
      : hasPermission({
          permissions: getPermissionKey(
            SHARING_UNIT_PERMISSION,
            UPDATES_VALIDATE_PERMISSION,
          ),
          entityId: productKeyId,
          modulePrefix: false,
        });

    const onAcceptCuUpdates = () => {
      dispatch(acceptConsumerUnit());
    };

    const onAcceptSuUpdates = (sharingUnitId: number | string) => {
      dispatch(
        acceptListingUpdates({
          sharingUnitId,
        }),
      );
    };

    const onRefuse = (payload: {
      entityId: number | string;
      entityType: string;
    }) => {
      dispatch(
        openRefuseModal({
          ...payload,
          title: i18n.t('Update refusal'),
        }),
      );
    };

    return (
      <AnchoredSection
        collapsible
        section={i18n.t('Update to validate')}
        id="updatetovalidate"
        order={order}
      >
        {!consumerUnitDiff.isEmpty() && (
          <div id="ProductShareDiff_CU" className="ProductShareDiff">
            <h2>
              {isDisplayUnit
                ? i18n.t('Display unit updates')
                : i18n.t('Consumer unit updates')}
            </h2>
            <HistoryDiffHeader />
            {consumerUnitDiff
              .map((item) => <HistoryDiffLine key={item} diff={item} />)
              .toArray()}
            <ProductShareDiffActions
              type={CONSUMER_UNIT_TYPE}
              entityId={productVersionId}
              onAccept={onAcceptCuUpdates}
              onRefuse={onRefuse}
              readOnly={!canValidateProductUpdates}
              applyInProgress={applyRequestsInProgress}
            />
          </div>
        )}
        {(
          sharingUnitDiffs.entrySeq() as any as [
            number,
            Immutable.Map<string, any>,
          ][]
        )
          .filter(
            ([, sharingUnit]) =>
              sharingUnit.get('diff') && sharingUnit.get('diff').size > 0,
          )
          .map(([key, sharingUnit]) => (
            <div
              id={`ProductShareDiff_SU-${key}`}
              className="ProductShareDiff"
              key={key}
            >
              <h2>{sharingUnitsMap.getIn([key, 'data', 'name'])}</h2>
              <HistoryDiffHeader />
              {sharingUnit.get('diff').map((diff) => (
                <HistoryDiffLine key={diff} diff={diff} />
              ))}{' '}
              <ProductShareDiffActions
                type={SHARING_UNIT_TYPE}
                entityId={key}
                onAccept={onAcceptSuUpdates}
                onRefuse={onRefuse}
                readOnly={!canValidateSharingUnitUpdates}
                applyInProgress={applyRequestsInProgress}
              />
            </div>
          ))}
      </AnchoredSection>
    );
  }),
);

import { registerModuleReducer } from 'utils/modules/reducer';
import { registerSaga } from 'utils/modules/saga';

import { DATA_OPS_REDUCER } from './constants';
import { dataOpsReducers } from './reducers';
import { dataOpsSagas, savePatches as savePatchesSaga } from './sagas';

registerModuleReducer(DATA_OPS_REDUCER, dataOpsReducers);
registerSaga(dataOpsSagas);

export * from './types';
export * from './constants';
export * from './actions';
export * from './selectors';
export { DataOpsEditPlugin } from './components/edit-plugin';
export { DataOpsInfoPlugin } from './components/info-plugin';
export { DataOpsDiffBlocks } from './components/diff-blocks';
export { savePatchesSaga };

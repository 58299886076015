import classNames from 'classnames';
import { Map } from 'immutable';
import { useMemo } from 'react';
import { v4 as uuid } from 'uuid';

import { LazyTooltip } from '@alkem/react-ui-tooltip';

import PercentageCell from 'modules/catalog/product/components/list/table/cell/percentage';
import i18n from 'utils/i18n';
import { sortDesc } from 'utils/sort';
import { track } from 'utils/tracking';

import { MaturityPanelTabIndex } from '../maturity-panel';
import { MaturityPanelManager } from '../maturity-panel-manager';
import '../maturity/index.scss';
import {
  MATURITY_COLOR_RANGE,
  MaturitiesSummary,
  MaturitySummary,
  isScenarioComplete,
} from '../types';

interface Props {
  data: Map<string, any>;
  rowIdentifier?: number;
  clickable?: boolean;
}

export default function MaturityCell({
  data,
  rowIdentifier,
  clickable = false,
}: Props) {
  const id = useMemo(() => uuid(), []);
  const maturitiesSummary: MaturitiesSummary = data.toJS();

  const trackDisplayMaturitiesInfo = (displayType: string) => {
    track({
      category: 'product',
      action: 'display_scenarios_info_on_catalog',
      modal_type: displayType,
    });
  };

  const body = (
    <PercentageCell
      data={data as Map<string, number>}
      countPath="successful"
      totalPath="total"
      colorRange={MATURITY_COLOR_RANGE}
      className={classNames(
        maturitiesSummary.not_requested && 'Maturity__not-requested',
      )}
    />
  );

  if (clickable) {
    return (
      <div className="MaturityCell">
        <MaturityPanelManager
          maturitiesSummary={maturitiesSummary}
          selectedTab={MaturityPanelTabIndex.COMPLETION}
          rowIdentifier={rowIdentifier}
        >
          {body}
        </MaturityPanelManager>
      </div>
    );
  }

  if (maturitiesSummary.active_scenarios.length && !clickable) {
    const maturitiesToDisplay = maturitiesSummary.active_scenarios;

    const maxMaturitiesToDisplayInTooltip = 5;

    const message = (
      <>
        {maturitiesToDisplay
          .sort((a, b) =>
            sortDesc(`${a.total - a.successful}`, `${b.total - b.successful}`),
          )
          .slice(0, maxMaturitiesToDisplayInTooltip)
          .map((maturity: MaturitySummary) => (
            <div
              key={`maturity-${maturity.id}-${id}`}
              className={classNames(
                'alk-flex',
                'alk-flex-center',
                'MaturityCell__details',
                isScenarioComplete(maturity)
                  ? 'MaturityCell__details--ok'
                  : 'MaturityCell__details--ko',
              )}
            >
              <div>{maturity.label || maturity.id}</div>
              <div>
                {maturity.successful} / {maturity.total}
              </div>
              <i
                className={
                  isScenarioComplete(maturity)
                    ? 'ProductValidationStatusOk'
                    : 'ProductValidationStatusKo'
                }
              />
            </div>
          ))}
        {maturitiesToDisplay.length - maxMaturitiesToDisplayInTooltip > 0 && (
          <div className="MaturityCell__rest">
            {i18n.t('+{{count}} more', {
              count:
                maturitiesToDisplay.length - maxMaturitiesToDisplayInTooltip,
            })}
          </div>
        )}
      </>
    );
    return (
      <div className="MaturityCell alk-flex alk-flex-center alk-flex-justify-center">
        <LazyTooltip
          id={`tooltip-${id}`}
          tooltipLabel={message}
          afterShow={() => trackDisplayMaturitiesInfo('tooltip')}
          block
        >
          {body}
        </LazyTooltip>
      </div>
    );
  }

  return (
    <div className="MaturityCell alk-flex alk-flex-center alk-flex-justify-center">
      {body}
    </div>
  );
}

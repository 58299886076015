import classNames from 'classnames';
import { get } from 'lodash';
import moment from 'moment';
import { connect } from 'react-redux';

import Field from 'components/ui/form/field';
import Date from 'components/ui/input/date';
import {
  TIME_FORMAT_MINUTE_LOCALIZED,
  formatTimeImplicitUTC,
  parseDateFromValue,
} from 'utils/datetime';

import './date.scss';

class DateField extends Field {
  componentDidUpdate() {
    this.ensureDataIsPresent();
  }

  getValue(date) {
    if (!moment.isMoment(date)) {
      return null;
    }
    if (get(this.props, 'field.inputKind.output') === 'timestamp') {
      return moment.utc([date.year(), date.month(), date.date()]).unix();
    }
    if (
      get(this.props, 'field.inputKind.output') === 'iso_date' ||
      get(this.props, 'field.inputKind.kind') === 'iso_date'
    ) {
      return formatTimeImplicitUTC(date, TIME_FORMAT_MINUTE_LOCALIZED);
    }
    return date.format('YYYY-MM-DD');
  }

  render() {
    const { field } = this.props;
    if (!field) {
      return null;
    }

    const renderedLabel = this.renderLabel('col-xs-4');
    const classes = {
      FormDate__input: true,
      'col-xs-8': !!renderedLabel,
      'col-xs-12': !renderedLabel,
    };
    const output =
      get(this.props, 'field.inputKind.output') === undefined
        ? get(this.props, 'field.inputKind.kind')
        : get(this.props, 'field.inputKind.output');

    return (
      <div className={classNames(this.getClasses({ 'FormDate row': true }))}>
        {renderedLabel}
        <div className={classNames(classes)}>
          <Date
            id={this.getId()}
            value={parseDateFromValue(this.props.value, output)}
            placeholder={field.placeholder || field.label}
            onSelect={this.handleChange}
            readOnly={this.isReadOnly()}
          />
          {this.renderPlugins()}
        </div>
      </div>
    );
  }
}

export default connect(null)(DateField);

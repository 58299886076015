import { set } from 'lodash/fp';

import { ActionPayload } from 'types/redux';
import { createFpReducer } from 'utils/redux';

import { receiveRFPs } from './actions';
import { CLEAR, RECEIVE } from './constants';
import { ProductRFPs } from './types';

export interface RFPsState {
  rfps: ProductRFPs[] | [];
}

export const initialState: RFPsState = {
  rfps: [],
};

export const productPageReducer = createFpReducer<RFPsState>(initialState, {
  [RECEIVE]: (data: ActionPayload<typeof receiveRFPs>) =>
    set('rfps', data || []),
  [CLEAR]: () => set('rfps', []),
});

import { List, Map } from 'immutable';
import { PureComponent, ReactElement } from 'react';

import { Spinner } from '@alkem/react-ui-spinner';

import { buildFiltersFromQuery } from 'core/modules/list/utils/filters';
import { FilterAggregation, FilterChangeEvent } from 'types';
import i18n from 'utils/i18n';
import { getKey } from 'utils/immutable';

import CollapsibleFilter from '../collapsible';

import Filter from './filter';

interface ListCollapsibleAdvancedFilterProps {
  id: string;
  filters: FilterAggregation[] | any;
  selectedFilterMap?: Map<string, any>;
  aggregations?:
    | Map<string, any>
    | null
    | Record<string, { id: string; doc_count: number }>;
  searchQuery?: string;
  page?: number;
  itemsPerPage?: number;
  renderItem?: (filter: { id: string | number; name: string }) => ReactElement;
  filterKey: string;
  filterType?: string;
  filterLabel: string;
  collapsed?: boolean;
  searchPlaceholder?: string;
  onFilter: (key: string, query: string) => void;
  onChange: (
    filters: FilterChangeEvent | FilterChangeEvent[],
    fromQuery?: boolean,
  ) => void;
  onChangePage?: (key: string, page: number) => void;
  onCollapse: (filterKey: string, collapsed: string) => void;
  onSort?: (key: string) => void;
  withPagination?: boolean;
  withTree?: boolean;
  selectors?: {
    selectId: (...args: any) => string;
    selectLabel: (...args: any) => string;
    selectIconClass?: () => string;
    selectChildren?: (...args: any) => any;
  };
  isLoading?: boolean;
  filterQueryValues?: string[];
  isExactFilter?: boolean;
  hasMissingFilter?: boolean;
  missingFilterKey?: string;
  forceDocCount?: boolean;
}

export default class ListCollapsibleAdvancedFilter extends PureComponent<ListCollapsibleAdvancedFilterProps> {
  static defaultProps = {
    filters: List(),
    selectedFilterMap: Map(),
    aggregations: Map(),
    searchQuery: '',
    page: 1,
    itemsPerPage: 8,
    collapsed: true,
    withPagination: true,
    withTree: false,
    selectors: {
      selectId: (filter) => getKey(filter, 'id'),
      selectLabel: (filter) => getKey(filter, 'name'),
    },
    isLoading: false,
    isExactFilter: false,
    forceDocCount: false,
  };

  onCollapse = (collapsed) => {
    this.props.onCollapse(this.props.filterKey, collapsed);
  };

  updateSelectionFromQuery = () => {
    const { onChange, filterQueryValues, filterKey, filterLabel, filters } =
      this.props;
    buildFiltersFromQuery({
      filterQueryValues,
      filterList: filters,
      filterKey,
      selectFilterValue: (filter) => getKey(filter, 'id'),
      selectFilterLabel: (filter) =>
        `${filterLabel}: ${getKey(filter, 'name')}`,
      selectFilterData: (filter) => [getKey(filter, 'id')],
    }).then((filtersFromQuery) => {
      onChange(filtersFromQuery, true);
    });
  };

  componentDidMount() {
    if (this.props.filterQueryValues) {
      this.updateSelectionFromQuery();
    }
  }

  render() {
    return (
      <CollapsibleFilter
        id={this.props.id}
        label={this.props.filterLabel}
        collapsed={this.props.collapsed}
        onCollapse={this.onCollapse}
      >
        {this.props.isLoading ? (
          <div className="loader">
            <Spinner small />
            {i18n.t('frontproductstream.core.list_filter_advanced.loading', {
              defaultValue: 'Loading...',
            })}
          </div>
        ) : (
          <Filter
            filterKey={this.props.filterKey}
            filterType={this.props.filterType}
            filterLabel={this.props.filterLabel}
            filters={this.props.filters}
            selectedFilterMap={this.props.selectedFilterMap}
            aggregations={this.props.aggregations}
            searchQuery={this.props.searchQuery}
            searchPlaceholder={this.props.searchPlaceholder}
            page={this.props.page}
            itemsPerPage={this.props.itemsPerPage}
            renderItem={this.props.renderItem}
            selectors={this.props.selectors}
            onFilter={this.props.onFilter}
            onChange={this.props.onChange}
            onChangePage={this.props.onChangePage}
            withPagination={this.props.withPagination}
            withTree={this.props.withTree}
            onSort={this.props.onSort}
            isExactFilter={this.props.isExactFilter}
            hasMissingFilter={this.props.hasMissingFilter}
            missingFilterKey={this.props.missingFilterKey}
            hasDocCount={this.props.forceDocCount ? true : undefined}
          />
        )}
      </CollapsibleFilter>
    );
  }
}

import { SETTING_ENABLE_VALUE } from 'constants/organization-settings';
import { SOURCE_ALKEMICS } from 'constants/organization-source';
import { hasViewSourcing } from 'core/api/organization-settings';
import {
  getOrganizationId,
  getOrganizationSettings,
  getOrganizationSource,
  isAdmin,
  isLoggedAs,
  isManufacturer,
  isRetailer,
} from 'core/api/user';
import { hasFeatureValue } from 'modules/feature-flag';
import { FEATURE_COMPANY_PROFILE } from 'modules/feature-flag/constants';

const hasCompanyProfileSetting = (user) =>
  hasFeatureValue(user, FEATURE_COMPANY_PROFILE, SETTING_ENABLE_VALUE);

export const hasCompanyProfile = (user) =>
  (isManufacturer(user) &&
    getOrganizationSource(user) === SOURCE_ALKEMICS &&
    hasCompanyProfileSetting(user)) ||
  (isRetailer(user) && isLoggedAs(user));

export const hasCompanyProfileRetailerAccess = (user) =>
  isRetailer(user) &&
  getOrganizationSource(user) === SOURCE_ALKEMICS &&
  hasCompanyProfileSetting(user);

export const hasAccessToCompanyProfile = (user) =>
  hasCompanyProfile(user) || hasCompanyProfileRetailerAccess(user);

export const hasAccessToCompanyProfileEdit = (user) =>
  hasCompanyProfile(user) && isAdmin(user);

export const isOwnCompanyProfile = (user, companyId) =>
  companyId === getOrganizationId(user);

export const hasRetailerCompanyProfileActions = (user) =>
  hasCompanyProfileRetailerAccess(user) &&
  hasViewSourcing(getOrganizationSettings(user));

import classnames from 'classnames';
import { flatten, get, uniq } from 'lodash/fp';
import memoize from 'memoize-one';
import { PureComponent } from 'react';

import Anchor from 'components/ui/basic/anchor';
import Raguel from 'components/ui/form/plugins/validator';
import { ENTITY_TYPE_PRODUCT_VERSION_HIERARCHY } from 'constants/entities';
import { UNITED_KINGDOM } from 'constants/targetMarkets';
import { TypePackagingLabels } from 'constants/typePackaging';
import { getTypePackagingId } from 'core/api/productversion';
import { withErrorBoundary } from 'hocs';
import { TEXTILE_ONLY_FIELDS } from 'modules/product-page/modules/textile/constants';
import { findFieldByModel } from 'utils/displayGroup';
import i18n from 'utils/i18n';

import { getReference } from '../../../../core/api/product';
import {
  getMainHierarchyUnit,
  isConsumerOrDisplayUnit,
  isStandardTextileHierarchy,
} from '../../helpers';
import { Data } from '../../structures';
import LogisticalHierarchyForm from '../form';
import { LogisticalHierarchyDimensions } from '../grouped-fields/dimensions';
import { LogisticalHierarchyWeights } from '../grouped-fields/weights';
import LogisticalHierarchy from '../hierarchy';
import OneLineHierarchy from '../one-line';

import './collapsible-hierarchy.scss';
import { CollapsibleHierarchyError } from './error';
import { CollapsibleHierarchyProps } from './types';

function stopPropagation(e) {
  e.stopPropagation();
}

interface State {
  showErrorBlock: boolean;
  areErrorsNotRequested: boolean;
}

class LocalCollapsibleHierarchy extends PureComponent<
  CollapsibleHierarchyProps,
  State
> {
  public constructor(props: CollapsibleHierarchyProps) {
    super(props);
    this.state = {
      showErrorBlock: false,
      areErrorsNotRequested: false,
    };
  }

  private onJudgmentDay = ({
    error,
    notRequested,
  }: {
    error: boolean;
    notRequested: boolean;
  }) => {
    this.setState({
      showErrorBlock: error,
      areErrorsNotRequested: notRequested,
    });
  };

  private getFieldVisibilityAndReadOnly = (fieldName: string) =>
    memoize((allDisplayGroups) => {
      const field = findFieldByModel(
        flatten(Object.values(allDisplayGroups)),
        fieldName,
      );
      return {
        visible: !!field,
        readOnly: (field && get(['options', 'readOnly'], field)) || false,
      };
    });

  fieldVisibilityAndReadOnly = {
    isSizedBy: this.getFieldVisibilityAndReadOnly('isSizedBy'),
    grossWeight: this.getFieldVisibilityAndReadOnly('grossWeight'),
  };

  private getVersionData(data: Data) {
    const { currentVersionInfo } = this.props;
    if (getReference(data) === getReference(currentVersionInfo)) {
      return currentVersionInfo;
    }
    return data.version;
  }

  public toggle = () => {
    this.props.toggle(this.props.rootInternalId);
  };

  private isEditable = (internalId: string) => {
    const { dataMap, currentVersionInfo } = this.props;
    const versionDataReference = this.getVersionData(dataMap[internalId]);
    return (
      !isConsumerOrDisplayUnit(versionDataReference) &&
      getReference(versionDataReference) !== getReference(currentVersionInfo)
    );
  };

  private getHierarchyInternalIds = (internalId: string) => {
    const { hierarchyMap } = this.props;
    const childIds = hierarchyMap[internalId].map((e) => e.id);
    return uniq([
      internalId,
      ...flatten(childIds.map(this.getHierarchyInternalIds)),
    ]);
  };

  private renderErrorBlock() {
    const { rootInternalId, readOnly, logisticalUnitsAsTree } = this.props;
    return (
      <Raguel
        entityId={rootInternalId}
        entityKind={ENTITY_TYPE_PRODUCT_VERSION_HIERARCHY}
        label={i18n.t(
          'frontproductstream.collapsible_hierarchies.hierarchy.text',
          { defaultValue: 'Logistical Hierarchy' },
        )}
        model="logisticalHierarchies"
        value={logisticalUnitsAsTree}
        onJudgmentDay={this.onJudgmentDay}
        readOnly={readOnly}
      />
    );
  }

  private renderLevels(isGenericTextileHierarchy: boolean) {
    const {
      rootInternalId,
      dataMap,
      currentVersionInfo,
      allDisplayGroups,
      readOnly,
      patches,
      isPatchedHierarchy,
      hasFeatureNoGtin,
    } = this.props;
    const editableHierarchyIds = this.getHierarchyInternalIds(
      rootInternalId,
    ).filter(this.isEditable as any) as string[];

    const excludedFieldNames = ['isSizedBy', 'grossWeight'];
    if (!isGenericTextileHierarchy) {
      excludedFieldNames.push(...TEXTILE_ONLY_FIELDS);
    }

    return editableHierarchyIds.map((internalId) => {
      const unit = dataMap[internalId];
      const { gtin, productIdentifier, isPatch, isPatchableVersion } = unit;
      return (
        <LogisticalHierarchyForm
          key={internalId}
          internalId={internalId}
          rootInternalId={rootInternalId}
          gtin={gtin}
          productIdentifier={productIdentifier}
          hasFeatureNoGtin={hasFeatureNoGtin}
          data={this.getVersionData(unit)}
          currentLanguage={currentVersionInfo.currentLanguage}
          readOnly={isPatchableVersion || readOnly}
          allDisplayGroups={allDisplayGroups}
          hasFlatHierarchies
          excludedFieldNames={excludedFieldNames}
          patches={patches}
          disableDataOps={
            (isPatchedHierarchy && !isPatchableVersion) ||
            (!isPatchedHierarchy && isPatch && !isPatchableVersion)
          }
        />
      );
    });
  }

  public render() {
    const {
      rootInternalId,
      path,
      dataMap,
      hierarchyMap,
      currentVersionInfo,
      logisticalUnitsAsTree,
      onBulkUpdate,
      allDisplayGroups,
      targetMarketId,
      readOnly,
      patches,
      hasDataOps,
      onPatch,
      onBulkPatch,
      isPatchedHierarchy,
      isDataOpsPatcher,
      isDataOpsReceiver,
      expanded,
      areLogisticalUnitsReadOnly,
    } = this.props;
    const rootUnit = dataMap[rootInternalId];

    if (isPatchedHierarchy && rootUnit?.isAnomaly) {
      // caught by withErrorBoundary
      throw new Error(
        i18n.t(
          'frontproductstream.collapsible_hierarchies.root_hierarchy.error',
          { defaultValue: 'The root of this hierarchy already exists' },
        ),
      );
    }

    const mainHierarchyUnit = getMainHierarchyUnit(
      rootInternalId,
      dataMap,
      hierarchyMap,
      currentVersionInfo,
    );
    const isMainHierarchy =
      mainHierarchyUnit &&
      getReference(mainHierarchyUnit) === getReference(currentVersionInfo);

    const isGenericTextileHierarchy =
      !isStandardTextileHierarchy(
        rootInternalId,
        dataMap,
        hierarchyMap,
        currentVersionInfo,
      ) && (currentVersionInfo.textileVariantGTINs?.length ?? 0) > 0;
    const hierarchyProps = {
      ...this.props,
      readOnly: readOnly || (!isMainHierarchy && !!mainHierarchyUnit),
      hasFlatHierarchies: true,
    };
    const { visible: dimensionsVisible, readOnly: dimensionsReadOnly } =
      this.fieldVisibilityAndReadOnly.isSizedBy(allDisplayGroups);
    const { visible: weightsVisible, readOnly: weightsReadOnly } =
      this.fieldVisibilityAndReadOnly.grossWeight(allDisplayGroups);
    const groupedFieldsProps = {
      rootInternalId,
      path,
      dataMap,
      hierarchyMap,
      currentVersionInfo,
      targetMarketId,
      logisticalUnitsAsTree,
      onBulkUpdate,
      patches,
      hasDataOps,
      onPatch,
      onBulkPatch,
      isPatchedHierarchy,
      isDataOpsPatcher,
      isDataOpsReceiver,
      areLogisticalUnitsReadOnly,
    };
    return (
      <div
        className="CollapsibleHierarchy"
        data-root-internal-id={rootInternalId}
        data-patched-hierarchy={isPatchedHierarchy}
        data-patch-id={rootUnit?.patch_id}
      >
        <div
          id={`logisticalHierarchies-${path}-header`}
          className={classnames('CollapsibleHierarchy__header', {
            'FormField--raguelError': this.state.showErrorBlock,
            'FormField--notRequested': this.state.areErrorsNotRequested,
            'CollapsibleHierarchy__header--patched': isPatchedHierarchy,
          })}
          onClick={this.toggle}
        >
          <div className="CollapsibleHierarchy__headerContent">
            <OneLineHierarchy
              rootInternalId={rootInternalId}
              dataMap={dataMap}
              hierarchyMap={hierarchyMap}
              currentVersionInfo={currentVersionInfo}
            />
            <span className="alk-flex alk-flex-center">
              {getTypePackagingId(rootUnit?.version) ===
                TypePackagingLabels.CASE.id &&
                !areLogisticalUnitsReadOnly &&
                targetMarketId !== UNITED_KINGDOM && (
                  <span
                    className="OneLineHierarchy__caseHelp"
                    onClick={stopPropagation}
                  >
                    {i18n.t(
                      'frontproductstream.collapsible_hierarchies.add_pallet.help',
                      { defaultValue: 'How to add a pallet above a case?' },
                    )}{' '}
                    <Anchor
                      href={'https://help.salsify.com/SupplierXM/s/article/206'}
                    >
                      {i18n.t(
                        'frontproductstream.collapsible_hierarchies.documentation.help',
                        { defaultValue: 'Check our documentation' },
                      )}
                    </Anchor>
                  </span>
                )}
              <span className="CollapsibleHierarchy__collapseBtn">
                <i
                  className={classnames('mdi', {
                    'mdi-chevron-down': !expanded,
                    'mdi-chevron-up': expanded,
                  })}
                />
              </span>
            </span>
          </div>
          {isMainHierarchy && this.renderErrorBlock()}
        </div>
        {expanded && (
          <div className="CollapsibleHierarchy__content">
            <LogisticalHierarchy {...hierarchyProps} />
            {isMainHierarchy && (
              <>
                {this.renderLevels(isGenericTextileHierarchy)}
                {dimensionsVisible && (
                  <LogisticalHierarchyDimensions
                    {...groupedFieldsProps}
                    readOnly={readOnly || dimensionsReadOnly}
                  />
                )}
                {weightsVisible && (
                  <LogisticalHierarchyWeights
                    {...groupedFieldsProps}
                    readOnly={readOnly || weightsReadOnly}
                  />
                )}
              </>
            )}
          </div>
        )}
      </div>
    );
  }
}

export const CollapsibleHierarchy = withErrorBoundary(
  LocalCollapsibleHierarchy,
  CollapsibleHierarchyError,
);

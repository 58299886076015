import { ProgressBar } from '@alkem/react-ui-progress';

import { computePercentageAndQuality } from 'modules/catalog/product/components/list/table/cell/percentage';
import i18n from 'utils/i18n';

import {
  MATURITY_COLOR_RANGE,
  MaturitySummary,
  isScenarioComplete,
  isScenarioDeadlineBehind,
  parseDeadline,
} from '../../types';

import './index.scss';

interface Props {
  maturity: MaturitySummary;
  includeLateLabel: boolean;
}

export const LateMessage = ({ maturity }) => {
  if (!maturity.deadline) {
    return null;
  }
  const deadline = parseDeadline(maturity);
  const isDeadlineBehind = isScenarioDeadlineBehind(deadline);

  if (isScenarioComplete(maturity) || !isDeadlineBehind) {
    return null;
  }

  const message = i18n.t(
    'frontproductstream.maturity_panel.late_scenario.text',
    {
      defaultValue: 'Requested for the {{date}}',
      date: deadline.format('L'),
    },
  );

  return (
    <div className="MaturityCompletion__lateLabel">
      <i className="mdi mdi-calendar-alert" />
      {message}
    </div>
  );
};

export const MaturityCompletion = ({ maturity, includeLateLabel }: Props) => {
  const qualityToColor = {
    low: 'danger',
    medium: 'warning',
    good: 'success',
  };

  return (
    <div className="MaturityCompletion">
      <div className="MaturityCompletion__maturityLabel">{maturity.label}</div>
      {includeLateLabel && <LateMessage maturity={maturity} />}
      <ProgressBar
        color={
          qualityToColor[
            computePercentageAndQuality(
              maturity.successful,
              maturity.total,
              MATURITY_COLOR_RANGE,
            ).quality
          ]
        }
        value={maturity.successful}
        max={maturity.total}
        labelWithColor={true}
      />
    </div>
  );
};

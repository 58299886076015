import classNames from 'classnames';
import { get } from 'lodash';
import { Fragment, memo, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { ENTITY_TYPE_PRODUCT_MEDIA } from 'constants/entities';
import { ASSET_TYPE } from 'constants/media';
import { FEATURE_PRODUCTPICTURE_JSON_STORE_INPUT } from 'modules/feature-flag';
import {
  selectHasFeature,
  selectHasReleaseProductpageFlywheelMedia,
} from 'modules/feature-flag/selectors';
import { ruleEntities, updateAssetHasErrorByAssetId } from 'modules/validation';

import Raguel from '../../plugins/validator';
import type { AssetTypeV2 } from '../AssetV2/types';

import './asset.scss';
import DocumentAsset from './document';
import PictureAsset from './picture';
import RecipeAsset from './recipe';
import VideoAsset from './video';

type Props = {
  entityId?: number;
  options: object;
  actions: any[];
  selectedPictureIds?: number[];
  onPictureSelectionChange: (assetId: number, checked: boolean) => void;
  field?: object;
  hasUpdatePermission?: boolean;
  extraParams?: {
    recipientId: number;
  };
  asset: AssetTypeV2;
  productKeyId: string | number;
  updateEntities: (type: string, id?: number) => void;
  settingNewPackshot: (packshot: { newPackshotId: number | null }) => void;
  newPackshotId: number | null;
  index: number;
};

export const Asset = memo((props: Props) => {
  const [deleting, setDeleting] = useState(false);
  const [hasErrors, setErrors] = useState(false);
  const [areErrorsNotRequested, setErrorsNotRequested] = useState(false);
  const {
    selectedPictureIds = [],
    options = {},
    asset,
    field,
    actions,
    entityId,
    hasUpdatePermission,
    extraParams,
    onPictureSelectionChange,
  } = props;

  const hasMediaFieldMigrationFlag = useSelector(
    selectHasFeature(FEATURE_PRODUCTPICTURE_JSON_STORE_INPUT),
  );

  const hasReleaseProductpageFlywheelMedia = useSelector(
    selectHasReleaseProductpageFlywheelMedia,
  );

  const dispatch = useDispatch();

  const onDelete = (toggle: boolean) => {
    setDeleting(toggle);
  };

  const onValidate = ({ error, notRequested }) => {
    setErrors(Boolean(error));
    setErrorsNotRequested(notRequested);

    dispatch(updateAssetHasErrorByAssetId(props.asset.id, error));
  };

  const matchPaths = (paths, model, { entityType: _entityType }: any = {}) => {
    const { index } = asset;
    return paths.some((list) =>
      list.some(
        (path) =>
          ([
            ruleEntities.PRODUCT_PICTURE,
            ruleEntities.PRODUCT_DOCUMENT,
            ruleEntities.PRODUCT_VIDEO,
            ruleEntities.PRODUCT_PICTURES,
            ruleEntities.PRODUCT_DOCUMENTS,
            ruleEntities.PRODUCT_VIDEOS,
          ].includes(_entityType) ||
            (hasMediaFieldMigrationFlag &&
              [ruleEntities.CONSUMER_UNIT].includes(_entityType))) &&
          (path.startsWith(`${model}.${index}.`) ||
            path === `${model}.${index}`),
      ),
    );
  };

  const { recipientId } = extraParams || {};
  if (!asset) {
    return null;
  }

  const entityType = asset._type;
  let assetType: null | string = null;
  let readOnly: boolean = false;
  let children: null | any = null;
  let isPackshotPicture = false;

  switch (entityType) {
    case 'ProductPicture': {
      assetType = ASSET_TYPE.PICTURE;
      readOnly = Boolean(get(options, 'picture.readOnly'));
      isPackshotPicture = asset.isPackshot;

      const pictureProps = {
        ...props,
        asset,
        action: actions.find((a) => a.render === 'addPicture'),
        deleting: deleting,
        selected: selectedPictureIds.some((id) => id === asset.id),
        onDelete: onDelete,
        onSelect: onPictureSelectionChange,
        readOnly: readOnly,
      };

      children = <PictureAsset {...pictureProps} />;
      break;
    }
    case 'ProductVideo':
      assetType = ASSET_TYPE.VIDEO;
      readOnly = Boolean(get(options, 'video.readOnly'));
      children = (
        <VideoAsset
          {...props}
          disabled={deleting}
          onDelete={onDelete}
          readOnly={readOnly}
        />
      );
      break;
    case 'ProductEnrichedContent':
      assetType = ASSET_TYPE.ENRICHED_CONTENT;
      readOnly = Boolean(get(options, 'recipe.readOnly'));
      children = (
        <RecipeAsset
          {...props}
          deleting={deleting}
          onDelete={onDelete}
          readOnly={readOnly}
        />
      );
      break;
    case 'ProductDocument':
      assetType = ASSET_TYPE.DOCUMENT;
      readOnly = Boolean(get(options, 'document.readOnly'));
      children = (
        <DocumentAsset
          {...props}
          action={actions.find((a) => a.render === 'addDocument')}
          deleting={deleting}
          onDelete={onDelete}
          readOnly={readOnly}
        />
      );
      break;
    default:
      return null;
  }

  const flywheelMediaDisplayCondition =
    hasReleaseProductpageFlywheelMedia && (hasErrors || isPackshotPicture);

  const Wrapper = hasReleaseProductpageFlywheelMedia ? Fragment : 'div';

  return (
    <Wrapper>
      <div
        className={classNames(
          'AssetList__col',
          'AssetList__card',
          `AssetList__card--${entityType}`,
          {
            'AssetList__card-rpf':
              hasReleaseProductpageFlywheelMedia &&
              (hasErrors || !isPackshotPicture),
            'AssetList__card--displayed-rpf': flywheelMediaDisplayCondition,
            'AssetList__card--raguelError':
              hasErrors && !hasReleaseProductpageFlywheelMedia,

            'AssetList__card--notRequested': areErrorsNotRequested,
          },
        )}
      >
        {hasReleaseProductpageFlywheelMedia
          ? flywheelMediaDisplayCondition && children
          : children}
        <Raguel
          key={assetType}
          entityId={entityId}
          entityKind={ENTITY_TYPE_PRODUCT_MEDIA}
          label="assets"
          model={`assets.${assetType}`}
          value={field}
          onJudgmentDay={onValidate}
          readOnly={readOnly}
          displayActions={hasUpdatePermission}
          matchPaths={matchPaths}
          recipientId={recipientId}
          hidden
        />
        {!hasReleaseProductpageFlywheelMedia && hasErrors && (
          <i className="mdi mdi-alert" />
        )}
      </div>
    </Wrapper>
  );
});

export default Asset;

import { registerModuleReducer } from 'utils/modules/reducer';
import { registerSaga } from 'utils/modules/saga';

import { NORMALIZED_COMMENTS_STORE_KEY } from './constants';
import { DeprecatedNormalizedComments } from './deprecated-normalized-comments';
import reducer from './reducers';
import { normalizedCommentsSaga } from './sagas';

registerModuleReducer(NORMALIZED_COMMENTS_STORE_KEY, reducer);
registerSaga(normalizedCommentsSaga);

export default DeprecatedNormalizedComments;

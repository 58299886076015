import { registerModuleReducer } from 'utils/modules/reducer';

import Admin from './components/admin';
import Builder from './components/builder';
import Checker from './components/checker';
import { REDUCER_KEY } from './constants';
import reducer from './reducer';

registerModuleReducer(REDUCER_KEY, reducer);

export const ConsentManagerBuilder = Builder;
export const ConsentManagerChecker = Checker;
export const ConsentManagerAdmin = Admin;

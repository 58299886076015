import { ReactNode } from 'react';

import './error-pannel.scss';

interface Props {
  children?: ReactNode;
}

export function ErrorPanel({ children }: Props) {
  return (
    <div className="ErrorPanel">
      <i className="mdi mdi-alert ErrorPanel__icon"></i>
      {children}
    </div>
  );
}

import qs from 'query-string';
import { useMemo } from 'react';
import { useLocation } from 'react-router-dom';

export {
  fillPath as fill,
  hashPath as hash,
  laxPath,
  relativeLaxPath,
  relativePath,
} from '@alkem/micro-frontend-tools';

export function useQueryParams<
  T extends {
    [K in keyof T]?: string | boolean | (string | boolean)[];
  } = object,
>(): T {
  const { search } = useLocation();
  return useMemo(() => qs.parse(search), [search]);
}

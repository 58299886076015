import { useCallback } from 'react';
import { useDispatch } from 'react-redux';

import { openTargetCatalogImportModal } from './actions';

interface ConnectedProps {
  title: string;
  fileFormat: string;
}

type Props = ConnectedProps;

export const ButtonDropDown = ({ title, fileFormat }: Props) => {
  const dispatch = useDispatch();

  const handleActionClick = useCallback(() => {
    dispatch(openTargetCatalogImportModal(fileFormat));
  }, [dispatch, fileFormat]);

  return (
    <button
      type="button"
      className="ActionOption"
      onClick={handleActionClick}
      data-testid={title}
    >
      {title}
    </button>
  );
};

import { call, put, select } from 'redux-saga/effects';

import { notificationError, notificationSuccess } from 'actions/notification';
import { sendBulkMessageReminder } from 'resources/serviceProductApi';
import { i18n } from 'utils/i18n';
import { takeLatestSafe } from 'utils/saga';

import { SEND_BULK_MESSAGE } from '../actions/types';
import { selectOrganizationId } from '../selectors';

// Only Bulk message type Reminder available for the moment
function* sendSaga({ formattedInfoBulkMessage, message }) {
  const retailerId: number = yield select(selectOrganizationId);
  try {
    yield call(
      sendBulkMessageReminder,
      formattedInfoBulkMessage,
      message,
      retailerId,
    );
    yield put(
      notificationSuccess(
        i18n.t('frontproductstream.bulk_message.send_success.notification', {
          defaultValue:
            'Your message will be available in the organization chat in a few moments',
        }),
      ),
    );
  } catch (error) {
    yield put(
      notificationError(
        i18n.t('frontproductstream.bulk_message.send_error.notification', {
          defaultValue: 'An error occured while sending mass message reminder',
        }),
      ),
    );
  }
}

export function* sendBulkMessage() {
  yield takeLatestSafe([SEND_BULK_MESSAGE], sendSaga);
}

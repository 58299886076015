import { isManufacturer, isPrivateLabel, isRetailer } from 'core/api/user';
import { hasFeature } from 'modules/feature-flag';
import {
  FEATURE_DATA_OPS,
  RELEASE_B2K_COLLAB_EDITS_NEWFILTER,
  RELEASE_DATA_OPS_MANUFACTURERS,
} from 'modules/feature-flag/constants';
import { UserImmutable } from 'types';

const isPatchStatusesFilterAvailable = ({
  user,
}: { user?: UserImmutable } = {}) =>
  user != null &&
  isRetailer(user) &&
  hasFeature(user, RELEASE_B2K_COLLAB_EDITS_NEWFILTER);

export const patchFilter = {
  key: 'patch_summary.has_patch',
  query: 'patch-filter',

  isAvailable: ({ user }: { user?: UserImmutable } = {}) =>
    user != null &&
    ((isRetailer(user) && hasFeature(user, FEATURE_DATA_OPS)) ||
      ((isManufacturer(user) || isPrivateLabel(user)) &&
        hasFeature(user, RELEASE_DATA_OPS_MANUFACTURERS))),
};

export const patchAcceptedFilter = {
  key: 'patch_summary.has_accepted',
  query: 'patch-accepted-filter',

  isAvailable: isPatchStatusesFilterAvailable,
};
export const patchRefusedFilter = {
  key: 'patch_summary.has_refused',
  query: 'patch-refused-filter',

  isAvailable: isPatchStatusesFilterAvailable,
};
export const patchPendingFilter = {
  key: 'patch_summary.has_pending',
  query: 'patch-pending-filter',

  isAvailable: isPatchStatusesFilterAvailable,
};
export const patchDisputedFilter = {
  key: 'patch_summary.has_disputed',
  query: 'patch-disputed-filter',

  isAvailable: isPatchStatusesFilterAvailable,
};
export const patchIgnoredFilter = {
  key: 'patch_summary.has_ignored',
  query: 'patch-ignored-filter',

  isAvailable: isPatchStatusesFilterAvailable,
};

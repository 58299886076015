import PropTypes from 'prop-types';
import { Component } from 'react';
import { connect } from 'react-redux';

import './loader.scss';

const mapStateToProps = (state) => ({
  isLoading: state.navigation.isLoading,
});

class Loader extends Component {
  static propTypes = {
    isLoading: PropTypes.bool.isRequired,
  };

  renderLoaderBar() {
    return (
      <div className="Loader">
        <div className="Loader__color" />
        <div className="Loader__hider" />
      </div>
    );
  }

  render() {
    const { isLoading } = this.props;
    if (!isLoading) {
      return null;
    }
    return this.renderLoaderBar();
  }
}

export { Loader };
export default connect(mapStateToProps)(Loader);

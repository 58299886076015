import { flattenDeep } from 'lodash/fp';

import {
  ENTITY_TYPE_PRODUCTVERSION,
  ENTITY_TYPE_PRODUCTVERSION_OVERRIDE,
  ENTITY_TYPE_PRODUCT_MEDIA,
  ENTITY_TYPE_PRODUCT_PICTURE,
  ENTITY_TYPE_PRODUCT_VERSION_HIERARCHY,
  ENTITY_TYPE_SHARINGUNIT,
} from 'constants/entities';
import {
  ENTITY_TYPE_LOGISTICAL_HIERARCHY_DIMENSIONS,
  ENTITY_TYPE_LOGISTICAL_HIERARCHY_UNIT,
  ENTITY_TYPE_LOGISTICAL_HIERARCHY_WEIGHTS,
} from 'modules/logistical-hierarchies/constants';

import { NEW_TO_OLD_FIELD_NAME_MAPPING } from '../constants';
import { ApiRule } from '../types';

export function isValidEntityKind(entityKind: string) {
  return [
    ENTITY_TYPE_PRODUCTVERSION,
    ENTITY_TYPE_PRODUCTVERSION_OVERRIDE,
    ENTITY_TYPE_PRODUCT_MEDIA,
    ENTITY_TYPE_PRODUCT_PICTURE,
    ENTITY_TYPE_SHARINGUNIT,
    ENTITY_TYPE_PRODUCT_VERSION_HIERARCHY,
    ENTITY_TYPE_LOGISTICAL_HIERARCHY_UNIT,
    ENTITY_TYPE_LOGISTICAL_HIERARCHY_DIMENSIONS,
    ENTITY_TYPE_LOGISTICAL_HIERARCHY_WEIGHTS,
  ].includes(entityKind);
}

export function isMigratedMediaFieldRule(rule: ApiRule) {
  const oldNamesPattern = new RegExp(
    `.(${Object.values(NEW_TO_OLD_FIELD_NAME_MAPPING).join('|')}|${Object.keys(
      NEW_TO_OLD_FIELD_NAME_MAPPING,
    ).join('|')})$`,
  );
  return (
    rule.root_fields?.includes('assets') &&
    flattenDeep(Object.values(rule.paths)).some((path) => {
      return oldNamesPattern.test(path);
    })
  );
}

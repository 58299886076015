import { Button } from '@alkem/react-ui-button';

import i18n from 'utils/i18n';

import './index.scss';

type Props = {
  selected?: number;
  total?: number;
  inProgress?: boolean;
  fullExport?: boolean;
  onExport: () => void;
};

const ReportingExportButton = ({
  selected = 0,
  total = 0,
  inProgress = false,
  fullExport = false,
  onExport,
}: Props) => {
  const count = selected || total;
  let label;
  if (fullExport) {
    label = i18n.t(
      'frontproductstream.reporting_page_header.export_report.button',
      { defaultValue: 'Export current page' },
    );
  } else {
    label = i18n.t(
      'frontproductstream.reporting_page_header.export_report_lines.button',
      { defaultValue: 'Export {{count}} report', count: count },
    );
  }
  return (
    <Button
      secondary
      id="reporting-export-button"
      testid="reporting-export-button"
      className="ReportingExportButton"
      onClick={onExport}
      content={label}
      disabled={inProgress || (!fullExport && count <= 0)}
      displaySpinner={inProgress}
    />
  );
};

export { ReportingExportButton };
export default ReportingExportButton;

import { datadogRum } from '@datadog/browser-rum';
import { isArray, isError } from 'lodash/fp';

import {
  getOrganizationId,
  getOrganizationType,
  getUserId,
} from 'core/api/user';
import { UserImmutable } from 'types';
import { getAppConfig } from 'utils/app-config';
import { logError } from 'utils/logging';

let initialized = false;

const getDatadogConfig = () => getAppConfig().datadog || { enabled: false };

export const initDataDogRUM = () => {
  try {
    const config = getDatadogConfig();
    if (initialized || !config.enabled) {
      return;
    }
    const { enabled, ...rumConfig } = config;
    const allowedTracingUrls: Array<any> | undefined =
      config.allowedTracingUrls?.map((url) => ({
        match: url,
        propagatorTypes: ['datadog'],
      })) || undefined;

    datadogRum.init({
      ...rumConfig,
      allowedTracingUrls,
      version: global.FPS_VERSION,
    });
    initialized = true;
  } catch (err) {
    logError(err);
  }
};

export function identifyDataDogSession(user: UserImmutable) {
  try {
    if (getDatadogConfig().enabled) {
      datadogRum.setUser({
        id: getUserId(user),
        oid: getOrganizationId(user),
        otype: getOrganizationType(user),
      });
    }
  } catch (err) {
    logError(err);
  }
}

export function logToDatadogIfApplicable(error: any) {
  if (getDatadogConfig().enabled) {
    if (isError(error)) {
      datadogRum.addError(error);
    } else if (typeof error === 'string') {
      datadogRum.addError(new Error(error));
    } else if (isArray(error)) {
      const [firstError] = error;
      logToDatadogIfApplicable(firstError);
    } else {
      datadogRum.addError(new Error(JSON.stringify(error)));
    }
  }
}

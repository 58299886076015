export const TARGET_CATALOG_IMPORT_MODAL_STORE_KEY = 'target-catalog-import';

export const OPEN_TARGET_CATALOG_IMPORT_MODAL =
  'OPEN_TARGET_CATALOG_IMPORT_MODAL';
export const CLOSE_TARGET_CATALOG_IMPORT_MODAL =
  'CLOSE_TARGET_CATALOG_IMPORT_MODAL';
export const TARGET_CATALOG_IMPORT_UPLOAD = 'TARGET_CATALOG_IMPORT_UPLOAD';
export const TARGET_CATALOG_IMPORT_UPLOAD_DONE =
  'TARGET_CATALOG_IMPORT_UPLOAD_DONE';

export const TARGET_CATALOG_IMPORT_SELECT_FILE =
  'TARGET_CATALOG_IMPORT_SELECT_FILE';

export const OPEN_TARGET_CATALOG_IMPORT_FOLLOW_UP_MODAL =
  'OPEN_TARGET_CATALOG_IMPORT_FOLLOW_UP_MODAL';

export const RESTART_TARGET_CATALOG_IMPORT_MODAL =
  'RESTART_TARGET_CATALOG_IMPORT_MODAL';

export const FILE_FORMAT_RANGE = 'RANGE';

export const FILE_FORMAT_PRODUCT = 'PRODUCT';

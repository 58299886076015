import { once } from 'lodash/fp';
import { useEffect, useMemo } from 'react';

import { FilterOnChange, FilterOption, FilterOptions } from 'types';

import { buildFiltersFromQuery } from '../../../utils/filters';

export const updateFilterFromQuery = async (data: {
  filterList: FilterOptions;
  filterQueryValues: string[];
  onChange: FilterOnChange;
  key: string;
  filterLabel: string;
  selectFilterValue?: (filter: FilterOption) => string | number;
  selectFilterLabel?: (filter: FilterOption) => string;
  selectFilterData?: (filter: FilterOption) => {
    id: string | number;
    label: string;
  };
}) => {
  const filtersFromQuery = await buildFiltersFromQuery({
    filterQueryValues: data.filterQueryValues,
    filterList: data.filterList,
    filterKey: data.key,
    selectFilterValue:
      data.selectFilterValue || ((filter: FilterOption) => filter.get('id')),
    selectFilterLabel:
      data.selectFilterLabel ||
      ((filter: FilterOption) => `${data.filterLabel}: ${filter.get('label')}`),
    selectFilterData:
      data.selectFilterData ||
      ((filter: FilterOption) => ({
        id: filter.get('id'),
        label: filter.get('label'),
      })),
  });
  data.onChange(filtersFromQuery, true);
};

export const useQueryFilter = ({
  options,
  filter,
  selectedFilterMap,
  filterQueryValues,
  onChange,
}) => {
  const onUpdateFilterFromQuery = useMemo(
    () => once(updateFilterFromQuery),
    [],
  );
  useEffect(
    () => {
      const selectedFilters = selectedFilterMap?.size ?? 0;
      const queryFilters = filterQueryValues?.length ?? 0;
      if (selectedFilters || !queryFilters) {
        return;
      }
      onUpdateFilterFromQuery({
        filterList: options,
        filterQueryValues,
        onChange,
        ...filter,
      });
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [selectedFilterMap, filterQueryValues],
  );
};

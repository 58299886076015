import { createReducer } from 'redux-create-reducer';

import { GET_TEMPLATE_FIELDS } from 'constants/template';

const initialState = {
  fields: [],
};

export default createReducer(initialState, {
  [GET_TEMPLATE_FIELDS]: (state, action) => ({
    ...state,
    fields: action.value,
  }),
});

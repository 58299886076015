import { Map } from 'immutable';
import PropTypes from 'prop-types';
import { PureComponent } from 'react';
import ImmutablePropTypes from 'react-immutable-proptypes';

import { get } from 'utils/immutable';

import CollapsibleFilter from '../collapsible';

import FilterItem from './item';

export default class ListSimpleFilter extends PureComponent {
  static propTypes = {
    id: PropTypes.string.isRequired,
    filterList: PropTypes.oneOfType([ImmutablePropTypes.list, PropTypes.array])
      .isRequired,
    filterKey: PropTypes.string.isRequired,
    filterLabel: PropTypes.string.isRequired,
    selectedFilterMap: PropTypes.oneOfType([
      ImmutablePropTypes.map,
      PropTypes.object,
    ]),
    aggregations: ImmutablePropTypes.map,
    collapsed: PropTypes.bool,
    onChange: PropTypes.func.isRequired,
    onCollapse: PropTypes.func.isRequired,
    disabled: PropTypes.bool,
    header: PropTypes.string,
    queryValues: PropTypes.array,
    selectId: PropTypes.func,
    selectLabel: PropTypes.func,
  };

  static defaultProps = {
    selectedFilterMap: Map(),
    aggregations: Map(),
    queryValues: [],
    collapsed: true,
    selectId: (filter) => get(filter, 'id'),
    selectLabel: (filter) => get(filter, 'label'),
  };

  componentDidMount() {
    const {
      onChange,
      filterList,
      filterKey,
      filterLabel,
      disabled,
      queryValues,
      selectId,
      selectLabel,
    } = this.props;
    if (queryValues && !disabled) {
      queryValues.forEach((value) => {
        const filter = filterList.find((f) => selectId(f) === value);
        onChange({
          key: filterKey,
          value,
          label: `${filterLabel}: ${selectLabel(filter)}`,
          add: true,
          data: filter,
        });
      });
    }
  }

  onChange = (filter, selected) => {
    const {
      onChange,
      filterKey,
      filterLabel,
      disabled,
      selectId,
      selectLabel,
    } = this.props;

    if (!disabled) {
      onChange({
        key: filterKey,
        value: selectId(filter),
        label: `${filterLabel}: ${selectLabel(filter)}`,
        add: selected,
        data: filter,
      });
    }
  };

  render() {
    const {
      aggregations,
      collapsed,
      disabled,
      filterKey,
      filterLabel,
      filterList,
      header,
      id,
      onCollapse,
      selectedFilterMap,
      selectId,
      selectLabel,
    } = this.props;
    const hasDocCount = filterList.some(
      (filter) => aggregations.getIn([selectId(filter), 'doc_count']) >= 0,
    );
    return (
      <CollapsibleFilter
        id={id}
        label={filterLabel}
        collapsed={collapsed}
        onCollapse={onCollapse}
      >
        {header && <div className="ListSimpleFilter__header">{header}</div>}
        {filterList.map((filter) => {
          return (
            <FilterItem
              key={`list-simple-filter-${filterKey}-${selectId(filter)}`}
              filterKey={filterKey}
              filter={filter}
              aggregation={aggregations.get(selectId(filter))}
              selected={!!get(selectedFilterMap, selectId(filter))}
              hasDocCount={hasDocCount}
              onChange={this.onChange}
              disabled={disabled}
              selectId={selectId}
              selectLabel={selectLabel}
            />
          );
        })}
      </CollapsibleFilter>
    );
  }
}

import { createSelector } from '@reduxjs/toolkit';
import { map, mapValues } from 'lodash/fp';

import {
  AssetTypeV2,
  DocumentAssetV2,
  GenericEnhancedAssetV2,
  PictureAssetV2,
  ProductVideoAssetV2,
  RecipeAssetV2,
} from 'components/ui/form/asset-list/AssetV2/types';
import {
  ASSETS_RECEIVE_ALL,
  CLEAR_SELECTED_IDS,
  SET_CURRENT_ASSET_TAB,
  UPDATE_ENTITY,
  UPDATE_NEW_PACKSHOT,
  UPDATE_NEW_SPECIFIC_PACKSHOT,
  UPDATE_SELECTED_IDS,
  UPDATE_SPECIFIC_ASSET,
} from 'constants/events/media';
import { DELETE_PRODUCT_VERSION } from 'constants/events/productversion';
import { ASSET_SCOPE, ASSET_STATUS } from 'constants/media';
import {
  ADD_SPECIFIC_ASSETS,
  DELETE_SPECIFIC_ASSETS,
} from 'modules/recipient-specific-block/events';
import { get, set, update } from 'utils/immutable';
import { createReducer } from 'utils/redux';

const resolveStatusFromScope = (scope) =>
  scope === ASSET_SCOPE.PUBLIC ? ASSET_STATUS.ACTIVE : ASSET_STATUS.INACTIVE;

const transferAssetsToSpecific = mapValues(
  map((asset: AssetTypeV2) =>
    set(asset, 'status', resolveStatusFromScope(asset.scope)),
  ),
);

export type MediaTab = 'DIGITAL_ASSET_LIBRARY_TAB' | 'IMPORT_TAB';

type MediaState = {
  assets: {
    pictures: PictureAssetV2[];
    videos: ProductVideoAssetV2[];
    documents: DocumentAssetV2[];
    enriched_contents: RecipeAssetV2[];
  };
  specificData: SpecificData[];
  totalExpired: number;
  selectedIds: number[];
  currentAssetTab: MediaTab;
};

export const initialState: MediaState = {
  assets: {
    pictures: [],
    videos: [],
    documents: [],
    enriched_contents: [],
  },
  specificData: [],
  totalExpired: 0,
  selectedIds: [],
  currentAssetTab: 'DIGITAL_ASSET_LIBRARY_TAB',
};

type SpecificData = {
  targetOrganization: {
    id: string;
  };
  data: {
    pictures: PictureAssetV2[];
  };
};

export default createReducer(initialState, {
  [ASSETS_RECEIVE_ALL]: (
    state: MediaState,
    { payload: { assets, totalExpired, specificData } },
  ) => ({
    ...state,
    assets,
    totalExpired,
    specificData:
      // make sure we dont override specific data that has been set locally only
      specificData && specificData.length > 0
        ? specificData
        : state.specificData,
  }),

  [UPDATE_NEW_SPECIFIC_PACKSHOT]: (state: MediaState, { payload }) => {
    const { pictureId, recipientId, newSpecificPackshot } = payload;
    const specificRecipientIndex = state.specificData.findIndex(
      (elem) => elem.targetOrganization.id === recipientId,
    );
    const specificPictures =
      state.specificData[specificRecipientIndex].data.pictures;

    const newSpecificPackshotIndex = specificPictures.findIndex(
      (elem) => elem.id === pictureId,
    );

    let newState = {
      ...state,
    };
    // If the specific packshot is not in the state add it
    if (newSpecificPackshotIndex < 0) {
      const newSpecificPictures = [...specificPictures, newSpecificPackshot];
      newState = set(
        state,
        `specificData.${specificRecipientIndex}.data.pictures`,
        newSpecificPictures,
      );
    } else {
      newState = set(
        state,
        `specificData.${specificRecipientIndex}.data.pictures.${newSpecificPackshotIndex}.isPackshot`,
        true,
      );
      newState = set(
        newState,
        `specificData.${specificRecipientIndex}.data.pictures.${newSpecificPackshotIndex}.overriden`,
        true,
      );
    }

    const oldSpecificPackshotIndex = specificPictures.findIndex(
      (elem) => elem.isPackshot,
    );

    if (oldSpecificPackshotIndex >= 0) {
      newState = set(
        newState,
        `specificData.${specificRecipientIndex}.data.pictures.${oldSpecificPackshotIndex}.isPackshot`,
        false,
      );
    }

    return newState;
  },

  [UPDATE_NEW_PACKSHOT]: (state: MediaState, { payload }) => {
    const { pictureId } = payload;
    const { pictures } = state.assets;
    const newPackshotIndex = pictures.findIndex(
      (elem) => elem.id === pictureId,
    );
    if (newPackshotIndex === -1) {
      return state; // picture id not found
    }

    // add packshot flag to new picture asset
    let newState = set(
      state,
      `assets.pictures.${newPackshotIndex}.isPackshot`,
      true,
    );

    // remove packshot flag from previous picture asset
    const oldPackshotIndex = pictures.findIndex((elem) => elem.isPackshot);
    if (oldPackshotIndex >= 0) {
      newState = set(
        newState,
        `assets.pictures.${oldPackshotIndex}.isPackshot`,
        false,
      );
    }

    return newState;
  },

  [UPDATE_SPECIFIC_ASSET]: (state: MediaState, { payload }) => {
    const { recipientId, category, asset } = payload;
    const { specificData } = state;

    const recipientIndex = specificData.findIndex(
      (item) => item.targetOrganization.id === recipientId,
    );

    if (recipientIndex < 0) {
      return state; // recipient not found
    }

    const assets = get(specificData, [recipientIndex, 'data', category], []);
    let assetIndex = assets.findIndex(({ id }) => id === asset.id);
    if (assetIndex < 0) {
      assetIndex = assets.length;
    }

    const updatedRecipient = set(
      specificData[recipientIndex],
      ['data', category],
      set(assets, assetIndex, asset),
    );

    return set(
      state,
      'specificData',
      set(specificData, recipientIndex, updatedRecipient),
    );
  },

  [ADD_SPECIFIC_ASSETS]: (state, { payload }) =>
    update(state, 'specificData', (specificData) => {
      const newSpecificData = specificData.filter(
        (item) => item.targetOrganization.id !== payload.recipientId,
      );
      newSpecificData.push({
        targetOrganization: { id: payload.recipientId },
        data: transferAssetsToSpecific(state.assets),
      });
      return newSpecificData;
    }),

  [DELETE_SPECIFIC_ASSETS]: (state, { payload }) =>
    update(state, 'specificData', (specificData) =>
      specificData.filter(
        (item) => item.targetOrganization.id !== payload.recipientId,
      ),
    ),

  [DELETE_PRODUCT_VERSION]: () => initialState,

  [UPDATE_ENTITY]: (state, action) => {
    const pictureIndex = state.assets.pictures.findIndex(
      (picture) => picture.id === action.assetId,
    );
    switch (action.entityKind) {
      case 'ProductPicture':
        return update(
          state,
          `assets.pictures[${pictureIndex}].${action.key}`,
          () => action.value,
        );
      default:
        return state;
    }
  },
  [UPDATE_SELECTED_IDS]: (state, { payload }) => {
    const { selectedIds } = state;
    if (selectedIds.includes(payload)) {
      return {
        ...state,
        selectedIds: selectedIds.filter((assetId) => assetId !== payload),
      };
    } else {
      return {
        ...state,
        selectedIds: [...selectedIds, payload],
      };
    }
  },
  [CLEAR_SELECTED_IDS]: (state) => ({
    ...state,
    selectedIds: [],
  }),
  [SET_CURRENT_ASSET_TAB]: (state, { payload }) => ({
    ...state,
    currentAssetTab: payload,
  }),
});

// selectors

const selectLocalState: (state: any) => MediaState = (state) => state.media;

export const selectAssets = createSelector(
  selectLocalState,
  (state): MediaState['assets'] => get(state, 'assets'),
);

export const selectPictures = createSelector(
  selectAssets,
  (assets): MediaState['assets']['pictures'] => get(assets, 'pictures'),
);

export const selectVideos = createSelector(
  selectAssets,
  (assets): MediaState['assets']['videos'] => get(assets, 'videos'),
);

export const selectDocuments = createSelector(
  selectAssets,
  (assets): MediaState['assets']['documents'] => get(assets, 'documents'),
);

export const selectEnrichedContents = createSelector(
  selectAssets,
  (assets): MediaState['assets']['enriched_contents'] =>
    get(assets, 'enriched_contents'),
);

export const selectAssetExpiredCount = createSelector(
  selectLocalState,
  (state): MediaState['totalExpired'] => get(state, 'totalExpired'),
);

export const selectSpecificAssets = createSelector(
  selectLocalState,
  (state): MediaState['specificData'] => get(state, 'specificData'),
);

export const selectAmountOfPictures = createSelector(
  selectPictures,
  (pictures) => pictures.length,
);

export const selectEnhancedAssets = createSelector(
  selectSpecificAssets,
  selectAssets,
  (specificData, assets) => {
    const assetTypes = ['pictures', 'videos', 'documents', 'enriched_contents'];

    const getAssetIdIncludingRecipients = (
      assetType: (typeof assetTypes)[number],
    ) => {
      const assetsOfType: AssetTypeV2[] = assets[assetType];
      return assetsOfType.reduce<Record<string, GenericEnhancedAssetV2>>(
        (assetAcc, asset) => {
          const recipientStatusById = specificData.reduce<string[]>(
            (acc, specificAsset) => {
              const { data: specificAssetData } = specificAsset;

              const matchingspecificAsset = specificAssetData[assetType].find(
                (localAsset) => localAsset.id === asset.id,
              );

              return {
                ...acc,
                [specificAsset.targetOrganization.id]:
                  matchingspecificAsset.status,
              };
            },
            [],
          );

          return {
            ...assetAcc,
            [asset.id]: {
              ...asset,
              recipientStatusById,
            },
          };
        },
        {},
      );
    };

    return assetTypes.reduce<Record<string, GenericEnhancedAssetV2>>(
      (acc, assetType) => {
        return {
          ...acc,
          ...getAssetIdIncludingRecipients(assetType),
        };
      },
      {},
    );
  },
);

export const selectSelectedIds = (state) => selectLocalState(state).selectedIds;

export const selectCurrentAsset = (state) => {
  const selectedIds = selectSelectedIds(state);
  const enhancedAssets = selectEnhancedAssets(state);

  return enhancedAssets[selectedIds[0]];
};

export const selectCurrentAssetTab = (state) =>
  selectLocalState(state).currentAssetTab;

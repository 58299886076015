import { List, Map } from 'immutable';
import {
  all,
  call,
  delay,
  put,
  race,
  select,
  take,
  takeEvery,
  takeLatest,
} from 'redux-saga/effects';

import { updateEntity } from 'actions/entity';
import { startLoading, stopLoading } from 'actions/loading';
import { notificationError } from 'actions/notification';
import { LOGISTICAL_HIERARCHIES_UPDATE_REFERENCE } from 'modules/logistical-hierarchies/constants';
import { SAVE_PRODUCT } from 'modules/product-page/constants';
import { selectRecipients } from 'modules/view-as/selectors';
import {
  selectCanUpdateSharingUnit,
  selectProductKeyId,
} from 'reducers/productVersion';
import EtlApi from 'resources/etlApi';
import i18n from 'utils/i18n';
import { logError } from 'utils/logging';

import * as actions from '../actionTypes';
import {
  cancelSharingUnitStatusPolling,
  receiveLastExportFile,
  requestSharingUnitStatusPolling,
  updateSharingUnitStatus,
} from '../actions';
import api from '../api';
import { getFormattedList as selectSelectableLogisticalHierarchies } from '../components/sharing-unit/form/components/logistical-hierarchies-selector/selectors';
import {
  SHARING_UNIT_STATUS_DRAFT,
  SHARING_UNIT_STATUS_INIT,
  SHARING_UNIT_STATUS_REFUSED,
} from '../constants';
import { getSharingUnits, sharingUnitsByRetailerId } from '../selectors';

const errorMessages = {
  create: i18n.t('An error occured while creating a new listing.'),
  delete: i18n.t('An error occured while deleting this listing.'),
  share: i18n.t('An error occured while sharing listings for this product.'),
  load: i18n.t('An error occured while retrieving listings for this product.'),
};

export function* watchCreate() {
  yield watchRequest({
    method: api.createSharingUnit,
    params: [select(selectProductKeyId)],
    startWith: actions.CREATE_SHARING_UNIT_REQUEST,
    onSuccess: actions.CREATE_SHARING_UNIT_SUCCESS,
    onFailure: actions.CREATE_SHARING_UNIT_FAILURE,
    errorMessage: errorMessages.create,
    after: afterSharingUnitCreate,
  });
}

export function* watchRemove() {
  yield watchRequest({
    method: api.deleteSharingUnit,
    startWith: actions.DELETE_SHARING_UNIT_REQUEST,
    onSuccess: actions.DELETE_SHARING_UNIT_SUCCESS,
    onFailure: actions.DELETE_SHARING_UNIT_FAILURE,
    errorMessage: errorMessages.delete,
  });
}

export function* watchRequest(config) {
  yield takeEvery(config.startWith, routine, config);
}

export function* routine(config, action) {
  yield put(startLoading());
  const { result, error } = yield call(loading, config, action);
  if (config.after) {
    try {
      yield call(config.after, result, error);
    } catch (e) {
      logError(e);
    }
  }
  yield put(stopLoading());
}

export function* loading(config, { payload }) {
  const { result, error } = yield call(fetchResult, config, payload);
  if (result) {
    yield put({
      type: config.onSuccess,
      payload: result,
      requestPayload: payload,
    });
  }
  if (error) {
    yield put({ type: config.onFailure, error });
    yield put(notificationError(config.errorMessage));
    yield call(logError, error);
  }
  if (!result && !error) {
    throw new Error('API must return result or error');
  }
  return { result, error };
}

export function* fetchResult(config, payload) {
  const params = yield all(config.params || []);
  return yield call(config.method, ...params, payload);
}

export function* watchLoadLastExportFile() {
  yield takeEvery([actions.LOAD_SHARING_UNITS_EXPORT_FILE], loadLastExportFile);
}

export function* loadLastExportFile(action) {
  try {
    const response = yield call([EtlApi, EtlApi.ExportFile], {
      filter_gtins_in: action.payload.gtins.join(),
      filter_recipient_organization_id: action.payload.retailerId,
    });
    yield put(
      receiveLastExportFile({
        gtins: action.payload.gtins,
        data: response.data.data,
      }),
    );
  } catch (error) {
    yield put(
      notificationError(
        i18n.t('There was an error retrieving retailer acknowledgment'),
      ),
    );
  }
}

export function* afterSharingUnitCreate(result) {
  const sharingUnitId = result.sharingUnit.id;
  const { status } = result.sharingUnit;

  yield put(requestSharingUnitStatusPolling(sharingUnitId, status));

  const logisticalHierarchies = yield select(
    selectSelectableLogisticalHierarchies,
  );

  if (logisticalHierarchies.length === 1) {
    const selectedHierarchy = logisticalHierarchies[0];
    yield put(
      updateEntity(
        'hierarchyProduct',
        Map({
          [selectedHierarchy.referenceType]: selectedHierarchy.value,
          label: selectedHierarchy.label,
        }),
        sharingUnitId,
        'SharingUnit',
      ),
    );
  }
}

export function* pollSharingUnitStatusTask({
  sharingUnitId,
  previousStatus,
  attempt,
}) {
  const TRIES = 5;
  const DELAY_TIME = 1000;
  yield delay(DELAY_TIME * attempt);
  try {
    const { error, sharingUnit } = yield call(
      api.fetchSharingUnitStatus,
      sharingUnitId,
    );
    if (
      (error || sharingUnit.get('status') === previousStatus) &&
      attempt < TRIES
    ) {
      if (error) {
        logError(error);
      }
      yield put(
        requestSharingUnitStatusPolling(
          sharingUnitId,
          previousStatus,
          attempt + 1,
        ),
      );
    } else {
      if (!error && sharingUnit.get('status') !== previousStatus) {
        yield put(
          updateSharingUnitStatus({
            sharingUnit: sharingUnit.set('id', sharingUnitId),
          }),
        );
      }
      yield put(cancelSharingUnitStatusPolling());
    }
  } catch (error) {
    logError(error);
  }
}

export function* cancelSharingUnitStatusPollingTask() {
  yield put(cancelSharingUnitStatusPolling());
}

export function* pollSharingUnitStatusSaga(action) {
  yield race({
    cancel: take(actions.CANCEL_STATUS_POLLING),
    task: call(pollSharingUnitStatusTask, action),
  });
}

export function* watchPolling() {
  yield takeLatest(actions.REQUEST_STATUS_POLLING, pollSharingUnitStatusSaga);
}

export function* watchPollingCancel() {
  yield takeEvery(SAVE_PRODUCT, cancelSharingUnitStatusPollingTask);
}

export function* setLoneHierarchies() {
  const canUpdateSharingUnit = yield select(selectCanUpdateSharingUnit);
  if (!canUpdateSharingUnit) {
    return;
  }

  const logisticalHierarchies = yield select(
    selectSelectableLogisticalHierarchies,
  );

  if (!logisticalHierarchies || logisticalHierarchies.length !== 1) {
    return;
  }

  const selectedHierarchy = logisticalHierarchies[0];
  const sharingUnitsByRetailer = yield select(sharingUnitsByRetailerId);
  const sharingUnits = yield select(getSharingUnits);
  const recipients = yield select(selectRecipients);

  const sharingUnitIdsToUpdate = sharingUnitsByRetailer
    .filter((sharingUnitsIds, recipientId) => {
      const recipient = recipients.find((r) => r.get('id') === recipientId);
      if (!recipient) {
        return false;
      }

      if (
        !(recipient.getIn(['fields', 'sharingUnit']) || []).includes(
          'hierarchyProduct',
        )
      ) {
        return false;
      }

      return sharingUnitsIds && sharingUnitsIds.size > 0;
    })
    .map((sharingUnitsIds) =>
      sharingUnitsIds.filter((sharingUnitId) => {
        const su = sharingUnits.get(sharingUnitId);
        return (
          su &&
          !su.getIn(['template', 'id']) &&
          (su.get('status') === SHARING_UNIT_STATUS_DRAFT ||
            su.get('status') === SHARING_UNIT_STATUS_INIT ||
            su.get('status') === SHARING_UNIT_STATUS_REFUSED)
        );
      }),
    )
    .reduce((acc, v) => acc.concat(v), List());

  yield all(
    sharingUnitIdsToUpdate
      .map((sharingUnitId) =>
        put(
          updateEntity(
            'hierarchyProduct',
            Map({
              [selectedHierarchy.referenceType]: selectedHierarchy.value,
              label: selectedHierarchy.label,
            }),
            sharingUnitId,
            'SharingUnit',
          ),
        ),
      )
      .toArray(),
  );
}

export function* watchHierarchyGTINUpdates() {
  yield takeLatest(LOGISTICAL_HIERARCHIES_UPDATE_REFERENCE, setLoneHierarchies);
}

import PropTypes from 'prop-types';

import { Tipster } from '@alkem/react-ui-tipster';

import {
  ENTITY_TYPE_SHARINGUNIT_TARIFF,
  ENTITY_TYPE_SHARINGUNIT_TEMPLATE,
} from 'constants/entities';
import i18n from 'utils/i18n';

import SharingUnitPriceWaterfalls from './containers/sharing-unit';
import SharingUnitTariffPriceWaterfalls from './containers/sharing-unit-tariff';

const text = (
  <>
    {i18n.t(
      'frontproductstream.sharing_unit_template.detail.pricewaterfall_tax_tipster_left_text',
      {
        defaultValue:
          'All taxes are excluded from the gross price by default. Click on ',
      },
    )}
    <i className="mdi mdi-pencil alk-clickable-icon" />
    {i18n.t(
      'frontproductstream.sharing_unit_template.detail.pricewaterfall_tax_tipster_right_text',
      {
        defaultValue: ' to update the product taxes.',
      },
    )}
  </>
);

const PriceWaterfalls = (props) =>
  [ENTITY_TYPE_SHARINGUNIT_TARIFF, ENTITY_TYPE_SHARINGUNIT_TEMPLATE].includes(
    props.entityKind,
  ) ? (
    <>
      {props.entityKind === ENTITY_TYPE_SHARINGUNIT_TEMPLATE && (
        <Tipster info={text} />
      )}
      <SharingUnitTariffPriceWaterfalls {...props} />
    </>
  ) : (
    <SharingUnitPriceWaterfalls {...props} />
  );

PriceWaterfalls.propTypes = {
  entityKind: PropTypes.string,
  entity: PropTypes.any,
};

export default PriceWaterfalls;

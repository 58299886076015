import i18n from 'utils/i18n';

export const MODULE_NAME = 'textile';

export const CREATE = `${MODULE_NAME}/CREATE`;
export const CREATE_FAILURE = `${MODULE_NAME}/CREATE_FAILURE`;
export const CREATE_SUCCESS = `${MODULE_NAME}/CREATE_SUCCESS`;
export const SET_VARIANT_FIELDS = `${MODULE_NAME}/SET_VARIANT_FIELDS`;
export const ADD_VARIANT_FIELD = `${MODULE_NAME}/ADD_VARIANT_FIELD`;
export const REMOVE_VARIANT_FIELD = `${MODULE_NAME}/REMOVE_VARIANT_FIELD`;
export const TOGGLE_VARIANT = `${MODULE_NAME}/TOGGLE_VARIANT`;

// Statuses
export const Statuses = {
  ACTIVE: {
    id: 'ACTIVE',
    name: i18n.t('Active'),
  },
  INACTIVE: {
    id: 'INACTIVE',
    name: i18n.t('Inactive'),
  },
};

export const TEXTILE_ONLY_FIELDS = [
  'subQuantity',
  'allowedTextileSizeTextList',
  'allowedTextileColorTextList',
];

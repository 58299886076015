import { AssetV2 } from 'components/ui/form/asset-list/AssetV2';
import { AssetTypeV2 } from 'components/ui/form/asset-list/AssetV2/types';

import { Aside } from './Aside';
import styles from './AssetLibrary.module.scss';
import { AssetModalControls } from './AssetModalControls';

type AssetLibraryProps = {
  productKeyId: number;
  items: AssetTypeV2[];
  updateEntities: (mediaType?: string, assetType?: number) => void;
};

export const AssetLibrary = ({
  productKeyId,
  items,
  updateEntities,
}: AssetLibraryProps) => {
  return (
    <div className={styles.wrapper}>
      <div className={styles.main}>
        <AssetModalControls
          productKeyId={productKeyId}
          items={items}
          updateEntities={updateEntities}
        />
        <div className={styles.assets}>
          {items.map((item) => (
            <AssetV2 key={`${item.id}`} asset={item} />
          ))}
        </div>
      </div>
      <div className={styles.asideContainer}>
        <Aside />
      </div>
    </div>
  );
};

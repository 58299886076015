import { get } from 'lodash/fp';
import { useEffect, useMemo } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { Ellitips } from '@alkem/react-ui-ellitips';
import { Field } from '@alkem/sdk-dashboard';

import Raguel from 'components/ui/form/plugins/validator';
import { getLanguageValueFromMultiple } from 'core/api/productversion';
import { getLocalesByTargetMarket } from 'core/api/user';
import { selectHasEditableFirstBracketTariff } from 'modules/feature-flag/selectors';
import { getTaxLabels } from 'modules/price-waterfalls/actions';
import { PriceWaterfallDisplayType } from 'modules/price-waterfalls/constants';
import {
  getTaxInformationForProducts,
  selectFieldNames,
} from 'modules/price-waterfalls/selectors';
import {
  findChildrenPriceWaterfall,
  findFirstPriceWaterfall,
} from 'modules/price-waterfalls/utils';
import { selectUser } from 'modules/user';
import { DispatchType } from 'types';
import i18n from 'utils/i18n';

import { CollapsiblePriceWaterfall } from '../collapsible-price-waterfall';

import styles from './PriceWaterfallWithChildren.module.scss';

interface ProductVersion {
  isConsumerUnit: boolean;
  isDisplayUnit: boolean;
  isMadeOf?: [];
  catalogPrice?: [];
  dutyFeeTaxInformationList?: [];
}

interface Props {
  entityId: number;
  field: Field;
  onChange: (model: string, value: any, _, __, isDirty: boolean) => void;
  disabled?: boolean;
  targetMarketId: number;
  openByDefault: boolean;
  productVersion: ProductVersion;
  productId: number;
  targetOrganizationId: number;
  priceWaterfalls: any[];
  entityType: string;
  taxesNotIncludedByDefault?: boolean;
  canDelete?: boolean;
  displayType?: PriceWaterfallDisplayType;
}

export const getSubProducts = (productVersion: ProductVersion) => {
  if (productVersion.isDisplayUnit) {
    return (get('isMadeOf', productVersion) || []).filter(
      (product) => !!get('targetProduct', product),
    );
  }
  return [];
};

export function PriceWaterfallWithChildren({
  entityId,
  field,
  onChange,
  disabled = false,
  targetMarketId,
  openByDefault,
  productVersion,
  productId,
  targetOrganizationId,
  priceWaterfalls,
  entityType,
  taxesNotIncludedByDefault,
  canDelete = true,
  displayType = 'default',
}: Props) {
  const hasEditableFirstBracket: boolean = useSelector(
    selectHasEditableFirstBracketTariff,
  );
  const dispatch: DispatchType = useDispatch();
  const user = useSelector(selectUser);
  const locales = getLocalesByTargetMarket(user).get(String(targetMarketId));

  useEffect(() => {
    dispatch(getTaxLabels);
  }, [dispatch]);

  const fieldNamesFunc = useSelector(selectFieldNames);
  const taxInfo = useMemo(() => {
    return {
      [productId]: getTaxInformationForProducts(
        fieldNamesFunc,
        {
          ...productVersion,
          specializes: { id: productId },
        },
        targetOrganizationId,
      )[productId],
    };
  }, [fieldNamesFunc, productId, productVersion, targetOrganizationId]);

  const subProducts = getSubProducts(productVersion);

  const getTitle = (id, priceWaterfallData) => {
    const defaultTitle = (
      <div className={styles.subproductTitle}>
        {i18n.t(
          'frontproductstream.rfp_event.proposal_form_pricewaterfall_input.label',
          { defaultValue: 'Price waterfall' },
        )}
      </div>
    );
    // if parent product return default value
    if (id === 0) {
      return defaultTitle;
    }

    const priceWaterfallProductId = get('product.id', priceWaterfallData);
    if (!priceWaterfallProductId) {
      return defaultTitle;
    }
    const subProduct = subProducts.find(
      (p) => get('targetProduct.id', p) === priceWaterfallProductId,
    );
    return (
      <div className={styles.subproductTitle}>
        <div className={styles.subproductQuantity}>
          {'x' + get('quantity', subProduct)}
        </div>
        <div className={styles.subproductName}>
          <Ellitips
            id={`pw-subproduct-id-${priceWaterfallProductId}`}
            label={
              getLanguageValueFromMultiple(
                get('targetProduct.version.namePublicLong', subProduct),
                locales,
              ).data
            }
          />
        </div>
      </div>
    );
  };

  const displayPriceWaterfall = (value, id, firstOfMultiple) => {
    return (
      <CollapsiblePriceWaterfall
        key={`${field.model}.${id}`}
        model={`${field.model}.${id}`}
        value={value}
        entityId={entityId}
        onChange={onChange}
        targetOrganizationId={targetOrganizationId}
        editableFirstBracket={hasEditableFirstBracket}
        taxInformation={taxInfo}
        openByDefault={openByDefault}
        canDelete={canDelete && id === 0}
        isFirstOfMultiple={firstOfMultiple}
        title={getTitle(id, value)}
        entityType={entityType}
        disabled={disabled}
        taxesNotIncludedByDefault={!!taxesNotIncludedByDefault}
        displayType={displayType}
      />
    );
  };

  const renderPriceWaterfallBlock = () => {
    const firstPw = findFirstPriceWaterfall(productId, priceWaterfalls);
    const childrenPW = findChildrenPriceWaterfall(productId, priceWaterfalls);
    return (
      <>
        <div className="Raguel__block FormField--raguelError">
          <Raguel
            entityId={entityId}
            entityKind={entityType}
            label={field.label as string}
            model={`${field.model}`}
            value={priceWaterfalls}
            recipientId={targetOrganizationId}
          />
        </div>
        {displayPriceWaterfall(firstPw, 0, childrenPW.length > 0)}
        {childrenPW.map((v, id) => displayPriceWaterfall(v, id + 1, false))}
      </>
    );
  };

  return <div className="PriceWaterfalls">{renderPriceWaterfallBlock()}</div>;
}

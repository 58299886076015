import classnames from 'classnames';
import PropTypes from 'prop-types';
import { PureComponent } from 'react';
import { connect } from 'react-redux';
import { v4 as uuid } from 'uuid';

import { Checkbox } from '@alkem/react-ui-checkbox';

import {
  RESTRICTION_TYPE_BLOCKING,
  addStaticWarning,
  deleteStaticWarning,
  toggleRuleBypassForProduct,
} from 'modules/validation';
import i18n from 'utils/i18n';
import { get } from 'utils/immutable';
import { separateActions } from 'utils/redux';

import { renderRuleSetLabels } from './renderers';
import { areRulesNotRequested } from './utils';
import './validator.scss';

const mapDispatchToProps = {
  addStaticWarning,
  deleteStaticWarning,
  bypass: toggleRuleBypassForProduct,
};

class RaguelStatic extends PureComponent {
  static propTypes = {
    rule: PropTypes.object,
    className: PropTypes.string,
    warning: PropTypes.bool,
    blocking: PropTypes.bool,
    message: PropTypes.node,
    withBackground: PropTypes.bool,
    buttons: PropTypes.object,
    recipientId: PropTypes.number,
    actions: PropTypes.shape({
      addStaticWarning: PropTypes.func.isRequired,
      deleteStaticWarning: PropTypes.func.isRequired,
      bypass: PropTypes.func.isRequired,
    }),
  };

  static defaultProps = {
    warning: false,
    withBackground: false,
    recipientId: null,
  };

  componentDidMount() {
    if (this.props.warning) {
      this.props.actions.addStaticWarning();
    }
  }

  componentWillUnmount() {
    if (this.props.warning) {
      this.props.actions.deleteStaticWarning();
    }
  }

  isBypassed = () => {
    const { rule } = this.props;
    return rule && get(rule, 'bypassed');
  };

  onBypass = () => {
    const {
      rule,
      actions: { bypass },
    } = this.props;
    if (rule && bypass) {
      bypass(get(rule, 'id'), get(rule, 'bypassed'));
    }
  };

  renderBypassable() {
    const { rule } = this.props;
    if (!rule || !get(rule, 'bypassable')) {
      return null;
    }

    const bypassed = get(rule, 'bypassed');
    const bypassMessage = bypassed
      ? i18n.t(
          'frontproductstream.plugins.validator.static_bypass_checkbox.bypassed_label',
          { defaultValue: 'This attribute has been marked as non needed.' },
        )
      : i18n.t(
          'frontproductstream.plugins.validator.static_bypass_checkbox.label',
          {
            defaultValue:
              'I declare that my product does not need this attribute.',
          },
        );

    return (
      <Checkbox
        id={`raguel-error-message-rule-checkbox-${get(rule, 'id')}`}
        checked={bypassed}
        onChange={this.onBypass}
        label={bypassMessage}
      />
    );
  }

  renderRuleSetLabel() {
    const { rule } = this.props;
    if (!rule || !get(rule, 'ruleSet')) {
      return null;
    }
    return renderRuleSetLabels(
      get(rule, 'id'),
      [get(rule, 'ruleSet')],
      get(rule, 'bypassed'),
    );
  }

  render() {
    const {
      rule,
      message,
      buttons,
      className,
      warning,
      withBackground,
      recipientId,
    } = this.props;

    let { blocking } = this.props;
    if (rule) {
      blocking = get(rule, 'restrictionType') === RESTRICTION_TYPE_BLOCKING;
    }
    if (recipientId) {
      blocking =
        get(rule, `restrictionTypesByOrganizations.${recipientId}`) ===
        RESTRICTION_TYPE_BLOCKING;
    }
    const iconClasses = {
      mdi: true,
      'mdi-cancel': blocking,
      'mdi-alert': !blocking,
    };

    const messageClasses = this.isBypassed()
      ? {}
      : {
          Raguel__message: true,
          Raguel__message__warning: warning,
        };

    const msg = rule ? get(rule, 'errorMessage') : message;
    const notRequested = rule ? areRulesNotRequested([rule]) : false;

    const classes = {
      Raguel: true,
      'FormField--raguelError': withBackground && !warning,
      'FormField--notRequested': withBackground && !warning && notRequested,
      'FormField--raguelWarning': withBackground && warning,
    };
    if (className) {
      classes[className] = true;
    }

    return (
      <div className={classnames(classes)}>
        <div
          className={classnames(messageClasses)}
          id={`raguel-static-error-message-${uuid()}`}
        >
          {!this.isBypassed() && (
            <div
              className={classnames('Raguel__message__line', {
                Raguel__notRequestedErrors: notRequested,
              })}
            >
              <span className="Raguel__default">
                <i className={classnames(iconClasses)} />
                <span>{msg}</span>
                {this.renderRuleSetLabel()}
                <span className="Raguel__message__buttons">{buttons}</span>
              </span>
            </div>
          )}
          <div>{this.renderBypassable()}</div>
        </div>
      </div>
    );
  }
}

export { RaguelStatic };
export default connect(null, mapDispatchToProps, separateActions)(RaguelStatic);

import { get } from 'lodash/fp';
import { call, put, select } from 'redux-saga/effects';

import { ResponseWithData } from '@alkem/sdk-dashboard';

import contributionApi from 'resources/contributionApi';

import { dataOpsPatchesReceived, saveDataOpsPatchesDone } from '../actions';
import {
  selectDataOpsDirtyPatchList,
  selectDataOpsProductKeyIdToEntityId,
} from '../selectors';
import { DataOpsPatch } from '../types';

import { getEntityById, hasPatchPermission } from './utils';

type DirtyPatchList = ReturnType<typeof selectDataOpsDirtyPatchList>;
type ProductKeyIdToEntityId = ReturnType<
  typeof selectDataOpsProductKeyIdToEntityId
>;

export function* savePatches() {
  try {
    const patchList: DirtyPatchList = yield select(selectDataOpsDirtyPatchList);
    const productKeyIdToEntityId: ProductKeyIdToEntityId = yield select(
      selectDataOpsProductKeyIdToEntityId,
    );
    const patchesByProductKeyId: { [key: number]: typeof patchList } = {};

    const canPatch = yield call(hasPatchPermission, {
      withNotif: patchList.length > 0,
    });
    if (!canPatch) {
      return;
    }

    for (const patch of patchList) {
      const entity = yield call(
        getEntityById,
        productKeyIdToEntityId[patch.productKeyId],
      );
      const patchToSave: typeof patch = {
        ...patch,
        dataOld:
          patch.dataOld !== undefined
            ? patch.dataOld
            : get([patch.fieldName], entity),
      };
      if (patchToSave.dataOld === undefined) {
        patchToSave.dataOld = null;
      }
      if (!patchesByProductKeyId[patch.productKeyId]) {
        patchesByProductKeyId[patch.productKeyId] = [];
      }
      patchesByProductKeyId[patch.productKeyId].push(
        patchToSave as DataOpsPatch,
      );
    }

    const promises: PromiseLike<any>[] = [];
    const patchesToSave = Object.entries(patchesByProductKeyId);

    for (const [productKeyId, patches] of patchesToSave) {
      promises.push(
        contributionApi.put(
          `/contribution/v1/product/${productKeyId}/patches`,
          patches,
        ),
      );
    }

    const responses: ResponseWithData<DataOpsPatch[]>[] = yield call(() =>
      Promise.all(promises),
    );

    const receivedPatches = responses.reduce<DataOpsPatch[]>(
      (acc, response, index) => {
        const savedPatches = response.data.data;
        const mergedPatches = savedPatches.map((patch) => {
          const [, previousPatches] = patchesToSave[index];
          const previousPatch = (previousPatches as DataOpsPatch[]).find(
            (localPatch) => localPatch.id === patch.id,
          );
          return previousPatch ? { ...previousPatch, ...patch } : patch;
        });
        return acc.concat(mergedPatches);
      },
      [],
    );

    yield put(dataOpsPatchesReceived({ patches: receivedPatches }));
    yield put(saveDataOpsPatchesDone({}));
  } catch (error) {
    yield put(saveDataOpsPatchesDone({ error }));
    throw error;
  }
}

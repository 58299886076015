import classNames from 'classnames';
import moment from 'moment';
import { useCallback } from 'react';
import { useSelector } from 'react-redux';

import { Checkbox } from '@alkem/react-ui-checkbox';

import { updateSelectedIds } from 'actions/media';
import { useDispatch } from 'hooks/useDispatch';
import {
  getHasBackgroundWarning,
  getHasResolutionWarning,
} from 'modules/assets/warning-utils';
import { selectAssetHasErrorByAssetId } from 'modules/validation';
import { selectSelectedIds } from 'reducers/media';
import i18n from 'utils/i18n';

import styles from './AssetV2.module.scss';
import { AssetTypeV2 } from './types';
import { getAssetDerivedData } from './utils';

type ChipsProps = {
  isArchived: boolean;
  hasWarning: boolean;
  hasError: boolean;
  isPackshot: boolean;
};

const Chips = ({
  isPackshot,
  isArchived,
  hasWarning,
  hasError,
}: ChipsProps) => {
  return (
    <ul className={styles.chips}>
      {hasError && (
        <li className={classNames([styles.chip, styles.chipError])}>
          {i18n.t('frontproducstream.asset_list.error_chip.text', {
            defaultValue: 'Error',
          })}
        </li>
      )}
      {hasWarning && (
        <li className={classNames([styles.chip, styles.chipWarning])}>
          {i18n.t('frontproducstream.asset_list.warning_chip.text', {
            defaultValue: 'Warnings',
          })}
        </li>
      )}
      {isArchived && (
        <li className={classNames([styles.chip, styles.chipArchived])}>
          {i18n.t('frontproducstream.asset_list.archived_chip.text', {
            defaultValue: 'Archived',
          })}
        </li>
      )}
      {isPackshot && (
        <li className={classNames([styles.chip, styles.chipPackshot])}>
          {i18n.t('frontproducstream.asset_list.packshot_chip.text', {
            defaultValue: 'Main image',
          })}
        </li>
      )}
    </ul>
  );
};

type AssetV2Props = {
  asset: AssetTypeV2;
};

export const AssetV2 = ({ asset }: AssetV2Props) => {
  const dispatch = useDispatch();
  const assetHasErrorByAssetId = useSelector(selectAssetHasErrorByAssetId);

  const selectedIds = useSelector(selectSelectedIds);

  const isSelected = selectedIds.includes(asset.id);

  const hasError = assetHasErrorByAssetId[asset.id];

  const { isArchived, previewUri, label, fileType, fileExtension } =
    getAssetDerivedData(asset);

  const { iconName } = fileType;

  const handleChange = useCallback(() => {
    dispatch(updateSelectedIds(asset.id));
  }, [asset.id, dispatch]);

  const isPicture = asset._type === 'ProductPicture';
  const isDocument = asset._type === 'ProductDocument';

  const hasFileInfo = isPicture || isDocument;

  const hasWarning =
    isPicture &&
    (getHasResolutionWarning({ width: asset.width, height: asset.height }) ||
      getHasBackgroundWarning(asset.backgroundScore));

  const resolutionText = isPicture ? asset.definition : '';

  const viewsText = isPicture
    ? [
        asset.productFaceCode?.description,
        asset.pictureAngleVerticalCode?.description,
        asset.pictureAngleHorizontalCode?.description,
      ]
        .filter(Boolean)
        .join(', ')
    : '';

  const fileInfoText =
    hasFileInfo &&
    [`${Math.round(asset.fileSize / 1000)} ko`, resolutionText]
      .filter(Boolean)
      .join(', ');

  return (
    <div
      className={classNames([
        styles.wrapper,
        { [styles.wrapperSelected]: isSelected },
        { [styles.wrapperError]: hasError && !isSelected },
        { [styles.wrapperWarning]: hasWarning && !isSelected && !hasError },
      ])}
    >
      <div
        className={classNames([
          styles.previewWrapper,
          { [styles.previewWrapperNoPreview]: !previewUri },
        ])}
      >
        {isSelected && (
          <div className={styles.previewHeader}>
            <span className={styles.headerText}>
              {i18n.t('frontproducstream.asset_list.asset_selected.text', {
                defaultValue: 'Asset selected',
              })}
            </span>
          </div>
        )}
        {previewUri ? (
          <img
            className={classNames([
              styles.preview,
              { [styles.previewSelected]: isSelected },
            ])}
            src={previewUri}
            alt={i18n.t('frontproducstream.asset_list.picture.image.alt', {
              defaultValue: 'Product image',
            })}
          ></img>
        ) : (
          <i
            className={classNames(['mdi', `mdi-${iconName}`, styles.fileIcon])}
          ></i>
        )}
        <div className={styles.previewFooter}>
          <div className={styles.fixCheckbox}>
            <Checkbox
              id={`checkbox-picture-assetv2-${asset.id}`}
              checked={isSelected}
              onChange={handleChange}
            />
          </div>
          <Chips
            isArchived={isArchived}
            hasWarning={hasWarning}
            hasError={hasError}
            isPackshot={isPicture && asset.isPackshot}
          />
        </div>
      </div>
      <div className={styles.assetInfo}>
        <span className={styles.assetLabel} title={label}>
          {label}
        </span>
        <div className={styles.row}>
          {hasFileInfo && (
            <>
              {isDocument && (
                <div className={styles.fileExtWrapper}>
                  <span className={styles.fileExt}>{fileExtension}</span>
                </div>
              )}
              <span className={styles.infoText}>{fileInfoText}</span>
            </>
          )}
        </div>
        {isPicture && viewsText && (
          <div className={styles.row}>
            <span className={styles.label}>
              {i18n.t('frontproducstream.asset_list.view.text', {
                defaultValue: 'View',
              })}
            </span>
            <span className={styles.infoText} title={viewsText}>
              {viewsText}
            </span>
          </div>
        )}
        <div className={styles.row}>
          <span className={styles.label}>
            {i18n.t('frontproducstream.asset_list.created_at.text', {
              defaultValue: 'Created at',
            })}
          </span>
          <span className={styles.infoText}>
            {moment(asset.createdAt).format('YYYY-MM-DD')}
          </span>
        </div>
      </div>
    </div>
  );
};

import classNames from 'classnames';
import { noop } from 'lodash';
import { ComponentType, ElementType } from 'react';

import ListTableCell from '..';

import { craftCell, craftData } from './selectors';

export interface Column {
  id: number;
  code: string;
  description: string;
  rank: number;
  label: string | null;
  withoutAction?: boolean;
  firstLeftSticky?: boolean;
  selectable?: boolean;
  data: {
    default?: string;
    width?: string;
    clickable?: boolean;
    path?:
      | string
      | Record<
          string,
          {
            path: any;
            localized?: boolean;
          }
        >;
    sort?: {
      path: string;
      nestedPath?: string;
    };
    type: string;
    flattened_type?: string;
    localized?: boolean;
  };
}

export interface CellAttibutes {
  className: string;
  center: boolean;
}

export type CellClass = ComponentType & CellAttibutes;

interface Props {
  column: Column;
  row: object;
  cellCrafter?: (column: Column) => { Cell: CellClass; props: any };
  dataCrafter?: (
    column: Column,
    row: object,
  ) => { data: object; isDefaultData: boolean; localeCode?: string };
  actionCrafter?: (column: Column) => ElementType | null | void;
}

const SmartCell = ({
  column,
  row,
  cellCrafter = craftCell,
  dataCrafter = craftData,
  actionCrafter = noop,
}: Props) => {
  const { Cell, props } = cellCrafter(column);
  const { data, isDefaultData, localeCode } = dataCrafter(column, row);
  const CellAction = actionCrafter(column);
  const content = (
    <Cell
      {...props}
      localeCode={localeCode}
      data={data}
      isDefaultData={isDefaultData}
    />
  );

  return (
    <ListTableCell
      className={classNames('SmartCell', Cell.className)}
      data={{ code: props.code }}
      center={Cell.center}
    >
      {CellAction && !column.withoutAction ? (
        <CellAction>{content}</CellAction>
      ) : (
        content
      )}
    </ListTableCell>
  );
};

export default SmartCell;

import asyncComponent from 'core/modules/async/component';
import registerModule from 'core/modules/async/register';
import { registerModuleReducer } from 'utils/modules/reducer';
import { registerSaga } from 'utils/modules/saga';

import { REDUCER_KEY } from './constants';
import mainReducer from './reducers';
import mainSaga from './sagas';

registerSaga(mainSaga);
registerModuleReducer(REDUCER_KEY, mainReducer);

export { openImportModal } from './actions';
export { selectIsImportModalOpen } from './selectors';

const asyncModule = async () =>
  registerModule(
    await import(/* webpackChunkName: "import-modal" */ './async'),
  );

export const ImportModal = asyncComponent(asyncModule, {
  componentPath: 'ImportModal',
});
export const SimpleImportModal = asyncComponent(asyncModule, {
  componentPath: 'SimpleImportModal',
});
export const ImportReportModal = asyncComponent(asyncModule, {
  componentPath: 'ImportReportModal',
});

import { ValidationApi } from '@alkem/sdk-dashboard';

import { getUserLanguage as acceptLanguage } from 'utils/i18n';

const options = {
  cacheEnabled: false,
  acceptLanguage,
};

export default new ValidationApi({ ...options, immutable: true });

export const validationApiNotImmutable = new ValidationApi(options);
export const validationApi = validationApiNotImmutable;

export const createAdminValidationApi = () =>
  new ValidationApi({ ...options, useAdminToken: true });

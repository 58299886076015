import { archived as lifeCycleArchived } from 'constants/filters/lifeCycle';
import serviceProductApi from 'resources/serviceProductApi';

export const declineProduct = async (
  reference,
  targetMarketId,
  reason,
  message,
) => {
  await serviceProductApi.declineProduct(
    reference,
    targetMarketId,
    reason,
    message,
  );
};

export const createProductAsArchived = async (
  reference,
  targetMarketId,
  reason,
  message,
) => {
  await serviceProductApi.createProduct(
    reference,
    targetMarketId,
    reason,
    {
      productVersion: {
        data: {
          lifeCycle: lifeCycleArchived.id,
          isConsumerUnit: true,
        },
      },
    },
    message,
    true,
  );
};

import PropTypes from 'prop-types';
import { Component } from 'react';

import Modal from 'components/ui/modal';
import i18n from 'utils/i18n';
import { track } from 'utils/tracking';

import './hpp.scss';

class HppModal extends Component {
  static propTypes = {
    hppSrc: PropTypes.string.isRequired,
    onClose: PropTypes.func.isRequired,
  };

  componentDidMount() {
    track({
      category: 'product',
      action: 'product_previewed',
    });
  }

  render() {
    const { hppSrc, onClose } = this.props;
    return (
      <Modal
        hideFooter
        modalStyle="hpp"
        className="HppModal"
        title={i18n.t('frontproductstream.hpp.modal.title', {
          defaultValue: 'Hosted product page',
        })}
        onClose={onClose}
      >
        <iframe
          id="hpp-iframe"
          name="hpp-iframe"
          title="HPP"
          width="100%"
          className="HppModal__iframe"
          height="800px"
          scrolling="No"
          src={hppSrc}
        />
      </Modal>
    );
  }
}

export default HppModal;

import classNames from 'classnames';
import { Map } from 'immutable';

import { isRetailer } from 'core/api/user';
import {
  FEATURE_DATA_MATURITY_DEADLINE,
  hasFeature,
} from 'modules/feature-flag';
import { UserImmutable } from 'types';
import i18n from 'utils/i18n';

import { MaturityPanelTabIndex } from '../maturity-panel';
import { MaturityPanelManager } from '../maturity-panel-manager';
import {
  MaturitiesSummary,
  MaturitySummary,
  isScenarioComplete,
  isScenarioDeadlineBehind,
  parseDeadline,
} from '../types';

import styles from './index.module.scss';

interface Props {
  data: Map<string, any>;
  user: UserImmutable;
  rowIdentifier?: number;
}

function computeCalendarIconClass(
  isDeadlineBehind: boolean,
  isIncompleteScenario: boolean,
): string {
  if (isDeadlineBehind) {
    if (isIncompleteScenario) {
      return 'mdi-calendar-alert';
    } else {
      return 'mdi-calendar-blank';
    }
  } else {
    if (isIncompleteScenario) {
      return 'mdi-calendar-clock';
    } else {
      return 'mdi-calendar-check';
    }
  }
}

function MaturitiesDeadLinesNothing() {
  return (
    <span
      className={classNames(styles.cellContents, styles.information)}
      data-testid="maturity-cell-information"
    >
      <i data-testid="calendar-icon" className="mdi mdi-calendar-blank" />{' '}
      {i18n.t('frontproductstream.maturity_deadline_cell.noupcoming.text', {
        defaultValue: 'nothing coming',
      })}
    </span>
  );
}

function FeaturedUpcomingScenario({
  featured_upcoming_scenario,
}: {
  featured_upcoming_scenario: MaturitySummary;
}) {
  const scenarioIsComplete = isScenarioComplete(featured_upcoming_scenario);
  const upcomingDeadline = parseDeadline(featured_upcoming_scenario);
  const isDeadlineBehind = isScenarioDeadlineBehind(upcomingDeadline);

  if (scenarioIsComplete && isDeadlineBehind) {
    return <MaturitiesDeadLinesNothing />;
  }
  const iconClass = computeCalendarIconClass(
    isDeadlineBehind,
    !scenarioIsComplete,
  );

  return (
    <span className={styles.cellContents} data-testid="upcoming-deadline">
      <i data-testid="calendar-icon" className={classNames('mdi', iconClass)} />{' '}
      {upcomingDeadline.format('L')}
    </span>
  );
}

export const MaturitiesDeadlinesCell = ({
  data,
  rowIdentifier,
  user,
}: Props) => {
  const maturitiesSummary: MaturitiesSummary = data.toJS();

  if (isRetailer(user) && !hasFeature(user, FEATURE_DATA_MATURITY_DEADLINE)) {
    return null;
  }

  let body;
  if (!maturitiesSummary.featured_upcoming_scenario) {
    body = <MaturitiesDeadLinesNothing />;
  } else {
    const othersCount = maturitiesSummary.upcoming_scenarios.length - 1;
    body = (
      <div>
        <FeaturedUpcomingScenario
          featured_upcoming_scenario={
            maturitiesSummary.featured_upcoming_scenario
          }
        />
        {othersCount > 0 && (
          <div className={styles.others}>
            {i18n.t('frontproductstream.maturity_deadline_cell.others.text', {
              count: othersCount,
              defaultValue: '+{{count}} other upcomings',
            })}
          </div>
        )}
      </div>
    );
  }

  return (
    <div className={styles.cell}>
      <MaturityPanelManager
        maturitiesSummary={maturitiesSummary}
        selectedTab={MaturityPanelTabIndex.UPCOMING}
        rowIdentifier={rowIdentifier}
      >
        {body}
      </MaturityPanelManager>
    </div>
  );
};

import { isRetailer } from 'core/api/user';
import { hasFeature } from 'modules/feature-flag';
import { FEATURE_DATA_MATURITY_MANAGEMENT } from 'modules/feature-flag/constants';

const FILTER_ALL = 'COMPUTE_VALUE';
const FILTER_REQUESTED = 'FILTER_REQUESTED';
const FILTER_NOT_REQUESTED = 'FILTER_NOT_REQUESTED';
const FILTER_ERROR = 'FILTER_ERROR';
const FILTER_SUCCESS = 'FILTER_SUCCESS';

export const maturityFilter = {
  key: 'maturities_status',
  query: 'maturity',
  isAvailable: ({ user } = {}) =>
    isRetailer(user) && hasFeature(user, FEATURE_DATA_MATURITY_MANAGEMENT),
  customField: 'maturities_status',
  withCustomField: ({ user }) =>
    isRetailer(user) && hasFeature(user, FEATURE_DATA_MATURITY_MANAGEMENT),
  valuePath: 'data',

  FILTER_ALL,
  FILTER_REQUESTED,
  FILTER_NOT_REQUESTED,
  FILTER_ERROR,
  FILTER_SUCCESS,

  parseQueryValues: (queryStr = '') => {
    const [maturityIdQuery, filtersQuery = ''] = queryStr.split(':');
    const maturityId = Number(maturityIdQuery);
    if (maturityId > 0) {
      let filters = filtersQuery
        .split(';')
        .filter((f) =>
          [
            FILTER_ALL,
            FILTER_REQUESTED,
            FILTER_NOT_REQUESTED,
            FILTER_ERROR,
            FILTER_SUCCESS,
          ].includes(f),
        );
      if (filters.length === 0) {
        filters = [FILTER_ALL];
      }
      return [{ [maturityId]: filters }];
    }
    return [];
  },

  formatQueryValues: (value) => {
    if (value && !value.isEmpty()) {
      const maturityId = value.keySeq().first();
      const filters = value.get(maturityId) || [];
      return `${maturityId}:${filters.join(';')}`;
    }
    return '';
  },
};

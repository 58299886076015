import { flow } from 'lodash/fp';
import PropTypes from 'prop-types';
import { PureComponent } from 'react';
import { connect } from 'react-redux';
import { createStructuredSelector } from 'reselect';

import { ROOM_TYPE_PRODUCTVERSION } from 'constants/room';
import { hasProductCommentSetting } from 'core/api/organization-settings';
import { withUserType } from 'hocs';
import { Chat } from 'modules/chat';
import DashboardPublicationSummary from 'modules/product-page/modules/dashboard-publication-summary';
import { selectOrganizationSettings } from 'modules/user';
import { RetailerValidationDashboard } from 'modules/validation';
import {
  selectProductIsWaitingToBeShared,
  selectProductVersionId,
  selectSharedProductVersionId,
} from 'reducers/productVersion';
import i18n from 'utils/i18n';

import './product-dashboard.scss';

const mapStateToProps = createStructuredSelector({
  productVersionId: selectProductVersionId,
  sharedProductVersionId: selectSharedProductVersionId,
  organizationSettings: selectOrganizationSettings,
  productIsWaitingToBeShared: selectProductIsWaitingToBeShared,
});

export class ProductDashboard extends PureComponent {
  static propTypes = {
    // from store
    productVersionId: PropTypes.number.isRequired,
    sharedProductVersionId: PropTypes.number,
    productIsWaitingToBeShared: PropTypes.bool,
    organizationSettings: PropTypes.object.isRequired,
    // withUserType
    isPrivateLabel: PropTypes.bool.isRequired,
    isManufacturer: PropTypes.bool.isRequired,
    isRetailer: PropTypes.bool.isRequired,
    isThirdParty: PropTypes.bool.isRequired,
  };

  render() {
    const {
      isManufacturer,
      isRetailer,
      isPrivateLabel,
      isThirdParty,
      productVersionId,
      sharedProductVersionId,
      organizationSettings,
      productIsWaitingToBeShared,
    } = this.props;

    return (
      <div>
        <div className="row Dashboard__row row-eq-height">
          <div className="col-xs-12 Dashboard__col">
            {(isManufacturer || isPrivateLabel) && (
              <DashboardPublicationSummary />
            )}
            {isRetailer &&
              productIsWaitingToBeShared &&
              !sharedProductVersionId && (
                <div className="Dashboard__MessageDisabled">
                  <h2 className="Dashboard__MessageDisabledTitle">
                    {i18n.t('Product chat disabled')}
                  </h2>
                  {i18n.t(
                    'The supplier stopped sharing this product with you. Please use the supplier chat if needed.',
                  )}
                </div>
              )}
            {isRetailer && (
              <RetailerValidationDashboard
                productVersionId={productVersionId}
              />
            )}
          </div>
        </div>
        {(isThirdParty ||
          (isRetailer &&
            hasProductCommentSetting(organizationSettings) &&
            sharedProductVersionId)) && (
          <div className="row Dashboard__row row-eq-height">
            <div className="col-xs-12 Dashboard__col Dashboard__col--messenger">
              <h2 className="Dashboard__messengerLabel">
                {i18n.t('Exchanges')}
              </h2>
              <Chat
                roomType={ROOM_TYPE_PRODUCTVERSION}
                entityId={
                  isRetailer ? sharedProductVersionId : productVersionId
                }
              />
            </div>
          </div>
        )}
      </div>
    );
  }
}

const enhance = flow(withUserType, connect(mapStateToProps));

export default enhance(ProductDashboard);

import PropTypes from 'prop-types';

import { Button } from '@alkem/react-ui-button';

import './index.scss';

function ExpandedListFilters(props) {
  const { action, children } = props;
  return (
    <div className="ExpandedListFilters">
      <div className="ExpandedListFilters__scrollable">
        {action && (
          <Button
            secondary
            id="expanded-list-filters-action"
            href={action.link}
            onClick={action.onClick}
            className="ExpandedListFilters__action"
          >
            <i className="mdi mdi-plus-circle" />
            {action.label}
          </Button>
        )}
        {children}
      </div>
    </div>
  );
}

ExpandedListFilters.propTypes = {
  children: PropTypes.node,
  action: PropTypes.shape({
    link: PropTypes.string,
    onClick: PropTypes.func,
    label: PropTypes.string.isRequired,
  }),
};

export default ExpandedListFilters;

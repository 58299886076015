import { Channel } from 'redux-saga';
import { call, put, select } from 'redux-saga/effects';

import { notificationError } from 'actions/notification';
import i18n from 'utils/i18n';
import { takeEverySafe } from 'utils/saga';

import { closeRejectModal, fetchTemplate } from '../actions';
import api from '../api';
import {
  ACCEPT,
  ACCEPT_FAILURE,
  ACCEPT_SUCCESS,
  REJECT,
  REJECT_FAILURE,
  REJECT_SUCCESS,
} from '../constants';
import { selectId } from '../selectors';

function* rejectSaga({ reason }: { reason: string }) {
  const templateId = yield select(selectId);
  const { error } = yield call(api.reject, templateId, reason);

  if (!error) {
    yield put({ type: REJECT_SUCCESS });
    yield put(closeRejectModal());
    yield put(fetchTemplate(templateId));
  } else {
    yield put(
      notificationError(
        i18n.t(
          'frontproductstream.sharingunit_tariff.reject_modal_notification.error',
          {
            defaultValue:
              'An error occured rejecting the tariff, please retry later',
          },
        ),
        {
          context: 'modal',
          sticky: false,
          error,
        },
      ),
    );

    yield put({ type: REJECT_FAILURE });
  }
}

function* acceptSaga() {
  const templateId = yield select(selectId);
  const { error } = yield call(api.accept, templateId);

  if (!error) {
    yield put({ type: ACCEPT_SUCCESS });
    yield put(fetchTemplate(templateId));
  } else {
    yield put(
      notificationError(
        i18n.t(
          'frontproductstream.sharingunit_tariff.accept_tariff_notification.error',
          {
            defaultValue:
              'An error occured accepting the tariff, please retry later',
          },
        ),
      ),
    );

    yield put({ type: ACCEPT_FAILURE });
  }
}

export default function* saga() {
  yield takeEverySafe(
    REJECT as unknown as Channel<{
      reason: string;
    }>,
    rejectSaga,
  );

  yield takeEverySafe(
    ACCEPT as unknown as Channel<{
      reason: string;
    }>,
    acceptSaga,
  );
}

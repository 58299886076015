import { v4 as uuid } from 'uuid';

import i18n from 'utils/i18n';

import { Bracket, Level } from './types';

// Level types.
export const levelTypePrice = { id: 100032015 };
export const levelTypeAllowance = { id: 100032017, code: 'allowance' };

export const LEVEL_TYPE_EARLY_PAYMENT_DISCOUNT_CODE = 'earlyPaymentDiscount';
export const NET_PRICE_WITH_EARLY_DISCOUNT = 'netPriceWithEarlyPaymentDiscount';
export const NET_PRICE_WITH_EARLY_DISCOUNT_EXCLUDING_VAT =
  'netPriceWithEarlyPaymentDiscountExcludingVAT';

// Unit types.
const unitTypeAbsolute = {
  id: 100032011,
  label: i18n.t('Amount'),
  code: '',
  key: '',
  data: {},
  children: [],
};
const unitTypeRelative = { id: 100032013, label: i18n.t('Percentage') };
export const unitTypes = [unitTypeAbsolute, unitTypeRelative];

// Prices.
// TODO: review and add ids on those.
export const priceGross = {
  id: 331,
  key: 'grossPrice',
  label: i18n.t('Gross price'),
};
export const priceTaxesExcl = {
  id: 333,
  key: 'netPriceExcludingTaxes',
  label: i18n.t('Net price excluding taxes'),
};
export const priceVAT = {
  id: 335,
  key: 'netPriceExcludingVAT',
  label: i18n.t('Net price excluding VAT'),
};
export const priceNet = {
  id: 337,
  key: 'netPrice',
  label: i18n.t('Net price'),
};

export const allPrices = [priceGross, priceTaxesExcl, priceVAT, priceNet];

const prices = [priceTaxesExcl, priceVAT, priceNet];

// Default values.
export const defaultBracket = () => ({ min: null });
export const defaultItemValue = (defaultValue?) => ({
  uuid: uuid(),
  value: defaultValue || '',
});

export const defaultAllowanceItem = (numBrackets) => ({
  uuid: uuid(),
  type: null,
  unit: null,
  description: null,
  values: Array(numBrackets)
    .fill(null)
    .map(() => defaultItemValue()),
});

export const defaultGrossPrice = (defaultValue) => ({
  uuid: uuid(),
  type: levelTypePrice,
  items: [
    {
      uuid: uuid(),
      type: priceGross,
      unit: unitTypeAbsolute,
      description: priceGross.label,
      values: [defaultItemValue(defaultValue)],
    },
  ],
});

export const defaultPriceLevel = (price, values?) => ({
  uuid: uuid(),
  type: levelTypePrice,
  items: [
    {
      uuid: uuid(),
      type: price,
      unit: unitTypeAbsolute,
      description: price.label,
      values: values || [defaultItemValue()],
    },
  ],
});

export const defaultEarlyPaymentDiscount = (
  type,
  brackets: Bracket[] = [],
): Level => ({
  uuid: uuid(),
  type,
  items: [
    {
      uuid: uuid(),
      type: null,
      unit: null,
      description: '',
      values: brackets.map(() => defaultItemValue()),
    },
  ],
});

export const defaultAllowanceLevel = () => ({
  uuid: uuid(),
  type: levelTypeAllowance,
  items: [],
});

export const defaultPriceWaterfall = (
  productId = 0,
  defaultGrossPriceValue: string | null = null,
) => ({
  product: { id: productId },
  brackets: [{ min: 1 }],
  bracketUnit: null,
  levels: [defaultGrossPrice(defaultGrossPriceValue)].concat(
    prices.map((price) => defaultPriceLevel(price)),
  ),
  taxes: [],
  hasLabel: false,
  label: '',
});

export const minimalPriceWaterfall = (productId = 0) => ({
  product: { id: productId },
  brackets: [{ min: 1 }],
  bracketUnit: null,
  levels: [defaultGrossPrice(null)],
  taxes: [],
  hasLabel: false,
  label: '',
});

export const BRACKET_COLUMNS_DISPLAYED = 3;

export const ROUNDING_RULE_STORAGE_KEY = 'pricewaterfall-rounding-rule';
export const ROUNDING_RULE_DECIMAL_PLACES = 'pricewaterfall-decimale-places';

export const VATRateCodes = [
  'REGULAR',
  'SUPER_REDUCED',
  'REDUCED',
  'INTERMEDIATE',
  'SUPERIOR',
  'EXEMPT',
];

export type PriceWaterfallDisplayType =
  | 'default'
  | 'template'
  | 'tariff'
  | 'fromTemplate'
  | 'fromTariff'
  | 'templateSpecificDiscount';

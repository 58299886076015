import PropTypes from 'prop-types';

import { getFromStatusId } from 'constants/physicalCheckerStatus';
import i18n from 'utils/i18n';

export default function PhysicalCheckerStatus({ data }) {
  if (data == null) {
    return (
      <div className="physicalCheckerStatus">{i18n.t('Not available')}</div>
    );
  }

  const physicalCheckerStatusData = getFromStatusId(data);

  return (
    <div>
      {physicalCheckerStatusData
        ? physicalCheckerStatusData.name
        : i18n.t('Not available')}
    </div>
  );
}

PhysicalCheckerStatus.propTypes = {
  data: PropTypes.number,
};

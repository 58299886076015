import { useEffect } from 'react';
import { useParams } from 'react-router-dom';

import { pageview } from 'utils/tracking';

import { useMatchPattern } from './hooks';

export function PageView() {
  const matchPattern = useMatchPattern();
  const params = useParams();

  useEffect(() => {
    pageview(global.location.href, matchPattern, params);
  }, [matchPattern, params]);

  return null;
}

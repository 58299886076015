import moment from 'moment';
import { useCallback } from 'react';

import { startLoading, stopLoading } from 'actions/loading';
import { notificationError, notificationSuccess } from 'actions/notification';
import { useDispatch } from 'hooks/useDispatch';
import mediaApi from 'resources/mediaApi';
import i18n from 'utils/i18n';

export const useDownloadPictures = ({ productKeyIds }) => {
  const dispatch = useDispatch();

  const handleDownloadPictures = useCallback(async () => {
    dispatch(startLoading());
    try {
      const response = await mediaApi.ProductPictureDownloadAll({
        product_key_ids: productKeyIds,
        packshot_only: false,
      });
      const filename = `${moment().format('YYYY-MM-DD')}.zip`;
      (window as any).saveAs(response.data, filename);
      dispatch(
        notificationSuccess(
          i18n.t(
            'frontproductstream.asset_list.button_bar.download_pictures_notification.success',
            { defaultValue: 'Pictures have been downloaded' },
          ),
          {
            context: 'modal',
          },
        ),
      );
    } catch (error: any) {
      if (error.error?.status === 404) {
        dispatch(
          notificationError(
            i18n.t(
              'frontproductstream.asset_list.button_bar.download_pictures_notification.missing_error',
              { defaultValue: 'No pictures found' },
            ),
            {
              context: 'modal',
            },
          ),
        );
      } else {
        dispatch(
          notificationError(
            i18n.t(
              'frontproductstream.asset_list.button_bar.download_pictures_notification.error',
              { defaultValue: 'An error occured while downloading pictures' },
            ),
            {
              context: 'modal',
            },
          ),
        );
      }
    } finally {
      dispatch(stopLoading());
    }
  }, [dispatch, productKeyIds]);

  return handleDownloadPictures;
};

import { createAction } from 'utils/redux';

import {
  refuseConsumerUnit,
  refuseListingUpdates,
} from '../product-share-diff/actions';

import {
  REFUSED_CONSUMER_UNIT,
  REFUSED_SHARING_UNIT,
  RETAILER_REFUSE_MODAL_OPEN,
  RETAILER_REFUSE_MODAL_RESET,
} from './constants';
import { selectRefusedEntityId, selectRefusedEntityType } from './selectors';

export const openRefuseModal = createAction(RETAILER_REFUSE_MODAL_OPEN);
export const resetRefuseModal = createAction(RETAILER_REFUSE_MODAL_RESET);

export const refuse = (message) => (dispatch, getState) => {
  const state = getState();
  const entityId = selectRefusedEntityId(state);
  const entityType = selectRefusedEntityType(state);
  let promise;
  switch (entityType) {
    case REFUSED_CONSUMER_UNIT:
      promise = dispatch(refuseConsumerUnit({ message }));
      break;
    case REFUSED_SHARING_UNIT:
      promise = dispatch(
        refuseListingUpdates({
          sharingUnitId: entityId,
          message,
        }),
      );
      break;
    default:
      promise = Promise.reject(new Error('Unknown entity type'));
  }
  dispatch(resetRefuseModal());
  return promise;
};

import { put } from 'redux-saga/effects';

import { takeLatestSafe } from '@alkem/micro-frontend-tools';

import { USER_PROFILE_LOADED } from 'modules/user';
import { asAction } from 'utils/actions';

import { getRecipients } from './actions';

export function* recipientsRootSaga() {
  yield takeLatestSafe(USER_PROFILE_LOADED, userProfileLoadedSaga);
}

function* userProfileLoadedSaga() {
  yield put(asAction(getRecipients()));
}

import classNames from 'classnames';
import PropTypes from 'prop-types';
import { PureComponent } from 'react';

import InputLabel from 'components/ui/input/label';

class InputWithLabel extends PureComponent {
  static propTypes = {
    childId: PropTypes.string,
    children: PropTypes.node.isRequired,
    label: PropTypes.string,
    help: PropTypes.node,
    className: PropTypes.string,
    inputClassName: PropTypes.string,
    labelSize: PropTypes.number,
    inputSize: PropTypes.number,
    isValid: PropTypes.bool,
  };

  static defaultProps = {
    childId: null,
    help: null,
    labelSize: 4,
    inputSize: 8,
    isValid: true,
  };

  render() {
    const {
      childId,
      children,
      label,
      help,
      className,
      inputClassName,
      labelSize,
      inputSize,
      isValid,
    } = this.props;

    const validInputSize =
      inputSize + labelSize > 12 ? 12 - labelSize : inputSize;

    const inputClassNames = {
      [`col-xs-${validInputSize}`]: true,
    };

    return (
      <div
        data-testid={`input-with-label-${childId}`}
        className={classNames('InputWithLabel', 'row', className, {
          'FormField--raguelError': isValid === false,
        })}
      >
        <div className={`col-xs-${labelSize}`}>
          <InputLabel
            id={`${childId}-label`}
            label={label}
            help={help}
            htmlFor={childId}
          />
        </div>
        <div className={classNames(inputClassNames, inputClassName)}>
          {children}
        </div>
      </div>
    );
  }
}

export default InputWithLabel;

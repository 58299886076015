import { takeLatest } from 'redux-saga/effects';

import {
  ADD_RECIPIENT,
  POLL_GENERIC_IMPORT_REPORT,
  POLL_IMPORT_REPORT,
} from '../actions/types';

import { fetchRecipientsRequiredFields } from './fetch-recipients-fields';
import {
  manageGenericImportReport,
  manageImportReport,
} from './poll-import-report';

export default function* importModalMainSaga() {
  yield takeLatest(POLL_IMPORT_REPORT, manageImportReport);
  yield takeLatest(POLL_GENERIC_IMPORT_REPORT, manageGenericImportReport);

  yield takeLatest(ADD_RECIPIENT, fetchRecipientsRequiredFields);
}

import { bool, func, number, oneOfType, string } from 'prop-types';

import { Checkbox } from '@alkem/react-ui-checkbox';

import ListTableCell from 'core/modules/list/components/table/cell';

export default function CatalogCheckboxColumn({
  id,
  checked,
  disabled,
  onChange,
}) {
  return (
    <ListTableCell className="CatalogCheckboxColumn" center>
      <Checkbox
        id={`catalog-row-checkbox-${id}`}
        checked={checked}
        disabled={disabled}
        onChange={onChange}
      />
    </ListTableCell>
  );
}

CatalogCheckboxColumn.propTypes = {
  id: oneOfType([number, string]).isRequired,
  checked: bool,
  disabled: bool,
  onChange: func.isRequired,
};

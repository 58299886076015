import classNames from 'classnames';
import { number, oneOfType, string } from 'prop-types';

import WithReferential from 'core/modules/with-referential';

import './index.scss';

export default function ListReferentialCell({ data }) {
  return (
    <div className={classNames('ListReferentialCell')}>
      <WithReferential slug="targetmarkets">
        {({ entitiesById }) => (
          <span> {entitiesById.getIn([+data, 'label'])}</span>
        )}
      </WithReferential>
    </div>
  );
}

ListReferentialCell.className = 'ListReferentialCell';
ListReferentialCell.propTypes = {
  data: oneOfType([string, number]),
};

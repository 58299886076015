import classNames from 'classnames';
import { isEqual } from 'lodash';
import { connect } from 'react-redux';
import { createStructuredSelector } from 'reselect';

import { AddButton } from '@alkem/react-ui-button';
import { Ellitips } from '@alkem/react-ui-ellitips';
import { InputDisabled } from '@alkem/react-ui-inputs';

import Field, { FieldProps } from 'components/ui/form/field';
import { cleanFieldLabel } from 'components/ui/form/field/utils/clean';
import {
  enrichChildFields,
  filterChildFields,
} from 'components/ui/form/field/utils/filter';
import {
  cleanChildFields,
  getDefaultValueWithChildren,
} from 'components/ui/form/field/utils/seed';
import FormGroup from 'components/ui/form/form-group';
import Raguel from 'components/ui/form/plugins/validator';
import {
  DataOpsPatchesState,
  selectDataOpsPatch,
  selectDataOpsPatches,
} from 'modules/data-ops';
import i18n from 'utils/i18n';

import './list.scss';

class FormList extends Field<
  { patches: DataOpsPatchesState },
  { deletionMade?: boolean }
> {
  multiLevel = false;
  multipleChildrenContainerClass =
    'offset-xs-1 col-xs-11 FormList__list--multipleChildren';

  constructor(props) {
    super(props, {});
    // This is needed to allow deleting the remaining seeded value in some edge cases.
    this.state.deletionMade = false;
    this.onAddItemClick = this.onAddItemClick.bind(this);
  }

  componentDidUpdate() {
    this.ensureDataIsPresent();
  }

  shouldComponentUpdate(nextProps, nextState) {
    if (this.cleanList(nextProps.value)) {
      return false;
    }
    return super.shouldComponentUpdate(nextProps, nextState);
  }

  getSingleChild(children?) {
    const { field } = this.props;
    const localChildren = children || field.children;
    const filteredChildren = localChildren.filter(
      (f) => !(!f || f.options?.visible === false),
    );
    return filteredChildren.length === 1 ? filteredChildren[0] : null;
  }

  hasSingleChild() {
    return !!this.getSingleChild();
  }

  hasSingleItem() {
    return this.props.field?.options?.singleItem;
  }

  onAddItemClick() {
    this.addItem(this.props);
  }

  onChangeField(newValue, isDirty = true, ignoreField = true) {
    this.dispatchChange(newValue, isDirty, ignoreField);
  }

  addItem(props, isDirty = true) {
    const { value, field } = props;
    const newValue = value ? [...value] : [];
    newValue.push(getDefaultValueWithChildren(field, null, this.multiLevel));
    this.onChangeField(newValue, isDirty);
  }

  onDeleteItem = (index) => () => {
    const { value } = this.props;
    const newValue = [...value];
    newValue.splice(index, 1);

    this.onChangeField(newValue);

    if (!this.state.deletionMade && newValue.length > 0) {
      this.setState({ deletionMade: true });
    }
  };

  ensureDataIsPresent() {
    const changed = super.ensureDataIsPresent();
    if (changed && this.state.deletionMade) {
      this.setState({ deletionMade: false });
    }
  }

  cleanList(value) {
    const { field } = this.props;
    const newValue = value ? [...value] : [];

    let clean;
    clean = cleanChildFields(field, newValue);

    for (let i = 0; i < newValue.length; i += 1) {
      let j = 0;
      while (j < i) {
        if (isEqual(newValue[i], newValue[j])) {
          newValue.splice(j, 1);
          j -= 1;
          clean = true;
        }
        j += 1;
      }
    }

    if (clean) {
      this.onChangeField(newValue);
    }
    return clean;
  }

  renderItems(hasSingleChild, seededItemIndex) {
    const {
      entity,
      value,
      field,
      entityKind,
      entityId,
      validate,
      extraParams,
      patches,
    } = this.props;

    if (!value || !value.length) {
      return null;
    }

    const {
      onChange,
      noDispatch,
      entityIndex,
      flags,
      patch,
      isDisabled,
      disableDataOps,
      recipientId,
      entityPermissions,
    } = extraParams || {};

    // `value` holds the list of already selected items
    const noRepetition = field.options && field.options.noRepetition;
    let excludeList = noRepetition ? [...value] : [];
    if (
      field.model === 'isSubstitutableWith' ||
      field.model === 'isComplementaryWith'
    ) {
      excludeList = excludeList
        .map((item) => item.targetProduct)
        .filter((item) => !!item)
        .map((item) => {
          const item_ = { ...item };
          item_.key = item_.id;
          return item_;
        });
    }
    return value.map((_item, i) => {
      const localKey = `FormList__${field.model}-${i}`;
      const enrichedFields = enrichChildFields(field, i, excludeList);
      const filteredFields = this.filterDisplayableFields(
        enrichedFields,
        patches,
        entity,
        field,
      );
      const cleanedFields = hasSingleChild
        ? filteredFields.map((f) => cleanFieldLabel(f))
        : filteredFields;
      const dg = {
        kind: 'DisplayGroup',
        items: cleanedFields,
      };

      const hideDeleteButton =
        i === seededItemIndex && i === 0 && !this.state.deletionMade;

      // Special case for declinable sub field.
      const singleChild = hasSingleChild
        ? this.getSingleChild(cleanedFields)
        : null;

      const declinableSingleChild =
        singleChild && 'declinableBy' in singleChild;

      // If multiple elements, only display once.
      const shouldInstantiateRaguel =
        hasSingleChild &&
        validate &&
        i === value.length - 1 &&
        !declinableSingleChild;

      const raguelModel =
        (singleChild &&
          singleChild.declinableBy &&
          `${singleChild.model}.0.data`) ||
        (singleChild && singleChild.model);

      const renderedItem = [
        <div className="row FormList__item" key={localKey}>
          <div className="col-xs-12 FormList__itemContent">
            <FormGroup
              entity={entity}
              entityKind={entityKind}
              entityId={entityId}
              entityPermissions={entityPermissions}
              excludeList={excludeList}
              formGroup={dg}
              borders={!hasSingleChild}
              condensed
              validate={validate && (!hasSingleChild || declinableSingleChild)}
              onChangeField={onChange}
              noDispatch={noDispatch}
              entityIndex={entityIndex}
              flags={flags}
              patch={patch}
              disableDataOps={disableDataOps}
              isDisabled={isDisabled}
            />
            {this.renderDeleteButton(i, hideDeleteButton)}
          </div>
        </div>,
      ];
      if (shouldInstantiateRaguel) {
        renderedItem.push(
          <Raguel
            entityId={entityId}
            entityKind={entityKind}
            label={field.label}
            model={raguelModel}
            value={value}
            onJudgmentDay={this.onRaguelJudgment}
            readOnly={this.isReadOnly()}
            key="raguel"
            recipientId={recipientId}
          />,
        );
      }
      return renderedItem;
    });
  }

  filterDisplayableFields(
    enrichedFields,
    patches: DataOpsPatchesState,
    entity: { isDirty?: boolean; source: any } | undefined,
    field: FieldProps['field'],
  ) {
    const overrides = enrichedFields.reduce((acc, enrichedField) => {
      const sourceName =
        enrichedField?.filterApplicableCondition?.conditionSource;
      if (!sourceName) {
        return acc;
      }
      const rootSourceName = sourceName.split('.')[0];
      const conditionSourcePatch = selectDataOpsPatch(
        [this.props.entityId, rootSourceName],
        patches,
      );
      const patchData = conditionSourcePatch?.data?.[0];
      if (patchData) {
        acc[rootSourceName] = patchData.dataNew;
      }
      return acc;
    }, {});
    const filteredFields = filterChildFields(
      entity,
      field,
      enrichedFields,
      overrides,
    );
    return filteredFields;
  }

  renderAddButton(hasSingleChild, seededItemIndex) {
    const { field, value } = this.props;
    if (this.isReadOnly() || this.hasSingleItem()) {
      return null;
    }
    if (field.validators && value) {
      const validator = field.validators.find(
        (e) => e.kind === 'maxLengthList',
      );
      if (validator && value.length >= validator.arg) {
        return null;
      }
    }
    const disabled = seededItemIndex !== -1;
    const label = disabled
      ? i18n.t('frontproductstream.list.add_button.disabled_label', {
          defaultValue: 'Please modify the last item before adding another',
        })
      : i18n.t('frontproductstream.list.add_button.label', {
          defaultValue: 'Add item',
        });
    return (
      <div className="row">
        <div className={hasSingleChild ? 'col-xs-12' : 'offset-xs-4 col-xs-8'}>
          <div className="FormList__addButton">
            <AddButton
              label={
                <Ellitips
                  id={`ellitips-new-element-${this.getId()}`}
                  label={label}
                />
              }
              onClick={this.onAddItemClick}
              disabled={disabled}
            />
          </div>
        </div>
      </div>
    );
  }

  renderDeleteButton(index, isHidden) {
    if (
      this.isReadOnly() ||
      (this.hasSingleItem() && this.props.value?.length < 2)
    ) {
      return null;
    }
    const removeButtonClassNames = {
      FormList__deleteButton: true,
      'FormList__deleteButton--hidden': isHidden,
    };
    return (
      <div
        className={classNames(removeButtonClassNames)}
        onClick={this.onDeleteItem(index)}
        data-testid={`Item-Delete-Button-${index}`}
      >
        <i className="mdi mdi-delete" />
      </div>
    );
  }

  renderSingleChild(seededItemIndex) {
    return (
      <div id={this.getId()} className="col-xs-8 FormList__list--singleChild">
        {this.renderItems(true, seededItemIndex)}
        {this.renderAddButton(true, seededItemIndex)}
        {this.renderPlugins()}
      </div>
    );
  }

  renderMultipleChildren(seededItemIndex) {
    return (
      <div className="col-xs-12">
        <div className="row">
          <div
            id={this.getId()}
            className={this.multipleChildrenContainerClass}
          >
            {this.renderItems(false, seededItemIndex)}
            {this.renderPlugins({ offset: true })}
            {this.renderAddButton(false, seededItemIndex)}
          </div>
        </div>
      </div>
    );
  }

  renderEmptyReadOnly() {
    return (
      <div className="col-xs-8 InputField__input">
        <InputDisabled id={this.getId()} />
        {this.renderPlugins({ offset: true })}
      </div>
    );
  }

  render() {
    const { field, value } = this.props;
    if (!field) {
      return null;
    }

    // Check if the seeded data has been modified.
    const defaultValue = getDefaultValueWithChildren(field);
    const seededItemIndex = value
      ? value.findIndex((e) => isEqual(e, defaultValue))
      : -1;

    let content;
    if (this.isReadOnly() && (!value || !value.length)) {
      content = this.renderEmptyReadOnly();
    } else if (this.hasSingleChild()) {
      content = this.renderSingleChild(seededItemIndex);
    } else {
      content = this.renderMultipleChildren(seededItemIndex);
    }

    return (
      <div className={classNames(this.getClasses({ 'FormList row': true }))}>
        {this.renderLabel('col-xs-4')}
        {content}
      </div>
    );
  }
}

const mapStateToProps = createStructuredSelector({
  patches: selectDataOpsPatches,
});

export { FormList };
export default connect(mapStateToProps)(FormList);

import { get } from 'lodash';
import { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { Navigate, useSearchParams } from 'react-router-dom';

import { hasViewSourcing } from 'core/api/organization-settings';
import { getOrganizationSettings, isRetailer } from 'core/api/user';
import { selectIsAuthorized, selectUser } from 'modules/user';
import { fetchProductVersions } from 'resources/searchApi';
import * as routes from 'routes';

export function Landing() {
  const user = useSelector(selectUser);
  const isAuthorized = useSelector(selectIsAuthorized);
  const [searchParams] = useSearchParams();

  const [redirectTo, setRedirectTo] = useState<string>('');

  useEffect(() => {
    const nextLocation = searchParams.get('next');
    let nextRedirectTo: string = '';

    if (isAuthorized === false) {
      nextRedirectTo = routes.login;
    } else if (nextLocation && nextLocation.split('?')[0] !== routes.landing) {
      nextRedirectTo = nextLocation;
    } else if (
      user &&
      isRetailer(user) &&
      hasViewSourcing(getOrganizationSettings(user))
    ) {
      const handleSourcingRetailerRedirection = async () => {
        // We want to redirect retailer with no product and sourcing
        // to product network page
        try {
          const response = await fetchProductVersions({
            limit: 0,
            withSourceInclude: false,
          });
          setRedirectTo(
            get(response, 'total') === 0
              ? routes.productNetwork
              : routes.catalog,
          );
        } catch (e) {
          setRedirectTo(routes.catalog);
        }
      };
      handleSourcingRetailerRedirection();
    } else {
      nextRedirectTo = routes.catalog;
    }

    if (nextRedirectTo) {
      setRedirectTo(nextRedirectTo);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return redirectTo ? <Navigate to={redirectTo} /> : null;
}

import PropTypes from 'prop-types';

import ListTableCell from 'core/modules/list/components/table/cell';

import './index.scss';

export default function CatalogTargetStatusBarColumn({ status }) {
  return (
    <ListTableCell
      className={`ProductStatusBar StatusBar StatusBar--${status}`}
    >
      <div className="ProductStatusBar StatusBar" />
    </ListTableCell>
  );
}

CatalogTargetStatusBarColumn.propTypes = {
  status: PropTypes.string,
};

import PropTypes from 'prop-types';
import { PureComponent } from 'react';
import ImmutablePropTypes from 'react-immutable-proptypes';
import { connect } from 'react-redux';
import { createStructuredSelector } from 'reselect';

import { Button } from '@alkem/react-ui-button';

import { AnchoredSection } from 'modules/anchored';
import { deleteSharingUnit } from 'modules/sharing-units/actions';
import SharingUnit from 'modules/sharing-units/components/sharing-unit';
import {
  SHARING_UNIT_STATUS_INIT,
  SHARING_UNIT_STATUS_REFUSED,
} from 'modules/sharing-units/constants';
import { getSharingUnitTitle } from 'modules/sharing-units/utils/view';
import { selectOrganization } from 'modules/user';
import i18n from 'utils/i18n';

import { selectSharingunitsMap } from '../selectors';

const mapStateToProps = createStructuredSelector({
  organization: selectOrganization,
  sharingUnitsMap: selectSharingunitsMap,
});

const mapDispatchToProps = { onDeleteSharingUnit: deleteSharingUnit };

export class RetailerSharingUnits extends PureComponent {
  static propTypes = {
    // own props
    applyRequestsInProgress: PropTypes.bool,
    collapsed: PropTypes.bool,
    id: PropTypes.string,
    isNew: PropTypes.bool,
    isReadOnly: PropTypes.bool,
    onAccept: PropTypes.func,
    onRefuse: PropTypes.func,
    order: PropTypes.number.isRequired,
    sharingUnitIds: ImmutablePropTypes.list.isRequired,
    // sharingUnitsMap: ImmutablePropTypes.map.isRequired,

    // props from store
    organization: PropTypes.object.isRequired,
    sharingUnitsMap: ImmutablePropTypes.map.isRequired,

    // action dispatchers
    onDeleteSharingUnit: PropTypes.func.isRequired,
    hasFeatureReviewEngine: PropTypes.bool,
  };

  static defaultProps = {
    applyRequestsInProgress: false,
    collapsed: true,
    id: 'RetailerSharingUnit',
    isNew: false,
    isReadOnly: false,
    onAccept() {},
    onRefuse() {},
    hasFeatureReviewEngine: false,
  };

  refuse = (sharingunitId) => () => {
    this.props.onRefuse(sharingunitId);
  };

  accept = (sharingunitId) => () => {
    this.props.onAccept(sharingunitId);
  };

  processSharingUnit(sharingUnit) {
    if (sharingUnit.get('status') !== SHARING_UNIT_STATUS_REFUSED) {
      return sharingUnit;
    }
    return sharingUnit.set('data', sharingUnit.getIn(['lastRequest', 'data']));
  }

  presenter(hasFeatureReviewEngine, sharingUnitIds, sharingUnitsMap, isNew) {
    const viewModel = {
      sections: [],
    };
    for (let [index, sharingunitId] of sharingUnitIds.entries()) {
      const section = {
        sectionName: null,
        sharingunitId: {},
        sharingUnit: {},
      };

      const sharingUnit = this.processSharingUnit(
        sharingUnitsMap.get(sharingunitId.toString()),
      );
      //UTMB-378 we have to hide listing when review engine is enabled and sharing unit status is INIT
      if (
        !hasFeatureReviewEngine ||
        sharingUnit.get('status') !== SHARING_UNIT_STATUS_INIT
      ) {
        const defaultName = isNew
          ? `${i18n.t('New listing')} - ${index + 1}`
          : `${i18n.t('Listing')} - ${index + 1}`;
        section.sectionName = getSharingUnitTitle(sharingUnit, defaultName);
        section.sharingunitId = sharingunitId;
        section.sharingUnit = sharingUnit;
        viewModel.sections.push(section);
      }
    }
    return viewModel;
  }

  render() {
    const {
      id,
      sharingUnitsMap,
      sharingUnitIds,
      isNew,
      order,
      collapsed,
      isReadOnly,
      organization,
      applyRequestsInProgress,
      onDeleteSharingUnit,
      hasFeatureReviewEngine,
    } = this.props;

    const viewModel = this.presenter(
      hasFeatureReviewEngine,
      sharingUnitIds,
      sharingUnitsMap,
      isNew,
    );

    if (viewModel.sections.length > 0) {
      return (
        <div className="SharingUnits AnchoredBlock" id={id}>
          {viewModel.sections.map((section, index) => {
            return (
              <AnchoredSection
                collapsible
                collapsed={collapsed}
                section={section.sectionName}
                id={`listing-${section.sharingunitId}`}
                key={`listing-${section.sharingunitId}`}
                order={order + index}
                is={'string'} // to make react.render works during tests
              >
                <SharingUnit
                  sharingUnit={section.sharingUnit}
                  retailer={organization}
                  deleteSharingUnit={onDeleteSharingUnit}
                  isTarget
                  readOnly
                  is={'string'} // to make react.render works during tests
                />
                {isNew && (
                  <div className="RetailerProductPage__buttonBar">
                    <Button
                      id={`retailer-listing-refuse-${section.sharingunitId}`}
                      link
                      onClick={this.refuse(section.sharingunitId)}
                      content={i18n.t('Refuse this listing')}
                      disabled={isReadOnly || applyRequestsInProgress}
                    />
                    <Button
                      id={`retailer-listing-accept-${section.sharingunitId}`}
                      primary
                      onClick={this.accept(section.sharingunitId)}
                      content={i18n.t('Accept this listing')}
                      disabled={isReadOnly || applyRequestsInProgress}
                    />
                  </div>
                )}
              </AnchoredSection>
            );
          })}
        </div>
      );
    } else {
      return null;
    }
  }
}

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(RetailerSharingUnits);

import classNames from 'classnames';
import { List, Map } from 'immutable';

import i18n from 'utils/i18n';
import { get } from 'utils/immutable';
import { toPercentage } from 'utils/number';

import './index.scss';

interface Props {
  data: Map<string, number>;
  countPath: string;
  totalPath: string;
  colorRange?: List<number>;
  className?: string;
}

export default function PercentageCell({
  data,
  countPath,
  totalPath,
  colorRange,
  className,
}: Props) {
  const count = get(data, countPath);
  const total = get(data, totalPath);

  const { percentage, quality } = computePercentageAndQuality(
    count,
    total,
    colorRange,
  );

  const hasResult = percentage >= 0;
  return (
    <div
      className={classNames('PercentageCell', className)}
      data-testid="percentage-cell"
      data-quality={quality}
    >
      <div
        className={classNames(
          'PercentageCell__percentage',
          quality && `PercentageCell__percentage--${quality}`,
        )}
      >
        {hasResult ? `${percentage}%` : '-'}
      </div>
      <div className="PercentageCell__count">
        {hasResult ? `${count} / ${total}` : i18n.t('N.A.')}
      </div>
    </div>
  );
}

export function computePercentageAndQuality(count, total, colorRange?) {
  const { isInteger } = Number;
  let percentage = -1;
  let quality = 'neutral';

  if (isInteger(count) && isInteger(total) && total > 0) {
    percentage = Math.min(toPercentage(count, total) as number, 100);

    if (colorRange && colorRange.size >= 2) {
      const goodValue = colorRange.get(0);
      const mediumValue = colorRange.get(1);
      const reverse = goodValue < mediumValue;

      if (reverse) {
        if (percentage <= goodValue) {
          quality = 'good';
        } else if (percentage <= mediumValue) {
          quality = 'medium';
        } else if (percentage > mediumValue) {
          quality = 'low';
        }
      } else {
        // eslint-disable-next-line no-lonely-if
        if (percentage >= goodValue) {
          quality = 'good';
        } else if (percentage >= mediumValue) {
          quality = 'medium';
        } else if (percentage < mediumValue) {
          quality = 'low';
        }
      }
    }
  }

  return { percentage, quality };
}

import { createAction } from 'utils/redux';

import {
  ADD_RECIPIENTS,
  DEACTIVATE_GROUPS,
  FETCH_SUMMARY,
  OPEN_UNSHARE_PRODUCT,
  PREPARE_FOR_GROUP,
  UNSHARE_PRODUCT,
  UNSHARE_PRODUCT_DONE,
  VALIDATE_PRODUCT,
} from './events';

export const addRecipients = createAction(ADD_RECIPIENTS);
export const fetchSummary = createAction(FETCH_SUMMARY);

export const deactivateGroups = (groupIds) => ({
  type: DEACTIVATE_GROUPS,
  groupIds,
});

export const validateProduct = (recipientIds) => ({
  type: VALIDATE_PRODUCT,
  recipientIds,
});

export const prepareForGroup = (groupId, prepare) => ({
  type: PREPARE_FOR_GROUP,
  groupId,
  prepare,
});

export const openUnshareProduct = createAction(OPEN_UNSHARE_PRODUCT);
export const unshareProduct = createAction(UNSHARE_PRODUCT);
export const unshareProductDone = createAction(UNSHARE_PRODUCT_DONE);

import { isNotThirdParty } from 'core/api/user';
import { selectHasMessageCatalogFeature } from 'modules/feature-flag/selectors';

export const messageFilter = {
  key: 'alkemics.message.status',
  query: 'message',
  isAvailable: ({ user } = {}) =>
    isNotThirdParty(user) && selectHasMessageCatalogFeature(user),
  customField: 'recipients_message_status',
  extraKeys: ['alkemics.message'],
};

import i18n from 'utils/i18n';

export const fallbackLocale = {
  id: 75,
  normalizedCode: 'fr',
  code: 'fra-FR',
  label: i18n.t('frontproductstream.constants.localization.french', {
    defaultValue: 'French',
  }),
};

export const rightToLeftLocales = ['ar', 'he'];

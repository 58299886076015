import classNames from 'classnames';
import PropTypes from 'prop-types';
import { Component } from 'react';

import './label.scss';

export const LABEL_DEFAULT = 'default';
export const LABEL_IN_PROGRESS = 'inprogress';
export const LABEL_SUCCESS = 'success';
export const LABEL_WARNING = 'warning';
export const LABEL_DRAFT = 'draft';

export default class Label extends Component {
  static propTypes = {
    children: PropTypes.oneOfType([
      PropTypes.element,
      PropTypes.array,
      PropTypes.string,
    ]).isRequired,
    type: PropTypes.oneOf([
      LABEL_IN_PROGRESS,
      LABEL_DEFAULT,
      LABEL_SUCCESS,
      LABEL_WARNING,
      LABEL_DRAFT,
    ]),
    filled: PropTypes.bool,
    className: PropTypes.string,
  };

  static defaultProps = {
    type: LABEL_IN_PROGRESS,
    filled: false,
  };

  shouldComponentUpdate(nextProps) {
    const { children, type, filled } = this.props;
    return (
      children !== nextProps.children ||
      type !== nextProps.type ||
      filled !== nextProps.filled
    );
  }

  render() {
    const { type, filled, children, className } = this.props;
    const cn = classNames({
      Label: true,
      [`Label--${type}`]: true,
      'Label--filled': filled,
      ...(className && { [className]: true }),
    });

    return <span className={cn}>{children}</span>;
  }
}

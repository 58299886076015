import Modal from 'components/ui/modal';
import i18n from 'utils/i18n';

interface Props {
  onClose(): void;
  onConfirm(): void;
}

export function PriceWaterfallDeleteModal({ onClose, onConfirm }: Props) {
  return (
    <Modal
      className="RfpAnswerDeleteModal"
      modalStyle="dynamic"
      title={i18n.t(
        'frontproductstream.price_waterfalls.collapsible_pricewaterfall_delete_modal.title',
        { defaultValue: 'Deleting a price waterfall' },
      )}
      confirmButtonText={i18n.t(
        'frontproductstream.price_waterfalls.collapsible_pricewaterfall_delete_modal_confirm.button',
        { defaultValue: 'Delete' },
      )}
      onConfirm={onConfirm}
      onClose={onClose}
    >
      <div>
        {i18n.t(
          'frontproductstream.price_waterfalls.collapsible_pricewaterfall_delete_modal.confirm_message',
          {
            defaultValue:
              'Are you sure you want do delete this price waterfall ?',
          },
        )}
      </div>
      <div>
        {i18n.t(
          'frontproductstream.price_waterfalls.collapsible_pricewaterfall_delete_modal.information',
          {
            defaultValue:
              "By deleting the price waterfall of the display unit, all the children's price waterfall will be deleted as well",
          },
        )}
      </div>
    </Modal>
  );
}

/**
 * Helper functions for validation, sharing units and logistical units.
 */
import { List, fromJS } from 'immutable';
import { isUndefined } from 'lodash';

import { ENTITY_TYPE_RECIPIENT } from 'constants/entities';
import { ENTITY_TYPE_LOGISTICAL_HIERARCHY_UNIT } from 'modules/logistical-hierarchies/constants';
import { formatLogisticalUnitEntityId } from 'modules/logistical-hierarchies/helpers';
import { NEW_TO_OLD_FIELD_NAME_MAPPING } from 'modules/validation';

export const isRuleApplicableForRootField = (fieldName) => (rule) =>
  (rule.get('root_fields') || []).includes(fieldName);

export function processSharingUnitRules(rules) {
  const indexPattern = /^sharingUnits\.(\d+)\..*$/;
  const fieldNamePattern = /^(sharingUnits)\.\d+\.data\.(.*)$/;
  return processRules(rules, indexPattern, fieldNamePattern);
}

export function processRfpAnswerRules(rules) {
  const indexPattern = /^answers\.(\d+)\..*$/;
  const fieldNamePattern = /^(answers)\.\d+\.data\.(.*)$/;
  return processRules(rules, indexPattern, fieldNamePattern);
}

export function processLogisticalUnitRules(rules) {
  const indexPattern = /^(?:logisticalHierarchies|hierarchyGtin)\.(\d+).*$/;
  // This looks like:
  //  - `logisticalHierarchies.{index}` 1 times
  //  - `children.{index}.` 0 or more times
  //  - `version.` 0 or 1 times
  //  - the real path we are interested in.
  const fieldNamePattern =
    /^(logisticalHierarchies)\.\d+(?:\.)?(?:children\.\d+\.)*(?:version\.)?(.*)$/;
  const fieldGtinPattern = /^hierarchyGtin\.(\d+)\.(\d+)$/;
  return processRules(rules, indexPattern, fieldNamePattern, fieldGtinPattern);
}

export function getUnitsIndexes(rule, pattern) {
  // extracts index `1` from `sharingUnits.1.compaignName`
  const paths = rule
    .get('paths')
    .toList()
    .flatten()
    .filter((p) => p.match(pattern));
  return paths.map((path) => path.replace(pattern, '$1')).toSet();
}

export function replaceMigratedMediaFieldsInRule(rule) {
  const updatedRule = { ...rule };
  Object.keys(NEW_TO_OLD_FIELD_NAME_MAPPING).forEach((newField) => {
    Object.keys(updatedRule.paths).forEach((key) => {
      updatedRule.paths[key] = updatedRule.paths[key].map((path) =>
        path.replace(
          new RegExp(`${NEW_TO_OLD_FIELD_NAME_MAPPING[newField]}$`),
          newField,
        ),
      );
    });
  });
  return updatedRule;
}

export function cleanPaths(index, fieldNamePattern, fieldGtinPattern) {
  return (rule) => {
    // tranforms `sharingUnits.0.campaignName` to `campaignName` and `sharingUnits`
    // tranforms `logisticalHierarchies.0.campaignName` to `campaignName` and `logisticalHierarchies`
    // tranforms `hierarchyGtins.0.03010617421003` to `03010617421003`
    let cleanedPaths = rule.get('paths').map((paths) =>
      paths
        .map((path) => path.replace(fieldNamePattern, '$1'))
        .concat(paths.map((path) => path.replace(fieldNamePattern, '$2')))
        .toSet(),
    );

    let gtins = rule.getIn(['paths', 'failed_gtins']);
    if (fieldGtinPattern && gtins) {
      gtins = gtins
        .map((path) =>
          path.replace(fieldGtinPattern, (_, i, gtin) =>
            +i === +index ? gtin : null,
          ),
        )
        .filter((gtin) => gtin); // removing null values
      cleanedPaths = cleanedPaths.set('failed_gtins', gtins);
    }

    return rule.set('paths', cleanedPaths);
  };
}

function duplicateThoseWithMultipleIndexes(rules, pattern) {
  const hasIndex = (path, index) => path.replace(pattern, '$1') === index;

  return rules // eg for su: looks like [ rule-A-for-su-1, rule-B-for-su-1 and 2, ...]
    .map((rule) => {
      const indexes = getUnitsIndexes(rule, pattern);
      if (indexes.size > 1) {
        return indexes
          .map((index) =>
            rule
              .get('paths')
              .map((pathlist) =>
                pathlist.filter((path) => hasIndex(path, index)),
              ),
          )
          .map((pathByIndex) => rule.set('paths', pathByIndex));
      }
      return List([rule]); // eg for su: looks like [ [rules-A-for-su-1], [rules-B-for-su-1, rules-B-for-su-2], ...]
    })
    .flatten(1);
}

function processRules(
  rules,
  indexPattern,
  fieldNamePattern,
  fieldGtinPattern = null,
) {
  // when rule contains paths for n elements, makes n - 1 other rules
  const deduped = duplicateThoseWithMultipleIndexes(rules, indexPattern);

  // group by element index
  const groupedByIndex = deduped
    .groupBy((rule) => getUnitsIndexes(rule, indexPattern).first())
    .filter((v, k) => !isUndefined(k));

  return groupedByIndex.mapEntries(([index, v]) => [
    index,
    v.map(cleanPaths(index, fieldNamePattern, fieldGtinPattern)),
  ]);
}

/*
  Duplicate the hierarchy rules to have one rule per tuple (rootInternalId, gtin).
  This tuple will the the entityId for that rule.
*/
/**
 * @param {any} rules
 * @returns {any}
 */
export function duplicateHierarchyRules(rules) {
  let duplicatedRules = List();
  rules.forEach((hierarchyBatch) => {
    const rootInternalId = hierarchyBatch.getIn(['entity', 'id']);
    (hierarchyBatch.get('rules') || List()).forEach((r) => {
      (r.getIn(['paths', 'failed_gtins']) || List()).forEach((gtin) => {
        const rule = r
          .set('entityType', ENTITY_TYPE_LOGISTICAL_HIERARCHY_UNIT)
          .set('entityId', formatLogisticalUnitEntityId(rootInternalId, gtin))
          .deleteIn(['paths', 'failed_gtins']);
        duplicatedRules = duplicatedRules.push(rule);
      });
    });
  });
  return duplicatedRules.groupBy((r) => r.get('entityId'));
}

/*
  Extract the dimension/weight rules of hierarchy rules.
*/
export const duplicateSpecialRulesForHierarchy = (
  groupedRules,
  fieldName,
  entityType,
) =>
  groupedRules
    .map((byEntity) =>
      byEntity
        .setIn(['entity', '_type'], entityType)
        .set('fields', fromJS([fieldName]))
        .update('rules', (rules) =>
          rules
            .filter(isRuleApplicableForRootField(fieldName))
            .map((rule) => rule.set('paths', fromJS({ z: [fieldName] }))),
        ),
    )
    .filter((byEntity) => byEntity.get('rules').size > 0);

/*
  Split sharing unit and tariffs rules in 2:
    - those related to the template;
    - those retailed to the sharing units.
 */
export function splitSharingUnitRules(rules) {
  let templateRules = List();
  let sharingUnitRules = List();
  rules.forEach((rule) => {
    const indexPattern = /^sharingUnits\.(\d+)\..*$/;
    const suPaths = getUnitsIndexes(rule, indexPattern);
    if (suPaths.size > 0) {
      sharingUnitRules = sharingUnitRules.push(rule);
    } else {
      templateRules = templateRules.push(rule);
    }
  });
  return {
    templateRules,
    sharingUnitRules: processSharingUnitRules(sharingUnitRules),
  };
}

/*
  Duplicate the rules related to a recipient.
*/
/**
 * @param {any} rules
 * @returns {any}
 */
export function duplicateRecipientRules(rules) {
  let duplicatedRules = List();
  rules.forEach((rule) => {
    const failing_organization_ids =
      rule.getIn(['paths', 'target_organization_ids']) || List();
    failing_organization_ids.forEach((oId) => {
      const newRule = rule
        .set('entityType', ENTITY_TYPE_RECIPIENT)
        .set('entityId', oId)
        .deleteIn(['paths', 'target_organization_ids']);
      duplicatedRules = duplicatedRules.push(newRule);
    });
  });
  return duplicatedRules.groupBy((r) => r.get('entityId'));
}

import * as SegmentConsentManager from '@segment/consent-manager';
import {
  ReactNode,
  createContext,
  useCallback,
  useMemo,
  useState,
} from 'react';

import { getAppConfig } from 'utils/app-config';
import { logError } from 'utils/logging';

import { ConsentManagerData } from '../types';

const defaultProviderData = {
  destinations: [],
  setPreferences: () => {},
  saveConsent: () => {},
  preferences: {},
} as any as ConsentManagerData;

const { Provider, Consumer } =
  createContext<ConsentManagerData>(defaultProviderData);

export const ConsentManagerConsumer = Consumer;

const getSegmentConfig = () => getAppConfig().segment || { enabled: false };

export default function ConsentManagerBuilder({
  children,
}: {
  children: ReactNode;
}) {
  const [isSegmentEnabled, segmentToken] = useMemo(() => {
    const config = getSegmentConfig();
    if (config.enabled) {
      const token = config.clientToken;
      if (global.analytics) {
        (global.analytics as any)._writeKey = token;
      }
      return [true, token];
    }
    return [false, ''];
  }, []);

  const [segmentError, setSegmentError] = useState();

  const onSegmentError = useCallback((error) => {
    logError('@segment/consent-manager failed to initialize integrations');
    setSegmentError(error);
  }, []);

  if (!isSegmentEnabled || segmentError) {
    return (
      <Provider value={{ ...defaultProviderData, isTrackingReviewed: true }}>
        {children}
      </Provider>
    );
  }

  return (
    <SegmentConsentManager.ConsentManagerBuilder
      writeKey={segmentToken}
      onError={onSegmentError}
    >
      {(props) => (
        <Provider
          value={{
            ...props,
            isTrackingReviewed: props.destinations.every(
              (destination) => destination.id in props.preferences,
            ),
          }}
        >
          {children}
        </Provider>
      )}
    </SegmentConsentManager.ConsentManagerBuilder>
  );
}

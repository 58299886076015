import { debounce } from 'lodash';
import { isFunction } from 'lodash/fp';

import { OrganizationNetworkId } from '@alkem/lib-front-model';
import { TrackingActionType } from '@alkem/micro-frontend-tools';

import {
  MANUFACTURER_TYPE,
  PRIVATE_LABEL,
  RETAILER_TYPE,
  THIRD_PARTY_TYPE,
} from 'constants/types';
import {
  getLegalOrganizationName,
  getOrganization,
  getOrganizationId,
  getOrganizationNetworkId,
  getOrganizationSource,
  getOrganizationTargetMarketIds,
  getOrganizationType,
  getUserId,
  isAdmin,
  isUserAlkemics,
} from 'core/api/user';
import referentialApi from 'resources/referentialApi';
import { UserImmutable } from 'types';
import { getAppConfig } from 'utils/app-config';
import { getHostname } from 'utils/location';
import { logError } from 'utils/logging';
import storage from 'utils/storage';

let commonData: {
  admin_id?: any;
  user_connect_as?: any;
  organization_network_id?: number;
  [key: string]: any;
} = {};
let integrations;
export const defaultIntegrations = {
  All: true,
  HubSpot: false,
  Slack: false,
  Webhooks: false,
};

async function fetchReferential() {
  if (integrations) {
    return integrations;
  }
  try {
    const response = await referentialApi.ReferentialGetList(
      'segmentschemacontrols',
    );
    integrations = response.data.data.reduce(
      (accu, elem) =>
        Object.assign(accu, {
          [elem.get('code')]: elem.get('data').toJS(),
        }),
      {},
    );
  } catch {
    integrations = defaultIntegrations;
  }
  return integrations;
}

const isAnalyticsEnabled = () =>
  getAppConfig().segment?.enabled &&
  global.analytics &&
  [
    global.analytics.identify,
    global.analytics.page,
    global.analytics.track,
  ].every(isFunction);

const idIdentified = () => 'user_id' in commonData;

export const trackHubspot = () =>
  !commonData.user_connect_as &&
  commonData.organization_network_id === OrganizationNetworkId.PRODUCTION;

export const getIntegrations = (eventCode: string) => ({
  ...defaultIntegrations,
  ...(integrations[eventCode] || {}),
  ...(!trackHubspot() ? { HubSpot: false } : {}),
});

export const getOrgType = (orgTypeId: number) => {
  // See https://github.com/alkemics/lib-python-model/blob/d04d0335072cac973a8eb3d391e1bc1fd47344d3/alk_core/model/constant/organization.py#L33-L42
  switch (orgTypeId) {
    case RETAILER_TYPE:
      return 'Retailer';
    case MANUFACTURER_TYPE:
      return 'Manufacturer';
    case PRIVATE_LABEL:
      return 'Private label';
    case THIRD_PARTY_TYPE:
      return 'Third party';
    case 5:
      return 'White label';
    default:
      return 'Unknown';
  }
};

/*
 * Update tracking properties like userName, userKind and so on
 * @param {Immutable} user
 */
export const updateTracking = async (user: UserImmutable) => {
  try {
    if (!isAnalyticsEnabled()) {
      return;
    }
    const orgInfo = {
      organization_id: getOrganizationId(user),
      organization_name: getLegalOrganizationName(user),
      organization_network_id: getOrganizationNetworkId(user),
      organization_type: getOrgType(getOrganizationType(user)),
      organization_source: getOrganizationSource(user),
      organization_status: getOrganization(user).get('status'),
      organization_target_market_id:
        getOrganizationTargetMarketIds(user).first(),
      plan: [
        `org-id-${getOrganizationId(user)}`,
        `org-type-${getOrganizationType(user)}`,
        `target-market-${getOrganizationTargetMarketIds(user).first()}`,
      ], // plan is needed for elev.io segmentation,
    };
    const userInfo = {
      email: user.get('username'),
      id: getUserId(user),
      user_admin: isAdmin(user),
      user_alkemist: isUserAlkemics(user),
      user_createdat: user.get('createdAt'),
      user_email: user.get('username'),
      user_firstname: user.get('firstname'),
      user_id: getUserId(user),
      user_job_title: user.get('jobTitle'),
      user_language: user.get('language'),
      user_lastname: user.get('lastname'),
      user_phone: user.get('phonenumber'),
      user_status: user.get('status'),
      user_type: user.get('type'),
      user_hash: user.get('intercom_user_hash'),
    };
    const identifyInfo = { ...orgInfo, ...userInfo };
    commonData = {
      ...identifyInfo,
      admin_id: user.getIn(['loggedAs', 'adminId']),
      user_connect_as: !!user.getIn(['loggedAs', 'adminId']),
    };
    storage.removeItem('ajs_user_traits');
    await fetchReferential();
    global.analytics.identify(userInfo.id, identifyInfo, {
      // Prevent hubspot from using Identify with userInfo.
      integrations: { ...getIntegrations('IDENTIFY'), HubSpot: false },
    });
    if (trackHubspot()) {
      global._hsq = global._hsq || [];
      global._hsq.push(['identify', { email: userInfo.email }]);
    }
  } catch (err) {
    logError(err);
  }
};

/*
 * Track pageView
 * @param {String} location of the page like /product/03663215000110/general
 */
export const pageview = async (
  location: string,
  path: string,
  pathParams: { [key: string]: any } = {},
) => {
  try {
    if (!isAnalyticsEnabled() || !idIdentified()) {
      return;
    }
    const title = path;
    let filledPath = path;
    Object.keys(pathParams).forEach((key) => {
      filledPath = filledPath.replace(`:${key}`, pathParams[key]);
    });
    await fetchReferential();
    if (trackHubspot()) {
      global._hsq = global._hsq || [];
      global._hsq.push(['setPath', filledPath]);
    }
    global.analytics.page(
      title,
      {
        ...commonData,
        path: filledPath,
        title,
        url: location.split('#')[0],
      },
      {
        integrations: getIntegrations(`PAGE_${title}`),
      },
    );
  } catch (err) {
    logError(err);
  }
};

/*
 * Execute a tracking command which performs a request to the tracking API.
 * @param eventModel params to be tracked
 */
export const track = async (
  { action, ...props }: TrackingActionType = { action: '' },
) => {
  try {
    if (!isAnalyticsEnabled() || !action) {
      return;
    }
    const hashParts = document.location.hash.split('?');
    const page = {
      path: hashParts[0].replace('#', ''),
      referrer: document.referrer,
      search: hashParts[1] || '',
      url: getHostname(),
    };
    const data = { ...commonData, ...props, action };
    await fetchReferential();
    global.analytics.track(action, data, {
      context: { page },
      integrations: getIntegrations(`TRACK_${action}`),
    } as SegmentAnalytics.SegmentOpts);
  } catch (err) {
    logError(err);
  }
};

export const trackFilters = debounce(
  (filters: any, { category, action }: TrackingActionType = { action: '' }) => {
    function stringifyValue(value: string[] | { value: any } | any) {
      if (Array.isArray(value)) {
        return value.join();
      }
      if (typeof value === 'object' && 'value' in value) {
        return stringifyValue(value.value);
      }
      return value;
    }
    try {
      if (!isAnalyticsEnabled() || !filters || !category || !action) {
        return Promise.resolve();
      }
      const query = {};
      Object.keys(filters).forEach((key) => {
        const entity = filters[key];
        if (Array.isArray(entity) && entity.length) {
          query[key] = entity.map((obj) => stringifyValue(obj.value)).join();
        } else if (entity && 'value' in entity) {
          query[key] = stringifyValue(entity.value);
        }
      });
      return track({
        action,
        category,
        label: Object.keys(query)
          .map((key) => `${key}=${query[key]}`)
          .join('&'),
      });
    } catch (err) {
      logError(err);
    }
    return Promise.resolve();
  },
  2000,
);

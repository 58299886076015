import moment from 'moment';
import { PropTypes, shape } from 'prop-types';
import { PureComponent } from 'react';
import { connect } from 'react-redux';

import HelpTooltip from '@alkem/react-ui-helptooltip';

import {
  STATUS_ACCEPTED,
  STATUS_PENDING,
  STATUS_REFUSED,
} from 'constants/assignation';
import { getSupplierId } from 'core/api/retailerproductversion';
import {
  fetchSupplier,
  getRefusedInformation,
  openModal,
} from 'modules/catalog-requests-actions/actions';
import { MODAL_REFUSED_BY_RECIPIENT } from 'modules/catalog-requests-actions/constants';
import i18n from 'utils/i18n';
import { get } from 'utils/immutable';
import { separateActions } from 'utils/redux';

import { selectHasInformationRequest } from '../../../../../selectors/index';
import { getAnswerData } from '../utils';

import './index.scss';

const mapDispatchToProps = {
  openModal,
  fetchSupplier,
  getRefusedInformation,
};

const mapStateToProps = (state) => ({
  hasInformationRequest: selectHasInformationRequest(state),
});

export class CatalogAssignationDateColumn extends PureComponent {
  static propTypes = {
    actions: shape({
      openModal: PropTypes.func,
      fetchSupplier: PropTypes.func,
      getRefusedInformation: PropTypes.func,
    }),
    productVersion: PropTypes.object,
    data: PropTypes.object,
    productVersionId: PropTypes.number.isRequired,
    hasInformationRequest: PropTypes.bool,
  };

  static className = 'CatalogAssignationDateCell';

  constructor(props) {
    super(props);
    this.renderers = {
      [STATUS_REFUSED]: this.renderRefused.bind(this),
      [STATUS_ACCEPTED]: this.renderAccepted.bind(this),
      [STATUS_PENDING]: this.renderPending.bind(this),
    };
  }

  onOpenModal = () => {
    const { actions, productVersion } = this.props;
    const responses = get(productVersion, 'assignationEvents');
    const gtin = get(productVersion, [
      'specializes',
      'isIdentifiedBy',
      '0',
      'reference',
    ]);
    const refusedRecipients = responses.map((response) => {
      const [answerMessage, answerReason] = getAnswerData(response);
      return {
        organizationName: get(response, ['targetOrganization', 'name']),
        creationDate: moment(get(response, 'createdAt')).format(
          'YYYY/MM/DD HH:mm',
        ),
        answerMessage: answerMessage,
        answerReason: answerReason,
        organizationId: get(response, ['targetOrganization', 'id']),
      };
    });
    actions.getRefusedInformation(refusedRecipients, gtin);

    actions.fetchSupplier(getSupplierId(productVersion));

    actions.openModal(MODAL_REFUSED_BY_RECIPIENT);
  };

  renderTooltip() {
    const { data } = this.props;
    const [answerMessage] = getAnswerData(data);

    if (!answerMessage) {
      return null;
    }

    const message = (
      <>
        <p className="CatalogAssignationDateColumn--small">
          {i18n.t('Manufacturer message')}
        </p>
        <p>{answerMessage}</p>
      </>
    );

    return (
      <HelpTooltip
        id={`tooltip-assignation-date-${this.props.productVersionId}`}
        message={message}
        icon="email"
        extraClass="CatalogAssignationDateColumn--helpIcon"
      />
    );
  }

  renderRefused() {
    // date displayed : updatedAt
    const { data, hasInformationRequest, productVersionId } = this.props;
    return (
      <div className="CatalogAssignationDateColumn">
        <div className="CatalogAssignationDateColumn__left">
          <span className="CatalogAssignationDateColumn--small CatalogAssignationDateColumn--rejected">
            {i18n.t('Rejected by ')}
          </span>
          <div className="CatalogAssignationDateColumn--organisation CatalogAssignationDateColumn--bold">
            {get(data, ['targetOrganization', 'name'])}
            {this.renderTooltip()}
          </div>
          <div>
            {i18n.t('On the ')}
            {moment(get(data, 'updatedAt')).format('L')}
            {hasInformationRequest && (
              <i
                key={productVersionId}
                data-testid={`mdi-open-${productVersionId}`}
                className="mdi mdi-open-in-new CatalogAssignationDateColumn__open alk-clickable"
                onClick={this.onOpenModal}
              />
            )}
          </div>
        </div>
      </div>
    );
  }

  renderAccepted() {
    // date displayed : updatedAt
    const { data } = this.props;
    const [answerMessage] = getAnswerData(data);
    return answerMessage ? (
      <div className="CatalogAssignationDateColumn">
        <div className="CatalogAssignationDateColumn--archived CatalogAssignationDateColumn__left">
          <div>
            <p className="CatalogAssignationDateColumn--bold">
              <i className="mdi mdi-eye-off lifecycleIcon" />
              {i18n.t('Archived on')}
            </p>
            <p>{moment(get(data, 'updatedAt')).format('L')}</p>
          </div>
          <div>{this.renderTooltip()}</div>
        </div>
      </div>
    ) : (
      <div className="CatalogAssignationDateColumn">
        <div className="CatalogAssignationDateColumn__left">
          <span className="CatalogAssignationDateColumn--bold">
            {i18n.t('Accepted on')}
          </span>
          <div>{moment(get(data, 'updatedAt')).format('L')}</div>
        </div>
        <div className="CatalogAssignationDateColumn__right">
          {this.renderTooltip()}
        </div>
      </div>
    );
  }

  renderPending() {
    // date displayed : updatedAt
    const { data } = this.props;

    return (
      <div className="CatalogAssignationDateColumn">
        <div className="CatalogAssignationDateColumn__left">
          <div className="CatalogAssignationDateColumn--bold">
            {i18n.t('Last request on')}
          </div>
          <div>{moment(get(data, 'updatedAt')).format('L')}</div>
        </div>
      </div>
    );
  }

  render() {
    const { data } = this.props;
    if (!data) {
      return null;
    }

    const status = get(data, 'status');
    if (!this.renderers[status]) {
      return null;
    }
    return this.renderers[status]();
  }
}

export default connect(
  mapStateToProps,
  mapDispatchToProps,
  separateActions,
)(CatalogAssignationDateColumn);

import classnames from 'classnames';
import { get, noop, set, sortBy, update } from 'lodash/fp';
import { PureComponent } from 'react';

import { Ellitips } from '@alkem/react-ui-ellitips';
import { Radio } from '@alkem/react-ui-inputs';
import { Tooltip } from '@alkem/react-ui-tooltip/src/index';
import { Field } from '@alkem/sdk-dashboard';

import { getInlineValue } from 'components/ui/form/field/utils/inline_value';
import { renderField } from 'components/ui/form/field/utils/render';
import InputWithLabel from 'components/ui/input/input-with-label';
import InputText from 'components/ui/input/text';
import Modal from 'components/ui/modal';
import UnreadCount from 'components/unread-count';
import { parseBoolean } from 'utils';
import { getAsReadOnly } from 'utils/displayGroup';
import i18n from 'utils/i18n';

import './index.scss';

const options = [
  {
    label: i18n.t(
      'Archive this variant, it will still be a variant of this model',
    ),
    value: false,
  },
  {
    label: i18n.t(
      "Remove this variant from the model, it won't be treated as a variant anymore",
    ),
    value: true,
  },
];

interface Props {
  textileVariant: any;
  fields: Field[];
  currentLanguage: any;
  entity: any;
  entityId: number;
  entityKind: string;
  inactive: boolean;
  index: number;
  collapsed: boolean;
  errorCount: number;
  disabled: boolean;
  toggle();
  onDelete();
  onSetInactive();
  onSetActive();
}

interface State {
  removeModalOpen: boolean;
  realDelete: boolean;
}

export default class CollapsibleVariant extends PureComponent<Props, State> {
  state = {
    removeModalOpen: false,
    realDelete: false,
  };

  private onOpenRemoveModal = () =>
    this.setState({ removeModalOpen: true, realDelete: false });
  private onCloseRemoveModal = () =>
    this.setState({ removeModalOpen: false, realDelete: true });

  private onRemove = () => {
    const { onDelete, onSetInactive } = this.props;
    const { realDelete } = this.state;
    this.onCloseRemoveModal();
    if (realDelete) {
      onDelete();
    } else {
      onSetInactive();
    }
  };

  private onUpdateDisplay = (event) => {
    const realDelete = parseBoolean(event.currentTarget.value) as boolean;
    this.setState({ realDelete });
  };

  private renderField = (field: Field) => {
    const { index, inactive, entity, entityId, entityKind, fields } =
      this.props;
    const modelPrefix = `textileVariantList.${index}.textileVariant.version.`;
    let updatedField = set('model', `${modelPrefix}${field.model}`, field);
    if (inactive) {
      updatedField = getAsReadOnly(updatedField);
    }
    // Handle conditional fields.
    // If the condition field is not a variant field, do nothing,
    // otherwise, make it check the variant data.
    const conditionSource = get(
      ['filterApplicableCondition', 'conditionSource'],
      updatedField,
    ) as string;
    if (
      conditionSource &&
      !get(['filterApplicableCondition', 'relativeSource'], updatedField)
    ) {
      const [conditionSourceRootField] = conditionSource.split('.');
      if (fields.find((f) => f.model === conditionSourceRootField)) {
        updatedField = update(
          ['filterApplicableCondition', 'conditionSource'],
          (m) => `${modelPrefix}${m}`,
          updatedField,
        );
      }
    }
    return renderField(
      entity,
      entityKind,
      entityId,
      updatedField.model,
      updatedField,
    );
  };

  private renderNamePublicLong() {
    const { disabled } = this.props;
    let field = {
      kind: 'Field',
      model: 'namePublicLong',
      label: i18n.t('Long name'),
      help: i18n.t('Suggested long trade name.'),
      declinableBy: {
        kind: 'languages',
        model: 'expressedIn',
        url: '/core/v3/referentials/languages',
      },
      inputKind: { kind: 'list', oneLine: true },
      children: [
        {
          kind: 'Field',
          model: 'data',
          inputKind: { kind: 'text' },
        },
        {
          kind: 'Field',
          model: 'expressedIn',
          inputKind: {
            kind: 'autocomplete',
            name: 'languages',
            url: '/core/v3/referentials/languages',
          },
        },
      ],
    };
    if (disabled) {
      field = getAsReadOnly(field);
    }
    return this.renderField(field);
  }

  private renderContent() {
    const { textileVariant, fields, inactive, index, disabled, onSetActive } =
      this.props;
    return (
      <div className="CollapsibleVariant-content">
        <div className="CollapsibleVariant-content__data">
          <InputWithLabel
            childId={`CollapsibleVariant-content__gtin-${index}`}
            label={i18n.t('GTIN')}
            help={i18n.t(
              'Global Trade Item Number (GTIN) is an identifier for trade items. It should be 14 digits long.',
            )}
          >
            <InputText
              value={get('gtin', textileVariant)}
              id={`CollapsibleVariant-content__gtin-${index}`}
              disabled
              onChange={noop}
            />
          </InputWithLabel>
          {this.renderNamePublicLong()}
          {sortBy(get('rank'), fields).map(this.renderField)}
        </div>
        {!disabled && (
          <div className="CollapsibleVariant-content__actions">
            {!inactive && (
              <button
                className="CollapsibleVariant-content__actions"
                type="button"
                onClick={this.onOpenRemoveModal}
              >
                <span
                  data-tip={i18n.t('Remove this variant')}
                  data-for={`textile-variant-${index}-remove`}
                >
                  <i className="mdi mdi-eye-off" />
                  <Tooltip id={`textile-variant-${index}-remove`} />
                </span>
              </button>
            )}
            {inactive && (
              <button
                className="CollapsibleVariant-content__actions"
                type="button"
                onClick={onSetActive}
              >
                <span
                  data-tip={i18n.t('Unarchive this variant')}
                  data-for={`textile-variant-${index}-set-active`}
                >
                  <i className="mdi mdi-eye" />
                  <Tooltip id={`textile-variant-${index}-set-active`} />
                </span>
              </button>
            )}
          </div>
        )}
      </div>
    );
  }

  private renderRemoveModal() {
    const { removeModalOpen, realDelete } = this.state;
    if (!removeModalOpen) {
      return null;
    }
    return (
      <Modal
        modalStyle="dynamic"
        title={i18n.t('Remove this variant')}
        onConfirm={this.onRemove}
        onClose={this.onCloseRemoveModal}
      >
        <Radio
          id="textile-variant-remove-choice"
          value={realDelete}
          onChange={this.onUpdateDisplay}
          options={options}
          vertical
        />
      </Modal>
    );
  }

  public render() {
    const {
      textileVariant,
      fields,
      inactive,
      index,
      collapsed,
      errorCount,
      toggle,
    } = this.props;
    const importantVariantField = fields.filter(
      (f) => f.tags && f.tags.includes('textile_variant_important'),
    );
    return (
      <div className="CollapsibleVariant">
        <div className="CollapsibleVariant-header" onClick={toggle}>
          <div className="alk-flex alk-flex-center alk-flex-margin-horizontal alk-flex-space-between">
            <div>{get('gtin', textileVariant)}</div>
            <div className="alk-flex alk-flex-center alk-flex-margin-horizontal alk-flex-space-between">
              {importantVariantField.map((f) => {
                const value = getInlineValue(
                  f,
                  textileVariant.version,
                  this.props.currentLanguage,
                );
                return (
                  <Ellitips
                    label={value}
                    key={`CollapsibleVariant-header__Content--${index}-${f.model}`}
                    id={`CollapsibleVariant-header__Content--${index}-${f.model}`}
                  />
                );
              })}
            </div>
            <div className="alk-flex alk-flex-center alk-flex-half-margin-horizontal">
              {errorCount > 0 && <UnreadCount count={errorCount} />}
              {inactive && <i className="mdi mdi-eye-off alk-txt-gray-light" />}
              <i
                className={classnames('mdi', {
                  'mdi-chevron-down': collapsed,
                  'mdi-chevron-up': !collapsed,
                })}
              />
            </div>
          </div>
        </div>
        {!collapsed && this.renderContent()}
        {this.renderRemoveModal()}
      </div>
    );
  }
}

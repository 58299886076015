import { Checkbox } from '@alkem/react-ui-checkbox';

import { Derivative } from './types';

export function PictureDerivative({
  derivative,
  checked,
  onChange,
}: {
  derivative: Derivative;
  checked: boolean;
  onChange: (derivativeId: number, checked: boolean) => void;
}) {
  const onChangeLocal = (isChecked: boolean) => {
    onChange(derivative.id, isChecked);
  };
  return (
    <div>
      <Checkbox
        key={derivative.id}
        id={`picture-derivative-${derivative.id}`}
        label={derivative.label}
        checked={checked}
        onChange={onChangeLocal}
      />
    </div>
  );
}

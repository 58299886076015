import PropTypes from 'prop-types';
import { PureComponent } from 'react';
import ImmutablePropTypes from 'react-immutable-proptypes';
import { connect } from 'react-redux';

import {
  getProductProductKeyId,
  getProductVersionId,
  getUserLabels,
} from 'core/api/productversion';
import { hasAnyProductUpdatePermission } from 'core/api/user';
import {
  FEATURE_PERMISSIONS_V3_ORGANIZATION,
  FEATURE_PERMISSIONS_V3_PRODUCT,
} from 'modules/feature-flag/constants';
import { hasPermissionModule, hasPermissionV3 } from 'modules/permissions';
import {
  ORGANIZATION_PERMISSION,
  PRODUCT_PERMISSION,
  USERLABEL_MANAGE_PERMISSION,
} from 'modules/permissions/const';
import { UserLabelModal, showUserLabelModal } from 'modules/user-label';
import i18n from 'utils/i18n';

import '../index.scss';

const mapDispatchToProps = {
  showUserLabelModal,
};

class UserLabelAction extends PureComponent {
  static propTypes = {
    showUserLabelModal: PropTypes.func,
    selectedMap: ImmutablePropTypes.map.isRequired,
    productMap: ImmutablePropTypes.map.isRequired,
    disabled: PropTypes.bool,
  };

  static shouldBeDisplayed = ({ user }) => {
    if (
      hasPermissionV3(user, FEATURE_PERMISSIONS_V3_ORGANIZATION) ||
      hasPermissionV3(user, FEATURE_PERMISSIONS_V3_PRODUCT)
    ) {
      return (
        hasPermissionModule(
          user,
          PRODUCT_PERMISSION,
          USERLABEL_MANAGE_PERMISSION,
        ) ||
        hasPermissionModule(
          user,
          ORGANIZATION_PERMISSION,
          USERLABEL_MANAGE_PERMISSION,
        )
      );
    } else {
      return hasAnyProductUpdatePermission(user);
    }
  };

  constructor(props) {
    super(props);
    this.edit = this.edit.bind(this);
  }

  getLabel(nb) {
    return i18n.t('Edit tags for {{count}} sheet(s)', { count: nb });
  }

  prepareUserLabelProps() {
    const { selectedMap, productMap } = this.props;
    const checkedProducts = selectedMap.reduce((arr, selected, pvId) => {
      if (selected) {
        arr.push(productMap.get(pvId));
      }
      return arr;
    }, []);
    const productVersionIds = [];
    const productKeyIds = [];
    const currentLabels = {};
    const selectedLabelIds = [];
    checkedProducts.forEach((pv) => {
      productKeyIds.push(getProductProductKeyId(pv));
      productVersionIds.push(getProductVersionId(pv));
      const pvLabels = getUserLabels(pv) || [];
      pvLabels.forEach((l) => {
        const labelId = l.get('id').toString();
        if (labelId in currentLabels) {
          currentLabels[labelId] += 1;
        } else {
          currentLabels[labelId] = 1;
        }
      });
    });
    Object.keys(currentLabels).forEach((k) => {
      selectedLabelIds.push({
        id: +k,
        partial: currentLabels[k] !== checkedProducts.length,
      });
    });
    return { productVersionIds, selectedLabelIds, productKeyIds };
  }

  edit() {
    const { disabled } = this.props;
    if (disabled) {
      return;
    }
    const { productVersionIds, selectedLabelIds, productKeyIds } =
      this.prepareUserLabelProps();
    this.props.showUserLabelModal({
      productVersionIds: productVersionIds,
      selectedLabelIds: selectedLabelIds,
      productKeyIds,
    });
  }

  render() {
    const { selectedMap, productMap } = this.props;
    const label = this.getLabel(selectedMap.size);
    const selectedProductVersions = selectedMap
      .filter((isSelected) => isSelected)
      .map((isSelected, pvId) => productMap.get(pvId))
      .toList();
    return (
      <>
        <div className="ActionOption" onClick={this.edit}>
          {label}
        </div>
        <UserLabelModal productVersions={selectedProductVersions} bulk />
      </>
    );
  }
}

export default connect(null, mapDispatchToProps)(UserLabelAction);

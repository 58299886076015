import classNames from 'classnames';
import PropTypes from 'prop-types';
import { Component } from 'react';

import './icon.scss';
import Case from './images/case.png';
import DisplayShipper from './images/display_shipper.png';
import DisplayUnit from './images/display_unit.png';
import Each from './images/each.png';
import Pack from './images/pack.png';
import Pallet from './images/pallet.png';

const icons = {
  EACH: Each,
  PACK: Pack,
  DISPLAY_SHIPPER: DisplayShipper,
  CASE: Case,
  PALLET: Pallet,
  DISPLAY_UNIT: DisplayUnit,
};

class HierarchyIcon extends Component {
  static demoProps = [
    { type: 'EACH', number: 1 },
    { type: 'PACK', number: 100 },
    { type: 'DISPLAY_SHIPPER', number: 10 },
    { type: 'CASE', number: 12 },
    { type: 'PALLET', number: 14 },
  ];

  static propTypes = {
    type: PropTypes.string.isRequired,
    number: PropTypes.number,
    disabled: PropTypes.bool,
  };

  static defaultProps = {
    number: 0,
    disabled: false,
  };

  render() {
    const { number, disabled, type } = this.props;
    const iconClassNames = {
      HierarchyIcon__Icon: true,
      Disabled: disabled,
    };
    iconClassNames[`HierarchyIcon__${type}`] = true;

    const numberCircle = (
      <div className="HierarchyIcon__NumberCircle">{number}</div>
    );

    return (
      <div className={classNames(iconClassNames)}>
        <img className="HierarchyIcon__Image" src={icons[type]} alt="" />
        {number && !disabled ? numberCircle : null}
      </div>
    );
  }
}

export default HierarchyIcon;

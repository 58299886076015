import { debounce } from 'lodash';

import { startLoading, stopLoading } from 'actions/navigation';
import { ENTITY_TYPE_PRODUCTVERSION } from 'constants/entities';
import { selectEditedRoots } from 'modules/logistical-hierarchies/selectors';
import {
  selectIsManufacturer,
  selectIsRetailer,
  selectLocales,
  selectOrganizationId,
} from 'modules/user';
import { VIEW_AS_RECEIVE_RULESETS } from 'modules/view-as/events';
import { selectSelectedRecipientIds } from 'modules/view-as/selectors';
import {
  selectAvailableLanguages,
  selectProductVersionId,
  selectTargetMarketId,
} from 'reducers/productVersion';
import { get } from 'utils/immutable';
import { selectEnrichedData } from 'utils/selectors/product-data';

import { applyRulesForSpecificRecipients } from './common';
import {
  RECEIVE_RULE_RESULTS,
  RECEIVE_RULE_RESULTS_FOR_SELECTED_RECIPIENTS,
} from './events';

const applyRulesForViewAsRecipientsDebounced = debounce(
  async (entityKind, _entityId, dispatch, getState) => {
    dispatch(startLoading());

    const state = getState();
    let recipientIds;
    let languages;
    if (selectIsRetailer(state)) {
      recipientIds = [selectOrganizationId(state)];
      languages = selectLocales(state).toJS();
    } else {
      recipientIds = selectSelectedRecipientIds(state);
      languages = selectAvailableLanguages(state);
    }

    const targetMarketId = selectTargetMarketId(state);
    const filterRecipientsByTargetMarket = selectIsManufacturer(state);
    const results = await dispatch(
      applyRulesForSpecificRecipients({
        entityKind,
        recipientIds,
        withHierarchy: false,
        languages,
        withByLanguages: true,
        targetMarketId,
        filterRecipientsByTargetMarket,
      }),
    );

    dispatch(stopLoading());

    const { data } = selectEnrichedData(state);
    if (!data) {
      return;
    }

    dispatch({
      type: RECEIVE_RULE_RESULTS,
      data: results,
      versionId: get(results, 'entity.id'),
      sharingUnits: data.sharingUnits,
      logisticalUnits: selectEditedRoots(state),
    });
    dispatch({
      type: RECEIVE_RULE_RESULTS_FOR_SELECTED_RECIPIENTS,
      data: results,
      versionId: get(results, 'entity.id'),
    });
    if (results && results.get('ruleSets')) {
      dispatch({
        type: VIEW_AS_RECEIVE_RULESETS,
        ruleSets: results.get('ruleSets'),
      });
    }
  },
  1000,
);

export const applyRulesForViewAsRecipients =
  (entityKind?: string, entityId?: number) => (dispatch, getState) =>
    applyRulesForViewAsRecipientsDebounced(
      entityKind,
      entityId,
      dispatch,
      getState,
    );

export const applyRulesForCurrentProduct = () => (dispatch, getState) => {
  const entityId = selectProductVersionId(getState());
  return applyRulesForViewAsRecipientsDebounced(
    ENTITY_TYPE_PRODUCTVERSION,
    entityId,
    dispatch,
    getState,
  );
};

import asyncComponent from 'core/modules/async/component';
import registerModule from 'core/modules/async/register';

const loginModule = async () =>
  registerModule(await import(/* webpackChunkName: "login" */ './async'));

export const Login = asyncComponent(loginModule, { componentPath: 'Login' });
export const LoginSSO = asyncComponent(loginModule, {
  componentPath: 'LoginSSO',
});
export const LogAs = asyncComponent(loginModule, { componentPath: 'LogAs' });
export const MultipleOrganizations = asyncComponent(loginModule, {
  componentPath: 'MultipleOrganizations',
});

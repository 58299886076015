import PropTypes from 'prop-types';
import { memo, useMemo } from 'react';
import ImmutablePropTypes from 'react-immutable-proptypes';
import { useSelector } from 'react-redux';

import Form from 'components/ui/form';
import { ENTITY_TYPE_PRODUCTVERSION_OVERRIDE } from 'constants/entities';
import { selectDataOpsPatches } from 'modules/data-ops';
import i18n from 'utils/i18n';
import { toJsIfImmutable } from 'utils/immutable';

export const SpecificFields = memo(
  ({ recipient, specificFields, data, currentLanguage, permissions }) => {
    const patches = useSelector(selectDataOpsPatches);

    const entity = useMemo(
      () => ({
        edited: toJsIfImmutable(data) || {},
        permissions,
      }),
      [data, permissions],
    );
    const formGroup = useMemo(
      () => ({
        kind: 'DisplayGroup',
        items: [
          {
            kind: 'DisplayGroup',
            items: toJsIfImmutable(specificFields) || [],
            label: i18n.t(
              'frontproductstream.recipient_specific_block.specific_data.label',
              { defaultValue: 'Specific data' },
            ),
          },
        ],
        label: i18n.t(
          'frontproductstream.recipient_specific_block.recipient_specific_fields.label',
          { defaultValue: 'Recipient specific fields' },
        ),
      }),
      [specificFields],
    );

    if (!specificFields || specificFields.size === 0) {
      return null;
    }

    return (
      <Form
        id={`recipient-specific-fields-${recipient.get('id')}`}
        entity={entity}
        entityKind={ENTITY_TYPE_PRODUCTVERSION_OVERRIDE}
        entityId={recipient.get('id')}
        formGroup={formGroup}
        showTitle={false}
        currentLanguage={currentLanguage}
        patches={patches}
      />
    );
  },
);

SpecificFields.propTypes = {
  recipient: ImmutablePropTypes.map.isRequired,
  currentLanguage: PropTypes.object,
  data: ImmutablePropTypes.map,
  specificFields: ImmutablePropTypes.list,
  permissions: PropTypes.array,
};

export default SpecificFields;

import { connect } from 'react-redux';

import { Button } from '@alkem/react-ui-button';

import i18n from 'utils/i18n';

import { openConsentModal } from '../actions';

interface Props {
  showDetails: typeof openConsentModal;
}

const mapDispatchToProps = { showDetails: openConsentModal };

export function ConsentManagerAdmin({ showDetails }: Props) {
  return (
    <div className="card card-block">
      <h3>{i18n.t('GDPR')}</h3>
      <br />
      <Button onClick={showDetails} link>
        {i18n.t('Follow this link to update your GDPR preferences')}
      </Button>
    </div>
  );
}

export default connect(null, mapDispatchToProps)(ConsentManagerAdmin);

import classNames from 'classnames';
import PropTypes from 'prop-types';

import './status-bar.scss';

export const STATUS_BAR_DEFAULT = 'DEFAULT';
export const STATUS_BAR_NOT_ATTRIBUTED = 'NOT_ATTRIBUTED';
export const STATUS_BAR_ARCHIVED = 'ARCHIVED';
export const STATUS_BAR_ATTRIBUTION_PENDING = 'ATTRIBUTION_PENDING';
export const STATUS_BAR_ACCEPTED = 'ACCEPTED';
export const STATUS_BAR_VALIDATION_PENDING = 'VALIDATION_PENDING';
export const STATUS_BAR_VALIDATED = 'VALIDATED';

export const STATUS_BAR_DANGER = 'DANGER';
export const STATUS_BAR_SUCCESS = 'SUCCESS';
export const STATUS_BAR_PENDING = 'PENDING';
export const STATUS_BAR_UPDATED = 'UPDATED';

const StatusBar = ({ className, type, filter }) => (
  <div
    className={classNames(className, {
      StatusBar: true,
      [`StatusBar--${type}`]: !!type,
      'StatusBar--filter': !!filter,
    })}
  />
);

StatusBar.propTypes = {
  type: PropTypes.string.isRequired,
  filter: PropTypes.bool,
  className: PropTypes.string,
};

export default StatusBar;

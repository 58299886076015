import PropTypes from 'prop-types';

import './index.scss';
import LeftActions from './left';
import RightActions from './right';

export function ListActions({ children }) {
  return <div className="ListActions">{children}</div>;
}

ListActions.propTypes = {
  children: PropTypes.node,
};

export default ListActions;
export const ListLeftActions = LeftActions;
export const ListRightActions = RightActions;

import { isUndefined } from 'lodash';
import PropTypes from 'prop-types';
import { PureComponent } from 'react';
import { CSSTransition } from 'react-transition-group';
import { v4 as uuid } from 'uuid';

import { Tooltip } from '@alkem/react-ui-tooltip';

import './input-validity-hint.scss';

class InputValidityHint extends PureComponent {
  static propTypes = {
    valid: PropTypes.bool,
    message: PropTypes.string,
  };

  constructor(props) {
    super(props);
    this.id = uuid();
  }

  render() {
    const { valid, message } = this.props;
    if (isUndefined(valid) || valid === null) {
      return null;
    } // we don't show anything if no value
    const icon = valid ? 'check' : 'close';
    return (
      <div
        className="InputValidityHint"
        data-tip={message}
        data-for={this.id}
        data-testid="input-validity-hint"
      >
        <CSSTransition in={this.props.valid} timeout={100} classNames="appear">
          <i className={`mdi mdi-${icon}-circle`} role="img" />
        </CSSTransition>
        {message && <Tooltip place="top" id={this.id} />}
      </div>
    );
  }
}

export default InputValidityHint;

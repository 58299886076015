import classNames from 'classnames';
import { debounce } from 'lodash';
import { Fragment, PureComponent } from 'react';
import { connect } from 'react-redux';

import { Radio } from '@alkem/react-ui-inputs';
import { SimpleSelect } from '@alkem/react-ui-select';
import { Referential } from '@alkem/sdk-dashboard';

import { getId } from 'components/ui/form/field/utils/clean';
import Raguel from 'components/ui/form/plugins/validator';
import Helper from 'components/ui/helper';
import InputWithLabel from 'components/ui/input/input-with-label';
import InputText from 'components/ui/input/text';
import { hasFeature } from 'modules/feature-flag';
import {
  FEATURE_PRICEWATERFFAL_ESTIMATION,
  RELEASE_PRICE_WATERFALL_EARLY_PAYMENT_DISCOUNT,
} from 'modules/feature-flag/constants';
import { Bracket, Estimations, Level } from 'modules/price-waterfalls/types';
import { recipientByIdHasSetting } from 'modules/recipients';
import { selectIsLoggedAs } from 'modules/user';
import { parseBoolean } from 'utils';
import i18n from 'utils/i18n';
import { get } from 'utils/immutable';
import storage from 'utils/storage';

import {
  BRACKET_COLUMNS_DISPLAYED,
  LEVEL_TYPE_EARLY_PAYMENT_DISCOUNT_CODE,
  NET_PRICE_WITH_EARLY_DISCOUNT,
  NET_PRICE_WITH_EARLY_DISCOUNT_EXCLUDING_VAT,
  PriceWaterfallDisplayType,
  ROUNDING_RULE_DECIMAL_PLACES,
  ROUNDING_RULE_STORAGE_KEY,
  defaultEarlyPaymentDiscount,
  defaultItemValue,
  defaultPriceLevel,
  levelTypeAllowance,
  levelTypePrice,
  priceGross,
} from '../../constants';
// Inner module
import {
  GET_BRACKET_TYPES,
  GET_LEVEL_TYPES,
  GET_TAX_LABELS,
} from '../../events';
import {
  selectBracketTypeOptions,
  selectLevelItemTypes,
  selectLevelTypes,
  selectTaxInformation,
  selectTaxLabels,
} from '../../selectors';
import { onAddBracket, onRemoveBracket } from '../../utils';
import Brackets from '../brackets';
import PriceWaterfallHeader from '../header';
import AddLine from '../levels/add';
import AllowanceLevel from '../levels/allowance';
import AllowanceHeader from '../levels/allowance/header';
import { EarlyPaymentDiscountRow } from '../levels/early-payment-discount';
import PriceLevel from '../levels/price';
import TaxLevel from '../levels/tax';

import {
  estimatePrices,
  priceRoundingDecimalPlaces,
  priceRoundingDecimalPlacesDefaultIndex,
  priceRoundingRules,
  priceRoundingRulesDefaultIndex,
} from './estimate';
import './price-waterfall.scss';
import ValidateEstimationsButton from './validate-estimations-button';

const options = [
  { label: i18n.t('Yes'), value: true },
  { label: i18n.t('No'), value: false },
];

function handleTaxInformation(props) {
  const { model, value, taxLabels, taxInformation, onUpdate, disabled } = props;
  if (!taxInformation || !taxLabels || disabled) {
    return;
  }
  let newTaxes = [...(value.taxes || [])];
  const info = taxInformation[get(value, ['product', 'id'])];
  if (!info) {
    return;
  }
  const { data } = info;

  let add = false;
  let remove = false;
  let changed = false;
  // Handle new taxes.
  const presentTaxFieldNames = Object.keys(data);
  presentTaxFieldNames.forEach((k) => {
    // If present as an old field, update to use new code.
    // TODO PALPA-899: remove once migrated.
    const oldEntry = (newTaxes || []).find(
      (t) => t.fieldName === taxLabels.oldNames[k],
    );
    if (oldEntry) {
      oldEntry.fieldName = k;
      changed = true;
    } else {
      const entry = (newTaxes || []).find(
        (t) =>
          t.fieldName === k ||
          // TODO PALPA-899: remove once migrated.
          taxLabels.oldNames[t.fieldName] === k,
      );
      if (!entry) {
        add = true;
        newTaxes.push({ fieldName: k });
      }
    }
  });
  newTaxes = newTaxes.filter((t) => {
    if (!presentTaxFieldNames.includes(get(t, 'fieldName'))) {
      remove = true;
      return false;
    }
    return true;
  });
  // Handle taxes no longer filled.
  if (add || remove || changed) {
    onUpdate(`${model}.taxes`, newTaxes);
  }
}

const mapStateToProps = (
  state,
  {
    targetOrganizationId,
    taxInformation,
    entityId,
  }: {
    targetOrganizationId?: number;
    taxInformation?: any;
    entityId?: any;
  },
) => ({
  taxInformation: taxInformation
    ? taxInformation
    : selectTaxInformation(state)({ targetOrganizationId, entityId }),
  taxLabels: selectTaxLabels(state),
  bracketTypeOptions: selectBracketTypeOptions(state),
  hasEstimationFeature:
    !selectIsLoggedAs(state) &&
    hasFeature(state.user, FEATURE_PRICEWATERFFAL_ESTIMATION),
  hasEarlyPaymentDiscountRelease: recipientByIdHasSetting(
    targetOrganizationId,
    RELEASE_PRICE_WATERFALL_EARLY_PAYMENT_DISCOUNT,
  )(state),
  forcePriceDisabled: selectIsLoggedAs(state),
  levelTypes: selectLevelTypes(state),
  levelItemTypes: selectLevelItemTypes(state),
});

interface PriceWaterfallProps {
  model: string;
  forcePriceDisabled?: boolean;
  value?: {
    levels: Level[];
    taxes?: any;
    brackets?: Bracket[];
    bracketUnit?: Referential | null;
    bracketType?: { id: string };
    product?: {
      id: number;
      label: string;
    };
  };
  sharingUnitId?: number;
  taxInformation?: object;
  entityId?: number;
  entityType?: string;
  taxLabels?: {
    fieldNames: { [key: string]: string };
    referentialLabels: { [key: string]: string };
  };
  taxesNotIncludedByDefault?: boolean;
  onDelete?(...args: unknown[]): void;
  onUpdate?(
    model: string,
    value: any,
    _?: any,
    __?: any,
    isDirty?: boolean,
  ): void;
  disabled: boolean;
  targetOrganizationId?: number;
  canDelete?: boolean;
  dispatch?(...args: unknown[]): void;
  label?: {
    sharingUnit_id: number;
    product_id: number;
    label: string;
    present: boolean;
  } | null;
  onUpdateLabel?(...args: unknown[]): void;
  hasEstimationFeature?: boolean;
  bracketTypeOptions?: unknown[];
  editableFirstBracket?: boolean;
  hasEarlyPaymentDiscountRelease?: boolean;
  hasPriceWaterfallTaxesDatesManagementRelease?: boolean;
  displayType?: PriceWaterfallDisplayType;
  levelTypes?: Referential[];
  levelItemTypes?: Referential[];
}

interface PriceWaterfallState {
  bracketStartIndex: number;
  roundingRule: {
    id: string;
    label: string;
    option: string;
  };
  decimalPlaces: {
    id: string;
    label: string;
    option: string;
  };
  estimations: Estimations;
  estimationFailed: boolean;
  showEstimationInCell: boolean;
  raguelStatus: {
    error?: boolean;
    pendingSuggestions?: boolean;
    hideField?: boolean;
  };
  earlyPaymentDiscountAdded?: boolean;
}

export class PriceWaterfall extends PureComponent<
  PriceWaterfallProps,
  PriceWaterfallState
> {
  onEstimateNetPrices: () => void;

  static defaultProps: Partial<PriceWaterfallProps> = {
    hasEstimationFeature: false,
    displayType: 'default',
  };

  constructor(props: PriceWaterfallProps) {
    super(props);

    this.onEstimateNetPrices = debounce(
      this._onEstimateNetPrices.bind(this),
      500,
    );

    const earlyPaymentDiscountAdded = props.value?.levels.some(
      (level) => level.type.code === LEVEL_TYPE_EARLY_PAYMENT_DISCOUNT_CODE,
    );

    this.state = {
      bracketStartIndex: 0,
      roundingRule: priceRoundingRules[priceRoundingRulesDefaultIndex],
      decimalPlaces:
        priceRoundingDecimalPlaces[priceRoundingDecimalPlacesDefaultIndex],
      estimations: {} as Estimations,
      estimationFailed: false,
      showEstimationInCell: false,
      raguelStatus: {},
      earlyPaymentDiscountAdded,
    };
  }

  componentDidMount() {
    if (this.props.dispatch && !this.props.taxLabels) {
      this.props.dispatch({ type: GET_TAX_LABELS });
      this.props.dispatch({ type: GET_BRACKET_TYPES });
    }
    if (!this.props.levelTypes?.length) {
      this.props.dispatch?.({ type: GET_LEVEL_TYPES });
    }
    if (!this.props.disabled && this.props.hasEstimationFeature) {
      this.onSetupCalculator();
    }
  }

  componentDidUpdate(prevProps) {
    handleTaxInformation(this.props);

    if (
      !this.props.disabled &&
      this.props.hasEstimationFeature &&
      (prevProps.value !== this.props.value ||
        prevProps.taxInformation !== this.props.taxInformation)
    ) {
      this.onEstimateNetPrices();
    }
  }

  async _onEstimateNetPrices() {
    const { taxInformation, value } = this.props;
    const { roundingRule, decimalPlaces } = this.state;

    const productId = get(value, ['product', 'id']);
    if (!productId) {
      return;
    }

    let taxes = {};
    if (taxInformation) {
      taxes = taxInformation[productId] || {};
    }

    const { estimations, error } = await estimatePrices(
      value,
      taxes,
      roundingRule,
      decimalPlaces,
    );

    if (error) {
      this.setState({ estimationFailed: true });
    } else if (estimations) {
      this.setState({ estimations, estimationFailed: false });
    }
  }

  onBracketStartIndexChange = (shift) =>
    this.setState((prevState) => ({
      bracketStartIndex: prevState.bracketStartIndex + shift,
    }));

  onDeleteBracket = () => {
    const { model, value, onUpdate } = this.props;
    const newValue = onRemoveBracket(value);
    onUpdate?.(model, newValue);
    if (newValue.brackets.length >= BRACKET_COLUMNS_DISPLAYED) {
      this.onBracketStartIndexChange(-1);
    }
  };

  onAddBracket = () => {
    const { model, value, onUpdate } = this.props;
    const newValue = onAddBracket(value);
    onUpdate?.(model, newValue);
    if (newValue.brackets.length > BRACKET_COLUMNS_DISPLAYED) {
      this.onBracketStartIndexChange(1);
    }
  };

  onDeleteLevel = (index) => {
    const { model, value, onUpdate } = this.props;
    const newLevels = value?.levels.filter((_e, i) => i !== index);
    onUpdate?.(`${model}.levels`, newLevels);
  };

  onUpdateHasLabel = (event) => {
    const { label, onUpdateLabel } = this.props;
    const present = parseBoolean(event.currentTarget.value);
    onUpdateLabel?.({ ...label, present });
  };

  onUpdateLabel = (event) => {
    const { label, onUpdateLabel } = this.props;
    onUpdateLabel?.({ ...label, label: event.currentTarget.value });
  };

  onValidateEstimationsHover = (hover) => {
    this.setState({ showEstimationInCell: hover });
  };

  onSetupCalculator = () => {
    this.setState(
      () => {
        const roundingRuleId = storage.getItem(
          ROUNDING_RULE_STORAGE_KEY,
          priceRoundingRules[priceRoundingRulesDefaultIndex].id,
        );
        const decimalPlacesId = storage.getItem(
          ROUNDING_RULE_DECIMAL_PLACES,
          priceRoundingDecimalPlaces[priceRoundingDecimalPlacesDefaultIndex].id,
        );

        const roundingRule =
          priceRoundingRules.find((r) => r.id === roundingRuleId) ||
          priceRoundingRules[priceRoundingRulesDefaultIndex];
        const decimalPlaces =
          priceRoundingDecimalPlaces.find((d) => d.id === decimalPlacesId) ||
          priceRoundingDecimalPlaces[priceRoundingDecimalPlacesDefaultIndex];
        return {
          roundingRule,
          decimalPlaces,
        };
      },
      () => {
        this.onEstimateNetPrices();
      },
    );
  };

  onSelectRoundingRule = (opt) => {
    storage.setItem(ROUNDING_RULE_STORAGE_KEY, opt.id);

    this.setState({ roundingRule: opt }, () => {
      this.onEstimateNetPrices();
    });
  };

  onSelectDecimalePlaces = (opt) => {
    storage.setItem(ROUNDING_RULE_DECIMAL_PLACES, opt.id);

    this.setState({ decimalPlaces: opt }, () => {
      this.onEstimateNetPrices();
    });
  };

  getDisabledBasedOnDisplayType() {
    const { disabled, displayType } = this.props;
    return (
      disabled || ['fromTariff', 'fromTemplate'].includes(displayType || '')
    );
  }

  renderLevel(level, brackets, levelIndex, description: string | null = null) {
    const {
      model,
      entityId,
      entityType,
      onUpdate,
      disabled,
      targetOrganizationId,
      hasEstimationFeature,
      forcePriceDisabled,
    } = this.props;
    if (!level) {
      return null;
    }

    const { estimations, estimationFailed, showEstimationInCell } = this.state;

    const { items, type } = level;
    if (type.id === levelTypePrice.id) {
      if (!items || items.length === 0) {
        return null;
      }
      const item = items[0]; // only one item for a price type

      return (
        <PriceLevel
          key={get(level, 'uuid')}
          model={`${model}.levels.${levelIndex}.items.0`}
          entityId={entityId}
          entityType={entityType}
          value={item}
          onUpdate={onUpdate}
          brackets={brackets}
          bracketStartIndex={this.state.bracketStartIndex}
          disabled={disabled || forcePriceDisabled}
          hasEstimationFeature={hasEstimationFeature}
          estimations={estimations[item.type?.key]}
          estimationFailed={levelIndex > 0 && estimationFailed}
          showEstimationInCell={showEstimationInCell}
          description={description}
        />
      );
    }
    if (type.id === levelTypeAllowance.id) {
      if (!items || items.length === 0) {
        return null;
      }
      const components = [
        <AllowanceLevel
          key={get(level, 'uuid')}
          entityId={entityId}
          entityType={entityType}
          model={`${model}.levels.${levelIndex}`}
          value={level}
          onUpdate={onUpdate}
          onDeleteLevel={this.onDeleteLevel}
          levelIndex={levelIndex}
          brackets={brackets}
          bracketStartIndex={this.state.bracketStartIndex}
          disabled={this.getDisabledBasedOnDisplayType() || forcePriceDisabled}
          targetOrganizationId={targetOrganizationId}
        />,
      ];
      if (levelIndex === 1) {
        components.unshift(<AllowanceHeader key="header" />);
      }
      return components;
    }
    return null;
  }

  renderTaxes() {
    const {
      model,
      value,
      entityId,
      entityType,
      taxInformation,
      taxLabels,
      onUpdate,
      disabled,
      taxesNotIncludedByDefault,
    } = this.props;
    if (!taxInformation || !taxLabels) {
      return null;
    }

    const { taxes } = value || {};
    const { data = {}, VATRate } =
      taxInformation[get(value, ['product', 'id'])] || {};
    // Map tax data to tax information contained in the product page.
    const mappedTaxData = (taxes || []).map((t) => ({
      value: t,
      taxValue: data[t.fieldName],
      label:
        get(taxLabels.fieldNames, t.fieldName) ||
        get(taxLabels.referentialLabels, t.fieldName),
      includable: true,
    }));
    if (VATRate && get(VATRate, 'data.rate')) {
      mappedTaxData.push({
        value: { fieldName: 'VATrate' },
        taxValue: i18n.t('{{rate}}%', { rate: get(VATRate, 'data.rate') }),
        label: i18n.t('VAT'),
        includable: false,
      });
    }
    if (!mappedTaxData.length) {
      return null;
    }

    return (
      <div className="PriceWaterfall__taxes">
        {mappedTaxData.map((e, i) => (
          <Fragment key={`${model}-tax-${e.value.fieldName}`}>
            {!!e.label && (
              <TaxLevel
                entityId={entityId}
                entityType={entityType}
                value={e.value}
                taxValue={e.taxValue}
                label={e.label}
                model={`${model}.taxes.${i}`}
                onUpdate={onUpdate}
                includable={e.includable}
                disabled={disabled}
                taxesNotIncludedByDefault={taxesNotIncludedByDefault}
              />
            )}
          </Fragment>
        ))}
      </div>
    );
  }

  renderError = () => {
    const {
      model,
      value,
      entityId,
      entityType,
      disabled,
      targetOrganizationId,
    } = this.props;

    const { error, pendingSuggestions, hideField } = this.state.raguelStatus;
    const classes = {
      Raguel__block: error || pendingSuggestions || hideField,
      'FormField--raguelWarning': pendingSuggestions,
      'FormField--raguelError': error,
      'FormField--hideField': hideField,
    };
    return (
      <>
        {!!entityId && (
          <div className={classNames(classes)}>
            <Raguel
              entityId={entityId}
              entityKind={entityType || ''}
              label={i18n.t('Price waterfall')}
              model={model}
              value={value}
              recipientId={targetOrganizationId}
              readOnly={disabled}
            />
          </div>
        )}
      </>
    );
  };

  renderHeader = () => {
    const { value, onDelete, disabled, canDelete, forcePriceDisabled } =
      this.props;
    const { brackets } = value || {};
    if (disabled) {
      return null;
    }

    return (
      <PriceWaterfallHeader
        brackets={brackets}
        canDelete={canDelete}
        onAddBracket={this.onAddBracket}
        onDelete={onDelete}
        disabled={forcePriceDisabled}
      />
    );
  };

  renderBrackets = (disabled = false) => {
    const {
      model,
      value,
      entityId,
      entityType,
      onUpdate,
      bracketTypeOptions,
      editableFirstBracket,
    } = this.props;
    const { brackets, bracketUnit, bracketType } = value || {};
    return (
      <Brackets
        model={model}
        entityId={entityId}
        entityType={entityType}
        value={brackets || []}
        unit={bracketUnit}
        bracketStartIndex={this.state.bracketStartIndex}
        onBracketStartIndexChange={this.onBracketStartIndexChange}
        onUpdate={onUpdate}
        onDeleteBracket={this.onDeleteBracket}
        disabled={this.getDisabledBasedOnDisplayType() || disabled}
        bracketType={bracketType}
        bracketTypeOptions={bracketTypeOptions}
        editableFirstBracket={editableFirstBracket}
      />
    );
  };

  renderGrossPrice = (description: string | null = priceGross.label) => {
    const { value } = this.props;
    const { levels, brackets } = value || {};
    return this.renderLevel(levels?.[0], brackets, 0, description);
  };

  renderNetPrice = () => {
    const { value, levelItemTypes } = this.props;
    const { levels = [], brackets } = value || {};

    return levels
      .slice(1)
      .filter((level) => level.type.id === levelTypePrice.id)
      .map((level) => {
        const levelItem = levelItemTypes?.find(
          (itemType) => itemType.code === level.items[0]?.type?.code,
        );
        let levelDescription;
        if (levelItem) {
          levelDescription = levelItem.label;
        }
        return this.renderLevel(
          level,
          brackets,
          levels.findIndex((l) => l.uuid === level.uuid),
          levelDescription,
        );
      });
  };

  renderAllowances = () => {
    const {
      value,
      model,
      forcePriceDisabled,
      onUpdate,
      hasEarlyPaymentDiscountRelease,
    } = this.props;
    const { levels = [], brackets = [] } = value || {};
    const allowanceLevels =
      levels
        ?.slice(1)
        .filter((item) => item.type.code === levelTypeAllowance.code) || [];

    return (
      <>
        {allowanceLevels?.map((level, index) =>
          this.renderLevel(level, brackets, index + 1),
        )}
        {!this.getDisabledBasedOnDisplayType() && (
          <AddLine
            model={`${model}.levels`}
            levels={levels || []}
            onUpdate={onUpdate}
            numBaseLevels={allowanceLevels.length + 1}
            numBrackets={brackets.length}
            hasEarlyPaymentDiscount={hasEarlyPaymentDiscountRelease}
            onAddEarlyPaymentDiscountSelected={
              this.toggleEarlyPaymentDiscountAdded
            }
            addEarlyPaymentDiscountDisabled={
              this.state.earlyPaymentDiscountAdded
            }
            disabled={forcePriceDisabled}
          />
        )}
      </>
    );
  };

  toggleEarlyPaymentDiscountAdded = () => {
    const { value, onUpdate, model, levelTypes, levelItemTypes } = this.props;
    const { levels = [], brackets = [] } = value || {};

    const type = levelTypes?.find(
      (level) => level.code === LEVEL_TYPE_EARLY_PAYMENT_DISCOUNT_CODE,
    );
    const defaultItemsValuesWithBrackets = brackets.map(() =>
      defaultItemValue(),
    );
    const updates = [...levels, defaultEarlyPaymentDiscount(type, brackets)];

    const netPriceWithEarlyPaymentDiscountExcludingVAT = levelItemTypes?.find(
      (itemType) =>
        itemType.code === NET_PRICE_WITH_EARLY_DISCOUNT_EXCLUDING_VAT,
    );
    if (netPriceWithEarlyPaymentDiscountExcludingVAT) {
      // place it at 2nd position from the bottom of net prices list
      updates.splice(
        -2,
        0,
        defaultPriceLevel(
          netPriceWithEarlyPaymentDiscountExcludingVAT,
          defaultItemsValuesWithBrackets,
        ),
      );
    }

    const netPriceWithEarlyPaymentDiscount = levelItemTypes?.find(
      (itemType) => itemType.code === NET_PRICE_WITH_EARLY_DISCOUNT,
    );
    if (netPriceWithEarlyPaymentDiscount) {
      // place it at last position in prices list
      updates.push(
        defaultPriceLevel(
          netPriceWithEarlyPaymentDiscount,
          defaultItemsValuesWithBrackets,
        ),
      );
    }

    onUpdate?.(`${model}.levels`, updates);

    this.setState({
      earlyPaymentDiscountAdded: !this.state.earlyPaymentDiscountAdded,
    });
  };

  handleEarlyPaymentDiscountDelete = () => {
    const { value } = this.props;
    const { levels = [] } = value || {};
    const levelsWithoutEarlyPaymentDiscount = levels
      .filter(
        (level) =>
          ![LEVEL_TYPE_EARLY_PAYMENT_DISCOUNT_CODE].includes(
            level.type.code || '',
          ),
      )
      .filter(
        (level) =>
          ![
            NET_PRICE_WITH_EARLY_DISCOUNT_EXCLUDING_VAT,
            NET_PRICE_WITH_EARLY_DISCOUNT,
          ].includes(level.items[0]?.type?.code || ''),
      );
    this.toggleEarlyPaymentDiscountAdded();
    this.props.onUpdate?.(
      `${this.props.model}.levels`,
      levelsWithoutEarlyPaymentDiscount,
    );
  };

  renderEarlyPaymentDiscount = () => {
    const {
      hasEarlyPaymentDiscountRelease,
      onUpdate,
      sharingUnitId,
      targetOrganizationId,
      model,
      disabled,
      forcePriceDisabled,
      value,
    } = this.props;
    const { earlyPaymentDiscountAdded } = this.state;
    if (!hasEarlyPaymentDiscountRelease || !earlyPaymentDiscountAdded) {
      return null;
    }
    const { levels = [] } = value || {};
    const earlyPaymentDiscountLevelIndex = levels.findIndex(
      (level) => level.type.code === LEVEL_TYPE_EARLY_PAYMENT_DISCOUNT_CODE,
    );

    return (
      <EarlyPaymentDiscountRow
        model={`${model}.levels.${earlyPaymentDiscountLevelIndex}`}
        disabled={disabled || forcePriceDisabled}
        onUpdate={onUpdate}
        sharingUnitId={sharingUnitId}
        targetOrganizationId={targetOrganizationId}
        onDelete={this.handleEarlyPaymentDiscountDelete}
        value={value?.levels[earlyPaymentDiscountLevelIndex]?.items[0]}
        bracketStartIndex={this.state.bracketStartIndex}
      />
    );
  };

  renderEstimation = () => {
    const {
      model,
      value = {},
      onUpdate,
      disabled,
      hasEstimationFeature,
    } = this.props;

    const { roundingRule, decimalPlaces, estimations, estimationFailed } =
      this.state;
    if (!hasEstimationFeature || disabled) {
      return null;
    }

    return (
      <div className="PriceWaterfall__calculator">
        <div className="PriceWaterfall__calculator__validateAll">
          <ValidateEstimationsButton
            estimations={estimations}
            estimationFailed={estimationFailed}
            model={model}
            value={value}
            onUpdate={onUpdate}
            onHover={this.onValidateEstimationsHover}
          />
        </div>
        <Helper
          left={
            <>
              <i className="mdi mdi-calculator" />
              <span>{i18n.t('Calculator')}</span>
            </>
          }
          right={
            <>
              <div className="PriceWaterfall__calculator__infoBlock">
                <span>{i18n.t('Rounding rule')}</span>
                <SimpleSelect
                  id={`calculator-helper-${model}-rounding-rule`}
                  value={roundingRule}
                  className="InputSelect__select c-select"
                  onSelect={this.onSelectRoundingRule}
                  options={priceRoundingRules}
                />
              </div>
              <div className="PriceWaterfall__calculator__infoBlock">
                <span>{i18n.t('Decimale places')}</span>
                <SimpleSelect
                  id={`calculator-helper-${model}-decimal-places`}
                  value={decimalPlaces}
                  className="InputSelect__select c-select"
                  onSelect={this.onSelectDecimalePlaces}
                  options={priceRoundingDecimalPlaces}
                />
              </div>
            </>
          }
        />
      </div>
    );
  };

  renderLabel = () => {
    const {
      model,
      value,
      entityId,
      entityType,
      disabled,
      label,
      onUpdateLabel,
    } = this.props;
    const { levels } = value || {};
    if (
      !levels ||
      levels.length === 0 ||
      disabled ||
      !label ||
      !onUpdateLabel
    ) {
      return null;
    }

    return (
      <div className="PriceWaterfall__label">
        <InputWithLabel
          childId={getId(`${model}.has-label`, entityType, entityId)}
          label={i18n.t('Do you want to give a label to your pricing?')}
        >
          <Radio
            id={getId(`${model}.has-label`, entityType, entityId)}
            className="FormRadio__radio"
            value={label && label.present}
            onChange={this.onUpdateHasLabel}
            options={options}
            disabled={disabled}
          />
        </InputWithLabel>
        {label && label.present && (
          <InputWithLabel
            childId={
              'InputText-' + getId(`${model}.label`, entityType, entityId)
            }
            label={i18n.t('Label:')}
            help={i18n.t(
              'This label will not be shared with your retailers. An error will raise if you try to save an already used label.',
            )}
          >
            <InputText
              id={getId(`${model}.label`, entityType, entityId)}
              value={label.label}
              onChange={this.onUpdateLabel}
              disabled={disabled}
            />
          </InputWithLabel>
        )}
      </div>
    );
  };

  render = () => {
    const { value, displayType } = this.props;
    const { levels } = value || {};

    if (!levels || levels.length === 0) {
      return null;
    }
    if (displayType === 'tariff') {
      return (
        <div className="PriceWaterfall">
          {this.renderError()}
          {this.renderHeader()}
          {this.renderBrackets()}
        </div>
      );
    }
    if (displayType === 'fromTariff') {
      return (
        <div className="PriceWaterfall">
          {this.renderError()}
          {this.renderBrackets()}
          {this.renderGrossPrice(i18n.t('Base price'))}
        </div>
      );
    }

    if (displayType === 'template') {
      return (
        <div className="PriceWaterfall">
          {this.renderError()}
          {this.renderHeader()}
          {this.renderBrackets()}
          {this.renderAllowances()}
          {this.renderEarlyPaymentDiscount()}
        </div>
      );
    }
    if (displayType === 'fromTemplate') {
      return (
        <div className="PriceWaterfall">
          {this.renderError()}
          {this.renderBrackets()}
          {this.renderGrossPrice()}
          {this.renderAllowances()}
          {this.renderEarlyPaymentDiscount()}
          {this.renderTaxes()}
          {this.renderNetPrice()}
          {this.renderEstimation()}
        </div>
      );
    }
    if (displayType === 'templateSpecificDiscount') {
      return (
        <div className="PriceWaterfall">
          {this.renderError()}
          {this.renderBrackets(true)}
          {this.renderGrossPrice()}
          {this.renderAllowances()}
          {this.renderEarlyPaymentDiscount()}
          {this.renderTaxes()}
          {this.renderNetPrice()}
          {this.renderEstimation()}
        </div>
      );
    }

    return (
      <div className="PriceWaterfall">
        {this.renderError()}
        {this.renderHeader()}
        {this.renderBrackets()}
        {this.renderGrossPrice()}
        {this.renderAllowances()}
        {this.renderEarlyPaymentDiscount()}
        {this.renderTaxes()}
        {this.renderNetPrice()}
        {this.renderEstimation()}
        {this.renderLabel()}
      </div>
    );
  };
}

export const ConnectedPriceWaterfall = connect<any, any, PriceWaterfallProps>(
  mapStateToProps,
)(PriceWaterfall);
export default ConnectedPriceWaterfall;

import Dropdown from 'components/ui/dropdown';
import i18n from 'utils/i18n';

type Props = {
  itemsPerPage?: number;
  options?: number[];
  onChange: (option: number) => void;
};

const ListItemsPerPage = (props: Props) => {
  const { itemsPerPage = 20, options = [20, 50, 100, 250], onChange } = props;

  const onClick = (option) => {
    if (option !== itemsPerPage) {
      onChange(option);
    }
  };

  const selectId = (option) => `list-items-per-page-${option}`;

  const formatLabel = (_itemsPerPage) => {
    return i18n.t('frontproductstream.core.list_items_per_page.items_count', {
      defaultValue: '{{count}} per page',
      count: _itemsPerPage,
    });
  };

  const selectLabel = (option) => formatLabel(option);

  return (
    <Dropdown
      id="reporting-items-per-page"
      label={formatLabel(itemsPerPage)}
      options={options}
      selectKey={selectId}
      selectLabel={selectLabel}
      onClickOption={onClick}
      closeDropdownOnClickElement
      rightDropdown
    />
  );
};

export { ListItemsPerPage };
export default ListItemsPerPage;

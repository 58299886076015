import classNames from 'classnames';

import excelIcon from 'images/icons/excel.png';

import './icon.scss';

export default function ImageIcon({
  className,
  src,
}: {
  className?: string;
  src: string;
}) {
  return (
    <img src={src} className={classNames('ImageIcon', className)} alt="" />
  );
}

export function ImageIconExcel({ className }: { className?: string }) {
  return <ImageIcon src={excelIcon} className={className} />;
}

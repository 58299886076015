import { getFeatureValue } from '.';
import {
  FEATURE_PERMISSION_MANAGEMENT,
  FEATURE_PERMISSION_MANAGEMENT_HIERARCHY,
  FEATURE_PERMISSION_MANAGEMENT_KEYS,
} from './constants';

export function hasHierarchyFeature(user) {
  const featureValue = getFeatureValue(user, FEATURE_PERMISSION_MANAGEMENT);
  if (FEATURE_PERMISSION_MANAGEMENT_KEYS.includes(featureValue)) {
    return featureValue === FEATURE_PERMISSION_MANAGEMENT_HIERARCHY;
  }
  return true;
}

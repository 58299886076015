import { cloneDeep } from 'lodash';
import { v4 as uuid } from 'uuid';

import i18n from 'utils/i18n';
import { get } from 'utils/immutable';

import {
  defaultBracket,
  defaultItemValue,
  levelTypePrice,
  priceGross,
} from './constants';

export function getValueAtIndex(values, index) {
  if (values.length > index && values[index]) {
    return values[index];
  }
  return { uuid: uuid(), value: i18n.t('(empty)') };
}

export function getValue(event) {
  const { value } = event.currentTarget;
  return value.replace(/,/g, '.') || '';
}

const levelTypePath = (levelIdx) => ['levels', levelIdx, 'type', 'id'];
const priceItemTypePath = (levelIdx) => [
  'levels',
  levelIdx,
  'items',
  0,
  'type',
  'id',
];

const valuePath = (levelIdx, bracketIdx) => [
  'levels',
  levelIdx,
  'items',
  0,
  'values',
  bracketIdx,
  'value',
];

const selectPrice = (priceWatefall, levelIdx, wantedItemType, bracketIdx) => {
  const levelType = get(priceWatefall, levelTypePath(levelIdx));
  const levelItemType = get(priceWatefall, priceItemTypePath(levelIdx));

  if (levelType === levelTypePrice.id && levelItemType === wantedItemType) {
    return get(priceWatefall, valuePath(levelIdx, bracketIdx));
  }
  return null;
};

export const selectGrossPriceAtBracket = (priceWatefall, bracketIdx) =>
  selectPrice(priceWatefall, 0, priceGross.id, bracketIdx);

function isPriceValueEncrypted(value) {
  return value && value.length > 40 && isNaN(Number(value));
}

export function displayPriceValue(value) {
  if (isPriceValueEncrypted(value)) {
    return i18n.t(
      'frontproductstream.price_waterfalls.display_price_value.encrypted',
      {
        defaultValue: 'encrypted',
      },
    );
  }
  return value;
}

export function renderPriceValue(value) {
  if (isPriceValueEncrypted(value)) {
    return (
      <span className="alk-txt-muted">
        {i18n.t(
          'frontproductstream.price_waterfalls.display_price_value.encrypted',
          {
            defaultValue: 'encrypted',
          },
        )}
      </span>
    );
  }
  return value;
}

export function onRemoveBracket(priceWaterfall, clone = true) {
  // Copy data
  const newPriceWaterfall = clone ? cloneDeep(priceWaterfall) : priceWaterfall;
  // Remove last bracket and all associated values of each item of each level
  newPriceWaterfall.brackets.pop();
  newPriceWaterfall.levels.forEach((level) =>
    level.items.forEach((item) => item.values.pop()),
  );
  if (newPriceWaterfall.brackets.length === 1) {
    newPriceWaterfall.bracketUnit = null;
    newPriceWaterfall.brackets[0].min = 1;
  }
  return newPriceWaterfall;
}

export function onRemoveAllBrackets(priceWaterfall) {
  if (priceWaterfall.brackets.length === 1) {
    return priceWaterfall;
  }
  // Copy data
  let newPriceWaterfall = cloneDeep(priceWaterfall);
  for (let i = 0; i < priceWaterfall.brackets.length - 1; i += 1) {
    newPriceWaterfall = onRemoveBracket(newPriceWaterfall, false);
  }
  return newPriceWaterfall;
}

export function onAddBracket(priceWaterfall) {
  // Copy data
  const newPriceWaterfall = cloneDeep(priceWaterfall);
  // Add en empty new bracket
  newPriceWaterfall.brackets.push(defaultBracket());
  // Add corresponding value for each item of each level for this bracket
  newPriceWaterfall.levels.forEach((level, i) =>
    level.items.forEach((item) => {
      let v = null;
      if (i === 0 && item.values.length > 0) {
        v = item.values[item.values.length - 1].value;
      }
      item.values.push(defaultItemValue(v));
    }),
  );
  return newPriceWaterfall;
}

export function getDefaultGrossPriceValue(catalogPrice, currencyCode) {
  // Try to extract the gross price from the catalog price, based on the currency
  // used
  if (catalogPrice) {
    const matchingCatalogPrice = catalogPrice.find(
      (cp) => get(cp, ['expressedIn', 'code']) === currencyCode,
    );

    if (matchingCatalogPrice) {
      return `${matchingCatalogPrice.data}`;
    }
  }
  return null;
}

export const findFirstPriceWaterfall = (currentProductId, pricewaterfalls) => {
  if (pricewaterfalls.length === 1) {
    return pricewaterfalls[0];
  }
  return pricewaterfalls.find((pw) => pw.product.id === currentProductId);
};

export const findChildrenPriceWaterfall = (
  currentProductId,
  pricewaterfalls,
) => {
  if (pricewaterfalls.length === 1) {
    return [];
  }
  return pricewaterfalls.filter((pw) => pw.product.id !== currentProductId);
};

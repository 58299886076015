import { get, isEmpty, isUndefined } from 'lodash';
import { flatten } from 'lodash/fp';

export const cleanChildFields = function (field, value) {
  if (!value) {
    return false;
  }
  let clean = false;
  // If the list contains some conditional fields, we need to pop them if not applicable.
  field.children.forEach((f) => {
    if (
      Array.isArray(f.applicableConditionValues) &&
      f.filterApplicableCondition &&
      f.filterApplicableCondition.relativeSource &&
      f.filterApplicableCondition.conditionSource
    ) {
      value.forEach((v_) => {
        const v = v_;
        if (!isUndefined(v[f.model])) {
          const currentCondition = get(
            v,
            f.filterApplicableCondition.conditionSource,
          );
          if (
            f.applicableConditionValues.indexOf(currentCondition) === -1 &&
            !!v[f.model] &&
            !isEmpty(v[f.model])
          ) {
            delete v[f.model];
            clean = true;
          }
        }
      });
    }
  });
  return clean;
};

export const getDefaultValueWithChildren = function (
  field,
  currentLanguage,
  multiLevel,
) {
  const newValue = {};

  if (!field.children) {
    return newValue;
  }

  field.children.forEach((child) => {
    const { defaultValue } = getDefaultValue(
      child,
      newValue[child.model],
      currentLanguage,
      multiLevel,
    );
    newValue[child.model] = defaultValue;
  });
  // We do not want to seed values that are not applicable.
  cleanChildFields(field, [newValue]);
  return newValue;
};

export function getDefaultValue(field, value, currentLanguage, multiLevel) {
  const shouldSeedDefaultValue =
    isUndefined(value) ||
    (get(field, 'inputKind.kind') === 'list' &&
      !['isMadeOf'].includes(get(field, 'model')) &&
      (!get(field, 'model') ||
        !get(field, 'model').endsWith('isPartitionedBy')));
  if (!field || !shouldSeedDefaultValue) {
    return { changed: false };
  }

  let newValue = null;
  let changed = true;

  if (field.inputKind && 'defaultValue' in field.inputKind) {
    if (field.inputKind.referential || field.inputKind.url) {
      newValue = { id: field.inputKind.defaultValue };
    } else {
      newValue = field.inputKind.defaultValue;
    }
  } else if (
    get(field, 'inputKind.kind') === 'textarea' &&
    field.options.richContent
  ) {
    newValue = '';
  } else if (
    [
      'imageCheckbox',
      'labels',
      'multiAutocomplete',
      'string_list',
      'textileVariantStringList',
      'tags',
    ].includes(get(field, 'inputKind.kind')) ||
    (field.model && field.model.endsWith('isPartitionedBy'))
  ) {
    newValue = [];
  } else if (get(field, 'inputKind.kind') === 'list') {
    if (field.declinableBy) {
      const localValue = getDefaultValueForDeclinable(
        field,
        value,
        currentLanguage,
      );
      newValue = localValue.value;
      ({ changed } = localValue);
    } else if (field.inputKind.full) {
      const localValue = getDefaultValueForFullList(
        field,
        value,
        currentLanguage,
        multiLevel,
      );
      newValue = localValue.value;
      ({ changed } = localValue);
    } else {
      const localValue = getDefaultValueForList(
        field,
        value,
        currentLanguage,
        multiLevel,
      );
      newValue = localValue.value;
      ({ changed } = localValue);
    }
  } else if (get(field, 'inputKind.kind') === 'dict') {
    newValue = getDefaultValueWithChildren(field, currentLanguage, multiLevel);
  }

  return { defaultValue: newValue, changed };
}

function getDefaultValueForList(field, value, currentLanguage, multiLevel) {
  if (value && value.length > 0) {
    return { changed: false };
  }
  if (multiLevel) {
    return { value: [], changed: false };
  } else {
    const newValue = value ? [...value] : [];
    newValue.push(
      getDefaultValueWithChildren(field, currentLanguage, multiLevel),
    );
    return { value: newValue, changed: true };
  }
}

function getDefaultValueForFullList(field, value, currentLanguage, multiLevel) {
  const { mainKey } = field.inputKind;
  const { lines } = field;
  let changed = false;
  if (!mainKey || !lines) {
    return { value, changed };
  }
  const newValue = value ? [...value] : [];

  lines.forEach((entity, index) => {
    const match = newValue.find((currentValue) => {
      // This is always a referential entity so check the ID.
      // We need to be careful to avoid an infinite loop if mainKey is incorect.
      if (!(entity && mainKey in currentValue)) {
        return true;
      }
      return currentValue[mainKey] && currentValue[mainKey].id === entity.id;
    });
    const defaultValue = getDefaultValueWithChildren(
      field,
      currentLanguage,
      multiLevel,
    );
    if (!match) {
      // Add an empty item in the list.
      defaultValue[mainKey] = entity;
      newValue.splice(index, 0, defaultValue);
      changed = true;
    } else {
      // We need to check if all the field are filled (even with empty value) for the validation
      Object.keys(defaultValue).forEach((key) => {
        if (!Object.keys(match).includes(key)) {
          match[key] = defaultValue[key];
          changed = true;
        }
      });
    }
  });

  return { value: newValue, changed };
}

function getDefaultValueForDeclinable(field, value, currentLanguage) {
  const oldValue = value || [];
  let newValue = [];
  let changed = false;
  const isDeclinableByLang = field.declinableBy.kind === 'languages';
  if (!isDeclinableByLang && !oldValue.length) {
    const itemForDeclinable = getItemForDeclinable(field);
    if (itemForDeclinable) {
      changed = true;
      newValue = [itemForDeclinable];
    }
  } else if (
    currentLanguage &&
    isDeclinableByLang &&
    oldValue.every((elem) => get(elem, 'expressedIn.id') !== currentLanguage.id)
  ) {
    changed = true;
    // seed expressedIn and empty data if this is declinable by language and current language is not present in value
    // isDirty = false so that this does not appear as a change. Will be effective only if value modified and PV saved
    newValue = [...oldValue, getItemForDeclinable(field, currentLanguage)];
  }

  return { value: newValue, changed };
}

function getItemForDeclinable(field, expressedIn) {
  if (field.children && field.children.length > 0) {
    const dataIndex = field.children[0].model === 'data' ? 0 : 1;
    const { defaultValue } = getDefaultValue(field.children[dataIndex]);
    return { data: defaultValue, expressedIn: expressedIn || null };
  } else {
    return null;
  }
}

export function seedData(
  data,
  displayGroups,
  currentLanguage,
  scopeToFields = [],
  multiLevel,
) {
  if (!displayGroups || !data) {
    return [];
  }
  return flatten(
    displayGroups.map((dg) =>
      seedDatum(data, dg, currentLanguage, scopeToFields, multiLevel),
    ),
  );
}

function seedDatum(data, item, currentLanguage, scopeToFields, multiLevel) {
  if (item.kind === 'DisplayGroup') {
    return flatten(
      item.items.map((i) =>
        seedDatum(data, i, currentLanguage, scopeToFields, multiLevel),
      ),
    );
  } else if (
    item.kind === 'Field' &&
    (!scopeToFields ||
      scopeToFields.length === 0 ||
      scopeToFields.includes(item.model))
  ) {
    const { defaultValue, changed } = getDefaultValue(
      item,
      data[item.model],
      currentLanguage,
      multiLevel,
    );
    if (changed) {
      return [{ key: item.model, value: defaultValue }];
    }
  }
  return [];
}

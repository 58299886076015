import i18n from 'utils/i18n';

// for more information see
// https://alkemics.atlassian.net/wiki/display/TECH/B2B+SharingUnit+-+How+to+design+SharingUnit+workflow

export const draft = {
  name: i18n.t('frontproductstream.sharing_unit_status.draft.label', {
    context: 'SharingUnit',
    defaultValue: 'Draft',
  }),
  className: 'Draft',
};

export const draftUpdate = {
  name: i18n.t(
    'frontproductstream.constants.sharing_unit_status.draft_update',
    { defaultValue: 'Draft update' },
  ),
  className: 'Draft',
};

export const pending = {
  name: i18n.t(
    'frontproductstream.constants.sharing_unit_status.pending_acceptation',
    { defaultValue: 'Pending acceptation' },
  ),
  className: 'Pending',
};

export const updatePending = {
  name: i18n.t(
    'frontproductstream.constants.sharing_unit_status.pending_update',
    { defaultValue: 'Pending update' },
  ),
  className: 'Pending',
};

export const updateRefused = {
  name: i18n.t(
    'frontproductstream.constants.sharing_unit_status.update_refused',
    { defaultValue: 'Update refused' },
  ),
  className: 'Refused',
};

export const accepted = {
  name: i18n.t('frontproductstream.constants.sharing_unit_status.accepted', {
    defaultValue: 'Accepted',
  }),
  className: 'Accepted',
};

export const refused = {
  name: i18n.t('frontproductstream.constants.sharing_unit_status.refused', {
    defaultValue: 'Refused',
  }),
  className: 'Refused',
};

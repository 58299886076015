import { fromJS } from 'immutable';
import { createReducer } from 'redux-create-reducer';

import {
  BULK_ACTION_DONE,
  BULK_ACTION_IN_PROGRESS,
  BULK_ACTION_RESET,
  BULK_ACTION_SHOW,
} from '../actions/types';

export const KEY = 'bulkActionModal';

export const initialState = fromJS({
  visible: false,
  entities: {},
  inProgress: false,
  count: 0,
  status: 'info',
  errors: {},
  success: {},
  done: false,
});

export default createReducer(initialState, {
  [BULK_ACTION_SHOW]: (state, { payload }) =>
    state.withMutations((newState) =>
      newState.set('visible', true).set('entities', payload),
    ),
  [BULK_ACTION_RESET]: () => initialState,
  [BULK_ACTION_IN_PROGRESS]: (state, { payload = {} }) =>
    state.withMutations((newState) => {
      newState
        .set('inProgress', true)
        .set('count', payload.count || initialState.get('count'));
      if (payload.status) {
        if (payload.status.code === 'danger') {
          newState.setIn(['errors', payload.entityId], payload.status.message);
        } else {
          newState.setIn(['success', payload.entityId], payload.status.message);
        }
      }
      newState.set(
        'status',
        newState.get('errors').isEmpty() ? 'success' : 'danger',
      );
      return newState;
    }),
  [BULK_ACTION_DONE]: (state) =>
    state.withMutations((newState) =>
      newState.set('inProgress', false).set('done', true),
    ),
});

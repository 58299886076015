import classNames from 'classnames';
import { concat, flow, negate, set, update } from 'lodash/fp';
import { PureComponent } from 'react';
import Dropzone from 'react-dropzone';
import { connect } from 'react-redux';
import { createStructuredSelector } from 'reselect';

import { Button, SwitchButton } from '@alkem/react-ui-button';
import { Spinner } from '@alkem/react-ui-spinner';

import { notificationError, notificationSuccess } from 'actions/notification';
import Modal from 'components/ui/modal';
import { ASSET_ORIGIN_STREAM_USER } from 'constants/media';
import { selectIsRetailer, selectUser } from 'modules/user';
import { setFilterRuleSetsFields } from 'modules/view-as/actions';
import { selectFilterRuleSetsFields } from 'modules/view-as/selectors';
import mediaApi from 'resources/mediaApi';
import { GlobalState, UserImmutable } from 'types';
import i18n from 'utils/i18n';
import moment from 'utils/moment';

import { DocumentAssetV2 } from '../../AssetV2/types';

import DocumentForm from './document-form';
import './document-modal.scss';

interface Media {
  uniformResourceIdentifier: string;
}

type DocumentInformation = Record<string, any>;

interface ConnectedProps {
  user: UserImmutable;
  filterRuleSetsFields: boolean;
  showAdditionalFieldsSwitch?: boolean;
}

interface OwnProps {
  title: string;
  action: { items?: any[] };
  productKeyId: number;
  onSuccess: () => void;
  onClose: () => void;
  media?: DocumentAssetV2 | null;
  index?: number;
  readOnly?: boolean;
}

type Props = ConnectedProps & OwnProps & { dispatch: (action: any) => void };

interface State {
  loading: boolean;
  uploadedDocs: Media[];
  docInformation: DocumentInformation[];
  isAdding: boolean;
  isDirty: boolean;
  items: {
    name: string;
    defaultValue: any;
  }[];
}

export class DocumentModal extends PureComponent<Props, State> {
  static defaultProps = {
    media: null,
    index: 0,
  };

  static getDerivedStateFromProps({ action }, state) {
    return set(['items'], (action && action.items) || [], state);
  }

  constructor(props) {
    super(props);
    this.updateDocument = this.updateDocument.bind(this);
    this.uploadDocument = this.uploadDocument.bind(this);
    this.state = {
      loading: false,
      items: [],
      uploadedDocs: props.media ? [props.media] : [],
      docInformation: props.media
        ? [
            {
              ...props.media,
              product_key_id: props.productKeyId,
              path: props.media.uniformResourceIdentifier,
            },
          ]
        : [],
      isAdding: false,
      isDirty: false,
    };
  }

  updateField = (index, path, value) => {
    this.setState(update(['docInformation', index], set(path, value)));
  };

  updateFormGroup = (index, model, value, _entityId, _entityKind, isDirty) => {
    if (!this.state.isDirty && isDirty) {
      this.setState(set(['isDirty'], true));
    }
    this.updateField(index, model, value);
  };

  validateDocument = () => {
    const { productKeyId, dispatch } = this.props;
    this.setState(set(['isAdding'], true));
    const promises: any[] = [];
    let promise: any;
    this.state.docInformation.forEach((information, i) => {
      const uploadedDoc = this.state.uploadedDocs[i];

      // Create ProductDocument
      // Import data from form
      const doc = this.fillProductDocument(uploadedDoc, information);

      doc.uniformResourceIdentifier = doc.path;

      // Duplicate content
      doc.product_key_id = productKeyId;
      doc.origin = ASSET_ORIGIN_STREAM_USER.id;

      const payload = doc;
      promise = mediaApi.ProductDocumentCreate(payload).then(
        () => {
          dispatch(
            notificationSuccess(
              i18n.t(
                'frontproducstream.asset_list.document_modal.create_notification.success',
                { defaultValue: 'Document successfully added.' },
              ),
            ),
          );
          this.props.onSuccess();
        },
        () => {
          dispatch(
            notificationError(
              i18n.t(
                'frontproducstream.asset_list.document_modal.create_notification.error',
                {
                  defaultValue: 'An error occured while creating the document.',
                },
              ),
            ),
          );
          this.props.onClose();
        },
      );
      promises.push(promise);
    });
    return promises;
  };

  uploadDocument(docs) {
    // If “Add” button clicked (instead of file dropped), do nothing (wait for
    // actual file feed). In this case `docs` is a SyntheticMouseEvent
    // (instead of an array).
    if (!docs.length) {
      return [Promise.resolve()];
    }
    const { productKeyId, dispatch } = this.props;
    const { items } = this.state;

    this.setState(set(['loading'], true));
    return Promise.all(
      docs.map((file) => {
        const payload = {
          entity_id: productKeyId,
        };
        return mediaApi.ProductDocumentUpload(payload, file).then(
          (response) => {
            const information: DocumentInformation = {};
            items.forEach((form) => {
              information[form.name] = form.defaultValue;
            });
            information.product_key_id = productKeyId;
            information.path = response.data.data.url;
            this.setState(
              flow(
                update(['docInformation'], concat([information])),
                update(['uploadedDocs'], concat([response.data.data])),
                set(['loading'], false),
              ),
            );
          },
          (error) => {
            if (error.status === 415) {
              dispatch(
                notificationError(
                  i18n.t(
                    'frontproducstream.asset_list.document_modal.upload_notification.type_error',
                    {
                      defaultValue:
                        'The type of your document is not yet supported.',
                    },
                  ),
                ),
              );
            } else {
              dispatch(
                notificationError(
                  i18n.t(
                    'frontproducstream.asset_list.document_modal.upload_notification.error',
                    {
                      defaultValue:
                        'An error occured while uploading the document.',
                    },
                  ),
                ),
              );
            }
            this.props.onClose();
            return error;
          },
        );
      }),
    );
  }

  updateDocument() {
    const { dispatch } = this.props;
    this.setState(set(['isAdding'], true));
    // We should only have one document here
    const information = this.state.docInformation[0];
    let doc = this.state.uploadedDocs[0];

    // Import data from form
    doc = this.fillProductDocument(doc, information);
    const payload = doc;
    return mediaApi.ProductDocumentUpdate(payload).then(
      () => {
        dispatch(
          notificationSuccess(
            i18n.t(
              'frontproducstream.asset_list.document_modal.update_notification.sucess',
              { defaultValue: 'Document successfully edited.' },
            ),
          ),
        );
        this.props.onSuccess();
      },
      () => {
        dispatch(
          notificationError(
            i18n.t(
              'frontproducstream.asset_list.document_modal.update_notification.error',
              { defaultValue: 'An error occured while updating the document.' },
            ),
          ),
        );
        this.props.onClose();
      },
    );
  }

  fillProductDocument(doc, information) {
    const filledDoc = { ...doc };
    Object.keys(information).forEach((key) => {
      if (information[key] instanceof Date) {
        // If the Date has not been changed, we have to format it back to YYYY-MM-DD
        filledDoc[key] = moment(information[key]).format('YYYY-MM-DD');
      } else {
        filledDoc[key] = information[key];
      }
    });
    return filledDoc;
  }

  matchTag(tag) {
    // Matches anything that has a non-whitespace character in it
    return /\S/.test(tag);
  }

  renderDocumentList() {
    const { index } = this.props;
    const { docInformation, uploadedDocs, items } = this.state;
    return (
      <DocumentForm
        documentList={docInformation}
        uploadedDocs={uploadedDocs}
        fields={items}
        onChangeField={this.updateFormGroup}
        entityIndex={index}
      />
    );
  }

  renderDocumentDrop() {
    const dropzoneLabel = i18n.t(
      'frontproductstream.download_documents.dropzone.label',
      {
        defaultValue:
          'Only pdf, excel (.xls, .xslx), word (.doc, .docx) and powerpoint (.ppt, .pptx) files can be uploaded. Drop them here.',
      },
    );
    return (
      <Dropzone multiple onDrop={this.uploadDocument}>
        {({ getRootProps, getInputProps, isDragActive }) => (
          <div
            {...getRootProps({
              className: classNames(
                'DocumentModal__dropzone',
                isDragActive && 'DocumentModal__dropzone--active',
              ),
            })}
          >
            {this.state.loading ? (
              <Spinner big />
            ) : (
              <>
                <input {...getInputProps()} />
                <span className="Dropzone__label">{dropzoneLabel}</span>
                <span className="Dropzone__button">
                  <span>
                    {i18n.t(
                      'frontproducstream.asset_list.modal.upload_choice.separator',
                      { defaultValue: 'or' },
                    )}
                  </span>
                  <Button
                    content={i18n.t(
                      'frontproducstream.asset_list.modal.upload_choice.select',
                      { defaultValue: 'select them' },
                    )}
                    onClick={this.uploadDocument}
                    primary
                  />
                </span>
              </>
            )}
          </div>
        )}
      </Dropzone>
    );
  }

  onCheckOptionalField = (checked: boolean) => {
    const { user, dispatch } = this.props;
    dispatch(setFilterRuleSetsFields(!checked, user));
  };

  render() {
    const {
      title,
      media,
      readOnly,
      filterRuleSetsFields = true,
      showAdditionalFieldsSwitch = true,
    } = this.props;
    const { uploadedDocs, isAdding, isDirty } = this.state;
    return (
      <Modal
        title={title}
        modalStyle="fullHeight"
        confirmButtonText={
          media
            ? i18n.t(
                'frontproducstream.asset_list.modal.main_button_save.label',
                { defaultValue: 'Save' },
              )
            : i18n.t(
                'frontproducstream.asset_list.modal.main_button_add.label',
                { defaultValue: 'Add' },
              )
        }
        isProcessing={isAdding}
        onConfirm={media ? this.updateDocument : this.validateDocument}
        onClose={this.props.onClose}
        hideFooter={uploadedDocs.length === 0}
        confirmDisabled={readOnly ? !isDirty : readOnly}
        additionalFooterContent={
          showAdditionalFieldsSwitch && (
            <SwitchButton
              content={i18n.t(
                'frontproducstream.asset_list.modal.show_more_fields_button.label',
                {
                  defaultValue:
                    'Show optional fields to market more efficiently your product',
                },
              )}
              onChange={this.onCheckOptionalField}
              checked={!filterRuleSetsFields}
            />
          )
        }
      >
        <div className="DocumentModal ProductPage__modal">
          {this.state.uploadedDocs.length > 0
            ? this.renderDocumentList()
            : this.renderDocumentDrop()}
        </div>
      </Modal>
    );
  }
}

const mapStateToProps = createStructuredSelector({
  user: selectUser,
  filterRuleSetsFields: selectFilterRuleSetsFields,
  showAdditionalFieldsSwitch: negate(selectIsRetailer),
});

export default connect<ConnectedProps, {}, OwnProps, GlobalState>(
  mapStateToProps,
)(DocumentModal);

import { call, put, select } from 'redux-saga/effects';

import { notificationError, notificationSuccess } from 'actions/notification';
import { SharingUnitTariffsList } from 'routes';
import { push } from 'utils/history';
import i18n from 'utils/i18n';
import { logError } from 'utils/logging';
import { takeEverySafe } from 'utils/saga';

import api from '../api';
import { DELETE, DELETE_FAILURE, DELETE_SUCCESS } from '../constants';
import { selectId } from '../selectors';

export function* watchDelete() {
  yield takeEverySafe(DELETE, deleteTemplate);
}

export function* deleteTemplate() {
  const id = yield select(selectId);

  const { error } = yield call(api.deleteTemplate, id);

  if (!error) {
    yield put({
      type: DELETE_SUCCESS,
    });
    yield call(push, SharingUnitTariffsList);
    yield put(
      notificationSuccess(
        i18n.t(
          'frontproductstream.sharingunit_tariff.delete_notification.success',
          { defaultValue: 'The tariff has been deleted' },
        ),
      ),
    );
  } else {
    yield put(
      notificationError(
        i18n.t(
          'frontproductstream.sharingunit_tariff.delete_notification.error',
          { defaultValue: 'An error occured deleting the tariff' },
        ),
      ),
    );
    yield put({ type: DELETE_FAILURE, error });
    yield call(logError, error);
  }
}

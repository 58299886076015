import { isFunction } from 'lodash';
import memoize from 'memoize-one';

export function scroll({ element, top, left, behavior } = {}) {
  const options = { top, left, behavior };
  if (element && isFunction(element.scroll)) {
    element.scroll(options);
  } else {
    global.scroll(options);
  }
}

const getScrollMode = memoize(() => 'auto');

export function scrollSmoothly({ element, top, left } = {}) {
  scroll({ element, top, left, behavior: getScrollMode() });
}

export function scrollIntoView(element) {
  if (element && isFunction(element.scrollIntoView)) {
    element.scrollIntoView({
      behavior: getScrollMode(),
      block: 'center',
      inline: 'nearest',
    });
  }
}

export function getDocumentScrollTop() {
  return Math.max(
    global.document.documentElement.scrollTop,
    global.document.body.scrollTop,
    0,
  );
}

export function topCenter(element) {
  if (element && element.getBoundingClientRect) {
    const bodyRect = document.body.getBoundingClientRect();
    const elemRect = element.getBoundingClientRect();
    const offset = bodyRect.height / 2 - elemRect.height / 2;
    const top = elemRect.top - bodyRect.top - offset;
    return top;
  }
  return 0;
}

import Immutable from 'immutable';

import { size } from 'utils/immutable';
import { logError } from 'utils/logging';
import { track } from 'utils/tracking';

export default function* trackUserLabels({
  payload = {},
}: {
  payload?: {
    productVersionIds?: number[] | Immutable.List<number>;
    userLabelIds?: number[] | Immutable.List<number>;
    bulk?: boolean;
  };
} = {}) {
  try {
    const { productVersionIds, userLabelIds, bulk } = payload;
    if (!size(productVersionIds) || !size(userLabelIds)) {
      return;
    }
    const trackData = {
      category: 'product',
      action: 'product_tagged',
      label: `product_version_ids#${(productVersionIds as number[]).join()}`,
      tag_ids: (userLabelIds as number[]).join(),
    };
    if (bulk) {
      Object.assign(trackData, {
        action: 'product_bulk_tagged',
        value: size(productVersionIds),
      });
    }
    yield track(trackData);
  } catch (e) {
    logError(e);
  }
}

import classNamesUtil from 'classnames';
import { NavLink } from 'react-router-dom';

import { SharingUnitDropdown } from '../sharing-unit-item';

interface Props {
  id?: string | number;
  label: string;
  path: string;
  classNames?: string;
  onClick?: () => void;
  noIds?: boolean;
}

export const NavbarItem = ({
  id = '',
  label,
  path,
  classNames = 'nav-item',
  onClick = () => {},
  noIds = false,
}: Props) => (
  <li
    className={classNamesUtil(classNames)}
    data-testid={noIds ? '' : `nav-link-${id}`}
  >
    {id !== 'sharingUnitTemplatesAndTariffs' ? (
      <NavLink
        to={path}
        id={noIds ? '' : `nav-link-${id}`}
        className={(props) =>
          classNamesUtil('nav-link', props.isActive && 'active')
        }
        onClick={onClick}
      >
        {label}
      </NavLink>
    ) : (
      <SharingUnitDropdown />
    )}
  </li>
);

export default NavbarItem;

import i18n from 'utils/i18n';

export const isLabeledByFilter = {
  key: 'alkemics.sourcing.isLabeledBy.fra-FR',
  query: 'isLabeledBy',
  filterLabel: i18n.t('frontproductstream.core.list_filter_labeled_by.label', {
    defaultValue: 'IsLabeledBy',
  }),
  filterSearchPlaceHolder: i18n.t(
    'frontproductstream.core.list_filter_labeled_by.search_placeholder',
    { defaultValue: 'Search for labels' },
  ),
  filterNameKey: 'id',
  isAvailable: () => true,
};

import PropTypes from 'prop-types';
import { PureComponent } from 'react';
import ImmutablePropTypes from 'react-immutable-proptypes';

import './action-modal-info.scss';

export default class BulkActionModalInfo extends PureComponent {
  static propTypes = {
    entity: PropTypes.oneOfType([
      ImmutablePropTypes.map,
      PropTypes.object.isRequired,
    ]),
    rowComponent: PropTypes.func.isRequired,
    success: PropTypes.bool,
    error: PropTypes.bool,
    message: PropTypes.string,
  };

  render() {
    const {
      entity,
      success,
      error,
      message,
      rowComponent: RowComponent,
    } = this.props;

    return (
      <li className="BulkActionModalInfo">
        {!success && !error && (
          <i className="BulkActionModalInfo__icon mdi mdi-minus" />
        )}
        {!!success && <i className="BulkActionModalInfo__icon mdi mdi-check" />}
        {!!error && <i className="BulkActionModalInfo__icon mdi mdi-close" />}
        <RowComponent entity={entity} />
        {!!message && (
          <span>
            <span className="BulkActionModalInfo__separator">•</span>
            <strong>{message}</strong>
          </span>
        )}
      </li>
    );
  }
}

import { PureComponent } from 'react';
import { map } from 'react-immutable-proptypes';

import Dropdown from 'components/ui/dropdown';
import { isSuperAdmin, isSuperAdminLoggedAs } from 'core/api/user';
import i18n from 'utils/i18n';

import UnshareAction from './unshare';

export default class CatalogAdminActions extends PureComponent {
  static propTypes = {
    user: map.isRequired,
    productMap: map.isRequired,
    selectedMap: map.isRequired,
  };

  emptyOptions = [
    {
      key: 'empty',
      label: i18n.t('You must select at least one sheet'),
    },
  ];

  options = [
    {
      key: 'unshare',
      View: UnshareAction,
    },
  ];

  selectOptionKey = (opt) => opt.key;

  formatOptions() {
    const { selectedMap, user, productMap } = this.props;
    if (selectedMap.isEmpty()) {
      return this.emptyOptions;
    }
    return this.options
      .filter(({ View }) => View.isAvailable({ user }))
      .map(({ key, View }) => ({
        key,
        label: (
          <View user={user} selectedMap={selectedMap} productMap={productMap} />
        ),
      }));
  }

  render() {
    const { user } = this.props;
    if (!isSuperAdmin(user) && !isSuperAdminLoggedAs(user)) {
      return null;
    }
    const options = this.formatOptions();
    if (options.length < 1) {
      return null;
    }
    return (
      <Dropdown
        label={i18n.t('Alkemist')}
        options={options}
        selectKey={this.selectOptionKey}
        theme="danger"
        closeDropdownOnClickElement
        rightDropdown
        withoutWrappers
      />
    );
  }
}

import { levelTypeAllowance } from 'modules/price-waterfalls/constants';
import i18n from 'utils/i18n';

interface Props {
  pricewaterfall: any;
}

export function AllowanceLabel({ pricewaterfall }: Props) {
  const count = pricewaterfall.levels
    ?.filter((level) => level.type.id === levelTypeAllowance.id)
    ?.reduce((prev, curr) => prev + curr.items.length, 0);
  if (!count) {
    return null;
  }
  return (
    <span>
      {i18n.t(
        'frontproductstream.price_waterfalls.collapsible_pricewaterfall_allowance_label.text',
        { defaultValue: '{{count}} discount', count },
      )}
    </span>
  );
}

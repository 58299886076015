import classnames from 'classnames';
import PropTypes from 'prop-types';

import { LazyTooltip } from '@alkem/react-ui-tooltip';

import { SynchronizationStatusIdToInfo } from 'constants/synchronization-status';

import './index.scss';

export default function SynchronizationStatusColumn({
  data,
  productVersionId,
}) {
  if (data == null) {
    return null;
  }
  const { id, name, icon } = SynchronizationStatusIdToInfo[data];
  return (
    <div className="CatalogSynchronizationStatusCell">
      <span>
        <LazyTooltip
          id={`${productVersionId}_${data}`}
          className={classnames(
            'CatalogSynchronizationStatusColumn',
            `CatalogSynchronizationStatusColumn--${id}`,
          )}
          tooltipLabel={`${name}`}
        >
          <i className={`mdi ${icon}`} />
        </LazyTooltip>
      </span>
    </div>
  );
}

SynchronizationStatusColumn.center = true;
SynchronizationStatusColumn.propTypes = {
  data: PropTypes.string,
  productVersionId: PropTypes.number.isRequired,
};

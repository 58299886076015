import { set } from 'lodash/fp';

import { ActionPayload } from 'types/redux';

import { editDataOpsField } from '../actions';
import { dataOpsPatchKey } from '../selectors';

export const dataOpsEditFieldReducer = ({
  entityId,
  model,
  isReadOnly,
}: ActionPayload<typeof editDataOpsField>) =>
  set(['patches', dataOpsPatchKey(entityId, model), 'editable'], !isReadOnly);

import { set } from 'lodash/fp';

import { ActionPayload } from 'types';

import { dataOpsReplacePatches } from '../actions';

export const dataOpsReplacePatchesReducer = (
  { patches }: ActionPayload<typeof dataOpsReplacePatches> = {
    patches: [],
  },
) => set(['patches'], patches);

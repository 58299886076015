import { get } from 'lodash';
import { compose } from 'redux';

import { LIST_REDUCER_KEY } from '../constants';
import { UserAdminState, UserListState } from '../types';

import selectModuleState from '.';

const selectState: (state: UserAdminState) => UserListState = compose(
  (moduleState) => get(moduleState, LIST_REDUCER_KEY) || {},
  selectModuleState,
);

export const selectList = compose((state) => state.list, selectState);
export const selectSearch = compose((state) => state.search, selectState);
export const selectPagination = compose(
  (state) => state.pagination,
  selectState,
);
export const selectIsFetching = compose(
  (state) => state.isFetching,
  selectState,
);
export const selectFilters = compose((state) => state.filters, selectState);
export const selectOptions = compose((state) => state.options, selectState);

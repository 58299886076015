export const mandatoryFields = [
  'lifeCycle',
  'namePublicLong',
  'typePackaging',
  'isMadeOf',
];

export const mandatoryDisplayUnitFields = ['lifeCycle'];

export const ALWAYS_DISPLAYED_TAG = 'always_displayed';

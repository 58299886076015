import { every, set } from 'lodash';
import { update } from 'lodash/fp';
import { compose } from 'redux';

import { BRAND_PRODUCT_BRAND } from 'constants/brands';
import { KIND_BASE } from 'constants/concepts';
import { getBrandId, getKindId } from 'core/api/productversion';
import { parseIfNumber } from 'utils';
import { get } from 'utils/immutable';

export const updateReferenceOfPath = (object, completePath) => {
  const obj2 = { ...object };
  const keys = completePath.split('.');

  keys.reduce((oldPath, key) => {
    const path = (oldPath && `${oldPath}.${key}`) || key;
    const data = get(object, path);

    if (Array.isArray(data)) {
      set(obj2, path, [...data]);
    } else if (data instanceof Object) {
      set(obj2, path, { ...data });
    }

    return path;
  }, '');

  return obj2;
};

export const cleanBrandAndKind = (productVersion) => {
  const updatedProductVersion = { ...productVersion };
  // We want the base kind to act as if no kind is selected.
  const kindId = getKindId(productVersion);
  if (kindId === KIND_BASE) {
    updatedProductVersion.kind = null;
  }
  // Same for brand.
  const brandId = getBrandId(productVersion);
  if (brandId === BRAND_PRODUCT_BRAND) {
    updatedProductVersion.brand = null;
  }
  return updatedProductVersion;
};

// Set the same unit of measure where it's not filled.
// This will be meant to update similarly all expressedIn from the same referential.
export const updateIsSizedBy = (isSizedBy, key, value) => {
  if (
    // key should looks like `isSizedBy.i.width.j.expressedIn`
    !key.match(
      new RegExp(/^isSizedBy\.\d+\.(?:depth|height|width)\.\d+\.expressedIn$/),
    ) ||
    // Don't unset the others.
    !value
  ) {
    return isSizedBy;
  }
  // Update other paths.
  const path = key.split('.').map(parseIfNumber);
  return compose(
    ...['depth', 'height', 'width'].map((attr) => {
      const localPath = [path[1], attr, path[3], 'expressedIn'];
      // Don't change present values.
      if (get(isSizedBy, localPath)) {
        return (s) => s;
      }
      return update(localPath, () => ({ ...value }));
    }),
  )(isSizedBy);
};

export const updateIsSizedByInReducer = (action) => (state) => {
  if (!action.key.includes('isSizedBy')) {
    return state;
  }

  const path = action.key.split('.');
  const idx = path.indexOf('isSizedBy');
  const endPath = path.slice(idx);
  return update(path.slice(0, idx + 1), (isSizedBy) =>
    updateIsSizedBy(isSizedBy, endPath.join('.'), action.value),
  )(state);
};

export const updateIsMadeOfInReducer = (action) => (state) => {
  if (
    action.key === 'isBaseUnit' &&
    action.value &&
    every(state.isMadeOf, (child) => !child.targetProduct && !child.quantity)
  ) {
    return set(state, 'isMadeOf', []);
  }

  return state;
};

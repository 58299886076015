import asyncComponent from 'core/modules/async/component';
import registerModule from 'core/modules/async/register';

const authOthersModule = async () =>
  registerModule(await import(/* webpackChunkName: "activate" */ './async'));

export const Activate = asyncComponent(authOthersModule, {
  componentPath: 'Activate',
});

export const ForgotPassword = asyncComponent(authOthersModule, {
  componentPath: 'ForgotPassword',
});

export const CreatePassword = asyncComponent(authOthersModule, {
  componentPath: 'CreatePassword',
});

import PropTypes from 'prop-types';
import { PureComponent } from 'react';
import ImmutablePropTypes from 'react-immutable-proptypes';
import { connect } from 'react-redux';

import {
  selectSharingUnitsAgreed,
  selectSharingUnitsInit,
  selectSharingUnitsRefused,
} from '../selectors';

import SharingUnits from '.';

const mapStateToProps = (state) => ({
  sharingUnitsAgreedIds: selectSharingUnitsAgreed(state),
  sharingUnitsInitIds: selectSharingUnitsInit(state),
  sharingUnitsRefusedIds: selectSharingUnitsRefused(state),
});

export class SharingUnitsRefused extends PureComponent {
  static propTypes = {
    // own props
    order: PropTypes.number.isRequired,

    // props from store
    sharingUnitsAgreedIds: ImmutablePropTypes.list.isRequired,
    sharingUnitsInitIds: ImmutablePropTypes.list.isRequired,
    sharingUnitsRefusedIds: ImmutablePropTypes.list.isRequired,
  };

  render() {
    const {
      order,
      sharingUnitsAgreedIds,
      sharingUnitsInitIds,
      sharingUnitsRefusedIds,
    } = this.props;
    if (
      !sharingUnitsInitIds.isEmpty() ||
      !sharingUnitsAgreedIds.isEmpty() ||
      sharingUnitsRefusedIds.isEmpty()
    ) {
      return null;
    }

    return (
      <SharingUnits
        collapsed={false}
        id="SharingUnit__refused"
        order={order}
        sharingUnitIds={sharingUnitsRefusedIds}
      />
    );
  }
}

export default connect(mapStateToProps)(SharingUnitsRefused);

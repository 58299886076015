import HelpTooltip from '@alkem/react-ui-helptooltip';
import { Radio } from '@alkem/react-ui-inputs';

import { RaguelStatic } from 'components/ui/form/plugins/validator/static';
import IconWithTextButton from 'components/ui/icon-text-button';
import {
  AMBIGOUS_UNIT,
  CONSUMER_UNIT,
  DISPLAY_UNIT,
  HETEROGENEOUS_UNIT,
  LOGISTICAL_UNIT,
  UnitType,
} from 'constants/unitTypes';
import consumerUnitSrc from 'images/icons/consumer-unit.svg';
import displayUnitSrc from 'images/icons/display-unit.svg';
import heterogeneousUnitSrc from 'images/icons/heterogeneous-unit.svg';
import logisticalUnitSrc from 'images/icons/logistical-unit.svg';
import {
  FEATURE_HETEROGENEOUS_LOGISTICAL_UNIT,
  RELEASE_NEW_PRODUCT_TYPE_ICONS,
} from 'modules/feature-flag';
import i18n from 'utils/i18n';

import {
  CanChangeProductTypeData,
  Features,
  OnUnitTypeChange,
  RenderRfpSuLinkTooltip,
} from './FormTypePackaging.types';

interface UnitOption extends UnitType {
  value: number;
  hasTooltip?: boolean;
  iconSrc?: string;
  tooltip: string;
}

export const unitOptions: UnitOption[] = [
  {
    ...CONSUMER_UNIT,
    value: 0,
    iconSrc: consumerUnitSrc,
    tooltip: i18n.t('frontproductstream.packaging_type.consumer_unit.tooltip', {
      defaultValue: 'A consumer unit product can be purchased by the consumer.',
    }),
  },
  {
    ...DISPLAY_UNIT,
    value: 1,
    iconSrc: displayUnitSrc,
    tooltip: i18n.t('frontproductstream.packaging_type.display_unit.tooltip', {
      defaultValue:
        'A display unit is intended to be shipped to a store without being split at a distribution center and is intended to be displayed in a retail store either on a shelf or on the shop floor.',
    }),
  },
  {
    ...HETEROGENEOUS_UNIT,
    value: 2,
    iconSrc: heterogeneousUnitSrc,
    tooltip: i18n.t(
      'frontproductstream.packaging_type.heterogeneous_unit.tooltip',
      {
        defaultValue:
          'A heterogeneous unit is a logistical unit that contains different subunits.',
      },
    ),
  },
  {
    ...LOGISTICAL_UNIT,
    value: 2,
    iconSrc: logisticalUnitSrc,
    tooltip: i18n.t(
      'frontproductstream.packaging_type.logistical_unit.tooltip',
      {
        defaultValue:
          'A logistical unit is a shipping unit that cannot be purchased by the consumer, nor displayed in a store.',
      },
    ),
  },
];

export const unitOptionsMap = unitOptions.reduce((acc, cur) => {
  acc[cur.id] = cur;
  return acc;
}, {});

type UnitTypesProps = {
  isReadOnly: boolean;
  canChangeProductTypeData: CanChangeProductTypeData;
  features?: Features;
  selectedUnitTypeId: number;
  id: string;
  onChange: OnUnitTypeChange;
  isHetereogeneousUnit?: boolean;
  isHeterogeneousUnitSelected: boolean;
  onUnitTypeClick: (id: number) => void;
  renderRfpSuLinkTooltip: RenderRfpSuLinkTooltip;
};

export const UnitTypes = ({
  isReadOnly,
  canChangeProductTypeData,
  features,
  selectedUnitTypeId,
  id,
  onChange,
  isHetereogeneousUnit,
  isHeterogeneousUnitSelected,
  onUnitTypeClick,
  renderRfpSuLinkTooltip,
}: UnitTypesProps) => {
  const disabled = isReadOnly || !canChangeProductTypeData.hasNoRfpSuLinks;

  const isPredicateDisplayed = (typeId: number) => {
    if (typeId === HETEROGENEOUS_UNIT.id) {
      return !!(
        features?.[RELEASE_NEW_PRODUCT_TYPE_ICONS] &&
        features?.[FEATURE_HETEROGENEOUS_LOGISTICAL_UNIT]
      );
    }
    return true;
  };

  const isActivatedPredicate = (typeId: number) => {
    if (typeId === HETEROGENEOUS_UNIT.id) {
      return !!isHeterogeneousUnitSelected;
    }
    if (typeId === LOGISTICAL_UNIT.id) {
      return !isHetereogeneousUnit || !isHeterogeneousUnitSelected;
    }
    return true;
  };

  const radioData = unitOptions;
  if (!canChangeProductTypeData.hasNoRfpSuLinks) {
    radioData.forEach((o) => {
      o.hasTooltip = true;
    });
  }

  return (
    <>
      <div>
        {!features?.[RELEASE_NEW_PRODUCT_TYPE_ICONS] ? (
          <Radio
            id={id}
            value={selectedUnitTypeId}
            onChange={onChange}
            options={radioData.filter((type) => isPredicateDisplayed(type.id))}
            disabled={disabled}
            className="FormRadio__radio"
          />
        ) : (
          <div className="FormTypePackaging__TypeSelection">
            {unitOptions
              .filter((type) => isPredicateDisplayed(type.id))
              .filter((type) => {
                if (isReadOnly) {
                  return type.id === selectedUnitTypeId;
                }
                return true;
              })
              .map((type) => (
                <IconWithTextButton
                  data-tip
                  data-for={`InputRadio-FormField-typePackaging-unitType-${type.id}`}
                  key={type.label}
                  src={type.iconSrc}
                  title={type.label}
                  tooltip={
                    <HelpTooltip id={type.label} message={type.tooltip} />
                  }
                  active={
                    selectedUnitTypeId === type.id &&
                    isActivatedPredicate(type.id)
                  }
                  disabled={disabled}
                  onClick={() => onUnitTypeClick(type.id)}
                />
              ))}
          </div>
        )}
        {selectedUnitTypeId === AMBIGOUS_UNIT.id && (
          <RaguelStatic
            message={i18n.t('frontproductstream.packaging_type.missing.error', {
              defaultValue: 'You need to select one unit type',
            })}
          />
        )}
      </div>

      {!canChangeProductTypeData.hasNoRfpSuLinks &&
        radioData.map((opt) =>
          renderRfpSuLinkTooltip(
            canChangeProductTypeData,
            `InputRadio-FormField-typePackaging-unitType-${opt.id}`,
          ),
        )}
    </>
  );
};

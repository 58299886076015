import { identity } from 'lodash/fp';
import { all, call, put, race, select, take } from 'redux-saga/effects';

import { startLoading, stopLoading } from 'actions/navigation';
import { notificationError, notificationSuccess } from 'actions/notification';
import i18n from 'utils/i18n';
import { logError } from 'utils/logging';

import {
  resetUserLabelModal,
  saveUserLabelsDone,
  saveUserLabelsError,
  trackUserLabels,
} from '../actions';
import { CANCEL_SAVE_USER_LABELS } from '../actions/types';
import {
  selectAllUserLabels,
  selectProductKeyIds,
  selectProductVersionIds,
  selectSelectedUserLabels,
} from '../selectors/modal';

import attach from './attach';
import { detach } from './detach';

function* attachOrDetach() {
  const selectedLabels = yield select(selectSelectedUserLabels);
  const productVersionIds = yield select(selectProductVersionIds);
  const productKeyIds = yield select(selectProductKeyIds);
  const allLabels = yield select(selectAllUserLabels);
  const updatedLabels = selectedLabels
    .entrySeq()
    .filter(([, status]) => status.get('updated'))
    .toList();
  const labelsToAttach = updatedLabels
    .filter(([, status]) => status.get('value'))
    .map(([key]) => allLabels.getIn([key, 'id']))
    .filter(identity);
  const labelsToDetach = updatedLabels
    .filter(([, status]) => !status.get('value'))
    .map(([key]) => allLabels.getIn([key, 'id']))
    .filter(identity);
  const [attachResult, detachResult] = yield all([
    call(attach, {
      userLabelIds: labelsToAttach,
      productKeyIds,
    }),
    call(detach, {
      userLabelIds: labelsToDetach,
      productKeyIds,
    }),
  ]);
  const error = attachResult.error || detachResult.error;
  if (error) {
    return { error };
  }
  return {
    attachedLabels: labelsToAttach.map((id) => allLabels.get(id)),
    detachedLabels: labelsToDetach.map((id) => allLabels.get(id)),
    updatedProductIds: productVersionIds,
    updatedLabelIds: labelsToAttach.concat(labelsToDetach),
  };
}

function* save({
  payload = {},
}: { type?: string; payload?: { bulk?: boolean } } = {}) {
  try {
    yield put(startLoading());
    const {
      error,
      attachedLabels,
      detachedLabels,
      updatedProductIds,
      updatedLabelIds,
    } = yield call(attachOrDetach);
    if (error) {
      let message;
      if (error.status === 403) {
        message = i18n.t(
          'You are not allowed to update all the selected products',
        );
      } else {
        message = i18n.t('Something went wrong when setting up tags');
      }
      yield put(notificationError(message, { context: 'modal', error }));
      yield put(saveUserLabelsError());
    } else {
      yield put(resetUserLabelModal());
      yield put(
        saveUserLabelsDone({
          attachedLabels,
          detachedLabels,
          updatedProductIds,
        }),
      );
      yield put(notificationSuccess(i18n.t('Product successfully updated')));
      yield put(
        trackUserLabels({
          productIds: updatedProductIds,
          userLabelIds: updatedLabelIds,
          bulk: payload.bulk,
        }),
      );
    }
  } catch (err) {
    logError(err);
  } finally {
    yield put(stopLoading());
  }
}

export default function* saveUserLabels(action) {
  yield race({
    task: call(save, action),
    cancel: take(CANCEL_SAVE_USER_LABELS),
  });
}

import { useSelector } from 'react-redux';

import CoreLayout from 'layouts/CoreLayout';
import BodyLayout from 'layouts/body';

import { CatalogBody, CatalogMenu } from './menu';
import { selectRoute, selectUseCatalogWithMenu } from './menu/selectors';
import { ProductCatalog, SourcingCatalog } from './product';

export const Catalog = () => {
  const hasMenu = useSelector(selectUseCatalogWithMenu);
  const previousRoute = useSelector(selectRoute);
  return (
    <CoreLayout containerClass="Catalog" absolute>
      {hasMenu && <CatalogMenu />}
      <BodyLayout className="Catalog__body">
        {hasMenu ? (
          <CatalogBody defaultRoute={previousRoute} />
        ) : (
          <ProductCatalog />
        )}
      </BodyLayout>
    </CoreLayout>
  );
};

export const Sourcing = () => (
  <CoreLayout containerClass="Catalog" absolute>
    <BodyLayout className="Catalog__body">
      <SourcingCatalog />
    </BodyLayout>
  </CoreLayout>
);

export default Catalog;

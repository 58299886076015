import { bool, func, node, string } from 'prop-types';
import { PureComponent } from 'react';
import { map } from 'react-immutable-proptypes';
import { connect } from 'react-redux';
import { createStructuredSelector } from 'reselect';

import { notificationSuccess } from 'actions/notification';
import Modal from 'components/ui/modal';
import { selectUser } from 'modules/user';
import i18n from 'utils/i18n';
import { track } from 'utils/tracking';

import './modal.scss';

const mapStateToProps = createStructuredSelector({
  user: selectUser,
});

const mapDispatchToProps = {
  notificationSuccess,
};

export class FeatureModal extends PureComponent {
  static propTypes = {
    onClose: func.isRequired,
    title: string.isRequired,
    children: node,
    flag: string,
    trackCategory: string,
    requested: bool,
    showNotification: bool,
    notificationSuccess: func,
    trackDisplayed: bool,
    user: map.isRequired,
  };

  static defaultProps = {
    trackCategory: 'upsell',
    showNotification: false,
    trackDisplayed: true,
  };

  state = { requested: this.props.requested };

  tokens = {
    thankYou: i18n.t(
      'Thank you for your interest, we are going to recontact you soon.',
    ),
  };

  componentDidMount() {
    const { trackDisplayed } = this.props;
    if (trackDisplayed) {
      this.trackUpsellDisplayed();
    }
  }

  trackUpsell = () => {
    this.trackUpsellRequested();
    if (this.props.showNotification && this.props.notificationSuccess) {
      this.props.onClose();
      this.props.notificationSuccess(this.tokens.thankYou);
    } else {
      this.setState({ requested: true });
    }
  };

  trackUpsellDisplayed() {
    const { flag, trackCategory } = this.props;
    track({
      category: trackCategory,
      action: `${trackCategory}__displayed`,
      label: flag,
      feature_name: flag,
    });
    this.trackUpsellDisplayed = function () {};
  }

  trackUpsellRequested() {
    const { flag, trackCategory } = this.props;
    track({
      category: trackCategory,
      action: `${trackCategory}_requested`,
      label: flag,
      feature_name: flag,
    });
  }

  renderFeatureRequested() {
    return (
      <div className="FeatureModal__requested">
        <span>{this.tokens.thankYou}</span>
      </div>
    );
  }

  renderModalBody() {
    if (this.state.requested) {
      return this.renderFeatureRequested();
    }
    return this.props.children;
  }

  render() {
    const { title } = this.props;
    const { requested } = this.state;

    return (
      <Modal
        title={title}
        className="FeatureModal"
        confirmButtonText={i18n.t(
          'frontproductstream.product_page.feature_modal.more_info_button_text',
          { defaultValue: 'More info' },
        )}
        confirmDisabled={requested}
        onConfirm={this.trackUpsell}
        closeButtonText={requested ? i18n.t('Close') : i18n.t('Maybe later')}
        onClose={this.props.onClose}
      >
        {this.renderModalBody()}
      </Modal>
    );
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(FeatureModal);

import { i18n } from 'utils/i18n';

import { ActionToReviewStatus, BlockToReviewType } from './types';

export const ACTION_STATUSES: {
  [status in Exclude<ActionToReviewStatus, 0>]: {
    id: number;
    label: string;
    icon: string;
  };
} = {
  [ActionToReviewStatus.NOT_STARTED]: {
    id: ActionToReviewStatus.NOT_STARTED,
    label: i18n.t(
      'frontproductstream.product_review.review_action_status.not_started',
      { defaultValue: 'Not Started' },
    ),
    icon: 'mdi-checkbox-blank-circle-outline',
  },
  [ActionToReviewStatus.TO_REVIEW]: {
    id: ActionToReviewStatus.TO_REVIEW,
    label: i18n.t(
      'frontproductstream.product_review.review_action_status.to_review',
      { defaultValue: 'To Review' },
    ),
    icon: 'mdi-clock-outline',
  },
  [ActionToReviewStatus.REVIEWED]: {
    id: ActionToReviewStatus.REVIEWED,
    label: i18n.t(
      'frontproductstream.product_review.review_action_status.reviewed',
      { defaultValue: 'Reviewed' },
    ),
    icon: 'mdi-thumb-up-outline',
  },
  [ActionToReviewStatus.SKIPPED]: {
    id: ActionToReviewStatus.SKIPPED,
    label: i18n.t(
      'frontproductstream.product_review.review_action_status.skipped',
      { defaultValue: 'Skipped' },
    ),
    icon: 'mdi-checkbox-blank-circle-outline',
  },
  [ActionToReviewStatus.REVIEWING]: {
    id: ActionToReviewStatus.REVIEWING,
    label: i18n.t(
      'frontproductstream.product_review.review_action_status.reviewing',
      { defaultValue: 'Reviewing' },
    ),
    icon: 'mdi-checkbox-blank-circle-outline',
  },
  [ActionToReviewStatus.CHANGE_REQUESTED]: {
    id: ActionToReviewStatus.CHANGE_REQUESTED,
    label: i18n.t(
      'frontproductstream.product_review.review_action_status.change_requested',
      { defaultValue: 'Change Requested' },
    ),
    icon: 'mdi-thumb-down-outline',
  },
  [ActionToReviewStatus.UPDATED]: {
    id: ActionToReviewStatus.UPDATED,
    label: i18n.t(
      'frontproductstream.product_review.review_action_status.updated',
      { defaultValue: 'Updated by manufacturer' },
    ),
    icon: 'mdi-autorenew',
  },
  [ActionToReviewStatus.VALIDATED]: {
    id: ActionToReviewStatus.VALIDATED,
    label: i18n.t(
      'frontproductstream.product_review.review_action_status.validated',
      { defaultValue: 'Validated' },
    ),
    icon: 'mdi-check-circle-outline',
  },
  [ActionToReviewStatus.REJECTED]: {
    id: ActionToReviewStatus.REJECTED,
    label: i18n.t(
      'frontproductstream.product_review.review_action_status.rejected',
      { defaultValue: 'Rejected' },
    ),
    icon: 'mdi-close-circle-outline',
  },
};

export const BLOCK_TYPE_LABEL: {
  [type in BlockToReviewType]: {
    label: string;
  };
} = {
  [BlockToReviewType.PRODUCT_INFORMATION]: {
    label: i18n.t(
      'frontproductstream.product_review.block_type_label.product_information',
      { defaultValue: 'Product Information' },
    ),
  },
  [BlockToReviewType.LISTING]: {
    label: i18n.t(
      'frontproductstream.product_review.block_type_label.listing',
      { defaultValue: 'Listing' },
    ),
  },
  [BlockToReviewType.LOGISTICAL_HIERARCHY]: {
    label: i18n.t(
      'frontproductstream.product_review.block_type_label.logistical_hierarchy',
      { defaultValue: 'Logistical Hierarchies' },
    ),
  },
};

export const PRODUCT_TO_REVIEW_STATUSES = [
  ActionToReviewStatus.TO_REVIEW,
  ActionToReviewStatus.REVIEWING,
  ActionToReviewStatus.UPDATED,
  ActionToReviewStatus.REJECTED,
  ActionToReviewStatus.CHANGE_REQUESTED,
];

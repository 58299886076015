import { Map, fromJS } from 'immutable';

import { ASSETS_RECEIVE_ALL } from 'constants/events/media';
import {
  RECEIVE_GROUP_SCOPES,
  RECEIVE_PRODUCT_KEY,
  RECEIVE_PRODUCT_SHARES,
  RECEIVE_PRODUCT_VERSION,
  RECEIVE_PRODUCT_VERSION_PERMISSIONS,
} from 'constants/events/productversion';
import * as sharedStatusConstants from 'constants/sharedStatus';
import {
  getLastShareStatus,
  getTargetProductStatus,
} from 'core/api/retailerproductversion';
import { isMaker, isRetailer } from 'core/api/user';
import { SET_CONTRIBUTIONS } from 'modules/contribution/constants';
import { selectHasMultipleLocales } from 'modules/feature-flag/selectors';
import { init } from 'modules/logistical-hierarchies/actions';
import {
  LIST_HIERARCHIES,
  LOGISTICAL_HIERARCHIES_RECEIVE_DIFFS,
} from 'modules/logistical-hierarchies/constants';
import {
  RETAILER_RECEIVE_REQUESTS,
  RETAILER_RECEIVE_SHARINGUNITS_REQUESTS,
  RETAILER_REQUESTS_RECEIVE_TRANSACTION_IDS,
} from 'modules/product-page/modules/product-share-diff/constants';
import { RETAILER_RECEIVE_SHARINGUNITS } from 'modules/product-page/modules/retailer-sharing-units/constants';
import { RECEIVE_OVERRIDE_DATA } from 'modules/recipient-specific-block/events';
import { FETCH_SHARING_UNITS_SUCCESS } from 'modules/sharing-units/actionTypes';
import { selectLocalesByTargetMarket, selectUser } from 'modules/user';
import { get } from 'utils/immutable';

// Process permissions.
export const processPermissions = (permissions) => ({
  type: RECEIVE_PRODUCT_VERSION_PERMISSIONS,
  payload: { permissions },
});

// Process product key
export const processProductKey = (productKey) => ({
  type: RECEIVE_PRODUCT_KEY,
  payload: { productKey },
});

// Process product key
export const processProductShares = (productShares) => ({
  type: RECEIVE_PRODUCT_SHARES,
  payload: { productShares },
});

// Process metadata that was historically in productVersion.
const processProductVersionExtras = (pv, data, user) => {
  const productVersion = pv;
  // Add target product status.
  if (isRetailer(user)) {
    const targetProductStatus =
      get(data, ['statuses', 'target']) ||
      getTargetProductStatus(productVersion);
    productVersion.targetProductStatus = targetProductStatus;
  }
  // Add segments.
  productVersion.isClassifiedIn = get(data, ['segments', 'hierarchy']);
  productVersion.isUserLabeledBy = get(data, ['segments', 'userLabels']) || [];
  // Add shares.
  productVersion.isSharedFrom = get(data, ['shares', 'sources']) || [];
  productVersion.isSharedWith = get(data, ['shares', 'targets']) || [];
  // Add tags.
  productVersion.tags = {
    contentOwner: data.contentOwner,
    targetMarket: data.targetMarket,
  };
  productVersion.isTaggedBy = data.tags || [];
  // Add product.
  productVersion.specializes = {
    id: data.product_id,
    isIdentifiedBy: [{ reference: data.gtin }],
  };
};

// Process product version data.
export const processProductVersion = (data) => (dispatch, getState) => {
  if (data.data.productVersion.data) {
    const user = selectUser(getState());
    const productVersion = data.data.productVersion.data;
    processProductVersionExtras(productVersion, data, user);
    // Handle the last request.
    // Currently, we need to add it only if isSharedFrom is PENDING.
    // TODO: SNT - allow adding it also if ACCEPTED.
    const shareStatus = getLastShareStatus(productVersion);
    if (
      get(shareStatus, 'id') === sharedStatusConstants.pending.id &&
      data.data.productVersion.transactionId
    ) {
      const { lastRequest } = data.data.productVersion;
      processProductVersionExtras(lastRequest, data, user);
      productVersion.lastRequest = {
        data: lastRequest,
        updatedAt: data.data.productVersion.lastModified,
        id: data.data.productVersion.transactionId,
      };
    }
    dispatch({
      type: RECEIVE_PRODUCT_VERSION,
      productVersion,
      cleanBrandAndKind: isMaker(user),
      setupLocale: true,
      userLocalesByTargetMarket: selectLocalesByTargetMarket(getState()),
      supportsMultipleLocales: selectHasMultipleLocales(getState()),
    });
  }
};

export const processScopes = (data) => (dispatch) => {
  const { scopes } = data;
  dispatch({ type: RECEIVE_GROUP_SCOPES, scopes });
};

// Process assets data.
export const processAssets = (data) => (dispatch) => {
  if (data.data.assets.data) {
    let assets = data.data.assets.data;
    const shareStatus = getLastShareStatus({
      isSharedFrom:
        data.data.productVersion.data.isSharedFrom ||
        get(data, ['shares', 'sources']) ||
        [],
    });
    if (
      get(shareStatus, 'id') === sharedStatusConstants.pending.id &&
      data.data.assets.lastRequests
    ) {
      assets = data.data.assets.lastRequests;
    }
    const { specificData, totalExpired } = get(data, 'data.assets', {});
    dispatch({
      type: ASSETS_RECEIVE_ALL,
      payload: { assets, specificData, totalExpired },
    });
  }
};

// Process sharing units data.
export const processSharingUnits = (data) => (dispatch) => {
  dispatch({
    type: RETAILER_RECEIVE_SHARINGUNITS,
    sharingunits: fromJS(data.data.sharingUnits.map((su) => su.data)),
    flattenHierarchies: true,
  });
};

// Process consumer unit diffs (product version and assets).
export const processConsumerUnitDiffs = (data) => (dispatch) => {
  const requests = [];
  if (data.data.productVersion.transactionId) {
    requests.push({
      id: data.data.productVersion.transactionId,
      diff: data.data.productVersion.diff,
    });
  }
  if (data.data.assets.transactionIds) {
    // Note we have all the diffs grouped into a single list while we have a bunch of requests.
    // It does not matter if we attach all the diffs in the first request and have the others empty.
    data.data.assets.transactionIds.forEach((tid, i) => {
      requests.push({
        id: tid,
        diff: i === 0 ? data.data.assets.diff : [],
      });
    });
  }
  // TODO decouple this
  dispatch({
    type: RETAILER_RECEIVE_REQUESTS,
    requests: fromJS(requests),
  });
};

// Process sharing units diff.
export const processSharingUnitsDiffs = (data) => (dispatch) => {
  const diffs = Map(
    data.data.sharingUnits
      .filter((su) => su.diff && su.diff.length)
      .map((su) => [`${su.data.id}`, fromJS({ diff: su.diff })]),
  );
  // TODO decouple this
  dispatch({
    type: RETAILER_RECEIVE_SHARINGUNITS_REQUESTS,
    sharingunits: diffs,
  });
};

// Process transactionIDs.
export const processTransactionIds = (data) => (dispatch) => {
  // Consumer unit transaction IDs.
  let cuTrxIds;
  const versionTrxId = get(data, ['data', 'productVersion', 'transactionId']);
  const assetTrxIds = get(data, ['data', 'assets', 'transactionIds']) || [];
  if (versionTrxId) {
    cuTrxIds = [...assetTrxIds, versionTrxId];
  } else {
    cuTrxIds = [...assetTrxIds];
  }

  // Listing transaction IDs.
  const listingTrxIds = {};
  const hierarchyTrxIdsByRootId = {};
  (get(data, ['data', 'logisticalHierarchies_beta']) || []).forEach((h) => {
    if (h.transactionIds && h.transactionIds.length) {
      hierarchyTrxIdsByRootId[h.id] = h.transactionIds;
    }
  });
  (get(data, ['data', 'sharingUnits']) || []).forEach((su) => {
    const hierarchyId =
      get(su, ['data', 'lastRequest', 'data', 'hierarchyProduct', 'id']) ||
      get(su, ['data', 'data', 'hierarchyProduct', 'id']);
    const hierarchyTrxIds = hierarchyTrxIdsByRootId[hierarchyId] || [];
    const suTrxId = get(su, ['data', 'lastRequest', 'id']);
    if (suTrxId) {
      listingTrxIds[su.data.id.toString()] = [...hierarchyTrxIds, suTrxId];
    } else {
      listingTrxIds[su.data.id.toString()] = [...hierarchyTrxIds];
    }
  });

  // TODO decouple this
  return dispatch({
    type: RETAILER_REQUESTS_RECEIVE_TRANSACTION_IDS,
    consumerUnit: cuTrxIds,
    listing: listingTrxIds,
  });
};

// ----------------------------------
// Hierarchy dispatchers.
// ----------------------------------

export const processLogisticalHierarchyData = (data) => (dispatch) => {
  if (data.data.logisticalHierarchies_beta) {
    const { permissions = [] } = data;
    const hierarchies = data.data.logisticalHierarchies_beta
      .map((h) => {
        if (!h.data && h.lastRequest) {
          return {
            id: h.lastRequest.id,
            gtin: null,
            productIdentifier: null,
            version: {},
            children: [],
            lastRequest: h.lastRequest || {},
            permissions,
          };
        } else if (h.data) {
          return {
            ...h.data,
            lastRequest: h.lastRequest || {},
          };
        }
        // Should not happen but just in case ignore it.
        return null;
      })
      .filter((h) => !!h);
    dispatch({
      type: LIST_HIERARCHIES,
      hierarchies,
      permissions,
    });
    dispatch(init());
  }
};

export const processLogisticalHierarchyDiffs = (data) => (dispatch) => {
  if (data.data.logisticalHierarchies_beta) {
    const diffs = [];
    data.data.logisticalHierarchies_beta.forEach((h) => {
      if (h.diff && h.diff.length) {
        const hierarchyId =
          get(h, ['data', 'id']) || get(h, ['lastRequest', 'id']);
        diffs.push({
          hierarchyId,
          diff: h.diff,
        });
      }
    });
    dispatch({
      type: LOGISTICAL_HIERARCHIES_RECEIVE_DIFFS,
      diffs,
    });
  }
};

// ----------------------------------
// Manufacturer specific dispatchers.
// ----------------------------------

// Process manufacturer product version data.
export const processManufacturerProductVersion =
  (data) => (dispatch, getState) => {
    if (data.data.productVersion.data) {
      const user = selectUser(getState());
      const productVersion = data.data.productVersion.data;
      processProductVersionExtras(productVersion, data, user);
      dispatch({
        type: RECEIVE_PRODUCT_VERSION,
        productVersion,
        cleanBrandAndKind: isMaker(user),
        setupLocale: true,
        userLocalesByTargetMarket: selectLocalesByTargetMarket(getState()),
        supportsMultipleLocales: selectHasMultipleLocales(getState()),
      });
    }
  };

// Process manufacturer sharing units data.
export const processManufacturerSharingUnits = (data) => (dispatch) => {
  dispatch({
    type: FETCH_SHARING_UNITS_SUCCESS,
    payload: {
      sharingUnits: data.data.sharingUnits.map((su) => su.data),
      targetSharingStatuses: data.shares.targets,
      pricewaterfallLabels: data.pricewaterfallLabels,
    },
    requestPayload: {
      flattenHierarchies: true,
    },
  });
};

// Process contribution data
export const processContributions = (data) => (dispatch) => {
  if (data.data.contributions) {
    dispatch({
      type: SET_CONTRIBUTIONS,
      payload: {
        contributions: data.data.contributions,
      },
    });
  }
};

export const processSpecificData = (data) => (dispatch) => {
  if (data.data.productVersion.specificData) {
    dispatch({
      type: RECEIVE_OVERRIDE_DATA,
      data: data.data.productVersion.specificData,
    });
  }
};

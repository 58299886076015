import { put, takeEvery } from 'redux-saga/effects';

import { ENTITY_TYPE_SHARINGUNIT } from 'constants/entities';
import { applyRulesForViewAsRecipients } from 'modules/validation';

import {
  CREATE_SHARING_UNIT_SUCCESS,
  DELETE_SHARING_UNIT_SUCCESS,
} from '../actionTypes';

export function* watchCreationOrDeletion() {
  yield takeEvery(
    [CREATE_SHARING_UNIT_SUCCESS, DELETE_SHARING_UNIT_SUCCESS],
    runValidation,
  );
}

export function* runValidation({ payload }) {
  yield put(
    applyRulesForViewAsRecipients(
      ENTITY_TYPE_SHARINGUNIT,
      payload.sharingUnitId,
    ),
  );
}

import { Map } from 'immutable';
import { flow } from 'lodash/fp';
import PropTypes from 'prop-types';
import { Component } from 'react';
import { connect } from 'react-redux';

import { withLocation } from 'hocs';
import qs from 'utils/query';

import { requestRedirect } from '../actions';
import RedirectError from '../components/error';
import { getError } from '../reducer';

const mapStateToProps = (state) => ({
  error: getError(state),
});

const mapDispatchToProps = {
  requestRedirect,
};

export class Redirect extends Component {
  static propTypes = {
    error: PropTypes.object.isRequired,
    location: PropTypes.object.isRequired,
    requestRedirect: PropTypes.func.isRequired,
  };

  componentDidMount() {
    this.requestRedirect();
  }

  componentDidUpdate(prevProps) {
    const { location } = this.props;
    if (
      location.search !== prevProps.location.search ||
      location.pathname !== prevProps.location.pathname
    ) {
      this.requestRedirect();
    }
  }

  requestRedirect() {
    const { location } = this.props;
    const query = qs.parse(location.search);
    const transformedQuery = {};
    if (query.path || query.p) {
      transformedQuery.path = query.path || query.p;
    }
    if (query.action || query.a) {
      transformedQuery.action = query.action || query.a;
    }
    if (query.gtin || query.g) {
      transformedQuery.gtin = query.gtin || query.g;
    }
    if (query.productKeyId) {
      transformedQuery.productKeyId = query.productKeyId;
    }
    if (query.targetMarket || query.tm) {
      transformedQuery.targetMarket = query.targetMarket || query.tm;
    }
    if (query.buId || query.bi) {
      transformedQuery.buId = query.buId || query.bi;
    }
    this.props.requestRedirect(transformedQuery, location);
  }

  render() {
    const { error } = this.props;
    return (
      <div className="Redirect">
        {Map(error).get('show') && (
          <RedirectError message={Map(error).get('message')} />
        )}
      </div>
    );
  }
}

export default flow(
  connect(mapStateToProps, mapDispatchToProps),
  withLocation,
)(Redirect);

import moment from 'moment';
import PropTypes from 'prop-types';

export default function CatalogDateColumn({ data }) {
  const m = Number.isInteger(data) ? moment.unix(data) : moment(data);
  return <div>{data ? m.format('L') : <noscript />}</div>;
}

CatalogDateColumn.center = true;
CatalogDateColumn.propTypes = {
  data: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
};

import { createAction } from 'utils/redux';

import { CREATE_PRICE_WATERFALL, GET_TAX_LABELS } from './events';

export function createPriceWaterfall({
  entityId,
  productId,
  targetOrganizationId,
  duplicateFrom,
}: {
  entityId: number;
  productId: number;
  targetOrganizationId: number;
  duplicateFrom?: object;
}) {
  return {
    duplicateFrom,
    productId,
    sharingUnitId: entityId,
    targetOrganizationId,
    type: CREATE_PRICE_WATERFALL,
  };
}

export const getTaxLabels = createAction(GET_TAX_LABELS);

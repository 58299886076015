import PropTypes from 'prop-types';
import { PureComponent } from 'react';

import { Radio } from '@alkem/react-ui-inputs';

import { getId } from 'components/ui/form/field/utils/clean';
import InputText from 'components/ui/input/text';
import { parseBoolean } from 'utils';
import i18n from 'utils/i18n';

import './tax.scss';

const options = [
  { label: i18n.t('Yes'), value: true },
  { label: i18n.t('No'), value: false },
];

export default class TaxLevel extends PureComponent {
  static propTypes = {
    model: PropTypes.string.isRequired,
    entityId: PropTypes.number,
    entityType: PropTypes.string,
    value: PropTypes.object.isRequired,
    onUpdate: PropTypes.func,
    taxValue: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    label: PropTypes.string.isRequired,
    includable: PropTypes.bool.isRequired,
    disabled: PropTypes.bool.isRequired,
    taxesNotIncludedByDefault: PropTypes.bool,
  };

  onUpdateIncluded = (event) => {
    const model = `${this.props.model}.included`;
    const value = parseBoolean(event.currentTarget.value);
    this.props.onUpdate(model, value);
  };

  onUpdateTaxValue = () => null;

  renderLabelWrapper() {
    const {
      label,
      entityId,
      entityType,
      value,
      model,
      includable,
      disabled,
      taxesNotIncludedByDefault,
    } = this.props;

    if (
      !disabled &&
      includable &&
      taxesNotIncludedByDefault &&
      value.included == null
    )
      value.included = false;

    return (
      <div className="TaxLevel__labelWrapper">
        <div className="TaxLevel__label">
          <span>{label}</span>
        </div>
        {includable && (
          <div className="TaxLevel__included">
            <div>{i18n.t('Included in gross price?')}</div>
            <Radio
              id={getId(`${model}.included`, entityType, entityId)}
              value={value.included}
              onChange={this.onUpdateIncluded}
              options={options}
              disabled={disabled}
            />
          </div>
        )}
      </div>
    );
  }

  renderTaxValue() {
    const { taxValue, model, entityId, entityType } = this.props;
    return (
      <div className="TaxLevel__value">
        <InputText
          id={getId(`${model}.taxValue`, entityType, entityId)}
          value={taxValue}
          onChange={this.onUpdateTaxValue}
          disabled
        />
      </div>
    );
  }

  render() {
    return (
      <div className="TaxLevel">
        {this.renderLabelWrapper()}
        {this.renderTaxValue()}
      </div>
    );
  }
}

import classNames from 'classnames';
import PropTypes from 'prop-types';
import { useState } from 'react';

import TurboSelect from '@alkem/react-ui-turbo-select';

import { TypePackagingLabels } from 'constants/typePackaging';
import LogisticalHierarchyIcon from 'modules/logistical-hierarchies/components/icon';
import i18n from 'utils/i18n';

import './hierarchiesSelector.scss';

const packagingTypeId = (typePackaging) => {
  return {
    EACH: 0,
    PACK: 1,
    CASE: 2,
    PALLET: 3,
  }[typePackaging];
};

const capitalize = ([first, ...rest]) => {
  return first.toUpperCase() + rest.join('').toLocaleLowerCase();
};

function HierarchiesSelector(props) {
  const getDefault = (hierarchies) => {
    let defaultHieararchy = null;
    if (hierarchies.length) {
      for (let h of hierarchies) {
        if (!h.is_shared) {
          defaultHieararchy = {
            label: `${TypePackagingLabels[h.typePackaging.code]?.label} ${
              h.gtin
            }`,
            typePackaging: h.typePackaging.code,
            gtin: h.gtin,
          };
          return defaultHieararchy;
        }
      }
    }
    return defaultHieararchy;
  };

  const [selectedHierarchy, setSelectedHierarchy] = useState(
    getDefault(props.hierarchies),
  );

  const onSelect = (hierarchy) => {
    setSelectedHierarchy(hierarchy);
    props.onChange(hierarchy.value);
  };

  let availableHierarchies = [];
  for (let hierarchy of props.hierarchies) {
    if (!hierarchy.is_shared) {
      availableHierarchies.push({
        productKeyId: hierarchy.product_id,
        label: `${TypePackagingLabels[hierarchy.typePackaging.code]?.label} ${
          hierarchy.gtin
        }`,
        value: hierarchy.product_id,
        gtin: hierarchy.gtin,
        typePackaging: hierarchy.typePackaging.code,
      });
    }
  }

  const isAllHierarchiesShared = (hierarchies) => {
    for (let h of hierarchies) {
      if (!h.is_shared) {
        return false;
      }
    }

    return true;
  };

  const renderOneHierarchy = (hierarchy) => {
    const typePackaging = hierarchy.typePackaging;
    return (
      <div className="HierarchiesSelector">
        <LogisticalHierarchyIcon
          packagingTypeId={packagingTypeId(typePackaging)}
          extra_small
        />
        <span className="HierarchiesSelector__optionLabel">
          {capitalize(i18n.t(typePackaging.toLowerCase()))}
        </span>{' '}
        {hierarchy.gtin}
      </div>
    );
  };

  if (props.hierarchies.length === 0) {
    return i18n.t('There is no shareable logistical hierarchy');
  }

  if (isAllHierarchiesShared(props.hierarchies)) {
    return i18n.t(
      'All hierarchies have already been attributed to this retailer.',
    );
  }

  if (props.hierarchies.length === 1) {
    return renderOneHierarchy({
      typePackaging: props.hierarchies[0].typePackaging.code,
      gtin: props.hierarchies[0].gtin,
    });
  }

  if (availableHierarchies.length === 1) {
    return renderOneHierarchy({
      typePackaging: availableHierarchies[0].typePackaging,
      gtin: availableHierarchies[0].gtin,
    });
  }

  return (
    <TurboSelect
      className="HierarchiesSelector"
      id="recipients-selector"
      options={availableHierarchies}
      onChange={onSelect}
      value={selectedHierarchy}
      components={{ Option: CustomOption, SingleValue: CustomOption }}
      isClearable={false}
    />
  );
}

HierarchiesSelector.propTypes = {
  hierarchies: PropTypes.array.isRequired,
  onChange: PropTypes.func.isRequired,
};

function CustomOption({ innerRef, innerProps, data, isFocused }) {
  const typePackaging = data.typePackaging;
  return (
    <div
      ref={innerRef}
      {...innerProps}
      className={classNames(
        'TurboSelect__option',
        isFocused && 'TurboSelect__option--is-focused',
      )}
    >
      <LogisticalHierarchyIcon
        packagingTypeId={packagingTypeId(typePackaging)}
        extra_small
      />
      <span className="HierarchiesSelector__optionLabel">
        {capitalize(i18n.t(typePackaging.toLowerCase()))}
      </span>{' '}
      {data.gtin}
    </div>
  );
}

CustomOption.propTypes = {
  innerRef: PropTypes.elementType,
  innerProps: PropTypes.object,
  data: PropTypes.object,
  isFocused: PropTypes.bool,
};

export default HierarchiesSelector;

import { registerModuleReducer } from 'utils/modules/reducer';
import { registerSaga } from 'utils/modules/saga';

import { MODULE_NAME } from './constants';
import { WrapperRequestsActionsModal } from './modals';
import reducer from './reducer';
import catalogRequestsActionsSaga from './sagas';

registerSaga(catalogRequestsActionsSaga);
registerModuleReducer(MODULE_NAME, reducer);

export { WrapperRequestsActionsModal };

import { memo } from 'react';

import { PaginationData } from 'core/modules/pagination/types';
import { get } from 'utils/immutable';

import './buttons.scss';

type Props = {
  id?: string;
  pagination: PaginationData;
  onPrevious: () => void;
  onNext: () => void;
};

const ListPaginationButtons = memo((props: Props) => {
  const { id, pagination, onNext, onPrevious } = props;

  const isPreviousDisabled = () => get(pagination, 'page') <= 1;

  const isNextDisabled = () => {
    const keys = get(pagination, 'keys');
    const pages = get(pagination, 'pages');
    const page = get(pagination, 'page');
    if (keys && keys.size) {
      return !keys.get(page + 1);
    }
    if (pages) {
      return page >= pages;
    }
    return true;
  };

  const _onPrevious = () => {
    if (isPreviousDisabled()) {
      return;
    }
    onPrevious();
  };

  const _onNext = () => {
    if (isNextDisabled()) {
      return;
    }
    onNext();
  };

  return (
    <div id={id ?? 'list-pagination-buttons'} className="ListPaginationButtons">
      <button
        type="button"
        disabled={isPreviousDisabled()}
        onClick={_onPrevious}
      >
        <i className="mdi mdi-arrow-left-bold" />
      </button>
      <button type="button" disabled={isNextDisabled()} onClick={_onNext}>
        <i className="mdi mdi-arrow-right-bold" />
      </button>
    </div>
  );
});

export { ListPaginationButtons };
export default ListPaginationButtons;

import { fromJS } from 'immutable';
import { createReducer } from 'redux-create-reducer';

import {
  RETAILER_REFUSE_MODAL_OPEN,
  RETAILER_REFUSE_MODAL_RESET,
} from './constants';

const initialState = fromJS({
  opened: false,
});

export default createReducer(initialState, {
  [RETAILER_REFUSE_MODAL_OPEN]: (state, { payload }) =>
    state.withMutations((newState) =>
      newState
        .set('opened', true)
        .set('entityId', payload.entityId)
        .set('entityType', payload.entityType)
        .set('title', payload.title)
        .set('description', payload.description),
    ),
  [RETAILER_REFUSE_MODAL_RESET]: () => initialState,
});

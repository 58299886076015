import { get } from 'lodash/fp';
import { Link } from 'react-router-dom';

import { archived as lifeCycleArchived } from 'constants/filters/lifeCycle';
import * as routes from 'routes';
import i18n from 'utils/i18n';
import { fill } from 'utils/routing';

const isTheModel = i18n.t('it is the current model');
const existingVariant = i18n.t('it is already a variant of this product');
const existingVariantOfOtherModel = i18n.t(
  'it is already a variant of another model',
);
const wrongTargetMarketId = i18n.t(
  'its target market does not match the one set on this product',
);
const archived = i18n.t('it is an archived product');
const notConsumerUnit = i18n.t('it is not a consumer unit');
const kindMismatch = i18n.t(
  'its category does not match the one set on this product',
);
const packagingTypeMismatch = i18n.t(
  'its packaging type does not match the one set on this product',
);
const otherModel = i18n.t('it is a model that contains variants');

export default function creationError(
  productKeyId: number,
  existingProduct: object | null,
  modelProductKeyId: number,
  model: object,
) {
  let message = i18n.t(
    'The gtin is already used and cannot be used as a variant of this product for the following reason(s):',
  );
  let reasons = [] as string[];
  let canRedirect = true;

  if (!existingProduct) {
    message = i18n.t(
      'The gtin is already used and cannot be used as a variant of this product',
    );
  } else if (
    get(['id'], model) === parseInt(get(['id'], existingProduct), 10)
  ) {
    reasons = [isTheModel];
    canRedirect = false;
  } else if (
    modelProductKeyId ===
    get(['textileModel', 'product_key_id'], existingProduct)
  ) {
    reasons = [existingVariant];
    canRedirect = false;
  } else {
    if (get(['textileModel', 'product_key_id'], existingProduct)) {
      reasons.push(existingVariantOfOtherModel);
    }
    if (
      get(['tags', 'targetMarket', 'id'], model) !==
      get(['tags', 'targetMarket', 'id'], existingProduct)
    ) {
      reasons.push(wrongTargetMarketId);
    }
    if (get(['lifeCycle'], existingProduct) === lifeCycleArchived.id) {
      reasons.push(archived);
    }
    if (!get(['isConsumerUnit'], existingProduct)) {
      reasons.push(notConsumerUnit);
      canRedirect = !!get(['isDisplayUnit'], existingProduct);
    }
    if (get(['kind', 'id'], model) !== get(['kind', 'id'], existingProduct)) {
      reasons.push(kindMismatch);
    }
    if (
      get(['typePackaging', 'id'], model) !==
      get(['typePackaging', 'id'], existingProduct)
    ) {
      reasons.push(packagingTypeMismatch);
    }
    if (
      get(['alkemics', 'textileVariants', 'gtins'], existingProduct) &&
      get(['alkemics', 'textileVariants', 'gtins'], existingProduct).length > 0
    ) {
      reasons.push(otherModel);
    }
  }
  return (
    <>
      <p>{message}</p>
      {reasons.length > 0 && (
        <ul>
          {reasons.map((r) => (
            <li key={r}>{r}</li>
          ))}
        </ul>
      )}
      {canRedirect && (
        <Link
          id="variant-creation-error"
          className="alk-clickable-icon--white alk-flex alk-flex-half-margin-horizontal"
          key="redirect"
          to={fill(routes.productPage, productKeyId)}
          target="_blank"
        >
          <span>{i18n.t('Check product')}</span>
          <i className="mdi mdi-open-in-new" />
        </Link>
      )}
    </>
  );
}

import { connect } from 'react-redux';

import { Checkbox } from '@alkem/react-ui-checkbox';

import Field from 'components/ui/form/field';

import './checkbox.scss';

const mapStateToProps = () => ({});

class FormCheckbox extends Field {
  static defaultProps = Object.assign({}, Field.defaultProps, {
    value: false,
  });

  constructor(props) {
    super(props);
    this.handleChange = this.handleChange.bind(this);
  }

  getValue(value) {
    return value;
  }

  render() {
    const { value, field } = this.props;
    if (!field) {
      return null;
    }

    return (
      <div className="InputCheckbox row">
        <label htmlFor={this.getId()} className="InputField__label col-xs-4">
          {field.label}
        </label>
        <div className="InputField__input col-xs-8">
          <Checkbox
            id={this.getId()}
            checked={value}
            onChange={this.handleChange}
            disabled={this.isReadOnly()}
            form
          />
        </div>
      </div>
    );
  }
}

export default connect(mapStateToProps)(FormCheckbox);

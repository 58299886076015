import { selectHasRetailerInternalMultiCategorizationFeature } from 'modules/feature-flag/selectors';

export const internalCategorizationFilter = {
  key: 'internalMultiCategorization.isConceptualizedBy.id',
  aggregation: {
    value_field: 'internalMultiCategorization.isConceptualizedBy.label.raw',
  },
  query: 'internalcat',
  isAvailable: ({ user } = {}) =>
    selectHasRetailerInternalMultiCategorizationFeature(user),
};

import PropTypes from 'prop-types';
import { PureComponent } from 'react';
import { connect } from 'react-redux';
import { createStructuredSelector } from 'reselect';

import { selectHasProductUnsynchronization } from 'modules/feature-flag/selectors';
import PublicationSummary from 'modules/publication-summary';
import { separateActions } from 'utils/redux';

import { openConfirmUnsyncModal, openUnavailableUnsyncModal } from '../actions';
import {
  selectConfirmUnsyncModalOpened,
  selectUnavailableUnsyncModalOpened,
} from '../selectors';

import ConfirmUnsyncModal from './modals/unsyncConfirm';
import UnavailableUnsyncModal from './modals/unsyncUnavailable';

const mapStateToProps = createStructuredSelector({
  hasUnsynchronization: selectHasProductUnsynchronization,

  confirmUnsyncModalOpened: selectConfirmUnsyncModalOpened,
  unavailableUnsyncModalOpened: selectUnavailableUnsyncModalOpened,
});

const mapDispatchToProps = {
  openConfirmUnsyncModal,
  openUnavailableUnsyncModal,
};

export class DashboardPublicationSummary extends PureComponent {
  static propTypes = {
    confirmUnsyncModalOpened: PropTypes.bool.isRequired,
    unavailableUnsyncModalOpened: PropTypes.bool.isRequired,
    hasUnsynchronization: PropTypes.bool.isRequired,
    actions: PropTypes.shape({
      openConfirmUnsyncModal: PropTypes.func.isRequired,
      openUnavailableUnsyncModal: PropTypes.func.isRequired,
    }).isRequired,
  };

  onRemoveRecipient = ({ recipient }) => {
    const { actions } = this.props;

    if (recipient.get('hasProductInActiveRange')) {
      if (recipient.get('hasProductChatEnabled')) {
        actions.openUnavailableUnsyncModal({ targetRecipient: recipient });
      }
      // In case the product is in active range and the retailer does not have the product chat,
      // do not allows the unsynchronization
    } else {
      actions.openConfirmUnsyncModal({ targetRecipient: recipient });
    }
  };

  canRemoveRecipient = (recipient) => {
    return (
      !!recipient &&
      !(
        recipient.get('hasProductInActiveRange') &&
        !recipient.get('hasProductChatEnabled')
      )
    );
  };

  render() {
    const {
      hasUnsynchronization,
      confirmUnsyncModalOpened,
      unavailableUnsyncModalOpened,
    } = this.props;
    return (
      <>
        <PublicationSummary
          canRemoveRecipient={
            hasUnsynchronization ? this.canRemoveRecipient : null
          }
          onRemoveRecipient={
            hasUnsynchronization ? this.onRemoveRecipient : null
          }
        />
        {confirmUnsyncModalOpened && <ConfirmUnsyncModal />}
        {unavailableUnsyncModalOpened && <UnavailableUnsyncModal />}
      </>
    );
  }
}

export default connect(
  mapStateToProps,
  mapDispatchToProps,
  separateActions,
)(DashboardPublicationSummary);

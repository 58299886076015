import { FormItem } from '@alkem/sdk-dashboard';

import {
  Data as LogisticalHierarchyData,
  VersionData,
} from 'modules/logistical-hierarchies/structures';
import { Diff } from 'types/diff';

export type ReviewSummary = {
  pending: boolean;
  product_key_id?: number;
};

export type FieldDiff = Diff & {
  product_key_id?: number;
  media_type?: 'document' | 'picture';
  media_id?: number;
};

export type Product = {
  id: number;
  version: object;
  gtin: string;
  product_key_id: number;
};

export type logisticalFormItem = {
  packaging_type_id: number;
  display_groups: FormItem[] | null;
};

export type DisplayGroups = {
  version?: FormItem[] | null;
  picture?: FormItem[] | null;
  video?: FormItem[] | null;
  enriched_content?: FormItem[] | null;
  document?: FormItem[] | null;
  listing?: FormItem[] | null;
  logistical_units?: logisticalFormItem[] | null;
};

export enum FieldToReviewStatus {
  UNKNOWN = 0,
  PENDING,
  VALIDATED,
  REJECTED,
  UPDATED,
}

export enum FieldToReviewEntityType {
  CONSUMER_UNIT = 1,
  PICTURE,
  VIDEO,
  ENRICHED_CONTENT,
  DOCUMENT,
  SHARING_UNIT,
  DISPLAY_UNIT,
  LOGISTICAL_UNIT,
}

export type FieldToReview = {
  id: number;
  name: string;
  status: FieldToReviewStatus;
  diffs: FieldDiff[];
  entity_id: number;
  entity_type: FieldToReviewEntityType;
  media_url?: string;
};

export enum BlockToReviewType {
  PRODUCT_INFORMATION = 1,
  LOGISTICAL_HIERARCHY,
  LISTING,
}

export type PriceWaterfall = {
  product: Product;
};

export type ListingData = {
  hierarchy?: LogisticalHierarchyData;
  priceWaterfalls: PriceWaterfall[] | null;
};

export type BlockToReviewData = {
  version?: VersionData;
  assets?: {
    pictures: object[];
    documents: object[];
    videos: object[];
    enriched_contents: object[];
  };
  logistical_hierarchy?: LogisticalHierarchyData;
  listing?: ListingData;
};

export enum BlockToReviewStatus {
  UNKNOWN = 0,
  REJECTED = 1,
  ACCEPTED = 2,
}

export type BlockToReview = {
  id: string;
  type: BlockToReviewType;
  fields: FieldToReview[];
  product_key_id?: number;
  data: BlockToReviewData;
  main_transaction_id: number;
  status: BlockToReviewStatus;
  name?: string;
};

export enum ActionToReviewStatus {
  UNKNOWN = 0,
  NOT_STARTED = 1,
  TO_REVIEW = 2,
  REVIEWED = 3,
  SKIPPED = 4,
  REVIEWING = 5,
  CHANGE_REQUESTED = 6,
  UPDATED = 7,
  VALIDATED = 8,
  REJECTED = 9,
}

export type ActionToReview = {
  state_id: number;
  status: ActionToReviewStatus;
  last_updated_at?: string | null;
  group_name?: string | null;
  blocks?: BlockToReview[] | null;
};

export type ReviewDetails = {
  actions: ActionToReview[];
  display_groups?: DisplayGroups;
};

export enum ConflictErrorCode {
  UNKOWN = 0,
  UPDATED_BY_MANUFACTURER = 1,
  REVIEW_CONFLICT = 2,
}

export type ProductReviewState = {
  isLoading: boolean | null;
  details: ReviewDetails | null;
  productKeyId: number | null;
  summary: ReviewSummary;
  currentAction: ActionToReview | null;
  currentConflictCode: ConflictErrorCode | null;
  rejectedFields?: { [fieldModel: string]: any[] };
};

export type ActionStatusSummary = {
  state_id: number;
  status: ActionToReviewStatus;
};

export type ReviewStatusSummary = {
  is_completed: boolean;
  actions: ActionStatusSummary[];
};

import { omit, set, update } from 'lodash/fp';
import { createReducer } from 'redux-create-reducer';

import {
  CREATED_PRICE_WATERFALL,
  CREATE_PRICE_WATERFALL,
  RECEIVE_BRACKET_TYPES,
  RECEIVE_LEVEL_ITEM_TYPES,
  RECEIVE_LEVEL_TYPES,
  RECEIVE_TAX_LABELS,
} from './events';

const initialState = {
  taxLabels: null,
  bracketTypeOptions: null,
  creatingForProduct: {},
};

export default createReducer(initialState, {
  [RECEIVE_TAX_LABELS]: (state, { taxLabels }) => ({
    ...state,
    taxLabels,
  }),
  [RECEIVE_BRACKET_TYPES]: (state, { bracketTypes }) => ({
    ...state,
    bracketTypes,
  }),
  [CREATE_PRICE_WATERFALL]: (state, { productId }) =>
    update('creatingForProduct', set(productId, true), state),
  [CREATED_PRICE_WATERFALL]: (state, { productId }) =>
    update('creatingForProduct', omit(productId), state),
  [RECEIVE_LEVEL_TYPES]: (state, { levelTypes }) => ({ ...state, levelTypes }),
  [RECEIVE_LEVEL_ITEM_TYPES]: (state, { levelItemTypes }) => ({
    ...state,
    levelItemTypes,
  }),
});

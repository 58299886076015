import classNames from 'classnames';
import { flow } from 'lodash/fp';
import PropTypes from 'prop-types';
import { PureComponent } from 'react';
import { connect } from 'react-redux';
import { createStructuredSelector } from 'reselect';

import { Tipster } from '@alkem/react-ui-tipster';

import { notificationError } from 'actions/notification';
import Anchor from 'components/ui/basic/anchor';
import Dropdown from 'components/ui/dropdown';
import Modal from 'components/ui/modal';
import * as lifeCycle from 'constants/filters/lifeCycle';
import { UNITED_KINGDOM } from 'constants/targetMarkets';
import { TypePackagingLabels } from 'constants/typePackaging';
import { getDisplayName, getTypePackagingId } from 'core/api/productversion';
import { AnchoredSection, AnchoredSectionItem } from 'modules/anchored';
import {
  DataOpsPatchesPropType,
  bulkPatchDataOpsField,
  patchDataOpsField,
  selectDataOpsPatches,
  selectHasDataOps,
  selectHasDataOpsRetailerHierarchy,
  selectIsDataOpsPatcher,
  selectIsDataOpsReceiver,
} from 'modules/data-ops';
import { selectAreDisplayGroupsEditable } from 'modules/display-groups/selectors';
import {
  selectHasDisplayContainingHierarchiesOnBURetailerRelease,
  selectHasFlatHierarchies,
  selectHasProductsWithoutGtinsReleased,
} from 'modules/feature-flag/selectors';
import { selectSharingUnitHierarchyIds } from 'modules/product-page/modules/retailer-sharing-units/selectors';
import { selectRFPHierarchyIds } from 'modules/product-page/modules/rfps/selectors';
import { selectAlreadyAgreedHierarchyIds } from 'modules/sharing-units/selectors';
import {
  isCustomFieldReadOnly,
  selectIsLoggedAs,
  selectIsRetailer,
} from 'modules/user';
import {
  selectCanPatchProduct,
  selectCanUpdateProduct,
  selectCurrentLanguage,
  selectIsHeterogeneousLogisticalUnit,
  selectTargetMarketId,
} from 'reducers/productVersion';
import i18n from 'utils/i18n';
import { get, size } from 'utils/immutable';
import { separateActions } from 'utils/redux';

import { getReference } from '../../core/api/product';

import * as moduleActions from './actions';
import { CollapsibleHierarchy } from './components/collapsible-hierarchy';
import CreateHierarchyModal from './components/create-modal';
import LogisticalHierarchyForm from './components/form';
import LogisticalHierarchy from './components/hierarchy';
import { SECTION_ICON, SECTION_TITLE } from './constants';
import { typePackagingOptions } from './helpers';
import './logistical-hierarchies.scss';
import * as selectors from './selectors';

const mapStateToProps = createStructuredSelector({
  hasFeatureNoGtin: selectHasProductsWithoutGtinsReleased,
  roots: selectors.selectEditedRoots,
  dataMap: selectors.selectEditedDataMap,
  hasMatureListing: selectors.selectShouldShowLogisticalHierarchies,
  hierarchyMap: selectors.selectEditedHierarchyMap,
  logisticalUnitsAsTree: selectors.getEditedLogisticalUnitsAsTree,
  currentVersionInfo: selectors.selectCurrentVersionInfo,
  currentLanguage: selectCurrentLanguage,
  editedUnit: selectors.selectEditedUnit,
  isRetailer: selectIsRetailer,
  isLoggedAs: selectIsLoggedAs,
  sharingUnitHierarchyIds: selectSharingUnitHierarchyIds,
  rfpHierarchyIds: selectRFPHierarchyIds,
  alreadyAgreedHierarchyIds: selectAlreadyAgreedHierarchyIds,
  targetMarketId: selectTargetMarketId,
  allDisplayGroups: selectors.selectFilteredDisplayGroups,
  canUpdateProduct: selectCanUpdateProduct,
  canPatchProduct: selectCanPatchProduct,
  areLogisticalUnitsReadOnly: isCustomFieldReadOnly('logisticalunit'),
  hasFlatHierarchies: selectHasFlatHierarchies,
  areDisplayGroupsEditable: selectAreDisplayGroupsEditable,
  patches: selectDataOpsPatches,
  hasDataOps: selectHasDataOps,
  hasDataOpsRetailerHierarchy: selectHasDataOpsRetailerHierarchy,
  isDataOpsPatcher: selectIsDataOpsPatcher,
  isDataOpsReceiver: selectIsDataOpsReceiver,
  expandedInternalIds: selectors.selectExpandedInternalIds,
  isHeterogeneousLogisticalUnit: selectIsHeterogeneousLogisticalUnit,
  hasDisplayContainingLHRetailerRelease:
    selectHasDisplayContainingHierarchiesOnBURetailerRelease,
});

const mapDispatchToProps = {
  notificationError,
  init: moduleActions.init,
  setEditedUnit: moduleActions.setEditedUnit,
  createUnit: moduleActions.createUnit,
  deleteUnit: moduleActions.deleteUnit,
  updateQuantity: moduleActions.updateQuantity,
  updateReference: moduleActions.updateReference,
  bulkUpdate: moduleActions.bulkUpdate,
  patchDataOpsField,
  bulkPatchDataOpsField,
  toggle: moduleActions.toggle,
};

const enhance = flow(
  connect(mapStateToProps, mapDispatchToProps, separateActions),
);

export class LogisticalHierarchies extends PureComponent {
  static propTypes = {
    hasFeatureNoGtin: PropTypes.bool,
    roots: PropTypes.array,
    dataMap: PropTypes.object.isRequired,
    hasMatureListing: PropTypes.bool.isRequired,
    hierarchyMap: PropTypes.object.isRequired,
    logisticalUnitsAsTree: PropTypes.array.isRequired,
    currentVersionInfo: PropTypes.object.isRequired,
    currentLanguage: PropTypes.object,
    editedUnit: PropTypes.object,
    isRetailer: PropTypes.bool.isRequired,
    isLoggedAs: PropTypes.bool,
    areLogisticalUnitsReadOnly: PropTypes.bool.isRequired,
    sharingUnitHierarchyIds: PropTypes.array.isRequired,
    rfpHierarchyIds: PropTypes.array.isRequired,
    alreadyAgreedHierarchyIds: PropTypes.array.isRequired,
    targetMarketId: PropTypes.number.isRequired,
    allDisplayGroups: PropTypes.object.isRequired,
    hasFlatHierarchies: PropTypes.bool,
    actions: PropTypes.shape({
      init: PropTypes.func.isRequired,
      setEditedUnit: PropTypes.func.isRequired,
      createUnit: PropTypes.func.isRequired,
      deleteUnit: PropTypes.func.isRequired,
      updateQuantity: PropTypes.func.isRequired,
      updateReference: PropTypes.func.isRequired,
      bulkUpdate: PropTypes.func.isRequired,
      notificationError: PropTypes.func.isRequired,
      patchDataOpsField: PropTypes.func.isRequired,
      bulkPatchDataOpsField: PropTypes.func.isRequired,
      toggle: PropTypes.func.isRequired,
    }).isRequired,
    canUpdateProduct: PropTypes.bool.isRequired,
    canPatchProduct: PropTypes.bool,
    order: PropTypes.number,
    areDisplayGroupsEditable: PropTypes.bool,
    patches: DataOpsPatchesPropType,
    hasDataOps: PropTypes.bool,
    hasDataOpsRetailerHierarchy: PropTypes.bool,
    isDataOpsPatcher: PropTypes.bool,
    isDataOpsReceiver: PropTypes.bool,
    expandedInternalIds: PropTypes.array.isRequired,
    isHeterogeneousLogisticalUnit: PropTypes.bool,
    hasDisplayContainingLHRetailerRelease: PropTypes.bool,
  };

  static defaultProps = {
    alreadyAgreedHierarchyIds: [],
    roots: [],
    order: 1000,
    isHeterogeneousLogisticalUnit: false,
  };

  state = { isCreateModalOpen: false, insertFirst: false };

  componentDidMount() {
    this.props.actions.init();
  }

  componentDidUpdate(prevProps) {
    if (
      getReference(prevProps.currentVersionInfo) !==
      getReference(this.props.currentVersionInfo)
    ) {
      this.props.actions.init();
    }
  }

  openCreateModal = (insertFirst) => () => {
    this.setState({ isCreateModalOpen: true, insertFirst });
  };

  closeCreateModal = () => {
    this.setState({ isCreateModalOpen: false });
  };

  onDeleteUnit = (parentInternalId, internalId, isUsedInAgreedSharingUnit) => {
    const { currentVersionInfo } = this.props;
    try {
      this.props.actions.deleteUnit(
        currentVersionInfo.gtin || currentVersionInfo.productIdentifier,
        parentInternalId,
        internalId,
        isUsedInAgreedSharingUnit,
      );
    } catch (err) {
      this.props.actions.notificationError(err.message);
    }
  };

  onOpenEditModal = (internalId, parentInternalId, rootInternalId) =>
    this.props.actions.setEditedUnit({
      internalId,
      parentInternalId,
      rootInternalId,
    });

  onCloseEditModal = () => this.props.actions.setEditedUnit(null);

  hasASharingUnit = (rootInternalId) => {
    const { dataMap, sharingUnitHierarchyIds } = this.props;

    return !sharingUnitHierarchyIds.includes(dataMap[rootInternalId].id);
  };

  hasActiveListing = (rootInternalId) => {
    const { dataMap } = this.props;

    return dataMap[rootInternalId].activeListing;
  };

  shouldRenderHierarchy = (rootInternalId) => {
    const {
      dataMap,
      isRetailer,
      rfpHierarchyIds,
      sharingUnitHierarchyIds,
      hasDisplayContainingLHRetailerRelease,
    } = this.props;

    const unit = dataMap[rootInternalId];

    return (
      !isRetailer ||
      unit.isPatch ||
      sharingUnitHierarchyIds.includes(unit.id) ||
      rfpHierarchyIds.includes(unit.id) ||
      (hasDisplayContainingLHRetailerRelease &&
        this.hasActiveListing(rootInternalId))
    );
  };

  canPatch() {
    const { hasDataOps, canPatchProduct, isDataOpsPatcher } = this.props;
    return isDataOpsPatcher && hasDataOps && canPatchProduct;
  }

  canAddPatchHierarchy() {
    const { hasDataOpsRetailerHierarchy } = this.props;
    return this.canPatch() && hasDataOpsRetailerHierarchy;
  }

  isReadOnly({ withForceEditable = false } = {}) {
    const {
      isRetailer,
      canUpdateProduct,
      areLogisticalUnitsReadOnly,
      areDisplayGroupsEditable,
    } = this.props;

    return (
      isRetailer ||
      (withForceEditable && areDisplayGroupsEditable
        ? false
        : !canUpdateProduct) ||
      areLogisticalUnitsReadOnly
    );
  }

  shouldLoadHierarchyModal() {
    const { currentVersionInfo } = this.props;
    return [TypePackagingLabels.EACH.id, TypePackagingLabels.PACK.id].includes(
      get(currentVersionInfo, 'typePackaging.id'),
    );
  }

  renderHierarchies = () => {
    const {
      hasFlatHierarchies,
      roots,
      hasDisplayContainingLHRetailerRelease,
      isRetailer,
    } = this.props;

    const relatedLHs = [];
    const sharingUnitLHs = [];

    for (const rootInternalId of roots) {
      if (this.hasASharingUnit(rootInternalId)) {
        relatedLHs.push(rootInternalId);
      } else {
        sharingUnitLHs.push(rootInternalId);
      }
    }

    const relatedLHsTitle = i18n.t(
      'frontproductstream.product_page.logistical_hierarchies.related_hierarchy_item',
      {
        defaultValue: 'Related logistical hierarchies',
      },
    );

    const sharingUnitLHsTitle = i18n.t(
      'frontproductstream.product_page.logistical_hierarchies.shared_hiearchy_item',
      {
        defaultValue: 'Logistical hierarchies shared on the product',
      },
    );

    const isRelatedLHsTitleShown =
      isRetailer && size(relatedLHs.filter(this.shouldRenderHierarchy)) > 0;
    const isSharingUnitLHsShown =
      isRetailer && size(sharingUnitLHs.filter(this.shouldRenderHierarchy)) > 0;

    return (
      <>
        {!hasFlatHierarchies &&
          size(roots.filter(this.shouldRenderHierarchy)) > 0 &&
          this.renderAddHierarchy(true, 1)}
        {!hasDisplayContainingLHRetailerRelease ? (
          roots.map(this.renderHierarchy)
        ) : (
          <>
            {isSharingUnitLHsShown ? (
              <span
                className="LogisticalHierarchies__hierarchyTitle"
                id="sharingUnitLHs"
              >
                {sharingUnitLHsTitle}
              </span>
            ) : null}
            {sharingUnitLHs.map(this.renderHierarchy)}
            {isRelatedLHsTitleShown ? (
              <span
                className="LogisticalHierarchies__hierarchyTitle"
                id="relatedLHs"
              >
                {relatedLHsTitle}
              </span>
            ) : null}
            {relatedLHs.map((v, i) =>
              this.renderHierarchy(v, sharingUnitLHs.length + i),
            )}
          </>
        )}
      </>
    );
  };

  renderHierarchy = (rootInternalId, index) => {
    const {
      hasFeatureNoGtin,
      dataMap,
      hierarchyMap,
      logisticalUnitsAsTree,
      currentVersionInfo,
      currentLanguage,
      targetMarketId,
      allDisplayGroups,
      hasFlatHierarchies,
      patches,
      hasDataOps,
      actions,
      isDataOpsPatcher,
      isDataOpsReceiver,
      expandedInternalIds,
      areLogisticalUnitsReadOnly,
    } = this.props;
    // For retailers, only display hierarchies included in the sharing units.
    if (!this.shouldRenderHierarchy(rootInternalId)) {
      return null;
    }

    const { updateQuantity, updateReference } = this.props.actions;
    const unit = dataMap[rootInternalId];
    const displayName = getDisplayName(unit.version, currentLanguage);
    const reference =
      getReference(unit) ||
      (hasFeatureNoGtin
        ? i18n.t(
            'frontproductstream.product_page.logistical_hierarchies.reference_default_value',
            {
              defaultValue: '(No Reference)',
            },
          )
        : i18n.t(
            'frontproductstream.product_page.logistical_hierarchies.gtin_default_value',
            {
              defaultValue: '(No GTIN)',
            },
          ));
    const isAgreed = this.props.alreadyAgreedHierarchyIds.includes(unit.id);
    const { isPatch = false } = unit;
    const readOnly =
      isPatch && this.canAddPatchHierarchy() ? false : this.isReadOnly();

    const props = {
      hasFeatureNoGtin,
      path: `logisticalHierarchies-${index}`,
      rootInternalId,
      dataMap,
      hierarchyMap,
      logisticalUnitsAsTree,
      currentVersionInfo,
      targetMarketId,
      onEdit: this.onOpenEditModal,
      onDelete: this.onDeleteUnit,
      onUpdateQuantity: updateQuantity,
      onUpdateReference: updateReference,
      isAgreed,
      readOnly,
      patches,
      hasDataOps:
        (isDataOpsPatcher && hasDataOps) ||
        (isDataOpsReceiver && Object.keys(patches).length > 0),
      onPatch: actions.patchDataOpsField,
      onBulkPatch: actions.bulkPatchDataOpsField,
      isPatchedHierarchy: isPatch,
      isDataOpsPatcher,
      isDataOpsReceiver,
      areLogisticalUnitsReadOnly,
    };

    if (hasFlatHierarchies) {
      return (
        <AnchoredSectionItem
          linked
          key={rootInternalId}
          section={SECTION_TITLE}
          blockId={`${rootInternalId}-${reference}`}
          blockName={`${reference} - ${displayName}`}
          position={index}
        >
          <CollapsibleHierarchy
            {...props}
            allDisplayGroups={allDisplayGroups}
            onBulkUpdate={this.props.actions.bulkUpdate}
            toggle={actions.toggle}
            expanded={expandedInternalIds.includes(rootInternalId)}
          />
        </AnchoredSectionItem>
      );
    }
    return (
      <AnchoredSectionItem
        linked
        key={rootInternalId}
        section={SECTION_TITLE}
        blockName={`${reference} - ${displayName}`}
        position={index}
      >
        <LogisticalHierarchy {...props} />
      </AnchoredSectionItem>
    );
  };

  renderAddRoot(insertFirst = false, index) {
    const { targetMarketId } = this.props;
    if (this.isReadOnly()) {
      return null;
    }
    const options = typePackagingOptions
      .filter((typePackaging) => {
        const isNotEach = typePackaging.id !== TypePackagingLabels.EACH.id;
        const isNotPallet = typePackaging.id !== TypePackagingLabels.PALLET.id;
        if (targetMarketId === UNITED_KINGDOM) {
          // we don't show pallet for manufacturer on GB targetmarket cf. EXP-1213
          return isNotEach && isNotPallet;
        }
        return isNotEach;
      })
      .map((typePackaging) => ({
        label: typePackaging.label,
        onClick: () =>
          this.props.actions.createUnit(
            null,
            '',
            '',
            {
              isConsumerUnit: false,
              isDespatchUnit: true,
              lifeCycle: lifeCycle.purchasable.id,
              typePackaging,
            },
            insertFirst,
          ),
      }));
    return (
      <div
        id={`logistical-hierarchy-add-root-${index}`}
        className="LogisticalHierarchies__addRoot"
      >
        <i className="mdi mdi-plus-circle" />
        <div className="LogisticalHierarchies__addRootLabel">
          {i18n.t(
            'frontproductstream.product_page.create_new_logistical_hierarchy.text',
            {
              defaultValue: 'Create a new hierarchy',
            },
          )}
        </div>
        <Dropdown
          label={i18n.t(
            'frontproductstream.product_page.highest_level_of_hierarchy.dropdown',
            {
              defaultValue: 'What is the highest level of the hierarchy?',
            },
          )}
          options={options}
        />
      </div>
    );
  }

  renderAddHierarchy(insertFirst, index) {
    const { isDataOpsPatcher, currentVersionInfo } = this.props;
    // No need to display this since a pallet cannot be added in anything.
    const isPallet =
      getTypePackagingId(currentVersionInfo) === TypePackagingLabels.PALLET.id;

    if ((isDataOpsPatcher && !this.canAddPatchHierarchy()) || isPallet) {
      return null;
    }

    if (this.shouldLoadHierarchyModal()) {
      const disabled = isDataOpsPatcher ? false : this.isReadOnly();
      const classes = classNames('BigDottedButton', {
        'BigDottedButton--disabled': disabled,
      });
      return (
        <button
          type="button"
          className={classNames(classes)}
          onClick={this.openCreateModal(insertFirst)}
          disabled={disabled}
        >
          <i className="mdi mdi-plus-circle" />
          {i18n.t(
            'frontproductstream.product_page.create_new_logistical_hierarchy.button',
            {
              defaultValue: 'Create a new logistical hierarchy',
            },
          )}
        </button>
      );
    }
    return this.renderAddRoot(insertFirst, index);
  }

  renderEditModal() {
    const {
      dataMap,
      currentVersionInfo,
      editedUnit,
      allDisplayGroups,
      hasFeatureNoGtin,
    } = this.props;
    if (!editedUnit) {
      return null;
    }

    const readOnly = this.isReadOnly();
    const { gtin } = dataMap[editedUnit.internalId];
    const { productIdentifier } = dataMap[editedUnit.internalId];
    const reference = gtin || productIdentifier;
    const data = dataMap[editedUnit.internalId].version;
    const title = reference
      ? `${getDisplayName(
          data,
          currentVersionInfo.currentLanguage,
        )} ${reference}`
      : getDisplayName(data, currentVersionInfo.currentLanguage);
    return (
      <Modal
        modalStyle="fullHeight"
        title={title}
        confirmButtonText={
          readOnly
            ? i18n.t(
                'frontproductstream.logistical_hierarchy_edit_modal.confirm_readonly.button',
                {
                  defaultValue: 'Close',
                },
              )
            : i18n.t(
                'frontproductstream.logistical_hierarchy_edit_modal.confirm_edit.button',
                {
                  defaultValue: 'OK',
                },
              )
        }
        onConfirm={this.onCloseEditModal}
        onClose={this.onCloseEditModal}
        hideCloseButton
      >
        <LogisticalHierarchyForm
          id="logistical-hierarchy-edit-modal"
          internalId={editedUnit.internalId}
          rootInternalId={editedUnit.rootInternalId}
          gtin={gtin}
          productIdentifier={productIdentifier}
          hasFeatureNoGtin={hasFeatureNoGtin}
          data={data}
          currentLanguage={currentVersionInfo.currentLanguage}
          readOnly={this.isReadOnly({ withForceEditable: true })}
          allDisplayGroups={allDisplayGroups}
        />
      </Modal>
    );
  }

  render() {
    const {
      hasMatureListing,
      order,
      roots,
      isRetailer,
      hasFlatHierarchies,
      areLogisticalUnitsReadOnly,
    } = this.props;

    if (!hasMatureListing) {
      return null;
    }

    // isRetailer instead of isReadOnly because GS1 support still wants to see
    // the disabled button
    if (isRetailer && size(roots) === 0) {
      return null;
    }

    const headerContent = !isRetailer
      ? {
          icon: 'eye-off',
          text: i18n.t(
            'frontproductstream.product_page.logistical_hierarchy.retailer_visibility',
            {
              defaultValue: 'Only visible to you',
            },
          ),
        }
      : null;

    return (
      <AnchoredSection
        collapsible
        section={SECTION_TITLE}
        icon={SECTION_ICON}
        order={order}
        header={headerContent}
      >
        <div
          className={classNames('LogisticalHierarchies', {
            LogisticalHierarchies__new: hasFlatHierarchies,
          })}
        >
          {!areLogisticalUnitsReadOnly && (
            <Tipster>
              <span>
                <Anchor
                  href={'https://help.salsify.com/SupplierXM/s/article/97'}
                >
                  {i18n.t(
                    'frontproductstream.product_page.logistical_hierarchy_documentation.help',
                    {
                      defaultValue: 'Check our documentation',
                    },
                  )}
                </Anchor>{' '}
                {i18n.t(
                  'frontproductstream.product_page.logistical_hierarchy_documentation.text',
                  {
                    defaultValue:
                      'to learn how to create a logistical hierarchy.',
                  },
                )}
              </span>
            </Tipster>
          )}
          {this.renderHierarchies()}
          {this.renderAddHierarchy(false, 2)}
          {this.renderEditModal()}
          {this.shouldLoadHierarchyModal() && this.state.isCreateModalOpen && (
            <CreateHierarchyModal
              insertFirst={this.state.insertFirst}
              onClose={this.closeCreateModal}
              isPatch={this.canPatch()}
            />
          )}
        </div>
      </AnchoredSection>
    );
  }
}

export default enhance(LogisticalHierarchies);

export const FETCH_DISPLAY_GROUPS = 'FETCH_DISPLAY_GROUPS';
export const RECEIVE_DISPLAY_GROUPS = 'RECEIVE_DISPLAY_GROUPS';
export const RESET_DISPLAY_GROUPS = 'RESET_DISPLAY_GROUPS';
export const RESET_DISPLAY_GROUPS_READONLY = 'RESET_DISPLAY_GROUPS_READONLY';
export const OPEN_FORM_GROUP = 'OPEN_FORM_GROUP';
export const CLOSE_FORM_GROUP = 'CLOSE_FORM_GROUP';
export const CLOSE_ALL_FORM_GROUPS = 'CLOSE_ALL_FORM_GROUPS';
export const FORCE_EDITABLE_DISPLAY_GROUPS = 'display-groups/force-editable';
export const RESET_EDITABLE_DISPLAY_GROUPS = 'display-groups/reset-editable';
export const SELECTED_HETEROGENEOUS_UNIT =
  'type-packaging/SELECTED_HETEROGENEOUS_UNIT';
export const RESET_SELECTED_HETEROGENEOUS_UNIT =
  'type-packaging/RESET_SELECTED_HETEROGENEOUS_UNIT';

import { get, getFp } from 'utils/immutable';

import BoolCell from '../bool';
import DateCell from '../date';
import EmptyCell from '../empty';
import ListNumberCell from '../number';
import ReferentialCell from '../referential';
import TextCell from '../text';

// Basic selectors
export const selectId = getFp('id');
export const selectCode = getFp('code');
export const selectLabel = getFp('label');
export const selectData = getFp('data');
export const selectPath = getFp('data.path');
export const selectIsLocalized = getFp('data.localized');
export const selectType = getFp('data.type');
export const selectDefault = getFp('data.default');
export const selectSort = getFp('data.sort');
export const selectWidth = getFp('data.width');
export const selectIsBold = getFp('data.bold');
export const selectHeader = getFp('data.header');

export const craftCell = (column) => {
  const props: any = {
    code: selectCode(column),
    maxWidth: selectWidth(column),
  };

  let Cell;
  switch (selectType(column)) {
    case 'text':
      props.isBold = selectIsBold(column);
      Cell = TextCell;
      break;
    case 'bool':
      Cell = BoolCell;
      break;
    case 'referential':
      Cell = ReferentialCell;
      break;
    case 'date':
      Cell = DateCell;
      break;
    case 'number':
      Cell = ListNumberCell;
      break;
    default:
      Cell = EmptyCell;
  }
  return { Cell, props };
};

export const craftData = (column, row) => {
  const path = selectPath(column) || '';
  const data = get(row, path);
  if (
    data == null ||
    data === '' ||
    (Array.isArray(data) && data.length === 0)
  ) {
    return { data: selectDefault(column), isDefaultData: true };
  }
  return { data, isDefaultData: false };
};

import PropTypes from 'prop-types';
import { Component } from 'react';

import { Tooltip } from '@alkem/react-ui-tooltip';

import Picture from 'components/ui/picture';
import i18n from 'utils/i18n';

import './selectable-image.scss';

class SelectableImage extends Component {
  static propTypes = {
    element: PropTypes.object.isRequired,
    kind: PropTypes.string.isRequired,
    onClick: PropTypes.func.isRequired,
    selected: PropTypes.bool,
    disabled: PropTypes.bool,
    readOnly: PropTypes.bool,
  };

  static defaultProps = {
    selected: false,
    disabled: false,
    readOnly: false,
  };

  constructor(props) {
    super(props);
    this.state = {};
    this.onClick = this.onClick.bind(this);
  }

  onClick() {
    if (this.props.readOnly) {
      return;
    }
    if (!this.props.selected && this.props.disabled) {
      return;
    }
    this.props.onClick(this.props.element);
  }

  renderPicture(element) {
    if (!element.id) {
      return null;
    }
    return <Picture kind={this.props.kind} entity={element} />;
  }

  renderLabel(element) {
    const { selected, disabled, readOnly } = this.props;
    let classSuffix = '';
    const labelArray = element.label.split(' - ');
    if (selected) {
      classSuffix = '--active';
    } else if (disabled || readOnly) {
      classSuffix = '--disabled';
    }
    return labelArray.length === 2 ? (
      <>
        <div className={`SelectableImage__label__code${classSuffix}`}>
          {labelArray[0]}
        </div>
        <div className={`SelectableImage__label__text${classSuffix}`}>
          {labelArray[1]}
        </div>
      </>
    ) : (
      <div className={`SelectableImage__label__text${classSuffix}`}>
        {element.label}
      </div>
    );
  }

  render() {
    const { element, selected, kind, disabled, readOnly } = this.props;
    const tooltipId = `${kind}-${element.id}`;
    let classSuffix = '';
    if (selected) {
      classSuffix = '--active';
    } else if (disabled || readOnly) {
      classSuffix = '--disabled';
    }

    return (
      <div
        className={`SelectableImage${
          this.props.readOnly ? '--disabled' : classSuffix
        }`}
        onClick={this.onClick}
        data-tip={element.description}
        data-for={tooltipId}
      >
        {element.description ? <Tooltip place="bottom" id={tooltipId} /> : null}
        <div
          className={`SelectableImage__imageContainer${classSuffix}`}
          data-tip={element.description}
          data-for={element.id}
        >
          {this.renderPicture(element)}
        </div>
        <div className={`SelectableImage__label${classSuffix}`}>
          {this.renderLabel(element)}
        </div>
        <div className={`SelectableImage__add${classSuffix}`}>
          <i className="mdi mdi-plus-circle" />
          <span>
            {i18n.t('frontproductstream.image_checkbox.add_button.label', {
              defaultValue: 'Add',
            })}
          </span>
        </div>
      </div>
    );
  }
}

export default SelectableImage;

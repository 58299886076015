import { createSelector } from '@reduxjs/toolkit';

import { getDisplayName } from 'core/api/productversion';
import { selectSharableLogisticalHierarchies } from 'modules/logistical-hierarchies/selectors';
import { selectCurrentLanguage } from 'reducers/productVersion';

import { getReference } from '../../../../../../../core/api/product';

export const getFormattedList = createSelector(
  selectSharableLogisticalHierarchies,
  selectCurrentLanguage,
  (list, currentLanguage) => {
    const formattedList = list.map((unit) => {
      const label = `${getReference(unit)} - ${getDisplayName(
        unit.version,
        currentLanguage,
      )}`;
      return {
        value: getReference(unit),
        label,
        id: unit.id,
        referenceType: unit.gtin ? 'gtin' : 'productIdentifier',
      };
    });
    return formattedList;
  },
);

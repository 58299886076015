export const COLLAPSE_FILTERS = 'reporting-generic/collapse-filters';
export const UPDATE_FILTERED_FILTERS =
  'reporting-generic/update-filtered-filters';
export const UPDATE_FILTERS_PAGINATION =
  'reporting-generic/update-filters-pagination';
export const UPDATE_ITEMS_PER_PAGE = 'reporting-generic/update-items-per-page';
export const FETCH_REPORT = 'reporting-generic/fetch-report';
export const RECEIVE_REPORT = 'reporting-generic/receive-report';
export const PREVIOUS_PAGE = 'reporting-generic/previous-page';
export const NEXT_PAGE = 'reporting-generic/next-page';
export const UPDATE_SELECTED_FILTERS =
  'reporting-generic/update-selected-filters';
export const FETCH_PRODUCTS_AGGREGATIONS =
  'reporting-generic/fetch-products-aggreg';
export const RECEIVE_PRODUCTS_AGGREGATIONS =
  'reporting-generic/receive-products-aggreg';
export const SORT_REPORT = 'reporting-generic/sort-report';
export const EXPORT_REPORT = 'reporting-generic/export-report';
export const EXPORT_REPORT_DONE = 'reporting-generic/export-report-done';
export const SELECT_REPORT = 'reporting-generic/select-report';
export const RESET = 'reporting-generic/reset';
export const UPDATE_WITH_ARCHIVED_PRODUCTS =
  'reporting-generic/update-with-archived-products';
export const UPDATE_ONLY_DUPLICATED_PRODUCTS =
  'reporting-generic/update-only-duplicated-products';
export const REDIRECT_TO_CATALOG = 'reporting-generic/redirect-to-catalog';
export const SAVE_REPORT_CONFIG = 'reporting-generic/save-report-config';
export const START_REPORTING_LOADER =
  'reporting-generic/start-reporting-loader';
export const STOP_REPORTING_LOADER = 'reporting-generic/stop-reporting-loader';

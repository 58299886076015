import { List as ImmutableList } from 'immutable';
import { call, put } from 'redux-saga/effects';

import { notificationError, notificationSuccess } from 'actions/notification';
import { bulkActivateRecipients } from 'resources/serviceProductApi';
import { ActionWithPayload } from 'types';
import { i18n } from 'utils/i18n';

import { BulkActivateRecipientsActionPayload } from '../actions';

import { prepareParameters } from './list';

export function* bulkActivateRecipientsSaga(
  action: ActionWithPayload<BulkActivateRecipientsActionPayload>,
) {
  const { payload = { recipientIds: [] } } = action;
  const { recipientIds } = payload;

  const {
    advancedSearch: { advancedSearch },
  } = yield call(prepareParameters, {
    referentials: ImmutableList(),
  });

  try {
    yield call(bulkActivateRecipients, advancedSearch, recipientIds);
    yield put(notificationSuccess(i18n.t('Recipient activation in progress')));
  } catch (error) {
    yield put(notificationError(i18n.t('Recipient activation has failed')));
  }
}

// TODO remove (replace with lodash)
export const curry = function (fn, n) {
  const arity = n || fn.length;
  return function curried(...args) {
    const fnArgs = args;
    return args.length >= arity
      ? fn.apply(this, fnArgs)
      : (...rest) => curried.apply(this, fnArgs.concat(rest));
  };
};

import { registerModuleReducer } from 'utils/modules/reducer';
import { registerSaga } from 'utils/modules/saga';

import { REDUCER_KEY } from './constants';
import mainReducer from './reducer';
import mainSaga from './sagas';
import SpecificFieldsSection from './view';

registerSaga(mainSaga);
registerModuleReducer(REDUCER_KEY, mainReducer);

export default SpecificFieldsSection;

import asyncComponent from 'core/modules/async/component';
import registerModule from 'core/modules/async/register';

export const RFP = asyncComponent(async () =>
  registerModule(await import(/* webpackChunkName: "rfp" */ './async')),
);

export { hasRfpForManufacturer, hasRfpForRetailer } from './selectors';
export { list as fetchRfpEvents } from './modules/events/api';
export type { Organization } from './types';
export { fetchHasRfpModule, selectHasRfpModule } from './modules/userInfo';

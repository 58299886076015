import PropTypes from 'prop-types';
import { PureComponent } from 'react';
import ImmutablePropTypes from 'react-immutable-proptypes';

import { isManufacturer } from 'core/api/user';
import ItemsPerPage from 'core/modules/list/components/actions/items-per-page';
import {
  ListActions,
  ListLeftActions,
  ListRightActions,
} from 'core/modules/list/components/actions/layout';
import { ListPaginationButtons } from 'core/modules/list/components/actions/pagination/buttons';
import SelectAll from 'core/modules/list/components/actions/select-all';
import { PromoteProductsButton } from 'modules/promote-products-modal';
import { TargetCatalogImportModalButton } from 'modules/target-catalog-import';

import {
  ARCHIVED_PRODUCTS,
  PRODUCTS,
  PRODUCTS_WITH_MENU,
  SOURCING,
} from '../../../constants/context';

import CatalogAdminActions from './admin';
import CatalogBulkAction from './bulk-action';
import CatalogColumnSelector from './column-selector';
import { CatalogExportButton } from './export';
import CatalogImportButton from './import-button';
import './index.scss';
import SourcingBulkAction from './sourcing-bulk-action';

export default class CatalogActions extends PureComponent {
  static propTypes = {
    user: ImmutablePropTypes.map.isRequired,
    productMap: ImmutablePropTypes.map.isRequired,
    selectedMap: ImmutablePropTypes.map.isRequired,
    columnMap: ImmutablePropTypes.map.isRequired,
    pagination: ImmutablePropTypes.map.isRequired,
    referentials: ImmutablePropTypes.list.isRequired,
    filtersQueryMap: PropTypes.object,
    context: PropTypes.shape({}).isRequired,
    onPreviousPage: PropTypes.func.isRequired,
    onNextPage: PropTypes.func.isRequired,
    onSelectAllProduct: PropTypes.func.isRequired,
    onChangeColumn: PropTypes.func.isRequired,
    onChangeItemsPerPage: PropTypes.func.isRequired,
  };

  static defaultProps = {
    context: PRODUCTS,
  };

  render() {
    const {
      user,
      pagination,
      productMap,
      selectedMap,
      onSelectAllProduct,
      referentials,
      columnMap,
      filtersQueryMap,
      context,
      onChangeColumn,
      onChangeItemsPerPage,
      onPreviousPage,
      onNextPage,
    } = this.props;
    const totalResults = pagination.get('total');
    return (
      <ListActions>
        <ListLeftActions>
          {[PRODUCTS, PRODUCTS_WITH_MENU, ARCHIVED_PRODUCTS].includes(
            context,
          ) && (
            <>
              <SelectAll
                entityMap={productMap}
                selectedMap={selectedMap}
                onSelectAll={onSelectAllProduct}
              />
              {!!totalResults && (
                <CatalogExportButton
                  user={user}
                  selectedMap={selectedMap}
                  productMap={productMap}
                  nbFilteredProducts={totalResults}
                />
              )}
              <CatalogImportButton user={user} />
              <TargetCatalogImportModalButton />
              <CatalogBulkAction
                user={user}
                productMap={productMap}
                selectedMap={selectedMap}
                filtersQueryMap={filtersQueryMap}
                nbFilteredProducts={totalResults}
              />
              <CatalogAdminActions
                user={user}
                productMap={productMap}
                selectedMap={selectedMap}
              />
            </>
          )}
          {context === SOURCING && (
            <>
              <SelectAll
                entityMap={productMap}
                selectedMap={selectedMap}
                onSelectAll={onSelectAllProduct}
              />
              <SourcingBulkAction
                user={user}
                productMap={productMap}
                selectedMap={selectedMap}
              />
              {isManufacturer(user) && <PromoteProductsButton />}
            </>
          )}
        </ListLeftActions>
        <ListRightActions>
          <CatalogColumnSelector
            referentials={referentials}
            columnMap={columnMap}
            onChange={onChangeColumn}
          />
          <ItemsPerPage
            itemsPerPage={pagination.get('limit')}
            onChange={onChangeItemsPerPage}
          />
          <ListPaginationButtons
            pagination={pagination}
            onPrevious={onPreviousPage}
            onNext={onNextPage}
          />
        </ListRightActions>
      </ListActions>
    );
  }
}

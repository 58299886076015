import { get } from 'lodash';
import { select } from 'redux-saga/effects';

import { getPathname, getQuery, replace } from 'utils/history';
import { withQuery } from 'utils/query';

import { filterKeyMap, filterQueryMap } from '../constants/filters';
import { selectSearch, selectSelectedFilterList } from '../selectors';

export function* updateQueryParams() {
  const selectedFilters = yield select(selectSelectedFilterList);
  const search = yield select(selectSearch);
  const currentQueries = getQuery() || {};
  const queryToKeep = Object.keys(currentQueries).reduce((dict, key) => {
    if (key in filterQueryMap || key === 'search') {
      return dict;
    }
    return Object.assign(dict, { [key]: currentQueries[key] });
  }, {});
  const queries = selectedFilters.reduce((dict, filter) => {
    const filterKey = filter.get('key');
    const query = get(filterKeyMap, [filterKey, 'query']);
    if (query) {
      const valuePath = get(filterKeyMap, [filterKey, 'valuePath']) || 'value';
      const formatValue =
        get(filterKeyMap, [filterKey, 'formatQueryValues']) ||
        ((value, not) => (not ? `-${value}` : value));
      const currentValues = get(dict, query) || [];
      Object.assign(dict, {
        [query]: [
          ...currentValues,
          formatValue(filter.get(valuePath), filter.get('not')),
        ],
      });
    }
    return dict;
  }, queryToKeep);
  if (search) {
    queries.search = search;
  }
  const pathname = getPathname();
  if (withQuery(pathname, queries) !== withQuery(pathname, currentQueries)) {
    replace(pathname, queries);
  }
}

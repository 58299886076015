import PropTypes from 'prop-types';
import { PureComponent } from 'react';
import ImmutablePropTypes from 'react-immutable-proptypes';
import { connect } from 'react-redux';
import { createStructuredSelector } from 'reselect';

import { Tooltip } from '@alkem/react-ui-tooltip';

import { selectIsManufacturer } from 'modules/user';
import { selectValidationResultsForViewAsSettings } from 'modules/validation';
import { selectPrimaryRecipients } from 'modules/view-as/selectors';
import i18n from 'utils/i18n';

import './synchronization-error.scss';

const mapStateToProps = createStructuredSelector({
  primaryRecipients: selectPrimaryRecipients,
  validationData: selectValidationResultsForViewAsSettings,
  isManufacturer: selectIsManufacturer,
});

export class SynchronizationError extends PureComponent {
  static propTypes = {
    primaryRecipients: ImmutablePropTypes.list.isRequired,
    validationData: ImmutablePropTypes.map,
    isManufacturer: PropTypes.bool,
  };

  getRecipientName(recipientId) {
    const { primaryRecipients } = this.props;

    const recipient = primaryRecipients.find(
      (r) => r.get('id') === recipientId,
    );
    if (recipient && recipient.get('label')) {
      return recipient.get('label');
    }

    return i18n.t('one retailer');
  }

  getRecipientNames(recipientIds) {
    const { primaryRecipients } = this.props;

    return primaryRecipients
      .filter((r) => recipientIds.includes(r.get('id')))
      .map((r) => r.get('label'));
  }

  render() {
    const { primaryRecipients, validationData, isManufacturer } = this.props;

    if (
      !isManufacturer ||
      !validationData ||
      !validationData.get('recipients')
    ) {
      return null;
    }

    const primaryRecipientIds = primaryRecipients.map((r) => r.get('id'));

    const blockedRecipientIds = validationData
      .get('recipients')
      .filter((r) => primaryRecipientIds.includes(r.get('id')))
      .filter((r) => r.get('failedBlocking') && r.get('failedBlocking') > 0)
      .map((r) => r.get('id'));

    if (!blockedRecipientIds || !blockedRecipientIds.size) {
      return null;
    }

    return (
      <div className="SynchronizationError">
        <i className="mdi mdi-cancel" />
        {i18n.t('Validation failed - your product cannot be sent to:')}{' '}
        {blockedRecipientIds.size === 1 ? (
          <span>{this.getRecipientName(blockedRecipientIds.get(0))}</span>
        ) : (
          <span>
            <span
              data-for="unsynced-recipients"
              data-tip
              className="SynchronizationError__multiRecipients"
            >
              {i18n.t('{{n}} retailers', { n: blockedRecipientIds.size })}
            </span>
            <Tooltip id="unsynced-recipients">
              <div>
                {primaryRecipients
                  .filter((r) => blockedRecipientIds.includes(r.get('id')))
                  .map((r) => <div key={r.get('id')}>{r.get('label')}</div>)
                  .toArray()}
              </div>
            </Tooltip>
          </span>
        )}
      </div>
    );
  }
}

export default connect(mapStateToProps)(SynchronizationError);

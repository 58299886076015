import PropTypes from 'prop-types';

import { getSharingUnitStatus } from 'utils/sharingUnitStatus';

function SharingUnitStatus({ sharingUnit }) {
  const status = getSharingUnitStatus(sharingUnit);

  return (
    <div className="SharingUnit__Status">
      <div className={status.className}>{status.name}</div>
    </div>
  );
}

SharingUnitStatus.propTypes = {
  sharingUnit: PropTypes.object,
};

export default SharingUnitStatus;

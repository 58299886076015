import { MODULE_NAME } from './constants';

export const OPEN_MODAL = `${MODULE_NAME}/OPEN_MODAL`;
export const CLOSE_MODAL = `${MODULE_NAME}/CLOSE_MODAL`;

export const FETCH_SUPPLIER_NOT_RECONCILED = `${MODULE_NAME}/FETCH_SUPPLIER_NOT_RECONCILED`;
export const FETCH_SUPPLIER_NOT_RECONCILED_SUCCESS = `${MODULE_NAME}/FETCH_SUPPLIER_NOT_RECONCILED_SUCCESS`;
export const GET_REFUSED_INFORMATION = `${MODULE_NAME}/GET_REFUSED_INFORMATION`;

// Send message modal
export const SEND_MESSAGE_DONE = `${MODULE_NAME}/SEND_MESSAGE_DONE`;
export const SEND_MESSAGE_START = `${MODULE_NAME}/SEND_MESSAGE_START`;
export const SEND_MESSAGE_IN_PROGRESS = `${MODULE_NAME}/SEND_MESSAGE_IN_PROGRESS`;
export const UPDATE_MESSAGE = `${MODULE_NAME}/UPDATE_MESSAGE`;

import PropTypes from 'prop-types';
import { Component } from 'react';

import DropdownTreeNode from 'components/ui/dropdown-tree/node';

class DropdownTree extends Component {
  static propTypes = {
    entities: PropTypes.array.isRequired,
    onSelectionChange: PropTypes.func.isRequired,
    leafLabels: PropTypes.array,
    compulsory: PropTypes.bool,
    labelKey: PropTypes.string,
  };

  static defaultProps = {
    leafLabels: [],
    labelKey: 'label',
    compulsory: false,
  };

  constructor(props) {
    super(props);
    this.onSelectedSegmentChange = this.onSelectedSegmentChange.bind(this);
  }

  onSelectedSegmentChange(openSegmentId) {
    this.props.onSelectionChange(openSegmentId);
  }

  render() {
    const { entities, leafLabels, labelKey, compulsory } = this.props;
    return (
      <div className="DropdownTree">
        <DropdownTreeNode
          onSelectionChange={this.onSelectedSegmentChange}
          childPosition={DropdownTreeNode.POSITION_HORIZONTAL}
          entities={entities}
          leafLabels={leafLabels}
          compulsory={compulsory}
          labelKey={labelKey}
        />
      </div>
    );
  }
}

export default DropdownTree;

import { useDispatch } from 'react-redux';

import {
  MFE_CLEAR_NOTIFICATIONS_EVENT,
  MFE_NOTIFICATION_EVENT,
  NotificationContextEnum,
  NotificationType,
  NotificationTypeEnum,
  useSubscribe,
} from '@alkem/micro-frontend-tools';

import {
  notificationClear,
  notificationError,
  notificationSuccess,
} from 'actions/notification';
import { RETRY_OR_CONTACT_SUPPORT } from 'constants/errors';

export function NotificationListener() {
  const dispatch = useDispatch();

  useSubscribe<NotificationType>(
    MFE_NOTIFICATION_EVENT,
    ({
      message,
      type = NotificationTypeEnum.SUCCESS,
      context = NotificationContextEnum.DEFAULT,
      persistent = false,
      error,
    }) => {
      switch (type) {
        case NotificationTypeEnum.SUCCESS:
          dispatch(
            notificationSuccess(message, {
              context,
              sticky: persistent,
            }),
          );
          break;
        case NotificationTypeEnum.ERROR:
          dispatch(
            notificationError(message || RETRY_OR_CONTACT_SUPPORT, {
              context,
              sticky: persistent,
              error,
            }),
          );
          break;
      }
    },
  );

  useSubscribe<void>(MFE_CLEAR_NOTIFICATIONS_EVENT, () => {
    dispatch(notificationClear());
  });

  return null;
}

import PropTypes from 'prop-types';

import { getFromStatusId } from 'constants/physicalCheckerRetailerStatus';
import i18n from 'utils/i18n';

export default function PhysicalCheckerRetailerStatus({ data }) {
  if (data == null) {
    return (
      <div className="physicalCheckerRetailerStatus">
        {i18n.t('Not available')}
      </div>
    );
  }

  const physicalCheckerRetailerStatusData = getFromStatusId(data);

  return (
    <div>
      {physicalCheckerRetailerStatusData
        ? physicalCheckerRetailerStatusData.name
        : i18n.t('Not available')}
    </div>
  );
}

PhysicalCheckerRetailerStatus.propTypes = {
  data: PropTypes.number,
};

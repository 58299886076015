import { RaguelStatic } from 'components/ui/form/plugins/validator/static';
import { RuleApplicationStatus } from 'modules/validation/constants';

import { ValidationResult } from './types';
import { getStatusDisplayText } from './utils';

const renderStatus = (status: number) => {
  const className = {
    [RuleApplicationStatus.OK]: 'text-success',
    [RuleApplicationStatus.KO]: 'text-danger',
    [RuleApplicationStatus.NOT_EXECUTED]: 'text-info',
    [RuleApplicationStatus.BYPASSED]: '',
    [RuleApplicationStatus.ERROR]: 'text-danger',
  };

  return (
    <span className={className[status] || null}>
      {getStatusDisplayText(status) || status}
    </span>
  );
};

const getRuleLineHeadingText = (
  ruleID: string,
  ruleSetID?: string,
  ruleSetLabel?: string,
): string => {
  const ruleIDText = `Rule: ${ruleID}`;
  const ruleSetText =
    'Ruleset: ' + (ruleSetID ? `${ruleSetID} (${ruleSetLabel})` : 'N/A');

  return ruleIDText + ', ' + ruleSetText;
};

export const AdminRuleResultRow = ({
  ruleResult,
}: {
  ruleResult: ValidationResult;
}) => {
  const { id, ruleSet, status, root_fields, errorMessage, exception_message } =
    ruleResult;

  return (
    <div className="AdminRuleResultRow">
      <div>{getRuleLineHeadingText(id, ruleSet?.id, ruleSet?.label)}</div>
      <div>Status: {renderStatus(status)}</div>
      <div>Fields: {root_fields?.join(', ')}</div>
      <div>Message: {errorMessage ? errorMessage : ''}</div>
      {exception_message && (
        <div data-testid="admin-rule-result-row-exception">
          <RaguelStatic message={exception_message} />
        </div>
      )}
    </div>
  );
};

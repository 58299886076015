import { useDispatch } from 'react-redux';

import BulkActionModal from 'modules/bulk-action-modal';
import RowComponent from 'modules/bulk-action-modal/components/rowComponent';
import i18n from 'utils/i18n';

import { bulkUnshareStart } from '../actions';
import { ProductVersion } from '../types';

type Props = {
  rowComponent: RowComponent<ProductVersion>;
};

const UnshareProducts = (props: Props) => {
  const { rowComponent } = props;

  const dispatch = useDispatch();

  const modalProps = {
    retryOption: false,
    rowComponent,
    bulkEntities: () => {
      // start the job of unsharing products
      dispatch(bulkUnshareStart());
    },
    title: i18n.t('frontproductstream.bulk_unshare.modal_title.text', {
      defaultValue: 'Unshare',
    }),
    errorLabel: (count: number) =>
      i18n.t('frontproductstream.bulk_unshare.unshare_failed.error', {
        defaultValue: 'We have encountered an error while trying to unshare.',
        count,
      }),
    buttonLabel: (count: number) =>
      i18n.t('frontproductstream.bulk_unshare.unshare.button', {
        defaultValue: 'Unshare',
        count,
      }),
    contentLabel: (count: number) =>
      i18n.t('frontproductstream.bulk_unshare.confirm_unshare.button', {
        defaultValue: 'Are you sure you want to unshare this product ?',
        count,
      }),
  };

  return <BulkActionModal {...modalProps} />;
};

export default UnshareProducts;

import { isArray } from 'lodash';

import { Image } from 'components/ui/image';
import { Diff } from 'types/diff';
import i18n from 'utils/i18n';
import { get } from 'utils/immutable';

import './label.scss';
import { DiffList } from './list';

type Props = {
  diff: Diff;
  cause?: {
    [key: string]: any;
  };
  className?: string;
};

export const DiffLabel = ({ diff, cause, className = 'DiffLabel' }: Props) => {
  const pictureURL = diff.pictureURL;

  let label = isArray(diff.label) ? diff.label : [diff.label];

  // TODO: Remove that once Product will be able to handle translations
  // Tech initiative:
  // https://alkemics.atlassian.net/wiki/spaces/TECH/pages/1472528478/2020-01-13+Use+the+translations+in+service-product
  if (diff.model === 'is_in_active_range') {
    label = [i18n.t('Active range')];
  }

  if (cause && cause.action === 'specific_data') {
    const retailer = get(cause, ['targetOrganization', 'nameLegal']);
    if (retailer) {
      label.push(i18n.t('({{retailer}} specific)', { retailer }));
    }
  }

  if (pictureURL) {
    return (
      <div className={`DiffLabel__complexLabel`}>
        <div>
          <Image maxWidth={64} maxHeight={64} src={pictureURL} />
        </div>
        <div className={`DiffLabel__labelGroup`}>
          <DiffList label={label} type="div" labelClassName={className} />
        </div>
      </div>
    );
  }
  return <DiffList label={label} type="div" labelClassName={className} />;
};

import classNames from 'classnames';
import { PureComponent } from 'react';

import Raguel from 'components/ui/form/plugins/validator';
import { ENTITY_TYPE_PRODUCT_VERSION_HIERARCHY } from 'constants/entities';
import {
  TypePackagingLabels,
  getTypePackagingCode,
} from 'constants/typePackaging';
import { getTypePackaging, getTypePackagingId } from 'core/api/productversion';
import i18n from 'utils/i18n';
import { toJsIfImmutable } from 'utils/immutable';

import { getReference } from '../../../../core/api/product';
import { getMainHierarchyUnit, isConsumerOrDisplayUnit } from '../../helpers';
import { DataMap, HierarchyMap, MainHierarchyUnit } from '../../structures';
import LogisticalHierarchyCreateUnitDropdown from '../create-unit';
import GTINGenerator from '../gtin-generator';
import LogisticalHierarchyUnit from '../unit';

import './logistical-hierarchy.scss';

type Props = {
  hasFeatureNoGtin?: boolean;
  path?: string;
  currentVersionInfo: any;
  rootInternalId: string;
  dataMap: DataMap;
  hierarchyMap: HierarchyMap;
  logisticalUnitsAsTree?: any[];
  isAgreed?: boolean;
  readOnly: boolean;
  targetMarketId?: number;
  onEdit?: (internalId?: string, data?: any, rootInternalId?: string) => void;
  onDelete?: (data?: any) => void;
  onUpdateQuantity?: (data?: any) => void;
  onUpdateReference?: (data?: any) => void;
  hasFlatHierarchies?: boolean;
  isPatchedHierarchy?: boolean;
  withErrorBlock?: boolean;
};

export class LogisticalHierarchy extends PureComponent<Props> {
  static defaultProps = {
    withErrorBlock: true,
  };

  state = {
    showErrorBlock: false,
    areErrorsNotRequested: false,
  };

  onJudgmentDay = ({ error, notRequested }) => {
    this.setState({
      showErrorBlock: error,
      areErrorsNotRequested: notRequested,
    });
  };

  onFailedGtinClick = (gtin) => {
    const { dataMap, rootInternalId } = this.props;
    const [internalId] =
      Object.entries(dataMap).find(([, data]) => data.gtin === gtin) || [];
    this.props.onEdit?.(internalId, null, rootInternalId);
  };

  renderHierarchy = (
    internalId,
    localPath,
    mainHierarchyUnit: MainHierarchyUnit | null,
    parentInternalId?: string,
    quantity?: number,
  ) => {
    // Recursive function to render a hierarchy.
    const {
      hasFeatureNoGtin,
      readOnly,
      dataMap,
      hierarchyMap,
      currentVersionInfo,
      rootInternalId,
      targetMarketId,
      onEdit,
      onDelete,
      onUpdateQuantity,
      onUpdateReference,
      isAgreed,
      hasFlatHierarchies,
      isPatchedHierarchy,
    } = this.props;

    const children = hierarchyMap[internalId] || [];
    const childrenGtins = children
      .map((child) => dataMap[child.id].gtin)
      .join();
    const isFirstLevel = !parentInternalId;
    let parentPackagingTypeCode = null;
    let parentIsLogisticalUnit = false;
    let parentIsMainHierarchyUnit = false;
    if (parentInternalId && dataMap[parentInternalId]) {
      parentPackagingTypeCode = getTypePackagingCode(
        dataMap[parentInternalId].version.typePackaging,
      );
      parentIsLogisticalUnit = !isConsumerOrDisplayUnit(
        dataMap[parentInternalId].version,
      );
      parentIsMainHierarchyUnit =
        !!mainHierarchyUnit && parentInternalId === mainHierarchyUnit.id;
    }
    const classes = {
      LogisticalHierarchy__level: !isFirstLevel,
      'LogisticalHierarchy__level--root': isFirstLevel,
    };

    const isCreated = Boolean(
      dataMap[internalId] && dataMap[internalId].product_key_id,
    );
    const parentIsCreated = Boolean(
      parentInternalId &&
        dataMap[parentInternalId] &&
        dataMap[parentInternalId].product_key_id,
    );
    const isHeterogeneousLogisticalUnit =
      getTypePackagingId(dataMap[internalId]?.version) ===
        TypePackagingLabels.CASE.id &&
      children.length > 1 &&
      children.every((child) => !!child.isConsumerUnit);
    return (
      <div className={classNames(classes)}>
        <LogisticalHierarchyUnit
          hasFeatureNoGtin={hasFeatureNoGtin}
          key={internalId}
          path={localPath}
          internalId={internalId}
          parentInternalId={parentInternalId ?? undefined}
          rootInternalId={rootInternalId}
          data={dataMap[internalId]}
          quantity={quantity ?? undefined}
          currentVersionInfo={currentVersionInfo}
          isMainHierarchy={
            !!mainHierarchyUnit &&
            getReference(mainHierarchyUnit) === getReference(currentVersionInfo)
          }
          isParentMainHierarchyUnit={parentIsMainHierarchyUnit}
          hasChildren={children.length > 0}
          childrenGtins={childrenGtins}
          parentPackagingTypeCode={parentPackagingTypeCode ?? undefined}
          parentIsLogisticalUnit={parentIsLogisticalUnit}
          targetMarketId={targetMarketId}
          onEdit={onEdit}
          onUpdateQuantity={onUpdateQuantity}
          onUpdateReference={onUpdateReference}
          onDelete={onDelete}
          isAgreed={isAgreed}
          readOnly={readOnly}
          isCreated={isCreated}
          parentIsCreated={parentIsCreated}
          hasFlatHierarchies={hasFlatHierarchies}
          isPatchedHierarchy={isPatchedHierarchy}
          isHeterogeneousLogisticalUnit={isHeterogeneousLogisticalUnit}
        />
        {this.renderChildren(
          children,
          internalId,
          localPath,
          dataMap,
          mainHierarchyUnit,
        )}
      </div>
    );
  };

  renderChildren(
    children,
    internalId,
    localPath,
    dataMap,
    mainHierarchyUnit: MainHierarchyUnit | null,
  ) {
    const { currentVersionInfo, readOnly, isPatchedHierarchy } = this.props;

    const data = dataMap[internalId];
    const isStandardTextileCase =
      children.length > 0 &&
      children
        .map((c) => dataMap[c.id].gtin)
        .every((g) => currentVersionInfo.textileVariantGTINs?.includes(g));
    const addLevel = (
      <div
        key="add-level"
        className="LogisticalHierarchy__child LogisticalHierarchy__child LogisticalHierarchy__child--last--addNewLevel"
      >
        <div className="LogisticalHierarchy__level LogisticalHierarchy__level--addNewLevel">
          <div className="LogisticalHierarchy__addNewLevel">
            <i className="mdi mdi-plus-circle" />
            <span className="LogisticalHierarchy__addNewLevel__label">
              {i18n.t(
                'frontproductstream.logistical_hierarchies.add_unit.text',
                {
                  defaultValue: 'Add a unit to the hierarchy',
                },
              )}
            </span>
            <LogisticalHierarchyCreateUnitDropdown
              internalId={internalId}
              typePackagingId={getTypePackagingId(data.version)}
              isStandardTextileCase={isStandardTextileCase}
              isPatchedHierarchy={isPatchedHierarchy}
              mainHierarchyUnitId={mainHierarchyUnit?.id}
              hierarchyMap={this.props.hierarchyMap}
              dataMap={this.props.dataMap}
              currentVersionInfo={currentVersionInfo}
            />
          </div>
        </div>
      </div>
    );
    if (children.length > 0) {
      const textileCaseAndVariantToAddRemaining =
        isStandardTextileCase &&
        (currentVersionInfo.textileVariantGTINs?.length ?? 0) > children.length;
      const childrenNode = children.map((child, index) => {
        const childClasses = {
          LogisticalHierarchy__child: true,
          'LogisticalHierarchy__child--last':
            index === children.length - 1 &&
            !textileCaseAndVariantToAddRemaining,
        };
        return (
          <div key={child.id} className={classNames(childClasses)}>
            {this.renderHierarchy(
              child.id,
              `${localPath}-children-${index}`,
              mainHierarchyUnit,
              internalId,
              child.quantity,
            )}
          </div>
        );
      });
      // add the addLevel node for textile if the is more variant to add
      if (textileCaseAndVariantToAddRemaining) {
        childrenNode.push(addLevel);
      }
      return childrenNode;
    }

    if (!data) {
      return null;
    }

    const consumerOrDisplayUnit = isConsumerOrDisplayUnit(data.version);
    const isEach =
      getTypePackagingCode(getTypePackaging(data.version)) ===
      TypePackagingLabels.EACH.value;

    if (readOnly || isEach || consumerOrDisplayUnit) {
      return null;
    }

    return addLevel;
  }

  renderErrorBlock(rootInternalId) {
    const {
      readOnly,
      logisticalUnitsAsTree,
      hasFlatHierarchies,
      withErrorBlock,
    } = this.props;
    if (hasFlatHierarchies || !withErrorBlock) {
      return null;
    }
    return (
      <div
        className={classNames('Raguel__block', {
          'FormField--raguelError': this.state.showErrorBlock,
          'FormField--notRequested': this.state.areErrorsNotRequested,
        })}
      >
        <Raguel
          entityId={rootInternalId}
          entityKind={ENTITY_TYPE_PRODUCT_VERSION_HIERARCHY}
          label={i18n.t(
            'frontproductstream.logistical_hierarchies.error_block.title',
            {
              defaultValue: 'Logistical Hierarchy',
            },
          )}
          model="logisticalHierarchies"
          value={logisticalUnitsAsTree}
          onJudgmentDay={this.onJudgmentDay}
          onGtinClick={this.onFailedGtinClick}
          readOnly={readOnly}
        />
      </div>
    );
  }

  render() {
    const {
      currentVersionInfo,
      path,
      rootInternalId,
      dataMap,
      hierarchyMap,
      readOnly,
      onUpdateReference,
    } = this.props;

    const mainHierarchyUnit = getMainHierarchyUnit(
      rootInternalId,
      dataMap,
      hierarchyMap,
      currentVersionInfo,
    );

    const gtin = getReference(dataMap[rootInternalId]);

    return (
      <>
        {this.renderErrorBlock(rootInternalId)}
        <div
          id={`hierarchy-${rootInternalId}`}
          className="LogisticalHierarchy"
          data-logistical-hierarchy={`${gtin}`}
          aria-label={i18n.t(
            'frontproductstream.logistical_hierarchies.aria_label  ',
            {
              gtin: gtin,
              defaultValue: 'Logistical hierarchy with gtin {{gtin}}',
            },
          )}
        >
          {this.renderHierarchy(rootInternalId, path, mainHierarchyUnit)}
          {!readOnly && onUpdateReference && (
            <GTINGenerator
              baseGtin={currentVersionInfo.gtin}
              currentLanguage={currentVersionInfo.currentLanguage}
              rootInternalId={rootInternalId}
              dataMap={toJsIfImmutable(dataMap)}
              hierarchyMap={toJsIfImmutable(hierarchyMap)}
              onUpdateGTIN={onUpdateReference}
            />
          )}
        </div>
      </>
    );
  }
}

export default LogisticalHierarchy;

import classNames from 'classnames';
import { isEqual } from 'lodash';
import PropTypes from 'prop-types';
import { Component } from 'react';

import { Ellitips } from '@alkem/react-ui-ellitips';

import Tag from 'components/ui/input/tags/tag';
import Picture from 'components/ui/picture';

import './concept-label.scss';

class ConceptLabel extends Component {
  static propTypes = {
    label: PropTypes.object.isRequired,
    onClickDelete: PropTypes.func,
    onClick: PropTypes.func,
    disabled: PropTypes.bool,
    selectable: PropTypes.bool,
    selected: PropTypes.bool,
  };

  static defaultProps = {
    disabled: false,
    selectable: false,
    selected: false,
  };

  constructor(props) {
    super(props);
    this.state = {};
    this.onClickDelete = this.onClickDelete.bind(this);
    this.onClick = this.onClick.bind(this);
  }

  shouldComponentUpdate(props) {
    const { label, disabled, selectable, selected } = this.props;
    return (
      props.disabled !== disabled ||
      props.selectable !== selectable ||
      props.selected !== selected ||
      !isEqual(props.label, label)
    );
  }

  onClickDelete() {
    if (this.props.onClickDelete) {
      this.props.onClickDelete(this.props.label);
    }
  }

  onClick() {
    if (this.props.onClick) {
      this.props.onClick(this.props.label);
    }
  }

  renderPicture(concept, conceptLabel) {
    const { selectable } = this.props;
    if (!concept || !concept.id) {
      return null;
    }

    return (
      <div>
        <div className="ConceptLabel__overlay" />
        <div
          className="ConceptLabel__content"
          id={`label-tag-${selectable ? 'selectable' : ''}-${concept.id}`}
        >
          <div className="ConceptLabel__pictureContainer">
            <div className="ConceptLabel__picture">
              <Picture
                kind="Label"
                maxWidth="50px"
                maxHeight="50px"
                entity={concept}
              />
            </div>
          </div>
          <div className="ConceptLabel__labelContainer">
            <Ellitips
              label={conceptLabel}
              id={`label-tip-${selectable ? 'selectable' : ''}-${concept.id}`}
              lines={2}
            />
          </div>
        </div>
      </div>
    );
  }

  render() {
    const { label, selectable, disabled, selected } = this.props;
    const concept = label.isConceptualizedBy || {};

    const classes = {
      ConceptLabel__tag: true,
      'ConceptLabel__tag--disabled': selectable && disabled,
      'ConceptLabel__tag--selectable': selectable && !selected,
      'ConceptLabel__tag--selected': selectable && selected,
    };

    return (
      <Tag
        label={this.renderPicture(concept, concept.label)}
        value={label}
        className={classNames(classes)}
        deleteClassName="ConceptLabel__delete"
        onDelete={selectable ? null : this.onClickDelete}
        onClick={selectable ? this.onClick : null}
        readOnly={disabled}
      />
    );
  }
}

export default ConceptLabel;

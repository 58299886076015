import { Map, fromJS } from 'immutable';
import { once } from 'lodash';
import PropTypes from 'prop-types';
import { PureComponent } from 'react';
import ImmutablePropTypes from 'react-immutable-proptypes';

import SimpleFilter from 'core/modules/list/components/filter/simple';
import { workflowSharingStatusFilter } from 'core/modules/list/constants/filters/workflow-sharing-status';
import { buildFiltersFromQuery } from 'core/modules/list/utils/filters';

export class WorkflowSharingStatusFilter extends PureComponent {
  static propTypes = {
    selectedFilterMap: ImmutablePropTypes.map,
    aggregations: ImmutablePropTypes.map,
    collapsed: PropTypes.bool,
    filterQueryValues: PropTypes.array,
    onChange: PropTypes.func.isRequired,
    onCollapse: PropTypes.func.isRequired,
  };

  static defaultProps = {
    selectedFilterMap: Map(),
    aggregations: Map(),
    collapsed: false,
    filterQueryValues: [],
  };

  filterKey = workflowSharingStatusFilter.key;
  filterLabel = workflowSharingStatusFilter.filterLabel;

  updateSelectionFromQuery = once(() => {
    buildFiltersFromQuery({
      filterQueryValues: this.props.filterQueryValues,
      filterList: this.filterList,
      filterKey: this.filterKey,
      selectFilterValue: (filter) => filter.get('id'),
      selectFilterLabel: (filter) =>
        `${this.filterLabel}: ${filter.get('label')}`,
      selectFilterData: (filter) => filter,
    }).then((filtersFromQuery) => {
      this.props.onChange(filtersFromQuery, true);
    });
  });

  filterList = fromJS(
    workflowSharingStatusFilter.workflowSharingStatuses.map(({ id, name }) => ({
      id,
      label: name,
    })),
  );

  componentDidMount() {
    this.updateSelectionFromQuery();
  }

  onCollapse = (collapsed) => {
    this.props.onCollapse(this.filterKey, collapsed);
  };

  render() {
    return (
      <SimpleFilter
        id="list-filter-sourceProductStatus"
        filterList={this.filterList}
        filterKey={this.filterKey}
        filterLabel={this.filterLabel}
        selectedFilterMap={this.props.selectedFilterMap.get(this.filterKey)}
        aggregations={this.props.aggregations.get(this.filterKey)}
        collapsed={this.props.collapsed}
        onChange={this.props.onChange}
        onCollapse={this.onCollapse}
      />
    );
  }
}
